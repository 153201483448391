import request from '../api';
import { actionFactory } from './actionUtils';

const comp = "panpartner:secondaries.ajax"

const requestSecondaries = actionFactory("SECONDARIES_REQUEST");

const successSecondaries = actionFactory("SECONDARIES_SUCCESS");

export const clearSecondaries = actionFactory("SECONDARIES_RESET");

const failureSecondaries = actionFactory("SECONDARIES_FAILURE");

export const setFilterSecondaries = actionFactory("SECONDARIES_SET_FILTER");

export const clearFilterSecondaries = actionFactory("SECONDARIES_CLEAR_FILTER");

export const fetchSecondaries = (city, isGetFilter, form, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestSecondaries());
    return request(comp, "getSecondaries", {
      ...form,
      savedFilterXmlId,
      isGetFilter
    }).then(
      data => {
        const {
          secondaries,
          top,
          filter,
          subways,
          isActive,
          agent,
          isShowFilter,
          savedFilter,
          count
        } = data;
        dispatch(
          successSecondaries({
            data: secondaries,
            filterData: filter,
            top,
            subways,
            isActive,
            agent,
            isShowFilter,
            savedFilter,
            count,
            isGetFilter: isGetFilter == 1
          }),
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureSecondaries());
        return Promise.reject(error);
      },
    );
  };

const setCount = actionFactory("SECONDARIES_SET_COUNT");

export const getCount = (params, savedFilterXmlId) =>
  dispatch =>
    request(comp, "getCount", {
      ...params,
      savedFilterXmlId
    }).then(
      data => {
        dispatch(setCount({ count: data.count }));
        return Promise.resolve(data);
      },
    );

export const successSecondariesFilter = actionFactory("SECONDARIES_FILTER_SUCCESS");

export const fetchSecondariesFilter = (savedFilterXmlId, city = "spb") =>
  dispatch => request(comp, "getFilter", {
    city,
    savedFilterXmlId
  }).then(
    data => {
      dispatch(successSecondariesFilter(data));
      return Promise.resolve(data);
    },
  );
