import ym from "react-yandex-metrika";

const metrika = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case "@@router/LOCATION_CHANGE":
      if (window.yandex_metrika_accounts && window.location.host !== "dev2.panpartner.ru" && window.location.host !== "localhost:3000") {
        ym("hit", action.payload.location.pathname);
      }
      return state;
    default:
      return state;
  }
};

export default metrika;
