import React from "react";

const ThemeBg1 = (props) => {
  const {currentColor, secondColor} = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387 227" fill="none">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="387" height="227">
        <rect x="0.865234" y="0.762939" width="386" height="226.006" rx="5" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0)">
        <path d="M193.989 -37.8178L193.989 251.192L512.343 664.757L512.343 375.748L193.989 -37.8178Z" fill={secondColor}/>
        <path d="M193.942 251.691L193.942 -39.4064L427.712 -343.091L427.712 -51.9935L193.942 251.691Z" fill={currentColor}/>
        <path d="M332.558 213.221C333.599 213.221 334.442 212.375 334.442 211.331C334.442 210.288 333.599 209.442 332.558 209.442C331.517 209.442 330.674 210.288 330.674 211.331C330.674 212.375 331.517 213.221 332.558 213.221Z" fill="white"/>
        <path d="M101.077 156.554C102.117 156.554 102.961 155.708 102.961 154.665C102.961 153.621 102.117 152.775 101.077 152.775C100.036 152.775 99.1924 153.621 99.1924 154.665C99.1924 155.708 100.036 156.554 101.077 156.554Z" fill={currentColor}/>
        <path d="M189.006 151.063C190.047 151.063 190.89 150.217 190.89 149.173C190.89 148.13 190.047 147.284 189.006 147.284C187.966 147.284 187.122 148.13 187.122 149.173C187.122 150.217 187.966 151.063 189.006 151.063Z" fill="white"/>
      </g>
    </svg>
  )
};

export default ThemeBg1;
