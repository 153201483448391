import { chunk, take } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { clearEvents, fetchEvents, fetchEventsPdf } from "../actions/events";
import EmptyTable from '../components/core/EmptyTable';
import Modal from "../components/core/Modal";
import ErrorPlug from '../components/ErrorBoundry/ErrorPlug';
import CalendarSlider from "../components/Events/CalendarSlider";
import "../components/Events/index.css";
import Reports from "../components/Events/Reports";
import LoadMoreSpinner from "../components/LoadMoreSpinner";
import useDocTitle from "../hooks/useDocTitle";
import { ReactComponent as Pdf } from "../images/svg/pdf.svg";
import { socials } from "../selectors/user";
import { media } from '../utils';

const timestamp = (date) => new Date(date).setHours(0, 0, 0, 0);

const formattedDate = (date) => {
  const options = { day: "numeric", month: "long" };
  return new Date(date).toLocaleDateString("ru", options);
};

const Subscribe = () => {
  return (
    <>
      <h2 className="modal__title">Подпишись на наши соцсети</h2>
      <div className="subscribe">
        <div className="modal__hint">
          Выберите социальные сети, которые Вам интересны.
        </div>
        <ul className="subscribe__socials">
          {socials.map((social) => (
            <li className="subscribe__socials-item">
              <a
                className="subscribe__socials-link"
                href={social.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <button className="button button_with-icon button_view_social">
                  <svg className={`icon icon_social-${social.icon}`}>
                    <use xlinkHref={`#social-${social.icon}`}></use>
                  </svg>
                </button>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const ShowMore = (props) => {
  const { onClick, loading } = props;
  return (
    <div className="table__show-more">
      {loading ? (
        <LoadMoreSpinner loading={true} />
      ) : (
        <div className="table__show-more_btn" onClick={() => onClick()}>
          Показать еще{" "}
          <svg className="icon icon_arrow-bottom">
            <use xlinkHref="#arrow_bottom"></use>
          </svg>
        </div>
      )}
    </div>
  );
};

const List = (props) => {
  const { dataSource: items, customPagination } = props;

  const [page, setPage] = useState(0);
  const [pageCount] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setPage(0);
    setData(take(items, pageCount));
    setHasNextPage(items.length > pageCount);
  }, [items]);

  const loadItems = (prevArray = [], page = 0) =>
    new Promise((resolve) => {
      setTimeout(() => {
        let newArray = [];
        if (prevArray.length > page * pageCount) {
          newArray = chunk(prevArray, pageCount)[page];
        }
        resolve(newArray);
      }, 200);
    });

  const handleLoadMore = () => {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage((p + 1) * pageCount < items.length);
      setLoading(false);
    });
  };

  return (
    <>
      {data.map((event, index, events) => {
        let insertDate = true;

        if (index > 0) {
          insertDate =
            timestamp(events[index - 1].date) != timestamp(event.date);
        }

        const isAvailableRecord = () => {
          return Date.now() < new Date(event.endDateRecording) && !event.closed;
        };

        const isToday = () => {
          const options = { day: "numeric", month: "long" };
          return new Date().toLocaleDateString("ru", options) === new Date(event.date).toLocaleDateString("ru", options);
        };

        return (
          <div className="events__list-item events__day" key={index}>
            {insertDate && (
              <div className="events__day-title">
                { isToday() ?
                  <span className="color-red">Сегодня</span>
                  :
                  formattedDate(event.date)
                }
              </div>
            )}
            <div className="events__day-list">
              <div className="events__event">
                <div className="events__event-time">{event.time}</div>
                <div className="events__event-content">
                  <Link
                    className="events__event-name color-brand-1 link-hover h4"
                    to={`/events/${event.id}`}
                  >
                    {event.name}
                  </Link>
                  <div className="events__event-info">
                    <div className="events__event-label">
                      {!isAvailableRecord()
                        ? "Запись завершена"
                        : event.countEmpty > 0
                        ? `Осталось ${event.countEmpty} мест`
                        : "Мест не осталось"}
                    </div>
                    {event.countEmpty == 0 &&
                      event.countReserveEmpty > 0 &&
                      !event.closed && (
                        <Link
                          className="events__event-label link-hover"
                          to={`/events/${event.id}`}
                        >
                          Записаться в резерв
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {customPagination && hasNextPage && (
        <ShowMore onClick={handleLoadMore} loading={loading} />
      )}
    </>
  );
};

const Events = (props) => {
  const { data, fetch, reset, eventReports, fetchPdf, eventsPdf, user, banners } = props;
  const navRef = useRef(null);
  const pdf = useRef(null);

  const [subscribeModal, hideSubscribeModal] = useModal(
    ({  }) => (
      <Modal close={hideSubscribeModal}>
        <Subscribe />
      </Modal>
    ),
  );

  useEffect(() => {
    fetch();

    return () => {
      reset();
    };
  }, []);
  useDocTitle("Мероприятия")
  const sortedEvents = (identity) => {
    if (!data) return [];

    return data[identity].sort((a, b) =>
      timestamp(a.date) > timestamp(b.date) ? 1 : -1,
    );
  };

  const downloadPdf = () => {
    fetchPdf().then(() => {
      pdf.current.click();
    });
  };

  return (
    <div>
      <ErrorPlug wrappedComponent="src/pages/Events/Events.js - 1">
        <CalendarSlider navRef={navRef} data={data} user={user} />
      </ErrorPlug>

      <section className="section section_view_white">
        <div className="wrapper">
          <div className="wrapper__center">
            <div className="events__grid">
              <div className="events__grid-col events__list">
                {sortedEvents("actual").length > 0 ? <List
                  dataSource={sortedEvents("actual")}
                  customPagination={true}
                /> : <EmptyTable title="Нет запланированных мероприятий" />}
              </div>
              <div className="events__grid-col events__aside">
                <div className="events__aside_sticky">
                  {/* <div className="events__main">
										<div className="events__main-date">12 апреля 2020</div>
										<div className="events__main-time">16:00</div>
										<div className="events__main-name">
											Онлайн-обучение от застройщика по объектам ЛСР Московского
											региона
										</div>
										<div className="events__main-places">Осталось 15 мест</div>
										<button className="button button_view_default">
											Записаться
										</button>
									</div> */}
                  <div className="events__secondary events__secondary_socials">
                    {/* {media('isMobile') &&
  										<svg className="icon icon_mail-bg">
  											<use xlinkHref="#mail-bg"></use>
  										</svg>
                    } */}
                     <img className="events__secondary-img" src={require(`../assets/images/events/guy.png`).default} alt="" />
                    <div className="events__secondary-name h4">
                      Подписывайтесь на&nbsp;наши соц. сети
                    </div>
                    <div className="events__secondary-info">
                      Информация от экспертов в лайт-формате и на понятном языке
                    </div>
                    {/* <span
                      className="link js-modal-btn"
                      onClick={() => subscribeModal()}
                    >
                      Подписаться
                    </span> */}

                    <div className="events__socials">
                      <a href="https://t.me/pan_live" target={`_blank`}>
                        <img className="events__socials-img" src={require(`../assets/images/events/live.png`).default} alt="" />
                      </a>
                      <a href="https://vk.me/club156336509?ref=f7769723-2788-4d6c-bb8b-3f886431da30|source=portal" target={`_blank`}>
                        <img className="events__socials-img" src={require(`../assets/images/events/vk.png`).default} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="events__secondary">
                    {media('isMobile') &&
                      <Pdf className="pdf-icon" />
                    }
                    <div className="events__secondary-name h4">
                      Cписок мероприятий
                    </div>
                    <div className="events__secondary-info">
                      Вы можете скачать полный список мероприятий в формате PDF.
                    </div>
                    <span className="link" onClick={downloadPdf}>
                      Скачать
                    </span>
                    <a ref={pdf} href={eventsPdf} download></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {eventReports && eventReports.length > 0 && (
        <ErrorPlug wrappedComponent="src/pages/Events/Events.js - 2">
          <section className="section section_view_white">
            <div className="wrapper">
              <Reports data={eventReports} />
            </div>
          </section>
        </ErrorPlug>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.events.data,
  eventReports: state.events.eventReports,
  eventsPdf: state.events.eventsPdf,
  user: state.user,
  banners: state.banners
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (city) => dispatch(fetchEvents(city)),
  reset: () => dispatch(clearEvents()),
  fetchPdf: (city) => dispatch(fetchEventsPdf(city)),
});

Events.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
