import React, {useEffect, useMemo, useRef, useState} from 'react'
import './index.scss'
import {Link} from "react-router-dom";
import {
  SpeakerIcon, StudyIcon,
  TopCalendarIcon,
  WebinarEventsIcon,
  WebinarExcursionIcon, WebinarOfflineIcon
} from "../../components/Public/markup/icons";
import SearchInp from "../../components/core/SearchInput";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/core/Button";
import useApi from "../../hooks/useApi";
import Calendar from "react-calendar";
import {differenceInCalendarDays} from "date-fns";
import Preloader from "../../components/Preloader";
import {useModal} from "react-modal-hook";
import Wrap from "../../components/core/ModalWrap";
import MobileModal from "../../components/core/MobileModal";
import Modal from "../../components/core/Modal";
import useMedia from "../../hooks/useMedia";
import NewAlert from "../../components/core/NewAlert";
import PanModal from "../../components/core/PanModal";
import SpeakerRequest from "./SpeakerRequest";
import request, {getUrl} from "../../api";
import {useSelector} from "react-redux";
import useClickPast from "../../hooks/useClickPast";
import {getSuffixUniversal, parseDate, toFormData} from "../../utils";
import { DebounceInput } from 'react-debounce-input';
import EmptyTable from '../../components/core/EmptyTable';
import useUniversalReducer from '../../hooks/useUniversalReducer';

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

const getAbsoluteOffset = el => {
  const bounds = el?.getBoundingClientRect()
  if (!bounds) return
  return bounds.top + window.scrollY
};

const isToday = (date) => {
  const options = {day: "numeric", month: "long"};
  return new Date().toLocaleDateString("ru", options) === new Date(date).toLocaleDateString("ru", options);
};

const isTomorrow = (date) => {
  return new Date(date).getDate() - new Date().getDate() === 1;
};

const formattedDate = (date) => {
  const options = {day: "numeric", month: "long"};
  return new Date(date).toLocaleDateString("ru", options);
};

const formatDayOnly = (date) => {
  const options = {weekday: "long"};
  return new Date(date).toLocaleDateString("ru", options);
};

const ArrowIcon = () => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.47614 7.325L11.5238 4.175" stroke="#8F95AC" strokeLinecap="round"/>
    <path d="M11.5239 3.825L8.47624 0.675" stroke="#8F95AC" strokeLinecap="round"/>
    <path d="M11.7143 3.99996L0.5 3.99996" stroke="#8F95AC" strokeLinecap="round"/>
  </svg>
);

// const firstDayInCurrentMonth = new Date(new Date().setDate(1));

const NewEvents = () => {

  const isMobile = useMedia("isMobile");

  const { state, setValue, setValues } = useUniversalReducer({
    searchValue: "",
    isSearching: false,
    searchItems: false,

    selectedCity: "",
    activeType: "",
    // minDate: firstDayInCurrentMonth,

  })

  const {
    searchValue,
    isSearching,
    searchItems,

    selectedCity,
    activeType,
    minDate,

  } = state;

  const { data: { events, filter, video }, isLoading } = useApi({
    payload: ["panpartner:events.ajax", "getEventsList", toFormData({
      ...(selectedCity? {'filter[city]': selectedCity} : []),
      // ...(minDate ? { 'filter[date][min]': minDate.toLocaleDateString('en-CA') } : []),
    })],
    processData: (resp, prev) => {
      setValues({
        activeType: "",
        searchValue: "",
        isSearching: false,
        searchItems: false,

      })

      if (!resp.filter) return { ...prev, ...resp };

      const tmp = { ...resp.filter };

      tmp.city = tmp.city.map(({ code, title }) => ({ id: code ?? "", title }));

      tmp.types = tmp.types.map(type => ({ ...type, xmlId: type.xmlId ?? "" }));

      return { ...resp, filter: tmp }
    },
  }, [selectedCity, minDate])

  const searchEvent = e => {
    const searchValue = e?.target?.value ?? "";

    if (!searchValue) return setValues({
      isSearching: false,
      searchValue,
      searchItems: false
    });

    setValues({
      isSearching: true,
      searchValue,
    })

    return request("panpartner:events.ajax", "findByTitle", { title: e.target.value })
      .then(({ events }) => {
        setValues({
          isSearching: false,
          searchItems: events?.length > 0 && events
        })
      });
  }

  const getEventsPdf = () => {
    return request("panpartner:events.ajax", "getEventsPdf", {city: 'spb'})
      .then((data) => {
        let path = getUrl(data.eventsPdf)
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  }


  const filteredData = useMemo(() => {
    if (!events || !Array.isArray(events)) return [];
    const { month, year } = parseDate(minDate ? new Date(minDate) : new Date());
    return [...events]
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .filter(({ date, type, isTop }) => {
        const { month: eventMonth, year: eventYear } = parseDate(date);
        if (activeType === 'vip') {
          return isTop && (month === eventMonth && year === eventYear);
        }
        return (month === eventMonth && year === eventYear) && (!activeType || type?.xmlId === activeType);
      });

  }, [events, activeType, minDate]);

  const [currentEvent, setCurrentEvent] = useState(0);
  const containerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState('');

  const [calDate, setCalDate] = useState('')

  const scrollWithOffset = (element) => {
    const topOffset = 70;
    element.style.scrollMarginTop = `${topOffset}px`;
    element.scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    const scrollToActiveElement = () => {
      if (containerRef.current) {
        const activeElement = containerRef.current.querySelector('.hp__events-day.active');
        if (activeElement) {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    };
    scrollToActiveElement();
  }, [activeIndex, calDate]);


  const onChangeCalendar = nextValue => {
    setValue("activeType", "");
    setTimeout(()=>{
      const elem = document.getElementById(nextValue.toLocaleDateString('en-CA'));
      elem && scrollWithOffset(elem);
    })
  }

  const [currentDay, setCurrentDay] = useState(new Date(new Date().setDate(new Date().getDate())));
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let elem = document.getElementById(currentDay.toLocaleDateString('en-CA'));
      if (elem) {
        scrollWithOffset(elem);
      } else if (filteredData && filteredData.length > 0) {
        const upcomingEvents = filteredData.filter(event => new Date(event.date) > currentDay);
        if (upcomingEvents.length > 0) {
          const nextEvent = upcomingEvents.reduce((prev, current) => (
            new Date(current.date) < new Date(prev.date) ? current : prev
          ), upcomingEvents[0]);
          elem = document.getElementById(new Date(nextEvent.date).toISOString().split('T')[0]);
          if (elem) {
            scrollWithOffset(elem);
          }
        }
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentDay, filteredData]);




  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.hash.replace("#", "?"));
    const eventId = searchParam.get("eventId")
    if (!eventId || !events?.length || !events.some(event => event.id == eventId)) return
    setCurrentEvent(eventId)
    openEventModal()
  }, [events])

  useEffect(() => {
    const secondElement = document.querySelector('.events-page');
    const firstElement = secondElement.previousElementSibling;
    if (firstElement) {
      firstElement.style.display = 'none';
    }
  })


  const renderTile = ({date, view}) => {
    const eventsInCurrentDay = events.filter(item => isSameDay(new Date(item.date), date))
    if (view === 'month') {
      if (eventsInCurrentDay.length > 0) {
        return (
          <div className="hp__calendar-events">
            {eventsInCurrentDay.map((event, index) =>
              event.isTop ? <span key={event.id} className={`hp__calendar-circle hp__calendar-circle_show`}/> :
              <span key={event.id} className={`hp__calendar-circle hp__calendar-circle_${event.type.xmlId}`}/>
            )}
          </div>
        )
      }
    }
  };

  const [openEventModal, closeEventModal] = useModal(
    () => {
      const data = events.find(event => event.id == currentEvent);
      const closeEvent = () => {
        window.location.hash = ``
        closeEventModal()
      }
      return (
        <Wrap wrapper={children => isMobile
          ? <MobileModal hideDefaultClose={true} className="constructor-modal" closeClick={closeEvent}
                         title={events.title}>{children}</MobileModal>
          : <Modal hideDefaultClose={true} closeByPastClick classes="modal_task c-modal hp-modal"
                   close={closeEvent}>{children}</Modal>
        }>
          <EventModal isMobile={isMobile} closeEventModal={closeEvent} data={data} formattedDate={formattedDate}/>
        </Wrap>
      )
    }, [currentEvent]);
  const userInfo = useSelector(state => state.user.info)
  const [openSpeakerModal, closeSpeakerModal] = useModal(
    () => (
      <PanModal title={'Стать спикером'} closeModal={closeSpeakerModal} isSmall>
        <SpeakerRequest userInfo={userInfo}/>
      </PanModal>
    ),
    [],
  );

  const openEvent = id => {
    window.location.hash = `#eventId=${id}`
    openEventModal()
  }

  if (isLoading) return <Preloader loading={true}/>


  const HomeCalendar = () => {
    return (
      <>
        <div className="hp__calendar hp__calendar_events" style={{marginTop: 12}}>

          <Calendar
            onClickDay={onChangeCalendar}
            className="events-calendar"
            tileContent={renderTile}
            value={minDate ? minDate : new Date()}
            showNeighboringMonth={false}
            showFixedNumberOfWeeks={false}
            calendarType="ISO 8601"
            onClickMonth={date => setValue("minDate", date)}
            tileDisabled={({ view, date }) => {
              if (view !== "year" || !filter?.months) return;
              return !filter.months.some(({ id, year }) => {
                const { month: calendarMonth, year: calendarYear } = parseDate(date);
                return (calendarMonth === id - 1 && `${calendarYear}` === `20${year}`)
              })
            }}
          />
        </div>

        <div className="hp__calendar-legend">
          <span className="hp__event-status hp__event-status_webinar text" onClick={() => {setValue("activeType", 'webinar')}}>{`Вебинар`}</span>
          <span className="hp__event-status hp__event-status_learning text" onClick={() => {setValue("activeType", 'learning')}}>{`Офлайн-обучение`}</span>
          <span className="hp__event-status hp__event-status_excursion text" onClick={() => {setValue("activeType", 'excursion')}}>{`Экскурсии`}</span>
          <span className="hp__event-status hp__event-status_vip text" onClick={() => {setValue("activeType", 'vip')}}>{`VIP`}</span>
        </div>
      </>
    )
  };

  return (
    <div className="wrapper new-events">
      <div className="common-header">
        <div className="common-header__title common-header__title_withlink">
          <h3 className="common-title">
            Мероприятия
          </h3>
          <a href={'https://panpartner.ru/personal/events'} target="_blank" className="common-header__fullinfo-link">
            <span style={{marginTop: -2, marginRight: 10, float: 'left'}}><TopCalendarIcon/></span>
            Мои записи на меропрития
          </a>
        </div>
      </div>
      <div className="new-events__filters">
        <div className="new-events__filters-left">
          <SearchInp
            value={searchValue}
            onChange={searchEvent}
            reset={searchEvent}
            isSearching={isSearching}
            placeholder="Поиск по названию мероприятия"
            renderInput={props => <DebounceInput
              minLength={1}
              debounceTimeout={800}
              autoComplete="off"
              className="section-search__inp"
              {...props}
            />}
          />
          <Dropdown
            value={filter.city?.find?.(({ id }) => selectedCity === id)}
            options={filter.city}
            onChange={v => setValue("selectedCity", v)}
          />

          {searchItems ?
          <ul className="new-events__search-result">
            {
              searchItems.map((item) => {
                return (
                  <li key={item.id}>
                    <a href={item.link} target="_blank">
                      <div>
                        {item.type.xmlId === 'webinar' && <WebinarEventsIcon/>}
                        {item.type.xmlId === 'excursion' && <WebinarExcursionIcon/>}
                        {item.type.xmlId === 'learning' && <WebinarOfflineIcon/>}
                        <p>{item.title}</p>
                      </div>
                      <span>Перейти</span>
                    </a>
                  </li>
                )
              })
            }
          </ul> : null
          }
        </div>
        <div className="new-events__filters-right" style={{display: 'flex', gap: 20}}>
          <Button buttonType="line" onClick={() => window.open(getUrl('/office-training'), '_blank')}><StudyIcon/>Выездное обучение</Button>
          <Button buttonType="line" onClick={openSpeakerModal}><SpeakerIcon/>Стать спикером</Button>
        </div>
      </div>
      <div className="new-events__tabs">
        <EventTabs items={filter.types} activeTab={activeType} onChange={({xmlId})=>setValue("activeType", xmlId)}/>
      </div>
      <div className="sf">
        {!events.length && <EmptyTable title="Мероприятий пока нет, загляните, пожалуйста, позже." hideBorder />}
        {!!events.length && <div className="sf__detail-row sf__detail-row--common-page">
          <div className="sf__detail-col sf__detail-col_events-list" ref={containerRef}>

            {filteredData && filteredData.map((item, index) => {
              let insertDate = false;

              if (index > 0) {
                insertDate = new Date(filteredData[index - 1].date).getDate() != new Date(item.date).getDate();
              }

              return <Event
                data={item}
                openEventModal={openEvent}
                insertDate={insertDate}
                index={item.id}
                setCurrentEvent={setCurrentEvent}
                key={item.id}

                onTheAir={item.onTheAir ? item.onTheAir : false}
                isTop={item.isTop}
                link={item.link}
                ind={index}
                isActive={activeIndex === index}
              />
            })}

          </div>
          <div className="sf__detail-col sf__sticky sf__sticky_calendar-events" style={{zIndex: 2, marginTop: -86}}>

            <HomeCalendar />

            {
              video &&
              <div className="new-events__videolist">
                <div className="new-events__videolist-header">
                  <div className="new-events__videolist-title h5-l">Записи вебинаров</div>
                  <div className="new-events__videolist-link">
                    <Link to='/videos' target="_blank">
                      Смотреть все
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4758 15.325L17.5234 12.175" stroke="#8F95AC" stroke-linecap="round"/>
                        <path d="M17.5242 11.825L14.4766 8.675" stroke="#8F95AC" stroke-linecap="round"/>
                        <path d="M17.7143 12L6.5 12" stroke="#8F95AC" stroke-linecap="round"/>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="new-events__vebinars">
                  {
                    video.map((i) => {
                      return (
                        <Link className="new-events__vebinar-item" to={'/videos/' + i.id} target="_blank">
                          <div className="new-events__vebinar-img">
                            <img src={getUrl(i?.photo)} alt=""/>
                            <div className="new-events__vebinar-time">{i?.duration}</div>
                          </div>
                          <div className="new-events__vebinar-info">
                            <div className="new-events__vebinar-date">{new Date(i?.activeFrom).toLocaleDateString('ru-RU', { day: '2-digit', month: 'short', year: 'numeric' })}</div>
                            <div className="new-events__vebinar-title">
                              {i?.title}
                            </div>
                          </div>
                        </Link>
                      )
                    })
                  }
                </div>
              </div>
            }
            <div style={{ marginTop: 20 }}>
              <NewAlert
                title="Список мероприятий"
                text="Вы можете скачать весь список мероприятий в "
                link="формате PDF"
                bgColor="#f6f7f8"
                iconColor="#2CBA2E"
                linkColor="#2CBA2E"
                customModalLink={getEventsPdf}
              />
            </div>

          </div>
        </div>}
      </div>
    </div>
  )
}
const EventTabs = ({ items, activeTab, onChange }) => <ul className="tabs-nav">
  {
    items.map((it) => (
      <li className="tabs-nav-item" key={it.id}>
        <button
          className={`tabs-nav-item__button${it.xmlId === activeTab ? ` tabs-nav-item__button--active` : ``}`}
          onClick={() => onChange(it)}
        >
          {it.title}
        </button>
      </li>
    ))
  }
</ul>

const Event = (props) => {
  const { data, insertDate, openEventModal, index, setCurrentEvent, currentTab, setDateBound, onTheAir, isTop, link, calcDate, ind, isActive } = props;
  const date = new Date(data.date)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const eventDate = `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;


  return (

    <section className={`hp__events-day  ${isActive ? 'active' : ''}`}
    >
      {
        insertDate &&
        (
         <header className={`hp__events-header`} id={`${new Date(data.date).toLocaleDateString('en-CA')}`}>
            <span className={`hp__events-label ${eventDate === calcDate ? 'hp__events-label_active' : ''}`}>
              {
                isToday(data.date) ? `Сегодня` :
                  isTomorrow(data.date) ? `Завтра` : (
                    <>
                      {`${formattedDate(data.date)}, `}
                      <span>{formatDayOnly(data.date)}</span>
                    </>
                  )
              }
            </span>
          </header>
        )
      }

      <a href={link}
         target="_blank"
         type="button"
         className={`button hp__event hp__event_${data.type.xmlId}`}
         style={isTop ? {backgroundColor: '#F6F7F8', borderColor: '#F6F7F8', display: 'block'} : {display: 'block'}}
      >
        <header className="hp__event-header">
          {!onTheAir ? <span className="hp__event-time">{data.time}</span> :
            <span className="hp__event-status hp__event-status_ether text">Мы в эфире</span>}
          {data.isTop ?  <span className="hp__event-status text">VIP</span> :
          <span className="hp__event-status text">{data.type.title}</span>
          }
        </header>
        <h4 className="hp__event-name h5-l">{data.title}</h4>
        <footer className="hp__event-footer">
          {
            data.countEmpty > 0 && !data.closed &&
            <span className="hp__event-places">
              Осталось <span className="hp__event-counterplace">{data.countEmpty} </span>
              мест{getSuffixUniversal({ "1-1": "о", "2-4": "а", default: "" }, data.countEmpty)}
            </span>
          }
          {
            data.countEmpty == 0 && !data.closed &&
            <span className="hp__event-places">
              Мест не осталось
            </span>
          }
          {
            data.closed &&
            <span className="hp__event-places">
              Запись завершена
            </span>
          }
          <span type="button" className="button hp__event-showmore">
            Подробнее
          </span>
        </footer>
      </a>

    </section>
  );
};
const EventModal = ({data, isMobile, closeEventModal, formattedDate}) => {
  return (
    <div className="hp-modal__body">
      {!isMobile &&
      <button type="button"
              className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
              aria-label="Закрыть окно"
              onClick={closeEventModal}
      >
          <span className="c-modal__icon">
            <svg className="icon icon_cross">
              <use xlinkHref="#cross"/>
            </svg>
          </span>
      </button>
      }

      <div className="hp-modal__content">
        <h3 className="h3 hp-modal__title">{data.title}</h3>
        <div className="hp-modal__container">
          <div className="hp-modal__info">
            <div className="hp-modal__dates hp-modal__info-item">
              <span className="hp-modal__dates-title caps">Дата и время:</span>
              <h4 className="h4 hp-modal__h4">{`${formattedDate(data.date)} в ${data.time}`}</h4>
              <span className={`hp-modal__status hp-modal__status_${data.type.xmlId}`}>{data.type.title}</span>
            </div>

            <div className="hp-modal__speaker hp-modal__info-item">
              <span className="hp-modal__dates-title caps">Спикер</span>
              <h4 className="h4 hp-modal__h4">{data.speaker}</h4>
              <span className="hp-modal__text hp-modal__dates-text text">{data.speakerPost}</span>
            </div>
          </div>

          <div className="hp-modal__right-box hp-modal__descr"
               dangerouslySetInnerHTML={{
                 __html: data.detailText,
               }}>
          </div>
        </div>

        <div className="hp-modal__block">
          <div className="hp-modal__block-inner hp-modal__descr">
            Чтобы принять участие в мероприятии, <Link to={`/login?event=${data.id}`}
                                                       className="link link_red">войдите</Link> или <Link
            to={`/register`} className="link link_red">зарегистрируйтесь</Link>
          </div>
        </div>

      </div>
    </div>
  )
};
const MonthSelector = ({ value, onChange, months, mon, setMon, monthN }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMonthChange = (selectedMonth) => {
    const getValue = (val) => (val < 10 ? `0${val}` : val);
    const selectedDate = new Date(`20${selectedMonth.year}-${getValue(selectedMonth.id)}-0${1}`);
    onChange(selectedDate);
    setMon(selectedMonth.title)
    setIsOpen(false);
    // debugger
  };
  const node = useClickPast(() => {setIsOpen(false)});
  return (
    <div className={`custom-select custom-calendar-select ${isOpen ? 'is-showing' : ''}`} ref={node}>
      <div
        className={`custom-select__selected ${months.length < 2 ? ' custom-select__selected_noselect' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {mon ? mon : months.find(({ title }) => title === monthN)?.title}
      </div>
      <ul className="custom-select__options">
        {months.map((month) => (
          <li
            key={month.id}
            className={`custom-select__option ${month.id === value ? 'is-active' : ''}`}
            onClick={() => handleMonthChange(month)}
          >
            {month?.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewEvents
