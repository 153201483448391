import React, { memo, useState } from 'react';
import useMedia from '../../../hooks/useMedia';
import RadioDropdown from '../../core/RadioDropdown';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SearchFilter from '../../FilterRealty/SearchFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import SubmitButton from '../../SubmitButton';
import { getSelectPropsCreator } from '../utils';
import FilterBtn from './FilterBtn';
import VillagesParcelfilter from "./VillagesParcelfilter";
import {
  SuburbanAdvantagesFilterIcon,
  SuburbanCharacterFilterIcon,
  SuburbanHouseFilterIcon,
  SuburbanNavFilterIcon
} from "./icons";



const VillageFilterFormNew = memo(props => {
  const {
    clearExcludeOnly,
    clearFilterOnly,
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    searchPlaceholder,
    searchFilter,
    activeTab
  } = props


  const createSelectProps = getSelectPropsCreator(props);

  const isLaptop = useMedia('isLaptop');
  return (
    <>

      {activeTab.typeId === "house" ?
        <>

          <div style={{ marginTop: 0 }} className={`sf__filter filter__max filter__max_six show_second_filter form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>

            {!!filterData?.materialHouse?.length && <SelectFilter
              {...createSelectProps("materialHouse")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Материалы дома",
                identity: "materialHouse",
              }}
            />}

            {!!filterData?.countBedrooms?.items?.length && <SelectFilter
              {...createSelectProps("countBedrooms")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Кол-во спален",
                identity: "countBedrooms",
              }}
              items={filterData?.countBedrooms?.items}
            />}

            {!!filterData.countFloors?.items?.length && <FloorsFilter
              autoPosition
              items={filterData.countFloors?.items ?? []}
              filter={filter.countFloors ?? {}}
              wrapperClass="sf__filter-item"
              data={{
                title: 'Кол-во этажей',
                minPlaceholder: 'от ',
                maxPlaceholder: 'до ',
                defaultValues: filterData.countFloors?.range ?? null,
              }}
              onChange={v => setFilterAndExclude(v, 'countFloors')}
            />}

            {!!filterData?.countBathrooms?.items?.length && <SelectFilter
              {...createSelectProps("countBathrooms")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Кол-во санузлов",
                identity: "countBathrooms",
              }}
              items={filterData?.countBathrooms?.items}
            />}

            {!!filterData?.paymentType?.length && <SelectFilter
              {...createSelectProps("paymentType")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Способ оплаты",
                identity: "paymentType",
              }}
            />}

            {!!filterData?.squareHouse?.items?.length && <SquareFilterOnly
              wrapperClass="sf__filter-item"
              filter={filter.squareHouse ?? { min: "", max: "" }}
              data={{
                title: "Общая S дома",
                identity: "squareHouse",
                minPlaceholder: 'S дома от ',
                maxPlaceholder: 'S дома до ',
                defaultValues: filterData.squareHouse?.range ?? null
              }}
              onChange={({ filter }) => handleFilter('squareHouse', filter)}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              fullFilter={filter}
              identity={"squareHouse"}
              items={filterData.squareHouse?.items}
              showPlaceholderOnLists
              debounced
            />}

            {!!filterData?.squareLiving?.items?.length && <SquareFilterOnly
              wrapperClass="sf__filter-item"
              filter={filter.squareLiving ?? { min: "", max: "" }}
              data={{
                title: "Жилая S дома",
                identity: "squareLiving",
                minPlaceholder: 'S дома от ',
                maxPlaceholder: 'S дома до ',
                defaultValues: filterData.squareLiving?.range ?? null
              }}
              onChange={({ filter }) => handleFilter('squareLiving', filter)}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              fullFilter={filter}
              identity={"squareLiving"}
              items={filterData.squareLiving?.items}
              showPlaceholderOnLists
              debounced
            />}

            {<SelectFilter
              {...createSelectProps("communicationType")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Коммуникации",
                identity: "communicationType",
              }}
              items={filterData?.communicationType}
            />}

            {!!filterData?.landStatus?.length && <SelectFilter
              {...createSelectProps("landStatus")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Статус земли",
                identity: "landStatus",
              }}
            />}

            {!!filterData?.district?.length && <SelectFilter
              {...createSelectProps("district")}
              data={{
                title: "Район",
                identity: "district",
              }}
            />}

            {!!filterData.price && <PriceFilter
              autoPosition
              filter={filter.price ?? { min: "", max: "" }}
              items={filterData.price?.items ?? []}
              data={{
                title: "Цена",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.prices?.range ?? 0,
              }}
              onChange={v => setFilterAndExclude(v, "price")}
              debounced
            />}

            {!!filterData?.decorationHouse?.length && <SelectFilter
              {...createSelectProps("decorationHouse")}
              data={{
                title: "Отделка",
                identity: "decorationHouse",
              }}
            />}

            <EndingsFilter
              autoPosition
              ident={"endingDate"}
              filter={filter.endingDate ?? { min: "", max: "" }}
              items={filterData.endingDate ?? []}
              data={{
                title: "Срок сдачи",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: null,
              }}
              onChange={v => setFilterAndExclude(v, "endingDate")}
              secondData={{
                title: "Сдан",
                value: filter.isHanded ?? "N"
              }}
              secondChange={v => handleFilter("isHanded", v === "Y" && v)}
            />

            <SelectFilter
              {...createSelectProps("houseType")}
              wrapperClass="sf__filter-item"
              data={{
                title: "Тип дома",
                identity: "houseType",
              }}
            />

            <div className="filter__max_six-toggles">
              <Toggle
                ident="hasTerrace"
                {...props}
                text="Терраса"
              />
              <Toggle
                text="Кухня-гостинная"
                ident="hasKitchenLiving"
                {...props}
              />
              <Toggle
                text="Панорамные окна"
                ident="hasPanoramicWindows"
                {...props}
              />

              <Toggle
                text="Гардероб"
                ident="hasWardrobe"
                {...props}
              />

              <Toggle
                text="Второй свет"
                ident="hasSecondLight"
                {...props}
              />

              <Toggle
                text="Мансардный этаж"
                ident="hasAtticFloor"
                {...props}
              />

              <Toggle
                text="Сауна"
                ident="hasSauna"
                {...props}
              />
            </div>




          </div>
          {/*<div style={{ marginTop: 0 }} className={`sf__filter filter__max filter__max_six show_second_filter form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>*/}
          {/*  <div className="filter-title" style={{ gridColumn: '1', gridRow: "1 / span 2" }}>*/}
          {/*    <SuburbanCharacterFilterIcon />*/}
          {/*    Характеристики 2*/}
          {/*  </div>*/}

          {/*  {!!filterData?.squareHouse?.items?.length && <SquareFilterOnly*/}
          {/*    wrapperClass="sf__filter-item"*/}
          {/*    filter={filter.squareHouse ?? { min: "", max: "" }}*/}
          {/*    data={{*/}
          {/*      title: "Общая S дома",*/}
          {/*      identity: "squareHouse",*/}
          {/*      minPlaceholder: 'S дома от ',*/}
          {/*      maxPlaceholder: 'S дома до ',*/}
          {/*      defaultValues: filterData.squareHouse?.range ?? null*/}
          {/*    }}*/}
          {/*    onChange={({ filter }) => handleFilter('squareHouse', filter)}*/}
          {/*    clearFilterOnly={clearFilterOnly}*/}
          {/*    clearExcludeOnly={clearExcludeOnly}*/}
          {/*    fullFilter={filter}*/}
          {/*    identity={"squareHouse"}*/}
          {/*    items={filterData.squareHouse?.items}*/}
          {/*    showPlaceholderOnLists*/}
          {/*    debounced*/}
          {/*  />}*/}

          {/*  {!!filterData?.squareLiving?.items?.length && <SquareFilterOnly*/}
          {/*    wrapperClass="sf__filter-item"*/}
          {/*    filter={filter.squareLiving ?? { min: "", max: "" }}*/}
          {/*    data={{*/}
          {/*      title: "Жилая S дома",*/}
          {/*      identity: "squareLiving",*/}
          {/*      minPlaceholder: 'S дома от ',*/}
          {/*      maxPlaceholder: 'S дома до ',*/}
          {/*      defaultValues: filterData.squareLiving?.range ?? null*/}
          {/*    }}*/}
          {/*    onChange={({ filter }) => handleFilter('squareLiving', filter)}*/}
          {/*    clearFilterOnly={clearFilterOnly}*/}
          {/*    clearExcludeOnly={clearExcludeOnly}*/}
          {/*    fullFilter={filter}*/}
          {/*    identity={"squareLiving"}*/}
          {/*    items={filterData.squareLiving?.items}*/}
          {/*    showPlaceholderOnLists*/}
          {/*    debounced*/}
          {/*  />}*/}

          {/*  {<SelectFilter*/}
          {/*    {...createSelectProps("communicationType")}*/}
          {/*    wrapperClass="sf__filter-item"*/}
          {/*    data={{*/}
          {/*      title: "Коммуникации",*/}
          {/*      identity: "communicationType",*/}
          {/*    }}*/}
          {/*    items={filterData?.communicationType}*/}
          {/*  />}*/}

          {/*  {!!filterData?.landStatus?.length && <SelectFilter*/}
          {/*    {...createSelectProps("landStatus")}*/}
          {/*    wrapperClass="sf__filter-item"*/}
          {/*    data={{*/}
          {/*      title: "Статус земли",*/}
          {/*      identity: "landStatus",*/}
          {/*    }}*/}
          {/*  />}*/}

          {/*  {!!filterData?.district?.length && <SelectFilter*/}
          {/*    {...createSelectProps("district")}*/}
          {/*    data={{*/}
          {/*      title: "Район",*/}
          {/*      identity: "district",*/}
          {/*    }}*/}
          {/*  />}*/}

          {/*  {!!filterData.price && <PriceFilter*/}
          {/*    autoPosition*/}
          {/*    filter={filter.price ?? { min: "", max: "" }}*/}
          {/*    items={filterData.price?.items ?? []}*/}
          {/*    data={{*/}
          {/*      title: "Цена",*/}
          {/*      minPlaceholder: "от ",*/}
          {/*      maxPlaceholder: "до ",*/}
          {/*      defaultValues: filterData.prices?.range ?? 0,*/}
          {/*    }}*/}
          {/*    onChange={v => setFilterAndExclude(v, "price")}*/}
          {/*    debounced*/}
          {/*  />}*/}

          {/*  {!!filterData?.decorationHouse?.length && <SelectFilter*/}
          {/*    {...createSelectProps("decorationHouse")}*/}
          {/*    data={{*/}
          {/*      title: "Отделка",*/}
          {/*      identity: "decorationHouse",*/}
          {/*    }}*/}
          {/*  />}*/}

          {/*  <EndingsFilter*/}
          {/*    autoPosition*/}
          {/*    ident={"endingDate"}*/}
          {/*    filter={filter.endingDate ?? { min: "", max: "" }}*/}
          {/*    items={filterData.endingDate ?? []}*/}
          {/*    data={{*/}
          {/*      title: "Срок сдачи",*/}
          {/*      minPlaceholder: "от ",*/}
          {/*      maxPlaceholder: "до ",*/}
          {/*      defaultValues: null,*/}
          {/*    }}*/}
          {/*    onChange={v => setFilterAndExclude(v, "endingDate")}*/}
          {/*    secondData={{*/}
          {/*      title: "Сдан",*/}
          {/*      value: filter.isHanded ?? "N"*/}
          {/*    }}*/}
          {/*    secondChange={v => handleFilter("isHanded", v === "Y" && v)}*/}
          {/*  />*/}

          {/*  <SelectFilter*/}
          {/*    {...createSelectProps("houseType")}*/}
          {/*    wrapperClass="sf__filter-item"*/}
          {/*    data={{*/}
          {/*      title: "Тип дома",*/}
          {/*      identity: "houseType",*/}
          {/*    }}*/}
          {/*  />*/}


          {/*</div>*/}
          {/*<div style={{ marginTop: 0 }} className={`sf__filter filter__max filter__max_multi form smartfilter-wrapper  ${!isLaptop ? "" : "flex"}`}>*/}
          {/*  <div className="filter-title">*/}
          {/*    <SuburbanAdvantagesFilterIcon />*/}
          {/*    Приемущества*/}
          {/*  </div>*/}

          {/*  <Toggle*/}
          {/*    ident="hasTerrace"*/}
          {/*    {...props}*/}
          {/*    text="Терраса"*/}
          {/*  />*/}
          {/*  <Toggle*/}
          {/*    text="Кухня-гостинная"*/}
          {/*    ident="hasKitchenLiving"*/}
          {/*    {...props}*/}
          {/*  />*/}
          {/*  <Toggle*/}
          {/*    text="Панорамные окна"*/}
          {/*    ident="hasPanoramicWindows"*/}
          {/*    {...props}*/}
          {/*  />*/}

          {/*  <Toggle*/}
          {/*    text="Гардероб"*/}
          {/*    ident="hasWardrobe"*/}
          {/*    {...props}*/}
          {/*  />*/}

          {/*  <Toggle*/}
          {/*    text="Второй свет"*/}
          {/*    ident="hasSecondLight"*/}
          {/*    {...props}*/}
          {/*  />*/}

          {/*  <Toggle*/}
          {/*    text="Мансардный этаж"*/}
          {/*    ident="hasAtticFloor"*/}
          {/*    {...props}*/}
          {/*  />*/}

          {/*  <Toggle*/}
          {/*    text="Сауна"*/}
          {/*    ident="hasSauna"*/}
          {/*    {...props}*/}
          {/*  />*/}





          {/*</div>*/}


        </> :
        <div style={{ marginTop: 0 }} className="sf__filter filter__max form filter__max_six smartfilter-wrapper ">

          {!!filterData?.district?.length && <SelectFilter
            {...createSelectProps("district")}
            wrapperClass=" "
            data={{
              title: "Район",
              identity: "district",
            }}
          />}

          <SelectFilter
            {...createSelectProps("paymentType")}
            wrapperClass=" "
            data={{
              title: "Способ оплаты",
              identity: "paymentType",
            }}
          />
          <EndingsFilter
            autoPosition
            ident={"endingDate"}
            filter={filter.endingDate ?? { min: "", max: "" }}
            items={filterData.endingDate ?? []}
            data={{
              title: "Срок сдачи",
              minPlaceholder: "от ",
              maxPlaceholder: "до ",
              defaultValues: null,
            }}
            onChange={v => setFilterAndExclude(v, "endingDate")}
            secondData={{
              title: "Сдан",
              value: filter.isHanded ?? "N"
            }}
            secondChange={v => handleFilter("isHanded", v === "Y" && v)}
          />

          <SelectFilter
            {...createSelectProps("landStatus")}
            wrapperClass=" "
            data={{
              title: "Статус земли",
              identity: "landStatus",
            }}
          />
          {!!filterData.squareParcel?.range && <SquareFilterOnly
            autoPosition
            wrapperClass=" "
            filter={filter.squareParcel ?? { min: "", max: "" }}
            data={{
              title: 'Площадь участка',
              minPlaceholder: 'S уч. от ',
              maxPlaceholder: 'S уч. до ',
              defaultValues: filterData.squareParcel?.range ?? null,
            }}
            placeholderMes={'сот.'}
            onChange={v => setFilterAndExclude(v, 'squareParcel')}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
            fullFilter={filter}
            identity={"squareParcel"}
            items={filterData.squareParcel?.items}
            showPlaceholderOnLists
            debounced
          />}
          {!!filterData.price && <PriceFilter
            autoPosition
            filter={filter.price ?? { min: "", max: "" }}
            items={filterData.price?.items ?? []}
            wrapperClass=" "
            data={{
              title: "Цена",
              minPlaceholder: "от ",
              maxPlaceholder: "до ",
              defaultValues: filterData.price?.range ?? 0,
            }}
            onChange={v => setFilterAndExclude(v, "price")}
            debounced
          />}

        </div>
      }
    </>
  );
});

export default VillageFilterFormNew;


const Toggle = ({ title, filter, handleFilter, ident, text}) => {
  return (
    <div className="index-search__show-booked">
      <label className="switch" htmlFor={`filter-booked-${ident}`}>
        <div className="switch__box">
          <input

            className="switch__control"
            type="checkbox"
            id={`filter-booked-${ident}`}
            checked={filter[ident] === "Y"}
            onChange={e => handleFilter(ident, filter[ident] === "Y" ? "" : "Y")}

          />
          <div
            className="switch__input"
            htmlFor={`filter-booked-${ident}`}
          />
          <div className="switch__marker" />
        </div>
        <span className="switch__label">
          {/*{title}*/}
          {text}
        </span>
      </label>
    </div>
  )
}
