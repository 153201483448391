import { useMediaQuery } from 'beautiful-react-hooks';
import formatter from 'format-number';
import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { clearApartment, fetchApartment } from '../../actions/apartment';
import { fetchClients } from '../../actions/clients';
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from '../../actions/favorites';
import { getLand } from '../../actions/suburbans';
import { getPricePerMeter } from '../../utils';
import AddPresentation from '../AddPresentation';
import PopoverBlock from '../Cards/PopoverBlock';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Wrap from '../core/ModalWrap';
import ReserveApartment from '../forms/ReserveApartment';
import ReserveAssignation from "../forms/ReserveAssignation";
import ViewApartment from '../forms/ViewApartment';
import Manager from '../Manager';
import Preloader from '../Preloader';
import { successToast } from '../toasts';
import Banks from './Banks';
import MortgageBlock from './MortgageBlock';
import PhotosBlock from './PhotosBlock';
import PresentationBlock from './PresentationBlock';
const BlockLink = (props) => {
  const { id, city, name, type, coordinates, address, square, squareParcel } = props;
  const url = `/suburban/${city}/${id}`;

  const [showMap, setShowMap] = useState(false);
  const [Maps, setMaps] = useState(null)
  const [initMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const modalMap = useRef(null);
  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };

  useEffect(() => {
    if (!modalMap) return;
    dynamicMapImport()
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);

  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({YMaps, Map, Placemark, RulerControl})
  };

  return (
    <div className="flat__complex for-suburbans">
      <div className="flat__complex-title">
        <div className="flat__complex-title-wrap">
          <h1 className="h1" dangerouslySetInnerHTML={{ __html: `${type}, ${square ? `${square} м²` : `${squareParcel} сот.`}`}}></h1>

          {false && <div className="suburban-view-counter">
            <svg className="icon icon_watch">
              <use xlinkHref="#icon_watch"></use>
            </svg>

            34
          </div>}

          <span
            className="show__map"
            onClick={() => setShowMap(true)}
          >
          </span>
        </div>

        {/*
        <Link to={`${url}${window.location.search}`} className={`link flat__complex-link`}>
          Страница посёлка
        </Link>*/}
      </div>

      <div className="modal" ref={modalMap}>
        <div className="modal__shadow " data-id="dist-map"></div>
        <div className="modal__content">
          <div className="modal__inner">
            <div
              className="modal__close "
              data-id="dist-map"
              onClick={() => setShowMap(false)}
            >
              <svg className="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
            <h3 className="modal__title">{`${type} «${name}» на карте`}</h3>
            <div className="dist-map" id="dist-map-container">
              {initMap || showMap ? (
                <Maps.YMaps>
                  <Maps.Map
                    defaultState={{
                      center: coordinates,
                      zoom: 16,
                    }}
                    className="complex__map-wrap"
                    onLoad={mapLoad}
                  >
                    <Maps.Placemark
                      geometry={coordinates}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: "/assets/images/place.png",
                        iconImageSize: [25, 40],
                        iconImageOffset: [-25, -20],
                      }}
                    />
                    <Maps.RulerControl
                      options={{ position: { top: 10, left: 10 } }}
                    />
                  </Maps.Map>
                </Maps.YMaps>
              ) : (
                <div className="dist-map" id="dist-map-container" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const FlatColItem = ({ title, value, forFeatures, wrapperStyles }) => {
  if (!title || !value) return null
  return <div className="flat__info-col" style={{...wrapperStyles}}>
    <div className="flat__info-item">
      <div className="flat__info-item_value">
        {forFeatures ? value.map(item => item).join(', ') : value}
      </div>
      <div className="flat__info-item_desc">{title}</div>
    </div>
  </div>
}

const priceFormat = (price) => {
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

const Apartment = (props) => {
  const {
    fetch,
    reset,
    data,
    manager,
    banks,
    error,
    addToFavorites,
    removeFromFavorites,
    addClientToFavorite,
    removeClientFromFavorite,
    clients,
    fetchClients,
    getLand,
    client
  } = props;
  const isMobile = useMediaQuery("(max-width: 640px)")
  const { id, city, savedFilterXmlId } = useParams();
  const isPublic = !!savedFilterXmlId
  const ref = useRef(null);
  const [isFetching, setIsFetching] = useState(true)
  const [activeDesc, setActiveDesc] = useState("house")

  useEffect(() => {
    let el = document.createElement('html');
    el.innerHTML = data ? data.village.name : ''; // с бэка приходит хтмл поэтому так)
    document.title = `${data ? data.name + ' в ' + el.innerText : ''}`;
  }, [data]);
  useEffect(() => {
    getLand().then(() => setIsFetching(false))
    if (!savedFilterXmlId) {
    fetchClients();
    }
    return () => {
      reset();
    };
  }, [id, city]);
  useEffect(() => {
    if (!data) return

    setActiveDesc(!!data?.descriptionHouse ? "house" : "land")
  }, [data])
  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFromFavorites(city, id, 'suburban', 'land')
      : addToFavorites(city, id, 'suburban', 'land');
  };

  const submitReserve = () => {
    closeReserveForm();
    closeReserveModal();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const submitView = () => {
    closeViewForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };
  const cityFromUrl = window.location.href.includes("msk") ? "msk" : "spb"
  const [openReserveForm, closeReserveForm] = useModal(
    ({ in: open, onExited }) => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Забронировать объект" closeClick={closeReserveForm}>{children}</MobileModal>
        : <Modal close={closeReserveForm} classes="modal_task">{children}</Modal>
      } >
        <ReserveApartment id={id} handleSend={() => submitReserve()} city={cityFromUrl}  method="bookingSuburban" />
      </Wrap >
    ), [id, cityFromUrl]
  );
  const [openViewForm, closeViewForm] = useModal(({ in: open, onExited }) => (
    <Wrap wrapper={children => isMobile
      ? <MobileModal closeClick={closeViewForm} title="Запись на просмотр">{children}</MobileModal>
      : <Modal close={closeViewForm} classes="modal_task">{children}</Modal>
    } >
      <ViewApartment id={id} handleSend={() => submitView()} city={city} />
    </Wrap >
  ));
  const [openReserveModal, closeReserveModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeReserveModal} classes="modal_task flat-reservation">
        <ReserveAssignation
          id={id}
          handleSend={() => submitReserve()}
          client={client}
          isPublic={isPublic}
          savedFilterXmlId={savedFilterXmlId}
          title={"объект"}
          method="bookingSuburban"
        />
      </Modal>
    ), [client, id, savedFilterXmlId, isPublic]
  );
  const handleScroll = () => {

    if (!ref.current) return;

    const elScrollHeight = ref.current.offsetTop - window.innerHeight + 110

    if (window.scrollY > elScrollHeight) {
      ref.current.classList.add('flat__info-btns-sticky');
    } else if (window.scrollY < elScrollHeight) {
      ref.current.classList.remove('flat__info-btns-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  if (error) return 'Error';
  return (
    <>
      {data && (
        <section className="section section_view_white flat for-suburbans">
          <div className="wrapper">
            <div className="flat__header">
              <div className="flat__grid">
                <div className="flat__col flat__col-title">
                  {data.roomType &&
                    <div className="flat__title">
                      <h1 className="h1">{data.roomType}</h1>
                    </div>
                  }
                  {data &&
                    <BlockLink
                      {...data.village}
                      square={data.squareTotal}
                      squareParcel={data.squareParcel}
                      type={data.name}
                      city={city}
                      coordinates={data.coordinates}
                      address={data.address}
                    />
                  }


                  {
                    isMobile &&
                    <div className="flat__info-item for-mobile">
                      <div
                        className={`label label-item label_blue label_id`}
                      >
                        ID: {data.id}
                      </div>
                      <div
                        className={`label-item label_${data?.status?.color
                          }`}
                      >
                        {data?.status?.name}
                      </div>
                    </div>
                  }
                </div>
               {!isPublic && <div className="flat__col flat__col-download for-suburbans">
                  <PresentationBlock
                    objectXmlId={data.xmlId}
                    objectType="suburban"
                    city={city}
                  />
                  {!isMobile &&
                    <AddPresentation
                      id={data.xmlId}
                      city={city}
                      presentation={data.presentation}
                      type="suburban"
                    />
                  }
                </div>}
              </div>

              <div className="flat__suburban-address">{data.address}</div>

              {isMobile && !isPublic &&
                <div className="flat__mobile-presentation">
                  <AddPresentation
                    id={data.xmlId}
                    city={city}
                    presentation={data.presentation}
                    type="suburban"
                  />
                </div>
              }
            </div>
            <div className="flat__content">
              <div className="flat__grid">
                <div className="flat__col flat__col-images for-suburban">
                  <PhotosBlock {...data} />

                  {manager && (
                    <div className="flat__manager sm-hide">
                      <Manager data={manager} key="desktopManager" isPublic={isPublic} objectId={id}/>
                    </div>
                  )}

                </div>
                <div className="flat__col flat__col-info for-suburban">
                  <div className="flat__price">
                  {!isMobile &&
                    <div className="flat__price-col flat__price-item flat__price-item_flex flat__price-item_padding_top">
                      <div className="flat__info-item">
                        <div
                          className={`label label-item label_blue label_id`}
                        >
                          ID: {data.id}
                        </div>
                      </div>
                      <div className="flat__info-item">
                        <div
                          className={`label-item label_${data?.status?.color}`}
                        >
                          {data?.status?.name}
                        </div>
                      </div>
                    </div>
                  }

                    { !isPublic && <div className="flat__price-col flat__price-action">
                    <div
                    className={`add-fav-btn  ${data.favorite.inFavorite ? 'is-fav' : ''
                  }`}
                  id="addFavDiv"
                  onClick={(e) => {
                    if (e.target.id === 'addFavDiv') {
                      handleFavorites(data.favorite.inFavorite);
                    }
                  }}
                  >
                  <button
                  className="button button_type_settings"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavorites(data.favorite.inFavorite);
                  }}
                  >
                  <svg className="icon icon_settings">
                  <use xlinkHref="#fav"></use>
                  </svg>
                  </button>
                  {!isMobile && (data.favorite.inFavorite
                    ? 'В избранном'
                    : 'В избранное')}
                    <PopoverBlock
                    type="land"
                    id={data.id}
                    favorite={data.favorite}
                    city={'spb'}
                    allClients={clients}
                    addClientToFavorite={addClientToFavorite}
                    removeClientFromFavorite={removeClientFromFavorite}
                    fetchClients={fetchClients}
                    whatPage={'land'}
                    showOnLeft={true}
                    onClick={(e) => e.stopPropagation()}
                    />
                    </div>
                    </div>}

                  </div>
                  <div className="flat__price">
                    <div className="flat__price-col flat__price-item" style={{width: "50%"}}>
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(data.price)}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена
                        </div>
                      </div>
                    </div>
                    {!!data.squareTotal && !!data.price && <div className="flat__price-col flat__price-item" style={{width: "50%"}}>
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(parseInt(getPricePerMeter(data.price, data.squareTotal)))}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена за кв. метр
                        </div>
                      </div>
                    </div>}
                    {!data.squareTotal && !!data.price && !!data.squareParcel && <div className="flat__price-col flat__price-item" style={{width: "50%"}}>
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(parseInt(getPricePerMeter(data.price, data.squareParcel)))}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена за сотку
                        </div>
                      </div>
                    </div>}
                    {!!data.ending && <div className="flat__price-col flat__price-item">
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {data.ending}
                        </div>
                        <div className="flat__info-item_desc">
                            Срок сдачи
                        </div>
                      </div>
                    </div>}

                    <div className="flat__price-col flat__price-actions">
                      {false && (
                        <div className="flat__price-action">
                          <div
                            className="add-compare-btn js-compares-add"
                            data-compare-id="7005"
                          >
                            <button className="button button_type_settings">
                              <svg className="icon icon_settings">
                                <use xlinkHref="#icon-compare"></use>
                              </svg>
                            </button>
                            К сравнению
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {false && !!data.ending && <div className="flat__period-grid">
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.ending}
                        </div>
                        <div className="flat__info-item_desc">Срок сдачи</div>
                      </div>
                    </div>
                  </div>}
                  {data?.status?.id != "1001" && <div className="flat__info-btns">
                    <div ref={ref}>
                      <div
                        className="js-modal-btn"
                        data-flat-id="flat-1"
                        data-id="modal-reservation"
                      >
                        {isPublic ? <button
                          className="button button_view_default"
                          onClick={openReserveModal}
                        >
                            Хочу забронировать
                        </button> :
                          <Link
                            className="button button_view_default"
                            to={`/flat-booking/suburban/${id}/${city}`}
                          >
                            Хочу забронировать
                        </Link>}
                      </div>
                    </div>
                    {data.isSignUpToView && (
                      <div className={`flat__white-btn ${!data.isCustomerFixation && `flat__white-btn-is-one`}`} data-flat-id="flat-1" data-id="modal-viewing">
                        <button
                          className="button button_view_outline-with-icon"
                          onClick={openViewForm}
                        >
                          <svg className="icon icon_marker">
                            <use xlinkHref="#icon_watch"></use>
                          </svg>
                          <span>На просмотр</span>
                        </button>
                      </div>
                    )}
                    {data.isCustomerFixation && !isPublic && (
                      <div className="flat__white-btn" data-flat-id="flat-1" data-id="modal-fixing">
                        <button
                          className="button button_view_outline-with-icon"
                          onClick={() => window.open(`/client-fixation?city=${city}&builderId=${data?.builder?.id}&blockId=${data.block?.id}`)}
                        >
                          <svg className="icon icon_fix">
                            <use xlinkHref="#icon_fix"></use>
                          </svg>
                          <span>Фиксация</span>
                        </button>
                      </div>
                    )}
                  </div>}
                  <div className="flat__info-grid">
                    {
                      //<FlatColItem title={"id"} value={!!data.id && data.id} />
                    }
                    <FlatColItem title={"№ объекта"} value={!!data.number && data.number} />
                    <FlatColItem title={"Общая площадь"} value={!!data.squareTotal && data.squareTotal + " м²"} />
                    <FlatColItem title={"Площадь участка"} value={!!data.squareParcel && data.squareParcel + " соток"} />
                    <FlatColItem title={"Жилая площадь"} value={!!data.squareLiving && data.squareLiving + " м²"} />
                    <FlatColItem title={"Площадь кухни"} value={!!data.squareKitchen && data.squareKitchen + " м²"} />
                    <FlatColItem title={"Кол-во спален"} value={!!data.countBedrooms && data.countBedrooms} />
                    <FlatColItem title={"Кол-во этажей"} value={data.floors} />
                    <FlatColItem title={"Высота потолков"} value={!!data.cellHeight && data.cellHeight + " м"} />
                    <FlatColItem title={"Очередь"} value={data.line} />
                    <FlatColItem title={"Корпус"} value={data.corpus} />
                    <FlatColItem title={"Санузел"} value={!!data.bathroomTypes?.length && data.bathroomTypes} />
                    <FlatColItem title={"Отделка"} value={!!data.decoration && data.decoration} />
                    <FlatColItem title={"Форма договора"} value={!!data.contract && data.contract} />
                    <FlatColItem title={"Отделка дома"} value={!!data.decorationHouse && data.decorationHouse} />
                    <FlatColItem title={"Материалы дома"} value={!!data.materials?.length && data.materials.map((item, i, arr) => item + (arr.length !== i + 1 ? ", " : ""))} />
                    <FlatColItem title={"Особенности"} forFeatures value={!!data?.features?.length && data.features} />
                    <FlatColItem title={"Электричество кВт"} value={!!data.power && data.power} />
                  </div>
                  <div className="flat__info-grid" style={{width: "100%"}}>
                    <FlatColItem title={"Коммуникации"}
                      wrapperStyles={{width: "100%"}}
                      value={data.communications?.length && data.communications?.map?.((item, i, arr) => item + (arr.length !== i + 1 ? ", " : ""))}
                    />
                  </div>
                </div>
              </div>
            </div>
            {manager && (
              <div className="flat__manager sm-show">
                <Manager data={manager} key="mobileManager" isPublic={isPublic} />
              </div>
            )}
          </div>
        </section>
      )}

      {(!!data?.descriptionHouse || !!data?.descriptionParcel) && <div className="section section_view_white">
        <div className="wrapper">
          <div className="complex__toggle-wrap">
            <div className="complex__toggle">
              {!!data?.descriptionHouse && <div className={`complex__toggle-tab ${activeDesc === "house"? "is-active":""}`} onClick={()=>setActiveDesc("house")}>
                Описание дома
              </div>}

              {!!data?.descriptionParcel && <div className={`complex__toggle-tab ${activeDesc === "land"? "is-active":""}`} onClick={()=>setActiveDesc("land")}>
                Описание участка
              </div>}
            </div>
            <div className="complex__toggle-content">
              <p>{activeDesc === "house"? data?.descriptionHouse : data?.descriptionParcel}</p>
            </div>
          </div>
        </div>
      </div>}

      {data && (
        <MortgageBlock
          price={Number(data.price)}
          city={city}
          type="suburban"
          id={id}
          period={15}
          prepay={Number(data.price) * 0.2}
          percent={6.5}
          isPublic={isPublic}
          blockId={data?.village?.id}
        />
      )}
      {banks && banks.length > 0 && <Banks items={banks} />}
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const params = ownProps.match.params
  const form = new FormData()
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value)
  }
  return {
    fetch: (id, city) => dispatch(fetchApartment(id, city)),
    reset: () => dispatch(clearApartment()),
    addToFavorites: (city, id, type, whatPage) =>
      dispatch(addToFavorites(city, id, "suburban", "land",)),
    removeFromFavorites: (city, id, type, whatPage) =>
      dispatch(removeFromFavorites(city, id, "suburban", "land",)),
    fetchClients: () => dispatch(fetchClients()),
    addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
      dispatch(
        addClientToFavorite(id, clientId, client, favoriteId, "land", "suburban"),
      ),
    removeClientFromFavorite: (
      id,
      clientId,
      client,
      favoriteId,
      whatPage,
      type,
    ) =>
      dispatch(
        removeClientFromFavorite(
          id,
          clientId,
          client,
          favoriteId,
          "land",
          "suburban"
        ),
      ),
    getLand: () => getLand(form, dispatch)
  };
}
const mapStateToProps = (state) => ({
  // data: state.apartment.data,
  isFetching: state.suburbanLand.isFetching,
  error: state.suburbanLand.error,
  manager: state.suburbanLand.agent || state.suburbanLand.suburban?.manager,
  banks: state.suburbanLand.banks,
  clients: state.clients.data,
  data: state.suburbanLand.suburban,
  client: state?.suburbanLand?.client,

});

export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
