import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import request from "../../../api";
import useApi from "../../../hooks/useApi";
import useClickPast from "../../../hooks/useClickPast";
import { getUnreadCount } from "../../App";
import { MenuCloseIcon } from "../../Public/markup/icons";
import Tabs from "../Tabs";
import { NotificationsListSkeleton, NotifyAllReaded } from "./NotificationsListComponents";
import './index.scss';

const Nottifications = memo(({ isOpen, setIsOpen }) => {

  const dispatch = useDispatch();

  const unreadCount = useSelector(state => state.general.unreadCount);

  const nottificationsRef = useClickPast(null, ({ target }, { current }) => {
    if (current?.contains?.(target) || target?.closest?.('.nottifications')) return
    setIsOpen(false)
  })

  const {
    data: { groupItems }, // табы и данные я бы разделил
    isLoading,
    isRefreshing,
    refresher,
    setIsRefreshing,
    dataSetter
  } = useApi({
    payload: ["panpartner:push.ajax", "getItems"]
  })

  const setUnreadCount = count => dispatch({ type: "SET_UNREAD_COUNT", count });

  useEffect(() => {
    if (!isOpen) return window.BX?.SiteButton.show();
    window.BX?.SiteButton.hide();
    getUnreadCount();
  }, [isOpen])

  const removeUnreadedNotification = id => dataSetter("groupItems", prev => {
    const tmp = [...prev];
    const unreadedGroupIndex = tmp.findIndex(({ count }) => !!count); // так себе определение вкладки "Непрочитанные", упираемся в табы
    tmp.forEach(({ items}, i) => {
      const index = items.findIndex(item => item.id === id);
      if (index === -1) return
      items[index].isRead = true;
      if(tmp[i].count) tmp[i].count -= 1
    })
    const deleteCondition = tmp[unreadedGroupIndex] && tmp[unreadedGroupIndex].items.every(({ isRead }) => isRead);
    if (deleteCondition) tmp.splice(unreadedGroupIndex, 1);
    return tmp;
  });

  const readNottification = id => request("panpartner:push.ajax", "read", { id })
    .then(() => {
      removeUnreadedNotification(id)
      setUnreadCount(unreadCount - 1)
    })

  const readAllNottification = () => {
    setIsRefreshing(true);
    request("panpartner:push.ajax", "readAll").then(() => {
      refresher()
      setUnreadCount(0)
    })
  };

  const loading = isLoading || isRefreshing;
  const isEmpty = !groupItems?.length;

  return (
    <div className={"nottifications" + (isOpen ? ' is-showing' : '')} ref={nottificationsRef}>
      <div className="nottifications__header">
        <p className="nottifications__header-h">Уведомления</p>
        <div className="nottifications__close-nottifications">
          {unreadCount !== 0 && <span className="link" onClick={readAllNottification}>Прочитать все</span>}
          <span onClick={() => setIsOpen(false)}><MenuCloseIcon /></span>
        </div>
      </div>

      {!loading && !isEmpty && <Tabs key={groupItems?.length} withScroll>
        {groupItems.map(({ title, count, items }) => <section
          label={title}
          key={title + count}
          count={count ? count : false}
          style={{ height: '100%' }}
        >
          <SimpleBar className="nottifications-aside presentation__aside">
            <ul className="nottifications-list">
              {items.map(notify => {
                const { id, isRead, data, title, fromSite, dateAdd, message, target = "_blank" } = notify;
                return <li
                  className={`nottifications-list__item  ${!isRead ? ' nottifications-list__item_new' : ''}`}
                  key={id}
                  onClick={() => {
                    if (!isRead) readNottification(id);
                    window.open(data?.url, fromSite ? "_self" : target)
                  }}
                >
                  <div className="nottifications-list__item-header">
                    <div className="nottifications-list__item-title">
                      {title}
                    </div>
                    <div className="nottifications-list__item-time">{dateAdd}</div>
                  </div>
                  <div className="nottifications-list__item-text">
                    {message}
                  </div>
                </li>
              })}
            </ul>
          </SimpleBar>
        </section>
        )}
      </Tabs>}
      {!loading && isEmpty && <NotifyAllReaded />}
      {loading && <NotificationsListSkeleton />}
    </div>
  )
})

export default Nottifications;
