import React, { useState, useRef, useMemo } from 'react';
import ErrorPlug from './ErrorBoundry/ErrorPlug'
import TinySlider from 'tiny-slider-react';
import formatter from 'format-number';
import { getUrl } from '../api';
import { media } from '../utils';
import { commercialTopAction } from '../actions/user';

const priceFormat = (price) => {
  const suffix = price?.toString()?.includes("По договор")? "" : ' р.'
  return formatter({ integerSeparator: ' ', suffix: suffix })(price).toString().toLowerCase();
};

const TopItem = (props) => {
  const {
    id,
    roomType,
    squareTotal,
    baseCost,
    region,
    builderName,
    endingDate,
    commission,
    commissionPercent,
    isAgent,
    clientMode,
    plan,
    isCommercial,
    isSecondaries,
    builder,
    clickSideEffect = ()=>{},
    costWithDiscount,
    block,
    isOne
  } = props;
  const getLink = () => {
    if (isSecondaries) {
      return `/secondary/${id}`;
    }
    if (isCommercial) {
      return `/commercial/${id}?fromTop`;
    }
    return `/assignments/${id}?fromTop`;
  };
  const getTitle = () => {
    if (isSecondaries) {
      return (
        <>
          {roomType && <span> {roomType.name + ' '}|</span>}
          <span>{' ' + squareTotal} м²</span> |{' '}
          <span>{priceFormat(baseCost)}</span>
        </>
      );
    }
    return (
      <>
        {roomType && <span> {roomType + ' '}|</span>}
        <span>{' ' + squareTotal} м²</span> |{' '}
        <span>{priceFormat(costWithDiscount)}</span>
      </>
    );
  };


  const clickHandler = () => {
      clickSideEffect();

      if (isCommercial) {
        commercialTopAction(id, "viewCommercialTop");
      }

      window.open(getLink());
  }

  return (
    <div className={`slider__item ${isOne ? 'slider__item-one' : ''}`} onClick={clickHandler}>
      <div className="flats__card flats__card_compact" >
        {!isAgent && !clientMode && media('isMobile') && (
          <div
            class="card__pill"
            style={{
              width: 'fit-content',
              marginBottom: '20px',
            }}
          >
            Комиссия{' '}
            {isCommercial
              ? commission
              : priceFormat(
                  commissionPercent ? commissionPercent : commission,
                )}
          </div>
        )}
        <div className="flats__card-image">
          <img
            src={`${
              plan
                ? getUrl(plan.src)
                : 'https://dev2.panpartner.ru/upload/emptyPlan.svg'
            }`}
            alt={``}
          />
          <div className="flats__card-top">
            <div className="flats__card-status"></div>
          </div>
        </div>
        <div className="flats__card-info">
          {!isAgent && !clientMode && !media('isMobile') && (
            <div
              class="card__pill"
              style={{
                width: 'fit-content',
                marginBottom: '20px',
              }}
            >
              Комиссия{' '}
              {isCommercial
                ? commission
                : priceFormat(
                    commissionPercent ? commissionPercent : commission,
                  )}
            </div>
          )}
          <div className="flats__card-price top__title">{getTitle()}</div>
          <div className="card__row card__row_distribute_between">
            <div className="card__place">
              <div className="card__title">{block?.name}</div>
              <div className="card__address">{region?.name}</div>
            </div>
          </div>
          {builderName && (
            <div className="card__company">Застройщик: {builderName}</div>
          )}
          {builder && (
            <div className="card__company">Застройщик: {builder.name}</div>
          )}
          <div className="card__dates">{endingDate}</div>
        </div>
      </div>
    </div>
  );
};

const Top = (props) => {
  const { items, title, isCommercial, isSecondaries, clickSideEffect, wrapperStyle } = props;
  const node = useRef(null);
  const navRef = useRef(null);

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          nav: !media("isMobile") ? false : true,
          // loop: media("isMobile") ? false : true,
          loop: false,
          controls: false,
          navAsThumbnails: media("isMobile") && false,
          navContainer: media("isMobile") ? `#complex-slider-thumbnails` : false,
          responsive: {
            768: {
              items: 2,
              gutter: 10,
            },
            1600: {
              items: 3,
              gutter: 10,
            },
          },
        }
        : {},
    [navRef]
  );

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const [index, setIndex] = useState(1);

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  return (
    <ErrorPlug wrappedComponent="src/comp-ts/Top.js">
    <section className="section section_view_white" >
      <div className="wrapper">
        <div className="assignations-top" style={wrapperStyle}>
          <div className="assignations-top__title">
            <h2 className="h2">{title}</h2>
          </div>
          <div className="assignations-top__slider slider">
            {!media('isMobile') &&
              <div className="slider__nav">
                <ul className="slider__nav-wrap" id="assignations-nav">
                  <li
                    className="slider__nav-item prev"
                    onClick={() => moveSlider('prev')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    className="slider__nav-item next"
                    onClick={() => moveSlider('next')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            }
            <div className="slider__wrap" id="assignations-slider">
              <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                {items &&
                  items.map((item) => (
                    <TopItem
                      {...item}
                      isCommercial={isCommercial}
                      isSecondaries={isSecondaries}
                      isAgent={props.isAgent}
                      key={item.id}
                      clientMode={props.clientMode}
                      clickSideEffect={clickSideEffect}
                      isOne={items.length === 1}
                    />
                  ))}
              </TinySlider>

              {media("isMobile") &&
                <>
                  <ul
                    class="complex__slider-thumbnails"
                    id="complex-slider-thumbnails"
                    ref={navRef}
                  >
                    {items.map((item, index) => (
                      <li class="complex__slider-thumbnails_item" key={index}>
                        <span aria-controls="base"></span>
                      </li>
                    ))}
                  </ul>

                  <span class="complex__slider-info">
                    {index} / {items.length}
                  </span>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
    </ErrorPlug>
  );
};

export default Top;
