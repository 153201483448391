export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 7L12 12L17 7" strokeLinecap="round" />
    <path d="M7 17L12 12L17 17" strokeLinecap="round" />
  </svg>
);
