export const downloadFile = (resp) => {
    let headerLine = resp.headers['content-disposition'];//paste
    let startFileNameIndex = headerLine.indexOf('"') + 1
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    const href = URL.createObjectURL(resp.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
