import uniqBy from "lodash/uniqBy";
import moment from "moment";
import React, { useMemo, useState } from "react";
import useMedia from "../../hooks/useMedia";
import { removeBodyScroll, returnBodyScroll } from "../../utils";
import DatepickerDropdown from "../core/DatepickerDropdown";
import Badge from "../core/FilterBadge";
import SelectFilter from "../FilterRealty/SelectFilter";
import './index.scss';
require("moment/locale/ru");
moment.locale("ru");

const Filter = ({ filter, filterData, setFilter, clearFilter, showAgents }) => {

  const isMobile = useMedia("isMobile")
   //всё что дальше - костыли чтобы не переделывать бэк
  const clientsFilterData = useMemo(() => {
    if (!filterData?.title) return []
    return uniqBy(filterData.title, "title")
  }, [filterData])

  const findAllDeals = id => {
    if (!filterData?.title) return
    const findetitem = filterData.title.find(item => item.id == id)
    const title = findetitem?.title // ищем имя ублюдка по его id
    const allDeals = filterData.title.filter(item => item.title === title) //находим другие айтемы с таким же именем
    const additionalDeals = allDeals.map(item => item.id.toString()) //берём только id
    return additionalDeals
  }

  const handleTitleFilter = (checked, id) => {
    const additionalDeals = findAllDeals(id)
    if (checked) return handleFilter([...filter.title, ...additionalDeals], "title")
    return handleFilter(filter.title.filter(id => {
      if (additionalDeals.includes(id)) return false
      return true
    }), "title")
  }
  const titleBadges = useMemo(() => {
    const tmpFilterTitle = uniqBy(filterData.title.filter(item => filter.title.includes(item.id.toString())), "title").map(v => v.id)
    const remove = id => {
      const additionalDeals = findAllDeals(id)
      const newArray = filter.title.filter(id => !additionalDeals.includes(id))
      setFilter({ filter: { ...filter, title: newArray } })
    }

    return tmpFilterTitle.map((id) => {
      let b = filterData.title.find((item) => id == item.id);
      return (
        <Badge
          key={`title${id}`}
          identity={`title`}
          item={b}
          title={`Клиент`}
          handleClick={remove}
        />
      );
    })
  }, [filter])

  const showBadges = useMemo(() => (
    filter &&
    (filter.agent?.length > 0 ||
      filter.title?.length > 0 ||
      filter.manager?.length > 0 ||
      filter.status?.length > 0 ||
      filter.dates?.startDate ||
      filter.dates?.endDate)
  ), [filter])

  const badgesCount = useMemo(() => (
    filter &&
    (filter.agent?.length +
      filter.manager?.length +
      filter.title?.length +
      filter.status?.length +
      (filter.dates?.startDate || filter.dates?.endDate) && 1)
  ), [filter])

  const handleFilter = (value, identity) => setFilter({ filter: { ...filter, [identity]: value } });
  const handleRemoveFilter = (value, identity) => {
    switch (identity) {
      case "dates":
        setFilter({
          filter: {
            ...filter,
            [identity]: { startDate: null, endDate: null },
          }
        });
        break;
      default:
        let newArray = filter[identity];
        newArray = newArray.filter((item) => item != value);
        setFilter({ filter: { ...filter, [identity]: newArray } })
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? removeBodyScroll()
      : returnBodyScroll()
  }

  const filters = (
    <>
      {filterData.title && <div className="mini-filter__col">
        <SelectFilter
          onChange={handleTitleFilter}
          isExcludable={false}
          isAutocomplete={true}
          items={clientsFilterData}
          filterValues={filter.title}
          excludeValues={[]}
          data={{
            title: "Клиенты",
            autocompleteTitle: "Введите имя клиента",
            identity: "title",
          }}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
          wrapperClass={"requests_select-filter"}
          withoutHandling={true}
          selectedCount={titleBadges.length}
        />
      </div>}
      <div className="mini-filter__col">
        <DatepickerDropdown
          onChange={(value) => handleFilter(value, "dates")}
          value={filter.dates}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
          moveRangeOnFirstSelection={true}
        />
      </div>

      {showAgents && filterData.agent && <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "agent")}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.agent}
          filterValues={filter.agent}
          excludeValues={[]}
          data={{
            title: "Сотрудник",
            autocompleteTitle: "Введите имя сотрудника",
            identity: "agent",
          }}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
          wrapperClass={"requests_select-filter"}
        />
      </div>}
      {filterData.manager && <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "manager")}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.manager}
          filterValues={filter.manager}
          excludeValues={[]}
          data={{
            title: "Менеджер ПАН",
            autocompleteTitle: "Введите имя менеджера",
            identity: "manager",
          }}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
          wrapperClass={"requests_select-filter"}
        />
      </div>}
      {filterData.status && <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "status")}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.status?.map?.(item=>({...item, id: item?.id?.toString?.()}))}
          filterValues={filter.status}
          excludeValues={[]}
          data={{
            title: "Статус",
            autocompleteTitle: "Введите статус",
            identity: "status",
          }}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
          wrapperClass={"requests_select-filter"}
        />
      </div>}

    </>
  )

  return (
    <div className="mini-filter">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {isMobile ?
          <>
            {/* <div className="clients__filter-col clients__filter-col_search">
              <label
                className="input input_type_search input_width_available"
                htmlFor="clients-search"
              >
                <input
                  className="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск по клиенту, агенту ..."
                  value={filter.search}
                  onChange={(e) => handleFilter(e.target.value, "search")}
                />
                <svg className="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div> */}
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Фильтры
              {badgesCount > 0 && ` (${badgesCount})`}
            </button>
          </> :

          <div className="mini-filter__grid">
            {/* <div className="clients__filter-col clients__filter-col_search">
              <label
                className="input input_type_search input_width_available"
                htmlFor="clients-search"
              >
                <input
                  className="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск по клиенту, агенту ..."
                  value={filter.search}
                  onChange={(e) => handleFilter(e.target.value, "search")}
                />
                <svg className="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div> */}

            {filters}

            <div className="clients__filter-addit">
              <div className="clients__filter-addit-item">
                <button
                  className="button button_type_clear mini-filter__button"
                  onClick={clearFilter}
                  disabled={!showBadges}
                  type="button"
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              </div>
            </div>
          </div>
        }
      </form>

      {isOpen && isMobile &&
        <div className="mobile-filters for-clients">
          <div className="mobile-filters__title">
            <svg
              className="icon icon_arrow_up"
              onClick={() => {
                // props.onClick()
                toggleFilters()
              }}
            >
              <use xlinkHref="#arrow_up"></use>
            </svg>
            <h3>Все фильтры</h3>
          </div>
          <div className="mobile-filters__content">
            {filters}

            {showBadges && (
              <div className="index-search__filter-applied">
                <div className="index-search__filter-inc">
                  {filter.agent.map((id) => {
                    let b = filterData.agent.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`agent${id}`}
                        identity={`agent`}
                        item={b}
                        title={`Сотрудник`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                  {filter.manager.map((id) => {
                    let b = filterData.manager.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`manager${id}`}
                        identity={`manager`}
                        item={b}
                        title={`Менеджер`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                  {titleBadges}
                  {filter.status.map((id) => {
                    let b = filterData.status.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`status${id}`}
                        identity={`status`}
                        item={b}
                        title={`Статус`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                  {filter.dates && (filter.dates.startDate || filter.dates.endDate) && (
                    <Badge
                      key={`dates`}
                      identity={`dates`}
                      item={{
                        id: 0,
                        title: `${filter.dates.startDate
                          ? moment(filter.dates.startDate).format("ll")
                          : ``
                          } - ${filter.dates.endDate
                            ? moment(filter.dates.endDate).format("ll")
                            : ``
                          }`,
                      }}
                      title={`Дата`}
                      handleClick={handleRemoveFilter}
                    />
                  )}
                </div>
              </div>
            )}


            <div className="mobile-filters__show-all for-agents">
              <button
                className="button button_view_default"
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  toggleFilters()
                }}
              >
                Показать
              </button>
              <button
                className="button button__mobile-filters"
                type="button"
                onClick={() => {
                  clearFilter()
                  toggleFilters()
                }}
              >
                Очистить
              </button>
            </div>
          </div>
        </div>
      }


      {showBadges && !isMobile && (
        <div className="index-search__filter-applied">
          <div className="index-search__filter-inc">
            {filter.agent.map((id) => {
              let b = filterData.agent.find((item) => id == item.id);
              return (
                <Badge
                  key={`agent${id}`}
                  identity={`agent`}
                  item={b}
                  title={`Сотрудник`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.manager.map((id) => {
              let b = filterData.manager.find((item) => id == item.id);
              return (
                <Badge
                  key={`manager${id}`}
                  identity={`manager`}
                  item={b}
                  title={`Менеджер`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {titleBadges}
            {filter.status.map((id) => {
              let b = filterData.status.find((item) => id == item.id);
              return (
                <Badge
                  key={`status${id}`}
                  identity={`status`}
                  item={b}
                  title={`Статус`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.dates && (filter.dates.startDate || filter.dates.endDate) && (
              <Badge
                key={`dates`}
                identity={`dates`}
                item={{
                  id: 0,
                  title: `${filter.dates.startDate
                    ? moment(filter.dates.startDate).format("ll")
                    : ``
                    } - ${filter.dates.endDate
                      ? moment(filter.dates.endDate).format("ll")
                      : ``
                    }`,
                }}
                title={`Дата`}
                handleClick={handleRemoveFilter}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;