import React from 'react';
import { BackTop } from 'antd';
import 'antd/es/back-top/style/index.css';

const BackToTop = (props) => {
	return (
		<BackTop className="back-top">
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					opacity="0.1"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30 15C30 6.716 23.284 0 15 0C6.716 0 0 6.716 0 15C0 23.284 6.716 30 15 30C23.284 30 30 23.284 30 15Z"
					fill="#8F95AC"
					className="round-top"
				/>
				<path
					d="M18.1091 16L15.0545 13L12 16"
					stroke="#8F95AC"
					strokeLinecap="round"
				/>
			</svg>
		</BackTop>
	);
};

export default BackToTop;
