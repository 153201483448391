import { memo } from "react";
import { useDispatch } from "react-redux";
import { addToFavorites, removeFromFavorites } from "../../../../actions/favorites";
import { getUrl } from "../../../../api";
import { getRoundedPrice } from "../../../../utils";
import Card from "../../../core/Card";


const DubaiFavoritesCard = memo(props => {

    const { currency = "RUB", link, photo, address, builder, apartmentsInfo, title, endings, id, city, favorite={}, xmlId} = props
    const dispatch = useDispatch()
    const handleFavorite = e => {
        e.preventDefault()
        favorite?.inFavorite? 
        dispatch(removeFromFavorites("dxb", id, "blockEntity", "favorites")) :
        dispatch(addToFavorites("dxb", id, "blockEntity", "favorites"))
    }

    const fixedLink = link ?? `/realty/${city?.xmlId}/complex/${xmlId}`;

    return <Card
        {...props}
        src={getUrl(photo?.src)}
        title={title}
        descTitle={builder?.title}
        deadline={endings}
        subtiltle={address}
        customTable
        tableItems={apartmentsInfo?.apartmentsByRoomType.map?.(item => ({
            id: item.roomTypeId,
            title: item.roomTypeName,
            middleTitle: item.minSquare ? `от ${item.minSquare} м²` : "",
            subtitle: `от ${getRoundedPrice(item.minPrice[currency])} млн. ${currency}`
        }))}
        // link={`${link}?currency=${currency}`}
        link={fixedLink}
        {...favorite}
        handleFavorite={handleFavorite}
    />
});

export default DubaiFavoritesCard;
