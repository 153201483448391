import React from "react";
import Paginator from "paginator";
import { media } from "../utils";

const PreviuosPage = props => {
  const { active, page, handleClick } = props;

  return (
    <button className="button pagination__control pagination__prev" onClick={() => handleClick(!active && page)}>
      <svg className="icon icon_prev">
        <use xlinkHref="#pag-prev"></use>
      </svg>
    </button>
  );
}

const NextPage = props => {
  const { active, page, handleClick } = props;

  return (
    <button className="button pagination__control pagination__next" onClick={ () => handleClick(!active && page) }>
      <svg className="icon icon_next">
        <use xlinkHref="#pag-next"></use>
      </svg>
    </button>
  );
}

const DottedPage = props => {
  const { page, handleClick } = props;

  return (
    <li>
      <span className="pagination__item" >...</span>
    </li>
  );
}

const NumberPage = props => {
  const { active, page, handleClick } = props;
  const className = "pagination__item" + (active ? ' is-active': '');

  return (
    <li>
      <button type="button" className={className} onClick={ () => {handleClick(!active && page)} }>{page}</button>
    </li>
  );
}

const Pagination = ({ currentPage, itemsPerPage, itemsCount, onChange }) => {

  if (itemsPerPage >= itemsCount) return '';

  const paginator = new Paginator(itemsPerPage, media("isMobile") ? 2 : 5);
  const paginationInfo = paginator.build(itemsCount, currentPage);

  const handlePage = page => {
    return page && onChange(page)
  }

  const drawPages = () => {
    let pages = [];
    const { first_page, last_page, current_page } = paginationInfo;
    for (let i = first_page; i <= last_page; i++) {
      pages.push(<NumberPage key={i} page={i} active={i === current_page} handleClick={handlePage} />)
    }

    return pages;
  }

  return (
    <>
      <PreviuosPage active={paginationInfo.has_previous_page} page={paginationInfo.previous_page} handleClick={(page) => handlePage(page)} />
      <ul className="pagination__list">
        {paginationInfo.first_page > 1 && <NumberPage page={1} active={currentPage === 1} handleClick={(page) => handlePage(page)} />}
        {paginationInfo.first_page > 2 && <DottedPage />}
        {drawPages()}
        {paginationInfo.last_page < paginationInfo.total_pages - 1 && <DottedPage />}
        {paginationInfo.last_page < paginationInfo.total_pages && <NumberPage page={paginationInfo.total_pages} active={currentPage === paginationInfo.total_pages} handleClick={(page) => handlePage(page)} />}
      </ul>
      <NextPage key={'next'} active={paginationInfo.has_next_page} page={paginationInfo.next_page} handleClick={handlePage} />
    </>
  );
};

export default Pagination;
