import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useModal } from "react-modal-hook";
import { getUrl } from '../../../api';
import { icons } from '../../../utils/special';
import Modal from "../../core/Modal";

const isPhoneSocial = social => ["vb", "wa"].includes(social.type)
const SocialInput = props => {
    const { placeholder, social, changeHandler } = props
    const [validValue, setValidValue] = useState("")
    const patterns = {
        "tg": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_0-9a-zA-Z]{0,}`),
        "ig": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_.0-9a-zA-Z]{0,}`),
        "vk": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_.0-9a-zA-Z]{0,}`),
        "fb": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_.0-9a-zA-Z]{0,}`),
    }
    const telegramInputFocus = (e) => {
        if (e.target.value) {
            return
        }
        setValidValue(placeholder)
        changeHandler({ ...social, link: placeholder })
    }
    const telegramBlur = (e) => {
        if (e.target.value === placeholder) {
            setValidValue("")
            changeHandler({ ...social, link: "" })
        }
    }

    const setSocialValue = (e) => {
        let val = e.currentTarget.value
        if (val && !val.startsWith(placeholder)) {
            changeHandler({ ...social, link: `${placeholder}${val}` })
            val = `${placeholder}${val}`
        }
        let match = val.match(patterns[social.type])
        if (match && match[0] === val) {
            setValidValue(val)
            changeHandler({ ...social, link: val })
        } else {
            changeHandler({ ...social, link: validValue })
        }
    }
    return <input
        type="text"
        className="admin__input"
        value={social.link}
        // onChange={(e) => changeHandler({ ...social, link: e.target.value })}
        placeholder={placeholder}
        onFocus={telegramInputFocus}
        onChange={e => setSocialValue(e)}
        onBlur={e => telegramBlur(e)}
    />

}

const Socials = (props) => {
    const { socials, changeHandler } = props;
    const [openSocials, closeSocials] = useModal(({ in: open, onExited }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal" close={closeSocials} closeByPastClick={true}>
            <button type="button"
                className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                aria-label="Закрыть окно"
                onClick={closeSocials}
            >
                <span className="c-modal__icon">
                    <svg className="icon icon_cross">
                        <use xlinkHref="#cross" />
                    </svg>
                </span>
            </button>

            <div className="c-modal__box">
                <p className="c-modal__header">Добавить социальные сети</p>
                <div className="c-modal__content">
                    <ul className="c-modal__socials">
                        {socials.map((social, i) => {
                            return (<li key={"modal-social-" + i}>
                                <div className="checkbox">
                                    <div className="checkbox__box">
                                        <input className="checkbox__control" type="checkbox" id={"modal-social-" + i} checked={social.active} onChange={(e) => changeHandler({ ...social, active: e.target.checked, activeInPromo:  e.target.checked})} />
                                        <label className="checkbox__input" htmlFor={"modal-social-" + i} />
                                        <div className="checkbox__marker"></div>
                                    </div>
                                    <label className="checkbox__label" htmlFor={"modal-social-" + i}>
                                        <span>{social?.name}</span>
                                        <div className={`c-modal__social-label c-modal__social-label_${social.type}`} >
                                            <object type="image/svg+xml" style={{
                                                width: "100%",
                                                height: "100%",
                                            }} data={getUrl(social?.logo?.src)} className="icon" />
                                        </div>
                                    </label>
                                </div>
                            </li>)
                        })}
                    </ul>
                </div>
            </div>
        </Modal>
    ), [props]); //глубины сравнения не хватило для передачи сюда socials

    return (<div className="admin__socials">
        <p className="h6 admin__h6">Ваши социальные сети</p>
        <ul className="admin__socials-list">{socials.map((social, i) => {
            if (social.active) {
                return <li key={"social-" + i}>
                    <div className="admin__input-block admin__input-block_socials">
                        <div className={`admin__socials-icon admin__socials-icon_${social?.type}`} htmlFor="socials-viber">
                            {icons[social?.type]?.["logo"]}
                        </div>
                        {isPhoneSocial(social) ?
                            <InputMask
                                className="admin__input"
                                mask="+7 (999) 999-99-99"
                                autoComplete="off"
                                maskChar=" "
                                type="text"
                                value={social.link}
                                onChange={(e) => changeHandler({ ...social, link: e.target.value })}
                                placeholder={"+7 (   )    -  -  "}
                            />
                            : <SocialInput
                                changeHandler={changeHandler}
                                social={social}
                                placeholder={social.type != "tg" ?social.defaultLink + "/": "@"}
                            />}

                        <button type="button" className="button admin__icon-btn admin__icon-btn_with-hover" onClick={(e) => changeHandler({ ...social, active: false })}>
                            <span className="admin__icon admin__icon_square">
                                <svg className="icon icon_cross">
                                    <use xlinkHref="#cross" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </li>
            }
        })}
            <li>
                <div className="admin__socials-add">
                    <button
                        type="button"
                        className="button admin__icon-btn admin__icon-btn_gray"
                        aria-label="Добавить социальную сеть"
                        onClick={openSocials}
                    >
                        <span className="admin__icon admin__icon_sm">
                            <svg className="icon icon_plus">
                                <use xlinkHref="#plus" />
                            </svg>
                        </span>
                        <span>Добавить сеть</span>
                    </button>

                </div>
            </li>
        </ul>
    </div>)
};

export default Socials;
