import React from "react";

const EmptyLayout = ({ socials, children, layoutClasses = "" }) => {
  return (
    <div className="content">
      <main>
        <div className={`empty ${layoutClasses}`}>
          {children}
        </div>
      </main>
    </div>
  );
};

export default EmptyLayout
