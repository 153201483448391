import formatter from 'format-number';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import { getUrl } from '../../api';
import useMedia from '../../hooks/useMedia';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import SuburbansItem from '../Suburbans/TopItem';
const priceFormat = (price) => {
  const suffix = price?.toString()?.includes("По договор")? "" : ' р.'
  return formatter({ integerSeparator: ' ', suffix })(price).toString().toLowerCase();
};

const TopItem = (props) => {
  const {
    id,
    planUrl,
    roomType,
    squareTotal,
    baseCost,
    block,
    region,
    commission,
    commissionPercent,
    isAgent,
    clientMode,
    plan,
    isCommercial,
    builder,
    clickSideEffect = () => { },
    address,
    floor,
    floors
  } = props;

  const getTitle = () => {
    return (
      <>
        {roomType && <span> {roomType.name + ' '}|</span>}
        <span>{' ' + squareTotal} м²</span> |{' '}
        <span>{priceFormat(baseCost)}</span>
      </>
    );
  };
  const clickHandler = () => {
    clickSideEffect()
    window.open(`/secondary/${id}?fromTop`);
  }
  return (
    <div className="slider__item" onClick={clickHandler}>
      <div className="flats__card flats__card_compact" >
        <div className="flats__card-image">
          <img
            src={`${plan
              ? getUrl(plan.src)
              : 'https://dev2.panpartner.ru/upload/emptyPlan.svg'
              }`}
            alt={``}
          />
          <div className="flats__card-top">
            <div className="flats__card-status"></div>
          </div>
        </div>
        <div className="flats__card-info">
          {!isAgent && !clientMode && (!!commission || !!commissionPercent) && (
            <div
              class="card__pill"
              style={{
                width: 'fit-content',
                marginBottom: '20px',
              }}
            >
              Комиссия{' '}
              {isCommercial
                ? commission
                : priceFormat(
                  commissionPercent ? commissionPercent : commission,
                )}
            </div>
          )}
          <div className="flats__card-price top__title">{getTitle()}</div>
          <div class="card__company">{floor} этаж из {floors}</div>
          <div className="card__row card__row_distribute_between card__row_mt_10px">
            <div className="card__place">
              {<Link to={`/realty/spb/complex/${block.id}`} target="_blank" className="card__dates">{block.mame}</Link>}
              <div className="card__address card__address_mt_5px">{region?.name? region?.name + ", ":""}{address}</div>
            </div>
          </div>
          {builder && (
            <div className="card__company">Застройщик: {builder.name}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const Top = (props) => {
  const { items, title, isCommercial, isSecondaries, isSuburbans, clickSideEffect, wrapperStyle, clientMode, redisign } = props;
  const node = useRef(null);
  const navRef = useRef(null);
  const [index, setIndex] = useState(1);
  const isMobile = useMedia("isMobile")
  const settings = {
    items: 1,
    nav: false,
    loop: false,
    navAsThumbnails: isMobile && false,
    navContainer: isMobile ? `#complex-slider-thumbnails` : false,
    controls: false,
    responsive: {
      768: {
        items: 2,
        gutter: 20,
      },
      1280: {
        items: 3,
        gutter: 20,
      },
      1600: {
        items: 3,
        gutter: 20,
      },
    },
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  return (
    <section className={`section section_view_white ${redisign && 'redisign-slider'}`}>
      <div className={`${!redisign && 'wrapper'}`}>
        <div className={`assignations-top ${isSuburbans? 
          "suburbans-top" : ""}`} style={wrapperStyle}>
          <div className="assignations-top__title">
            <h2 className="h2">{title}</h2>
          </div>
          <div className="assignations-top__slider slider">
            {!isMobile && !redisign &&
              <div className="slider__nav">
                <ul className="slider__nav-wrap" id="assignations-nav">
                  <li
                    className="slider__nav-item prev"
                    onClick={() => moveSlider('prev')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    className="slider__nav-item next"
                    onClick={() => moveSlider('next')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            }
            <ErrorPlug  wrappedComponent="src/comp-ts/Secondaries/Top.js">
            <div className="slider__wrap" id="assignations-slider">
              {!isMobile && redisign &&
                <>
                  <li
                    className="slider__nav-item prev"
                    onClick={() => moveSlider('prev')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    className="slider__nav-item next"
                    onClick={() => moveSlider('next')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </>
              }
              <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                {items &&
                  items.map((item, i) => (
                    isSuburbans? <SuburbansItem
                      {...item}
                      isAgent={props.isAgent}
                      key={`villages-top-${i}`}
                      clientMode={clientMode}
                      clickSideEffect={clickSideEffect}
                      isMobile={isMobile}
                    />:
                    <TopItem
                      {...item}
                      isCommercial={isCommercial}
                      isSecondaries={isSecondaries}
                      isAgent={props.isAgent}
                      key={item.id}
                      clientMode={clientMode}
                      clickSideEffect={clickSideEffect}
                      isMobile={isMobile}
                    />
                  ))}
              </TinySlider>

              {isMobile &&
                <>
                  <ul
                    class="complex__slider-thumbnails"
                    id="complex-slider-thumbnails"
                    ref={navRef}
                  >
                    {items.map((item, index) => (
                      <li class="complex__slider-thumbnails_item" key={index}>
                        <span aria-controls="base"></span>
                      </li>
                    ))}
                  </ul>

                  <span class="complex__slider-info">
                    {index} / {items.length}
                  </span>
                </>
              }
            </div>
            </ErrorPlug>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Top;
