import React, {useEffect, useMemo, useState} from "react";
import useApi from "../../hooks/useApi";
import useModalWithData from "../../hooks/useModalWithData";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import FilterSkeleton from "../../pages/Crm/FilterSkeleton";
import { textToHTML, toFormData } from "../../utils";
import SelectFilter from "../FilterRealty/SelectFilter";
import Loader from "../Loader";
import Preloader from "../Preloader";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import { AptStatusIcon, DownloadLeftoversIcon } from "../Public/markup/icons";
import PanModal from "../core/PanModal";
import RadioDropdown from "../core/RadioDropdown";
import Table from "../core/Table";
import { LocaleLoading, LocaleNotFound } from "../core/Table/tableUtils";
import { dateToNewFormat, generateMotivationDateRange, getMotivationColumns, monthsWithSuffix } from "./motivationUtils";
import isEqual from 'lodash/isEqual';

const MotivationLast = ({ fromCrm }) => {

  const {
    state: filterState,
    setValue,
    setValues
  } = useUniversalReducer({});

  const [basicState, setBasicState] = useState({})
  const { data: {
    motivations,
    schedule,
    filter,
    file
  },
    refresher,
    isRefreshing,
    isLoading,
  } = useApi({
    payload: ['panpartner:motivation.ajax', 'getMotivationList', { isGetFilter: 1 }],
    processData: (resp, prev) => {
      const filter = resp.filter || prev.filter
      if (resp.filter?.dateFrom) {
        const [months, year] = generateMotivationDateRange(filter.dateFrom.min, filter.dateFrom.max)
        filter.months = months
        filter.year = year

        const latestYear = year[year.length - 1]?.id
        const latestMonth = months[latestYear][months[latestYear].length - 1]?.id
        const defaultClass = filter.class.find(({ selected }) => selected)?.id
        const city = filter.city.find(({ selected }) => selected)?.id

        setBasicState({
          year: latestYear,
          month: latestMonth,
          class: defaultClass,
          city
        })

        setValues({
          year: latestYear,
          month: latestMonth,
          class: defaultClass,
          city
        })
      }
      return { ...prev, ...resp }
    }
  });

  useEffect(() => {
    if (isLoading) return

    const params = {
      ...filterState,
      dateFrom: dateToNewFormat(filterState.year, filterState.month)
    };
    delete params.year
    delete params.month

    refresher(['panpartner:motivation.ajax', 'getMotivationList', toFormData(params, "filter")])

  }, [filterState])

  const { blocks, builders, monthsItems, advancePaymentTitle, motivationTitle } = useMemo(() => {
    const result = {
      blocks: [],
      builders: [],
      monthsItems: []
    }
    if (!filter) return result
    result.blocks = filter.block.filter(({ cityId, builderId }) => cityId == filterState.city || builderId === filterState.builder)
    result.builders = filter.builder.filter(({ cityId }) => cityId == filterState.city)
    result.monthsItems = filter?.months?.[filterState?.year] ?? []

    const selectedMonthName = result.monthsItems.find(({ id }) => id === filterState.month)?.title?.toLowerCase?.()
    result.motivationTitle = `За ${selectedMonthName} ${filterState.year} в ${filterState.city === 3 ? "Москве" : "Санкт-Петербурге"}`
    result.advancePaymentTitle = `В ${monthsWithSuffix[filterState.month - 1]} авансируем всех застройщиков за 3 часа`
    return result

  }, [filterState, filter])

  const { openModal: openContactsModal } = useModalWithData({
    component: ({ closeModal, contacts }) =>
      <PanModal title={'Контакты'} closeModal={closeModal} isSmall>
        <ContactsModal
          close={closeModal}
          contacts={contacts}
          schedule={schedule}
          params={{}}
        />
      </PanModal>,
    modalProps: {
      withoutBG: true
    },
  })

  const { openModal: openRulesModal } = useModalWithData({
    component: ({ closeModal, termsInteractionExt }) => {
      if(!termsInteractionExt) return null
      return (
      <PanModal title={'Условия взаимодействия'} closeModal={closeModal} isBig>
        <div className="motivation__terms-interaction">
          {
            <table>
              <tbody>
                {termsInteractionExt.map(({ title, value }, index) => (
                    <tr key={index}>
                    <td style={{width: 360, borderBottom: '1px solid #ccc'}} dangerouslySetInnerHTML={{ __html: title }}></td>
                    <td style={{borderBottom: '1px solid #ccc'}} dangerouslySetInnerHTML={{ __html: value }}></td>
                  </tr>
                  ))}
              </tbody>
            </table>
          }
        </div>
      </PanModal>
      )
    }
    ,
    modalProps: {
      withoutBG: true
    },
  })

  const motivationColumns = getMotivationColumns(openRulesModal, openContactsModal, filterState)

  if (isLoading && fromCrm) return <section className="section section_view_white orders"><div className="wrapper" style={{ textAlign: 'center' }}><FilterSkeleton /><Loader loaderColor='#2c2e3e' /></div></section>

  if (isLoading) return <Preloader loading />;

  return (
    <div className="wrapper">
      {!fromCrm &&
        <div className="common-header">
          <div className="common-header__title">
            <h3 className="common-title">
              Мотивация
            </h3>
          </div>

        </div>
      }
      <div className="motivation__period" style={{ marginBottom: 30 }}>
        <span>{motivationTitle}</span>
        {/*<span><AptStatusIcon color={'#EF372E'} />{advancePaymentTitle}</span>*/}
      </div>

      <div className="motivation-filters">

        <SelectFilter
          items={builders}
          filterValues={filterState.builder}
          onChange={v => setValue("builder", v)}
          isAutocomplete={filter?.builder?.length > 5}
          wrapperClass={"sf__filter-item"}
          data={{
            title: "Застройщик",
            identity: "builder",
          }}
        />

        <SelectFilter
          items={blocks}
          filterValues={filterState.block}
          onChange={v => setValue("block", v)}
          isAutocomplete={filter?.block?.length > 5}
          wrapperClass={"sf__filter-item"}
          data={{
            title: "Жилой комплекс",
            identity: "block",
          }}
        />

        <RadioDropdown
          onChange={v => setValues({
            "city": +v,
            "class": null,
            "block": [],
            "builder": [],
          })}
          items={filter?.city}
          value={filterState.city}
          data={{
            title: "Город",
            identity: "city",
          }}
          wrapperClass="sf__filter-item"
          noSubmit
        />


        <RadioDropdown
          onChange={v => setValue("class", Number(v))}
          items={filter?.class}
          value={filterState.class}
          data={{
            title: 'Тип недвижимости',
            identity: "class",
          }}
          wrapperClass="sf__filter-item"
          noSubmit
        />

        {/*<SelectFilter*/}
        {/*  items={filter?.award}*/}
        {/*  filterValues={filterState.award}*/}
        {/*  onChange={v => setValue("award", v)}*/}
        {/*  isAutocomplete={filter?.award?.length > 5}*/}
        {/*  wrapperClass={"sf__filter-item"}*/}
        {/*  data={{*/}
        {/*    title: "Размер вознаграждения",*/}
        {/*    identity: "award",*/}
        {/*  }}*/}
        {/*/>*/}

        <RadioDropdown
          onChange={v => setValue("year", v)}
          isAutocomplete={false}
          items={filter.year}
          value={filterState.year}
          data={{
            title: 'Год',
            autocomleteTitle: "",
            identity: "year",
          }}
          wrapperClass="sf__filter-item"
          noSubmit
        />

        <RadioDropdown
          onChange={v => setValue("month", v)}
          isAutocomplete={false}
          items={monthsItems}
          value={filterState.month}
          data={{
            title: 'Месяц',
            autocomleteTitle: "",
            identity: "month",
          }}
          wrapperClass="sf__filter-item"
          noSubmit
        />
        {!isEqual(filterState, basicState) &&
          <button
            className="button button_type_clear"
            onClick={() => {
              delete filterState.block;
              delete filterState.builder;
              delete filterState.award;
              setValues({
                year: basicState.year,
                month: basicState.month,
                class: basicState.class,
                city: basicState.city,
              })
            }}
            type="button"
          >
            <svg className="icon icon_x" style={{marginTop: '-2px', marginRight: 5}}>
              <use xlinkHref="#x"></use>
            </svg>
            <span>Очистить фильтр</span>
          </button>
        }

      </div>

      <div className="motivation__pretable-filters">
        <div className="switch">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="motivation-advance"
              name="advance"
              checked={filterState.advancePayment === "1"}
              onChange={e => setValue("advancePayment", e.target.checked ? "1" : false)}
            />
            <label
              className="switch__input"
              htmlFor="motivation-advance"
            ></label>
            <div className="switch__marker"></div>
          </div>
          <label className="switch__label" htmlFor="motivation-advance">
            Авансирование
          </label>
        </div>
        {/*<div className="motivation__dwn"*/}
        {/*  style={{ backgroundColor: 'transparent' }}>*/}
        {/*  <a href={file}*/}
        {/*    target='_blank'>*/}
        {/*    <DownloadLeftoversIcon />*/}
        {/*    Скачать мотивацию*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>


      <div className="motivation-table">
        <Table
          rowKey="id"
          columns={motivationColumns}
          dataSource={isRefreshing ? false : motivations}
          pagination={false}
          locale={isRefreshing ? LocaleLoading : LocaleNotFound}
        />
      </div>

    </div>
  )
}

export default MotivationLast;
