
import { useEffect, useState } from "react";
import request from '../api';
import { getFieldName, makeComa } from '../utils';

//TO DO: использовать где возможно, предварительно отрефакторить, а то чет с файлами пипец вышел #оптимизация
const useUniversalForm = (params, loadCondition) => {
    const {
        formId,
        city = "spb",
        defaultValues = {},
        isOneResult,
        component = "panpartner:forms.ajax",
        sendAction = "sendForm",
        getSettingsAction = "getFormSettings",
        requestOptions = {},
        onValueChanged = () => { },
        defaultCheckboxes = [],

    } = params
    const [formSettings, setFormSettings] = useState([])
    const [form, setForm] = useState({})
    const [response, setResponse] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isSending, setIsSending] = useState(false)
    const [errors, setErrors] = useState({

    })
    useEffect(
        () => {
            if (typeof loadCondition !== "undefined" && (loadCondition === false || !!formSettings.length)) return
            request(component, getSettingsAction, { formId, city, isOneResult, ...requestOptions }).then(
                (resp) => {
                    setFormSettings(resp?.questions || [])
                    setResponse(resp)
                    defaultCheckboxes.forEach(key => {
                        const tmpField = resp.questions.find(setting => setting.code === key)?.items?.[0]
                        setValue(true, tmpField?.fieldName, tmpField?.value)
                    })
                    setIsLoading(false)
                }
            )
        }, [formId, city, isOneResult, loadCondition]
    )
    useEffect(() => {
        if (!defaultValues || !formSettings?.length) return
        Object.entries(defaultValues).forEach(([key, value]) => {
            setValue(value, key)
        })
    }, [formSettings])

    const setCheckboxValue = (e, fieldName) => {
        const tmpField = response.questions.find(setting => setting.code === fieldName)?.items?.[0]
        setValue(e.target.checked, tmpField?.fieldName, tmpField?.value)
    }

    useEffect(() => {
        onValueChanged(form)
    }, [form])
    const handle = (value, code, arrayValue) => {
        const tmp = {
            ...form
        }
        if (arrayValue && value) {
            if (!tmp[code]) {
                tmp[code] = []
            }
            tmp[code].push(arrayValue)
        }
        else if (arrayValue && !value) {
            tmp[code] = tmp[code].filter(val => val !== arrayValue)
        } else {
            const ident = getFieldName(code, formSettings)
            tmp[ident] = value
        }
        return tmp
    }

    const setValue = (value, code, arrayValue) => {
        const tmp = handle(value, code, arrayValue)
        setForm(prev => ({ ...prev, ...tmp }))
    }
    const forcedSetValue = (value, code) => {
        setForm(prev => ({ ...prev, [code]: value }))
    }

    const resetForm = () => {
        setForm(defaultValues);
        if (!defaultCheckboxes) return;
        defaultCheckboxes.forEach(key => {
            const tmpField = response.questions.find(setting => setting.code === key)?.items?.[0]
            setValue(true, tmpField?.fieldName, tmpField?.value)
        })
    }
    const clearForm = () => setForm(object => {
        const result = {}
        Object.keys(object).forEach(key => {
            result[key] = ""
        })
        return result
    });

    const getSettingField = code => formSettings.find(setting => setting.code === code)
    const checkRequeredFields = reqFields => {
        if (!reqFields) return false
        const tmp = {}
        for (const key in reqFields) {
            if (!getValue(key)) tmp[key] = reqFields[key]
        }
        setErrors(tmp)
        const errorsArray = Object.values(tmp);
        return !!errorsArray.length ? errorsArray : false;
    }
    const send = (altValues, additionalData = {}, reqFields) => {
        const check = checkRequeredFields(reqFields);
        if (check) return Promise.reject([{ message: check.map((v, i, a) => makeComa(v, i, a, true, " \n")) }]);
        let tmpForm = { ...form }
        setIsSending(true)
        if (altValues) {
            Object.entries(altValues).forEach(([key, value]) => {
                const fieldName = getFieldName(key, formSettings)
                const val = handle(value, key)?.[fieldName]
                tmpForm = { ...tmpForm, [fieldName]: val }
            })
        }
        setIsLoading(true)
        let formData = new FormData();
        Object.entries(tmpForm).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                const items = getItems(key)
                if (!items.length) {
                    value.forEach(val => formData.append(`${key}`, val))
                    return
                }
                const isFiles = !!value?.[0]?.fileObj

                if (isFiles) {
                    const fileSettings = getSettingField(key)
                    for (let i = 0; i < value.length; i++) {
                        const file = value[i]
                        const fieldName = items[i]?.fieldName || `${fileSettings.code}_${fileSettings.type}`
                        formData.append(fieldName, file.fileObj, file.fileName)
                    }
                    return
                }
                items.forEach((item, i) => {
                    const val = value[i]
                    if (!val) return
                    formData.append(`${item.fieldName}`, val)
                })
                return
            }
            if (!!value) formData.append(`${key}`, value);
        })
        Object.entries(additionalData).forEach(([key, value]) => value ? formData.append(key, value) : null)
        if (formId) {
            formData.append("formId", formId)
        }

        formData.append("city", city)
        return request(component, sendAction, formData).then(
            success => {
                setIsLoading(false)
                setIsSending(false)
                return Promise.resolve(success)
            },
            err => {
                setIsLoading(false)
                setIsSending(false)
                return Promise.reject(err)
            }
        )
    }
    const getValue = (code, isCheckbox) => {
        const value = form[getFieldName(code, formSettings)]
        return isCheckbox ? value?.[0] : value;
    }

    const getItems = code => formSettings.find(q => q.code === code)?.items || []

    const setValues = values => {
        setForm(prev => {
            const tmp = { ...prev }
            Object.entries(values).forEach(([code, value]) => {
                const ident = getFieldName(code, formSettings)
                tmp[ident] = value
            })
            return tmp;
        })
    }
    const convertArrayToUniformData = (code, value) => {
        if (!Array.isArray(value)) return;
        const tmp = {}
        const idents = getFieldName(code, formSettings, true)
        value.forEach((val, i) => {
            const ident = idents?.[i]?.fieldName ?? code;
            tmp[ident] = val
        })
        return tmp
    }

    return {
        setValue,
        send,
        form,
        formSettings,
        isLoading,
        isSending,
        response,
        getValue,
        getItems,
        forcedSetValue,
        resetForm,
        clearForm,
        setCheckboxValue,
        setValues,
        convertArrayToUniformData,
        errors,
        setErrors,
        setResponse
    }
};

export default useUniversalForm;
