import React from "react";
import ContentLoader from "react-content-loader";

const Loader = (props) => (
  <section className="section section_view_white orders">
    <div className="wrapper" style={{ display: "flex", maxWidth: "fit-content" }}>
      <ContentLoader
        speed={1}
        width={1600}
        height={415}
        viewBox="0 0 1600 631"
        backgroundColor="#f4f4f8"
        foregroundColor="#ecebeb"
        {...props}
      >
        {/* <rect x="65" y="0" rx="4" ry="4" width="1470" height="93" /> */}
        <rect x="65" y="0" rx="4" ry="4" width="1470" height="55" />
        <rect x="85" y="121" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="121" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="121" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="200" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="200" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="200" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="260" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="260" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="260" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="321" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="321" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="321" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="383" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="383" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="383" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="445" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="445" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="445" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="507" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="507" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="507" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="569" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="569" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="569" rx="50" ry="50" width="40" height="40" />
        <rect x="85" y="631" rx="4" ry="4" width="400" height="40" />
        <rect x="580" y="631" rx="4" ry="4" width="800" height="40" />
        <rect x="1474" y="631" rx="50" ry="50" width="40" height="40" />
      </ContentLoader>
    </div>
  </section>
);

export default Loader;
