import floor from 'lodash/floor';
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearCommercial,
  clearFilterCommercial,
  fetchCommercial,
  getCount,
  setFilterCommercial
} from "../../actions/commercial";
import useDocTitle from '../../hooks/useDocTitle';
import { mapCommercial } from "../../selectors/commercial";
import { scrollToRef } from '../../utils';
import Filter from "../Assignation/Filter";
import Preloader from "../Preloader";
import FilterDisabled from "../Public/FilterDisabled";
import Items from "./Items";


const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    setFilter,
    clearFilter,
    data,
    features,
    mapItems,
    getCount,
    countToShow,
    clientMode,
    isShowFilter,
    isActive,
    agent,
    savedFilter
  } = props;
  const itemsRef = useRef(null);
  const { savedFilterXmlId } = useParams();
  const city = "spb"
  const handleFilter = (params) => {
    fetch("spb",0, params, savedFilterXmlId).then((data) => {
      scrollToRef(itemsRef, 80);
    });
  };
  useDocTitle("Коммерческая недвижимость")
  useEffect(() => {
    // getCount(filterToParams(defaultFullFilter),savedFilterXmlId);
    const noStatistic = new URLSearchParams(window.location.search).get("noStatistic")
    fetch("spb", 1, {noStatistic}, savedFilterXmlId);
    return () => {
      reset();
    };
  }, []);
  const correctedFilter = (filter) => {
    if (!filter)
      return {
        filter: {},
        exclude: {},
      };

    const specials = ["isNoFirstFloor", "isApartments", "isOnlyHanded"];
    const result = filter;

    specials.forEach((key) => {
      Object.entries(result).forEach(([identity, value]) => {
        if (Array.isArray(value?.[key]) || !value?.[key]) return;
        result[identity][key] = [{ id: value[key] }];
      });
    });

    return result;
  };
  return (
    <>
      <div class="wrapper">
        <div class="assignations-filter">
          <div class="assignations-filter__btns"></div>
        </div>
      </div>
      {isActive ? (
        <>
          {<Filter
            savedFilterXmlId={savedFilterXmlId}
            filterData={filterData}
            savedFilter={correctedFilter(savedFilter)}
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(params) => {
              handleFilter(params);
            }}
            getCount={getCount}
            info={{...data, count: floor(950,-2)}}
            countToShow={countToShow}
            filterType="commercial"
            isPublic={true}
            city={city}
            isCommercial={true}
            isShowFilter={isShowFilter}
          />}
          <Items
            items={data}
            innerRef={itemsRef}
            features={features}
            mapItems={mapItems}
            clientMode={clientMode}
            alternativeLink={`/public/commercials/${savedFilterXmlId}/spb/`}
          />
        </>
      ) : (
        <FilterDisabled agent={agent} />
      )}
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city,isGetFilter, form, savedFilterXmlId) =>
    dispatch(fetchCommercial(city,isGetFilter, form, savedFilterXmlId)),
  reset: () => dispatch(clearCommercial()),
  setFilter: (filter) => dispatch(setFilterCommercial(filter)),
  clearFilter: () => dispatch(clearFilterCommercial()),
  getCount: (params, savedFilterXmlId) => dispatch(getCount(params, savedFilterXmlId)),
});

const mapStateToProps = (state) => ({
  filterData: state.commercial.filterData,
  filter: state.commercial.filter,
  isFetching: state.commercial.isFetching,
  error: state.commercial.error,
  data: state.commercial.data,
  features: mapCommercial(state),
  mapItems: state.commercial.mapItems,
  countToShow: state.commercial.countToShow,
  clientMode: state.general.clientMode,
  isShowFilter: state.commercial.isShowFilter,
  isActive: state.commercial.isActive,
  agent: state.commercial.agent,
  savedFilter: state.commercial.savedFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
