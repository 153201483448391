import React from "react";
import Block from "./Block.js";
import BlockCottageSettlement from "./BlockCottageSettlement";
// import blockMoc from "./blockMoc.js";


const CardCottageSettlement = (props) => {

  const {block, navigation, isClientMode, clients , addClientToFavorite, removeClientFromFavorite, fetchClients, activeNav, haveApartments, savedFilterXmlId, theme} = props;
// console.log(props)
  return (
    <section className="card constructor__section">
      <div className="constructor__container">

        <div className="constructor__wrapper card__wrapper">
          <BlockCottageSettlement
            block={block}
            navigation={navigation}
            isClientMode={isClientMode}
            clients={clients}
            isPublic={true}
            addClientToFavorite={addClientToFavorite}
            removeClientFromFavorite={removeClientFromFavorite}
            fetchClients={fetchClients}
            activeNav={activeNav}
            haveApartments={haveApartments}
            savedFilterXmlId={savedFilterXmlId}
            theme={theme}
            forConstructorCard
          />
        </div>
      </div>
    </section>
  )
};

export default CardCottageSettlement;
