import { useMediaQuery } from "beautiful-react-hooks";
import dateFormat from "dateformat";
import formatter from "format-number";
import isEqual from "lodash/isEqual";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Map, RulerControl, YMaps } from "react-yandex-maps";
import usePermission from "../../../hooks/usePermission";
import { media } from "../../../utils";
import AutopanTrigger from '../../AutopanTrigger/AutopanTrigger.js';
import Photos from "../../Complex/Photos";
import { themeColors } from '../constructorUtils';
import MobileModal from '../MobileModal';
import Breadcrumbs from '../InnerPage/Breadcrumbs/Breadcrumbs';
const updateFormat = `dd.mm.yyyy в HH:MM`;

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const SubwayList = (props) => {
  const { items } = props;

  const routeType = (type) => {
    const way = type === "byTransport" ? "auto" : "pedestrian";
    return (
      <span className="complex__route-type">
        <svg className={`icon icon_${way}`}>
          <use xlinkHref={`#${way}`}></use>
        </svg>
      </span>
    );
  };

  return (
    <div className="complex__info-routes">
      <ul className="complex__routes">
        {items.map((subway) => (
          <li className="complex__routes-item" key={subway.id}>
            <span
              className="complex__metro-color"
              style={{ backgroundColor: subway.color }}
            ></span>
            <span className="complex__metro-station">{subway.name}</span>
            {routeType(subway.distanceType)}
            {subway.minutes && (
              <span className="complex__route-time">{subway.minutes} мин.</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Block = (props) => {
  const {
    block,
    isFavorite,
    navigation,
    isClientMode,
    isPublic,
    activeNav,
    haveApartments,
    savedFilterXmlId,
    theme,
    forConstructorCard
  } = props;


  const [initMap, setInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  const [isFixed, setIsFixed] = useState(false);
  const modalMap = useRef(null);
  const middleScreen = useMediaQuery('(max-width: 1599px)');
  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
 const permission = usePermission("motivation")
  useEffect(() => {
    if (!modalMap) return;
    if (!media('isMobile')) {
      if (showMap) {
        modalMap.current.classList.add("is-opened");
        document.body.classList.add("no-scroll");
      } else {
        modalMap.current.classList.remove("is-opened");
        document.body.classList.remove("no-scroll");
      }
    }
  }, [showMap]);

  const typesList = (types) => {
    return types.map((item) => item.name).join(", ");
  };

  const endingsList = (endings) => {
    if (!endings || !endings.from) {
      return "";
    }
    let value = `${endings.from.quarter} кв. ${endings.from.year}`;
    if (!isEqual(endings.from, endings.to)) {
      value += ` - ${endings.to.quarter} кв. ${endings.to.year}`;
    }
    return value;
  };

  useEffect(() => {
    if(!mapInstance || !ymaps || !theme) return
    const placemarkTemplate =  ymaps.templateLayoutFactory.createClass(
      `<svg width="48" height="57" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 56" style="enable-background:new 0 0 48 56;" xml:space="preserve">
      <path style="fill-rule:evenodd;clip-rule:evenodd;" fill="${themeColors[theme.color]}" d="M8.12,0.12c-4.42,0-8,3.58-8,8v31.75c0,4.42,3.58,8,8,8h10.56L24,55.88l5.32-8.02h10.56c4.42,0,8-3.58,8-8V8.12  c0-4.42-3.58-8-8-8H8.12z"/>
      <path fill="#FFFFFF" d="M30.73,11.33H17.27c-0.37,0-0.67,0.29-0.67,0.65v23.86c0,0.36,0.3,0.65,0.67,0.65h5.22v-3.81h3.03v3.81h5.22  c0.37,0,0.67-0.29,0.67-0.65V11.99C31.4,11.62,31.1,11.33,30.73,11.33z M22.85,30.24h-3.03v-3.28h3.03V30.24z M22.85,24.5h-3.03  v-3.28h3.03V24.5z M22.85,18.77h-3.03V15.5h3.03V18.77z M28,30.24h-3.03v-3.28H28V30.24z M28,24.5h-3.03v-3.28H28V24.5z M28,18.77  h-3.03V15.5H28V18.77z"/>
      </svg>
      `,
    )
    const placeMaprk  = new ymaps.Placemark(block.coordinates, {
      hintContent: 'Собственный значок метки',
      balloonContent: 'Это красивая метка'
  }, {
      iconLayout: placemarkTemplate,
      iconOffset: [-24, -58],

  });
  mapInstance.geoObjects
  .add(placeMaprk);
  }, [mapInstance, theme, ymaps])
  return (
    <>
      <div className="complex__block">
        {
          forConstructorCard && <Breadcrumbs currentPage={block.name} />
        }
        <div className={`complex__block-row`}>

          <div className="complex__block-col info__col info__col-top">
            {block.commission && !isClientMode && !isPublic && permission &&(
              <ul className="complex__badges">
                <li className="complex__badges-item">
                  <div className="badge complex__badge-badge">
                    Комиссия: {block.commission.join(", ")}
                  </div>
                </li>
              </ul>
            )}
            {!isPublic && (
              <div className="complex__info-update">
                {`Обновлено ${dateFormat(
                  block.dateUpdate,
                  updateFormat,
                  "isoTime",
                )} `}
              </div>
            )}
          </div>
          {!isPublic && !media('isMobile') && (
            <div className="complex__block-col text__col sm-hide">
              <div
                className="complex__address"
                style={{ alignItems: "center" }}
              >
                <div className="complex__address-value">
                  {block.address}
                </div>
                <div className="complex__address-map">
                  <span
                    className="show__map"
                    onClick={() => setShowMap(true)}
                  >
                  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52772 17.4444C6.52772 17.4444 12.3333 11.0575 12.3333 6.06279C12.3333 3.02095 9.73394 0.555542 6.52772 0.555542C3.32112 0.555542 0.722168 3.02095 0.722168 6.06279C0.722168 11.1765 6.52772 17.4444 6.52772 17.4444ZM9.16661 6.3611C9.16661 4.90399 7.98483 3.72221 6.52772 3.72221C5.07062 3.72221 3.88883 4.90399 3.88883 6.3611C3.88883 7.8182 5.07062 8.99999 6.52772 8.99999C7.98483 8.99999 9.16661 7.8182 9.16661 6.3611Z" />
                  </svg>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="complex__info-main mobile sm-show">
          {!media('isMobile') && 
            <div className="complex__address">
              <div className="complex__address-value">{block.address}</div>
              <div className="complex__address-map">
                <div
                  className="link show__map-mobile"
                  onClick={() => setShowMap(true)}
                >
                  {!media("isMobile") &&
                    <Fragment>
                      <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52772 17.4444C6.52772 17.4444 12.3333 11.0575 12.3333 6.06279C12.3333 3.02095 9.73394 0.555542 6.52772 0.555542C3.32112 0.555542 0.722168 3.02095 0.722168 6.06279C0.722168 11.1765 6.52772 17.4444 6.52772 17.4444ZM9.16661 6.3611C9.16661 4.90399 7.98483 3.72221 6.52772 3.72221C5.07062 3.72221 3.88883 4.90399 3.88883 6.3611C3.88883 7.8182 5.07062 8.99999 6.52772 8.99999C7.98483 8.99999 9.16661 7.8182 9.16661 6.3611Z" />
                      </svg>
                      Показать на карте
                    </Fragment>
                  }
                </div>
              </div>
            </div>
          }
          <div className="complex__main">
            <h1 className="h2 complex__title">
              <span>{`ЖК «${block.name}»`}</span>
              {block.isAutoPan && !savedFilterXmlId && <AutopanTrigger modClass="autopan-trigger_constructor" />}
            </h1>
            {block.subway && block.subway.length > 0 && (
              <SubwayList items={block.subway} />
            )}

            <div className="complex__address-map">
              <span
                className="show__map"
                onClick={() => setShowMap(true)}
              >
              <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52772 17.4444C6.52772 17.4444 12.3333 11.0575 12.3333 6.06279C12.3333 3.02095 9.73394 0.555542 6.52772 0.555542C3.32112 0.555542 0.722168 3.02095 0.722168 6.06279C0.722168 11.1765 6.52772 17.4444 6.52772 17.4444ZM9.16661 6.3611C9.16661 4.90399 7.98483 3.72221 6.52772 3.72221C5.07062 3.72221 3.88883 4.90399 3.88883 6.3611C3.88883 7.8182 5.07062 8.99999 6.52772 8.99999C7.98483 8.99999 9.16661 7.8182 9.16661 6.3611Z" />
              </svg>
              </span>
            </div>
          </div>
        </div>

        <div className="complex__block-row">
          <div className="complex__block-col info__col">
            {<Photos photos={block.photos} tour={block?.builder?.typical3DTour} isCounstructor={true} />}
          </div>
          <div className="complex__block-col text__col">
            <div className="complex__info-main sm-hide">
              <div className="complex__main">
                {isPublic && (
                  <div
                    className="complex__block-col info__col-top"
                    style={{ margin: 0 }}
                  >
                    <div className="complex__address-value">
                      {block.address}
                    </div>
                    <div className="complex__address-map">
                      <span
                        className="show__map"
                        onClick={() => setShowMap(true)}
                      >
                      <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52772 17.4444C6.52772 17.4444 12.3333 11.0575 12.3333 6.06279C12.3333 3.02095 9.73394 0.555542 6.52772 0.555542C3.32112 0.555542 0.722168 3.02095 0.722168 6.06279C0.722168 11.1765 6.52772 17.4444 6.52772 17.4444ZM9.16661 6.3611C9.16661 4.90399 7.98483 3.72221 6.52772 3.72221C5.07062 3.72221 3.88883 4.90399 3.88883 6.3611C3.88883 7.8182 5.07062 8.99999 6.52772 8.99999C7.98483 8.99999 9.16661 7.8182 9.16661 6.3611Z" />
                      </svg>
                      </span>
                    </div>
                  </div>
                )}
                <h1 className="h2 complex__title">
                  <span>{`ЖК «${block.name}»`}</span>
                  {block.isAutoPan && <AutopanTrigger modClass="autopan-trigger_constructor" />}
                </h1>

                {block.subway && block.subway.length > 0 && (
                  <SubwayList items={block.subway} />
                )}
              </div>
            </div>
            <div className="complex__bottom">
              {block.apartmentsInfo && (
                <div className="complex__price">
                  <div className="complex__price-row">
                    <div className="complex__price-col complex__price-left">
                      <div className="complex__price-value">{`от ${priceFormat(
                        block.apartmentsInfo?.minPrice,
                      )}`}</div>
                      <div className="complex__info-item-desc">
                        Цена при 100% оплате
                      </div>
                    </div>
                    <div className="complex__price-col complex__price-right">
                      <div className="complex__price-value">
                        {block.apartmentsInfo.count}
                      </div>
                      <div className="complex__info-item-desc">
                        Квартир в продаже
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  "complex__additional" + (isPublic ? " no-border" : "")
                }
              >
                <div className="complex__additional-row">
                  <div className="complex__additional-col complex__additional-item">
                    <div className="complex__info-item-value">
                      {block.builder.name}
                    </div>
                    <div className="complex__info-item-desc">
                      Застройщик
                    </div>
                  </div>
                  {!!block.endingPeriod && <div className="complex__additional-col complex__additional-item">
                    <div className="complex__info-item-value">
                      {endingsList(block.endingPeriod)}
                    </div>
                    <div className="complex__info-item-desc">
                      Срок сдачи
                    </div>
                  </div>}
                 {!!block.blockClass && <div className="complex__additional-col complex__additional-item">
                    <div className="complex__info-item-value">
                      {block.blockClass.name}
                    </div>
                    <div className="complex__info-item-desc">
                      Класс жилья
                    </div>
                  </div>}
                  {!!block.decorationTypes &&
                    block.decorationTypes.length > 0 && (
                      <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {typesList(block.decorationTypes)}
                        </div>
                        <div className="complex__info-item-desc">
                          Отделка
                        </div>
                      </div>
                    )}
                  {!!block.buildingTypes && block.buildingTypes.length > 0 && (
                    <div className="complex__additional-col complex__additional-item">
                      <div className="complex__info-item-value">
                        {typesList(block.buildingTypes)}
                      </div>
                      <div className="complex__info-item-desc">
                        Тип дома
                      </div>
                    </div>
                  )}
                  {!!block.registration && <div className="complex__additional-col complex__additional-item">
                    <div className="complex__info-item-value">
                      {block.registration.name}
                    </div>
                    <div className="complex__info-item-desc">Прописка</div>
                  </div>}
                  {block.paymentTypes && block.paymentTypes.length > 0 && (
                    <div className="complex__additional-col complex__additional-full">
                      <div className="complex__info-item-value">
                        {typesList(block.paymentTypes)}
                      </div>
                      <div className="complex__info-item-desc">Оплата</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {media('isMobile') && showMap &&
        <MobileModal title={`ЖК «${block.name}» на карте`} closeClick={() => setShowMap(false)} className="for-map">
          <div className="dist-map" id="dist-map-container">
            {initMap || showMap ? (
              <YMaps>
                <Map
                  defaultState={{
                    center: block.coordinates,
                    zoom: 16,
                  }}
                  className="complex__map-wrap"
                  onLoad={mapLoad}
                  modules={['templateLayoutFactory', 'Template', "Placemark"]}
                  instanceRef={(inst) => setMapInstance(inst)}
                >
                  <RulerControl
                    options={{ position: { top: 10, left: 10 } }}
                  />
                </Map>
              </YMaps>
            ) : (
              <div className="dist-map" id="dist-map-container" />
            )}
            </div>
        </MobileModal>
      }

      {!media('isMobile') &&
        <div className="modal" ref={modalMap}>
          <div className="modal__shadow " data-id="dist-map"></div>
          <div className="modal__content">
            <div className="modal__inner">
              <div
                className="modal__close "
                data-id="dist-map"
                onClick={() => setShowMap(false)}
              >
                <svg className="icon">
                  <use xlinkHref="#x"></use>
                </svg>
              </div>
              <h3 className="modal__title">{`ЖК «${block.name}» на карте`}</h3>

              <div className="dist-map" id="dist-map-container">
                {initMap || showMap ? (
                  <YMaps>
                    <Map
                      defaultState={{
                        center: block.coordinates,
                        zoom: 16,
                      }}
                      className="complex__map-wrap"
                      onLoad={mapLoad}
                      modules={['templateLayoutFactory', 'Template', 'Placemark']}
                      instanceRef={(inst) => setMapInstance(inst)}

                    >
                      <RulerControl
                        options={{ position: { top: 10, left: 10 } }}
                      />
                    </Map>
                  </YMaps>
                ) : (
                  <div className="dist-map" id="dist-map-container" />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Block;
