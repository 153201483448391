import React, {useEffect, useMemo, useRef, useState} from "react";
import formatter from "format-number";
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.min.css";
import {CalculatorResultPayIcon, CalculatorResultWalletIcon} from "../Public/markup/icons";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import PaymentsSchedule from "./payments";
import NewAlert from "../core/NewAlert";

const priceFormat = (price) => formatter({integerSeparator: " ", suffix: " р."})(price);

const MortgageCalc = (props) => {
  const [tab, setTab] = useState("mortgage");

  const {
    id=0,
    price,
    period,
    prepay,
    percent,
    city,
    type,
    isPublic,
    showInstallment = true,
    openCallbackModal,
    btnClass,
    mortgage,
    setPriceEstate,
    setFirstPayEstate,
    setTimeEstate,
    setRateEstate,
    closeCalc,
    setShowCalcBtn,
    showCalcBtn,
    setCalc2,
    setCalc3,
    sendUniveralForm,
    forPdfSettings
  } = props;


  const [calc, setCalc] = useState({
    price: price,
    period: period,
    prepay: prepay,
    percent: percent,
    total: 0,
  });

  const [total, setTotal] = useState();

  const prepayRef = useRef(null);

  const getTotal = useMemo(() => total, [total]);

  const [maxPrepay, setMaxPrepay] = useState(0);

  const changeTotal = (tmpCalc) => {
    let {price, period, prepay, percent} = tmpCalc;
    let payment = price - prepay;
    let m = percent / 100 / 12;
    let koef =
      (m * Math.pow(1 + m, period * 12)) / (Math.pow(1 + m, period * 12) - 1);
    let result = (payment * koef).toFixed();
    setTotal(result);
    handleChange("total", result);
  };

  const handleChange = (name, value) => {
    let tmpCalc = calc;
    tmpCalc[name] = value;
    setCalc(tmpCalc);
    if (name !== "total") {
      changeTotal(tmpCalc);
    }
    if (name === "price") {
      setMaxPrepay(value);
      setPriceEstate(value)
    }
    if (name === "prepay") {
      setFirstPayEstate(value)
    }
    if (name === "period") {
      setTimeEstate(value)
    }
    if (name === "percent") {
      setRateEstate(value)
    }

  };

  const [initPercentRate, setInitPercentRate] = useState(0)
  const [initPrepayRate, setInitPrepayRate] = useState(0)

  const crumbsRateCallback = (value) => {
    setRateEstate(value)
  }
  const crumbsPercentCallback = (value) => {
    setFirstPayEstate((price / 100) * value)
  }

  useEffect(() => {
    initInputSliders();
  }, []);

  const formatNumbers = (n) => {
    const val = (n + "").split(" ").join("");
    n = val;
    return (n + "")
      .split("")
      .reverse()
      .join("")
      .replace(/(\d{3})/g, "$1 ")
      .split("")
      .reverse()
      .join("")
      .replace(/^ /, "");
  };

  const initInputSliders = () => {
    const slidersContainers = [...document.querySelectorAll(`.slider-input-${id}`)];

    slidersContainers.forEach((container) => {
      const valueInput = container.querySelector(".slider-input__control");
      const slider = container.querySelector(`.slider__input-${id}`);
      const postfix = container.dataset.postfix;
      const isPrice = container.dataset.isPrice !== undefined;
      const start = Number(container.dataset.start);
      const step = Number(container.dataset.step);
      let min = Number(container.dataset.min);
      let max = Number(container.dataset.max);
      const name = valueInput.name;
      if (min === max) {
        min += 1
      }
      noUiSlider.create(slider, {
        start,
        connect: [true, false],
        range: {
          min,
          max,
        },
        step,
        format: {
          to: function (value) {
            let result = step % 1 > 0 ? value.toFixed(2) : parseInt(value);
            return formatNumbers(result) + postfix;
          },
          from: function (value) {
            if (postfix)
              return Number(value.replace(postfix, "").replace(/\ /g, ""));
            let n = value.replace(/\,/g, ".");
            return Number(n.replace(/\ /g, ""));
          },
        },
      });
      let percentSlider = document.getElementById(`percentSlider-${id}`);
      let prepaySlider = document.getElementById(`prepaySlider-${id}`);
      const calculatorDiv = document.getElementById(`${id}`);

      const crumbs = calculatorDiv.querySelectorAll('.calculator__crumbs');
      crumbs.forEach(button => {
        button.addEventListener('click', () => {
          const data = button.getAttribute('data-percent');
          percentSlider.noUiSlider.set([data]);
        });
      });

      const crumbs3 = calculatorDiv.querySelectorAll('.calculator__crumbs3');
      crumbs3.forEach(button => {
        button.addEventListener('click', () => {
          const data = button.getAttribute('data-prepay');
          prepaySlider.noUiSlider.set([(price / 100) * data]);
        });
      });

      const firstCrumb = calculatorDiv.querySelector('.calculator__crumbs');
      const dataPercent = firstCrumb.getAttribute('data-percent');
      setInitPercentRate(dataPercent)

     if (forPdfSettings) {
        const firstCrumb3 = calculatorDiv.querySelector('.calculator__crumbs3');
        const dataPrepay = firstCrumb3.getAttribute('data-prepay');
        setInitPrepayRate(dataPrepay)
     }

      slider.noUiSlider.on("update", function (values, handle) {
        valueInput.value = values[handle];
        handleChange(
          name,
          Number(values[handle].replace(postfix, "").replace(/\ /g, ""))
        );
      });

      valueInput.addEventListener("change", function () {
        slider.noUiSlider.set(this.value);
      });

      valueInput.addEventListener("input", function () {
        let pos = this.selectionStart;
        const prevLength = this.value.length;

        this.value = formatNumbers(this.value);
        const newLength = this.value.length;

        pos = pos + (newLength - prevLength);
        this.setSelectionRange(pos, pos);
      });

    });
    const allPriceSliders = [...document.querySelectorAll(".price-input")];
    allPriceSliders.forEach((slider) => {
      let sliderName = slider.name;
      if (sliderName !== "price") {
        slider.addEventListener("focus", function () {
          this.value = this.value.replace("", "");
        });

        slider.addEventListener("blur", function () {
          if (!this.value) return;
          this.value = this.value.replace("", "") + "";
        });
      }
    });
  };


  const [openMortgageModal, closeMortgageModal] = useModal(
    () => (
      <PanModal title={'График платежей'} closeModal={closeMortgageModal} isBig>
        <PaymentsSchedule price={price} prepay={prepay} period={period} rateEstate={percent}/>
      </PanModal>
    ),
    [price, prepay, period, percent]);


  const [disabledButtons, setDisabledButtons] = useState(true)
  useEffect(() => {
    if (price !== 0 && period !== 0 && Number(total) > 0) {
      setDisabledButtons(false)
    } else {
      setDisabledButtons(true)
    }
  }, [price, prepay, period])

  const removeCalc = (event) => {
    const parentElement = event.target.closest('.calculator-mortgage');
    if (parentElement) {
      parentElement.remove();
    }
    setShowCalcBtn(showCalcBtn )
  }


  const [applicationBtn, setApplicationBtn] = useState(true)
  const [hintText, setHintText] = useState('')

  useEffect(() => { // повторная возможность отправки при изменении данных
    if (applicationBtn) return
    setApplicationBtn(true)
  }, [price, prepay, period, percent])

  const applicationCallback = () => {
    sendUniveralForm(false, { // на бэке ожидаются массивы, не будем его переделывать
      'price[]': price,
      'firstPayment[]': prepay,
      'period[]': period,
      'percent[]': percent
    }).then(
      resp => {
        setApplicationBtn(false)
        setHintText(resp.message)
      }
    )
  }

  const clearValues = () => {

    setPriceEstate(0)
    setFirstPayEstate(initPrepayRate)
    setFirstPayEstate(0)
    setTimeEstate(0)
    setRateEstate(initPercentRate)
    if (setCalc2) {
      setCalc2(false)
    } else {
      setCalc3(false)
    }
  }


  const prepayPercents = [
    {"percent": 10},
    {"percent": 15},
    {"percent": 20.1},
    {"percent": 30.1},
  ]

  return (

    <div className={`calculator calculator-mortgage ${forPdfSettings ? ' calculator-mortgage_for-pdf' : ''}`} id={id}>
      <div className="calculator__grid">
        {closeCalc &&
          <span className="calculator__close" onClick={clearValues}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 7L12 12L17 7" stroke="#2C2E3E" strokeLinecap="round"/>
              <path d="M7 17L12 12L17 17" stroke="#2C2E3E" strokeLinecap="round"/>
            </svg>
          </span>
        }
        <div className="calculator__form">
          <div
            className={`calculator__item slider-input-${id}`}
            data-start={price}
            data-min={0}
            data-max={150000000}
            data-step={50000}
            data-postfix=""
            data-is-price
          >
            <label htmlFor="mortgage-price">Стоимость недвижимости</label>
            <div className="calculator__item-wrapper">
              <input
                className="slider-input__control price-input"
                type="text"
                id="mortgage-price"
                name="price"
              />
              <span className="slider-input__control-postfix">руб.</span>
            </div>
            <div className={`slider__input slider__input-${id}`}/>
          </div>
          <div
            className={`calculator__item slider-input-${id}`}
            data-start={prepay}
            data-min={0}
            data-max={!forPdfSettings ? 150000000 : price}
            data-step={20000}
            data-postfix=""
            data-is-price
          >
            <label htmlFor="mortgage-prepay" className="mortgage-percents">
              Первоначальный взнос
              {forPdfSettings &&
                <ul>
                  {
                    prepayPercents.map((item) => {
                      return (
                        <li data-prepay={item.percent} className="calculator__crumbs3"  onClick={() => {crumbsPercentCallback(item.percent)}}>{item?.percent}%</li>
                      )
                    })
                  }
                </ul>
              }
            </label>
            <div className="calculator__item-wrapper">
              <input
                className="slider-input__control price-input"
                type="text"
                id="mortgage-prepay"
                name="prepay"
              />
              <span className="slider-input__control-postfix">руб.</span>
            </div>
            <div className={`slider__input slider__input-${id}`}   id={`prepaySlider-${id}`} ref={prepayRef}/>
          </div>



          <div
            className={`calculator__item slider-input-${id}`}
            data-start={period}
            data-min="0"
            data-max="30"
            data-step={1}
            data-postfix=""
          >
            <label htmlFor="mortgage-period">Срок ипотеки</label>
            <div className="calculator__item-wrapper">
              <input
                className="slider-input__control"
                type="text"
                id="mortgage-period"
                name="period"
              />
              <span className="slider-input__control-postfix">лет</span>
            </div>
            <div className={`slider__input slider__input-${id}`}/>
          </div>

          <div
            className={`calculator__item slider-input-${id}`}
            data-start={percent}
            data-min="0"
            data-max="20"
            data-step={0.01}
            data-postfix=""
            id='slideId'
          >
            <label htmlFor="mortgage-percent" className="mortgage-percents">
              Процентная ставка
              {
                mortgage && forPdfSettings &&
                  <ul>
                    {mortgage.map((item) => {
                      return (
                          <li data-percent={item.percent} className="calculator__crumbs" onClick={() => {crumbsRateCallback(item.percent)}}>
                            {item.percent + '%'}
                          </li>
                      )
                    })}
                </ul>
              }
            </label>
            <div className="calculator__item-wrapper">
              <input
                className="slider-input__control"
                type="text"
                id="mortgage-percent"
                name="percent"
              />
              <span className="slider-input__control-postfix">%</span>
            </div>
            <div className={`slider__input slider__input-${id}`} id={`percentSlider-${id}`}/>
          </div>

          {
            mortgage && !forPdfSettings && mortgage.map((item) => {
              return (
                <span data-percent={item.percent} className="calculator__crumbs" onClick={() => {crumbsRateCallback(item.percent)}}>
                  {item.title}
                </span>
              )
            })
          }




          {!mortgage && <span data-percent={12} className="calculator__crumbs" >

          </span>}
        </div>
        {!forPdfSettings &&
          <div className="calculator__result">
            <div className="calculator__result-title h4">
              Предварительный расчет
            </div>
            <div className="calculator__result-block">
              <ul className="calculator__result-list">
                <li className="calculator__result-list-item">
                  <div className="calculator__result-list-icon">
                    <CalculatorResultPayIcon/>
                  </div>
                  <div className="calculator__result-list-overstat calculator__result-list-overstat_active">
                    <div className="calculator__result-list-title">Ежемесячный платеж</div>
                    <div className="calculator__result-list-value h3">{total !== 'NaN' && total > 0 && total !== 'Infinity' && total !== '-Infinity' ? priceFormat(total) : '0 р.'}</div>
                  </div>
                </li>
                <li className="calculator__result-list-item">
                  <div className="calculator__result-list-icon">
                    <CalculatorResultWalletIcon/>
                  </div>
                  <div className="calculator__result-list-overstat">
                    <div className="calculator__result-list-title">Необходимый доход</div>
                    <div className="calculator__result-list-value h3">{total !== 'NaN' && total > 0 && total !== 'Infinity' && total !== '-Infinity' ? priceFormat(Number(total) * 2) : '0 р.'} </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              className={`button  button_view_outline`}
              style={{width: '100%', marginBottom: 10, fontWeight: 'bold'}}
              onClick={openMortgageModal}
              disabled={disabledButtons}
            >
              График платежей
            </button>
            {
              applicationBtn ? <button
                className={`button ${disabledButtons ? `button_view_outline` : `button_view_default`}`}
                style={{width: '100%', fontWeight: 'bold'}}
                disabled={disabledButtons}
                onClick={applicationCallback}
              >
                Заявка на расчет
              </button> : <NewAlert
                title={hintText}
                bgColor="#DFF5E0"
                iconColor="#2CBA2E"
                oneLine
              />
            }

          </div>
        }
      </div>
    </div>

  );
};

export default MortgageCalc;
