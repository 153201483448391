import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './submenu.scss';

const SubMenuItem = (props) => {
  const { className, link, modal, external = false, showModal, children } = props;

  const itemClass = classNames('sub__item', className);

  return (

    <li className={itemClass}>
      {
        link.includes('https://panpartner.ru/') && !link.includes('pan-comfort') ?
        (
          <Link to={link.replace('https://panpartner.ru', '')} className="sub__link">
          {children}
        </Link>
        ) : 
        (
          <a href={link} target="_blank" className="sub__link">
            {children}
          </a>
        )

      }
  </li>
  );
};

SubMenuItem.propTypes = {
  link: PropTypes.string,
  className: PropTypes.array,
};

const SubMenu = (props) => {
  const { menu, showModal, openSub } = props;

  return (
    <div className={`sub${openSub ? ` show` : ``}`}>
      <ul className="sub__grid">
        {menu.map((item) => (
          <SubMenuItem {...item} key={`$submenu-${item.id}`} showModal={showModal}>
            <span className="sub__title">{item.title}</span>
            {/* <span className="sub__text">{item.subtitle}</span> */}
          </SubMenuItem>
        ))}
      </ul>
    </div>
  );
};

SubMenu.propTypes = {
  menu: PropTypes.array,
};



export default SubMenu;