import { useMediaQuery } from 'beautiful-react-hooks';
import without from 'lodash/without';
import React, { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import useMedia from '../../hooks/useMedia';
import {
  filterToParams,
  isEmptyFilterOrExclude
} from '../../utils';
import {
  defaultFullFilter
} from '../../utils/defaultFilters';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Dragable from '../FilterRealty/Dragable';
import EndingsFilter from '../FilterRealty/EndingsFilter';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';
import SaveFilter from '../SavedFilters/SendFilter';

var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    getCount,
    clearFilterOnly,
    clearExcludeOnly,
    city,
    fetching,
    info,
    countToShow,
    isFiltersOpen
  } = props;
  const isMobile = useMedia("isMobile")
  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };
    clearTimeout(inDebounce);
    let params = filterToParams(tmpFilter);
    inDebounce = setTimeout(() => {
      getCount(params);
    }, 300);
  };

  const smallScreen = useMediaQuery('(max-width: 1280px)');
  const middleScreen = useMediaQuery('(min-width: 1280px) and (max-width: 1550px)');
  const handleClearFilter = (e) => {
    e.preventDefault();
    getCount(filterToParams(defaultFullFilter));
    clearFilter();
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    let params = filterToParams(filter);
    onSubmit(params);
  };

  const handleToggle = (e) => {
    let tempFilter = [...filter.filter.status];
    if (e.target.checked) {
      tempFilter.push('3');
    } else {
      tempFilter = without(tempFilter, '3');
    }
    setFilter({
      filter: { status: tempFilter },
    });
    let tmpFullFilter = {...filter}
    tmpFullFilter.filter.status = tempFilter
    let params = filterToParams(tmpFullFilter);
    getCount(params);
  };

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSaveFilterModal} classes="modal_filter">
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
          filterType={"commercial"}
        />
      </Modal>
    ),
    [filter, filterData],
  );

  const [saveMobileFilterModal, hideMobileSaveFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal title="Сохранить фильтр" closeClick={hideMobileSaveFilterModal}>
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
          filterType={"commercial"}
        />
      </MobileModal>
    ),
    [filter, filterData],
  );

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    if(isMobile) document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  useEffect(() => {
    if (isFiltersOpen) setIsOpen(!isOpen);
  }, [isFiltersOpen]);

  const [count, setCount] = useState(0);

  const countFilters = (filtersCount) => {
    setCount(filtersCount)
  }
  
  useEffect(() => {
    props.countFilters && props.countFilters(count)
  }, [count]);

  const filters = (
    <div className="section__grid-col index-search__filter">
      <form className="form">
        <div className="index-search__filter-body">
          <div className="index-search__filter-top">
            <div className="form__row">
              {/* <div className="form__item form__item_2x form__item_main">
                    <div className="index-search__search">
                            <label className="input index-search__search-input" htmlFor="index-search">
                              <input className="input__control" type="text" id="index-search" placeholder="Поиск по 1 234 вариантам жилья" autocomplete="off">
                                      <svg className="icon icon_search">
                                        <use xlinkHref="#search"></use>
                                      </svg>
                              <div className="index-search__tooltip">
                                <button className="button button_view_outline-small">Исключить</button>
                              </div>
                            </label>
                              <div className="info">
                                        <svg className="icon icon_info">
                                          <use xlinkHref="#info"></use>
                                        </svg>
                                <div className="info__popup">
                                  <p>Начинайте вводить параметры фильтра и&nbsp;выбирайте из&nbsp;предложенного списка. Вы&nbsp;также можете любой запрос добавить в&nbsp;исключения</p>
                                </div>
                              </div>
                    </div>
                  </div> */}

              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'districts')
                }
                isExcludable={true}
                isAutocomplete={true}
                items={
                  filterData.districts
                    ? Object.values(filterData.districts)
                    : []
                }
                filterValues={filter.filter.districts}
                excludeValues={filter.exclude.districts}
                data={{
                  title: 'Район',
                  autocompleteTitle: 'Введите название района',
                  identity: 'districts',
                }}

      					isMobile={isOpen && isMobile}
      					clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'subways')
                }
                isExcludable={true}
                isAutocomplete={true}
                items={
                  filterData.subways
                    ? Object.values(filterData.subways)
                    : []
                }
                filterValues={filter.filter.subways}
                excludeValues={filter.exclude.subways}
                data={{
                  title: 'Метро',
                  autocompleteTitle: 'Введите название станции',
                  identity: 'subways',
                }}

                isMobile={isOpen && isMobile}
      					clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'builders')
                }
                isExcludable={true}
                isAutocomplete={true}
                items={
                  filterData.builders
                    ? Object.values(filterData.builders)
                    : []
                }
                filterValues={filter.filter.builders}
                excludeValues={filter.exclude.builders}
                data={{
                  title: 'Застройщик',
                  autocompleteTitle: 'Введите название застройщика',
                  identity: 'builders',
                }}
                right={!smallScreen}

                isMobile={isOpen && isMobile}
      					clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <EndingsFilter
                ident={"endings"}
                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}

                right={smallScreen}
                filter={
                  filter.filter.endings
                    ? filter.filter.endings
                    : { min: '', max: '' }
                }
                items={filterData.endings ? filterData.endings.items : []}
                data={{
                  title: 'Срок сдачи',
                  minPlaceholder: 'от ',
                  maxPlaceholder: 'до ',
                  defaultValues: filterData.endings
                    ? filterData.endings.ranges
                    : null,
                }}
                onChange={(a) => handleFilter(a, 'endings')}
                secondData={{
                  title: "Сданные дома",
                  value: filter.filter.isOnlyHanded.length? filter.filter.isOnlyHanded[0].id : "N"
                }}
                secondChange={(a) =>{
                  let tmp = a === "Y" ? [{ id: a }]: []
                  handleFilter(
                    {
                      filter: tmp,
                      exclude: [
                        ...filter.exclude.isOnlyHanded.filter((val) => val.id != a)
                      ],
                    },
                    "isOnlyHanded"
                  )
                }

                }
              />
              <SquareFilter
                filterSecond={filter.filter.squareTotal}
                dataSecond={{
                  title: 'S',
                  minPlaceholder: 'S общ. от ',
                  maxPlaceholder: 'S общ. до ',
                  defaultValues: filterData.squareTotal
                    ? filterData.squareTotal.range
                    : null,
                }}
                alternativeTitle="Площадь"
                onChange={(a, identity) => handleFilter(a, identity)}
                isArea={true}
                isAreaOnly={true}

                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <PriceFilter
                  filter={
                    filter.filter.prices
                      ? filter.filter.prices
                      : { min: "", max: "" }
                  }
                  exclude={
                    filter.exclude.prices
                      ? filter.exclude.prices
                      : { min: "", max: "" }
                  }
                  items={
                    filterData.prices ? filterData.prices.items : []
                  }
                  data={{
                    title: "Цена",
                    minPlaceholder: "от ",
                    maxPlaceholder: "до ",
                    defaultValues: filterData.prices
                      ? filterData.prices.range
                      : 0,
                  }}
                  right={middleScreen}
                  onChange={(a) => handleFilter(a, "prices")}

                  isMobile={isOpen && isMobile}
                  clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExcludeOnly}
                  fullFilter={filter}
                />
            </div>
          </div>
          <div className="index-search__filter-main">
            <div className="form__row">
              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'blocks')
                }
                isExcludable={true}
                isAutocomplete={true}
                items={
                  filterData.blocks
                    ? Object.values(filterData.blocks)
                    : []
                }
                filterValues={filter.filter.blocks}
                excludeValues={filter.exclude.blocks}
                data={{
                  title: 'Название ЖК',
                  autocompleteTitle: 'Введите название ЖК',
                  identity: 'blocks',
                }}

                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <FloorsFilter
                items={
                  filterData.floors?.items ? filterData.floors.items : []
                }
                filter={filter.filter.floor}
                data={{
                  title: 'Этаж',
                  minPlaceholder: 'от ',
                  maxPlaceholder: 'до ',
                  defaultValues: filterData.floors
                    ? filterData.floors.range
                    : null,
                }}
                onChange={(a) => handleFilter(a, 'floor')}
                secondData={{
                  title: filterData.isFirstFloor
                    ? filterData.isFirstFloor[0].title
                    : 'Только первый',
                  value: filter.filter.isFirstFloor?.length
                    ? filter.filter.isFirstFloor[0].id
                    : 'N',
                }}
                secondChange={(a) => {
                  let tmp = a === 'Y' ? [{ id: a }] : [];
                  handleFilter(
                    {
                      filter: tmp,
                      exclude: [
                        ...filter.exclude.isFirstFloor.filter((val) => val.id != a)
                      ],
                    },
                    'isFirstFloor',
                  );
                }}
                ident="floor"
                right={smallScreen}

                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'decoration')
                }
                isExcludable={true}
                isAutocomplete={false}
                items={
                  filterData.decoration
                    ? Object.values(filterData.decoration)
                    : []
                }
                filterValues={filter.filter.decoration}
                excludeValues={filter.exclude.decoration}
                data={{
                  title: 'Отделка',
                  autocompleteTitle: '',
                  identity: 'decoration',
                }}

                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
              <SelectFilter
                onChange={(a, b) =>
                  handleFilter({ filter: a, exclude: b }, 'payment')
                }
                isExcludable={true}
                isAutocomplete={false}
                items={
                  filterData.payment
                    ? Object.values(filterData.payment)
                    : []
                }
                filterValues={filter.filter.payment}
                excludeValues={filter.exclude.payment}
                data={{
                  title: 'Способ оплаты',
                  autocompleteTitle: '',
                  identity: 'payment',
                }}

                isMobile={isOpen && isMobile}
                clearFilterOnly={clearFilter}
                clearExcludeOnly={clearExcludeOnly}
                fullFilter={filter}
              />
               <div className="form__row" >
                <div className="index-search__show-booked">
                  <label className="switch" htmlFor="filter-booked">
                    <div className="switch__box">
                      <input
                        className="switch__control"
                        type="checkbox"
                        id="filter-booked"
                        checked={filter.filter.status.includes("3")}
                        onChange={handleToggle}
                      />
                      <div
                        className="switch__input"
                        htmlFor="filter-booked"
                      ></div>
                      <div className="switch__marker"></div>
                    </div>
                    <span className="switch__label">
                      Показать забронированные
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {filter && filterData && !isEmptyFilterOrExclude(filter) && (
          <Dragable
            filter={filter.filter}
            fullFilter={filter}
            exclude={filter.exclude}
            filterData={filterData}
            handleFilter={handleFilter}
            clearExcludeOnly={clearExcludeOnly}
            clearFilterOnly={clearFilterOnly}
            getCount={(filterOrExclude) =>
              getCount(filterToParams(filterOrExclude))
            }
            enableRemoving={true}
            countFilters={countFilters}
          />
        )}
        <div className="index-search__filter-controls">
          {/* <div className="index-search__filter-control index-search__filter-voice">
            <button className="button button_view_round">
              <svg className="icon icon_mic">
                <use xlinkHref="#mic"></use>
              </svg>
            </button>
            <span>Голосовой помощник</span>
          </div> */}
          <div className="index-search__filter-control index-search__filter-options">
            <button
              className="button button_view_outline"
              onClick={(e) => {
                e.preventDefault();
                isMobile ? saveMobileFilterModal() : saveFilterModal();
              }}
            >
              Сохранить фильтр
            </button>
            {/* <button className="button button_type_settings">
                <svg className="icon icon_settings">
                  <use xlinkHref="#settings"></use>
                </svg>
              </button> */}
          </div>
          <button
            onClick={handleClearFilter}
            className="button button_type_clear visible_mobile"
          >
            <svg className="icon icon_x">
              <use xlinkHref="#x"></use>
            </svg>
            <span>Очистить фильтр</span>
          </button>
          <a
            className="index-search__filter-control index-search__filter-submit"
            href="#filter-results"
          >
            {countToShow ? (
              <button
                className="button button_view_default"
                type="submit"
                onClick={handleFilterClick}
                disabled={fetching ? true : false}
              >
                Найдено{` ${countToShow} объектов`}
              </button>
            ) : (
              <button
                className="button button_view_default"
                type="submit"
                disabled
              >
                Ничего не найдено
              </button>
            )}
          </a>
          <div className="index-search__filter-control index-search__filter-options">
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_note"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
          </div>
        </div>
        <div className="index-search__filter-controls-m">
          <div className="index-search__filter-controls-col">
            {/* <div className="index-search__filter-control index-search__filter-voice">
              <button className="button button_view_round">
                <svg className="icon icon_mic">
                  <use xlinkHref="#mic"></use>
                </svg>
              </button>
            </div> */}
            {!isMobile &&
              <button
                onClick={handleClearFilter}
                className="button button_type_clear visible_mobile"
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            }
          </div>
          <div className={`index-search__filter-controls-col ${isMobile ? 'mobile-filters__show-all' : ''}`}>
            <div className="index-search__filter-control index-search__filter-submit">
              {countToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={(e) => {
                    handleFilterClick(e)
                    toggleFilters()
                  }}
                  disabled={fetching ? true : false}
                >
                  Найдено{` ${countToShow} объектов`}
                </button>
              ) : (
                <button
                  className="button button_view_default"
                  type="submit"
                  disabled
                >
                  Ничего не найдено
                </button>
              )}
            </div>
          </div>
          <div className="index-search__filter-controls-col">
            <div className="index-search__filter-control index-search__filter-options">
              <button
                onClick={handleClearFilter}
                className="button button_type_clear visible_note"
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
              <div className="index-search__filter-save">
                <button
                  className="button button_view_outline"
                  onClick={(e) => {
                    e.preventDefault();
                    isMobile ? saveMobileFilterModal() : saveFilterModal();
                  }}
                >
                  Сохранить фильтр
                </button>
                {/* <button className="button button_type_settings">
                  <svg className="icon icon_settings">
                    <use xlinkHref="#settings"></use>
                  </svg>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )



  return (
    <section className="section index-search index-search_dark">
      <div className="wrapper">
        <div className="section__grid">
          <div className="section__grid-col index-search__header">
            {isMobile ?
              <h1>Коммерческая недвижимость</h1> :
              <h1 className="h2" style={{ whiteSpace: 'pre-wrap' }}>
                Коммерческая недвижимость
              </h1>
            }
          </div>

          {isMobile ?
            <>
              <button
                className="button-mobile for-commercial"
                onClick={() => {
                  toggleFilters()
                }}
              >
                Фильтры
                {count > 0 && ` (${count})`}
              </button>

              <a
                className="index-search__filter-control index-search__filter-submit"
                href="#filter-results"
                style={{
                  flexBasis: '100%',
                }}
              >
                {countToShow ? (
                  <button
                    className="button button_view_default"
                    type="submit"
                    onClick={handleFilterClick}
                    disabled={fetching ? true : false}
                    style={{
                      borderRadius: '5px',
                      marginTop: '10px',
                      width: 'calc(100% - 20px)',
                    }}
                  >
                    Показать
                  </button>
                ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled

                    style={{
                      borderRadius: '5px',
                      marginTop: '10px',
                      width: 'calc(100% - 20px)',
                    }}
                  >
                    Ничего не найдено
                  </button>
                )}
              </a>
            </> :

            <>
              {filters}
            </>
          }

          {isOpen && isMobile &&
    				<div className="mobile-filters for-commercial">
    					<div className="mobile-filters__title is-fixed">
    						<svg
    							className="icon icon_arrow_up"
    							onClick={() => {
    								toggleFilters()
                    props.closeClick()
    							}}
    						>
    							<use xlinkHref="#arrow_up"></use>
    						</svg>
    						<h3>Все фильтры</h3>
    					</div>
    					<div className="mobile-filters__content">
    						{filters}
    					</div>
    				</div>
    			}

        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  city: ownProps.city ? ownProps.city : state.user.info.city,
  countApartments: state.estate.countApartments,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearFilter: () => dispatch({ type: 'COMMERCIAL_CLEAR_FILTER' }),
    clearFilterOnly: () => dispatch({ type: 'COMMERCIAL_CLEAR_FILTER_ONLY' }),
    clearExcludeOnly: () => dispatch({ type: 'COMMERCIAL_CLEAR_EXCLUDE_ONLY' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
