import React, { useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router';
import { getAgents, getEvent, subscribeOnEventReq, unsubscribeOnEventReq } from '../../actions/event';
import { getUrl } from '../../api';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import Preloader from '../../components/Preloader';
import { errorToast, standartErrorToast, standartSuccessToast } from '../../components/toasts';
import useMedia from "../../hooks/useMedia";
import usePermission from '../../hooks/usePermission';
import { months, parseDate } from '../../utils';
import './index.scss';
import PageSign from './PageSign';
import {Link} from "react-router-dom";
// import vebinarstub from "../../images/vebinarstub.png";
const SVG_TEMPLATE = `<svg width="25" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 40S25 24.873 25 13.044C25 5.839 19.403 0 12.5 0 5.596 0 0 5.84 0 13.043 0 25.155 12.5 40 12.5 40zm5.682-26.25c0-3.451-2.545-6.25-5.682-6.25-3.137 0-5.682 2.799-5.682 6.25S9.363 20 12.5 20c3.137 0 5.682-2.799 5.682-6.25z" fill="#3F3F57"/></svg>`

const PageEvents = () => {  
  const { id } = useParams();
  const isLaptop = useMedia("isLaptop");
  const isMobile = useMedia("isMobile");
  const isMaster = usePermission("agent");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState(null)
  const [isMainStream, setIsMainStream] = useState(false)
  const [user, setUser] = useState(null)
  const [manager, setManager] = useState(null)
  const {
    address,
    availableCount,
    availableReserveCount,
    closed,
    coordinates,
    date,
    detailText,
    endDateRecording,
    name,
    recordLimit,
    recordLimitReserve,
    speaker,
    speakerPost = "",
    subscribedAgents,
    eventType,
    speakerPhoto,
    isWebinar,
    telegramLink,
    video
  } = data
  useEffect(() => {
    if (isMaster) getAgents("spb").then(
      respData => {
        const data = respData.agents.map((item) => {
          let title = `${item.name}${item.lastName ? ` ${item.lastName}` : ``
          }`;
          return { id: item.id, title };
        })
        setAgents(data)
      });
    getEvent({ id, city: "spb" }).then(resp => {
      setData(resp.event)
      setIsMainStream(resp.isMainStream)
      setUser(resp.agent)
      setManager(resp.manager)
    })
      .finally(() => setIsLoading(false))
  }, [])

  const isSubscribed = useMemo(() => {
    if (!subscribedAgents || !user) return
    return !!subscribedAgents.find(agent => agent.id == user.id);
  }, [user, subscribedAgents])
  const evDate = useMemo(() => {
    if (!date) return ""
    const {
      days,
      month,
      formattedTime
    } = parseDate(date)

    return `${days} ${months[month]}, ${formattedTime}`
  }, [date])
  const unsubscribe = (agentId, comment) => {
    const form = { eventId: id, agentId, comment }
    return unsubscribeOnEventReq(form).then(data => {
        setData(data.event)
        standartSuccessToast(data)
      },
      standartErrorToast)
  }

  const subscribe = agentId => {
    const form = { eventId: id, agentId, city: "spb" }
    return subscribeOnEventReq(form).then(data => {
        setData(data.event)
        standartSuccessToast(data)
        if(data.warning){
          errorToast(data.warning)
        }
      },
      standartErrorToast)
  }

  const [Maps, setMaps] = useState(null)
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({ YMaps, Map, Placemark, RulerControl })
  };
  useEffect(() => dynamicMapImport(), [])


  const [openMapModal, closeMapModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeMapModal} title="" >{children}</MobileModal>
        : <Modal close={closeMapModal} classes="modal__event_only">{children}</Modal>
      } >
        <div className="dist-map" id="dist-map-container">
          {Maps && <Maps.YMaps>
            <Maps.Map
              defaultState={{
                center: coordinates,
                zoom: 17,
              }}
              modules={['templateLayoutFactory', 'Template', "Placemark"]}
              onLoad={setYmaps}
              instanceRef={setMapInstance}
              className="complex__map-wrap"

            >
            </Maps.Map>
          </Maps.YMaps>}
        </div>
      </Wrap >
    ), [Maps, coordinates]);

  useEffect(() => {
    if (!mapInstance || !ymaps || !coordinates) return
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(SVG_TEMPLATE)
    const placeMaprk = new ymaps.Placemark(coordinates, {
    }, {
      iconLayout: placemarkTemplate,
      iconOffset: [-12.5, -40],
    });
    mapInstance.geoObjects
      .add(placeMaprk);
  }, [mapInstance, ymaps, coordinates])

  if (isLoading) return <Preloader loading={true} />
  console.log(video)
  return (
    <section className="section section_view_gray">
      <div className="wrapper">
        <section className="pe">
          <div className="pe__left">
            <aside className="pe__info">
              <div className="pe__mobile-info">
                {eventType && <div className={`label-item label_id`}>{eventType}</div>}
                <h2 className="pe__main-h2">{name}</h2>
              </div>
              <div className="pe__manager">
                {speakerPhoto && <img
                  src={getUrl(speakerPhoto?.src)} alt="" className="pe__manager-img"
                  onError={e=>{
                    e.target.onerror = null;
                    e.target.src = "https://panpartner.ru/upload/upload/sites/015/015d6b36401d57e227672e483a539d1c.svg"
                  }}
                />}
                <p className="pe__manager-name h4">{speaker}</p>
                <p className="pe__manager-pos text color-brand-3">
                  {speakerPost}
                </p>
              </div>

              <div className="pe__date">
                <div className="pe__date-block">
                  <p className="pe__date-key text color-brand-3">Дата и время проведения</p>
                  <p className="pe__date-value h4">{evDate}</p>
                </div>
                <div className="pe__date-block">
                  <p className="pe__date-key text color-brand-3">Адрес проведения</p>
                  <p className="pe__date-value h4">{address}</p>
                  {coordinates && !isWebinar && <button className="pe__date-map button link-red" type="button" onClick={openMapModal}>На карте</button>}
                </div>
              </div>
            </aside>
            {isLaptop && <PageSign
              date={endDateRecording}
              availableCount={availableCount}
              recordLimit={recordLimit}
              subscribedAgents={subscribedAgents}
              isMainStream={isMainStream}
              isMaster={isMaster}
              availableReserveCount={availableReserveCount}
              eventId={id}
              closed={closed}
              agents={agents}
              isSubscribed={isSubscribed}
              eventTime={evDate}
              name={name}
              user={user}
              unsubscribe={unsubscribe}
              subscribe={subscribe}
              manager={manager}
              isWebinar={isWebinar}
            />}
          </div>

          <main className="pe__main">
            <div className="pe__main-box">
              {eventType && <div className={`label-item label_id`}>{eventType}</div>}

              <div className="pe__main-content">
                <h2 className={`pe__main-h2 ${telegramLink ? `pe__main-h2_small-margin` : ``}`}>{name}</h2>

                {
                  telegramLink &&
                  (
                    <div className="pe__sign-content pe__sign-content_white_and_border">
                      <img src={require('../../assets/images/pe/warning.svg').default} alt="" className="pe__inactive-warning" />
                      <div className="pe__sign-box">
                        <p className="bold pe__inactive-title">
                          Уважаемые партнеры! Все участники данного мероприятия для подтверждения участия в нем обязаны вступить в телеграм группу.
                        </p>
                        <p className="pe__inactive-text">
                          Обратите внимание, что список участников экскурсии формируется только после подтверждения участия в группе.
                        </p>
                        <a className="button button_view_default" href={telegramLink} target="_blank" onClick={() => subscribe(user.id)}>Вступить</a>
                      </div>
                    </div>
                  )

                }

                <div
                  dangerouslySetInnerHTML={{
                    __html: detailText,
                  }}
                />
              </div>
            </div>

            <div className="pe__banner">
              <img src={require('../../assets/images/pe/bot.svg').default} alt="" className="pe__banner-img" />
              <div className="pe__banner-box">
                <h5>Подключайтесь к нашему телеграм-боту.</h5>
                <p>Он напомнит о Вашей записи на мероприятие, а еще в нем можно подтвердить или отменить свое участие</p>
                <a href="https://t.me/PanPartner_ReloadBot" className="pe__banner-btn button button_view_default" target="_blank">Подключить</a>
              </div>
            </div>

          </main>

          {!isLaptop &&
            <div>
              <PageSign
                date={endDateRecording}
                availableCount={availableCount}
                recordLimit={recordLimit}
                subscribedAgents={subscribedAgents}
                isMainStream={isMainStream}
                isMaster={isMaster}
                availableReserveCount={availableReserveCount}
                eventId={id}
                agents={agents}
                closed={closed}
                isSubscribed={isSubscribed}
                eventTime={evDate}
                name={name}
                user={user}
                unsubscribe={unsubscribe}
                subscribe={subscribe}
                manager={manager}
                isWebinar={isWebinar}
              />

              {
                video &&
                <div className="pe-vebinars" style={video.length === 1 ? {backgroundColor: '#2c2e3e'} : {}}>
                  <div className="new-events__videolist">
                    <div className="new-events__videolist-header">
                      <div className="new-events__videolist-title h5-l" style={video.length === 1 ? {color: 'white'} : {}}>{video.length === 1 ? 'Запись вебинара' : 'Записи вебинаров'}</div>
                    </div>
                    <div className="new-events__vebinars">
                      {
                        video.map((i) => {
                          return (
                            <Link className="new-events__vebinar-item" style={video.length === 1 ? {border: '1px solid transparent'} : {}} to={'/videos/' + i.id} target="_blank">
                              <div className="new-events__vebinar-img">
                                <img src={getUrl(i?.photo)} alt=""/>
                                <div className="new-events__vebinar-time">{i?.duration}</div>
                              </div>
                              {video.length !== 1 &&
                                <div className="new-events__vebinar-info">
                                  <div className="new-events__vebinar-date">{new Date(i?.activeFrom).toLocaleDateString('ru-RU', { day: '2-digit', month: 'short', year: 'numeric' })}</div>
                                  <div className="new-events__vebinar-title">
                                    {i?.title}
                                  </div>
                                </div>
                              }
                            </Link>
                          )
                        })
                      }
                    </div>
                    <div className="new-events__videolist-link">
                      <Link to='/videos' target="_blank" style={video.length === 1 ? {color: 'white'} : {}}>
                        Смотреть все
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.4758 15.325L17.5234 12.175" stroke={video.length === 1 ? "white" : "#8F95AC"} stroke-linecap="round"/>
                          <path d="M17.5242 11.825L14.4766 8.675" stroke={video.length === 1 ? "white" : "#8F95AC"} stroke-linecap="round"/>
                          <path d="M17.7143 12L6.5 12" stroke={video.length === 1 ? "white" : "#8F95AC"} stroke-linecap="round"/>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>

              }



            </div>

          }
        </section>
      </div>
    </section>
  );
};


export default PageEvents;
