import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../../hooks/useDropdown';
import { media } from '../../../utils';

const DropdownBordered = ({ value, options, onChange, borderColor }) => {
  const [node, open, show, hide] = useDropdown(false);
  const [current, setCurrent] = useState(null);

  const handleChange = (selectedValue) => {
    onChange(options.find((item) => item.id
      ? (item.id === selectedValue)
      : (item.cityCode === selectedValue)
    ));
    hide();
  };

  useEffect(() => {
    let m = false;
    if (value) {
      m = options.find((item) => item.id ? (item.id === value) : (item.cityCode === value));
    }
    setCurrent(m || options[0]);
  }, [value, options]);
  return (
    <div ref={node} className={`custom-select ${open ? `is-showing` : ``}`} style={{ maxWidth: !media('isMobile') && "fit-content" }}>
      {current && (
        <>
          <div
            className="custom-select__selected"
            onClick={(e) => (open ? hide() : show())}
            style={{
              border: `1px solid ${borderColor}`,
              backgroundColor: "white",
              borderRadius: 8
            }}
          >
            <span className={`label-item label_${current.color}`}>
              {current.name ? current.name : current.title}
            </span>
          </div>
          <SimpleBar className="custom-select__options">
            {options.map((item, i) => (
              <li
                className={
                  'custom-select__option' +
                  (current.id === item.id ? ' is-active' : '')
                }
                key={`drop-option-${i}`}
                onClick={() => handleChange(item.id ? item.id : item.cityCode)}
              >
                <span className={`label-item label_${item.color}`}>
                  {item.name ? item.name : item.title}
                </span>
              </li>
            ))}
          </SimpleBar>
        </>
      )}
    </div>
  );
};

export default DropdownBordered;
