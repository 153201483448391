import React from "react";
import { ReactComponent as Ellipse } from "../../../images/svg/white-ellipse.svg";
import { ReactComponent as GreenEllipse } from "../../../images/svg/green-ellipse.svg";
import { media } from '../../../utils';

const BgRight3 = (props) => {
  return (
    <div className="background__right">
        <svg viewBox="0 0 1303 813" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${media('isMobile') ? "background__right_mobile_green" : ''} "background__right_green"`}>
          <g filter="url(#filter1_d)">
          <ellipse cx="969.714" cy="430.274" rx="370.447" ry="265.356" transform="rotate(-29.8028 969.714 430.274)"  className="background__fill-main"/>
          </g>

          <defs>
            <filter id="filter1_d" x="476.375" y="-14.1038" width="986.676" height="908.756" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="10"/>
              <feGaussianBlur stdDeviation="20"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.579288 0 0 0 0 0.523998 0 0 0 0 0.800449 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
          </defs>
        </svg>

      {media('isMobile') &&
        <>
          <Ellipse className="background__right_white"/>
        </>
      }
    </div>
  )
};

export default BgRight3;
