import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getSpecialOffers } from "../actions/suburbans.js";
import MobileModal from '../components/core/MobileModal';
import Modal from "../components/core/Modal";
import Wrap from '../components/core/ModalWrap';
import SpecialForm from "../components/forms/Special.js";
import Preloader from "../components/Preloader.js";
import DetailCard from "../components/Special/DetailCard.js";
import DetailHeader from "../components/Special/DetailHeader.js";
import '../components/Special/index.scss';
import SpecialModal from "../components/Special/SpecialModal.js";
import { standartErrorToast } from "../components/toasts.js";
import useApi from "../hooks/useApi.js";
import useMedia from "../hooks/useMedia.js";
import DetailCardNew from "../components/Special/DetailCardNew";
import { renameObjectKeys } from "../utils/index.js";

const keyMap = {
  descriptionHouse: "detailText",
  priceBase: "price",
  squareHouse: "squareTotal",
  materialHouse: "section",
  constructionPeriod: "constructionPeriod",
  countFloors: "floors",
  countBedrooms: "countBedrooms",
  countBathrooms: "countBathrooms",
  photo: "photos",
  plan: "planPhotos",
  responsible: "manager",
};

const SpecialDetail = props => {
  const { id, savedFilterXmlId } = useParams()
  const isMobile = useMedia("isMobile");
  const [isShowRoom, setIsShowRoom] = useState(false);
  const [specialModal, setSpecialModal] = useState(false);
  const [specialModalData, setSpecialModalData] = useState({});

  const user = useSelector(state => state.user?.info)
  const isPublic = !!savedFilterXmlId;

  const {
    data: { suburban, otherProjects, objectInfo, priceInfo, dateUpdateInfo, agent },
    isLoading: loading,
    refresher,
    isRefreshing,
    loadMore,
    moreIsLoading,
    inFavorite,
    isFavoriteLoading,
    toggleFavorite
  } = useApi({
    payload: ["panpartner:new.suburbans.ajax", "getSuburban", { id, savedFilterXmlId }],
    // payload: ["panpartner:suburban.special.offers.ajax", "getContractConstruction", {id}],
    favoritesParams: {
      id,
      type: 'contractConstruction', //
    },
    favoritePath: 'suburban.favorite',
  }, [id])

  const openSpecialModal = (houseName, manager) => {
    setSpecialModalData({ houseName, manager })
    setSpecialModal(true)
  }

  const [openSpecialModalForm, hideSpecialModalForm] = useModal(
    ({ in: open, onExited }) => (

      <Wrap wrapper={children => isMobile
        ? <MobileModal title={isShowRoom ? `Записаться в шоу-рум` : `Получить консультацию`} closeClick={hideSpecialModalForm} className="special-form-modal">{children}</MobileModal>
        : <Modal close={() => {
          hideSpecialModalForm();
          setIsShowRoom(false);
        }}>{children}</Modal>
      } >
        <SpecialForm close={() => {
          hideSpecialModalForm();
          setIsShowRoom(false);
        }} specialOfferId={id} isShowRoom={isShowRoom} setIsShowRoom={setIsShowRoom} />
      </Wrap >
    ), [id, isShowRoom]
  );

  return (
    <>
      <div className="wrapper">
        {suburban && <div className="special__detail-wrapper">
          <div className="special__detail-container">
            <DetailCardNew
              {...renameObjectKeys(suburban, keyMap)}
              dateUpdateInfo={dateUpdateInfo}
              objectInfo={objectInfo}
              priceInfo={priceInfo}
              openSpecialModal={openSpecialModal}
              semiObjects={otherProjects}
              inFavorite={inFavorite}
              isFavoriteLoading={isFavoriteLoading}
              toggleFavorite={toggleFavorite}
              hidePrice
              manager={agent}
            />
          </div>
        </div>}
      </div>
      <SpecialModal name={user?.name} setSpecialModal={setSpecialModal} specialModal={specialModal} {...specialModalData} sendReq={openSpecialModalForm} />
      <Preloader loading={loading} />
    </>
  );
};


export default SpecialDetail;
