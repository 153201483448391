import React from 'react';
import Mortgage from '../components/Constructor/InnerPage/Mortgage/Mortgage';
import '../components/Constructor/index.scss';



const ConstructorMortgage = ({setFullScreenLoading}) => {
  return  <Mortgage setFullScreenLoading={setFullScreenLoading} />
};

export default ConstructorMortgage;
