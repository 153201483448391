import React, { memo } from 'react';
import useMedia from '../../../hooks/useMedia';
import RadioDropdown from '../../core/RadioDropdown';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SearchFilter from '../../FilterRealty/SearchFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import SubmitButton from '../../SubmitButton';
import FilterBtn from './FilterBtn';



const PrimaryComplexFilterForm = memo(props => {
  const {
    clearExcludeOnly,
    clearFilterOnly,
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude
  } = props
  return (
    <div className="sf__filter form">
      <SearchFilter
        placeholder="№ квартиры"
        handleChange={v => handleFilter('number', v)}
        value={filter.number ?? ""}
        wrapperClass="sf__filter-item"
      />
      <SelectFilter
        onChange={(filter, exclude) => setFilterAndExclude({ filter, exclude }, "buildings")}
        items={filterData.buildings ?? []}
        filterValues={filter.buildings}
        wrapperClass="sf__filter-item"
        data={{
          title: "Корпус",
          identity: "buildings",
        }}
      />

      <EndingsFilter
        autoPosition
        ident={"endings"}
        filter={filter.endings ?? { min: "", max: "" }}
        items={filterData.endings?.items ?? []}
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.endings?.ranges ?? null,
        }}
        wrapperClass="sf__filter-item"
        onChange={v => setFilterAndExclude(v, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.isOnlyHanded ?? "N"
        }}
        secondChange={v => handleFilter("isOnlyHanded", v === "Y" ? v : null)}
      />

      <EndingsFilter
        autoPosition
        filter={filter.issuingKeys ?? { min: "", max: "" }}
        items={filterData.issuingKeys?.items ?? []}
        wrapperClass="sf__filter-item"
        data={{
          title: "Выдача ключей",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.issuingKeys?.ranges ?? null,
        }}
        onChange={v => setFilterAndExclude(v, "issuingKeys")}
        ident={"issuingKeys"}
      />

      <SelectFilter
        autoPosition
        onChange={(filter, exclude) => setFilterAndExclude({ filter, exclude }, "rooms")}
        items={filterData.rooms ?? []}
        filterValues={filter.rooms}
        wrapperClass="sf__filter-item"
        data={{
          title: "К-во комнат",
          autocompleteTitle: "",
          identity: "rooms",
        }}
      />

      <PriceFilter
        autoPosition
        filter={filter.prices ?? { min: "", max: "" }}
        items={filterData.prices?.items ?? []}
        wrapperClass="sf__filter-item"
        data={{
          title: "Цена",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.prices?.range ?? 0,
        }}
        onChange={v => setFilterAndExclude(v, "prices")}
        debounced
      />

      <SelectFilter
        autoPosition
        onChange={(filter, exclude) => setFilterAndExclude({ filter, exclude }, "decoration")}
        items={filterData.decoration ?? []}
        wrapperClass="sf__filter-item"
        filterValues={filter.decoration}
        data={{
          title: "Отделка",
          identity: "decoration",
        }}
      />

      <SquareFilterOnly
        autoPosition
        wrapperClass="sf__filter-item"
        filter={filter.squareTotal ?? { min: "", max: "" }}
        data={{
          title: 'S общая',
          minPlaceholder: 'S общ. от ',
          maxPlaceholder: 'S общ. до ',
          defaultValues: filterData.squareTotal?.range ?? null
        }}
        onChange={({ filter }) => handleFilter('squareTotal', filter)}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareTotal"}
        items={filterData.squareTotal?.items}
        showPlaceholderOnLists
        debounced
      />
      {!!filterData.squareKitchen?.items?.length && <SquareFilterOnly
        autoPosition
        filter={filter.squareKitchen ?? { min: "", max: "" }}
        wrapperClass="sf__filter-item"
        data={{
          title: 'S кухни',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.squareKitchen?.range ?? null
        }}
        onChange={({ filter }) => handleFilter('squareKitchen', filter)}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareKitchen"}
        items={filterData.squareKitchen?.items}
        showPlaceholderOnLists
        debounced
      />}
      <SelectFilter
        autoPosition
        onChange={v => handleFilter('bathroom', v)}
        items={filterData.bathroom ?? []}
        wrapperClass="sf__filter-item"
        filterValues={filter.bathroom}
        data={{
          title: 'Санузел',
          identity: 'bathroom',
        }}
      />

      {filterData.cellHeight?.items?.length > 0 && <RadioDropdown
        autoPosition
        onChange={v => handleFilter("cellHeight", { "min": v },)}
        wrapperClass="sf__filter-item"
        items={filterData.cellHeight?.items ?
          filterData?.cellHeight?.items.map(item => ({ ...item, title: `от ${item.title} м.` })) :
          []}
        value={filter.cellHeight?.min ?? {}}
        alternativeTitle={"Высота потолков"}
        data={{
          title: "Высота потолков",
          identity: "cellHeight",
        }}
      />}

      <SelectFilter
        autoPosition
        onChange={v => handleFilter('balconyType', v)}
        items={filterData.balconyType ?? []}
        wrapperClass="sf__filter-item"
        filterValues={filter.balconyType}
        data={{
          title: "Балкон",
          identity: "balconyType",
        }}
        disabledItems={{
          w: ["b", "l", "t"],
          b: ["w"],
          l: ["w"],
          t: ["w"],
        }}
      />

      <FloorsFilter
        autoPosition
        items={filterData.floors?.items ?? []}
        filter={filter.floors ?? {}}
        wrapperClass="sf__filter-item"
        data={{
          title: 'Этаж',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.floors?.range ?? null,
        }}
        onChange={({ filter }) => handleFilter('floors', filter)}
        secondData={{
          title: filterData.isNoFirstFloor?.[0]?.title ?? 'Не первый',
          value: filter.isNoFirstFloor ?? 'N',
        }}
        secondChange={v => handleFilter('isNoFirstFloor', v === 'Y' ? v : "")}
      />

      <SelectFilter
        autoPosition
        onChange={v => handleFilter('payment', v)}
        items={filterData.payment ?? []}
        filterValues={filter.payment}
        wrapperClass="sf__filter-item"
        data={{
          title: 'Способы оплаты',
          identity: 'payment',
        }}
      />

      <SelectFilter
        autoPosition
        onChange={v => handleFilter('banks', v)}
        items={filterData.banks ?? []}
        filterValues={filter.banks}
        wrapperClass="sf__filter-item"
        data={{
          title: 'Банки',

          identity: 'banks',
        }}
      />
      <RadioDropdown
        autoPosition
        onChange={v => handleFilter('isApartments', v)}
        items={filterData.isApartments ?? []}
        wrapperClass="sf__filter-item"
        value={filter.isApartments ?? {}}
        alternativeTitle={'Апартаменты'}
        data={{
          title: 'Апартаменты',
          identity: 'isApartments',
        }}
      />
      <RadioDropdown
        autoPosition
        onChange={v => handleFilter('assignments', v)}
        items={filterData.assignments ?? []}
        wrapperClass="sf__filter-item"
        value={filter.assignments}
        alternativeTitle={'Переуступки'}
        data={{
          title: 'Переуступки',
          identity: 'assignments',
        }}
      />

        <RadioDropdown
          onChange={v => handleFilter("isTwoLevels", v)}
          items={filterData.isTwoLevels}
          value={filter.isTwoLevels}
          data={{
            title: 'Двухъярусная',
            identity: 'isTwoLevels',
          }}
          clearFilterOnly={clearFilterOnly}
          clearExcludeOnly={clearExcludeOnly}
          wrapperClass={'sf__filter-item'}
        />

        <RadioDropdown
          onChange={v => handleFilter("masterBedroom", v)}
          items={filterData.masterBedroom}
          value={filter.masterBedroom}
          data={{
            title: 'Мастер-спальня',
            identity: 'masterBedroom',
          }}
          clearFilterOnly={clearFilterOnly}
          clearExcludeOnly={clearExcludeOnly}
          wrapperClass={'sf__filter-item'}
        />
      <div className="form__row sf__filter-item_full">
        <div className="index-search__show-booked">
          <label className="switch" htmlFor="filter-booked">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                id="filter-booked"
                checked={filter.status?.includes?.("3")}
                onChange={e => handleFilter("status", "3")}
              />
              <div
                className="switch__input"
                htmlFor="filter-booked"
              ></div>
              <div className="switch__marker"></div>
            </div>
            <span className="switch__label">
              Показать забронированные
            </span>
          </label>
        </div>
      </div>
    </div>
  );
});

export default PrimaryComplexFilterForm;
