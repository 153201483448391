import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import EmptyTable from "../components/core/EmptyTable";

const AccessDeniedPage = ({ history, userInfo, setFullScreenLoading }) => {

  useEffect(() => {
    if (setFullScreenLoading) {
      setFullScreenLoading(false)
    }
  }, [])


  return <EmptyTable
    title="Доступ запрещен"
    link="/"
    button="На главную"
  />
};
const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default withRouter(connect(mapStateToProps, null)(AccessDeniedPage));
