import React, { useEffect, useState } from "react";
import { getUrl } from '../../../api';
import { runScriptsFromHTML, sendConstructorEvent } from "../../../utils";
import { Link } from 'react-router-dom';
const Reviews = (props) => {
  const {widgetReviews, reviews} = props
  const [reviewsSliced, setReviews] = useState(reviews.slice(0, 3))

  const [customHtml, setCustomHtml] = useState(null)

  useEffect(() => {
    if (widgetReviews){
      try {
        setCustomHtml(runScriptsFromHTML(widgetReviews))
      } catch (error) {
        
      }
    } 
  }, [widgetReviews])

  const getImage = (review) => {
    if (review.photo) {
      return review.photo.src ? getUrl(review.photo.src) : review.photo
    }
    return require('../../../assets/images/constructor/photo-upload.svg').default
  }
  if (!reviews?.length && !widgetReviews) {
    return ""
  }

  const showAllReviews = () => {
    setReviews(reviews)
    sendConstructorEvent("tr_all_reviews_view")
  }
  
  return (
    <section className="reviews constructor__section constructor__section_gray" ref={props.innerRef} id="reviews">
      <div className="constructor__container">
        <div className="constructor__wrapper reviews__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title advantages__title">
              Отзывы
            </h2>
          </div>

          {<ul className="reviews__list constructor__box">
            {reviewsSliced.map(review => {
              return (<li className="reviews__item" key={"review-" + review.id}>
                {/* <img
                  className="reviews__avatar"
                  src={getImage(review)}
                  alt=""
                /> */
                  // <div className="reviews__avatar"></div>
                }

                <div className="reviews__cloud">
                  <span className="reviews__name big-text">
                    {review.fio}
                  </span>
                  <p className="reviews__text text"
                    dangerouslySetInnerHTML={{
                      __html: review?.review?.replace(/\n/g, "<br />"),
                    }}
                  >
                  </p>
                </div>
              </li>)
            })}
          </ul>}
          {reviews.length > 3 && reviewsSliced.length === 3 && <Link to={'/reviews'} className="button constructor__btn constructor__btn_transparent">Показать все отзывы</Link>}
          {!!customHtml && <div style={{width: "100%", marginTop: !!reviews.length? 40 : 0 }} dangerouslySetInnerHTML={{__html: customHtml}} />}
        </div>

      </div>
    </section>
  )
};

export default Reviews;
