import take from "lodash/take";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import ym from 'react-yandex-metrika';
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import Aeropanorama from '../../assets/images/Aeropanorama.svg';
import T3DTour from '../../assets/images/T3DTour.svg';
import useMedia from '../../hooks/useMedia';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import ShowroomTooltip from '../ShowroomTooltip/index.js';
const PhotoPopup = (props) => {
  const { items, index = 0, open, onClose } = props;

  const options = useMemo(() => {
    return {
      index: index === 0 ? 0 : index - 1,
      closeOnScroll: false,
      closeOnVerticalDrag: false
    };
  }, [index]);
  const photos = useMemo(
    () =>
      items.map((item) => ({
        src: getUrl(item.src),
        w: item.width? item.width : 800,
        h: item.height? item.height : 600,
      })),
    [items]
  );
  return (
    <PhotoSwipe
      isOpen={open}
      items={photos}
      options={options}
      onClose={onClose}

    />
  );
};

const PhotoBlock = (props) => {
  const { photos, identity, aeroPanorama, current, tour } = props;
  const isMobile = useMedia("isMobile")
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(isMobile ? 1 : 0);
  const iframeRef = useRef(null)
  const navRef = useRef(null);
  const node = useRef(null);

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          controls: false,
          navContainer: `#complex-slider-thumbnails-${identity}`,
          navAsThumbnails: isMobile ? false : true,
        }
        : {},
    [navRef]
  );
  useEffect(() => {
    if (iframeRef && iframeRef.current && (current === "aeroPanorama" || current === "tour")) {
      iframeRef.current.src = iframeRef.current.src;
    }
  }, [current]);

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  return (
    <ErrorPlug wrappedComponent="src/comp-ts/Suburban/Photos.js">
      <>
        {current == "tour" && <ShowroomTooltip />}
        {identity != "aeroPanorama" && identity != "tour" ? (

          <div>
            <div
              class="complex__slider-wrapper gallery__wrapper"
              id="complex-slider-photos"
            >
              <TinySlider
                settings={settings}
                ref={node}
                onIndexChanged={handleChangeIndex}
              >
                {photos.map((photo, index) => (
                  <div
                    class="complex__slider-item gallery__item"
                    key={index}
                    onClick={() => handleOpen()}
                  >
                    <img src={getUrl(photo.src)} />
                  </div>
                ))}
              </TinySlider>
            </div>
            <div class="complex__slider-preview">
              <ul
                class="complex__slider-thumbnails"
                id={`complex-slider-thumbnails-${identity}`}
                ref={navRef}
              >
                {photos.map((photo, index) => (
                  <li class="complex__slider-thumbnails_item" key={index}>
                    {isMobile
                      ? <span aria-controls="base"></span>
                      : <img src={getUrl(photo.src)} />
                    }
                  </li>
                ))}
              </ul>
            </div>
            <ul
              class="complex__slider-controls"
              id={`complex-slider-controls-${identity}`}
            >
              <li class="prev" onClick={() => moveSlider("prev")}>
                <svg class="icon icon-white_stroke">
                  <use xlinkHref="#icon-left_arrow"></use>
                </svg>
              </li>
              <li class="next" onClick={() => moveSlider("next")}>
                <svg class="icon icon-white_stroke">
                  <use xlinkHref="#icon-right_arrow"></use>
                </svg>
              </li>
            </ul>
            <div class="complex__slider-magnifier" onClick={() => handleOpen()}>
              <svg class="icon icon_search">
                <use xlinkHref="#search"></use>
              </svg>
            </div>{" "}

            {isMobile &&
              <span class="complex__slider-info">
                {index} / {photos.length}
              </span>
            }
          </div>
        ) : (
            <iframe
              allow="true"
              loading="lazy"
              allowFullScreen={true}
              src={identity != "aeroPanorama" ? getUrl(tour) : `${aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`}
              width="100%"
              ref={iframeRef}
              {...(window.location.host.includes("toprealtors.ru") ? { referrerPolicy: "no-referrer" } : [])}
            ></iframe>
          )}
        <PhotoPopup
          open={open}
          items={photos}
          index={index}
          onClose={handleClose}
        />
      </>
    </ErrorPlug>
  );
};

const navTitles = {
  generalPlan: "Ген. план",
  complex: "Фотографии",
  decoration: "Отделка",
  interior: "Интерьер",
  aeroPanorama: "Панорама",
  tour: "Шоу-рум"
};

const Apartments = (props) => {
  const { id, city, photos, tour } = props;

  const [current, setCurrent] = useState("");
  const setCurrentTab = (identity) => {
    if (identity === "tour") {
      ym('reachGoal', 'showroom_view')
    }
    setCurrent(identity)
  }
  const navigation = useMemo(() => {
    let nav = [];
    ["complex", "generalPlan", "decoration", "interior", "aeroPanorama"].forEach((identity) => {
      if (identity === "aeroPanorama" && photos[identity].length > 0) {
        nav.push({
          identity: identity,
          type: "photos",
          title: navTitles[identity],
          icon: Aeropanorama
        });
        return
      }
      if (photos[identity] && photos[identity].length > 0) {
        nav.push({
          identity: identity,
          type: "photos",
          title: navTitles[identity],
        });
      }
    });
    if (tour && tour.length > 0) {
      nav.push({
        identity: "tour",
        type: "photos",
        title: navTitles["tour"],
        icon: T3DTour
      });
    }

    /*if (photos.aeroPanarama) {
      nav.push({
        identity: "aeroPanorama",
        type: "aeropanorama",
        title: navTitles.aeroPanorama,
      });
    }*/

    return nav;
  }, [photos]);

  useEffect(() => {
    if (navigation.length > 0) {
      setCurrentTab(navigation[0].identity);
    }
  }, [navigation]);
  return (
    <div class="complex__images">
      {navigation.length > 1 && (
        <ul className="complex__images-navs">
          {navigation.map((nav, index) => (
            <li key={`nav-item-${index}`}
              className={`complex__images-nav ${nav.identity === current ? `active` : ``
                }`}
              data-tab={nav.identity}
              onClick={() => setCurrentTab(nav.identity)}
              style={{ ...(!!nav.icon ? { paddingLeft: 10 } : []) }}
            >
              {!!nav.icon && <object type="image/svg+xml" data={nav.icon} style={{ marginRight: 3, maxWidth: 22 }} />}{nav.title}
            </li>
          ))}
        </ul>
      )}
      <div class="complex__slider gallery">
        {navigation.map((nav, i) => (
          <div
            className={`complex__slider-tab ${nav.identity === current ? `active` : ``
              }`}
            key={"nav-items-" + i}
          >
            <PhotoBlock photos={take(photos[nav.identity], 5)} aeroPanorama={photos.aeroPanorama} tour={tour} identity={nav.identity} current={current} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Apartments;
