import React, { Suspense, useEffect, useState, useCallback, memo } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { YMInitializer } from 'react-yandex-metrika';
import request, { isDev } from '../api/index';

import ConstructorLayout from './layout/ConstructorLayout';
import Dashboard from './layout/Dashboard';
import EmptyLayout from './layout/EmptyLayout';
import HomeLayout from './layout/HomeLayout';
import PreviewLayout from './layout/PreviewLayout';
import PublicLayout from './layout/PublicLayout';

import Actions from './Actions/Actions';
import Flat from './Apartment';
import AppRoute from './AppRoute';
import Assignation from './Assignation';
import Chess from './Chess';
import Commercial from './Commercial';
import Complex from './Complex';
import ComplexOld from './Complex/old.index.js';
import Contacts from './Contacts';
import EmptyTable from './core/EmptyTable';
import Estate from './Estate';
import Files from './Files';
import Internal from './Internal';
import Motivation from './Motivation';
import Personal from './Personal';
import Preloader from './Preloader';
import Recovery from './Recovery';
import SandBox from './SandBox';
import ScrollRouter from './ScrollRouter';
import Secondary from './Secondaries';
import Suburban from './Suburban';
import Suburban2 from './Suburban/_index.js';
import Land from './SuburbanLand';
import Suburbans from './Suburbans';
import ActionSeptember from './News/ActionSeptember';
import HotNews from './News/HotNews';
import IphoneCds from './News/IphoneCds';
import Morskaya from './News/Morskaya';
import Premium from './News/Premium';
import Primorskiy from './News/Primorskiy';
import Smena from './News/Smena';
import NewLogin from './Preview/Login';
import NewRegister from './Preview/Register';
import TrialRequest from './Preview/TrialRequest';
import Public from './Public';
import PublicAssignments from './PublicAssignment';
import PublicCommercial from './PublicCommercial';
import PublicSecondary from './PublicSecondary';
import PublicAssignmentsNew from '../pages/PublicAssignments';

import FixateClient from '../pages/FixateClient';
import FixateClientSpecial from '../pages/FixateClientSpecial';
import AccessDeniedPlaceholder from '../pages/AccessDenied';
import AddPartner from '../pages/AddPartner';
import BotStepan from '../pages/BotStepan';
import Cashback from '../pages/Cashback';
import Booking from '../pages/Booking';
import Constructor from '../pages/Constructor';
import PublicCommercialNew from '../pages/PublicCommercial';
import ConstructorAddObj from '../pages/ConstructorAddObj';
import ConstructorAdmin from '../pages/ConstructorAdmin';
import ConstructorCard from '../pages/ConstructorCard';
import ConstructorContacts from '../pages/ConstructorContacts';
import ConstructorLid from '../pages/ConstructorLid';
import ConstructorObject from '../pages/ConstructorObject';
import ConstructorObjects from '../pages/ConstructorObjects';
import ConstructorObjectsTable from '../pages/ConstructorObjectsTable';
import ConstructorReviews from '../pages/ConstructorReviews';
import ElevenPan from '../pages/ElevenPan';
import Events from '../pages/Events';
import Faq from '../pages/Faq';
import FlatBooking from '../pages/FlatBooking';
import SuccessBooking from '../pages/FlatBooking/SuccessBooking';
import Future from '../pages/Future';
import Home from '../pages/Home';
import Invite from '../pages/Invite';
import InviteFriend from '../pages/Invite/InviteFriend';
import Lawyers from '../pages/Lawyers';
import LegalCourse from '../pages/LegalCourse';
import MeetingRooms from '../pages/MeetingRooms';
import PublicSecondaryNew from '../pages/PublicSecondary';
import NotFoundPage from '../pages/NotFoundPage';
import PageEvents from '../pages/PageEvents';
import Payment from '../pages/Payment';
import Policy from '../pages/Policy';
import Poll from '../pages/Poll';
import PresentationView from '../pages/PresentationView';
import Preview from '../pages/Preview';
import RieltorSchool from '../pages/RieltorSchool';
import SaleTeacher from '../pages/SaleTeacher';
import School from '../pages/School';
import SchoolOnRoad from '../pages/SchoolOnRoad';
import AutoPan from '../pages/AutoPan';
import SellAssigment from '../pages/SellAssigment';
import AddSecondary from '../pages/SellAssigment/AddSecondary';
import Special from '../pages/Special';
import SpecialDetail from '../pages/SpecialDetail';
import Start from '../pages/Start';
import TenYears from '../pages/TenYears';
import TgBot from '../pages/TgBot';
import ToprealtorsPolicy from '../pages/ToprealtorsPolicy';
import TradeIn from '../pages/TradeIn';
import Training from '../pages/Training';
import TrainingAdditional from '../pages/Training/Additional';
import Confirm from '../pages/Training/Confirm';
import TrainingDone from '../pages/Training/Done';
import TrainingResult from '../pages/Training/Result';
import TrainingTask from '../pages/Training/Task';
import Unsub from '../pages/Unsub';
import UnsubMini from '../pages/Unsub/UnsubMini';
import YandexMapManager from '../pages/YandexMapManager';
import PlatformCheck from '../pages/PlatformCheck';
import ZagorodLanding from '../pages/ZagorodLanding';
import ConstructorMortgage from '../pages/ConstructorMortgage';

import HomePage from '../pages/HomePage';
import HomeContacts from '../pages/HomePage/Contacts';
import HomeEvents from '../pages/HomePage/Events';
import HomeLogin from '../pages/HomePage/Login';
import HomeRecovery from '../pages/HomePage/Recovery';
import HomeRegister from '../pages/HomePage/Register';
import HomeRegisterForm from '../pages/HomePage/RegisterForm';
import HomeTrialRequest from '../pages/HomePage/TrialRequest';
import HomeHelp from '../pages/HomePage/HelpForm';
import HomeSelf from '../pages/HomePage/Self';
import HomeBlog from '../pages/HomePage/Blog';
import HomeFurnish from '../pages/HomePage/Furnish';
import HomeAuto from '../pages/HomePage/Auto';

import Filter from '../pages/Filter';
import FilterDetail from '../pages/Filter/detail.js';
import PublicVillages from '../pages/Filter/PublicVillages';
import Videos from '../pages/Videos';
import VideoPage from './Video';
import Crm from '../pages/Crm';
import useBitrixButton from '../hooks/useBitrixButton';
import Cases from '../pages/Cases';
import useInsertScript from '../hooks/useInsertScript';
import Case from "./Case";
import Villages from '../pages/Filter/Villages';
import ViewingPage from "../pages/viewingPage";
import ComplexDubai from "./Complex/ComplexDubai";
import ForDubaiRoute, { ForDubaiRouteSecond } from "./Complex/ComplexDubai/CustomRoute";
import VillagesDubai from "./Complex/ComplexDubai/ComplexList";
import DubaiPublic from '../pages/Filter/DubaiPublic';
import FastViewDubaiModal from "./Complex/ComplexDubai/FastView";
import MortgagePage from "../pages/Mortgage";
import MorgagePDFPage from '../pages/Mortgage/MortgagePDFPage';
import SuburbanObject from "./SuburbanObject";
import Fixations from "../pages/Fixations";
import HomeNew from "../pages/HomeNew";
import ViewingEstate from "../pages/ViewingEstate";
import useMedia from "../hooks/useMedia";
import ApartmentNew from "./Apartment/NewAppartment";
import NewEvents from "../pages/NewEvents";

import debounce from 'lodash/debounce';
import { store } from '..';
import Compare from "../pages/Compare";
import RecordViewing from "./RecordViewing";
import NewFavorites from "../pages/NewFavorites";
import Compilation from "../pages/NewFavorites/compilation";
import PublicCompilation from "../pages/NewFavorites/publicCompilation";
import JustNotes from "../pages/JustNotes/JustNotes";
import MotivationNew from "./Motivation/MotivationNew";
import Payment2 from "../pages/Payment/customPayment";
import Payment3 from "../pages/Payment/customPayment2";
import MotivationLast from "./Motivation/MotivationLast";
import NewLawyers from "../pages/Lawyers/new";
import RecoveryNew from '../pages/HomePage/RecoveryNew.js';
import PublicProjects from '../pages/Filter/PublicProjects.js';
import Chat from "./Chat";
import DeleteUser from "./DeleteUser";
import DeleteForm from "./DeleteUser/deleteForm";
import MortgageNew from "../pages/Mortgage/new";
import Panschool from "../pages/Panschool";
import AutoPanNew from "../pages/AutoPanNew";
import OfficeTraining from "../pages/OfficeTraining";
import BookingNew from "../pages/BookingNew";
import PanComfort from "../pages/Comfort";
import SuburbanPresentation from "../pages/SuburbanPresentation";
import VillagesNew from "../pages/Filter/VillagesNew";
import SuburbanNew from "./Suburban/_indexNew";
import SuburbanObjectNew from "./SuburbanObject/indexNew";
import SpecialDetailNew from "../pages/SpecialDetailNew";
import HouseNew from "../pages/HouseNew";
import SpecialNew from "../pages/SpecialNew";
import CookiesPolicy from '../pages/Policy/CookiesPolicy.js';
import CompareTable from "../pages/Compare/new";
import ConstructorCardSuburban from "../pages/ConstructorCardSuburban";
import Polzovatel from '../pages/Policy/Polzovatel.js';
import PersonalCabinet from "../pages/PersonalCabinet";
import ClientsList from "./Clients/ClientsList";
import FixationsList from "../pages/PersonalCabinet/FixationsNew";
import AddCompany from "../pages/Company";
import AccessDeniedPage from '../pages/AccessDeniedPage.js';

export const getUnreadCount = () => request("panpartner:push.ajax", "getUnreadCount").then(({ count }) => {
  store.dispatch({ type: "SET_UNREAD_COUNT", count })
})

export const AccessDenied = () => (
  <EmptyTable title="Доступ к разделу запрещен" />
);
const homePaths = ["/", "/realty/spb", "/realty/msk", "/commercial"];

const App = memo((props) => {
  const {
    pathname,
    isAuthorized,
    isOnlyAssignments,
    yandexMetric,
    unreadCount,
    userId
  } = props;

  const [fullScreenLoading, setFullScreenLoading] = useState(
    pathname === '/constructor',
  );


  const onScrollGetUnread = useCallback(debounce(getUnreadCount, 30000, { leading: true, maxWait: 30000 }), [])

  useBitrixButton();

  useEffect(() => {
    if (!isAuthorized) return () => window.removeEventListener("scroll", onScrollGetUnread)
    if (unreadCount === undefined) return getUnreadCount()
    window.addEventListener("scroll", onScrollGetUnread)
    return () => window.removeEventListener("scroll", onScrollGetUnread)
  }, [isAuthorized, unreadCount])

  useEffect(() => {
    isAuthorized && !isDev && !!userId && window.OneSignal && window.OneSignal.push(function () {
      window.OneSignal.sendTag("userId", userId, function (tagsSent) {
        // Callback called when tags have finished sending
      });
    });
  }, []);
  
  const isTablet = useMedia('isTablet');

  return (
    <ModalProvider>
      <Switch>
        <Suspense fallback={<Preloader loading={true} />}>
          <ScrollRouter />

          {!isAuthorized && <AppRoute exact path="/" layout={HomeLayout} component={HomePage} isPublic />}

          {homePaths.includes(pathname) && <AppRoute exact path={[...(isAuthorized ? ["/"] : []), "/realty/:city", "/commercial"]} component={isOnlyAssignments ? Assignation : HomeNew} />}

          <AppRoute exact path={"/commercial/:city"} component={isOnlyAssignments ? Assignation : HomeNew} />

          {!homePaths.includes(pathname) && <AppRoute exact path={["/realty/:city"]} component={isOnlyAssignments ? AccessDenied : ForDubaiRoute} redesign />}

          {/*<AppRoute*/}
          {/*  path="/motivationnew"*/}
          {/*  component={MotivationLast}*/}
          {/*/>*/}

          {/*<AppRoute*/}
          {/*  path="/motivation"*/}
          {/*  component={Motivation}*/}
          {/*/>*/}

          <AppRoute path="/motivation" component={MotivationLast} />

          <AppRoute path="/old/:city/complex/:complex" component={isOnlyAssignments ? AccessDenied : ComplexOld} />

          <AppRoute path="/realty/:city/complex/:blockId" component={isOnlyAssignments ? AccessDenied : ForDubaiRouteSecond} redesign={true} hideHeader={isTablet} />

          <AppRoute path="/dxb/realty/complex/:id" component={isOnlyAssignments ? AccessDenied : ComplexDubai} />

          <AppRoute exact path="/realty/:city/apartment/:id" component={isOnlyAssignments ? AccessDenied : ApartmentNew} />

          <AppRoute path="/realty/:city/parking/:id" component={ViewingPage} />

          <AppRoute exact path="/realty/:city/chess/:id" component={isOnlyAssignments ? AccessDenied : Chess} hideFooter />

          <AppRoute path="/public/apartments/:savedFilterXmlId/" component={Public} isPublic hideHeader hideFooter />

          <AppRoute path="/public/assignments/:savedFilterXmlId/" component={PublicAssignments} isPublic hideHeader hideFooter />

          <AppRoute path="/public/commercials/:savedFilterXmlId/" component={PublicCommercial} isPublic hideHeader hideFooter />

          <AppRoute path="/public/secondary/:savedFilterXmlId/" component={PublicSecondary} isPublic hideHeader hideFooter
          // component={PublicSecondaryNew}
          />

          <Route exact path={`/public/:savedFilterXmlId/:city/apartment/:id`} render={(props) => (<Redirect to={`/public/apartments/${props.match.params.savedFilterXmlId}/${props.match.params.city}/apartment/${props.match.params.id}`} />)} />

          <Route exact path={`/public/:savedFilterXmlId/:city/complex/:id`} render={(props) => (<Redirect to={`/public/apartments/${props.match.params.savedFilterXmlId}/${props.match.params.city}/complex/${props.match.params.id}`} />)} />

          <Route exact path="/public/:savedFilterXmlId/" render={(props) => (<Redirect to={`/public/apartments/${props.match.params.savedFilterXmlId}`} />)} />

          <Route exact path="/personal/rating" render={(props) => <Redirect to={`/`} />} />
          {/* <AppRoute path="/commercial"
 component={isOnlyAssignments ? AccessDenied : Commercial}  /> */}
          <AppRoute path="/secondary" component={isOnlyAssignments ? AccessDenied : Secondary} />

          <AppRoute path="/assignments" component={Assignation} />

          <AppRoute path="/trade-in" isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} component={TradeIn} />

          <AppRoute exact path="/events" addMainClass='events-page' component={NewEvents} />

          {/*<AppRoute*/}
          {/*  exact*/}
          {/*  path="/eventstest"*/}
          {/*  addMainClass='events-page'*/}
          {/*  component={AppTest}*/}
          {/*/>*/}

          <AppRoute exact path="/events-old" component={Events} />

          {/*
          TO DO вырезать всё причастное к этому компоненту
          <AppRoute exact
path="/eventsOld/:id"
 component={Event}
  /> */}
          <AppRoute path="/contacts" component={Contacts} />

          <AppRoute path="/files/:id?" component={Files} />

          <AppRoute path="/personal" component={Personal} />

          <AppRoute path="/internal" isPublic layout={PublicLayout} component={Internal} mobileVersionDisabled />

          <AppRoute path="/presentation/view" isPublic exact layout={EmptyLayout} component={PresentationView} hideFooter hideHeader mobileVersionDisabled />

          <AppRoute path="/not-found" exact component={NotFoundPage} isPublic />

          <AppRoute path="/denied" exact component={AccessDeniedPage} isPublic />

          <AppRoute exact isPublic path="/policy" layout={isAuthorized ? Dashboard : PreviewLayout} component={Policy} layoutProps={{ headerWithBg: true }} mobileVersionDisabled />

          <AppRoute exact isPublic path="/polzovatel" layout={isAuthorized ? Dashboard : PreviewLayout} component={Polzovatel} layoutProps={{ headerWithBg: true }} mobileVersionDisabled />

          <AppRoute exact isPublic path="/cookies_policy" layout={isAuthorized ? Dashboard : PreviewLayout} component={CookiesPolicy} layoutProps={{ headerWithBg: true }} mobileVersionDisabled />

          <AppRoute exact isPublic path="/toprealtors-policy" layout={isAuthorized ? Dashboard : PreviewLayout} component={ToprealtorsPolicy} layoutProps={{ headerWithBg: true }} mobileVersionDisabled />

          <AppRoute exact path="/start" component={Start} isPublic />

          <AppRoute exact path="/pan-school-old" component={School} />

          <AppRoute exact path="/bot/:id" component={BotStepan} />

          <AppRoute exact path="/constructor" layout={ConstructorLayout} component={Constructor} setFullScreenLoading={setFullScreenLoading} layoutProps={{ isMainPage: true }} />

          <AppRoute exact path="/complexes/:city/:id" layout={ConstructorLayout} component={ConstructorCard} isPublic setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/suburbans/:city/:id" layout={ConstructorLayout} component={ConstructorCardSuburban} isPublic setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/constructor-admin" component={ConstructorAdmin} />

          <AppRoute exact path="/objects/add" component={ConstructorAddObj} />

          <AppRoute exact path="/objects/edit/:id" component={ConstructorAddObj} />

          <AppRoute exact path="/objects" component={ConstructorObjectsTable} />

          <AppRoute exact path="/constructor-reviews" layout={ConstructorLayout} component={ConstructorReviews} setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/constructor-contacts" layout={ConstructorLayout} component={ConstructorContacts} setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/constructor-ipoteka" layout={ConstructorLayout} component={ConstructorMortgage} setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/constructor-object/:selfObjectId" layout={ConstructorLayout} component={ConstructorObject} setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/constructor-objects" layout={ConstructorLayout} component={ConstructorObjects} />

          <AppRoute exact path="/constructor-lid" layout={ConstructorLayout} layoutProps={{ isEmpty: true }} component={ConstructorLid} setFullScreenLoading={setFullScreenLoading} />

          <AppRoute exact path="/ten-years" component={TenYears} isPublic mobileVersionDisabled />

          <AppRoute exact path="/poll" component={Poll} mobileVersionDisabled />

          <AppRoute exact path="/telegram" component={TgBot} />

          <AppRoute exact path="/invite/:inviteCode" component={Invite} hideLayout isPublic />

          <AppRoute exact path="/invite-friend" component={InviteFriend} />

          <AppRoute exact path="/hot-news/exclusive" component={HotNews} />

          <AppRoute exact path="/hot-news/polis-primorskiy" component={Primorskiy} />

          <AppRoute exact path="/hot-news/premium-partner" component={Premium} />

          <AppRoute exact path="/hot-news/start-morskaya-mily" component={Morskaya} />

          <AppRoute exact path="/hot-news/smena15" component={Smena} />

          <AppRoute exact path="/hot-news/action-september" component={ActionSeptember} />

          <AppRoute exact path="/hot-news/iphone-cds" component={IphoneCds} />

          <AppRoute exact path="/sell/assignment" component={SellAssigment} />

          <AppRoute exact path="/sell/secondary" component={AddSecondary} />

          {/* <AppRoute exact path="/client-fixation" component={FixateClient}  /> */}

          <AppRoute exact path="/client-fixation-special/:objectId" component={FixateClientSpecial} />

          <AppRoute exact path="/add-partner" component={AddPartner} />

          <AppRoute exact path="/legal-services" isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} component={NewLawyers} />

          <AppRoute exact path="/new-partner" component={Future} isPublic hideLayout />

          <AppRoute exact path="/sale-teacher" component={SaleTeacher} isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} />

          <AppRoute exact path="/meeting-rooms" component={MeetingRooms} />

          <AppRoute
            exact
            path="/suburbans/:city"
            component={isOnlyAssignments && false ? AccessDenied : VillagesNew}
          />

          <AppRoute
            path="/public/suburbans/:savedFilterXmlId"
            component={VillagesNew}
            isPublic
            hideHeader
            hideFooter
          />

          <AppRoute
            path="/public/suburban/:savedFilterXmlId/:city/:villageId"
            component={SuburbanNew}
            isPublic
            hideHeader
            hideFooter
          />

          <AppRoute
            path="/public/lands/:savedFilterXmlId/:city/:id"
            component={SuburbanObjectNew}
            isPublic
            hideHeader
            hideFooter
          />
          <AppRoute
            path="/public/house/:city/:id/:savedFilterXmlId"
            component={HouseNew}
            isPublic
            hideHeader
            hideFooter
          />


          <AppRoute
            path={["/suburban/spb/:villageId"]} // можно будет вернуть :city но конфликтует с презентациями "/suburban/presentation/:id"
            component={isOnlyAssignments && false ? AccessDenied : SuburbanNew}
          />

          <AppRoute exact path="/suburban/presentation/:id" isPublic  layout={isAuthorized ? Dashboard : EmptyLayout} component={SuburbanPresentation} />

          <AppRoute exact path="/lands/:city/:id" component={isOnlyAssignments && false ? AccessDenied : SuburbanObjectNew} />

          <AppRoute exact path="/access-denied" component={AccessDeniedPlaceholder} />

          <AppRoute path="/map-frame/:type/:city/:id" component={YandexMapManager} isPublic hideHeader hideFooter hideLayout layout={EmptyLayout} />

          <AppRoute exact path="/flat-booking/:type/:objectId/:city?" component={FlatBooking} />

          {isDev && <AppRoute path="/sandbox" component={SandBox} isPublic hideFooter hideHeader layout={EmptyLayout} />}

          <AppRoute exact path="/unsubscribe" component={Unsub} isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} />

          <AppRoute exact isPublic path="/unsubscribe/mailing" layout={isAuthorized ? Dashboard : PreviewLayout} component={UnsubMini} layoutProps={{ headerWithBg: true }} />

          <AppRoute exact path="/success-booking" component={SuccessBooking} />

          {/* OLD SUBURBANS */}
          <AppRoute exact path="/old/suburbans/:city" component={isOnlyAssignments && false ? AccessDenied : Villages} />

          <AppRoute exact path="/old/build-house" component={Special} />

          <AppRoute exact path="/old/build-house/:id" component={SpecialDetail} />

          <AppRoute path="/old/public/suburbans/:savedFilterXmlId/" component={PublicVillages} isPublic hideHeader hideFooter layoutProps={{ filterType: 'suburbans', reducer: 'suburbans', }} />

          <AppRoute
            path={["/old/suburban/spb/:villageId"]} // можно будет вернуть :city но конфликтует с презентациями "/suburban/presentation/:id"
            component={isOnlyAssignments && false ? AccessDenied : Suburban2}
          />

          <AppRoute
            path="/old/public/suburban/:savedFilterXmlId/:city/:id"
            component={Suburban}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{ showPublicHeader: false, filterType: 'suburbans', reducer: 'suburban', }}
          />

          <AppRoute exact path="/old/lands/:city/:id" component={isOnlyAssignments && false ? AccessDenied : SuburbanObject} />
          <AppRoute
            exact
            path="/old/public/lands/:savedFilterXmlId/:city/:id"
            component={Land}
            isPublic
            hideHeader
            hideFooter
            showPublicHeader
            layoutProps={{ showPublicHeader: true, filterType: 'suburbans', reducer: 'suburbanLand', }}
          />

          {/* OLD SUBURBANS END */}

          <AppRoute exact path="/build-house" component={SpecialNew} />

          <AppRoute exact path="/house/:id" component={HouseNew} />

          <AppRoute exact path="/build-house/:id" component={SpecialDetailNew} />

          <AppRoute exact path="/training" component={Training} />

          <AppRoute exact path="/training-confirm/:type/:agentId" component={Confirm} />

          <AppRoute exact path="/training-task" component={TrainingTask} />

          <AppRoute exact path="/training-result" component={TrainingResult} />

          <AppRoute exact path="/training-additional" component={TrainingAdditional} />

          <AppRoute exact path="/training-done" component={TrainingDone} />

          <AppRoute exact path="/office-training-last" layout={isAuthorized ? Dashboard : PreviewLayout} component={SchoolOnRoad} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/office-training" layout={isAuthorized ? Dashboard : HomeLayout} component={OfficeTraining} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/autopan" layout={isAuthorized ? Dashboard : PreviewLayout} component={AutoPan} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/autopanorama" layout={isAuthorized ? Dashboard : HomeLayout} component={AutoPanNew} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/cashback" component={Cashback} layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/adv-booking-last" component={Booking} layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/adv-booking" component={BookingNew} layout={isAuthorized ? Dashboard : HomeLayout} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/pan-school" component={Panschool} layout={isAuthorized ? Dashboard : HomeLayout} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/pan-school-last" component={RieltorSchool} layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} isPublic />

          {/*<AppRoute*/}
          {/*  exact*/}
          {/*  path="/pan-comfort"*/}
          {/*  component={PanComfort}*/}
          {/*  layout={isAuthorized ? Dashboard : HomeLayout}*/}
          {/*  layoutProps={{ headerWithBg: true }}*/}
          {/*   isPublic*/}
          {/*/>*/}<AppRoute exact path="/platform-check" component={PlatformCheck} layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} isPublic />

          <AppRoute exact path="/legal-course" component={LegalCourse} isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} />

          <AppRoute exact path="/payment" component={Payment} />

          <AppRoute exact path="/payment2" component={Payment2} />

          <AppRoute exact path="/payment3" component={Payment3} isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true, isNewPayment: true }} />

          <AppRoute exact path="/faq" component={Faq} />

          <AppRoute exact path="/zl" component={ZagorodLanding} hideFooter hideHeader />

          <AppRoute exact path="/pan11" component={ElevenPan} />

          <AppRoute exact path="/events/:id" component={PageEvents} />

          <AppRoute exact path="/actions/:actionId" component={Actions} isPublic layout={isAuthorized ? Dashboard : PreviewLayout} layoutProps={{ headerWithBg: true }} />

          <AppRoute exact path="/welcome" layout={isAuthorized ? Dashboard : HomeLayout} component={HomePage} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/register" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeRegister} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/support" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeContacts} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/schedule" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeEvents} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/login" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeLogin} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/recovery" layout={isAuthorized ? Dashboard : HomeLayout} component={RecoveryNew} isPublic addMainClass="hp hp_dashboard" />

          {/*<AppRoute*/}
          {/*  exact*/}
          {/*  path="/register/:who"*/}
          {/*  layout={isAuthorized ? Dashboard : HomeLayout}*/}
          {/*  component={HomeRegisterForm}*/}
          {/*   isPublic*/}
          {/*  addMainClass="hp hp_dashboard"*/}
          {/*/>*/}

          <AppRoute exact path="/register/new" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeRegisterForm} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/trial" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeTrialRequest} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/help" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeHelp} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/selfemployed" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeSelf} isPublic addManClass="hp hp_dashboard" />

          <AppRoute exact path="/blog/:section/:element" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeBlog} isPublic addMainClass="hp hp_dashboard" />

          <AppRoute exact path="/meblirovka-kvartiry" layout={isAuthorized ? Dashboard : HomeLayout} component={HomeFurnish} isPublic addMainClass="hp hp_dashboard" />

          {/*<AppRoute*/}
          {/*  exact*/}
          {/*  path="/vykup-avto"*/}
          {/*  layout={isAuthorized ? Dashboard : HomeLayout}*/}
          {/*  component={HomeAuto}*/}
          {/*   isPublic*/}
          {/*  addMainClass="hp hp_dashboard"*/}
          {/*/>*/}

          <AppRoute exact path="/public/apartments/:savedFilterXmlId/" component={Filter} isPublic hideHeader hideFooter />

          <AppRoute exact path="/public/global/:savedFilterXmlId/" component={DubaiPublic} isPublic hideHeader hideFooter />

          <AppRoute exact path="/public/project/:savedFilterXmlId/" component={PublicProjects} isPublic hideHeader hideFooter />

          <AppRoute exact path="/public/project/:savedFilterXmlId/:id" component={SpecialDetail} isPublic hideHeader hideFooter />

          <AppRoute exact path="/public/global/:savedFilterXmlId/:city/complex/:blockId" component={ComplexDubai} isPublic hideHeader hideFooter redesign={true} />

          <AppRoute exact path="/public/global/:savedFilterXmlId/:city/complex/:blockId/:id" component={FastViewDubaiModal} isPublic hideHeader hideFooter />

          <AppRoute path="/filter" component={Filter} isPublic hideHeader hideFooter />

          <AppRoute path="/filter-detail/:id" component={FilterDetail} isPublic hideHeader hideFooter />

          <AppRoute exact isPublic path="/videos" component={Videos} layoutProps={{ disableTitle: true }} />

          <AppRoute isPublic path="/videos/:id" layoutProps={{ disableTitle: true }} component={VideoPage} />

          <AppRoute path="/crm" component={Crm} />

          <AppRoute exact isPublic path="/cases" component={Cases} layoutProps={{ disableTitle: true }} />

          <AppRoute isPublic path="/cases/:id" layoutProps={{ disableTitle: true }} component={Case} />

          <AppRoute exact path="/mortgage" component={MortgageNew} />

          <AppRoute exact path="/mortgage/:hash" component={MortgageNew} layout={EmptyLayout} isPublic />

          <AppRoute exact path="/client-fixation" component={Fixations} />

          <AppRoute exact path="/viewed" component={ViewingEstate} isPublic />

          <AppRoute exact path="/compare/:id" component={Compare} />

          <AppRoute exact path="/public/compare/:id" component={Compare} isPublic hideLayout />

          <AppRoute exact path="/comparenew/:id" component={CompareTable} />

          <AppRoute exact path="/favorites" component={NewFavorites} />

          <AppRoute exact path="/compilation/:id" component={Compilation} />

          <AppRoute exact path="/public/compilation/:xmlId" component={PublicCompilation} isPublic hideHeader hideFooter />

          <AppRoute exact path="/justnotes" component={JustNotes} isPublic hideHeader hideFooter />

          <AppRoute exact path="/book-a-tour" component={RecordViewing} />

          <AppRoute exact path="/book-a-tour/:city" component={RecordViewing} />

          <AppRoute exact path="/book-a-tour/:city/:id" component={RecordViewing} />

          <AppRoute exact path="/chat" component={Chat} />

          <AppRoute exact path="/isAuthorized/delete" component={DeleteUser} isPublic />

          <AppRoute exact path="/isAuthorized/delete/form" component={DeleteForm} />

          <AppRoute exact path="/personalcabinet" component={PersonalCabinet} />

          <AppRoute exact path="/personalcabinet/clients" component={ClientsList} />

          <AppRoute exact path="/personalcabinet/company" component={AddCompany} />

          <AppRoute exact path="/personalcabinet/fixations" component={FixationsList} />

        </Suspense>
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        transition={Slide}
      />

      {/* {isAuthorized && !clientMode && acceptOffers &&
        <Banner
          dateTo={new Date(2020,10,18)}
          link="https://panpartner.ru/realty/spb/complex/14030?from=bottom-banner"
          image={bannerImage}
          bannerId={'enfild'}
    />
      } */}
      <Preloader loading={fullScreenLoading} />
      {yandexMetric && (
        <YMInitializer
          accounts={[yandexMetric]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: false,
          }}
        />
      )}
    </ModalProvider>
  );
});

const mapStateToProps = (state) => {
  return {
    yandexMetric: state.agency.yandexMetric,
    pathname: state.router.location.pathname,
    userId: state.user?.info?.id,
    isAuthorized: state.user.isAuthorized,
    isOnlyAssignments: state.user?.info?.groups?.includes('only_assignments'),
    unreadCount: state.general.unreadCount
  };
};

export default connect(mapStateToProps)(App);
