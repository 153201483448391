import dateFormat from "dateformat";
import formatter from "format-number";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useMedia from '../../hooks/useMedia';
import usePermission from "../../hooks/usePermission";
import { evaulateEndings, scrollToRef } from "../../utils";
import AutopanTrigger from '../AutopanTrigger/AutopanTrigger.js';
import PopoverBlock from "../Cards/PopoverBlock";
import Photos from "./Photos";

const updateFormat = `dd.mm.yyyy в HH:MM`;

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const SubwayList = (props) => {
  const { items } = props;

  const routeType = (type) => {
    if(!type){
      return ""
    }
    const way = type === "byTransport" ? "auto" : "pedestrian";
    return (
      <span className="complex__route-type">
        <svg className={`icon icon_${way}`}>
          <use xlinkHref={`#${way}`}></use>
        </svg>
      </span>
    );
  };

  return (
    <div className="complex__info-routes">
      <ul className="complex__routes">
        {items.map((subway) => (
          <li className="complex__routes-item" key={subway.id}>
            <span
              className="complex__metro-color"
              style={{ backgroundColor: subway.color }}
            ></span>
            <span className="complex__metro-station">{subway.name}</span>
            {routeType(subway.distanceType)}
            {subway.minutes && (
              <span className="complex__route-time">{subway.minutes} мин.</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Block = (props) => {
  const {
    block,
    handleFavorites,
    isFavorite,
    navigation,
    isClientMode,
    clients,
    addClientToFavorite,
    removeClientFromFavorite,
    fetchClients,
    isPublic,
    activeNav,
    haveApartments,
    savedFilterXmlId,
  } = props;
  const { suburbansInfo, builder } = block
  const isMobile = useMedia("isMobile")
  const middleScreen = useMedia("isDesktop")
  const [initMap, setInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const modalMap = useRef(null);
  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
 const permission = usePermission("motivation")
  useEffect(() => {
    if (!modalMap) return;
    dynamicMapImport()
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);
  const [Maps, setMaps] = useState(null)
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({YMaps, Map, Placemark, RulerControl})
  };
  const typesList = (types) => {
    return types.map((item) => item.name).join(", ");
  };

  const itemsList = (items) => {
    return items.map((item) => item).join(", ")
  };

  const navRef = useRef(null);

  const fixComplexNav = () => {
    const navigation = document.querySelector(".navigation__block");

    if (navigation) {
      let fixPosition;
      // Пока слайдеры грузятся, отступ элемента недействителен
      setTimeout(() => {
        fixPosition = navigation.offsetTop + 100; // getCoords(navigation);
      }, 500);
      const getCSS = () => {
        if(isPublic){
          return `top: -80px;`
        }
        if (isClientMode){
          return `top: -10px;`
        }
        return `top: ${middleScreen ? -10 : 0}px;`
      }

      const fix = (e) => {
        const isFixed = navigation.classList.contains("nav_fixed");
        const pageYOffset = window.scrollY;
        if (pageYOffset >= fixPosition && !isFixed) {
          navigation.classList.add("nav_fixed");
          let css = getCSS()
          navigation.style.cssText = css;
          setIsFixed(true);
        } else if (pageYOffset < fixPosition && isFixed) {
          navigation.classList.remove("nav_fixed");
          setIsFixed(false);
          navigation.style.cssText = "";
        }
      };

      let timer = false;
      document.addEventListener("scroll", (event) => {
        if (!timer)
          timer = setTimeout(() => {
            fix(event);
            timer = false;
          }, 200);
      });
    }
  };

  useEffect(() => {
    fixComplexNav();
  }, []);

  const favButton = (
    <div className="complex__actions-item is-fav">
      <div
        className={`add-fav-btn dark ${
          block.favorite?.inFavorite ? "is-fav" : ""
        }`}
        onClick={() =>
          handleFavorites(block.favorite?.inFavorite)
        }
      >
        <button className="button button_type_settings">
          <svg className="icon icon_settings">
            <use xlinkHref="#fav"></use>
          </svg>
        </button>

        <PopoverBlock
          type="village"
          id={block.id}
          favorite={block.favorite}
          city={block.city == 3 ? "msk" : "spb"}
          allClients={clients}
          addClientToFavorite={addClientToFavorite}
          removeClientFromFavorite={removeClientFromFavorite}
          fetchClients={fetchClients}
          whatPage={"suburban"}
          showOnLeft={true}
        />
      </div>
    </div>
  )
  const blockPhotos = {...block.photos}
  blockPhotos.decoration = blockPhotos.preFinishing.concat(blockPhotos.roughFinishing, blockPhotos.withoutFinishing)

  block.promo = `3012`;
  return (
    <div>
      <section className="section section_view_white section__block for-suburbans">
        <div className="wrapper">
          <div className="complex__block">
            <div className="complex__block-row">
              <div className="complex__block-col info__col info__col-top">
                {!isMobile && block.commission && !isClientMode && !isPublic && permission &&(
                  <ul className="complex__badges">
                    <li className="complex__badges-item">
                      <div className="badge complex__badge-badge">
                        Комиссия: {block.commission.join(", ")}
                      </div>
                    </li>
                  </ul>
                )}
                {!isPublic && (
                  <div className="complex__info-update">
                    {`Обновлено ${dateFormat(
                      block.dateUpdate,
                      updateFormat,
                      "isoTime",
                    )} `}
                  </div>
                )}
              </div>
              {!isPublic && (
                <div className="complex__block-col text__col sm-hide">
                  <div
                    className="complex__address"
                    style={{ alignItems: "center" }}
                  >
                    <div className="complex__address-value">
                      {block.address}
                    </div>
                    <div className="complex__address-map">
                      <span
                        className="show__map"
                        onClick={() => setShowMap(true)}
                      >
                        {/*
                          <svg className="icon icon_map-pin">
                            <use xlinkHref="#test"></use>
                          </svg>
                        */}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="complex__info-main mobile sm-show">
              {!isMobile &&
                <div className="complex__address">
                  <div className="complex__address-value">{block.address}</div>
                  <div className="complex__address-map">
                    <div
                      className="link show__map-mobile"
                      onClick={() => setShowMap(true)}
                    >
                      {!isMobile &&
                        <Fragment>
                          <svg className="icon icon_map-pin">
                            <use xlinkHref="#test"></use>
                          </svg>
                          Показать на карте
                        </Fragment>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className="complex__main">
                <h1 className={`${!isMobile ? 'h2' : ''} complex__title`}>
                  <span>{block.name}</span>
                  {block.isAutoPan && !savedFilterXmlId && <AutopanTrigger modClass="autopan-trigger_detail" />}
                </h1>
                {!isMobile && block.subway && block.subway.length > 0 && (
                  <SubwayList items={block.subway} />
                )}
              </div>

              {isMobile && block.commission && !isClientMode && !isPublic && permission &&(
                <ul className="complex__badges">
                  <li className="complex__badges-item">
                    <div className="badge complex__badge-badge">
                      Комиссия: {block.commission.join(", ")}
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div className="complex__block-row">
              <div className="complex__block-col info__col">
                {<Photos photos={blockPhotos} tour={block?.builder?.typical3DTour} />}
              </div>
              {isMobile &&
                <div className="complex__mobile-address">
                  <div className="complex__address">
                    <div>
                      <div className="complex__address-value">{block.address}</div>
                      {block.subway && block.subway.length > 0 && (
                        <SubwayList items={block.subway} />
                      )}
                    </div>
                    <div className="complex__address-map">
                      <div
                        className="link show__map-mobile"
                        onClick={() => setShowMap(true)}
                      >
                        {!isMobile &&
                          <Fragment>
                            <svg className="icon icon_map-pin">
                              <use xlinkHref="#test"></use>
                            </svg>
                            Показать на карте
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="complex__block-col text__col">
                <div className="complex__info-main sm-hide">
                  <div className="complex__main">
                    {isPublic && (
                      <div
                        className="complex__block-col info__col-top"
                        style={{ margin: 0 }}
                      >
                        <div className="complex__address-value">
                          {block.address}
                        </div>
                        <div className="complex__address-map">
                          <span
                            className="show__map"
                            onClick={() => setShowMap(true)}
                          >
                            {/* <svg className="icon icon_map-pin">
                              <use xlinkHref="#test"></use>
                            </svg> */}
                          </span>
                        </div>
                      </div>
                    )}
                    <h1 className='h2 complex__title'>
                      <span>{block.name}</span>
                      {block.isAutoPan && !savedFilterXmlId && <AutopanTrigger modClass="autopan-trigger_detail" />}
                    </h1>

                    {block.subway && block.subway.length > 0 && (
                      <SubwayList items={block.subway} />
                    )}
                  </div>
                </div>
                <div className="complex__bottom">
                  <div className="complex__border">

                    {/* <div className="complex__suburbans-options">
                      <div className="component__suburbans-option">
                        <div className="component__suburbans-option-title">
                          Участок от 3.5 сот.
                        </div>

                        <div className="component__suburbans-option-counter">
                          34 участка
                        </div>

                        <div className="component__suburbans-option-price">
                          от 3 000 000 р.
                        </div>
                      </div>

                      <div className="component__suburbans-option">
                        <div className="component__suburbans-option-title">
                          Дом + Участок
                        </div>

                        <div className="component__suburbans-option-square">
                          <div>от 140 м²</div>

                          <div>от 4 сот.</div>
                        </div>

                        <div className="component__suburbans-option-counter">
                          34 участка
                        </div>

                        <div className="component__suburbans-option-price">
                          от 3 000 000 р.
                        </div>
                      </div>
                    </div> */}

                    {haveApartments && (
                      <div className="complex__price">
                        <div className="complex__price-row">
                          <div className="complex__price-col complex__price-left">
                            <div className="complex__price-value">{`от ${priceFormat(suburbansInfo?.minPrice)}`}</div>
                            <div className="complex__info-item-desc">
                              Цена при 100% оплате
                            </div>
                          </div>
                          {!!suburbansInfo?.countSuburbans && <div className="complex__price-col complex__price-right">
                            <div className="complex__price-value">
                              {suburbansInfo.countSuburbans}
                            </div>
                            <div className="complex__info-item-desc">
                              Объектов
                            </div>
                          </div>}
                        </div>
                      </div>
                    )}
                    {isMobile && favButton}
                  </div>
                  <div
                    className={
                      "complex__additional for-suburban" + (isPublic ? " no-border" : "")
                    }
                  >

                    <div className="complex__additional-row suburbans">
                      {builder.name && <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {builder.name}
                        </div>
                        <div className="complex__info-item-desc">
                          Застройщик
                        </div>
                      </div>}
                      {!!block.endingPeriod && <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {evaulateEndings(block.endingPeriod)}
                        </div>
                        <div className="complex__info-item-desc">
                          Срок сдачи
                        </div>
                      </div>}
                      {!!block.registration?.title && <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {block.registration.title}
                        </div>
                        <div className="complex__info-item-desc">Прописка</div>
                      </div>}
                      {!!block?.decorationTypes?.length && (
                        <div className="complex__additional-col complex__additional-item">
                          <div className="complex__info-item-value">
                            {itemsList(block.decorationTypes)}
                          </div>
                          <div className="complex__info-item-desc">
                            Отделка
                            </div>
                        </div>
                      )}
                      {!!suburbansInfo?.types?.length && (
                        <div className="complex__additional-col complex__additional-item">
                          <div className="complex__info-item-value">
                            {suburbansInfo?.types}
                          </div>
                          <div className="complex__info-item-desc">
                            Типы объектов
                          </div>
                        </div>
                      )}
                      {!!block.landStatus.title && <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {block.landStatus.title}
                        </div>
                        <div className="complex__info-item-desc">Статус земли</div>
                      </div>}
                      {!!block.materials?.length &&
                        <div className="complex__additional-col complex__additional-item">
                          <div className="complex__info-item-value">
                            {itemsList(block.materials)}
                          </div>
                          <div className="complex__info-item-desc">Тип дома</div>
                        </div>}
                    </div>
                    {!!block.communications?.length && <div className="complex__additional-row" >
                      <div className="complex__additional-col complex__additional-item" style={{width: "auto"}}>
                        <div className="complex__info-item-value">
                          {/*block.communications.map((com, i, arr) => `${com.title}${i !== arr.length - 1 ? ", " : ""} `)*/}
                          {itemsList(block.communications)}
                        </div>
                        <div className="complex__info-item-desc">Коммуникации</div>
                      </div>
                    </div>}
                  </div>
                </div>
                {!isPublic && haveApartments && (
                  <div className="complex__actions">
                    {Boolean(block.files) && (
                      <div className="complex__actions-item">
                        <Link to={`/files/${block.files}`} target="_blank">
                          <div className="button button_view_outline-invert button__files">
                            Файлы
                          </div>
                        </Link>
                      </div>
                    )}
                    {!!block.equipment && (
                      <div className="complex__actions-item complex__actions-item_basis_0">
                        <Link to={`/files/${block.equipment}`} target="_blank">
                          Комплектация дома
                        </Link>
                      </div>
                    )}
                    {!!block.promo && (
                      <div className="complex__actions-item complex__actions-item_basis_0">
                        <Link to={`/files/${block.promo}`} target="_blank">
                          Рекламные материалы
                        </Link>
                      </div>
                    )}

                    {!isMobile && favButton}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isMobile &&
          <div className="navigation__block">
            <div className="wrapper">
              <div className="navigation__block-inner">
                <ul className="complex__navigation">
                  {navigation.map((nav) => (
                    <li key={nav.title}>
                      <span
                        className={`complex__navigation-item ${
                          activeNav === nav.type && isFixed ? "active" : ""
                        }`}
                        onClick={() => scrollToRef(nav.ref, 70)}
                      >
                        {nav.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        }
      </section>

      <div className="modal" ref={modalMap}>
        <div className="modal__shadow " data-id="dist-map"></div>
        <div className="modal__content">
          <div className="modal__inner">
            <div
              className="modal__close "
              data-id="dist-map"
              onClick={() => setShowMap(false)}
            >
              <svg className="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
            <h3 className="modal__title">{`«${block.name}» на карте`}</h3>
            <div className="dist-map" id="dist-map-container">
              {initMap || showMap ? (
                <Maps.YMaps>
                  <Maps.Map
                    defaultState={{
                      center: block.coordinates,
                      zoom: 16,
                    }}
                    className="complex__map-wrap"
                    onLoad={mapLoad}
                  >
                    <Maps.Placemark
                      geometry={block.coordinates}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: "/assets/images/place.png",
                        iconImageSize: [25, 40],
                        iconImageOffset: [-25, -20],
                      }}
                    />
                    <Maps.RulerControl
                      options={{ position: { top: 10, left: 10 } }}
                    />
                  </Maps.Map>
                </Maps.YMaps>
              ) : (
                <div className="dist-map" id="dist-map-container" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block;
