import _ from "lodash";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { reserve } from '../../actions/forms';
import { media } from '../../utils';
import Dropdown from "../core/Dropdown";
import DropdownMulti from "../core/DropdownMulti";
import FileUploader from "../FileUploader";
import SubmitButton from "../SubmitButton";
import FormAddClient from "./FormAddClient";
const payments = [
  { id: 1, title: "100% оплата"},
  { id: 2, title: "Рассрочка" },
  { id: 3, title: "Ипотека" },
  { id: 4, title: "Военная ипотека" },
  { id: 5, title: "Мат. капитал" },
];

const ReserveApartment = (props) => {
  const { clients, id, phone, email, name, handleSend, isPublic, savedFilterXmlId, client, isCommercial,title, isSecondary, method =  "bookingAssignment", savedFilterSettings } = props;
  const defaultValues = {
    id: id,
    //name: isPublic ? `${client?.name} ${client?.lastName}` : name,
    name: isPublic ? `` : name,
    clientId: isPublic ? client?.id : "",
    //phone: isPublic ? client?.phone?.[0] : phone,
    phone: isPublic ? `` : phone,
    email: isPublic ? client?.email?.[0] : email,
    comment: "",
    payType: [],
    agree: true,
    fromClient: isPublic ? "Y" : "N",
    url: window.location.href
  };
  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [show, setShow] = useState(false);

  const [files, setFiles] = useState([]);

  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAddClient = (id) => {
    setValue("clientId", id);
    setShow(false);
  };

  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    let keys = _.keys(data);

    keys.forEach((item) => {
      let identity = item;
      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, data[item]);
      }
    });
    if(isPublic && savedFilterXmlId){
      form.append(`savedFilterXmlId`, savedFilterXmlId);
      form.append(`fromClient`, "Y");
    }
    if(isPublic){
      form.append(`url`, window.location.href);
    }
    files.forEach(item=> {
      form.append(`files[]`, item.fileObj);
      form.append(`fileName[]`, item.fileName);
    })
    let reserveMethod = method
    // form.append(`fromClient`, `Y`);
    if(isSecondary){
      reserveMethod = "bookingSecondary"
    }
    if (isCommercial) {
      reserveMethod = "bookingCommercial"
    }
    reserve(reserveMethod, form).then(
      (data) => {
        setSuccess(true);
        handleSend();
        setFetching(false);
      },
      (error) => {
        setError(true);
        setFetching(false);
      }
    )
  };
console.log(isPublic)
  return (
    <>
      <div className="assignation-reservation" style={{paddingTop: (isPublic || media("isMobile")) ? 0 : "240px"}}>
        {!isPublic && !media("isMobile") && <div className="assignation-reservation__header">
          <div className="assignation-reservation__header-bg">
            <svg className="icon">
              <use xlinkHref="#reservation-bg"></use>
            </svg>
          </div>
          <div className="assignation-reservation__title">
            <h3 className="h3">Коллеги, важно!</h3>
          </div>
          <div className="assignation-reservation__warning">
            <span>мы за честное сотрудничество</span>
          </div>
          <div className="assignation-reservation__content">
            <p>
              ПАН Партнер не приемлет никакие виды «дополнительных»
              стимулирований клиента со стороны субагента.
            </p>
          </div>
        </div>}
        {!media("isMobile") && <h2 className="modal__title">Забронировать {title? title :"переуступку"}</h2>}
        <form className="modal-form" name="reserve-apartment">
          <div className="modal-form__wrapper">
            <input type="hidden" name="id" ref={register} />
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя и фамилия</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    className={
                      "input__control" + (errors.name ? " input__error" : "")
                    }
                    ref={register({
                      required: "Введите имя и фамилию",
                    })}
                    placeholder="Введите имя и фамилию"
                  />
                  {errors.name && (
                    <span className="input__error-label">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <Controller
                    as={
                      <InputMask
                        className={
                          "input__control" +
                          (errors.phone ? " input__error" : "")
                        }
                        placeholder="Введите телефон"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                      />
                    }
                    name="phone"
                    control={control}
                    rules={{
                      required: "Введите телефон",
                    }}
                  />
                  {errors.phone && (
                    <span className="input__error-label">
                      {errors.phone.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            {!isPublic &&<div className="modal-form__row">
              <div className="modal-form__item">
                <div className="select select_type_form">
                  <label
                    className="custom-select-label"
                    htmlFor="modal-reservation-client"
                  >
                    Клиент
                  </label>
                  <Controller
                    as={Dropdown}
                    name="clientId"
                    options={clients}
                    onChange={([value]) => value}
                    inputRef={register}
                    control={control}
                    placeholder="Не выбран"
                    rules={{
                      required: "Выберите клиента",
                    }}
                  />
                  {errors.clientId && (
                    <span className="input__error-label">
                      {errors.clientId.message}
                    </span>
                  )}
                </div>
                <div
                  className="add-client js-show active"
                  style={{ display: !show ? "flex" : "none" }}
                  onClick={() => setShow(true)}
                >
                  <button
                    className="button button_type_add"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  ></button>
                  <span>Добавить клиента</span>
                </div>
              </div>
            </div>}
            {!isPublic &&<FormAddClient
              show={show}
              onAddClient={(id) => handleAddClient(id)}
            />}
            {!isPublic &&<div className="modal-form__row">
              <div className={`modal-form__item ${media('isMobile') ? 'modal-form__full-width' : ''}`}>
                <Controller
                  as={DropdownMulti}
                  name="payType"
                  items={payments}
                  data={{
                    title: "Форма оплаты",
                    identity: "payment",
                  }}
                  onChange={([value]) => value}
                  inputRef={register}
                  control={control}
                  isMobile={media('isMobile') && true}
                />
              </div>
            </div>}
            {!isPublic &&<div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="textarea textarea_type_form"
                  htmlFor="modal-reservation-commentary"
                >
                  <span className="textarea__label">Комментарий</span>
                  <textarea
                    className="textarea__control"
                    id="modal-reservation-commentary"
                    ref={register}
                    name="comment"
                  ></textarea>
                </label>
              </div>
            </div>}
            {!isPublic &&
            <FileUploader onFilesChange={setFiles} />}
            <div className="modal-form__row">
              <div className="modal-form__item">
                <div className="checkbox checkbox_view_invert">
                  <div
                    className={
                      "checkbox__box" +
                      (errors.agree ? " checkbox__box-error" : "")
                    }
                  >
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-reservation-agreement"
                      name="agree"
                      ref={register({
                        required:
                          "Необходимо согласиться с правилами обработки данных",
                      })}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-reservation-agreement"
                    ></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label
                    className="checkbox__label"
                    htmlFor="modal-reservation-agreement"
                  >
                    Cогласие на обработку персональных данных
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form__submit">
            <SubmitButton
              className="button button_view_default"
              isLoading={fetching}
              onClick={handleSubmit(onSubmit)}
              addStyle={isPublic &&  savedFilterSettings ? {backgroundColor: savedFilterSettings?.colorButton, color: savedFilterSettings?.colorButtonText} : {}}
            >
              <span>Отправить заявку</span>
            </SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.user?.info?.email,
    phone: state.user?.info?.phone,
    clients: ownProps.client
      ? [ownProps.client]
      : state.user?.clients?.map((client) => ({
          id: client.id,
          title: `${client.name} ${client.lastName}`,
        })),
    name: state.user?.info?.name
      ? `${state.user?.info?.name} ${state.user?.info?.lastName}`
      : "",
  };
};

export default connect(mapStateToProps, null)(ReserveApartment);
