import { useMediaQuery } from "beautiful-react-hooks";
import React, { useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import { getBankPrograms } from "../../actions/complex";
import { getUrl } from "../../api";
import { media } from "../../utils";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import BankInfo from "./BankInfo";
import {useSelector} from "react-redux";

const Bank = (props) => {
  const {
    name,
    logo,
    maxPeriod,
    minInitialPayment,
    minPercent,
    id,
    blockId
  } = props;
  const [bankInfo, setBankInfo] = useState({})
  const { complex, city } = useParams();

  const getProgram = () => {
    if(bankInfo.bankPrograms) return media("isMobile") ? openModalBankInfo() : openBankInfo()
    getBankPrograms({ blockId: complex || blockId, city, bankId: id }).then(data => {
      setBankInfo(data)
    }).finally(() => media("isMobile") ? openModalBankInfo() : openBankInfo())
  }

  const [openBankInfo, closeBankInfo] = useModal(({ in: open, onExited }) => (
    <Modal close={closeBankInfo} classes="modal_task modal-banks">
      <BankInfo {...bankInfo} />
    </Modal>
  ), [bankInfo]);

  const [openModalBankInfo, closeModalBankInfo] = useModal(({ in: open, onExited }) => (
    <MobileModal closeClick={closeModalBankInfo} title="Аккредитация">
      <BankInfo {...bankInfo} />
    </MobileModal>
  ), [bankInfo]);

  const savedFilterSettings = useSelector(state => state.complex.savedFilterSettings ?? {})

  return (
    <div class="banks__slider-item">
      <div class="banks__slider-inner">
        {logo && (
          <div class="banks__slider-image">
            <img src={getUrl(logo.src)} alt={name} />
          </div>
        )}
        <div class="banks__slider-content">
          <div class="banks__slider-conditions">
            <div class="banks__slider-condition">Процентная ставка</div>
            <div class="banks__slider-value" style={{color: savedFilterSettings?.colorButton}}>от {minPercent}%</div>
          </div>
          <div class="banks__slider-conditions">
            <div class="banks__slider-condition">Первоначальный взноc</div>
            <div class="banks__slider-value" style={{color: savedFilterSettings?.colorButton}}>от {minInitialPayment}%</div>
          </div>
          <div class="banks__slider-conditions">
            <div class="banks__slider-condition">Срок ипотеки</div>
            <div class="banks__slider-value" style={{color: savedFilterSettings?.colorButton}}>до {maxPeriod} лет</div>
          </div>
          <div class="banks__slider-more">
            {" "}
            <span
              class="banks__slider-link"
              data-id="modal-banks"
              onClick={getProgram}
            >
              Подробнее
              <svg class="icon icon_more">
                <use xlinkHref="#chevron"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Banks = (props) => {
  const { items, innerRef, type, blockId } = props;

  const node = useRef(null);
  const navRef = useRef(null);

  const responsive1600 = useMediaQuery("(min-width: 1600px)");
  const responsive1024 = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1599px)"
  );
  const responsive768 = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const responsiveOther = useMediaQuery("(max-width: 767px)");

  const [index, setIndex] = useState(1);

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          nav: !media("isMobile") ? false : true,
          loop: media("isMobile") ? false : true,
          controls: false,
          navAsThumbnails: media("isMobile") && false,
          navContainer: media("isMobile") ? `#complex-slider-thumbnails` : false,
          responsive: {
            600: {
              items: 2,
              gutter: 2,
            },
            768: {
              items: 3,
              gutter: 2,
            },
            1600: {
              items: type === 'assignment' ? 3 : 4,
              gutter: 2,
            },
          },
        }
        : {},
    [navRef]
  );

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const showNavigation = useMemo(() => {
    const count = items.length;
    if (count > 4 && responsive1600) {
      return true;
    } else if (count > 3 && responsive1024) {
      return true;
    } else if (count > 2 && responsive768) {
      return true;
    } else if (count > 1 && responsiveOther) {
      return true;
    }

    return false;
  }, [responsive1600, responsive1024, responsive768, responsiveOther, items]);

  return (
    <ErrorPlug wrappedComponent="src/comp-ts/Complex/Banks.js">
      <section class={`section section_view_white section__banks${type === 'assignment' ? ` section__banks_assignment` : ``}`} ref={innerRef}>
        <div class="wrapper">
          <div class="wrapper__center">
            <div class="block__title">
              {!media("isMobile") && <h2 class="h2">Аккредитованные банки </h2>}
            </div>
          </div>
          <div class={`banks__slider${type === 'assignment' ? ` wrapper__center` : ``}`}>
            {!media("isMobile") && showNavigation && (
              <div class="banks__slider-nav">
                <ul class="banks__slider-nav_wrap" id="banksSlider-nav">
                  <li
                    class="slider__nav-item prev"
                    onClick={() => moveSlider("prev")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    class="slider__nav-item next"
                    onClick={() => moveSlider("next")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            )}
            <div class="banks__slider-wrap" id="banksSlider">
                <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                  {items && items.map((item) => <Bank {...item} key={item.id} blockId={blockId} />)}
                </TinySlider>
              {media("isMobile") &&
                <>
                  <ul
                    class="complex__slider-thumbnails"
                    id="complex-slider-thumbnails"
                    ref={navRef}
                  >
                    {items.map((item, index) => (
                      <li class="complex__slider-thumbnails_item" key={index}>
                        <span aria-controls="base"></span>
                      </li>
                    ))}
                  </ul>

                  <span class="complex__slider-info">
                    {index} / {items.length}
                  </span>
                </>
              }
            </div>
          </div>
        </div>
      </section>
    </ErrorPlug>
  );
};

export default Banks;
