import { defaultExcludeEstate, defaultFilterEstate, defaultFullFilterEstate } from '../utils/defaultFilters';
import { handleFavorite } from './handlers';
const sortList = [
  {
    id: 'prices',
    title: 'По цене',
    defaultSortBy: "asc", // по возрастанию
    current: "asc", 
  },
  {
    id: 'subway',
    title: 'По удаленности',
    defaultSortBy: "asc",
    current: "asc",
  },
  {
    id: 'ending',
    title: 'По сроку сдачи',
    defaultSortBy: "desc",
    current: "desc",
  },
  {
    id: 'start',
    title: 'По старту продаж',
    defaultSortBy: "desc",
    current: "asc",// тк по умолчанию
  },
  {
    id: 'pop',
    title: 'По популярности',
    defaultSortBy: "asc",
    current: "asc", 
  }
];

const initState = {
  city: 'spb',
  filterData: {},
  filter: { ...defaultFullFilterEstate },
  items: [{ isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }],
  savedFilter: null,
  agent: null,
  // активность сохр. фильтра по ссылкам /public/*
  isActive: true,
  countApartments: null,
  countBlocks: null,
  countApartmentsTotal: null,
  countBlocksTotal: null,
  page: 1,
  pageCount: 9,
  totalPages: 1,
  sort: 'start',
  view: 'list',
  countBlocksToShow: null,
  countApartmentsToShow: null,
  fetching: true,
  features: [],
  mapItems: [],
  sortList,
};
export const switchSortBy = sortBy => sortBy === "asc" ? "desc" : "asc";

const estate = (state = initState, action) => {
  const {
    type,
    city,
    filterData,
    filter,
    items,
    page,
    pageCount,
    sort,
    view,
  } = action;
  switch (type) {
    case 'SET_FILTER_CITY':
      return { ...state, city: city };
    case 'ESTATE_FILTER_SUCCESS':
      return { ...state, filterData };
    case 'ESTATE_SET_FILTER':
      let tmpFilter = {
        filter: { ...state.filter.filter, ...filter.filter },
        exclude: { ...state.filter.exclude, ...filter.exclude },
      };
      return { ...state, filter: tmpFilter };
    case 'ESTATE_CLEAR_FILTER':
      return { ...state, filter: { ...defaultFullFilterEstate } };
    case 'ESTATE_CLEAR_FILTER_ONLY':
      const tmpFilterOnly = {
        filter: { ...state.filter.filter, ...defaultFilterEstate },
        exclude: { ...state.filter.exclude },
      };
      return { ...state, filter: tmpFilterOnly };
    case 'ESTATE_CLEAR_EXCLUDE_ONLY':
      const tmpExcludeOnly = {
        filter: { ...state.filter.filter },
        exclude: { ...state.filter.exclude, ...defaultExcludeEstate },
      };
      return { ...state, filter: tmpExcludeOnly };
    case 'ESTATE_REQUEST': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'ESTATE_SUCCESS':
      let tempState = {
        ...state,
        items: action.blocks,
        agent: action.agent,
        isActive: action.isActive,
        savedFilter: action.savedFilter,
        // filter: {
        //   filter: { ...defaultFullFilterEstate.filter, ...action.savedFilter.filter },
        //   exclude: { ...defaultFullFilterEstate.exclude, ...action.savedFilter.exclude },
        // },
        isShowFilter: action.isShowFilter,
        countApartments: action.countApartments,
        countBlocks: action.countBlocks,
        fetching: false,
        totalPages: 9,
      };
      if (action.filter) {
        tempState.filterData = action.filter;
        tempState.countApartmentsTotal = action.filter.countApartments
        tempState.countBlocksTotal = action.filter.countBlocks
      }
      if (action.isFirstRequest) {
        tempState.countBlocksToShow = action.countBlocks
      }
      return tempState;

    case 'ESTATE_FAILURE':
      return {
        ...state,
        fetching: false,
        isActive: false
      };
    case 'SET_ITEMS':
      return { ...state, items: items };
    case 'ESTATE_SET_PAGE':
      return { ...state, page: page };
    case 'ESTATE_SET_PAGE_COUNT':
      return { ...state, pageCount: pageCount };
    case 'SET_SORT':
      const tmpState = { ...state }
      const tmpSortList = [...sortList]
      const currentSortIndex = tmpSortList.findIndex(sortItem => sortItem.id === sort)
      if (sort === state.sort) {
        tmpSortList[currentSortIndex].current = switchSortBy(tmpSortList[currentSortIndex].current)
      } else {
        tmpSortList[currentSortIndex].current = switchSortBy(tmpSortList[currentSortIndex].defaultSortBy)
      }
      tmpState.sort = sort
      tmpState.sortList = tmpSortList
      return tmpState;
    case 'ESTATE_SET_VIEW':
      return { ...state, view: view };
    case 'ESTATE_SET_COUNT_BLOCKS':
      return {
        ...state,
        countBlocksToShow: action.countBlocks,
        countApartmentsToShow: action.countApartments,
      };
    case '@@router/LOCATION_CHANGE':
      if (
        action.payload.location.state &&
        action.payload.location.state.noClearFilter === false
      ) {
        return state;
      } else {
        return { ...state, filter: { ...defaultFullFilterEstate }, view: 'list', page: 1 };
      }
    case 'ESTATE_REQUEST_MAP': {
      return {
        ...state,
        fetchingMap: true,
      };
    }
    case 'ESTATE_SUCCESS_MAP':
      let features = [];

      if (action.blocks) {
        action.blocks.map((item) => {
          if (item.coordinates) {
            features.push({
              type: 'Feature',
              id: item.id,
              geometry: {
                type: 'Point',
                coordinates: item.coordinates,
              },
              properties: {
                hintContent: item.name,
              },
            });
          }

          return item;
        });
      }
      return {
        ...state,
        mapItems: action.blocks,
        features: features,
        fetchingMap: false,
      };

    case 'ADD_TO_FAVORITES':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'estate' ||
        action.data.whatPage === 'estateMap') {
        let pageIdentity = action.data.whatPage === 'estate' ? 'items' : 'mapItems';
        return { ...state, [pageIdentity]: handleFavorite(state[pageIdentity], action) }
      }
      return state
    default:
      return state;
  }
};

export default estate;
