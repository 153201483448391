import React, { memo } from 'react';
import LoadMoreSpinner from '../../LoadMoreSpinner';
import './index.css';

const ShowMore = memo(props => {
    const { onClick, loading, innerRef, customMoreTitle } = props;
    return (
        <div className="table__show-more" ref={innerRef}>
            {loading ? (
                <LoadMoreSpinner loading={true} />
            ) : (
                <div className="table__show-more_btn" onClick={() => onClick()}>
                    {customMoreTitle || `Показать еще${' '}`}
                    <svg className="icon icon_arrow-bottom">
                        <use xlinkHref="#arrow_bottom"></use>
                    </svg>
                </div>
            )}
        </div>
    );
});
export default ShowMore
