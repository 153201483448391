import isArray from "lodash/isArray";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getBanners } from "../../actions/banners";
import { fetchClients } from "../../actions/clients";
import {
  getCount, getSuburbans,
  setFilterSuburbans
} from "../../actions/suburbans";
import useDocTitle from "../../hooks/useDocTitle";
import { getFeatures, mapItems } from "../../selectors/suburbans";
import {
  filterToParams,
  getParserTypes,
  media,
  parseParams,
  serialize,
  typesWithArrayValues
} from '../../utils';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import Filter from "../FilterRealty/FilterSuburbans";
import PrepaidBanner from "../PrepaidBanner";
import Top from '../Secondaries/Top';
import "./index.scss";
import Items from "./Items";

const scrollToRef = (ref, offset = 80) => {
  try {
    window.scrollTo({
      top: media("isMobile") ? ref.current.offsetTop + offset : ref.current.offsetTop - offset,
      behavior: "smooth",
    });
  } catch {

  }
}

const Suburbans = (props) => {
  const {
    banners,
    clientMode,
    features,
    fetch,
    fetchClients,
    filter,
    getBanners,
    getCount,
    isAgent,
    items,
    location,
    mapItems,
    setFilter,
    countVillages,
    countVillagesToShow,
    top
  } = props;
  const { city, savedFilterXmlId } = useParams();
  const isPublic = !!savedFilterXmlId
  const [page, setPage] = useState("1")
  const [pageCount, setPageCount] = useState(9)
  const [fetching, setFetching] = useState(true);
  const [view, setView] = useState("list")
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const itemsRef = useRef(null);

  const history = useHistory();
  const queryParams = location.search;


  useEffect(() => {
    setFetching(true);
    if (!isPublic) {
      fetchClients();
      getBanners(window.location.pathname.replace("/app", ""))
    }
    let output = {};
    const customizer = (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    if (queryParams && queryParams != "?scroll") {
      let params = new URLSearchParams(queryParams);
      const temp = {};
      typesWithArrayValues.forEach(
        (v) => (temp[v] = { filters: [], exclude: [] })
      );
      for (const [key, value] of params) {
        if (!getParserTypes(key)) {
          break
        }
        let type = getParserTypes(key)[0];
        let subType = getParserTypes(key)[1];
        if (typesWithArrayValues.includes(subType)) {
          mergeWith(
            output,
            parseParams(key, value, typesWithArrayValues, temp[subType][type]),
            customizer
          );
        } else {
          merge(output, parseParams(key, value, typesWithArrayValues));
        }
      }
    }
    let newFilter = { ...filter, ...output };
    setFilter(newFilter);
    handleFilter(true, true, newFilter)
    let paramsFromFilter = filterToParams(newFilter);
    paramsFromFilter.city = city
    if(savedFilterXmlId){
      paramsFromFilter.savedFilterXmlId = savedFilterXmlId
    }
    getCount(paramsFromFilter);

  }, [city]);


  useDocTitle(`Загородная недвижимость "Санкт-Петербурга"`)

  // city === undefined, пока скрыл, поправить
  //useDocTitle(`Загородная недвижимость ${city === "spb" ? "Санкт-Петербурга" : "Москвы"}`)

  const handleFilter = (isGetFilter = false, disableQueryParams = false, newFilter, newPage, limit) => {
    const paramsFromFilter = filterToParams(newFilter ? newFilter : filter);
    if (!disableQueryParams) {
      history.push({
        search: `?${serialize(paramsFromFilter)}`,
      });
    }
    setFetching(true);

    paramsFromFilter.page = newPage || newPage === 1 ? newPage : page
    paramsFromFilter.limit = limit || limit === 0 ? limit : pageCount
    paramsFromFilter.isGetFilter = isGetFilter ? 1 : 0
    paramsFromFilter.city = city
    paramsFromFilter.savedFilterXmlId = savedFilterXmlId
    fetch(paramsFromFilter).then(data => setFetching(false))
  };
  useEffect(() => {
    if (view === "map" || page === "1") return
    if (page !== "1") {
      scrollToRef(itemsRef);
    }
    handleFilter(false, true)
  }, [page])

  const setCountOnPage = (pageCount) => {
    setPageCount(pageCount);
    setPage(1);
    scrollToRef(itemsRef);
    handleFilter()
  };

  const handleChildClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
  }

  const handleCloseClick = () => {
    setIsFiltersOpen(false);
  }

  const handleViewChange = (newView) => {
    if (newView === "map") {
      setPageCount(0)
    } else {
      setPageCount(9)
    }
    setPage(1)
    setView(newView)
    handleFilter(false, true, null, ...(newView === "map" ? [1, 0] : []))
  }

  return (
    <div>
       <ErrorPlug wrappedComponent="comp-ts/Suburbans/index.jsx - 173">
      <Filter
        onSubmit={(params) => {
          handleFilter(params);
          scrollToRef(itemsRef);
        }}
        city={city}
        fetching={fetching}
        isFiltersOpen={isFiltersOpen}
        onClick={handleCloseClick}
        isPublic={isPublic}
        savedFilterXmlId={savedFilterXmlId}
      />
      </ErrorPlug>
      {top?.length > 0 && !isPublic && (
        <Top
          isAgent={isAgent}
          items={top}
          clientMode={clientMode}
          title={`Лучшие предложения`}
          isSuburbans={true}
          wrapperStyle={(banners?.assignments || banners?.before) && !clientMode ? { paddingBottom: 0 } : null}
        />
      )}
      {banners?.before && !isPublic && <div className="wrapper">
        <PrepaidBanner bannerId="before" banners={banners} />
      </div>}
      <ErrorPlug wrappedComponent="comp-ts/Suburbans/index.jsx - 199">
      <Items
        items={items}
        innerRef={itemsRef}
        features={features}
        mapItems={mapItems}
        countBlocks={countVillages}
        view={view}
        handleView={handleViewChange}
        page={page}
        handlePage={setPage}
        pageCount={pageCount}
        handlePageCount={setCountOnPage}
        fetching={fetching}
        city={city || "spb"}
        filter={filter}
        clientMode={clientMode}
        isAgent={isAgent}
        onClick={handleChildClick}
        banners={banners}
        isPublic={isPublic}
        savedFilterXmlId={savedFilterXmlId}
        foundTitle={`Найдено посёлков: ${countVillages}`}
      />
       </ErrorPlug>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilter: (filter) => dispatch(setFilterSuburbans(filter)),
  getCount: (params) => getCount(params, "suburbans", dispatch),
  fetchClients: () => dispatch(fetchClients()),
  getBanners: (url) => dispatch(getBanners(url)),
  fetch: (form) => getSuburbans(form, dispatch)
});

const mapStateToProps = (state) => {
  return {
    filterData: state.suburbans?.filterData,
    filter: state.suburbans?.filter,
    items: state.suburbans?.items,
    top: state.suburbans?.topVillages,
    countVillages: state.suburbans.countVillages,
    countVillagesToShow: state.suburbans.countVillagesToShow,
    features: getFeatures(state),
    mapItems: mapItems(state),
    isAgent: !state.user?.info?.groups?.some((group) => group === "master_account" || group === "general_director" || group === "agent_extended") || state.user.info.groups.includes("test_access"),
    clientMode: state.general?.clientMode,
    banners: state?.banners,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suburbans);
