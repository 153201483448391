const initialState = {
  header: null
}

const refs = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REF":
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};

export default refs;
