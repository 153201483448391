import React from "react";

const ThemeBg1 = (props) => {
  const {currentColor} = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 386 226" style={{enableBackground: 'new 0 0 386 226'}} xmlSpace="preserve">
      <g>
        <g>
          <path fill={currentColor} d="M5,0h376c2.8,0,5,2.2,5,5v216c0,2.8-2.2,5-5,5H5c-2.8,0-5-2.2-5-5V5C0,2.2,2.2,0,5,0z" />
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="330.9835" y1="79.6661" x2="138.2457" y2="259.3078">
            <stop offset="5.558972e-04" style={{stopColor: '#FFFFFF', stopOpacity: '0.2'}} />
            <stop offset={1} style={{stopColor: '#FFFFFF', stopOpacity: '5.000000e-02'}} />
          </linearGradient>
          <polygon fill="url(#SVGID_1_)" points="204.4,0 49.2,226 386,226 386,0" />
        </g>
        <path fill="#FFFFFF" d="M77.1,216.2c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7   C75.4,215.4,76.1,216.2,77.1,216.2z" />
        <g>
          <path fill="#FFFFFF" d="M303.1,98.1c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C301.5,97.3,302.2,98.1,303.1,98.1z" />
          <path fill="#FFFFFF" d="M231.6,42.1c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2    C225.4,39.3,228.2,42.1,231.6,42.1z" />
          <path fill="#FFFFFF" d="M231.6,80c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2C225.4,77.3,228.2,80,231.6,80    z" />
          <path fill="#FFFFFF" d="M247.9,51.5c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2    C241.8,48.7,244.5,51.5,247.9,51.5z" />
          <path fill="#FFFFFF" d="M215.4,70.7c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2    C209.2,67.9,212,70.7,215.4,70.7z" />
          <path fill="#FFFFFF" d="M248.2,70.3c3.4,0,6.2-2.8,6.2-6.2s-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2S244.7,70.3,248.2,70.3z" />
          <path fill="#FFFFFF" d="M215.1,51.9c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2c-3.4,0-6.2,2.8-6.2,6.2    C208.9,49.1,211.6,51.9,215.1,51.9z" />
          <path fill="#FFFFFF" d="M200.1,41.3c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C195.2,39.1,197.4,41.3,200.1,41.3z" />
          <path fill="#FFFFFF" d="M264.4,78.3c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C259.5,76.1,261.7,78.3,264.4,78.3z" />
          <path fill="#FFFFFF" d="M195.2,59.8c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C190.2,57.5,192.4,59.8,195.2,59.8z" />
          <path fill="#FFFFFF" d="M269.3,59.9c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C264.4,57.7,266.6,59.9,269.3,59.9z" />
          <path fill="#FFFFFF" d="M200,78.2c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9S197.3,78.2,200,78.2z" />
          <path fill="#FFFFFF" d="M264.4,41.4c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9S261.7,41.4,264.4,41.4z" />
          <path fill="#FFFFFF" d="M213.7,92c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9C208.8,89.8,211,92,213.7,92z" />
          <path fill="#FFFFFF" d="M250.7,27.6c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C245.7,25.4,247.9,27.6,250.7,27.6z" />
          <path fill="#FFFFFF" d="M232.1,97c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9C227.2,94.8,229.4,97,232.1,97    z" />
          <path fill="#FFFFFF" d="M232.2,22.6c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C227.3,20.4,229.5,22.6,232.2,22.6z" />
          <path fill="#FFFFFF" d="M250.7,92.1c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9S247.9,92.1,250.7,92.1z" />
          <path fill="#FFFFFF" d="M213.8,27.5c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9    C208.9,25.3,211.1,27.5,213.8,27.5z" />
          <path fill="#FFFFFF" d="M186.7,32.2c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C183.1,30.6,184.7,32.2,186.7,32.2    z" />
          <path fill="#FFFFFF" d="M277.5,84.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S275.5,84.6,277.5,84.6z" />
          <path fill="#FFFFFF" d="M180.5,49.1c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S178.5,49.1,180.5,49.1z" />
          <path fill="#FFFFFF" d="M283.7,67.7c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C280.1,66.1,281.7,67.7,283.7,67.7    z" />
          <path fill="#FFFFFF" d="M180.5,67.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S178.5,67.6,180.5,67.6z" />
          <path fill="#FFFFFF" d="M283.7,49.1c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C280.1,47.6,281.7,49.1,283.7,49.1    z" />
          <path fill="#FFFFFF" d="M186.7,84.8c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S184.7,84.8,186.7,84.8z" />
          <path fill="#FFFFFF" d="M277.5,32.1c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C273.9,30.5,275.5,32.1,277.5,32.1    z" />
          <path fill="#FFFFFF" d="M198.5,98.7c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S196.5,98.7,198.5,98.7z" />
          <path fill="#FFFFFF" d="M265.7,18.2c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S263.8,18.2,265.7,18.2z" />
          <path fill="#FFFFFF" d="M214,107.8c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S212,107.8,214,107.8z" />
          <path fill="#FFFFFF" d="M250.1,9.1c2,0,3.6-1.6,3.6-3.6S252.1,2,250.1,2c-2,0-3.6,1.6-3.6,3.6S248.2,9.1,250.1,9.1z" />
          <path fill="#FFFFFF" d="M232.2,111c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S230.3,111,232.2,111z" />
          <path fill="#FFFFFF" d="M250,107.9c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S248.1,107.9,250,107.9z" />
          <path fill="#FFFFFF" d="M214.2,9c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6S212.3,9,214.2,9z" />
          <path fill="#FFFFFF" d="M265.9,98.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6S263.9,98.6,265.9,98.6z" />
          <path fill="#FFFFFF" d="M198.3,18.3c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C194.7,16.7,196.3,18.3,198.3,18.3    z" />
          <path fill="#FFFFFF" d="M173.9,23.7c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4    C171.5,22.7,172.6,23.7,173.9,23.7z" />
          <path fill="#FFFFFF" d="M290.1,91.1c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S288.8,91.1,290.1,91.1z" />
          <path fill="#FFFFFF" d="M167.2,40c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S165.9,40,167.2,40z" />
          <path fill="#FFFFFF" d="M296.8,74.9c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S295.5,74.9,296.8,74.9z" />
          <path fill="#FFFFFF" d="M164.9,57c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C162.5,55.9,163.6,57,164.9,57    z" />
          <path fill="#FFFFFF" d="M299.2,57.8c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4    C296.7,56.7,297.8,57.8,299.2,57.8z" />
          <path fill="#FFFFFF" d="M167.2,74.9c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S165.9,74.9,167.2,74.9z" />
          <path fill="#FFFFFF" d="M296.8,40c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C294.4,38.9,295.5,40,296.8,40    z" />
          <path fill="#FFFFFF" d="M173.9,91.2c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S172.6,91.2,173.9,91.2z" />
          <path fill="#FFFFFF" d="M290.1,23.7c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S288.8,23.7,290.1,23.7z" />
          <path fill="#FFFFFF" d="M184.6,105c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S183.3,105,184.6,105z" />
          <path fill="#FFFFFF" d="M279.5,9.9c1.3,0,2.4-1.1,2.4-2.4S280.8,5,279.5,5c-1.3,0-2.4,1.1-2.4,2.4S278.1,9.9,279.5,9.9z" />
          <path fill="#FFFFFF" d="M198.4,115.7c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S197.1,115.7,198.4,115.7z" />
          <path fill="#FFFFFF" d="M214.6,122.5c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S213.3,122.5,214.6,122.5z" />
          <path fill="#FFFFFF" d="M232.2,124.8c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S230.9,124.8,232.2,124.8z" />
          <path fill="#FFFFFF" d="M249.4,122.5c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S248.1,122.5,249.4,122.5z" />
          <path fill="#FFFFFF" d="M265.6,115.7c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4S264.3,115.7,265.6,115.7z" />
          <path fill="#FFFFFF" d="M279.6,105c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S278.2,105,279.6,105z" />
          <path fill="#FFFFFF" d="M184.6,9.9c1.3,0,2.4-1.1,2.4-2.4S185.9,5,184.6,5c-1.3,0-2.4,1.1-2.4,2.4S183.3,9.9,184.6,9.9z" />
          <path fill="#FFFFFF" d="M310.3,82.1c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S309.3,82.1,310.3,82.1z" />
          <path fill="#FFFFFF" d="M154,31.4c0.9,0,1.7-0.8,1.7-1.7S154.9,28,154,28s-1.7,0.8-1.7,1.7S153.1,31.4,154,31.4z" />
          <path fill="#FFFFFF" d="M313.7,65.4c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S312.8,65.4,313.7,65.4z" />
          <path fill="#FFFFFF" d="M150.6,48.1c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S149.6,48.1,150.6,48.1z" />
          <path fill="#FFFFFF" d="M313.8,48.7c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S312.9,48.7,313.8,48.7z" />
          <path fill="#FFFFFF" d="M150.4,64.9c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S149.5,64.9,150.4,64.9z" />
          <path fill="#FFFFFF" d="M310.4,31.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C308.7,31,309.4,31.7,310.4,31.7z" />
          <path fill="#FFFFFF" d="M154,81.8c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7C152.3,81.1,153.1,81.8,154,81.8z" />
          <path fill="#FFFFFF" d="M303.3,15.8c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C301.7,15,302.4,15.8,303.3,15.8z" />
          <path fill="#FFFFFF" d="M161,97.8c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C159.3,97,160.1,97.8,161,97.8    z" />
          <path fill="#FFFFFF" d="M171.2,111.8c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C169.5,111.1,170.3,111.8,171.2,111.8z" />
          <path fill="#FFFFFF" d="M183.6,123.2c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C182,122.4,182.7,123.2,183.6,123.2z" />
          <path fill="#FFFFFF" d="M198.6,131.9c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S197.7,131.9,198.6,131.9z" />
          <path fill="#FFFFFF" d="M215.2,137.4c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C213.5,136.6,214.2,137.4,215.2,137.4z" />
          <path fill="#FFFFFF" d="M232.1,139.2c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S231.2,139.2,232.1,139.2z" />
          <path fill="#FFFFFF" d="M249.3,137.3c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C247.6,136.5,248.4,137.3,249.3,137.3z" />
          <path fill="#FFFFFF" d="M265.5,132c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C263.9,131.3,264.6,132,265.5,132z" />
          <path fill="#FFFFFF" d="M280.6,123.2c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C278.9,122.4,279.7,123.2,280.6,123.2z" />
          <path fill="#FFFFFF" d="M293.2,111.8c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C291.5,111.1,292.3,111.8,293.2,111.8z" />
          <path fill="#FFFFFF" d="M161.1,15.5c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7    C159.5,14.8,160.2,15.5,161.1,15.5z" />
          <path fill="#FFFFFF" d="M169.4,0c0,0,0,0.1,0,0.1c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7c0,0,0-0.1,0-0.1H169.4z" />
          <path fill="#FFFFFF" d="M293.1,1.7c0.9,0,1.7-0.8,1.7-1.7h-3.4C291.4,0.9,292.2,1.7,293.1,1.7z" />
          <path fill="#FFFFFF" d="M229.2,0c-0.5,0.6-0.9,1.4-0.9,2.3c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-0.9-0.3-1.7-0.9-2.3H229.2z" />
        </g>
      </g>
    </svg>
  )
};

export default ThemeBg1;
