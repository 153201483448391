import './index.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppPopover from '../Popover';
import CopyToClipboard from 'react-copy-to-clipboard';

// *** buttonType ***
// primary - красная
// primary_gray - темно-серый фон
// line - белая с обводкой
// secondary - светло-серый фон
// clean - пустая, без padding

const Button = ({
  isSubmit = false, // отправка форм
  withIcon = false,
  buttonType = 'primary',
  isLink = false, // меняет кнопку на ссылку
  isDownload = false,
  to = '/', // путь для ссылки
  isFull = false, // во всю ширину
  target = '_self',
  addClass = '',
  onClick,
  copy = null, // Копирование в буфер параметра copy
  children,
  disabled = false
}) => {
  const btnClass = classNames({
    'btn h6': true,
    [`btn_${buttonType}`]: true,
    btn_full: isFull,
    [`${addClass}`]: true,
  });

  return isLink ? (
    <a href={to} className={btnClass} target={target} download={isDownload}>
      {children}
    </a>
  ) : copy ? (
    <AppPopover
      trigger="click"
      content="Ссылка скопирована"
      overlayClassName={'share-popover'}
      addBtnClass={btnClass}
    >
      <CopyToClipboard text={copy}>
        <span>
          {children}
        </span>
      </CopyToClipboard>
    </AppPopover>
  ) : (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={btnClass}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
