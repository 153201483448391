import React, {useState} from "react";
import Button from "../../core/Button";
import NewAlert from "../../core/NewAlert";
import request, {getUrl} from "../../../api";
import SubmitButton from "../../SubmitButton";

const ErrorReport = ({id, city, type}) => {


  const [errorText, setErrorText] = useState("")
  const [errorHint, setErrorHint] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const sendError = () => {
    setIsLoading(true)
    return request('panpartner:block.detail.ajax', 'sendErrorDescription', {
      id: id,
      city: city,
      type: type,
      text: errorText
    })
      .then((data) => {
        setIsLoading(false)
        setErrorText("")
        setErrorHint(data.message)
      })
  }

  return (
    <div className="error-report">
      <p className="h5-l" style={{width: '100%', textAlign: 'center', marginBottom: 20}}>Нашли ошибку в описании объекта?<br/>Сообщите нам об этом.</p>
      <div className="error-report__area">
        <label
          className="textarea textarea_type_form"
          htmlFor="modal-reservation-commentary"
        >
          <span className="textarea__label">Опишите проблему</span>
          <textarea
            style={{minHeight: 87}}
            className="textarea__control"
            onChange={(e) => setErrorText(e.target.value)}
            value={errorText}
          />
        </label>
      </div>
      {errorHint ?
        <NewAlert
          title={errorHint}
          bgColor="#DFF5E0"
          iconColor="#2CBA2E"
        /> :
        <SubmitButton
        className="btn h6 btn_primary "
        addStyle={{width: '100%'}}
        isLoading={isLoading}
        onClick={errorText && sendError}
        >
        Отправить
        </SubmitButton>
      }


    </div>
  )
}

export default ErrorReport
