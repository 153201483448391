import React, { useState } from "react";
import { unsubscribe } from "../../actions/forms";
import { ReactComponent as UnsubImg1 } from "../../assets/images/unsub/unsub-1.svg";
import { ReactComponent as UnsubImg2 } from "../../assets/images/unsub/unsub-2.svg";
import { standartErrorToast, successToast } from "../../components/toasts";
import { objectFromSearchParams } from "../../utils";
import './index.scss';

const Unsub = () => {

  const [actionStatus, setActionStatus] = useState(false);

  const unsubClickHandler = () => {
    const params = objectFromSearchParams()
    unsubscribe(params).then(()=>{
      successToast("Вы успешно отписаны от рассылок")
      setActionStatus(true);
    }, standartErrorToast)
  };

  return (

  <section className="section section_view_white">
    <div className="wrapper">
      <div className="not-found-wrapper unsub">
        <div className="not-found-content">
          {
            !actionStatus ?
            <>
              <UnsubImg1 />
              <h2 className="h2 unsub__title">Отписаться от рассылки</h2>
              <span className="unsub__second-text big-text">
                Если Вы больше не хотите получать от нас письма, Вы можете отписаться от рассылки
              </span>
              <button
                className="button button_view_default unsub__btn"
                onClick={unsubClickHandler}
              >
                Отписаться
              </button>
            </> :

            <>
              <UnsubImg2 />
              <h2 className="h2 unsub__title">Вы успешно отписаны</h2>
            </>
          }
        </div>
      </div>
    </div>
    </section>
  );
};

export default Unsub;
