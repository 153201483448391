import { Table as AntdTable } from 'antd';
import React, {useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import useDebounceState from "../../hooks/useDebounceState";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import FilterSkeleton from "../../pages/Crm/FilterSkeleton";
import { dealsColumns } from "../../pages/Crm/columns";
import { filterToParamDeals } from "../../pages/Requests/utils";
import SelectFilter from "../FilterRealty/SelectFilter";
import LoadMoreSpinner from "../LoadMoreSpinner";
import Loader from "../Loader";
import SearchInp from "../core/SearchInput";
import { LocaleNotFound } from "../core/Table/tableUtils";
import DatepickerDropdown from '../core/DatepickerDropdown';
import {useHistory} from "react-router-dom";

const BackDeals = ({fromCrm}) => {

  const {
    state: filterState,
    setValue,
    reset,
    setValues
  } = useUniversalReducer({});

  const { data: {
    deals = [],
    filters = {},
    countAllDeals,
    countDeals,
    meta
  },
    isLoading,
    refresher,
    isRefreshing,
    loadMore: loadMoreRequest,
    moreIsLoading,
    send,
    isSending,
    setData
  } = useApi({
    payload: ["panpartner:deals.ajax", "getDealsList", { isGetFilter: 1 }],
    processData: (resp, prev) => ({ ...prev, ...resp })
  })

  const [debouncedValues, debouncedHandleChange, setDebouncedValues] = useDebounceState({
    setState: (prev, v) => v,
    fn: search => refresher(["panpartner:deals.ajax", "getDealsList", search ? { 'filter[search]': search } : {}]),
    defaultState: "",
    delay: 400
  })

  const loadMore = () => {
    const page = deals[deals.length - 1].id
    const params = filterToParamDeals({ page }, filterState)
    if (params['filter[dateCreate]']) {
      delete params['filter[dateCreate]'];
      params['filter[dateCreate][min]'] = formatDate(selectDates.startDate)
      params['filter[dateCreate][max]'] = formatDate(selectDates.endDate)
    }
    loadMoreRequest(["panpartner:deals.ajax", "getDealsList", params], "deals", resp => ({ countAllDeals: resp.countAllDeals, countDeals: resp.countDeals }))
  }
  const history = useHistory();

  const [selectDates, setSelectDates] = useState({})
  function formatDate(inputDate) {
    const parts = inputDate.split('.');
    const formattedDate = `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    return formattedDate;
  }
  useEffect(() => {
    if (!filters) return
    const params = filterToParamDeals({}, filterState)
    if (params['filter[dateCreate]']) {
      delete params['filter[dateCreate]'];
      params['filter[dateCreate][min]'] = formatDate(selectDates.startDate)
      params['filter[dateCreate][max]'] = formatDate(selectDates.endDate)
    }
    send(["panpartner:deals.ajax", "getDealsList", params]).then(resp => setData(prev => ({ ...prev, ...resp })))
  }, [filterState])

  const filterDates = (v) => {
    const formattedDates = {};
    for (const key in v) {
      if (Object.hasOwnProperty.call(v, key)) {
        const date = new Date(v[key]);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        formattedDates[key] = `${day}.${month}.${year}`;
      }
    }
    return formattedDates
  }

  const filterMyClients = () => {
    if (filterState.onlySelfDeals === undefined) {
      setValue("onlySelfDeals", filters?.onlySelfDeals)
    }
    if (filterState.onlySelfDeals !== undefined) {
      setValue("onlySelfDeals", undefined)
    }
  }

  
  if (isLoading) return <section style={fromCrm ? {marginTop: '-15px'} : {}} className="section section_view_white orders"><div className="wrapper" style={{textAlign: 'center'}}><FilterSkeleton /><Loader loaderColor='#2c2e3e'/></div></section>
  
  const filterStatus = filters?.status.reduce((acc, item) => {
    item.id.forEach(id => {
      acc.push({
        id,
        sort: item.sort,
        title: item.title
      });
    });
    return acc;
  }, []);
 
  return (
    <div className="wrapper" style={fromCrm ? {marginTop: '-15px'} : {}}>
      <div className="crm-filters-header">
        {filters && <div className="crm-filters-header__first-row crm-filters-header__first-row_back" style={{position: 'relative'}}>
          <div className="crm-filters-header__left-cell">
            <div className={`crm-filters-header__search`}>
              <SearchInp
                value={debouncedValues}
                onChange={e =>debouncedHandleChange(e.target.value)}
                isSearching={isRefreshing}
                reset={() => debouncedHandleChange("")}
                placeholder={'Фио клиента'}
              />
            </div>
            <div className="crm-filters-header__select">
              <SelectFilter
                items={filters?.agent}
                filterValues={filterState.agent}
                onChange={v => setValue("agent", v)}
                isAutocomplete={filters?.agent?.length > 5}
                wrapperClass={"custom-select"}
                data={{
                  title: "Сотрудник",
                  autocompleteTitle: "Введите имя сотрудника",
                  identity: "agent",
                }}
              />
            </div>
            <div className="crm-filters-header__select">
              <SelectFilter
                items={filterStatus}
                filterValues={filterState.status}
                onChange={v => setValue("status", v)}
                wrapperClass={"custom-select"}
                data={{
                  title: "Статус",
                  autocompleteTitle: "Введите название статуса",
                  identity: "status",
                }}
              />
            </div>
            <div className="crm-filters-header__select">
              <DatepickerDropdown
                onChange={v => {
                  setSelectDates(filterDates(v))
                  setValue("dateCreate", v)
                }}
                value={filterState.dateCreate || {
                  startDate: null,
                  endDate: null,
                }}

                label={Object.keys(selectDates).length !== 0 ? selectDates.startDate + ' - ' + selectDates.endDate : false}
                clearFilters={() => {
                  setSelectDates(
                    {
                      startDate: filterDates(filters?.dateCreate.range).min,
                      endDate: filterDates(filters?.dateCreate.range).max
                    }
                  )
                  setValues(
                    {...filterState, dateCreate:
                      {...filterState.dateCreate,
                        endDate: new Date(filters?.dateCreate.range.max),
                        startDate: new Date(filters?.dateCreate.range.min)
                      }
                    }
                  )
                }}
                crmIntervalFilter
              />
            </div>
          </div>
        </div>}
        {filters?.onlySelfDeals &&
          <div className="crm-filters-header__second-row">
          <div className="checkbox checkbox_view_red" onChange={filterMyClients}>
            <div className="checkbox__box">
              <input className="checkbox__control" type="checkbox" id="crm-checkbox-selfClient" name="notify"/>
              <label className="checkbox__input" htmlFor="crm-checkbox-selfClient"/>
              <div className="checkbox__marker"></div></div>
            <label className="checkbox__label" htmlFor="crm-checkbox-selfClient">Только мои клиенты</label>
          </div>
        </div>
        }
      </div>
      <div className="crm-table crm-table_filters crm-table_filters-deals">
        <AntdTable
          rowKey="id"
          columns={dealsColumns}
          dataSource={isSending ? false : deals}
          locale={isSending ? LocaleLoading : LocaleNotFound}
          pagination={false}
          rowClassName={(record, index) => ((index + 1) % 2 === 0 ? "ant-table-second-color" : "")}
          onRow={row => ({
            onClick: e => {
              history.push(`/crm/deals/${row.id}`)
            }
          })}
        />
        {countAllDeals > deals.length && deals.length >= 50 && countDeals > 0 && !isSending && <ShowMore loading={moreIsLoading} onClick={loadMore} />}
      </div>
    </div>
  )
}

export default BackDeals

const ShowMore = (props) => {
  const { onClick, loading, innerRef, customMoreTitle } = props;
  return (
    <div className="table__show-more" ref={innerRef}>
      {loading ? (
        <LoadMoreSpinner loading={true} />
      ) : (
        <div className="table__show-more_btn" onClick={() => onClick()}>
          {customMoreTitle || `Показать еще${' '}`}
          <svg className="icon icon_arrow-bottom">
            <use xlinkHref="#arrow_bottom"></use>
          </svg>
        </div>
      )}
    </div>
  );
};

const LocaleLoading = {
  emptyText: <ShowMore loading={true} />
};
