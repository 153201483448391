import formatter from "format-number";
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.min.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { media } from "../../utils";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import InstallmentForm from "../forms/InstallmentForm";
import MortgageForm from "../forms/MortgageForm";
import { successToast } from "../toasts";

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const MortgageBlock = (props) => {
  const [tab, setTab] = useState("mortgage");

  const {
    id,
    price,
    period,
    prepay,
    percent,
    city,
    type,
    isPublic,
    showInstallment = true,
    blockId
  } = props;

  const [calc, setCalc] = useState({
    price: price,
    period: period,
    prepay: prepay,
    percent: percent,
    total: 0,
  });

  const [total, setTotal] = useState();

  const prepayRef = useRef(null);

  const getTotal = useMemo(() => total, [total]);

  const [maxPrepay, setMaxPrepay] = useState(0);

  const changeTotal = (tmpCalc) => {
    let { price, period, prepay, percent } = tmpCalc;
    let payment = price - prepay;
    let m = percent / 100 / 12;
    let koef =
      (m * Math.pow(1 + m, period * 12)) / (Math.pow(1 + m, period * 12) - 1);
    let result = (payment * koef).toFixed();
    setTotal(result);
    handleChange("total", result);
  };

  const handleChange = (name, value) => {
    let tmpCalc = calc;
    tmpCalc[name] = value;
    setCalc(tmpCalc);
    if (name !== "total") {
      changeTotal(tmpCalc);
    }
    if (name === "price") {
      setMaxPrepay(value);
    }
  };

  useEffect(() => {
    initInputSliders();
  }, []);

  /*
  useEffect(() => {
    if (prepayRef !== undefined) {
      prepayRef.current.noUiSlider.updateOptions({
        range: {
          min: 100000,
          max: maxPrepay,
        },

      });
    }
  }, [maxPrepay]);*/

  const formatNumbers = (n) => {
    const val = (n + "").split(" ").join("");
    n = val;
    return (n + "")
      .split("")
      .reverse()
      .join("")
      .replace(/(\d{3})/g, "$1 ")
      .split("")
      .reverse()
      .join("")
      .replace(/^ /, "");
  };

  const initInputSliders = () => {
    const slidersContainers = [...document.querySelectorAll(".slider-input")];

    slidersContainers.forEach((container) => {
      const valueInput = container.querySelector(".slider-input__control");
      const slider = container.querySelector(".slider__input");
      const postfix = container.dataset.postfix;
      const isPrice = container.dataset.isPrice !== undefined;
      const start = Number(container.dataset.start);
      const step = Number(container.dataset.step);
      let min = Number(container.dataset.min);
      let max = Number(container.dataset.max);
      const name = valueInput.name;
      if(min === max){
        min += 1
      }
      noUiSlider.create(slider, {
        start,
        connect: [true, false],
        range: {
          min,
          max,
        },
        step,
        format: {
          to: function (value) {
            let result = step % 1 > 0 ? value.toFixed(1) : parseInt(value);
            return formatNumbers(result) + postfix;
          },
          from: function (value) {
            if (postfix)
              return Number(value.replace(postfix, "").replace(/\ /g, ""));
            let n = value.replace(/\,/g, ".");
            return Number(n.replace(/\ /g, ""));
          },
        },
      });

      slider.noUiSlider.on("update", function (values, handle) {
        valueInput.value = values[handle];
        handleChange(
          name,
          Number(values[handle].replace(postfix, "").replace(/\ /g, ""))
        );
      });

      valueInput.addEventListener("change", function () {
        slider.noUiSlider.set(this.value);
      });

      valueInput.addEventListener("input", function () {
        // Сохраняем позицию курсора
        let pos = this.selectionStart;
        const prevLength = this.value.length;

        this.value = formatNumbers(this.value);
        const newLength = this.value.length;

        // Возвращаем курсор к своей настоящей позиции
        pos = pos + (newLength - prevLength);
        this.setSelectionRange(pos, pos);
      });
    });

    const allPriceSliders = [...document.querySelectorAll(".price-input")];
    allPriceSliders.forEach((slider) => {
      let sliderName = slider.name;
      if (sliderName !== "price") {
        slider.addEventListener("focus", function () {
          this.value = this.value.replace(" р.", "");
        });

        slider.addEventListener("blur", function () {
          if (!this.value) return;
          this.value = this.value.replace(" р.", "") + " р.";
        });
      }
    });
  };

  const submitMortgage = () => {
    media("isMobile") ? closeMortgageMobileForm() : closeMortgageForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const submitInstallment = () => {
    media("isMobile") ? closeInstallmentMobileForm() : closeInstallmentForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const [openMortgageForm, closeMortgageForm] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeMortgageForm} classes="modal_task">
        <MortgageForm
          handleSend={() => submitMortgage()}
          id={id}
          calc={calc}
          total={getTotal}
          city={city}
          type={type}
          blockId={blockId}
        />
      </Modal>
    )
  );

  const [openMortgageMobileForm, closeMortgageMobileForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={closeMortgageMobileForm} title="Рассчитать ипотеку">
        <MortgageForm
          handleSend={() => submitMortgage()}
          id={id}
          calc={calc}
          total={getTotal}
          city={city}
          type={type}
          blockId={blockId}
        />
      </MobileModal>
    )
  );

  const [openInstallmentForm, closeInstallmentForm] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeInstallmentForm} classes="modal_task">
        <InstallmentForm
          handleSend={() => submitInstallment()}
          id={id}
          city={city}
          type={type}
        />
      </Modal>
    )
  );

  const [openInstallmentMobileForm, closeInstallmentMobileForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={closeInstallmentMobileForm} title="Рассчитать рассрочку">
        <InstallmentForm
          handleSend={() => submitInstallment()}
          id={id}
          city={city}
          type={type}
        />
      </MobileModal>
    )
  );

  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="wrapper__center">
          <div className="calculators light__calc">
            <div className="calculators__nav">
              {media("isMobile") ?
                <div className="calculators__mobile-nav">
                  <h3>Калькулятор ипотеки</h3>
                  {!isPublic && showInstallment && (
                    <span
                      className="installment-link"
                      onClick={media("isMobile") ? openInstallmentMobileForm : openInstallmentForm}
                    >
                      Рассрочка
                    </span>
                  )}
                </div>
                 :
                <ul className="calculators__navs">
                  <li
                    className={`calculators__navs-item ${
                      tab === "mortgage" ? `active` : ``
                    }`}
                    onClick={() => setTab("mortgage")}
                  >
                    Калькулятор ипотеки
                  </li>
                  {!isPublic && showInstallment && (
                    <li
                      className={`calculators__navs-item`}
                      onClick={openInstallmentForm}
                    >
                      Рассрочка
                    </li>
                  )}
                </ul>
              }
            </div>
            <div className="calculators__container">
              <div
                className={`calculators__tab ${
                  tab === "mortgage" ? `active` : ``
                }`}
              >
                <div className="calculator">
                  <div className="calculator__grid">
                    <div className="calculator__form">
                      <div
                        className="calculator__item slider-input"
                        data-start={price}
                        data-min={0}
                        data-max={price}
                        data-step={50000}
                        data-postfix=" р."
                        data-is-price
                      >
                        <label htmlFor="mortgage-price">Стоимость {type ==="commercial"? "помещения" : "квартиры"}</label>
                        <div className="calculator__item-wrapper">
                          <input
                            className="slider-input__control price-input"
                            type="text"
                            id="mortgage-price"
                            name="price"
                            readOnly
                          />
                        </div>
                        <div
                          className="slider__input"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div
                        className="calculator__item slider-input"
                        data-start={period}
                        data-min="1"
                        data-max="30"
                        data-step={1}
                        data-postfix=""
                      >
                        <label htmlFor="mortgage-period">Срок кредита, лет</label>
                        <div className="calculator__item-wrapper">
                          <input
                            className="slider-input__control"
                            type="text"
                            id="mortgage-period"
                            name="period"
                          />
                        </div>
                        <div className="slider__input"></div>
                      </div>
                      <div
                        className="calculator__item slider-input"
                        data-start={prepay}
                        data-min={100000}
                        data-max={price}
                        data-step={20000}
                        data-postfix=" р."
                        data-is-price
                      >
                        <label htmlFor="mortgage-prepay">
                          Первоначальный взнос
                        </label>
                        <div className="calculator__item-wrapper">
                          <input
                            className="slider-input__control price-input"
                            type="text"
                            id="mortgage-prepay"
                            name="prepay"
                          />
                        </div>
                        <div className="slider__input" ref={prepayRef}></div>
                      </div>
                      <div
                        className="calculator__item slider-input"
                        data-start={percent}
                        data-min="1"
                        data-max="20"
                        data-step={0.1}
                        data-postfix=""
                      >
                        <label htmlFor="mortgage-percent">
                          Процентная ставка, %
                        </label>
                        <div className="calculator__item-wrapper">
                          <input
                            className="slider-input__control"
                            type="text"
                            id="mortgage-percent"
                            name="percent"
                          />
                        </div>
                        <div className="slider__input"></div>
                      </div>
                    </div>
                    <div className="calculator__result">
                      <div className="calculator__result-info">
                        Ваш ежемесячный платеж составит *
                      </div>
                      <div className="calculator__result-value">
                        {priceFormat(total)}
                      </div>
                      {!isPublic && (
                        <div
                          className="calculator__result-btn js-modal-btn"
                          data-id="modal-mortgage"
                        >
                          <button
                            className="button button_view_default"
                            onClick={media("isMobile") ? openMortgageMobileForm : openMortgageForm}
                          >
                            Заявка на расчет
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MortgageBlock;
