import React, { memo } from 'react';
import RadioDropdown from '../../core/RadioDropdown';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import { getSelectPropsCreator } from '../utils';
import Checkbox from "../../core/Checkbox";
import ym from "react-yandex-metrika";
import useMedia from "../../../hooks/useMedia";

const PrimaryBottomFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  const isLaptop = useMedia('isLaptop');
  const isSmallLaptop = useMedia('isSmallLaptop');
console.log(props)
  return <>
    {
      isSmallLaptop &&
      <SelectFilter
        {...createSelectProps("rooms")}
        data={{
          title: "К-во комнат",
          identity: "rooms",
        }}
        isAutocomplete={false}
        wrapperClass={wrapperClass}
      />
    }
    {
      isLaptop &&
      <EndingsFilter
        autoPosition
        ident={"endings"}
        filter={filter.endings ?? { min: "", max: "" }}
        items={filterData.endings?.items ?? []}
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
        }}
        onChange={v => setFilterAndExclude(v, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.isOnlyHanded ?? "N"
        }}
        secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
        clearFilterOnly={() => clearByIdent("endings")}
        clearExcludeOnly={() => clearByIdent("endings", "exclude")}
        wrapperClass={wrapperClass}
      />
    }
    <SelectFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("microDistricts")}
      data={{
        title: "Микрорайон",
        autocompleteTitle: "Введите название микрорайона",
        identity: "microDistricts",
      }}
      wrapperClass={wrapperClass}
    />
    <EndingsFilter
      autoPosition
      filter={filter.issuingKeys ?? { min: "", max: "" }}
      items={filterData.issuingKeys?.items ?? []}
      wrapperClass={wrapperClass}
      data={{
        title: "Выдача ключей",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.issuingKeys?.ranges ?? null,
      }}
      onChange={v => setFilterAndExclude(v, "issuingKeys")}
      ident={"issuingKeys"}
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("decoration")}
      data={{
        title: "Отделка",
        identity: "decoration",
      }}
      wrapperClass={wrapperClass}
    />

    <SquareFilter
      // debounced
      wrapperClass={wrapperClass}
      filter={filter.squareKitchen}
      data={{
        title: "S кухни",
        minPlaceholder: "S кухни от ",
        maxPlaceholder: "S кухни до ",
        defaultValues: filterData.squareKitchen?.range
      }}
      filterSecond={filter.squareTotal}
      dataSecond={{
        title: "S",
        minPlaceholder: "S общ. от ",
        maxPlaceholder: "S общ. до ",
        defaultValues: filterData.squareTotal?.range,
      }}
      alternativeTitle="Площадь, от и до"
      isArea={true}
      onChange={({ filter }, ident) => handleFilter(ident, filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      autoPosition
    />
    <RadioDropdown
      wrapperClass={wrapperClass}
      autoPosition
      onChange={min => handleFilter("cellHeight", { min })}
      items={filterData.cellHeight?.items ? filterData?.cellHeight?.items.map(item => ({ ...item, title: `от ${item.title} м.` })) : []}
      value={filter.cellHeight?.min ?? {}}
      alternativeTitle={"Высота потолков"}
      data={{
        title: "Высота потолков",
        identity: "cellHeight",
      }}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
    />
    <SelectFilter
      {...createSelectProps("balconyType")}
      data={{
        title: "Балкон",
        autocompleteTitle: "",
        identity: "balconyType",
      }}
      disabledItems={{
        w: ["b", "l", "t"],
        b: ["w"],
        l: ["w"],
        t: ["w"],
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      autoPosition
      items={filterData.floors?.items ?? []}
      filter={filter.floors ?? {}}
      data={{
        title: 'Этаж',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.floors?.range ?? null,
      }}
      onChange={({ filter }) => handleFilter('floors', filter)}
      secondData={{
        title: filterData.isNoFirstFloor?.[0]?.title ?? 'Не первый',
        value: filter.isNoFirstFloor ?? 'N',
      }}
      secondChange={v => handleFilter('isNoFirstFloor', v === 'Y' ? v : "")}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("blockClass")}
      data={{
        title: "Класс ЖК",
        identity: "blockClass",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("parkingType")}
      data={{ title: "Тип паркинга", identity: "parkingType" }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("subways")}
      data={{
        title: "Метро",
        autocompleteTitle: "Введите название метро",
        identity: "subways",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("availability")}
      data={{
        title: "До метро",
        identity: "availability",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("registrations")}
      data={{
        title: "Прописка",
        identity: "registrations",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("banks")}
      data={{
        title: "Банки",
        identity: "banks",
      }}
      wrapperClass={wrapperClass}
    />
    {filterData.isApartments && (
      <RadioDropdown
        onChange={v => handleFilter("isApartments", v)}
        items={filterData.isApartments}
        value={filter.isApartments}
        data={{
          title: 'Апартаменты',
          identity: 'isApartments',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}
    {filterData.assignments && (
      <RadioDropdown
        onChange={v => handleFilter("assignments", v)}
        items={filterData.assignments}
        value={filter.assignments}
        data={{
          title: 'Переуступки',
          identity: 'assignments',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}
    {filterData.isTwoLevels && (
      <RadioDropdown
        onChange={v => handleFilter("isTwoLevels", v)}
        items={filterData.isTwoLevels}
        value={filter.isTwoLevels}
        data={{
          title: 'Двухъярусная',
          identity: 'isTwoLevels',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}

    {filterData.masterBedroom && (
      <RadioDropdown
        onChange={v => handleFilter("masterBedroom", v)}
        items={filterData.masterBedroom}
        value={filter.masterBedroom}
        data={{
          title: 'Мастер-спальня',
          identity: 'masterBedroom',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}
    {filterData.escrowAccount && (
      <SelectFilter
        {...createSelectProps("escrowAccount")}
        data={{
          title: 'Эскроу счет',
          identity: 'escrowAccount',
        }}
        wrapperClass={wrapperClass}
      />
    )}
    <div className="form__row form__row_tapfilters">

      <div className="index-search__show-booked index-search__show-booked_fst">
        <Checkbox
          id={'tradeIn'}
          onChange={(e) => {
            handleFilter('tradeIn', e.target.checked? "Y" : false)
            try {
              ym("reachGoal", "trade_In_filter")
            } catch (error) {

            }
          }}
          checked={filter?.tradeIn === "Y"}
          labelText={"Trade-In"}
        />
      </div>

      <div className="index-search__show-booked index-search__show-booked_second">
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status.includes("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            />
            <div className="switch__marker"/>
          </div>
          <span className="switch__label">
                          Забронированные
                        </span>
        </label>
      </div>
    </div>

    {/*<div className="form__row">*/}
    {/*  <div className="index-search__show-booked ">*/}
    {/*    <label className="switch" htmlFor="filter-booked">*/}
    {/*      <div className="switch__box">*/}
    {/*        <input*/}
    {/*          className="switch__control"*/}
    {/*          type="checkbox"*/}
    {/*          id="filter-booked"*/}
    {/*          checked={filter.status.includes("3")}*/}
    {/*          onChange={e => handleFilter("status", "3")}*/}
    {/*        />*/}
    {/*        <div*/}
    {/*          className="switch__input"*/}
    {/*          htmlFor="filter-booked"*/}
    {/*        />*/}
    {/*        <div className="switch__marker"/>*/}
    {/*      </div>*/}
    {/*      <span className="switch__label">*/}
    {/*                      Забронированные*/}
    {/*                    </span>*/}
    {/*    </label>*/}
    {/*  </div>*/}
    {/*</div>*/}

  </>
});

export default PrimaryBottomFilters;
