import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import Confirm from '../core/Confirm';
import Table from '../core/Table';
import { errorToast, infoToast } from '../toasts';


const ColumnStatus = (props) => {
  const { status, statusColor } = props;

  if (!statusColor) return '';

  return (
    <>
      {status && (
        <div className={`label-item label_${statusColor}`}>{status}</div>
      )}
    </>
  );
};

const ColumnPay = (props) => {
  const { items } = props;

  if (!items) return '';

  const getText = () => {
    return items.map(({ name }) => name).join('<br />');
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: getText() }} />
    </>
  );
};

const ColumnManager = (props) => {
  const { manager } = props;

  if (!manager) return '';

  const { name, lastName, phone } = manager;

  return (
    <>
      {lastName} {name.charAt(0)}.<br />
      <a class="clients__phone" href={`tel:${phone}`}>
        {phone}
      </a>
    </>
  );
};

const ColumnAction = (props) => {
  const { deleteRequest, sendRequest } = props;

  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setOpen(false);
    }
  };

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const confirm = () => {
    deleteRequest(props.id).then(
      () => {
        hideModal();
        infoToast(<div>Заявка удалена</div>)
      },
      error => {
        hideModal();
        errorToast(<div>{error}</div>)
      },
    );
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить заявку?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const handleSend = () => {
    setOpen(false);
    sendRequest({
      id: props.id,
      client: props.clientId,
      object: props.objectId,
      manager: props.agentId,
      city: props.city,
    }).then(
      data => infoToast(<div>Заявка отправлена менеджеру бронирования</div>),
      error => errorToast(<div>{error}</div>)
    );
  };

  if (!props.statusColor || props.statusColor !== 'violet') return '';

  return (
    <div class="control_more">
      <div class="form__item">
        <div
          ref={node}
          className={
            'dropdown js-dropdown form__drop' + (open ? ' is-showing' : '')
          }
        >
          <button
            type="link"
            class="dropdown__btn button form__drop-btn button_type_round"
            onClick={(e) => setOpen(!open)}
          >
            <span class="form__drop-name"></span>
            <svg class="icon icon_elipsis">
              <use xlinkHref="#elipsis"></use>
            </svg>
          </button>
          <div class="dropdown__block">
            <div class="dropdown__inner">
              <div class="form__drop-content">
                <div class="dt__more">
                  <hr />
                  {props.statusColor === 'violet' && (
                    <div class="dt__more-control" onClick={() => handleSend()}>
                      <svg class="icon icon_delete">
                        <use xlinkHref="#delete"></use>
                      </svg>
                      <span>Отправить заявку</span>
                    </div>
                  )}

                  <div
                    class="dt__more-control js-modal-btn js-client-del"
                    data-client-id="client-1"
                    data-id="delete-client"
                    onClick={showConfirm}
                  >
                    <svg class="icon icon_delete">
                      <use xlinkHref="#delete"></use>
                    </svg>
                    <span>Удалить</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlockRequests = (props) => {
  const { items, innerRef } = props;

  const getColumns = () => [
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      render: (column, record) => (
        <Link
          className="orders__table-link orders__table-client"
          to={`/personal/clients/${record.clientId}`}
        >
          {column}
        </Link>
      ),
    },
    {
      title: 'Объект',
      key: 'object',
      dataIndex: 'object',
      render: (column, record) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: column }} />
          {record.city !== null && record.objectId && (
            <Link
              class="orders__table-label"
              to={`/realty/${record.city}/apartment/${record.objectId}`}
            >
              Карточка объекта
            </Link>
          )}
        </>
      ),
    },
    {
      title: 'Агент',
      dataIndex: 'agent',
      key: 'agent',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.baseCost - b.baseCost,
    },
    {
      title: 'Дата бр.',
      dataIndex: 'dateCreate',
      key: 'dateCreate',
      sorter: (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1),
      render: (column) =>
        new Date(column).toLocaleDateString('ru', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.statusColor > b.statusColor,
      render: (status, { statusColor }) => (
        <ColumnStatus status={status} statusColor={statusColor} />
      ),
    },
    {
      title: 'Форма оплаты',
      dataIndex: 'payType',
      key: 'payType',
      render: (column, { payType }) => <ColumnPay items={payType} />,
    },
    {
      title: 'Менеджер',
      dataIndex: 'manager',
      key: 'manager',
      render: (manager) => <ColumnManager manager={manager} />,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (text, record) => (
        <ColumnAction
          {...record}
          // deleteRequest={handleDelete}
          // sendRequest={handleSend}
        />
      ),
    },
  ];

  return (
    <div className="client__section has-offset" ref={innerRef}>
      <div className="client__section-header">
        <h3 className="client__section__title">Заявки</h3>
      </div>
      <div className="client__table">
        <div class="orders__content">
          <Table
            rowKey="id"
            showSorterTooltip={false}
            columns={getColumns()}
            dataSource={items}
            pagination={false}
            locale={{ emptyText: 'ничего не найдено' }}
            customPagination={true}
          />
        </div>
      </div>
    </div>
  );
};

export default BlockRequests;
