import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { chunk } from "lodash";
import TinySlider from "tiny-slider-react";
import ErrorPlug from '../ErrorBoundry/ErrorPlug'
import { getUrl } from "../../api";

const LeftPromo = (props) => {
  const { items } = props;

  if (!items.length) return null;

  return (
    <div class="complex__promo-left">
      {items.map((item) => (
        <div class="complex__promo-item" key={item.id}>
          <div class="complex__promo-image">
            <img src={getUrl(item.previewPicture.src)} alt={item.name} />
          </div>
          <div class="complex__promo-content">
            <div class="complex__promo-body">
              <div class="complex__promo-title h3">
                {" "}
                <a href="#">{item.name}</a>
              </div>
              {false && (
                <div
                  class="complex__promo-desc"
                  dangerouslySetInnerHTML={{ __html: item.previewText }}
                />
              )}
            </div>
            <div class="complex__promo-bottom">
              <ul class="complex__promo-badges">
                <li class="complex__promo-badge">Квартиры</li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RightPromo = (props) => {
  const { items } = props;

  if (!items.length) return null;

  return (
    <div class="complex__promo-right">
      {items.map((item) => (
        <div class="complex__promo-item" key={item.id}>
          <div class="complex__promo-image">
            <img src={getUrl(item.previewPicture.src)} alt={item.name} />
          </div>
          <div class="complex__promo-content">
            <div class="complex__promo-title h2">
              <a href="#">{item.name}</a>
            </div>
            {false && (
              <div
                class="complex__promo-desc"
                dangerouslySetInnerHTML={{ __html: item.previewText }}
              />
            )}
            <div class="complex__promo-bottom">
              <ul class="complex__promo-badges">
                <li class="complex__promo-badge">Квартиры</li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const PromoBlock = (props) => {
  const { items } = props;

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    const sides = chunk(items, 2);
    if (sides.length > 0) {
      setLeft(sides[0]);
    }
    if (sides.length > 1) {
      setRight(sides[1]);
    }
  }, [items]);

  return (
    <div>
      <div class="complex__promo-wrapper">
        <LeftPromo items={left} />
        <RightPromo items={right} />
      </div>
    </div>
  );
};

const Promo = (props) => {
  const { items, innerRef } = props;

  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    setBlocks(chunk(items, 3));
  }, [items]);

  const node = useRef(null);

  const settings = {
    items: 1,
    nav: false,
    loop: true,
    controls: false,
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  return (
    <ErrorPlug wrappedComponent="src/comp-ts/Complex/Promo.js">
    <section class="section section_view_white section__promo" ref={innerRef}>
      {blocks.length > 1 && (
        <div class="wrapper">
          <div class="wrapper__center">
            <div class="block__title block__title-buttons">
              <h2 class="h2">Скидки и акции</h2>
              <div class="block__title-nav">
                <ul class="block__title-navs" id="complexNewsNav">
                  <li
                    class="block__title-navs_item prev"
                    onClick={() => moveSlider("prev")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    class="block__title-navs_item next"
                    onClick={() => moveSlider("next")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div class="complex__promo">
              <TinySlider settings={settings} ref={node}>
                {blocks.map((block, index) => (
                  <PromoBlock key={`promoblock${index}`} items={block} />
                ))}
              </TinySlider>
            </div>
          </div>
        </div>
      )}
    </section>
    </ErrorPlug>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(Promo);
