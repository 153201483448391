const initialState = {
  isFetching: false,
  error: false,
  data: [],
  deals: [],
  filterData: {
    agents: [],
    statuses: [],
    clients: [],
    dates: {
      min: null,
      max: null,
    },
  },
  filter: {
    search: "",
    agents: [],
    clients: [],
    dates: {
      startDate: null,
      endDate: null,
    },
    statuses: [],
    isFavorite: false,
  },
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case "CLIENTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "CLIENTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        deals: action.deals,
        filterData: action.filterData,
        statuses: action.statuses,
        agents: action.agents
      };
    case "CLIENTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "CLIENTS_SET_FAVORITE":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.id) {
            return { ...item, isFavorite: action.active };
          }
          return item;
        }),
      };
      case "CLIENTS_SET_STATUS":
        return {
          ...state,
          data: state.data.map((item) => {
            if (item.id === action.data.id) {
              return { ...item, status: action.data.status };
            }
            return item;
          }),
        };
    case "CLIENTS_TASKS_REMOVE":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.clientId) {
            let tasks = item.tasks.filter((item) => item.id != action.id);
            return { ...item, tasks: tasks };
          }
          return item;
        }),
      };
    case "CLIENTS_TASKS_ADD":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id == action.clientId) {
            let tasks = [...item.tasks, action.deal];
            return { ...item, tasks };
          }
          return item;
        }),
      };
    case "CLIENTS_TASKS_EDIT":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id == action.clientId) {
            let tasks = item.tasks.map((task) => {
              if (task.id == action.id) {
                task = action.deal;
              }
              return task;
            });
            return { ...item, tasks };
          }
          return item;
        }),
      };
    case "CLIENTS_RESET":
      return { ...initialState };
    case "CLIENTS_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "CLIENTS_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };
    case "CLIENTS_CLEAR_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

export default clients;
