import React, {useState, useMemo, useEffect} from 'react';
import SwiperCore, {Navigation, Pagination, Thumbs} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {PhotoSwipe} from 'react-photoswipe';
import 'swiper/swiper.scss';
import './index.scss';
import 'react-photoswipe/lib/photoswipe.css';
import {ZoomIcon} from './icons';
import Button from '../Button';
import {getUrl} from '../../../api';
import ApartmentMap from '../../ApartmentMap';
import ErrorPlug from '../../ErrorBoundry/ErrorPlug';
import {themeColors} from "../../Constructor/constructorUtils";
import {Map, YMaps} from "react-yandex-maps";

SwiperCore.use([Navigation, Thumbs, Pagination]);

const SwiperSlider = (props) => {
  const {
    navigation,
    nextEl,
    prevEl,
    items,
    wrap = (item) => item,
    options = {},
    thumbs = null,
    withCounter = null,
    slidesPerView = 1,
    doOnChange = null,
    addBtn = null,
    spaceBetween = 0,
    swiperIndex,
    setSwiperIndex = indx => setEmergencyState({indx}),
    setOpenGallery,
    showWindowView,
    viewFromWindow,
    windowView,
    showComplexMap,
    complexMap,
    defoultThumbs
  } = props;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState({})
  const [emergencyState, setEmergencyState] = useState({
    indx: 0
  })
  const indx = swiperIndex ?? emergencyState.indx

  const settings = {
    navigation:
      items?.length > 1 &&
      (navigation ?? {
        nextEl,
        prevEl,
      }),
    spaceBetween: spaceBetween,
    slidesPerView: slidesPerView,
    setWrapperSize: true,
    slidesPerGroup: 1,
    thumbs: thumbs && items.length > 1 ? {swiper: thumbsSwiper} : false,
    // pagination: { clickable: true },
    pagination: false,
    breakpoints: {
      767: {
        slidesPerView: slidesPerView,
        pagination: false,
        spaceBetween: spaceBetween,
      },
      1279: {
        slidesPerView: slidesPerView,
        pagination: false,
        spaceBetween: spaceBetween,
      },
    },
    ...options,
  };
  const slideTo = (index) => {
    swiperInstance?.slideTo(index)
    thumbsSwiper?.slideTo(index)
  }


  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  useEffect(() => {
    if (!mapInstance || !ymaps) return;
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
      `<svg width="48" height="57" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 56" style="enable-background:new 0 0 48 56;" xml:space="preserve">
            <path style="fill-rule:evenodd;clip-rule:evenodd;" fill="${
        themeColors.red
      }" d="M8.12,0.12c-4.42,0-8,3.58-8,8v31.75c0,4.42,3.58,8,8,8h10.56L24,55.88l5.32-8.02h10.56c4.42,0,8-3.58,8-8V8.12  c0-4.42-3.58-8-8-8H8.12z"/>
            <path fill="#FFFFFF" d="M30.73,11.33H17.27c-0.37,0-0.67,0.29-0.67,0.65v23.86c0,0.36,0.3,0.65,0.67,0.65h5.22v-3.81h3.03v3.81h5.22  c0.37,0,0.67-0.29,0.67-0.65V11.99C31.4,11.62,31.1,11.33,30.73,11.33z M22.85,30.24h-3.03v-3.28h3.03V30.24z M22.85,24.5h-3.03  v-3.28h3.03V24.5z M22.85,18.77h-3.03V15.5h3.03V18.77z M28,30.24h-3.03v-3.28H28V30.24z M28,24.5h-3.03v-3.28H28V24.5z M28,18.77  h-3.03V15.5H28V18.77z"/>
        </svg>
        `,
    );
    const placeMaprk = new ymaps.Placemark(
      complexMap,
      {},
      {
        iconLayout: placemarkTemplate,
        iconOffset: [-24, -58],
      },
    );
    mapInstance.geoObjects.add(placeMaprk);
  }, [mapInstance, ymaps]);



  return (
    <>
      {/*Стал вывод карты для модалки быстрого просмотра квартиры*/}
      {viewFromWindow?.cords && showWindowView && windowView &&
      <div className="apt-modal__map">
        <ErrorPlug stopSending>
          <ApartmentMap  {...viewFromWindow} />
        </ErrorPlug>
      </div>}

      {showComplexMap && complexMap &&
      <div className="apt-modal__map">
        <YMaps>
          <Map
            defaultState={{
              center: complexMap,
              zoom: 12,
            }}
            modules={['templateLayoutFactory', 'Template', 'Placemark']}
            onLoad={setYmaps}
            instanceRef={setMapInstance}
            className="complex__map-wrap"
          />
        </YMaps>
      </div>}

      <Swiper
        {...settings}
        onSlideChange={(swiper) => {
          setSwiperIndex(swiper.activeIndex);
          doOnChange && doOnChange(swiper.activeIndex);
        }}
        onSwiper={setSwiperInstance}
      >
        {/*Был вывод карты для модалки быстрого просмотра квартиры без флажка !windowView*/}
        {viewFromWindow?.cords && showWindowView && !windowView && <SwiperSlide>
          <div className="sf-slide">
            <div className="flat__images gallery">
              <div className={`flat__image gallery__wrapper active`}
                   style={{margin: "auto", minHeight: 300, border: "none"}}
              >
                <ErrorPlug stopSending>
                  <ApartmentMap  {...viewFromWindow} />
                </ErrorPlug>
              </div>
            </div>
          </div>
        </SwiperSlide>}
        {showWindowView && viewFromWindow?.src && <SwiperSlide>
          {wrap(viewFromWindow, false, 0, slideTo)}
        </SwiperSlide>
        }
        {!showWindowView && !showComplexMap && items &&
        items.map((item, i) => (
          <SwiperSlide key={'slide-' + i}>{wrap(item, false, i, slideTo)}</SwiperSlide>
        ))}
        {withCounter && (
          <div className="swiper-counter">
            {`${indx + 1}/${items.length}`}</div>
        )}

        {setOpenGallery && !showWindowView && (
          <>
            <Button
              buttonType="clean"
              addClass="swiper-zoom"
              onClick={() => setOpenGallery(true)}
            >
              <ZoomIcon/>
            </Button>
          </>
        )}
      </Swiper>
      {thumbs && (
        <div className={`swiper-thumbs-block ${thumbs.length === 1 ? 'active-one-thumb-custom' : ''}`}>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={'auto'}
            freeMode={true}
            watchSlidesProgress={true}
            className={`swiper-thumbs`}
          >
            {items && items.length > 1 ?
            items.map((item, i) => (
              <SwiperSlide key={'slidethumb-' + i}>
                {!defoultThumbs ? wrap(item, true, i, slideTo) : null}
              </SwiperSlide>
            )) : complexMap &&

              items.map((item, i) => (
                <SwiperSlide key={'slidethumb-' + i}>
                  {wrap(item, true, i, slideTo)}
                </SwiperSlide>
              ))
            }
          </Swiper>
          {addBtn && addBtn()}
        </div>
      )}
    </>
  );
};

export default SwiperSlider;

