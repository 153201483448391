import _filter from "lodash/filter";
import without from "lodash/without";
import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import ym from "react-yandex-metrika";
import {
  clearFilterEstate,
  getCountBlocks, setFilterEstate, setView
} from "../../actions/estate";
import useMedia from "../../hooks/useMedia";
import {
  filterToParams,
  isEmptyFilterOrExclude
} from "../../utils";
import {
  defaultFullFilterEstate
} from "../../utils/defaultFilters";
import Checkbox from "../core/Checkbox";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import Wrap from '../core/ModalWrap';
import RadioDropdown from "../core/RadioDropdown";
import SaveFilter from "../SavedFilters/SendFilter";
import Controls from './Controls';
import DistrictsFilter from "./DistrictsFilter";
import Dragable from "./Dragable";
import EndingsFilter from "./EndingsFilter";
import FloorsFilter from "./FloorsFilter";
import PriceFilter from "./PriceFilter";
import SelectFilter from "./SelectFilter";
import SmartFilter from "./SmartFilter";
import SquareFilter from "./SquareFilter";

var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    countBlocksToShow,
    city,
    getCountBlocks,
    fetching,
    setView,
    countApartmentsTotal,
    headerRef,
    isFiltersOpen,
    onClick
  } = props;

  const [showFilter, toggleFilter] = useState(true);
  const isMobile = useMedia("isMobile")

  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    if (identity === "isOnlyHanded") {
      tempValue.filter.endings = { min: "", max: "" };
      tempValue.exclude.endings = { min: "", max: "" };
    }
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };

    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      getCountSF(tmpFilter);
    }, 300);
  };
  const getCountSF = (tmpFilter) => {
    let params = filterToParams(tmpFilter);
    getCountBlocks(props.city, params);
  }

  const [clearedSmartFilter, setCleared] = useState(false);
  const handleClearFilter = (e) => {
    if (e) {
      e.preventDefault();
    }
    setCleared("full")
    getCountBlocks(props.city, filterToParams(defaultFullFilterEstate));
    clearFilter();
  };

  const handleFilterClick = (e, showMap = false) => {
    e.preventDefault();
    if (showMap) {
      setView("map");
    }
    onSubmit(showMap);
  };

  const handleToggle = (e) => {
    let tempFilter = [...filter.filter.status];
    if (e.target.checked) {
      tempFilter.push("3");
    } else {
      tempFilter = without(tempFilter, "3");
    }
    setFilter({
      filter: { ...filter.filter, status: tempFilter },
    });
    let params = filterToParams({
      ...filter,
      filter: { ...filter.filter, status: tempFilter },
    });
    getCountBlocks(props.city, params);
  };

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, filterData],
  );

  // const getTitle = () => {
  //   let el = document.createElement("html");
  //   if (countApartmentsTotal && countBlocksTotal) {
  //     el.innerHTML = `Более ${countApartmentsTotal} квартир <br />в&nbsp;${countBlocksTotal}&nbsp;ЖК&nbsp;${
  //       props.city == "msk" ? "Москвы" : "Санкт-Петербурга"
  //     }`;
  //   } else {
  //     el.innerHTML = `Новостройки ${
  //       props.city == "msk" ? "Москвы" : "Санкт-Петербурга"
  //     }`;
  //   }
  //   return el.innerText;
  // };

  const clearExcludeOnly = () => {
    props.clearExcludeOnly()
    setCleared("exclude")
  }
  const clearFilterOnly = () => {
    props.clearFilterOnly()
    setCleared("filter")
  }

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    document.body.classList.add('no-scroll')
    headerRef && headerRef.header.current.classList.add('hidden')
    // switchRef && switchRef.header.current && switchRef.header.current.classList.add('hidden')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
    headerRef && headerRef.header.current.classList.remove('hidden')
    // switchRef && switchRef.current && switchRef.current.classList.remove('hidden')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  const showAllClick = (e, showMap = false) => {
    e.preventDefault();
    toggleFilters()
    if (showMap) {
      setView("map");
    }
    onSubmit(showMap);
    onClick && onClick();
  }


  useEffect(() => {
    if (isFiltersOpen) toggleFilters();
  }, [isFiltersOpen]);

  const [count, setCount] = useState(0);

  const countFilters = (filtersCount) => {
    setCount(filtersCount)
  }

  const dragable =
    <Dragable
      filter={filter.filter}
      fullFilter={filter}
      exclude={filter.exclude}
      filterData={filterData}
      handleFilter={handleFilter}
      clearExcludeOnly={clearExcludeOnly}
      clearFilterOnly={clearFilterOnly}
      getCount={(filterOrExclude) =>
        getCountBlocks(props.city, filterToParams(filterOrExclude))
      }
      enableRemoving={true}
      hideParams={true}
      defFullFilter={defaultFullFilterEstate}
      isMobile={isOpen && isMobile}
      countFilters={countFilters}
    />

  const topFilters = (
    <>
      <SelectFilter
        onChange={(a, b) =>
          handleFilter({ filter: a, exclude: b }, "blocks")
        }
        isExcludable={true}
        isAutocomplete={true}
        items={
          filterData.blocks
            ? Object.values(filterData.blocks)
            : []
        }
        filterValues={filter.filter.blocks}
        excludeValues={filter.exclude.blocks}
        data={{
          title: "Название ЖК",
          autocompleteTitle: "Введите название ЖК",
          identity: "blocks",
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountBlocks(props.city, filterToParams(filterOrExclude))
        }
        defFullFilter={defaultFullFilterEstate}
        fullFilter={filter}
        autoPosition
      />
      <DistrictsFilter
        onChange={(a, b) =>
          handleFilter({ filter: a, exclude: b }, "districts")
        }
        isExcludable={true}
        isAutocomplete={true}
        items={
          filterData.districts
            ? Object.values(filterData.districts)
            : []
        }
        filterValues={filter.filter.districts}
        excludeValues={filter.exclude.districts}
        data={{
          title: "Район",
          autocompleteTitle: "Введите название района",
          identity: "districts",
        }}
        city={props.city}
        setDistricts={(a) =>
          handleFilter({ filter: a }, "districts")
        }
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountBlocks(props.city, filterToParams(filterOrExclude))
        }
        defFullFilter={defaultFullFilterEstate}
        fullFilter={filter}
        autoPosition
      />
      <SelectFilter
        onChange={(a, b) =>
          handleFilter({ filter: a, exclude: b }, "rooms")
        }
        isExcludable={true}
        isAutocomplete={false}
        items={
          filterData.rooms
            ? Object.values(filterData.rooms)
            : []
        }
        filterValues={filter.filter.rooms}
        excludeValues={filter.exclude.rooms}
        data={{
          title: "К-во комнат",
          autocompleteTitle: "",
          identity: "rooms",
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountBlocks(props.city, filterToParams(filterOrExclude))
        }
        defFullFilter={defaultFullFilterEstate}
        fullFilter={filter}
        autoPosition
      />
      <EndingsFilter
        ident={"endings"}
        autoPosition
        filter={
          filter.filter.endings
            ? filter.filter.endings
            : { min: "", max: "" }
        }
        items={
          filterData.endings ? filterData.endings.items : []
        }
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.endings
            ? filterData.endings.ranges
            : null,
        }}
        onChange={(a) => handleFilter(a, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.filter.isOnlyHanded?.length
            ? filter.filter.isOnlyHanded[0]?.id
            : "N",
        }}
        secondChange={(a) => {
          let tmp = a === "Y" ? [{ id: a }] : [];
          handleFilter(
            {
              filter: tmp,
              exclude: [
                ..._filter(
                  filter.exclude.isOnlyHanded,
                  (val) => val.id != a,
                ),
              ],
            },
            "isOnlyHanded",
          );
        }}
        style={{ display: showFilter ? "block" : "none" }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountBlocks(props.city, filterToParams(filterOrExclude))
        }
        defFullFilter={defaultFullFilterEstate}
        fullFilter={filter}
      />
    </>
  )

  const filters = (
    <div className="index-search__filter-body">
      <div className="index-search__filter-top">
        <div className="form__row">

          <SmartFilter
            filterData={filterData}
            setFilter={setFilter}
            handleFilter={handleFilter}
            filterValues={filter.filter}
            excludeValues={filter.exclude}
            fullFilterValues={filter}
            clearFilter={handleClearFilter}
            toggleFilter={toggleFilter}
            showFilter={showFilter}
            submit={onSubmit}
            city={city}
            clearExcludeOnly={clearExcludeOnly}
            clearFilterOnly={clearFilterOnly}
            getCount={getCountSF}
            isCleared={clearedSmartFilter}
            resetCleared={() => setCleared(false)}
            isMobile={isOpen}
            countApartmentsTotal={countApartmentsTotal}
          />

          {isMobile && !isOpen &&
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Все фильтры
              {count > 0 && ` (${count})`}
            </button>
          }

          {isOpen && filter && filterData && !isEmptyFilterOrExclude(filter, "full", defaultFullFilterEstate) &&
            dragable
          }

          {showFilter && !isMobile && topFilters}

          {showFilter && isOpen && isMobile && topFilters}

          {!isMobile &&
            <button
              class="button button_type_reveal-filter"
              type="button"
              onClick={() => toggleFilter(!showFilter)}
              style={{ display: showFilter ? "none" : "block" }}
            >
              <span class="visible_mobile">Фильтры</span>
              <span class="visible_note">Развернуть фильтр</span>
            </button>
          }
        </div>
      </div>

      {(!isMobile || isOpen) &&
        <div
          className="index-search__filter-main"
          style={{ display: showFilter ? "block" : "none" }}
        >
          <div className="form__row">
            <PriceFilter
              filter={
                filter.filter.prices
                  ? filter.filter.prices
                  : { min: "", max: "" }
              }
              exclude={
                filter.exclude.prices
                  ? filter.exclude.prices
                  : { min: "", max: "" }
              }
              items={
                filterData.prices ? filterData.prices.items : []
              }
              data={{
                title: "Цена",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.prices
                  ? filterData.prices.range
                  : 0,
              }}
              onChange={(a) => handleFilter(a, "prices")}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <EndingsFilter
              autoPosition
              filter={
                filter.filter.issuingKeys
                  ? filter.filter.issuingKeys
                  : { min: "", max: "" }
              }
              items={
                filterData.issuingKeys ? filterData.issuingKeys.items : []
              }
              data={{
                title: "Выдача ключей",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.issuingKeys
                  ? filterData.issuingKeys.ranges
                  : null,
              }}
              onChange={(a) => handleFilter(a, "issuingKeys")}
              style={{ display: showFilter ? "block" : "none" }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              ident={"issuingKeys"}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "builders")
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.builders
                  ? Object.values(filterData.builders)
                  : []
              }
              filterValues={filter.filter.builders}
              excludeValues={filter.exclude.builders}
              data={{
                title: "Застройщик",
                autocompleteTitle: "Введите название застройщика",
                identity: "builders",
              }}
              autoPosition
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "decoration")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.decoration
                  ? Object.values(filterData.decoration)
                  : []
              }
              filterValues={filter.filter.decoration}
              excludeValues={filter.exclude.decoration}
              data={{
                title: "Отделка",
                autocompleteTitle: "",
                identity: "decoration",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <SquareFilter
              filter={filter.filter.squareKitchen}
              data={{
                title: isMobile ? 'Площадь' : "S кухни",
                minPlaceholder: "S кухни от ",
                maxPlaceholder: "S кухни до ",
                defaultValues: filterData.squareKitchen
                  ? filterData.squareKitchen.range
                  : null,
              }}
              filterSecond={filter.filter.squareTotal}
              dataSecond={{
                title: "S",
                minPlaceholder: "S общ. от ",
                maxPlaceholder: "S общ. до ",
                defaultValues: filterData.squareTotal
                  ? filterData.squareTotal.range
                  : null,
              }}
              alternativeTitle="Площадь"
              isArea={true}
              onChange={(a, identity) => handleFilter(a, identity)}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            {/* <PriceFilter
              autoPosition
              filter={
                filter.filter.cellHeight
                  ? filter.filter.cellHeight
                  : { min: "", max: "" }
              }
              exclude={
                filter.exclude.cellHeight
                  ? filter.exclude.cellHeight
                  : { min: "", max: "" }
              }
              items={
                filterData.cellHeight ? filterData.cellHeight.items : []
              }
              data={{
                title: "Высота потолков",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.cellHeight
                  ? filterData.cellHeight.range
                  : 0,
              }}
              customFormat={v => `${v} м`}
              customFormatEnabled={true}
              onChange={(a) => handleFilter(a, "cellHeight")}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            /> */}
            <RadioDropdown
              autoPosition
              onChange={(a) => handleFilter(
                {
                  filter: {
                    "max": "",
                    "min": a
                  },
                  exclude: {
                    "max": "",
                    "min": ""
                  },
                },
                "cellHeight",
              )
              }
              items={filterData.cellHeight?.items ? filterData?.cellHeight?.items.map(item => ({ ...item, title: `от ${item.title} м.` })) : []}
              value={
                filter.filter.cellHeight?.min
                  ? filter.filter.cellHeight?.min
                  : {}
              }
              alternativeTitle={"Высота потолков"}
              data={{
                title: "Высота потолков",
                identity: "cellHeight",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "balconyType")
              }
              isExcludable={false}
              isAutocomplete={false}
              items={
                filterData.balconyType
                  ? Object.values(filterData.balconyType)
                  : []
              }
              filterValues={filter.filter.balconyType}
              excludeValues={filter.exclude.balconyType}
              data={{
                title: "Балкон",
                autocompleteTitle: "",
                identity: "balconyType",
              }}
              disabledItems={
                {
                  w: ["b", "l", "t"],
                  b: ["w"],
                  l: ["w"],
                  t: ["w"],
                }
              }
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <FloorsFilter
              autoPosition
              filter={filter.filter.floors}
              items={
                filterData.floors?.items ? filterData.floors.items : []
              }
              data={{
                title: "Этаж",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.floors
                  ? filterData.floors.range
                  : null,
              }}
              onChange={(a) => handleFilter(a, "floors")}
              secondData={{
                title: filterData.isNoFirstFloor
                  ? filterData.isNoFirstFloor[0].title
                  : "Не первый",
                value: filter.filter.isNoFirstFloor.length
                  ? filter.filter.isNoFirstFloor[0].id
                  : "N",
              }}
              secondChange={(a) => {
                let tmp = a === "Y" ? [{ id: a }] : [];
                handleFilter(
                  {
                    filter: tmp,
                    exclude: [
                      ..._filter(
                        filter.exclude.isNoFirstFloor,
                        (val) => val.id != a,
                      ),
                    ],
                  },
                  "isNoFirstFloor",
                );
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "blockClass")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.blockClass
                  ? Object.values(filterData.blockClass)
                  : []
              }
              filterValues={filter.filter.blockClass}
              excludeValues={filter.exclude.blockClass}
              data={{
                title: "Класс ЖК",
                autocompleteTitle: "",
                identity: "blockClass",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "subways")
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.subways
                  ? Object.values(filterData.subways)
                  : []
              }
              filterValues={filter.filter.subways}
              excludeValues={filter.exclude.subways}
              data={{
                title: "Метро",
                autocompleteTitle: "Введите название станции",
                identity: "subways",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "availability")
              }
              isExcludable={false}
              isAutocomplete={false}
              items={
                filterData.availability
                  ? Object.values(filterData.availability)
                  : []
              }
              filterValues={filter.filter.availability}
              excludeValues={filter.exclude.availability}
              data={{
                title: "До метро",
                autocompleteTitle: "",
                identity: "availability",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "registrations")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.registrations
                  ? Object.values(filterData.registrations)
                  : []
              }
              filterValues={filter.filter.registrations}
              excludeValues={filter.exclude.registrations}
              data={{
                title: "Прописка",
                autocompleteTitle: "",
                identity: "registrations",
              }}
              autoPosition
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />
            <SelectFilter
              autoPosition
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "payment")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.payment
                  ? Object.values(filterData.payment)
                  : []
              }
              filterValues={filter.filter.payment}
              excludeValues={filter.exclude.payment}
              data={{
                title: "Способ оплаты",
                autocompleteTitle: "",
                identity: "payment",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />

            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "banks")
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.banks ? Object.values(filterData.banks) : []
              }
              filterValues={filter.filter.banks}
              excludeValues={filter.exclude.banks}
              data={{
                title: "Банки",
                autocompleteTitle: "Введите название банка",
                identity: "banks",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
              autoPosition
            />
            <RadioDropdown
              autoPosition
              onChange={(a) =>
                handleFilter(
                  {
                    filter: [{ id: a }],
                    exclude: [
                      ..._filter(
                        filter.exclude.isApartments,
                        (val) => val.id != a,
                      ),
                    ],
                  },
                  "isApartments",
                )
              }
              isAutocomplete={false}
              items={
                filterData.isApartments ? filterData.isApartments : []
              }
              value={
                filter.filter.isApartments[0]
                  ? filter.filter.isApartments[0].id
                  : {}
              }
              alternativeTitle={"Апартаменты"}
              data={{
                title: "Апартаменты",
                identity: "isApartments",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />
            <RadioDropdown
              autoPosition
              onChange={(a) =>
                handleFilter(
                  {
                    filter: [{ id: a }],
                    exclude: [
                      ..._filter(
                        filter.exclude.assignments,
                        (val) => val.id != a,
                      ),
                    ],
                  },
                  "assignments",
                )
              }
              isAutocomplete={false}
              items={
                filterData.assignments ? filterData.assignments : []
              }
              value={
                filter.filter.assignments[0]
                  ? filter.filter.assignments[0].id
                  : {}
              }
              alternativeTitle={"Переуступки"}
              data={{
                title: "Переуступки",
                identity: "assignments",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountBlocks(props.city, filterToParams(filterOrExclude))
              }
              defFullFilter={defaultFullFilterEstate}
              fullFilter={filter}
            />

            <div className="form__row">
              <div className="index-search__show-booked">
                <Checkbox
                  id={'tradeIn'}
                  onChange={(e) => {
                    handleFilter({ filter: e.target.checked ? [{ id: "Y" }] : [] }, 'tradeIn')
                    try {
                      ym("reachGoal", "trade_In_filter")
                    } catch (error) {

                    }
                  }}
                  isChecked={!!filter.filter.tradeIn.length}
                  checked={!!filter.filter.tradeIn.length}
                  labelText={"Trade-In"}
                />
              </div>
              <div className="index-search__show-booked">
                <label className="switch" htmlFor="filter-booked">
                  <div className="switch__box">
                    <input
                      className="switch__control"
                      type="checkbox"
                      id="filter-booked"
                      checked={filter.filter.status.includes("3")}
                      onChange={handleToggle}
                    />
                    <div
                      className="switch__input"
                      htmlFor="filter-booked"
                    ></div>
                    <div className="switch__marker"></div>
                  </div>
                  <span className="switch__label">
                    Забронированные
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )


  return (
    <section className="section index-search index-search_dark for-main">
      <div className="wrapper">
        <div className="section__grid">
          <div className="section__grid-col index-search__header for-main">
            <h1 className={isMobile ? "" : "h2"}>
              {isMobile ?
                <>
                  Новостройки
                  <br />
                  {props.city == "msk" ? "Москвы" : "Санкт-Петербурга"}
                </> :
                <>
                  {`Новостройки ${props.city == "msk" ? "Москвы" : "Санкт-Петербурга"}`}
                </>
              }
            </h1>
          </div>
          <div className="section__grid-col index-search__filter ">
            <form className="form">
              {isOpen && isMobile ?
                <div className="mobile-filters">
                  <div className="mobile-filters__title">
                    <svg
                      className="icon icon_arrow_up"
                      onClick={() => {
                        toggleFilters()
                        onClick && onClick()
                      }}
                    >
                      <use xlinkHref="#arrow_up"></use>
                    </svg>
                    <h3>Все фильтры</h3>
                  </div>
                  <div className="mobile-filters__content">
                    {filters}

                    <button
                      className="button button_view_outline for-mobile-filters"
                      onClick={(e) => {
                        e.preventDefault();
                        saveFilterModal();
                      }}
                    >
                      Сохранить фильтр
                    </button>
                  </div>

                  <div className="mobile-filters__show-all">

                    {countBlocksToShow ? (
                      <button
                        className="button button_view_default"
                        type="submit"
                        onClick={showAllClick}
                        disabled={fetching ? true : false}
                      >
                        Показать{` ${countBlocksToShow} ЖК`}
                      </button>
                    ) : (
                      <button
                        className="button button_view_default"
                        type="submit"
                        disabled
                      >
                        Ничего не найдено
                      </button>
                    )}
                  </div>
                </div> :

                filters
              }

              {!isOpen && filter && filterData && !isEmptyFilterOrExclude(filter, "full", defaultFullFilterEstate) &&
                dragable
              }

              <Controls
                saveFilterModal={saveFilterModal}
                handleClearFilter={handleClearFilter}
                handleFilterClick={handleFilterClick}
                countBlocksToShow={countBlocksToShow}
                fetching={fetching}
                isHomePage={props.isHomePage}
                isMobile={isMobile}
              />
            </form>


          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  city: ownProps.city ? ownProps.city : state.user.info.city,
  filterData: state.estate.filterData,
  filter: state.estate.filter,
  countApartments: state.estate.countApartments,
  countBlocks: state.estate.countBlocks,
  countBlocksToShow: state.estate.countBlocksToShow,
  countApartmentsTotal: state.estate.countApartmentsTotal,
  countBlocksTotal: state.estate.countBlocksTotal,
  headerRef: state.refs,
  switchRef: state.home.refs,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  let cityProps = ownProps.city;
  return {
    setFilter: (filter) => dispatch(setFilterEstate(filter)),
    clearFilter: () => dispatch(clearFilterEstate()),
    getCountBlocks: (city, params) =>
      dispatch(getCountBlocks(cityProps ? cityProps : city, params)),
    clearFilterOnly: () => dispatch({ type: "ESTATE_CLEAR_FILTER_ONLY" }),
    clearExcludeOnly: () => dispatch({ type: "ESTATE_CLEAR_EXCLUDE_ONLY" }),
    setView: (view) => dispatch(setView(view)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
