const initialState = {
  isFetching: false,
  error: false,
  data: [],
  filterData: {
    agents: [],
    rights: [],
    active: [],
  },
  filter: {
    search: "",
    agents: [],
    rights: [],
    active: [],
  },
};

const agents = (state = initialState, action) => {
  switch (action.type) {
    case "AGENTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "AGENTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        filterData: action.filterData,
      };
    case "AGENTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "AGENTS_SET_ACTIVE":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.data.id) {
            return { ...item, active: action.data.active };
          }
          return item;
        }),
      };
    case "AGENTS_RESET":
      return { ...initialState };
    case "AGENTS_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "AGENTS_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };
    case "AGENTS_CLEAR_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

export default agents;
