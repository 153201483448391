import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchClients } from '../../actions/clients';
import {
  clearComplex,
  fetchComplex,
  getApartments,
  getCount,
} from '../../actions/complex';
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites,
} from '../../actions/favorites';
import useMedia from '../../hooks/useMedia';
import { ReactComponent as Bank } from '../../images/svg/bank.svg';
import { ReactComponent as Calculator } from '../../images/svg/calc.svg';
import { ReactComponent as Desc } from '../../images/svg/desc.svg';
import { ReactComponent as Dinam } from '../../images/svg/dinam.svg';
import { ReactComponent as Newspaper } from '../../images/svg/news.svg';
import { cleanFilter } from '../../reducers/complex';
import { getEndingsTitle } from '../../utils';
import Collapse from '../core/Collapse';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import Preloader from '../Preloader';
import About from './About';
import Apartments from './Apartments';
import Banks from './Banks';
import Block from './Block';
import ComplexChart from './ComplexChart';
import './index.scss';
import Mortgage from './Mortgage';
import News from './News';
import OtherBlocks from './OtherBlocks';
import Promo from './Promo';
import { filterToParam } from './Utils';

var inDebounce;
const Complex = (props) => {
  const { complex, city } = useParams();

  const {
    fetch,
    reset,
    isFetching,
    error,
    block,

    addToFavorites,
    news,
    actions,
    removeFromFavorites,
    otherBlocks,
    realtyFilter,
    isClientMode,
    userInfo,
    fetchClients,
    clients,
    addClientToFavorite,
    removeClientFromFavorite,

    setFilter,
    filter,
    clearFilter,
    filterData,
    apartments,
    getApartments,
    count,
    getCount,
  } = props;

  const id = complex;
  let localSavedFilter = JSON.parse(localStorage.getItem('blocksFilter'));
  let endings = {
    min:
      localSavedFilter?.[id] &&
      getEndingsTitle(localSavedFilter[id].endings?.min)
        ? localSavedFilter[id]?.endings?.min
        : null,
    max:
      localSavedFilter?.[id] &&
      getEndingsTitle(localSavedFilter[id].endings?.max)
        ? localSavedFilter[id]?.endings?.max
        : null,
  }; //ппц
  let issuingKeys = {
    min:
      localSavedFilter?.[id] &&
      getEndingsTitle(localSavedFilter[id].issuingKeys?.min)
        ? localSavedFilter[id]?.issuingKeys?.min
        : null,
    max:
      localSavedFilter?.[id] &&
      getEndingsTitle(localSavedFilter[id].issuingKeys?.max)
        ? localSavedFilter[id]?.issuingKeys?.max
        : null,
  }; //ппц
  const initialFilter = {
    buildings: [],
    bathroom: [],
    roomType: [],
    decoration: localSavedFilter?.[id] ? localSavedFilter[id].decoration : [],
    squareKitchen: localSavedFilter?.[id]
      ? localSavedFilter[id].squareKitchen
      : { min: '', max: '' },
    endings: endings,
    issuingKeys,
    squareTotal: localSavedFilter?.[id]
      ? localSavedFilter[id].squareTotal
      : { min: '', max: '' },
    floors: localSavedFilter?.[id]
      ? localSavedFilter[id].floors
      : { min: '', max: '' },
    prices: localSavedFilter?.[id]
      ? localSavedFilter[id].prices
      : { min: '', max: '' },
    cellHeight: localSavedFilter?.[id]
      ? localSavedFilter[id].cellHeight
      : { min: '', max: '' },
    banks: localSavedFilter?.[id] ? localSavedFilter[id].banks : [],
    rooms: localSavedFilter?.[id] ? localSavedFilter[id].rooms : [],
    status: localSavedFilter?.[id] ? localSavedFilter[id].status : [1],
    isOnlyHanded: localSavedFilter?.[id]?.isOnlyHanded?.length
      ? localSavedFilter[id].isOnlyHanded
      : '',

    payment:
      localSavedFilter?.[id]?.payment?.filter?.((id) =>
        filterData?.payment?.some((fDataItem) => id == fDataItem.id),
      ) ?? [],
    number: '',
    isNoFirstFloor: localSavedFilter?.[id]?.isNoFirstFloor?.length
      ? localSavedFilter[id].isNoFirstFloor[0]?.id
      : '',

    isApartments: localSavedFilter?.[id]
      ? localSavedFilter[id].isApartments
      : [],
    assignments: localSavedFilter?.[id]?.assignments?.length
      ? localSavedFilter[id].assignments[0]?.id
      : '',
    balconyType: localSavedFilter?.[id]?.balconyType
      ? localSavedFilter[id].balconyType
      : [],
    isAction: '',
  };
  const checkRealtyFiltered = () => {
    return !isEqual(initialFilter, cleanFilter);
  };
  const isMobile = useMedia('isMobile');
  useEffect(() => {
    setFilter(initialFilter);
    fetch({
      id: complex,
      city,
      ...filterToParam(initialFilter, id, false),
      isGetApartments: true,
      isAssignment: true,
      isGetFilter: true,
    });
    fetchClients();
    return () => {
      reset();
    };
  }, [complex, city]);
  useEffect(() => {
    if (!filterData) return;
    handleSetFilter(initialFilter);
  }, [filterData]);

  useEffect(() => {
    if (checkRealtyFiltered() && apartments?.length > 0 && !haveApartments) {
      setHaveApartments(true);
      getCount(city, filterToParam(initialFilter, id, false));
    }
  }, [apartments]);
  useEffect(() => {
    document.title = `ЖК «${block ? block.name : ''}»`;
  }, [block]);

  const getComplexApartments = (params = []) => {
    getApartments(city, filterToParam(filter, id, false), null, params);
  };
  const handleResetFilter = () => {
    clearFilter();
    if (!isMobile) {
      getApartments(city, filterToParam(cleanFilter, id, false), null, {
        onlyTypes: true,
      });
      return;
    }
  };

  const handleSetFilter = (data, savedFilterXmlId) => {
    let tmpFilter = { ...filter, ...data };
    setFilter(tmpFilter);
    if (!isMobile) {
      getApartments(city, filterToParam(tmpFilter, id, false), null, {
        onlyTypes: true,
      });
      return;
    }
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      getCount(city, filterToParam(tmpFilter, id, false), savedFilterXmlId);
    }, 300);
  };
  const handleFavoritesClick = (inFavorite) => {
    inFavorite
      ? removeFromFavorites(city, complex, 'block', 'complexMain')
      : addToFavorites(city, complex, 'block', 'complexMain');
  };

  const [haveApartments, setHaveApartments] = useState(false);

  const pricesRef = useRef(null);
  const aboutRef = useRef(null);
  const mortgageRef = useRef(null);
  const newsRef = useRef(null);
  const otherRef = useRef(null);
  const banksRef = useRef(null);
  const promoRef = useRef(null);
  const chartRef = useRef(null);

  const navigation = useMemo(() => {
    let nav = [];
    if (haveApartments) {
      nav.push({
        title: 'Планировки и цены',
        ref: pricesRef,
        type: 'prices',
      });
    }
    if (block?.about?.previewText?.length > 0) {
      nav.push({
        title: 'Описание',
        ref: aboutRef,
        type: 'about',
      });
    }

    // Добавить проверку ComplexChart
    nav.push({
      title: 'Динамика цены',
      ref: chartRef,
      type: 'chart',
    });

    // if (otherBlocks.length > 0) {
    //   nav.push({
    //     title: "Инфраструктура",
    //     ref: aboutRef,
    //   });
    // }

    if (block && block.banks.length > 0 && haveApartments) {
      nav.push({
        title: 'Банки',
        ref: banksRef,
        type: 'banks',
      });
    }
    // if (block && block.mortgageManager && haveApartments) {
    //   nav.push({
    //     title: "Ипотека",
    //     ref: mortgageRef,
    //     type: "mortgage",
    //   });
    // }
    if (actions?.length > 0) {
      nav.push({
        title: 'Скидки и акции',
        ref: promoRef,
        type: 'promo',
      });
    }
    // if (news?.length > 0) {
    //   nav.push({
    //     title: "Новости",
    //     ref: newsRef,
    //     type: "news",
    //   });
    // }
    // if (otherBlocks?.length > 0) {
    //   nav.push({
    //     title: "Другие ЖК застройщика",
    //     ref: otherRef,
    //     type: "other",
    //   });
    // }

    return nav;
  }, [block, haveApartments]);

  return (
    <div class="complex">
      {block && (
        <div>
          <Block
            block={block}
            handleFavorites={handleFavoritesClick}
            navigation={navigation}
            isClientMode={isClientMode}
            clients={clients}
            addClientToFavorite={addClientToFavorite}
            removeClientFromFavorite={removeClientFromFavorite}
            fetchClients={fetchClients}
            haveApartments={haveApartments}
            pricesRef={pricesRef}
            aboutRef={aboutRef}
            mortgageRef={mortgageRef}
            newsRef={newsRef}
            otherRef={otherRef}
            banksRef={banksRef}
            promoRef={promoRef}
            chartRef={chartRef}
          />
          <Apartments
            city={city}
            id={complex}
            manager={block.manager}
            realtyFilter={realtyFilter}
            innerRef={pricesRef}
            setHaveApartments={setHaveApartments}
            haveApartments={haveApartments}
            clientMode={isClientMode}
            userInfo={userInfo}
            filter={filter}
            setFilter={handleSetFilter}
            clearFilter={handleResetFilter}
            filterData={filterData}
            apartments={apartments}
            getApartments={getComplexApartments}
            count={count}
          />

          {isMobile ? (
            <div className="complex__collapse">
              <Collapse
                title={
                  <>
                    <span className="complex__collapse-icon">
                      <Desc />
                    </span>
                    Описание
                  </>
                }
              >
                <About
                  title={`О ЖК ${block.name}`}
                  previewText={block.about.previewText}
                  detailText={block.about.previewText}
                  advantages={block.about.advantages}
                  innerRef={aboutRef}
                />
              </Collapse>

              {
                // ComplexChart
              }
              {
                <Collapse
                  title={
                    <>
                      <span className="complex__collapse-icon">
                        <Dinam />
                      </span>
                      Динамика цены
                    </>
                  }
                >
                  <ComplexChart
                    innerRef={chartRef}
                    title={block.name}
                    id={id}
                    city={city}
                  />
                </Collapse>
              }

              <Collapse
                title={
                  <>
                    <span className="complex__collapse-icon">
                      <Bank />
                    </span>
                    Аккредитованные банки
                  </>
                }
              >
                {block?.banks?.length > 0 && haveApartments && (
                  <Banks items={block.banks} innerRef={banksRef} />
                )}
              </Collapse>
              {false && haveApartments && (
                <Collapse
                  title={
                    <>
                      <span className="complex__collapse-icon">
                        <Calculator />
                      </span>
                      Рассчитать ипотеку
                    </>
                  }
                >
                  <Mortgage
                    manager={block.mortgageManager}
                    id={block.id}
                    innerRef={mortgageRef}
                    price={block.apartmentsInfo?.minPrice}
                    period={15}
                    prepay={round(block.apartmentsInfo?.minPrice * 0.2, -4)}
                    percent={6.5}
                  />
                </Collapse>
              )}

              {actions?.length > 0 && (
                <Collapse
                  title={
                    <>
                      <span className="complex__collapse-icon">
                        <Calculator />
                      </span>
                      Промо
                    </>
                  }
                >
                  <Promo items={actions} innerRef={promoRef} />
                </Collapse>
              )}

              {false && news?.length > 0 && (
                <Collapse
                  title={
                    <>
                      <span className="complex__collapse-icon">
                        <Newspaper />
                      </span>
                      Новости
                    </>
                  }
                >
                  <News items={news} innerRef={newsRef} />
                </Collapse>
              )}
            </div>
          ) : (
            <>
              <About
                title={`О ЖК ${block.name}`}
                previewText={block.about.previewText}
                detailText={block.about.previewText}
                advantages={block.about.advantages}
                innerRef={aboutRef}
              />

              <ComplexChart
                innerRef={chartRef}
                title={block.name}
                id={id}
                city={city}
              />

              {block?.banks?.length > 0 && haveApartments && (
                <Banks items={block.banks} innerRef={banksRef} />
              )}

              {false && haveApartments && (
                <ErrorPlug wrappedComponent="src/comp-ts/Complex/index.jsx">
                  <Mortgage
                    manager={block.mortgageManager}
                    id={block.id}
                    innerRef={mortgageRef}
                    price={block.apartmentsInfo?.minPrice}
                    period={15}
                    prepay={round(block.apartmentsInfo?.minPrice * 0.2, -4)}
                    percent={6.5}
                  />
                </ErrorPlug>
              )}

              {actions?.length > 0 ? (
                <Promo items={actions} innerRef={promoRef} />
              ) : (
                <div className="margin-sect"></div>
              )}

              {false && news?.length > 0 ? (
                <News items={news} innerRef={newsRef} />
              ) : (
                <div className="margin-sect"></div>
              )}
            </>
          )}

          {/* <Subscribe email={userInfo.email} /> */}

          {false && otherBlocks?.length > 0 ? (
            <OtherBlocks items={otherBlocks} innerRef={otherRef} city={city} />
          ) : (
            <div className="margin-sect"></div>
          )}
        </div>
      )}
      <Preloader loading={isFetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch({ type: 'COMPLEX_SET_FILTER', filter }),
  clearFilter: () => dispatch({ type: 'COMPLEX_CLEAR_FILTER' }),
  fetch: (params) => dispatch(fetchComplex(params)),
  getApartments: (city, filter, savedFilterXmlId, params) =>
    dispatch(getApartments(city, filter, savedFilterXmlId, params)),
  getCount: (city, filter, savedFilterXmlId) =>
    dispatch(getCount(city, filter, savedFilterXmlId)),

  reset: () => dispatch(clearComplex()),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        whatPage,
        type,
      ),
    ),
});

const mapStateToProps = (state) => ({
  filter: state.complex.filter,
  isFetching: state.complex.isFetching,
  error: state.complex.error,
  block: state.complex.data,
  news: state.complex.news,
  actions: state.complex.actions,
  otherBlocks: state.complex.otherBlocks,
  realtyFilter: state.estate.filter.filter,
  isClientMode: state.general.clientMode,
  userInfo: state.user.info,
  clients: state.clients.data,
  filterData: state.complex.filterData,
  apartments: state.complex.apartments,
  count: state.complex.count,
});

export default connect(mapStateToProps, mapDispatchToProps)(Complex);
