import formatter from "format-number";
import { round } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useModal } from 'react-modal-hook';
import { useSelector } from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import ym from "react-yandex-metrika";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import { getPricePerMeter } from "../../utils";
import AddPresentation from "../AddPresentation";
import PopoverBlock from "../Cards/PopoverBlock";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import Wrap from "../core/ModalWrap";
import Popup from "../core/Popup";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import ReserveAssignation from "../forms/ReserveAssignation";
import Manager from "../Manager";
import { successToast } from "../toasts";
import Viewer from "../Viewer/Viewer";
import AssignationText from "./AssignationText";
import PresentationBlock from "./PresentationBlock";

const priceFormat = (price) => {
  if (price && price.toString().includes("%")) {
    return price
  }
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};
const tabs = [
  "plan",
  "floorPlan",
  "complex",
  "decoration",
  "interior",
  "aeroPanorama",
  "decorationFinish",
  "decorationPreFinish",
  "decorationRaw",
  "gidImg",
  "viewFromWindow",
  "viewFromWindowCords",
  "generalPlan"
]
const percentFormat = (comission) => {
  let values = comission.replace(/,/g, ".").replace(/ /g, "").replace(/%/g, "").split("-")
  if (values.length === 1) {
    return values[0] + "%"
  }
  return values.map((value, i) => {
    return i == 0 ? value + "%-" : value + "%"
  })
};




const AssignationInfo = (props) => {
  const {
    manager,
    data,
    city,
    clientMode,
    addClientToFavorite,
    removeClientFromFavorite,
    handleFavorites,
    clients,
    fetchClients,
    isAgent,
    isCommercial,
    disableButtons,
    complexLink,
    agent,
    savedFilterXmlId,
    isPublic,
    presentationType,
    client,
    filterType = "assignments",
  } = props;
  const { id } = useParams();



  const isMobile = useMedia("isMobile")
  const photos = useMemo(() => ({
    plan: [data.plan],
    complex: data.photos?.map((src, id) => ({ id, src })),
    ...(data.floorPlan ? { floorPlan: [data.floorPlan] } : []),
    ...(data.gidImg?.src ? { gidImg: [data.gidImg] } : []),
    ...(data.viewFromWindow?.src && !data.viewFromWindow?.empty ? { viewFromWindow: [data.viewFromWindow] } : []),
    ...(data.viewFromWindow?.cone ? { viewFromWindowCords: data.viewFromWindow } : []),
    ...(data.generalPlan? { generalPlan: data.generalPlan } : []),
    ...(data.builder?.typical3DTour? { tour: getUrl(data.builder?.typical3DTour) } : []),
  }), [data])
  const regulationsLink = useSelector(state=>state.assignment?.regulationsLink)
  const isApartments = data?.isApartments === "Y"


  let location = useLocation();
  let splitUrl = location.pathname.split('/')
  const savedFilterSettings = useSelector(state => {
    if (splitUrl[2] === 'commercials') {
      return state.commercialUnit.savedFilterSettings ?? {}
    } else if (splitUrl[2] === 'assignments') {
      return state.assignment.savedFilterSettings ?? {}
    }
  })

  const [openReserveModal, closeReserveModal] = useModal(({ in: open, onExited }) => (
    <Wrap wrapper={children => isMobile
      ? <MobileModal closeClick={closeReserveModal} title={isCommercial? "Забронировать объект" : "Забронировать квартиру"}>{children}</MobileModal>
      : <Modal close={closeReserveModal} classes="modal_task">{children}</Modal>
    } >
        <ReserveAssignation
          id={id}
          savedFilterSettings={savedFilterSettings}
          handleSend={() => submitReserve()}
          client={client}
          isPublic={isPublic}
          savedFilterXmlId={savedFilterXmlId}
          title={isCommercial? "объект" :"квартиру"}
          method={isCommercial ? `bookingCommercial` : `bookingAssignment`}
        />
    </Wrap >
  ));

  const submitReserve = () => {
    closeReserveModal();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };


  const [initMap, setInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isPhotoOpened, setisPhotoOpened] = useState(false);
  const mapLoad = (ymaps) => {
    setInitMap(true);
  };
  const [Maps, setMaps] = useState(null)
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({ YMaps, Map, Placemark, RulerControl })
  };
  useEffect(() => {
    if (!modalMap) return;
    dynamicMapImport()
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);
  // Sticky button
  const ref = useRef(null);
  const modalMap = useRef(null);

  const handleScroll = () => {

    if (!ref.current) return;

    const elScrollHeight = ref.current.offsetTop - window.innerHeight + 110

    if (window.scrollY > elScrollHeight) {
      ref.current.classList.add('flat__info-btns-sticky');
    } else if (window.scrollY < elScrollHeight) {
      ref.current.classList.remove('flat__info-btns-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!modalMap) return;
    //dynamicMapImport()
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);



  return (
    <>
      <section className="section section_view_white flat flat_margin_0">
        <div className="wrapper">
          <div className="assignation-info">
            <div className="flat__header">
              <div className="flat__grid">
                <div className={`flat__col flat__col-title`}>
                  <div className="flat__col-row is-row">
                    <div className={`flat__title${!isCommercial ? ` flat__title_small `: ``}`}>
                      <h1 className="h1">{isCommercial ? `Помещение \n ${data.squareTotal} м²` : data.roomType}</h1>
                      {data.dateUpdate && (
                        <div className="flat__updated">
                          Обновлено{" "}
                          {new Date(
                            data.dateUpdate.substring(0, 10),
                          ).toLocaleDateString()}
                        </div>
                      )}
                    </div>
                    {!disableButtons && isMobile &&
                      <PresentationBlock
                        objectXmlId={data.xmlId}
                        objectType={presentationType}
                        city={city}
                      />
                    }
                    <div className="flat__complex">
                      <div className="flat__complex-grid">
                        <div className="flat__complex-col flat__col-with-map-mobile">

                          <div className="flat__complex-title">
                            <h4 className="h4">ЖК {data.block?.name}</h4>
                            {!!data.block?.id && !data.block?.hideLink && (
                              <div className="flat__complex-link">
                                <Link
                                  target="_blank"
                                  className="link"
                                  style={savedFilterSettings ? {color: savedFilterSettings?.colorButton} : {}}
                                  to={
                                    complexLink
                                      ? `${complexLink}${data.block?.id}${savedFilterXmlId
                                        ? "?filtertype=" + filterType
                                        : ""
                                      }`
                                      : `/realty/${data.city}/complex/${data.block?.id}`
                                  }
                                >
                                  Страница ЖК
                                </Link>
                              </div>
                            )}


                          </div>
                          {
                            isMobile && !!data.coordinates && <div className="complex__address-map">
                              <span
                                className="show__map"
                                onClick={() => setShowMap(true)}
                              >
                              </span>
                            </div>
                          }
                        </div>


                        {
                          isMobile &&
                          <div className="flat__info-item for-mobile">

                            <div
                              className={`label label-item label_blue label_id`}
                            >
                              ID: {data.id}
                            </div>

                            <div
                              className={`label-item label_${data?.status?.color
                                }`}
                            >
                              {data?.status?.name}
                            </div>



                            {
                              !clientMode && !isAgent && !disableButtons && !isPublic &&
                              <div className="pill">
                                Комиссия {data.commission && isCommercial ? percentFormat(data.commission) : priceFormat(data.commissionPercent)}
                              </div>
                            }

                          </div>
                        }

                        {!isMobile &&
                          <div className="flat__complex-col">
                            <div className="flat__complex-address">
                              <span>
                                {data.region?.name} {data.address}
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                      <div className={`flat__complex-grid${data.subway.length > 2 ? ` flat__complex-grid_wrap` : ``}`}>
                        {data.subway && !isMobile &&
                          data.subway.map((subway, i) => {
                            if (!subway) {
                              return;
                            }
                            return (
                              <div
                                className="flat__complex-col"
                                key={subway.name + i}
                              >
                                <div className="complex__routes-item">
                                  <span
                                    className="complex__metro-color"
                                    style={{ backgroundColor: subway.color }}
                                  ></span>
                                  <span className="complex__metro-station">
                                    м. {subway.name}
                                  </span>
                                  <span className="complex__route-type">
                                    <svg
                                      className={`icon icon_${subway.distanceType === "byFoot"
                                        ? "pedestrian"
                                        : "auto"
                                        }`}
                                    >
                                      <use
                                        xlinkHref={`#${subway.distanceType === "byFoot"
                                          ? "pedestrian"
                                          : "auto"
                                          }`}
                                      ></use>
                                    </svg>
                                  </span>
                                  <span className="complex__route-time">
                                    {subway.minutes} мин.
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                </div>

                {!disableButtons && !isMobile && (
                  <div className="flat__col flat__col-download">

                    {!!data.coordinates &&
                      <div className="complex__address-map">
                        <span
                          className="show__map"
                          onClick={() => setShowMap(true)}
                        >

                        </span>
                      </div>
                    }

                    <PresentationBlock
                      objectXmlId={data.xmlId}
                      objectType={presentationType}
                      city={city}
                    />
                    <AddPresentation
                      id={data.xmlId}
                      city={city}
                      presentation={data.presentation}
                      type={presentationType}
                    />
                  </div>
                )}

              </div>


            </div>
            <div
              className="flat__content"
              style={{ ...(isCommercial ? { borderBottom: "none" } : []) }}
            >
              <div className="flat__grid flat__sticky-container">
                <div className="flat__col flat__col-images flat__col-slider" style={isPhotoOpened ? { position: "static" } : null}>
                  <div className="special__detail-slider">
                    <ErrorPlug stopSending={true}>
                      <Viewer
                        data={photos}
                        onTabChange={id => id === "tour" && ym('reachGoal', 'showroom_view')}
                        addClass={`assignation-photos special__detail-photos`}
                        tabs={tabs}
                        statusSold={data.status.id === 1001}
                        blurBg
                        onPhotoOpen={() => setisPhotoOpened(true)}
                        onPhotoClosed={() => setisPhotoOpened(false)}
                      />
                    </ErrorPlug>
                  </div>
                  {(manager || agent) && (
                    <div className="flat__manager sm-hide">
                      <Manager
                        data={agent ? agent : manager}
                        isAssignment={!isCommercial}
                        isCommercial={isCommercial}
                        key="mobileManager"
                        objectId={id}
                        isPublic={disableButtons}
                        onSuccessCallback={() =>
                          ym("reachGoal", "assignment_callback")
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="mobile-complex-details">
                  {isMobile &&
                    <div className="flat__complex-col">
                      <div className="flat__complex-address">
                        <span>
                          {data.region?.name} {data.address}
                        </span>
                      </div>
                    </div>
                  }
                  {data.subway && isMobile &&
                    data.subway.map((subway, i) => {
                      if (!subway) {
                        return;
                      }
                      return (
                        <li
                          className="flat__complex-col"
                          key={subway.name + i}
                        >
                          <div className="complex__routes-item">
                            <span
                              className="complex__metro-color"
                              style={{ backgroundColor: subway.color }}
                            ></span>
                            <span className="complex__metro-station">
                              м. {subway.name}
                            </span>
                            <span className="complex__route-type">
                              <svg
                                className={`icon icon_${subway.distanceType === "byFoot"
                                  ? "pedestrian"
                                  : "auto"
                                  }`}
                              >
                                <use
                                  xlinkHref={`#${subway.distanceType === "byFoot"
                                    ? "pedestrian"
                                    : "auto"
                                    }`}
                                ></use>
                              </svg>
                            </span>
                            <span className="complex__route-time">
                              {subway.minutes} мин.
                            </span>
                          </div>
                        </li>
                      );
                    })}
                </div>
                {!disableButtons && isMobile &&
                  <div className="flat__mobile-presentation">
                    <AddPresentation
                      id={data.xmlId}
                      city={city}
                      presentation={data.presentation}
                      type={presentationType}
                    />
                  </div>
                }
                <div className="flat__col flat__col-info">
                  <div className="flat__price">
                    {!isMobile &&
                      <div className="flat__price-col flat__price-item flat__price-item_flex flat__price-item_padding_top">
                        <div className="flat__info-item">
                          <div
                            className={`label label-item label_blue label_id`}
                          >
                            ID: {data.id}
                          </div>
                        </div>
                        <div className="flat__info-item">
                          <div
                            className={`label-item label_${data?.status?.color}`}
                          >
                            {data?.status?.name}
                          </div>
                        </div>

                        {
                          data.clientType && (
                            <div className="flat__info-item">
                              <div className="pill assignation-info__client-type" style={{ marginLeft: 0 }}>
                                {data.clientType.title}
                              </div>
                            </div>
                          )
                        }

                      </div>
                    }
                    <div className="flat__price-col flat__price-actions for-fav">
                      <div className="flat__price-action">
                        {!disableButtons && (
                          <div className="complex__actions-item">
                            <div
                              className={`add-fav-btn js-favorites-add dark ${data.favorite && data.favorite.inFavorite
                                ? "is-fav"
                                : ""
                                }`}
                              onClick={() =>
                                handleFavorites(data.favorite.inFavorite)
                              }
                            >
                              <button className="button button_type_settings">
                                <svg className="icon icon_settings">
                                  <use xlinkHref="#fav"></use>
                                </svg>
                              </button>
                              {!isMobile && (data.favorite && data.favorite.inFavorite
                                ? "В избранном"
                                : "В избранное")}
                              <PopoverBlock
                                type={isCommercial ? "commercial" : "assignment"}
                                id={data.id}
                                favorite={data.favorite}
                                city={"spb"}
                                allClients={clients}
                                addClientToFavorite={addClientToFavorite}
                                removeClientFromFavorite={
                                  removeClientFromFavorite
                                }
                                fetchClients={fetchClients}
                                whatPage={isCommercial ? "commercial" : "assignment"}
                                showOnLeft={true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {false && (
                        <div className="flat__price-action">
                          <div className="add-compare-btn js-compares-add">
                            <button className="button button_type_settings">
                              <svg className="icon icon_settings">
                                <use xlinkHref="#icon-compare"></use>
                              </svg>
                            </button>
                            К сравнению
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flat__period-grid">
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {priceFormat(data.costWithDiscount)}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена, 100% оплата
                        </div>
                      </div>
                    </div>
                    {!isPublic && !clientMode && (!!data.priceAgreement && data.priceAgreement > 0) && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {priceFormat(data.priceAgreement)}
                          </div>
                          <div className="flat__info-item_desc">
                            Цена ДДУ
                          </div>
                        </div>
                      </div>
                    )}
                    {(!!data.priceWithoutUnderstating && data.priceWithoutUnderstating > 0) && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {priceFormat(round(data.priceWithoutUnderstating, -3))}
                          </div>
                          <div className="flat__info-item_desc">
                            Цена без занижения
                          </div>
                        </div>
                      </div>
                    )}
                    {!!data.costWithDiscount && !!data.squareTotal &&
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {priceFormat(parseInt(getPricePerMeter(data.costWithDiscount, data.squareTotal)))}
                          </div>
                          <div className="flat__info-item_desc">
                            Цена за кв. метр
                          </div>
                        </div>
                      </div>}

                    {!clientMode && !isAgent && !disableButtons && !isMobile && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.commission && isCommercial ? percentFormat(data.commission) : priceFormat(data.commissionPercent)}
                          </div>
                          <div className="flat__info-item_desc">Комиссия</div>
                        </div>
                      </div>
                    )}
                    {data.endingDate && data.endingDate.length > 0 && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.endingDate}
                          </div>
                          <div className="flat__info-item_desc">Срок сдачи</div>
                        </div>
                      </div>
                    )}
                    {!!data.expensesForDeveloper && (
                      <>
                        {!!data.expensesForDeveloper ? (
                          <div className="flat__period-col">
                            <div className="flat__info-item">
                              <div className="flat__info-item_value" style={{ display: 'flex' }}>
                                <span style={isMobile ? { paddingTop: '2px' } : {}}>{data.expensesForDeveloper }</span>

                                {
                                  !!data.expensesForDeveloper && (

                                    <>
                                      {
                                        isMobile ?
                                          <Popup
                                            trigger={
                                              <div className="info info_inline-flex">
                                                <svg className="icon icon_info">
                                                  <use xlinkHref="#info"></use>
                                                </svg>
                                              </div>
                                            }
                                          >
                                            <p>Возможные расходы при проведении сделки</p>
                                          </Popup> :
                                          (
                                            <div className={`info info_inline-flex`}>
                                              <svg className="icon icon_info">
                                                <use xlinkHref="#info"></use>
                                              </svg>
                                              <div className="info__popup">
                                                <p className="flat__info-item_desc">Возможные расходы при проведении сделки</p>
                                              </div>
                                            </div>
                                          )
                                      }

                                    </>
                                  )
                                }

                              </div>
                              <div className="flat__info-item_desc">
                                Расходы на застройщика
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flat__period-col">
                            <div className="flat__info-item">
                              <div className="flat__info-item_value">
                                {data.expensesForDeveloper}
                              </div>
                              <div className="flat__info-item_desc">
                                Расходы на застройщика
                              </div>
                            </div>
                          </div>
                        )
                        }
                      </>
                    )}
                    {data.encumbrances && data.encumbrances.length > 0 && (
                      <>
                        {(data.encumbranceDescription && data.encumbranceDescription.length > 0) ? (
                          <div className="flat__period-col">
                            <div className="flat__info-item">
                              <div className="flat__info-item_value" style={{ display: 'flex' }}>
                                <span style={isMobile ? { paddingTop: '2px' } : {}}>{data.encumbrances.join(", ")}</span>

                                {
                                  data.encumbranceDescription && data.encumbranceDescription.length > 0 && (

                                    <>
                                      {
                                        isMobile ?
                                          <Popup
                                            trigger={
                                              <div className="info info_inline-flex">
                                                <svg className="icon icon_info">
                                                  <use xlinkHref="#info"></use>
                                                </svg>
                                              </div>
                                            }
                                          >
                                            <p>{data.encumbranceDescription}</p>
                                          </Popup> :
                                          (
                                            <div className={`info info_inline-flex`}>
                                              <svg className="icon icon_info">
                                                <use xlinkHref="#info"></use>
                                              </svg>
                                              <div className="info__popup">
                                                <p className="flat__info-item_desc">{data.encumbranceDescription}</p>
                                              </div>
                                            </div>
                                          )
                                      }

                                    </>
                                  )
                                }

                              </div>
                              <div className="flat__info-item_desc">
                                Список обременений
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flat__period-col">
                            <div className="flat__info-item">
                              <div className="flat__info-item_value">
                                {data.encumbrances.join(", ")}
                              </div>
                              <div className="flat__info-item_desc">
                                Список обременений
                              </div>
                            </div>
                          </div>
                        )
                        }
                      </>
                    )}
                  </div>
                  <div className="flat__info-btns">
                    {data?.status?.id != "1001" && <div style={{ width: isMobile ? '100%' : 'auto' }} ref={ref}>
                      <div className="js-modal-btn">
                        {
                          isPublic ? (
                            <button
                              className="button button_view_default"
                              onClick={openReserveModal}
                              style={savedFilterSettings ? {color: savedFilterSettings?.colorButtonText, backgroundColor: savedFilterSettings?.colorButton} : {}}
                            >
                              Хочу забронировать
                            </button>
                          ) : (
                            <Link
                            className="button button_view_default"
                            to={`/flat-booking/${isCommercial ? "commercial" : "assignment"}/${id}`}
                          >
                              Хочу забронировать
                          </Link>
                          )
                        }

                      </div>
                    </div>}
                    {!isCommercial && !disableButtons && regulationsLink &&  (
                      <div className="js-modal-btn">
                        <a
                          href={`${regulationsLink}`}
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                        >
                          <button className="button button_view_outline for-reglament justify-center">
                            {isMobile &&
                              <svg class="icon file-up__icon">
                                <use xlinkHref="#deals-01"></use>
                              </svg>
                            }
                            <span>Регламент</span>
                          </button>
                        </a>
                      </div>
                    )}
                    {isCommercial && !!data.files && !disableButtons && (
                      <div className="js-modal-btn">
                        <Link to={`/files/${data.files}`} target="_blank">
                          <button className="button button_view_outline for-reglament justify-center">
                            {isMobile &&
                              <svg class="icon file-up__icon">
                                <use xlinkHref="#deals-01"></use>
                              </svg>
                            }
                            <span>Файлы</span>
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className="flat__info-grid">
                  {data.realtyType && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.realtyType.title}
                          </div>
                          <div className="flat__info-item_desc">Тип договора</div>
                        </div>
                      </div>
                    )}
                    {data.builder?.name && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.builder?.name}
                          </div>
                          <div className="flat__info-item_desc">Застройщик</div>
                        </div>
                      </div>
                    )}
                    {isApartments && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            Апартаменты
                          </div>
                          <div className="flat__info-item_desc">Тип</div>
                        </div>
                      </div>
                    )}
                    {
                      // <div className="flat__info-col">
                      // <div className="flat__info-item">
                      // <div className="flat__info-item_value">{data.id}</div>
                      // <div className="flat__info-item_desc">ID</div>
                      // </div>
                      // </div>
                    }
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.squareTotal} м<sup>2</sup>
                        </div>
                        <div className="flat__info-item_desc">Площадь</div>
                      </div>
                    </div>
                    {Boolean(data.squareLiving) && data.squareLiving > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareLiving} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Жилая площадь
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(data.squareBalcony) && data.squareBalcony > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareBalcony} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Площадь балкона
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(data.squareToilet) && data.squareToilet > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareToilet} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Санузел
                          </div>
                        </div>
                      </div>
                    )}
                    {data.number && data.number != 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.number}
                          </div>
                          <div className="flat__info-item_desc">
                            {isCommercial ? "Номер помещения" : "Номер квартиры"}
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(data.squareKitchen) && data.squareKitchen > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareKitchen} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Площадь кухни
                          </div>
                        </div>
                      </div>
                    )}
                    {data.corpus && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.corpus}
                          </div>
                          <div className="flat__info-item_desc">
                            Корпус / Участок / Дом
                          </div>
                        </div>
                      </div>
                    )}
                    {data.floor && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.floor}
                          </div>
                          <div className="flat__info-item_desc">Этаж</div>
                        </div>
                      </div>
                    )}
                    {data.decoration && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.decoration}
                          </div>
                          <div className="flat__info-item_desc">Отделка</div>
                        </div>
                      </div>
                    )}
                    {data.cellHeight && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.cellHeight} м.
                          </div>
                          <div className="flat__info-item_desc">Высота потолков</div>
                        </div>
                      </div>
                    )}
                    {data.contract && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.contract}
                          </div>
                          <div className="flat__info-item_desc">
                            Форма договора
                          </div>
                        </div>
                      </div>
                    )}
                    {data.power && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.power} кВт
                          </div>
                          <div className="flat__info-item_desc">
                            Выделенная мощность
                          </div>
                        </div>
                      </div>
                    )}
                    {data.ventilation && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.ventilation} мм
                          </div>
                          <div className="flat__info-item_desc">
                            Вентиляция
                          </div>
                        </div>
                      </div>
                    )}
                    {data.section && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.section}
                          </div>
                          <div className="flat__info-item_desc">
                            Секция
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {data && !!data.note && <AssignationText note={data.note} />}
                </div>
              </div>
            </div>
            {(manager || agent) && (
              <div className="flat__manager sm-show">
                <Manager
                  // data={manager}
                  key="mobileManager"
                  isAssignment={!isCommercial}
                  isCommercial={isCommercial}
                  data={agent ? agent : manager}
                  isPublic={disableButtons}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="modal" ref={modalMap}>
        <div className="modal__shadow " data-id="dist-map" style={{ background: `rgba(0, 0, 0, 0.4)` }}></div>
        <div className="modal__content">
          <div className="modal__inner">
            <div
              className="modal__close "
              data-id="dist-map"
              onClick={() => setShowMap(false)}
            >
              <svg className="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
            <h3 className="modal__title">{`ЖК «${data.block?.name}» на карте`}</h3>
            <div className="dist-map" id="dist-map-container">
              {
                initMap || showMap ? (
                  <Maps.YMaps>
                    <Maps.Map
                      defaultState={{
                        center: data.coordinates,
                        zoom: 16,
                      }}
                      className="complex__map-wrap"
                      onLoad={mapLoad}
                    >
                      <Maps.Placemark
                        geometry={data.coordinates}
                        options={{
                          iconLayout: "default#image",
                          iconImageHref: "/assets/images/place.png",
                          iconImageSize: [25, 40],
                          iconImageOffset: [-25, -20],
                        }}
                      />
                      <Maps.RulerControl
                        options={{ position: { top: 10, left: 10 } }}
                      />
                    </Maps.Map>
                  </Maps.YMaps>
                ) : (
                  <div className="dist-map" id="dist-map-container" />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignationInfo;
