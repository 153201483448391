import React from 'react';
import SimpleBar from 'simplebar-react';
import useMedia from "../../hooks/useMedia";
import { parseDate } from '../../utils';

const SuccessIcon = () => <svg className="icon icon_check-mark">
  <use xlinkHref="#check-mark"></use>
</svg>

const FailIcon = () => <svg className="icon icon_x-mark">
  <use xlinkHref="#x-mark"></use>
</svg>

const getIcon = (status, isFail) => {
  if(status === "active" || status === "inactive") return ""
  const child = isFail ? <FailIcon /> : <SuccessIcon />
  return <span className="training__task-result">
    {child}
  </span>
}


const getProgressPercent = progress => {
  return `${(progress?.current / progress?.all)*100}%`
}


const TaskList = (props) => {
  const isMobile = useMedia("isMobile");
  
  return (
    isMobile ? <div className="training__task-list">{props.children}</div> : <SimpleBar className="training__task-list">{props.children}</SimpleBar>
  );
};

const classByStatus= {
  inactive: "training__task_not-available",
  active: "training__task_inactive",
  finished: "",
  complete: ""

}
const Aside = ({ schedule, setActiveShedule, progress, isSendAnket, rating, setActivePage, activeShedule, answersResults}) => {

  return (
    <aside className="training__aside">
      <div className="training__aside-header">
        <h5 className="h5 training__aside-title">Прогресс по курсу</h5>
        <div className="training__progress">
          <div className="training__progress-bar">
            <div className="training__progress-filler" style={{ width: getProgressPercent(progress) }}></div>
          </div>

          <p className="training__progress-counter">
            <span className="training__progress-current">{progress?.current}</span> / <span className="training__progress-total">{progress?.all}</span>
          </p>
        </div>

        {rating && <p className="training__place">
          Место в общем рейтинге:
          <span className="training__current-place">{rating}</span>
        </p>}
      </div>


      <TaskList>
        <div className="training__task-body">
          {!isSendAnket && <button
            type="button"
            className="training__task training__task_current"
            onClick={()=>setActivePage("anket")}
          >
            {/* <span className="training__task-date">{dateParsed}</span> */}
            <span className="training__task-name">Анкета участника</span>
          </button>}
          {schedule && schedule.map(item => {
            const [dateParsed] = parseDate(item.dateFrom, true)

            return <button
              key={item.id}
              type="button"
              className={`training__task
              ${activeShedule === item.test.id ? "" : classByStatus[item.test?.status]}
              ${activeShedule === item.test.id ? "training__task_current": ""}`
              }
              onClick={() => item.test?.status === "inactive" ? null: setActiveShedule(item)}
            >
              <span className="training__task-date">{dateParsed}</span>
              <span className="training__task-name">{item.test.name}</span>
              {getIcon(item.test?.status, answersResults?.[item.test.id]?.percent < 80 || item.test?.status ==="finished"  )}
              {item.test?.status === "inactive" && <span className="training__tooltip">Задание станет доступно {dateParsed}</span>}
            </button>
          })}
        </div>
      </TaskList>
    </aside>
  );
};


export default Aside;
