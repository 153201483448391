import { Link } from 'react-router-dom';
import { PromoIcon } from '../icons';
import PanModal from '../../../core/PanModal';
import { useModal } from 'react-modal-hook';
import PromoCardModal from './PromoCardModal';

const PromoCard = ({ id, title, activeTo, managerInfo }) => {

  const [openModal, closeModal] = useModal(
    () => (
      <PanModal title={'Акция'} closeModal={closeModal} isSmall>
        <PromoCardModal id={id} managerInfo={managerInfo} />
      </PanModal>
    ),
    [id],
  );

  return (
    <div onClick={openModal} className="sf__promo sf__block sf__promo_card">
      <div className="sf__promo-icon">
        <PromoIcon />
      </div>

      <div className="sf__promo-body">
        <p className="h4-l sf__promo-title">{title}</p>
        <p className="sf__promo-timing">
          <span className="sf__promo-key text">Акция действует:</span>
          <p className="sf__promo-value h6">{activeTo}</p>
        </p>
      </div>
    </div>
  );
};

export default PromoCard;
