import './index.scss';
import Header from '../../components/Public/markup/Header';
import Title from '../../components/Public/markup/Title';
import CardsList from '../../components/Public/markup/CardsList';
import MapBtn from '../../components/Public/markup/MapBtn';
import Sort from '../../components/Public/markup/Sort';
import Tags from '../../components/Public/markup/Tags';
import FilterForm from '../../components/Public/markup/FilterForm';
import FilterBtn from '../../components/Public/markup/FilterBtn';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import MapBlock from '../../components/Public/markup/MapBlock';
import TopBar from '../../components/Public/markup/TopBar';

import useMedia from '../../hooks/useMedia';
import useFilter from '../../hooks/useFilter';
import { getSuffix, media } from '../../utils';
import SubmitButton from '../../components/SubmitButton';
import Pagination from '../../components/Pagination';
import CountRealty from '../../components/CountRealty';
import Dropdown from '../../components/core/Dropdown';
import { sortlistPrimary } from '../../hooks/useFilter/filterSets';
import Preloader from '../../components/Preloader';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import RealtyMap from '../../components/Public/markup/Map';
import { extractPrimaryMapItems } from '../../hooks/useFilter/filterUtils';
import PrimaryPublicCard from '../../components/Public/markup/Containers/PrimaryPublicCard';
import PublicPrimaryMapFilter from '../../components/Public/markup/MapFilters/PublicPrimaryMapFilter';
import PublicMobileFilter from '../../components/Public/markup/MapFilters/PublicMobileFilter';
import DraggableZone from '../../components/FilterRealty/Draggable';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import Card from '../../components/core/Card';
import { useModal } from 'react-modal-hook';
import Wrap from '../../components/core/ModalWrap';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import FilterDisabled from '../../components/Public/FilterDisabled';
import PrimaryPublicFilters from '../../components/Public/markup/PrimaryPublicFilters';
import { useMemo } from 'react';
import useRootCSS from '../../hooks/useRootCSS';
import { omit } from 'lodash';

const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count?.countBlocks}
      loaderColor={loaderColor}
    >
      {`${count?.countBlocks} объект${getSuffix(count?.countBlocks)}`}
    </SubmitButton>
  );
};

const Filter = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  const { savedFilterXmlId } = useParams();
  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      blocks = [],
      filter: filterData = {},
      agent,
      city,
      isActive,
      isShowFilter,
      savedFilter,
      savedFilterSettings,
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent
  } = useFilter({
    apiConfig: {
      component: 'panpartner:block.filter.ajax',
      getCountFunction: 'getCount',
      submitFunction: 'getBlocks',
      getMapItems: 'getBlocks',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      savedFilterXmlId,
    },
    defaultOrder: 'start',
    defaultBy: 'desc',
    defaultSortId: "7",
    sortEnabled: true,
    paginationEnabled: true,
    sortList: sortlistPrimary,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'blocks',
    shadowFilters: ['status'],
    extractCount: ({ countApartments, countBlocks }) => ({
      countApartments,
      countBlocks,
    }),
    extractLoadMoreCount: ({ countBlocks }) => countBlocks,
    extractMapItems: ({ blocks }) => blocks,
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },
    fields: {
      blocks: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
      microDistricts: []
    },
  });
  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <PublicMobileFilter
            component={PrimaryPublicFilters}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count?.countBlocks} объект${getSuffix(
              count?.countBlocks,
            )}`}
            resetFilter={() => resetFilter(true)}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count?.countBlocks}
            isMobile={isMobile}
            loaderColor={savedFilterSettings?.colorButtonText}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );
  const badges = useMemo(() => isShowFilter || !savedFilter?.filter ? filterBadges : createBadges(savedFilter?.filter), [filterBadges, savedFilter])
  const saveToLocalSotage = id => {
    const tmpFilter = savedFilter?.filter ? {...savedFilter.filter, ...filter} : filter;
    const tmpExclude = savedFilter?.exclude ? {...savedFilter.exclude, ...exclude} : exclude;
    // localStorage.setItem("blocksFilter", JSON.stringify({ [id]: { filter: tmpFilter, exclude: tmpExclude } })) // для новой карточки ЖК нужно это
    localStorage.setItem("blocksFilter", JSON.stringify({ [id]: tmpFilter}))
  }
  if (isLoading) return <Preloader loading={true} />;
  if (!isActive) return <FilterDisabled agent={agent} />;
  if (view === 'map')
    return (
      <div className="sf sf_map"> 
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems}
          extractFeatures={extractPrimaryMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}
          savedFilterSettings={savedFilterSettings}
        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={PrimaryPublicCard}
          getLink={(item) =>
            `/public/apartments/${savedFilterXmlId}/${city}/complex/${item.id}`
          }
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={PublicPrimaryMapFilter}
          count={`${count?.countBlocks} объект${getSuffix(count?.countBlocks)}`}
          onSumbit={submitMap}
          disableSubmit={!count?.countBlocks}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          onClick={saveToLocalSotage}
          isShowFilter={isShowFilter}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          loaderColor={savedFilterSettings?.colorButtonText}
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      {!savedFilterSettings?.hideHeader && (
        <Header {...agent} savedFilterSettings={savedFilterSettings} />
      )}

      {!savedFilterSettings?.hideTitle && (
        <Title
          title={
            savedFilterSettings?.title
              ? savedFilterSettings?.title
              : `Новостройки ${city === 'spb' ? 'Санкт-Петербурга' : 'Москвы'}`
          }
        />
      )}
      {!isTablet && (
        <>
          {isShowFilter && (
            <div className="sf__filter form">
              <PrimaryPublicFilters
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count?.countBlocks} объект${getSuffix(
                  count?.countBlocks,
                )}`}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count?.countBlocks}
                clearByIdent={clearByIdent}
              />
            </div>
          )}
          <div style={{marginTop: 25}}>
          <Tags
            badges={badges}
            removeBadge={removeBadge}
            resetFilter={() => resetFilter(true)}
            readonly={!isShowFilter}
          />
          </div>
          {/* <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          /> */}
        </>
      )}

      {isLaptop && !isTablet && (
        <FilterSubmitButton
          onClick={submit}
          isLoading={isCountLoading || isLoading}
          count={count}
          loaderColor={savedFilterSettings?.colorButtonText}
        />
      )}
      <div className="sf__row">
        {!isTablet && (
          <div className="special-filter-drop">
            <Dropdown
              innerClass={``}
              value={sortId}
              options={sortlistPrimary}
              onChange={handleSort}
            />
          </div>
        )}

        <div className="sf__filter-btns">
          {isTablet && isShowFilter && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn
            setShowMap={() => setView('map')}
            iconColor={savedFilterSettings?.colorButton}
          />
          {!isLaptop && (
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}
              loaderColor={savedFilterSettings?.colorButtonText}
            />
          )}
        </div>
      </div>

      <CardsList
        listFromSf
        items={blocks}
        limit={limit}
        isLoading={showSkeleton}
        itemsRef={infiniteRef}
        cardComponent={PrimaryPublicCard}
        getLink={(item) =>
          `/public/apartments/${savedFilterXmlId}/${city}/complex/${item.id}`
        }
        onClick={saveToLocalSotage}
      />
      <LoadMoreSpinner loading={moreIsLoading} />
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут 
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count?.countBlocks}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count?.countBlocks) && (
            <CountRealty
              itemsCount={count?.countBlocks}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Filter;
