const initialState = {
  isFetching: false,
  error: false,
  data: {
    districts: [],
  },
  clients: [],
};

const autopan = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTOPAN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
      };
    case 'AUTOPAN_CLIENTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: false,
        clients: action.clients,
      };
    case 'AUTOPAN_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};

export default autopan;
