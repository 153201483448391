import cloneDeep from "lodash/cloneDeep";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ym from "react-yandex-metrika";
import { isDev } from "../../api";
import useDocTitle from "../../hooks/useDocTitle";
import { combineFormData, filterToParams, getSuffix, toFormData } from "../../utils";
import Preloader from "../Preloader";
import PrepaidBanner from "../PrepaidBanner";
import Top from "../TopNew";
import AttentionBlock from "./AttentionBlock";
// import Filter from "./Filter";
import Filter from "./FilterNew";
import Items from "./ItemsNew";

import RealtyMap from "../Public/markup/Map";
import MapBlock from '../Public/markup/MapBlock';
import TopBar from '../Public/markup/TopBar';


import useFilter from "../../hooks/useFilter";
import { extractAssignmentsMapItems } from '../../hooks/useFilter/filterUtils';
import AssignmentsMapFilters from "../Public/markup/AssignmentsMapFilters";
import AssignationPublicCard from "../Public/markup/Containers/AssignationPublicCard";
import MapFilters from "../Public/markup/MapFilters/Index";
import LoadMoreSpinner from "../LoadMoreSpinner";

const openSellAssignments = () => window.open("/sell/assignment", "_blank");

const ListNew = props => {

  useDocTitle("Переуступки Санкт-Петербурга");

  const clientMode = useSelector(state => state.general?.clientMode)
  const isAgent = useSelector(state => state.user?.info?.groups?.some?.(group => group === "test_access"))
  const banners = useSelector(state => state.banners);

  const extractSubItems = (setData, resp) => {
    const assignments = resp.assignments?.types;
    const type = assignments?.[0];
    setData((prev) => {
      const tmp = cloneDeep(prev);
      const currentAssignmentsType = tmp.assignments?.types.findIndex(
        ({ roomTypeId }) => roomTypeId === type.roomTypeId,
      );
      tmp.assignments.types[currentAssignmentsType].items = type.items;

      return tmp;
    });
  };

  const {
    data,
    handleFilter,
    submit,
    isLoading,
    infiniteRef,
    filter = {},
    exclude = {},
    count,
    data: {
      assignments = [],
      filter: filterData = {},
      top,
      savedFilterSettings,
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    loadSubItems,
    isCountLoading,
    view: viewType,
    setView: setViewType,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    limit,
    moreIsLoading,
    createBadges,
    handleSort,
    sortId,
  } = useFilter({
    //TO DO: вынести конфиги фильтров отдельно
    apiConfig: {
      component: 'panpartner:assignments.ajax',
      submitFunction: 'getAssignments',
      getCountFunction: 'getCount',
      loadSubItems: 'getAssignments',
      getMapItems: 'getMapAssignments',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      city: "spb",
      onlyTypes: 1,
      getTop: 1,
    },
    shadowFilters: ['status'],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: '',
    },
    sortEnabled: false,
    paginationEnabled: false,
    loadMoreEnabled: false,
    isGetFilter: 1,
    loadMoreDataKey: 'assignments',
    // disableLoadMapItems: true,
    extractCount: ({ count }) => count,
    extractMapItems: ({ assignments }) => assignments,
    extractSubItems,
    extractLoadMoreCount: ({ count }) => count,
    processData: (resp, prev) => ({ ...prev, ...resp }),
    defaultView: "list"
  });

  const [previousState, setPreviousState] = useState({
    filter: {},
    exclude: {}
  })

  const submitFn = (...params) => {
    setPreviousState({
      filter,
      exclude
    })
    submit(...params)
  }
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const loadRow = roomTypeId => {

    const tmpFilter = {
      ...previousState
    }
    tmpFilter.filter.rooms = [roomTypeId]
    tmpFilter.exclude.rooms = []
    const filterFormData = toFormData(tmpFilter.filter, "filter")
    const excludesFormData = toFormData(tmpFilter.exclude, "exclude")
    const formData = combineFormData(filterFormData, excludesFormData)
    loadSubItems(formData, ["onlyTypes", "isGetFilter", "getTop"], true)
  }

  const handleChildClick = () => setIsFiltersOpen(prev => !prev);

  const closeClick = () => setIsFiltersOpen(false);

  const [showMapBlock, setShowMapBlock] = useState(true);
  const showHintRef = useRef(null);

  if (viewType === 'map') return (
    <div className="sf sf_map">
      <RealtyMap
        dispatch={dispatchMap}
        {...mapState}
        mapCenter={[59.9342802, 30.3350986]}
        items={mapItems}
        extractFeatures={extractAssignmentsMapItems}
        isLoading={isMapItemsLoading}
        innerCbRef={showHintRef}
        onObjectClick={() => setShowMapBlock(true)}
        savedFilterSettings={savedFilterSettings}
      />
      <div className="sf__map-top">
        <TopBar setShowMap={() => setViewType("list")} />
      </div>
      <MapBlock
        items={
          mapState?.selectedItems?.length ? mapState.selectedItems : mapItems
        }
        filterData={filterData}
        filter={filter}
        exclude={exclude}
        setFilterAndExclude={setFilterAndExclude}
        handleFilter={handleFilter}
        cardComponent={AssignationPublicCard}
        getLink={({ id }) =>
          `/assignments/${id}`
        }
        resetFilter={resetFilter}
        MapFilterBlock={MapFilters}
        component={AssignmentsMapFilters}
        withoutBtnsList={true}
        count={`${count} объект${getSuffix(count)}`}
        onSumbit={submitMap}
        disableSubmit={!count}
        isLoading={isCountLoading || isLoading}
        showFilters={mapState.showFilters}
        setShowFilters={(val) =>
          dispatchMap({ type: 'setData', payload: { showFilters: val } })
        }
        onMouseMove={(id) => showHintRef.current(id)}
        showBlock={showMapBlock}
        setShowBlock={setShowMapBlock}
        isShowFilter={true}
        loaderColor={savedFilterSettings?.colorButtonText}
      />
    </div>
  );
  if (isLoading) return <Preloader loading={true} />;
  return (
    <>
      {/*<AttentionBlock openBuyModal={openSellAssignments} />*/}
      <Filter
        filter={filter}
        exclude={exclude}
        filterData={filterData}
        handleFilter={setFilterAndExclude}
        openBuyModal={openSellAssignments}
        onSubmit={submitFn}

        info={data}
        countToShow={count}
        filterType="assignment"
        isFiltersOpen={isFiltersOpen}
        onClick={handleChildClick}
        closeClick={closeClick}
        countFilters={excludeBadges?.length + filterBadges?.length}


        filterBadges={filterBadges}
        excludeBadges={excludeBadges}
        clearExclude={clearExclude}
        clearFilter={clearFilter}
        isExcludeEmpty={isExcludeEmpty}
        isFilterEmpty={isFilterEmpty}
        removeBadge={removeBadge}
        switchFilterToExclude={switchFilterToExclude}
        setFilter={handleFilter}
        resetFilter={resetFilter}
        city="spb"
        redesign

        setViewType={setViewType}
      />
      {!!top?.length && <Top
        isAgent={isAgent}
        items={top}
        clientMode={clientMode}
        title={`ТОП-${top.length} переуступок`}
        clickSideEffect={() => !isDev && ym('reachGoal', 'top_assignments')}
        wrapperStyle={banners?.assignments && !clientMode ? {paddingBottom: 0} : null}
        redisign
      />}
      <div className="wrapper">
        <PrepaidBanner bannerId={"assignments"}/>
      </div>
      {!showSkeleton && <Items
        items={assignments}
        innerRef={infiniteRef}
        mapItems={mapItems}
        isAgent={isAgent}
        clientMode={clientMode}
        onClick={handleChildClick}
        count={count}
        loadRow={loadRow}
        viewType={viewType}
        setViewType={setViewType}
      />}
      {showSkeleton && <LoadMoreSpinner loading={true}/>}
    </>
  );
};

export default ListNew
