import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../hooks/useDropdown';
import { searchRuSymbols } from '../../utils';
const ChecboxItem = (props) => {
  const {
    identity,
    id,
    name,
    exclude = false,
    checked = false,
    onChange,
    disabled,
    value
  } = props;


  return (
    <div className="checkbox-group__item" style={{
      filter:
        disabled
          ? 'opacity(0.5)'
          : 'none',
    }}>
      <div className={exclude ? 'checkbox is-excluded' : 'checkbox'}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id={`${identity}-${id}`}
            checked={checked}
            value={value}
            onChange={(e) => onChange(e.target.checked, e.target.value)}
            disabled={disabled ? disabled : false}
          />
          <label
            className="checkbox__input"
            htmlFor={`${identity}-${id}`}
          ></label>
          <div className="checkbox__marker"></div>
        </div>

        <label className="checkbox__label" htmlFor={`${identity}-${id}`}>
          {name}
        </label>
      </div>
    </div>
  );
};

const SelectFilter = (props) => {
  const {
    isAutocomplete = false,
    items,
    changeHandler,
    right = false,
    formData,
    identity
  } = props;

  const [node, open, show, hide] = useDropdown(false);

  const [search, setSearch] = useState('');

  const [filterable, setFilterable] = useState(items || []);

  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right,
  });
 
  useEffect(() => {
    setFilterable(
      search
        ? items.filter((item) => {
          let tmpSearch = search.toLowerCase().replace(/ё/g, 'е');
          let title = item.name
            ? item.name.toLowerCase().replace(/ё/g, 'е')
            : false;
          if (!title) {
            return;
          }
          let aliasesSearch = null;
          if (item.aliases) {
            aliasesSearch = item.aliases.some(
              (alias) =>
                alias.toLowerCase().includes(tmpSearch.toLowerCase()) ||
                alias
                  .toLowerCase()
                  .includes(searchRuSymbols(tmpSearch.toLowerCase())),
            );
          }
          if (title.includes(tmpSearch) || aliasesSearch) {
            return true;
          } else {
            return searchRuSymbols(title.toLowerCase()).includes(
              searchRuSymbols(tmpSearch.toLowerCase()),
            );
          }
        })
        : items,
    );
  }, [search, items]);
  
  return (
    <div className="form__item">
      <div
        ref={node}
        className={'dropdown form__drop' + (open ? ' is-showing' : '')}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={() => (open ? hide() : show())}
        >
          <span className="form__drop-name">{items.find(item=>item.value == formData?.[0])?.name}</span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              <div className="filter">
                {isAutocomplete && (
                  <div className="filter__filter">
                    <label className="input input_type_default input_width_available ">
                      <input
                        className="input__control"
                        type="text"
                        placeholder={"поиск"}
                        onChange={(e) =>
                          setSearch(e.target.value.toLowerCase())
                        }
                      />
                    </label>
                  </div>
                )}
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {filterable.map((item, i) => (
                      <ChecboxItem
                        {...item}
                        checked={
                          formData?.includes?.(item.value)
                        }
                        onChange={changeHandler}
                        key={`item-${item.id}-${i}`}
                        id={i}
                        identity={identity}
                      />
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectFilter;
