import compact from 'lodash/compact'
export const converter = (state) => {
    const { services,
        theme,
        blocks,
        advantages,
        agent,
        isFetching,
        sets,
        status,
        agency,
        socials,
        promo,
        customJS,
        subDomain,
        reviews,
        yandexMetric,
        socialTypes,
        cities,
        defaultCity,
        defaultRealtyType,
        leadMagnet = {}
     } = state
    return {
        get socials() {
            return compact(socials.map(soc => {
                if (soc.active && !!soc.link) {
                    return {
                        "id": "",
                        "type": soc.id,
                        "link": soc.link
                    }
                }
            }))
        },
        get sets() {
            return compact(sets.map(set => {
                if (set.isActive) {
                    return set.id
                }
            }))
        },
        get advantages() {
            return compact(advantages.map(adv => {

                return {
                    "id": adv.id,
                    "name":adv.title, 
                    "description": adv.text,
                    "active": adv.isActive ? 1: 0
                }

            }))
        },
        get services() {
            return compact(services.map(sevice => {
                return {
                    "id": sevice.id,
                    "name": sevice.title,
                    "description": sevice.text,
                    "icon": sevice.icon,
                    "active": sevice.isActive ? 1: 0,
                    ...(sevice.hasOwnProperty("serviceType")? {serviceType: sevice.serviceType}: [])
                }

            }))
        },
        get blocks() {
            return compact(blocks.map(block => {
                if (block.active && block.component != "promo") {
                    return block.id
                }
                if (block.active && block.component === "promo" && socials.some(soc=>soc.active)) {
                    return block.id
                }
            }))
        },
        
        get promoSocials() {
            return compact(socials.map(soc => {
                if (soc.activeInPromo) {
                    return {
                        "id": "",
                        "type": soc.id,
                        "link": soc.link
                    }
                }
            }))
        },
        get defaultCity() {
            return cities.find(city=>city.code === defaultCity)
        },
        get defaultRealtyType() {
            return defaultRealtyType
        },
        
        get leadMagnet() {
            const tmp = {...leadMagnet}
            delete tmp.file
            return tmp
        },
    }

}

export const convertPhoto = photo => new Promise((resolve, reject) => {
    if (typeof photo === "string") {
        resolve(photo);
    }
    if (!photo) {
        resolve("");
    }
    const reader = new FileReader();
    reader.readAsDataURL(photo);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const createPreview = async (data) => {

    const tmp = { ...data }
    tmp.agent.photo = tmp.agent.photo?.src ? { ...tmp.agent.photo, src: tmp.agent.photo?.src } : await convertPhoto({ ...tmp.agent.photo }.blob)
    tmp.agency.logo = tmp.agency.logo?.src ? { ...tmp.agency.logo, src: tmp.agency.logo?.src } : await convertPhoto({ ...tmp.agency.logo }.blob)
    tmp.blocks = compact(tmp.blocks.map(block => {
        if (block.component === "promo" && tmp.socials.some(soc => soc.active)) {
            return block
        }
        return block
    })
    )
    localStorage.setItem("previewData", JSON.stringify(tmp))
    window.open(window.location.origin + "/constructor?preview", "_blank")
}