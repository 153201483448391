import { defaultFullFilterSuburbans } from '../utils/defaultFilters';
import { handleFavorite } from './handlers';
const initState = {
  filterData: {},
  filter: { ...defaultFullFilterSuburbans },
  items: [{ isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }, { isFake: true }],
  countVillages: 0,
  countVillagesToShow: 0,
  topVillages: [],
  agent: null,
};

const suburbans = (state = initState, action) => {
  const {
    type,
    filter
  } = action;
  const cleanAction = { ...action }
  delete cleanAction.type
  switch (type) {
    case 'GET_SUBURBANS_SUCCESS':
      return {
        ...state,
        ...action
      }
    case 'SUBURBANS_SET_FILTER':
      let tmpFilter = {
        filter: { ...state.filter.filter, ...filter.filter },
        exclude: { ...state.filter.exclude, ...filter.exclude },
      };
      return { ...state, filter: tmpFilter };
    case 'SUBURBANS_CLEAR_FILTER':
      return { ...state, filter: { ...defaultFullFilterSuburbans } };
    case 'SUBURBANS_CLEAR_FILTER_ONLY':
      const tmpFilterOnly = {
        filter: { ...state.filter.filter, ...defaultFullFilterSuburbans.filter },
        exclude: { ...state.filter.exclude },
      };
      return { ...state, filter: tmpFilterOnly };
    case 'SUBURBANS_CLEAR_EXCLUDE_ONLY':
      const tmpExcludeOnly = {
        filter: { ...state.filter.filter },
        exclude: { ...state.filter.exclude, ...defaultFullFilterSuburbans.exclude },
      };
      return { ...state, filter: tmpExcludeOnly };
    case 'GET_COUNT_VILLAGES':
      return { ...state, countVillagesToShow: action.countVillages };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'suburbans') {
        return { ...state, items: handleFavorite(state.items, action) }
      }
      return { ...state }

    case 'GET_SUBURBANS_FILTER_SUCCESS':
      return { ...state, filterData: action.data.filter };  
    default:
      return state;
  }
};

export default suburbans;
