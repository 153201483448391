import React from "react";

const BgRight1 = (props) => {
  return (
    <div className="background__right">
      <svg viewBox="0 0 729 1536" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="background__fill-black" d="M254.306 107.648L254.306 695.012L901.305 1535.51L901.306 948.152L254.306 107.648Z"/>
        <path className="background__fill-main" d="M254.209 696.027L254.209 104.42L729.305 -512.767L729.306 78.8388L254.209 696.027Z" />
        <path d="M535.921 617.842C538.035 617.842 539.75 616.123 539.75 614.002C539.75 611.881 538.035 610.162 535.921 610.162C533.806 610.162 532.092 611.881 532.092 614.002C532.092 616.123 533.806 617.842 535.921 617.842Z" fill="white"/>
        <path className="background__fill-main" d="M3.9207 141.842C6.03548 141.842 7.74985 140.123 7.74985 138.002C7.74985 135.881 6.03548 134.162 3.9207 134.162C1.80593 134.162 0.0915527 135.881 0.0915527 138.002C0.0915527 140.123 1.80593 141.842 3.9207 141.842Z" />
        <path className="background__fill-main" d="M65.4764 502.677C67.5911 502.677 69.3055 500.957 69.3055 498.837C69.3055 496.716 67.5911 494.997 65.4764 494.997C63.3616 494.997 61.6472 496.716 61.6472 498.837C61.6472 500.957 63.3616 502.677 65.4764 502.677Z" />
      </svg>
    </div>
  )
};

export default BgRight1;
