import React, { useState } from 'react';

import SubmitButton from '../SubmitButton';
import { media, formatDate } from '../../utils';

const RemoveEventForm = (props) => {
  const { date, time, name, handleSubmit } = props;
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    handleSubmit(comment).then(() => {
      setLoading(false);
    }, (error) => {
      setLoading(false);
    });
  };

  return (
    <>
      {!media('isMobile') && <h2 className="modal__title">Отмена бронирования</h2>}
      <div className="modal-form">
        <div className="modal-form__wrapper">
          <div className="modal-form__row">
            <div className="modal-form__gist">
              <div className="modal-form__row">
                <div className="lk-events__eventCancel-label">
                  Переговорная:
                </div>
                <div className="lk-events__eventCancel-info">
                  <div className="lk-events__eventCancel-name">{name}</div>
                  <div className="lk-events__date">
                    <span>
                      {formatDate(date, {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      })}
                    </span>
                    <div
                      className="lk-events__time"
                      dangerouslySetInnerHTML={{ __html: time }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="lk-events__eventCancel-reason">
                <label className="textarea textarea_width_available textarea_type_form">
                  <span className="textarea__label">Причина отмены</span>
                  <textarea
                    className="textarea__control"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            onClick={onSubmit}
            isLoading={loading}
          >
            <span>Отменить</span>
          </SubmitButton>
        </div>
      </div>
    </>
  );
};

export default RemoveEventForm;
