import request from "../api";
import { actionFactory } from './actionUtils';

const component = "panpartner:files.ajax"

const requestFiles = actionFactory("FILES_REQUEST");

const successFiles = actionFactory("FILES_SUCCESS");

export const changeFolder = folder => ({
  type: "FILES_CHANGE_FOLDER",
  folder,
});

export const clearFiles = actionFactory("FILES_RESET");

const failureFiles = actionFactory("FILES_FAILURE");

export const setFilterFiles = filter => ({
  type: "FILES_SET_FILTER",
  filter,
});

export const clearFilterFiles = actionFactory("FILES_CLEAR_FILTER");

export const fetchFiles = sectionId => dispatch => {
  dispatch(requestFiles());
  return request(component, "getFiles", { city: "spb", sectionId })
    .then(
      data => {
        dispatch(successFiles(data));
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureFiles());
        return Promise.reject(error);
      }
    );
}

export const searchFiles = search => request(component, "searchFiles", { city: "spb", search })