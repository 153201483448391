import React from "react";
import { ReactComponent as UnsubImg2 } from "../../assets/images/unsub/unsub-2.svg";
import './index.scss';

const UnsubMini = () => {

  return (

    <section className="section section_view_white">
      <div className="wrapper">
        <div className="not-found-wrapper unsub">
          <div className="not-found-content">
            <UnsubImg2 />
            <h2 className="h2 unsub__title">Вы успешно отписаны</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnsubMini;
