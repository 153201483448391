
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/lib/integration/react";
import httpService from "./api/interceptors";
import 'react-toastify/dist/ReactToastify.min.css';
import "nouislider/distribute/nouislider.min.css"
import "simplebar/dist/simplebar.css";
import './index.scss';
import RootContainer from "./containers/RootContainer";
import FatalError from "./components/ErrorBoundry/FatalError";
import configureStore, { history } from "./store/configureStore";
import { YMInitializer } from "react-yandex-metrika";
import 'url-search-params-polyfill';
import 'core-js/es/';
import 'raf/polyfill';
import CookiesUp from "./components/Cookies";
require('intersection-observer');
if (process.env.NODE_ENV !== 'production') {
  const consoleWarn = console.warn;
  const consoleError = console.error;

  console.warn = function() {};
  console.error = function() {};
}
export const { store, persistor } = configureStore();
if(!NodeList.prototype?.forEach){
  NodeList.prototype.forEach = Array.prototype.forEach
}
httpService.setupInterceptors(store, history);
const whiteList = ["panpartner.ru", "dev2.panpartner.ru", "artur.panpartner.ru", "aibeck.panpartner.ru", "panpartner.ru"];
const host = window.location.host;
const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <FatalError>
            <CookiesUp/>
            <Component />
            {whiteList.includes(host) && (
              <YMInitializer
                accounts={[97626334]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: false,
                }}
              />
            )}
          </FatalError>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("realtyWrapper")
  );
};

render(RootContainer);

if (module.hot) {
  module.hot.accept("./containers/RootContainer", () => {
    render(RootContainer);
  });
}
