import formatter from 'format-number';
import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { clearApartment, fetchApartment } from '../../actions/apartment';
import { fetchClients } from '../../actions/clients';
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from '../../actions/favorites';
import useMedia from '../../hooks/useMedia';
import { getPricePerMeter } from '../../utils';
import AddPresentation from '../AddPresentation';
import PopoverBlock from '../Cards/PopoverBlock';
import Chart from '../Chart';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Wrap from '../core/ModalWrap';
import FixApartment from '../forms/FixApartment';
import ReserveApartment from '../forms/ReserveApartment';
import ViewApartment from '../forms/ViewApartment';
import Manager from '../Manager';
import Preloader from '../Preloader';
import { successToast } from '../toasts';
import Banks from './Banks';
import MortgageBlock from './MortgageBlock';
import PhotosBlock from './PhotosBlock';
import PresentationBlock from './PresentationBlock';

const BlockLink = (props) => {
  const { id, city, name } = props;
  const url = `/realty/${city}/complex/${id}`;

  return (
    <div className="flat__complex">
      <div className="flat__complex-title">
        <h4 className="h4" dangerouslySetInnerHTML={{ __html: name }}></h4>
      </div>
      <Link to={`${url}${window.location.search}`} className={`link flat__complex-link`}>
        Страница ЖК
      </Link>
    </div>
  );
};

const priceFormat = (price) => {
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

const Apartment = (props) => {
  const {
    fetch,
    reset,
    isFetching,
    data,
    manager,
    banks,
    error,
    addToFavorites,
    removeFromFavorites,
    addClientToFavorite,
    removeClientFromFavorite,
    clients,
    fetchClients,
    clientMode
  } = props;

  const { id, city } = useParams();
  const isMobile = useMedia("isMobile")
  const [isPhotoOpened, setisPhotoOpened] = useState(false);
  useEffect(() => {
    if(!data) return
    let el = document.createElement('html');
    el.innerHTML = data ? data.block.name : ''; // с бэка приходит хтмл поэтому так)
    document.title = `${data.roomType}, ${data.squareTotal} м², № ${data.number} в ${el.innerText}`;
  }, [data]);

  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFromFavorites(city, id, 'apartment', 'apartment')
      : addToFavorites(city, id, 'apartment', 'apartment');
  };

  const submitReserve = () => {
    closeReserveForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const submitFix = () => {
    closeFixForm()
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const submitView = () => {
    closeViewForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const cityFromUrl = window.location.href.includes("msk") ? "msk" : "spb"

  const [openReserveForm, closeReserveForm] = useModal(() => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Забронировать квартиру" closeClick={closeReserveForm}>{children}</MobileModal>
        : <Modal close={closeReserveForm} classes="modal_task">{children}</Modal>
      } >
        <ReserveApartment id={id} handleSend={() => submitReserve()} city={cityFromUrl} />
      </Wrap >
    ));

  const [openFixForm, closeFixForm] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Фиксация клиента" closeClick={closeFixForm}>{children}</MobileModal>
        : <Modal close={closeFixForm} classes="modal_task">{children}</Modal>
      } >
        <FixApartment id={id} handleSend={() => submitFix()} builderId={data?.builder?.id} />
      </Wrap >
    ));

  const [openViewForm, closeViewForm] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeViewForm} title="Запись на просмотр">{children}</MobileModal>
        : <Modal close={closeViewForm} classes="modal_task">{children}</Modal>
      } >
        <ViewApartment id={id} handleSend={() => submitView()} city={city} />
      </Wrap >
    ));

  useEffect(() => {
    fetch(id, city);
    fetchClients();
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Sticky button

  const ref = useRef(null);

  const handleScroll = () => {

    if (!ref.current) return;

    const elScrollHeight = ref.current.offsetTop - window.innerHeight + 110

    if (window.scrollY > elScrollHeight) {
      ref.current.classList.add('flat__info-btns-sticky');
    } else if (window.scrollY < elScrollHeight) {
      ref.current.classList.remove('flat__info-btns-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  if (error) return 'Error';
  return (
    <>
      {data && (
        <section className="section section_view_white flat flat_margin_0">
          <div className="wrapper">
            <div className="flat__header">
              <div className="flat__grid">
                <div className="flat__col flat__col-title">
                  <div className="flat__title">
                    <h1 className="h1">{data.roomType}</h1>
                  </div>
                  {data && <BlockLink {...data.block} />}

                  {
                    isMobile &&
                    <div className="flat__info-item for-mobile">
                      <div
                        className={`label label-item label_blue label_id`}
                      >
                        ID: {data.id}
                      </div>
                      <div
                        className={`label-item label_${data?.status?.color
                          }`}
                      >
                        {data?.status?.name}
                      </div>
                    </div>
                  }
                </div>
                <div className="flat__col flat__col-download">
                  <PresentationBlock
                    objectXmlId={data.xmlId}
                    objectType="apartments"
                    city={city}
                  />
                  {!isMobile &&
                    <AddPresentation
                      id={data.xmlId}
                      city={city}
                      presentation={data.presentation}
                      type="apartments"
                    />
                  }
                </div>
              </div>

              {isMobile &&
                <div className="flat__mobile-presentation">
                  <AddPresentation
                    id={data.xmlId}
                    city={city}
                    presentation={data.presentation}
                    type="apartments"
                  />
                </div>
              }
            </div>
            <div className="flat__content">
              <div className="flat__grid flat__sticky-container" >
                <div className="flat__col flat__col-images flat__col-slider"  style={isPhotoOpened? {position: "static"}: null}>
                  <div className="special__detail-slider">
                    {
                      <PhotosBlock 
                      {...data}
                      onPhotoOpen={()=>setisPhotoOpened(true)}
                      onPhotoClosed={()=>setisPhotoOpened(false)}
                      />
                    }
                  </div>

                  {manager && (
                    <div className="flat__manager sm-hide">
                      <Manager data={manager} key="desktopManager" objectId={id} />
                    </div>
                  )}
                </div>
                <div className="flat__col flat__col-info">
                  <div className="flat__price">

                  {!isMobile &&
                    <div className="flat__price-col flat__price-item flat__price-item_flex flat__price-item_padding_top">
                      <div className="flat__info-item">
                        <div
                          className={`label label-item label_blue label_id`}
                        >
                          ID: {data.id}
                        </div>
                      </div>
                      <div className="flat__info-item">
                        <div
                          className={`label-item label_${data?.status?.color}`}
                        >
                          {data?.status?.name}
                        </div>
                      </div>
                    </div>
                  }
                  </div>

                  <div className="flat__price">
                    <div className="flat__price-col flat__price-item">
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(data.costWithDiscount)}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена, 100% оплата
                        </div>
                      </div>
                    </div>
                    <div className="flat__price-col flat__price-item">
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(getPricePerMeter(data.costWithDiscount, data.squareTotal))}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена за кв. метр
                        </div>
                      </div>
                    </div>
                    <div className="flat__price-col flat__price-actions">
                      <div className="flat__price-action">
                        <div
                          className={`add-fav-btn  ${data.favorite.inFavorite ? 'is-fav' : ''
                            }`}
                          id="addFavDiv"
                          onClick={(e) => {
                            if (e.target.id === 'addFavDiv') {
                              handleFavorites(data.favorite.inFavorite);
                            }
                          }}
                        >
                          <button
                            className="button button_type_settings"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFavorites(data.favorite.inFavorite);
                            }}
                          >
                            <svg className="icon icon_settings">
                              <use xlinkHref="#fav"></use>
                            </svg>
                          </button>
                          {!isMobile && (data.favorite.inFavorite
                            ? 'В избранном'
                            : 'В избранное')}
                          <PopoverBlock
                            type="apartment"
                            id={data.id}
                            favorite={data.favorite}
                            city={'spb'}
                            allClients={clients}
                            addClientToFavorite={addClientToFavorite}
                            removeClientFromFavorite={removeClientFromFavorite}
                            fetchClients={fetchClients}
                            whatPage={'apartment'}
                            showOnLeft={true}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                      {false && (
                        <div className="flat__price-action">
                          <div
                            className="add-compare-btn js-compares-add"
                            data-compare-id="7005"
                          >
                            <button className="button button_type_settings">
                              <svg className="icon icon_settings">
                                <use xlinkHref="#icon-compare"></use>
                              </svg>
                            </button>
                            К сравнению
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flat__period-grid">
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.ending}
                        </div>
                        <div className="flat__info-item_desc">Срок сдачи</div>
                      </div>
                    </div>
                    {/* {data.issuingKeys && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.issuingKeys}
                          </div>
                          <div className="flat__info-item_desc">
                            Срок выдачи ключей
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {data.status?.id != "1001" && <div className="flat__info-btns">
                    <div ref={ref}>
                      <div
                        className="js-modal-btn"
                        data-flat-id="flat-1"
                        data-id="modal-reservation"
                      >
                        <Link
                          className="button button_view_default"
                          to={`/flat-booking/apartment/${id}/${city}`}
                        >
                          Забронировать
                        </Link>
                      </div>
                    </div>
                    {data.isSignUpToView && (
                      <div className={`flat__white-btn ${!data.isCustomerFixation && `flat__white-btn-is-one`}`} data-flat-id="flat-1" data-id="modal-viewing">
                        <button
                          className="button button_view_outline-with-icon"
                          onClick={openViewForm}
                        >
                          <svg className="icon icon_marker">
                            <use xlinkHref="#icon_watch"></use>
                          </svg>
                          <span>На просмотр</span>
                        </button>
                      </div>
                    )}
                    {data.isCustomerFixation && (
                      <div className="flat__white-btn" data-flat-id="flat-1" data-id="modal-fixing">
                        <button
                          className="button button_view_outline-with-icon"
                          onClick={() => window.open(`/client-fixation?city=${city}&builderId=${data?.builder?.id}&blockId=${data.block?.id}`)}
                        >
                          <svg className="icon icon_fix">
                            <use xlinkHref="#icon_fix"></use>
                          </svg>
                          <span>Фиксация</span>
                        </button>
                      </div>
                    )}
                  </div>}
                  <div className="flat__info-grid">
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {priceFormat(data.baseCost)}
                        </div>
                        <div className="flat__info-item_desc">Базовая цена</div>
                      </div>
                    </div>
                    {data.flatType &&(
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.flatType}
                          </div>
                          <div className="flat__info-item_desc">
                            Тип
                          </div>
                        </div>
                      </div>
                    )}
                    {data.squareTotal && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareTotal} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Общая площадь
                          </div>
                        </div>
                      </div>
                    )}
                    {
                      // <div className="flat__info-col">
                      //   <div className="flat__info-item">
                      //     <div className="flat__info-item_value">{data.id}</div>
                      //     <div className="flat__info-item_desc">ID</div>
                      //   </div>
                      // </div>
                    }
                    {data?.squareLiving > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareLiving} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Жилая площадь
                          </div>
                        </div>
                      </div>
                    )}
                    {data.number && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.number}
                          </div>
                          <div className="flat__info-item_desc">
                            Номер квартиры
                          </div>
                        </div>
                      </div>
                    )}
                    {data.numberPib && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.numberPib}
                          </div>
                          <div className="flat__info-item_desc">
                            Номер квартиры ПИБ
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.floor}
                        </div>
                        <div className="flat__info-item_desc">Этаж</div>
                      </div>
                    </div>
                    {data?.squareKitchen > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareKitchen} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Площадь кухни
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.cellHeight > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.cellHeight} м
                          </div>
                          <div className="flat__info-item_desc">
                            Высота потолков
                          </div>
                        </div>
                      </div>
                    )}
                    {data.line && data.line > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.line}
                          </div>
                          <div className="flat__info-item_desc">Очередь</div>
                        </div>
                      </div>
                    )}
                    {data.corpus && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.corpus}
                          </div>
                          <div className="flat__info-item_desc">
                            Корпус / Участок / Дом
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.bathroom}
                        </div>
                        <div className="flat__info-item_desc">Санузел</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.section}
                        </div>
                        <div className="flat__info-item_desc">Секция</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.decoration}
                        </div>
                        <div className="flat__info-item_desc">Отделка</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.contract}
                        </div>
                        <div className="flat__info-item_desc">
                          Форма договора
                        </div>
                      </div>
                    </div>
                    {data.isAction && !clientMode && <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          Скидка от застройщика
                        </div>
                        <div className="flat__info-item_desc">
                          Акция
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            {manager && (
              <div className="flat__manager sm-show">
                <Manager data={manager} key="mobileManager" />
              </div>
            )}
          </div>
        </section>
      )}
      {data && <Chart info={data} />}
      {data && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="apartment"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
        />
      )}
      {banks && banks.length > 0 && <Banks items={banks} data={data} />}
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, city) => dispatch(fetchApartment(id, city)),
  reset: () => dispatch(clearApartment()),
  addToFavorites: (city, id, type) => dispatch(addToFavorites(city, id, type)),
  removeFromFavorites: (city, id, type) =>
    dispatch(removeFromFavorites(city, id, type)),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        whatPage,
        type,
      ),
    ),
  fetchClients: () => dispatch(fetchClients()),
});

const mapStateToProps = (state) => ({
  data: state.apartment.data,
  isFetching: state.apartment.isFetching,
  error: state.apartment.error,
  manager: state.apartment.manager,
  banks: state.apartment.banks,
  clients: state.clients.data,
  clientMode: state.general.clientMode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
