import React from 'react';
import { getUrl } from '../../../api';

const SingleImage = ({ src, title, dataId = "none", className="" }) => {
  if (!src) return null
  
  return <div className={`pdf-block ${className}`}>
    <div className="presentation__box" data-id={dataId}>
      {title && <div className="presentation__title">{title}</div>}
      <img className="presentation__img" src={getUrl(src)} alt="" style={{ maxHeight: 415 }} />
    </div>
  </div>
}

export default SingleImage