import {Link, useParams} from "react-router-dom";
import Checkbox from "../../components/core/Checkbox";
import {
  SfFilterCloseIcon,
  SfFilterCopyIcon,
  SfFilterDeleteIcon,
  SfFilterEditIcon, SfFilterMenuIcon,
  SmallRightArrow
} from "../../components/Public/markup/icons";
import { formatDate, renderDate } from "../../utils";
import React, {useRef, useState} from 'react';
import {useModal} from "react-modal-hook";
import Modal from "../../components/core/Modal";
import Button from "../../components/core/Button";
import { useLocation } from "react-router-dom";
import request, {getUrl} from "../../api";
import useClickPast from "../../hooks/useClickPast";
import {toast} from "react-toastify";
import {successToast} from "../../components/toasts";
import {useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";



const hexToRgb = hex =>
  hex?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
    , (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

const dealsColumns = [
  {
    title: "Клиент",
    dataIndex: "client",
    render: client => typeof client != "object" && <p className="h6">{client}</p>,
  },
  {
    title: "Статус",
    dataIndex: "stage",
    render: (stage, { stageColor }) => (
      <span className="crm-table__status" style={{ color: stageColor, backgroundColor: `rgba(${hexToRgb(stageColor) + ',0.2'})` }}>
        {stage}
      </span>
    )
  },
  {
    title: "Дата заявки",
    dataIndex: "dateCreate",
    render: renderDate,
  },
  {
    title: "Сотрудник",
    dataIndex: "agent",
    permission: "not-agent",
  },
  {
    title: "Объект",
    dataIndex: "object",
    render: object => (
      <div className="crm-table__deals-object-cell">
        <span className="crm-table__deals-object-text">{object}</span>
        <span><SmallRightArrow /></span>
      </div>
    ),
  },
];
const fixationsColumns = [
  {
    title: "Клиент",
    dataIndex: "client",
    render: (client, { endFixationPeriod, endAllFixations }) => <>
      {typeof client != "object" && <p className={`h6 ${endAllFixations ? 'end-all-fixations' : '' }`}>{client}</p>}
      {endFixationPeriod && <p className="crm-table__end-term">{endFixationPeriod}</p>}
    </>
  },
  {
    title: "Дата заявки",
    dataIndex: "lastDate",
    // render: renderDate,
    render: (lastDate, {endAllFixations}) => <>
      <span className={`${endAllFixations ? 'end-all-fixations' : '' }`}>
        {renderDate(lastDate)}
      </span>
    </>
  },
  {
    title: "Сотрудник",
    dataIndex: "contact",
    permission: "not-agent",
    // render: contact => !contact ? "" :
    //   <div className="crm-table__employer">
    //     <span}>{contact.lastName + ' '} {contact.name[0] + '.'}</span>
    //     <span><SmallRightArrow /></span>
    //   </div>,
    render: (contact, { endAllFixations }) => {
      if (!contact) {
        return "";
      } else {
        return <div className="crm-table__employer">
          <span className={`${endAllFixations ? 'end-all-fixations' : '' }`}>{contact.lastName + ' '} {contact.name[0] + '.'}</span>
          <span><SmallRightArrow /></span>
        </div>;
      }
    }
  },
];


const fixationColumns = [
  {
    title: "Застройщик / ЖК",
    dataIndex: "builder",
    render: (builder, { blockName }) => (
      <p className="h6">
        {builder && builder !== false ? builder.title : blockName}
      </p>
    )
  },
  {
    title: "Статус",
    dataIndex: "stage",
    render: (stage, { stageColor }) => (
      <span className="crm-table__status" style={{ color: stageColor, backgroundColor: `rgba(${hexToRgb(stageColor) + ',0.2'})` }}>
        {stage}
      </span>
    )
  },
  {
    title: "Фиксация",
    dataIndex: "fixationDate",
    render: (fixationDate, { endFixationPeriod }) => <>
      {fixationDate}
      {endFixationPeriod && <p className="crm-table__end-term">{endFixationPeriod}</p>}
    </>
  },
  {
    title: "Сотрудник",
    permission: "not-agent",
    dataIndex: "manager",
  },
  {
    title: "Комментарий",
    dataIndex: "comment",
    render: comment => (
      <div className="crm-table__employer">
        <span className="crm-table__comment" dangerouslySetInnerHTML={{ __html: comment }} />
        <span><SmallRightArrow /></span>
      </div>
    )
  },
];

const viewingColumns = [
  {
    title: "Жилой комплекс",
    dataIndex: "blockName",
    render: blockName => <p className="h6">
      {blockName}
    </p>
  },
  {
    title: "Статус",
    dataIndex: "stage",
    render: (stage, { stageColor }) => (
      <span className="crm-table__status" style={{ color: stageColor, backgroundColor: `rgba(${hexToRgb(stageColor) + ',0.2'})` }}>
        {stage}
      </span>
    )
  },
  {
    title: "Дата посещения",
    dataIndex: "dateViewing",
    render: (dateViewing, { endFixationPeriod }) => <>
      {dateViewing}
      {/*{endFixationPeriod && <p className="crm-table__end-term">{endFixationPeriod}</p>}*/}
    </>
  },
  {
    title: "Сотрудник",
    permission: "not-agent",
    dataIndex: "manager",
  },
  {
    title: "Комментарий",
    dataIndex: "commentViewing",
    render: commentViewing => (
      <div className="crm-table__employer">
        <span className="crm-table__comment" dangerouslySetInnerHTML={{ __html: commentViewing }} />
        <span><SmallRightArrow /></span>
      </div>
    )
  },
];

const viewingsColumns = [
  {
    title: "Клиент",
    dataIndex: "client",
    render: (client, { endFixationPeriod }) => <>
      {typeof client != "object" && <p className="h6">{client}</p>}
      {endFixationPeriod && <p className="crm-table__end-term">{endFixationPeriod}</p>}
    </>
  },
  {
    title: "Дата заявки",
    dataIndex: "lastDate",
    render: renderDate,
  },
  {
    title: "Сотрудник",
    dataIndex: "contact",
    permission: "not-agent",
    render: contact => !contact ? "" :
      <div className="crm-table__employer">
        <span>{contact.lastName + ' '} {contact.name[0] + '.'}</span>
        <span><SmallRightArrow /></span>
      </div>
  },
];

const getSFColumns = (onSelectItem, selectedItems, user, onChangeActive) => [
  // {
  //   title: () => (
  //     <Checkbox
  //       id={"all"}
  //       view="invert"
  //       onChange={(event) => onSelectItem(event.target.checked, "all")}
  //     />
  //   ),
  //   dataIndex: "id",
  //   key: "id",
  //   width: 50,
  //   render: (id, record) => (
  //     record.agent?.id == user.id ? <Checkbox
  //       id={id}
  //       view="invert"
  //       checked={!!selectedItems.find((item) => item.id == id)}
  //       onChange={(event) => onSelectItem(event.target.checked, id)}
  //     /> : ""
  //   ),
  // },
  {
    title: "Активность",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive, record) => {
      return (
        <label className="switch">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              defaultChecked={isActive}
              onChange={() => onChangeActive(record)}
            />
            <div className="switch__input"></div>
            <div className="switch__marker"></div>
          </div>
        </label>
      );
    },
  },
  {
    title: "Клиент",
    dataIndex: "client",
    key: "client",
    sorter: (a, b) => {
      if (!a.client?.name) return 0;
      if (!b.client?.name) return 1;

      const nameA = `${a.client?.name} ${a.client?.lastName}`;
      const nameB = `${b.client?.name} ${b.client?.lastName}`;

      return nameA > nameB ? 1 : -1;
    },
    render: (client, record) => {
      if (!client) return null;
      return (
        <Link
          className="clients__table-link clients__table-client"
          to={`/personal/clients/${client?.id}`}
        >
          <span className="nowrap">{`${client?.name} ${client?.lastName !== null ? client?.lastName : ``}`}</span>
          <svg className="icon icon_external-link">
            <use xlinkHref="#external_link"></use>
          </svg>
        </Link>
      );
    },
  },
  {
    title: "Агент",
    dataIndex: "agent",
    key: "agent",
    render: (agent, record) => {
      return (
        <span className="nowrap">{`${agent?.name} ${agent?.lastName}`}</span>
      );
    },
  },
  {
    title: "Дата создания",
    dataIndex: "date",
    key: "date",
    sorter: (a, b) =>
      new Date(a.dateCreate) > new Date(b.dateCreate) ? 1 : -1,
    render: (text, record) => {
      return (
        <span className="nowrap">{formatDate(record.dateCreate, { day: "numeric", month: "numeric", year: "numeric" })}</span>
      );
    },
  },
  {
    title: "Посл. просмотр",
    dataIndex: "lastView",
    key: "lastView",
    sorter: (a, b) => {
      if (isNaN(new Date(a.lastView).getTime())) return -1;
      if (isNaN(new Date(b.lastView).getTime())) return 1;

      return new Date(a.lastView) > new Date(b.lastView) ? 1 : -1;
    },
    render: (text, record) => {
      let dateValue = record.lastView;
      if (isNaN(new Date(dateValue).getTime())) return "—";

      return <span className="nowrap">{formatDate(dateValue, { day: "numeric", month: "numeric", year: "numeric" })}</span>;
    },
  },
  {
    title: "",
    dataIndex: "views",
    key: "views",
    width: 50,
    render: (text, record) => (
      <div className="label_with-icon_prefix">
        <svg
          className="label__icon icon icon_watch"
          style={{ width: "18px", marginRight: "10px" }}
        >
          <use xlinkHref="#icon_watch"></use>
        </svg>
        {record.countView}
      </div>
    ),
  },
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
    sorter: (a, b) => (new Date(a.lastView) > new Date(b.lastView) ? 1 : -1),
    render: (text, record) => {
      let dateValue = record.lastView;
      if (isNaN(new Date(dateValue).getTime())) dateValue = record.dateCreate;
      return (
        <div className="filter-row">
          {record.filter &&
            record.filterView &&
            record.filterView.length > 0 && (
              <div className="filter-row__block">
                <div className="filter-row__block-name">Параметры</div>
                {record.filterView.map((item, i) => (
                  <div className="filter-row__item" key={i}>
                    <div className="filter-row__type">{item.name}:</div>
                    <div
                      className="filter-row__value"
                      dangerouslySetInnerHTML={{ __html: item.values }}
                    ></div>
                  </div>
                ))}
              </div>
            )}
          {record.exclude &&
            record.excludeView &&
            record.excludeView.length > 0 && (
              <div className="filter-row__block">
                <div className="filter-row__block-name">Исключения</div>
                {record.excludeView.map((item, i) => (
                  <div className="filter-row__item" key={i}>
                    <div className="filter-row__type">{item.name}:</div>
                    <div
                      className="filter-row__value"
                      dangerouslySetInnerHTML={{ __html: item.values }}
                    />
                  </div>
                ))}
              </div>
            )}
        </div>
      );
    },
  },
];


export const SfControls = ({xmlId, userId, type, url, city, links}) => {

  const [compilationId, setCompilationId] = useState(null)
  const location = useLocation()
  const currentUserId = useSelector(state => state.user.info.id)

  const successToast = (message, config = {}) => toast.success(({ closeToast }) =>
    <div className="Toastify__wrap">
        <span className="Toastify__icon-wrap">
            <svg className="icon icon_check_white">
                <use xlinkHref="#check_white" />
            </svg>
        </span>
      {message}
    </div>, config
  );




  const deleteCompilation = (id) => {
    request("panpartner:saved.filter.ajax", "delete", {
      id: id,
      city: 'spb'
    }).then(
      (success) => {
        hideModal()
        deleteCompilationRow(id)
        successToast(<div>Ваша подборка успешно удалена</div>)
      },
    )
  }

  const deleteCompilationRow = (id) => {
    const tableRows = document.getElementsByClassName('ant-table-row')
    for (let i = 0; i < tableRows.length; i++) {
      const row = tableRows[i];
      if (row.getAttribute("data-row-key") === id) {
        row.remove()
        break;
      }
    }
  }

  const openModalCallback = (e) => {
    const target = e.target;
    const parentTr = target.closest('tr');
    const dataValue = parentTr.getAttribute('data-row-key');
    if(String(currentUserId) !== userId) {return}
    setCompilationId(dataValue)
    openModal();
    setIsActive(false)
  };
  const [openModal, hideModal] = useModal(({}) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#FF2D23"/>
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round"/>
          <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23"/>
        </svg>
        Удалить подборки?
      </div>
      <div className="crm-table__sf-controlsmodaltext">
        После удаления вся информация будет утеряна
      </div>
      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={() => deleteCompilation(compilationId)}>Да</Button>
        <Button buttonType="line" onClick={hideModal}>Нет</Button>
      </div>
    </Modal>
  ),[compilationId]);

  const [isActive, setIsActive] = useState(false)
  const node = useClickPast(() => {setIsActive(false)});

  const textToCopy = getUrl(url);
  const handleCopy = () => {
    successToast(<div>Скопировано в буфер обмена</div>);
  };

  return (
  <>
    <span className="crm-table__sf-opencontrols" onClick={() => {setIsActive(!isActive)}}>
      <SfFilterMenuIcon/>
    </span>
    <ul className={`${isActive ? 'crm-table__sf-controls active' : 'crm-table__sf-controls'}`}  ref={node} onClick={() => {setIsActive(!isActive)}}>
      <li><Link style={{color: 'inherit', display: 'flex', width: '100%'}} to={'/crm' + links?.edit}><SfFilterEditIcon/>Редактировать</Link></li>
      <li>
          <CopyToClipboard text={links?.copy} onCopy={handleCopy}>
            <Button buttonType="clean"><SfFilterCopyIcon/>Скопировать ссылку</Button>
          </CopyToClipboard>
      </li>

      <li className={String(currentUserId) === userId ? "" : "disabled"} onClick={openModalCallback}><SfFilterDeleteIcon/>Удалить {String(currentUserId) !== userId && <div className="hint">Вы не можете удалять подборки своих сотрудников</div>}</li>
    </ul>
  </>
  )
}


export const changeActiveCallback = (city, id, active) => {
  request("panpartner:saved.filter.ajax", "changeActive", {
    id: id,
    city: city,
    active: active
  }).then(
    (success) => {
      successToast(<div>Фильтр успешно обновлен</div>)
    },
  )
}
const sfColumns =  [

  {
    title: "Активность",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive, record) => {
      return (
        <label className="switch" style={{display: 'inline-block'}}>
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              defaultChecked={isActive === 1 ? true : false}
              onChange={ e=> {
                changeActiveCallback(record.city, record.id, e.target.checked ? 'Y' : 'N')
              }}
            />
            <div className="switch__input"/>
            <div className="switch__marker"/>
          </div>
        </label>
      );
    },
  },
  {
    title: "Название подборки",
    dataIndex: "client",
    key: "client",
    render: (client, record) => {
      if (!client) return null;
      return (
        <div>
          <Link
            to={'/crm' + record.links?.edit}
            className="clients__table-link clients__table-client"
          >
            <span className="nowrap">{record?.name}</span>
          </Link>
          <div>
            {record.countView} {record.lastView && <> / <span style={{color: '#777A89'}}>{record.lastView}</span></>}
          </div>
        </div>
      );
    },
  },
  {
    title: "Сотрудник",
    dataIndex: "agent",
    key: "agent",
    render: (agent, record) => {
      return (
        <span className="nowrap">{`${agent?.name}`}</span>
      );
    },
    permission: "not-agent",
    filterBy: ["filter.agentId.length", v => v > 1]
  },
  {
    title: "Дата создания",
    dataIndex: "dateCreateFormated",
    key: "dateCreateFormated",
    render: (text, record) => {
      return (
        <span className="nowrap">{record.dateCreateFormated}</span>
      );
    },
  },
  {
    title: "",
    dataIndex: "lastView",
    key: "lastView",

    render: (text, record, isActive) => {
      return (
        <div className="crm-table__sf-action">
          <SfControls links={record.links} city={record.city} url={record.url} type={record.filterType} userId={record.client?.agentId} xmlId={record.xmlId}/>
        </div>
      )
    },
  },

];
export { dealsColumns, fixationsColumns, fixationColumns, viewingsColumns, viewingColumns, getSFColumns, sfColumns };
