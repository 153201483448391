import _ from 'lodash';
import React, { useState } from "react";
import { getCountChess } from '../../actions/chess';
import useMedia from '../../hooks/useMedia';
import { filterToParam } from '../Complex/Utils';
import RadioDropdown from '../core/RadioDropdown';
import EndingsFilter from '../FilterRealty/EndingsFilter';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';
import SubmitButton from '../SubmitButton';
var inDebounce;
const ChessFilter = (props) => {
  const { setFilter, filter, filterData, fetch, clearFilter, city, id, closeClick= ()=>{} } = props
  const isMobile = useMedia("isMobile")
  const [count, setCount] = useState("initial")

  const getCount = (params) => {
    getCountChess(params).then(resp => {
      setCount(resp.allCount)
    }
    )
  }
const clear = () => {
  const params = {
    "filter[blockId]": id,
    "filter[status]": [1, 3, 1001],
    city,
    isAssignment: true,
    isGetFilter: true,
  }
  clearFilter()
  getCount(params)
}

  const handleFilter = (data, identity) => {
    let tempValue = {
      [identity]: data ? data : filter[identity],
    }
    if (identity === "isOnlyHanded") {
      tempValue.endings = { min: "", max: "" };
    }

    let tmpFilter = { ...filter, ...tempValue }
    setFilter(tmpFilter);

    clearTimeout(inDebounce);
    let params = filterToParam(tmpFilter);
    inDebounce = setTimeout(() => {
      params.city = city
      params["filter[blockId]"] = id
      getCount(params);

    }, 300);
  };

  const handleToggle = (checked, status = "3") => {
    let tempFilter = [...filter.status];
    if (checked) {
      tempFilter.push(status);
    } else {
      tempFilter = _.without(tempFilter, status);
    }
    setFilter({ ...filter, status: tempFilter });
    let params = filterToParam({
      ...filter,
      status: tempFilter,
    });
    params["filter[blockId]"] = id
    params.city = city
    getCount(params);
  };
  const handleSubmit = () => {
    const tmpFilter = filterToParam(filter)
    tmpFilter["filter[blockId]"] = id
    tmpFilter.city = city
    fetch(tmpFilter);
    closeClick();
  }

  const filters = (
    <>
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'rooms')}
        isExcludable={false}
        isAutocomplete={false}
        items={filterData.rooms ? Object.values(filterData.rooms) : []}
        filterValues={filter.rooms}
        excludeValues={[]}
        data={{
          title: 'Ко-во комнат',
          autocompleteTitle: '',
          identity: 'rooms',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <EndingsFilter
        ident={"endings"}
        filter={filter.endings ? filter.endings : { min: '', max: '' }}
        items={filterData.endings ? filterData.endings.items : []}
        data={{
          title: 'Срок сдачи',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.endings
            ? filterData.endings.ranges
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'endings')}
        secondData={{
          title: 'Сданные дома',
          value: filter.isOnlyHanded.length
            ? filter.isOnlyHanded[0].id
            : 'N',
        }}
        secondChange={(a) =>
          handleFilter(a === 'Y' ? [{ id: a }] : [], 'isOnlyHanded')
        }
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <PriceFilter
        filter={filter.prices ? filter.prices : { min: '', max: '' }}
        exclude={{ min: '', max: '' }}
        items={filterData.prices ? filterData.prices.items : []}
        data={{
          title: 'Цена',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.prices ? filterData.prices.range : 0,
        }}
        onChange={(a) => handleFilter(a.filter, 'prices')}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'decoration')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.decoration
            ? Object.values(filterData.decoration)
            : []
        }
        filterValues={filter.decoration}
        excludeValues={[]}
        data={{
          title: 'Отделка',
          autocompleteTitle: '',
          identity: 'decoration',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <SquareFilter
        filter={filter.squareTotal}
        data={{
          title: 'S общая',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.squareTotal
            ? filterData.squareTotal.range
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'squareTotal')}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <FloorsFilter
        items={
          filterData.floors?.items ? filterData.floors.items : []
        }
        filter={filter.floors}
        data={{
          title: 'Этаж',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.floors
            ? filterData.floors.range
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'floors')}
        secondData={{
          title: filterData.isNoFirstFloor
            ? filterData.isNoFirstFloor[0]?.title
            : 'Не первый',
          value: filter.isNoFirstFloor?.length
            ? filter.isNoFirstFloor
            : 'N',
        }}
        secondChange={(a) => {
          let tmp = a === 'Y' ? 'Y' : '';
          handleFilter(tmp, 'isNoFirstFloor');
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <RadioDropdown

        onChange={(a, type) => handleFilter([{ id: a }], 'isApartments')}
        isAutocomplete={false}
        items={filterData.isApartments ? filterData.isApartments : []}
        value={filter.isApartments[0] ? filter.isApartments[0].id : {}}
        alternativeTitle={'Апартаменты'}
        data={{
          title: 'Апартаменты',
          identity: 'isApartments',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />


      <RadioDropdown

        onChange={(a, type) => handleFilter(a, 'assignments')}
        isAutocomplete={false}
        items={filterData.assignments ? filterData.assignments : []}
        value={filter.assignments[0] === "Y" || filter.assignments[0] === "N" ? filter.assignments[0] : filter?.assignments?.[0]?.id}
        alternativeTitle={'Переуступки'}
        data={{
          title: 'Переуступки',
          identity: 'assignments',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />


      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'payment')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.payment ? Object.values(filterData.payment) : []
        }
        filterValues={filter.payment}
        excludeValues={[]}
        data={{
          title: 'Способы оплаты',
          autocompleteTitle: '',
          identity: 'payment',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />

      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'banks')}
        isExcludable={false}
        isAutocomplete={false}
        items={filterData.banks ? Object.values(filterData.banks) : []}
        filterValues={filter.banks}
        excludeValues={[]}
        data={{
          title: 'Банки',
          autocompleteTitle: '',
          identity: 'banks',
        }}
        isMobile={isMobile}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
      />
    </>
  )

  return (
    <div className={`chess__filter ${isMobile && 'mobile-filters index-search'}`}>
      {isMobile ?
        <div class="mobile-filters__title is-fixed">
          <svg
            className="icon icon_arrow_up"
            onClick={closeClick}
          >
            <use xlinkHref="#arrow_up"></use>
          </svg>
          <h3>
            Фильтры шахматка
          </h3>
        </div> :
        <div className="chess__filter-title">Фильтр</div>
      }

      <div className={`chess__filter-wrapper ${isMobile && 'mobile-filters__content'}`}>

        <div className={`chess__filter-list ${isMobile && 'index-search__filter-top '}`}>

          {isMobile ?
            <div className="form__row">
              {filters}
            </div> :
            filters
          }

          <div className="index-search__show-booked">
            <label className="switch" htmlFor="filter-booked">
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="filter-booked"
                  checked={!filter.status.includes("3")}
                  onChange={(e) => handleToggle(!e.target.checked, "3")}
                />
                <div className="switch__input" htmlFor="filter-booked"></div>
                <div className="switch__marker"></div>
              </div>
              <span className="switch__label">Скрыть забронированные</span>
            </label>
          </div>

          <div className="index-search__show-booked">
            <label className="switch" htmlFor="filter-sold">
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="filter-sold"
                  checked={!filter.status.includes("1001")}
                  onChange={(e) => handleToggle(!e.target.checked, "1001")}
                />
                <div className="switch__input" htmlFor="filter-sold"></div>
                <div className="switch__marker"></div>
              </div>
              <span className="switch__label">Скрыть проданные</span>
            </label>
          </div>

          <div className={`chess__filter-submit ${isMobile && 'mobile-filters__show-all'}`}>
            <SubmitButton
              className="button button_view_default"
              onClick={handleSubmit}
              disabled={!count}
            >
              {count === "initial" ? "Показать все" : !count ? "Ничего не найдено" : `Показать ${count}`}

            </SubmitButton>
          </div>
          <div className="chess__filter-submit">
            <button
              className="button button_type_clear mini-filter__button"
              onClick={clear}
              style={{margin: 0}}
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
          </div>
        </div >
      </div>
    </div>
  );
};

export default ChessFilter;
