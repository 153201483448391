import React, {useEffect, useRef, useState} from "react";
import './index.scss'
import {
  AddToCompilationIcon,
  AptStatusIcon,
  HeartIcon,
  LockIcon,
  PdfIcon,
  PresentationIcon,
  TopArrowIcon
} from "../Public/markup/icons";
import Slider from "../Public/markup/detail/Slider";
import InfoCard from "../Public/markup/detail/InfoCard";
import Button from "../core/Button";
import ContactCard from "../core/ContactCard";
import NewAlert from "../core/NewAlert";
import stub from '../../assets/images/stub-contact.png'
import useApi from "../../hooks/useApi";
import Preloader from "../Preloader";
import { PhotoPopup } from "../Viewer/PhotoSlider";
import SubmitButton from "../SubmitButton";
import { useSelector } from "react-redux";
import AddPresenatation from "../AddPresentation";
import { getUrl } from "../../api";
import ResidenceHint from "../ResidenceHint";
import {successToast} from "../toasts";
import {objectsArrayToFormData, toFormData} from "../../utils";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import { useParams } from "react-router-dom";
import SuburbansItem from "../Suburbans/TopItem";
import TinySlider from "tiny-slider-react";
import {getURLparam} from "../../utils/location";

const AptModal = ({ selectedItem = {}, city, defaultTitle = "ПАН партнер", closeModal, columnsType }) => {
  const { id, objectType, xmlId } = selectedItem;
  const [showMapView, setShowMapView] = useState(false);
  const userInfo = useSelector(state => state.user?.info);
  const [callbackStatus, setCallbackStatus] = useState(false)
  const [filePath, setfilePath] = useState(false)
  const isCommercial = getURLparam("type") === "commercial";
  const {
    data: {
      status,
      link,
      title,
      objectInfo,
      priceInfo,
      managerInfo,
      photos,
      viewFromWindow,
      bookingLink,
      presentation,
      compilationObjectType,
      contacts
    },
    isLoading,
    toggleFavorite,
    inFavorite,
    isFavoriteLoading,
    send,
    isSending,
    fixationLink
  } = useApi({
    payload: ['panpartner:block.detail.ajax', `${columnsType === 'parkings' ? 'fastViewParking' : 'fastView'}`, { id, objectType, city, }],
    favoritesParams: {
      city,
      id,
      type: columnsType === 'parkings' ? 'parking' : objectType,
    },
    favoritePath: 'favorite',
  }, [id]);

  const infoHead = [priceInfo?.costWithDiscount, priceInfo?.meterPrice];
  const callback = () => send(["panpartner:forms.ajax", "callback", {
    urgently: true,
    managerId: managerInfo?.id,
    name: `${userInfo?.name} ${userInfo?.lastName}`,
    type: objectType,
    objectId: id,
    city
  }]).then(
    success => setCallbackStatus({ type: "success" }),
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )

  // так я решил смотреть параметры экрана и в зависимости от них показывать блоки
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!title) return
    window.history.pushState({}, title, `#&id=${id}&objectType=${objectType}`)
    window.addEventListener("hashchange", closeModal)
    return () => {
      window.history.pushState({}, defaultTitle, `#`)
      window.removeEventListener("hashchange", closeModal)
    }
  }, [title])

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: id, type: compilationObjectType, city }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })


  const node = useRef(null);


  const settings = {
    items: 1,
    nav: true,
    loop: true,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 5000,
      responsive: {
        1600: {
          items: 1,
          gutter: 0,
        },
        1280: {
          items: 1,
          gutter: 0,
        },
        768: {
          items: 1,
          gutter: 0,
        },
      },
  };







  if (isLoading) return <Preloader loading />;
  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }
  const renderPdf = ({ fetching, finish, resp, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={filePath ? () => window.open(filePath) : () => handleSend().then(resp => {
        window.open(getUrl(resp?.filePath))
        setfilePath(getUrl(resp?.filePath))
      })}
    >
      <PdfIcon />
      {windowSize.innerWidth > 1279 && 'Скачать PDF'}
    </SubmitButton>
  }
  return (
    <div className="apt-modal">
      <div className="apt-modal__header">
        <div className="apt-modal__title">
          <h3 className="h3">{title}</h3>
          <div className={`label-item apt-modal__status label_${status?.color}`}>
            <AptStatusIcon />
             {status.name}
          </div>
        </div>
        <div className="apt-modal__header-second">
          <div className="sf sf--apt-modal">
            <div className="sf__elems">
              <button
                type="button"
                className="sf__elem-btn button link"
                onClick={() => !isFavoriteLoading && toggleFavorite()}
                style={{width: 192}}
              >
                <HeartIcon isActive={inFavorite} />
                {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
              </button>

              <button
                type="button"
                className="sf__elem-btn button link"
                onClick={openCompilationModal}
              >
                <AddToCompilationIcon/>
                В подборку
              </button>

              <AddPresenatation
                type={objectType}
                id={xmlId}
                city={city}
                presentation={presentation}
                render={renderPresentations}
                withoutMortgage={0}
              />

              <AddPresenatation
                type={objectType}
                id={xmlId}
                city={city}
                method="getPdf"
                render={renderPdf}
              />
              {!!fixationLink && <button
                type="button"
                className="sf__elem-btn button link"
                onClick={() => window.open(fixationLink)}
              >
                <LockIcon />
                {windowSize.innerWidth > 1279 && 'Зафиксировать клиента'}
              </button>}
            </div>
          </div>
          <a className="apt-modal__fullinfo-link" href={isCommercial ? link + '?type=commercial' : link} target="_blank" rel="noreferrer">
            Показать полную информацию
            <TopArrowIcon/>
          </a>

        </div>

        <div className="sf">
          <div className="sf__detail-row sf__detail-row--common-modal">
            <div className="sf__detail-col">
              <Slider
                items={photos}
                showButton={!!viewFromWindow}
                viewFromWindow={viewFromWindow}
                withBorder
                windowView
              />
            </div>

            <div className="sf__detail-col">
              <InfoCard items={objectInfo} cols={infoHead}/>

              {/* old contactCard */}
              {/*{managerInfo && <ContactCard*/}
              {/*  forAptModal*/}
              {/*  logo*/}
              {/*  {...managerInfo}*/}
              {/*/>}*/}

              {contacts?.length > 1 && (
                <div className="sf__detail-contactSlider">
                  <TinySlider settings={settings} ref={node}>
                    {
                      contacts.map((item, i) => (
                        <ContactCard
                          forAptModal
                          logo
                          {...item}
                        />
                      ))}
                  </TinySlider>
                </div>
              )}
              {contacts?.length === 1 && <ContactCard
                forAptModal
                logo
                {...contacts[0]}
              />}

              {callbackStatus?.type === "success" && <NewAlert
                title="Заявка отправлена"
                text={`Ожидайте звонок. ${managerInfo?.name ? managerInfo?.name + " скоро свяжется с Вами." : "Мы свяжемся с Вами в рабочее время."}`}
                bgColor="#DFF5E0"
                iconColor="#2CBA2E"
              />}
              {callbackStatus?.type === "error" && <NewAlert
                title="Ошибка"
                text={callbackStatus?.message}
                bgColor="#FFEAE9"
                iconColor="#FF2D23"
              />}
              <section className="sf__section">
                {!(callbackStatus?.type === "success") && <SubmitButton
                  type="button"
                  className="btn h6 btn_line btn_full"
                  onClick={callback}
                  isLoading={isSending}
                >
                  Перезвоните мне
                </SubmitButton>}
               {!!bookingLink &&<Button buttonType="primary" isFull onClick={()=>window.open(bookingLink)}>
                  Забронировать
                </Button>}
              </section>
            </div>

          </div>
        </div>



      </div>


      <PhotoPopup
        open={showMapView}
        items={viewFromWindow}
        index={0}
        onClose={()=>setShowMapView(false)}

        />
    </div>
  )
}

export default AptModal
