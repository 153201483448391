import React, { useState } from 'react';
import './index.scss';

const Collapse = ({ children, title, chevron, addClass, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  return (
    <div className={`collapse${chevron ? ` collapse_with-chevron` : ``}${addClass ? ` ${addClass}` : ``}`} {...props}>
      <div class="collapse__title" onClick={toggling}>
        <span className="collapse__title-text">{title}</span>
        {
          chevron ? (
            <div className={`collapse__chevron${isOpen ? ` collapse__chevron_opened` : ``}`}>
              <svg class="icon icon_chevron">
                <use xlinkHref="#chevron"></use>
              </svg>
            </div>
          ) :
          (
            <span className="collapse__sign">
            {!isOpen ?
              <svg class="icon icon_plus">
                <use xlinkHref="#plus"></use>
              </svg> :
              <div className="collapse__minus" />
            }
          </span>
          )
        }

      </div>

      {isOpen && (
        <div className="collapse__content">
          {children}
        </div>
      )}
    </div>
  );
}

export default Collapse;
