import chunk from "lodash/chunk";
import take from "lodash/take";
import React, { useEffect, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { addToFavorites, removeFromFavorites } from "../../actions/favorites";
import { getUrl } from "../../api";
import { getRoundedPrice, media } from "../../utils";
import LoadMoreSpinner from "../LoadMoreSpinner";

const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 200);
  });

const MapItem = (props) => {
  const {
    city,
    clientMode,
    isAgent,
    commission,
    favorite,
    removeFav,
    addToFavorites,
    isPublic,
    savedFilterXmlId,
    sideEffectOnClick = () =>{},
  } = props;

  const handleFavorite = (city, id) => {
    favorite.inFavorite ? removeFav(city, id) : addToFavorites(city, id);
  };

  const routes = (
    <ul className="map-card__routes">
      {props.subway.map((subway, index) => {
        if (!subway) {
          return;
        }
        return (
          <li className="map-card__routes-item" key={index}>
            {subway.color &&
              <span
                className="map-card__metro-color"
                style={{ backgroundColor: subway.color }}
              ></span>
            }
            {subway.name &&
              <span className="map-card__metro-station">
                м. {subway.name}
              </span>
            }
            {subway.distanceType &&
              <span className="map-card__route-type">
                <svg
                  className={`icon icon_${
                    subway.distanceType === "byFoot"
                      ? "pedestrian"
                      : "auto"
                  }`}
                >
                  <use
                    xlinkHref={`#${
                      subway.distanceType === "byFoot"
                        ? "pedestrian"
                        : "auto"
                    }`}
                  ></use>
                </svg>
              </span>
            }
            {subway.minutes &&
              <span className="map-card__route-time">
                {subway.minutes} мин.
              </span>
            }
          </li>
        );
      })}
    </ul>
  )

  return (
    <li
      className="map-list__item"
      onClick={() => props.setMapCenter(props.coordinates)}
    >
      <div className="map-card">
        <div className="map-card__header">
          {!media("isMobile") && !clientMode && !isAgent && !isPublic &&  !!commission && (
            <ul className="card__pills">
              <li className="card__pill" href="#">
                {`Комиссия: ${commission.join(", ")}`}
              </li>
            </ul>
          )}
          {!isPublic && !media("isMobile") && (
            <button
              className={`button button_view_fav button_with_icon map-card__fav ${
                favorite.inFavorite ? "is-fav" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleFavorite(city, props.id, "block");
              }}
              type="button"
            >
              <svg className="icon icon_fav">
                <use xlinkHref="#fav"></use>
              </svg>
            </button>
          )}
        </div>

        {media("isMobile") &&
          <>
            <h3 className="map-card__title">{props.name}</h3>
            <div className="map-card__address">{props.address}</div>
          </>
        }

        <div className="map-card__body">
          <div className="map-card__image">
            <img
              src={getUrl(props.photo.src)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = getUrl('/upload/bcg_houses-01-01.svg');
              }}
            />

            {media("isMobile") && !clientMode && !isAgent && !isPublic && !!commission && (
              <ul className="card__pills">
                <li className="card__pill" href="#">
                  {`Комиссия: ${commission.join(", ")}`}
                </li>
              </ul>
            )}
          </div>
          <div className="map-card__content">
            <div className="map-card__row map-card__row_distribute_between">
              <div className="map-card__place">
                {!media("isMobile") &&
                  <>
                    <h3 className="map-card__title">{props.name}</h3>
                    <div className="map-card__address">{props.address}</div>
                  </>
                }
              </div>
              {/* {!media("isMobile") && props?.apartmentsInfo?.apartmentsByRoomType &&
                <div className="map-card__price">
                  от{" "}
                  {getRoundedPrice(
                    minBy(
                      props.apartmentsInfo.apartmentsByRoomType,
                      (v) => v.minPrice
                    ).minPrice
                  )}{" "}
                  млн. р.
                </div>
              } */}
            </div>
            {!media("isMobile") &&
              <div className="map-card__company">
                <div className="map-card__grid">
                  <div className="map-card__left">
                    Застройщик: {props.builder.name}
                  </div>
                  <div className="map-card__right">
                    {props.countBuildings} корпусов
                  </div>
                </div>
              </div>
            }
            {props.endings && props.endings.from &&<div className="map-card__dates">
              {props.endings.from.quarter} кв. {props.endings.from.year} —{" "}
              {props.endings.to.quarter} кв. {props.endings.to.year}
            </div>}
            {props.apartmentsInfo?.apartmentsByRoomType && (
              <div className="card__variants">
                {props.apartmentsInfo.apartmentsByRoomType.map((item, index) => (
                  <div className="card__grid" key={index}>
                    <div className="card__left">
                      <div className="card__left-inner">
                        <div className="card__left-part">
                          <strong>{item.roomTypeName}</strong>
                          {!media("isMobile") && <span> / {item.count}</span>}
                        </div>
                        {!media("isMobile") &&
                          <div className="card__left-part">
                            от {item.minSquare} м2
                          </div>
                        }
                      </div>
                    </div>
                    <div className="card__right">
                      от {getRoundedPrice(item.minPrice)} млн. р.
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!media("isMobile") && routes}

            {(props.apartmentsInfo.isApartment || props.apartmentsInfo.isAssignment) &&
              <ul className="map-card__options">
                {props.apartmentsInfo.isApartment && (
                  <li className="map-card__option ">Апартаменты</li>
                )}
                {props.apartmentsInfo.isAssignment && (
                  <li className="map-card__option ">Переуступки застр.</li>
                )}
              </ul>
            }
            {!media("isMobile") &&
              <Link
                to={
                  isPublic
                    ? `/public/${savedFilterXmlId}/${city}/complex/${props.id}`
                    : `/realty/${city}/complex/${props.id}`
                }
                target="_blank"
                onClick={()=>sideEffectOnClick(props)}
              >
                <button className="button button_view_default map-card__details-button">
                  Планировки и цены
                </button>
              </Link>
            }
          </div>
        </div>

        {media("isMobile") && routes}
      </div>
    </li>
  );
};

const MapList = (props) => {
  const {
    items,
    city,
    clientMode,
    isAgent,
    removeFav,
    addToFavorites,
    isPublic,
    savedFilterXmlId,
    sideEffectOnClick
  } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage(p * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    threshold: 20,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  useEffect(() => {
    setPage(0);
    setData(take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  useEffect(() => {
    media("isMobile") && props.isOpen && (
      document.body.classList.add("no-scroll", "is-fixed")
    )
  }, [props.isOpen]);

  useEffect( () => {
    media("isMobile") && !props.isOpen && (
      document.body.classList.remove("no-scroll", "is-fixed")
    )
  }, [props.isOpen])

  return (
    <div className={`map__list ${media('isMobile') && props.showComplex ? 'is-visible' : ''}`}>
      <div className="wrapper wrapper_fluid">
        <div className="cards__map-cards">
          {!media("isMobile") && <h3 className="map-list-title">{items.length} ЖК</h3>}

          <SimpleBar className="map-list">
            <div ref={infiniteRef}>
              <ul className="map-list__list">
                {data &&
                  data.map((item) => {
                    return (
                      media("isMobile") ?
                        <Link
                          to={
                            isPublic
                              ? `/public/${savedFilterXmlId}/${city}/complex/${item.id}`
                              : `/realty/${city}/complex/${item.id}`
                          }
                          target="_blank"
                          className="map-list__link"
                        >
                          <MapItem
                            key={item.id}
                            {...item}
                            city={city}
                            setMapCenter={props.setMapCenter}
                            isAgent={isAgent}
                            clientMode={clientMode}
                            removeFav={removeFav}
                            addToFavorites={addToFavorites}
                            isPublic={isPublic}
                            savedFilterXmlId={savedFilterXmlId}
                            sideEffectOnClick={sideEffectOnClick}
                          />
                        </Link> :
                        <MapItem
                          key={item.id}
                          {...item}
                          city={city}
                          setMapCenter={props.setMapCenter}
                          isAgent={isAgent}
                          clientMode={clientMode}
                          removeFav={removeFav}
                          addToFavorites={addToFavorites}
                          isPublic={isPublic}
                          savedFilterXmlId={savedFilterXmlId}
                          sideEffectOnClick={sideEffectOnClick}
                        />
                      )
                  })}
              </ul>
            </div>
            {loading && <LoadMoreSpinner loading={true} />}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  removeFav: (city, id) => {
    dispatch(removeFromFavorites(city, id, "block", "estateMap"));
  },

  addToFavorites: (city, id) =>
    dispatch(addToFavorites(city, id, "block", "estateMap")),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapList);
