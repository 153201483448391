import { useState } from 'react';
import { useSelector } from 'react-redux';
import { callback } from '../../../../actions/forms';
import { toFormData } from '../../../../utils';
import SubmitButton from '../../../SubmitButton';
import NewAlert from '../../../core/NewAlert';
import { AttentionIcon } from '../icons';
import ManagerModal from './ManagerModal';

const ContactsModal = ({ contacts, schedule, managerId, params, blockId = {} }) => {
  const [fetching, setFetching] = useState(false);
  const [successCallback, setSuccessCallback] = useState(false);
  const userInfo = useSelector((state) => state.user.info);
  const onSubmit = () => {
    setFetching(true);
    const form = toFormData({
      name: `${userInfo.name} ${userInfo.lastName}`,
      urgently: true,
      blockId,
      managerId,
      ...params
    })
    callback(form).then(() => {
      setFetching(false);
      setSuccessCallback(true);
    });
  };
  if(!contacts) return null
  return (
    <section className="sf__contacts-modal">
      {schedule && (
        <div className="sf__block">
          <div className="sf__contacts-header">
            <AttentionIcon />
            <h4 className="h4-l">График работы</h4>
          </div>

          <div className="sf__schedule">
            <div className="sf__sch-row">
              {schedule.map((it) => (
                <div className="sf__sch-key" key={it.title}>{it.title}</div>
              ))}
            </div>

            <div className="sf__sch-row">
              {schedule.map((it) => (
                <div className="sf__sch-key" key={it.value}>{it.value}</div>
              ))}
            </div>
          </div>
        </div>
      )}

      {contacts.map((contact) => {
        return (
          contact.managers.length > 0 && (
            <div className="sf__manager-block">
              <h5 className="h5-l">{contact.title}</h5>
              {contact.managers.map((manager) => (
                <ManagerModal {...manager} />
              ))}
            </div>
          )
        );
      })}

      {!successCallback ? (
        <SubmitButton
          className="btn h6 btn_primary btn_full sf__manager-cb"
          isLoading={fetching}
          onClick={onSubmit}
        >
          Заказать обратный звонок
        </SubmitButton>
      ) : (
        <NewAlert
          title="Заявка отправлена"
          text={`Ожидайте звонок. Мы свяжемся с Вами в рабочее время.`}
          bgColor="#DFF5E0"
          iconColor="#2CBA2E"
        />
      )}
    </section>
  );
};

export default ContactsModal;
