import classNames from "classnames";
import indexOf from "lodash/indexOf";
import union from "lodash/union";
import without from "lodash/without";
import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import SimpleBar from "simplebar-react";
import useDropdown from "../../hooks/useDropdown";
import useElementPosition from "../../hooks/useElementPosition";
import { searchRuSymbols } from '../../utils';
import DistrictsMap from "../DistrictsMap";
import SearchInput from "./SearchInput";

const ChecboxItem = (props) => {
  const {
    identity,
    id,
    title,
    isExcludable,
    exclude = false,
    checked = false,
    onChange,
    isMobile
  } = props;


  const handleSelect = (checked) => {
    onChange(checked, id);
  };

  const handleExclude = (e) => {
    onChange(true, id, !exclude);
    e.preventDefault();
  };

  return (
    <div className="checkbox-group__item">
      <div className={exclude ? "checkbox is-excluded" : "checkbox"}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id={`${identity}-${id}`}
            checked={checked}
            onChange={(e) => handleSelect(e.target.checked)}
          />
          <label
            className="checkbox__input"
            htmlFor={`${identity}-${id}`}
          ></label>
          <div className="checkbox__marker"></div>
        </div>

        {isExcludable && isMobile && checked && (
          <button
            className="button button_view_link filter__exclude-btn"
            onClick={(e) => handleExclude(e)}
          >
            {exclude ? "Включить" : "Исключить"}
          </button>
        )}

        <label className="checkbox__label" htmlFor={`${identity}-${id}`}>
          {title}
        </label>
      </div>
      {isExcludable && !isMobile && (
        <button
          className="button button_view_link filter__exclude-btn"
          onClick={(e) => handleExclude(e)}
        >
          {exclude ? "Включить" : "Исключить"}
        </button>
      )}
    </div>
  );
};

const DistrictsFilter = (props) => {
  const {
    isAutocomplete = false,
    data,
    items,
    isExcludable,
    filterValues = [],
    excludeValues = [],
    onChange,
    right = false,
    closePopup,
    city,
    setDistricts,
    selectedDistricts,
    isMobile,
    autoPosition,
    wrapperClass = "",
    hideMapButton
  } = props;
  const [node, open, show, hide] = useDropdown(false);
  const [isOpened, setIsOpened] = useState(false);
  const [search, setSearch] = useState("");
  const formItemRef = useRef(null)
  const position = useElementPosition(formItemRef, 150)
  const [filterable, setFilterable] = useState(items || []);

  const selected = union(filterValues, excludeValues);
  const searchRef = useRef(null)
  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus();
      searchRef.current.select();
    }
  }, [open])
  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right")),
  });
  const handleChange = (checked, ids, exclude = false) => {
    let id = ids.toString();
    let filterTmpValues = [...filterValues];
    let excludeTmpValues = [...excludeValues];
    if (!checked) {
      filterTmpValues = without(filterTmpValues, id);
      excludeTmpValues = without(excludeTmpValues, id);
    } else {
      if (exclude) {
        filterTmpValues = without(filterTmpValues, id);
        if (indexOf(excludeTmpValues, id) < 0) {
          excludeTmpValues.push(id);
        }
      } else {
        excludeTmpValues = without(excludeTmpValues, id);
        if (indexOf(filterTmpValues, id) < 0) {
          filterTmpValues.push(id);
        }
      }
    }
    onChange(filterTmpValues, excludeTmpValues);
  };
  useEffect(() => {
    setFilterable(
      search
        ? items.filter(
          (item) => {
            let tmpSearch = search.toLowerCase().replace(/ё/g, "е")
            let title = item.title ? item.title.toLowerCase().replace(/ё/g, "е") : false
            if (!title) {
              return
            }
            if (title.includes(tmpSearch)) {
              return true
            } else {
              return searchRuSymbols(title).includes(searchRuSymbols(tmpSearch))
            }
          }
        )
        : items
    );
  }, [search, items]);
  const [
    openModal,
    closeModal,
  ] = useModal(({ in: open, onExited }) => (
    <DistrictsMap
      closePopup={closeModal}
      city={city}
      setDistricts={setDistricts}
      selectedDistricts={filterValues}
      key={`show-map-${open}`}
      clearFilters={clearFilters}
    />
  ), [city, filterValues]);

  const clearFilters = (e) => {
    e.preventDefault()
    if (props.getCount && typeof props.getCount === "function") {
      props.getCount({
        ...props.fullFilter,
        filter: props.defFullFilter
          ? props.defFullFilter.filter
          : props.defaultFilter,
      });
    }
    props.clearFilterOnly()
    props.clearExcludeOnly()
  }

  return (
    <div className={`form__item ${wrapperClass ?? ""}`} ref={formItemRef}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">
            <div>{data.title}</div>
            {selected.length > 0 && <span style={{
              backgroundColor: "#d0d3da",
              color: "#fff",
              width: 25,
              height: 25,
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
              alignItems: "center",
              lineHeight: 0,
            }}>{selected.length}</span>}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                  {selected.length > 0 && <span style={{
                    backgroundColor: "#d0d3da",
                    color: "#fff",
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50%",
                    alignItems: "center",
                    lineHeight: 0,
                    marginLeft: 10
                  }}>{selected.length}</span>}
                </div>
              }
              <div className="filter">
                {!hideMapButton && city === "spb" && (
                  <button
                    className="button button_with_icon filter__dist-btn dist-map"
                    onClick={() => openModal(true)}
                    type="button"
                  >
                    <svg className="icon icon_place">
                      <use xlinkHref="#place"></use>
                    </svg>
                    <span>Карта районов</span>
                  </button>
                )}
                {isAutocomplete && (
                  <div className="filter__filter">
                    <SearchInput
                      placeholder={data.autocompleteTitle}
                      onChange={(e) =>
                        setSearch(e.target.value.toLowerCase())
                      }
                      inputRef={searchRef}
                      reset={() => setSearch("")}
                      value={search}
                    />
                  </div>
                )}
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {filterable.map((item, i) => (
                      <ChecboxItem
                        {...item}
                        isExcludable={isExcludable}
                        exclude={
                          isExcludable &&
                          item.id &&
                          indexOf(excludeValues, item.id.toString()) > -1
                        }
                        identity={data.identity}
                        checked={
                          item.id.toString() &&
                          indexOf(selected, item.id.toString()) > -1
                        }
                        onChange={handleChange}
                        key={`item-${item.id}-${i}`}
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                </SimpleBar>

                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => clearFilters(e)}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        hide()
                      }
                      }
                    >
                      Применить
                    </button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default DistrictsFilter;
