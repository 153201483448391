import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { getDynamicPrices } from "../../actions/complex";
import Badge from '../../components/Badge';
import Dropdown from '../../components/core/Dropdown';
import { formatMoney, getPricePerMeter, months } from '../../utils';
import Prices from './Prices';

const ChartInfoItem = props => {
  const { prices, color, roomType, squareTotal } = props

  const [start, setStart] = useState(null)
  const [noStart, setNoStart] = useState(false)

  useEffect(() => {
    if (prices) {
      let startOject = prices.find(price => price.isStart)
      if (!startOject) {
        startOject = prices[0]
        setNoStart(true)
      }
      if (!startOject) {
        return
      }
      const startObjectDate = new Date(startOject.date)
      startOject.day = startObjectDate.getDate()
      startOject.month = months[startObjectDate.getMonth()]
      startOject.year = startObjectDate.getFullYear()
      setStart(startOject)
    }
  }, [prices])
  const startCost = start?.costWithDiscount
  const currentCost = prices[prices.length - 1]?.costWithDiscount

  const startCostPerMeter = round(startCost / squareTotal)


  const currentCostPerMeter = round(currentCost / squareTotal)

  const zeroDynamic = currentCost === startCost

  const totalDynamic = currentCost - startCost

  const moneyPerPercent = startCost / 100
  const totalDynamicPercent = round(currentCost / moneyPerPercent - 100, 2)

  const dynamicPerMeter = round(totalDynamic / squareTotal)

  const badges = {
    none: null,
    percent: <span className={`chart__percent chart__percent_lg chart__percent_pos_rel ${totalDynamicPercent > 0 ? "chart__percent_plus" : "chart__percent_minus"}`}>
      {totalDynamicPercent > 0 ? "+" + totalDynamicPercent : totalDynamicPercent}%
      </span>,
    status: <span className={`chart__diff  ${totalDynamic > 0 ? "chart__diff_plus" : "chart__diff_minus"}`}>
      <svg class="icon icon_triangle-down">
        <use xlinkHref="#triangle-down"></use>
      </svg>
    </span>
  }

  return <div className="chart__info-block chart__info-block_padding_sm chart__info-block_border_none" style={{ fontSize: "14px", paddingRight: 15 }}>
    <div className="chart__info-row">
      <div style={{
        background: color,
        borderRadius: "4px",
        padding: "0 9px",
        lineHeight: "21px",
        fontWeight: 700,
        color: "#fff",
        marginBottom: "10px",
      }}>
        {roomType}
      </div>
    </div>
    <div style={{ display: "flex", flex: "1 1 auto", lineHeight: "13px", marginBottom: 10, alignItems: "flex-end", }}>
      <div style={{ color: "#8F95AC" }}>Старт продаж</div>
      <div style={{ flexGrow: 1, borderBottom: "1px dashed #8F95AC", opacity: 0.3 }}></div>
      <div>
        <span className="chart__current-value">{formatMoney(startCost)} руб.</span><span style={{ fontSize: "13px", color: "#8F95AC" }}>/ {formatMoney(startCostPerMeter)} руб./м² </span>
      </div>
      <div></div>
    </div>


    <div style={{ display: "flex", flex: "1 1 auto", lineHeight: "13px", marginBottom: 10, alignItems: "flex-end", position: "relative" }}>
      <div style={{ color: "#8F95AC" }}>Цена сегодня</div>
      <div style={{ flexGrow: 1, borderBottom: "1px dashed #8F95AC", opacity: 0.3 }}></div>
      <div>
        <span className="chart__current-value">{formatMoney(currentCost)} руб.</span><span style={{ fontSize: "13px", color: "#8F95AC" }}>/ {formatMoney(currentCostPerMeter)} руб./м²</span>
      </div>
      {zeroDynamic ? null : <div style={{position: "absolute", right: "-10px", top: "3px"}}>{badges.status}</div>}
    </div>

    {!!totalDynamic && <div className="PREP-ROW" style={{ display: "flex", flex: "1 1 auto", lineHeight: "13px", marginBottom: 10, alignItems: "flex-end", position: "relative" }}>
      <div style={{ color: "#8F95AC" }}>Общая динамика</div>
      <div style={{ flexGrow: 1, borderBottom: "1px dashed #8F95AC", opacity: 0.3 }}></div>
      <div style={{flexShrink: 0,}}><span className="chart__current-value">{dynamicPerMeter > 0 ? "+" + formatMoney(totalDynamic) : formatMoney(totalDynamic)} руб.</span></div>
      {zeroDynamic ? null : <div>{badges.percent}</div>}
    </div>}
  </div>
}
const SingleItem = (props) => {
  const { prices, color, roomType, squareTotal } = props
  const today = new Date()
  const date = today.getDate()
  const month = months[today.getMonth()]
  const currentCost = prices?.[prices.length - 1]?.costWithDiscount
  const pricePerMeter = formatMoney(getPricePerMeter(currentCost, squareTotal))
  const [start, setStart] = useState(null)

  const startCost = prices?.[0]?.costWithDiscount
  const totalDynamic = currentCost - startCost
  const zeroDynamic = currentCost === startCost
  const moneyPerPercent = startCost / 100
  const totalDynamicPercent = round(currentCost / moneyPerPercent - 100, 2)
  const dynamicPerMeter = round(totalDynamic / squareTotal)
  const [noStart, setNoStart] = useState(false)
  useEffect(() => {
    if(prices){
      let startOject = prices.find(price=>price.isStart)
      if(!startOject){
        startOject = prices[0]
        setNoStart(true)
      }
      if(!startOject){
        return
      }
      const startObjectDate = new Date(startOject.date)
      startOject.day = startObjectDate.getDate()
      startOject.month = months[startObjectDate.getMonth()]
      startOject.year = startObjectDate.getFullYear()
      setStart(startOject)
    }
  }, [])


  return <div className="chart__info">
  <div className="chart__info-block">
    <div className="chart__info-row chart__info-row_paddings chart__info-row_wrap_sm">
      {start && <div className="chart__info-col">
        <div className="chart__info-item flat__info-item">
          <div className="flat__info-item_desc">{noStart? "Цена на":"Старт продаж,"} {start.day} {start.month} {start.year}</div>
        </div>
        <div className="chart__info-item flat__info-item">
          <div className="chart__info-value">
            {formatMoney(start.costWithDiscount)} руб.
            {/* <span className="chart__diff chart__diff_minus">
              <svg class="icon icon_triangle-down">
                <use xlinkHref="#triangle-down"></use>
              </svg>
            </span> */}
          </div>
          <div className="flat__info-item_desc">{formatMoney(getPricePerMeter(start.costWithDiscount, squareTotal))} руб./м²</div>
        </div>
      </div>
      }

      <div className="chart__info-col">
        <div className="chart__info-item flat__info-item">
          <div className="flat__info-item_desc">Цена на сегодня, {date} {month}</div>
        </div>
        <div className="chart__info-item flat__info-item">
          <div className="chart__info-value">
          {formatMoney(currentCost)} руб.
           {totalDynamic != 0 && <span className={`chart__diff  ${totalDynamic > 0 ? "chart__diff_plus" : "chart__diff_minus"}`}>
              <svg class="icon icon_triangle-down">
                <use xlinkHref="#triangle-down"></use>
              </svg>
            </span>}
          </div>
          <div className="flat__info-item_desc">{pricePerMeter} руб./м²</div>
        </div>
      </div>

      {!zeroDynamic &&
        <div className="chart__info-col">
          <div className="chart__info-item flat__info-item">
            <div className="flat__info-item_desc">Общая динамика</div>
          </div>
          <div className="chart__info-item flat__info-item">
            <div className="chart__info-value chart__info-value_sm">
              {totalDynamic > 0 ? "+" + formatMoney(totalDynamic) : formatMoney(totalDynamic)} руб.
              <span className={`chart__percent ${totalDynamicPercent > 0 ? "chart__percent_plus" : "chart__percent_minus"}`}>{totalDynamicPercent > 0 ? "+" + totalDynamicPercent : totalDynamicPercent}%</span>
            </div>
          </div>
        </div>
      }

      {!zeroDynamic &&
        <div className="chart__info-col">
          <div className="chart__info-item flat__info-item">
            <div className="flat__info-item_desc">Динамика за&nbsp;м²</div>
          </div>
          <div className="chart__info-item flat__info-item">
            <div className="chart__info-value chart__info-value_sm">
            {dynamicPerMeter > 0 ? "+" + formatMoney(dynamicPerMeter) : formatMoney(dynamicPerMeter)} руб./м²
              {/* <span className="chart__percent chart__percent_minus">-15%</span> */}
            </div>
          </div>
        </div>
      }

    </div>
  </div>

  {/* <div className="chart__info-block">
    <div className="chart__info-row chart__info-row_align-center">
      <div className="chart__info-item flat__info-item">
        <div className="flat__info-item_desc">Средний рост цены в год</div>
      </div>
      <div className="chart__info-item flat__info-item">
        <div className="chart__pie">
          <div className="chart__pie-label">+15%</div>
        </div>
      </div>
    </div>
  </div> */}
</div>
}

const ComplexChart = (props) => {
  const { innerRef, city, id} = props;
  const [chartInfo, setChartInfo] = useState(null)
  const [year, setYear] = useState()
  const [building, setBuilding] = useState()
  const [roomType, setroomType] = useState()
  const [isDefault, setIsDefault] = useState(true)
  const getDynamic = (buildingId, roomTypeId, year)=> {
    const formData = new FormData();
    formData.append("id", id)
    let changedValue = []
    if(buildingId){
      formData.append("buildingId", buildingId)
      changedValue.push("buildingId")
    }
    if(year){
      formData.append("year", year)
      changedValue.push("year")
    }
    if(roomTypeId){
      formData.append("roomTypeId[]", roomTypeId)
      changedValue.push("roomTypeId")
    }
    if(changedValue.length === 1){
      changedValue = changedValue[0]
    }
    formData.append("city", city)
    getDynamicPrices(formData).then(data=>{
      let { buildings, years, prices, roomTypes } = data
      if(!chartInfo){
        buildings = buildings.map(building=> ({...building, title: building.ending}))
        years = years.map(year => ({ id: year, title: year, years }))
        buildings.unshift({id: 0, title: "Все корпуса", disabled: false})
        roomTypes.unshift({id: 0, title: "Все типы", disabled: false})
        years.unshift({id: 0, title: "За весь период", disabled: false})
        setChartInfo({ buildings, years, prices, roomTypes })
        return
      }
      const buildingsIds = buildings.map(val=> val.id)
      const roomTypesIds = roomTypes.map(val=> val.id)
      years = chartInfo.years.map(item=> ({...item, disabled: changedValue !== "year" && !years.includes(item.id) && item.id != 0 }))
      buildings = chartInfo.buildings.map(item=> ({...item, disabled: changedValue !== "buildingId" && !buildingsIds.includes(item.id) && item.id != 0 }))
      roomTypes = chartInfo.roomTypes.map(item=> ({...item, disabled: changedValue !== "roomTypeId" && !roomTypesIds.includes(item.id) && item.id != 0 }))
      setChartInfo({ buildings, years, prices, roomTypes })
    })
  }
  useEffect(() => {
    if ((!building && !roomType && !year) && (building !== 0 && roomType !== 0 && year !== 0)) {
      setIsDefault(true)
    }
    else {
      setIsDefault(false)
    }
    getDynamic(building, roomType, year)
  }, [building, roomType, year])

  const resetFilter = (params) => {
    setBuilding(0)
    setroomType(0)
    setYear(0)
  }

  if (!chartInfo){
    return null
  }
  return (
    <section class="section section_view_white complex__section-about complex__section-chart chart" ref={innerRef}>
      <div id="anchor-about"></div>
      <div class="wrapper">
        <div class="wrapper__center">
          <div class="section__title chart__header">График изменения цены </div>
          {/* <p className="chart__subtitle">Чтобы посмотреть график изменения цены, выберите параметры</p> */}
          <div class="complex__about">

            <div className="complex__about-col">
              <form action="#" className="form">
                <div className="form__row chart__filter">
                  <div className="form__item">
                    <div className="select select_type_form">
                      <Dropdown
                        options={chartInfo.years}
                        value={year}
                        onChange={id => setYear(id)}
                        placeholder="Год"
                      />
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="select select_type_form">
                      <Dropdown
                        options={chartInfo.buildings}
                        value={chartInfo.buildings.find(item => item.id == building)?.id}
                        onChange={id => setBuilding(id)}
                        placeholder="Корпус"
                      />
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="select select_type_form">
                      <Dropdown
                        options={chartInfo.roomTypes}
                        value={chartInfo.roomTypes.find(item => item.id == roomType)?.id}
                        onChange={id =>setroomType(id)}
                        placeholder="Кол-во комнат"
                      />
                    </div>
                  </div>
                    <button className="button button_type_clear form__reset" type="button" onClick={resetFilter}>
                      <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                      </svg>
                      <span>очистить фильтр</span>
                    </button>
                  {/* <div className="form__item">
                    <button className="button button_view_default" type="button" onClick={()=>getDynamic(building, roomType, year)}>Показать график</button>
                  </div> */}
                </div>
                <div className="mini-filter">
                  <div className="index-search__filter-applied">
                    <div className="index-search__filter-inc">
                      {!!roomType && <Badge item={chartInfo.roomTypes.find(item => item.id == roomType)} title={"Тип"} handleClick={() => setroomType()} />}
                      {!!building && <Badge item={chartInfo.buildings.find(item => item.id == building)} title={"Корпус"} handleClick={() => setBuilding()} />}
                      {!!year && <Badge item={chartInfo.years.find(item => item.id == year)} title={"Год"} handleClick={() => setYear()} />}
                    </div>
                  </div>
                </div>


              </form>
              <div className="chart__block">

                {chartInfo.prices.length === 1 ? <SingleItem {...chartInfo.prices[0]} /> : <div
                  className="chart__info chart__info_bordered"

                 >
                  <div className="chart__info-container">
                    {chartInfo.prices.map((item, i) => <ChartInfoItem {...item} key={`chart-info-item-${i}`} />)}
                  </div>
                </div>}

                <div className="chart__list">
                  {chartInfo.prices.map(item => {
                    return (
                      <div style={{
                        color: item.color,
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <div style={{
                          width: 8,
                          height: 8,
                          border: `1px solid ${item.color}`,
                          borderRadius: "50%",
                          marginRight: 8
                        }}>
                        </div> {item.roomType}<div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="chart__box chart__box_width_full">
                <div className="chart__container" style={{ width: '100%' }}>
                <div className="chart__chart">

                <Prices {...props} isDefault={isDefault} chartInfo={chartInfo} />

                </div>
                {/* пока без этих кнопок/ */}
                <div className="chart__dates">
                {/* <button type="button" className="chart__date chart__date_active">
                <span className="chart__date-month chart__date-month">Май</span>
                <span className="chart__date-year">2020</span>
                </button>

                <button type="button" className="chart__date">
                <span className="chart__date-month">Июнь</span>
                </button>

                <button type="button" className="chart__date">
                <span className="chart__date-month">Июль</span>
                </button>

                <button type="button" className="chart__date">
                <span className="chart__date-month">Август</span>
                </button>

                <button type="button" className="chart__date">
                <span className="chart__date-month">Сентябрь</span>
                </button>

                <button type="button" className="chart__date">
                <span className="chart__date-current">Сегодня</span>
                </button> */}
                </div>
                </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComplexChart;
