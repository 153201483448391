import React, { useState } from "react"
import './index.scss'
import {
  SfFilterCopyIcon,
  SfFilterEyeIcon,
  SfFilterLinkIcon,
  SfFilterTelegramIcon,
  SfFilterWhatsupIcon
} from "../../components/Public/markup/icons";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { changeActiveCallback } from "../Crm/columns";
import SubmitButton from "../../components/SubmitButton";
import Dropdown from "../../components/core/Dropdown";
import Preloader from "../../components/Preloader";
import usePermission from "../../hooks/usePermission";
import Button from "../../components/core/Button";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {errorToast, standartErrorToast, standartSuccessToast, successToast} from "../../components/toasts";
import {getUrl} from "../../api";
import ColorPicker from "../../components/core/ColorPicker";

const CommonSettingsSF = ({ changeSettings, isActive, agents = [], agent, savedFilter, clients = [], client, isLoading, save, isUserSF }) => {
  const phone = client?.phone?.toString?.() ?? "";
  const permissionToAgents = usePermission('master+');

  const handleCopy = (text, result) => {
    if (result) {
      successToast(<div>Скопировано в буфер обмена</div>);
    } else {
      errorToast(<div>Ошибка копирования</div>);
    }
  }

  if (!savedFilter) return <Preloader loading />
  return (
    <div className="sf-settings-container">
      <div className="sf-setting-view">
        <SfFilterEyeIcon />Общее количество просмотров — {savedFilter && savedFilter?.countView}
      </div>
      <div className="sf-setting-common">
        <label className="switch">
          <div className="switch__box">
            {savedFilter &&
              <input
                className="switch__control"
                type="checkbox"
                checked={isActive}
                onChange={e => {
                  changeSettings("isActive", e.target.checked)
                  changeActiveCallback(savedFilter.city, savedFilter.id, !e.target.checked ? 'N' : 'Y')
                }}
              />
            }
            <div className="switch__input" />
            <div className="switch__marker" />
          </div>
          <span style={{ marginLeft: 12 }}>Активность подборки</span>
        </label>

        {
          !isUserSF && !permissionToAgents && !agents?.length &&
          <div className="hp__form-item disabled">
            <label className="hp__form-label text">
              Сотрудник
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={'input__control'}
                // placeholder="Введите имя"
                value={agent?.lastName + ' ' + agent?.name}
                disabled
              />
            </label>
          </div>
        }
     {permissionToAgents && !!agents?.length && <div className="hp__form-item">
          <label className="hp__form-label text">
          Сотрудник
          </label>
          <label
            className="input input_width_available input_type_form"
          >
            <Dropdown
              wrapperClass="custom-select_sf-filter"
              withSearch={agents?.length > 4}
              options={agents}
              value={agent?.id}
              onChange={v => changeSettings("agent", agents.find(agent => agent.id == v))}
            />
          </label>
        </div>}


        <div className="hp__form-item">
          <label className="hp__form-label text">
            Название подборки
          </label>
          <label
            className="input input_width_available input_type_form"
          >
            <input
              className={'input__control'}
              placeholder="Введите название"
              value={savedFilter?.name}
              onChange={e => changeSettings("savedFilter.name", e.target.value)}
            />
          </label>
        </div>

        <div className="sf-setting-common__titleblock">
          <h3 className="h3">Данные клиента</h3>
          <div>
            <label className="hp__form-label text">
              Выберите клиента
            </label>

            <Dropdown
              wrapperClass="custom-select_sf-filter"
              withSearch={clients?.length > 4}
              options={clients}
              value={client?.id}
              onChange={v => changeSettings("client", clients.find(client => client.id == v))}
            />
          </div>

          <div>
            <label className="input input_width_available input_type_form">
              <span className="input__label">Телефон</span>
              <InputMask
                className="input__control input input_type_form"
                name="phone"
                placeholder="Введите телефон"
                mask="+7 (999) 999-99-99"
                autoComplete="off"
                maskChar="_"
                value={phone}
                onChange={e => changeSettings("client.phone", e.target.value)}
              />
            </label>
          </div>

        </div>

        <div className="sf-setting-common__titleblock">
          <h3 className="h3">Отправить подборку клиенту</h3>

          <ul className="sf-setting-links">
            <a className="sf-setting-links__item"
              href={`https://wa.me/${phone.replace(/[+() \-_]/g, "").length === 11 ? phone.replace(/[+() \-_]/g, "") : ""}?text=${getUrl(savedFilter.url)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="sf-setting-links__icon">
                <SfFilterWhatsupIcon />
              </div>
              <p className="sf-setting-links__text">
                WhatsApp
              </p>
            </a>
            <a
              className="sf-setting-links__item"
              // href={`https://t.me/+${phone?.replace?.(/\D/g, "")}`}
              href={`https://t.me/share/url?url=${getUrl(savedFilter.url)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="sf-setting-links__icon">
                <SfFilterTelegramIcon />
              </div>
              <p className="sf-setting-links__text">
                Telegram
              </p>
            </a>
            <CopyToClipboard text={getUrl(savedFilter.url)} onCopy={handleCopy}>
            <li className="sf-setting-links__item">
              <div className="sf-setting-links__icon">
                <SfFilterLinkIcon />
              </div>
              <p className="sf-setting-links__text">
                Ссылкой
              </p>
            </li>
            </CopyToClipboard>
          </ul>
        </div>

        <SubmitButton
          className="button button_view_default"
          isLoading={isLoading}
          onClick={save}
        >
          Сохранить
        </SubmitButton>
      </div>
    </div>
  )
}
export default CommonSettingsSF

export const SettingsSettingsSF = ({ savedFilterSettings = {}, changeSettings, isLoading, save }) => {


  return (
    <div className="sf-settings-container">
      <div className="sf-setting-common">

        <ColorPicker
          labelTextInput="Цвет шапки сайта"
          backHexColor={savedFilterSettings?.colorHeader ? savedFilterSettings?.colorHeader : ''}
          changeSettings={changeSettings}
          changeSettingsPayload="savedFilterSettings.colorHeader"
        />
        <ColorPicker
          labelTextInput="Цвет текста в шапке"
          backHexColor={savedFilterSettings?.colorHeaderText ? savedFilterSettings?.colorHeaderText : ''}
          changeSettings={changeSettings}
          changeSettingsPayload="savedFilterSettings.colorHeaderText"
        />
        <ColorPicker
          labelTextInput="Цвет кнопок"
          backHexColor={savedFilterSettings?.colorButton ? savedFilterSettings?.colorButton : ''}
          changeSettings={changeSettings}
          changeSettingsPayload="savedFilterSettings.colorButton"
        />
        <ColorPicker
          labelTextInput="Цвет текста на кнопках"
          backHexColor={savedFilterSettings?.colorButtonText ? savedFilterSettings?.colorButtonText : ''}
          changeSettings={changeSettings}
          changeSettingsPayload="savedFilterSettings.colorButtonText"
        />
        <ColorPicker
          labelTextInput="Цвет hover эфекта"
          backHexColor={savedFilterSettings?.colorHover ? savedFilterSettings?.colorHover : ''}
          changeSettings={changeSettings}
          changeSettingsPayload="savedFilterSettings.colorHover"
        />

        <div className="hp__form-item">
          <label className="hp__form-label text">Свой заголовок страницы</label>
          <label className="input input_width_available input_type_form">
            <input
              className={'input__control'}
              value={savedFilterSettings?.title ? savedFilterSettings?.title : ''}
              onChange={e => changeSettings("savedFilterSettings.title", e.target.value)}
            />
          </label>
        </div>

        <div className="checkbox checkbox_view_red ">
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="title-view"
              name="remember"
              checked={savedFilterSettings?.hideTitle ? savedFilterSettings?.hideTitle : false}
              onChange={e => changeSettings("savedFilterSettings.hideTitle", e.target.checked)}
            />
            <label className="checkbox__input" htmlFor="title-view" />
            <div className="checkbox__marker" />
          </div>
          <label className="checkbox__label" htmlFor="title-view">Отключить вывод заголовка</label>
        </div>

        <div className="checkbox checkbox_view_red ">
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="agency-name"
              checked={!savedFilterSettings?.hideAgency}
              onChange={e => changeSettings("savedFilterSettings.hideAgency", !e.target.checked)}
            />
            <label className="checkbox__input" htmlFor="agency-name" />
            <div className="checkbox__marker" />
          </div>
          <label className="checkbox__label" htmlFor="agency-name">Показывать название агенства</label>
        </div>

        <div className="checkbox checkbox_view_red ">
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="header-site"
              checked={!savedFilterSettings?.hideHeader}
              onChange={e => changeSettings("savedFilterSettings.hideHeader", !e.target.checked)}
            />
            <label className="checkbox__input" htmlFor="header-site" />
            <div className="checkbox__marker" />
          </div>
          <label className="checkbox__label" htmlFor="header-site">Показывать шапку сайта</label>
        </div>

        <SubmitButton
          className="button button_view_default"
          isLoading={isLoading}
          onClick={save}
        >
          Сохранить
        </SubmitButton>
        <SubmitButton
          type="button"
          className="button link btn_full"
          isLoading={isLoading}
          onClick={()=>save("default")}
        >
          Сохранить как настройки по умолчанию
        </SubmitButton>
      </div>
    </div>
  )
}
