import React from "react";
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlan.svg';
import { priceFormat } from '../Complex/Utils';


const FlatCard = (props) => {
  const {
    id,
    roomType,
    decoration,
    squareTotal,
    costWithDiscount,
    squareKitchen,
    block,
    ending,
    floor,
    address,
    corpus,
    subway,
    planSmall,
    city,
    favorite,
    whatPage,
    showOnLeft,
    number,
    countBuildings,
    type,
    builder,
    disableFavorites,
    alternativeLink,
    isCommercial,
    status,
    isPublic,
    savedFilterXmlId,
    objectType,
    plan,
    squareLiving,
    endingDate,
    onMouseMove
  } = props;
  

  return (
    <div className="complex__appartments-card complex__appartments-card_tile"  onMouseMove={()=>onMouseMove?.(id)}>
      <div
        className="card-tile"
        onClick={(e) => {
          
          let link = isPublic
            ? `/public/${savedFilterXmlId}/${city}/apartment/${id}`
            : `/realty/${city}/apartment/${id}${window.location.search}`
          if (objectType === "assignment") {
            link = `/assignments/${id}${window.location.search}`
          }
          if(alternativeLink){
            link = alternativeLink + id
          }
          window.open(link);
        }}

      >
        <div className="card-tile__image">
          <img src={
            getUrl(plan?.src)}
            alt={""}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }}
          />
        </div>

        {status && <div className="card-tile__status table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>}
        
        {
          isCommercial ?
          <div className="card-tile__body">
            <div className="card-tile__row">
              <div className="card-tile__col">
                <span className="card-tile__value-small">{floor} этаж</span>
                <span className="card-tile__value-lg">{priceFormat(costWithDiscount)}</span>
              </div>
              <div className="card-tile__col">
                <span className="card-tile__value-small">№ помещения: {number}</span>
                <span className="card-tile__value-lg">{squareTotal} м²</span>
              </div>
            </div>
  
            <div className="card-tile__row">
              <div className="card-tile__col">
                <span className="card-tile__key">Отделка</span>
                <span className="card-tile__value">{decoration}</span>
              </div>
              <div className="card-tile__col">
                <span className="card-tile__key">Срок сдачи</span>
                <span className="card-tile__value">{endingDate}</span>
              </div>
            </div>
          </div> :
          // Переуступки
          <div className="card-tile__body">
            <div className="card-tile__row">
              <div className="card-tile__col">
                <span className="card-tile__value-small">{floor} этаж</span>
                <span className="card-tile__value-lg">{priceFormat(costWithDiscount)}</span>
              </div>
              <div className="card-tile__col">
                <span className="card-tile__value-small">{`№ квартиры:`} {number}</span>
                <span className="card-tile__value-lg">{squareTotal} м²</span>
              </div>
            </div>

            <div className="card-tile__row">
              {squareLiving && <div className="card-tile__col">
                <span className="card-tile__key">S жилая</span>
                <span className="card-tile__value">{squareLiving}</span>
              </div>}
              {squareKitchen && <div className="card-tile__col">
                <span className="card-tile__key">S кухни</span>
                <span className="card-tile__value">{squareKitchen} м²</span>
              </div>}
              {decoration && <div className="card-tile__col">
                  <span className="card-tile__key">Отделка</span>
                  <span className="card-tile__value">{decoration}</span>
                </div>}
            </div>
          </div>
        }


      </div>
    </div>
  );
};
export default FlatCard;
