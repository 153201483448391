import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  clearOffers, fetchOffers, saveOffers
} from "../../actions/profile.offers";
import { acceptOffers } from "../../actions/user";
import { media } from '../../utils';
import Preloader from "../Preloader";


const OffersMessage = (props) => {
  const { text } = props;
  return (
    <div class="reglament__message">
      <div class="reglament__message-icon">
        <svg class="icon">
          <use xlinkHref="#warning"></use>
        </svg>
        <span>!</span>
      </div>
      <div class="reglament__message-text">
        <p>{text}</p>
      </div>
    </div>
  );
};

const OffersForm = (props) => {
  const { onSubmit } = props;
  const [checked, setChecked] = useState(false);

  return (
    <form
      class="form reglament__form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div class="reglament__sign">
        <div class="checkbox checkbox_view_invert">
          <div class="checkbox__box">
            <input
              class="checkbox__control"
              type="checkbox"
              id="reglament-agreement"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
            <label
              class="checkbox__input"
              htmlFor="reglament-agreement"
            ></label>
            <div class="checkbox__marker"></div>
          </div>
          <label class="checkbox__label" for="reglament-agreement">
            Согласие со всеми офертами. Нажимая кнопку согласиться Вы принимаете
            все условия.
          </label>
        </div>
      </div>
      <div class="reglament__submit">
        <button
          class="button button_view_default"
          type="submit"
          disabled={!checked}
        >
          <span>Согласиться</span>
        </button>
      </div>
    </form>
  );
};

const OffersList = (props) => {
  const { items } = props;

  return (
    <div class="reglament__list">
      {items.map((item) => (
        <div class="document__row files__item" key={item.id}>
          <div class="document__col document__col_icon">
            <div class="document__icon">
              <svg class="icon icon_document">
                <use xlinkHref="#document"></use>
              </svg>
            </div>
          </div>
          <div class="document__col document__col_name">
            <a href={item.file.src} target="_blank" rel="noopener noreferrer">
              <div className="files__item-name link-hover">{item.name}</div>
            </a>
          </div>
          {!media('isMobile') && <div class="document__col document__col_info"></div>}
          <div class="document__col document__col_controls">
            {media('isMobile') &&
              <a href={item.file.src} target="_blank" rel="noopener noreferrer">
                <svg className="icon icon_watch">
                  <use xlinkHref="#icon_watch"></use>
                </svg>
              </a>
            }
            <a href={item.file.src} download>
              <div class="files__item-control files__item-download">
                <svg class="icon">
                  <use xlinkHref="#download"></use>
                </svg>
              </div>
            </a>
          </div>
          <div class="document__arrow">
            <svg class="icon">
              <use xlinkHref="#arrow_bottom"></use>
            </svg>
          </div>
        </div>
      ))}
    </div>
  );
};

const BackButton = (props) => {
  const { to } = props;
  return (
    <Link to={to}>
      <button
        class={`
          button button_view_outline-with-icon clients__back-btn
          ${media('isMobile') ? 'is-small' : ''}
        `}
      >
        <svg class="icon icon_back">
          <use xlinkHref="#back"></use>
        </svg>
        {!media('isMobile') &&<span>Вернуться</span>}
      </button>
    </Link>
  );
};

const ProfileOffers = (props) => {
  const {
    save,
    fetch,
    reset,
    fetching,
    error,
    offers,
    agree,
    acceptOffers,
    location,
  } = props;

  useEffect(() => {
    fetch();
    return () => {
      reset();
    };
  }, []);

  const handleSave = () => {
    save().then(
      (data) => {
        acceptOffers();
      },
      (error) => {}
    );
  };

  if (fetching) return <Preloader />;

  if (error) return "Error";

  return (
    <>
      <section class="section section_view_white no-header-profile">
        <div class="wrapper">
          <div class="personal_content">
            <div class="profile__header">
              <div class="profile__title">
                {agree && media('isMobile') && (
                  <BackButton to={`/personal/profile`}/>
                )}
                <h1>Оферты</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section_view_white">
        <div class="wrapper">
          <div class="reglament__body">
            <div class="reglament__grid">
              <div class="reglament__grid-col reglament__back">
                {agree && !media('isMobile') && (
                  <BackButton to={`/personal/profile`}/>
                )}
              </div>
              <div class="reglament__grid-col reglament__info">
                {!agree && (
                  <OffersMessage text="Просим Вас внимательно ознакомиться со всеми документами. Чтобы получить доступ к базе Вы должны принять все оферты." />
                )}
                {offers &&  <OffersList items={offers} />}
                {!agree && <OffersForm onSubmit={handleSave} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchOffers()),
  reset: () => dispatch(clearOffers()),
  save: () => dispatch(saveOffers()),
  acceptOffers: () => dispatch(acceptOffers()),
});

const mapStateToProps = (state) => ({
  fetching: state.profileOffers.fetching,
  error: state.profileOffers.error,
  offers: state.profileOffers.offers,
  agree: state.user.acceptOffers,
});

export { OffersList };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOffers);
