import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';

import { ListIcon, TileIcon } from '../core/Tabs/icons';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import CardsList from '../Public/markup/CardsList';
import NotFound from '../Public/markup/detail/NotFound';
import Tags from '../Public/markup/Tags';
import VillageFilterForm from '../Public/markup/VillageFilterForm';


import { TableApartments } from './ApartmentList';
import SuburbanCardNew from '../Public/markup/Containers/SuburbanCardNew';
import { ShowMore } from '../core/Table2';

const viewTabs = [
  { title: 'Список', icon: ListIcon },
  { title: 'Плитка', icon: TileIcon },
  // { title: 'Шахматка', icon: ChessIcon },
];

const SuburbanContent = memo(props => {
  const [activeView, setActiveView] = useState(0);
  const {
    handleFilter,
    isLoading,
    filter = {},
    data: {
      filter: filterData = {},
      headers,
      items,
      limit,
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    removeBadge,
    resetFilter,
    hasNextPage,
    handleLoadMore,
    moreIsLoading,
    tab,
    villageId,
    city

  } = props;


  return (
    <>
      <VillageFilterForm
        filterData={filterData}
        filter={filter}
        setFilterAndExclude={setFilterAndExclude}
        handleFilter={handleFilter}
        isLoading={isLoading}
        {...tab}
      />
      <Tags
        badges={filterBadges}
        removeBadge={removeBadge}
        resetFilter={resetFilter}
      />
      {!!items?.length && (
        <StatelessTabs
          tabs={viewTabs}
          activeTab={activeView}
          setActiveTab={setActiveView}
          withIcons
          onClick={(i) =>
            i === 2
              ? window.open(
                `/realty/${city}/chess/${villageId}`,
                '_blank',
              )
              : setActiveView(i)
          }
        />
      )}

      <div className="complex-results-wrapper">
        {!!items?.length && (
          <div className="complex__appartments table2">
            <div className="complex__appartments-tab active">
              <div style={{ display: 'block' }}>
                {activeView === 1 ? (
                  <CardsList
                    withoutBtnsList
                    items={items}
                    limit={limit}
                    isLoading={showSkeleton}
                    cardComponent={SuburbanCardNew}
                    getLink={(item) => `/old/lands/${city}/${item.id}`}
                    listClass="sf__cards-list sf__cards-list_flats"
                  />
                ) : (
                  <TableApartments tabTitle={tab.title} items={items} headers={headers}     getLink={(item) => `/old/lands/${city}/${item.id}`}/>
                )}
                {hasNextPage && <ShowMore onClick={handleLoadMore} loading={moreIsLoading} />}
              </div>
            </div>
          </div>
        )}
      </div>
      {!items?.length && <NotFound />}
    </>
  );
});

export default SuburbanContent;
