import React from 'react';
import { getUrl } from '../../../api';
import { media } from '../../../utils';


const Images = ({ images, object, settings, typical3dTour }) => {
 
  return (
    <div className="pdf-block">
      <div className={`presentation__box ${settings?.['typical_3d_tour']?.disabled == 'Y' || !object?.typical3dTour? ' hidden' : ''}`} data-id="typical_3d_tour" >
        <div className="presentation__title">Типовой шоу-рум застройщика</div>
        <div className="presentation__showroom">
          <a href={getUrl(object?.typical3dTour)} target="_blank" className="button button_view_default">Посмотреть</a>
          <div className="infrastructure__map" style={{ height: media('isMobile') ? 250 : 390 }}>
            <iframe
              allow="true"
              loading="lazy"
              allowFullScreen={true}
              referrerPolicy="no-referrer"
              src={getUrl(object?.typical3dTour)}
              width="100%"
              height="100%"
            ></iframe>
          </div>

          <div className="showroom-tooltip">
            <div className="showroom-tooltip__decor">
              <div className="showroom-tooltip__icon">
                <svg className="icon icon_info">
                  <use xlinkHref="#info"></use>
                </svg>
              </div>
            </div>
            Типовой шоу-рум от застройщика
          </div>
        </div>
      </div>


      <div className={`presentation__box ${images?.length > 0 ? '' : ' hidden'}`}  >
        <div className="presentation__title">Дополнительные фотографии</div>
        <div className="presentation__more">
          <div className="presentation__more-images presentation__info-grid">
            {images.map((image, index) => (
              <div className="presentation__more-image presentation__info-col" key={index}>
                <img src={getUrl(image.src)} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Images;
