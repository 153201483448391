import React from 'react';

const Banner = ({clickHandler}) => {
  return (
    <div href="#" onClick={clickHandler} className="training__banner">
      <h4 className="h4">Хотите заработать дополнительные баллы?</h4>
    </div>
  );
};

export default Banner;
