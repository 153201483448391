import React from 'react';

const StartBlock = (props) => {

  const {openCallbackModal} = props;
  return (
    <div className="section__grid-col cards__item start-block">
      <div className="card start-block__card">
        <div className="start-block__box">
          <div className="start-block__content">
            <div className="">
            <span className="h3">На старт!</span>
              <p className="big-text">Хотите узнать о старте продаж новых жилых комплексов?</p>
            </div>

            <button className="button constructor__btn" type="button" onClick={()=>openCallbackModal("Оставить заявку")}>
               Оставить заявку
            </button>

          </div>

        </div>
      </div>
    </div>
  );
};

export default StartBlock;
