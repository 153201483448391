const initialState = {
    profile: {
        name: '',
        lastName: '',
        patronymic: '',
        email: [],
        phone: [],
        watsApp: '',
        telegram: ''
    },
    error: false,
    fetching: false,
  };
  
  const profile = (state = initialState, action) => {
    switch (action.type) {
      case "PROFILE_REQUEST":
        return { ...state, error: false, fetching: true };
      case "PROFILE_SUCCESS":
        return {
          error: false,
          fetching: false,
          profile: action.profile
        };
      case "PROFILE_FAILURE":
        return { ...state, error: true, fetching: false };
      default:
        return state;
    }
  };
  
  export default profile;