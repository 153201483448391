import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { sendError } from "../../actions/forms";

export const FatalErrorComponent = ({ title, text, showButton = true, style }) => <div className="fatal-wrapper" style={style}>
  <div className="fatal-content">
    <img className="fatal-image" src={require('../../assets/images/fatal.svg').default} alt="" />
    {title}
    <span className="big-text fatal-second-text">
      {text}
    </span>
    {showButton && <button
      className="button button_view_default fatal-button"
      onClick={() => {
        this.props.history.push("/");
        window.location.reload();
      }}
    >
      На главную
    </button>}
  </div>
</div>
class FatalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      comment: "",
      errorInfo: "",
      error: "",
      isSended: false,
      errorId: Date.now().toString().substring(7),
    };
  }
  componentDidMount() {
    this.setState({ isSended: false });
  }
  static getDerivedStateFromError(error) {
    const NOW = Date.now()
    if ((NOW - localStorage.getItem('lastForciblyReloaded') > 30000) && window.location.host !== "localhost:3000") {
      localStorage.setItem('lastForciblyReloaded', NOW);
      window.location.reload()
    }
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //тут можно отправлять ошибки на сервер
    this.setState({ error: error, errorInfo: errorInfo });
    localStorage.removeItem("blocksFilter")
    let data = {
      "fields[errorId]": this.state.errorId,
      "fields[pageUrl]": window.location.href,
      "fields[error]": error,
      "fields[errorInfo]": JSON.stringify(errorInfo),
      "fields[comment]": "ОШИБКА ОТПРАВЛНА ДО КОММЕНТИРОВАНИЯ",
      "fields[userInfo]": JSON.stringify(this.props.user.info),
      "fields[userHistory]": JSON.stringify(this.props.user.history),
      "fields[userAgent]": window.navigator.userAgent,
      "fields[screenWxH]": window.screen.width + "x" + window.screen.height,
      "fields[windowWxH]": window.innerWidth + "x" + window.innerHeight,

    };
    let form = new FormData();
    let keys = _.keys(data);
    keys.forEach((key) => {
      form.append(`${key}`, data[key]);
    });
    if (window.location.host !== "localhost:3000") {
      sendError(form)
    }
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <FatalErrorComponent
        title={<h1 className="h1 fatal-title">По техническим причинам,<br />страница недоступна</h1>}
        text={`Просим прощения за доставленные неудобства.`}
      />
    }
    return this.props.children;
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FatalError));
