import isEqual from "lodash/isEqual";
import _keys from "lodash/keys";
import reduce from "lodash/reduce";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { sendError } from "../../actions/forms";


const cleanFilter = {
  buildings: [],
  bathroom: [],
  roomType: [],
  decoration: [],
  squareKitchen: { min: "", max: "" },
  endings: {
    min: null,
    max: null,
  },
  squareTotal: { min: "", max: "" },
  floors: { min: "", max: "" },
  prices: { min: "", max: "" },
  banks: [],
  rooms: [],
  status: [1],
  isOnlyHanded: [],
  payment: [],
  number: "",
  isNoFirstFloor: "",
  isApartments: [],
  assignments: [],
};

function difference(object) {
return  reduce(object, function(result, value, key) {
    return isEqual(value, cleanFilter[key]) ?
        result : result.concat(key);
}, []);
}

class FilterError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      comment: "",
      errorInfo: "",
      error: "",
      isSended: false,
    };
  }
  componentDidMount() {
    this.setState({ isSended: false });
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    localStorage.removeItem("blocksFilter")
    localStorage.removeItem("suburbansFilter")
    if (this.state.isSended) {
      return;
    }
    if (this.state.isSended) {
      this.props.history.push("/");
      window.location.reload();
      return;
    }
    let filterDifs = difference(this.props.filter)
    let tempfilter = {}
    let tempFilterData = {}
    filterDifs.forEach(key => {
      tempfilter[key] = this.props.filter[key]
      tempFilterData[key] = this.props.filterData[key]
    })
    let data = {
      "fields[pageUrl]": window.location.href,
      "fields[comment]": "УПАЛ ФИЛЬТР",
      "fields[error]": error,
      "fields[errorInfo]": JSON.stringify(errorInfo),
      "fields[userInfo]": JSON.stringify(this.props.user.info),
      "fields[userHistory]": JSON.stringify(this.props.user.history),
      "fields[userAgent]": window.navigator.userAgent,
      "fields[filterData]": JSON.stringify(tempFilterData),
      "fields[filter]": JSON.stringify(tempfilter),
    };
    let form = new FormData();
    let keys = _keys(data);
    keys.forEach((key) => {
      form.append(`${key}`, data[key]);
    });

    sendError(form).then((data) => {
      this.setState({ isSended: true });
    });
  }

  render() {
    if (this.state.hasError) {
      return "";
    }

    return this.props.children;
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterError)
);
