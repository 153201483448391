import request from "../api";
import { actionFactory } from './actionUtils';

const requestAssignment = actionFactory("ASSIGNMENT_REQUEST");

const successAssignment = actionFactory("ASSIGNMENT_SUCCESS");

const clearAssignment = actionFactory("ASSIGNMENT_RESET");

const failureAssignment = actionFactory("ASSIGNMENT_FAILURE");
// TO DO убрать такие уёбищные конструкции #рефакторинг
const fetchAssignment = (id, city, savedFilterXmlId, isCount = false) =>
  dispatch => {
    dispatch(requestAssignment());
    return request("panpartner:assignments.ajax", "getAssignment", {
      id,
      city,
      savedFilterXmlId,
      isCount
    })
      .then(
        (data) => {
          const { assignment, bankPrograms, subways, agent, isActive,
            isShowFilter, client, savedFilterSettings } = data;
          const { manager, ...rest } = assignment;
          dispatch(
            successAssignment({
              data: rest,
              manager: manager,
              banks: bankPrograms,
              subways: subways,
              agent,
              isActive,
              isShowFilter,
              client,
              regulationsLink: data?.regulationsLink,
              savedFilterSettings
            }),
          );
          return Promise.resolve(data);
        },
        (error) => {
          dispatch(failureAssignment());
          return Promise.reject(error);
        },
      );
  };
export {
  clearAssignment,
  fetchAssignment
};

