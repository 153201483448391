import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import useDisableCopy from '../../hooks/useDisableCopy';
import PublicSecondary from '../../pages/PublicSecondary';
import Preloader from '../Preloader';
import Header from '../Public/markup/Header';
import PublicHeader from '../Public/PublicHeader';
import List from './List';
import View from './View';

// import "./index.css";

const Public = (props) => {
  const { path } = useRouteMatch();

  useDisableCopy();

  return (
    <div>
      {/* <Helmet>
        <meta property="og:title" content="Подборка ЖК" />
        <meta
          property="og:image"
          content={
            baseUrl + require("../../assets/images/og_image_filters.png")
          }
        />
      </Helmet> */}
      <Suspense fallback={<Preloader loading={true} />}>
        <Switch>
          <Route exact path={`${path}`} component={PublicSecondary} />
          <Route
            path={`/public/secondary/:savedFilterXmlId/:id`}
            component={View}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('state: ', state);
  return {
    agent: state.secondaries.agent || state.secondary.agent,
    isFilterEnabled: state.secondaries.isActive || state.secondary.isActive,
    savedFilterSettings: state.secondaries.savedFilterSettings || state.secondary.savedFilterSettings,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Public);
