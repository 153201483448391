import React, { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import SquareFilter from "../../FilterRealty/SquareFilter";
import RadioDropdown from "../../core/RadioDropdown";
import FloorsFilter from "../../FilterRealty/FloorsFilter";

const PrimaryPublicFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <SelectFilter
      {...createSelectProps("blocks")}
      data={{
        title: "Название ЖК",
        autocompleteTitle: "Введите название ЖК",
        identity: "blocks",
      }}
    />
    <SelectFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
    />
    <SelectFilter
      {...createSelectProps("rooms")}
      data={{
        title: "К-во комнат",
        identity: "rooms",
      }}
      isAutocomplete={false}
    />
    <EndingsFilter
      autoPosition
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass="sf__filter-item"
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}
    />
    <PriceFilter
      autoPosition
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass="sf__filter-item"
      clearFilterOnly={() => clearByIdent("prices")}
      clearExcludeOnly={() => clearByIdent("prices", "exclude")}
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
    />


  </>
});

export default PrimaryPublicFilters;
