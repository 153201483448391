import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../../hooks/useDropdown";
import { searchRuSymbols } from '../../../utils';

const ChecboxItem = (props) => {
  const { identity, id, title, name, checked = false, onChange, disabled = false } = props;

  const handleSelect = (checked) => {
    onChange(checked, id);
  };

  return (
    <div className="checkbox-group__item" style={{ ...(disabled ? { filter: "opacity(0.5)" } : []) }}>
      <div className={"checkbox"}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id={`${identity}-${id}`}
            checked={checked}
            onChange={(e) => handleSelect(e.target.checked)}
            disabled={disabled}
          />
          <label
            className="checkbox__input"
            htmlFor={`${identity}-${id}`}
          ></label>
          <div className="checkbox__marker"></div>
        </div>
        <label className="checkbox__label" htmlFor={`${identity}-${id}`}>
          {title || name}
        </label>
      </div>
    </div>
  );
};

const DropdownMulti = (props) => {
  const { data,
    items,
    value = [],
    onChange,
    right = false,
    isMobile,
    isAutocomplete,
    autocompleteTitle,
    flatBookingTooltip,
    disabledFields = [], 
    wrapperClass= ""
  } = props;
  const [node, open, show, hide] = useDropdown(false);

  const [filterable, setFilterable] = useState(items || []);
  const [search, setSearch] = useState('');

  const selected = _.union(value);
  const searchRef = useRef(null)
  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus();
      searchRef.current.select();
    }
  }, [open])
  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right,
  });

  const handleChange = (checked, ids) => {
    let id = ids.toString();
    let filterTmpValues = [...value];
    if (!checked) {
      filterTmpValues = _.without(filterTmpValues, id);
    } else {
      filterTmpValues.push(id);
    }

    onChange(filterTmpValues);
  };

  useEffect(() => {
    setFilterable(
      search
        ? items.filter((item) => {
          let tmpSearch = search.toLowerCase().replace(/ё/g, 'е');
          let title = item.title
            ? item.title.toLowerCase().replace(/ё/g, 'е')
            : item.name?.toLowerCase()?.replace(/ё/g, 'е');
          if (!title) {
            return;
          }
          let aliasesSearch = null;
          if (item.aliases) {
            aliasesSearch = item.aliases.some(
              (alias) =>
                alias.toLowerCase().includes(tmpSearch.toLowerCase()) ||
                alias
                  .toLowerCase()
                  .includes(searchRuSymbols(tmpSearch.toLowerCase())),
            );
          }
          if (title.includes(tmpSearch) || aliasesSearch) {
            return true;
          } else {
            return searchRuSymbols(title.toLowerCase()).includes(
              searchRuSymbols(tmpSearch.toLowerCase()),
            );
          }
        })
        : items,
    );
  }, [search, items]);

  return (
    <div className={`form__item ${wrapperClass}`}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">
            {data.title}

            {flatBookingTooltip && !selected.length && (
              <div className="fbooking__tooltip">
                {`${flatBookingTooltip}`}
              </div>
            )}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                </div>
              }
              <div className="filter">
                {isAutocomplete && (
                  <div className="filter__filter">
                    <label className="input input_type_default input_width_available ">
                      <input
                        className="input__control"
                        type="text"
                        placeholder={data.autocompleteTitle || autocompleteTitle}
                        onChange={(e) =>
                          setSearch(e.target.value.toLowerCase())
                        }
                        ref={searchRef}
                      />
                    </label>
                  </div>
                )}
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {filterable.map((item) => (
                      <ChecboxItem
                        {...item}
                        identity={data.identity}
                        checked={_.indexOf(selected, item.id.toString()) > -1}
                        onChange={handleChange}
                        key={item.id}
                        disabled={disabledFields.some(field => field.id == item.id)}
                      />
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMulti;
