import { Link } from "react-router-dom";
import { parseDate } from "../../utils";

export const getMotivationColumns = (openRules, openContacts, filterState) => [
    {
        title: 'Застройщик',
        dataIndex: 'title',
        render: (title, item) => {
            return (
                <div className="motivation-table__fst-cell" >
                    {title && <div className="motivation-table__builder-title">{title}</div>}
                    {!!item.termsInteractionExt && <div>
                        <span className="motivation-table__builder-link"
                            onClick={() => openRules({ termsInteractionExt: item.termsInteractionExt })}
                        >Условия взаимодействия
                        </span>
                    </div>}
                    <div>
                        <span className="motivation-table__builder-link"
                            onClick={() => openContacts({ contacts: item.contacts })}
                        >Контакты
                        </span>
                    </div>
                </div>
            )
        }
    },
    {
        title: 'Жилой комплекс',
        dataIndex: 'blocks',
        render: (block) => {
            return {
                children: <table>
                    {
                        block.map((i) => {
                            return (
                                <tr className="motivation-table__twoRow-row" key={block.id}>
                                    <td className="motivation-table__twoRow-cell motivation-table__td motivation-table__block-title">
                                        <Link to={filterState.class === 478 ? '/suburban/spb/' + i.block?.id : `/realty/` + i.block?.city + '/complex/' + i.block?.id} target="_blank">{i.block?.title}</Link>
                                    </td>
                                    <td className="motivation-table__twoRow-cell ">
                                        {i.params.map((j, i) => {
                                            return (
                                                <table key={i}>
                                                    <tr>
                                                        <td className="motivation-table__td-second">{j.objectType}</td>
                                                        <td className="motivation-table__td-second"><b>{j.award}</b></td>
                                                        <td className="motivation-table__td-second">{j.paymentTerms}</td>
                                                        <td className="motivation-table__td-second">{j.comment}</td>
                                                    </tr>
                                                </table>
                                            )
                                        }
                                        )}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>,
                props: {
                    colSpan: 5,
                }
            };
        }
    },
    {
        title: 'Тип объекта',
        dataIndex: 'blocks',
        render: () => {
            return {
                props: {
                    colSpan: 0,
                }
            }
        }
    },
    {
        title: 'Вознаграждение',
        dataIndex: 'blocks',
        render: () => {
            return {
                props: {
                    colSpan: 0,
                }
            }
        }
    },
    {
        title: 'Условия оплаты',
        dataIndex: 'blocks',
        render: () => {
            return {
                props: {
                    colSpan: 0,
                }
            }
        }
    },
    {
        title: 'Комментарий',
        dataIndex: 'blocks',
        render: () => {
            return {
                props: {
                    colSpan: 0,
                }
            }
        }
    },
]

export const months = [
    "Январь", "Февраль", "Март", "Апрель",
    "Май", "Июнь", "Июль", "Август",
    "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
];

export const monthsWithSuffix = ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'];

export const generateMotivationDateRange = (minDate, maxDate) => {
    //вообще лучше бы присылать с бэка в нужном формате всё
    if (!minDate) return {}

    const minMs = new Date(minDate.substr(0, 10)).getTime()
    const maxMs = new Date(maxDate).getTime()

    let currentDate = minMs

    const result = {}

    while (currentDate <= maxMs) {
        const { getValue, year, month } = parseDate(currentDate)
        const currentMonth = `${getValue(month + 1)}`
        result[year] = result[year] ? [...result[year], { id: currentMonth, title: months[month] }] : [{ id: currentMonth, title: months[month] }]
        currentDate = new Date(currentDate).setMonth(month + 1)
    }

    const years = Object.keys(result).map(id => ({ id, title: id }))

    return [result, years]
}

export const dateToNewFormat = (year, month) => `${year}-${month}-01 12:00:00`
