import React, { memo, useState } from "react";
import useMedia from "../../hooks/useMedia";
import { useHistory } from "react-router-dom";
import Table from "../core/Table";
import ShowMore from "../core/Table/ShowMore";
import { expandedRow, getColumns, getMobileColumns } from './Columns';

const Items = memo(({ items, handleDelete, handleSend, loadData, hideLoadMore, showAgents }) => {
  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");
  const [isLoading, setIsLoading] = useState(false)

  const loadMore = () => {
    setIsLoading(true)
    loadData().finally(() => setIsLoading(false))
  }

  const history = useHistory()
  const openDeal = (e) => {
    const id = e.currentTarget.getAttribute("data-row-key")
    history.push("/personal/deals/" + id)
  }
  return (
    <div className="orders__content for-agents">
      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={isMobile ? getMobileColumns(handleDelete, handleSend, showAgents) : getColumns(handleDelete, handleSend, showAgents)}
          expandable={isMobile ? {
            expandedRowRender: record => expandedRow(record, showAgents),
          } : false}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: "ничего не найдено" }}
          customPagination={false}
          isDesktop={isDesktop}
          onRow={() => ({
            onClick: openDeal
          })}
        />
        {!hideLoadMore && <ShowMore onClick={loadMore} loading={isLoading} />}
      </div>
    </div>
  );
});

export default Items;
