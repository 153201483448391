import _keys from 'lodash/keys';
import React, { useEffect, useRef } from 'react';
import ym from 'react-yandex-metrika';
import WOW from 'wow.js';
import { sendError } from '../../actions/forms';
import { isMobile, scrollToRef } from '../../utils';
import './index.scss';


const openLink = (evt)=> {
  evt.preventDefault();
  ym('reachGoal','telegram_bot_connect');
  window.open("https://t.me/PanPartner_ReloadBot", "_blank");
}


const TgBot = () => {
  useEffect(() => {
    if (!isMobile()) {
      try {
        new WOW({
          mobile: false,
        }).init();
      } catch (err) {
        console.error(err)
        let data = {
          'fields[pageUrl]': window.location.href,
          'fields[error]': err,
          'fields[comment]': "ЭТО WOW JS",
          'fields[userAgent]': window.navigator.userAgent,
          'fields[screenWxH]': window.screen.width + 'x' + window.screen.height,
          'fields[windowWxH]': window.innerWidth + 'x' + window.innerHeight,
        };
        let form = new FormData();
        let keys = _keys(data);
        keys.forEach((key) => {
          form.append(`${key}`, data[key]);
        });
        if (window.location.host !== 'localhost:3000') {
          sendError(form);
        }
      }
    }
  });

  const regRef = useRef();

  return (
    <div className="tgBot">

      <section className="promo">

        <div className="container">
          <div className="promo__block block animate__animated animate__fadeIn">
            <h1 className="h1 tgBot__title">Умный бот Сте<span className="highlighted">Пан</span></h1>

            <p className="promo__text big-text">Ваш личный ассистент при работе с порталом <a href="https://panpartner.ru" target="_blank" className="link" rel="noopener noreferrer">panpartner.ru</a><br />Никакого спама, только старты продаж, только помощь при работе с клиентом</p>

            <button type="button" className="tgBtn promo__btn button button_view_default" onClick={() => scrollToRef(regRef, 100)}>Подробнее</button>
          </div>

          <img src={require(`../../assets/images/tgBot/promo-phone.svg`).default} alt=""/>

        </div>
      </section>
      <section className="about" ref={regRef}>
        <div className="container">
          <div className="about__block block wow animate__animated animate__fadeInUpBig">
            <h2 className="h2">Что такое Бот?</h2>

            <p className="about__text big-text">Бот — это некий программный «робот», который взаимодействуя с вами, выполняет заложенные в него функции.</p>

            <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn button button_view_default">Подключиться</button>
          </div>


        </div>
      </section>



        <section className="else">
          <div className="container">
            <h2 className="h1 tgBot__title wow animate__animated animate__fadeInUpBig">Что ещё умеет СтеПАН?</h2>

            <article className="features wow animate__animated animate__fadeInUpBig">
              <span className="features__counter h5">01</span>
              <h3 className="features__title h2">Коммуникация с&nbsp;клиентами</h3>
              <p className="features__text big-text">СтеПАН, это ваш личный помощник в коммуникации с клиентами. Он подскажет, когда клиент посмотрел презентацию, подборку фильтров или забронировал квартиру.</p>
              <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn tgBtn_transparent button button_view_default">Подключиться</button>
              <img src={require(`../../assets/images/tgBot/features-1.1.svg`).default} alt=""/>
              <img src={require(`../../assets/images/tgBot/features-1.2.svg`).default} alt=""/>
            </article>

            <article className="features wow animate__animated animate__fadeInUpBig">
              <span className="features__counter h5">02</span>
              <h3 className="features__title h2">Статус бронирования</h3>
              <p className="features__text big-text">Наш бот тесно взаимодействует с отделом бронирования. Он сразу же сообщит Вам, когда наш менеджер взял в работу Вашу заявку на бронирование квартиры.</p>
              <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn tgBtn_transparent button button_view_default">Подключиться</button>
              <img src={require(`../../assets/images/tgBot/features-2.svg`).default} alt=""/>
            </article>

            <article className="features wow animate__animated animate__fadeInUpBig">
              <span className="features__counter h5">03</span>
              <h3 className="features__title h2">Помогает продавать переуступки</h3>
              <p className="features__text big-text">Продаете переуступки через panpartner?</p>
              <p className="features__text big-text">Тогда вам точно нужно подружится со СтеПАНом. Он сообщит, когда ваш объект добавлен в базу ПАН, а еще пришлет уведомление, если данные по вашему объекту изменились.</p>
              <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn tgBtn_transparent button button_view_default">Подключиться</button>
              <img src={require(`../../assets/images/tgBot/features-3.svg`).default} alt=""/>
            </article>

            <article className="features wow animate__animated animate__fadeInUpBig">
              <span className="features__counter h5">04</span>
              <h3 className="features__title h2">Старты продаж</h3>
              <p className="features__text big-text">СтеПАН ярый ненавистник нежелательных рассылок. Поэтому клянется всеми микросхемами отправлять вам только важную информацию по стартам продаж.</p>
              <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn tgBtn_transparent button button_view_default">Подключиться</button>
              <img src={require(`../../assets/images/tgBot/features-4.svg`).default} alt=""/>
            </article>

          </div>
        </section>

        <section className="connect">
          <div className="container">
            <h2 className="h1 connect__title">Как подключиться к Боту?</h2>
          </div>

          <section className="connect__item option">
            <div className="container">
              <p className="option__counter wow animate__animated animate__fadeInUpBig">
                <span className="option__num">01</span>
                <span className="option__counter-text">вариант</span>
              </p>

              <div className="steps steps_column">
                <div className="steps__item wow animate__animated animate__fadeInUpBig">
                  <div className="steps__header">
                    <span className="steps__step">Шаг 1</span>
                  </div>
                  <div className="steps__row">
                    <p className="steps__text big-text">Для того, чтобы синхронизироваться со СтеПАНом, вы можете перейти по <span onClick={openLink} className="link">этой ссылке</span></p>
                  </div>

                </div>
                <div className="steps__item steps__item_arrow-bg wow animate__animated animate__fadeInUpBig">

                  <div className="steps__row steps__row_align_center">
                    <div className="steps__div">
                      <div className="steps__header">
                        <span className="steps__step">Шаг 2</span>
                      </div>
                      <p className="steps__text steps__text_width big-text">В открывшимся окне нажимаем на кнопку «SEND MESSAGE»</p>

                    </div>
                      <img src={require(`../../assets/images/tgBot/option-1.2.svg`).default} alt=""/>
                  </div>

                </div>
              </div>
            </div>

          </section>


          <section className="connect__item option wow animate__animated animate__fadeInUpBig">
            <div className="container">
              <div className="option__second">
                <div className="option__second-left">
                  <p className="option__counter">
                    <span className="option__num">02</span>
                    <span className="option__counter-text">вариант</span>
                  </p>

                  <div className="option__app big-text">
                    <p>В приложении Telegram, в графе поиска, введите <b className="is-red-text">@PanPartner_ReloadBot</b></p>
                    <p>Выберите «СтеПАН | Умный бот»</p>

                    <p className="option__no-app">Нет приложения?</p>
                    <ul className="option__download">
                      <li><a href="https://apps.apple.com/ru/app/telegram-messenger/id686449807" target="_blank" rel="noopener noreferrer"><img src={require(`../../assets/images/tgBot/appstore.svg`).default} alt=""/></a></li>
                      <li><a href="https://play.google.com/store/apps/details?id=org.telegram.messenger&hl=ru&gl=US" target="_blank" rel="noopener noreferrer"><img src={require(`../../assets/images/tgBot/googleplay.svg`).default} alt=""/></a></li>
                      <li><a href="https://www.microsoft.com/ru-ru/p/telegram-messenger/9wzdncrdzhs0?activetab=pivot:overviewtab" target="_blank" rel="noopener noreferrer"><img src={require(`../../assets/images/tgBot/microsoft.svg`).default} alt=""/></a></li>
                    </ul>
                  </div>
                </div>
                <img className="option__second-img" src={require(`../../assets/images/tgBot/option-2.svg`).default} alt=""/>

              </div>

              <button type="button" onClick={openLink} rel="noopener noreferrer" className="tgBtn connect__btn button button_view_default">Подключиться</button>

            </div>
          </section>

          <section className="connect__item connect__item_last">
            <div className="container">
              <h3 className="h3">После того, как вы нашли бота, пора к нему подключиться.</h3>
              <p className="connect__subtitle big-text">Это не сложно.</p>

              <div className="steps">
                <div className="steps__item wow animate__animated animate__fadeInLeft">
                  <div className="steps__header steps__header_bg-arrow">
                    <span className="steps__step">Шаг 1</span>
                  </div>
                  <p className="steps__text big-text">Для начала нажимам «Старт». Далее нужно подтвердить, что вы наш партнер.</p>
                  <img src={require(`../../assets/images/tgBot/step-1.svg`).default} alt=""/>
                </div>
                <div className="steps__item wow animate__animated animate__fadeInRight">
                  <div className="steps__header">
                    <span className="steps__step">Шаг 2</span>
                  </div>
                  <p className="steps__text steps__text_width big-text">В ответ бот отправит вам ссылку на подключение. Ссылка ведет на портал panpartner. Чтобы перейти, нажимаем на «Подключиться к боту».</p>
                  <img src={require(`../../assets/images/tgBot/step-2.svg`).default} alt=""/>
                </div>
              </div>
              <div className="steps">
                <div className="steps__item wow animate__animated animate__fadeInLeft">
                  <div className="steps__header">
                    <span className="steps__step">Шаг 3</span>
                  </div>
                  <p className="steps__text big-text">После перехода по ссылке синхронизация с ботом будет завершена.</p>
                  <img src={require(`../../assets/images/tgBot/step-3.svg`).default} alt=""/>
                </div>
              </div>
            </div>

          </section>

        </section>

        <section className="banner wow animate__animated animate__fadeIn">
          <div className="container">
            <div className="banner__block">
              <h2 className="banner__title h2">Хотите подключиться к Боту?</h2>
              <button type="button" onClick={openLink} rel="noopener noreferrer" className="banner__btn tgBtn tgBtn_transparent button button_view_default">Подключиться</button>
            </div>
          </div>
        </section>

    </div>
  );
};

export default TgBot;
