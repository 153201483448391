import React, {useEffect, useState} from "react";

const VideoEmbed = ({ url }) => {
  const getIframeSrc = (videoUrl) => {
    if (videoUrl.includes("rutube.ru")) {
      const videoId = videoUrl.match(/video\/([\w\d]+)/)?.[1];
      return videoId ? `https://rutube.ru/play/embed/${videoId}?autoplay=1&mute=1` : null;
    } else if (videoUrl.includes("vkvideo.ru")) {
      const videoId = videoUrl.match(/video-([\w\d_]+)/)?.[1];
      if (videoId) {
        const [oid, id] = videoId.split("_");
        return `https://vk.com/video_ext.php?oid=-${oid}&id=${id}&hash=12345&autoplay=1&mute=1`;
      }
    }
    return null;
  };

  const iframeSrc = getIframeSrc(url);

  if (!iframeSrc) {
    return <p>Платформа видео не поддерживается или ссылка некорректна.</p>;
  }

  return (
    <iframe
      src={iframeSrc}
      width="100%"
      height="100%"
      frameBorder="0"
      title="Video Player"
      allow="clipboard-write; autoplay"
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen
      // autoplay
    ></iframe>
  );
};


export default VideoEmbed

const getVideoPreview = async (url) => {
  if (url.includes("rutube.ru")) {
    try {
      const response = await fetch(url);
      const html = await response.text();

      // Используем регулярное выражение, чтобы найти ссылку на превью
      const thumbnailMatch = html.match(/property="og:image" content="(.*?)"/);

      if (thumbnailMatch && thumbnailMatch[1]) {
        return thumbnailMatch[1]; // Ссылка на превью
      }
    } catch (error) {
      console.error("Ошибка при получении превью Rutube:", error);
    }
  }
  return null; // Если платформа не поддерживается или произошла ошибка
};


export const VideoThumbnail = ({ url }) => {
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const fetchThumbnail = async () => {
      const preview = await getVideoPreview(url);
      setThumbnail(preview);
    };
    fetchThumbnail();
  }, [url]);

  return (
    <div>
      {thumbnail ? (
        <img
          src={thumbnail}
          alt="Video Thumbnail"
          style={{ width: "100%", height: "auto", borderRadius: "8px" }}
        />
      ) : (
        <p>Loading preview...</p>
      )}
    </div>
  );
};
