import CardsList from '../../components/Public/markup/CardsList';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import MapBlock from '../../components/Public/markup/MapBlock';
import MapBtn from '../../components/Public/markup/MapBtn';
import Title from '../../components/Public/markup/Title';
import TopBar from '../../components/Public/markup/TopBar';
import './index.scss';

import SubmitButton from '../../components/SubmitButton';
import useFilter from '../../hooks/useFilter';
import useMedia from '../../hooks/useMedia';
import { getSuffix, media } from '../../utils';

import Dropdown from '../../components/core/Dropdown';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import Preloader from '../../components/Preloader';
import RealtyMap from '../../components/Public/markup/Map';
import { sortlistVillages } from '../../hooks/useFilter/filterSets';
import { extractSuburbansMapItems } from '../../hooks/useFilter/filterUtils';

import { useRef, useState } from 'react';

import { useModal } from 'react-modal-hook';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';


import DraggableZone from '../../components/FilterRealty/Draggable';
import SmartFilter from '../../components/FilterRealty/SmartFilter';
import VillageCardContainer from '../../components/Public/markup/Containers/VillageCardContainer';
import MapFilters from '../../components/Public/markup/MapFilters/Index';
import MobileMapFilter from '../../components/Public/markup/MapFilters/MobileMapFilter';
import VillagesPublicFilter from '../../components/Public/markup/VillagesPublicFilter';
import VillagesTopFilter from '../../components/Public/markup/VillagesTopFilter';
import VillagesFilter from '../../components/Public/markup/VillagesFilter';
import Top from '../../components/Secondaries/Top';
import PrepaidBanner from '../../components/PrepaidBanner';
import { useSelector } from 'react-redux';
import usePermission from '../../hooks/usePermission';
import SendFilter from '../../components/SavedFilters/SendFilter';
import { ReactComponent as SavedFilterIcon } from '../../assets/images/icons/SaveFilter.svg'
import { useParams } from 'react-router-dom';
import {TgDubaiIcon} from "../../components/Public/markup/icons";
import { FilterSearch } from '../../components/FilterRealty/FilterSearch';
import EmptyTable from '../../components/core/EmptyTable';

const SfButton = ({ onClick }) => <div className="villages-save-filter" onClick={onClick}>
  <SavedFilterIcon />
  <span>Сохранить фильтр</span>
</div>
const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}
    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};

const Villages = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  const [clearedSmartFilter, setCleared] = useState(false);
  const clientMode = useSelector(state => state.general.clientMode);
  const banners = useSelector(state => state.banners);
  const isAgent = usePermission('agent');
  const { city } = useParams()
  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      villages = [],
      topVillages,
      filter: filterData = {},
      telegramChannel
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent,
    setFilterState,
    favoritesProps,
    get
  } = useFilter({
    apiConfig: {
      component: 'panpartner:suburbans.ajax',
      getCountFunction: 'getCountVillages',
      submitFunction: 'getVillages',
      getMapItems: 'getVillages',
    },
    defaultFilter: {
      status: [1],
    },
    defaultOrder: 'price',
    defaultBy: 'asc',
    defaultSortId: "0",
    sortList: sortlistVillages,
    sortEnabled: true,
    paginationEnabled: true,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'villages',
    shadowFilters: ['status'],
    extractCount: ({ countVillages }) => countVillages,
    extractLoadMoreCount: ({ countVillages }) => countVillages,
    extractMapItems: ({ villages }) => villages,
    processData: (resp, prev) => {
      if (prev.filter) delete resp.filter;
      return { ...prev, ...resp };
    },
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },

    favoritesParams: {
      city,
      type: 'village',
    },

    fields: {
      villages: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
  });
  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);
// console.log(favoritesProps)
//   mapState && console.log(mapState?.selectedItems)
//   mapItems && console.log(mapItems)
  const saveToLocalSotage = id => localStorage.setItem("suburbansFilter", JSON.stringify({ [id]: filter }))

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <MobileMapFilter
            component={props => <>
              <VillagesTopFilter
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
              <VillagesFilter
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count} объект${getSuffix(
                  count,
                )}`}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
              <button
                className="button button_view_outline for-mobile-filters"
                type='button'
                style={{ margin: "10px 0" }}
                onClick={saveFilterModal}
              >
                Сохранить фильтр
              </button>

            </>}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );

  // умный поиск, копипаста кода Айбека, отрефакторить и вынести как обёртку  или забить болт
  const [showFilter, toggleFilter] = useState(false);

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="suburban"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, exclude, filterData],
  );
  //
  if (isLoading) return <Preloader loading={true} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems}
          extractFeatures={extractSuburbansMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}

        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={VillageCardContainer}
          getLink={(item) => `/old/suburban/spb/${item.id}`}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={VillagesPublicFilter}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          isShowFilter
          onClick={saveToLocalSotage}
          dubai
          {...favoritesProps}
          get={get}
        />
      </div>
    );
  return (
    <div className="sf wrapper">
      <h3 className="h3 sf__h1 with-tg-link">
        <p>Загородная недвижимость</p>
        {
          !isLaptop &&
          <a href={telegramChannel && telegramChannel} target="_blank" className="with-tg-link__link">
            Telegram-канал о загородной недвижимости
            <TgDubaiIcon />
          </a>
        }
      </h3>
      <div className={`sf__filter filter__max form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>
        <FilterSearch
          filterData={filterData}
          config={{
            fields: {
              villages: {
                addLink: ({ id }) => `/old/suburban/spb/${id}`
              },
              builders: {},
              districts: {},
              banks: {},
              subways: {},
            }
          }}

          placeholder="Название КП, застройщик, район"
          toggleFilter={() => toggleFilter(prev => !prev)}
          filterValues={filter}
          excludeValues={exclude}
          handleFilter={setFilterAndExclude}
          hideInfoButton
          submit={submit}
        />
        {!isLaptop && <VillagesTopFilter
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          onSumbit={submit}
          isLoading={isCountLoading || isLoading}
          disableButton={!count}
          clearByIdent={clearByIdent}
          wrapperClass=" "
        />}
        {!isLaptop && <button
          className="button button_type_reveal-filter white-button"
          type="button"
          onClick={() => toggleFilter(!showFilter)}
          // style={{ display: showFilter ? "none" : "block" }}
        >
          <span >Все фильтры</span>
        </button>}
      </div>

      {(isLaptop || (!isTablet && showFilter)) && <div style={{ padding: "0 16px" }}>
        <div className="sf__filter filter__max form">
          {isLaptop && <VillagesTopFilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
          />}
          {!isTablet && showFilter && <VillagesFilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
          />}
        </div>
      </div>}

      <DraggableZone
        filterBadges={filterBadges}
        excludeBadges={excludeBadges}
        clearFilter={clearFilter}
        clearExclude={clearExclude}
        isExcludeEmpty={isExcludeEmpty}
        isFilterEmpty={isFilterEmpty}
        removeBadge={removeBadge}
        switchValues={switchFilterToExclude}
      />
      {isLaptop && !isTablet && (
        <FilterSubmitButton
          onClick={submit}
          isLoading={isCountLoading || isLoading}
          count={count}

        />
      )}
      <div className="sf__row">
        {!isTablet && <SfButton onClick={saveFilterModal} />}
        <div />
        <div className="sf__filter-btns">
          {isTablet && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn
            setShowMap={() => setView('map')}

          />
          {!isTablet && (
            <span style={isLaptop ? {marginLeft: 20} : {}}>
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}

            />
            </span>
          )}
        </div>
      </div>
      {topVillages?.length > 0 && (
        <Top
          isAgent={isAgent}
          items={topVillages}
          clientMode={clientMode}
          title={`Лучшие предложения`}
          isSuburbans={true}
          wrapperStyle={(banners?.assignments || banners?.before) && !clientMode ? { paddingBottom: 0 } : null}
          redisign
        />
      )}
      {banners?.before && <div className="wrapper">
        <PrepaidBanner bannerId="before" banners={banners} />
      </div>}
      {!villages?.length && <EmptyTable title="Ничего не найдено" hideBorder />}
      {!!villages?.length && <div className="sf__row">
        {!isTablet && (
          <div className="special-filter-drop">
            <Dropdown
              innerClass={``}
              value={sortId}
              options={sortlistVillages}
              onChange={handleSort}
            />
          </div>
        )}
        <div />
        <div className="sf__filter-btns">
        </div>
      </div>}
      <CardsList
        items={villages}
        limit={limit}
        isLoading={showSkeleton}
        itemsRef={infiniteRef}
        cardComponent={VillageCardContainer}
        getLink={(item) => `/old/suburban/spb/${item.id}`}
        listClass="sf__cards-list sf__cards-list_subuebans"
        // listClass="sf__cards-list sf__cards-list_dubai-complex-list"
        onClick={saveToLocalSotage}
        {...favoritesProps}
        get={get}
      />
      <div style={{marginTop: '-30px', marginBottom: 30}}><LoadMoreSpinner loading={moreIsLoading} /></div>
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count) && (
            <CountRealty
              itemsCount={count}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Villages;
