import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMedia from "../../../../hooks/useMedia";
import request from '../../../../api';
import Preloader from '../../../Preloader';
import { getSubdomain } from '../../../../utils/location';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import InnerForm from '../InnerForm/InnerForm';
import YaMap from '../../../core/YaMap/YaMap';


const ContactsPage = (props) => {
  const {setFullScreenLoading} = props;
  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const subDomain = getSubdomain();
  //const subDomain = 'agent-5435';
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const agencyData = useSelector(state=>state.agency);

  useEffect(() => {
    request("panpartner:agent.sites.ajax", "getContacts", { subDomain })
    .then(resp => {
      setData(resp?.site || []);
      let agent = resp.site?.agent
      let agency = resp.site?.agency
      let theme = resp.site?.theme
      let socials = resp.site?.socials
      let yandexMetric = resp.site?.yandexMetric
      let phoneAgency = resp.site?.phoneAgency
      let coordinates = resp.site?.coordinates
      dispatch({
        type: "AGENCY_SET_STATE", payload: {
          agent,
          agency,
          socials,
          theme,
          yandexMetric,
          phoneAgency,
          coordinates,
        }
      })
    })
    .finally(() => {
      setIsLoading(false)
      setFullScreenLoading(false)
    })
  }, []);


  if (isLoading) return <Preloader loading={true} />

  const isAgency = !!agencyData.agency.name;
  const isAnyPhone = (agencyData.agency.phone || data.phoneAgency);


  const reviewsTitle = isAgency ? `Контакты агентства ${agencyData.agency.name}` : `Контакты риэлтора ${agencyData.agent.name} ${agencyData.agent.lastName}`;

  const emailCutter = (isDesktop && !isLaptop) ? 16 : 22;

  return (
    <div className="constructor">
      <section className="constructor__section inner">
        <div className="constructor__container">
          <div className="constructor__box">

            <Breadcrumbs currentPage={`Контакты`} />

            <div className="inner__main">
              <header className="inner__header inner__header_no-border">
                <h1 className="h1 inner__title">{reviewsTitle}</h1>
              </header>

              <section className="contacts-list">

                {
                isAnyPhone &&
                <article className="contact">
                  <svg className="contact__icon" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2996 2.51514C20.8684 3.47544 23.6562 6.26325 24.6165 9.83207" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16.2288 6.51318C18.37 7.08902 20.0424 8.76136 20.6182 10.9027" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.64455 13.4814C9.71691 15.6744 11.4961 17.4451 13.6943 18.5088C14.0219 18.664 14.4064 18.6347 14.7064 18.4329L17.9441 16.2727C18.2355 16.0779 18.6045 16.0451 18.9251 16.183L24.9818 18.7778C25.4042 18.9571 25.6576 19.395 25.6024 19.8501C25.2059 22.9483 22.5698 25.2706 19.4458 25.2706C9.73415 25.2706 1.86035 17.3968 1.86035 7.68513C1.85863 4.56285 4.18094 1.92503 7.27909 1.5285C7.73424 1.47333 8.17215 1.72676 8.35145 2.14916L10.9496 8.21097C11.0858 8.52992 11.0548 8.89542 10.8651 9.18507L8.71351 12.4711C8.51524 12.7728 8.48766 13.1556 8.64455 13.4814Z" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>

                  <span className="contact__type text">Телефон</span>
                  {
                    agencyData.agency.phone &&
                    <a className="contact__anchor h3" href={`tel:${agencyData.agency.phone.replace(/[\- \(\)]/gi, "")}`}>{agencyData.agency.phone}</a>
                  }

                  {
                    agencyData.phoneAgency &&
                    <a className="contact__anchor h3" href={`tel:${agencyData.phoneAgency}`}>{agencyData.phoneAgency}</a>
                  }
                </article>

                }


                {
                agencyData.agency.email &&
                <article className="contact">
                  <svg className="contact__icon" width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.9992 22.9097H3.1974C1.68031 22.9097 0.726245 21.6783 1.07015 20.1612L4.58136 4.62986C4.92527 3.11277 6.43125 1.88135 7.95111 1.88135H31.7502C33.27 1.88135 34.2213 3.11277 33.8774 4.62986L30.3662 20.1612C30.0251 21.6783 28.5163 22.9097 26.9992 22.9097Z" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.64905 2.88266L14.0887 13.6825C14.0887 13.6825 16.1494 17.0523 20.1043 13.6825L33.4364 2.74121" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.8019 12.0352L1.1062 21.5759" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22.1124 12.0352L29.5592 21.6563" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M32.7319 10.3555H42.843" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M31.7319 14.4351H37.7874" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>


                  <span className="contact__type text">Почта</span>
                  <a className="contact__anchor h3" href={`mailto:${agencyData.agency.email}`}>
                    {agencyData.agency.email.length > emailCutter ? `${agencyData.agency.email.slice(0, emailCutter)}...` : agencyData.agency.email}

                    {agencyData.agency.email.length > emailCutter && <span className="contact__overlay">{agencyData.agency.email}</span>}
                  </a>
                </article>
                }

                {
                agencyData.agency.address &&
                <article className="contact">
                <svg className="contact__icon" width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.85986 6.27091V25.2706L10.2816 20.5207L19.9064 25.2706L28.3282 20.5207V1.521L19.9064 6.27091L10.2816 1.521L1.85986 6.27091Z" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.2816 1.521V20.5207" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.9064 6.271V25.2707" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                  <span className="contact__type text">Адрес</span>
                  <a className="contact__anchor h3">{agencyData.agency.address}</a>
                </article>
                }

              </section>


              {
              agencyData.socials.length > 0 &&
              <section className="contacts-socials">
                <span className="contacts-socials__header text">Соц.сети</span>
                <div className="contacts-socials__list">
                  {
                    agencyData.socials.map((social) => {

                      const icon = social.type.xmlId == 'vb' ? 'viber' : social.type.xmlId;
                      const link = social.link.replace("https://instagram.com/", "").replace("https://facebook.com/", "").replace("https://vk.com/", "");

                      return (
                        <a href={
                          social.type.xmlId === 'tg'
                          ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                          : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                            : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                              : social.link
                        } className="contacts-socials__item h55" target="_blank">
                        <span className="contacts-socials__icon">
                          <svg className={`icon icon_social-${icon}`}>
                            <use xlinkHref={`#social-${icon}`} />
                          </svg>
                        </span>

                        <span>{link}</span>
                      </a>
                      )
                    })
                  }

                </div>
              </section>
              }

              {
                agencyData?.coordinates && agencyData?.coordinates.length > 0 &&
                <section className="contacts-map">
                  {<YaMap markerColor={agencyData.theme.color} cords={agencyData?.coordinates}  mapCenter={agencyData?.coordinates} />}
                </section>
              }
            </div>

          </div>
        </div>

        <InnerForm />
      </section>
    </div>
  );
};

export default ContactsPage;
