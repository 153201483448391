import React, {useState} from "react";
import './style.scss'
import {
  CabinetClientsIconCompare,
  CabinetClientsIconPresentation,
  CabinetClientsIconSF,
  CabinetClientsIconSite, CabinetCompanyIconAnalytics, CabinetCompanyIconSettings,
  CabinetCrmIconCalendar,
  CabinetCrmIconDeals,
  CabinetCrmIconFixation,
  CabinetCrmIconRecord,
  CabinetFavoritesIconComplexes,
  CabinetFavoritesIconCottageSettment,
  CabinetFavoritesIconObjects, CabinetFinanceIconCard, CabinetFinanceIconLock, CabinetFinanceIconWallet,
  CabinetMenuIconClient,
  CabinetMenuIconCrm,
  CabinetMenuIconCustomers,
  CabinetMenuIconFavorites,
  CabinetMenuIconMoney,
  CabinetMenuIconObjects,
  CabinetMenuIconSecondClient,
  CabinetMenuIconWallet,
  CabinetMortgageIconDocument,
  CabinetMortgageIconPercent,
  CabinetObjectsIconFeed,
  CabinetObjectsIconHouse,
  MenuNavArrowIcon,
  MenuNavPeoplesIcon
} from "../../components/Public/markup/icons";
import {Link} from "react-router-dom";

const PersonalCabinet = () => {

  const [activeMenu, setActiveMenu] = useState(null);

  const menu = {
    crm: {
      title: 'CRM',
      icon: <CabinetMenuIconCrm />,
      items: [
        {
          to: "/personalcabinet/clients",
          title: "Клиенты",
          icon: <MenuNavPeoplesIcon />
        },
        {
          to: "/personalcabinet/fixations",
          title: "Фиксации",
          icon: <CabinetCrmIconFixation />
        },
        {
          to: "",
          title: "Записи на просмотр",
          icon: <CabinetCrmIconRecord />
        },
        {
          to: "",
          title: "Сделки",
          icon: <CabinetCrmIconDeals />
        },
        {
          to: "",
          title: "Календарь",
          icon: <CabinetCrmIconCalendar />
        },
      ]
    },
    clients: {
      title: 'Клиентам',
      icon: <CabinetMenuIconClient />,
      items: [
        {
          to: "",
          title: "Презентации",
          icon: <CabinetClientsIconPresentation />
        },
        {
          to: "",
          title: "Сохраненные фильтры",
          icon: <CabinetClientsIconSF />
        },
        {
          to: "",
          title: "Сравнение",
          icon: <CabinetClientsIconCompare />
        },
        {
          to: "",
          title: "Личный сайт",
          icon: <CabinetClientsIconSite />
        },
      ]
    },
    favorites: {
      title: 'Избранное',
      icon: <CabinetMenuIconFavorites />,
      items: [
        {
          to: "",
          title: "Объекты",
          icon: <CabinetFavoritesIconObjects />
        },
        {
          to: "",
          title: "Комплексы",
          icon: <CabinetFavoritesIconComplexes />
        },
        {
          to: "",
          title: "Поселки",
          icon: <CabinetFavoritesIconCottageSettment />
        },
      ]
    },
    objects: {
      title: 'Объекты',
      icon: <CabinetMenuIconObjects />,
      items: [
        {
          to: "",
          title: "Вторичка",
          icon: <CabinetFavoritesIconComplexes />
        },
        {
          to: "",
          title: "Уступка",
          icon: <CabinetFavoritesIconComplexes />
        },
        {
          to: "",
          title: "Загородка",
          icon: <CabinetObjectsIconHouse />
        },
        {
          to: "",
          title: "Фиды",
          icon: <CabinetObjectsIconFeed />
        },
      ]
    },
    mortgage: {
      title: 'Ипотека',
      icon: <CabinetMenuIconMoney />,
      items: [
        {
          to: "",
          title: "Ипотечные заявки",
          icon: <CabinetMortgageIconPercent />
        },
        {
          to: "",
          title: "Списки документов",
          icon: <CabinetMortgageIconDocument />
        },
      ]
    },
    company: {
      title: 'Компания',
      icon: <CabinetMenuIconCustomers />,
      items: [
        {
          to: "",
          title: "Сотрудники",
          icon: <MenuNavPeoplesIcon />
        },
        {
          to: "",
          title: "Аналитика",
          icon: <CabinetCompanyIconAnalytics />
        },
        {
          to: "",
          title: "Настройки",
          icon: <CabinetCompanyIconSettings />
        },
      ]
    },
    finances: {
      title: 'Финансы',
      icon: <CabinetMenuIconWallet />,
      items: [
        {
          to: "",
          title: "Онлайн оплата",
          icon: <CabinetFinanceIconCard />
        },
        {
          to: "",
          title: "История операций",
          icon: <CabinetFinanceIconWallet />
        },
        {
          to: "",
          title: "Закрытие сделок",
          icon: <CabinetFinanceIconLock />
        },
      ]
    },
    profile: {
      title: 'Профиль',
      icon: <CabinetMenuIconSecondClient />,
      items: [

      ]
    },
  }

  return (
    <>
      <ul className="cabinet-menu">
        {Object.entries(menu).map(([key, content]) => (
          <li
            key={key}
            onMouseEnter={() => setActiveMenu(key)}
            onMouseLeave={() => setActiveMenu(null)}
          >
            <span className="cabinet-menu__icon">{content.icon}</span>
            {React.isValidElement(content) ? (
              content
            ) : (
              <>
                {content.title}
                <BlockMenu content={content} isActive={activeMenu === key} />
              </>
            )}
          </li>
        ))}
      </ul>


    </>
  )
}

export const BlockMenu = ({content}) => {
  return (
    <div className="sidebar menu-nav">
      <p className="sidebar__title">{content.title}</p>
      <nav className="sidebar__list">
        {content.items.map(({ to, title, icon }, index) => (
          <Link to={to} className="menu-nav__item" key={index}>
            <div className="menu-nav__item-text">
              {icon}
              {title}
            </div>
            <div className="menu-nav__item-arrow"><MenuNavArrowIcon/></div>
          </Link>
        ))}
      </nav>
    </div>
  )
}

export default PersonalCabinet
