import request from "../api";
import { actionFactory } from "./actionUtils";

const requestApartment = actionFactory("APARTMENT_REQUEST");

const successApartment = actionFactory("APARTMENT_SUCCESS");

const clearApartment = actionFactory("APARTMENT_RESET");

const failureApartment = actionFactory("APARTMENT_FAILURE");

const fetchApartment = (id, city, savedFilterXmlId) => dispatch => {
  dispatch(requestApartment());
  return request("panpartner:block.detail.ajax", "getApartment", { id, city, savedFilterXmlId })
    .then(
      respData => {
        const { manager, agent, banks, isActive, savedFilterSettings, ...data } = respData;
        dispatch(
          successApartment({
            isActive,
            data,
            manager,
            agent,
            banks,
            savedFilterSettings
          }),
        );
      },
      error => {
        dispatch(failureApartment());
        return Promise.reject(error);
      },
    );
};

export {
  clearApartment,
  fetchApartment
};
