import React from "react";


const SpecialModal = ({ name, specialModal, setSpecialModal, houseName, sendReq }) => {

  return (
    <>
    <div className={`special-modal${specialModal ? ` show` : ``}`}>
      <p className="text special-modal__text">{`${name}, специально для вас, наш специалист готов ответить на все вопросы касательно дома «${houseName}», хотите оставить заявку?`}</p>
      <div className="special-modal__btns">
        <button type="button" className="button button_view_default" onClick={()=>{
          sendReq()
          setSpecialModal(false)
          }}>Да, конечно</button>
        <button type="button" className="button button_view_outline" onClick={() => setSpecialModal(false)}>Позже</button>
      </div>
    </div>

        <div className={`special-modal__body-blur${specialModal ? ` show` : ``}`}></div>
    </>
  );
};


export default SpecialModal;
