import { useState, useCallback } from 'react';

/**
 * Улучшенный хук useState для работы с объектом состояния
 * @param {Object} initialState - Начальное состояние
 * @returns {Array} [state, setValue, setValues, reset]
 */
const useObjectState = (initialState = {}) => {
    const [state, setState] = useState(initialState);

    // Установка одного значения (значение или функция)
    const setValue = useCallback((key, value) => {
        setState((prev) => {
            const newValue = typeof value === 'function' ? value(prev[key]) : value;
            return {
                ...prev,
                [key]: newValue,
            };
        });
    }, []);

    // Установка нескольких значений (объект или функция)
    const setValues = useCallback((updates) => {
        setState((prev) => {
            const updatesObject = typeof updates === 'function' ? updates(prev) : updates;
            return {
                ...prev,
                ...updatesObject,
            };
        });
    }, []);

    // Сброс состояния к начальному
    const reset = useCallback(() => {
        setState(initialState);
    }, [initialState]);

    return { state, setValue, setValues, reset };
};

export default useObjectState;