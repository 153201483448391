import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useParams } from "react-router";
import { Map, YMaps } from 'react-yandex-maps';
import { getFormSettings, sendForm } from '../../../../actions/constructor';
import { sendConstructorEvent, sendVKEvent } from "../../../../utils";
import { getFormWithUTM, getSubdomain, getUrlWithProtocol } from '../../../../utils/location';
import { infoToast, standartErrorToast, successToast } from "../../../toasts";

const InnerForm = (props) => {


  const {isPreview} = props;

  const { handleSubmit, register, errors } = useForm();
  const [callbackForm, setCallbackForm] = useState({});
  const [formSettings, setFormSettings] = useState([]);
  const { id } = useParams();
  const subDomain = getSubdomain();
  //const subDomain = 'agent-5435';

  const resetForm = () => setCallbackForm(prev => {
    const result = {};
    for (const key in prev) {
      result[key] = Array.isArray(prev[key]) ? prev[key] : "";
    }
    return result;
  });

  const getFieldName = (code) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return "";
  };

  const setForm = (value, code, arrayValue) => {
    const tmp = {
      ...callbackForm
    }

    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      const ident = getFieldName(code)
      tmp[ident] = value
    }
    setCallbackForm(prev => ({ ...prev, ...tmp }));
  };

  const submit = (successCallback = () => { }) => {
    let formData = getFormWithUTM();
    let keys = Object.keys(callbackForm);
    if (isPreview) {
      infoToast(<div>В режиме предварительного просмотра отправка форм невозможна</div>)
      return
    }
    keys.forEach((key) => {
      if (Array.isArray(callbackForm[key])) {
        callbackForm[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, callbackForm[key]);
      }
    });
    if (id) {
      const buildingField = getFieldName("building_name")
      formData.append(buildingField, id)
    }
    formData.append("subDomain", subDomain)
    formData.append("title", "Получить консультацию")
    formData.append("url", window.location.href)
    sendForm(34, formData).then(
      () => {
        successToast(<div>Спасибо! Форма успешно отправлена</div>);
        sendConstructorEvent(id ? "tr_buiding_form_send" : "tr_main_form_send", id ? { complexId: id } : null, true);
        sendVKEvent('conversion');
        resetForm();
      },

      standartErrorToast
    );
  };

  useEffect(() => {
    getFormSettings(34, "spb", subDomain)
      .then(
        resp => setFormSettings(resp.questions),
        standartErrorToast
      )
  }, []);

  useEffect(() => {
    if (!formSettings) return
    const defaultPolicyValue = formSettings.find(setting => setting.code === "policy")?.items?.[0]?.value
    setForm([defaultPolicyValue], "policy")
  }, [formSettings]);


  return (

    <section className="inner-form">
      <div className="constructor__container">
        <div className="constructor__box">   
          <form action="#" className="inner-form__form">
            <h3 className="h3 inner-form__title">
              Еще не выбрали своего риэлтора? Сделайте первый шаг!
            </h3>

            <div className="inner-form__block">
              <div className="inner-form__row">
                <div className="inner-form__input">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-partnership-firstName"
                  >

                    <input
                      id="modal-partnership-firstName"
                      name="name"
                      className={
                        "input__control text" + (errors.name ? " input__error" : "")
                      }
                      ref={register({
                        required: "Введите имя",
                      })}
                      placeholder="Ваше имя"
                      value={callbackForm[getFieldName("name")]}
                      onChange={e => setForm(e.target.value, "name")}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="inner-form__input">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-partnership-name"
                  >

                    <InputMask
                      id="modal-partnership-name"
                      className={
                        "input__control text" + (errors.phone ? " input__error" : "")
                      }
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                      name="phone"
                      inputRef={register({
                        required: "Введите телефон",
                      })}
                      placeholder="Ваш телефон"
                      value={callbackForm[getFieldName("phone")]}
                      onChange={e => setForm(e.target.value, "phone")}
                    />
                    {errors.phone && (
                      <span className="input__error-label">
                        {errors.phone.message}
                      </span>
                    )}
                  </label>
                </div>
                <button className="button constructor__btn inner-form__submit button-blick" type="button" onClick={() => submit()}>
                  Получить консультацию
                </button>
              </div>


              {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
                return (
                  <div className="inner-form__row" key={`consultation-item-${i}`}>
                    <div className="inner-form__checkbox checkbox checkbox_view_invert">
                      <div
                        className={
                          "checkbox__box inner-form__checkbox-box" +
                          (errors.agreement ? " checkbox__box-error" : "")
                        }
                      >
                        <input
                          className="checkbox__control"
                          type="checkbox"
                          id="modal-partnership-agreement"
                          name="agreement"
                          ref={register({
                            required: true,
                          })}
                          value={item.value}
                          onChange={(e) => setForm(e.target.checked, item?.fieldName, e.target.value)}
                          checked={!!callbackForm?.[item?.fieldName]?.includes(item.value)}
                        />
                        <label
                          className="checkbox__input"
                          htmlFor="modal-partnership-agreement"
                        ></label>
                        <div className="checkbox__marker">
                          <svg className="checkbox__marker-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="20"><path d="M0 4.765l.855-.8 4.19 2.272L12.267 0 13 .665 5.36 10 0 4.765z"/></svg>
                        </div>
                      </div>
                      <label
                        className="checkbox__label text"
                        htmlFor="modal-partnership-agreement"
                      >
                        Отправляя эту форму, Вы соглашаетесь с <a className="inner-form__agreement link" href="https://toprealtors.ru/policy" target="_blank">политикой конфиденциальности</a>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </form>
        </div>
      </div>

    </section>
  );
};

export default InnerForm;
