import axios from "axios";
import React, { useEffect, useMemo, useState } from 'react';
import InputMask from "react-input-mask";
import { connect } from 'react-redux';
import request from '../../api';
import DropdownGrouped from "../../components/core/DropdownGrouped";
import Preloader from "../../components/Preloader";
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast } from "../../components/toasts";
import { formatMoney } from "../../utils";
import './index.scss';

const Payment = (props) => {

  const { userInfo = { name: "", phone: "", lastName: "", email: "" } } = props;

  const errors = false;

  const [services, setServices] = useState(null)
  services && console.log(services)
  const [values, setValues] = useState({
    ...userInfo
  })
  const [isLoading, setIsLoading] = useState(false)
  const setForm = (value, ident) => setValues(prev => ({ ...prev, [ident]: value }))
  const [selectedServices, setSelectedServices] = useState([])

  useEffect(() => {
    request("panpartner:b24.ajax", "getServices").then(data => setServices(data.services))
  }, [])

  const changeServices = (checked, id, serviceId) => {
    if (!checked) {
      setSelectedServices(prev => prev.filter(serv => serv.id != id))
      return
    }

    const serviceById = services.find(serv => serv.id == serviceId)
    const subItemById = serviceById.items.find(item => item.id == id)
    setSelectedServices(prev => ([...prev, { ...subItemById, serviceId }]))

  }
  const totalPrice = useMemo(() => {
    if (selectedServices.length === 0) return 0
    return selectedServices.reduce((prev, current) => prev + Number(current.price), 0)
  }, [selectedServices])


  const payRequest = () => {
    if (!totalPrice) return errorToast("Выберите услугу")
    setIsLoading(true);
    let windowReference = window.open();
    request("panpartner:payment.ajax", "getOrderId").then(data => {
      return axios({
        method: 'post',
        'Content-Type': 'application/json',
        url: "https://securepay.tinkoff.ru/v2/Init",
        data: {
          TerminalKey: "1629446085622", //required
          Amount: totalPrice * 100, //сумма в копейках
          OrderId: data?.orderId, //Номер заказа required
          Name: `${values.name} ${values.lastName}`, //ФИО плательщика
          Email: values.email,
          Phone: values?.phone,
          Receipt: {
            Email: values.email,
            Phone: values?.phone,
            Taxation: "usn_income_outcome",
            Items: selectedServices.map(serv => ({
              Name: serv.name,
              Amount: serv.price * 100,
              Quantity: 1,
              Price: serv.price * 100,
              Tax: "none"
            }))
          }
        }
      }).then(resp => {

        if (resp.data.Success) {
          return Promise.resolve(resp.data)
        }
        return Promise.reject(resp.data.Message)
      }).then((data => windowReference.location = data.PaymentURL), errorToast)
    }, standartErrorToast).finally(() => setIsLoading(false))
  }

  if (!services) return <Preloader loading={true} />
  return (
    <section className="section section_view_white payment">

      <div className="payment__content">
        <h1 className="h1 payment__h1">Оплата online</h1>
        <div>
          <div className="payment__container">
            <div className="payment__left">
              <h4 className="h4 payment__h3">Здесь вы можете оплатить услуги компании ПАН Партнер</h4>
              <p className="text payment__text">Выберите услугу, заполните форму и оплатите, информация об оплате будет выслана вам на почту.</p>
              <img src={require('../../assets/images/payment.svg').default} alt="" className="payment__img" />
            </div>

            <div className="payment__form">
              <div className="modal__grey-wrapper">
                <form action="#" className="modal-form modal-form_width_470">
                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form" htmlFor="form-name">
                        <span className="input__label">Ваше имя</span>
                        <input
                          onChange={(e) => setForm(e.target.value, "name")}
                          name="name"
                          id="form-name"
                          className="input__control"
                          value={values.name}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            Введите имя
                          </span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form" htmlFor="form-surname">
                        <span className="input__label">Ваша фамилия</span>
                        <input
                          onChange={(e) => setForm(e.target.value, "lastName")}
                          name="surname"
                          id="form-surname"
                          className="input__control"
                          value={values.lastName}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            Введите фамилию
                          </span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-phone"
                      >
                        <span className="input__label">Телефон</span>
                        <InputMask
                          onChange={(e) => setForm(e.target.value, "phone")}
                          className={
                            'input__control'
                          }
                          name="phone"
                          placeholder="Введите телефон"
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar=" "
                          value={values.phone}
                        />
                        {errors.phone && (
                          <span className="input__error-label">
                            {errors.phone?.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form">
                        <span className="input__label">E-mail</span>
                        <input
                          name="email"
                          className={
                            "input__control" + (errors.email ? " input__error" : "")
                          }
                          onChange={(e) => setForm(e.target.value, "email")}
                          value={values.email}
                        />
                        {errors.email && (
                          <span className="input__error-label">
                            {errors.email?.message}
                          </span>
                        )}

                      </label>
                    </div>
                  </div>

                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-name"
                      >
                        <span className="input__label">Выберите услугу</span>
                        <DropdownGrouped
                          options={services}
                          data={{
                            title: "Услуги"
                          }}
                          value={selectedServices}
                          selectGroupKey="serviceId"
                          selectSubItemKey="id"
                          onChange={changeServices}
                          redTitle
                        />
                      </label>
                    </div>
                  </div>

                  {!!totalPrice && <div className="modal-form__row modal-form__row_mt_30">
                    <div className="modal-form__item">
                      <span className="payment__price-text">Стоимость услуг*</span>
                      <p className="payment__price h3">{formatMoney(totalPrice)} р.</p>
                    </div>
                    <div className="modal-form__item payment__submit">
                      <SubmitButton type="button" className="button button_view_default" onClick={payRequest} isLoading={isLoading}>Оплатить</SubmitButton>
                    </div>
                  </div>}

                  <p className="payment__sign">* При оплате взимается комиссия до 3,5%, в указанную сумму комиссия не включена</p>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
