import React, { memo, useState } from 'react';
import { formatDate } from '../../utils';
import SubmitButton from '../SubmitButton';


const UnsubscribeForm = memo((props) => {
	const { date, time, name, handleSubmit, hideDate } = props;
	const [comment, setComment] = useState('');

	const onSubmit = () => {
		handleSubmit(comment);
	};

	return (
		<>
			<h2 className="modal__title">Отмена записи</h2>
			<div className="modal-form">
				<div className="modal-form__wrapper">
					<div className="modal-form__row">
						<div className="modal-form__gist">
							<div className="modal-form__row">
								<div className="lk-events__eventCancel-label">Мероприятие:</div>
								<div className="lk-events__eventCancel-info">
									<div className="lk-events__eventCancel-name">{name}</div>
									{!hideDate && <div className="lk-events__date">
										<span>
											{formatDate(date, {
												day: 'numeric',
												month: 'numeric',
												year: 'numeric',
											})}
										</span>
										<div className="lk-events__time">{time}</div>
									</div>}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-form__row">
						<div className="modal-form__item">
							<div className="lk-events__eventCancel-reason">
								<label className="textarea textarea_width_available textarea_type_form">
									<span className="textarea__label">Причина отмены</span>
									<textarea
										className="textarea__control"
										value={comment}
										onChange={(e) => setComment(e.target.value)}
									></textarea>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-form__submit">
					<SubmitButton
						className="button button_view_default"
						onClick={onSubmit}
					>
						<span>Отменить</span>
					</SubmitButton>
				</div>
			</div>
		</>
	);
});

export default UnsubscribeForm;
