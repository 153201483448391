import _ from "lodash";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import useMedia from '../../hooks/useMedia';
import { validateMail } from '../../utils';
import Dropdown from "../core/Dropdown";
import { infoToast, standartErrorToast, successToast } from "../toasts";

const phoneRequired = (v) =>
  v && v.indexOf("_") === -1 ? undefined : "Введите телефон";

const Input = (props) => {
  const { name, inputRef, value, ...rest } = props;
  return (
    <InputMask {...rest} mask="+7 (999) 999-99-99" maskChar="_">
      {(inputProps) => (
        <input value={value} name={name} ref={inputRef} {...inputProps} />
      )}
    </InputMask>
  );
};

const Form = (props) => {
  const {
    add,
    history,
    defaultValues,
    groups,
    edit = false,
    checkEmail,
  } = props;
  
  const isMobile = useMedia("isMobile")

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: defaultValues,
  });
  
  const onSubmit = (values) => {
    let form = new FormData();
    let keys = _.keys(values);

    keys.forEach((item) => {
      let identity = item === "role" ? "rights" : item;
      if (Array.isArray(values[item])) {
        values[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, values[item]);
      }
    });
    form.append("city", "spb")
    add(form).then(
      data => {
        edit ?
          infoToast(
            <div className="Toastify__text">
              Данные сотрудника{" "}
              <strong>
                {values.lastName} {values.name}
              </strong>{" "}
          обновлены
        </div>
          ) :
          successToast(
            <div>
              Сотрудник{" "}
              <strong>
                {values.lastName} {values.name}
              </strong>{" "}
            успешно добавлен
          </div>
          )
        history.push(`/personal/agents`);
      }, standartErrorToast
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "phone",
  });

  const {
    fields: fieldsEmail,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  return (
    <section className="section section_view_white clients for-agents">
      <div className="wrapper">
        <div className="personal_content">
          <div className="personal__clients">
            <div className="clients__header">
              <div className="clients__title">
                {isMobile &&
                  <Link to={`/personal/agents`}>
                    <button className="button button_view_outline-with-icon js-go-back clients__back-btn is-small">
                      <svg className="icon icon_back">
                        <use xlinkHref="#back"></use>
                      </svg>
                    </button>
                  </Link>
                }
                <div className="h1">
                  {edit && (isMobile ? 'Редактирование' : `Редактирование сотрудника`)}
                  {!edit && 'Добавить сотрудника'}
                </div>
              </div>
            </div>
            <div className="clients__grid">
              {!isMobile &&
                <div className="clients__grid-col clients__back">
                  <Link to={`/personal/agents`}>
                    <button className="button button_view_outline-with-icon js-go-back clients__back-btn">
                      <svg className="icon icon_back">
                        <use xlinkHref="#back"></use>
                      </svg>
                      <span>Вернуться</span>
                    </button>
                  </Link>
                </div>
              }
              <div className="clients__grid-col clients__new">
                <form
                  className="clients__form js-form-address"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {edit && <input type="hidden" name="id" ref={register} />}
                  <div className="lk-form__row">
                    <div className={`lk-form__row-aside ${!isMobile ? 'padding_bottom_0' : ''}`}>
                      <div className="switch">
                        <div className="switch__box">
                          <input
                            className="switch__control"
                            type="checkbox"
                            id="agent-active"
                            name="active"
                            ref={register}
                          />
                          <label
                            className="switch__input"
                            for="agent-active"
                          ></label>
                          <div className="switch__marker"></div>
                        </div>
                        <label className="switch__label" for="agent-active">
                          Активен
                        </label>
                      </div>
                    </div>
                    {isMobile && <div className={`lk-form__row-content ${isMobile ? 'is-gray' : ''}`}>
                      <div className="lk-form__fr lk-form__fr_full">
                        <div className="radio-group radio-group_type_form">
                          <div className="radio-group__label">
                            Уровень доступа:
                          </div>
                          <div className="radio-group__list radio-group__list_dir_horizontal">
                          <Controller
                                as={Dropdown}
                                name="role"
                                control={control}
                                options={groups}
                                ref={register}
                                onChange={([value]) => {
                                  return value;
                                }}
                              /> 
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                 {!isMobile && <div className="lk-form__row">
                  
                    <div className="lk-form__row-title">Уровень доступа</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr select select_type_form">
                        <Controller
                          as={Dropdown}
                          name="role"
                          control={control}
                          options={groups}
                          ref={register}
                          onChange={([value]) => {
                            return value;
                          }}
                          wrapperClass="input__control"
                        />
                      </div>
                    </div>
                  </div>}
                  <div className="lk-form__row">
                    <div className="lk-form__row-title">Об агенте</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-sec-name"
                        >
                          <span className="input__label">{isMobile ? 'Фамилия агента' : 'Фамилия:'}</span>
                          <input
                            name="lastName"
                            className={
                              "input__control" +
                              (errors.lastName ? " input__error" : "")
                            }
                            id="cl-sec-name"
                            ref={register({
                              required: "Введите фамилию",
                            })}
                            autocomplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-name"
                        >
                          <span className="input__label">{isMobile ? 'Имя агента:' : 'Имя:'}</span>
                          <input
                            name="name"
                            className={
                              "input__control" +
                              (errors.name ? " input__error" : "")
                            }
                            ref={register({
                              required: "Введите имя",
                            })}
                            id="cl-name"
                            autocomplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="cl-f-name"
                        >
                          <span className="input__label">{isMobile ? 'Отчество агента:' : 'Отчество:'}</span>
                          <input
                            name="patronymic"
                            className="input__control"
                            id="cl-f-name"
                            autocomplete="off"
                            ref={register}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__row is-gray">
                    <div className="lk-form__row-title">Контактные данные</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr">
                        {fields.map((item, index) => (
                          <label
                            className="input input_type_form input_width_available"
                            htmlFor={`cl-phone${index}`}
                            key={`phone${index}`}
                          >
                            <span className="input__label">Телефон:</span>

                            <Input
                              name={`phone[${index}]`}
                              className={
                                "input__control" +
                                (errors.phone && errors.phone[index]
                                  ? " input__error"
                                  : "")
                              }
                              type="text"
                              id={`cl-phone${item.id}`}
                              inputRef={register({
                                required: "Введите телефон",
                                validate: { inputTelRequired: phoneRequired },
                              })}
                              defaultValue={item.value}
                              key={item.id}
                              mask="+7 (999) 999-99-99"
                            />

                            {index === 0 ? (
                              <span className="input__alert info">
                                <svg className="icon icon_alert">
                                  <use xlinkHref="#alert"></use>
                                </svg>
                                <div className="info__popup">
                                  <p>
                                    <strong>Приоритетный номер</strong>
                                  </p>
                                  <p>
                                    При заполненни формы обратной связи, этот
                                    номер будет автоматически подставляться в
                                    форму.
                                  </p>
                                </div>
                              </span>
                            ) : (
                              <button
                                className="input__remove"
                                onClick={(e) => {
                                  remove(index);
                                  e.preventDefault();
                                }}
                              >
                                <svg
                                  className="icon"
                                  width="7"
                                  height="8"
                                  viewBox="0 0 7 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.66667 1.3L0.333333 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                  <path
                                    d="M0.333333 1.3L5.66667 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                </svg>
                              </button>
                            )}
                          </label>
                        ))}
                        <div className="lk-form__add-param">
                          <button
                            className="button button_type_add"
                            onClick={(e) => {
                              e.preventDefault();
                              append("");
                            }}
                          ></button>
                          <span>Добавить ещё телефон</span>
                        </div>
                      </div>
                      <div className="lk-form__fr">
                        {fieldsEmail.map((item, index) => (
                          <>
                            <label
                              className="input input_type_form input_width_available"
                              htmlFor={`cl-email${index}`}
                            >
                              <span className="input__label">E-mail:</span>
                              <input
                                name={`email[${index}]`}
                                className={
                                  "input__control" +
                                  (errors.email && errors.email[index]
                                    ? " input__error"
                                    : "")
                                }
                                type="email"
                                id={`cl-email${index}`}
                                autocomplete="off"
                                ref={register({
                                  required: "Введите e-mail",
                                  pattern: {
                                    value: validateMail,
                                    message: "Неправильный формат e-mail",
                                  },
                                  validate: async (value) => {
                                    if (
                                      index > 0 ||
                                      value === defaultValues.email[0]
                                    )
                                      return true;
                                    let result = checkEmail(value);
                                    const freeEmail = await result;
                                    return (
                                      freeEmail ||
                                      "Пользователь уже зарегистрирован. Свяжитесь с менеджером отдела развития."
                                    );
                                  },
                                })}
                              />
                              {index === 0 ? (
                                <span className="input__alert info">
                                  <svg className="icon icon_alert">
                                    <use xlinkHref="#alert"></use>
                                  </svg>
                                  <div className="info__popup">
                                    <p>
                                      <strong>Приоритетный e-mail</strong>
                                    </p>
                                    <p>
                                      При заполненни формы обратной связи, этот
                                      e-mail будет автоматически подставляться в
                                      форму.
                                    </p>
                                  </div>
                                </span>
                              ) : (
                                <button
                                  className="input__remove"
                                  onClick={(e) => {
                                    removeEmail(index);
                                    e.preventDefault();
                                  }}
                                >
                                  <svg
                                    className="icon"
                                    width="7"
                                    height="8"
                                    viewBox="0 0 7 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.66667 1.3L0.333333 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                    <path
                                      d="M0.333333 1.3L5.66667 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                  </svg>
                                </button>
                              )}
                            </label>
                            {index === 0 &&
                              errors.email &&
                              errors.email[index] && (
                                <span className="input__error-label">
                                  {errors.email[index].message}
                                </span>
                              )}
                          </>
                        ))}
                        <div className="lk-form__add-param">
                          <button
                            className="button button_type_add"
                            onClick={(e) => {
                              e.preventDefault();
                              appendEmail("");
                            }}
                          ></button>
                          <span>Добавить ещё e-mail</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__row">
                    <div className="lk-form__row-title">
                      Комментарий к&nbsp;агенту
                    </div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_auto no-padding">
                        <label
                          className="textarea textarea_type_form textarea_width_available"
                          for="cl-comment"
                        >
                          <span className="textarea__label">
                            Текст комментария:
                          </span>
                          <textarea
                            className="textarea__control"
                            id="cl-comment"
                            name="comment"
                            ref={register}
                            rows="5"
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__submit">
                    <div className="lk-form__btns">
                      <Link to={`/personal/agents`}>
                        <button className="button button_view_outline-with-icon js-go-back clients__back-btn">
                          <svg className="icon icon_back">
                            <use xlinkHref="#back"></use>
                          </svg>
                          <span>Вернуться</span>
                        </button>
                      </Link>
                      <button
                        className="button button_view_default"
                        type="submit"
                      >
                        {edit ? `Сохранить` : `Добавить сотрудника`}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
