import minBy from 'lodash/minBy';
import request from '../api';
import { defaultFullFilterSuburbans } from '../utils/defaultFilters';
import { actionFactory } from './actionUtils';

export const getSuburbans = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getVillages', form).then(
    data => {
      let tmpSF = { ...defaultFullFilterSuburbans }
      if (data.savedFilter) {
        tmpSF.filter = { ...tmpSF.filter, ...data.savedFilter.filter }
      }
      dispatch({
        type: "GET_SUBURBANS_SUCCESS",
        items: data.villages,
        topVillages: data.topVillages,
        countVillages: data.countVillages,
        countVillagesToShow: data.countVillages,
        ...(data.agent ? { agent: data.agent } : null),
        ...(data.savedFilter ? { filter: tmpSF } : {}),
        ...(data.filter ? { filterData: data.filter } : []),
        isActive: data.isActive,
        isShowFilter: data.isShowFilter
      })
      return Promise.resolve(data);
    },
  );

export const getSuburbansFilter = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getVillagesFilter', form).then(
    data => {
      dispatch({ type: "GET_SUBURBANS_FILTER_SUCCESS", data })
      return Promise.resolve(data);
    },
  );

export const getSuburbanFilter = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getSuburbansFilter', form).then(
    (data) => {
      dispatch({ type: "GET_SUBURBAN_FILTER_SUCCESS", data })
      return Promise.resolve(data);
    },
  );

export const getCount = (form, type, dispatch) => {
  const method = type === "suburbans" ? "getCountVillages" : "getCountSuburbans"
  const action = type === "suburbans" ? "GET_COUNT_VILLAGES" : "GET_COUNT_LANDS"
  const valueTypes = {
    "GET_COUNT_VILLAGES": "countVillages",
    "GET_COUNT_LANDS": "count",
  }
  return request('panpartner:suburbans.ajax', method, form).then(
    data => {
      dispatch({
        type: action,
        [valueTypes[action]]: data[[valueTypes[action]]],
        countVillagesToShow: data[[valueTypes[action]]]
      })
      return Promise.resolve(data);
    },
  );
};

export const getCountForFilter = (city, params, dispatch, type = "suburbans") => {
  params.city = city
  getCount(params, type, dispatch)
};

export const setFilterSuburbans = (filter) => ({
  type: 'SUBURBANS_SET_FILTER',
  filter,
});

export const setView = (view) => ({
  type: 'SUBURBANS_SET_VIEW',
  view,
});

export const clearFilterSuburbans = actionFactory("SUBURBANS_CLEAR_FILTER");

export const clearIncludesSuburbans = actionFactory("SUBURBANS_CLEAR_INCLUDES");

export const clearExcludesSuburbans = actionFactory("SUBURBANS_CLEAR_EXCLUDES");

export const getVillageLands = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getSuburbans', form).then(
    data => {
      dispatch({
        type: "GET_VILLAGE_LANDS_SUCCESS",
        suburbans: data.suburbans,
      })
      return Promise.resolve(data);
    },
  );

export const getVillage = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getVillage', form).then(
    data => {
      const tmpVillage = { ...data.village }
      const minByBrice = minBy(data.village.suburbansInfo, "minPrice")
      const types = data.village.suburbansInfo.map((item, i, arr) => i === arr.length - 1 ? item.type.title : `${item.type.title}, `)
      tmpVillage.suburbansInfo = {
        suburbansByType: data.village.suburbansInfo,
        minPrice: minByBrice.minPrice,
        countSuburbans: data.village.suburbansInfo.reduce((acc, current) => acc + current.count, 0),
        types
      }
      dispatch({
        type: "GET_VILLAGE_SUCCESS",
        suburbans: data.suburbans,
        villages: data.villages,
        village: tmpVillage,
        ...(data.agent ? { agent: data.agent } : null),
        ...(data.filter ? { filterData: data.filter } : []),
        isActive: data.isActive,
        otherVillages: data.otherVillages,
        savedFilterSettings: data.savedFilterSettings
      })
      return Promise.resolve(data);
    },
  );

export const getLand = (form, dispatch) =>
  request('panpartner:suburbans.ajax', 'getSuburban', form).then(
    data => {
      dispatch({
        type: "GET_LAND_SUCCESS",
        suburban: data.suburban,
        client: data.client,
        ...(data.agent ? { agent: data.agent } : null),
        isActive: data.isActive
      })
      return Promise.resolve(data);
    },
  );

export const getSpecialTypes = () => request('panpartner:suburban.special.offers.ajax', 'getTypes', {});

export const getSpecialOffers = (id) => request('panpartner:suburban.special.offers.ajax', 'getSpecialOffers', { 'filter[typeId]': id });

export const sendRequest = (form = {}) => request('panpartner:suburban.special.offers.ajax', 'sendRequest', form);
