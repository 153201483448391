import React from 'react';

import { useModal } from 'react-modal-hook';
import Modal from '../core/Modal';
import ConsultationRequest from '../forms/ConsultationRequest';

const BannerThin = () => {
  const [openModal, closeModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeModal} classes="modal_small get-consultation">
        <ConsultationRequest />
      </Modal>
    ),
  );
  return (
    <div className="preview__banner preview__banner_thin">
      <div className="preview__banner-content">
        <div className="preview__banner-body">
          <div className="preview__banner-title h3">
            Оставьте заявку прямо сейчас
          </div>
          <div className="preview__banner-text">
            Оптимизируйте работу с клиентами и зарабатывайте больше уже сегодня
          </div>
        </div>
        <div className="preview__banner-btn">
          <button className="button button_view_default" onClick={openModal}>
            Получить консультацию
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerThin;
