import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getUrl } from '../../api';
import useUniversalForm from "../../hooks/useUniversalForm";
import "../News/index.scss";
import NewsShare from '../News/Share.js';
import { standartErrorToast, standartSuccessToast } from "../toasts";

const Actions = props => {

  const { actionId } = useParams();

  const [isParticipating, setIsParticipating] = useState(null);

  const user = useSelector(state => state.user)

  const {
    isLoading,
    response,
    send,
  } = useUniversalForm({
    requestOptions: {
      c: "panpartner:actions.ajax",
      action: "getAction",
      id: actionId,
      isOneResult: 1
    },
    defaultValues: {
      actionId,
      phone: user?.info?.phone,
    }
  });

  const onSend = () => send({ actionName: response?.action?.name }, { formId: response?.form?.id })
    .then(standartSuccessToast, standartErrorToast)
    .then(() => setIsParticipating(true));

  return (
    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper sor sor_cashback">
       {false && <NewsShare description={response?.action?.name} />}
        <div className="news__container container news__container_smena">
          <h1 className="h1 news__title">
            {response?.action?.name}
          </h1>
          <img
            src={getUrl(response?.action?.previewPicture?.src)}
            alt=""
            className="news__promo-img"
          />
          <div dangerouslySetInnerHTML={{ __html: response?.action?.detailText }} />
          {!user?.isAuthorized && !!response?.form?.id && <span>
            Чтобы принять участие в акции, <Link
              target="_blank"
              className="link"
              to={"/login"}
            >
              войдите
            </Link> или <Link
              target="_blank"
              className="link"
              to={"/register"}
            >
              зарегестрируйтесь
            </Link>
          </span>}
          {user?.isAuthorized && !!response?.form?.id && <button
            type="button"
            className="news__btn news__btn_left news__btn_mt-0 button button_view_default"
            onClick={onSend}
            disabled={isLoading || isParticipating || response.isExistResult}
          >
            {isParticipating || response.isExistResult ? `Уже участвуете` : `Участвовать`}
          </button>}
        </div>
      </div>
    </section>
  )
};

export default Actions;
