import React from 'react';
import BackToTop from '../BackToTop';
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer';


const HomeLayout = ({children}) => {
  return (
    <>
      <div className="content">
        <main className={`hp`}>
          <Header />
          {children}
          <Footer />
        </main>
      </div>
      
      <BackToTop />
    </>
  );
};
export default HomeLayout;