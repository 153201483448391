import { useMediaQuery } from 'beautiful-react-hooks';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { media } from '../../../utils';
import {useParams} from "react-router-dom";

const SearchResult = (props) => {
  const {
    results,
    lastWord,
    isVisible,
    selected,
    searchResultHandler,
    excludeHandler,
    defaultCity,
  } = props;

  const {city = defaultCity} = useParams()

  const getItemByType = (identity, title, color, id, xmlId) => {
    switch (identity) {
      case 'subways':
        return (
          <>
            <span
              className="complex__metro-color"
              style={{ backgroundColor: color }}
            ></span>
            <span>ст. м. {title}</span>{' '}
          </>
        );
      case 'blocks':
        return (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 19.3929H21" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.2858 19.3929V5.25C13.2858 5.07951 13.218 4.91599 13.0975 4.79544C12.9769 4.67488 12.8134 4.60715 12.6429 4.60715H4.92862C4.75813 4.60715 4.59461 4.67488 4.47405 4.79544C4.3535 4.91599 4.28577 5.07951 4.28577 5.25V19.3929" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.7143 19.3929V10.3929C19.7143 10.2224 19.6466 10.0588 19.526 9.93829C19.4055 9.81773 19.242 9.75 19.0715 9.75H13.2858" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.85718 7.82144H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.14282 12.9643H10.7142" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.85718 16.1786H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.8572 16.1786H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.8572 12.9643H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div style={{ paddingLeft: '10px' }}>ЖК {title}</div>
            <a
              href={xmlId && city === "dxb" ? `/realty/dxb/complex/${xmlId}` : `/realty/${city}/complex/${id}${window.location.pathname.includes("commercial") ? "?type=commercial" : ""}`}
              target="_blank"
              className="exclude"
              style={{ ...(smallScreen ? { visibility: "visible" } : []), marginLeft: 20, visibility: "visible", order: 1 }}
              rel="noreferrer"
            >
              Перейти
            </a>
          </>
        );
      case 'villages':
        return (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 19.3929H21" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.2858 19.3929V5.25C13.2858 5.07951 13.218 4.91599 13.0975 4.79544C12.9769 4.67488 12.8134 4.60715 12.6429 4.60715H4.92862C4.75813 4.60715 4.59461 4.67488 4.47405 4.79544C4.3535 4.91599 4.28577 5.07951 4.28577 5.25V19.3929" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.7143 19.3929V10.3929C19.7143 10.2224 19.6466 10.0588 19.526 9.93829C19.4055 9.81773 19.242 9.75 19.0715 9.75H13.2858" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.85718 7.82144H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.14282 12.9643H10.7142" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.85718 16.1786H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.8572 16.1786H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.8572 12.9643H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div style={{ paddingLeft: '10px' }}>{title}</div>
            <a
              href={id && city === "spb" && `/suburban/spb/${id}`}
              target="_blank"
              className="exclude"
              style={{ ...(smallScreen ? { visibility: "visible" } : []), marginLeft: 20, visibility: "visible", order: 1 }}
              rel="noreferrer"
            >
              Перейти
            </a>
          </>
        );
      case 'districts':
      case 'address':
        return (
          <>
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 0C2.89865 0 0 2.89865 0 6.5C0 7.29054 0.175676 8.08108 0.439189 8.78378C0.439189 8.78378 1.93243 12.6486 6.58784 17.6554C6.58784 17.6554 10.8041 13.7905 12.7365 8.78378C12.7365 8.78378 13.0878 7.55405 13.0878 6.5C13 2.89865 10.1014 0 6.5 0ZM6.5 9.39865C5.00676 9.39865 3.77703 8.16892 3.77703 6.67568C3.77703 5.18243 5.00676 3.9527 6.5 3.9527C7.99324 3.9527 9.22297 5.18243 9.22297 6.67568C9.31081 8.16892 7.99324 9.39865 6.5 9.39865Z"
                fill="#8F95AC"
              />
            </svg>

            <div style={{ paddingLeft: '10px' }}>{title}</div>
          </>
        );
      case 'builders':
        return (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.69116 7.14795L18.2567 7.14795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M6.0885 11.3795L18.2568 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M12.8405 7.19943L10.4424 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M17.1945 7.19943L14.7964 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M8.48666 7.19943L6.0885 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M17.2658 14.1646L6.73421 14.1646L5 9.1289L6.73421 4L17.2658 4L19 9.1289L17.2658 14.1646Z" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
              <path d="M11.5033 14.8849C11.5033 14.8849 11.5638 16.1442 10.7346 16.9849C9.90547 17.8256 9.35388 18.4165 9.35388 19.5053C9.35388 20.861 10.715 21.4158 11.489 21.4158C12.263 21.4158 13.7078 20.9903 13.7078 19.1624" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/>
            </svg>
            <div style={{ paddingLeft: '10px' }}>{title}</div>
          </>
        );
      case 'banks':
        return (
          <>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.98877 12.1716C1.98877 9.68574 2.23736 5.62549 2.23736 5.62549H3.8946C3.8946 5.62549 4.14319 9.68574 4.14319 12.1716H1.98877Z"
                fill="#8F95AC"
              />
              <path
                d="M6.62891 12.1716C6.62891 9.68574 6.87749 5.62549 6.87749 5.62549H8.53474C8.53474 5.62549 8.78333 9.68574 8.78333 12.1716H6.62891Z"
                fill="#8F95AC"
              />
              <path
                d="M11.2695 12.1716C11.2695 9.68574 11.5181 5.62549 11.5181 5.62549H13.1754C13.1754 5.62549 13.424 9.68574 13.424 12.1716H11.2695Z"
                fill="#8F95AC"
              />
              <path
                d="M15.0809 14.9889H0.248535V13.5802C0.248535 13.1659 0.579984 12.7516 1.07716 12.7516H14.2523C14.6666 12.7516 15.0809 13.083 15.0809 13.5802V14.9889Z"
                fill="#8F95AC"
              />
              <path
                d="M15.2467 3.63673C15.1638 3.63673 15.1638 3.55387 15.1638 3.55387L8.03764 0.0736554C7.70619 -0.0920692 7.37474 0.0736554 7.37474 0.0736554L0.248587 3.55387C0.0828623 3.63673 0 3.7196 0 3.88532V4.63108C0 4.87967 0.165725 5.04539 0.414311 5.04539H14.9981C15.2467 5.04539 15.4124 4.87967 15.4124 4.63108V3.88532C15.4124 3.80246 15.3295 3.7196 15.2467 3.63673Z"
                fill="#8F95AC"
              />
            </svg>
            <div style={{ paddingLeft: '10px' }}>{title}</div>
          </>
        );

      default:
        return title;
    }
  };
  const smallScreen = useMediaQuery('(max-width: 1280px)');
  return (
    <SimpleBar
      // className={`smart-serach-result ${props.dubai ? 'smart-serach-result_dubai' : ''}`}
      className={`smart-serach-result ${props.redesignResult ? 'smart-serach-result_redesign' : ''}`}
      // style={props.dubai ? {
      style={props.redesignResult ? {
          display: isVisible ? 'block' : 'none',
          maxHeight: 200,
          overflow: 'hidden auto',
        }:
        {
        left: media('isMobile') ? 0 : props.left + 42,
        display: isVisible ? 'block' : 'none',
        minWidth: media('isMobile') ? '100%' : 350,
        maxHeight: 200,
        overflow: 'hidden auto',
      }}
    >
      <ul>
        {results.map((type) => {
          return Object.keys(type).map((identity) => {
            return type[identity].map((item, i) => {
              const { id, title, color, xmlId } = item;
              return (
                <li
                  className={`smart-filter-result ${
                    selected && selected.id == id ? 'selected' : ''
                  }`}
                  key={id}
                  onClick={() => {
                    searchResultHandler(
                      title,
                      id,
                      identity,
                      lastWord,
                      'filter',
                    );
                  }}
                >
                  {getItemByType(identity, title, color, id, xmlId)}
                  <span
                    className="exclude"
                    onClick={(e) => {
                      e.stopPropagation();
                      excludeHandler({ identity, id, title });
                    }}
                    style={{...(smallScreen? {visibility: "visible"}: [])}}
                  >
                    Исключить
                  </span>
                </li>
              );
            });
          });
        })}
      </ul>
    </SimpleBar>
  );
};
export default SearchResult;
