import React, { memo } from "react";

const FavoritesSwitcher = memo(props => {
  const { setTab, currentTab, cityLen } = props;
  return (
    <section className="section section_view_white no-header-profile">
      <div className="wrapper">
        <div className="favorites__header">
          <div className="favorites__title">
            <div className="h1">Избранное</div>
          </div>
          {cityLen > 0 && (
            <div className="favorites__switcher switcher">
              <div
                className={`switcher__btn ${currentTab === "block" ? "active" : ""}`}
                onClick={() => setTab("block")}
              >
                Жилые комплексы
              </div>
              <div
                className={`switcher__btn ${currentTab === "apartment" ? "active" : ""}`}
                onClick={() => setTab("apartment")}
              >
                Квартиры
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});
export default FavoritesSwitcher;
