import React, { useRef, useState } from 'react';
import { useModal } from "react-modal-hook";
import { getUrl } from '../../../api';
import Modal from "../../core/Modal";
const Review = props => {
    const { handleImageUpload, setReview, review, removeReview } = props
    const { fio, text, photo, fakeId } = review
    const fakeInputRef = useRef(null)
    const [image, setPicture] = useState(getUrl(photo?.src))
    const setImage = e => {
        const fileObject = e.target.files[0]
        handleImageUpload(fileObject)
        const reader = new FileReader();
        reader.onload = (e) => setPicture(e.target.result)
        reader.readAsDataURL(fileObject);
    }
    const handleChange = (value, ident, fakeId) => {
        const tmp = { ...review }
        tmp[ident] = value
        setReview(fakeId, tmp)
    }
    const delPhoto = () => {
        handleImageUpload(false)
        setPicture("")
    }
    const [openPhotoUpload, closePhotoUpload] = useModal(({ in: open, onExited }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal">

            <button type="button"
                className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                aria-label="Закрыть окно"
                onClick={closePhotoUpload}
            >
                <span className="c-modal__icon">
                    <svg className="icon icon_cross">
                        <use xlinkHref="#cross" />
                    </svg>
                </span>
            </button>
            <div className="c-modal__box">
                <p className="c-modal__header">Загрузить фото менеджера</p>
                <div className="c-modal__content upload">
                    <div className="upload__photo">
                        {image && <img src={image} style={{ borderRadius: "50%" }} />}
                    </div>
                    <div className="upload__info">
                        <input type="file" style={{ display: 'none' }} onChange={setImage} ref={fakeInputRef} />
                        <button type="button" className="upload__btn button" onClick={() => fakeInputRef.current.click()}>Загрузить фото</button>
                        {photo && <p className="upload__result c-modal__upload-result">
                            <span className="c-modal__upload-text">{photo.name}</span>
                            <button onClick={delPhoto} type="button" className="button c-modal__icon-btn c-modal__icon-btn_with-hover" aria-label="Удалить фото">
                                <span className="c-modal__icon c-modal__icon_sm">
                                    <svg className="icon icon_cross">
                                        <use xlinkHref="#cross" />
                                    </svg>
                                </span>
                            </button>
                        </p>}

                        <p className="upload__descr">
                            Можно загрузить картинку в формате png, jpg и gif.
                            Размеры не меньше 200 × 200 точек, объём файла не больше 7 МБ.
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    ), [image, photo]);

    return (
        <>
            <div className="admin__input-row" >
                <div className="admin__input-block">
                    <label htmlFor="" className="admin__label">Имя и фамилия</label>
                    <input type="text" className="admin__input" value={fio} onChange={e => handleChange(e.target.value, "fio")} />
                </div>
                {/* <div className="admin__input-block">
                    <div className="admin__upload-block admin__upload-block_inside">
                        {!image && <img src={require('../../../assets/images/constructor/photo-upload.svg').default} alt="" className="admin__upload-img" />}
                        {image && <img src={image} alt="" className="admin__upload-img" />}
                        <div className="admin__upload">
                            <input type="file" style={{ display: 'none' }} onChange={setImage} ref={fakeInputRef} />
                            <button
                                type="button"
                                className="button admin__upload-label admin__anchor"
                                onClick={openPhotoUpload}
                            >
                                Загрузить фото
                            </button>
                            {photo && <p className="admin__upload-result">
                                <span className="admin__upload-text">{photo.name}</span>
                                <button onClick={delPhoto} type="button" className="button admin__icon-btn admin__icon-btn_with-hover" aria-label="Удалить фото">
                                    <span className="admin__icon admin__icon_sm">
                                        <svg className="icon icon_cross">
                                            <use xlinkHref="#cross" />
                                        </svg>
                                    </span>
                                </button>
                            </p>}
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="admin__input-block" style={{ borderBottom: "1px solid rgba(143, 149, 172, 0.2)" }}>
                <label htmlFor="" className="admin__label">Текст отзыва</label>
                <textarea
                    className="admin__textarea"
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    value={review.review}
                    onChange={e => handleChange(e.target.value, "review")}
                ></textarea>
                <button type="button" className="button admin__remove-review"
                    onClick={() => removeReview(fakeId)}
                >Удалить отзыв</button>
            </div>
        </>)
}
const Reviews = props => {
    const { isActive, setIsActive, handleImageUpload, revs, addReview, setReview, removeReview, widgetReviews, changeWidget } = props
    return (
        <div className={`admin__reviews admin__section admin__section_sm${isActive ? `` : ` admin__section_pb-20`}`}>
            <div className="admin__title">
                <label className="switch" htmlFor="reviews-work">
                    <div className="switch__box">
                        <input className="switch__control" type="checkbox" id="reviews-work"
                            checked={isActive}
                            onChange={setIsActive}
                        />
                        <div className="switch__input" htmlFor="reviews-work" />
                        <div className="switch__marker" />
                    </div>
                </label>
                <h5 className="admin__h5 h5">
                    Отзывы
          </h5>
                <div className="info">
                    <svg className="icon icon_info">
                        <use xlinkHref="#info" />
                    </svg>
                    <div className="info__popup">
                        <p>Вывод отзывов на сайте</p>
                    </div>
                </div>
            </div>

            {isActive && revs.map((review, i) => <Review handleImageUpload={(photo) => handleImageUpload(review.id, photo)} review={review} key={`review-${i}`} setReview={(fakeId, val) => setReview(fakeId, val)} removeReview={removeReview} />)}

            {isActive && <button type="button" className="button admin__add-btn" onClick={addReview} >+ Добавить отзыв</button>}
            {isActive && <div className="admin__input-block" style={{ marginTop: 30 }}>
                <label htmlFor="" className="admin__label">
                    Код виджета
                    <div className="info">
                        <svg className="icon icon_info">
                            <use xlinkHref="#info" />
                        </svg>
                        <div className="info__popup">
                            <p>Виджет позволит вам транслировать отзывы о Вас или вашей компании прямо на сайт</p>
                        </div>
                    </div>
                </label>
                <textarea
                    className="admin__textarea"
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    value={widgetReviews}
                    onChange={changeWidget}
                ></textarea>
                {/* <span class="admin__input-notice" style={{ color: "#2C2E3E" }}>Как подключить код виджета </span> */}
            </div>}
        </div>
    )
}

export default Reviews
