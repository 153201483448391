import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AgentsList from "./List";
import AgentsAdd from "./Add";
import AgentsEdit from "./Edit";
import  './index.css';
const Agents = props => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={AgentsList} />
      <Route exact path={`${path}/add`} component={AgentsAdd} />
      <Route exact path={`${path}/edit/:id`} component={AgentsEdit} />
    </Switch>
  );
};

export default Agents;
