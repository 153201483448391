import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../../api';
import Preloader from '../../../../components/Preloader';
import { runScriptsFromHTML } from "../../../../utils";
import { getSubdomain } from '../../../../utils/location';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import InnerForm from '../InnerForm/InnerForm';

const ReviewsPage = (props) => {
  const {setFullScreenLoading} = props;
  const subDomain = getSubdomain();
  //const subDomain = 'agent-5435';
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const agencyData = useSelector(state=>state.agency);

  const [customHtml, setCustomHtml] = useState(null)

  
  useEffect(() => {
    request("panpartner:agent.sites.ajax", "getReviews", { subDomain })
    .then(resp => {
      setData(resp?.site?.reviews || [])
      let agency = resp.site?.agency
      let agent = resp.site?.agent
      let socials = resp.site?.socials
      let theme = resp.site?.theme
      let yandexMetric = resp.site?.yandexMetric
      let isReviewsActive = resp.site?.blocks[7].active
      let widgetReviews = resp.site?.widgetReviews
      dispatch({
        type: "AGENCY_SET_STATE", payload: {
          agent,
          agency,
          socials,
          theme,
          yandexMetric,
          isReviewsActive,
          widgetReviews
        }
      })
    })
    .finally(() => {
      setIsLoading(false)
      setFullScreenLoading(false)
    })
  }, []);
  

  useEffect(() => {
    if (agencyData.widgetReviews){
      try {
        setCustomHtml(runScriptsFromHTML(agencyData.widgetReviews))
      } catch (error) {
        
      }
    } 
  }, [agencyData]);

  if (isLoading) return <Preloader loading={true} />
  
  const reviewsTitle = agencyData.agency.name ? `Отзывы агентства ${agencyData.agency.name}` : `Отзывы о риэлторе ${agencyData.agent.name} ${agencyData.agent.lastName}`;

  return (
    <div className="constructor">
      <section className="constructor__section inner">
        <div className="constructor__container">
          <div className="constructor__box">

            <Breadcrumbs currentPage={`Отзывы`} />
            {
            // (Отзывы или виджет) и показ вкл
            ((data.length > 0 || !!customHtml) && agencyData.isReviewsActive) ?
            (
            <div className="inner__main">
              <header className="inner__header">
                <h1 className="h1 inner__title">{reviewsTitle}</h1>
                <p className="big-text inner__text">Рекомендуем посмотреть отзывы людей, которые уже воспользовались нашими услугами. Убедитесь сами, что качественный сервис среди риэлторов — не миф, а приятная реальность!</p>
              </header>

              <section className="reviews-list">
                {
                data.map((item) => {
                  return (
                    <article className="review">
                      <div className="review-container">
                        <span className="review-author big-text">{item.fio}</span>
                        <p className="review-text text"
                            dangerouslySetInnerHTML={{
                              __html: item?.review?.replace(/\n/g, "<br />"),
                            }}
                        ></p>
                      </div>
                    </article>
                  );
                })
                }

                {!!customHtml && <div style={{width: "100%", marginTop: !!data.length ? 40 : 0 }} dangerouslySetInnerHTML={{__html: customHtml}} />}

              </section>
            </div>
            ) :

            (
            <div className="inner__main">
              <header className="inner__header inner__header_no-border">
                <h1 className="h1 inner__title">Отзывы еще не добавлены</h1>
                <p className="big-text inner__text text-center">Вернитесь сюда попозже, отзывы обязательно появятся в ближайшее время...</p>
              </header>
              <div className="reviews-list reviews-list_mt-0">
                <div className="inner__main-color-bg">
                  <img src={require('../../../../assets/images/constructor/reviews-placeholder.png').default} alt="" />
                </div>
              </div>
            </div>
            )

            }

          </div>
        </div>

        <InnerForm />
      </section>
    </div>
  );
};

export default ReviewsPage;