import React, {useEffect, useMemo, useReducer, useRef, useState} from "react";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import Preloader from "../../components/Preloader";
import RealtyMap from "../../components/Public/markup/Map";
import { clusterTemplateMarkupNew, iconTemplateMarkupNew } from "../../components/Public/markup/Map/mapUtils";
import TopBar from "../../components/Public/markup/TopBar";
import { Binoculars } from "../../components/Public/markup/icons";
import Modal from "../../components/core/Modal";
import PanModal from "../../components/core/PanModal";
import useApi from "../../hooks/useApi";
import { extractFavoritesMapItems, realtyMapReducer } from "../../hooks/useFilter/filterUtils";
import useMedia from "../../hooks/useMedia";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import PdfSettingsModal from "./PdfSettingsModal";

import InputMask from "react-input-mask";
import cluster_point_new from '../../assets/images/cluster_point_new.svg';
import map_point_new from '../../assets/images/map_point_new.svg';
import MapBlock from "../../components/Public/markup/MapBlock";
import SubmitButton from "../../components/SubmitButton";
import Attention from "../../components/core/Attention";
import { errorToast, standartErrorToast, standartSuccessToast } from "../../components/toasts";
import { toFormData } from "../../utils";
import { onImgError } from "../../utils/special";
import { useYaMap } from "../../hooks/useYaMap";
import { YMaps, Map, Placemark, RulerControl } from 'react-yandex-maps';
import Wrap from "../../components/core/ModalWrap";
import MobileModal from "../../components/core/MobileModal";
import { YMInitializer } from "react-yandex-metrika";
import { getUrl } from "../../api";
import useUniversalForm from "../../hooks/useUniversalForm";
import {PhotoPopup} from "../../components/Viewer/PhotoSlider";
import {PhotoSwipe, PhotoSwipeGallery} from "react-photoswipe";
import FilterDisabled from "../../components/Public/FilterDisabled";
import mappic from '../../../src/assets/images/mappic.png'
import Slider from "../../components/Public/markup/detail/Slider";
import ApartmentMap from "../../components/ApartmentMap";
import ErrorPlug from "../../components/ErrorBoundry/ErrorPlug";

const colorByStatus = {
  green: ' label_green',
  gray: ' label_red',
  red: ' label_red'
}
const MapCard = props => {
  const {
    id,
    title,
    note,
    displayNote,
    ending,
    photo,
    types,
    minPrice,
    status,
    address,
    builderTitle,
    onMouseMove,
    showBlockTitle,
    showBuilderTitle,
    showButtonGetPdf,
    showContacts,
    showDynamicPrice,
    showPaymentGraphic,
    showPrice,
  } = props

  return <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      borderRadius: "20px",
      marginBottom: 18,
      backgroundColor: "#F6F7F8"
    }}
    onMouseMove={() => onMouseMove?.(id)}
  >
    <img
      style={{
        width: "100%",
        borderRadius: "20px",
        height: 142,
        objectFit: "contain",
        background: "white",
      }}
      src={getUrl(photo?.src)}
      onError={onImgError}
      alt={"img"}
    />

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "14px",
        paddingTop: "10px"
      }}
    >
      <div className="favorites-list__item-right" style={{ marginTop: 20, marginBottom: 15 }}>
        <ul className="favorites-list__item-tags">
          {!!status && <li className={`label-item ${colorByStatus[status.color] ?? ''}`}>
            {status.name}
          </li>}
          {types && types.map(v => <li key={v}>{v}</li>)}
        </ul>
      </div>
      <div className="favorites-list__item-title h4" style={{ display: 'flex', alignItems: 'center' }}>
        {minPrice}
      </div>
      <div className="favorites-list__item-price">{title}</div>
      <div className="favorites-list__item-address">{builderTitle ?? address}</div>
      <div className="favorites-list__item-deadline">{ending}</div>
      {displayNote.xmlId === 'all' && !!note && <div
        style={{
          backgroundColor: "#ECEDF0",
          padding: "16px 10px",
          overflowWrap: "anywhere",
          borderRadius: 5
        }}
      >
        <b>Заметка:</b>
        {note}
      </div>}
    </div>
  </div>
}

const PublicCompilation = () => {


  const [openGallery, setOpenGallery] = useState(false);
  const [imageIndex, setImageIndex] = useState(1)
  const [itemsGallery, setItemsGallery] = useState([])
console.log(itemsGallery)
  const [viewFromWindow, setViewFromWindow] = useState(false)

  const { xmlId } = useParams();

  const { data: { items = [], compilation, agent = {}, emptyCompilation }, isLoading, errors } = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilation", { id: xmlId, withCoordinates: "Y" }],
  }) 
  const { form, send: sendCallback, setValue: setCallbackValue, getValue, isSending } = useUniversalForm({
    formId: 68,
    defaultValues: {
      "link": getUrl(`/public/compilation/${xmlId}`)
    },
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic", 
  })
  const {
    state: {
      pdfItem,
      view,
      showMapBlock,
      formStatus,
      policy,
      selectedOnMap
    },
    setValue,
    setValues,
  } = useUniversalReducer({
    pdfItem: {},
    view: "cards",
    selectedOnMap: false,

    showMapBlock: "true",
    formStatus: "",
    policy: true,
  
  });

  const showHintRef = useRef(null);
  const [mapState, dispatchMap] = useReducer(realtyMapReducer, {
    zoom: 9,
    mapCenter: [59.9342802, 30.3350986],
  });
  const callbackHandler = () => {
    sendCallback(false, {}, {
      phone: "Необходимо ввести номер телефона",
       name: "Необходимо ввести имя"
    }).then(resp => {
      setValues({
        "formStatus": resp.message,
      })
      standartSuccessToast(resp)
    },
      standartErrorToast)
  }
  
  useEffect(() => {
    if (isLoading) return
    return () => {
      if (window[`yaCounter${compilation.yandexMetric}`]) {
        window[`yaCounter${compilation.yandexMetric}`]?.destruct()
      }
    }
  }, [isLoading])
 
  // 99.9% что del: 
  // const [showPdfModal, hidePdfModal] = useModal(() => (
  //   <PanModal isBig title={'Настроить PDF'} closeModal={closePdfModal}>
  //     <PdfSettingsModal item={pdfItem} />
  //   </PanModal>
  // ), [pdfItem]);

  
  // const openPdfModal = item => {
  //   setValue("pdfItem", item)
  //   showPdfModal()
  // }
  // const closePdfModal = (params) => {
  //   setValue("pdfItem", {})
  //   hidePdfModal()
  // }
  // delend
  const isTablet = useMedia('isTablet');
  const isSmallLaptop = useMedia('isSmallLaptop');
  const isMobile = useMedia('isMobile'); // *что-то не то

  const [showMapModal, hideMapModal] = useModal(() => (
    <Wrap wrapper={children => isMobile
      ? <MobileModal closeClick={hideMapModal} title="Объект на карте">{children}</MobileModal>
      : <PanModal isBig closeModal={hideMapModal} title="Объект на карте">{children}</PanModal>
    } >
      <div className="sf-map"><div className="complex__map-wrap">
        {selectedOnMap && <YMaps>
          <Map
            defaultState={{
              center: selectedOnMap,
              zoom: 16,
            }}
            className="complex__map-wrap"
          >
            <Placemark
              geometry={selectedOnMap}
              options={{
                iconLayout: "default#image",
                iconImageHref: "/assets/images/place.png",
                iconImageSize: [25, 40],
                iconImageOffset: [-25, -20],
              }}
            />
            <RulerControl
              options={{ position: { bottom: 50, right: 10 } }}
            />
          </Map>
        </YMaps>}
      </div>
      </div>
    </Wrap >
  ), [selectedOnMap]);

  const showOnMap = ({ coordinates, builderTitle, address }) => {
    const title = builderTitle ?? address
    setValue("selectedOnMap", coordinates)
    showMapModal()
  }

  const {phone: agentPhone, workCompany, name: agentName, lastName, logo} = agent;
  const [openMobileModal, hideMobileModal] = useModal(({ }) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_mobilecompilationsmodal" closeByPastClick close={hideMobileModal}>
      <div className="compilation-public-mobile-form">
        <div className="h4">
          <span>Остались вопросы</span>
          <span onClick={hideMobileModal}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L12 12L17 7" stroke="#2C2E3E" stroke-linecap="round"/><path d="M7 17L12 12L17 17" stroke="#2C2E3E" stroke-linecap="round"/></svg>
            </span>
        </div>
        <p>Оставьте заявку на обратный звонок</p>
        <br/>
        <div className="hp__form-item" style={{width: '100%'}}>
          <label className="input input_width_available input_type_form">
            <input
              className={'input__control'}
              style={{  boxShadow: 'none' }}
              placeholder="Ваше имя"
              onChange={e => setCallbackValue(e.target.value, "name")}
              value={getValue("name")}
            />
          </label>
        </div>

        <div className="hp__form-item" style={{width: '100%'}}>
          <label className="input input_width_available input_type_form">
            <InputMask
              style={{ boxShadow: 'none' }}
              placeholder="Введите номер телефона"
              className={'input__control'}
              mask="+7 (999) 999-99-99"
              autoComplete="off"
              maskChar=" "
              name="phone"
              onChange={e => setCallbackValue(e.target.value, "phone")}
              value={getValue("phone")}
            />
          </label>
        </div>

        <div className="checkbox checkbox_view_red fixations__checkboxs" style={{marginTop: 10, marginBottom: 10}}>
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="data-id"
              name="remember"
              checked={policy}
              onChange={e=>setValue("policy", e.target.checked)}
            />
            <label className="checkbox__input" htmlFor="data-id"/>
            <div className="checkbox__marker"/>
          </div>
          <label className="checkbox__label" htmlFor="data-id" style={{marginBottom: -3}}>
            Отправляя заявку вы соглашаетесь на обработку
            персональных
            данных
          </label>
        </div>

        {!formStatus && <div style={{marginTop: 15}}>
          <SubmitButton
            className="btn h6 btn_primary btn_full"
            isLoading={isSending}
            type="button"
            onClick={callbackHandler}
          >
            Отправить заявку
          </SubmitButton>
        </div>}
        {!!formStatus && <Attention text="Ваша заявка отправлена" style={{marginTop: 10}}/>}
      </div>
    </Modal>
  ), [isSending, form, policy, formStatus]);


  const [mobDynamic, setMobDynamic] = useState(false)
  const setView = view => setValue("view", view)

  const addMap = (state) => {
    setItemsGallery([...state, { html: React.createElement(<ApartmentMap {...viewFromWindow} />) }]);
  }
  const [jsx, setJsx] = useState(null)

  const contentByIndex = useMemo(() => {
    if (!viewFromWindow || !itemsGallery) return {}
    return {
      [itemsGallery.length]: <div style={{ width: "100%", height: "100vh" }}>
        <ApartmentMap  {...viewFromWindow} />
      </div>
    }
  }, [viewFromWindow, itemsGallery])

  if (isLoading) return <Preloader loading />;
  const {
    showBlockTitle,
    showBuilderTitle,
    showButtonGetPdf,
    showContacts,
    showDynamicPrice,
    showPaymentGraphic,
    showPrice,
  } = compilation?.settings || {}
  if(errors?.[0]) return <FilterDisabled text={errors?.[0]?.message}/>
  if (view === 'map')
  return (
    <div className="sf sf_map">
      <RealtyMap
        dispatch={dispatchMap}
        mapCenter={[59.9342802, 30.3350986]}
        {...mapState}
        items={items}
        extractFeatures={extractFavoritesMapItems}
        isLoading={isLoading}
        innerCbRef={showHintRef}
        onObjectClick={() => setValue("showMapBlock", true)}
        clusterPointTemplate={clusterTemplateMarkupNew}
        clusterPointIcon={cluster_point_new}
        pointTemplateFn={iconTemplateMarkupNew}
        mapPointIcon={map_point_new}
      />
      <div className="sf__map-top">
        <TopBar setShowMap={setView} />
      </div>
      <MapBlock
        items={
          mapState?.selectedItems?.length ? mapState?.selectedItems : items
        }
        cardComponent={MapCard} 
        getLink={v => v.link}
        isLoading={isLoading}
        showFilters={false}
        onMouseMove={(id) => showHintRef.current(id)}
        showBlock={showMapBlock}
        setShowBlock={val=>setValue("showMapBlock", val)}
        isShowFilter={false}
        {...compilation?.settings || {}}
      />
    </div>
  );
  const openSlider = (index, item) => {
    setImageIndex(index)
    setOpenGallery(true)
    if (item.viewFromWindow) {
      setViewFromWindow(item.viewFromWindow)
      return setItemsGallery([...item.photos, { src: "map" }])
    }
    setItemsGallery(item.photos)
  }
  return (
    <>
      <div className="compilation-public-header">
        {isMobile && showContacts &&
        <div className="wrapper">
          <div className="compilation-public-top">
            <div className="compilation-public-top__left">
                {logo?.src && <img
                  src={getUrl(logo?.src)}
                  style={{
                    height: 65,
                    marginRight: 16
                  }}
                  alt="logo"
                />}
              <div>
                <div className="compilation-public-form__user" style={{marginBottom: 0}}>{lastName} {agentName}</div>
                <div className="compilation-public-form__firm" style={{marginTop: 0, marginBottom: 0}}>{workCompany}</div>
              </div>

            </div>

            <div onClick={openMobileModal}>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9014 5.01251C16.0834 5.32993 17.1612 5.95258 18.0266 6.81801C18.892 7.68344 19.5147 8.76122 19.8321 9.94325" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.1787 7.7038C14.8893 7.89343 15.5373 8.26711 16.0573 8.78714C16.5773 9.30717 16.951 9.95516 17.1406 10.6657" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.07415 12.3999C9.79088 13.8806 10.988 15.0749 12.4705 15.7881C12.5799 15.84 12.701 15.8625 12.8218 15.8533C12.9426 15.8442 13.0589 15.8037 13.1593 15.7359L15.3372 14.2816C15.4337 14.217 15.5448 14.1775 15.6604 14.1668C15.776 14.156 15.8925 14.1743 15.9992 14.22L20.0763 15.9716C20.2156 16.0295 20.3319 16.1316 20.4075 16.2622C20.4831 16.3927 20.5136 16.5445 20.4944 16.6941C20.3653 17.7028 19.8731 18.6299 19.1098 19.3018C18.3466 19.9738 17.3646 20.3446 16.3477 20.3448C13.2055 20.3448 10.192 19.0966 7.97011 16.8747C5.74824 14.6529 4.5 11.6393 4.5 8.49714C4.50025 7.48024 4.87105 6.49827 5.54301 5.73502C6.21498 4.97176 7.14205 4.47953 8.15072 4.35044C8.30034 4.33125 8.45212 4.36178 8.58267 4.43734C8.71323 4.51291 8.81531 4.6293 8.8732 4.76859L10.6248 8.85373C10.6704 8.95865 10.6895 9.07319 10.6804 9.18724C10.6713 9.30129 10.6343 9.41134 10.5725 9.50768L9.11712 11.7146C9.05141 11.8154 9.01291 11.9315 9.00538 12.0516C8.99785 12.1717 9.02154 12.2917 9.07415 12.3999V12.3999Z" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>

          </div>
        </div>
        }
        <div className="wrapper" style={{maxWidth: 768}}>
          <Binoculars/>
          <h2 className="compilation-public-header__title h2">{compilation.title}</h2>
          <p className="compilation-public-header__info-text">
            {compilation.comment}
          </p>
        </div>

      </div>
      <div className="sf wrapper compilation-public-items">
      <div className="sf">
        <div className="sf__detail-row sf__detail-row--common-page" style={{ ...(showContacts? []:{gridTemplateColumns: "auto"}) }}>
          <div className="sf__detail-col">
            <div className="common-header">
              <div className="common-header__title">
                <h3 className="common-title">
                  Подобранные варианты
                  <br/>
                  <span className="common-title__blue-link" style={{marginTop: isTablet? 16 :-3, }} onClick={e=>setView("map")}>Посмотреть на карте</span>
                </h3>
              </div>
            </div>

            <ul className="favorites-list favorites-list_public">
              {
                items.map((item, index) => {
                  return (
                    <li className={`favorites-list__over-item favorites-list__over-item_nopointer`}  id={`myIdIs-${item.id}`} key={item.id}>
                      <div className="favorites-list__item">
                        {(item?.status?.name === 'Продано' || item?.status?.name === 'Бронь') && <div className="favorites-list__item_sold" />}
                        <div className="favorites-list__item-left">


                          <div className="favorite-list__img-block">
                            <img className="favorites-list__item-img favorites-list__item-img_apartments favorites-list__item-img_apartments-public" src={getUrl(item?.photos[0]?.src)}
                              onClick={() => {
                                openSlider(1, item)
                              }}
                            />

                            {
                              item.photos.length > 1 &&
                              <div className="favorite-list_public__img-items">
                                {item.photos[1] &&
                                  <div><img src={getUrl(item?.photos[1].src)} alt=""
                                    onClick={() => {
                                      openSlider(2, item)
                                    }}
                                /></div>}
                                {item.photos[2] && <div><img src={getUrl(item?.photos[2].src)} alt=""
                                  onClick={() => {
                                    openSlider(3, item)
                                  }}
                                /></div>}
                                {item.photos[3] && !item.viewFromWindow && <div><img src={getUrl(item?.photos[3].src)} alt=""
                                  onClick={() => {
                                    openSlider(4, item)
                                  }}
                                /></div>}

                                {
                                  item.viewFromWindow &&
                                    <>
                                    <div onClick={() => {
                                       openSlider(item.photos?.length + 1, item)
                                    }}>


                                      <img src={mappic} alt=""/>
                                    </div>
                                    </>
                                }

                              </div>
                            }

                          </div>

                          <div className="favorites-list__item-content">
                            <div className="favorites-list__item-info">
                              {isSmallLaptop &&
                              <div className="favorites-list__item-right" style={{marginTop: 20, marginBottom: 15}}>
                                <ul className="favorites-list__item-tags">
                                  <li className={`label-item ${item?.status?.color === 'green' ? ' label_green' : item?.status?.color === 'gray' ?  ' label_red' : item?.status?.color === 'red' ?  ' label_red' : ''}`}>{item?.status?.name}</li>
                                  {item.types && item.types.map(v =><li key={v}>{v}</li>)}
                                </ul>
                              </div>
                              }
                              <div className="favorites-list__item-title h4" style={{display: 'flex', alignItems: 'center'}}>
                                {showPrice && item?.minPrice}

                                <div className={`favorites-list__item-dynamic ${isMobile && ' favorites-list__item-dynamic_mobile'}`} onClick={() => {setMobDynamic(!mobDynamic)}}>
                                  {showDynamicPrice && !!item?.dynamicPrices?.[2]?.flag && <svg style={item?.flag === 'down' ? {cursor: 'pointer', transform: 'rotate(180deg)'} : {marginBottom: 3, cursor: 'pointer'}} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.92578 0.0742188L11.0577 8.96289H0.793904L5.92578 0.0742188Z" fill={item?.dynamicPrices[2]?.flag === 'up' ? '#2CBA2E' : '#ff2d23'}/></svg>}
                                  <div className={`sf favorites-list__item-dynamic_hint ${mobDynamic ? ' favorites-list__item-dynamic_hint-mobilebg' : ''}`}>
                                    {item.dynamicPrices && item?.dynamicPrices &&
                                    <div className={`sf__block-hint`}>
                                      <p className="h5-l" style={{marginBottom: 20}}>Динамика цены
                                        {isMobile && <span style={{float: 'right'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7 7L12 12L17 7" stroke="#2C2E3E" stroke-linecap="round"/>
                                          <path d="M7 17L12 12L17 17" stroke="#2C2E3E" stroke-linecap="round"/>
                                        </svg></span>
                                        }
                                      </p>
                                      {item?.dynamicPrices.map(({ title, value, flag }) => (
                                        <div className="sf__info-row" key={item.id}>
                                          <div className="sf__info-key">{title}</div>
                                          <div className="sf__info-value" style={flag && flag === 'up' ? {color: '#2CBA2E'} : flag && flag === 'down' && {color: '#ff2d23'} }>{ value}</div>
                                        </div>
                                      ))}
                                    </div>}
                                  </div>
                                </div>
                              </div>
                              <div className="favorites-list__item-price">{item?.title}</div>


                              {item.objectType === 'secondary' &&
                                <div className="favorites-list__item-address">{item?.address}</div>
                              }
                              {item.objectType !== 'secondary' && showBlockTitle &&
                              <div className="favorites-list__item-address">{item?.blockTitle}</div>
                              }

                              {!!item?.coordinates?.length && <span style={{ color: '#8790E5' }} onClick={()=>showOnMap(item)} >На карте</span>}
                              <div className="favorites-list__item-deadline">{item?.ending}</div>
                            </div>

                            {false && showButtonGetPdf && <div className="favorites-list__item-action">
                              <div className="items-filters" style={{marginTop: 0}}>
                                <div className="items-filters__buttons">
                                  <span className="link" onClick={(e) =>{
                                    e?.stopPropagation?.();
                                    // openPdfModal(item)
                                  }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12.4286V5.61905C6 5.45487 6.06522 5.29741 6.18131 5.18131C6.29741 5.06522 6.45487 5 6.61905 5H14.0476L18.381 9.33333V12.4286" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.0476 5V9.33333H18.3809" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 18.0001H7.2381C7.56646 18.0001 7.88137 17.8697 8.11356 17.6375C8.34575 17.4053 8.47619 17.0904 8.47619 16.762C8.47619 16.4337 8.34575 16.1187 8.11356 15.8866C7.88137 15.6544 7.56646 15.5239 7.2381 15.5239H6V19.2382" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.9999 15.5239H16.8333V19.2382" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.6904 17.6904H16.8333" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.1905 19.2382C12.6831 19.2382 13.1554 19.0425 13.5037 18.6943C13.852 18.346 14.0477 17.8736 14.0477 17.3811C14.0477 16.8885 13.852 16.4162 13.5037 16.0679C13.1554 15.7196 12.6831 15.5239 12.1905 15.5239H11.1072V19.2382H12.1905Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                  Скачать PDF
                                </span>

                                </div>
                              </div>
                            </div>}
                          </div>
                        </div>

                        {!isSmallLaptop &&
                          <div className="favorites-list__item-right">
                            <ul className="favorites-list__item-tags">
                              <li className={`label-item ${item?.status?.color === 'green' ? ' label_green' : item?.status?.color === 'gray' ? ' label_red' : item?.status?.color === 'red' ? ' label_red' : ''}`}>{item?.status?.name}</li>
                              {item.types && item.types.map(v =><li key={v}>{v}</li>)}
                            </ul>
                          </div>
                        }

                        <div className="favorite-note">
                          {item.note !== '' && item.displayNote.xmlId === 'all' &&
                            <div className={`favorite-note__ready-note`}>
                              <div className='favorite-note__ready-content'>
                                <b>Заметка:{"\n"}</b>
                                {item.note}
                              </div>
                            </div>
                          }
                        </div>
                        <div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>

          </div>
          {!isSmallLaptop && showContacts &&
          <div className="sf__detail-col">
            <div className="compilation-public-form">
                  {logo?.src && <img
                    src={getUrl(logo?.src)}
                    style={{
                      height: 65,
                      margin: "0 auto"
                    }}
                    alt="logo"
                  />}

              <div className="compilation-public-form__firm">{workCompany}</div>
              <div className="compilation-public-form__user">{lastName} {agentName}</div>
              <div className="compilation-public-form__phone">{agentPhone}</div>


              <div className="hp__form-item" style={{width: '100%'}}>
                <label className="input input_width_available input_type_form">
                  <input
                    className={'input__control'}
                    style={{  boxShadow: 'none' }}
                    placeholder="Ваше имя"
                    onChange={e => setCallbackValue(e.target.value, "name")}
                    value={getValue("name")}
                  />
                </label>
              </div>

              <div className="hp__form-item" style={{width: '100%'}}>
                <label className="input input_width_available input_type_form">
                      <InputMask
                        style={{ boxShadow: 'none' }}
                        placeholder="Введите номер телефона"
                        className={'input__control'}
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        name="phone"
                        onChange={e => setCallbackValue(e.target.value, "phone")}
                        value={getValue("phone")}
                      />
                </label>
              </div>
                  {!formStatus && <div style={{ marginTop: 15 }}>
                    <SubmitButton
                      className="btn h6 btn_primary btn_full"
                      isLoading={isSending}
                      type="button"
                      onClick={callbackHandler}
                    >
                      Отправить заявку
                    </SubmitButton>
                  </div>}
                  {!!formStatus && <Attention text="Ваша заявка отправлена" style={{ marginTop: 10 }} />}

            </div>
          </div>
          }

        </div>
      </div>
    </div>
      {!!compilation.yandexMetric && <YMInitializer
        accounts={[+compilation.yandexMetric]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: false,
        }}
      />}

      <PhotoPopup
        items={itemsGallery}
        index={imageIndex}
        open={openGallery}
        onClose={() => setOpenGallery(false)}
        contentByIndex={contentByIndex}
        setIndex={setImageIndex}
      />

      {/*<PhotoSwipe*/}
      {/*  isOpen={openGallery}*/}
      {/*  items={itemsGallery}*/}
      {/*  options={{index: imageIndex}}*/}
      {/*  onClose={() => setOpenGallery(false)}*/}
      {/*/>*/}

    </>
  )
}

export default PublicCompilation;




