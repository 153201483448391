import React, {useRef, useState} from "react";
import InputMask from "react-input-mask";
import useMedia from "../../hooks/useMedia";

const AddClient = (props) => {
    const {
        show,
        errors = {},
        nameProps,
        lastNameProps,
        patronymicProps,
        phoneProps,
        emailProps,
        setValue
    } = props;

    const isMobile = useMedia("isMobile")
    const [phone, setPhone] = useState('');

    const phoneRef = useRef();

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = (e.clipboardData || window.clipboardData).getData('text');
        let sanitizedData = pasteData.replace(/[^\d]/g, '');
        if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
            sanitizedData = sanitizedData.substring(1);
        } else if (sanitizedData.startsWith('+7')) {
            sanitizedData = sanitizedData.substring(2);
        }
        const formattedData = `+7 (${sanitizedData.slice(0, 3)}) ${sanitizedData.slice(3, 6)}-${sanitizedData.slice(6, 8)}-${sanitizedData.slice(8, 10)}`;
        phoneRef.current.value = formattedData;
        setPhone(formattedData);
        setValue('phone', formattedData);
    };
    return (
        <div
            className="modal-form__gist modal-form__row modal-form__new-client"
            id="reservation-new-client"
            style={{ display: show ? "block" : "none" }}
        >
            {props.forFixate && !isMobile && <h4 className="sell__subtitle h4">Добавить клиента</h4>}

            {props.closeClick && !isMobile &&
                <span className="close-button" onClick={props.closeClick}>
                    <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                    </svg>
                </span>
            }
            <div className="new-client">
                <div className="modal-form__row">
                    <div className="modal-form__item">
                        <label className="input input_width_available input_type_form">
                            <span className="input__label">Фамилия клиента<span className="is-red-text sell__required">*</span></span>
                            <input
                                name="lastName"
                                className={
                                    "input__control" + (errors.lastName ? " input__error" : "")
                                }
                                {...lastNameProps}
                            />
                            {errors.lastName && (
                                <span className="input__error-label">
                                    {errors.lastName?.message}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
                <div className="modal-form__row">
                    <div className="modal-form__item">
                        <label className="input input_width_available input_type_form">
                            <span className="input__label">Имя клиента<span className="is-red-text sell__required">*</span></span>
                            <input
                                name="name"
                                className={
                                    "input__control" + (errors.name ? " input__error" : "")
                                }
                                {...nameProps}
                            />
                            {errors.name && (
                                <span className="input__error-label">
                                    {errors.name?.message}
                                </span>
                            )}
                        </label>
                    </div>
                    <div className="modal-form__item">
                        <label className="input input_width_available input_type_form">
                            <span className="input__label">Отчество клиента</span>
                            <input
                                name="patronymic"
                                className={
                                    'input__control' + (errors.patronymic ? ' input__error' : '')
                                }
                                {...patronymicProps}
                            />
                            {errors.patronymic && (
                                <span className="input__error-label">
                                    {errors.patronymic?.message}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
                <div className="modal-form__row">
                    <div className="modal-form__item">
                        <label className="input input_width_available input_type_form">
                            <span className="input__label">Телефон<span className="is-red-text sell__required">*</span></span>
                            <InputMask
                              className={
                                'input__control' + (errors.phone ? ' input__error' : '')
                              }
                              placeholder="Введите телефон"
                              mask="+7 (999) 999-99-99"
                              autoComplete="off"
                              maskChar=" "
                              name="phone"
                              value={phone}
                              onPaste={handlePaste}
                              onChange={(e) => {
                                  setPhone(e.target.value);
                                  phoneProps.onChange(e);
                              }}
                              inputRef={(ref) => {
                                  phoneRef.current = ref;
                              }}
                            />

                            {errors.phone && (
                                <span className="input__error-label">
                                    {errors.phone?.message}
                                </span>
                            )}
                        </label>
                    </div>
                    <div className="modal-form__item">
                        <label className="input input_width_available input_type_form">
                            <span className="input__label">E-mail<span className="is-red-text sell__required">*</span></span>
                            <input
                                name="email"
                                className={
                                    "input__control" + (errors.email ? " input__error" : "")
                                }
                                {...emailProps}
                            />
                            {errors.email && (
                                <span className="input__error-label">
                                    {errors.email?.message}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddClient
