import { memo, useState } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";
import { getRoundedPrice, evaulateEndings } from "../../../../utils";



const VillageCardContainer = memo(props => {
  const { name, link, photos, address, builder, suburbansInfo, nearby, endings, toggleFavorite, id, index, get, favoritesPath } = props
  const path = favoritesPath ? favoritesPath(index): `villages[${index}].favorite`
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const handleFavorite = e => {
    e.preventDefault()
    if (isFavoriteLoading) return
    setIsFavoriteLoading(true)

    toggleFavorite({ id }, path).finally(setIsFavoriteLoading)
  };

  return <Card
    {...props}
    link={link}
    title={name}
    subtiltle={address}
    src={getUrl(photos[0].src)}
    topLabel={evaulateEndings(endings)}
    descTitle={builder.name}
    // descValue={suburbansInfo.suburbansByType[0].type.title} // why...
    tableItems={suburbansInfo?.suburbansByType?.map?.(item => ({
      id: item.roomTypeId,
      title: item.type.title,
      count: item.count,
      middleTitle: item.minSquareHome ? `от ${item.minSquareHome} м²` : "",
      subtitle: `от ${getRoundedPrice(item.minPrice)} млн.р.`
    }))}
    bottomLabelList={nearby.map(item => item.title)}
    handleFavorite={handleFavorite}
    inFavorite={get(`${path}.inFavorite`)}
  />
});

export default VillageCardContainer;
