import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from "react-input-mask";
import { useModal } from "react-modal-hook";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Dropdown from '../../components/core/Dropdown';
import MobileModal from "../../components/core/MobileModal";
import Modal from "../../components/core/Modal";
import Wrap from "../../components/core/ModalWrap";
import SwiperSlider from "../../components/core/SwiperSlider";
import Preloader from '../../components/Preloader';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast, standartSuccessToast, standartInfoToast } from '../../components/toasts';
import useApi from '../../hooks/useApi';
import useMedia from "../../hooks/useMedia";
import { scrollToRef } from "../../utils";
import './index.scss';





const Accordeon = ({ number, title, children }) => {

  const defaultState = () => number === 1;
  const [isOpened, setIsOpened] = useState(defaultState);


  return (
    <div className="sor__prog-item" key={`sor-prog-${number}`}>
      <div className="sor__prog-header" onClick={() => setIsOpened(prevState => !prevState)}>

        <h4 className="h4 sor__h4">{title}</h4>

        <button
          type="button"
          className={`sor__accordion-btn${isOpened ? ` opened` : ``}`}
          aria-label="Открыть панель">
          <svg className="icon icon_more">
            <use xlinkHref="#chevron"></use>
          </svg>
        </button>
      </div>
      <div className={`sor__prog-body${isOpened ? ` opened` : ``}`}>
        {children}
      </div>
    </div>
  );
};

const aboutItems = [
  {
    number: `1`,
    title: `Экскурсия`,
    content: [`на автомобиле комфорт-класса по интересующим новостройкам`],
  },
  {
    number: `2`,
    title: `Индивидуальный просмотр`,
    content: [`только вы, клиент и эксперт АвтоПАН`],
  },
  {
    number: `3`,
    title: `Экономия времени`,
    content: [`за счет грамотного маршрута экскурсия занимает от 1 до 3 часов!`],
  },
  {
    number: `4`,
    title: `Максимум информации`,
    content: [`о застройщике, ЖК, районе, инфраструктуре`],
  },
  {
    number: `5`,
    title: `Помощь и поддержка эксперта АвтоПАН`,
    content: [`демонстрирует объекты с лучших видовых точек`, `дает объективную оценку с акцентами на преимуществах`, `поддерживает непринужденную беседу, помогает закрыть возражения`],
  },

];

const stepsItems = [
  {
    number: `Шаг 01`,
    text: `Оставляете заявку, выбираете районы и ЖК, которые хотите посмотреть с клиентом`,
  },
  {
    number: `Шаг 02`,
    text: `Получаете обратный звонок от нашего менеджера: уточняете детали поездки, согласовываете маршрут`,
  },
  {
    number: `Шаг 03`,
    text: `Записываетесь на просмотр к застройщику, подтверждаете запись у менеджера АвтоПАН`,
  },
  {
    number: `Шаг 04`,
    text: `Эксперт АвтоПАН на автомобиле забирает вас и клиента у ближайшей к объекту станции метро`,
  },
  {
    number: `Шаг 05`,
    text: `Во время поездки формируете полноценное впечатление об инфраструктуре района, получаете комментарии эксперта`,
  },
  {
    number: `Шаг 06`,
    text: `Смотрите объекты с «правильных» ракурсов: клиент сразу видит преимущества объекта и получает экспертную оценку`,
  },
  {
    number: `Шаг 07`,
    text: `Специалист АвтоПАН отвечает на вопросы, снимает сомнения клиента и мягко подводит к покупке`,
  },
];



const formatDay = (date) => {
  return date.toISOString().split('T')[0];
};

const getFakeTimes = () => { //попросили захардкодить times (хотфикс)
  const today = new Date()
  const tomorow = formatDay(new Date(today - 86400000))
  return {
    [formatDay(today)]: [{ time: '10:00-13:00', active: true }],
    [tomorow]: [{ time: '10:00-13:00', active: true }],
  }
}

const AutoPan = memo(props => {

  const progRef = useRef();
  const formRef = useRef();
  const formRef2 = useRef();

  const isMobile = useMedia("isMobile");

  const user = useSelector(state => state.user?.info)
  const [currentReview, setCurrentReview] = useState({});

  const [openReviewModal, closeReviewModal] = useModal(({ in: open, onExited }) => (
    <Wrap wrapper={children => isMobile
      ? <MobileModal closeClick={closeReviewModal} title={`Отзывы`}>{children}</MobileModal>
      : <Modal close={closeReviewModal} classes="modal_task">{children}</Modal>
    } >
      <FullReview />
    </Wrap >
  ), [currentReview]);

  const FullReview = () => {
    return (
      <div>
        <p className="sor__about-header">
          <span className="sor__about-counter">{currentReview.name}</span>
        </p>
        <p className="sor__about-text big-text">{currentReview.description}</p>
      </div>
    )
  };

  //хорошо бы это в useReducer запихнуть но чет лень
  const [userName, setUserName] = useState(user ? `${user.name} ${user.lastName}` : "")
  const [phone, setPhone] = useState(user ? user.phone : "")
  const [chosenDay, setChosenDay] = useState();
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [agree, setAgree] = useState(true);
  const [errors, setErrors] = useState({})
  const [isManagerVisible, setVisible] = useState(true);

  // const times = useMemo(()=>getFakeTimes(), []) //hotfix

  const { data: {districts, reviews, times }, isLoading: isFetching, send: sendForm, isSending, } = useApi({
    payload: ['panpartner:autopan.ajax', "getAutoPan"],
    successCallback: ({ districts, times }) => {
      if (times) {
        setSelectedDistrict(districts?.[0]?.id)
        const firstActiveDay = Object.entries(times).find(([date, times]) => times.some(time => time.active))
        setChosenDay(formatDay(new Date(firstActiveDay[0])))
      }

    }
  })

  const offsetTop = 30;

  const handleScroll = () => {
    let currentScrollPosition = managerShowRef.current?.getBoundingClientRect().top;
    setVisible(currentScrollPosition > offsetTop);
  };

  const managerShowRef = useRef(null);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  // const includedTimes = useMemo(() =>
  //   times?.[chosenDay]?.map(item => item.active ? new Date(item.date.replace("Z", "")) : null),
  //   [chosenDay, times])
  const formattedTimes = useMemo(() =>
    times?.[chosenDay]?.map(item => ({
      id: item.time,
      disabled: !item.active,
      title: item.time,
    })),
    [chosenDay, times])

  const selectDay = day => {
    setChosenDay(day)
    setSelectedTime(null)
  }
  const checkFields = largeForm => {
    const err = {
      phone: !phone ? "Введите телефон" : false,
      name: !userName ? "Введите имя" : false,
      agreement: !agree ? "Необходимо согласие на обработку персональных данных" : false
    }
    // if (largeForm) {
    //   err.timeFrom = !selectedTime ? "Выберите время" : false
    // }
    setErrors(err)
    return Object.values(err).some(err => !!err)
  }
  const CustomSucessMessage = () => (
    <div className="autopan-viewing-customToast">
      <b>Благодарим за обращение! В ближайшее время наш Администратор свяжется с Вами.</b><br/>
      (Заявки, направленные в выходные и праздничные дни будут обработаны в ближайший рабочий день)
    </div>
  );
  const submitRequestsForm = (title, largeForm) => {
    if (checkFields(largeForm)) return errorToast("Заполните обязательные поля")
    if (!agree) return errorToast("Необходимо согласиться на обработку персональных данных")
    // const { formattedTime } = parseDate(selectedTime)
    const form = {
      title,
      date: `${chosenDay} ${selectedTime}`,
      districtId: selectedDistrict,
      name: userName,
      phone,
    }
    sendForm(['panpartner:autopan.ajax', "addRequest", form])
      // .then(standartSuccessToast, standartErrorToast)
      .then(standartInfoToast({message: <CustomSucessMessage/>}, {autoClose: 10000}), standartErrorToast)
      .finally(() => setErrors({}))
  }


  const scrollToFooter = () => scrollToRef(formRef, 0);


  if (isFetching) {
    return <Preloader loading={true} />
  }



  return (
    <section className="section section_view_white">

      <div className={`trade-in__manager${isManagerVisible ? ` hidden` : ``}`}>
        <img  src={require('../../assets/images/auto/manager.jpg').default} alt="" className="trade-in__manager-img" />
        <div className="trade-in__manager-block">
          <span className="h6 trade-in__manager-name">Меланич Наталья</span>
          <p className="trade-in__manager-position">Администратор обучающего центра</p>
          <a href="tel:+79657874839" className="trade-in__manager-phone link-hover">+7 (965) 787-48-39</a>
        </div>
      </div>

      <section className="sor sor_cashback sor_auto">
        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">
                <div className="sor__content">
                  <span className="sor__sup">Для партнеров ПАН</span>
                  <h1 className="sor__h1 rie__title">АвтоПАН — закройте клиента на сделку всего за 3 часа</h1>
                  <p className="sor__sign big-text">Экскурсия по новостройкам для вас и клиента на автомобиле комфорт-класса</p>
                </div>




                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/auto/img1.png').default} alt="" className="sor__promo-img-lg" />
                </div>
              </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef2, 0)}>Записаться на поездку</button>
                <a href={`https://panpartner.ru/files/938`} target="_blank" className="sor__promo-btn button button_view_outline-with-icon-invert">Файлы</a>
              </div>


            </div>
          </div>
        </header>

        <div className="wrapper">
          <div className="wrapper__center">

            <section className="sor__ifnow sor__section">
              <h2 className="sor__h2 h2">Если сейчас:</h2>
              <div className="sor__ifnow-content">
                <div className="sor__ifnow-item">
                  <span className="sor__ifnow-label">Клиент</span>
                  <ul className="sor__ifnow-list sor__prog-ul">
                    <li>Давно ведет с вами переговоры, но все еще мучается с выбором</li>
                    <li>Задает странные и непонятные вопросы </li>
                    <li>Хочет посмотреть и сравнить интересующие объекты</li>
                    <li>Боится ошибиться и нуждается в независимом мнении</li>
                    <li>Не может принять окончательное решение</li>
                  </ul>
                </div>
                <div className="sor__ifnow-item">
                  <span className="sor__ifnow-label">Вы</span>
                  <ul className="sor__ifnow-list sor__prog-ul">
                    <li>Не можете сблизиться с клиентом и раскрыть на истинные боли, страхи и пожелания</li>
                    <li>Нервничаете при встрече, потому что знаете объект не на 100% — переживаете насчет «подводных камней»</li>
                    <li>Без личного автомобиля — показ отдаленных объектов становится «головной болью»</li>
                    <li>Устали отрабатывать возражения и подозреваете, что клиент вам не полностью доверяет</li>
                  </ul>




                </div>
              </div>
            </section>

          </div>
        </div>

        <section className="sor__dark-label">
          <span className="sor__dark-label-h3 h3">Воспользуйтесь бесплатным сервисом АвтоПАН!</span>
        </section>


        <div className="wrapper">
          <div className="wrapper__center">
            <section className="sor__section">
              <h2 className="sor__h2 sor__h2_760 h2">АвтоПАН — решение для тех, кто хочет продавать легко и за 1 показ</h2>

              {
                !isMobile && (
                  <div className="sor__grid">
                    {
                      aboutItems.map((item, i) =>
                        <div className="sor__about-item" key={i}>
                          <p className="sor__about-header">
                            <span className="sor__about-counter">{item.number}</span>
                          </p>
                          <p className="sor__about-title h55">{item.title}</p>
                          {
                            item.content.length > 0 ?
                              <ul className="sor__about-inner sor__about-text">
                                {item.content.map((it, i) => <li key={i}>{it}</li>)}
                              </ul> :
                              <p className="sor__about-text">{item.content[0]}</p>
                          }

                        </div>)
                    }


                  </div>
                )
              }

              {
                isMobile && (
                  <div className="swiper-pan sor__grid">
                    <SwiperSlider
                      items={aboutItems}
                      wrap={item => (
                        <div className="sor__about-item">
                          <p className="sor__about-header">
                            <span className="sor__about-counter">{item.number}</span>
                          </p>
                          <p className="sor__about-title h55">{item.title}</p>
                          <ul className="sor__about-inner sor__about-text">
                            {item.content.map((it, i) => <li key={i}>{it}</li>)}
                          </ul>
                        </div>
                      )
                      }
                      withCounter

                      options={{
                        slidesPerView: 1,
                        spaceBetween: 15,
                        pagination: true,
                        autoHeight: true,
                      }}
                    />
                  </div>
                )
              }

              <button type="button" className="sor__promo-btn sor__promo-btn_center button button_view_default" onClick={() => scrollToRef(formRef2, 0)}>Выбрать локацию</button>

            </section>


            <section className="sor__section sor__steps">
              <h2 className="sor__h2 h2">7 шагов, чтобы получить довольного клиента</h2>
              {
                !isMobile && (
                  <div className="sor__grid sor__grid_steps">
                    {
                      stepsItems.map((item, i) =>
                        <div className="sor__about-item" key={i}>
                          <p className="sor__about-header">
                            <span className="sor__about-counter">{item.number}</span>
                          </p>
                          <p className="sor__about-text">{item.text}</p>
                        </div>
                      )
                    }

                  </div>
                )
              }

              {
                isMobile && (
                  <div className="swiper-pan sor__grid sor__grid_steps">
                    <SwiperSlider
                      items={stepsItems}
                      wrap={item => (
                        <div className="sor__about-item">
                          <p className="sor__about-header">
                            <span className="sor__about-counter">{item.number}</span>
                          </p>
                          <p className="sor__about-text">{item.text}</p>
                        </div>
                      )
                      }
                      withCounter

                      options={{
                        slidesPerView: 1,
                        spaceBetween: 15,
                        pagination: true,
                      }}
                    />
                  </div>
                )
              }


            </section>
          </div>
        </div>
        <section className="sor__footer" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="h2 sor__h2 sor__footer-title">Оставьте заявку на бесплатную консультацию</h2>
              </div>
              <form action="#" className="sor__form">
                <div className="sor__form-box">
                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-name"
                    >
                      <span className="input__label">Имя</span>
                      <input
                        id="modal-reservation-name"
                        name="name"
                        value={userName}
                        className={"input__control" + (errors.name ? " input__error" : "")}
                        placeholder="Ваше имя"
                        onChange={e => setUserName(e.target.value, "name")}
                      />
                      {!!errors.name && (
                        <span className="input__error-label">
                          {errors.name}
                        </span>
                      )}
                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-phone"
                    >
                      <span className="input__label">Телефон</span>
                      <InputMask
                        id="modal-reservation-phone"
                        className={
                          "input__control" + (errors.phone ? " input__error" : "")
                        }
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        name="phone"
                        placeholder="Ваш телефон"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      />
                      {errors.phone && (
                        <span className="input__error-label">
                          {errors.phone}
                        </span>
                      )}
                    </label>
                  </div>

                  <SubmitButton
                    type="button"
                    className="sor__submit button button_view_default"
                    onClick={() => submitRequestsForm("Заявка на консультацию")}
                    isLoading={isSending}
                  >
                    Оставить заявку
                  </SubmitButton>

                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box' +
                        (errors.agreement ? " checkbox__box-error" : "")
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>


        <section className="sor__section sor__section_dark sor__author">
          <div className="wrapper">
            <div className="wrapper__center">

              <div className="sor__author-box">

                <div className="sor__author-content">
                  <h2 className="sor__h2 h2 sor__author-title">Тамара Горохова</h2>
                  <span className="caps color-red">Эксперт АвтоПАН</span>

                  <div className="sor__author-flex">
                    <ul className="sor__prog-ul sor__author-list">
                      <li>Управляющая сервисом «АвтоПАН» и «АвтоПАНОРАМА»</li>
                      <li>В недвижимости с 2011 года</li>
                      <li>200+ закрытых сделок за 7 лет частной практики в качестве агента</li>
                      <li>Ежегодно проводит более 1000 поездок с агентами и клиентами — из них более 600 сразу выходят на сделку</li>
                    </ul>
                    <ul className="sor__prog-ul sor__author-list">
                      <li>Эксперт по новостройкам и продажам: досконально знает все актуальные объекты, их «плюсы» и «минусы»</li>
                      <li>Дипломированный специалист по истории и градостроительству Петербурга</li>
                      <li>Автор уникальной программы обучения для риелторов на базе выездных экскурсий в рамках сервиса «АвтоПАНОРАМА»</li>
                    </ul>
                  </div>

                </div>

                <div className="sor__author-pic">
                  <img src={require('../../assets/images/auto/img2.png').default} alt="" className="sor__author-img" />
                  <p className="sor__author-sign h3">«Любое возражение легко закрывается, если на каждый «минус» даешь клиенту 5 преимуществ»</p>
                </div>

              </div>

            </div>
          </div>
        </section>


        <div className="wrapper">
          <div className="wrapper__center">
            <section className="sor__section">
              <h2 className="sor__h2 sor__h2_845 h2">Более <span>3000 агентов</span> уже провели просмотр с АвтоПАН — <span>73%</span> закрыли клиента на сделку в тот же день!</h2>


              <div className="sor__reviews">
                <div className="swiper-pan">
                  <SwiperSlider
                    items={reviews}
                    wrap={(item) => {

                      return (
                        <div className="sor__review">
                          <div className="sor__about-item">
                            <p className="sor__about-header">
                              <span className="sor__about-counter">{item.name}</span>
                            </p>
                            <p className="sor__about-text">{item.description.length > 250 ? `${item.description.slice(0, 250)}...` : `${item.description}`}</p>
                            {
                              item.description.length > 250 &&
                              (
                                <button type="button" className="sor__review-more button"
                                  onClick={() => {
                                    setCurrentReview(item);
                                    openReviewModal();

                                  }}>
                                  Читать полностью
                                  <svg className={`icon`}>
                                    <use xlinkHref="#arrow_bottom"></use>
                                  </svg>
                                </button>
                              )

                            }

                          </div>
                        </div>
                      )
                    }}
                    navigation={!isMobile}
                    withCounter={isMobile}


                    options={{
                      slidesPerView: 1,
                      spaceBetween: 30,
                      pagination: false,
                      autoHeight: isMobile,
                      breakpoints: {
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        1280: {
                          slidesPerView: 3,
                          pagination: false,
                        },
                      }
                    }}
                  />
                </div>

              </div>
            </section>
          </div>
        </div>

        <section className="sor__section sor__section_gray" ref={formRef2}>
          <div className="wrapper">
            <div className="wrapper__center">
              <h2 className="sor__h2 h2">Проведите продающий просмотр с АвтоПАН!</h2>

              <form action="#" className="sor__form sor__form_autopan">
                <div className="sor__form-box">
                  {/*<div className="sor__form-row">*/}
                  {/*  <div className="sor__form-item sor__form-item_location">*/}
                  {/*    <label*/}
                  {/*      className="input input_width_available input_type_form"*/}
                  {/*      htmlFor="modal-reservation-program"*/}
                  {/*    >*/}
                  {/*      <span className="input__label">Локация</span>*/}
                  {/*      <Dropdown*/}
                  {/*        innerClass="input__control"*/}
                  {/*        options={districts}*/}
                  {/*        value={selectedDistrict}*/}
                  {/*        titleKey="name"*/}
                  {/*        onChange={setSelectedDistrict}*/}
                  {/*      />*/}
                  {/*    </label>*/}
                  {/*  </div>*/}

                  {/*  <div className="sor__form-item sor__form-item_half">*/}
                  {/*    <label*/}
                  {/*      className="input input_width_available input_type_form"*/}
                  {/*      htmlFor="modal-reservation-date"*/}
                  {/*    >*/}
                  {/*      <span className="input__label">Выберите дату</span>*/}
                  {/*      <DatePicker*/}
                  {/*        className="datepicker-autopan input__control"*/}
                  {/*        //dateFormat="d MMM yyyy, HH:mm"*/}
                  {/*        dateFormat="d MMM yyyy"*/}
                  {/*        // minDate={new Date(new Date().setHours(24))}*/}
                  {/*        //showTimeSelect={true}*/}


                  {/*        selected={times && new Date(chosenDay)}*/}
                  {/*        value={!times && `Нет доступных дат`}*/}

                  {/*        dropdownMode="select"*/}
                  {/*        autoComplete="off"*/}
                  {/*        monthsShown={2}*/}
                  {/*        onChange={(date) => {*/}
                  {/*          selectDay(formatDay(date));*/}
                  {/*        }}*/}
                  {/*        shouldCloseOnSelect*/}
                  {/*        includeDates={times ? Object.keys(times).map(day => new Date(day)) : []}*/}

                  {/*      />*/}


                  {/*    </label>*/}
                  {/*  </div>*/}

                  {/*  <div className="sor__form-item sor__form-item_half">*/}
                  {/*    <label*/}
                  {/*      className="input input_width_available input_type_form"*/}
                  {/*      htmlFor="modal-reservation-time"*/}
                  {/*    >*/}
                  {/*      <span className="input__label">Выберите время</span>*/}
                  {/*      /!* <DatePicker*/}
                  {/*        dateFormat="HH:mm"*/}
                  {/*        timeFormat="HH:mm"*/}
                  {/*        timeCaption="Время"*/}
                  {/*        autoComplete="off"*/}
                  {/*        showTimeSelect={true}*/}
                  {/*        showTimeSelectOnly={true}*/}
                  {/*        className={*/}
                  {/*          'input__control' +*/}
                  {/*          (errors.timeFrom ? ' input__error' : '')*/}
                  {/*        }*/}
                  {/*        selected={selectedTime}*/}
                  {/*        onChange={setSelectedTime}*/}
                  {/*        shouldCloseOnSelect*/}
                  {/*        includeTimes={includedTimes}*/}
                  {/*      /> *!/*/}

                  {/*      <Dropdown*/}
                  {/*        innerClass="input__control"*/}
                  {/*        options={times ? formattedTimes : [{id: 'Нет доступного времени', disabled: true, title: 'Нет доступного времени'}]}*/}
                  {/*        value={selectedTime}*/}
                  {/*        onChange={setSelectedTime}*/}
                  {/*        placeholder={"Выберите время"}*/}
                  {/*      />*/}
                  {/*      {!!errors.timeFrom && (*/}
                  {/*        <span className="input__error-label">*/}
                  {/*          {errors.timeFrom}*/}
                  {/*        </span>*/}
                  {/*      )}*/}
                  {/*    </label>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="sor__form-row">
                    <div className="sor__form-item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-name"
                      >
                        <span className="input__label">Имя</span>
                        <input
                          id="modal-reservation-name"
                          name="name"
                          value={userName}
                          className={
                            "input__control" + (errors.name ? " input__error" : "")
                          }
                          placeholder="Ваше имя"
                          onChange={e => setUserName(e.target.value, "name")}
                        />
                        {!!errors.name && (
                          <span className="input__error-label">
                            {errors.name}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="sor__form-item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-phone"
                      >
                        <span className="input__label">Телефон</span>
                        <InputMask
                          id="modal-reservation-phone"
                          className={
                            "input__control" + (errors.phone ? " input__error" : "")
                          }
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar=" "
                          name="phone"
                          placeholder="Ваш телефон"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        />
                        {!!errors.phone && (
                          <span className="input__error-label">
                            {errors.phone}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>


                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box' +
                        (errors.agreement ? " checkbox__box-error" : "")
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement2"
                        name="agree2"
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement2"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement2"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>
                  </div>

                  <SubmitButton type="button"
                    className="sor__submit button button_view_default"
                    onClick={() => submitRequestsForm("Заявка на поездку", true)}
                    isLoading={isSending}
                  >
                    Записаться на поездку
                  </SubmitButton>
                </div>
              </form>

            </div>
          </div>
        </section>

        <section className="sor__prog">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="sor__h2">Часто задаваемые вопросы</h2>
              </div>

              <div className="sor__prog-list sor__prog-list_autopan">
                <Accordeon title={`Как все проходит?`} number={1}>
                  <div className="sor__prog-text">
                    <ol className="sor__prog-ol">
                      <li>1. Вы оставляете заявку;</li>
                      <li>2. Вам звонит менеджер и уточняет детали бронирования: дату экскурсии, время, район и объекты, предлагает оптимальный маршрут и дает рекомендации, на какое время записаться на просмотр у застройщика.</li>
                      <li>3. Вы записываетесь на просмотр к застройщикам с помощью нашего отдела бронирования, и после этого подтверждаете дату и время экскурсии у менеджера АвтоПАН.</li>
                      <li>4. В день экскурсии эксперт Тамара Горохова на автомобиле забирает вас и клиента у ближайшей к объекту станции метро.</li>
                      <li>5. Вы с клиентом смотрите объекты, наш специалист все время с вами: рассказывает и показывает, отвечает на вопросы — деликатно отрабатывает возражения клиента, мягко подводит к покупке;</li>
                      <li>6. После экскурсии Тамара Горохова отвезёт вас и клиента к ближайшей станции метро.</li>
                    </ol>
                  </div>
                </Accordeon>

                <Accordeon title={`Сколько человек может присутствовать на экскурсии?`} number={2}>
                  <div className="sor__prog-text">
                    <p>
                      Экскурсия строго индивидуальная: только для 1 агента и 1-2 клиентов.
                    </p>
                  </div>
                </Accordeon>

                <Accordeon title={`Как все проходит?`} number={3}>
                  <div className="sor__prog-text">
                    <p>
                      За одну поездку с АвтоПАН вы можете посмотреть:
                    </p>

                    <ul>
                      <li>1-2 ближайших друг к другу района — более 100 жилых комплексов на выбор;</li>
                      <li>1-3 объекта с посещением шоурумов и квартир + обзорный экскурс.</li>
                    </ul>
                  </div>

                </Accordeon>

                <Accordeon title={`Сколько времени займет просмотр и поездка?`} number={4}>
                  <div className="sor__prog-text">
                    <p>
                      Не больше 3 часов.
                    </p>
                    <p>
                      Если вы хотите посмотреть объекты еще быстрее — сообщите об этом менеджеру АвтоПАН во время обратного звонка по вашей заявке.
                    </p>
                  </div>

                </Accordeon>



                <Accordeon title={`Сколько стоит экскурсия с АвтоПАН?`} number={5}>
                  <div className="sor__prog-text">
                    <p>
                      Бесплатно для партнеров ПАН — агентов и субагентов.
                    </p>
                    <p>
                      Обратите внимание, если в течение 6 месяцев у вас не было сделок — сервис АвтоПАН для вас будет временно недоступен.
                    </p>
                  </div>

                </Accordeon>

                <Accordeon title={`У меня не было сделок в течение 6 месяцев. Как я могу вернуть бесплатный доступ к сервису?`} number={6}>
                  <div className="sor__prog-text">
                    <p>
                      Обратитесь к своему управляющему из отдела развития.
                    </p>
                  </div>

                </Accordeon>

                <Accordeon title={`Может ли эксперт АвтоПАН «увести» у меня клиента?`} number={7}>
                  <div className="sor__prog-text">
                    <p>
                      Нет. У эксперта АвтоПАН другая задача: помочь вам заключить сделку и предоставить максимум информации об объектах во время экскурсии.
                    </p>
                    <p>
                      Менеджер АвтоПАН не проводит показы без агента, а на объекте вы контролируете весь процесс.
                    </p>
                  </div>

                </Accordeon>
                <div className="sor__prog-ref" ref={progRef}></div>
              </div>
            </div>
          </div>
        </section>

      </section>
    </section>
  );
});


export default AutoPan;
