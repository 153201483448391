import request from '../api';
import { actionFactory } from './actionUtils';

export const requestTradeIn = actionFactory("TRADEIN_REQUEST");

export const successTradeIn = actionFactory("TRADEIN_SUCCESS");

export const clearTradeIn = actionFactory("TRADEIN_RESET");

export const failureTradeIn = actionFactory("TRADEIN_FAILURE");

export const fetchTradeIn = city =>
	dispatch => {
		dispatch(requestTradeIn());
		return request('panpartner:trade.in.ajax', 'getTradeIn', { city })
			.then(
				data => dispatch(
					successTradeIn({
						data: data.tradeIn,
					})
				),
				error => dispatch(failureTradeIn()),
			);
	};

export const becomePartners = (form, city) =>
	dispatch => {
		dispatch(requestTradeIn());
		return request('panpartner:trade.in.ajax', 'becomePartners', { city, ...form })
			.then(null,
				error => {
					dispatch(failureTradeIn());
					return Promise.reject(error);
				},
			);
	};

export const sendPartner = form=> request('panpartner:system.auth.form', 'forgotPassword', form);