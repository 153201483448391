import { memo } from "react";
import { getUrl } from "../../../../api";
import { priceFormat } from "../../../../utils";
import Card from "../../../core/Card";


const SecondaryPublicCard = memo((props) => {
    const { address, link, buildingYear, roomType, plan, baseCost, squareTotal, floor, status } = props
    console.log('card secondary props:', props);
    const cardTitle = `${priceFormat(baseCost)}, ${squareTotal} м², ${floor} эт.`
    return <Card
        {...props}
        isFlat
        topLabel={roomType.title}
        link={link}
        bottomText={buildingYear ? `Год постройки: ${buildingYear}` : null}
        title={cardTitle}
        src={getUrl(plan.src)}
        descTitle={address}
        status={status}
    />
});

export default SecondaryPublicCard;
