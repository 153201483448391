export const FavoritesCheckboxItem = ({ id, title, createProps, onToggle }) => <li>
    <label className="switch" htmlFor={id}>
        <span className="switch__label">
            {title}
        </span>
        <div className="switch__box">
            <input
                className="switch__control"
                type="checkbox"
                id={id}
                {...createProps(id)}
                onChange={(e) => {
                    createProps(id).onChange(e);
                    onToggle && onToggle(e.target.checked);
                }}
            />
            <div className="switch__input" htmlFor={id} />
            <div className="switch__marker" />
        </div>
    </label>
</li>
