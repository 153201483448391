import React from 'react';
import { toast } from "react-toastify";
import { ReactComponent as Warning } from "../images/svg/warning.svg";

const errorToast = (message, config) => toast.error(({ closeToast }) =>
    <div className="Toastify__wrap">
        <Warning className="Toastify__error-icon" />
        <span className="Toastify__error-sign">!</span>
        {message}
    </div>, config
);
const warningToast = (message, config={}) => toast.warn(({ closeToast }) =>
    <div className="Toastify__wrap">
        <Warning className="Toastify__error-icon" />
        <span className="Toastify__error-sign">!</span>
        {message}
    </div>, {autoClose: 8000, ...config}
);
const successToast = (message, config = {}) => toast.success(({ closeToast }) =>
    <div className="Toastify__wrap">
        <span className="Toastify__icon-wrap">
            <svg className="icon icon_check_white">
                <use xlinkHref="#check_white" />
            </svg>
        </span>
        {message}
    </div>, config
);

const infoToast = (message, config = {}) => toast.info(({ closeToast }) =>
    <div className="Toastify__wrap">
        <span className="Toastify__icon-wrap">
            <svg className="icon icon_info">
                <use xlinkHref="#info" />
            </svg>
        </span>
        {message}
    </div>, config
)
const standartErrorToast = error => errorToast(<div>{error[0]?.message}</div>)

const standartSuccessToast = data => successToast(<div>{data?.message}</div>)
const standartInfoToast = (data, config) => infoToast(<div>{data?.message}</div>, config)

const standartWarningToast = (warning, config) => warning? warningToast(<div>{warning?.[0]?.message}</div>, config): ""

export { errorToast, successToast, infoToast, standartErrorToast, standartSuccessToast, warningToast, standartWarningToast, standartInfoToast };

