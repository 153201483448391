import React, {useEffect, useMemo, useRef, useState} from "react";
import './index.scss'
import {Link, useParams} from "react-router-dom";
import Dropdown from "../core/Dropdown";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {useSelector} from "react-redux";
import useUniversalForm from "../../hooks/useUniversalForm";
import {useModal} from "react-modal-hook";
import Modal from "../core/Modal";
import Button from "../core/Button";
import Preloader from "../Preloader";
import ru from "date-fns/locale/ru";
import NewAlert from "../core/NewAlert";
import SubmitButton from "../SubmitButton";
import { formatDate } from "../../utils";
import { standartErrorToast, successToast } from "../toasts";
import moment from 'moment';
import useFileManager from "../../hooks/useFileManager";
import SimpleBar from "simplebar-react";

registerLocale('ru', ru);
setDefaultLocale('ru');
const toDate = str => {
  if (str instanceof Date) return str
  const [day, months, years] = str?.split?.(".") || [];
  if (years?.trim?.()?.length !== 4) return new Date();
  return new Date(years, months - 1, day);
}
const emptyClient = {
  email: "",
  lastName: "",
  name: "",
  patronymic: "",
  phone: "",
  id: 0
}
const emptyUniFormClient = {
  clientEmail: "",
  clientLastName: "",
  clientName: "",
  clientPatronymic: "",
  clientPhone: "",
  clientId: 0,
  clientBirthday: ""
}
const clientToUniForm = cl => ({
  clientEmail: cl.email ? cl.email : "",
  clientLastName: cl.lastName,
  clientName: cl.name,
  clientPatronymic: cl.patronymic,
  clientPhone: cl.phone,
  clientId: cl.id,
  clientBirthday: ""
})

const filterAndMap = (arr, fn) => {
  const tmp = [];
  for (const item of arr) {
    const value = fn(item);
    value && tmp.push(value);
  }
  return tmp;
}

const RecordViewingSuburban = () => {

  const tabs = [
    { title: 'В Санкт-Петербурге' },
    { title: 'В Москве' },
  ];


  const { id, city } = useParams();

  const [activeTab, setActiveTab] = useState(city === 'msk' ? 1 : 0)

  const userInfo = useSelector(state => state.user.info)
  const initialObjectKey = "blocks"
  const initialObjectCodeName = "block";

  const [modalData, setModalData] = useState({})
  const openModalWithData = (data) => {
    setModalData(data)
    openModal()
  }
  const closeModal = () => {
    setModalData({})
    hideModal()
  }

  const [openModal, hideModal] = useModal(({}) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick
           close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#2C2E3E"/>
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round"/>
          <circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E"/>
        </svg>
        {modalData.title}
      </div>
      <div className="crm-table__sf-controlsmodaltext"
           dangerouslySetInnerHTML={{
             __html: modalData.text,
           }}
      >
      </div>
      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={closeModal}>Понятно</Button>
      </div>
    </Modal>
  ), [modalData]);


  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if(id) {
      setSelectedItems([Number(id)])
    }
  }, [id])
  const [selectedDates, setSelectedDates] = useState({})
  const [selectedType, setSelectedType] = useState([])
  const [isDateError, setIsDateError] = useState(false)
  const [successMessage, setSuccessMessage] = useState()
  const requiredFields = {
    clientLastName: "Введите фамилию клиента",
    clientName: "Введите имя клиента",
    clientPhone: "Введите телефон клиента",
    clientPatronymic: "Введите отчество клиента",
    ...(isDateError ? { time: "Введите корректное время" } : {}),
    ...(selectedItems?.length ? [] : {selected: `Выберите ЖК`})
  }
  const {
    form,
    getValue,
    isLoading,
    isSending,
    response: {
      clients,
      builders,
      fieldsDescription,
      // blocks,
      ...resp
    },
    setValue,
    setCheckboxValue,
    setValues,
    send,
    convertArrayToUniformData,
    errors,
    forcedSetValue
  } = useUniversalForm({
    formId: city === 'spb' ? 18 : city === 'msk' ? 19 : 18,
    city: city,
    defaultValues: {
      name: `${userInfo.name} ${userInfo.lastName}`,
      phone: userInfo.phone,

    },
    defaultCheckboxes: ["personal_data", "familiar_with_advertising", "familiar_with_builder"],
  })

  const selectClient = id => {
    const client = clients.find(client => client.id === id)
    setValues(id ? clientToUniForm(client) : emptyUniFormClient)
  }

  const normalizedClients = useMemo(() => {
    if (!clients) return [];
    const tmpClients = [{...emptyClient, name: "Ничего не выбрано", lastName: ""}, ...clients]
    return tmpClients.map(cl => ({...cl, title: `${cl.name} ${cl.lastName}`}))
  }, [clients])

  const [requaredPassportBuilders, setRequaredPassportBuilders] = useState([])
  useEffect(() => {
    if (builders) {
      setRequaredPassportBuilders(builders.filter(item => item.passportView === true))
    }
  },[builders])

  const [openPassportModal, hidePassportModal] = useModal(() => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick close={hidePassportModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="8.5" stroke="#2C2E3E" /><line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round" /><circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E" /></svg>
        Застройщики для которых важен паспорт:
      </div>
      <SimpleBar className="search-result" style={{ maxHeight: 200, display: "block", position: 'relative', boxShadow: 'none' }}>
        {requaredPassportBuilders.map((builder) => {
          return(
            <div style={{marginTop: 5, marginBottom: 5}}>{builder.name}</div>
          )
        })}
      </SimpleBar>

      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={hidePassportModal}>Понятно</Button>
      </div>
    </Modal>
  ), [requaredPassportBuilders]);

  const processValues = () => {
    const clientBirthday = getValue("clientBirthday");
    if (!clientBirthday) return;
    return { clientBirthday: formatDate(clientBirthday) }
  }

  const convertValues = () => {
    const dates = []
    const times = []
    const where = []
    Object.values(selectedDates).forEach(({ date, time }) => {
      dates.push(date)
      times.push(time)
    })
    const tmpWhereItems = { ...recordItem }
    selectedItems.forEach(key => {
      if (key in tmpWhereItems) return
      const index = resp[initialObjectKey].findIndex(({ id }) => id === key)
      const { flat, showRoom } = resp[initialObjectKey][index]?.where
      if (flat && showRoom) {
        tmpWhereItems[key] = ""
        return
      }
      tmpWhereItems[key] = flat ? "flat" : "showRoom"
    })
    Object.values(tmpWhereItems).forEach((v) => where.push(v));

    const tmpSelected = selectedItems ? [...selectedItems].sort((a, b) => a - b) : []

    return {
      ...(convertArrayToUniformData(initialObjectCodeName, tmpSelected) || []),
      ...(convertArrayToUniformData("date", dates) || []),
      ...(convertArrayToUniformData("time", times.map(item => item.replace(/C(\d{2}:\d{2})до(\d{2}:\d{2})/g, 'C $1 до $2'))) || []),
      ...(convertArrayToUniformData("where", where) || []),
    }
  }

  const submit = () => {
    send(
      processValues(),
      convertValues(),
      requiredFields,
    ).then(resp => {
      setSuccessMessage(resp.message)
      successToast(resp.message)
      setSelectedItems([])
      setSelectedDates({})
      setSelectedType({})
      setRecordItem({})
      setTimeout(setSuccessMessage, 5000)
      setValues({...emptyUniFormClient, comment: ""})
    }, standartErrorToast)
  }

  const selectDateTime = (val, key, subkey, error = "") => {
    setSelectedDates(prev => ({
      ...prev,
      [key]: prev[key] ? { ...prev[key], [subkey]: val, [`${subkey}Error`]: error } : { [subkey]: val, [`${subkey}Error`]: error  }
    }))
  }

  const selectDate = (val, key, subkey) => {
    const momentDate = val instanceof Date ? moment(val) : moment(val, 'DD.MM.YYYY', true);
    const isValidDate = momentDate?.isValid?.();
    const value = isValidDate ? momentDate.format("DD.MM.YYYY") : val;
    const isValidCount = val?.replace?.(/\D/gi, "")?.length < 8
    selectDateTime(value, key, subkey, isValidDate || isValidCount ? "" : "Некорректная дата")
  }

  const selectTime = (val, key, subkey) => {
    const strippedVal = val.replace(/\s/g, "");
    const isValidFormat = /^C\d{2}:\d{2}до\d{2}:\d{2}$/.test(strippedVal);
    let error = "";
    if (!isValidFormat) {
      error = "Некорректное время или интервал времени";
    } else {
      const [from, to] = strippedVal.substring(1).split("до").map(time => moment(time, "HH:mm", true));

      if (!from.isValid() || !to.isValid() || from.isSameOrAfter(to)) {
        error = "Неверный интервал времени";
      }
    }
    console.log(error);
    if (error !== "") {
      setIsDateError(true);
    } else {
      setIsDateError(false);
    }
    selectDateTime(strippedVal, key, subkey, error);
  };

  const onFilesChange = files => forcedSetValue(files, "passport")

  const { files, uploadFiles, deleteFile, editFileName, } = useFileManager({
    maxFiles: 10,
    onFilesChange
  });

  const [recordItem, setRecordItem] = useState({})

  const setSelectedWhere = (key, value) => {
    setRecordItem(prev => ({
      ...prev,
      [key]: value
    }))
  }
  const CustomRadio = ({recordItem, setRecordItem}) => {

    const handleOptionChange = (e) => {
      setRecordItem(e.target.value);
    };


    return (
      <div className="custom-radio">

        <label>
          <input
            type="radio"
            value="show"
            checked={recordItem === 'show'}
            onChange={handleOptionChange}
          />
          Шоурум
        </label>


        <label>
          <input
            type="radio"
            value="flat"
            checked={recordItem === 'flat'}
            onChange={handleOptionChange}
          />
          Квартира
        </label>

      </div>
    );
  };

  if (isLoading && !isSending) return <Preloader loading={true}/>;
  return (
    <div className="fixations subuebans-book">

          <div className="form__item sf__filter-item">

            <label
              className="input input_width_available input_type_form"
            >
              <Dropdown
                wrapperClass="custom-select_sf-filter custom-select_fixations"
                withSearch={normalizedClients?.length > 4}
                options={normalizedClients}
                options={clients}
                placeholder="Выберите клиента"
                value={getValue("clientId")}
                onChange={selectClient}
                searchPlaceholder="Введите ФИО клиента"
              />
            </label>
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              <span>Фамилия<span className="is-red-text sell__required">*</span></span>
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={`input__control${errors.clientLastName ? " input__error" : ""}`}
                value={getValue("clientLastName")}
                onChange={e => setValue(e.target.value, "clientLastName")}
              />
            </label>
            {errors.clientLastName && (
              <span className="input__error-label">
                            {errors.clientLastName}
                        </span>
            )}
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <span className="textarea__label">Комментарий</span>
                <textarea
                  style={{minHeight: 132}}
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  placeholder="По желанию Вы можете указать дополнительную информацию о записи на просмотр"
                  value={getValue("comment")}
                  onChange={e => setValue(e.target.value, "comment")}
                />
              </label>
            </div>
          </div>

          <div className="checkbox fixations__checkboxs">
            <div className="checkbox__box">
              <input
                className="checkbox__control"
                type="checkbox"
                id="data-id"
                name="remember"
                checked={!!getValue("personal_data", true)}
                onChange={e => setCheckboxValue(e, "personal_data")}

              />
              <label className="checkbox__input" htmlFor="data-id"/>
              <div className="checkbox__marker"/>
            </div>
            <label className="checkbox__label" htmlFor="data-id" style={{marginBottom: -3}}>
              Отправляя заявку вы соглашаетесь на обработку
              <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link link-red"> персональных
                данных</Link>
            </label>
          </div>


<div style={{textAlign: 'center', paddingBottom: 40}}>
  {successMessage && <NewAlert
    title={successMessage}
    bgColor="#DFF5E0"
    iconColor="#2CBA2E"
    oneLine
  />}
  <SubmitButton
    className="button button_view_default"
    type="button"
    isLoading={isSending}
    onClick={submit}
  >
    <span>Отправить заявку</span>
  </SubmitButton>
</div>



    </div>
  )
}



export default RecordViewingSuburban
