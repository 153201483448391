import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearFilterSecondaries,
  clearSecondaries,
  fetchSecondaries,
  getCount,
  setFilterSecondaries
} from "../../actions/secondaries";
import useDocTitle from "../../hooks/useDocTitle";
import { mapItemsSecondaries, mapSecondaries } from "../../selectors/secondaries";
import { filterToParams, scrollToRef } from "../../utils";
import { defaultFullFilter } from "../../utils/defaultFilters";
import Preloader from "../Preloader";
import Filter from "./Filter";
import Items from "./Items";


const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    setFilter,
    clearFilter,
    data,
    top,
    features,
    mapItems,
    getCount,
    countToShow,
    isAgent,
    clientMode,
    user,
    isShowFilter
  } = props;

  const itemsRef = useRef(null);
  const { savedFilterXmlId } = useParams();
  const handleFilter = (params) => {
    fetch("spb",0, params, savedFilterXmlId).then((data) => {
      scrollToRef(itemsRef, 80);
    });
  };
  useDocTitle("Вторичная недвижимость")
  useEffect(() => {
    let params= filterToParams(defaultFullFilter)
    const noStatistic = new URLSearchParams(window.location.search).get("noStatistic")
    // getCount(params, savedFilterXmlId);
    fetch("spb",1, {params, noStatistic}, savedFilterXmlId);
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <Filter
        filter={filter}
        filterData={filterData}
        setFilter={setFilter}
        clearFilter={clearFilter}
        onSubmit={(params) => {
          handleFilter(params);
        }}
        getCount={getCount}
        info={data}
        countToShow={countToShow}
        isShowFilter={isShowFilter}
        savedFilterXmlId={savedFilterXmlId}
        isPublic={true}
      />
      <Items
        items={data}
        innerRef={itemsRef}
        features={features}
        mapItems={mapItems}
        isAgent={isAgent}
        clientMode={clientMode} 
        savedFilterXmlId={savedFilterXmlId}
      />
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city,isGetFilter=0, form, savedFilterXmlId) => dispatch(fetchSecondaries(city,isGetFilter, form, savedFilterXmlId)),
  reset: () => dispatch(clearSecondaries()),
  setFilter: (filter) => dispatch(setFilterSecondaries({ filter })),
  clearFilter: () => dispatch(clearFilterSecondaries()),
  getCount: (params, savedFilterXmlId) => dispatch(getCount(params, savedFilterXmlId)),
});

const mapStateToProps = (state) => {
  return {
  filterData: state.secondaries.filterData,
  filter: state.secondaries.filter,
  isFetching: state.secondaries.isFetching,
  error: state.secondaries.error,
  data: state.secondaries.data,
  features: mapSecondaries(state),
  mapItems: mapItemsSecondaries(state),
  top: state.secondaries.top,
  countToShow: state.secondaries.countToShow,
  isShowFilter: state.secondaries.isShowFilter,
  isAgent: state?.user?.info?.groups?.some((group) => group === "agent"),
  clientMode: state?.general?.clientMode,
  user: state?.user?.info,
}};

export default connect(mapStateToProps, mapDispatchToProps)(List);
