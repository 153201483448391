import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import PanSchool from '../../components/forms/PanSchool';
import { media } from "../../utils";
import './index.scss';

const School = (props) => {

  const {user = {} } = props;
  const [blackout, setBlackout] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [openSchoolModal, closeSchoolModal] = useModal(
    () => (
      <Modal close={closeSchoolModal} classes="modal_task">
        <PanSchool
          title="Записаться в школу риелторов"
          hide={closeSchoolModal}
          userInfo={user.info}
          selectedCourse={selectedCourse}
        />
      </Modal>
    ),[selectedCourse]
  );

  const [openMobileSchoolModal, closeMobileSchoolModal] = useModal(
    () => (
      <MobileModal title="Школа риелторов" closeClick={closeMobileSchoolModal} classes="modal_task">
        <PanSchool
          title="Записаться в школу риелторов"
          hide={closeSchoolModal}
          userInfo={user.info}
          selectedCourse={selectedCourse}
        />
      </MobileModal>
    ),[selectedCourse]
  );

  const handleOpen = (course)=> {
    setSelectedCourse(course)
    media('isMobile') ? openMobileSchoolModal() : openSchoolModal()
  }
  return (
    <section className="school section section_view_white">

      <section className={`promo${blackout ? ` promo_blackout ` : ` `}school__section`}>
        <div className="wrapper">
          <div className="wrapper__center wrapper__center_start">
            <div className="promo__container">
              <div className="promo__box">
                <div className="promo__logo">
                  <span className="promo__label red-pill">Пан</span>

                  <img
                    className="promo__logo-svg"
                    src={require('../../assets/images/school/frame.svg').default}
                    alt=""
                  />
                </div>

                <h1 className="promo__title h1">Школа риелторов</h1>
                <span className="promo__sub-title h3">Идет набор!</span>

                <p className="promo__text big-text">
                  Цель обучения — передать знания и техники, чтобы ученики
                  сразу после курса могли войти в профессию и не теряли клиентов.
                </p>
              </div>
              <img
                className="promo__image"
                src={require('../../assets/images/school/promo.svg').default}
                alt=""
              />
            </div>
          </div>
        </div>

        <section className="tariff">
          <div className="wrapper">
            <ul className="tariff__list">
              {
                // Medium
              }
              <li className="tariff__item" onMouseEnter={() => setBlackout(true)} onMouseLeave={() => setBlackout(false)}>
                <h2 className="h2 tariff__title">Medium</h2>
                <span className="tariff__label">Online</span>

                <ul className="tariff__info">
                  <li className="tariff__detail tariff__detail_price">
                    Бесплатно
                  </li>
                  <li className="tariff__detail tariff__detail_duration">
                    1,5 часа
                  </li>
                </ul>

                <div className="tariff__descr">
                  <ul className="tariff__ul">
                    <li>
                      Узнайте путь клиента от первого обращения до заключения договора (звонок/встреча)
                    </li>
                    <li>
                      Портреты покупателей, цели покупки, частые возражения
                    </li>
                    <li>
                      Получите пошаговый план работы с клиентами (чек-листы, скрипты)
                    </li>
                  </ul>
                </div>
                <button type="button" className="tariff__btn button button_view_default" onClick={()=>handleOpen("medium")}>Записаться</button>

              </li>
              {
                // Medium well
              }
              <li className="tariff__item" onMouseEnter={() => setBlackout(true)} onMouseLeave={() => setBlackout(false)}>
                <h2 className="h2 tariff__title">Medium well</h2>
                <span className="tariff__label">Offline</span>

                <div className="tariff__info">

                    {
                    // <li className="tariff__detail tariff__detail_price">
                    //   14 990 руб.
                    // </li>
                    }
                    <li className="tariff__detail tariff__detail_duration">
                      5 дней с 10.00 до 18.00
                    </li>

                  {
                    // <div className="tariff__price">
                    //   <span className="tariff__old-price">14 990 руб.</span>
                    //   <span className="tariff__new-price">
                    //     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //       <path d="M10.6672 21.1971C16.052 21.1971 20.4172 16.8319 20.4172 11.4471C20.4172 6.06237 16.052 1.69714 10.6672 1.69714C5.28246 1.69714 0.917236 6.06237 0.917236 11.4471C0.917236 16.8319 5.28246 21.1971 10.6672 21.1971Z" strokeWidth="1.5" strokeMiterlimit="10"/>
                    //       <path d="M12.8965 9.49803C12.8965 9.49803 12.5065 7.96399 10.6994 7.96399C8.59338 7.96399 8.32037 9.44603 8.56738 10.018C8.91838 10.8241 9.5294 11.0061 11.2064 11.4611C13.2345 12.0071 13.3125 12.9041 13.3125 13.2811C13.3125 13.6581 13.0655 14.9582 10.6994 14.9582C8.33337 14.9582 7.91736 13.1641 7.91736 13.1641" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    //       <path d="M10.6994 7.96416V6.62512" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    //       <path d="M10.6994 16.2843V14.9453" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                    //     </svg>
                    //
                    //     7 499 руб. в ноябре
                    //   </span>
                    // </div>
                  }

                </div>
                <div className="tariff__descr">
                  <ul className="tariff__ul">
                    <li>
                      Овладейте практическими навыками и начните продавать сразу после обучения
                    </li>
                    <li>
                      Готовые кейсы отработанные на практике. Техники беспроигрышных переговоров на всех этапах касания с клиентом: звонок – встреча – показ – договор.
                    </li>
                  </ul>
                  {
                    //<p className="tariff__cashback">Лучшему ученику вернём стоимость обучения</p>
                  }
                </div>
                <button type="button" className="tariff__btn button button_view_default" onClick={()=>handleOpen("Medium well")}>Записаться</button>

              </li>
              {
                // Well Done
              }
              <li className="tariff__item" onMouseEnter={() => setBlackout(true)} onMouseLeave={() => setBlackout(false)}>
                <h2 className="h2 tariff__title">Well Done</h2>
                <span className="tariff__label">деловые поединки</span>

                <ul className="tariff__info">
                  {
                  // <li className="tariff__detail tariff__detail_price">
                  //   10 000 руб.
                  // </li>
                  }
                  <li className="tariff__detail tariff__detail_duration">
                    4 дня по 3 часа
                  </li>
                </ul>
                <div className="tariff__descr">
                  <ul className="tariff__ul">
                    <li>
                      Проработайте зоны роста
                    </li>
                    <li>
                      Заимствуйте опыт коллег
                    </li>
                    <li>
                      Сформируйте план действий для достижения результата
                    </li>
                    <li>
                      Сертификат повышения квалификации государственного образца
                    </li>
                  </ul>
                </div>
                <button type="button" className="tariff__btn button button_view_default" onClick={()=>handleOpen(">Well Done")}>Записаться</button>
              </li>
            </ul>
          </div>
        </section>
      </section>



      <section className="program school__section">
        <div className="wrapper">
          <div className="wrapper__center wrapper__center_start">
            <div className="program__box">
              <h2 className="h2 program__title">Из чего состоит обучение</h2>
              <p className="program__descr big-text">
                Изучите и закрепите на практике четкий алгоритм покупки новостройки с клиентом.
                Опирайтесь на кейсы экспертов, используйте готовые чек-листы и скрипты продаж.
              </p>
            </div>
            <ul className="program__list">
              <li className="program__item">
                <span className="program__counter counter">01</span>
                <h5 className="h5 program__subtitle">Пройдите обучение от ТОП-овых застройщиков с выездом на действующие объекты и в шоу-румы</h5>
                <p className="program__text text">Так вы изучите объекты, которые будете продавать, и заведете важные знакомства среди застройщиков для будущей работы.</p>
              </li>
              <li className="program__item">
                <span className="program__counter counter">02</span>
                <h5 className="h5 program__subtitle">Научитесь на практике подбирать объекты под любые запросы клиента</h5>
                <p className="program__text text">— отрабатывайте навыки с коллегами при поддержке куратора курса.</p>
              </li>
              <li className="program__item">
                <span className="program__counter counter">03</span>
                <h5 className="h5 program__subtitle">Освойте технику беспроигрышных переговоров на всех этапах касания с клиентом</h5>
                <ul className="program__ul">
                  <li>Звонок</li>
                  <li>Встреча</li>
                  <li>Показ</li>
                  <li>Договор</li>
                </ul>
              </li>
              <li className="program__item">
                <span className="program__counter counter">04</span>
                <h5 className="h5 program__subtitle">Преподаватели быстро дают обратную связь и указывают на ваши зоны роста</h5>
                <p className="program__text text">Ошибки в ходе настоящей работы будут стоить дорого вам, вашим клиентам и партнерам. В процессе нашего обучения можно и нужно ошибаться, чтобы быстро разобраться в причине ошибки, сделать выводы и отработать правильную схему работы.</p>
              </li>
            </ul>

          </div>
        </div>
      </section>
      <div className="wrapper">
        <div className="wrapper__center wrapper__center_start">
          <section className="sign school__section">
            <div className="sign__container">
              <p className="h3 sign__descr">
                Овладейте практическими навыками и начните продавать сразу после обучения
              </p>
              <button type="button" className="button button_view_default" onClick={()=>handleOpen("Консультация")}>Записаться</button>
            </div>
          </section>

          <section className="whom school__section">
            <h2 className="h2 whom__title">Кому подходит</h2>
            <ul className="whom__list">
              <li className="whom__item block">
                <img
                  className="block__img whom__img"
                  src={require('../../assets/images/school/whom-01.svg').default}
                  alt=""
                />
                <h4 className="block__title">Новички</h4>
                <p className="block__descr big-text">Будете готовы к работе в продажах новостроек</p>
                <p className="block__text text">Даем всю необходимую базу и ресурсы для эффективного входа в профессию.</p>
              </li>

              <li className="whom__item block">
                <img
                  className="block__img whom__img"
                  src={require('../../assets/images/school/whom-02.svg').default}
                  alt=""
                />
                <h4 className="block__title">Риелторы из аренды, вторички и коммерции</h4>
                <p className="block__descr big-text">Сможете построить свой бизнес на новостройках</p>
                <p className="block__text text">Освоите новую нишу, получите знания, инструменты и связи для дополнительного дохода.</p>
              </li>

              <li className="whom__item block">
                <img
                  className="block__img whom__img"
                  src={require('../../assets/images/school/whom-03.svg').default}
                  alt=""
                />
                <h4 className="block__title">Риелторы с опытом, которые хотят улучшить свой результат</h4>
                <p className="block__descr big-text">Попробуйте новые знания на практике</p>
                <p className="block__text text">Обменяйтесь опытом с коллегами, прокачайте зоны роста, получайте оперативную обратную связь от тренера.</p>
              </li>
            </ul>
          </section>

          <section className="sign sign_insta school__section">
            <div className="sign__container">
              <div className="sign__info">
                <h2 className="sign__title">Присоединяйся в Instagram</h2>
                <p className="sign__text big-text">Страница школы риелторов</p>
              </div>

              <a
                href="https://www.instagram.com/pan_school/"
                className="button button_view_default"
                target="_blank"
                rel="noopener noreferrer">
                Подписаться
              </a>
            </div>

          </section>
        </div>
      </div>


      <section className="know school__section">
        <div className="wrapper">
          <div className="wrapper__center wrapper__center_start">
            <h2 className="h2 know__title">Какие знания вы получите</h2>
            <div className="know__row">
              <div className="know__item">
                <span className="counter know__counter">01</span>
                <h4 className="h4 know__subtitle">Ведение переговоров</h4>
                <p className="know__text text">Как эффективно вести переговоры при продаже недвижимости на всех этапах касания с клиентом</p>
                <ul className="know__list">
                  <li><span>звонок</span></li>
                  <li><span>встреча-показ</span></li>
                  <li><span>письмо</span></li>
                </ul>
              </div>
              <div className="know__item">
                <span className="counter know__counter">02</span>
                <h4 className="h4 know__subtitle">Техника продаж</h4>
                <p className="know__text text">Путь от первого обращения клиента до заключения договора</p>
              </div>
              <div className="know__item">
                <span className="counter know__counter">03</span>
                <h4 className="h4 know__subtitle">Юридические аспекты</h4>
                <p className="know__text text">Условия оплаты, виды сделок, схема работы, регламенты взаимодействия</p>
              </div>
            </div>
            <div className="know__row">
              <div className="know__item">
                <span className="counter know__counter">04</span>
                <h4 className="h4 know__subtitle">Инструменты для работы</h4>
                <p className="know__text text">Готовые кейсы, скрипты и чек-листы</p>
              </div>
              <div className="know__item">
                <span className="counter know__counter">05</span>
                <h4 className="h4 know__subtitle">Основы профессии</h4>
                <p className="know__text text">Исчерпывающую информацию о рынке недвижимости</p>
              </div>
              <div className="know__item">
                <span className="counter know__counter">06</span>
                <h4 className="h4 know__subtitle">Оценка недвижимости</h4>
                <p className="know__text text">Сравнение жилых комплексов. Преимущества объектов недвижимости</p>
              </div>
            </div>
            <div className="know__row">
              <div className="know__item">
                <span className="counter know__counter">07</span>
                <h4 className="h4 know__subtitle">Практика</h4>
                <p className="know__text text">Отработка полученных знаний на практике</p>
              </div>
              <div className="know__item">
                <span className="counter know__counter">08</span>
                <h4 className="h4 know__subtitle">Инструменты для работы</h4>
                <p className="know__text text">Готовые кейсы под запрос клиента</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advantages school__section">
        <div className="wrapper">
          <div className="wrapper__center wrapper__center_start">
            <h2 className="h2 advantages__title">Преимущества</h2>

            <ul className="advantages__list">
              <li className="block advantages__item">
                <img
                  className="block__img advantages__img"
                  src={require('../../assets/images/school/advantages-01.svg').default}
                  alt=""
                />
                <h4 className="block__title">Практика</h4>
                <p className="block__descr big-text">Практической работы будет много</p>
                <p className="block__text text">
                  После каждого занятия вас ждет домашняя работа и проверка куратором.
                  В начале вам будет казаться, что мозг скоро взорвется.
                  Но чтобы вы не «закипели», мы разбавили обучение играми.
                  Обещаем, вы легко усвоите материал и войдете во вкус.
                </p>
              </li>
              <li className="block advantages__item">
                <img
                  className="block__img advantages__img"
                  src={require('../../assets/images/school/advantages-02.svg').default}
                  alt=""
                />
                <h4 className="block__title">Больше практики</h4>
                <p className="block__descr big-text">Личные встречи, деловые поединки, презентации – 60% от времени обучения</p>
                <p className="block__text text">
                  На время обучения вы получите доступ к действующей базе с более чем 40 000 квартир.
                  Мы научим с ней работать.
                  Наши ученики начинают заключать договоры уже через месяц после прохождения обучения.
                </p>
              </li>
              <li className="block advantages__item">
                <img
                  className="block__img advantages__img"
                  src={require('../../assets/images/school/advantages-03.svg').default}
                  alt=""
                />
                <h4 className="block__title">Поддержка после обучения</h4>
                <p className="block__descr big-text">Мы не бросаем учеников</p>
                <p className="block__text text">
                  У нас есть закрытая группа в соц. сетях, где мы отвечаем на вопросы и помогаем в работе с клиентами после обучения.
                  Партнерских продаж ПАН Партнер сопровождает агентства и частных риелторов на всех этапах сделки.
                </p>
              </li>
            </ul>
          </div>
        </div>

      </section>

      <div className="wrapper">
        <div className="wrapper__center wrapper__center_start">
          <section className="sign sign_materials school__section">
            <div className="sign__container">
              <p className="h3 sign__descr sign__descr_lg">
                У вас останется учебник со всеми материалами, которые пригодятся в работе.
                Вы сможете обратиться к нему в нестандартной ситуации
              </p>
              <button type="button" className="button button_view_default" onClick={()=>handleOpen("Консультация")}>Записаться</button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(School);
