import React, { useEffect, useRef, useState } from 'react';
import { getUrl } from '../../api';
import { imageTypes } from '../../utils';
import useMedia from "../../hooks/useMedia";
import axios from 'axios'
import fileUploadingImage from './fileUploading.gif'
const imageStyles = {
    border: "1px solid rgba(143, 149, 172, 0.2)",
    width: 94,
    height: 94,
    objectFit: "cover",
    borderRadius: "4px",
}
const imageStylesDocs = {
    border: "1px dashed #8F95AC",
    width: 94,
    height: 94,
    objectFit: "cover",
    borderRadius: "4px",
    padding: '13px',
    backgroundColor: '#ffffff',
    marginBottom: '8px'
}
const blankStyle = {
    width: 94,
    height: 94,
    objectFit: "cover",
    borderRadius: "4px",
}
const wrapperStyles = {
    display: "inline-flex",
    flexDirection: "column",
    position: "relative",
    cursor: "pointer"
}
const textStyles = {
    maxWidth: 94,
    overflow: "hidden",
    textOverflow: "ellipsis"
}

const isImage = (file) => {
    for (let type of imageTypes) {
        if (file?.type === type) {
            return true;
        }
    }
    return false;
}

const ImageItem = ({ file, removeFile, withBorder, deleteFileRequest, isLoading }) => {
    const [photo, setPhoto] = useState("")
    const [uploadedFile, setUploadedFile] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const isCrmFile = file?.src?.includes?.("file.ajax")
    useEffect(() => {
        if (isCrmFile && !uploadedFile) {
            setLoadingState("fileLoad")
            axios({
                method: 'get',
                withCredentials: true,
                url: getUrl(file.src),
            }).then(resp => {
                setUploadedFile(resp.data?.data)
                if(imageTypes.includes(resp.data?.data?.contentType)){
                    setPhoto(getUrl(resp.data?.data.src))
                }
            },
            err=>{
                setPhoto("")
                setUploadedFile(null)
            }).finally(()=> setLoadingState(false))
        }
        if (imageTypes?.includes(file?.contentType)) {
            setPhoto(getUrl(file.src))
            return
        }
        if (isImage(file)) {
            const reader = new FileReader();
            reader.onload = (e) => setPhoto(e.target.result)
            reader.readAsDataURL(file);
            return
        }
    }, [file])
    const handleDelete =(e, id )=> {
        e.stopPropagation()
        if(deleteFileRequest){
            setLoadingState("delete")
            return deleteFileRequest(id, file.fakeId).finally(()=> setLoadingState(false))
        } 
        removeFile(id)
    }
    const openFile = () =>uploadedFile && window.open(getUrl(uploadedFile?.src), "_blank")

    const getFileName = ()=>{
        if(loadingState === "fileLoad" || isLoading) return "Загрузка..."
        if(loadingState === "delete") return "Удаление..."
        return  uploadedFile ? uploadedFile.originalName : file?.name || file?.fileName;
    }
    const fileName = getFileName() 
    const TEXT_LIMIT = 8;
    const shortFileName = fileName?.length > 8 ? fileName.slice(0, TEXT_LIMIT) : fileName;
    if (loadingState || isLoading) return <div style={wrapperStyles}>
        <img src={fileUploadingImage} style={withBorder ? imageStylesDocs : imageStyles} />
        <span style={textStyles}>{fileName}</span>
    </div>
    return (
        <div style={wrapperStyles} onClick={openFile}>{photo ?
            <img src={photo} style={withBorder ? imageStylesDocs : imageStyles} /> :
            <img src={require('../../assets/images/paper.svg').default} style={blankStyle} />}
            <span style={textStyles}>{shortFileName}</span>
            <button className="button button_view_round-control file-up__del" style={{ flexShrink: 0, position: "absolute", top: "5px", right: "5px", background: "#f4f4f7" }} type="button" onClick={e => handleDelete(e, uploadedFile?.id)}>
                <svg className={`icon ${withBorder ? ' dark' : ''}`}>
                    <use xlinkHref="#x"></use>
                </svg>
            </button>
        </div>
    )
}

const FileButton = ({ loadFiles = () => { }, label, additionalWrapperClass = "", info, files, ident, removeFile, deleteFileRequest,  loadableFiles }) => {
    const isMobile = useMedia("isMobile");
    const isLaptop = useMedia("isLaptop");
    const fakeInputRef = useRef(null);
    
    return (
        <div className={`modal-form__item ${additionalWrapperClass}`} >
            <label
              style={additionalWrapperClass && additionalWrapperClass === 'modal-form__item--client-docs' && files && files.length > 0 ? {marginTop: 20} : {}}
              className="input input_width_available input_type_form"
                htmlFor="modal-assignation-sell-layout"
            >
                <input
                    className="input__control input__hidden"
                    type="file"
                    multiple
                    onChange={loadFiles}
                    ref={fakeInputRef}
                    key={files?.length}
                />
                {info ? <div className="input_type_upload-btn sell__button-with-info" onClick={() => fakeInputRef.current.click()}>
                    {label}
                    <div className="info">
                        <svg className="icon icon_info">
                            <use xlinkHref="#info"></use>
                        </svg>
                        <div className="info__popup">
                            {info}
                        </div>
                    </div>
                </div> :
                    <div className="input_type_upload-btn" onClick={() => fakeInputRef.current.click()}>
                        {
                            additionalWrapperClass === 'modal-form__item--client-docs' ?
                              <div className="modal-form__add-docs">
                                  <div className="modal-form__add-docs-logo">
                                      <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect x="0.5" width="40" height="40" rx="5" fill="white"/>
                                          <path d="M28.1483 12.4176C27.9325 12.2018 27.6398 12.0806 27.3346 12.0806H13.658C13.5063 12.0796 13.3559 12.1087 13.2154 12.1661C13.075 12.2235 12.9473 12.3081 12.8396 12.415C12.732 12.522 12.6466 12.6491 12.5883 12.7892C12.53 12.9293 12.5 13.0796 12.5 13.2313V26.9298C12.5 27.0816 12.53 27.2318 12.5883 27.3719C12.6466 27.512 12.732 27.6392 12.8396 27.7461C12.9473 27.8531 13.075 27.9377 13.2154 27.9951C13.3559 28.0525 13.5063 28.0815 13.658 28.0805H27.3346C27.6398 28.0805 27.9325 27.9593 28.1483 27.7435C28.3641 27.5277 28.4853 27.235 28.4853 26.9298V13.2313C28.4853 12.9261 28.3641 12.6334 28.1483 12.4176Z" stroke="#777A89" strokeMiterlimit="10" strokeLinecap="round"/>
                                          <path d="M28.5 22.9897L24.6539 19.1436C24.5185 19.0064 24.3342 18.9286 24.1415 18.9273C23.9488 18.9259 23.7634 19.0011 23.6261 19.1363L23.6188 19.1436L19.5642 23.1981C19.4973 23.2663 19.4175 23.3206 19.3295 23.358C19.2415 23.3953 19.147 23.4149 19.0515 23.4157C18.9559 23.4165 18.8611 23.3984 18.7725 23.3626C18.6839 23.3267 18.6033 23.2737 18.5352 23.2066L18.5279 23.1981L16.6539 21.3254C16.5185 21.1883 16.3342 21.1104 16.1415 21.1091C15.9488 21.1077 15.7634 21.1829 15.6261 21.3181L15.6176 21.3254L12.5 24.4442" stroke="#777A89" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M17.9547 17.8987C18.5572 17.8987 19.0456 17.4103 19.0456 16.8078C19.0456 16.2053 18.5572 15.7169 17.9547 15.7169C17.3522 15.7169 16.8638 16.2053 16.8638 16.8078C16.8638 17.4103 17.3522 17.8987 17.9547 17.8987Z" fill="#777A89"/>
                                      </svg>
                                  </div>
                                  <div className="modal-form__add-docs-overtext">
                                      <div className="modal-form__add-docs-text">
                                          {label}
                                      </div>
                                      <div className="modal-form__add-docs-hint">
                                          JPG, PNG, PDF, DOC не более 10 Mb
                                      </div>
                                  </div>
                              </div> :
                              label
                        }
                    </div>}
            </label>

                <div style={
                    additionalWrapperClass === 'modal-form__item--client-docs' ?
                      {
                        display: 'grid',
                        gridTemplateColumns: '94px 94px 94px 94px 94px 94px',
                        gap: '20px',
                        marginBottom: '0px',
                        marginTop: '0px',
                        order: -1,
                      } : {
                        display: 'grid',
                        gridTemplateColumns: isLaptop && !isMobile ? '94px 94px' : '94px 94px 94px',
                        gap: '20px',
                        marginTop: '20px',
                      }
                }>

                {files.map((file, i) => <ImageItem
                    withBorder={additionalWrapperClass === 'modal-form__item--client-docs'}
                    key={file.fakeId}
                    file={file}
                    removeFile={id => removeFile(ident, i, id)}
                    deleteFileRequest={deleteFileRequest}
                />)}
                {loadableFiles && loadableFiles.map((file, i) => <ImageItem
                    withBorder={additionalWrapperClass === 'modal-form__item--client-docs'}
                    key={file.fakeId}
                    file={file}
                    removeFile={id => removeFile(ident, i, id)}
                    deleteFileRequest={deleteFileRequest}
                    isLoading={true}
                />)}
            </div>

        </div>
    )
}

export default FileButton
