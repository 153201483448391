import { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

const VillagesPublicFilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass="sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>
    <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("prices")}
      clearExcludeOnly={() => clearByIdent("prices", "exclude")}
    />
    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        autocompleteTitle: "",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <EndingsFilter
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("materials")}
      data={{
        title: "Материал дома",
        autocompleteTitle: "",
        identity: "materials",
      }}
      wrapperClass={wrapperClass}

    />
    <SelectFilter
      {...createSelectProps("features")}
      data={{
        title: "Особенности",
        autocompleteTitle: "Например, озеро или лес",
        identity: "features",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("communications")}
      data={{
        title: "Коммуникации",
        autocompleteTitle: "Например, электричество",
        identity: "communications",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("villages")}
      data={{
        title: "Название КП",
        autocompleteTitle: "Введите название КП",
        identity: "villages",
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      {...createSelectProps("countFloors")}
      onChange={v => setFilterAndExclude(v, "countFloors")}
      filter={filter?.countFloors ?? { min: "", max: "" }}
      items={filterData.countFloors?.items ?? []}
      data={{
        title: "Этажность",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.countFloors?.range ?? null,
      }}
      wrapperClass={wrapperClass}
    />
    {/* скрыто тк пока высоток нет */}
    {false && <FloorsFilter
      {...createSelectProps("floors")}
      filter={filter?.floors}
      data={{
        title: "Этаж",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.floors?.range ?? null,
      }}
    />}
    <SelectFilter
      {...createSelectProps("nearby")}
      data={{
        title: "Рядом",
        autocompleteTitle: "",
        identity: "nearby",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("purpose")}
      data={{
        title: "Статус земли",
        autocompleteTitle: "",
        identity: "purpose",
      }}
      wrapperClass={wrapperClass}
    />
    {/* <div className="form__row">
      <div className="index-search__show-booked">
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status.includes("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            ></div>
            <div className="switch__marker"></div>
          </div>
          <span className="switch__label">
            Показать забронированные
          </span>
        </label>
      </div>
    </div> */}
  </>
});

export default VillagesPublicFilter;