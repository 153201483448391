import React, { useMemo } from 'react';
import { makeComa } from '../../../utils';

export const InfoItem = ({ title, value, hide, dataId = "none" }) => {
  if (!value || hide) return null
  return <div className="presentation__info-grid" data-id={dataId}>
    <div className="presentation__info-col">
      {title}
    </div>
    <div className="presentation__info-col">
      {value}
    </div>
  </div>
}


const ComplexInfo = ({ data: { object, typeXmlId }, activeSettings, isCommercial }) => {
  const isSuburban = typeXmlId === "suburban"
  const isAssignmnent = typeXmlId === "apartments_for_assignment"
  const isApartments = typeXmlId === "apartments"
  const title = useMemo(() => {
    if (isSuburban && activeSettings?.complex_name) {
      return `О «${object?.blockName}»`
    }
    if (isSuburban) {
      return "О ЖК"
    }
    if (!activeSettings?.complex_name) {
      return `Информация о ЖК`
    }
    return `Информация о ЖК «${object?.blockName}»`

  }, [activeSettings, isSuburban])

  return <div className="presentation__info pdf-block presentation__info_flat">
    <div className="presentation__box" data-id="house_characteristics">
      <div className="presentation__title">{title}</div>
      <InfoItem title="Адрес" value={object?.address} />
      <InfoItem title={isSuburban ? "Количество объектов" : "Квартир в продаже"} value={object.countObjects} hide={isCommercial} />
      <InfoItem
        title="Типы объектов"
        value={object?.village?.types?.map?.(makeComa)}
      />
      <InfoItem title="Стоимость жилой недвижимости" value={object?.village?.minPrice} />
      <InfoItem title="Стоимость участков" value={object?.village?.minPriceParcel} />
      <InfoItem
        title="Статус земли"
        value={object?.village?.purpose?.map?.(makeComa)}
      />
      <InfoItem title="Застройщик" value={object?.builderName} dataId="builder" />
      <InfoItem title="Класс жилья" value={object?.class} />
      <InfoItem
        title="Отделка"
        value={object?.decorations?.map?.(makeComa)}
        hide={isCommercial || object?.decorations?.length === 0}
      />
      <InfoItem
        title={isSuburban ? "Материалы" : "Тип дома"}
        value={isSuburban ? object?.village?.materials?.map?.(makeComa) : object?.buildingTypeName?.map?.(makeComa)}
        hide={isCommercial}
      />
      <InfoItem
        title={"Отделка"}
        value={isSuburban ? object?.village?.decorations?.map?.(makeComa) : object?.buildingTypeName?.map?.(makeComa)}
        hide={isCommercial || isAssignmnent || isApartments || object?.buildingTypeName?.length === 0}
      />
      <InfoItem title="Прописка" value={object?.registration} hide={!object.registration} />
      <InfoItem title="Оплата" value={object?.paymentType} hide={!object.paymentType} />
      <InfoItem title="Срок сдачи" value={isSuburban ? object?.village?.ending : object?.endingDate} hide={isSuburban} />
      <InfoItem
        title="Коммуникации"
        value={object?.village?.communications?.map?.(makeComa)}
      />
      
      <InfoItem title="Вентиляция" value={`${object.ventilation} мм`}  hide={!object.ventilation} />
    </div>
  </div>
};


export default ComplexInfo;