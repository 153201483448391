import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from 'react-redux';
import { sendLeadMagnet } from '../../../../actions/forms';
import request, { getUrl } from '../../../../api';
import useDocTitle from "../../../../hooks/useDocTitle";
import useMedia from '../../../../hooks/useMedia';
import { applyToFormData, downloadLink, generateConstructorMarkdown, iOSSafari, sendConstructorEvent, sendVKEvent } from "../../../../utils";
import { getFormWithUTM, getSubdomain } from '../../../../utils/location';
import Preloader from '../../../Preloader';
import { errorToast, standartErrorToast } from "../../../toasts";


const LidPageHeader = ({title, phone}) => {
  return (
    <div className="lp__header">
      <span className="bold lp__name">{title}</span>
      {
      phone &&
      <a className="bold lp__phone" href={`tel:${phone}`}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.426 4.6676C9.67435 4.96575 9.80098 5.34667 9.78059 5.73416C9.76019 6.12166 9.59427 6.48717 9.316 6.7576C9.186 6.9176 9.046 7.0876 8.876 7.2676L8.596 7.5476L7.826 8.3176C8.21818 9.39867 8.8789 10.3622 9.746 11.1176C10.5014 11.9847 11.4649 12.6454 12.546 13.0376L13.316 12.2676C13.6188 11.9561 13.954 11.6778 14.316 11.4376C14.5838 11.2094 14.9241 11.084 15.276 11.084C15.6279 11.084 15.9682 11.2094 16.236 11.4376C17.4833 12.2679 18.6666 13.1905 19.776 14.1976C20.2413 14.6973 20.5 15.3548 20.5 16.0376C20.5 16.7204 20.2413 17.3779 19.776 17.8776C19.466 18.2076 19.076 18.6076 18.606 19.0476C15.796 21.8676 9.866 20.1576 5.316 15.5976C0.766001 11.0376 -1.024 5.1176 1.786 2.3076L2.946 1.1476C3.44791 0.678718 4.10915 0.417908 4.796 0.417908C5.48286 0.417908 6.14409 0.678718 6.646 1.1476C7.65709 2.25172 8.58623 3.42818 9.426 4.6676ZM14.726 13.6676L13.966 14.4276C12.656 15.7376 10.436 14.6276 8.336 12.5176C6.236 10.4076 5.086 8.2076 6.426 6.8976L7.196 6.1376L7.426 5.8876C7.50887 5.80663 7.58574 5.71973 7.656 5.6276C6.91882 4.5572 6.11384 3.53511 5.246 2.5676C5.11618 2.47024 4.95828 2.4176 4.796 2.4176C4.63372 2.4176 4.47582 2.47024 4.346 2.5676L3.206 3.7176C1.426 5.5176 2.776 10.3076 6.656 14.1876C10.536 18.0676 15.316 19.4176 17.126 17.6276C17.586 17.1876 17.956 16.8076 18.246 16.5076C18.3552 16.3792 18.4152 16.2162 18.4152 16.0476C18.4152 15.8791 18.3552 15.716 18.246 15.5876C17.2992 14.7294 16.2971 13.9344 15.246 13.2076C15.0506 13.3532 14.8667 13.5137 14.696 13.6876L14.726 13.6676Z" className="lp__phone-svg" />
        </svg>
        {phone}
      </a>
      }

    </div>
  )
};

const LidPageSVG = () => {
  return (
    <div className="lp__svg">
    <svg width={785} height={814} viewBox="0 0 785 814" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.1" width={785} height={814} fill="#8F95AC" />
      <path d="M676.5 271.02H513.5H418.5L513.5 283.02L466.5 296.02H739.5L638.5 283.02L676.5 271.02Z" fill="white" />
      <path d="M201.131 76H143.348H48.3479L143.348 88L96.3479 101H219.131L163.131 88L201.131 76Z" fill="white" />
      <path d="M648.056 546.544H339.71H160L339.71 569.244L250.801 593.836H767.232L576.172 569.244L648.056 546.544Z" fill="white" />
      <path opacity="0.4" d="M673.824 431.676H625.453H445.743L557 454.376L484 478.968L723 478.968L660 454.376L673.824 431.676Z" fill="white" />
      <path opacity="0.4" d="M444.237 296.02H395.867H216.157L327.414 318.72L254.414 343.312L493.414 343.312L430.414 318.72L444.237 296.02Z" fill="white" />
      <path opacity="0.4" d="M702.281 646.113V525.141H686.366V510.47H641.475V457.056H638.015V510.47H624.088V525.141H608.174V583.795L553.548 638.442V814.139H733.401V646.129L702.281 646.113ZM586.828 666.411H577.911V640.362H586.828V666.411ZM681.549 611.967H672.632V585.945H681.549V611.967ZM681.549 575.827H672.632V549.805H681.549V575.827ZM711.203 729.421H702.286V703.399H711.203V729.421ZM711.203 692.469H702.286V666.447H711.203V692.469Z" fill="#DBDCE4" />
      <path d="M489.16 751.639C486.6 749.29 483.407 747.745 479.977 747.197C476.547 746.649 473.031 747.121 469.867 748.555C468.112 742.037 464.489 736.176 459.444 731.696C454.398 727.216 448.153 724.313 441.478 723.345C445.858 717.099 448.105 709.605 447.885 701.977C447.462 693.303 443.765 685.113 437.54 679.062C431.316 673.012 423.028 669.553 414.354 669.386C405.679 669.218 397.264 672.353 390.81 678.158C384.357 683.963 380.346 692.004 379.589 700.656C375.616 699.188 371.401 698.491 367.167 698.6C357.995 698.969 349.344 702.969 343.117 709.721C336.89 716.472 333.597 725.423 333.961 734.604C334.075 738.45 334.81 742.252 336.137 745.863C333.036 751.428 331.499 757.729 331.687 764.098C331.76 768.646 332.728 773.135 334.535 777.309C336.341 781.483 338.952 785.26 342.217 788.424C345.483 791.588 349.338 794.077 353.565 795.749C357.791 797.421 362.305 798.243 366.849 798.169C372.591 798.011 378.193 796.367 383.11 793.396C383.367 793.723 383.645 794.034 383.942 794.326C386.46 796.619 389.484 798.284 392.766 799.188C396.049 800.091 399.498 800.206 402.834 799.524C406.169 798.842 409.297 797.382 411.962 795.262C414.628 793.143 416.757 790.424 418.175 787.326C422.791 790.464 428.121 792.392 433.676 792.931C439.23 793.469 444.831 792.603 449.963 790.41C450.513 792.888 451.8 795.141 453.655 796.871C454.927 798.029 456.414 798.925 458.031 799.507C459.648 800.089 461.364 800.347 463.081 800.265C464.798 800.183 466.482 799.763 468.036 799.03C469.591 798.296 470.986 797.264 472.142 795.99C473.749 794.27 474.872 792.154 475.397 789.858C475.921 787.562 475.827 785.168 475.125 782.92C477.853 783.099 480.587 782.665 483.126 781.648C485.665 780.632 487.944 779.059 489.796 777.046C491.425 775.338 492.7 773.325 493.55 771.122C494.399 768.919 494.806 766.571 494.747 764.21C494.688 761.85 494.164 759.525 493.206 757.368C492.247 755.21 490.872 753.264 489.16 751.639Z" fill="#DBDCE4" />
      <path d="M406.267 807.298C406.071 809.893 405.973 811.924 405.973 813.882H427.638C440.597 803.421 452.721 791.964 463.901 779.616C459.5 784.511 440.745 796.333 437.003 800.102C435.292 799.099 438.96 788.354 439.204 780.277C439.204 780.277 430.695 795.256 433.335 802.892C428.458 806.305 423.334 809.349 418.004 811.998C417.026 799.637 416.243 791.119 415.559 785.221C415.412 783.752 430.01 757.661 429.252 758.591C423.261 766.178 420.498 767.549 415.436 776.507C413.822 779.371 409.959 753.598 409.959 753.598C407.905 755.237 410.252 772.86 409.47 776.776C408.982 779.651 408.663 782.553 408.516 785.465C408.296 789.063 407.758 797.703 407.318 801.228C403.479 800.322 396.094 791.731 386.851 774.965C386.9 775.039 394.016 803.725 406.267 807.298Z" fill="#8F95AC" />
      <path d="M403.479 813.882H413.26C402.208 808.693 366.629 799.588 353.645 788.868C353.621 788.819 358.731 801.84 403.479 813.882Z" fill="#8F95AC" />
      <path d="M-260.508 116.359L307.724 205.109V814H-260.508L-260.508 116.359Z" className="fill-1" />
      <path opacity="0.4" d="M-260.508 174.775C-260.508 144.084 -233.115 120.638 -202.792 125.374L265.44 198.505C289.78 202.306 307.724 223.271 307.724 247.906V764C307.724 791.614 285.338 814 257.724 814H-210.508C-238.122 814 -260.508 791.614 -260.508 764L-260.508 174.775Z" fill="url(#paint0_linear_4062_11239)" />
      <path d="M166.189 379.277L185.923 372.662V313.859L166.189 320.474V379.277Z" fill="#DBDCE4" />
      <path d="M166.276 379.24L307.724 394V403.755L365.939 389.017L185.883 372.667L166.276 379.24Z" fill="#A8ABCE" />
      <path d="M181.452 315.385V249.822L266.002 259.345V321.245L185.863 313.92L181.452 315.385Z" fill="#8F95AC" />
      <path d="M181.452 460.978V395.598L266.002 402.557V464.274L185.863 459.513L181.452 460.978Z" fill="#8F95AC" />
      <path d="M181.452 256.415L256.811 264.656V320.513L266.002 321.245V259.345L181.452 249.822V256.415Z" fill="#DBDCE4" />
      <path d="M221.704 257.697H216.558V318.682H221.704V257.697Z" fill="#DBDCE4" />
      <path d="M226.851 318.682V304.031L252.583 283.52V321.246L226.851 318.682Z" fill="#AFB3C4" />
      <path d="M181.452 402.191L256.811 407.868V464.824L266.002 465.373V402.557L181.452 395.598V402.191Z" fill="#DBDCE4" />
      <path d="M221.704 462.443L216.558 461.894V400.909H221.704V462.443Z" fill="#DBDCE4" />
      <path d="M226.851 463.358V447.059L252.583 426.731V464.274L226.851 463.358Z" fill="#AFB3C4" />
      <path d="M181.452 610.052V540.094L266.002 547.053V608.953L185.863 610.052H181.452Z" fill="#8F95AC" />
      <path d="M181.452 546.687L256.811 548.152V609.32L266.002 610.052V542.841L181.452 540.094V546.687Z" fill="#DBDCE4" />
      <path d="M221.704 611.151L216.558 610.602V545.405H221.704V611.151Z" fill="#DBDCE4" />
      <path d="M226.851 610.784V591.738L252.583 571.41V611.7L226.851 610.784Z" fill="#AFB3C4" />
      <path d="M181.452 758.21V688.252L266.002 695.211V757.111L185.863 758.21H181.452Z" fill="#8F95AC" />
      <path d="M181.452 694.295H256.811V757.66L266.002 758.21V688.252H181.452V694.295Z" fill="#DBDCE4" />
      <path d="M221.704 759.491L216.558 758.759V693.745H221.704V759.491Z" fill="#DBDCE4" />
      <path d="M226.851 758.942V739.896L252.583 719.567V759.857L226.851 758.942Z" fill="#AFB3C4" />
      <path d="M365.99 389.006L185.863 372.707V313.92L365.99 330.219V389.006Z" fill="white" />
      <path d="M166.176 521.819L185.843 518.5V459.51L166.176 462.828V521.819Z" fill="#DBDCE4" />
      <path d="M166.349 673.234L186.016 669.938V607.305L166.349 610.601V673.234Z" fill="#DBDCE4" />
      <path d="M365.99 528.739L185.863 518.454V459.5L365.99 469.785V528.739Z" fill="white" />
      <path d="M365.99 673.967L185.863 669.754V607.305L365.99 612.433V673.967Z" fill="white" />
      <path d="M164.904 521.567L307.719 531.09V540.613L366 528.692L184.571 518.454L164.904 521.567Z" fill="#A8ABCE" />
      <path d="M166.411 673.233L307.695 676.628V681.673L366 673.966L186.058 669.754L166.411 673.233Z" fill="#A8ABCE" />
      <path d="M186.016 755.462H166.349V813.882H186.016V755.462Z" fill="#DBDCE4" />
      <path d="M365.99 755.462H185.863V813.882H365.99V755.462Z" fill="white" />
      <path opacity="0.4" d="M477.429 448.199V417.326H436.601V385.682H433.433V417.326H413.26V815H531.475V448.199H477.429ZM439.102 657.493H430.937V436.295H439.102V657.493ZM453.869 657.493H445.704V436.295H453.869V657.493ZM485.55 597.883H477.386V574.094H485.55V597.883ZM485.55 525.418H477.386V501.629H485.55V525.418ZM485.55 492.38H477.386V468.591H485.55V492.38Z" fill="#DBDCE4" />
      <path d="M449.757 239.021C449.359 238.195 448.968 237.473 448.661 236.716C448.273 235.777 447.938 234.816 447.659 233.84C447.486 233.221 447.514 232.558 448.218 232.024L451.845 237.749C453.261 237.083 454.722 236.279 455.644 234.766C455.767 234.569 456.014 234.437 456.203 234.273C456.358 234.525 456.618 234.766 456.65 235.033C456.819 236.454 456.266 237.64 455.282 238.638C454.916 239.01 454.478 239.315 453.987 239.717L455.122 241.13C454.342 241.037 453.527 240.968 452.73 240.841C451.667 240.673 450.609 240.465 449.553 240.258C449.328 240.215 449.073 240.16 448.91 240.014C448.727 239.85 448.605 239.63 448.565 239.389C448.554 239.303 448.892 239.144 449.091 239.074C449.266 239.019 449.459 239.043 449.757 239.021Z" fill="#8F95AC" />
      <path d="M610.4 175.427C610.434 174.894 610.494 174.413 610.497 173.932C610.503 173.149 610.345 172.868 609.602 172.631C609.02 172.434 608.413 172.328 607.801 172.317C604.458 172.329 601.115 172.34 597.823 171.598C596.999 171.399 596.193 171.126 595.416 170.781C594.776 170.507 594.192 170.1 593.965 169.206C597.784 171.194 601.544 170.79 605.394 169.445C605.243 169.275 605.101 169.185 605.082 169.081C605.01 168.699 604.838 168.239 604.976 167.943C605.094 167.692 605.573 167.577 605.922 167.497C606.17 167.453 606.427 167.493 606.652 167.611C607.939 168.289 609.132 167.788 610.298 167.337C613.531 166.088 616.135 163.934 618.428 161.376C619.36 160.335 620.211 159.219 621.204 158.007C621.13 158.628 621.129 159.146 621.003 159.63C620.178 162.822 618.693 165.684 616.706 168.28C616.11 169.059 615.268 169.395 614.357 169.617C613.867 169.737 613.269 169.757 613.286 170.501C613.305 171.222 613.682 171.654 614.515 171.706C615.312 171.755 616.119 171.662 616.923 171.621C617.287 171.601 617.646 171.52 618.009 171.515C618.226 171.511 618.446 171.602 618.665 171.65C618.593 171.879 618.57 172.143 618.441 172.327C618.217 172.664 617.946 172.966 617.637 173.223C616.818 173.848 615.999 174.487 615.119 175.023C613.909 175.761 612.536 175.608 611.192 175.528C610.929 175.513 610.668 175.461 610.4 175.427Z" fill="#8F95AC" />
      <path d="M580.864 488.237C580.83 487.705 580.769 487.223 580.767 486.743C580.761 485.959 580.919 485.678 581.662 485.442C582.244 485.245 582.851 485.139 583.463 485.127C586.806 485.139 590.149 485.151 593.441 484.408C594.265 484.21 595.071 483.936 595.848 483.591C596.488 483.317 597.071 482.911 597.299 482.017C593.48 484.005 589.72 483.6 585.869 482.256C586.021 482.086 586.163 481.995 586.182 481.892C586.254 481.509 586.426 481.05 586.287 480.753C586.17 480.503 585.691 480.387 585.342 480.307C585.094 480.264 584.837 480.304 584.612 480.421C583.325 481.099 582.132 480.598 580.966 480.147C577.733 478.898 575.129 476.744 572.836 474.187C571.904 473.146 571.053 472.029 570.06 470.817C570.134 471.439 570.135 471.957 570.261 472.44C571.085 475.632 572.571 478.494 574.558 481.09C575.153 481.869 575.996 482.205 576.907 482.428C577.397 482.548 577.995 482.567 577.977 483.311C577.959 484.032 577.582 484.465 576.749 484.516C575.952 484.565 575.145 484.472 574.341 484.431C573.977 484.411 573.618 484.33 573.255 484.325C573.038 484.322 572.818 484.412 572.599 484.461C572.671 484.689 572.694 484.953 572.823 485.137C573.047 485.474 573.318 485.776 573.627 486.034C574.446 486.658 575.264 487.297 576.145 487.833C577.355 488.572 578.728 488.418 580.072 488.339C580.335 488.323 580.596 488.272 580.864 488.237Z" fill="#8F95AC" />
      <defs>
        <linearGradient id="paint0_linear_4062_11239" x1="25.3498" y1="824.283" x2="20.4465" y2="133.839" gradientUnits="userSpaceOnUse">
          <stop offset="0.02" stopColor="#2C2E3E" />
          <stop offset="0.25" stopColor="#2C2E3E" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  </div>
  );
};

const LidPageSVG2 = () => {
  return (
    <svg width="286" height="225" viewBox="0 0 286 225" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M258.307 60.4376L210.412 35.3142C195.369 27.4234 176.795 33.1889 168.925 48.192L142.975 97.6634C135.105 112.666 140.92 131.226 155.963 139.116L203.858 164.24C218.901 172.131 237.475 166.365 245.345 151.362L271.295 101.891C279.165 86.8876 273.35 68.3285 258.307 60.4376Z" className="fill-1"/>
      <path opacity="0.1" d="M116.35 21.0209L184.609 56.8262C205.805 67.9444 213.996 94.0929 202.909 115.229L165.925 185.735C154.838 206.871 128.668 214.996 107.473 203.878L39.214 168.072C18.0185 156.954 9.82703 130.806 20.9142 109.669L57.8981 39.1635C68.9852 18.0271 95.1546 9.90269 116.35 21.0209Z" className="stroke-1"/>
      <path d="M172.99 93.9445H129.01C115.197 93.9445 104 105.112 104 118.889V164.316C104 178.092 115.197 189.26 129.01 189.26H172.99C186.803 189.26 198 178.092 198 164.316V118.889C198 105.112 186.803 93.9445 172.99 93.9445Z" className="fill-1"/>
      <path opacity="0.4" d="M172.99 93.9445H129.01C115.197 93.9445 104 105.112 104 118.889V164.316C104 178.092 115.197 189.26 129.01 189.26H172.99C186.803 189.26 198 178.092 198 164.316V118.889C198 105.112 186.803 93.9445 172.99 93.9445Z" fill="url(#paint0_linear_4082_11242)"/>
      <g filter="url(#filter0_d_4082_11242)">
      <path d="M181.029 122.225C180.334 121.51 179.504 120.939 178.587 120.544C177.671 120.149 176.685 119.937 175.687 119.922C174.688 119.907 173.696 120.088 172.768 120.455C171.84 120.822 170.993 121.368 170.276 122.062L146.37 145.186L136.069 135.208C134.62 133.81 132.672 133.042 130.655 133.076C128.638 133.109 126.717 133.94 125.315 135.386C123.912 136.832 123.143 138.774 123.176 140.786C123.21 142.797 124.043 144.713 125.493 146.112L141.036 161.143C142.446 162.511 144.335 163.278 146.302 163.282H146.402C148.378 163.284 150.276 162.516 151.692 161.143L180.849 132.949C181.567 132.257 182.141 131.431 182.539 130.517C182.936 129.603 183.15 128.62 183.166 127.625C183.183 126.629 183.003 125.639 182.636 124.713C182.269 123.787 181.723 122.941 181.029 122.225Z" className="fill-1"/>
      </g>
      <path d="M146.418 163.282C144.904 163.282 143.425 162.832 142.169 161.988C140.913 161.145 139.939 159.947 139.37 158.547C138.802 157.148 138.665 155.611 138.977 154.134C139.289 152.657 140.037 151.306 141.124 150.255L170.276 122.062C171.724 120.661 173.67 119.892 175.687 119.923C177.703 119.953 179.625 120.782 181.029 122.225C182.433 123.669 183.205 125.61 183.174 127.621C183.143 129.632 182.312 131.549 180.865 132.949L151.708 161.143C150.292 162.516 148.394 163.284 146.418 163.282Z" fill="white"/>
      <path d="M146.302 163.282C144.326 163.284 142.428 162.516 141.012 161.143L125.493 146.112C124.762 145.422 124.177 144.595 123.769 143.677C123.362 142.76 123.141 141.771 123.12 140.768C123.098 139.765 123.276 138.767 123.644 137.834C124.012 136.9 124.562 136.048 125.262 135.328C125.963 134.607 126.799 134.033 127.724 133.637C128.648 133.242 129.642 133.033 130.648 133.024C131.654 133.014 132.652 133.203 133.584 133.581C134.516 133.959 135.364 134.518 136.077 135.224L151.62 150.255C152.708 151.307 153.456 152.66 153.768 154.14C154.08 155.619 153.941 157.158 153.369 158.558C152.797 159.959 151.819 161.157 150.56 161.998C149.301 162.84 147.818 163.287 146.302 163.282Z" fill="white"/>
    </svg>
  );
};

const TelegramInput = props => {
  const { placeholder, value, changeHandler, isMobile } = props
  const [validValue, setValidValue] = useState("")
  const patterns = {
    "telegram": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_0-9a-zA-Z]{0,}`),
  }
  const telegramInputFocus = (e) => {
    if (e.target.value) {
      return
    }
    setValidValue(placeholder)
    changeHandler(placeholder)
  }
  const telegramBlur = (e) => {
    if (e.target.value === placeholder) {
      setValidValue("")
      changeHandler("")
    }
  }

  const setSocialValue = (e) => {
    let val = e.currentTarget.value
    if (val && !val.startsWith(placeholder)) {
      changeHandler(`${placeholder}${val}`)
      val = `${placeholder}${val}`
    }
    let match = val.match(patterns["telegram"])
    if (match && match[0] === val) {
      setValidValue(val)
      changeHandler(val)
    } else {
      changeHandler(validValue)
    }
  }
  return <input
    type="text"
    className={`admin__input`}
    placeholder={placeholder}
    value={value}
    onFocus={telegramInputFocus}
    onChange={e => setSocialValue(e)}
    onBlur={e => telegramBlur(e)}
    id={`lid-telegram`}
  />
}

const LidPage = (props) => {
  const { handleSubmit, register } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");

  const {setFullScreenLoading} = props;
  const subDomain = getSubdomain();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const agencyData = useSelector(state=>state.agency);
  

  useEffect(() => {
    document.body.classList.add('lid-page');
    request("panpartner:agent.sites.ajax", "getSite", { subDomain })
    .then(resp => {
      setData(resp?.site || [])
      dispatch({
        type: "AGENCY_SET_STATE", payload: resp?.site
      })
    })
    .finally(() => {
      setIsLoading(false)
      setFullScreenLoading(false)
      
    })
  }, []);
  
  const htmlTitle = useMemo(() =>generateConstructorMarkdown(data?.leadMagnet?.title, agencyData.theme.colorHash || "red"), [data, agencyData])

  const isAgency = !!agencyData.agency.name;
  const isAnyPhone = (agencyData.agency.phone || data.phoneAgency);
  const leadMagnetData = data?.leadMagnet;
  const fields = leadMagnetData?.fields;
  const leadMagnetType = leadMagnetData?.type;
  const reviewsTitle = isAgency ? `${agencyData.agency.name}` : `${agencyData.agent.name} ${agencyData.agent.lastName}`;

  let leadMagnetLink = leadMagnetData?.link;

  if (!/^https?:\/\//i.test(leadMagnetLink)) {
    leadMagnetLink = 'http://' + leadMagnetLink;
}
  useDocTitle(leadMagnetData?.title?.replace?.(/\[|\]/g, ""));

  const [fieldsState, setState] = useState({ agree: true })

  const setValues = (val, id) => setState(prev => ({ ...prev, [id]: val }))

  const phoneFields = ["phone", "whatsapp", "viber"]
  const textFields = ["name", "surname"]

  const componentByField = field => {
    if (phoneFields.includes(field.id)) {
      return <InputMask
        id={`lid-${field.id}`}
        type="text"
        className={`admin__input`}
        mask="+7 (999) 999-99-99"
        autoComplete="off"
        maskChar=" "
        value={fieldsState[field.id]}
        onChange={(e) => setValues(e.target.value, field.id)}

      />
    }
    if (field.id === "email") {
      return <input
        id={`lid-${field.id}`}
        type="text"
        className={`admin__input`}
        placeholder="E-mail"
        value={fieldsState[field.id]}
        onChange={(e) => setValues(e.target.value, field.id)}

      />
    }
    if (field.id === "telegram") {
      return <TelegramInput
        placeholder="@"
        value={fieldsState[field.id]}
        changeHandler={val => setValues(val, field.id)}
        isMobile={isMobile}
      />
    }
    if (textFields.includes(field.id)) {
      return <input id={`lid-${field.id}`}
        type="text"
        className={`admin__input`}
        placeholder={field.name}
        onChange={(e) => setValues(e.target.value, field.id)}
        value={fieldsState[field.id]}
      />
    }
    return null
  }
  const [errors, setErrors] = useState({})

  const setError = (val, id) => setErrors(prev => ({ ...prev, [id]: val }))

  const noContactField = ["name", "surname"]
  
  const validateForm = () => {
    if (!fieldsState.agree) {
      errorToast("Необходимо согласиться с политикой конфиденциальности")
      return
    }

    const tmp = { ...fieldsState }
    delete tmp.agree
    delete tmp.name
    const haveContact = Object.values(tmp).some(val => !!val.replace(/\s/gi, ""))
    const haveName = fieldsState?.name?.toString?.().replace?.(/ /gi, "")
    if (!haveContact) {
      const unfiledFields = fields.map(field => field.active && !noContactField.includes(field.id) ? <>{field.name}<br /></> : null)
      errorToast(<div>
        {!haveName ? <>Заполните поле "имя".<br /> </> : ""}
        Заполните одно из полей: <br />{unfiledFields}</div>)
      return
    }
    if(!haveName){
      errorToast(`Заполните поле "имя"`)
      return
    }
    return true
  }

  const sendForm = () => {
    if (!validateForm()) return
    const formWithUtm = getFormWithUTM()
    const params = applyToFormData(formWithUtm, fieldsState)
    params.append("subDomain", getSubdomain())
    const file = leadMagnetData?.file;
    
    if (leadMagnetType == 'file') {
      if (iOSSafari && !!file?.src) {
        var windowRef = window.open();
      }
    }



    sendLeadMagnet(params).then(data => {

      

      if (leadMagnetType == 'file') {

        if (windowRef) {
          windowRef.location.href = getUrl(file.src, "https://panpartner.ru")
        }
  
        if (!!file?.src && !windowRef) {
          setTimeout(() => {
            downloadLink(getUrl(file.src, "https://panpartner.ru", '_self'))
          }, 4000)
          
        }

      }

      if (leadMagnetType == 'link') {
                  
        setTimeout(() => {
          window.open(leadMagnetLink, '_self')
        }, 4000)
      }

      sendConstructorEvent("tr_lead_magnet_page_send", null, true);
      sendVKEvent('lead');
      setIsSuccess(true);
      // successToast(data.message)
    }, err => {
      standartErrorToast(err)
      return windowRef?.close?.()
    })

    

  }



  if (isLoading) return <Preloader loading={true} />

  return (

    <section className="lp">

      {isLaptop && <LidPageHeader title={reviewsTitle} phone={isAnyPhone} />}

      {!isSuccess && <LidPageSVG />}
      

      <div className={`lp__content${isSuccess ? ` lp__content_success`: ``}`}>

        {!isLaptop && <LidPageHeader title={reviewsTitle} phone={isAnyPhone} />}
        {
          !isSuccess &&
            <div className="lp__main">
              <h2
              className="lp__title"
              dangerouslySetInnerHTML={{
                __html: htmlTitle,
              }}
              ></h2>
              <p className="lp__text big-text">{leadMagnetData.subTitle}</p>

              <form action="" className="lp__form admin">
                <div className={`lp__input-grid`}>

                  {!!fields?.length && fields.map(field => field.active ?
                    <div className={`admin__input-block`} key={field.id}>
                      <label htmlFor={`lid-${field.id}`} className={`admin__label`}>{field.name}</label>
                      {componentByField(field)}
                    </div>
                  : null
                  )}
                  </div>

                <div className="lp__checkbox checkbox checkbox_view_invert">
                  <div
                    className={
                      "checkbox__box lp__checkbox-box background-1" +
                      (errors.agreement ? " checkbox__box-error" : "")
                    }
                  >
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-partnership-agreement"
                      name="agreement"
                      ref={register({
                        required: true,
                      })}
                      checked={fieldsState.agree}
                      onChange={(e) => setValues(e.target.checked, "agree")}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-partnership-agreement"
                    ></label>
                    <div className="checkbox__marker">
                      <svg className="checkbox__marker-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="20"><path d="M0 4.765l.855-.8 4.19 2.272L12.267 0 13 .665 5.36 10 0 4.765z"/></svg>
                    </div>
                  </div>
                  <label
                    className="checkbox__label text"
                    htmlFor="modal-partnership-agreement"
                  >
                  Я принимаю условия <a className="lp__agreement link" href="https://toprealtors.ru/policy" target="_blank">политики конфиденциальности</a>
                  </label>

                </div>

                <div className="lp__info">
                  <button className="button constructor__btn lp__btn" type="submit" onClick={(evt) => {
                    evt.preventDefault();
                    sendForm();
                  }}>
                    {leadMagnetData.buttonTitle}
                  </button>

                  <div className="lp__info-block">
                    <span className="lp__info-text">Доступ откроется после отправки формы</span>
                    <div className="lp__info-icon background-1">
                      <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.75736 5.5V5.49246C2.73156 4.91207 2.8236 4.33248 3.02792 3.78864C3.23225 3.2448 3.54462 2.74798 3.94619 2.32816C4.34777 1.90834 4.83022 1.57422 5.36446 1.34595C5.89869 1.11768 6.47363 1 7.05458 1C7.63554 1 8.21048 1.11768 8.74471 1.34595C9.27894 1.57422 9.7614 1.90834 10.163 2.32816C10.5645 2.74798 10.8769 3.2448 11.0812 3.78864C11.2856 4.33248 11.3776 4.91207 11.3518 5.49246V8" stroke="white" strokeWidth="1.5" strokeMiterlimit="10"/>
                        <path d="M7.05883 15.5185C7.99597 15.5185 8.75566 14.7588 8.75566 13.8216C8.75566 12.8845 7.99597 12.1248 7.05883 12.1248C6.1217 12.1248 5.362 12.8845 5.362 13.8216C5.362 14.7588 6.1217 15.5185 7.05883 15.5185Z" fill="white"/>
                        <rect x="0.75" y="8.45589" width="12.6176" height="9.79412" rx="1.25" stroke="white" strokeWidth="1.5"/>
                      </svg>
                    </div>
                  </div>
                </div>



              </form>
            </div>
        }

        {
          isSuccess &&
          <div className="lp__success">
            <LidPageSVG2 />
            <h2 className="h2 lp__success-title">Ваша заявка отправлена</h2>


            {
              leadMagnetType == 'file' && 
              <>
                <img src={require('../../../../assets/images/preloader.gif').default} alt="" />
                <p className="lp__text big-text lp__success-text">Файл начнет скачиваться через несколько секунд</p>

                <a className="button constructor__btn lp__success-btn"href="/">
                  больше предложений
                </a>
              </>
            }

            {
              leadMagnetType == 'link' && 
              <>
                <img src={require('../../../../assets/images/preloader.gif').default} alt="" />
                <p className="lp__text big-text lp__success-text">Сейчас Вы будете перенаправленына страницу результата формы</p>
              </>
            }

          </div>
        }
      </div>
    </section>
  )
};


export default LidPage;