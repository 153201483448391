export const badgeTitles = {
    assignments: "Переуступки:",
    isTwoLevels: "Двухъярусная:",
    masterBedroom: "Мастер-спальня:",
    escrowAccount: "Эскроу счет:",
    availability: "До метро:",
    toSea: "До моря:",
    balcony: "Балкон: ",
    balconyType: "Балкон: ",
    banks: "Банк:",
    blockClass: "Класс ЖК:",
    blocks: "ЖК:",
    builders: "Застройщик:",
    cellHeight: {
        max: "Потолок до:",
        min: "Потолок от:"
    },
    communications: "Коммуникации: ",
    countBedrooms: "Спален:",
    countFloors: {
        max: "Этажность до:",
        min: "Этажность от:"
    },
    decoration: "Отделка:",
    districts: "Район:",
    encumbrances: "Обременения: ",
    endings: {
        max: "Сдача до:",
        min: "Сдача от:"
    },
    features: "Особенности:",
    floor: {
        max: "Этаж до:",
        min: "Этаж от:"
    },
    floors: {
        max: "Этаж до:",
        min: "Этаж от:"
    },
    isApartments: "Апартаменты:",
    isFirstFloor: "Этаж:",
    isNoFirstFloor: "Этаж:",
    isOnlyHanded: "Сданные дома: ",
    isHanded: "Сдан: ",
    issuingKeys: {
        max: "Выдача ключей до:",
        min: "Выдача ключей от:"
    },
    materials: "Материал:",
    microDistricts: "Микрорайон:",
    nearby: "Рядом:",
    objectType: "Тип объекта: ",
    payment: "Способы оплаты:",
    paymentType: "Способы оплаты:",
    purpose: "Статус земли:",
    parkingType: "Тип паркинга:",
    prices: {
        max: "Цена до:",
        min: "Цена от:"
    },
    registrations: "Прописка:",
    residencePermit: "ВНЖ:",
    rooms: "К-во комнат:",
    squareHouse: {
        max: "S дома до:",
        min: "S дома от:"
    },
    squareKitchen: {
        max: "S кухни до:",
        min: "S кухни от:"
    },
    squareParcel: {
        max: "S участка до:",
        min: "S участка от:"
    },
    squareTotal: {
        max: "Площадь до:",
        min: "Площадь от:"
    },
    squareLiving: {
        max: "S жилая до:",
        min: "S жилая от:"
    },
    subways: "Метро:",
    type: "Тип:",
    villages: "Посёлок:",
    address: "Адрес:",
    realtyType: "Тип договора:",
    buildings: "Корпус:",
    number: "№ квартиры:",
    tradeIn: "Trade-In:",

    material: "Материалы дома:",
    projectCountBathrooms: {
        max: "Спален до:",
        min: "Спален от:"
    },
    projectCountBedrooms: {
        max: "Спален до:",
        min: "Спален от:"
    },
    constructionPeriod: "Срок строительства:",
    price: {
        max: "Цена до:",
        min: "Цена от:"
    },
    priceHouse: {
        max: "Цена до:",
        min: "Цена от:"
    },
    priceParcel: {
        max: "Цена до:",
        min: "Цена от:"
    },
    projectTitle: "Проект:",
    onlySelfBuild: "Возможность строительства:",



    hasTerrace: "Терраса:",
    hasKitchenLiving: "Кухня-гостиная:",
    hasPanoramicWindows: "Панорамные окна:",
    hasWardrobe: "Гардероб:",
    hasSecondLight: "Второй свет:",
    hasAtticFloor: "Мансардный этаж:",
    hasSauna: "Сауна:",

    decorationHouse: "Отделка:",
    countBathrooms: "Кол-во санузлов:",
    landStatus: "Статус земли:",
    cottageSettlement: "КП:",
    endingDate: {
        max: "Сдача до:",
        min: "Сдача от:"
    },
    districtSpb: "Район:",
    builderVillage: "Застройщик:",
};
export const specialBadgeValuesTitles = {

    hasTerrace: {
        N: 'Нет',
        Y: 'Да'
    },
    hasKitchenLiving: {
        N: 'Нет',
        Y: 'Да'
    },
    hasPanoramicWindows: {
        N: 'Нет',
        Y: 'Да'
    },
    hasWardrobe: {
        N: 'Нет',
        Y: 'Да'
    },
    hasSecondLight: {
        N: 'Нет',
        Y: 'Да'
    },
    hasAtticFloor: {
        N: 'Нет',
        Y: 'Да'
    },
    hasSauna: {
        N: 'Нет',
        Y: 'Да'
    },

    assignments: {
        N: 'Нет',
        Y: 'Да'
    },
    isTwoLevels: {
        // N: 'Нет',
        Y: 'Да'
    },
    masterBedroom: {
        // N: 'Нет',
        Y: 'Да'
    },
    encumbrances: {
        N: "без обременений",
        Y: "c обременениями"
    },
    isApartments: {
        N: "Нет",
        Y: "Да"
    },
    isFirstFloor: {
        Y: "только первый"
    },
    isNoFirstFloor: {
        Y: "не первый"
    },
    isOnlyHanded: {
        Y: "Да"
    },
    isHanded: {
        Y: "Да"
    },
    residencePermit: {
        "2years": "2 года",
        "5years": "5 лет"
    },
    onlySelfBuild: {
        "Y": "Обязательный подряд",
        "N": "Свободного назначения"
    },

};



export const sortlistPrimary = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "prices",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "prices",
        by: "desc" // по убыванию
    },
    {
        id: '2',
        title: 'По удаленности (сначала ближе)',
        value: "subway",
        by: "asc"
    },
    {
        id: '3',
        title: 'По удаленности (сначала дальше)',
        value: "subway",
        by: "desc"
    },
    {
        id: '4',
        title: 'По сроку сдачи (позже)',
        value: "ending",
        by: "asc"
    },
    {
        id: '5',
        title: 'По сроку сдачи (раньше)',
        value: "ending",
        by: "desc"
    },
    {
        id: '6',
        title: 'По старту продаж (позже)',
        value: "start",
        by: "asc"
    },
    {
        id: '7',
        title: 'По старту продаж (раньше)',
        value: "start",
        by: "desc"
    },
    {
        id: '8',
        title: 'По популярности',
        value: "pop",
        by: "asc"
    }
];


export const sortlistAssignments = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "price",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "price",
        by: "desc" // по убыванию
    },
    {
        id: '2',
        title: 'По площади (сначала меньше)',
        value: "squareTotal",
        by: "asc"
    },
    {
        id: '3',
        title: 'По площади (сначала больше)',
        value: "squareTotal",
        by: "desc"
    },
    {
        id: '4',
        title: 'По сроку сдачи (позже)',
        value: "ending",
        by: "asc"
    },
    {
        id: '5',
        title: 'По сроку сдачи (раньше)',
        value: "ending",
        by: "desc"
    },
];


export const sortlistSecondary = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "price",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "price",
        by: "desc" // по убыванию
    },
    {
        id: '2',
        title: 'По площади (сначала меньше)',
        value: "squareTotal",
        by: "asc"
    },
    {
        id: '3',
        title: 'По площади (сначала больше)',
        value: "squareTotal",
        by: "desc"
    },
];

export const sortlistVillages = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "price",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "price",
        by: "desc" // по убыванию
    },
];

export const sortlistFavorites = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "price",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "price",
        by: "desc" // по убыванию
    },
    {
        id: '2',
        title: 'По дате добавления (сначала ранние)',
        value: "date",
        by: "asc" // по возрастанию
    },
    {
        id: '3',
        title: 'По дате добавления (сначала поздние)',
        value: "date",
        by: "desc" // по убыванию
    },
];

export const sortlistDxb = [
    {
        id: '0',
        title: 'По популярности',
        value: "pop",
        by: "desc"
    },
    {
        id: '1',
        title: 'По цене (сначала дешевле)',
        value: "prices",
        by: "asc"
    },
    {
        id: '2',
        title: 'По цене (сначала дороже)',
        value: "prices",
        by: "desc"
    },
];
export const sortlistCommercial = [
    {
        id: '0',
        title: 'По цене (сначала дешевле)',
        value: "prices",
        by: "asc" // по возрастанию
    },
    {
        id: '1',
        title: 'По цене (сначала дороже)',
        value: "prices",
        by: "desc" // по убыванию
    },
    {
        id: '2',
        title: 'По удаленности (сначала ближе)',
        value: "subway",
        by: "asc"
    },
    {
        id: '3',
        title: 'По удаленности (сначала дальше)',
        value: "subway",
        by: "desc"
    },
    {
        id: '4',
        title: 'По сроку сдачи (позже)',
        value: "ending",
        by: "asc"
    },
    {
        id: '5',
        title: 'По сроку сдачи (раньше)',
        value: "ending",
        by: "desc"
    },
    {
        id: '6',
        title: 'По популярности',
        value: "pop",
        by: "asc"
    }
];

export const sortListProjects = [
    {
        id: '0',
        title: 'По популярности',
        value: "sort",
        by: "asc"
    },
    {
        id: '1',
        title: 'По цене (сначала дешевле)',
        value: "price",
        by: "asc"
    },
    {
        id: '2',
        title: 'По цене (сначала дороже)',
        value: "price",
        by: "desc"
    },
    {
        id: '3',
        title: 'По площади (сначала с меньшей)',
        value: "squareTotal",
        by: "asc"
    },
    {
        id: '4',
        title: 'По площади (сначала с большей)',
        value: "squareTotal",
        by: "desc"
    },
];
