import { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SquareFilterOnly from "../../FilterRealty/SquareFilterOnly";
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import DistrictsFilter from "../../FilterRealty/DistrictsFilter";

const VillagesFilter = memo(props => {
  const {
    filter,
    exclude,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;


  const createSelectProps = getSelectPropsCreator(props);
  return <>

    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        autocompleteTitle: "Например, сельская ипотека",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <EndingsFilter
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}
    />
    <DistrictsFilter
      onChange={(filter, exclude) => setFilterAndExclude({ filter, exclude }, "districts")}
      isExcludable={true}
      isAutocomplete={true}
      items={filterData?.districts ?? []}
      filterValues={filter.districts}
      excludeValues={exclude.districts}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      city={"spb"}
      setDistricts={filter => setFilterAndExclude({ filter }, "districts")}
      wrapperClass={wrapperClass + ' form__item_suburbans-region'}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      hideMapButton
    />
    {/* <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    /> */}

    {/* <SelectFilter
      {...createSelectProps("features")}
      data={{
        title: "Особенности",
        autocompleteTitle: "Например, озеро или лес",
        identity: "features",
      }}
      wrapperClass={wrapperClass}
    /> */}
    <SelectFilter
      {...createSelectProps("communications")}
      data={{
        title: "Коммуникации",
        autocompleteTitle: "Например, электричество",
        identity: "communications",
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareParcel ?? { min: "", max: "" }}
      data={{
        title: 'S участка',
        minPlaceholder: 'S уч. от ',
        maxPlaceholder: 'S уч. до ',
        defaultValues: filterData.squareParcel?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareParcel', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareParcel"}
      items={filterData.squareParcel?.items}
      showPlaceholderOnLists
      debounced
      placeholderMes="сот."
    />
    <SquareFilterOnly

      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareHouse ?? { min: "", max: "" }}
      data={{
        title: 'S дома',
        minPlaceholder: 'S дома от ',
        maxPlaceholder: 'S дома до ',
        defaultValues: filterData.squareHouse?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareHouse', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareHouse"}
      items={filterData.squareHouse?.items}
      showPlaceholderOnLists
      debounced
    />
    <SelectFilter
      {...createSelectProps("materials")}
      data={{
        title: "Материал дома",
        autocompleteTitle: "",
        identity: "materials",
      }}
      wrapperClass={wrapperClass}

    />
    {/* <SelectFilter
      {...createSelectProps("villages")}
      data={{
        title: "Название КП",
        autocompleteTitle: "Введите название КП",
        identity: "villages",
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      {...createSelectProps("countFloors")}
      onChange={v => setFilterAndExclude(v, "countFloors")}
      filter={filter?.countFloors ?? { min: "", max: "" }}
      items={filterData.countFloors?.items ?? []}
      data={{
        title: "Этажность",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.countFloors?.range ?? null,
      }}
      wrapperClass={wrapperClass}
    /> */}
    {/* скрыто тк пока высоток нет */}
    {false && <FloorsFilter
      {...createSelectProps("floors")}
      filter={filter?.floors}
      data={{
        title: "Этаж",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.floors?.range ?? null,
      }}
    />}
    <SelectFilter
      {...createSelectProps("nearby")}
      data={{
        title: "Инфраструктура",
        autocompleteTitle: "",
        identity: "nearby",
      }}
      wrapperClass={wrapperClass}
    />

    <div className="form__row">
      <div className="index-search__show-booked" style={{ marginTop: 0 }}>
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status.includes("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            ></div>
            <div className="switch__marker"></div>
          </div>
          <span className="switch__label">
            Забронированные
          </span>
        </label>
      </div>
    </div>
  </>
});

export default VillagesFilter;
