import React from 'react';
import { getHoursSuffix } from '../../utils';
import Done from './Done';
import './index.scss';
import Result from './Result';

const ClockIcon = () => {
  return (
    <svg className="training__poll-clock" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8825 36.7881C28.8643 36.7881 36.9562 28.6962 36.9562 18.7144C36.9562 8.73251 28.8643 0.640625 18.8825 0.640625C8.9006 0.640625 0.808716 8.73251 0.808716 18.7144C0.808716 28.6962 8.9006 36.7881 18.8825 36.7881Z" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M18.8824 33.0938C26.824 33.0938 33.2619 26.6559 33.2619 18.7144C33.2619 10.7728 26.824 4.33496 18.8824 4.33496C10.9409 4.33496 4.50302 10.7728 4.50302 18.7144C4.50302 26.6559 10.9409 33.0938 18.8824 33.0938Z" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M18.8824 13.1444V18.828L23.884 23.7727" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M18.8824 4.33496V7.00624" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M18.8824 30.3656V33.0369" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M33.205 18.7144H30.5337" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M7.1743 18.7144H4.50302" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
};
const Question = ({ items, name, index, handleChange, form }) =>
  <div className="training__question-box">
    <span className="training__q"><span>{index}.</span> {name}</span>
    <div className="training__a">
      {items && items.map(({ name, value, fieldName }) => <div className="radio" key={value}>
        <div className="radio__box">
          <input
            className="radio__control"
            type="radio"
            id={`variant-${fieldName}-${value}`}
            name={`variant-${fieldName}-${value}`}
            onChange={e => handleChange(e.target.value, fieldName)}
            value={value}
            checked={!!form?.[fieldName]?.includes(value)}
          />
          <label className="radio__input" htmlFor={`variant-${fieldName}-${value}`}></label>
          <div className="radio__marker"></div>
        </div>
        <label className="radio__label" htmlFor={`variant-${fieldName}-${value}`}>{name}</label>
      </div>)}
    </div>
  </div>;

const Task = props => {
  const {
    submit,
    endTime,
    description,
    countdown,
    formSettings,
    disableQuestions,
    form,
    handleChange,
    errors,
    agreement,
    setAgreement,
    isFinished,
    answersResults,
    haveActiveTest
  } = props
  if(!haveActiveTest)  return <Done text="Задания пока недоступны" />
  if (isFinished) return <Done text="Задание больше недоступно" />
  if (answersResults[form?.testId]) return <Result answersResult={answersResults[form?.testId]} formSettings={formSettings} />

  return (
    <div className="training__poll">

      <div className="training__poll-header">
        <h3 className="h3 training__poll-title">{description}</h3>
        {!!endTime && <div className="training__poll-timer">
          <ClockIcon />
          <div>
            <span className="training__poll-sign">Осталось времени</span>
            <span className="training__poll-time">{countdown.hours} {getHoursSuffix(countdown.hours)} {countdown.minutes} мин.</span>
          </div>
        </div>}
      </div>

      <div className="training__questions">
        {formSettings.map((setting, i) => {
          return <Question {...setting} index={i + 1} key={setting.name} handleChange={handleChange} form={form} />
        })}

        <button
          type="button"
          className="training__submit button button_view_default"
          onClick={submit}
        >
          Отправить
        </button>
      </div>

    </div>
  );
};

export default Task;
