import React from "react";
import { useModal } from "react-modal-hook";
import useUniversalReducer from "./useUniversalReducer";
import Modal from "../components/core/Modal";

const useModalWithData = config => {
    const {
        component: Component,
        modalProps,
        initialState = {},
        dep = [],
        content: Content
    } = config;
    const {
        state = {},
        setValue,
        reset,
        setValues
    } = useUniversalReducer(initialState);

    const [open, close] = useModal(() => {
        const closeModal = () => {
            reset()
            close()
        }
        
        if(Content) return <Content closeModal={closeModal} {...state}/>

        return <Modal {...modalProps} close={closeModal}>
            <Component
                closeModal={closeModal}
                {...state}
            />
        </Modal>
    }, [state, ...dep]);

    const closeModal = () => {
        reset()
        close()
    }
    const openModal = (data = {}) => {
        setValues(data)
        open()
    }

    return {
        openModal,
        setValue,
        reset,
        setValues,
        closeModal
    }
}

export default useModalWithData;
