import { useMediaQuery } from 'beautiful-react-hooks';
import formatter from 'format-number';
import without from 'lodash/without';
import React, { useEffect, useRef, useState } from 'react';
import useMedia from '../../hooks/useMedia';
import { getEndingsTitle } from "../../utils";
import RadioDropdown from '../core/RadioDropdown';
import EndingsFilter from '../FilterRealty/EndingsFilter';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SearchFilter from '../FilterRealty/SearchFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';
const priceFormat = (price) =>
  formatter({ integerSeparator: ' ', suffix: ' р.' })(price);

const Badge = (props) => {
  const { item, handleClick, title, identity } = props;

  return (
    <div className="filtered">
      <div className="filtered__inner">
        <span className="filtered__type">{title}:</span>
        <span className="filtered__value">{item?.title}</span>
        <button
          className="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            className="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    count,
    isNumberEntered,
    isPublic,
    savedFilterXmlId,
    clientMode
  } = props;
  const isMobile = useMedia("isMobile")
  const handleFilter = (value, identity) => {
    setFilter({ [identity]: value });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };
  const smallScreen = useMediaQuery('(max-width: 1280px)');
  const middleScreen = useMediaQuery('(min-width: 1280px)');


  const showBadges = () => {
    return (
      (filter &&
        (filter.rooms?.length > 0 ||
          filter.decoration?.length > 0 ||
          filter.payment?.length > 0 ||
          filter.bathroom?.length > 0 ||
          filter.banks?.length > 0 ||
          filter.buildings?.length > 0 ||
          filter.number.replace(/\s/, '').length > 0 ||
          filter.floors?.min ||
          filter.floors?.max ||
          filter.prices?.min ||
          filter.prices?.max ||
          filter.squareTotal?.min ||
          filter.squareTotal?.max ||
          filter.squareKitchen?.min ||
          filter.squareKitchen?.max ||
          filter.endings?.min ||
          filter.endings?.max ||
          filter.issuingKeys?.max ||
          filter.issuingKeys?.min ||
          filter.isOnlyHanded?.length > 0 ||
          filter.isNoFirstFloor == 'Y')) ||
      filter.isApartments?.length > 0 ||
      filter.assignments?.length > 0 ||
      filter.balconyType?.length > 0 ||
      filter.cellHeight?.min ||
      filter.cellHeight?.max ||
      filter.isTwoLevels?.length > 0 ||
      filter.masterBedroom?.length > 0 ||
      (!!filter.isAction && !clientMode && !isPublic)
    );
  };
  const handleRemoveFilter = (value, identity) => {
    switch (identity) {
      case 'squareTotal':
      case 'squareKitchen':
      case 'prices':
      case 'cellHeight':
      case 'floors':
      case 'endings':
      case "issuingKeys":
        setFilter({
          ...filter,
          [identity]: { ...filter[identity], [value]: "" },
        });
        break;
      case 'isOnlyHanded':
        setFilter({
          ...filter,
          [identity]: [],
        });
        break;
      case 'isApartments':
        setFilter({
          ...filter,
          [identity]: [],
        });
        break;
      case 'isNoFirstFloor':
      case 'assignments':
      case 'number':
      case 'isAction':
      case 'isTwoLevels':
      case 'masterBedroom':
        setFilter({
          ...filter,
          [identity]: '',
        });
        break;
      default:
        let newArray = filter[identity];
        newArray = newArray.filter((item) => item != value);
        setFilter({ ...filter, [identity]: newArray });
    }
  };

  const handleToggle = (e) => {
    let tempFilter = [...filter.status];
    if (e.target.checked) {
      tempFilter.push('3');
    } else {
      tempFilter = without(tempFilter, '3');
    }
    setFilter({
      status: tempFilter,
    });
  };
  const handleAction = e => setFilter({
    isAction: e.target.checked ? "Y" : "",
  })

  const scrollRef = useRef(null);

  const scrollToRef = (ref) => {
    try {
      window.scrollTo({
        top: (window.pageYOffset + ref.current.getBoundingClientRect().top),
        behavior: "smooth",
      });
    } catch {

    }
  }

  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => {
    setIsOpen(!isOpen);

    !isOpen && scrollToRef(scrollRef);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  useEffect(() => {
    if (props.isFiltersOpen) toggleFilters();
  }, [props.isFiltersOpen]);

  const submitTitle = () => {
    if (count === "initial") {
      return `Показать все`
    }
    return count === 0
      ? 'Ничего не найдено'
      : `Показать ${count} квартир`
  }

  const submitBtn = (
    <div className="complex-search__filter-submit">
      <button
        className="button button_view_default"
        type="submit"
        disabled={count === 0}
        onClick={(e) => {
          onSubmit();
        }}
      >
        <span>
          {submitTitle()}
        </span>
      </button>
    </div>
  )

  const filters = (
    <>
      <PriceFilter
        filter={filter.prices ? filter.prices : { min: '', max: '' }}
        exclude={{ min: '', max: '' }}
        items={filterData.prices ? filterData.prices?.items : []}
        data={{
          title: 'Цена',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.prices ? filterData.prices.range : 0,
        }}
        onChange={(a) => handleFilter(a.filter, 'prices')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'decoration')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.decoration
            ? Object.values(filterData.decoration)
            : []
        }
        filterValues={filter.decoration}
        excludeValues={[]}
        data={{
          title: 'Отделка',
          autocompleteTitle: '',
          identity: 'decoration',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      <SquareFilter
        filter={filter.squareTotal}
        data={{
          title: 'S общая',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.squareTotal
            ? filterData.squareTotal.range
            : null,
        }}
        right={!middleScreen}
        onChange={(a) => handleFilter(a.filter, 'squareTotal')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      {filterData.squareKitchen?.items?.length > 0 && <SquareFilter
        filter={filter.squareKitchen}
        data={{
          title: 'S кухни',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.squareKitchen
            ? filterData.squareKitchen.range
            : null,
        }}
        right={middleScreen}
        onChange={(a) => handleFilter(a.filter, 'squareKitchen')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />}
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'bathroom')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.bathroom ? Object.values(filterData.bathroom) : []
        }
        filterValues={filter.bathroom}
        excludeValues={[]}
        data={{
          title: 'Санузел',
          autocompleteTitle: '',
          identity: 'bathroom',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      {/* <PriceFilter
        filter={filter.cellHeight ? filter.cellHeight : { min: '', max: '' }}
        exclude={{ min: '', max: '' }}
        items={filterData.cellHeight?.items ? filterData.cellHeight?.items : []}
        data={{
          title: 'Высота потолков',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.cellHeight ? filterData.cellHeight.range : 0,
        }}
        onChange={(a) => handleFilter(a.filter, 'cellHeight')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      /> */}
      {filterData.cellHeight?.items?.length > 0 && <RadioDropdown
        autoPosition
        onChange={(a) => handleFilter(
          {
            "max": "",
            "min": a
          },
          "cellHeight",
        )
        }
        items={filterData.cellHeight?.items ? filterData?.cellHeight?.items.map(item => ({ ...item, title: `от ${item.title} м.` })) : []}
        value={
          filter.cellHeight?.min
            ? filter.cellHeight?.min
            : {}
        }
        alternativeTitle={"Высота потолков"}
        data={{
          title: "Высота потолков",
          identity: "cellHeight",
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />}
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'balconyType')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.balconyType
            ? Object.values(filterData.balconyType)
            : []
        }
        filterValues={filter.balconyType}
        excludeValues={[]}
        data={{
          title: "Балкон",
          autocompleteTitle: "",
          identity: "balconyType",
        }}
        disabledItems={
          {
            w: ["b", "l", "t"],
            b: ["w"],
            l: ["w"],
            t: ["w"],
          }
        }
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        right={smallScreen}
      />
      <FloorsFilter
        items={
          filterData.floors?.items ? filterData.floors?.items : []
        }
        filter={filter.floors}
        data={{
          title: 'Этаж',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.floors
            ? filterData.floors.range
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'floors')}
        secondData={{
          title: filterData.isNoFirstFloor
            ? filterData.isNoFirstFloor[0]?.title
            : 'Не первый',
          value: filter.isNoFirstFloor?.length
            ? filter.isNoFirstFloor
            : 'N',
        }}
        secondChange={(a) => {
          let tmp = a === 'Y' ? 'Y' : '';
          handleFilter(tmp, 'isNoFirstFloor');
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />

      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'payment')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.payment ? Object.values(filterData.payment) : []
        }
        filterValues={filter.payment}
        excludeValues={[]}
        data={{
          title: 'Способы оплаты',
          autocompleteTitle: '',
          identity: 'payment',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      {false && (
        <RadioDropdown
          onChange={(a, type) => handleFilter({ id: a }, 'isApartments')}
          isAutocomplete={false}
          items={filterData.isApartments ? filterData.isApartments : []}
          value={filter.isApartments ? filter.isApartments[0].id : {}}
          alternativeTitle={'Апартаменты'}
          data={{
            title: 'Апартаменты',
            identity: 'isApartments',
          }}
          isMobile={isOpen && isMobile}
          clearFilterOnly={(e) => handleClearFilter(e)}
          fullFilter={filter}
          right={smallScreen}
        />
      )}

      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'banks')}
        isExcludable={false}
        isAutocomplete={false}
        items={filterData.banks ? Object.values(filterData.banks) : []}
        filterValues={filter.banks}
        excludeValues={[]}
        data={{
          title: 'Банки',
          autocompleteTitle: '',
          identity: 'banks',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      <RadioDropdown
        right={smallScreen}
        onChange={(a, type) => handleFilter([{ id: a }], 'isApartments')}
        isAutocomplete={false}
        items={filterData.isApartments ? filterData.isApartments : []}
        value={filter.isApartments[0] ? filter.isApartments[0].id : {}}
        alternativeTitle={'Апартаменты'}
        data={{
          title: 'Апартаменты',
          identity: 'isApartments',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      {!savedFilterXmlId &&
        <RadioDropdown
          right={!smallScreen}
          onChange={(a, type) => handleFilter(a, 'assignments')}
          isAutocomplete={false}
          items={filterData.assignments ? filterData.assignments : []}
          value={filter.assignments?.[0] === "Y" || filter.assignments?.[0] === "N" ? filter.assignments?.[0] : filter?.assignments?.[0]?.id}
          alternativeTitle={'Переуступки'}
          data={{
            title: 'Переуступки',
            identity: 'assignments',
          }}
          isMobile={isOpen && isMobile}
          clearFilterOnly={(e) => handleClearFilter(e)}
          fullFilter={filter}
        />
      }
      {!!filterData.isTwoLevels?.length &&<RadioDropdown
        right={!smallScreen}
        onChange={(a, type) => handleFilter(a, 'isTwoLevels')}
        isAutocomplete={false}
        items={filterData.isTwoLevels ? filterData.isTwoLevels : []}
        value={filter.isTwoLevels}
        alternativeTitle={'Двухъярусная'}
        data={{
          title: 'Двухъярусная',
          identity: 'isTwoLevels',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />}
      {!!filterData.masterBedroom?.length && <RadioDropdown
        right={!smallScreen}
        onChange={(a, type) => handleFilter(a, 'masterBedroom')}
        isAutocomplete={false}
        items={filterData.masterBedroom ? filterData.masterBedroom : []}
        value={filter.masterBedroom}
        alternativeTitle={'Мастер-спальня'}
        data={{
          title: 'Мастер-спальня',
          identity: 'masterBedroom',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />}
       {!isPublic && !clientMode && filterData.isAction?.length > 0 && (
        <div className="checkbox checkbox_view_red complex__filter-switch" >
          <div className="checkbox__box first-order">
            <input
              className="checkbox__control"
              type="checkbox"
              id="filter-is-action"
              onChange={handleAction}
              checked={!!filter.isAction}
            />
            <label
              className="checkbox__input"
              htmlFor="filter-is-action"
            ></label>
            <div className="checkbox__marker forced-enable-z"></div>
          </div>
          <label className="checkbox__label second-order" htmlFor="filter-is-action">
            Акционные квартиры
          </label>
        </div>
      )}
      {!isPublic && (
        <div className="complex__filter-switch">
          <label className="switch" for="filter-booked">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                id="filter-booked"
                onChange={handleToggle}
                checked={filter.status.includes('3')}
              />
              <div className="switch__input" for="filter-booked"></div>
              <div className="switch__marker"></div>
            </div>
            <span className="switch__label">
              Показать забронированные
            </span>
          </label>
        </div>
      )}

    </>
  )

  const topFilters = (
    <>
      {!isMobile &&
        <SearchFilter
          placeholder="№ квартиры"
          handleChange={(val) => handleFilter(val, 'number')}
          value={filter.number}
        />
      }
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'buildings')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.buildings ? Object.values(filterData.buildings) : []
        }
        filterValues={filter.buildings}
        excludeValues={[]}
        data={{
          title: 'Корпус',
          autocompleteTitle: '',
          identity: 'buildings',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      <EndingsFilter
        ident={"endings"}
        filter={filter.endings ? filter.endings : { min: '', max: '' }}
        items={filterData.endings ? filterData.endings?.items : []}
        data={{
          title: 'Срок сдачи',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.endings
            ? filterData.endings?.ranges
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'endings')}
        secondData={{
          title: 'Сданные дома',
          value: filter.isOnlyHanded?.length
            ? filter.isOnlyHanded?.[0]?.id
            : 'N',
        }}
        secondChange={(a) =>
          handleFilter(a === 'Y' ? [{ id: a }] : [], 'isOnlyHanded')
        }
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />
      <EndingsFilter
        filter={
          filter.issuingKeys
            ? filter.issuingKeys
            : { min: "", max: "" }
        }
        items={
          filterData.issuingKeys ? filterData.issuingKeys?.items : []
        }
        data={{
          title: "Выдача ключей",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.issuingKeys
            ? filterData.issuingKeys?.ranges
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, "issuingKeys")}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        ident={"issuingKeys"}
        right={smallScreen}
      />
      <SelectFilter
        onChange={(a, b) => handleFilter(a, 'rooms')}
        isExcludable={false}
        isAutocomplete={false}
        items={filterData.rooms ? Object.values(filterData.rooms) : []}
        filterValues={filter.rooms}
        excludeValues={[]}
        data={{
          title: 'Ко-во комнат',
          autocompleteTitle: '',
          identity: 'rooms',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
      />

    </>
  )

  const appliedFilters = (
    <div className="index-search__filter-applied">
      <div className="complex-filter_wrapper">
        {filter.rooms?.map?.((id) => {
          let b = filterData.rooms.find((item) => id == item.id);
          if (!b) {
            return '';
          }
          return (
            <Badge
              key={`rooms${id}`}
              identity={`rooms`}
              item={b}
              title={`К-во комнат`}
              handleClick={handleRemoveFilter}
            />
          );
        })}
        {filter.squareTotal && filter.squareTotal?.min && (
          <Badge
            key={`squareTotalMin`}
            identity={`squareTotal`}
            item={{ id: 'min', title: `${filter.squareTotal?.min}  м²` }}
            title={`Общая площадь, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.squareTotal && filter.squareTotal?.max && (
          <Badge
            key={`squareTotalMax`}
            identity={`squareTotal`}
            item={{ id: 'max', title: `${filter.squareTotal?.max}  м²` }}
            title={`Общая площадь, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.squareKitchen && filter.squareKitchen?.min && (
          <Badge
            key={`squareKitchenMin`}
            identity={`squareKitchen`}
            item={{ id: 'min', title: `${filter.squareKitchen?.min}  м²` }}
            title={`Площадь кухни, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.squareKitchen && filter.squareKitchen?.max && (
          <Badge
            key={`squareKitchenMax`}
            identity={`squareKitchen`}
            item={{ id: 'min', title: `${filter.squareKitchen?.max}  м²` }}
            title={`Площадь кухни, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.prices && filter.prices?.min && (
          <Badge
            key={`priceMin`}
            identity={`prices`}
            item={{ id: 'min', title: priceFormat(filter.prices?.min) }}
            title={`Цена, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.prices && filter.prices?.max && (
          <Badge
            key={`priceMax`}
            identity={`prices`}
            item={{ id: 'max', title: priceFormat(filter.prices?.max) }}
            title={`Цена, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.cellHeight && filter.cellHeight?.min && (
          <Badge
            key={`cellHeightMin`}
            identity={`cellHeight`}
            item={{ id: 'min', title: `${filter.cellHeight?.min} м` }}
            title={`Потолок, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.cellHeight && filter.cellHeight?.max && (
          <Badge
            key={`cellHeightMax`}
            identity={`cellHeight`}
            item={{ id: 'max', title: `${filter.cellHeight?.max} м` }}
            title={`Потолок, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.endings && filter.endings?.min && (
          <Badge
            key={`endingsMin`}
            identity={`endings`}
            item={{
              id: 'min',
              title: getEndingsTitle(filter.endings?.min),
            }}
            title={`Срок сдачи, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.endings && filter.endings?.max && (
          <Badge
            key={`endingsMax`}
            identity={`endings`}
            item={{
              id: 'max',
              title: getEndingsTitle(filter.endings?.max),
            }}
            title={`Срок сдачи, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.issuingKeys && filter.issuingKeys?.min && (
          <Badge
            key={`issuingKeysMin`}
            identity={`issuingKeys`}
            item={{
              id: 'min',
              title: getEndingsTitle(filter.issuingKeys?.min),
            }}
            title={`Выдача ключей от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.issuingKeys && filter.issuingKeys?.max && (
          <Badge
            key={`issuingKeysMax`}
            identity={`issuingKeys`}
            item={{
              id: 'max',
              title: getEndingsTitle(filter.issuingKeys?.max),
            }}
            title={`Выдача ключей до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.decoration &&
          filter.decoration.map((id) => {
            let b = filterData.decoration.find((item) => id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`decoration${id}`}
                identity={`decoration`}
                item={b}
                title={`Отделка`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.bathroom &&
          filter.bathroom.map((id) => {
            let b = filterData.bathroom.find((item) => id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`bathroom${id}`}
                identity={`bathroom`}
                item={b}
                title={`Санузел`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.banks &&
          filter.banks.map((id) => {
            let b = filterData.banks.find((item) => id == item.id);
            return (
              <Badge
                key={`banks${id}`}
                identity={`banks`}
                item={b}
                title={`Банк`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.balconyType &&
          filter.balconyType.map((id) => {
            let b = filterData.balconyType.find((item) => id == item.id);
            return (
              <Badge
                key={`balconyType${id}`}
                identity={`balconyType`}
                item={b}
                title={`Балкон`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.payment &&
          filter.payment.map((id) => {
            let b = filterData.payment.find((item) => id == item.id);
            return (
              <Badge
                key={`payment${id}`}
                identity={`payment`}
                item={b}
                title={`Оплата`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.buildings &&
          filter.buildings.map((id) => {
            let b = filterData.buildings.find((item) => id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`buildings${id}`}
                identity={`buildings`}
                item={b}
                title={`Корпус`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.isOnlyHanded &&
          filter.isOnlyHanded?.map?.((filterItem) => {
            let b = filterData.isOnlyHanded.find((item) => filterItem.id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`isOnlyHanded${filterItem.id}`}
                identity={`isOnlyHanded`}
                item={{ id: 0, title: 'Да' }}
                title={`Сданные дома`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.isNoFirstFloor && (
          <Badge
            key={`isNoFirstFloor`}
            identity={`isNoFirstFloor`}
            item={{ id: 0, title: 'не первый' }}
            title={`Этаж`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.number && (
          <Badge
            key={`number`}
            identity={`number`}
            item={{ id: 0, title: filter.number }}
            title={`№ квартиры`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.floors && filter.floors?.min && (
          <Badge
            key={`floorMin`}
            identity={`floors`}
            item={{ id: 'min', title: filter.floors?.min }}
            title={`Этаж, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.floors && filter.floors?.max && (
          <Badge
            key={`floorMax`}
            identity={`floors`}
            item={{ id: 'max', title: filter.floors?.max }}
            title={`Этаж, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.isApartments &&
          filter.isApartments?.map?.((isAp) => {
            let b = filterData.isApartments.find((item) => isAp.id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`isApartments${isAp.id}`}
                identity={`isApartments`}
                item={{ id: 0, title: isAp.id === "Y" ? "Да" : "Нет" }}
                title={`Апартаменты`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.assignments?.length > 0 &&
          <Badge
            key={`assignments`}
            identity={`assignments`}
            item={filter.assignments === "Y" || filter.assignments?.id === "Y" ? { id: "Y", title: "Да" } : { id: "Y", title: "Нет" }}
            title={`Переуступки`}
            handleClick={handleRemoveFilter}
          />
        }
        {!!filter.isAction && !clientMode && !isPublic &&
          <Badge
            key={`isAction`}
            identity={`isAction`}
            item={{ id: "Y", title: "Да" }}
            title={`Акционные квартиры`}
            handleClick={handleRemoveFilter}
          />
        }
        {!!filter.isTwoLevels && <Badge
          key={`isTwoLevels`}
          identity={`isTwoLevels`}
          item={{ id: "Y", title: "Да" }}
          title={`Двухъярусная`}
          handleClick={handleRemoveFilter}
        />
        }
        {!!filter.masterBedroom && <Badge
          key={`masterBedroom`}
          identity={`masterBedroom`}
          item={{ id: "Y", title: "Да" }}
          title={`Мастер-спальня`}
          handleClick={handleRemoveFilter}
        />
        }
        {
          !isMobile &&
            <button
              className="button button_type_clear complex__clear-btn"
              onClick={() => clearFilter()}
            >
            <svg className="icon icon_x">
              <use xlinkHref="#x"></use>
            </svg>
            <span>очистить фильтр</span>
          </button>
        }

      </div>

      {isMobile &&
        <div className="complex__filter-clear-wrapper">
          <button
            className="complex__filter-clear-filter"
            onClick={() => clearFilter()}
          >
            <span>Очистить параметры</span>
          </button>
        </div>
      }
    </div>
  )


  return (
    <div className="complex__filter">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="complex__filter-form">
          <div className="form__row complex__filter-row" ref={scrollRef}>
            {!isMobile && topFilters}

            {!isMobile && filters}

            {isOpen &&
              <div className="mobile-filters">
                <div className="mobile-filters__title">
                  <svg
                    className="icon icon_arrow_up"
                    onClick={() => {
                      toggleFilters()
                      props.onClick()
                    }}
                  >
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>Все фильтры</h3>
                </div>
                <div className="mobile-filters__content">
                  <SearchFilter
                    placeholder="№ квартиры"
                    handleChange={(val) => handleFilter(val, 'number')}
                    value={filter.number}
                  />

                  {showBadges() && filterData.rooms && appliedFilters}
                  {topFilters}
                  {filters}

                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        handleClearFilter(e)
                        toggleFilters()
                      }}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        toggleFilters()
                      }}
                    >
                      Применить
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        {isMobile &&
          <button className="button-mobile" onClick={toggleFilters}>
            {isOpen ? 'Скрыть фильтры' : 'Все фильтры'}
          </button>
        }

        {isMobile && submitBtn}

        {showBadges() && filterData.rooms && appliedFilters}

      </form>
    </div>
  );
};

export default Filter;
