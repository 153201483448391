import React from "react";
import './index.scss'
import {MortgageDwnIcon} from "../Public/markup/icons";
import useApi from "../../hooks/useApi";
import Table from "../core/Table";
import Preloader from "../Preloader";
import { LocaleNotFound } from "../../components/core/Table/tableUtils";
import {getUrl} from "../../api";
import { downloadFile } from "./utils";
import { toFormData } from "../../utils";




const PaymentsSchedule = ({price, prepay, period, rateEstate, otherData = {}}) => {
  // console.log(price, prepay, period, rateEstate)
  const { data: { headers, paymentSchedule, sum, info }, send, isLoading } = useApi({
    payload: ["panpartner:calc.ajax", "getPaymentSchedule", toFormData({
      price,
      yearPercent: rateEstate,
      years: period,
      firstPayment: prepay,
      ...otherData
    })]
  })

  const downloadChartCallback = () => send(['panpartner:calc.ajax', 'getPdf',
    toFormData({
      price,
      yearPercent: rateEstate,
      years: period,
      firstPayment: prepay,
      ...otherData
    }),
    false,
    {
      withoutHandling: true,
      responseType: 'blob'
    }])
    // .then(resp => downloadFile(resp)
    // );
.then((resp) => {
  console.log(resp)
    downloadFile(resp)
  // debugger
  })


  if (isLoading) return <Preloader loading={true}/>;
  const data = paymentSchedule.flatMap(year => {
    const yearData = [];
    for (const [yearKey, months] of Object.entries(year)) {
      yearData.push({
        key: yearKey,
        "Месяц": yearKey,
        "Платеж": "",
        "Проценты": "",
        "Основной долг": "",
        "Остаток долга": "",
        className: "year-row"
      });
      for (const month of months) {
        yearData.push({
          key: yearKey + month.month,
          "Месяц": month.month,
          "Платеж": month.monthlyPayment,
          "Проценты": month.percentHalf,
          "Основной долг": month.generalHalf,
          "Остаток долга": month.remainPrice,
          className: "month-row"
        });
      }
    }
    return yearData;
  });

  const columns = headers.map(header => ({
    title: header,
    dataIndex: header,
    key: header,
  }));

  const tableData = data.concat(sum.map(item => ({
    key: "sum",
    "Месяц": item.countMonths,
    "Платеж": item.monthlyPayment,
    "Проценты": item.percentHalf,
    "Основной долг": item.generalHalf,
    "Остаток долга": "0.00 ₽",
    className: "sum-row"
  })));
  return (
    <div className="payments-schedule">
      <div className="payments-schedule-info">
        <div className="payments-schedule-info__col">
          <div className="payments-schedule-info__label h6">{info[0][0].title}</div>
          <div className="payments-schedule-info__value">{info[0][0].value}</div>
        </div>
        <div className="payments-schedule-info__col">
          <div className="payments-schedule-info__label h6">{info[0][1].title}</div>
          <div className="payments-schedule-info__value">{info[0][1].value}</div>
        </div>
        <div className="payments-schedule-info__col">
          <div className="payments-schedule-info__label h6">{info[0][2].title}</div>
          <div className="payments-schedule-info__value">{info[0][2].value}</div>
        </div>
        <div className="payments-schedule-info__col">
          <div className="payments-schedule-info__label h6">{info[0][3].title}</div>
          <div className="payments-schedule-info__value">{info[0][3].value}</div>
        </div>
      </div>
      <div className="payments-schedule-table">
        <div className="payments-schedule-table__header">
          <div className="payments-schedule-table__header-title">График платежей</div>
          <div className="payments-schedule-table__header-dwn" onClick={downloadChartCallback}>
            <MortgageDwnIcon/>
            Скачать график
          </div>
        </div>

        <div className="crm-table payments-schedule-table" >
          <Table
            dataSource={tableData}
            columns={columns}
            rowClassName={record => record.className}
            pagination={false}
            customPagination={true}
            autoLoadMore={true}
            locale={LocaleNotFound}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentsSchedule
