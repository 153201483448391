import React from 'react';

const DotsSVG = () => {
  return (
    <svg
      width="295"
      height="295"
      viewBox="0 0 295 295"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <circle
          opacity="0.2"
          cx="6.76139"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.359"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.56"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="6.76121"
          r="6.76121"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76132"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 6.76132 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76132"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 6.76132 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76132"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 6.76132 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76119"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 6.76119 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76132"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 6.76132 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="6.76132"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 6.76132 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 53.5598 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 53.5598 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 53.5598 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 53.5598 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 53.5598 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="53.5598"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 53.5598 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.358"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 100.358 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.358"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 100.358 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.358"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 100.358 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.359"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 100.359 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.358"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 100.358 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="100.358"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 100.358 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 147.157 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 147.157 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 147.157 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 147.157 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 147.157 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="147.157"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 147.157 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 193.956 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 193.956 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 193.956 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 193.956 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 193.956 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="193.956"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 193.956 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 240.755 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 240.755 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 240.755 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.754"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 240.754 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 240.755 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="240.755"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 240.755 287.553)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="100.358"
          r="6.76121"
          transform="rotate(90 287.553 100.358)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="147.157"
          r="6.76121"
          transform="rotate(90 287.553 147.157)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="240.754"
          r="6.76121"
          transform="rotate(90 287.553 240.754)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="53.5599"
          r="6.76121"
          transform="rotate(90 287.553 53.5599)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="193.956"
          r="6.76121"
          transform="rotate(90 287.553 193.956)"
          fill="#8F95AC"
        />
        <circle
          opacity="0.2"
          cx="287.553"
          cy="287.553"
          r="6.76121"
          transform="rotate(90 287.553 287.553)"
          fill="#8F95AC"
        />
      </g>
    </svg>
  );
};

export default DotsSVG;
