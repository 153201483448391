import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from '../../api';
import EmptyTable from '../../components/core/EmptyTable';
import ErrorPlug from '../../components/ErrorBoundry/ErrorPlug';
import Preloader from '../../components/Preloader';
import { errorToast, standartErrorToast, successToast } from '../../components/toasts';
import Aside from "../../components/Training/Aside.js";
import Banner from "../../components/Training/Banner.js";
import useCountdown from '../../hooks/useCountdown';
import Additional from './Additional';
import './index.scss';
import Poll from './Poll';
import Task from './Task';

const construct = question => ({
  items: question?.variants?.map((variant, i) => ({ name: variant, value: i, fieldName: `question[${question.id}]` })),
  type: "radio",
  name: question.question,
  required: true
})

const adapter = questions => questions?.map(construct)

const Training = props => {
  const user = useSelector(state => state.user.info)
  const [formSettings, setFormSettings] = useState([])

  const [isExistResult, setIsExistResult] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const [schedule, setSchedule] = useState([])
  const [description, setDescription] = useState("")
  const [progress, setProgress] = useState({})
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [agreement, setAgreement] = useState(true)

  const [isSendAnket, setIsSendAnket] = useState(true)
  const [optionalPoints, setOptionalPoints] = useState({})

  const [serverTime, setServerTime] = useState(null)
  const [endTime, setEndTIme] = useState(null)
  const [rating, setRating] = useState(0)

  const [activePage, setActivePage] = useState("anket")

  const [answersResults, setAnswersResults] = useState({})

  const [haveActiveTest, setHaveActiveTest] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    // return // "Набор в школу закрыт"
    request("panpartner:learning.ajax", "getLearning", {}).then(resp => {
      setIsExistResult(resp.isExistResult)
      if (resp.form) {
        setFormSettings(resp.questions)
        setIsSendAnket(false)
        setForm({ formId: resp.learning.formId })
      } else {
        const tmp = [...resp.schedule].reverse();
        const findedItem = tmp.find(item => item.test.status === "active" || item.test.status === "complete")
        if (findedItem) {
          setHaveActiveTest(true)
          setActiveShedule(findedItem)
          
        } else {
          setHaveActiveTest(false)
        }
        setActivePage("poll")
      }
      setOptionalPoints(resp.optionalPoints)
      setAnswersResults(resp.testResults)
      setRating(resp.rating)
      setServerTime(resp.serverTime.replace("Z", ""))
      setSchedule(resp.schedule)
      setProgress(resp.progress)
      setIsFetching(false)
    }, err => {
      setIsError(true)
      errorToast(err?.message ? err.message : err?.[0]?.message)
    })
  }, [])
  const setLastActiveShedule = () => {
    const tmp = [...schedule].reverse();
    const findedItem = tmp.find(item => item.test.status === "active" || item.test.status === "complete")
    if(findedItem){
      setHaveActiveTest(true)
      setActiveShedule(findedItem)
      return
    }
    setHaveActiveTest(false)
  }

  const setActiveShedule = (item) => {

    const name = item.description
    const questions = adapter(item.test.questions)
    const testId = item.test.id
    const status = item.test.status
    const dateTo = item.dateTo
    setActivePage("poll")
    setDescription(name)
    setFormSettings(questions)
    setForm({ testId })
    setIsExistResult(status === "complete")
    setIsFinished(status === "finished")
    setEndTIme(dateTo.replace("Z", ""))
  }

  const handleChange = (value, ident, arrayValue) => {
    const tmp = {
      ...form
    }
    if (arrayValue && value) {
      if (!tmp[ident]) {
        tmp[ident] = []
      }
      tmp[ident].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[ident] = tmp[ident].filter(val => val !== arrayValue)
    } else {
      tmp[ident] = value
    }
    setForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = (page) => {
    if (!agreement && page === "anket") {
      setErrors(prev => ({ ...prev, agreement: true }))
      return
    }
    let formData = new FormData();
    let keys = Object.keys(form);

    keys.forEach((key) => {
      if (Array.isArray(form[key])) {
        form[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, form[key]);
      }
    });

    request("panpartner:learning.ajax", "sendAnswers", formData).then(
      (data) => {
        setIsExistResult(true)
        if (page === "anket") {
          setLastActiveShedule()
          setIsSendAnket(true)
        }
        if (data.answersResult) {
          setAnswersResults(prev => ({ ...prev, [form.testId]: data.answersResult }))
        }
        if (data.progress) {
          setProgress(data.progress)
        }
        if (data.rating) {
          setRating(data.rating)
        }

        successToast(<div>Спасибо! Форма успешно отправлена</div>)
      },
      standartErrorToast
    );
  }
  const { countdown, timeIsOver } = useCountdown(serverTime, endTime, 60000)
  if(isError) return  <EmptyTable loading={true} title="Набор в школу закрыт" />
  if (isFetching) return <Preloader loading={true} />
  return (
    <div className="wrapper">
      <section className="training section section_view_white">
        <h1 className="h1 training__title">{activePage === "add" ? `Дополнительные задания` : `PAN School`}</h1>
        <div className="training__container">
          <Aside
            schedule={schedule}
            setActiveShedule={setActiveShedule}
            progress={progress}
            isSendAnket={isSendAnket}
            rating={rating}
            setActivePage={setActivePage}
            activeShedule={form.testId}
            answersResults={answersResults}
            form={form}
          />

          <div className="training__block">
            {activePage !== "add" && isSendAnket && (!optionalPoints.link || !optionalPoints.review) && <Banner clickHandler={() => setActivePage("add")} />}
            {(() => {
              switch (activePage) {
                case "anket":
                  return <Poll
                    submit={() => submit("anket")}
                    formSettings={formSettings}
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    agreement={agreement}
                    setAgreement={setAgreement}
                    isExistResult={isExistResult}

                  />
                case "poll":
                  return <Task
                    submit={submit}
                    endTime={endTime}
                    description={description}
                    countdown={countdown} 
                    formSettings={formSettings}
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    isExistResult={isExistResult}
                    answersResults={answersResults}
                    isFinished={isFinished}
                    haveActiveTest={haveActiveTest}
                  />
                case "add":
                  return <Additional optionalPoints={optionalPoints} setOptionalPoints={setOptionalPoints} setLastActiveShedule={setLastActiveShedule} />
                default:
                  return "";
              }
            })()}
          </div>
        </div>
      </section>
    </div>
  );
};
const Wrapped = () => <ErrorPlug stopRerender> <Training /></ErrorPlug>;
export default Wrapped;
