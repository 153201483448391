import axios from "axios";
import React, { useEffect, useMemo, useState } from 'react';
import InputMask from "react-input-mask";
import { connect } from 'react-redux';
import request from '../../api';
import Preloader from "../../components/Preloader";
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast } from "../../components/toasts";
import { formatMoney } from "../../utils";
import './index.scss';
import {useLocation} from "react-router-dom";

const Payment3 = (props) => {

  const [fromLegal, setFromLegal] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const legalSum = queryParams.get('sum');
  const legalAppointment = queryParams.get('appointment');
  const fromSum = queryParams.get('fromSum');


  const { userInfo = { name: "", phone: "", lastName: "", email: "" } } = props;

  const errors = false;

  const [services, setServices] = useState(null)
  const [values, setValues] = useState({
    ...userInfo
  })
  const [isLoading, setIsLoading] = useState(false)
  const setForm = (value, ident) => setValues(prev => ({ ...prev, [ident]: value }))

  useEffect(() => {
    request("panpartner:b24.ajax", "getServices").then(data => setServices(data.services))
  }, [])



  const [sum, setSum] = useState('')

  useEffect(() => {
    if (legalSum) {
      setFromLegal(true)
      setSum(legalSum)
    }
  }, [legalSum, legalAppointment])

  const payRequest = () => {
    if (sum === '') return errorToast("Выберите услугу")
    setIsLoading(true);
    let windowReference = window.open();
    request("panpartner:payment.ajax", "getOrderId").then(data => {
      console.log(data)
      return axios({
        method: 'post',
        'Content-Type': 'application/json',
        url: "https://securepay.tinkoff.ru/v2/Init",
        data: {
          TerminalKey: "1629446085622", //required
          Amount: Number(sum) * 100, //сумма в копейках
          OrderId: data?.orderId, //Номер заказа required
          Name: `${values.name} ${values.lastName}`, //ФИО плательщика
          Email: values.email,
          Phone: values?.phone,
          Receipt: {
            Email: values.email,
            Phone: values?.phone,
            Taxation: "usn_income_outcome",

            Items: [{
              Name: legalAppointment ? legalAppointment : 'Платеж за рекламу',
              Amount: sum * 100,
              Quantity: 1,
              Price: sum * 100,
              Tax: "none"
            }]
          }
        }
      }).then(resp => {
        // debugger
        if (resp.data.Success) {
          return Promise.resolve(resp.data)
        }
        return Promise.reject(resp.data.Message)
      }).then((data => windowReference.location = data.PaymentURL), errorToast)
    }, standartErrorToast).finally(() => setIsLoading(false))
  }

  if (!services) return <Preloader loading={true} />
  return (
    <section className="section section_view_white payment newpayment">

      <div className="payment__content">
        <h1 className="h1 payment__h1">Online оплата услуги</h1>
        <div>
          <div className="payment__container">
            <div className="payment__left">
              <h3 className="h3 payment__h3">{legalAppointment}</h3>
              {/*<p className="text payment__text">Выберите услугу, заполните форму и оплатите, информация об оплате будет выслана вам на почту.</p>*/}
              <img src={require('../../assets/images/payment.svg').default} alt="" className="payment__img" />
            </div>

            <div className="payment__form">
              <div className="modal__grey-wrapper">
                <form action="#" className="modal-form modal-form_width_470 custom-payment">
                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form" htmlFor="form-name">
                        <span className="input__label">Ваше имя</span>
                        <input
                          onChange={(e) => setForm(e.target.value, "name")}
                          name="name"
                          id="form-name"
                          className="input__control"
                          value={values.name}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            Введите имя
                          </span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form" htmlFor="form-surname">
                        <span className="input__label">Ваша фамилия</span>
                        <input
                          onChange={(e) => setForm(e.target.value, "lastName")}
                          name="surname"
                          id="form-surname"
                          className="input__control"
                          value={values.lastName}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            Введите фамилию
                          </span>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="modal-form__row">
                    <div className="modal-form__item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-phone"
                      >
                        <span className="input__label">Телефон</span>
                        <InputMask
                          onChange={(e) => setForm(e.target.value, "phone")}
                          className={
                            'input__control'
                          }
                          name="phone"
                          placeholder="Введите телефон"
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar=" "
                          value={values.phone}
                        />
                        {errors.phone && (
                          <span className="input__error-label">
                            {errors.phone?.message}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="modal-form__item">
                      <label className="input input_width_available input_type_form">
                        <span className="input__label">E-mail</span>
                        <input
                          name="email"
                          className={
                            "input__control" + (errors.email ? " input__error" : "")
                          }
                          onChange={(e) => setForm(e.target.value, "email")}
                          value={values.email}
                        />
                        {errors.email && (
                          <span className="input__error-label">
                            {errors.email?.message}
                          </span>
                        )}

                      </label>
                    </div>
                  </div>


                  {!fromLegal &&
                    <div className="modal-form__row">
                      <div className="modal-form__item">
                        <label className="input input_width_available input_type_form" htmlFor="">
                          <span className="input__label">Укажите сумму оплаты</span>
                          <input
                            onChange={(e) => setSum(e.target.value)}
                            className="input__control"
                            type="number"
                            value={sum}
                          />
                          <span style={{position: 'absolute', right: '10px', bottom: '18px', color: '#8f95ac'}}>руб.</span>
                        </label>
                      </div>
                    </div>
                  }

                  {fromSum &&
                    <div className="modal-form__row">
                      <div className="modal-form__item">
                        <label className="input input_width_available input_type_form" htmlFor="">
                          <span className="input__label">Укажите сумму оплаты</span>
                          <input
                            onChange={(e) => setSum(e.target.value)}
                            className="input__control"
                            type="number"
                            value={sum}
                          />
                          <span style={{position: 'absolute', right: '10px', bottom: '18px', color: '#8f95ac'}}>руб.</span>
                        </label>
                      </div>
                    </div>
                  }



                  {
                    sum !== '' &&
                    <div className="modal-form__row modal-form__row_mt_30">
                      <div className="modal-form__item">
                        <span className="payment__price-text">Стоимость услуг*</span>
                        <p className="payment__price h3">{formatMoney(sum)} р.</p>
                      </div>
                      <div className="modal-form__item payment__submit">
                        <SubmitButton type="button" className="button button_view_default" onClick={payRequest} isLoading={isLoading}>Оплатить</SubmitButton>
                      </div>
                    </div>
                  }



                  <p className="payment__sign">* При оплате взимается комиссия до 3,5%, в указанную сумму комиссия не включена</p>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Payment3);
