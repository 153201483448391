import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import PriceFilter from '../../FilterRealty/PriceFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';


const SuburbanProjectsTopFilter = memo(props => {
  const {
    filter,
    exclude,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass = " "
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>

    {!!filterData.countBedrooms?.items?.length && <FloorsFilter
      {...createSelectProps("countBathrooms")}
      autoPosition
      items={filterData.countBedrooms?.items ?? []}
      filter={filter.countBedrooms ?? {}}
      wrapperClass={wrapperClass}
      data={{
        title: 'Кол-во спален',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.countBedrooms?.range ?? null,
      }}
      onChange={v => setFilterAndExclude(v, 'countBedrooms')}
    />}
    {!!filterData.countBathrooms?.items?.length && <FloorsFilter
      {...createSelectProps("countBathrooms")}
      autoPosition
      items={filterData.countBathrooms?.items ?? []}
      filter={filter.countBathrooms ?? {}}
      wrapperClass={wrapperClass}
      data={{
        title: 'Кол-во санузлов',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.countBathrooms?.range ?? null,
      }}
      onChange={v => setFilterAndExclude(v, 'countBathrooms')}
      // onChange={v => {
      //   setFilterAndExclude(v, 'countBathrooms')
      // }}

      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      // onChange={(a) => handleFilter(a.filter, 'countBathrooms')}
    />}



    {!!filterData.squareHouse && <SquareFilterOnly
      autoPosition
      wrapperClass=" "
      filter={filter.squareHouse ?? { min: "", max: "" }}
      data={{
        title: 'Общая S дома',
        minPlaceholder: 'S дома от ',
        maxPlaceholder: 'S дома до ',
        defaultValues: null
      }}
      onChange={v => setFilterAndExclude(v, 'squareHouse')}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareHouse"}
      items={filterData.squareHouse?.items}
      showPlaceholderOnLists
      debounced
    />}
  </>
});

export default SuburbanProjectsTopFilter;
