import React from "react";
import './index.scss'
import {ResidenceHintIcon} from "../Public/markup/icons";

const ResidenceHint = ({withBottomBorder, title, text}) => {
  return (
    <div className={`residence-hint ${withBottomBorder && 'residence-hint_bottom-border'}`}>
      <div className="residence-hint__icon">
        <ResidenceHintIcon/>
      </div>
      <div className="residence-hint__content">
        <p className="residence-hint__title">{title}</p>
        <p className="residence-hint__text">{text}</p>
      </div>
    </div>
  )
}

export default ResidenceHint
