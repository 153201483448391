import { memo } from "react";
import { getUrl } from "../../../../api";
import { priceFormat } from "../../../../utils";
import Card from "../../../core/Card";


const AssignationPublicCard = memo((props) => {
    const { block, link, endingDate, planUrl, costWithDiscount, squareTotal, roomType } = props
    const cardTitle = `${priceFormat(costWithDiscount)}, ${squareTotal} м²`;

    return <Card
        {...props}
        isFlat
        isBordered
        topLabel={roomType}
        link={link}
        bottomText={endingDate ? `Срок сдачи: ${endingDate}` : null}
        title={cardTitle}
        src={getUrl(planUrl)}
        descTitle={block.name}
    />
});

export default AssignationPublicCard;
