import React, { useRef, useState } from "react";
import "react-photoswipe/lib/photoswipe.css";
import ErrorPlug from "../components/ErrorBoundry/ErrorPlug";
import LoadMoreSpinner from "../components/LoadMoreSpinner";
import Preloader from "../components/Preloader";
import Promo from "../components/Special/Promo.js";
import '../components/Special/index.scss';

import DraggableZone from "../components/FilterRealty/Draggable";
import { FilterSearch } from "../components/FilterRealty/FilterSearch";
import { CardWithFavorite } from '../components/Special/Card';
import Dropdown from "../components/core/Dropdown/index.js";
import EmptyTable from "../components/core/EmptyTable";
import { sortListProjects } from "../hooks/useFilter/filterSets.js";
import useFilter from "../hooks/useFilter/index.js";
import usePermission from "../hooks/usePermission.js";

import useMedia from "../hooks/useMedia.js";
import ProjectsTopFilter from "../components/Public/markup/ProjectsTopFilter.jsx";
import ProjectsBottomFilter from "../components/Public/markup/ProjectsBottomFilter.jsx";
import { getSuffix } from "../utils/index.js";
import SubmitButton from "../components/SubmitButton.js";

import { ReactComponent as SavedFilterIcon } from "../assets/images/icons/SaveFilter.svg";
import { useModal } from 'react-modal-hook';
import Wrap from "../components/core/ModalWrap/index.js";
import MobileModal from "../components/core/MobileModal/index.js";
import Modal from "../components/core/Modal/index.js";
import SendFilter from "../components/SavedFilters/SendFilter.js";
import FilterToggleBtn from "../components/Public/markup/FilterToggleBtn.jsx";
import MapBtn from "../components/Public/markup/MapBtn.jsx";
import useDocTitle from "../hooks/useDocTitle";
import {getURLparam} from "../utils/location";
import {FilterDebouncedSearch} from "../components/FilterRealty/FilterDebouncedSearch";
import request from "../api";
import PriceFilter from "../components/FilterRealty/PriceFilter";
import SelectFilter from "../components/FilterRealty/SelectFilter";
import {getSelectPropsCreator} from "../components/Public/utils";
import FloorsFilter from "../components/FilterRealty/FloorsFilter";
import SquareFilterOnly from "../components/FilterRealty/SquareFilterOnly";
import {SuburbanAdvantagesFilterIcon, SuburbanHouseFilterIcon} from "../components/Public/markup/icons";
import { uniqBy } from "lodash";

const SpecialNew = () => {

  const showComission = usePermission("motivation")

  const [showFilter, toggleFilter] = useState(false);

  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');

  const [localState, setLocalState] = useState({
    showFilter: false,
    realtyType: "house",
    searchValue: "",
    searchResults: [],
    searchData: []
  });
  
  const localStateRef = useRef(localState)
  localStateRef.current = localState;

  const {
    data: { filter: filterData, contractConstructions },
    handleFilter,
    submit,
    isLoading,
    infiniteRef,
    filter = {},
    exclude = {},
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    loadSubItems,
    isCountLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,
    count,
    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    limit,
    moreIsLoading,
    createBadges,
    handleSort,
    sortId,
    favoritesProps,
    get,
    clearByIdent
  } = useFilter({
    apiConfig: {
      component: "panpartner:new.suburbans.ajax",
      getCountFunction: 'getProjectListCount',
      submitFunction: 'getProjectList',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      city: "spb",
      type: localState.realtyType,
    },
    shadowFilters: ['status'],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: '',
    },
    sortEnabled: true,
    paginationEnabled: true,
    loadMoreEnabled: true,
    isGetFilter: 1,
    loadMoreDataKey: 'contractConstructions',
    processData: (resp, prev) => ({ ...prev, ...resp }),
    defaultView: "list",
    defaultPage: 1,
    defaultLimit: 9,
    defaultSortId: 0,
    defaultOrder: "sort",
    defaultBy: "asc",
    sortList: sortListProjects,
    badgeFormattersParams: {
      data: localStateRef.current.searchData
    },
    specialBadgeTitles: {
      floors: "Этажей: ",
      contractConstruction: "Проект: "
    },
    refreshWhenFilterChange: false,
    favoritesParams: {
      type: 'contractConstruction',
    },
  });

  const [openSF, closeSF] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeSF} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={closeSF} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="contractConstruction"
          hide={closeSF}
          city={"spb"}
          filterBadges={filterBadges}
          excludeBadges={excludeBadges}
        />
      </Wrap >
    ),
    [filter, exclude, filterData, filterBadges, excludeBadges],
  );

  useDocTitle("Строительство домов");

  const onSearch = query => {
    setLocalState(prev => ({ ...prev, searchValue: query }))
    if (!query) return setLocalState(prev => ({ ...prev, searchValue: "", searchResults: [] }))
    request('panpartner:new.suburbans.ajax', "search", { query, isProjects: 1 }).then(resp => {
      setLocalState(prev => ({ ...prev, searchResults: resp.result, searchData: uniqBy([...prev.searchData, ...resp.result], "title") }))
    })
  };


  const createSelectProps = getSelectPropsCreator({filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    clearExclude,
    clearFilter
    // clearExcludeOnly,
    // clearFilterOnly
  });


  if (isLoading) return <Preloader loading={true} />;
  return (
    <section className="special">
      <Promo />
      <section className="special__cards">
        <div className="wrapper">

          <div className="special__filter-section section">
            <h3 className="h3 special__h3 special__filter-title">Поиск дома по параметрам</h3>


            <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_new-suburban ${!isLaptop ? "" : "flex"}`} style={{marginTop: 0}}>
              <FilterDebouncedSearch
                searchResultHandler={(v, key) => {
                  handleFilter(key, v)
                  setLocalState(prev => ({ ...prev, searchResults: [] }))
                }}
                results={localState.searchResults}
                onSearch={onSearch}
                value={localState.searchValue}
                placeholder="Название КП, застройщик, район"
                toggleFilter={() => toggleFilter(prev => !prev)}
                hideInfoButton
                suburbansSearch
              />
              <PriceFilter
                filter={filter.price ?? { min: "", max: "" }}
                items={filterData.price?.items ?? []}
                data={{
                  title: "Цена",
                  minPlaceholder: "от ",
                  maxPlaceholder: "до ",
                  defaultValues: filterData.price?.range ?? 0
                }}
                onChange={({ filter }) => setFilterAndExclude({ filter }, "price")}
                debounced
                clearFilterOnly={() => clearByIdent("price")}
                clearExcludeOnly={() => clearByIdent("price", "exclude")}
              />

              <SelectFilter
                {...createSelectProps("materialHouse")}
                data={{
                  title: "Материалы дома",
                  identity: "materialHouse",
                }}
              />

              <SquareFilterOnly
                autoPosition
                filter={filter.squareHouse ?? { min: "", max: "" }}
                data={{
                  title: 'Общая S дома',
                  minPlaceholder: 'S дома от ',
                  maxPlaceholder: 'S дома до ',
                  defaultValues: null
                }}
                onChange={v => setFilterAndExclude(v, 'squareHouse')}
                clearExclude
                clearFilter
                fullFilter={filter}
                identity={"squareHouse"}
                items={filterData.squareHouse?.items}
                showPlaceholderOnLists
                debounced
              />

              <button
                className="button button_type_reveal-filter white-button"
                type="button"
                onClick={() => toggleFilter(!showFilter)}
              >
                <span >Все фильтры</span>
              </button>

            </div>

            {showFilter &&
              <>
              <div className={`sf__filter filter__max filter__max_seven form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`} style={{marginTop: 10}}>
                <div className="filter-title">
                  <SuburbanHouseFilterIcon/>
                  Параметры дома
                </div>


                <SquareFilterOnly
                  autoPosition
                  filter={filter.squareLiving ?? { min: "", max: "" }}
                  data={{
                    title: 'Жилая S дома',
                    minPlaceholder: 'S дома от ',
                    maxPlaceholder: 'S дома до ',
                    defaultValues: null
                  }}
                  onChange={v => setFilterAndExclude(v, 'squareLiving')}
                  clearExclude
                  clearFilter
                  fullFilter={filter}
                  identity={"squareLiving"}
                  items={filterData.squareLiving?.items}
                  showPlaceholderOnLists
                  debounced
                />

                <FloorsFilter
                  {...createSelectProps("countFloors")}
                  onChange={v => setFilterAndExclude(v, "countFloors")}
                  filter={filter?.countFloors ?? { min: "", max: "" }}
                  items={filterData.countFloors?.items ?? []}
                  data={{
                    title: "Кол-во этажей",
                    minPlaceholder: "от ",
                    maxPlaceholder: "до ",
                    defaultValues: filterData.countFloors?.range ?? null,
                  }}
                  wrapperClass={' '}
                />
                <SelectFilter
                  {...createSelectProps("countBedrooms")}
                  data={{
                    title: "Кол-во спален",
                    identity: "countBedrooms",
                  }}
                  wrapperClass={' '}
                />
                <SelectFilter
                  {...createSelectProps("countBathrooms")}
                  data={{
                    title: "Кол-во санузлов",
                    identity: "countBathrooms",
                  }}
                  wrapperClass={' '}
                />

                <SelectFilter
                  {...createSelectProps("decorationHouse")}
                  data={{
                    title: "Отделка",
                    identity: "decorationHouse",
                  }}
                  wrapperClass={' '}
                />

                <SelectFilter
                  {...createSelectProps("constructionPeriod")}
                  data={{
                    title: "Срок строительства",
                    identity: "constructionPeriod",
                  }}
                  wrapperClass={' '}
                />

              </div>

              <div className={` sf sf__filter filter__max filter__max_multi form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`} style={{marginTop: 10}}>

                <div className="filter-title">
                  <SuburbanAdvantagesFilterIcon />
                  Приемущества
                </div>

                <Toggle
                  ident="hasTerrace"
                  filter={filter}
                  handleFilter={handleFilter}
                  text="Терраса"
                />
                <Toggle
                  text="Кухня-гостинная"
                  ident="hasKitchenLiving"
                  filter={filter}
                  handleFilter={handleFilter}
                />
                <Toggle
                  text="Панорамные окна"
                  ident="hasPanoramicWindows"
                  filter={filter}
                  handleFilter={handleFilter}
                />

                <Toggle
                  text="Гардероб"
                  ident="hasWardrobe"
                  filter={filter}
                  handleFilter={handleFilter}
                />

                <Toggle
                  text="Второй свет"
                  ident="hasSecondLight"
                  filter={filter}
                  handleFilter={handleFilter}
                />

                <Toggle
                  text="Мансардный этаж"
                  ident="hasAtticFloor"
                  filter={filter}
                  handleFilter={handleFilter}
                />

                <Toggle
                  text="Сауна"
                  ident="hasSauna"
                  filter={filter}
                  handleFilter={handleFilter}
                />
              </div>
              </>
            }



            {
              !isLoading &&
              <>

                <DraggableZone
                  filterBadges={filterBadges}
                  excludeBadges={excludeBadges}
                  clearFilter={clearFilter}
                  clearExclude={clearExclude}
                  isExcludeEmpty={isExcludeEmpty}
                  isFilterEmpty={isFilterEmpty}
                  removeBadge={removeBadge}
                  switchValues={switchFilterToExclude}
                />

                <div className="sf__row" style={{marginTop: 10}}>
                  {!isTablet && <div className="villages-save-filter" onClick={openSF}>
                    <SavedFilterIcon />
                    <span>Сохранить фильтр</span>
                  </div>}
                  <div />
                  <div className="sf__filter-btns">
                    {/* {isTablet && <FilterToggleBtn />}
                    <MapBtn
                      setShowMap={() => setView('map')}

                    /> */}
                    {!isTablet && (
                      <span style={isLaptop ? { marginLeft: 20 } : {}}>
                        <SubmitButton
                          className="sf__filter-btn btn btn_primary h6"
                          onClick={submit}
                          isLoading={isLoading}
                          type="button"
                          disabled={!count}
                        >
                          {`${count ?? 0} проект${getSuffix(count ?? 0)}`}
                        </SubmitButton>
                      </span>
                    )}
                  </div>
                </div>

              </>
            }

            {/*<div className="special-filter-drop">*/}
            {/*  <Dropdown*/}
            {/*    innerClass={``}*/}
            {/*    value={sortId}*/}
            {/*    options={sortListProjects}*/}
            {/*    onChange={handleSort}*/}
            {/*  />*/}
            {/*</div>*/}

            <h4 className="h4" style={{marginTop: 35, marginBottom: 10}}>{`Найдено ${count} проект${getSuffix(count)}`}</h4>

            {contractConstructions?.length > 0 ? <ErrorPlug stopSending={true}>
              <div className="special__cards-list" ref={infiniteRef}>
                {contractConstructions.map((item, i) => <CardWithFavorite hideTitleNewVersion newProjectHouse key={item.id} {...item} {...favoritesProps} index={i} get={get} />)}
              </div>
            </ErrorPlug> :
              <EmptyTable title="Ничего не найдено" />
            }
            {moreIsLoading && <div className="table__show-more"> <LoadMoreSpinner loading={true} /></div>}
          </div>
        </div>
      </section>
      <Preloader loading={isLoading} />
    </section>
  );
};
const Toggle = ({ title, filter, handleFilter, ident, text}) => {
  return (
    <div className="index-search__show-booked">
      <label className="switch" htmlFor={`filter-booked-${ident}`}>
        <div className="switch__box">
          <input

            className="switch__control"
            type="checkbox"
            id={`filter-booked-${ident}`}
            checked={filter[ident] === "Y"}
            onChange={e => handleFilter(ident, filter[ident] === "Y" ? "" : "Y")}

          />
          <div
            className="switch__input"
            htmlFor={`filter-booked-${ident}`}
          />
          <div className="switch__marker" />
        </div>
        <span className="switch__label">
          {/*{title}*/}
          {text}
        </span>
      </label>
    </div>
  )
}

export default SpecialNew;
