import _ from "lodash";
import { createSelector } from "reselect";
import request from "../api";
import { actionFactory } from "./actionUtils";

const groupsSelector = (state) => state.contacts.groups;
const dataSelector = (state) => state.contacts.contacts;
const filterSelector = (state) => state.contacts.filter;

export const filteredData = createSelector(
  [dataSelector, filterSelector, groupsSelector],
  (clients, filter, groups) => {
    let result = clients;

    if (filter.searchTerm) {
      result = result.filter(({ search }) =>
        search.some((elem) =>
          elem.toLowerCase().includes(filter.searchTerm.toLowerCase())
        )
      );
    }

    if (filter.department.length) {
      result = result.filter((item) =>
        _.includes(filter.department, item.sectionId)
      );
    }

    if (filter.builder.length) {
      result = result.filter(
        ({ builders }) =>
          builders &&
          builders.some((item) => _.includes(filter.builder?.toString?.(), item.id?.toString?.()))
      );
    }

    let filterGroupsIds = _.uniq(_.map(result, "sectionId"));

    let contactsItems = groups
      .filter((group) => _.includes(filterGroupsIds, group.id))
      .map((group) => {
        return {
          ...group,
          items: result.filter((item) => group.id == item.sectionId),
        };
      });

    return contactsItems;
  }
);

const requestContacts = actionFactory("CONTACTS_REQUEST");

const successContacts = actionFactory("CONTACTS_SUCCESS");

export const clearContacts = actionFactory("CONTACTS_RESET");

const failureContacts = actionFactory("CONTACTS_FAILURE");

export const setFilterContacts = actionFactory("CONTACTS_SET_FILTER");

export const clearFilterContacts = actionFactory("CONTACTS_CLEAR_FILTER");

export const fetchContacts = () =>
  dispatch => {
    dispatch(requestContacts());
    return request("panpartner:contacts.ajax", "getContacts")
      .then(
        data => {
          dispatch(successContacts(proccedData(data)));
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureContacts());
          return Promise.reject(error);
        }
      );
  };

const proccedData = (data) => {
  let groups = [];
  let items = [];
  let contacts = [];
  if (data.personalManager) {
    contacts.push({
      id: 0,
      name: "Ваш личный менеджер",
      items: [
        {
          ...data.personalManager,
          sectionId: 0,
        },
      ],
    });
  }
  contacts = [...contacts, ...data.contacts];
  //groups = data.filter.department;

  Object.keys(contacts).map((key) => {
    let { id, name } = contacts[key];
    let newItems = contacts[key].items.map((item) => ({
      ...item,
      search: _.compact([
        item.name,
        item.watsApp,
        item.email,
        item.phone,
        item.position,
      ]),
    }));
    items = [...items, ...newItems];
    groups.push({ id, title: name });
    return key;
  });

  return {
    filterData: data.filter,
    contacts: items,
    groups: groups,
    managersOnDuty : data.managersOnDuty,
  };
};