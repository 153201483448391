import { useMediaQuery } from 'beautiful-react-hooks';
import React, { memo, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PanCard from '../../components/Cards/PanCard';
import useMedia from "../../hooks/useMedia";
import {
  applyToFormData,
  formatMoney,
  getPricePerMeter,
  getRoundedPrice,
  getSuffix,
  objectsArrayToFormData,
  toFormData
} from "../../utils";
import ComplexView from '../Complex/ComplexView';
import Table from "../core/Table";
import TablePhoto from '../Secondaries/TablePhoto';
import { parseStringAsInt } from './Utils';
import {
  AddToCompilationIcon, PdfIcon, SfFilterCopyIcon,
  SuburbanAddPresentationIcon,
  SuburbanShareTableIcon,
  TelegramIcon, WhatsupIcon
} from "../Public/markup/icons";
import useApi from "../../hooks/useApi";
import {successToast} from "../toasts";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import request, {getUrl} from "../../api";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "../core/Button";
import useClickPast from "../../hooks/useClickPast";
const whitelistForCardClick = [
  // "card__left",
  // "card__right",
  // "card__metro-station",
  // "card__left-part",
  // "card__metro-color card__metro-color_1",
  "card__pill",
  "pan-card__header",
  "pan-card__content",
  "pan-card__title",
  "pan-card__address",
  "pan-card__dates-to",
  "pan-card__dates-from",
  "card__pill",
  "pan-card__controls",
  "aero-icon",
  "aero-svg",
];


export const Card = (props) => {
  const {
    id,
    squareTotal,
    floor,
    floors,
    isPublic,
    savedFilterXmlId,
    squareKitchen,
    decoration,
    section,
    plan,
    costWithDiscount,
    countBedrooms,
    number,
    price,
    squareParcel,
    photos,
    ending,
    bathroomTypes,
    roomType,
    communications
  } = props;

  const { city } = useParams();
  return (
    <div className="complex__appartments-card">
      <PanCard
        handleClick={(e) => {
          if (whitelistForCardClick.includes(e.target.classList.toString())) {
            window.open(
              isPublic
                ? `/public/lands/${savedFilterXmlId}/${city}/${id}`
                : `/lands/${city}/${id}${window.location.search}`
            );
          }
        }}
        communications={communications}
        countBedrooms={countBedrooms}
        ending={ending}
        floors={floors}
        number={number}
        photos={photos}
        plan={plan}
        price={price}
        roomType={roomType}
        square={squareTotal}
        squareParcel={squareParcel}
        savedFilterXmlId={savedFilterXmlId}
        listItems={
          [
            {
              title: 'ID',
              value: id
            },
            {
              title: 'S кухни',
              value: squareKitchen
            },
            {
              title: 'Этаж',
              value: floor
            },
            {
              title: 'Отделка',
              value: decoration
            },
            {
              title: 'Корпус',
              value: section
            },
            {
              title: '№ кв.',
              value: number
            },
          ]

        }
      />
    </div>
  );
};




export const TableApartments = memo(props => {

  const { clientMode, items, isPublic, savedFilterXmlId, headers, tabTitle, isNewSuburban, getLink, parcelId, externalSorting } = props;

  const { city } = useParams();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isSmallMobile = useMedia("isSmallMobile")
  const media1080 = useMediaQuery('(max-width: 1080px)');


  const DropTog = (id, compilationObjectType) => {

    const [active, setAvtive] = useState(false)
    const node = useClickPast(() => {setAvtive(false)});
    const getPdf = () => {
      let formData = toFormData( {});
      formData = objectsArrayToFormData([{objectId: id.id, city: 'spb', type: 'contractConstruction'},{objectId: parcelId, city: 'spb', type: 'parcel'}], "objects", formData)
      return request('panpartner:new.suburbans.ajax', 'addPresentation', formData)
        .then((data) => {

          return request('panpartner:new.suburbans.ajax', 'getPresentationPdf', {type: 'contractConstruction', id: data.xmlId})
            .then((resp) => {
              window.open(getUrl(resp.path))
            })

        })
    }

    const addSuburbanPresentation = () => {
      request("panpartner:new.suburbans.ajax", "addPresentation",
        objectsArrayToFormData([{type: "contractConstruction", city: "spb", objectId: id.id}, {objectId: parcelId, city: 'spb', type: 'parcel'} ], "objects"))
        .then((resp) => {
          if (resp?.xmlId) {
            navigator.clipboard.writeText(getUrl(`/suburban/presentation/${resp.xmlId}`))
            successToast("Презентация успешно создана. Cсылка скопирована в буфер обмена")
          } else {
            console.error("Ошибка: xmlId не найден в ответе");
          }
        })
        .catch((error) => {
          console.error("Ошибка запроса:", error);
        });
    };

    return (
      <div className="table-compilation-icon"  ref={node} onClick={() => {setAvtive(!active)}}>
        <span className="table-compilation-icon_share"><SuburbanShareTableIcon/></span>


        {active && <ul style={{right: 0, top: 0, width: 300}} className={`crm-table__sf-controls crm-table__sf-controls_shareOn`}>
          <li onClick={() => {addSuburbanPresentation()}}>
          <span style={{color: 'inherit', display: 'flex', width: '100%', alignItems: 'center'}}>
            <SuburbanAddPresentationIcon/>
            Ссылка на презентацию
          </span>
          </li>
          <li onClick={() => {getPdf()}}>
          <span style={{color: 'inherit', display: 'flex', width: '100%', alignItems: 'center'}}>
            <PdfIcon />
            Скачать PDF
          </span>
          </li>
        </ul>}
      </div>
    )
  }

  const some = (key) => !!items.filter(item=> !!item[key]).length
  const filtrateTable = ({ dataIndex, required }) => {
    const inHeaders = headers ? headers.includes(dataIndex) : true
    return inHeaders && (required || some(dataIndex))
  }
  const haveSquareTotal = some("squareTotal")

  const [isId, setIsId] = useState(null)
  const [isType, setIsType] = useState(null)

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: isId, type: isType, city }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })


  const getColumns = () => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Фото',
      dataIndex: 'photo',
      width: media1080 ? 60 : 100,
      key: 'photo',
      render: (plan) => {
        return {
          props: {
            className: 'image-table-cell',
          },
          children: <TablePhoto plan={typeof plan === "string" ? {src: plan} : plan} isNewSuburban/>,
        };
      },
    },
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: 'Фото',
      dataIndex: 'plan',
      width: media1080 ? 60 : 100,
      key: 'plan',
      render: (plan) => {
        return {
          props: {
            className: 'image-table-cell',
          },
          children: <TablePhoto isNewSuburban={isNewSuburban} plan={plan} />,
        };
      },
    },
    {
      title: "Цена 100%",
      dataIndex: "priceDiscount",
      key: "priceDiscount",
      sorter: (a, b) => parseStringAsInt(a.priceDiscount) - parseStringAsInt(b.priceDiscount),
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
    {
      title: "Цена базовая",
      dataIndex: "priceBase",
      key: "priceBase",
      sorter: (a, b) => parseStringAsInt(a.priceBase) - parseStringAsInt(b.priceBase),
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
    {
      title: "Тип объекта",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => (a.type?.title > b.type?.title ? 1 : -1),
      render: (type, item, index) => {
        return type?.title
      },
    },
    {
      title: "Срок строительства",
      dataIndex: "constructionPeriod",
      key: "constructionPeriod",
      sorter: (a, b) => a.constructionPeriod > b.constructionPeriod ? 1 : -1,
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
    {
      title: "Материал стен",
      dataIndex: "materialHouse",
      key: "materialHouse",
      sorter: (a, b) => parseStringAsInt(a.materialHouse) - parseStringAsInt(b.materialHouse),
      render: (materialHouse) => {
        return materialHouse || '—'
      },
    },
    {
      title: "S дома, м²",
      dataIndex: "squareHouse",
      key: "squareHouse",
      sorter: (a, b) => parseStringAsInt(a.squareTotal) - parseStringAsInt(b.squareTotal),
      render: (squareTotal) => {
        return squareTotal || '—'
      },
    },
    {
      title: "Цена 100%",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => parseStringAsInt(a.price) - parseStringAsInt(b.price),
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
    {
      title: "Цена, м² ",
      dataIndex: "price",
      key: "pricePerMeter",
      render: (price, item) => <span className="nowrap">{!!item.squareTotal && !!price ? `${formatMoney(getPricePerMeter(price, item.squareTotal), " ₽")}`: '—'}</span>,
    },
    // {
    //   title: "Цена за сотку ",
    //   dataIndex: "price",
    //   key: "pricePerMeter",
    //   render: (price, item) => <span className="nowrap">{!!item.squareParcel && !!price ? `${formatMoney(getPricePerMeter(price, item.squareParcel))} ₽`: '—'}</span>,
    // },
    {
      title: "S общ., м²",
      dataIndex: "squareTotal",
      key: "squareTotal",
      sorter: (a, b) => parseStringAsInt(a.squareTotal) - parseStringAsInt(b.squareTotal),
      render: (squareTotal) => {
        return squareTotal || '—'
      },
    },
    {
      title: "S уч. сот.",
      dataIndex: "squareParcel",
      key: "squareParcel",
      sorter: (a, b) => parseStringAsInt(a.squareParcel) - parseStringAsInt(b.squareParcel),
      render: (squareParcel) => {
        return squareParcel || '—'
      },
    },
    {
      title: "№ участка",
      dataIndex: "numberParcel",
      key: "numberParcel",
      sorter: (a, b) => a.number - b.number,
      render: (number) => {
        return number || '—'
      },
    },
    {
      title: "Коммуникации",
      dataIndex: "communications",
      key: "communications",
      sorter: (a, b) => a.communications > b.communications ? 1 : -1,
    },
    {
      title: "Доп.платежи",
      dataIndex: "extraPayments",
      key: "extraPayments",
      sorter: (a, b) => a.extraPayments > b.extraPayments ? 1 : -1,
    },
    {
      title: "Доп. опции",
      dataIndex: "extraOptions",
      key: "extraOptions",
      sorter: (a, b) => a.extraOptions > b.extraOptions ? 1 : -1,
    },
    {
      title: "Спален",
      dataIndex: "countBedrooms",
      key: "countBedrooms",
      sorter: (a, b) => a.countBedrooms - b.countBedrooms,
      render: (countBedrooms, { id, compilationObjectType}) => {
        return (
          <>
            {countBedrooms || '—'}
            <div className="table2-status" style={{float: 'right'}}>

              <div className="table-compilation-icon" onClick={(e) => {
                e?.stopPropagation?.();
                setIsId(id)
                setIsType(compilationObjectType)
                openCompilationModal()
              }
              }>
                <AddToCompilationIcon/>
                <div className="table-compilation-icon__hint">Добавить в подборку</div>
              </div>
            </div>
          </>
        )
      },

    },
    {
      title: "№",
      dataIndex: "number",
      key: "number",
      sorter: (a, b) => a.number - b.number,
      render: (number) => {
        return number || '—'
      },
    },
    {
      title: media1080 ? "Эт." : "Этажей",
      dataIndex: "floors",
      key: "floors",
      sorter: (a, b) => a.floors - b.floors,
      render: (floors) => {
        return floors || '—'
      },
    },
    {
      title: "Тип",
      dataIndex: "section",
      key: "section",
      sorter: (a, b) => a.section > b.section ? 1 : -1,
      render: (section, { id, compilationObjectType}) => {
        return (
          <>
            {section}
            {tabTitle === 'ТаунХаус' &&
            <div className="table2-status" style={{float: 'right'}}>
              <div className="table-compilation-icon" onClick={(e) => {
                e?.stopPropagation?.();
                setIsId(id)
                setIsType(compilationObjectType)
                openCompilationModal()
              }
              }>
                <AddToCompilationIcon/>
                <div className="table-compilation-icon__hint">Добавить в подборку</div>
              </div>
            </div>
            }
          </>
        )
      },
    },
    {
      title: "Цена с участком",
      dataIndex: "fullPrice",
      key: "fullPrice",
      sorter: (a, b) => parseStringAsInt(a.fullPrice) - parseStringAsInt(b.fullPrice),
      render: (price, { id, compilationObjectType} ) => {
        return (
          <>
            {typeof price === "string" ? price : formatMoney(price)}
            <div className="table2-status" style={{float: 'right'}}>
            <div className="table-compilation-icon" onClick={(e) => {
              if (!isNewSuburban) {
              e?.stopPropagation?.();
              setIsId(id)
              setIsType(compilationObjectType)
              openCompilationModal()} else {
                e?.stopPropagation?.();
              }
            }
            }>

              {!isNewSuburban ? <AddToCompilationIcon/> : <DropTog id={id} compilationObjectType={compilationObjectType}/>}

              {!isNewSuburban && <div className="table-compilation-icon__hint">Добавить в подборку</div>}
            </div>
            </div>
          </>
        )

      },
    },
    {
      title: media1080 ? "Сдача" : "Срок сдачи",
      dataIndex: "ending",
      key: "ending",
      sorter: (a, b) => a.ending.length - b.ending.length,
      render: (ending, { id, compilationObjectType} ) => {
        return (
          <>
            {ending}
            <div className="table2-status" style={{float: 'right'}}>
              <div className="table-compilation-icon" onClick={(e) => {
                e?.stopPropagation?.();
                setIsId(id)
                setIsType(compilationObjectType)
                openCompilationModal()
              }
              }>
                <AddToCompilationIcon/>
                <div className="table-compilation-icon__hint">Добавить в подборку</div>
              </div>
            </div>
          </>
        )

      },
    },
    {
      title: media1080 ? "Сдача" : "Срок сдачи",
      dataIndex: "endingDate",
      key: "endingDate",
      sorter: (a, b) => a.endingDate.length - b.endingDate.length,
    },
    {
      title: "Примечание",
      dataIndex: "note",
      key: "note",
      required: true,
      sorter: (a, b) => a.note.length - b.note.length,
      render: (note, { id, compilationObjectType, xmlId} ) => {
        return (
          <>
            {note || '—'}
            <div className="table2-status" style={{float: 'right'}}>

              <div className="table-compilation-icon" onClick={(e) => {
                e?.stopPropagation?.();
                setIsId(id)
                setIsType(compilationObjectType)
                openCompilationModal()
              }
              }>
                <AddToCompilationIcon/>
                <div className="table-compilation-icon__hint">Добавить в подборку</div>
              </div>
            </div>
          </>
        )

      },
    },
    {
      title: "Отделка",
      dataIndex: "decoration",
      key: "decoration",
      sorter: (a, b) => a.decoration.length - b.decoration.length,
    },
    // {
    //   title: "Статус",
    //   dataIndex: "status",
    //   key: "status",
    //   sorter: (a, b) => (a.status?.name > b.status?.name ? 1 : -1),
    //   render: (status, item, index) => {
    //     if (item.objectType === "assignment") {
    //       return <div class="status-assignment">Уступка ПАН</div>
    //     }
    //     return status?.name
    //   },
    // },
  ].filter(filtrateTable);

  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const getMobileColumns = () => [
    {
      title: "Эт",
      dataIndex: "floors",
      key: "floors",
    },
    {
      title: "S общ",
      dataIndex: "squareTotal",
      key: "squareTotal",
      render: (square) => <span className="nowrap">{square} м²</span>,
    },
    {
      title: "Цена 100%",
      dataIndex: "price",
      key: "price",
      render: (price) => <span className="nowrap">{getRoundedPrice(parseStringAsInt(price))} млн.</span>,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status, item, index) => {
        if (item.objectType === "assignment") {
          return <div class="status-assignment">Уступка ПАН</div>
        }
        return status?.name
      },
    },
    {
      title: "№ участка",
      dataIndex: "numberParcel",
      key: "numberParcel",
      sorter: (a, b) => a.number - b.number,
      render: v => v || '—',
    },

    {
      title: "S уч. сот.",
      dataIndex: "squareParcel",
      key: "squareParcel",
      sorter: (a, b) => parseStringAsInt(a.squareParcel) - parseStringAsInt(b.squareParcel),
      render: v => v || '—',
    },
    {
      title: "Цена 100%",
      dataIndex: "priceDiscount",
      key: "priceDiscount",
      sorter: (a, b) => parseStringAsInt(a.price) - parseStringAsInt(b.price),
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
  ].filter(filtrateTable);

  const getSmallMobileColumns = () => [
    {
      title: "Эт",
      dataIndex: "floors",
      key: "floors",
    },
    ...(haveSquareTotal? [{
      title: "S общ",
      dataIndex: "squareTotal",
      key: "squareTotal",
      render: (square) => <span className="nowrap">{square} м²</span>,
    }]: []),
    {
      title: "Цена 100%",
      dataIndex: "price",
      key: "price",
      render: (price) => <span className="nowrap">{getRoundedPrice(parseStringAsInt(price))} млн.</span>,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status, item, index) => {
        if (item.objectType === "assignment") {
          return <div class="status-assignment">Уступка ПАН</div>
        }
        return status?.name
      },
    },

    {
      title: "№ участка",
      dataIndex: "numberParcel",
      key: "numberParcel",
      sorter: (a, b) => a.number - b.number,
      render: v => v || '—',
    },

    {
      title: "S уч. сот.",
      dataIndex: "squareParcel",
      key: "squareParcel",
      sorter: (a, b) => parseStringAsInt(a.squareParcel) - parseStringAsInt(b.squareParcel),
      render: v => v || '—',
    },
    {
      title: "Цена 100%",
      dataIndex: "priceDiscount",
      key: "priceDiscount",
      sorter: (a, b) => parseStringAsInt(a.price) - parseStringAsInt(b.price),
      render: (price) => {
        if(typeof price === "string") return price
        return formatMoney(price)
      },
    },
  ].filter(filtrateTable);

  const adaptivePrice = (price) => isMobile
    ? `${getRoundedPrice(price)} млн.`
    : formatMoney(price, " ₽")

  const expandedRow = (record) => (
    <div className="flats__row-table-expanded">
      <div className="flats__row-table-expanded-column">
        <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">ID:</span>
          {record.id}
        </div>

        <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">Фото:</span>
          <TablePhoto plan={record.plan} />
        </div>
        <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">Тип объекта:</span>
          {record.type.title}
        </div>
       {!!record.squareTotal && <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">Цена, м²:</span>
          {formatMoney(getPricePerMeter(record.price, record.squareTotal))} ₽
        </div>}
        <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">S общ.:</span>
          {record.squareTotal}
        </div>

        <div className="lk-presentation__clients">
          <span className="flats__row-table-expanded-title">S жил.:</span>
          {record.squareLiving}
        </div>
        {/*
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">S кух.:</span>
            {record.squareKitchen}
          </div> */}

        {!isPublic && !clientMode && (
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Комиссия:</span>
            {formatMoney(record.commission, " ₽")}
          </div>
        )}
        {/*
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Год:</span>
            {record.buildingYear || '–'}
          </div> */}

      </div>
    </div>
  )

  function onChange(pagination, filters, sorter, extra) {
    if (externalSorting) return externalSorting({ pagination, filters, sorter, extra })
  }

  const getRowLink = (record, isPublic) => {
    if(getLink) return getLink(record)
    return isPublic
      ? `/public/lands/${savedFilterXmlId}/${city}/${record.id}`
      : isNewSuburban && record.compilationObjectType === 'parcel' ? `/lands/${city}/${record.xmlId}${window.location.search}`
        : isNewSuburban && record.compilationObjectType === 'house' ? `/house/${record.xmlId}${window.location.search}`
        : `/lands/${city}/${record.id}${window.location.search}`;
  };

  const columns = useMemo(() => {
    const cols = isSmallMobile ? getSmallMobileColumns() : isMobile ? getMobileColumns() : getColumns(); // скопировал, вообще бы переделать таблицы
    return cols?.length && cols.map(column => externalSorting && column.sorter ? { ...column, sorter: true } : column)
  }, [isSmallMobile, isMobile])

  return (
    <div className={`flats__row-table is-active`} style={{ paddingBottom: (!isMobile && isOpen) && 15 }}>
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={columns}
        dataSource={items}
        pagination={false}
        locale={{ emptyText: "ничего не найдено" }}
        customPagination={false}
        onChange={onChange}
        expandable={isMobile ? {
          expandedRowRender: record => expandedRow(record),
        } : false}
        firstPageCount={30}
        pageCount={50}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              window.open(getRowLink(record, isPublic));
            },
          };
        }}
      />
    </div>
  );
});

const ApartmentList = memo(props => {
  const { items, isPublic, savedFilterXmlId, isMobile, toggleFilters, clientMode, innerRef, getLink, externalSorting } = props;
  const viewTypes = ["cards", "table"];
  const [view, setView] = useState("table");

  return (
    <div className="complex-results-wrapper" key={"complex-results" + items?.length} ref={innerRef}>
      {items && (
        <section className="section for-suburbans">
          <div className="wrapper">
            {!isMobile && (
              <div className="complex__appartments-navigation">
                <div className="complex__appartments-title">
                  Найдено {items.length} объект{getSuffix(items.length)}
                </div>
                <div className="complex__views">
                  <ul className="complex__view">
                    {viewTypes.map((item) => (
                      <li
                        className={`complex__view-item view__${item} ${item === view ? `active` : ``
                          }`}
                        key={item}
                        onClick={() => setView(item)}
                      >
                        <svg className="view__icon">
                          <use
                            xlinkHref={`#view_${item === "cards" ? `cards` : `rows`
                              }`}
                          ></use>
                        </svg>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="complex__appartments">
              <div className="complex__appartments-tab active">
                <div
                  className="flats__row-results"
                  style={{ display: "block" }}

                >
                  {view === "cards" ? <div className="complex__appartments-cards" >
                    {items.map((item) => (
                      <Card
                        {...item}
                        key={item.id}
                        isPublic={isPublic}
                        savedFilterXmlId={savedFilterXmlId}
                        clientMode={clientMode}
                      />
                    ))}
                  </div> :
                    <div className="assignations-flats__tables">
                      <TableApartments
                        items={items}
                        view={view}
                        viewTypes={viewTypes}
                        setView={setView}
                        isPublic={isPublic}
                        savedFilterXmlId={savedFilterXmlId}
                        isMobile={isMobile}
                        clientMode={clientMode}
                        getLink={getLink}
                        externalSorting={externalSorting}
                      />
                    </div>
                  }
                  {isMobile && <ComplexView
                    viewTypes={viewTypes}
                    view={view}
                    setView={setView}
                    onClick={toggleFilters}
                  />
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
});

export default ApartmentList;
