import 'antd/es/table/style/index.css';
import React, { memo, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import { getUrl } from '../../../../api';
import useClickPast from '../../../../hooks/useClickPast';
import useDropdown from '../../../../hooks/useDropdown';
import useFileManager from '../../../../hooks/useFileManager';
import FileButton from '../../../../pages/SellAssigment/FileButton';
import { media } from '../../../../utils';
import Dropdown from '../../../core/Dropdown';
import MobileModal from '../../../core/MobileModal';
import Modal from '../../../core/Modal';
import Popup from '../../../core/Popup';
import NewClientForm from '../../../forms/NewClient';
import ObjectEditRequest from '../../../forms/ObjectEditRequest';
import SubmitButton from '../../../SubmitButton';
import { infoToast, standartErrorToast } from '../../../toasts';



const isMobile = media("isMobile");

export const MyObjectName = memo(({ title, roomType, id, plan, subTitle, photos, domain }) => isMobile ?
  <div className="lk-presentations__name">
    <a
      className="color-brand-1 link-hover"
      href={`https://${domain}/object/${id}`}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </a>
  </div> :
  <div className="ot__col-with-img">
    <img src={getUrl((plan?.empty || !plan) && photos?.length ? photos[0]?.src : plan?.src)} alt="" />
    <div>
      <a
        className="bold color-brand-1 link-hover"
        href={`https://${domain}/object/${id}`}
        target="_blank"
        rel="noreferrer"
      >
        {title}
      </a>
      <p>{subTitle?? roomType}</p>
    </div>
  </div>)



export const MyObjectClients = memo(props => {
  const [node, open, show, hide] = useDropdown(false);
  const { way = 'left', clients, fetch, data, handleAttachClient } = props;

  const [activeTab, setActiveTab] = useState('attachClient');
  const [attachingClientId, setAttachingClientId] = useState(0);
  const [attaching, setAttaching] = useState(false);
  const [attachedClients, setAttachedClients] = useState([]);

  useEffect(() => {
    if (clients?.[0]) setAttachingClientId(clients[0].id);
  }, []);

  useEffect(() => {
    setAttachedClients(data.clients);
  }, [props]);

  const submitAttach = (clientId, action) => {
    setAttaching(true);
    handleAttachClient({ clientId, objectId: data.id }, action === "add").then(
      data => {
        infoToast(<div>{data.message}</div>)
        hide();
        hideAttachMobileModal()
      },
      standartErrorToast,
    ).finally(() => { setAttaching(false) })
  };

  const handleAddClient = id => {
    setAttachingClientId(id)
    fetch();
    setActiveTab('attachClient');
  };

  const [attachMobileModal, hideAttachMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideAttachMobileModal} title="Прикрепить к своему клиенту">
        <div
          className="button-mobile is-add-client"
          onClick={openMobileAddForm}
        >
          <button className="button button_type_add"></button>
          <span>Добавить клиента</span>
        </div>

        <Dropdown
          options={clients}
          value={attachingClientId}
          onChange={setAttachingClientId}
        />

        <div className="mobile-filters__show-all">
          <SubmitButton
            className="button button_view_default"
            isLoading={attaching}
            onClick={() => submitAttach(attachingClientId, 'add')}
            disabled={!attachingClientId}
          >
            <span>Прикрепить</span>
          </SubmitButton>
        </div>
      </MobileModal>
    ),
    [
      clients,
      attachingClientId,
      setAttachingClientId,
      attaching,
      attachingClientId,
      setActiveTab,
      submitAttach
    ],
  );

  const [openMobileAddForm, closeMobileAddForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        title="Добавить клиента"
        closeClick={closeMobileAddForm}
        fixedTitle={true}
      >
        <NewClientForm onAdd={handleAddClient} />
      </MobileModal>
    ), [handleAddClient]
  );

  return (
    <div className="lk-presentations__clients">
      {attachedClients.length > 0 && (
        <ul className="lk-presentations__clients-list">
          {attachedClients.map((client, index) => {
            const name = `${client.name} ${client.lastName ? client.lastName : ""}`

            return (
              <li className="lk-presentations__client" key={client.id}>
                <div className="label_with-icon_postfix">
                  <div className="lk-presentations__client-name">
                    {isMobile
                      ? (`${name.slice(name.indexOf(' '))} ${name?.[0]}.`)
                      : name
                    }
                  </div>
                  <button
                    className="button button_type_delete label__icon"
                    onClick={() => submitAttach(client.id, 'remove')}
                  ></button>
                </div>
              </li>
            )
          })}
        </ul>
      )}
      <div className="lk-presentations__clients-attach" ref={node}>
        {attachedClients.length === 0 && (
          <svg className="icon icon_plus">
            <use xlinkHref="#plus"></use>
          </svg>
        )}
        {attachedClients.length === 0 && <div className={`popover fav-attach ${open ? ' is-showing' : ''}`} >
          <div
            className="fav-attach__btns"
            onClick={() => {
              media('isMobile') ? attachMobileModal() : show()
            }}
          >
            <div className="fav-attach__btn">
              <svg className="icon icon_user">
                <use xlinkHref="#user"></use>
              </svg>
            </div>
            {attachedClients.length == 0 && (
              <div className="lk-presentations__clients-attach_new link-hover">
                <span>Прикрепить к клиенту</span>
              </div>
            )}
          </div>
          <div className={`popover__block popover__block_align_${way}`}>
            <div className="popover__inner">
              <div className="form-attach">
                <div className={`form-attach__tab ${activeTab == 'attachClient' ? ' active' : ''}`}            >
                  <div className="form-attach__body">
                    <div className="bold">
                      Хотите прикрепить к своему клиенту?
                    </div>
                    <div className="select select_type_form">
                      <label className="custom-select-label">Клиент</label>
                      <Dropdown
                        options={clients}
                        value={attachingClientId}
                        onChange={setAttachingClientId}
                      />
                    </div>
                    <div
                      className="add-client"
                      onClick={() => setActiveTab('newClient')}
                    >
                      <button className="button button_type_add"></button>
                      <span>Добавить клиента</span>
                    </div>
                    <div className="form-attach__submit">
                      <SubmitButton
                        className="button button_view_grey"
                        isLoading={attaching}
                        onClick={() => submitAttach(attachingClientId, 'add')}
                        disabled={!attachingClientId}
                      >
                        <span>Прикрепить</span>
                      </SubmitButton>
                    </div>
                  </div>
                </div>
                <div className={`form-attach__tab ${activeTab == 'newClient' ? ' active' : ''}`} >
                  <div className="form-attach__return">
                    <button
                      className="button button_view_outline-with-icon"
                      onClick={() => setActiveTab('attachClient')}
                    >
                      <svg className="icon icon_arrow-return">
                        <use xlinkHref="#arrow_return"></use>
                      </svg>
                      Вернуться
                    </button>
                  </div>
                  <div className="form-attach__new-client">
                    <NewClientForm onAdd={handleAddClient} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
});

export const MyObectsAction = memo(props => {
  const { id, assignmentId } = props
  const [open, setOpen] = useState(false);

  const node = useClickPast(() => setOpen(false))

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Modal close={hideModal} classes="">
      <ObjectEditRequest close={hideModal} id={assignmentId} />
    </Modal>
  ));
  const content = (!!assignmentId ? <div className="dt__more">
    <hr />
    <div>
      <div
        className="dt__more-control"
        onClick={confirmModal}
      >
        <svg className="icon icon_edit">
          <use xlinkHref="#edit"></use>
        </svg>
        <span>Редактировать</span>
      </div>
    </div>
  </div> :
    <div className="dt__more">
      <hr />
      <div>
        <Link
          className="dt__more-control"
          to={`/objects/edit/${id}`}
        >
          <svg className="icon icon_edit">
            <use xlinkHref="#edit"></use>
          </svg>
          <span>Редактировать</span>
        </Link>
      </div>
    </div>
  )

  const button = (
    <button
      type="link"
      className="dropdown__btn button form__drop-btn button_type_round"
      onClick={() => setOpen(prev => !prev)}
    >
      <span className="form__drop-name"></span>
      <svg className="icon icon_elipsis">
        <use xlinkHref="#elipsis"></use>
      </svg>
    </button>
  )

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={`dropdown form__drop ${open ? ' is-showing' : ''}`}
        >
          {isMobile ?
            <Popup
              trigger={button}
            >
              {content}
            </Popup> :

            <>
              {button}
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    {content}
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
});

export const Price = item => {
  let cost = '—';
  if (item.object?.costWithDiscount) cost = item.object.costWithDiscountFormated;
  if (item.object?.baseCost) cost = item.object.baseCostFormated;
  return <span className="nowrap">{cost}</span>;
}

export const getDefaultConfig = data => {
  return {
    saleType: data.saleTypes[0]?.id,
    objectType: data.objectTypes[0]?.id,
    rentType: data.rentTypes[0]?.id,
    type: data.types[0]?.id,
    plan: [],
    photos: [],
    status: data.statuses[0].id
  }
}
export const processFiles = (files = []) => {
  const result = []
  const now = Date.now()
  let i = 0
  for (const file of files) {
    i ++ 
    file.name = file.src.substr(file.src.lastIndexOf("/") + 1)
    file.fakeId = now + i
    const fileId = new URLSearchParams(file.src).get("id")
    file.id = fileId? +fileId: file.id
    result.push(file)
  }
  return result
}

export const RenderTabs = memo(({ items = [], value, onClick, title, addClass }) => <div className="aa__block">
  <h5 className="h55 aa__title">{title}</h5>
  <div className={`aa__switcher switcher${addClass ? ` ${addClass}` : ``}`}>
    {items.map(item => <button
      type="button"
      className={`button switcher__btn${value === item.id ? " active" : ""}`}
      onClick={() => onClick(item.id)}
      key={item.id}
    >
      {item.title}
    </button>)}
  </div>
</div>)

export const FileUploader = memo(({ onFilesChange, filesList = [], label, maxFiles = 20, loadableFiles, additionalWrapperClass }) => {
  const { uploadFiles, deleteFile, } = useFileManager({
    maxFiles,
    onFilesChange,
    onlyImages: true,
    defaultFIles: filesList
  });

  return <FileButton
    label={label}
    loadFiles={e => uploadFiles(e)}
    files={filesList.map(item => item.fileObj ? item.fileObj : item)}
    ident="plan"
    removeFile={(ident, i, id) => deleteFile(i, id)}
    additionalWrapperClass={additionalWrapperClass}
    loadableFiles={loadableFiles}
  />
})
