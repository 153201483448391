import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import request from '../api';
import { actionFactory } from './actionUtils';

const comp = "panpartner:saved.filter.ajax"

const savedFiltersSelector = (state) => state.savedFilters.data;
const filterSelector = (state) => state.savedFilters.filter;

export const filteredSavedFilters = createSelector(
	[savedFiltersSelector, filterSelector],
	(savedFilters, filter) => {
		let result = savedFilters;

		if (filter.isActive.length) {
			result = result.filter((item) => {
				return includes(filter.isActive, `${item.isActive}`);
			});
		}

		if (filter.clients.length) {
			result = result.filter((item) =>
				includes(filter.clients, `${item.client?.id}`),
			);
		}

		if (filter.agents.length) {
			result = result.filter((item) =>
				includes(filter.agents, `${item.agent?.id}`),
			);
		}

		if (filter.dates.startDate) {
			result = result.filter((item) => item.date >= filter.dates.startDate);
		}

		if (filter.dates.endDate) {
			result = result.filter((item) => item.date <= filter.dates.endDate);
		}

		return result;
	},
);

const requestSavedFilters = actionFactory("SAVEDFILTERS_REQUEST");

const successSavedFilters = actionFactory("SAVEDFILTERS_SUCCESS");

export const removeSavedFilters = (ids) => {
	return {
		type: 'SAVEDFILTERS_REMOVE',
		ids: ids,
	};
};

export const clearSavedFilters = actionFactory("SAVEDFILTERS_RESET");

const failureSavedFilters = actionFactory("SAVEDFILTERS_FAILURE");

export const setFilterSavedFilters = (filter) => ({
	type: 'SAVEDFILTERS_SET_FILTER',
	filter,
});

export const clearFilterSavedFilters = actionFactory("SAVEDFILTERS_CLEAR_FILTER");

export const fetchSavedFilters = (city = '') =>
	dispatch => {
		dispatch(requestSavedFilters());
		return request(comp, "getSavedFilters", { city })
			.then(
				data => {
					const { items, filterData, clients } = handleFilter(data);
					dispatch(successSavedFilters({ data: items, filterData, clients }));
					return Promise.resolve(data);
				},
				error => {
					dispatch(failureSavedFilters());
					return Promise.reject(error);
				},
			);
	};

const handleFilter = ({ filter, savedFilters, clients }) => {
	let allClients = [];
	let items = savedFilters.map((item) => {
		let iDate = new Date(item.dateCreate).setHours(0, 0, 0, 0);
		return { ...item, date: iDate };
	});

	let minMax = {
		min: new Date(filter.dateCreate.range.min),
		max: new Date(filter.dateCreate.range.max),
	};

	clients.forEach(({ id, name, lastName, email, phone }) => {
		let title = `${name}${lastName ? ` ${lastName}` : ``}`;

		allClients.push({
			id: id,
			title: title,
			email: email[0],
			phone: phone[0],
		});
	});

	return {
		filterData: {
			agents: filter.agentId,
			isActive: filter.isActive,
			clients: filter.clientId,
			dates: minMax,
		},
		items: items,
		clients: allClients,
	};
};

export const editFilter = params =>
	dispatch =>
		request(comp, "editFilter", params)
			.then(
				(data) => {
					dispatch(fetchSavedFilters());
					return Promise.resolve(data);
				},
				(error) => {
					dispatch(failureSavedFilters());
					return Promise.reject(error);
				},
			);

export const changeActive = (id, active, city) =>
	dispatch =>
		request(comp, "changeActive", {
			id,
			active,
			city,
		})
			.then(
				data => {
					dispatch(fetchSavedFilters());
					return Promise.resolve(data);
				},
				error => {
					dispatch(failureSavedFilters());
					return Promise.reject(error);
				},
			);

export const removeFilter = (id, city = '') =>
	dispatch =>
		request(comp, "delete", {
			id, city
		}).then(
			data => {
				dispatch(removeSavedFilters(id));
				return Promise.resolve(data);
			},
			error => {
				dispatch(failureSavedFilters());
				return Promise.reject(error);
			},
		);

export const sendFilter = (id, clientId, city) =>
	dispatch =>
		request(comp, "sendPost", {
			id,
			clientId,
			city,
		}).then(
			data => Promise.resolve(data),
			error => {
				dispatch(failureSavedFilters());
				return Promise.reject(error);
			},
		);