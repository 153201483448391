import React, { useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import useMedia from '../../hooks/useMedia';
import { getManager, scrollToRef } from "../../utils";
import Modal from "../core/Modal";
import Popup from "../core/Popup";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import FilterError from "../ErrorBoundry/FilterError";
import Callback from "../forms/Callback";
import { successToast } from "../toasts";
import ApartmentsList from "./ApartmentList";
import Filter from "./Filter";

const MobileManager = (props) => {
  const {
    callbackClick,
    isPublic,
    city,
    manager,
    clientMode,
    userInfo,
    isMobile
  } = props;

  const { fullName, phone, email, position, photo } = getManager(manager, userInfo, clientMode)


  const managerDetails = (
    <>
      {/*!isPublic && manager.id > 0 && (*/}
      {true && (
        <div class="filter__manager-call" onClick={() => callbackClick()}>
          <svg class="icon icon_phone">
            <use xlinkHref="#phone"></use>
          </svg>
          Обратный звонок
        </div>
      )}
      {position && <div class="complex__manager-post">{position}</div>}
      <div class="complex__manager-contacts">
        <ul class="complex__manager-contacts_list">
          {phone && (
            <li class="complex__manager-contacts_item">
              <a class="link-hover" href={`tel:${phone}`}>
                {phone}
              </a>
            </li>
          )}
          {email && (
            <li class="complex__manager-contacts_item">
              <a class="link-hover" href={`mailto:${email}`}>
                {email}
              </a>
            </li>
          )}
          {manager.telegram && !clientMode && (
            <li class="complex__manager-contacts_item">
              <a
                className="link-hover"
                href={`https://t.me/${manager.telegram}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{manager.telegram}
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  )

  return (
    <div class="filter__manager is-hidden-desktop">
      <div className="filter__manager-photo" style={{ backgroundImage: `url(${photo})` }}>
      </div>
      <div class="filter__manager-content">
        <div class="filter__manager-name">
          {fullName}

          {isMobile &&
            <Popup
              trigger={
                <div className="info">
                  <svg className="icon icon_info">
                    <use xlinkHref="#info"></use>
                  </svg>
                </div>
              }
            >
              {managerDetails}
            </Popup>
          }
        </div>
        <div class="filter__manager-label">{position}</div>
        {!isMobile && managerDetails}
      </div>
      {/* <div class="filter__manager-hint">
        <div class="info">
          <svg class="icon icon_info">
            <use xlinkHref="#info"></use>
          </svg>
          <div class="info__popup"></div>
        </div>
      </div> */}
    </div>
  );
};

const DesktopManager = (props) => {
  const {
    callbackClick,
    isPublic,
    manager,
    clientMode,
    userInfo
  } = props;


  const { fullName, photo, phone, email, position } = getManager(manager, userInfo, clientMode)
  return (
    <div class="complex__manager">
      <div class="complex__manager-avatar">
        <div class="complex__manager-avatar_wrapper">
          <img
            src={photo}
            alt={fullName}
          />
        </div>
      </div>
      <div class="complex__manager-content">
        <div class="complex__manager-title">
          {fullName}
        </div>
        {position && <div class="complex__manager-post">{position}</div>}
        <div class="complex__manager-contacts">
          <ul class="complex__manager-contacts_list">
            {phone && (
              <li class="complex__manager-contacts_item">
                <a class="link-hover" href={`tel:${phone}`}>
                  {phone}
                </a>
              </li>
            )}
            {email && (
              <li class="complex__manager-contacts_item">
                <a class="link-hover" href={`mailto:${email}`}>
                  {email}
                </a>
              </li>
            )}
            {manager.telegram && !clientMode && (
              <li class="complex__manager-contacts_item">
                <a
                  className="link-hover"
                  href={`https://t.me/${manager.telegram}`}
                  target="_blank"
                >
                  @{manager.telegram}
                </a>
              </li>
            )}
          </ul>
        </div>
        {!isPublic && (
          <div class="complex__manager-action">
            <button
              class="button button_view_outline"
              onClick={() => callbackClick()}
            >
              Обратный звонок{" "}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Apartments = (props) => {
  const {
    id,
    city,
    manager,
    innerRef,
    isPublic,
    savedFilterXmlId,
    agent,
    haveApartments,
    clientMode,
    userInfo,
    filter,
    setFilter,
    clearFilter,
    filterData,
    apartments,
    count,
    getApartments
  } = props;
  const apartmentsRef = useRef(null);
  const isMobile = useMedia("isMobile")
  const submitCallback = () => {
    hideCallbackModal();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const [callbackModal, hideCallbackModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideCallbackModal}>
        <Callback handleClickSubmit={() => submitCallback()} manager={manager} objectId={id} />
      </Modal>
    ),
  );

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    !isFiltersOpen
      ? addClasses()
      : removeClasses()
  }

  const handleCloseClick = () => {
    setIsFiltersOpen(false);
  }

  // useEffect(() => {
  //   if (isFiltersOpen) toggleFilters();
  // }, [isFiltersOpen]);

  return (
    <div>
      {apartments?.length > 0 && (
        <div>
          <section class="section complex__section-filter" ref={innerRef}>
            <div class="wrapper">
              {isMobile && apartments.length > 0 && (manager || agent) && (
                <MobileManager
                  manager={isPublic ? agent : manager}
                  callbackClick={callbackModal}
                  isPublic={isPublic}
                  city={city}
                  clientMode={clientMode}
                  userInfo={userInfo}
                  isMobile={isMobile}
                />
              )}
              <div class="filter__header">
                {haveApartments && isMobile &&
                  <h2 class="h2 filter__title">Наличие и цены</h2>
                }
                {haveApartments && (
                  <div class="block__title with-button for-mobile">
                    {!isMobile && <h2 class="h2">Наличие и цены</h2>}

                    {false && !isPublic &&  (
                      <Link to={`/realty/${city}/chess/${id}`} target="_blank">
                        <button class="button button_view_outline-with-icon">
                          <svg class="icon icon_checkerboard">
                            <use xlinkHref="#checkerboard"></use>
                          </svg>
                          <span>Шахматка</span>
                        </button>
                      </Link>
                    )}
                  </div>
                )}
                {!isMobile && apartments.length > 0 && (manager || agent) && (
                  <MobileManager
                    manager={isPublic ? agent : manager}
                    callbackClick={callbackModal}
                    isPublic={isPublic}
                    city={city}
                    clientMode={clientMode}
                    userInfo={userInfo}
                    isMobile={isMobile}
                  />
                )}
              </div>
              {apartments?.length > 0 && (
                <div class="complex__filter-wrapper">
                  <FilterError filterData={filterData} filter={filter}>
                    <Filter
                      filterData={filterData}
                      filter={filter}
                      setFilter={(data) => setFilter(data, savedFilterXmlId)}
                      clearFilter={clearFilter}
                      onSubmit={(params) => {
                        getApartments();
                        scrollToRef(apartmentsRef, 70);
                      }}
                      count={count}
                      isPublic={isPublic}
                      savedFilterXmlId={savedFilterXmlId}
                      isFiltersOpen={isFiltersOpen}
                      onClick={handleCloseClick}
                    />
                  </FilterError>
                  {(manager || agent) && (<DesktopManager
                    manager={isPublic ? agent : manager}
                    callbackClick={callbackModal}
                    isPublic={isPublic}
                    clientMode={clientMode}
                    userInfo={userInfo}
                  />)}
                </div>
              )}
            </div>
          </section>
          <ErrorPlug wrappedComponent="src/comp-ts/Complex/Apartents.js">
            <ApartmentsList
              items={apartments}
              innerRef={apartmentsRef}
              isPublic={isPublic}
              savedFilterXmlId={savedFilterXmlId}
              toggleFilters={toggleFilters}
              isMobile={isMobile}
              clientMode={clientMode}
            />
          </ErrorPlug>
        </div>
      )}
    </div>
  );
};

export default Apartments;
