import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import DetailHeader from "../Public/markup/detail/DetailHeader";
import AptModal from "../AptModal";
import {
  AddToCompilationIcon,
  AptStatusIcon,
  HeartIcon,
  LockIcon,
  PdfIcon,
  PresentationIcon,
  SuburbanAboutMainIcon, SuburbanBuilderStarIcon, SuburbanListCameraIcon,
  SuburbanListFireIcon,
  SuburbanListNetworkIcon, SuburbanListProtectIcon,
  SuburbanListSepticIcon, SuburbanListWaterIcon,
  SuburbanMapIcon,
  TopArrowIcon
} from "../Public/markup/icons";
import Slider from "../Public/markup/detail/Slider";
import Preloader from "../Preloader";
import InfoCard from "../Public/markup/detail/InfoCard";
import SubmitButton from "../SubmitButton";
import request, { getUrl } from "../../api";
import AddPresenatation from "../AddPresentation";
import Button from "../core/Button";
import { useMapModal } from "../../hooks/useMapModal";
import { useModal } from "react-modal-hook";
import PanModal from "../core/PanModal";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import StatelessTabs from "../core/Tabs/StatelessTabs";
import Tabs from "../core/Tabs";
import useMedia from "../../hooks/useMedia";
import { useMemo } from 'react';
import useDocTitle from "../../hooks/useDocTitle";
import { downloadLink, objectsArrayToFormData, toFormData } from '../../utils';
import {errorToast, successToast} from "../toasts";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import Files from "../Public/markup/detail/Files";
import useFilter from '../../hooks/useFilter';
import { extractSubItems, getSuburbanFilterConfig, getSuburbanFilterConfigNew } from '../Suburban/Utils';
import VillageFilterForm from '../Public/markup/VillageFilterForm';
import Tags from '../Public/markup/Tags';
import SuburbanProjectContent from '../Suburban/SuburbanProjectContent';
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import axios from "axios";


const apiConfig = {
  component: 'panpartner:new.suburbans.ajax',
  submitFunction: 'getSuburban',
  loadSubItems: 'getProject',
}

const SuburbanObjectNew = (props) => {

  const { id, city, savedFilterXmlId } = useParams()

  const filterParams = useFilter({
    apiConfig,
    defaultFilter: {
      status: [1],
      cottageSettlement: id
    },
    defaultParams: {
      city,
      id,
      type: 'parcel',
      ...(savedFilterXmlId ? { savedFilterXmlId } : [])
    },
    favoritesParams: {
      city,
      id,
      type: 'parcel',
    },
    favoritePath: 'suburban.favorite',
    shadowFilters: ['villageId', 'status', "cottageSettlement", "parcelId"],
    fields: {
      type: [],
      squareHouse: {},
      squareParcel: {},
      prices: {},
      countBedrooms: {},
      bathroomTypes: [],
      communications: [],
      materials: [],
      features: [],
      endings: {},
      floors: [],
      countFloors: {},
      isOnlyHanded: [],
      payment: [],
      status: [],
      constructionPeriod: [],
      section: [],
    },
    isGetFilter: true,
    refreshWhenFilterChange: true,
    refreshMethod: "getProject", //"getProjectItem", to do вернуить после того как починят
    paginationEnabled: true,
    defaultLimit: 15,
    legacyRestoreFromLS: true,
    loadMoreDataKey: "items",
    extractSubItems: (...args) => extractSubItems(...args, city),
    filterFromLS: {
      filter: JSON.parse(localStorage.getItem('suburbanObjectFilter'))?.[
        id
      ]
    },
    requiredFilters: ["villageId"],
    processData: (resp, prev) => {
      if (prev.filter) delete resp.filter;
      return { ...prev, ...resp };
    },
    configMutation: (resp, config) => {
      if (!resp?.suburban?.xmlId) return
      config.defaultParams.id = resp.suburban.xmlId
      config.defaultParams["filter[parcelId]"] = resp.suburban.xmlId
      config.defaultParams["filter[type]"] = "contractConstruction"
      delete config.defaultParams.type
      config.apiConfig.submitFunction =  config.apiConfig.loadSubItems;
      config.refreshMethod =  config.apiConfig.loadSubItems;
      if (!resp?.tabs?.[0]?.method) return
      config.apiConfig.submitFunction = resp.tabs?.[0]?.method;
      config.refreshMethod = resp.tabs?.[0]?.method;

    },
    defaultLoadCondition: true,
    specialBadgeTitles: {
      countBathrooms: {
        max: "Санузлов до:",
        min: "Санузлов от:"
      },
      countBedrooms: {
        max: "Спален до:",
        min: "Спален от:"
      },
    },
    sortEnabled: true,
  });

  const {
    data:
    {
      suburban: {
        xmlId,
        description,
        contractType,
        landStatus,
        cadastralNumber,
        photo,
        cottageSettlement,
        endingDate,
        dateUpdate,
        status,
        isCustomerFixation,
        communicationType,
        address,
        coordinates,
        title,
        presentation,
        favorite,
        squareParcel,
        //old:
        plan,
        photos,
        genPlan,
        compilationObjectType = "parcel",
        id: suburbanId,
      } = {},
      fullDescription,
      //доступно
      isActive,
      dateUpdateInfo,
      priceInfo,
      bookingLink,
      fixationLink,
      blockParamsInfo,
      contacts,
      schedule,
      objectInfo,
      filter: filterData,
      count,
      headers,
      items,

      // нужно запросить у Сергея, в старых было, в новых - нет:
      meterPriceInfo,
      tabs,
      filesInfo
    },
    error,
    isLoading,
    isSending,
    inFavorite,
    isFavoriteLoading,
    toggleFavorite,
    filter = {},
    filterBadges,
    removeBadge,
    resetFilter,
    handleFilter,
    setFilterAndExclude
  } = filterParams;

  const [filePath, setfilePath] = useState(false)

  useDocTitle(title)
  const [openMapModal, closeMapModal] = useMapModal({
    cords: coordinates,
    mapCenter: coordinates,
  })


  const [fetchingPdf, setFetchingPdf] = useState(false);
  const getPdf = () => {
    setFetchingPdf(true)
    let formData = toFormData( {});
    formData = objectsArrayToFormData([{objectId: suburbanId, city, type: 'parcel'}], "objects", formData)
    return request('panpartner:new.suburbans.ajax', 'addPresentation', formData)
      .then((data) => {

        return request('panpartner:new.suburbans.ajax', 'getPresentationPdf', {type: 'parcel', id: data.xmlId})
          .then((resp) => {
            setFetchingPdf(false)
            window.open(getUrl(resp.path))
          })

      })
  }



  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          params={{
            type: "suburban",
            objectId: id,
            city
          }}
        />
      </PanModal>
    ),
    [contacts],
  );


  const isLaptop = useMedia("isLaptop");

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: suburbanId, type: compilationObjectType, city }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  const [complexView, setComplexView] = useState('photos')
  const [isLessDescription, setIsLessDescription] = useState(true)

  useEffect(() => {
    if (photo?.length === 0 && genPlan?.length !== 0) {
      setComplexView('planPhotos')
    }
  }, [isLoading])
  if (isLoading) return <Preloader loading />;

  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }
  const renderPdf = ({ fetching, finish, resp, handleSend }) => {
    return null
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={filePath ? () => window.open(filePath) : () => handleSend().then(resp => {
        downloadLink(getUrl(resp?.filePath))
        setfilePath(getUrl(resp?.filePath))
      })}
    >
      <PdfIcon />
      Скачать PDF
    </SubmitButton>
  }


  const addSuburbanPresentation = (id) => {
    request("panpartner:new.suburbans.ajax", "addPresentation",
      objectsArrayToFormData([{type: "parcel", city: "spb", objectId: id} ], "objects"))
      .then((resp) => {
        if (resp?.xmlId) {
          // window.open(`/suburban/presentation/${resp.xmlId}`, "_blank");
          navigator.clipboard.writeText(getUrl(`/suburban/presentation/${resp.xmlId}`))
          successToast("Презентация успешно создана. Cсылка скопирована в буфер обмена")
        } else {
          console.error("Ошибка: xmlId не найден в ответе");
          errorToast(error[0]?.message)
        }
      })
      .catch((error) => {
        console.error("Ошибка запроса:", error);
      });
  };




  return (

    <div className="sf wrapper">
      <div className="apt-modal__title">
        <h3 className="h3">{title}</h3>
        <div className={`label-item apt-modal__status label_${status?.color}`}>
          <AptStatusIcon />
          {status?.title}
        </div>
      </div>

      <div className="sf__detail-bar" style={{ marginTop: 7, marginBottom: 0 }}>
        {!!blockParamsInfo && <a href={blockParamsInfo[0]?.link} className="link link_color-red">{blockParamsInfo[0]?.value}</a>}
        {!!address && <p className="text sf__detail-descr sf__detail-descr_center" style={{ color: '#2C2E3E' }}>{address}</p>}
        {!!coordinates && (
          <span className="link link_color-red" onClick={openMapModal}>
            На карте
          </span>
        )}

      </div>
      <div className="apt-modal__header-second">
        <div className="sf sf--apt-modal">
          <div className="sf__elems">
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => filterParams?.favoritesProps?.toggleFavorite({id: suburbanId})}
              style={{ width: 192 }}
            >
              <HeartIcon isActive={filterParams?.favoritesProps?.inFavorite} />
              {filterParams?.favoritesProps?.inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
            </button>

            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={openCompilationModal}
            >
              <AddToCompilationIcon />
              В подборку
            </button>


            <SubmitButton
              type="button"
              className="sf__elem-btn button link"
              // isLoading={fetching}
              onClick={() => addSuburbanPresentation(suburbanId)}
            >
              <PresentationIcon />
              Ссылка на презентацию
            </SubmitButton>

            <SubmitButton
              className="sf__elem-btn button link"
              isLoading={fetchingPdf}
              onClick={() => {getPdf()}}
            >
              <PdfIcon />
              Скачать PDF
            </SubmitButton>

            {!!fixationLink && <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => window.open(fixationLink)}
            >
              <LockIcon />
              {!isLaptop && 'Зафиксировать клиента'}
            </button>}
          </div>
        </div>
      </div>

      <div className="sf">
        <div className="sf__detail-row">
          <div className="sf__detail-col complex-custom-view">


            {genPlan?.length > 0 && complexView == 'planPhotos' && (
              <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                <Slider
                  items={genPlan}
                  defoultThumbs
                />
              </div>
            )}
            {photo?.length > 0 && complexView == 'photos' && (
              <div className="complex-custom-view__main-container">
              <Slider
                items={photo}
                defoultThumbs
              />
              </div>
            )}

            <ul className="complex-view-list">
              <Swiper
                spaceBetween={15}
                slidesPerView={5}
                modules={[Navigation]}
              >

                {genPlan?.length > 0 && <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'planPhotos' ? 'active' : ''}`} onClick={() => {setComplexView('planPhotos')}}>
                    <img src={getUrl(genPlan?.[0]?.src)} alt=""/>
                    <span>Ген-план</span>
                  </li> </SwiperSlide>}
                {photo?.length > 0 &&
                  <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => {setComplexView('photos')}}>
                    <img src={getUrl(photo?.[0]?.src)} alt=""/>
                    <span>Фотографии</span>
                  </li>
                </SwiperSlide>}


              </Swiper>
            </ul>

            {!!tabs?.length &&
              <section style={{ marginTop: 35 }} className={`sf__section ${tabs.length === 1 ? 'sf__section_single-tab' : ''}`}>
                <Tabs title={'Описание'}>
                  {tabs.map(({ title, value }) => {
                    return (
                      <div label={title}>
                        <p
                          className="sf__about-text medium-text-l"
                          dangerouslySetInnerHTML={{
                            __html: value,
                          }}
                        />
                      </div>
                    )
                  }
                  )}
                </Tabs>
              </section>
            }

          </div>
          <div className="sf__detail-col">
            <InfoCard items={objectInfo} topDate={dateUpdateInfo.title + ' ' + dateUpdateInfo.value} cols={priceInfo} />
            <section className="sf__section">
              {!!bookingLink && <Button buttonType="primary" isFull onClick={() => window.open(bookingLink)}>
                Забронировать
              </Button>}
              <Button buttonType="line" isFull onClick={openContactsModal}>
                Контакты
              </Button>
            </section>
          </div>
        </div>

        <div className="sf__detail-row">
          <div className="sf__detail-col">
            <div className="about-redesign">
              <div className="about-redesign__top">
                <div className="about-redesign__top-icon">
                  <SuburbanMapIcon />
                </div>

                <div className="about-redesign__top-content">
                  <p className="about-redesign__top-title">
                    Об участке
                  </p>

                    <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescription ? description : description?.split('<br>')[0]}}></p>
                    {description?.includes?.('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescription(prev=>!prev)}>{!isLessDescription ?  'Свернуть' : 'Подробнее'}</span>}

                  <ul className="quick-card__info-list" style={{ margin: 0 }}>
                    {!!squareParcel && <li>
                      <p>{squareParcel}</p>
                      <p>площадь участка</p>
                    </li>}
                    {!!landStatus && <li>
                      <p>{landStatus?.title}</p>
                      <p>ВРИ</p>
                    </li>}
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>

        {!!communicationType?.length && <div className="sf__detail-row">
          <div className="sf__detail-col">
            <div className="communications-redesign">
              <p className="communications-redesign__title">Коммуникации и удобства</p>

              <div className="communications-redesign__list">
                <ul className="about-redesign__list">
                  {communicationType.map(({ icon, title, xmlId }) => <li className="about-redesign__list-item" key={xmlId}>
                    <div className="about-redesign__list-icon">
                      <img src={getUrl(icon)} height={40} />
                    </div>
                    <div className="about-redesign__list-text">{title}</div>
                  </li>
                  )}
                </ul>
              </div>

            </div>
          </div>
        </div>}

        <SuburbanProjectContent city={city} {...filterParams} externalSorting={({sorter})=>filterParams.handleSort(null, {value: sorter?.field, by: sorter?.order?.replace?.("end", "")})} />

        {filesInfo?.length > 0 && (
          <div className="sf__detail-row">
            <div className="sf__detail-col">
              <div className="about-redesign">
                <Files customTitle=" " items={filesInfo} />
              </div>
            </div>
          </div>
        )}


      </div>

    </div>
  )
}

export default SuburbanObjectNew;
