import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import PriceFilter from '../../FilterRealty/PriceFilter';


const VillagesTopFilter = memo(props => {
  const {
    filter,
    exclude,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>
    <SelectFilter
      {...createSelectProps("type")}
      data={{
        title: "Тип объекта",
        autocompleteTitle: "",
        identity: "type",
      }}
      wrapperClass={wrapperClass}
    />
    <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("prices")}
      clearExcludeOnly={() => clearByIdent("prices", "exclude")}
    />
    <SelectFilter
      {...createSelectProps("purpose")}
      data={{
        title: "Статус земли",
        autocompleteTitle: "",
        identity: "purpose",
      }}
      wrapperClass={wrapperClass}
    />
  </>
});

export default VillagesTopFilter;