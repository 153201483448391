import React from 'react';
import ReviewsPage from '../components/Constructor/InnerPage/ReviewsPage/ReviewsPage';
import '../components/Constructor/index.scss';



const ConstructorReviews = ({setFullScreenLoading}) => {
  return  <ReviewsPage setFullScreenLoading={setFullScreenLoading} />
};

export default ConstructorReviews;
