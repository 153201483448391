import ru from 'date-fns/locale/ru';
import React, { memo, useEffect } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputMask from "react-input-mask";
import {
    CabinetFixationsCancelAction, CabinetFixationsDeleteAction, CabinetFixationsEditAction, CabinetFixationsSaveAction,
    FixationImportantIcon
} from "../../components/Public/markup/icons";
import Button from "../../components/core/Button";
import Dropdown from "../../components/core/Dropdown";
import NewAlert from "../../components/core/NewAlert";
import useObjectState from "../../hooks/useObjectState";
import './index.scss';
import useFileManager from '../../hooks/useFileManager';
import { add } from 'lodash';

registerLocale('ru', ru);
setDefaultLocale('ru');

export const emptyClient = {
    email: "",
    lastName: "",
    name: "",
    patronymic: "",
    phone: "",
    id: 0
}
export const emptyUniFormClient = {
    clientEmail: "",
    clientLastName: "",
    clientName: "",
    clientPatronymic: "",
    clientPhone: "",
    clientId: 0,
    clientBirthday: ""
}
export const clientToUniForm = cl => ({
    clientEmail: cl.email ? cl.email : "",
    clientLastName: cl.lastName,
    clientName: cl.name,
    clientPatronymic: cl.patronymic,
    clientPhone: cl.phone,
    clientId: cl.id,
    clientBirthday: ""
})

export const uniFormToClient = uniFormClient => ({
    email: uniFormClient.clientEmail || "",
    lastName: uniFormClient.clientLastName || "",
    name: uniFormClient.clientName || "",
    patronymic: uniFormClient.clientPatronymic || "",
    phone: uniFormClient.clientPhone || "",
    id: uniFormClient.clientId || 0
});

export const filterAndMap = (arr, fn) => {
    const tmp = [];
    for (const item of arr) {
        const value = fn(item);
        value && tmp.push(value);
    }
    return tmp;
}


export const AddNewClientButton = ({ handleAddClient }) => <div className="fixations__redesign-part">
    <div className="modal-form__add" onClick={handleAddClient}>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M34 17C34 7.61147 26.3885 0 17 0C7.61147 0 0 7.61147 0 17C0 26.3885 7.61147 34 17 34C26.3885 34 34 26.3885 34 17Z"
                fill="#F6F7F8" />
            <path d="M13 17H21" stroke="#2C2E3E" stroke-linecap="round" />
            <path d="M17 13L17 21" stroke="#2C2E3E" stroke-linecap="round" />
        </svg>

        <div className="modal-form__add-text">
            <h6 className="h6">Добавить клиента</h6>
            <span>Максимум 3 человека</span>
        </div>
    </div>
    <NewAlert
        text="Укажите супругов и других родственников. Для фиксации более 3-х человек, нужно заполнить несколько форм."
        bgColor="#FFE6E5"
        customIcon={<FixationImportantIcon color="#FF2D23" />}
    />
</div>


export const Client = memo(props => {
    const {
        index,
        addedClient,
        onDelete,
        normalizedClients,
        errors,
        fieldsDescription,
        openBuilderModal,
        handlePaste,
        openPassportModal,
        setValues,
        saveClient,
        forcedSetValue
    } = props;

    const { state: clientState, setValue: setClientValue, setValues: setClientValues, reset } = useObjectState({
        ...addedClient,
        isEdit: addedClient.clientId !== 0,
    });
    useEffect(() => {
        if (!addedClient.clientId) return;
        setClientValues(addedClient)
    }, [addedClient])


    const { isEdit, ...client } = clientState;

    const onFilesChange = files => forcedSetValue(files, index === 0 ? "files" : `file_${index}`)

    const { files, uploadFiles, deleteFile, editFileName, } = useFileManager({
        maxFiles: 10,
        onFilesChange
    });


    const toggleEdit = () => setClientValue("isEdit", prev => !prev);

    const selectClient = v => {
        saveClient(clientToUniForm(v), index)
    };

    const handleDelete = () => onDelete(index, clientState.clientId);

    return <div
        className={`fixations__redesign-part fixations__redesign-part_client-block`}>
        <div className="fixations__redesign-partheader">
            <p className="h6">Клиент {index + 1}</p>
            <ul className="fixations__redesign-clientactions">
                {!isEdit && <>
                    {client.clientId !== 0 && !!client.clientId && <li className="fixations__redesign-actionedit" onClick={toggleEdit}>
                        <CabinetFixationsEditAction />
                        Редактировать
                    </li>}
                    <li onClick={handleDelete} className="fixations__redesign-actiondelete">
                        <CabinetFixationsDeleteAction />
                        Удалить
                    </li>
                </>}

                {isEdit && <>
                    <li className="fixations__redesign-actioncancel" onClick={() => {
                        toggleEdit()
                        setValues(addedClient)

                    }}>
                        <CabinetFixationsCancelAction />
                        Отменить
                    </li>
                    <li className="fixations__redesign-actionsave" onClick={() => {
                        saveClient(client, index)
                        toggleEdit()
                    }}>
                        <CabinetFixationsSaveAction />
                        Сохранить
                    </li>
                </>}

            </ul>
        </div>

        {!(client.clientId === 0 && isEdit) && <div className="hp__form-item">
            <label className="input input_width_available input_type_form">
                <Dropdown
                    wrapperClass="custom-select_sf-filter custom-select_fixations"
                    withSearch={normalizedClients?.length > 4}
                    options={normalizedClients}
                    placeholder="Выберите клиента"
                    value={client?.clientId}
                    searchPlaceholder="Введите ФИО клиента"
                    customHandler={selectClient}
                    key={client?.clientId}
                />
            </label>
        </div>}

        {isEdit && < /* FORM CLIENT */ >
            <div className="hp__form-item">
                <label
                    className="input input_width_available input_type_form"
                >
                    <input
                        className={`input__control${errors.clientLastName ? " input__error" : ""}`}
                        value={clientState.clientLastName}
                        onChange={e => setClientValue("clientLastName", e.target.value)}
                        placeholder="Фамилия"
                        disabled={!isEdit}
                    />
                </label>
                {errors.clientLastName && (
                    <span className="input__error-label">
                        {errors.clientLastName}
                    </span>
                )}
            </div>
            <div className="hp__form-item hp__form-item_half">
                <div className="hp__form-container">
                    <label
                        className="input input_width_available input_type_form"
                    >
                        <input
                            className={`input__control${errors.clientPhone ? " input__error" : ""}`}
                            value={clientState.clientName}
                            onChange={e => setClientValue("clientName", e.target.value)}
                            placeholder="Имя"
                            disabled={!isEdit}
                        />
                    </label>
                    {errors.clientName && (
                        <span className="input__error-label">
                            {errors.clientName}
                        </span>
                    )}
                </div>
                <div className="hp__form-container">
                    <label className="hp__form-label hp__form-label_hint">
                        {fieldsDescription?.patronymic &&
                            <svg onClick={() => openBuilderModal(fieldsDescription.patronymic)} width="25" height="24"
                                viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.5" cy="12" r="8.5" stroke="#8790E5" />
                                <line x1="12.5435" y1="7.5" x2="12.5435" y2="13.5" stroke="#8790E5" stroke-linecap="round" />
                                <circle cx="12.5435" cy="15.5" r="0.5" fill="#8790E5" />
                            </svg>
                        }
                    </label>
                    <label
                        className={`input input_width_available input_type_form`}
                    >
                        <input
                            className={'input__control'}
                            value={clientState.clientPatronymic}
                            onChange={e => setClientValue("clientPatronymic", e.target.value)}
                            placeholder="Отчество"
                            disabled={!isEdit}
                        />
                    </label>
                </div>

            </div>


            <div className="hp__form-item hp__form-item_half">
                <div className="hp__form-container">
                    <label className="hp__form-label hp__form-label_hint">
                        {fieldsDescription?.birthday &&
                            <svg onClick={() => openBuilderModal(fieldsDescription.birthday)} width="25" height="24"
                                viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.5" cy="12" r="8.5" stroke="#8790E5" />
                                <line x1="12.5435" y1="7.5" x2="12.5435" y2="13.5" stroke="#8790E5" stroke-linecap="round" />
                                <circle cx="12.5435" cy="15.5" r="0.5" fill="#8790E5" />
                            </svg>
                        }
                    </label>
                    <label className="input input_width_available input_type_form fixation-dp-fix">
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date()}
                            showTimeSelect={false}
                            todayButton="Сегодня"
                            dropdownMode="select"
                            autoComplete="off"
                            className="input__control"
                            onChange={v => setClientValue("clientBirthday", v)}
                            shouldCloseOnSelect
                            showYearDropdown
                            showMonthDropdown
                            selected={clientState.clientBirthday}
                            placeholderText={'Дата рождения'}
                            disabled={!isEdit}
                        />
                    </label>
                </div>
                <div className="hp__form-container">
                    <label className="input input_width_available input_type_form">
                        <InputMask
                            className={`input__control input input_type_form${errors.clientPhone ? " input__error" : ""}`}
                            name="phone"
                            mask="+7 (999) 999-99-99"
                            autoComplete="off"
                            maskChar="_"

                            value={clientState.clientPhone}
                            onChange={e => setClientValue("clientPhone", e.target.value)}
                            onPaste={handlePaste}
                            placeholder="Телефон"
                            disabled={!isEdit}
                        />
                    </label>
                    {errors.clientPhone && (
                        <span className="input__error-label">
                            {errors.clientPhone}
                        </span>
                    )}
                </div>
            </div>

            <div className="hp__form-item">

                <label className="hp__form-label hp__form-label_hint">
                    {fieldsDescription?.email &&
                        <svg onClick={() => openBuilderModal(fieldsDescription.email)} width="25" height="24"
                            viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.5" cy="12" r="8.5" stroke="#8790E5" />
                            <line x1="12.5435" y1="7.5" x2="12.5435" y2="13.5" stroke="#8790E5" stroke-linecap="round" />
                            <circle cx="12.5435" cy="15.5" r="0.5" fill="#8790E5" />
                        </svg>
                    }
                </label>

                <label className="input input_width_available input_type_form">
                    <input
                        className={'input__control'}

                        value={clientState.clientEmail}
                        onChange={e => setClientValue("clientEmail", e.target.value)}
                        placeholder="E-mail"
                        disabled={!isEdit}
                    />
                </label>
            </div>

            <div className="hp__form-item">

                <label className="hp__form-label hp__form-label_hint">
                    <svg onClick={() => openPassportModal()} width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12" r="8.5" stroke="white" />
                        <line x1="12.5435" y1="7.5" x2="12.5435" y2="13.5" stroke="white" stroke-linecap="round" />
                        <circle cx="12.5435" cy="15.5" r="0.5" fill="white" />
                    </svg>
                </label>


                <div className="modal-form__row">
                    <div className="modal-form__item">
                        <label
                            className={`input input_type_upload-btn input_width_available ${!isEdit ? 'input_type_upload-btn-disabled' : ''}`}
                            htmlFor={`modal-reservation-file-input-${index}`}
                        >
                            <input
                                className="input__control"
                                type="file"
                                multiple
                                id={`modal-reservation-file-input-${index}`}
                                name="file"
                                onChange={uploadFiles}
                                disabled={!isEdit}
                            />
                            Прикрепить паспорт
                        </label>
                        <div className="modal-form__files">
                            {files.map((file, i) => (
                                <div className="file-up" key={file.fakeId}>
                                    <div className="file-up__inner">
                                        <svg className="icon file-up__icon">
                                            <use xlinkHref="#deals-01"></use>
                                        </svg>
                                        <span className="file-up__name">{file?.fileName}</span>
                                    </div>
                                    <button className="button button_view_round-control file-up__del" onClick={() => deleteFile(i)}>
                                        <svg className="icon">
                                            <use xlinkHref="#x"></use>
                                        </svg>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>}

        {!isEdit && <div className="hp__form-item">
            <Button
                type="button"
                buttonType="line"
                onClick={() => {
                    setClientValues(emptyUniFormClient)
                    toggleEdit()
                }}
                isFull
            >
                Создать нового клиента
            </Button>
        </div>}
    </div>
});