import {useEffect, useState} from 'react';
import { getUrl } from '../../../../api';
import houseEmpty from '../../../../assets/images/bcg_houses-01-01.svg';
import SwiperSlider from '../../../core/SwiperSlider';
import { PhotoPopup } from '../../../Viewer/PhotoSlider';
import {ComplexMapIcon, GenPlanIcon, WindowViewIcon} from '../icons';
import {ComplexMap} from "../../../Complex/ComplexDubai/ComplexMap";

const Slider = ({ items, openGeneralPlan, showButton, withBorder, windowView, viewFromWindow, complexMap, defoultThumbs }) => {
  const [openGallery, setOpenGallery] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [showWindowView, setShowWindowView] = useState(false)
  const [showComplexMap, setShowComplexMap] = useState(false)


  return (
    <>
      <section className={`sf-slider ${withBorder ? "sf-slider--with-border" : ""}`}>
        <div className="swiper-pan">

          <SwiperSlider
            complexMap={complexMap}
            showComplexMap={showComplexMap}
            swiperIndex={swiperIndex}
            setSwiperIndex={setSwiperIndex}
            setOpenGallery={setOpenGallery}
            showWindowView={showWindowView}
            viewFromWindow={viewFromWindow}
            windowView
            withGallery
            defoultThumbs={defoultThumbs}
            thumbs={items}
            items={items}
            wrap={(item, isThumb, index, slideTo) => (
              <div className="sf-slide sf-slide_main" onClick={() => {
                setShowWindowView(false)
                setShowComplexMap(false)
                setTimeout(() => slideTo(index), 100)
              }}>
                <img
                  src={getUrl(item.src)}
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = houseEmpty;
                  }}
                  alt={item.title}
                />
              </div>
            )}
            navigation={items?.length > 1}
            addBtn={showButton ? () => (
              <button
                type="button"
                className="button sf-slider-btn"
                onClick={windowView ? () => setShowWindowView(true) : complexMap ? () => {setShowComplexMap(true)} : openGeneralPlan}
              >
                {
                  windowView ?
                    <>
                      <WindowViewIcon />
                      <span className="h6">Вид из окон</span>
                    </> : complexMap ?
                    <>
                      <ComplexMapIcon />
                      <span className="h6">Комплекс на карте</span>
                    </> :
                    <>
                      <GenPlanIcon />
                      <span className="h6">Ген.план</span>
                    </>
                }
              </button>
            ) : null}
          />
        </div>
      </section>
      <PhotoPopup
        items={items}
        index={swiperIndex}
        open={openGallery}
        onClose={() => setOpenGallery(false)}

      />
    </>
  );
};

export default Slider;
