import React, { memo, useMemo, useState } from "react";
import {NavLink, useHistory} from "react-router-dom";
import Table from "../../components/core/Table";
import NewTabsNavigation from "../../components/core/TabsNew/TabsNavigation";
import {CalendarIcon, SmallCloseIcon} from "../../components/Public/markup/icons";
import useApi from "../../hooks/useApi";
import useMedia from "../../hooks/useMedia";
import { compareWords } from "../../utils";
import columns, {LocaleNotFound} from './videosColumns';
import './index.scss';
import "../../components/core/TabsNew/index.scss";
import Preloader from '../../components/Preloader';
import DownloadApp from "../../components/HomePage/DownloadApp";
import useDocTitle from "../../hooks/useDocTitle";
import { getURLparam } from "../../utils/location";

const Videos = memo(props => {

  const isSmallLaptop = useMedia('isSmallLaptop')
  const isMobile = useMedia("isMobile");

  const { data: { videos, tabs = [], title }, isLoading } = useApi({
    payload: ["panpartner:video.ajax", "getVideos"],
    successCallback: ({ tabs }) => {
      const tabName = getURLparam("tab")
      if (!tabName) return;
      const index = tabs?.findIndex?.(({ type }) => type === tabName)
      setSelectedTab(index !== -1 ? index : 0)
    }
  })
  useDocTitle(title)
  const history = useHistory()
  const openVideo = e => {
    const id = e.currentTarget.getAttribute("data-row-key")
    history.push("/videos/" + id)
  }
  const [search, setSearch] = useState("")
  const [selectedTab, setSelectedTab] = useState(0)

  const filteredData = useMemo(() => {
    if (!videos) return []
    if (selectedTab === 0 && !search.replace(/ /g, "")) return videos
    if (!search.replace(/ /g, "")) return videos.filter(video => video.type?.xmlId === tabs[selectedTab]?.type)
    if (!!search.replace(/ /g, "")) return videos.filter(video => {
      const typeCondition = selectedTab === 0 ? true : video.type?.xmlId === tabs[selectedTab]?.type
      const titleCondition = compareWords(video.title, search)
      const blockCondition = video.blocks.some(block => compareWords(block.title, search) || block.aliases.some(alias => compareWords(alias, search)))
      const builderCondition = video.builder?.some?.(builder => compareWords(builder.title, search) || builder.aliases.some(alias => compareWords(alias, search)))
      return typeCondition && (titleCondition || blockCondition || builderCondition)
    })
  }, [search, videos, selectedTab, tabs])
console.log(filteredData)
  const [downloadApp, setDownloadApp] = useState(isMobile);
  if (isLoading) return <Preloader loading />

  return (
    downloadApp ? <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} /> :
    <div className="wrapper">
      <div className="common-header">
        <div className="common-header__title"><h3 className="common-title">Видео</h3></div>
      </div>
      <div className="video-search">
        <div className="section-search">
          <input className="section-search__inp" value={search} placeholder="Поиск по названию, ЖК, застройщику" onChange={e=>setSearch(e.target.value)}/>
          {
            search !== '' &&
            <div className="section-search__icon" onClick={()=>setSearch("")}>
              <SmallCloseIcon/>
            </div>
          }
        </div>
      </div>
      <div className="videos">
        <NavLink className="common-link" to="/events">
          <CalendarIcon/>
          Календарь мероприятий
        </NavLink>
        <NewTabsNavigation tabs={tabs} onNavClick={setSelectedTab} activeTabId={selectedTab} />
        <Table
              rowKey="id"
              columns={columns(isSmallLaptop, tabs)}
              dataSource={filteredData}
              pagination={false}
              locale={LocaleNotFound}
              onRow={() => ({
                onClick: openVideo
              })}
            />
      </div>
    </div>
  )
})

export default Videos
