import classNames from 'classnames';
import indexOf from 'lodash/indexOf';
import union from 'lodash/union';
import without from 'lodash/without';
import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { memo } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../hooks/useDropdown';
import useElementPosition from "../../hooks/useElementPosition";
import { compareWords, searchRuSymbols } from '../../utils';
import SearchInput from './SearchInput';

const selectedCountStyle = { //убрать в css
  backgroundColor: "#d0d3da",
  color: "#fff",
  width: 25,
  height: 25,
  display: "flex",
  justifyContent: "center",
  borderRadius: "50%",
  alignItems: "center",
  lineHeight: 0,
}

const ChecboxItem = memo(props => {
  const {
    identity,
    id,
    isExcludable,
    exclude = false,
    checked = false,
    onChange,
    disabled,
    isMobile,
    titleKey = "title"
  } = props;

  const handleSelect = e => onChange(e.target.checked, id);
  const wrapperStyle = { filter: disabled ? 'opacity(0.5)' : 'none' };
  const excludeButton = isExcludable && <button
    type="button"
    className="button button_view_link filter__exclude-btn"
    onClick={() => onChange(true, id, !exclude)}
  >
    {exclude ? 'Включить' : 'Исключить'}
  </button>;

  return (
    <div className="checkbox-group__item" style={wrapperStyle}>
      <div className={exclude ? 'checkbox is-excluded' : 'checkbox'}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id={`${identity}-${id}`}
            checked={checked}
            onChange={handleSelect}
            disabled={disabled ?? false}
          />
          <label htmlFor={`${identity}-${id}`} className="checkbox__input" />
          <div className="checkbox__marker"></div>
        </div>
        {isMobile && checked && excludeButton}
        <label className="checkbox__label" htmlFor={`${identity}-${id}`}>
          {props[titleKey]}
        </label>
      </div>
      {!isMobile && excludeButton}
    </div>
  );
});

const SelectFilter = (props) => {
  const {
    isAutocomplete = false,
    data,
    items = [],
    isExcludable,
    filterValues = [],
    excludeValues = [],
    onChange,
    right = false,
    disabledItems = {}, // объект ключ - id , значение - массив , при выбранном id все остальные значения в массиве будут disabled
    disabledIds = [],
    isMobile = false,
    clearFilterOnly,
    clearExcludeOnly,
    autoPosition,
    wrapperClass,
    withoutHandling,
    selectedCount,
    titleKey = "title",
    openTop = false,
    getCount, // убрать срань ниже и пересмотреть ту что выше после замены фильтров #реф #del
    fullFilter,
    defFullFilter,
    defaultFilter
  } = props;

  const searchRef = useRef(null)
  const formItemRef = useRef(null)

  const [search, setSearch] = useState('');

  const [node, open, show, hide] = useDropdown(false);

  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus();
      searchRef.current.select();
    }
  }, [open])

  const filterable = useMemo(() => {
    if (!search) return items;
    return items.filter(item => compareWords(item[titleKey], search)
      || item.aliases?.some?.(alias => compareWords(alias, search)));
  }, [items, search, titleKey])

  const position = useElementPosition(formItemRef, 150)

  const selected = union(filterValues, excludeValues);

  const dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right")),
    dropdown__block_align_top: openTop,
  });

  const handleChange = (checked, val, exclude = false) => {
    // debugger
    if (withoutHandling) return onChange(checked, val, exclude);
    const compare = (id, isExclude) => !checked || (!exclude && isExclude) ? val != id : true;
    const filterTmpValues = filterValues.filter(id => compare(id));
    const excludeTmpValues = excludeValues.filter(id => compare(id, true));
    if (checked && exclude && !excludeTmpValues.some(id => id == val)) excludeTmpValues.push(val);
    if (checked && !exclude && !filterTmpValues.some(id => id == val)) filterTmpValues.push(val);
    onChange(filterTmpValues.filter(id=>!excludeTmpValues.includes(id)), excludeTmpValues);
  };

  const clearFilters = () => {
    if (typeof getCount === "function") { // #del см коммент выше
      getCount({
        ...fullFilter,
        filter: defFullFilter?.filter ?? defaultFilter
      });
    }
    hide()
    clearFilterOnly?.();
    clearExcludeOnly?.();
  }

  const isExcluded = id => isExcludable && id && excludeValues.some(v => v == id);

  const { title, identity, autocompleteTitle } = data;
  // console.log(items)
  if (!items.length) return null
  return (
    <div className={`form__item ${wrapperClass ?? ""}`} ref={formItemRef}>
      <div ref={node} className={`dropdown form__drop ${open ? "is-showing" : ""}`}>
        <div onClick={open ? hide : show} className="dropdown__btn button form__drop-btn">
          <span className="form__drop-name">
            <div>{title}</div>
            {selected.length > 0 && <span style={selectedCountStyle}>{selectedCount || selected.length}</span>}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={hide}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{title}</h3>
                  {selected.length > 0 && <span style={selectedCountStyle}> {selectedCount || selected.length}</span>}
                </div>
              }
              <div className="filter">
                {isAutocomplete && identity !== 'rooms' && (
                  <div className="filter__filter">
                    <SearchInput
                      placeholder={autocompleteTitle}
                      onChange={e =>
                        setSearch(e.target.value.toLowerCase())
                      }
                      inputRef={searchRef}
                      reset={() => setSearch("")}
                      value={search}
                    />
                  </div>
                )}
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {filterable.map((item, i) => (
                      <ChecboxItem
                        {...item}
                        isExcludable={isExcludable}
                        exclude={isExcluded(item?.id)}
                        identity={identity}
                        checked={!!item?.id && selected.some(id => id == item.id)}
                        onChange={handleChange}
                        // key={`${item.id}-${i}`}
                        // disabled={(disabledItems[item.id] && (
                        //   filterValues.some(fv => disabledItems[item.id].includes(fv)) || excludeValues.some(fv => disabledItems[item.id].includes(fv))
                        // )) || disabledIds.includes(item?.id)
                        // }
                        key={`${item?.id}-${i}`}
                        disabled={(item?.id && disabledItems[item.id] && (
                          filterValues.some(fv => disabledItems[item.id].includes(fv)) || excludeValues.some(fv => disabledItems[item.id].includes(fv))
                        )) || disabledIds.includes(item?.id)
                        }
                        isMobile={isMobile}
                        titleKey={titleKey}
                      />
                    ))}
                  </div>
                </SimpleBar>

                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button type="button" className="button button__mobile-filters" onClick={clearFilters}>
                      Очистить
                    </button>
                    <button type="button" className="button button__mobile-filters" onClick={hide}>
                      Применить
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default SelectFilter;
