import React, {useEffect, useRef, useState} from 'react'
import './index.scss';
import ManagerModal from "../../components/Public/markup/detail/ManagerModal";
import Slider from "../../components/Public/markup/detail/Slider";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {getUrl} from "../../api";
import useMedia from "../../hooks/useMedia";
import {
  SuburbanAboutMainIcon,
  SuburbanBedIcon,
  SuburbanBuildingAreaIcon,
  SuburbanCeilingHeightIcon,
  SuburbanCountBathroomsIcon,
  SuburbanProjFloorIcon,
  SuburbanProjMetrIcon,
  SuburbanProjSecondFasadIcon,
  SuburbanProjSecondFloorIcon,
  SuburbanProjSecondHouseIcon,
  SuburbanProjSecondRepairIcon,
  SuburbanProjSecondRoofIcon,
} from "../../components/Public/markup/icons";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import useApi from "../../hooks/useApi";
import Preloader from "../../components/Preloader";
import {useParams} from "react-router-dom";
import useDocTitle from "../../hooks/useDocTitle";
import { SuburbanPresentationMap } from './SuburbanPresentationMap';

const SuburbanPresentation = () => {

  const {id} = useParams()
  const {data: {objects, generalInfo, agent, coordinates, address}, isLoading} = useApi({
    payload: ["panpartner:new.suburbans.ajax", "getPresentation", {id}]
  })

  const isDesktop = useMedia("isDesktop");
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isSmallLaptop = useMedia('isSmallLaptop');

  useDocTitle('ПАН Партнер — презентация')

  const [complexView, setComplexView] = useState('photos')
  const [numberOfSlides, setNumberOfSlides] = useState(0);
  const handleInit = (swiper) => {
    if (swiper) {
      setNumberOfSlides(swiper.slides.length);
    }
  };


  const [isLessDescription, setIsLessDescription] = useState(true)
  const [isLessDescriptionSecond, setIsLessDescriptionSecond] = useState(true)
  const [isLessDescriptionSecond2, setIsLessDescriptionSecond2] = useState(true)
  const [isLessDescriptionHouse, setIsLessDescriptionHouse] = useState(true)
  const [isLessDescriptionCp, setIsLessDescriptionCp] = useState(true)

  const markers = [
    {
      coords: coordinates,
      title: address,
    },
  ]
  if (isLoading) return <Preloader loading/>;
  return (
    <div className='suburban-presentation'>
      <div className="suburban-presentation__grid suburban-presentation__grid_header">
        <div className="suburban-presentation__left">
          <div className="suburban-presentation__top-info">
            <div className="suburban-presentation__top-infoList">
              <div className="suburban-presentation__top-infoItem">
                <p className="suburban-presentation__top-title">
                  {generalInfo?.info?.[0]?.title}
                </p>
                <p className="suburban-presentation__top-text">
                  {generalInfo?.info?.[0]?.params}
                </p>
              </div>
              <div className="suburban-presentation__top-infoItem">
                <p className="suburban-presentation__top-title">
                  {generalInfo?.info?.[1]?.title}
                </p>
                <p className="suburban-presentation__top-text">
                  {generalInfo?.info?.[1]?.params}
                </p>
                <p className="suburban-presentation__top-text">
                  {generalInfo?.info?.[1]?.address}
                </p>
              </div>
            </div>
            <div className="suburban-presentation__top-infoPrice">
              {generalInfo?.price}
            </div>


          </div>
        </div>
        <div className="suburban-presentation__right">
          <ManagerModal
            name={agent.name + ' ' + agent.lastName}
            photo={{src: '/upload/app_images/logo2.svg'}}
            post={agent.workCompany}
            email="support@panpartner.ru"
            phone={agent.phone}
            whatsApp={agent.phone}
            telegram={agent.phone}
          />
        </div>
      </div>

      {objects?.contractConstruction &&
        <div className="suburban-presentation__grid suburban-presentation__grid_full">
          <div className="suburban-presentation__left">

            {isSmallLaptop &&
              <div className="suburban-presentation__right-labels suburban-presentation__right-labels_mobile">
                <span>{objects?.contractConstruction?.title}</span>
                {!Array.isArray(objects?.contractConstruction?.equipment) && <span
                  className="suburban-presentation__right-labelActive">{objects?.contractConstruction?.price}</span>}
              </div>
            }

            <div className="suburban-presentation__left-slider">
              {!!objects?.contractConstruction?.photo?.length && complexView == 'photos' && (
              <Slider
                items={objects?.contractConstruction?.photo}
                defoultThumbs
              />
              )}
              {!!objects?.contractConstruction?.plan?.length && complexView == 'planPhotos' && (
                  <Slider
                    items={objects?.contractConstruction?.plan}
                    defoultThumbs
                  />
              )}
              <ul className="complex-view-list">
                  <Swiper
                    spaceBetween={isSmallLaptop ? 10 : 15}
                    slidesPerView={isSmallLaptop ? 2 : 3.5}
                    modules={[Navigation]}
                  >

                    {!!objects?.contractConstruction?.photo && objects?.contractConstruction?.photo.length > 0 &&
                      <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`}
                        onClick={() => {
                          setComplexView('photos')
                        }}>
                      <img src={getUrl(objects?.contractConstruction?.photo?.[0].src)} alt=""/>
                      <span>Фотографии</span>
                    </li>
                  </SwiperSlide>}
                  {!!objects?.contractConstruction?.plan && objects?.contractConstruction?.plan.length > 0 && <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'planPhotos' ? 'active' : ''}`}
                        onClick={() => {
                          setComplexView('planPhotos')
                        }}>
                      <img src={getUrl(objects?.contractConstruction?.plan?.[0].src)} alt=""/>
                      <span>Планировки</span>
                    </li>
                  </SwiperSlide>}
                </Swiper>
              </ul>
            </div>

            <div className="suburban-presentation__left-content">
              <div className="communications-redesign__list">
                <ul className="about-redesign__list">


                  {objects?.contractConstruction?.projectInfo.map((i) => {
                    return (
                      <li className="about-redesign__list-item">
                        <div className="about-redesign__list-icon">

                          {i.type === 'squareHouse' && <SuburbanProjMetrIcon/>}
                          {i.type === 'countFloors' && <SuburbanProjFloorIcon/>}
                          {i.type === 'countBedrooms' && <SuburbanBedIcon/>}
                          {i.type === 'buildingArea' && <SuburbanBuildingAreaIcon/>}
                          {i.type === 'countBathrooms' && <SuburbanCountBathroomsIcon/>}
                          {i.type === 'ceilingHeight' && <SuburbanCeilingHeightIcon/>}

                        </div>
                        <div className="about-redesign__list-text">
                          <div className="about-redesign__listtext-sm">{i.title}</div>
                          <div className="about-redesign__listtext-bd">{i.value}</div>
                        </div>
                      </li>
                    )
                  })}

                </ul>
              </div>

              {objects?.contractConstruction?.descriptionHouse !== '' &&
              <div className="about-redesign">
                <div className="about-redesign__top">
                  <div className="about-redesign__top-icon">
                    <SuburbanAboutMainIcon/>
                  </div>
                  <div className="about-redesign__top-content">
                    <p className="about-redesign__top-title">
                      О проекте
                    </p>

                      <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescription ? objects?.contractConstruction?.descriptionHouse : objects?.contractConstruction?.descriptionHouse?.split('<br>')[0]}}></p>
                      {objects?.contractConstruction?.descriptionHouse.includes('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescription(prev=>!prev)}>{!isLessDescription ?  'Свернуть' : 'Подробнее'}</span>}

                  </div>
                </div>
              </div>
              }

            </div>
          </div>
          <div className="suburban-presentation__right">
            <div className="suburban-presentation__right-content">

              {!isSmallLaptop &&
                <div className="suburban-presentation__right-labels">
                  <span>{objects?.contractConstruction?.title}</span>
                  {!Array.isArray(objects?.contractConstruction?.equipment) && <span
                    className="suburban-presentation__right-labelActive">{objects?.contractConstruction?.price}</span>}
                </div>
              }

              {!Array.isArray(objects?.contractConstruction?.equipment) &&
                <div>
                  <p className="communications-redesign__title">{objects?.contractConstruction?.equipment?.title}</p>
                  <div>

                    <div className="communications-redesign__list communications-redesign__list_white">
                      <ul className="about-redesign__list">

                        {objects?.contractConstruction?.equipment?.items?.map((it) => {
                          return (
                            <li className="about-redesign__list-item">
                              <div className="about-redesign__list-icon">

                                {it.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                {it.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                {it.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                {it.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                {it.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}

                              </div>
                              <div className="about-redesign__list-text">
                                <div className="about-redesign__listtext-sm">{it.title}</div>
                                <div className="about-redesign__listtext-bd">{it.value}</div>
                              </div>
                            </li>
                          )
                        })}

                      </ul>
                    </div>

                    <div style={{marginTop: 20}}>
                      <p className="communications-redesign__title">Также в комплектацию входит:</p>
                      <ul className="about-redesign__complectation-list">
                        <div className="about-redesign__complectation-list-container"
                             dangerouslySetInnerHTML={{__html: objects?.contractConstruction?.equipment?.description}}></div>
                      </ul>
                    </div>


                  </div>
                </div>
              }

              {Array.isArray(objects?.contractConstruction?.equipment) &&
                <div>
                  {
                    objects?.contractConstruction?.equipment.map((item) => {
                      return (
                        <div style={{marginBottom: 30, border: '1px solid #E3E5EC', borderRadius: 20, padding: 20}}>

                          <div className="suburban-presentation__right-labels">
                            <span style={{fontSize: 14}}>{item.title}</span>
                            <span style={{fontSize: 14}} className="suburban-presentation__right-labelActive">{item.price}</span>
                          </div>
                          <div>
                            <div className="communications-redesign__list communications-redesign__list_white">
                              <ul className="about-redesign__list">

                                {Object.entries(item).map((it) => {

                                  return (
                                    <>
                                      {it[0] === 'items' && it[1].map((icon) => {
                                        console.log(icon.type)
                                        return (
                                          <li className="about-redesign__list-item">
                                            <div className="about-redesign__list-icon">
                                              {icon.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                              {icon.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                              {icon.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                              {icon.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                              {icon.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}
                                            </div>
                                            <div className="about-redesign__list-text">
                                              <div className="about-redesign__listtext-sm">{icon.title}</div>
                                              <div className="about-redesign__listtext-bd">{icon.value}</div>
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </>

                                  )
                                })}

                              </ul>
                            </div>

                            <div style={{marginTop: 20}}>
                              <p className="communications-redesign__title">Также в комплектацию входит:</p>
                              <ul className="about-redesign__complectation-list">
                                <div className="about-redesign__complectation-list-container"
                                     dangerouslySetInnerHTML={{__html: item.description}}></div>
                              </ul>
                            </div>

                          </div>

                        </div>
                      )

                    })
                  }
                </div>
              }


            </div>
          </div>
        </div>
      }

      {objects?.house &&
        <>
        <div className="suburban-presentation__grid suburban-presentation__grid_full">
          <div className="suburban-presentation__left">

            {isSmallLaptop &&
              <div className="suburban-presentation__right-labels suburban-presentation__right-labels_mobile">
                <span>{objects?.house?.title}</span>
                {!Array.isArray(objects?.house?.equipment) && <span
                  className="suburban-presentation__right-labelActive">{objects?.house?.price}</span>}
              </div>
            }

            <div className="suburban-presentation__left-slider">
              {!!objects?.house?.photo?.length && complexView == 'photos' && (
                <Slider
                  items={objects?.house?.photo}
                  defoultThumbs
                />
              )}
              {!!objects?.house?.plan?.length && complexView == 'planPhotos' && (
                <Slider
                  items={objects?.house?.plan}
                  defoultThumbs
                />
              )}
              <ul className="complex-view-list">
                <Swiper
                  spaceBetween={isSmallLaptop ? 10 : 15}
                  slidesPerView={isSmallLaptop ? 2 : 3.5}
                  modules={[Navigation]}
                >

                  {!!objects?.house?.photo && objects?.house?.photo.length > 0 &&
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`}
                          onClick={() => {
                            setComplexView('photos')
                          }}>
                        <img src={getUrl(objects?.house?.photo?.[0].src)} alt=""/>
                        <span>Фотографии</span>
                      </li>
                    </SwiperSlide>}
                  {!!objects?.house?.plan && objects?.house?.plan.length > 0 && <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'planPhotos' ? 'active' : ''}`}
                        onClick={() => {
                          setComplexView('planPhotos')
                        }}>
                      <img src={getUrl(objects?.house?.plan?.[0].src)} alt=""/>
                      <span>Планировки</span>
                    </li>
                  </SwiperSlide>}
                </Swiper>
              </ul>
            </div>

            <div className="suburban-presentation__left-content">


            </div>
          </div>
          <div className="suburban-presentation__right">
            <div className="suburban-presentation__right-content">

              {!isSmallLaptop &&
                <div className="suburban-presentation__right-labels">
                  <span>{objects?.house?.title}</span>
                  {!Array.isArray(objects?.house?.equipment) && <span
                    className="suburban-presentation__right-labelActive">{objects?.house?.price}</span>}
                </div>
              }

              {!Array.isArray(objects?.house?.equipment) &&
                <div>
                  <p className="communications-redesign__title">{objects?.house?.equipment?.title}</p>
                  <div>

                    <div className="communications-redesign__list communications-redesign__list_white">
                      <ul className="about-redesign__list">

                        {objects?.house?.equipment?.items?.map((it) => {
                          return (
                            <li className="about-redesign__list-item">
                              <div className="about-redesign__list-icon">

                                {it.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                {it.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                {it.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                {it.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                {it.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}

                              </div>
                              <div className="about-redesign__list-text">
                                <div className="about-redesign__listtext-sm">{it.title}</div>
                                <div className="about-redesign__listtext-bd">{it.value}</div>
                              </div>
                            </li>
                          )
                        })}

                      </ul>
                    </div>

                    <div style={{marginTop: 20}}>
                      <p className="communications-redesign__title">Также в комплектацию входит:</p>
                      <ul className="about-redesign__complectation-list">
                        <div className="about-redesign__complectation-list-container"
                             dangerouslySetInnerHTML={{__html: objects?.house?.equipment?.description}}></div>
                      </ul>
                    </div>


                  </div>
                </div>
              }

              {Array.isArray(objects?.house?.equipment) &&
                <div>
                  {
                    objects?.house?.equipment.map((item) => {
                      return (
                        <div style={{marginBottom: 30, border: '1px solid #E3E5EC', borderRadius: 20, padding: 20}}>

                          <div className="suburban-presentation__right-labels">
                            <span style={{fontSize: 14}}>{item.title}</span>
                            <span style={{fontSize: 14}} className="suburban-presentation__right-labelActive">{item.price ?? generalInfo?.price}</span>
                          </div>
                          <div>
                            <div className="communications-redesign__list communications-redesign__list_white">
                              <ul className="about-redesign__list">

                                {Object.entries(item).map((it) => {

                                  return (
                                    <>
                                      {it[0] === 'items' && it[1].map((icon) => {
                                        console.log(icon.type)
                                        return (
                                          <li className="about-redesign__list-item">
                                            <div className="about-redesign__list-icon">
                                              {icon.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                              {icon.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                              {icon.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                              {icon.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                              {icon.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}
                                            </div>
                                            <div className="about-redesign__list-text">
                                              <div className="about-redesign__listtext-sm">{icon.title}</div>
                                              <div className="about-redesign__listtext-bd">{icon.value}</div>
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </>

                                  )
                                })}

                              </ul>
                            </div>

                          {!!item.description && <div style={{marginTop: 20}}>
                              <p className="communications-redesign__title">Также в комплектацию входит:</p>
                              <ul className="about-redesign__complectation-list">
                                <div className="about-redesign__complectation-list-container"
                                     dangerouslySetInnerHTML={{__html: item.description}}></div>
                              </ul>
                            </div>}

                          </div>

                        </div>
                      )

                    })
                  }
                </div>
              }


            </div>
          </div>
          <div className="suburban-presentation__left suburban-presentation__left_house">
            <div className="suburban-presentation__left-content">
              {objects?.house?.descriptionHouse !== '' &&
                <div className="about-redesign">
                  <div className="about-redesign__top">
                    <div className="about-redesign__top-icon">
                      <SuburbanAboutMainIcon/>
                    </div>
                    <div className="about-redesign__top-content">
                      <p className="about-redesign__top-title">
                        О проекте
                      </p>

                      <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescriptionHouse ? objects?.house?.descriptionHouse : objects?.house?.descriptionHouse?.split('<br>')[0]}}></p>
                      {objects?.house?.descriptionHouse.includes('<br>') && <span className="about-redesign__top-more" onClick={(e)=>setIsLessDescriptionHouse(prev=>!prev)}>{!isLessDescriptionHouse ?  'Свернуть' : 'Подробнее'}</span>}

                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="suburban-presentation__right suburban-presentation__right_house">
            <div className="suburban-presentation__right-content">
              <div className="communications-redesign__list">
                <ul className="about-redesign__list">


                  {objects?.house?.projectInfo.map((i) => {
                    return (
                      <li className="about-redesign__list-item">
                        <div className="about-redesign__list-icon">

                          {i.type === 'squareHouse' && <SuburbanProjMetrIcon/>}
                          {i.type === 'countFloors' && <SuburbanProjFloorIcon/>}
                          {i.type === 'countBedrooms' && <SuburbanBedIcon/>}
                          {i.type === 'buildingArea' && <SuburbanBuildingAreaIcon/>}
                          {i.type === 'countBathrooms' && <SuburbanCountBathroomsIcon/>}
                          {i.type === 'ceilingHeight' && <SuburbanCeilingHeightIcon/>}

                        </div>
                        <div className="about-redesign__list-text">
                          <div className="about-redesign__listtext-sm">{i.title}</div>
                          <div className="about-redesign__listtext-bd">{i.value}</div>
                        </div>
                      </li>
                    )
                  })}

                </ul>
              </div>
            </div>
          </div>

          <div className="suburban-presentation__left suburban-presentation__left_house">
            <div className="suburban-presentation__left-content">
              {objects?.house?.cottageSettlement?.description !== '' &&
                <div className="about-redesign">
                  <div className="about-redesign__top">
                    <div className="about-redesign__top-icon">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.2987 7.53526L25.39 13.7086C25.5076 13.951 25.683 14.1617 25.9012 14.3231C26.1195 14.4844 26.3741 14.5917 26.6434 14.6356L33.5611 15.6182C33.8689 15.6587 34.1589 15.7834 34.3983 15.978C34.6376 16.1727 34.8165 16.4294 34.9147 16.7192C35.0129 17.009 35.0265 17.32 34.9538 17.6169C34.8811 17.9139 34.7251 18.185 34.5035 18.3991L29.4896 23.2006C29.2985 23.3864 29.1558 23.615 29.0737 23.8668C28.9917 24.1186 28.9727 24.3861 29.0184 24.6467L30.1965 31.4225C30.2493 31.7224 30.2158 32.0309 30.0997 32.313C29.9837 32.5952 29.7896 32.8399 29.5396 33.0193C29.2896 33.1987 28.9935 33.3057 28.6849 33.3282C28.3763 33.3507 28.0674 33.2878 27.7932 33.1467L21.6013 29.9395C21.3616 29.8205 21.0969 29.7585 20.8285 29.7585C20.56 29.7585 20.2953 29.8205 20.0557 29.9395L13.8637 33.1467C13.5895 33.2878 13.2806 33.3507 12.972 33.3282C12.6634 33.3057 12.3673 33.1987 12.1173 33.0193C11.8673 32.8399 11.6733 32.5952 11.5572 32.313C11.4411 32.0309 11.4076 31.7224 11.4604 31.4225L12.6479 24.6467C12.6941 24.3854 12.6744 24.1169 12.5906 23.8648C12.5068 23.6127 12.3615 23.3846 12.1673 23.2006L7.16282 18.3991C6.94128 18.185 6.78529 17.9139 6.71259 17.6169C6.6399 17.32 6.65341 17.009 6.75162 16.7192C6.84983 16.4294 7.02878 16.1727 7.26809 15.978C7.5074 15.7834 7.79747 15.6587 8.10528 15.6182L15.0324 14.6356C15.3 14.5903 15.5527 14.4826 15.7692 14.3213C15.9857 14.16 16.1596 13.9498 16.2764 13.7086L19.3771 7.53526C19.5168 7.2731 19.7268 7.05344 19.9843 6.90024C20.2417 6.74705 20.537 6.66602 20.8379 6.66602C21.1388 6.66602 21.434 6.74705 21.6915 6.90024C21.949 7.05344 22.159 7.2731 22.2987 7.53526Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
                      </svg>
                    </div>
                    <div className="about-redesign__top-content">
                      <p className="about-redesign__top-title">
                        О { objects?.house?.cottageSettlement?.title}
                      </p>

                      <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescriptionCp ? objects?.house?.cottageSettlement?.description : objects?.house?.cottageSettlement?.description.split('<br>')[0]}}></p>
                      {objects?.house?.cottageSettlement?.description.includes('<br>') && <span className="about-redesign__top-more" onClick={(e)=>setIsLessDescriptionCp(prev=>!prev)}>{!isLessDescriptionCp ?  'Свернуть' : 'Подробнее'}</span>}

                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="suburban-presentation__right suburban-presentation__right_house">
            <div className="suburban-presentation__right-content">
              {objects?.house?.communicationType &&
                <>
                  <p className="communications-redesign__title" style={{marginTop: 20}}>Коммуникации и удобства</p>
                  <div className="communications-redesign__list">
                    <ul className="about-redesign__list">

                      {objects?.house?.communicationType?.map(({icon, title, xmlId}) => <li
                          className="about-redesign__list-item" key={xmlId}>
                          <div className="about-redesign__list-icon">
                            <img src={getUrl(icon)} height={40}/>
                          </div>
                          <div className="about-redesign__list-text">{title}</div>
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              }
            </div>
          </div>

        </div>

        </>
      }


      {objects?.parcel &&
        <div className={`suburban-presentation__grid suburban-presentation__grid_full ${objects?.parcel?.status?.title === 'Продано' ? 'suburban-presentation__grid_wasted' : ''}`}>
          <div className="suburban-presentation__left">

            {isSmallLaptop &&
              <div className="suburban-presentation__right-labels suburban-presentation__right-labels_mobile">
                <span>{objects?.parcel?.title}</span>
                <span className="suburban-presentation__right-labelActive">
                  {objects?.parcel?.status?.title === 'Продано' ? objects?.parcel?.status?.title : objects?.parcel?.price}
                  </span>
              </div>
            }

            {objects?.parcel?.photo?.length > 0 &&
              <div className="suburban-presentation__left-slider">
                <Slider
                  items={objects?.parcel?.photo}
                  defoultThumbs
                />
                <ul className="complex-view-list">
                  <Swiper
                    spaceBetween={isSmallLaptop ? 10 : 15}
                    slidesPerView={isSmallLaptop ? 2 : 3.5}
                    modules={[Navigation]}
                  >
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`}
                          onClick={() => {
                            setComplexView('photos')
                          }}>
                        <img src={getUrl(objects?.parcel?.photo?.[0].src)} alt=""/>
                        <span>Фотографии</span>
                      </li>
                    </SwiperSlide>
                  </Swiper>
                </ul>
              </div>
             }
            <p className="suburban-presentation__land-status">
              <svg style={{marginRight: 10, flexShrink: 0}} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.5435" cy="12.0996" r="10" fill="white"/>
                <line x1="11.5869" y1="15.5996" x2="11.5869" y2="9.59961" stroke="#777A89" stroke-linecap="round"/>
                <circle cx="11.5869" cy="7.59961" r="0.5" transform="rotate(-180 11.5869 7.59961)" fill="#777A89"/>
              </svg>
              Вид разрешенного строительства {objects?.parcel?.landStatus?.title}
            </p>

            <div className="suburban-presentation__left-content">
              {objects?.parcel?.description !== '' &&
                <div className="about-redesign">
                  <div className="about-redesign__top">
                    <div className="about-redesign__top-icon">
                      <SuburbanAboutMainIcon/>
                    </div>
                    <div className="about-redesign__top-content">
                      <p className="about-redesign__top-title">
                        Об участке
                      </p>

                      <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescriptionSecond ? objects?.parcel?.description : objects?.parcel?.description?.split('<br>')[0]}}></p>
                      {objects?.parcel?.description.includes('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescriptionSecond(prev=>!prev)}>{!isLessDescriptionSecond ?  'Свернуть' : 'Подробнее'}</span>}

                    </div>
                  </div>
                </div>
              }

              {objects?.parcel?.cottageSettlement?.description !== '' &&
                <div className="about-redesign">
                  <div className="about-redesign__top">
                    <div className="about-redesign__top-icon">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.2987 7.53526L25.39 13.7086C25.5076 13.951 25.683 14.1617 25.9012 14.3231C26.1195 14.4844 26.3741 14.5917 26.6434 14.6356L33.5611 15.6182C33.8689 15.6587 34.1589 15.7834 34.3983 15.978C34.6376 16.1727 34.8165 16.4294 34.9147 16.7192C35.0129 17.009 35.0265 17.32 34.9538 17.6169C34.8811 17.9139 34.7251 18.185 34.5035 18.3991L29.4896 23.2006C29.2985 23.3864 29.1558 23.615 29.0737 23.8668C28.9917 24.1186 28.9727 24.3861 29.0184 24.6467L30.1965 31.4225C30.2493 31.7224 30.2158 32.0309 30.0997 32.313C29.9837 32.5952 29.7896 32.8399 29.5396 33.0193C29.2896 33.1987 28.9935 33.3057 28.6849 33.3282C28.3763 33.3507 28.0674 33.2878 27.7932 33.1467L21.6013 29.9395C21.3616 29.8205 21.0969 29.7585 20.8285 29.7585C20.56 29.7585 20.2953 29.8205 20.0557 29.9395L13.8637 33.1467C13.5895 33.2878 13.2806 33.3507 12.972 33.3282C12.6634 33.3057 12.3673 33.1987 12.1173 33.0193C11.8673 32.8399 11.6733 32.5952 11.5572 32.313C11.4411 32.0309 11.4076 31.7224 11.4604 31.4225L12.6479 24.6467C12.6941 24.3854 12.6744 24.1169 12.5906 23.8648C12.5068 23.6127 12.3615 23.3846 12.1673 23.2006L7.16282 18.3991C6.94128 18.185 6.78529 17.9139 6.71259 17.6169C6.6399 17.32 6.65341 17.009 6.75162 16.7192C6.84983 16.4294 7.02878 16.1727 7.26809 15.978C7.5074 15.7834 7.79747 15.6587 8.10528 15.6182L15.0324 14.6356C15.3 14.5903 15.5527 14.4826 15.7692 14.3213C15.9857 14.16 16.1596 13.9498 16.2764 13.7086L19.3771 7.53526C19.5168 7.2731 19.7268 7.05344 19.9843 6.90024C20.2417 6.74705 20.537 6.66602 20.8379 6.66602C21.1388 6.66602 21.434 6.74705 21.6915 6.90024C21.949 7.05344 22.159 7.2731 22.2987 7.53526Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
                      </svg>
                    </div>
                    <div className="about-redesign__top-content">
                      <p className="about-redesign__top-title">
                        О {' ' + objects?.parcel?.cottageSettlement?.title}
                      </p>

                      <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescriptionSecond2 ? objects?.parcel?.cottageSettlement?.description : objects?.parcel?.cottageSettlement?.description?.split('<br>')[0]}}></p>
                      {objects?.parcel?.cottageSettlement?.description.includes('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescriptionSecond2(prev=>!prev)}>{!isLessDescriptionSecond2 ?  'Свернуть' : 'Подробнее'}</span>}

                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="suburban-presentation__right">
            <div className="suburban-presentation__right-content">

              {!isSmallLaptop &&
              <div className="suburban-presentation__right-labels">
                <span>{objects?.parcel?.title}</span>
                <span className="suburban-presentation__right-labelActive">
                  {objects?.parcel?.status?.title === 'Продано' ? objects?.parcel?.status?.title : objects?.parcel?.price}
                  </span>
              </div>
              }
              <div>
                <p className="communications-redesign__title">Коммуникации и удобства</p>
                <div className="communications-redesign__list">
                  <div className="communications-redesign__list">
                    <ul className="about-redesign__list">

                      {objects?.parcel?.communicationType?.map(({icon, title, xmlId}) => <li
                          className="about-redesign__list-item" key={xmlId}>
                          <div className="about-redesign__list-icon">
                            <img src={getUrl(icon)} height={40}/>
                          </div>
                          <div className="about-redesign__list-text">{title}</div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

{coordinates && address &&
        <div className="suburban-presentation__map">
          <div className="pan-comfort__map">
           <SuburbanPresentationMap cords={coordinates} mapCenter={coordinates} address={address} />
          </div>
        </div>
      }

    </div>
  )
}

export default SuburbanPresentation
