
import React, { useRef } from 'react';
import './index.scss';
const Dropzone = props => {
  const {
    uploadFiles,
    className = "dropzone",
    style = {},
    children,
    onDrragOver = () => { },
    title = `Загрузите файлы`
  } = props

  const inputRef = useRef(null)

  const dropHandler = e => {
    e.persist();
    e.preventDefault();
    uploadFiles(e, true)
  }
  const dragOverHandler = e => {
    e.preventDefault()
    onDrragOver()
  }

  const startIputUpload = () => inputRef.current.dispatchEvent(new MouseEvent("click"))

  return <div
    className={className}
    style={style}
    onDrop={dropHandler}
    onDragOver={dragOverHandler}
    onClick={startIputUpload}
  >
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.626953 11.907V21.5907C0.626953 22.143 1.07467 22.5907 1.62695 22.5907H19.8729C20.4252 22.5907 20.8729 22.143 20.8729 21.5907V11.907" stroke="#8F95AC"/>
      <path d="M16.5302 7.23796L10.0638 0.981727" stroke="#8F95AC"/>
      <path d="M11.0771 0.981703L4.61075 7.23793" stroke="#8F95AC"/>
      <path d="M10.5704 1.41824L10.5704 17.7954" stroke="#8F95AC"/>
    </svg>
    <p>{title}</p>
    {children}
    <input type="file" ref={inputRef} multiple onChange={uploadFiles} style={{ display: "none" }} />
  </div>
}

export default Dropzone
