import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
// import ThemeBg1 from './Themes/ThemeBg1';
// import ThemeBg2 from './Themes/ThemeBg2';
// import ThemeBg3 from './Themes/ThemeBg3';
import { getUrl } from '../../../api';
import { ReactComponent as Dots } from "./dotes.svg";
import { ReactComponent as Triangle } from "./triangle.svg";


const Preview = (props) => {
  const { agent } = props
  const [image, setImage] = useState("")

  useEffect(() => {
      if(!agent.photo){
          setImage(require('../../../assets/images/constructor/photo-upload.svg').default)
          return
      }
      if(agent.photo?.src){
          setImage(getUrl(agent.photo?.src))
      } else {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result)
      reader.readAsDataURL(agent.photo);
      }
  }, [agent.photo])

  return (
    <div className="c-modal__preview c-preview">
      {
        // <ThemeBg2 currentColor={`#DD453B`} />
      }
      <div className="c-preview__content">
        <div className="c-preview__info">
          <span className="c-preview__label text">{`Новое агентство недвижимости`}</span>
          <p className="h2 c-preview__text">{`Продаем и подбираем недвижимость для вас`}</p>
          <div className="c-preview__sign big-text">{`для счастливой жизни и успешного бизнеса`}</div>
        </div>

        {/*<img src="https://dev2.panpartner.ru/upload/sites/a46/3.-Leopard.jpg" alt="" className="c-preview__image"/>*/}
      </div>

      <div className="c-preview__image">
        <img src={image} alt="" className="admin__theme-avatar c-preview__image" />
      </div>

      <Dots className="c-preview__dots"/>
      <Triangle className="c-preview__triangle" />

      <div className="c-preview__note">
        <p>
          <span role="img" aria-label="flag">🚩</span>
          &nbsp;
          Основной заголовок страницы, которой отображается на первом экране сайта.
        </p>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    agent: state.siteConstructor.agent,
  }
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
