import React from 'react';
import { getUrl } from '../../../api';

const Infrastructure = ({ infrastructure}) => {

  return <div className="presentation__info pdf-block presentation__info_flat">
    <div className="presentation__box" data-id="building_on_map">
      <div className="presentation__title">Инфраструктура</div>
      <div className="presentation__floor-plan presentation__floor-plan_full-img"><img src={getUrl(infrastructure?.src)} alt="" /></div>
    </div>

  </div>
};

export default Infrastructure;