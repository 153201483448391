import './index.scss';

const DownloadApp = ({ downloadApp, setDownloadApp, noCloser }) => {
  const thanksHandler = () => {
    setDownloadApp(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <section className={`da${downloadApp ? `` : ` hidden`}`}>
      <div className="da__container">
        <p className="da__title">У нас есть мобильное приложение</p>
        <p className="da__text">
          Весь функционал ПАН Партнер в удобном формате
        </p>
        <span className="da__smile">😉</span>

        <ul className="da__list">
          <li className="da__item">
            <a
              href="https://bit.ly/3kGH4q1"
              className="da__anchor"
              target="_blank"
            >
              <img
                src={
                  require('../../../assets/images/preview/da__android.svg')
                    .default
                }
                alt=""
                className="da__svg"
              />
            </a>
          </li>
          <li className="da__item">
            <a
              href="https://apple.co/3klTwLt"
              className="da__anchor"
              target="_blank"
            >
              <img
                src={
                  require('../../../assets/images/preview/da__apple.svg')
                    .default
                }
                alt=""
                className="da__svg"
              />
            </a>
          </li>
        </ul>

        {!noCloser && (
          <button
            type="button"
            className="button da__thanks"
            onClick={() => thanksHandler()}
          >
            спасибо, не сейчас
          </button>
        )}
      </div>
    </section>
  );
};

export default DownloadApp;
