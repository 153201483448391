import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import agency from './agency';
import agents from './agents';
import apartment from './apartment';
import assignment from './assignment';
import assignments from './assignments';
import autopan from './autopan';
import banners from './banners';
import chess from './chess';
import clients from './clients';
import commercial from './commercial';
import commercialUnit from './commercialUnit';
import complex from './complex';
import contacts from './contacts';
import estate from './estate';
import event from './event';
import events from './events';
import favorites from './favorites';
import files from './files';
import forms from './forms';
import general from './general';
import home from './home';
import meetingRooms from './meetingRooms';
import meetings from './meetings';
import motivation from './motivation';
import myObjects from './myObjects';
import presentation from './presentation';
import presentations from './presentations';
import profile from './profile';
import profileOffers from './profile.offers';
import realty from './realty';
import refs from './refs';
import register from './register';
import requests from './requests';
import savedFilters from './savedFilters';
import secondaries from './secondaries';
import secondary from './secondary';
import siteConstructor from './siteConstructor';
import start from './start';
import suburban from './suburban';
import suburbanLand from './suburbanLand';
import suburbans from './suburbans';
import tradeIn from './tradeIn';
import user from './user';
import metrika from './yandex-metrika';


const prod = window.location.host === 'panpartner.ru';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    general: persistReducer(
      {
        key: 'general',
        storage,
        debug: !prod,
        blacklist: ['unreadCount'],
      },
      general,
    ),
    user: persistReducer(
      {
        key: 'user',
        storage,
        debug: !prod,
        blacklist: ['error', 'fetching', 'history'],
      },
      user,
    ),
    estate,
    contacts,
    files,
    apartment,
    motivation,
    agents,
    clients,
    profile: persistReducer(
      {
        key: 'profile',
        storage,
        debug: !prod,
      },
      profile,
    ),
    register,
    assignments,
    assignment,
    profileOffers,
    home,
    chess,
    presentation,
    presentations: persistReducer(
      {
        key: 'presentations',
        storage,
        debug: !prod,
      },
      presentations,
    ),
    complex: persistReducer(
      {
        key: 'complex',
        storage,
        debug: !prod,
        whitelist: ['filterData'],
      },
      complex,
    ),
    savedFilters,
    requests: persistReducer(
      {
        key: 'requests',
        storage,
        debug: !prod,
      },
      requests,
    ),
    events: persistReducer(
      {
        key: 'events',
        storage,
        debug: !prod,
      },
      events,
    ),
    favorites,
    event,
    tradeIn,
    meetingRooms,
    autopan,
    commercial,
    commercialUnit,
    meetings,
    forms,
    secondaries,
    secondary,
    start,
    realty,
    myObjects,
    agency,
    metrika,
    siteConstructor,
    refs,
    banners,
    suburbans,
    suburban,
    suburbanLand,
  });

export default rootReducer;
