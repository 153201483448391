import React from "react";

const Preloader = ({ loading = false }) => (
  <div className={`preloader ${loading ? `loading` : ``}`}>
    <object style={{ width: "100px" }} type="image/svg+xml" data="/assets/images/prelouder_new.svg">
      <img src="/assets/images/pan-preloader.svg" alt="preloader" />
    </object>
  </div>
)

export default Preloader;