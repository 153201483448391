import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ZoomIcon } from '../core/SwiperSlider/icons';

const SearchFilter = (props) => {
  const { placeholder, handleChange, value, wrapperClass } = props;
  return (
    <div
      className={`form__item form__item_2x form__item_main ${
        wrapperClass ?? ''
      }`}
    >
      <div className="index-search__search">
        <label
          className="input index-search__search-input"
          htmlFor="index-search"
        >
          <DebounceInput
            className="input__control"
            type="text"
            id="index-search"
            placeholder={placeholder ? placeholder : ''}
            value={value}
            minLength={1}
            debounceTimeout={800}
            autoComplete="off"
            onChange={(event) => handleChange(event.target.value)}
          />
          <span className="icon zoom-icon">
            <ZoomIcon />
          </span>

          <div className="index-search__tooltip">
            <button className="button button_view_outline-small">
              Исключить
            </button>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SearchFilter;
