import PanModal from '../../../core/PanModal';
import { useModal } from 'react-modal-hook';
import { getUrl } from '../../../../api';
import Button from '../../../core/Button';

import dateFormat from 'dateformat';
import { getYoutubeId } from '../../../../utils';
const VideoCard = ({
  speaker,
  duration,
  link,
  title,
  type,
  photo,
  speakerPost,
  previewText,
  presentation,
  activeFrom,
}) => {
  const [openModal, closeModal] = useModal(
    () => {
      const youtubeId = getYoutubeId(link);
      return (
      <PanModal title={'Видео'} closeModal={closeModal} isSmall>
        <iframe
          src={link.includes("https://rutube")? `${link.replace("/video/private/", "/play/embed/")}&autoplay=1&mute=1`: `https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
          controls
          autoPlay
        />

        <div className="sf__video-content">
          <div className="sf__video-header">
            <h3 className="h3-l sf__video-h3">{title}</h3>
            <span className={`sf__video-type text-xs-l${type.xmlId === 'webinar' ? ' sf__video-type_webinar' : ''}`}>{type.title}</span>
          </div>
          <div className="sf__video-body">
            {activeFrom && (
              <div className="sf__video-item">
                <p className="sf__video-key">Дата добавления:</p>
                <p className="h5-l">
                  {`${dateFormat(activeFrom, `dd mmmm HH:MM`, 'isoTime')} `}
                </p>
              </div>
            )}

            {speaker && (
              <div className="sf__video-item">
                <p className="sf__video-key">Спикер:</p>
                <p className="h5-l">
                  {`${speaker}${speakerPost ? ` - ${speakerPost}` : ''}`}
                </p>
              </div>
            )}

            {previewText && (
              <div
                className="sf__video-descr"
                dangerouslySetInnerHTML={{
                  __html: previewText,
                }}
              ></div>
            )}

            <div className="sf__video-btns">
              {presentation && (
                <Button
                  addClass="sf__video-btn"
                  buttonType="primary_gray"
                  isLink
                  isFull
                  to={presentation.src}
                  isDownload
                  target="_blank"
                >
                  Скачать презентацию
                </Button>
              )}

              <Button
                addClass="sf__video-btn"
                buttonType="line"
                isCopy
                isFull
                copy={link}
                target={'_blank'}
                download
              >
                Скопировать ссылку
              </Button>
            </div>
          </div>
        </div>
      </PanModal>
    )},
    [],
  );

  return (
    <section className="sf__video-card" onClick={openModal}>
      <div className="sf__video-image">
        <img src={getUrl(photo.src)} alt="" />
        <span className="sf__timing text-xs-l">{duration}</span>
      </div>

      <div className="sf__video-body">
        <h4 className="h4-l sf__video-title">{title}</h4>
        <span className={`sf__video-type text-xs-l${type.xmlId === 'webinar' ? ' sf__video-type_webinar' : ''}`}>{type.title}</span>
      </div>
    </section>
  );
};

export default VideoCard;
