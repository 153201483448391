import React, { useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import request from '../../api';
import SubmitButton from '../../components/SubmitButton';
import { standartErrorToast, successToast } from '../../components/toasts';
import { scrollToRef } from "../../utils";
import { getFormWithUTM } from '../../utils/location';
import './index.scss';

const progLearning = [
  {
    title: `Основам гражданского, земельного, жилищного, налогового и административного законодательства в сфере недвижимости`,
  },
  {
    title: `Методикам проведения правовой экспертизы в сделках с недвижимостью`,
  },
  {
    title: `Осуществлять комплексное сопровождение сделок с недвижимым имуществом`,
  },
  {
    title: `Ведению переговоров и структурирования сделок`,
  },
  {
    title: `Систематизации и учету, ведению документации о сделках с недвижимостью`,
  },

];

const CheckIcon1 = () => {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.61551L7.6041 13.1587L20.2536 0.588867" stroke="#44D89E" strokeMiterlimit="10" />
    </svg>
  );
};

const CheckIcon2 = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.0707" cy="22.7464" r="21.9686" fill="#ff2d23" />
      <path d="M13.4964 22.4881L20.1005 29.0313L32.75 16.4614" stroke="white" strokeMiterlimit="10" />
    </svg>
  );
};

const RieltorSchool = props => {
  const formRef = useRef();
  const userInfo = useSelector(state => state.user?.info)
  const [submitOnProcess, setSubmitOnProcess] = useState(false)
  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: {
      agree: true,
      name: userInfo?.name ? `${userInfo.name} ${userInfo.lastName}` : "",
      phone: userInfo?.phone || "",
    },
  });

  const onSubmit = data => {
    setSubmitOnProcess(true)
    const utm = {}
    Object.entries(getFormWithUTM(true)).forEach(([key, value]) => {
      utm[`utm[${key}]`] = value
    })
    const { name, phone, comment } = data
    request("panpartner:b24.ajax", "addPublicDeal", {
      comment: `${name} <br>
      ${phone} <br>
      ${comment}`,
      title: "Заявка на юридический курс",
      assignedId: 659,
      categoryId: 34,
      "fields[UF_DEAL_TELEGRAM_CHAT]": -1001795120003,
      ...utm
    }).then(() => {
      successToast("Заявка успешно отправлена")
      window.fbq('track', 'Lead');
      setValue("name", "")
      setValue("phone", "")
      setValue("comment", "")
    }, standartErrorToast).finally(() => setSubmitOnProcess(false))
  }


  return (
    <section className="section section_view_white">

      <section className="sor sor_rieltor rie legal">

        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">
                <span className="sor__sup">курсы</span>
                <h1 className="sor__h1 rie__title">Специалист по юридическому сопровождению сделок с недвижимостью</h1>
                <p className="sor__sign big-text">Программа разработана практикующими юристами и направлена на получение слушателями актуальных теоретических знаний и практических навыков, на основе двух специальностей: риелтор и юрист</p>

                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/legal/promo.png').default} alt="" className="sor__promo-img-lg" />
                  <img src={require('../../assets/images/legal/textSvg-1.svg').default} alt="" className="rie__promo-svg-1 rotating-reverse" />
                  <img src={require('../../assets/images/legal/textSvg-1.svg').default} alt="" className="rie__promo-svg-2 rotating" />
                </div>
              </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 0)}>Оставить заявку</button>
              </div>
            </div>
          </div>
        </header>

        <div className="sor__faq-section">
          <div className="wrapper">
            <div className="wrapper__center">
              <ul className="sor__faq">
                <li className="sor__faq-item">
                  <span className="sor__faq-title">длительность</span>
                  <p className="sor__faq-descr">4 недели <span>/ по субботам</span></p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">формат</span>
                  <p className="sor__faq-descr">Онлайн и офлайн интенсивы</p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">уровень</span>
                  <p className="sor__faq-descr">Новичок, средний</p>
                </li>
              </ul>
            </div>
          </div>

        </div>


        <div className="wrapper">
          <div className="wrapper__center">

            <section className="rie__about rie__section">
              <div className="rie__about-left">
                <img className="rie__about-img-left" src={require('../../assets/images/legal/rieltor-2.png').default} alt="" />
                <p className="rie__about-info">Чтобы быть в рынке, быстро адаптироваться в новых условиях, сохранять экспертные позиции и получать все преференции профессии, пройдите наш курс по юридическому сопровождению сделок с недвижимостью.</p>
              </div>

              <div className="rie__about-right">
                <img className="rie__about-img-right" src={require('../../assets/images/legal/icon.svg').default} alt="" />
                <p className="rie__about-text">Вы сможете зарабатывать больше, не бояться сложных сделок, мошенников на рынке недвижимости и «попадания» Вашего клиента на налоги</p>
              </div>
            </section>

            <section className="rie__learn rie__section">
              <h2 className="rie__title rie__section-title">Чему научитесь</h2>
              <div className="rie__learn-container">
                {progLearning.map((it) => (
                  <div className="rie__learn-item">
                    <CheckIcon2 />
                    <p className="rie__learn-title">{it.title}</p>
                  </div>))
                }

                {
                  progLearning.length / 3 !== 0 && (
                    <div className="rie__learn-item"></div>
                  )
                }
              </div>

            </section>


            <section className="rie__how rie__section">
              <h2 className="rie__title rie__section-title">Как проходит обучение</h2>
              <div className="rie__how-container">

                <aside className="rie__how-aside">
                  <img className="rie__how-img" src={require('../../assets/images/legal/rieltor-3.png').default} alt="" />
                  <button type="button" className="rie__how-btn button button_view_default" onClick={() => scrollToRef(formRef, 0)}>Оставить заявку</button>
                </aside>
                <section className="rie__how-list">
                  <div className="rie__how-item">
                    <p className="rie__how-title">01 / <b>Теоретическая часть</b></p>
                    <span className="rie__how-badge">Офлайн</span>
                    <p className="rie__how-text">Основные понятия необходимые для работы по сопровождению сделок с недвижимостью</p>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">02 / <b>Сделки с несовершеннолетними</b></p>
                    <span className="rie__how-badge">Онлайн</span>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">03 / <b>Недействительные сделки</b></p>
                    <span className="rie__how-badge">Офлайн</span>
                    <p className="rie__how-text">Оспоримые и ничтожные сделки. Проблемы оспаривания сделок.</p>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">04 / <b>Материнский капитал, банкротство</b></p>
                    <span className="rie__how-badge">Онлайн</span>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">05 / <b>Организация проведения сделки купли - продажи</b></p>
                    <span className="rie__how-badge">Офлайн</span>
                    <ul>
                      <li>Договор купли-продажи. Подготовка к сделке.</li>
                      <li>Условия заключения договоров купли-продажи.</li>
                      <li>Формы наличных и безналичных расчетов.</li>
                      <li>Порядок взаимодействия по регистрации права собственности.</li>
                      <li>Электронная регистрация сделок с недвижимостью.</li>
                    </ul>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">06 / <b>Налоги. Основные понятия</b></p>
                    <span className="rie__how-badge">Офлайн</span>
                    <ul>
                      <li>Имущественные налоговые вычеты</li>
                      <li>Кто может получить налоговый вычет</li>
                      <li>Кто не может получить налоговый вычет</li>
                    </ul>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">07 / <b>Итоговая аттестация</b></p>
                    <span className="rie__how-badge">Офлайн</span>
                  </div>
                </section>
              </div>
            </section>

          </div>
        </div>

        <section className="rie__banner">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="rie__banner-container">
                <div className="rie__banner-content">
                  <div>
                    <span className="rie__banner-percent">15 000 р.</span>
                    <p className="rie__banner-text">Стоимость обучения</p>
                  </div>
                  <div className="rie__banner-box">
                    <p className="rie__banner-title">Для тех, кто прошел программу скидка на дополнительный пакет обучения с получением диплома о&nbsp;повышении квалификации</p>

                    <img className="rie__banner-img" src={require('../../assets/images/rie/banner.svg').default} alt="" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sor__footer" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="h2 sor__h2 sor__footer-title rie__footer-title">Получите консультацию</h2>
              </div>
              <form action="#" className="sor__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="sor__form-box">
                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-name"
                    >
                      <span className="input__label">Имя</span>
                      <input
                        id="modal-reservation-name"
                        name="name"
                        className={
                          "input__control" + (errors.name ? " input__error" : "")
                        }
                        placeholder="Ваше имя"
                        ref={register({
                          required: "Введите имя",
                        })}
                      />
                      {/* {errors.name && (
                        <span className="input__error-label">
                          {errors.name.message}
                        </span>
                      )} */}
                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-phone"
                    >
                      <span className="input__label">Телефон</span>
                      <Controller
                        as={
                          <InputMask
                            id="modal-reservation-phone"
                            className={
                              'input__control' +
                              (errors.phone ? ' input__error' : '')
                            }
                            placeholder="Ваш телефон"
                            mask="+7 (999) 999-99-99"
                            autoComplete="off"
                            maskChar=" "
                          />
                        }
                        name="phone"
                        control={control}
                        rules={{
                          required: 'Введите телефон',
                        }}
                      />
                      {/* {errors.phone && (
                        <span className="input__error-label">
                          {errors.phone.message}
                        </span>
                      )} */}
                    </label>
                  </div>


                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-message"
                    >
                      <span className="input__label">Комментарий</span>
                      <input
                        id="modal-reservation-message"
                        name="comment"
                        className={
                          "input__control" + (errors.name ? " input__error" : "")
                        }
                        placeholder="Текст комментария"
                        ref={register}
                      />
                      {errors.comment && (
                        <span className="input__error-label">
                          {errors.comment.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <SubmitButton
                    type="submit"
                    className="sor__submit button button_view_default"
                    isLoading={submitOnProcess}
                  >
                    Оставить заявку
                  </SubmitButton>
                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box' +
                        (errors.agree ? " checkbox__box-error" : "")
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        ref={register({
                          required: "Необходимо согласиться с правилами обработки данных",
                        })}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>

                  </div>
                  {errors.agree && (
                    <span className="input__error-label">
                      {errors.agree.message}
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>

      </section>
    </section>
  );
};

export default RieltorSchool