import _keys from 'lodash/keys';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import useMedia from '../../hooks/useMedia';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, restorePassword, testLogin } from '../../actions/user';
import useTimer from '../../hooks/useTimer';
import { restoreLastPath } from '../../utils';
import Modal from '../core/Modal';
import Forgot from '../forms/Forgot';
import SubmitButton from '../SubmitButton';
import { errorToast, successToast } from '../toasts';
import BannerThin from './BannerThin';
import DotsSVG from './DotsSVG';
import DownloadApp from '../core/DownloadApp';
import useDownloadApp from '../../hooks/useDownloadApp';

const LoginContainer = (props) => (
  <Login
    {...props}
    key={props?.history?.location?.state?.fromLogin + Date.now()}
  />
); //быстрое решение чтобы кнопочка войти работала

const Login = (props) => {
  const { login, history, fetching, error, location, testLogin, user } = props;
  const { handleSubmit, register, errors, control, getValues } = useForm({
    defaultValues: {
      phone: '',
      email: '',
    },
  });
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isFetching, setIsFetching] = useState(fetching);
  const [paswordIsSended, setPaswordIsSended] = useState(false);
  const { timeout, timerIsStarted, runTimer } = useTimer(60);
  const [phoneSaved, setPhoneSaved] = useState(null);
  const [openForgotModal, closeForgotModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeForgotModal} classes="modal_small pass-recover">
        <Forgot />
      </Modal>
    ),
  );

  const onError = (error) => {
    setIsFetching(false);
    errorToast(error[0]?.message);
  };
  const onSuccess = (data) => {
    setErrorMessage(false);
    setIsFetching(false);
    successToast(data.message);
  };

  const startRestoreByPhone = (data) => {
    let phone = data || getValues('login') || phoneSaved;
    setPhoneSaved(phone);
    setPaswordIsSended(true);
    runTimer();
  };
  const [loginType, setLoginType] = useState(null);

  const isMobile = useMedia('isMobile');

  const restore = () => {
    let login = getValues('login') || phoneSaved;
    restorePassword({ login }).then((data) => {
      onSuccess(data);
      setLoginType(data.type);
      if (data.type === 'phone') {
        startRestoreByPhone(login);
        return;
      }
      history.replace({ pathname: 'welcome' });
    }, onError);
  };

  const onSubmit = (values, isTest) => {
    setIsFetching(true);
    setDownloadApp(false);

    if (!paswordIsSended && isForgotPass) {
      restore();
      return;
    }

    let form = new FormData();
    if (isTest) {
      testLogin().then(
        () => {
          setIsFetching(false);
          history.replace({ pathname: restoreLastPath(user.history) });
        },
        (errorMessage) => {
          setErrorMessage(errorMessage);
          setIsFetching(false);
        },
      );
      return;
    } else {
      let keys = _keys(values);
      keys.forEach((item) => {
        if (item === 'remember') {
          form.append(`${item}`, values[item] ? 'Y' : 'N');
          return;
        }
        form.append(`${item}`, values[item]);
      });
    }
    if (paswordIsSended && !form.get('login')) {
      form.append('login', phoneSaved);
    }
    login(form).then(
      () => {
        setIsFetching(false);
        history.replace({ pathname: restoreLastPath(user.history) });
      },
      (errorMessage) => {
        setErrorMessage(errorMessage);
        setIsFetching(false);
      },
    );
  };

  const ua = window.navigator.userAgent;
  const isAndroid = /android/i.test(ua);


  const [downloadApp, setDownloadApp] = useDownloadApp();

  return (
    <>
      <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} />
      {!downloadApp && (
        <section className="section preview__auth">
          <div className="wrapper">
            <div className="auth__content">
              <div className="auth__title preview__auth-title preview__auth-title_nomob">
                <h1 className="h1">
                  {isForgotPass ? 'Восстановление пароля' : 'Войти'}
                </h1>
              </div>

              <div className="preview__mobile-tabs">
                <button
                  type="button"
                  className="button preview__mobile-tab preview__mobile-tab_active"
                >
                  Войти
                </button>
                <Link to={`/register`} className="button preview__mobile-tab">
                  Зарегистрироваться
                </Link>
              </div>

              {/* { Приложение ПАН Партнер

              (isMobile && isAndroid) && 
              <a href="https://play.google.com/store/apps/details?id=com.panmobile" target="_blank" className="preview__app-download">
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0998 2.98547H5.23981C4.81235 2.98547 4.46582 3.332 4.46582 3.75946V15.823C4.46582 16.2504 4.81235 16.597 5.23981 16.597H13.0998C13.5272 16.597 13.8738 16.2504 13.8738 15.823V3.75946C13.8738 3.332 13.5272 2.98547 13.0998 2.98547Z" stroke="white" strokeMiterlimit="10"/>
                  <path d="M2.73127 14.155H2.02401C1.81873 14.155 1.62186 14.0735 1.47671 13.9283C1.33156 13.7832 1.25 13.5863 1.25 13.381V1.37089C1.25 1.26925 1.27003 1.16861 1.30893 1.0747C1.34782 0.980799 1.40484 0.895488 1.47671 0.823616C1.54858 0.751745 1.63392 0.694728 1.72782 0.655832C1.82172 0.616935 1.92237 0.596924 2.02401 0.596924H9.87063" stroke="white" strokeMiterlimit="10"/>
                  <path d="M7.82886 14.1549H10.4978" stroke="white" strokeMiterlimit="10"/>
                </svg>

                <span>Приложение <b>ПАН Партнер</b></span>

                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.5 7.59692L14.5 12.5969L9.5 17.5969" stroke="white" strokeLinecap="round"/>
                </svg>

              </a>
            } */}

              <div className="preview__auth-dots">
                <DotsSVG />
              </div>
              <div className="auth__form preview__auth-form">
                <div className="auth__sign-in">
                  <div className="sign-in">
                    <form
                      className="form"
                      name="sign-in"
                      onSubmit={handleSubmit((val) => onSubmit(val))}
                    >
                      {!isForgotPass && (
                        <div className="sign-in__title">
                          {paswordIsSended
                            ? 'Введите полученный пароль'
                            : 'Введите свои данные, если Вы наш партнер'}
                        </div>
                      )}
                      {isForgotPass && paswordIsSended && (
                        <div className="sign-in__title">
                          Введите полученный пароль
                        </div>
                      )}
                      {error && (
                        <div className="form__error-text">
                          {errorMessage ||
                            `Неверный логин пользователя или пароль`}
                        </div>
                      )}
                      {location.state && location.state.referrer && (
                        <div className="form__info-text">
                          Ваш пароль был успешно изменен
                        </div>
                      )}
                      {isForgotPass && (
                        <div
                          className="form__info-text"
                          style={{
                            backgroundColor: 'unset',
                            textAlign: 'center',
                          }}
                        >
                          Для восстановления введите телефон или E-mail, который
                          был указан при регистрации
                        </div>
                      )}
                      <div className="sign-in__tab">
                        <div className="sign-in__tab-content">
                          {!paswordIsSended && (
                            <div className="sign-in__row">
                              <div className="sign-in__input">
                                <label
                                  className="input input_width_available input_type_form"
                                  htmlFor="sign-in_email"
                                >
                                  <input
                                    name="login"
                                    placeholder="Ваш e-mail или телефон"
                                    className={
                                      'input__control' +
                                      (errors.email ? ' input__error' : '')
                                    }
                                    ref={register({
                                      required: 'Введите e-mail или телефон',
                                    })}
                                  />
                                  {errors.login && (
                                    <span className="input__error-label">
                                      {errors.login.message}
                                    </span>
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                          {(!isForgotPass || paswordIsSended) && (
                            <div className="sign-in__row">
                              <div className="sign-in__input">
                                <label
                                  className="input input_width_available input_type_form"
                                  htmlFor="sign-in_password"
                                >
                                  <input
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    className={
                                      'input__control' +
                                      (errors.password ? ' input__error' : '')
                                    }
                                    id="sign-in_password"
                                    ref={register({
                                      required: 'Введите пароль',
                                    })}
                                  />

                                  {errors.password && (
                                    <span className="input__error-label">
                                      {errors.password.message}
                                    </span>
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!timerIsStarted &&
                        isForgotPass &&
                        loginType === 'phone' &&
                        paswordIsSended && (
                          <div className="sign-in__row">
                            <span className="preview__anchor" onClick={restore}>
                              Запросить новый пароль
                            </span>
                          </div>
                        )}
                      {timerIsStarted &&
                        isForgotPass &&
                        loginType === 'phone' && (
                          <div className="sign-in__row preview__countdown">
                            <p>
                              Запросить новый пароль можно будет через:{' '}
                              <span className="preview__counter">
                                0:{timeout < 10 && '0'}
                                {timeout}
                              </span>
                            </p>
                          </div>
                        )}
                      {!isForgotPass && (
                        <div className="sign-in__row">
                          <div className="sign-in__remember">
                            <div className="checkbox checkbox_view_invert">
                              <div className="checkbox__box">
                                <input
                                  className="checkbox__control"
                                  type="checkbox"
                                  id="sign-in_remember"
                                  name="remember"
                                  ref={register}
                                />
                                <label
                                  className="checkbox__input"
                                  htmlFor="sign-in_remember"
                                ></label>
                                <div className="checkbox__marker"></div>
                              </div>
                              <label
                                className="checkbox__label"
                                htmlFor="sign-in_remember"
                              >
                                Запомнить меня
                              </label>
                            </div>
                          </div>
                          <div className="sign-in__forgot">
                            <div
                              className="link-hover"
                              onClick={() => setIsForgotPass(true)}
                            >
                              Забыли пароль?
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="sign-in__row">
                        <div className="sign-in__submit">
                          <SubmitButton
                            className="button button_view_default"
                            isLoading={isFetching}
                            type="submit"
                          >
                            <span>
                              {isForgotPass && !paswordIsSended
                                ? 'Отправить'
                                : 'Войти'}
                            </span>
                          </SubmitButton>
                        </div>
                        {(window.location.host === 'dev2.panpartner.ru' ||
                          window.location.host === 'localhost:3000') && (
                          <div className="sign-in__submit">
                            <SubmitButton
                              className="button button_view_default"
                              isLoading={isFetching}
                              type="button"
                              onClick={() => onSubmit(null, true)}
                            >
                              <span>Тестовый доступ</span>
                            </SubmitButton>
                          </div>
                        )}
                      </div>
                      {!isForgotPass && (
                        <div className="sign-in__row">
                          <div className="sign-in__footer">
                            Еще нет аккаунта?{' '}
                            <Link to={`/register${window.location.search}`}>
                              <span className="sign-in__sign-up link-hover">
                                Зарегистрироваться
                              </span>
                            </Link>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="preview__auth-banner">
              <BannerThin />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (form) => dispatch(login(form)),
  testLogin: () => dispatch(testLogin()),
});

const mapStateToProps = (state) => ({
  user: state.user,
  fetching: state.user.fetching,
  error: state.user.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
