import uniq from 'lodash/uniq';
import without from "lodash/without";
import React, {
  // memo, 
  useEffect,
  useMemo,
  useState
} from "react";
import { Link } from "react-router-dom";
import request from '../../../../api';
import useMedia from '../../../../hooks/useMedia';
import DropdownCustomColor from "../../../core/DropdownCustomColor";
import EmptyTable from '../../../core/EmptyTable';
import FilterBadge from "../../../core/FilterBadge";
import SelectFilter from "../../../FilterRealty/SelectFilter";
import Preloader from '../../../Preloader';
import { standartErrorToast, successToast, warningToast } from "../../../toasts";
// import Filter from '../../Presentations/Filter';
import Items from './Items';
import mocItemsData from './mocItemsData.js';
const getObjects = form => request("panpartner:self.objects.ajax", "getObjects", form)

const getClients = form => request("panpartner:client.edit.form", "getClients", form)

const getFilterByDealType = dealType => ({
  [`filter[${dealType === "rent" ? "saleType" : "objectType"}]`]: dealType,
  ...(dealType !== "rent" ? { "filter[saleType]": "sale" } : [])
})

const attachClient = (form, isActive) => request("panpartner:self.objects.ajax", isActive ? "addClientToObject" : "removeClientFromObject", form);

const changeStatus = form => request("panpartner:self.objects.ajax", "changeStatus", form);

const getFilter = form => request("panpartner:self.objects.ajax", "getFilter", form);

const filterToParams = filter => {
  const result = {}
  for (const key in filter) {
    result[`filter[${key}]`] = filter[key]
  }
  return result
}

const ObjectsTable = () => {
  const [isLoading, setisLoading] = useState(true);
  const [filter, setFilter] = useState({})
  const [filterData, setFilterData] = useState(null)
  const [clients, setClients] = useState([])
  const [data, setData] = useState({})
  const [statuses, setStatuses] = useState([])
  const [status, setStatus] = useState([])
  const [selectedObjects, setSelectedObjects] = useState([])
  const [site, setSite] = useState({})
  const [dealType, setDealType] = useState("assignment")
  const [error, setError] = useState(false)
  const isMobile = useMedia("isMobile");
  useEffect(() => {
    getObjects({
      isGetFilter: true,
      ...getFilterByDealType(dealType)
    })
      .then(resp => {
        setData(resp.objects)
        setSite(resp.site)
        setStatuses(resp.statuses)
        setStatus(resp.statuses?.[0]?.id)
        setFilterData(resp.filter)
        if(resp.warnings){
          warningToast(resp.warnings?.[0]?.message);
        }
      },
      error => setError(true))
      .finally(() => setisLoading(false))
    getClients().then(resp => setClients(resp.clients))
  }, [])

  useEffect(() => {
    if (!filterData) return
    getObjects({
      isGetFilter: false,
      ...filterToParams(filter),
      ...getFilterByDealType(dealType),
    })
      .then(resp => {
        setData(resp.objects)
        setSelectedObjects(prev => (prev.filter(id => resp.objects.some(object => object.id == id))))
      })
  }, [dealType, filter])

  const statusKey = useMemo(() => dealType === "rent" ? "rentTitle" : "title", [dealType])

  const filterDataByType = useMemo(() => {
    if (!filterData) return {}
    const result = {}
    for (const [key, values] of Object.entries(filterData)) {
      result[key] = []
      for (const item of values) {
        if (item.type.includes(dealType)) {
          result[key].push(item)
        }
      }
    }
    return result
  }, [dealType, filterData])
  
  const handleRemoveFilter = (value, ident) => setFilter(prev => ({ ...prev, [ident]: prev[ident].filter(item => item != value) }))

  const renderBadges = useMemo(() => {
    const entries = Object.entries(filter)
    if (entries.length === 0 || !filterData) return []
    const titles = {
      type: "Тип",
      client: "Клиент",
      status: "Статус"
    }
    const result = []
    for (const [key, value] of entries) {
      for (const subvalue of value) {
        const item = filterData[key].find(item => item.id == subvalue)
        result.push(<FilterBadge
          key={`${key}-${item.id}`}
          identity={key}
          item={item}
          title={titles[key]}
          handleClick={handleRemoveFilter}
        />)
      }
    }
    return result
  }, [filter, filterData])

  const fetchFilterData = () => getFilter().then(resp => setFilterData(resp.filter))

  const handleFilter = (value, ident) => setFilter(prev => ({ ...prev, [ident]: value }))

  const selectItems = id => setSelectedObjects(prev => uniq(prev.concat(id))) 
  const deselectItems = id => setSelectedObjects(prev => without(prev, ...(Array.isArray(id) ? id : [id])))
  const toggleItems = (id, checked) => checked ? selectItems(id) : deselectItems(id)

  const handleAttachClient = (form, isActive) => attachClient(form, isActive).then(data => {
    const { clientId, objectId } = form
    const client = clients.find(client => client.id === clientId)
    setData(prev => prev.map(item => {
      if (item.id == objectId) {
        item.clients = isActive ? [client] : []
      }
      return item
    }))
    fetchFilterData()
    return data
  })

  const changeDealTypes = deal => {
    if (deal === dealType) return
    setDealType(deal)
    setSelectedObjects([])
  }
  const changeStatusMultiple = (id, status) => {
    changeStatus({ id, status }).then(() => {
      const selectedStatus = statuses.find(statusItem => statusItem.id === status)
      setData(prev => prev.map(item => {
        const condition = (Array.isArray(id) && id.includes(item.id)) || id === item.id
        if (condition) {
          item.status = selectedStatus
        }
        return item
      }))
      fetchFilterData()
      successToast(`Статус объект${id.length === 1 ? "а" : "ов"} обновлен`)
    }, standartErrorToast)
  }

  if (isLoading) return <Preloader loading={true} />;
  if(error) return <EmptyTable title="Чтобы добавить свои объекты, опубликуйте сайт" />;


  return (
    <div className="constructor aa ot">
      <section className="constructor__main for-admin">
        <section className="admin constructor__section ot__section">
          <div className="constructor__container">
            <div className="admin__wrapper constructor__wrapper">
              <div className="admin__header-wrapper">
                <div className="admin__header">
                  <h1 className="h1 admin__h1">Свои объекты</h1>
                  <div className="ot__btns">
                    <div class="aa__switcher ot__switcher switcher">
                      <button
                        type="button"
                        className={`button switcher__btn ${dealType === "assignment" ? "active" : ""}`}
                        onClick={() => changeDealTypes("assignment")}
                      >Переуступки
                      </button>
                      <button
                        type="button"
                        className={`button switcher__btn ${dealType === "secondary" ? "active" : ""}`}
                        onClick={() => changeDealTypes("secondary")}
                      >Вторичка
                      </button>
                      <button
                        type="button"
                        className={`button switcher__btn ${dealType === "rent" ? "active" : ""}`}
                        onClick={() => changeDealTypes("rent")}
                      >
                        Аренда
                      </button>
                    </div>
                  </div>

                  {
                    !isMobile && (
                      <Link
                        to="/objects/add"
                        className="button button_view_default ot__add-obj"
                      >
                        + Добавить объект
                      </Link>
                    )
                  }


                </div>
              </div>

              <div className="ot__filter">
                <div className="mini-filter">
                  <form className="form">
                    <div className="mini-filter__grid">
                      <div className="mini-filter__col">
                        <SelectFilter
                          onChange={(a, b) => handleFilter(a, "type")}
                          isExcludable={false}
                          items={filterDataByType.type ? filterDataByType.type : []}
                          filterValues={filter.type}
                          excludeValues={[]}
                          data={{
                            title: "Тип объекта",
                            identity: "type",
                          }}
                        />
                      </div>
                      <div className="mini-filter__col">
                        <SelectFilter
                          onChange={(a, b) => handleFilter(a, "client")}
                          isExcludable={false}
                          isAutocomplete={true}
                          items={filterDataByType.client ? filterDataByType.client : []}
                          filterValues={filter.client}
                          excludeValues={[]}
                          data={{
                            title: "Клиенты",
                            autocompleteTitle: "Введите имя клиента",
                            identity: "client",
                          }}
                        />
                      </div>
                      <div className="mini-filter__col">
                        <SelectFilter
                          onChange={(a, b) => handleFilter(a, "status")}
                          isExcludable={false}
                          items={filterDataByType.status ? filterDataByType.status : []}
                          filterValues={filter.status}
                          excludeValues={[]}
                          data={{
                            title: "Статус",
                            identity: "status",
                          }}
                        />
                      </div>
                      <div className="mini-filter__col mini-filter__col_clear"><button
                        className="button button_type_clear mini-filter__button"
                        type="button"
                        onClick={() => setFilter({})}
                      ><svg className="icon icon_x">
                          <use xlinkHref="#x" />
                        </svg><span>Очистить фильтр</span></button></div>
                    </div>
                  </form>
                  <div class="index-search__filter-applied">
                    <div class="index-search__filter-inc no-margin">
                      {renderBadges}
                    </div>
                  </div>
                </div>
              </div>


              <div className={`ot-table${isMobile ? ` table2` : ``}`}>
                <Items
                  items={data}
                  handleDelete={mocItemsData.handleDelete}
                  clients={clients}
                  handleAttachClient={handleAttachClient}
                  onClick={mocItemsData.onClick}

                  toggleItems={toggleItems}
                  selectedItems={selectedObjects}
                  fetchClients={() => getClients().then(resp => setClients(resp.clients))}
                  statuses={statuses}
                  statusTitleKey={statusKey}
                  changeStatus={changeStatusMultiple}
                  domain={site?.domain}
                  dealType={dealType}

                />
              </div>

              {!!selectedObjects.length &&
                <div className="ot-change-status">
                  <div className="ot-change-status__item">
                    <b>Сменить статус</b>
                    <DropdownCustomColor
                      options={statuses}
                      value={status}
                      onChange={setStatus}
                      titleKey={statusKey}
                      openTop
                    />
                  </div>

                  <button
                    type="button"
                    className="button button_view_grey"
                    onClick={() => changeStatusMultiple(selectedObjects, status)}
                  >Применить</button>
                </div>}

                {
                  isMobile && (
                    <Link
                      to="/objects/add"
                      className="button button_view_default ot__add-obj"
                    >
                      + Добавить объект
                    </Link>
                  )
                }
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};


export default ObjectsTable;