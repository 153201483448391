import React, { useState } from "react";
import useDropdown from "../../hooks/useDropdown";

const Dropdown = ({ value, options, onChange }) => {
  const [node, open, show, hide] = useDropdown(false);

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    hide();
  };

  if (!options.length) return "";

  const current = value || options[0];


  return (
    <div class="select select_type_table">
      <div ref={node} className={`custom-select ${open ? `is-showing` : ``}`}>
        <div
          className="custom-select__selected"
          onClick={(e) => (open ? hide() : show())}
        >
          {current && <span className={`label-item ${current.label}`}>{current.title}</span>}
        </div>
        <ul className="custom-select__options">
          {options.map((item) => (
            <li
              className={
                "custom-select__option" +
                (current.id === item.id ? " is-active" : "")
              }
              key={item.id}
              onClick={() => handleChange(item.id)}
            >
              <span className={`label-item ${item.label}`}>{item.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
