export const selfData = [
  {
    id: 0,
    header: `Личный сайт-витрина с объектами недвижимости`,
    list: [
      {
        icon: `01`,
        title: `Профессиональная платформа`,
        text: `Создайте современный сайт риелтора с базой недвижимости — всего за пару минут и без команды разработчиков`,
      },
      {
        icon: `02`,
        title: `Собственная база недвижимости`,
        text: `Добавляйте свои объекты, создавайте персональные подборки, делитесь ими в соцсетях и отправляйте клиентам`,
      },
      {
        icon: `03`,
        title: `Встроенные инструменты`,
        text: `Есть все для удобной и легкой работы: ипотечный калькулятор, готовые шаблоны текстов для лид-магнита и не только`,
      },
    ],
  },

  {
    id: 1,
    header: `Доступ к закрытой базе недвижимости Петербурга и Москвы`,
    list: [
      {
        icon: `04`,
        title: `Все виды недвижимости`,
        text: `Новостройки, вторичка, переуступки, апартаменты, коммерческая и загородная недвижимость`,
      },
      {
        icon: `05`,
        title: `Всегда актуальная информация`,
        text: `Необходимая информация о проектах всегда под рукой: стоимость, условия продаж, планировки, описание проектов, буклеты и презентации`,
      },
      {
        icon: `06`,
        title: `Онлайн-бронирование`,
        text: `Бронируйте объекты 24/7 — в удобное для вас время`,
      },
    ],
  },

  {
    id: 2,
    header: `Скидки до 50% — на покупку объявлений`,
    list: [
      {
        icon: `07`,
        title: `Экономьте больше`,
        text: `Разместить объявления на Авито будет проще и намного дешевле!`,
      },
      {
        icon: `08`,
        title: `Управляйте легче`,
        text: `Экономьте на покупке объявлений, а разобраться со счетами поможет личный менеджер`,
      },
    ],
  },

  {
    id: 3,
    header: `Сертификат эксперта рынка недвижимости`,
    list: [
      {
        icon: `09`,
        title: `Повышайте компетенции`,
        text: `Хотите зарабатывать больше — пройдите обучение в школе риелторов под руководством опытных профессионалов рынка!`,
      },
      {
        icon: `10`,
        title: `Полноценный курс о том, что важно знать каждому риелтору`,
        text: `Все про объекты, застройщиков, ипотеку, юридические вопросы, а также психология общения с клиентами и секреты успешных продаж`,
      },
      {
        icon: `11`,
        title: `Теория и практика`,
        text: `Онлайн и оффлайн-занятия, экскурсии на объекты, учебники, скрипты, чек-листы и домашние задания`,
      },
      {
        icon: `12`,
        title: `Сертификат государственного образца`,
        text: `После успешной сдачи экзамена вы получаете сертификат, подтверждающий повышение квалификации`,
      },
    ],
  },

  {
    id: 4,
    header: `Безопасные, юридически чистые сделки`,
    list: [
      {
        icon: `13`,
        title: `Работайте без рисков`,
        text: `С помощью практикующих юристов по недвижимости`,
      },
      {
        icon: `14`,
        title: `Получите решение любого вопроса`,
        text: `От составления договоров и юридической проверки документов — до оптимизации НДФЛ, ведения дел в суде и сделок под ключ`,
      },
    ],
  },
];