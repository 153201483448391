import find from "lodash/find";
import request from "../api";
import { actionFactory } from "./actionUtils";

const component = "panpartner:client.edit.form";

// TO DO  большинство запросов для clients используются без редакса, поэтому надо подчистить
const requestClients = actionFactory("CLIENTS_REQUEST");

const successClients = actionFactory("CLIENTS_SUCCESS");

const setFavoriteClients = actionFactory("CLIENTS_SET_FAVORITE");

const removeClients = actionFactory("CLIENTS_REMOVE");

const removeTasks = actionFactory("CLIENTS_TASKS_REMOVE");

const addTask = actionFactory("CLIENTS_TASKS_ADD");

const editTask = actionFactory("CLIENTS_TASKS_EDIT");

export const clearClients = actionFactory("CLIENTS_RESET")

const failureClients = actionFactory("CLIENTS_FAILURE");

export const setFilterClients = filter => ({
  type: "CLIENTS_SET_FILTER",
  filter,
});

export const clearFilterClients = actionFactory("CLIENTS_CLEAR_FILTER");

const addUserClient = actionFactory("ADD_USER_CLIENT");
// вот это всё надо рефакторить, лишние фильтры и прочее говно они не используются #оптимизация
export const fetchClients = (city) =>
  dispatch => {
    dispatch(requestClients());
    return request(component, "getClients", { city: "spb" })
      .then(
        data => {
          let { filterData, items, deals } = handleFilter(data);
          dispatch(
            successClients({
              data: items,
              filterData: filterData,
              deals: deals,
              statuses: data.statuses,
              agents: data.agents? data.agents.map(a=>({...a, title: `${a.lastName} ${a.name}`})): []
            })
          );
        },
        error => {
          dispatch(failureClients());
          return Promise.reject(error);
        }
      );
  };

//TO DO отрефакторить за Олегом
const handleFilter = (data) => {
  let agents = [];
  let statuses = [];
  let clients = [];
  let items = [];
  let dates = [];
  data.agents.map(({ id, name, lastName }) => {
    let title = `${name}${lastName ? ` ${lastName}` : ``}`;
    agents.push({
      id: id,
      title: title,
    });
  });
  data.statuses.map(({ id, name, color }) => {
    statuses.push({
      id: id,
      title: name,
      color: color,
    });
  });
  items = data.clients.map((item) => {
    let title = `${item.name}${item.lastName ? ` ${item.lastName}` : ``}`;

    clients.push({
      id: item.id,
      title: title,
    });

    let iDate = new Date(item.date).setHours(0, 0, 0, 0);

    dates.push(iDate);
    let searchAgent = find(agents, (agent) => agent.id == item.agent);
    let search = [
      item.name,
      item.lastName,
      item.patronymic,
      ...item.phone,
      ...item.email,
      searchAgent ? searchAgent.title : false,
    ];
    search = search.map((text) => text?.toLowerCase?.());

    return { ...item, search: search, date: iDate };
  });

  let deals = [];

  let minMax = {
    min: new Date(Math.min(...dates)),
    max: new Date(Math.max(...dates)),
  };

  return {
    filterData: {
      agents: agents,
      statuses: statuses,
      clients: clients,
      dates: minMax,
    },
    items: items,
    deals: deals,
  };
};

export const handleFavoriteClients = (id, active) =>
  dispatch =>
    request("panpartner:favorite.ajax", active ? "addToFavorite" : "removeFromFavorite",
      {
        city: "spb",
        id,
        type: "client"
      }).then(
        data => {
          dispatch(setFavoriteClients({ id, active }));
          return Promise.resolve(data);
        },
      );

export const removeClient = clientId =>
  dispatch =>
    request(component, "removeClient", {
      city: "spb",
      clientId
    }).then(
      data => {
        dispatch(removeClients({ id: clientId }));
        return Promise.resolve(data);
      },
    );

export const editClients = form =>
  dispatch =>
    request(component, "editClient", form)
      .then(
        data => {
          let client = {
            id: data.id,
            name: form.get("name"),
            lastName: form.get("lastName"),
          };
          dispatch(addUserClient({ data: client }));
          return Promise.resolve(data);
        },
      );

// хотите охуеть? посмотрите как используется эта функция и сколько раз прокидывается. Нахуя так сделали - непонятно
export const editDeals = form => {
  let id = form.get("id");
  let clientId = form.get("clientId");
  form.append("city", "spb")
  return (dispatch) => request(component, "editDeal", form)
    .then(
      data => {
        const deal = data.deal
        if (id) {
          dispatch(editTask({ id, clientId, deal }));
        } else {
          dispatch(addTask({ clientId, deal }));
        }
        return Promise.resolve(data);
      },
    );
};

export const removeTask = (id, clientId) =>
  dispatch =>
    request(component, "removeDeal", { city: "spb", id, clientId })
      .then(
        (data) => {
          dispatch(removeTasks({ id, clientId }));
          return Promise.resolve(data);
        },
      );

export const getClientData = () => request(component, "getAgents");

export const getClient = (clientId) => request(component, "getClient", { city: "spb", clientId });

export const setClientStatus = (id, status) => ({
  type: "CLIENTS_SET_STATUS",
  data: { id, status },
});

export const handleClientStatus = (id, status) =>
  dispatch => request(component, "setStatus", { id, status })
    .then(
      (data) => {
        dispatch(setClientStatus(id, status));
        return Promise.resolve(data);
      },
    );

export const addLeadAction = form => request("panpartner:b24.ajax", "addLead", form);

export const addLeadActionPublic = form => request("panpartner:b24.ajax", "addPublicLead", form);

export const exportClients = params => request(component, "exportClients", params, false, { responseType: 'blob', withoutHandling: true })