import React from "react";
import Photos from "../Complex/Photos";


const Sliders = (props) => {
  const { data } = props;
  const photos = { ...data.block.photos }
  const photosComplex = {
    complex: [...(photos.complex? photos.complex: [])],
    ...(!!data.block.aeroPanorama ? { aeroPanorama: data.block.aeroPanorama } : [])
  }
  delete photos.complex
  const isObjectHaveValues = obj => Object.values(obj).reduce((acc, current)=>!!current.length? acc +  1 : acc, 0)

  const haveDecoration = isObjectHaveValues(photos)
  const haveComplexPhotos = isObjectHaveValues(photosComplex)

  return (
    <>
      {haveComplexPhotos && <div
        className="section section_view_white complex__section-about"
      >
        <div className="wrapper">
          <div className="wrapper__center">
            <div className="flat__grid">
              <div className="flat__col flat__col_width_100 flat__col-slider">
                <div class={`section__title flat__slider-title${haveComplexPhotos > 1 ? ` flat__slider-title_absolute` : ``}`}>О комплексе</div>
                <Photos
                  photos={photosComplex}
                  tour={data.builder.typical3DTour}
                  addClass={`assignation-photos`}
                  blurBg
                  customIdent="about"
                  disableIcons={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>}

      {!!haveDecoration && <div
        className="section section_view_white complex__section-about"
      >
        <div className="wrapper">
          <div className="wrapper__center">
            <div className="flat__grid">
              <div className="flat__col flat__col_width_100 flat__col-slider">
                <div class={`section__title flat__slider-title${haveDecoration > 1 ? ` flat__slider-title_absolute` : ``}`}>Отделка</div>
                <Photos photos={photos} addClass={`assignation-photos`} blurBg customIdent="decoration" />
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Sliders;
