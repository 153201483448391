import React, { useEffect, useState } from 'react';
import { useModal } from "react-modal-hook";
import useMedia from '../../../hooks/useMedia';
import Modal from "../../core/Modal";

const Item = ({ item, i, ident, modalTitle, changeHandler, withoutModal }) => {
    const [title, setTitle] = useState(item.title)
    const [text, setDescription] = useState(item.text)
    const handleSubmit = () => {
        changeHandler({ ...item, text, title, default: false })
        closeServices()
    }
    const [openServices, closeServices] = useModal(({ in: open, onExited }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal" closeByPastClick close={closeServices}>

            <button type="button"
                className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                aria-label="Закрыть окно"
                onClick={closeServices}
            >
                <span className="c-modal__icon">
                    <svg className="icon icon_cross">
                        <use xlinkHref="#cross" />
                    </svg>
                </span>
            </button>

            <div className="c-modal__box">
                <p className="c-modal__header">Редактировать {modalTitle}</p>
                <form action="#" method="post" className="c-modal__content">
                    <div className="c-modal__input-block">
                        <label htmlFor="" className="c-modal__label">Заголовок</label>
                        <input type="text" className="c-modal__input" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="c-modal__input-block">
                        <label htmlFor="" className="c-modal__label">Описание</label>
                        <textarea className="c-modal__textarea" name="" id="" cols="30" rows="5" value={text} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                    <button type="button" className="button button_view_default c-modal__submit" onClick={handleSubmit}>Сохранить</button>
                </form>
            </div>
        </Modal>
    ), [item, modalTitle, title, text]);

    return (
        <div className="checkbox">
            <div className="checkbox__box">
                <input className="checkbox__control" type="checkbox" id={`${ident}-${i}`} checked={item.isActive} onChange={(e) => changeHandler({ ...item, isActive: e.target.checked })} />
                <label className="checkbox__input" htmlFor={`${ident}-${i}`} />
                <div className="checkbox__marker"></div>
            </div>
            <label className="checkbox__label" htmlFor={`${ident}-${i}`}>{item.title}</label>

            {!withoutModal && <button
                type="button"
                onClick={openServices}
                aria-label="Редактировать услугу"
                className="admin__edit-btn">
            </button>}
        </div>
    )
}
const Tabs = (props) => {
    const {
        tabs,
        setActive,
        activeTab,
        children,
        ident,
        withoutTabs,
    } = props
    const isMobile = useMedia("isMobile");
    if (withoutTabs) return children

    return <div className="admin__list-tabs">

        <div className="admin__list-container">
            {tabs.map((tab, i) => {
                return (
                    <div className={`admin__list-tab ${i === activeTab ? "active" : ""}`} key={`${ident}-tabs-${i}`} onClick={() => setActive(i)}>
                        <span>{tab.title}</span>
                        {tab.selected > 0 && <div className="dot">{tab.selected}</div>}
                    </div>)
            })}
        </div>
        <div className="admin__list-box">
            {children}
        </div>
    </div>
}
const Checkboxes = (props) => {
    const { items, ident } = props
    const firstColumn = items.slice(0, Math.round(items.length / 2))
    const secondColumn = items.slice(Math.round(items.length / 2))
    return <div className="admin__checkboxes">
        <div className="admin__checkboxes-column">
            {firstColumn.map((item, i) => <Item {...props} item={item} key={"first-column" + ident + i} i={item.fakeId} />)}
        </div>
        {secondColumn?.length > 0 && <div className="admin__checkboxes-column">
            {secondColumn.map((item, i) => <Item {...props} item={item} key={"second-column" + ident + i} i={item.fakeId} />)}
        </div>}
    </div>
}

const List = (props) => {
    const { items, ident, title, withoutModal, modalTitle, tooltipTitle, defaultActiveTab, withoutTabs, switcher } = props
    const [filteredItems, setFilteredItems] = useState(items)
    const [activeTab, setActiveTab] = useState(defaultActiveTab || 0)
    const [tabs, setTabs] = useState([
        {
            title: "Новостройки СПБ",
            filter: (item) => item.city === "spb" && item.type !== "suburban",
            selected: items.filter((item) => item.city === "spb" && item.type !== "suburban" && item.isActive).length,
            selectedFilter: (item) => item.city === "spb" && item.type !== "suburban" && item.isActive
        },
        {
            title: "Новостройки МСК",
            filter: (item) => item.city === "msk",
            selected: items.filter((item) => item.city === "msk" && item.isActive).length,
            selectedFilter: (item) => item.city === "msk" && item.isActive
        },
        {
            title: "Загородная недвижимость",
            filter: (item) => item.city === "spb" && item.type === "suburban",
            selected: items.filter((item) => item.city === "spb" && item.type === "suburban" && item.isActive).length,
            selectedFilter: (item) => item.city === "spb" && item.type === "suburban" && item.isActive,
        },
    ])
    const evaluateActive = (items) => {
        const tmpTabs = tabs.map((tab) => {
            tab.selected = items.filter(tab["selectedFilter"]).length
            return tab
        })
        setTabs(tmpTabs)
    }
    useEffect(() => {
        if (withoutTabs) return
        evaluateActive(items)
    }, [props]) // глубоко

    useEffect(() => {
        if (withoutTabs) {
            return
        }
        let current = { ...tabs[activeTab] }
        const newItems = items.filter(current["filter"])
        setFilteredItems(newItems)
    }, [activeTab, items])


    return (
        <>
            <div className="admin__title">
                {switcher && <label className="switch" htmlFor={switcher.id}>
                    <div className="switch__box">
                        <input className="switch__control"
                            type="checkbox"

                            {...switcher}
                        />
                        <div className="switch__input" htmlFor={switcher.id} />
                        <div className="switch__marker" />
                    </div>
                </label>}
                <h5 className="admin__h5 h5">
                    {title}
                </h5>
                <div className="info">
                    <svg className="icon icon_info">
                        <use xlinkHref="#info" />
                    </svg>
                    <div className="info__popup">
                        <p>{modalTitle || tooltipTitle}</p>
                    </div>
                </div>
            </div>
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActive={setActiveTab}
                ident={ident}
                withoutTabs={withoutTabs}
            >
                <Checkboxes {...props} items={filteredItems} />
            </Tabs>
        </>
    )
}
export default List
