import { memo } from "react";
import useMedia from "../../../../../hooks/useMedia";
import GetColumns from "./columns";
import ApartmentRow from "./Row";
import './index.scss';

const DetailList = memo(({ items, loadItems, showSkeleton, view, city, quickViewEnabled, defaultTitle, columnsType, sortHandler, ...props }) => {

  const isDesktop = useMedia("isDesktop");
  const isLaptop = useMedia("isDesktop");
  const columns = GetColumns({ isDesktop, isLaptop, type: columnsType, items, city, ...props })
  return (
    <section className="sf__detail-table">
      {items.map((building, buildingIndex )=>
        building?.types && <div className={`complex__appartments table2`} key={building.buildingId}>
          <div className="complex__title-info">
            <h4 className="h4-l">{building.title}</h4>
            <div className="complex__title-text">
              {building.ending && building.isHanded ? <span className="complex__title-text_handed">{building.ending}</span> : building.ending && <span>Срок сдачи — {building.ending}</span>}
              {building.issuingKeys && <span>Ключи — {building.issuingKeys}</span>}
            </div>
          </div>
          {building.types.map((type, itemsIndex) => {
            const tableProps = {
              onChange: (...args) => sortHandler?.(...args, type?.items, buildingIndex, itemsIndex)
            }
            return <ApartmentRow
              key={`${type.roomTitle}`}
              {...type}
              columns={columns}
              showSkeleton={showSkeleton}
              loadItems={loadItems}
              buildingId={building.buildingId}
              view={view}
              city={city}
              quickViewEnabled={quickViewEnabled}
              defaultTitle={defaultTitle}
              columnsType={columnsType}
              tableProps={tableProps}
            />
          })}
        </div>
      )}
    </section>
  );
});

export default DetailList;
