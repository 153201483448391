import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useMedia from "../hooks/useMedia";
import usePermission from '../hooks/usePermission';
import Deal from "../pages/Deals";
import Favorites from '../pages/Favorites';
import Requests from '../pages/Requests/index';
import SavedFilters from '../pages/SavedFilters';
import TenRating from '../pages/TenYears/Rating.js';
import Agents from './Agents';
import Clients from './Clients';
import Events from './Events';
import Meetings from './Meetings';
import MyObjects from './MyObjects';
import PersonalNavigation from './PersonalNavigation';
import Presentations from './Presentations';
import Profile from './Profile';
import ProfileOffers from './Profile/Offers';
import NewFavorites from "../pages/NewFavorites";

const ConditionRoute = ({
  component: Component,
  needRedirect = false,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (needRedirect) return <Redirect to="/" />;
        return <Component {...props} />;
      }}
    />
  );
};

const Personal = props => {
  let { path, url } = useRouteMatch();
  const showAgents = usePermission("agent");
  const isMobile = useMedia("isMobile")
  const menu = [
    { title: 'Сделки', link: '/personal/deals', icon: '#lk-orders', expandable: false, hide: false },
    { title: 'Профиль', link: '/personal/profile', icon: '#lk-profile', expandable: false, hide: false },
    //{ title: 'Файлы', link: '/personal/files', icon: '#lk-files', expandable: false, hide: false},
    { title: 'Переговорные', link: '/personal/meetings', icon: '#lk-con', expandable: false },
    { title: 'Клиенты', link: '/personal/clients', icon: '#lk-clients', expandable: false, hide: false },
    { title: 'Агенты', link: '/personal/agents', icon: '#lk-agents', expandable: false, hide: !showAgents },
    { title: 'Презентации', link: '/personal/presentations', icon: '#lk-presentations', expandable: false, hide: false },
    { title: 'Мероприятия', link: '/personal/events', icon: '#lk-events', expandable: false, hide: false },
    { title: 'Избранное', link: '/favorites', icon: '#lk-favorites', expandable: true },
    // { title: 'Мои объекты', link: '/personal/objects', icon: '#lk-compare', expandable: true},
    //{ title: 'Сравнение', link: '/personal/compare', icon: '#lk-compare', expandable: true},
    { title: 'Сохраненные фильтры', link: '/personal/filters', icon: '#lk-filters', expandable: true },
    // { title: 'Рейтинг', link: '/personal/rating', icon: '#lk-filters', expandable: true},
    //{ title: 'Уведомления', link: '/personal/notifications', icon: '#lk-orders', expandable: true},
    //{ title: 'Настроить фильтр', link: '/personal/settings', icon: '#lk-orders', expandable: true}
  ];

  const getMenu = () => menu.filter(menu => !menu.hide);

  return (
    <>
      {/* <section className="section section_view_white section__personal-header">
        <div className="wrapper">
          {!isMobile &&
            <div className="personal__header">
              <div className="personal__search">
                <h4 className="h4">Личный кабинет</h4>
                <div className="personal__search-wrap">
                  <label
                    className="input input_type_search input_width_available"
                    htmlFor="lk-search"
                  >
                    <input
                      className="input__control"
                      type="search"
                      id="lk-search"
                      placeholder="Поиск по личному кабинету"
                    />
                    <svg className="icon icon_search_lk">
                      <use xlinkHref="#search"></use>
                    </svg>
                  </label>
                </div>
              </div>
              <PersonalNavigation items={getMenu()} />
            </div>
          }
        </div>
      </section> */}

      <Switch>

        <Route exact path={`${path}/profile`} component={Profile} />
        <Route path={`${path}/profile/offers`} component={ProfileOffers} />
        <Route path={`${path}/clients`} component={Clients} />
        <Route path={`${path}/deals`} component={Requests} exact/>
        <Route path={`${path}/deals/:id`} component={Deal} exact/>
        <Route path={`${path}/filters`} component={SavedFilters} />
        <Route path={`${path}/presentations`} component={Presentations} />
        <Route path={`/personal/favorites`} component={Favorites} />
        {/*<Route path={`${path}/new-favorites`} component={NewFavorites} />*/}
        <Route path={`${path}/events`} component={Events} />
        <Route path={`${path}/meetings`} component={Meetings} />
        <Route path={`${path}/objects`} component={MyObjects} />
        {/*<ConditionRoute path={`${path}/agents`} component={Agents} needRedirect={!showAgents} />*/}
        <ConditionRoute path={`${path}/agents`} component={Agents} />
        <Route path={`${path}/rating`} component={TenRating} />

      </Switch>

    </>
  );
};

export default Personal;
