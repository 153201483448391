const Overlay = ({ state, setState, addClass }) => {
  if (state) {
    return (
      <div
        class={`mobile-slide-menu-overlay${addClass ? ` ${addClass}` : ``}`}
        onClick={() => setState(false)}
      ></div>
    );
  }

  return null;
};

export default Overlay;
