import _filter from 'lodash/filter';
import without from 'lodash/without';
import React from 'react';
import useMedia from '../../hooks/useMedia';
import Checkbox from '../core/Checkbox';
import RadioDropdown from "../core/RadioDropdown";
import EndingsFilter from "../FilterRealty/EndingsFilter";
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from "../FilterRealty/PriceFilter";
import SelectFilter from "../FilterRealty/SelectFilter";
import SquareFilter from "../FilterRealty/SquareFilter";

const FilterUniversal = (props) => {
    const {
        filterData,
        filter,
        handleFilter,
        clearFilter,
        clearExcludeOnly,
        clearFilterOnly,
        filterType,
        setFilter
    } = props
    const isMobile = useMedia("isMobile")
    const smallScreen = useMedia("isLaptop");
    const isCommercial = filterType === "commercial"
    const isSecondary = filterType === "secondary"
    const isAssignment = filterType === "assignment"
    const isSuburban = filterType === "suburban"
    const isPrimary = filterType === "primary"

    const handleToggle = (e) => {
        let tempFilter = [...filter.filter.status];
        if (e.target.checked) {
            tempFilter.push("3");
        } else {
            tempFilter = without(tempFilter, "3");
        }
        setFilter({
            filter: { status: tempFilter },
        });
    };
    return (
        <>
            <div className="modal-form__row">
                {filter.filter.objectType && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, 'objectType')
                        }
                        isExcludable={false}
                        isAutocomplete={false}
                        items={
                            filterData.objectType
                                ? Object.values(filterData.objectType)
                                : []
                        }
                        filterValues={filter.filter.objectType}
                        excludeValues={filter.exclude.objectType}
                        data={{
                            title: 'Тип объекта',
                            autocompleteTitle: '',
                            identity: 'objectType',
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "blocks")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={filterData.blocks ? Object.values(filterData.blocks) : []}
                        filterValues={filter.filter.blocks}
                        excludeValues={filter.exclude.blocks}
                        data={{
                            title: "Название ЖК",
                            autocompleteTitle: "Введите название ЖК",
                            identity: "block",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "districts")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                            filterData.districts ? Object.values(filterData.districts) : []
                        }
                        filterValues={filter.filter.districts}
                        excludeValues={filter.exclude.districts}
                        data={{
                            title: "Район",
                            autocompleteTitle: "Введите название района",
                            identity: "districts",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                {
                    !isCommercial &&
                    <div className="modal-form__item">
                        <SelectFilter
                            onChange={(a, b) =>
                                handleFilter({ filter: a, exclude: b }, "rooms")
                            }
                            isExcludable={true}
                            isAutocomplete={false}
                            items={filterData.rooms ? Object.values(filterData.rooms) : []}
                            filterValues={filter.filter.rooms}
                            excludeValues={filter.exclude.rooms}
                            data={{
                                title: "К-во комнат",
                                autocompleteTitle: "",
                                identity: "rooms",
                            }}

                            isMobile={isMobile}
                            clearFilterOnly={clearFilterOnly}
                            clearExcludeOnly={clearExcludeOnly}
                            fullFilter={filter}
                        />
                    </div>
                }
                {filter.filter.endings && filterData.endings && !isSecondary && !isSuburban && <div className="modal-form__item">
                    <EndingsFilter
                        ident={"endings"}
                        right={smallScreen}
                        filter={
                            filter.filter.endings
                                ? filter.filter.endings
                                : { min: "", max: "" }
                        }
                        items={filterData.endings ? filterData.endings.items : []}
                        data={{
                            title: "Срок сдачи",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.endings
                                ? filterData.endings.ranges
                                : null,
                        }}
                        onChange={(a) => handleFilter(a, "endings")}
                        secondData={{
                            title: "Сданные дома",
                            value: filter.filter?.isOnlyHanded?.length
                                ? filter.filter.isOnlyHanded[0].id
                                : "N",
                        }}
                        secondChange={(a) => {
                            let tmp = a === "Y" ? [{ id: a }] : [];
                            handleFilter(
                                {
                                    filter: tmp,
                                    exclude: [
                                        ..._filter(
                                            filter.exclude.isOnlyHanded,
                                            (val) => val.id != a,
                                        ),
                                    ],
                                },
                                "isOnlyHanded",
                            );
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}

            </div>
            <div className="modal-form__row">
                <div className="modal-form__item">
                    <PriceFilter
                        filter={
                            filter.filter.prices
                                ? filter.filter.prices
                                : { min: "", max: "" }
                        }
                        exclude={
                            filter.exclude.prices
                                ? filter.exclude.prices
                                : { min: "", max: "" }
                        }
                        items={filterData.prices ? filterData.prices.items : []}
                        data={{
                            title: "Цена",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.prices ? filterData.prices.range : 0,
                        }}
                        onChange={(a) => handleFilter(a, "prices")}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "builders")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                            filterData.builders ? Object.values(filterData.builders) : []
                        }
                        filterValues={filter.filter.builders}
                        excludeValues={filter.exclude.builders}
                        data={{
                            title: "Застройщик",
                            autocompleteTitle: "Введите название застройщика",
                            identity: "builders",
                        }}
                        right={!smallScreen}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "subways")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                            filterData.subways ? Object.values(filterData.subways) : []
                        }
                        filterValues={filter.filter.subways}
                        excludeValues={filter.exclude.subways}
                        data={{
                            title: "Метро",
                            autocompleteTitle: "Введите название станции",
                            identity: "subways",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                {filter.filter.decoration && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "decoration")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.decoration
                                ? Object.values(filterData.decoration)
                                : []
                        }
                        filterValues={filter.filter.decoration}
                        excludeValues={filter.exclude.decoration}
                        data={{
                            title: "Отделка",
                            autocompleteTitle: "",
                            identity: "decoration",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {isSecondary && <div className="modal-form__item">
                    <SquareFilter
                        filter={filter.filter.squareKitchen}
                        data={{
                            title: "S кухни",
                            minPlaceholder: "S кухни от ",
                            maxPlaceholder: "S кухни до ",
                            defaultValues: filterData.squareKitchen
                                ? filterData.squareKitchen.range
                                : null,
                        }}
                        filterSecond={filter.filter.squareTotal}
                        dataSecond={{
                            title: "S",
                            minPlaceholder: "S общ. от ",
                            maxPlaceholder: "S общ. до ",
                            defaultValues: filterData.squareTotal
                                ? filterData.squareTotal.range
                                : null,
                        }}
                        alternativeTitle="Площадь"
                        isArea={true}
                        onChange={(a, identity) => handleFilter(a, identity)}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
            </div>
            <div className="modal-form__row">
                {!isSecondary && !isSuburban && <div className="modal-form__item">
                    <SquareFilter
                        filter={filter.filter.squareKitchen}
                        data={{
                            title: "S кухни",
                            minPlaceholder: "S кухни от ",
                            maxPlaceholder: "S кухни до ",
                            defaultValues: filterData.squareKitchen
                                ? filterData.squareKitchen.range
                                : null,
                        }}
                        filterSecond={filter.filter.squareTotal}
                        dataSecond={{
                            title: "S",
                            minPlaceholder: "S общ. от ",
                            maxPlaceholder: "S общ. до ",
                            defaultValues: filterData.squareTotal
                                ? filterData.squareTotal.range
                                : null,
                        }}
                        alternativeTitle="Площадь"
                        isArea={true}
                        onChange={(a, identity) => handleFilter(a, identity)}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filterData.balconyType && (
                    <div className="modal-form__item">
                        <SelectFilter
                            onChange={(a, b) =>
                                handleFilter({ filter: a, exclude: b }, "balconyType")
                            }
                            isExcludable={false}
                            isAutocomplete={false}
                            items={
                                filterData.balconyType
                                    ? Object.values(filterData.balconyType)
                                    : []
                            }
                            filterValues={filter.filter.balconyType}
                            excludeValues={filter.exclude.balconyType}
                            data={{
                                title: "Балкон",
                                autocompleteTitle: "",
                                identity: "balconyType",
                            }}
                            disabledItems={
                                {
                                    w: ["b", "l", "t"],
                                    b: ["w"],
                                    l: ["w"],
                                    t: ["w"],
                                }
                            }

                            isMobile={isMobile}
                            clearFilterOnly={clearFilterOnly}
                            clearExcludeOnly={clearExcludeOnly}
                            fullFilter={filter}
                        />
                    </div>)}
                <div className="modal-form__item">
                    <FloorsFilter
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}

                        right={smallScreen}
                        filter={filter?.filter?.floor ? filter?.filter?.floor : filter?.filter?.floors}
                        data={{
                            title: "Этаж",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.floor ? filterData.floor.range : null,
                        }}
                        onChange={(a) => handleFilter(a, "floor")}
                        secondData={{
                            title: filterData?.isNoFirstFloor
                                ? filterData?.isNoFirstFloor[0]?.title
                                : filterType === "commercial" ? "только первый" : "Не первый",
                            value: filter?.filter?.isNoFirstFloor?.length && !isCommercial
                                ? filter.filter.isNoFirstFloor[0].id
                                : "N",
                            ...(filter?.filter?.isNoFirstFloor?.length && !isCommercial
                                ? { value: filter.filter.isNoFirstFloor[0].id }
                                : []),
                            ...(filter?.filter?.isFirstFloor?.length && isCommercial
                                ? { value: filter.filter.isFirstFloor[0].id }
                                : [])
                        }}
                        isCommercial={filterType === "commercial"}
                        secondChange={(a) => {
                            let tmp = a === "Y" ? [{ id: a }] : [];
                            handleFilter(
                                {
                                    filter: tmp,
                                    exclude: [
                                        ..._filter(
                                            isCommercial ? filter.exclude.isFirstFloor : filter.exclude.isNoFirstFloor,
                                            (val) => val.id != a,
                                        ),
                                    ],
                                },
                                filterType === "commercial" ? "isFirstFloor" : "isNoFirstFloor",
                            );
                        }}
                    />
                </div>
                {filter.filter.balcony && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) => handleFilter({ filter: a }, 'balcony')}

                        isExcludable={false}
                        isAutocomplete={false}
                        items={
                            filterData.balcony
                                ? Object.values(filterData.balcony)
                                : []
                        }
                        filterValues={filter.filter.balcony}
                        excludeValues={[]}
                        data={{
                            title: "Балкон",
                            autocompleteTitle: "",
                            identity: "balcony",
                        }}
                        disabledItems={
                            {
                                w: ["b", "l", "t"],
                                b: ["w"],
                                l: ["w"],
                                t: ["w"],
                            }
                        }

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filter.filter.payment && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "payment")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.payment ? Object.values(filterData.payment) : []
                        }
                        filterValues={filter.filter.payment}
                        excludeValues={filter.exclude.payment}
                        data={{
                            title: "Оплата",
                            autocompleteTitle: "",
                            identity: "payment",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filter.filter.registrations && (isAssignment) && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "registrations")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.registrations
                                ? Object.values(filterData.registrations)
                                : []
                        }
                        filterValues={filter.filter.registrations}
                        excludeValues={filter.exclude.registrations}
                        data={{
                            title: "Прописка",
                            autocompleteTitle: "",
                            identity: "registrations",
                        }}
                        right={!smallScreen}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
            </div>
            <div className="modal-form__row">
                {filter.filter.registrations && !isCommercial && !isAssignment && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "registrations")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.registrations
                                ? Object.values(filterData.registrations)
                                : []
                        }
                        filterValues={filter.filter.registrations}
                        excludeValues={filter.exclude.registrations}
                        data={{
                            title: "Прописка",
                            autocompleteTitle: "",
                            identity: "registrations",
                        }}
                        right={!smallScreen}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filter.filter.availability && !isCommercial && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "availability")
                        }
                        isExcludable={false}
                        isAutocomplete={false}
                        items={
                            filterData.availability
                                ? Object.values(filterData.availability)
                                : []
                        }
                        filterValues={filter.filter.availability}
                        excludeValues={filter.exclude.availability}
                        data={{
                            title: "Доступность",
                            autocompleteTitle: "",
                            identity: "availability",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filterData.banks && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "banks")
                        }
                        isExcludable={false}
                        isAutocomplete={false}
                        items={filterData.banks ? Object.values(filterData.banks) : []}
                        filterValues={filter.filter.banks}
                        excludeValues={filter.exclude.banks}
                        data={{
                            title: "Банки",
                            autocompleteTitle: "",
                            identity: "banks",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filterData.isApartments && <div className="modal-form__item">
                    <RadioDropdown
                        right={!smallScreen}
                        onChange={(a, type) =>
                            handleFilter(
                                {
                                    filter: [{ id: a }],
                                    exclude: [
                                        ..._filter(
                                            filter.exclude.isApartments,
                                            (val) => val.id != a,
                                        ),
                                    ],
                                },
                                "isApartments",
                            )
                        }
                        isAutocomplete={false}
                        items={
                            filterData.isApartments ? filterData.isApartments : []
                        }
                        value={
                            filter.filter.isApartments[0]
                                ? filter.filter.isApartments[0].id
                                : {}
                        }
                        alternativeTitle={"Апартаменты"}
                        data={{
                            title: "Апартаменты",
                            identity: "isApartments",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filterData.encumbrances && (
                    <div className="modal-form__item">
                        <RadioDropdown
                            right={!smallScreen}
                            onChange={(a, type) =>
                                handleFilter(
                                    {
                                        filter: [{ id: a }],
                                        exclude: [
                                            ..._filter(
                                                filter.exclude.encumbrances,
                                                (val) => val.id != a,
                                            ),
                                        ],
                                    },
                                    "encumbrances",
                                )
                            }
                            isAutocomplete={false}
                            items={
                                filterData.encumbrances
                                    ? filterData.encumbrances
                                    : [
                                        { id: "Y", title: "C обременениями" },
                                        { id: "N", title: "Без обременений" },
                                    ]
                            }
                            value={
                                filter.filter.encumbrances[0]
                                    ? filter.filter.encumbrances[0].id
                                    : {}
                            }
                            alternativeTitle={"Обременения"}
                            data={{
                                title: "Обременения",
                                identity: "encumbrances",
                            }}

                            isMobile={isMobile}
                            clearFilterOnly={clearFilterOnly}
                            clearExcludeOnly={clearExcludeOnly}
                            fullFilter={filter}
                        />
                    </div>)}
                {filterData.realtyType ? <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "realtyType")
                        }
                        isExcludable={false}
                        isAutocomplete={false}
                        items={filterData.realtyType ? Object.values(filterData.realtyType) : []}
                        filterValues={filter.filter.realtyType}
                        excludeValues={filter.exclude.realtyType}
                        data={{
                            title: "Тип договора",
                            autocompleteTitle: "",
                            identity: "realtyType",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div> : <div className="modal-form__item"> </div>}
                {(isCommercial || isAssignment) && <div className="modal-form__item"></div>}
            </div>
            {filter.filter.issuingKeys && filterData.issuingKeys && !isSecondary && !isSuburban && <div className="modal-form__row">
                <div className="modal-form__item">
                    <EndingsFilter
                        ident={"issuingKeys"}
                        right={smallScreen}
                        filter={
                            filter.filter.issuingKeys
                                ? filter.filter.issuingKeys
                                : { min: "", max: "" }
                        }
                        items={filterData.issuingKeys ? filterData.issuingKeys.items : []}
                        data={{
                            title: "Выдача ключей",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.issuingKeys
                                ? filterData.issuingKeys.ranges
                                : null,
                        }}
                        onChange={(a) => handleFilter(a, "issuingKeys")}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                {filterData.cellHeight ? <div className="modal-form__item">  <RadioDropdown
                    autoPosition
                    onChange={(a) => handleFilter(
                        {
                            filter: {
                                "max": "",
                                "min": a
                            },
                            exclude: {
                                "max": "",
                                "min": ""
                            },
                        },
                        "cellHeight",
                    )
                    }
                    items={filterData.cellHeight?.items ? filterData?.cellHeight?.items.map(item => ({ ...item, title: `от ${item.title} м.` })) : []}
                    value={
                        filter.filter.cellHeight?.min
                            ? filter.filter.cellHeight?.min
                            : {}
                    }
                    alternativeTitle={"Высота потолков"}
                    data={{
                        title: "Высота потолков",
                        identity: "cellHeight",
                    }}
                    isMobile={isMobile}
                    clearFilterOnly={clearFilterOnly}
                    clearExcludeOnly={clearExcludeOnly}
                    fullFilter={filter}
                /> </div> : <div className="modal-form__item"> </div>}
                {filterData.assignments ? <div className="modal-form__item"> <RadioDropdown
                    autoPosition
                    onChange={(a) =>
                        handleFilter(
                            {
                                filter: [{ id: a }],
                                exclude: [
                                    ..._filter(
                                        filter.exclude.assignments,
                                        (val) => val.id != a,
                                    ),
                                ],
                            },
                            "assignments",
                        )
                    }
                    isAutocomplete={false}
                    items={
                        filterData.assignments ? filterData.assignments : []
                    }
                    value={
                        filter.filter.assignments
                            ? filter.filter.assignments
                            : {}
                    }
                    alternativeTitle={"Переуступки"}
                    data={{
                        title: "Переуступки",
                        identity: "assignments",
                    }}
                    isMobile={isMobile}
                    clearFilterOnly={clearFilterOnly}
                    clearExcludeOnly={clearExcludeOnly}
                    fullFilter={filter}
                /></div> : <div className="modal-form__item"> </div>}
                <div className="modal-form__item"> </div>
            </div>}
            {!isSecondary && <div className="modal-form__row">
                {isPrimary &&
                    <div className="modal-form__item">
                        <div className="index-search__show-booked">
                            <Checkbox
                                id={'tradeIn'}
                                onChange={(e) => {
                                    handleFilter({ filter: e.target.checked ? [{ id: "Y" }] : [] }, 'tradeIn')
                                }}
                                isChecked={!!filter.filter.tradeIn.length}
                                checked={!!filter.filter.tradeIn.length}
                                labelText={"Trade-In"}
                            />
                        </div>
                    </div>
                }
                <div className="modal-form__item">
                    <div className="index-search__show-booked">
                        <label className="switch" htmlFor="filter-booked">
                            <div className="switch__box">
                                <input
                                    className="switch__control"
                                    type="checkbox"
                                    id="filter-booked"
                                    checked={filter.filter.status.includes("3")}
                                    onChange={handleToggle}
                                />
                                <div className="switch__input" htmlFor="filter-booked"></div>
                                <div className="switch__marker"></div>
                            </div>
                            <span className="switch__label">Показать забронированные</span>
                        </label>
                    </div>
                </div>
                <div className="modal-form__item"> </div>
                <div className="modal-form__item"> </div>

            </div>}
        </>
    )
}
export default FilterUniversal