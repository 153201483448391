import React, { memo, useRef, useState } from "react";
import useMedia from "../../../../../hooks/useMedia";
import Table from "../../../../core/Table2";
import { rowClassName } from "./utils";
import {useModal} from "react-modal-hook";
import PanModal from "../../../../core/PanModal";
import AptModal from "../../../../AptModal";


const TableApartments = memo(props => {
    const {
        active,
        items,
        columns,
        tableProps = {},
        city,
        quickViewEnabled,
        defaultTitle,
        columnsType
    } = props;
    const isMobile = useMedia("isMobile");
    const tableRef = useRef(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
    const [isShowToopltip, setIsShowToopltip] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)

    const [openAptModal, closeAptModal] = useModal(
      () => (
        <PanModal title={'Быстрый просмотр'} closeModal={closeAptModal} isBig>
            <AptModal columnsType={columnsType} selectedItem={selectedItem} city={city} closeModal={closeAptModal}  defaultTitle={defaultTitle} />
        </PanModal>
      ),
      [selectedItem, defaultTitle]);

      // const getRowLink = (record, isPublic, isAssignment )=> {
      //   if(isAssignment){
      //   return  `/assignments/${record.id}${window.location.search}`
      //   }
      //   if (columnsType === 'parkings') {
      //     return `/realty/${city}/parking/${record.id}${window.location.search}`
      //   }
      //  return `/realty/${city}/apartment/${record.id}${window.location.search}`
      // }

    return <div className={`flats__row-table ${active ? `is-active` : ``}`}
        ref={tableRef}
    >
        <Table
            rowKey="id"
            showSorterTooltip={false}
            columns={columns}
            dataSource={items}
            pagination={false}
            locale={{ emptyText: " " }}
            customPagination={true}
            firstPageCount={10}
            pageCount={50}
            rowClassName={rowClassName}
            onRow={(record, rowIndex) => ({
                onClick: () => {
                  // console.log(record)
                  setSelectedItem(record)
                  console.log(selectedItem)
                    if (!quickViewEnabled) return window.open(
                        // getRowLink(record, false, record.objectType === "assignment")
                        record.link
                    );
                    setSelectedItem(record)
                    openAptModal()
                },
                onMouseMove: ({ target, clientY, clientX }) => {
                    const cond = !record.isAction ||
                        isMobile ||
                        target.tagName === "IMG" ||
                        target.classList.toString().includes("status");

                    if (cond) return setIsShowToopltip(false)
                    const targetBounds = tableRef.current.getBoundingClientRect()
                    setTooltipPosition({ top: clientY - targetBounds.y, left: clientX - targetBounds.x + 25 })
                    setIsShowToopltip(true)
                },
                onMouseLeave: () => setIsShowToopltip(false)
            })}
            {...tableProps}
        />
        <div className="action-row-tooltip" style={{ visibility: isShowToopltip ? "visible" : "hidden", ...tooltipPosition }}>
            На данную квартиру распространяется скидка от застройщика. Размер скидки и другие подробности акции уточняйте у менеджера.
        </div>
    </div>
});
export default TableApartments
