import floor from 'lodash/floor';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import React, { useEffect, useRef, useState } from "react";
import { Line } from 'react-chartjs-2';
import { formatMoney, getPricePerMeter, getRoundedPrice, media, months } from '../../utils';
import Popup from '../core/Popup';

const getData = (data) => {
  return data.map(item => ({ ...item, x: new Date(item.date), y: item.costWithDiscount }))
}
const getStepSIze = (data) => {
  const minCost = minBy(data, (item) => item.costWithDiscount)?.costWithDiscount
  const maxCost = maxBy(data, (item) => item.costWithDiscount)?.costWithDiscount
  const delta = maxCost - minCost
  if(delta > 10000000){
    return 5000000
  }
  if(delta > 4000000){
    return 2000000
  }
  if(delta > 2000000){
    return 1000000
  }
  if(delta > 999999){
    return 500000
  }
  if(delta < 100000){
    return 10000
  }
  return 100000
}

const getCosts = (data) => {
  if(!data?.length) return
  const allPrices = data.reduce((acc, item) => acc.concat(item.prices), [])
  const stepSize = getStepSIze(allPrices)
  let minByCost = minBy(allPrices, (item) => item.costWithDiscount)
  let maxByCost = maxBy(allPrices, (item) => item.costWithDiscount)
  let minYLabel = floor(minByCost.costWithDiscount / stepSize) * stepSize
  let maxYLabel = (floor(maxByCost.costWithDiscount / stepSize) + 1) * stepSize
  if(!minYLabel){
    minYLabel = 1000000
  }
  return {
    min: minYLabel,
    max: maxYLabel
  }
  // const labels = []
  // let currentPrice = minYLabel
  // do {
  //   labels.push(currentPrice)
  //   currentPrice += 500000
  // } while (currentPrice < maxYLabel)
  // return labels
}

const getDate = (data) => {
  if(!data?.length) return
  const allPrices = data.reduce((acc, item) => acc.concat(item.prices), []).map(item=>{
    if(item.date.toString().length > 4){
      return item
    }
    item.date += "-01-01"
    return item
  })
  let minByDate = minBy(allPrices, (item) => new Date(item.date))
  let maxByDate = maxBy(allPrices, (item) => new Date(item.date))
  return {
    min: minByDate,
    max: maxByDate
  }
}



const Prices = (props) => {
  const { info, chartInfo, isDefault } = props
  const _chartRef = useRef();
  const [tooltipData, setTooltipData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)
  const allPrices = chartInfo.prices.reduce((acc, item) => acc.concat(item.prices), []).map(item=>{
    if(item.date.toString().length > 4){
      return item
    }
    item.date += "-01-01"
    return item
  })

  const options = {
    // onHover: (...args)=> console.log('args', args),
    bounds: "ticks",
    hover: {
      mode: 'nearest',
      intersect: false
    },
    scales: {

      yAxes: [
        {
          ticks: {
            ...getCosts(chartInfo.prices),
            beginAtZero: true,
            stepSize: getStepSIze(allPrices),
            // maxTicksLimit: chartInfo.prices.length,
            callback: function (value, index, values) {
              return getRoundedPrice(value).toString().replace(".", ",") + " млн. р.";
            },
            fontSize: 14,
            fontFamily: "Formular",
            padding: 20,
            fontColor: "#8F95AC"
          },
          gridLines: {
            borderDash: [1, 2],
            color: 'rgba(0, 0, 0, 0.2)',
            zeroLineColor: 'rgba(199, 202, 213, 1)',
            zeroLineWidth: 1,
            drawTicks: false,
            // drawBorder: false, // линия вдоль графика вверх
          },

        }
      ],
      xAxes: [
        {
          type: 'time',
          display: true,
          gridLines: {
            display: false,
            zeroLineColor: 'rgba(199, 202, 213, 1)',
          },
          distribution: 'series',
          time: {
            unit: 'day',
            displayFormats: {
              // day: 'YYYY-M-DD'
              day: allPrices[0]?.dateFormated?.length === 4 ? "YYYY": "DD.MM.YY"
            }

          },
          ticks: {
              ...getDate(chartInfo.prices),
              callback: function (value, index, values) {
                // const date = new Date(value)
                // const month = months[date.getMonth()]
                // const day = date.getDate()
                // return `${day} ${month}`;
                return value
              },
              fontSize: 14,
              fontFamily: "Formular",
              padding: 20
        },
      }
      ],
    },
    legend: {
      display: false
    },
    tooltips: {
      "enabled": false,
      "mode": "point",
      "intersect": false,
      "custom": (tooltipModel) => {
        const chart = _chartRef.current;
        if (!chart) {
          return;
        }
        if (tooltipModel.opacity === 0) {
          setTimeout(setShowTooltip, 100) // бред, но если сетить из этой функции состояние, то она не срабатывает баг chart js
          return;
        }
        const currentDatasetIndex = tooltipModel.dataPoints[0].datasetIndex
        const currentDataset = chartInfo.prices[currentDatasetIndex]
        // const position = chart.chartInstance.canvas.getBoundingClientRect();
        // const left = position.left + tooltipModel.caretX;
        // const top = position.top + tooltipModel.caretY;

        const currentIndexInDataset = tooltipModel.dataPoints[0].index;
        const isLast = currentDataset.prices.length - 1 === currentIndexInDataset;

        const left = isLast ? tooltipModel.caretX - 250 : tooltipModel.caretX + 10;
        const top = tooltipModel.caretY;
        const date = new Date(tooltipModel.dataPoints[0].xLabel);
        const value = tooltipModel.dataPoints[0].yLabel;

        let setTooltip = () => {
          setTooltipData({ top, left, date, value, currentDataset, isLast });
          setShowTooltip(true);
        }
        setTimeout(setTooltip, 100)

      },

    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },

  }

  useEffect(() => {
    if(!_chartRef) return
     const chart = _chartRef.current.chartInstance
     chart.options = options

      const isYearOnly = chart.config.data.datasets?.[0]?.data[0]?.dateFormated?.length === 4
      chart.options.scales.xAxes[0].time.displayFormats.day = isYearOnly? "YYYY": "DD.M.YY"
      chart.update();
    }, [_chartRef, isDefault])
  const data = {
    datasets:    chartInfo.prices.map((info, i)=>{
        return   {
          data: getData(info.prices),
          fill: chartInfo.prices?.length === 1,
          backgroundColor: 'rgba(113, 113, 113, 0.03)',
          borderColor: info.color,
          lineTension: 0,
          pointBackgroundColor: 'rgba(255, 255, 255, 1)',
          pointHoverBackgroundColor: info.color,
          pointHoverBorderColor: info.color,
          pointRadius: 4,
          pointHitRadius: 4,
          borderWidth: 1,
          label: `dataset-${i}`
        }
      }),
  }

  const Tooltip = props => {
    const { top, value, date, currentDataset, isLast} = props;
    let {left} = props;
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    const squareTotal = currentDataset?.squareTotal || 55
    const pricePerMeter = formatMoney(parseInt(getPricePerMeter(value, squareTotal)))
    const [previousObject, setPreviousObject] = useState(null)
    const selfTime = date.getTime()
    const currentIndex = allPrices.findIndex(item => selfTime === new Date(item.date).getTime())
    // const prevObject  = info.prices[currentIndex - 1]
    // setPreviousObject(prevObject)
    const first = currentDataset.prices[0]
    const moneyPerPercent = first.costWithDiscount / 100
    let dynamic = round(value / moneyPerPercent - 100, 2)

    return (
      <div style={{ left, top, minWidth: "max-content", zIndex: 999, }} className="chart__tooltip chart__tooltip_lg">
        <div className="flat__info-item_desc chart__tooltip-date">
          {`${day} ${months[month]} ${year}`}
        </div>
        <div className="chart__info-value chart__info-value_sm">
          {formatMoney(value) + ' руб.'}
          {!!dynamic && <span className={`chart__percent ${dynamic > 0 ? "chart__percent_plus" : "chart__percent_minus"}`}>{dynamic > 0 ? "+" + dynamic : dynamic}%</span>}
        </div>
        <span className="flat__info-item_desc chart__tooltip-detail">{pricePerMeter} руб./м²</span>
      </div>
    )
  }
  return (
    <>
      {media('isMobile') ?
        <Popup
          trigger={
            <Line data={data} options={options} ref={_chartRef} />
          }
          onClose={()=>setShowTooltip(false)}
        >
        </Popup> :
        <div onMouseLeave={()=>setShowTooltip(false)}>
          <Line data={data} options={options} ref={_chartRef} height={100} />
        </div>
      }
        {showTooltip && <Tooltip {...tooltipData} />}
    </>
  );
};

export default Prices;
