import { memo } from 'react';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

const ProjectsBottomFilter = memo(props => {

  const {
    filter,
    filterData,
    setFilterAndExclude,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <SelectFilter
      {...createSelectProps("constructionPeriod")}
      data={{
        title: "Срок строительства",
        autocompleteTitle: "",
        identity: "constructionPeriod",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("floors")}
      data={{
        title: "Этажей",
        autocompleteTitle: "",
        identity: "floors",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("material")}
      data={{
        title: "Материалы дома",
        autocompleteTitle: "",
        identity: "material",
      }}
      wrapperClass={wrapperClass}
    />
    <PriceFilter
      autoPosition
      filter={filter.price ?? { min: "", max: "" }}
      items={filterData.price?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.price?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "price")}
      debounced
      wrapperClass={wrapperClass}
    />
  </>
});

export default ProjectsBottomFilter;