import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useModal } from "react-modal-hook";
import { getUrl } from '../../../api';
import useClickPast from '../../../hooks/useClickPast';
import useMedia from '../../../hooks/useMedia';
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";


const ModalComponent = (props) => {
    const { image, title, modalTitle, addPhotoStyles = {}, delPhoto, photo, closePhotoUpload, setImage, isFile } = props

    const isMobile = useMedia("isMobile")


    const fakeInputRef = useRef(null)
    const [scale, setScale] = useState(1)
    const editorRef = useRef(null)
    const [rotation, setRotation] = useState(0)

    const closeModal = () => {
        if (editorRef.current) {
            const photo = editorRef.current.getImage().toBlob(blob => setImage({
                name: image.name,
                blob: blob,
            }, true))
        }
        closePhotoUpload()
    }
    const node = useClickPast(closeModal);

    const onPhotoScroll = (e) => {
        if (!editorRef.current) return
        if (e.deltaY > 0) {
            setScale(prev => prev - 0.1)
        }
        if (e.deltaY < 0) {
            setScale(prev => prev + 0.1)
        }
    }
    const handleScale = e => setScale(parseFloat(e.target.value))
    const handleRotate = e => setRotation(e.target.value)

    const modalContent = (
        <>
            {!isMobile &&
                <button type="button"
                    className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                    aria-label="Закрыть окно"
                    onClick={closeModal}
                >
                    <span className="c-modal__icon">
                        <svg className="icon icon_cross">
                            <use xlinkHref="#cross" />
                        </svg>
                    </span>
                </button>
            }
            <div className="c-modal__box" ref={node}>

                <div className="c-modal__content upload">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="upload__photo" onWheel={onPhotoScroll}>
                            {!isFile && photo && <img src={photo} style={{ borderRadius: "50%", ...addPhotoStyles }} />}

                            {isFile &&
                                <AvatarEditor
                                    ref={editorRef}
                                    image={photo}
                                    width={220}
                                    height={220}
                                    border={0}
                                    // color={[255, 255, 255, 0.6]} // RGBA
                                    scale={scale}
                                    rotate={rotation}
                                    borderRadius={110}
                                />
                            }
                        </div>
                        {isFile && <>
                            <div style={{ display: "flex", marginTop: 15 }}>Масштаб <input
                                name="scale"
                                type="range"
                                onChange={handleScale}
                                min="0.1"
                                max="2"
                                step="0.01"
                                value={scale}
                            />
                            </div>
                            <div style={{ display: "flex", marginTop: 15 }}>Вращение <input
                                name="rotate"
                                type="range"
                                onChange={handleRotate}
                                min="-180"
                                max="180"
                                step="1"
                                value={rotation}
                            />
                            </div>
                        </>}
                    </div>
                    <div className="upload__info" onClick={() => fakeInputRef.current.click()}>
                        <input type="file" style={{ display: 'none' }} onChange={setImage} ref={fakeInputRef} />
                        <div className="dropzone">
                        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.626953 11.907V21.5907C0.626953 22.143 1.07467 22.5907 1.62695 22.5907H19.8729C20.4252 22.5907 20.8729 22.143 20.8729 21.5907V11.907" stroke="#8F95AC"/>
                          <path d="M16.5302 7.23796L10.0638 0.981727" stroke="#8F95AC"/>
                          <path d="M11.0771 0.981703L4.61075 7.23793" stroke="#8F95AC"/>
                          <path d="M10.5704 1.41824L10.5704 17.7954" stroke="#8F95AC"/>
                        </svg>
                        <p>Загрузите свою фотографию</p>
                      </div>

                        {image && <p className="upload__result c-modal__upload-result">
                            <span className="c-modal__upload-text">{image.name ? image.name : title.slice(-4)}</span>
                            <button onClick={delPhoto} type="button" className="button c-modal__icon-btn c-modal__icon-btn_with-hover" aria-label="Удалить фото">
                                <span className="c-modal__icon c-modal__icon_sm">
                                    <svg className="icon icon_cross">
                                        <use xlinkHref="#cross" />
                                    </svg>
                                </span>
                            </button>
                        </p>}

                        <p className="upload__descr">
                            Можно загрузить картинку в формате png, jpg и gif.
                            Размеры не меньше 200 × 200 точек, объём файла не больше 7 МБ.
                         </p>
                    </div>
                </div>
            </div>
        </>
    )

    return (isMobile ?
        <MobileModal closeClick={closeModal} className="modal_task c-modal" title={modalTitle}>
            {modalContent}
        </MobileModal> :
        <Modal hideDefaultClose={true} classes="modal_task c-modal">
            {modalContent}
        </Modal>
    )
}

const PhotoUpload = (props) => {
    const { image, handleImageUpload, title } = props
    const [photo, setPhoto] = useState(getUrl(image?.src))
    const setImage = (e, isBlob) => {
        if (isBlob) {
            handleImageUpload(e)
            const reader = new FileReader();
            reader.onload = (e) => setPhoto(e.target.result)
            reader.readAsDataURL(e.blob);
            return
        }
        const fileObject = e.target.files[0]
        handleImageUpload(fileObject)
        const reader = new FileReader();
        reader.onload = (e) => setPhoto(e.target.result)
        reader.readAsDataURL(fileObject);
    }
    const delPhoto = () => {
        handleImageUpload(false)
        setPhoto(null)
    }
    const [openPhotoUpload, closePhotoUpload] = useModal(({ in: open, onExited }) => (
        <ModalComponent delPhoto={delPhoto} closePhotoUpload={closePhotoUpload} setImage={setImage} photo={photo} setPhoto={setPhoto} {...props} isFile={image instanceof File} />
    ), [image, photo]);
    return (
        <div className="admin__upload-block" onClick={openPhotoUpload}>
            {!photo && <img src={require('../../../assets/images/constructor/photo-upload.svg').default} alt="" className="admin__upload-img admin__upload-img_no-border" />}
            {photo && <img src={photo} alt="" className="admin__upload-img admin__upload-img_no-border" />}
            <div className="admin__upload">
                <button
                    type="button"
                    className="button admin__upload-label admin__anchor"
                >
                    {title}
                </button>
                {image && <p className="admin__upload-result">
                    <span className="admin__upload-text">{image.name ? image.name : title.slice(-4)}</span>
                    <button type="button" onClick={(e) => {
                        e.stopPropagation()
                        delPhoto()
                    }} className="button admin__icon-btn admin__icon-btn_with-hover" aria-label="Удалить фото">
                        <span className="admin__icon admin__icon_sm">
                            <svg className="icon icon_cross">
                                <use xlinkHref="#cross" />
                            </svg>
                        </span>
                    </button>
                </p>}
            </div>
        </div>
    )
}

export default PhotoUpload
