import fill from 'lodash/fill'
import random from 'lodash/random'
export const fakeData = () => {
    const sectionsCount = 4
    const maxFloor = 10


    const data = {
        "sections": [
        ],
        "maxFloor": maxFloor
    }
    let floors = fill(Array(15), 1).map((v,i)=>i+1)
    fill(Array(sectionsCount), 1).map((sect, i) => {
        const fakeObject = {
            apartments: {},
            count: 4,
            name: `Секция ${i + 1}`,
            id: i + 1,
            maxFloor
        };

        floors.map(floor => {
            let randomApartment = fill(Array(random(0, 3)), 0).map(() => random(1, 3))
            randomApartment.map(v => {
                fakeObject.apartments[floor] = {
                    [v]: true
                }
            })
            return floor
        })
  
        data.sections.push(fakeObject)
        return sect
    })
    return data
}