import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import request from '../../api/index.js';
import EmptyTable from '../../components/core/EmptyTable/index.js';
import { standartErrorToast, standartSuccessToast } from '../../components/toasts.js';
import './index.scss';




const Confirm = () => {
  const { type, agentId } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    request("panpartner:learning.ajax", "confirm", {
      type,
      agentId
    }).then(standartSuccessToast, standartErrorToast).finally(() => setIsLoading(false))
  }, [])

  return (<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "100px 0"}}>
    <EmptyTable title="Подтверджение начисления баллов" />
    {isLoading ? <PropagateLoader /> : "Готово"}
  </div>


  );
};

export default Confirm;
