import { useEffect } from "react";
import { isDev } from "../api";

const useDisableCopy = (params) => {
    useEffect(() => {
        const prevent = (e) =>{
             const val = e.target.innerText || e.target.value
             const condition = e.type === "copy" && ( val.includes("http://") || val.includes("https://"))
             if(condition || isDev) return val
             e.preventDefault()
            }
        window.addEventListener("contextmenu", prevent)
        window.addEventListener("copy", prevent)
        return () => {
            window.removeEventListener("contextmenu", prevent)
            window.removeEventListener("copy", prevent)
        }
    }, [])
}

export default useDisableCopy;
