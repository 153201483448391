import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addPresentation } from "../actions/presentations";
import SubmitButton from "./SubmitButton";
import { errorToast, successToast } from "./toasts";

// TO DO вставить этот компонент везде, в остальных местах кроме этого - копипаста #оптимизация
const AddPresenatation = props => {
  const { city, id, type, render, method, firstPay, percent, period, withoutMortgage } = props;
  const [fetching, setFetching] = useState(false);
  const [resultId, setResultId] = useState(false);
  const [finish, setFinish] = useState(false);
  useEffect(() => {
    if (props.presentation) {
      setFinish(true)
      setResultId(props.presentation?.id)
    }
  }, []);

  const handleSend = () => {
    setFetching(true);
    return addPresentation(id, city, type, method, firstPay, percent, period, withoutMortgage).then(
      data => {
        setFetching(false);
        setFinish(true);
        setResultId(data?.presentation?.id);
        successToast("Презентация успешно добавлена")
        return Promise.resolve(data)
      },
      error => {
        setFetching(false);
        setFinish(false);
        errorToast(error[0]?.message)
        return Promise.reject(error)
      },
    );
  };
  if (render) return render({
    fetching,
    finish,
    link: `/personal/presentations/edit/${resultId}`,
    handleSend,
  })
  return (
    <>
      {finish ? (
        <Link to={`/personal/presentations/edit/${resultId}`} className="flat__col-to-presentation">
          <button className="button button_view_outline-invert">
            К презентации
          </button>
        </Link>
      ) : (
        <SubmitButton
          className="button button_view_outline button_presentation"
          onClick={handleSend}
          isLoading={fetching}
        >
          Оформить презентацию
        </SubmitButton>
      )}
    </>
  );
};

export default AddPresenatation;
