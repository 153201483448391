import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { premiumUserAction } from '../../actions/user';
import Preloader from '../Preloader';
import { errorToast, standartErrorToast } from "../toasts";
import "./index.scss";
import NewsShare from './Share.js';


const Premium = (props) => {

  const [status, setStatus] = useState("fetching");

  useEffect(() => {
    premiumUserAction().then(
      (resp) => {
        const isPremium = resp.haveRights;
        setStatus("isPremium");

        if (!isPremium) {
          errorToast(<div>Доступ к странице запрещен</div>)
          setStatus("needRedirect");
        }

      },
      (error) => {
        standartErrorToast(error)
        setStatus("needRedirect");
      },
    );
  }, []);

  if (status === "fetching") {
    return <Preloader loading={true}/>
  }

  if (status === "needRedirect") {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    )
  }

  return (
    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Предбронирование Полис Приморский`} />
        <div className="news__container container">
          <h1 className="h1 news__title">ПАН Привилегия — программа лояльности для самых активных</h1>
          <img src={require('../../assets/images/news/premium.svg').default} alt="" className="news__promo-img" />

          <p className="news__text">
            Вам пришла секретная ссылка на эту страницу, потому что мы записали вас в закрытый клуб привилегий ПАН Партнер.
          </p>
          <p className="news__text">
            Всего 15 партнерских агентств вместе с вами вошли в клуб.<br />
            Ощутите преимущества привилегированного партнера.
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Стираем рамки
          </p>

          <p className="news__text">
            Когда-то мы первыми на рынке придумали акцию Авансирование +1, а через несколько лет эта акция стала нормой для рынка недвижимости Петербурга.<br />
            Для вас мы идем дальше и расширяем границы акции.<br />
            Выплаты по Авансирование+1 продлеваются до конца января.
          </p>

          <p className="news__text">
            В акции участвуют: СПб Реновация, Л1, ЛенРусСтрой, BONAVA, Газпромбанкинвест, AAG, Терминал Ресурс, Еврострой, Прок, Интер Групп, ГАЛС, Ойкумена, Самолет, Запстрой, Ильич, Инвестторг, Темп.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Подпиши сделки с ПАН по застройщикам, участвующим в акции, и забирай своё авансирование ещё по одной сделке по ЛЮБОМУ другому застройщику в декабре и январе.
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Расширенный список приоритетных застройщиков по карте Авансирование 1+1
          </p>

          <p className="news__text">
            Невозможное возможно. На индивидуальных условиях для вашего агентства еще 7 застройщиков присоединились к выплатам по акции Авансирование 1+1.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Авансируем: ЦДС, ЛСР, Лидер Групп, Арсенал, Евроинвест Девелопмент, Полис Групп,  Росстройинвест вместе с Северным Городом, LEGENDA, ПСК,  GLORAX Development и Строительным Трестом
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Турбо-авансирование всех сделок<br />за один рабочий час
          </p>

          <p className="news__text">
            Такого мы еще не делали, и организуем только для вас.<br />
            Получайте вознаграждение всех сделок из карты авансирования в течение одного часа.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Отправьте нам акт, проведите встречу с клиентом, переведите дыхание на часок — а вот и наша благодарность за плодотворную работу в виде ускоренного авансирования<span role="img" aria-label="flex">💪</span>
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Вы в приоритете
          </p>

          <p className="news__text">
            Вы самый желанный гость в Обучающем центре ПАН.
          </p>

          <p className="news__text">
            Зима — самое время накапливать знания, которые помогут вам в динамичном декабре, и станут двигателем продаж в будущем году.
          </p>

          <p className="news__text">
            Для вас и троих сотрудников вашего агентства мы зарезервировали места на всех мероприятиях в Обучающем центре ПАН до 31 января 2021 включительно.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Обучения от застройщиков, новые практики от тренеров школы риелторов ПАН, экскурсии, на которых вы еще не были. Скорее смотрите список мероприятий декабря и записывайтесь у координатора Алёны.
          </p>

          <p className="news__text italic">
            <span className="color-red">*</span>Запись на автобусную экскурсию — минимум за 3 дня, чтобы мы для сформировали для вас комфортную по количеству участников группу
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Персональные обучающие экскурсии с 50% скидкой
          </p>

          <p className="news__text">
            В октябре этого года мы запустили сервис, который взорвал наши телефоны и мессенджеры.<br />АвтоПАНОРАМА — персональные обучающие экскурсии по новостройкам с личным гидом:
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> 175 объектов на выбор и персональный маршрут поездки на комфортном авто
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> 3 часа интенсивного обучения объектам и их конкурентным преимуществам
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Тет-а-тет с личным гидом или с коллегами из агентства
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Сравнение локаций, застройщиков и ЖК
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Знакомство со стартами и фишками, которые помогают их продавать
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> 100% рабочие приемы продающих презентаций
          </p>

          <p className="news__text">
            Мы собрали весь опыт работы с новостройками, “упаковали” его в классную обучающую программу.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Только для вас и троих ваших коллег весь январь — скидка 50%
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> 3 локации вместо 1 на АвтоПАН
          </p>

          <p className="news__text">
            Для вас АвтоПАН стал еще удобнее. Теперь за одну поездку вы посетите 3 локации вместо одной, а значит — продемонстрируете больше ЖК клиентам, проведете с ними больше эффективного времени и с большей вероятностью закроете на сделку при поддержке нашего эксперта.
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Воспользоваться бонусом можно только один раз. Поставьте задачу продающим риелторам записаться на “дожимающую” экскурсию в декабре и поделитесь, удалось ли агенту выйти на сделку
          </p>

          <p className="h3 news__subtitle">
            <span role="img" aria-label="star">⭐️</span> Индивидуальный репетитор по продажам<br />в вашем агентстве
          </p>

          <p className="news__text">
            3 часа супер-концентрированного материала по продажам:
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> 20% теории, 80% практики:
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Чек-лист высококонверсионного звонка
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Как написать письмо и закрыть клиента на встречу в 1 действие
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Как провести встречу и договориться о сделке
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Живые кейсы
          </p>

          <p className="news__text">
            <span role="img" aria-label="check">✅</span> Сессия вопросов и ответов с тренером
          </p>

          <p className="news__text">
            <span role="img" aria-label="point">👉</span> Прокачайте компетенции своих сотрудников и выходите на новый уровень продаж
          </p>

        </div>
      </div>

    </section>
  )
};

export default Premium;
