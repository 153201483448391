import React from 'react';
import './index.scss';

const Policy = () => {
  return (
    <section className="policy">
      <div className="wrapper">
        <section className="promo">
          <h1 className="promo__title h1">Политика конфиденциальности</h1>
          <p className="promo__date">
            г. Санкт-Петербург
            <br />
            «01» января 2017 г.
          </p>
          <p className="promo__text">
            Настоящая Политика конфиденциальности персональных данных (далее –
            Политика конфиденциальности) действует в отношении всей информации,
            которую компания ООО “ПАН” расположенная на доменном имени
            www.panpartner.ru, может получить о Пользователе во время использования
            сайта panpartner.ru
          </p>
        </section>
        <section className="block block_01">
          <img
            src={require('../../assets/images/policy/01_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">01. Определение терминов</h4>
            <div className="block__container">
              <span className="block__counter">1.1.</span>
              <p>
                В настоящей Политике конфиденциальности используются следующие
                термины:
              </p>
              <p>
                1.1.1. «Администрация сайта компания ООО “ПАН” далее – Администрация
                сайта) » – уполномоченные сотрудники на управления сайтом, действующие
                от имени ООО “ПАН”, которые организуют и (или) осуществляет обработку
                персональных данных, а также определяет цели обработки персональных
                данных, состав персональных данных, подлежащих обработке, действия
                (операции), совершаемые с персональными данными.
              </p>
              <p>
                1.1.2. «Персональные данные» - любая информация, относящаяся к прямо
                или косвенно определенному или определяемому физическому лицу
                (субъекту персональных данных).
              </p>
              <p>
                1.1.3. «Обработка персональных данных» — любое действие (операция) или
                совокупность действий (операций), совершаемых с использованием средств
                автоматизации или без использования таких средств с персональными
                данными, включая сбор, запись, систематизацию, накопление, хранение,
                уточнение (обновление, изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
              </p>
              <p>
                1.1.4. «Конфиденциальность персональных данных» — обязательное для
                соблюдения Оператором или иным получившим доступ к персональным данным
                лицом требование не допускать их распространения без согласия субъекта
                персональных данных или наличия иного законного основания.
              </p>
              <p>
                1.1.5. «Пользователь сайта panpartner.ru (далее - Пользователь)» —
                лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее
                Сайт panpartner.ru
              </p>
              <p>
                1.1.6. «Cookies» — небольшой фрагмент данных, отправленный
                веб-сервером и хранимый на компьютере пользователя, который веб-клиент
                или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
                попытке открыть страницу соответствующего сайта.
              </p>
              <p>
                1.1.7.«IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
                построенной по протоколу IP.
              </p>
            </div>
          </div>
        </section>
        <section className="block block_02">
          <img
            src={require('../../assets/images/policy/02_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">02. Общие положения</h4>
            <div className="block__container">
              <span className="block__counter">2.1.</span>
              <p>
                Использование Пользователем сайта panpartner.ru означает согласие с
                настоящей Политикой конфиденциальности и условиями обработки
                персональных данных Пользователя.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.2.</span>
              <p>
                В случае несогласия с условиями Политики конфиденциальности
                Пользователь должен прекратить использование сайта panpartner.ru.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.3.</span>
              <p>
                Настоящая Политика конфиденциальности применяется только к сайту
                компании panpartner.ru. омпания ООО “ПАН” не контролирует и не несет
                ответственность за сайты третьих лиц, на которые Пользователь может
                перейти по ссылкам, доступным на сайте panpartner.ru.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.4.</span>
              <p>
                Администрация сайта не проверяет достоверность персональных данных,
                предоставляемых Пользователем сайт
              </p>
            </div>
          </div>
        </section>
        <section className="block block_03">
          <img
            src={require('../../assets/images/policy/03_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">03. Предмет политики конфиденциальности</h4>
            <div className="block__container">
              <span className="block__counter">3.1.</span>
              <p>
                Настоящая Политика конфиденциальности устанавливает обязательства
                Администрации сайта по неразглашению и обеспечению режима защиты
                конфиденциальности персональных данных, которые Пользователь
                предоставляет по запросу Администрации сайта при регистрации на сайте.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.2.</span>
              <p>
                Персональные данные, разрешённые к обработке в рамках настоящей
                Политики конфиденциальности, предоставляются Пользователем путём
                заполнения регистрационных форм на Сайте panpartner.ru и включают в
                себя следующую информацию:
              </p>
              <p>3.2.1. фамилию, имя, отчество Пользователя;</p>
              <p>3.2.2. контактный телефон Пользователя;</p>
              <p>3.2.3. адрес электронной почты (e-mail);</p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.3.</span>
              <p>
                ООО “ПАН” защищает Данные, которые автоматически передаются в процессе
                просмотра рекламных блоков и при посещении страниц, на которых
                установлен статистический скрипт системы ("пиксель"):
              </p>
              <ul>
                <li>— IP адрес;</li>
                <li>— информация из cookies;</li>
                <li>
                  — информация о браузере (или иной программе, которая осуществляет
                  доступ к показу рекламы);
                </li>
                <li>— время доступа;</li>
                <li>— адрес страницы, на которой расположен рекламный блок;</li>
                <li>— реферер (адрес предыдущей страницы).</li>
              </ul>
              <p>
                3.3.1. Отключение cookies может повлечь невозможность доступа к частям
                сайта, требующим авторизации.
              </p>
              <p>
                3.3.2. ООО “ПАН” осуществляет сбор статистики об IP-адресах своих
                посетителей. Данная информация используется с целью выявления и
                решения технических проблем, для контроля законности проводимых
                финансовых платежей.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.4.</span>
              <p>
                Любая иная персональная информация неоговоренная выше (история
                покупок, используемые браузеры и операционные системы и т.д.) подлежит
                надежному хранению и нераспространению, за исключением случаев,
                предусмотренных в п.п. 5.2. и 5.3. настоящей Политики
                конфиденциальности.
              </p>
            </div>
          </div>
        </section>
        <section className="block block_04">
          <img
            src={require('../../assets/images/policy/04_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">
              04. Цели сбора персональной информации пользователя
            </h4>
            <div className="block__container">
              <span className="block__counter">4.1.</span>
              <p>
                Персональные данные Пользователя Администрация сайта
                может использовать в целях:
              </p>
              <p>
                4.1.1. Идентификации Пользователя, зарегистрированного на сайте
                panpartner.ru
              </p>
              <p>
                4.1.2. Предоставления Пользователю доступа к персонализированным
                ресурсам Сайта.
              </p>
              <p>
                4.1.3. Установления с Пользователем обратной связи, включая
                направление уведомлений, запросов, касающихся использования Сайта,
                оказания услуг, обработка запросов и заявок от Пользователя.
              </p>
              <p>
                4.1.4. Определения места нахождения Пользователя для обеспечения
                безопасности, предотвращения мошенничества.
              </p>
              <p>
                4.1.5. Подтверждения достоверности и полноты персональных данных,
                предоставленных Пользователем.
              </p>
              <p>
                4.1.9. Предоставления Пользователю эффективной клиентской и
                технической поддержки при возникновении проблем связанных с
                использованием Сайта.
              </p>
              <p>
                4.1.10. Предоставления Пользователю с его согласия, обновлений
                продукции, специальных предложений, информации о ценах, новостной
                рассылки и иных сведений от имени ООО “ПАН” или от имени партнеров.
              </p>
              <p>
                4.1.11. Осуществления рекламной деятельности с согласия Пользователя.
              </p>
              <p>
                4.1.12. Предоставления доступа Пользователю на сайты или сервисы
                партнеров с целью получения продуктов, обновлений и услуг.
              </p>
            </div>
          </div>
        </section>
        <section className="block block_05">
          <img
            src={require('../../assets/images/policy/05_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">
              05. Способы и сроки обработки персональной информации
            </h4>
            <div className="block__container">
              <span className="block__counter">5.1.</span>
              <p>
                Обработка персональных данных Пользователя осуществляется без
                ограничения срока, любым законным способом, в том числе в
                информационных системах персональных данных с использованием средств
                автоматизации или без использования таких средств.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.2.</span>
              <p>
                Пользователь соглашается с тем, что Администрация сайта вправе
                передавать персональные данные третьим лицам, в частности, курьерским
                службам, организациями почтовой связи, операторам электросвязи,
                исключительно в целях выполнения заказа Пользователя, оформленного на
                Сайте panpartner.ru.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.3.</span>
              <p>
                Персональные данные Пользователя могут быть переданы уполномоченным
                органам государственной власти Российской Федерации только по
                основаниям и в порядке, установленным законодательством Российской
                Федерации.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.4.</span>
              <p>
                При утрате или разглашении персональных данных Администрация сайта
                информирует Пользователя об утрате или разглашении персональных
                данных.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.5.</span>
              <p>
                Администрация сайта принимает необходимые организационные и
                технические меры для защиты персональной информации Пользователя от
                неправомерного или случайного доступа, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных
                неправомерных действий третьих лиц.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.6.</span>
              <p>
                Администрация сайта совместно с Пользователем принимает все
                необходимые меры по предотвращению убытков или иных отрицательных
                последствий, вызванных утратой или разглашением персональных данных
                Пользователя.
              </p>
            </div>
          </div>
        </section>
        <section className="block block_06">
          <img
            src={require('../../assets/images/policy/06_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">06. Обязательства сторон</h4>
            <div className="block__container">
              <span className="block__counter">6.1.</span>
              <p>Пользователь обязан:</p>
              <p>
                6.1.1. Предоставить информацию о персональных данных, необходимую для
                пользования Сайтом.
              </p>
              <p>
                6.1.2. Обновить, дополнить предоставленную информацию о персональных
                данных в случае изменения данной информации.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">6.2.</span>
              <p>Администрация сайта обязана:</p>
              <p>
                6.2.1. Использовать полученную информацию исключительно для целей,
                указанных в п. 4 настоящей Политики конфиденциальности.
              </p>
              <p>
                6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
                разглашать без предварительного письменного разрешения Пользователя, а
                также не осуществлять продажу, обмен, опубликование, либо разглашение
                иными возможными способами переданных персональных данных
                Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики
                Конфиденциальности.
              </p>
              <p>
                6.2.3. Принимать меры предосторожности для защиты конфиденциальности
                персональных данных Пользователя согласно порядку, обычно
                используемого для защиты такого рода информации в существующем деловом
                обороте.
              </p>
              <p>
                6.2.4. Осуществить блокирование персональных данных, относящихся к
                соответствующему Пользователю, с момента обращения или запроса
                Пользователя или его законного представителя либо уполномоченного
                органа по защите прав субъектов персональных данных на период
                проверки, в случае выявления недостоверных персональных данных или
                неправомерных действий.
              </p>
            </div>
          </div>

        </section>
        <section className="block block_07">
          <img
            src={require('../../assets/images/policy/07_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">07. Ответственность сторон</h4>
            <div className="block__container">
              <span className="block__counter">7.1.</span>
              <p>
                Администрация сайта, не исполнившая свои обязательства, несёт
                ответственность за убытки, понесённые Пользователем в связи с
                неправомерным использованием персональных данных, в соответствии с
                законодательством Российской Федерации, за исключением случаев,
                предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
                Конфиденциальности.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">7.2.</span>
              <p>
                В случае утраты или разглашения Конфиденциальной информации
                Администрация сайта не несёт ответственность, если данная
                конфиденциальная информация:
              </p>
              <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
              <p>
                7.2.2. Была получена от третьей стороны до момента её получения
                Администрацией сайта.
              </p>
              <p>7.2.3. Была разглашена с согласия Пользователя.</p>
            </div>
          </div>
        </section>
        <section className="block block_08">
          <img
            src={require('../../assets/images/policy/08_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">08. Разрешение споров</h4>
            <div className="block__container">
              <span className="block__counter">8.1.</span>
              <p>
                До обращения в суд с иском по спорам, возникающим из отношений между
                Пользователем сайта Интернет-магазина и Администрацией сайта,
                обязательным является предъявление претензии (письменного предложения
                о добровольном урегулировании спора).
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">8.2.</span>
              <p>
                Получатель претензии в течение 30 календарных дней со дня получения
                претензии, письменно уведомляет заявителя претензии о результатах
                рассмотрения претензии.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">8.3.</span>
              <p>
                При не достижении соглашения спор будет передан на рассмотрение в
                судебный орган в соответствии с действующим законодательством
                Российской Федерации.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">8.4.</span>
              <p>
                К настоящей Политике конфиденциальности и отношениям между
                Пользователем и Администрацией сайта применяется действующее
                законодательство Российской Федерации.
              </p>
            </div>
          </div>
        </section>
        <section className="block block_09">
          <img
            src={require('../../assets/images/policy/09_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">09. Дополнительные условия</h4>
            <div className="block__container">
              <span className="block__counter">9.1.</span>
              <p>
                Администрация сайта вправе вносить изменения в настоящую Политику
                конфиденциальности без согласия Пользователя.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">9.2.</span>
              <p>
                Новая Политика конфиденциальности вступает в силу с момента ее
                размещения на Сайте интернет-магазина, если иное не предусмотрено
                новой редакцией Политики конфиденциальности.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">9.3.</span>
              <p>
                Действующая Политика конфиденциальности размещена на странице по
                адресу https://panpartner.ru/policy
              </p>
            </div>
          </div>
        </section>

        <p className="update-date">Обновлено «1» января 2017 г.</p>
      </div>
    </section>
  );
};

export default Policy;
