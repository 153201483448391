import React from 'react';
import { getUrl } from '../../../api';
const FloorPlan = ({ data: { object }, settings: { layout_description, view_for_windows }, isCommercial }) => (
  <div className="presentation__info pdf-block">
    <div className={`presentation__box ${layout_description.disabled == 'Y' ? 'hidden' : 'mb-25'}`} data-id="layout_description">
      <div className="presentation__title">{isCommercial ? "Объект" : "Квартира"} на этаже</div>
      <div className="presentation__floor-plan presentation__bordered-block">
        <img src={getUrl(object.floorPlan?.src)} alt="" />
      </div>
    </div>
    {!isCommercial && <div className={`presentation__box ${view_for_windows.disabled == 'Y' ? 'hidden' : ''}`} data-id="view_for_windows">
      <div className="presentation__title">Направление вида из окон</div>
      <div className="presentation__floor-plan presentation__floor-plan_full-img">
        <img src={getUrl(object.viewFromWindow?.src)} alt="" />
      </div>
    </div>}
  </div>
)

export default FloorPlan;