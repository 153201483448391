import React, {memo, useEffect, useMemo, useState} from "react";
import InputMask from "react-input-mask";
import SelectFilter from "../../components/FilterRealty/SelectFilter";
import Preloader from "../../components/Preloader";
import Tag from "../../components/Public/markup/Tag";
import SubmitButton from "../../components/SubmitButton";
import Dropdown from "../../components/core/Dropdown";
import NewAlert from "../../components/core/NewAlert";
import { standartErrorToast, successToast } from "../../components/toasts";
import useUniversalForm from "../../hooks/useUniversalForm";
import './index.scss';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import {Link, useParams} from "react-router-dom";
import FlexibleTag from "./FlexibleTag";
import { formatDate } from "../../utils";
import {AptFixationIcon, FixationAlarmIcon, FixationImportantIcon} from "../../components/Public/markup/icons";
import {useModal} from "react-modal-hook";
import PanModal from "../../components/core/PanModal";
import {getUrl} from "../../api";
import AdvantagesFixation from "./AdvantagesFixation";
import FixateClient from "../../components/forms/FixateClient";
import useFileManager from "../../hooks/useFileManager";
import FileUploader from "../../components/FileUploader";
import Modal from "../../components/core/Modal";
import Forgot from "../../components/forms/Forgot";
import Button from "../../components/core/Button";
import SimpleBar from "simplebar-react";

registerLocale('ru', ru);
setDefaultLocale('ru');

const emptyClient = {
    email: "",
    lastName: "",
    name: "",
    patronymic: "",
    phone: "",
    id: 0
}
const emptyUniFormClient = {
    clientEmail: "",
    clientLastName: "",
    clientName: "",
    clientPatronymic: "",
    clientPhone: "",
    clientId: 0,
    clientBirthday: ""
}
const clientToUniForm = cl => ({
    clientEmail: cl.email ? cl.email : "",
    clientLastName: cl.lastName,
    clientName: cl.name,
    clientPatronymic: cl.patronymic,
    clientPhone: cl.phone,
    clientId: cl.id,
    clientBirthday: ""
})

const filterAndMap = (arr, fn) => {
    const tmp = [];
    for (const item of arr) {
        const value = fn(item);
        value && tmp.push(value);
    }
    return tmp;
}

const FixationForm = memo(props => {
    const { userInfo, openBuilderModal, showContent, formId, city, loadCondition } = props

    const initialObjectKey = city === "msk" ? "blocks" : "builders"
    const initialObjectCodeName = city === "msk" ? "block" : "builders"
    const queryParams = new URLSearchParams(window.location.search);
    const builderId = queryParams.get('builderId');
    const blockId = queryParams.get('blockId');
    const cityParam = queryParams.get('city');

    const [selectedItems, setSelectedItems] = useState([])

    useEffect(() => {
        if (builderId && cityParam === 'spb') {
            setSelectedItems([Number(builderId)])
        }
        if (blockId && cityParam === 'msk') {
            setSelectedItems([Number(blockId)])
        }
    }, [])

    const [successMessage, setSuccessMessage] = useState()
    const requiredFields = {
        clientLastName: "Введите фамилию клиента",
        clientName: "Введите имя клиента",
        clientPhone: "Введите телефон клиента",
        ...(selectedItems?.length ? []: { selected: `Выберите ${city === "msk" ? "ЖК" : "застройщика"}` })
    }
    const {
        form,
        getValue,
        isLoading,
        isSending,
        response: {
            clients,
            fieldsDescription,
            ...resp
        },
        setValue,
        setCheckboxValue,
        setValues,
        send,
        convertArrayToUniformData,
        errors,
        forcedSetValue
    } = useUniversalForm({
        formId,
        city,
        defaultValues: {
            name: `${userInfo.name} ${userInfo.lastName}`,
            phone: userInfo.phone,

        },
        defaultCheckboxes: ["personal_data", "familiar_with_advertising", "familiar_with_builder"],
    }, loadCondition)
    // console.log(response)
    const selectClient = id => {
        const client = clients.find(client => client.id === id)
        setValues(id ? clientToUniForm(client) : emptyUniFormClient)
    }
    const normalizedClients = useMemo(() => {
        if (!clients) return [];
        const tmpClients = [{ ...emptyClient, name: "Ничего не выбрано", lastName: "" }, ...clients]
        return tmpClients.map(cl => ({ ...cl, title: `${cl.name} ${cl.lastName}` }))
    }, [clients])


    const [openPassportModal, hidePassportModal] = useModal(() => (
        <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick close={hidePassportModal}>
            <div className="crm-table__sf-controlsmodaltitle">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="8.5" stroke="#2C2E3E" /><line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round" /><circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E" /></svg>
                Застройщики для которых важен паспорт:
            </div>
            <SimpleBar className="search-result" style={{ maxHeight: 200, display: "block", position: 'relative', boxShadow: 'none' }}>
                {resp.builders && filterAndMap(resp.builders, (builder) => {
                    if(!builder.passportFix) return
                    return(
                        <div style={{marginTop: 5, marginBottom: 5}}>{builder.name}</div>
                    )
                })}
            </SimpleBar>

            <div className="crm-table__sf-controlsmodalbtns">
                <Button buttonType="line" onClick={hidePassportModal}>Понятно</Button>
            </div>
        </Modal>
    ), [resp.builders]);

    const submit = () => {
        send(
            getValue("clientBirthday") ? { clientBirthday: formatDate(getValue("clientBirthday")) } : false,
            convertArrayToUniformData(initialObjectCodeName, selectedItems),
            requiredFields
        ).then(resp => {
            setSuccessMessage(resp.message)
            successToast(resp.message)
            setSelectedItems([])
            setTimeout(setSuccessMessage, 5000)
            setValues({ ...emptyUniFormClient, comment: "" })
        }, standartErrorToast)
    }

    const [openModal, closeModal] = useModal(() => (
      <PanModal title={'Преимущества фиксации'} closeModal={closeModal}>
          <AdvantagesFixation/>
      </PanModal>
    ), []);

    const onFilesChange = files => forcedSetValue(files, "passport")

    const { files, uploadFiles, deleteFile, editFileName, } = useFileManager({
      maxFiles: 10,
      onFilesChange
    });

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = (e.clipboardData || window.clipboardData).getData('text');
        let sanitizedData = pasteData.replace(/[^\d]/g, '');
        if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
            sanitizedData = sanitizedData.substring(1);
        } else if (sanitizedData.startsWith('8')) {
            sanitizedData = sanitizedData.substring(1);
        } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
            sanitizedData = sanitizedData.substring(2);
        }
        setValue(sanitizedData, "clientPhone")
    };

    if (isLoading && !isSending && loadCondition) return <Preloader loading={true} />;
    if (!showContent) return null;
    return <div className="fixations__container">
        <div className="fixations__settings-block">
            <p className="h6">Ваши контакты</p>

            <div className="hp__form-item">
                <label className="hp__form-label text" style={{ opacity: "0.5" }}>
                    Имя и фамилия
                </label>
                <label
                    className="input input_width_available input_type_form"
                >
                    <input
                        className={'input__control'}
                        style={{ background: "#fff", border: "1px solid #DFE1E9" }}
                        value={`${userInfo.name} ${userInfo.lastName}`}
                        disabled
                    />
                </label>
            </div>

            <div className="fixations__block-dash">
                <label className="input input_width_available input_type_form">
                    <span className="input__label" style={{ opacity: "0.5" }}>Телефон</span>
                    <InputMask
                        className="input__control input input_type_form"
                        name="phone"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar="_"
                        value={userInfo.phone}
                        style={{ background: "#fff", border: "1px solid #DFE1E9" }}
                        disabled
                    />
                </label>
            </div>

            <p className="h6">Контакты клиента</p>

            <div className="hp__form-item">
                <label className="hp__form-label text">
                    Заполнить данные автоматически
                </label>
                <label
                    className="input input_width_available input_type_form"
                >
                    <Dropdown
                        wrapperClass="custom-select_sf-filter custom-select_fixations"
                        withSearch={normalizedClients?.length > 4}
                        options={normalizedClients}
                        placeholder="Выберите клиента"
                        value={getValue("clientId")}
                        onChange={selectClient}
                        searchPlaceholder="Введите ФИО клиента"
                    />
                </label>
            </div>

            <div className="hp__form-item">
                <label className="hp__form-label text">
                    <span>Фамилия<span className="is-red-text sell__required">*</span></span>
                </label>
                <label
                    className="input input_width_available input_type_form"
                >
                    <input
                        className={`input__control${errors.clientLastName ? " input__error" : ""}`}
                        value={getValue("clientLastName")}
                        onChange={e => setValue(e.target.value, "clientLastName")}
                    />
                </label>
                {errors.clientLastName && (
                    <span className="input__error-label">
                        {errors.clientLastName}
                    </span>
                )}
            </div>

            <div className="hp__form-item">
                <label className="hp__form-label text">
                    <span>Имя<span className="is-red-text sell__required">*</span></span>
                </label>
                <label
                    className="input input_width_available input_type_form"
                >
                    <input
                        className={`input__control${errors.clientPhone ? " input__error" : ""}`}
                        value={getValue("clientName")}
                        onChange={e => setValue(e.target.value, "clientName")}
                    />
                </label>
                {errors.clientName && (
                    <span className="input__error-label">
                        {errors.clientName}
                    </span>
                )}
            </div>

            <div className="hp__form-item">
                <label className="hp__form-label text">
                    Отчество
                    {fieldsDescription?.patronymic && <span
                        className="hp__form-label-rules"
                        onClick={() => openBuilderModal(fieldsDescription.patronymic)}
                    >
                        Почему это важно
                    </span>}
                </label>
                <label
                    className="input input_width_available input_type_form"
                >
                    <input
                        className={'input__control'}
                        value={getValue("clientPatronymic")}
                        onChange={e => setValue(e.target.value, "clientPatronymic")}
                    />
                </label>
            </div>

            <div>
                <label className="input input_width_available input_type_form">
                    <span className="input__label">
                        <span>Телефон<span className="is-red-text sell__required">*</span></span>
                    </span>
                    <InputMask
                        className={`input__control input input_type_form${errors.clientPhone ? " input__error" : ""}`}
                        name="phone"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar="_"
                        value={getValue("clientPhone")}
                        onChange={e => setValue(e.target.value, "clientPhone")}
                        onPaste={handlePaste}
                    />
                </label>
                {errors.clientPhone && (
                    <span className="input__error-label">
                        {errors.clientPhone}
                    </span>
                )}
            </div>

            <div className="hp__form-item">
                <label className="hp__form-label text">
                    E-mail
                    {fieldsDescription?.email && <span
                        className="hp__form-label-rules"
                        onClick={() => openBuilderModal(fieldsDescription.email)}
                    >
                        Почему это важно
                    </span>}
                </label>
                <label className="input input_width_available input_type_form" >
                    <input
                        className={'input__control'}
                        value={getValue("clientEmail")}
                        onChange={e => setValue(e.target.value, "clientEmail")}
                    />
                </label>
            </div>
            <div className="hp__form-item">
                <label className="hp__form-label text">
                    Дата рождения
                    {fieldsDescription?.birthday && <span
                        className="hp__form-label-rules"
                        onClick={() => openBuilderModal(fieldsDescription.birthday)}
                    >
                        Почему это важно
                    </span>}
                </label>
                <label className="input input_width_available input_type_form fixation-dp-fix">
                    <DatePicker
                        dateFormat="dd.MM.yyyy"
                        maxDate={new Date()}
                        showTimeSelect={false}
                        todayButton="Сегодня"
                        dropdownMode="select"
                        autoComplete="off"
                        className="input__control"
                        onChange={v => setValue(v, "clientBirthday")}
                        shouldCloseOnSelect
                        showYearDropdown
                        showMonthDropdown
                        selected={getValue("clientBirthday")}
                    />
                </label>
            </div>


            <div className="hp__form-item fixations__block-dash">
                <label className="hp__form-label text">
                    Паспорт клиента
                    <span
                      className="hp__form-label-rules"
                      onClick={() => openPassportModal()}
                    >
                        Почему это важно
                    </span>
                </label>



                <div className="modal-form__row">
                    <div className="modal-form__item">
                        <label
                          className="input input_type_upload-btn input_width_available"
                          htmlFor="modal-reservation-file-input"
                        >
                            <input
                              className="input__control"
                              type="file"
                              multiple
                              id="modal-reservation-file-input"
                              name="file"
                              onChange={uploadFiles}
                            />
                            Прикрепить документ
                        </label>
                        <div
                          className="modal-form__files js-upload-container"
                          data-id="modal-reservation-file-input"
                        >
                            {files.map((file, i) => (
                              <div data-num="0" className="file-up" key={file.fakeId}>
                                  <div className="file-up__inner">
                                      <svg className="icon file-up__icon">
                                          <use xlinkHref="#deals-01"></use>
                                      </svg>
                                      <span className="file-up__name">{file?.fileName}</span>
                                  </div>
                                  <button className="button button_view_round-control file-up__del"
                                      onClick={() => deleteFile(i)}
                                  >
                                      <svg className="icon">
                                          <use xlinkHref="#x"></use>
                                      </svg>
                                  </button>
                              </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <p className="h6">Информация о фиксации</p>
            <div className="hp__form-item">
                <label className="hp__form-label text">
                    <span>{city === "msk" ? "Название ЖК" : "Застройщики (не более 15)"}<span className="is-red-text sell__required">*</span></span>
                </label>

                <label
                    className="input input_width_available input_type_form"
                >
                    <SelectFilter
                        items={resp[initialObjectKey]}
                        isAutocomplete
                        filterValues={selectedItems}
                        onChange={(val) => setSelectedItems(val)}
                        data={{
                            title: city === "msk" ? "Выберите ЖК" : "Выберите застройщика",
                            identity: initialObjectKey,
                            autocompleteTitle: city === "msk" ? "Введите название ЖК" : "Введите название застройщика",
                        }}
                        titleKey="name"

                        wrapperClass={`select-filter-fix custom-select_sf-filter custom-select_sf-filter-fixations${!selectedItems.length && !!Object.entries(errors).length ? " drop__error" : ""}`}
                        disabledIds={selectedItems.length > 14 && city !== "msk" ? filterAndMap(resp[initialObjectKey], item => selectedItems.includes(item.id) ? false : item.id) : []}
                    />
                </label>
                {!selectedItems.length && !!Object.entries(errors).length && (
                    <span className="input__error-label">
                        {city === "msk" ? "Выберите ЖК" : "Выберите застройщика"}
                    </span>
                )}
                <div style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
                    {selectedItems.map(id => {
                        const item = resp[initialObjectKey]?.find?.(item => item.id === id)
                        const fixCondition = city === "msk" ? false : item?.fixConditions;
                        return <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5 }}>
                            <FlexibleTag title={item?.name} removeBadge={() => setSelectedItems(prev => prev.filter(selectedId => selectedId !== id))} />
                            {fixCondition && <span className="rules-text" onClick={() => openBuilderModal({ title: `Условия фиксации ${item.name}`, text: fixCondition })}>
                                Условия фиксации
                            </span>}
                        </div>
                    })}
                </div>
            </div>

            <div className="modal-form__row">
                <div className="modal-form__item">
                    <label
                        className="textarea textarea_type_form"
                        htmlFor="modal-reservation-commentary"
                    >
                        <span className="textarea__label">Комментарий</span>
                        <textarea
                            style={{minHeight: 132}}
                            className="textarea__control"
                            id="modal-reservation-commentary"
                            placeholder="Укажите дополнительную информацию"
                            value={getValue("comment")}
                            onChange={e => setValue(e.target.value, "comment")}
                        />
                    </label>
                </div>
            </div>
            <div className="checkbox checkbox_view_red fixations__checkboxs">
                <div className="checkbox__box">
                    <input
                        className="checkbox__control"
                        type="checkbox"
                        id="data-id"
                        name="remember"
                        checked={!!getValue("personal_data", true)}
                        onChange={e => setCheckboxValue(e, "personal_data")}

                    />
                    <label className="checkbox__input" htmlFor="data-id" />
                    <div className="checkbox__marker" />
                </div>
                <label className="checkbox__label" htmlFor="data-id" style={{ marginBottom: -3 }}>
                    Отправляя заявку вы соглашаетесь на обработку
                    <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link link-red"> персональных данных</Link>
                </label>
            </div>
            <div className="checkbox checkbox_view_red fixations__checkboxs">
                <div className="checkbox__box">
                    <input
                        className="checkbox__control"
                        type="checkbox"
                        id="data-adv"
                        name="remember"
                        checked={!!getValue("familiar_with_advertising", true)}
                        onChange={e => setCheckboxValue(e, "familiar_with_advertising")}

                    />
                    <label className="checkbox__input" htmlFor="data-adv" />
                    <div className="checkbox__marker" />
                </div>
                <label className="checkbox__label" htmlFor="data-adv">
                    Ознакомлен (-а) с регламентом по рекламе
                </label>
            </div>
            <div className="checkbox checkbox_view_red fixations__checkboxs">
                <div className="checkbox__box">
                    <input
                        className="checkbox__control"
                        type="checkbox"
                        id="data-reglament"
                        name="remember"
                        checked={!!getValue("familiar_with_builder", true)}
                        onChange={e => setCheckboxValue(e, "familiar_with_builder")}
                    />
                    <label className="checkbox__input" htmlFor="data-reglament" />
                    <div className="checkbox__marker" />
                </div>
                <label className="checkbox__label" htmlFor="data-reglament" style={{ marginBottom: -3 }}>
                    Ознакомлен (-а) с регламентом взаимодействия с застройщиком
                </label>
            </div>
            {successMessage && <NewAlert
                title={successMessage}
                bgColor="#DFF5E0"
                iconColor="#2CBA2E"
                oneLine
            />}
            <SubmitButton
                className="button button_view_default"
                type="button"
                isLoading={isSending}
                onClick={submit}
            >
                <span>Отправить заявку</span>
            </SubmitButton>
        </div>
        <div className="sf__detail-col sf__sticky_fixations" style={{ alignSelf: 'start', marginTop: 10, maxWidth: 308, position: 'absolute', right: '-10px', height: '100%' }}>
            <NewAlert
              title="Преимущества фиксации"
              text="Зачем фиксировать клиента"
              link="читайте здесь."
              bgColor="#f6f7f8"
              iconColor="#2CBA2E"
              linkColor="#2CBA2E"
              openAppModal
              customModalLink={openModal}
            />
            <br/>
            <NewAlert
                text="Получайте уведомление об изменении статуса ваших фиксаций в нашем"
                link="мобильном приложении"
                bgColor="#f6f7f8"
                iconColor="#2CBA2E"
                linkColor="#2CBA2E"
                customIcon={<FixationAlarmIcon color="#2CBA2E"/>}
                openAppModal
            />
            <br/>
            <div className="sf__sticky">
            <NewAlert
              title="Важно"
              text="Для фиксации супругов и других родственников клиента, необходимо отправить заявку на фиксацию по каждому из них."
              bgColor="#FFE6E5"
              customIcon={<FixationImportantIcon color="#FF2D23"/>}
            />
            </div>
        </div>
    </div>
})
export default FixationForm;
