import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPdf, sendPresentation } from "../../actions/presentations";
import { media } from '../../utils';
import BotBar from '../BotBar/BotBar';
import Dropdown from "../core/Dropdown";
import NewClient from "../forms/NewClient";
import SubmitButton from "../SubmitButton";
import { errorToast, infoToast, standartErrorToast } from "../toasts";

const SendPresentation = (props) => {
  const {
    presentationIds,
    city,
    fetch,
    clients,
    clientId, 
    send,
    hide,
    attachClient,
  } = props;

  const [showClientForm, setShowClientForm] = useState(false);
  const [client, setClient] = useState(0);
  // const [url, setUrl] = useState('');
  const [inProcess, setInProcess] = useState(false);

  useEffect(() => {
    if (clientId) setClient(clientId);
    else if (clients?.[0]) setClient(clients[0].id);
  }, []);

  const handleAddClient = (id) => {
    fetch().then(() => {
      setShowClientForm(false);
      setClient(id);
    });
  };

  const onSend = () => {
    setInProcess(true);
    send(presentationIds, [client], [city]).then(
      (data) => {
        setInProcess(false);
        infoToast(<div>{data.message}</div>)
        hide();
        fetch();
      },
      (error) => {
        setInProcess(false);
        errorToast(<div>{error?.[0]?.message}</div>)
      }
    );
  };

  const clientPhone = () => {
    if (!clients || !clients.length) return "";

    let person = clients.find((item) => item.id == client);
    if (!person) return "";

    return person.phone.replace(/[+() -]/g, "");
  };

  const socialLinks = (url) => ({
    whatsapp: `https://web.whatsapp.com/send?phone=${clientPhone()}&text=${url}`,
    telegram: `tg://msg_url?url=${url}`,
    viber: `viber://pa?chatURI=${clientPhone()}&text=${url}`,
  });

  const onSubmit = (type) => {
    switch (type) {
      case "mail":
        onSend();
        break;
      default:
        getPdf({id: presentationIds}).then((data) => {
          presentationIds.forEach((id) => {
            attachClient(client, id, city, true);
          });
          let host =
            window.location.host === "panpartner.ru" ||  window.location.host === 'panpartner.ru'
              ? "https://panpartner.ru"
              : "https://dev2.panpartner.ru";
          let url = `${host}${data.filePath}`;
          window.open(socialLinks(url)[type], "_blank");
        },
        standartErrorToast);
        break;
    }
  };

  const [path, setPath] = useState(null);

  // useEffect(() => {
  //   console.log('presentationIds, client', presentationIds, client)
  //   if(!client) return
  //   getPdf({ id: presentationIds}).then((data) => {
  //     presentationIds.forEach((id) => {
  //       attachClient(client, id, city,  true); 
  //     });
  //     let host = 
  //       window.location.host === "panpartner.ru" ||  window.location.host === 'panpartner.ru'
  //         ? "https://panpartner.ru"
  //         : "https://dev2.panpartner.ru";
  //     let url = `${host}${data.filePath}`;
  //     setPath(url)
  //     // window.open(socialLinks(url)[type], "_blank");
  //   },
  //   standartErrorToast);
  // }, [presentationIds, client])

  const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);

  return (
    <>
      <h2 className="modal__title">Отправить клиенту</h2>
      <div className="modal-form">
        <div className="modal-form__grid">
          <div className="modal-form__col">
            <div className="modal-form__row">
              <div className="modal-form__wrap">
                <div className="select select_type_form">
                  <label className="custom-select-label">Клиент</label>
                  <Dropdown
                    options={clients}
                    value={client}
                    onChange={(value) => {
                      setClient(value);
                    }}
                  />
                </div>
                {!showClientForm && (
                  <div
                    className="modal-form__add"
                    style={{ width: "auto" }}
                    onClick={() => setShowClientForm(true)}
                  >
                    <button className="button button_type_add"></button>
                    <span>Добавить клиента</span>
                  </div>
                )}
                {showClientForm && <NewClient onAdd={handleAddClient} />}
                <div className="modal-form__send">
                  <SubmitButton
                    className="button button_view_default"
                    disabled={client === 0}
                    isLoading={inProcess}
                    onClick={() => onSubmit("mail")}
                  >
                    <span>Отправить по почте</span>
                  </SubmitButton>
                </div>
              </div>
            </div>
            <div className="modal-form_row" style={{ padding: media('isMobile') ? 0 : "26px 162px" }}>
              <div
                className="send-filter"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 className="send-filter__label" style={{ margin: "15px 0" }}>
                  Отправить в мессенджерах
                </h4>

                {media('isMobile') ?
                <>
                  <ul
                    className="subscribe__socials"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <li
                      className="subscribe__socials-item"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <div
                        className="subscribe__socials-link"
                        style={{
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <a
                          href={`https://wa.me/${clientPhone()}?text=${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <svg
                            className="icon icon_social-wa"
                            style={{ transform: "none" }}
                          >
                            <use xlinkHref="#social-wa"></use>
                          </svg>
                        </a>
                      </div>
                    </li>

                    <li
                      className="subscribe__socials-item"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <div
                        className="subscribe__socials-link"
                        style={{
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <a
                          href={`tg://msg_url?url=${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <svg
                            className="icon icon_social-tg"
                            style={{ transform: "none" }}
                          >
                            <use xlinkHref="#social-tg"></use>
                          </svg>
                        </a>
                      </div>
                    </li>

                    <li
                      className="subscribe__socials-item"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <div
                        className="subscribe__socials-link"
                        style={{
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                      <a
                        href={`viber://pa?chatURI=${clientPhone()}&text=${path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'flex' }}
                      >
                        <svg
                          className="icon icon_social-viber"
                          style={{ transform: "none" }}
                        >
                          <use xlinkHref="#social-viber"></use>
                        </svg>
                      </a>
                      </div>
                    </li>
                  </ul>
                </> :

                <ul
                  className="subscribe__socials"
                  style={{ justifyContent: "flex-start" }}
                >
                  <li
                    className="subscribe__socials-item"
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => !isMobile && onSubmit("whatsapp")}
                  >
                    <div
                      className="subscribe__socials-link"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      // href={`https://wa.me/${clientPhone()}?text=${url}`}
                    >
                      {isMobile ?
                        <a
                          href={`https://wa.me/${clientPhone()}?text=${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <svg
                           className="icon icon_social-wa"
                           style={{ transform: "none" }}
                          >
                            <use xlinkHref="#social-wa"></use>
                          </svg>
                        </a> :

                        <svg
                         className="icon icon_social-wa"
                         style={{ transform: "none" }}
                        >
                         <use xlinkHref="#social-wa"></use>
                        </svg>
                      }
                    </div>
                  </li>
                  <li
                    className="subscribe__socials-item"
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => !isMobile && onSubmit("telegram")}
                  >
                    <div
                      className="subscribe__socials-link"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      // href={`tg://msg_url?url=${url}`}
                    >
                      {isMobile ?
                        <a
                          href={`tg://msg_url?url=${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <svg
                            className="icon icon_social-tg"
                            style={{ transform: "none" }}
                          >
                            <use xlinkHref="#social-tg"></use>
                          </svg>
                        </a> :

                        <svg
                          className="icon icon_social-tg"
                          style={{ transform: "none" }}
                        >
                          <use xlinkHref="#social-tg"></use>
                        </svg>
                      }
                    </div>
                  </li>
                  <li
                    className="subscribe__socials-item"
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => !isMobile && onSubmit("viber")}
                  >
                    <div
                      className="subscribe__socials-link"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      // href={`viber://pa?chatURI=${clientPhone()}&text=${url}`}
                    >
                      {isMobile  ?
                        <a
                          href={`viber://pa?chatURI=${clientPhone()}&text=${path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex' }}
                        >
                          <svg
                            className="icon icon_social-viber"
                            style={{ transform: "none" }}
                          >
                            <use xlinkHref="#social-viber"></use>
                          </svg>
                        </a> :

                        <svg
                          className="icon icon_social-viber"
                          style={{ transform: "none" }}
                        >
                          <use xlinkHref="#social-viber"></use>
                        </svg>
                      }
                    </div>
                  </li>
                </ul>
              }

              </div>
            </div>

            <div className="modal-form_row">
              <BotBar customText={`Моментальные уведомления о\u00A0просмотре презентации клиентом в нашем телеграм-боте`} />
            </div>
            {/* <div className="modal-form__row">
							<div className="modal-form__wrap">
								<label
									className="input input_width_available input_type_form"
									htmlFor="gen-link"
								>
									<span className="input__label">
										Для вас сгененрирована ссылка
									</span>
									<input
										className="input__control"
										id="gen-link"
										defaultValue="https://disk.yandex.ru/client/disk/PAN"
									/>
								</label>
								<div className="modal-form__send">
									<button className="button button_view_another">
										Отправить по почте
									</button>
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  send: (ids, clientIds, city) =>
    dispatch(sendPresentation(ids, clientIds, city)),
});

export default connect(null, mapDispatchToProps)(SendPresentation);
