
import React, { useEffect, useState } from 'react';
import { themeColors } from '../../Constructor/constructorUtils';

const YaMap = ({ cords = [], mapCenter = [], markerColor, bigMarker }) => {
    const [Maps, setMaps] = useState(null)
    const [ymaps, setYmaps] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);

    const dynamicMapImport = async () => {
        const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
        setMaps({ YMaps, Map, Placemark, RulerControl })
    };
    useEffect(() => {
        if(!cords) return 
        dynamicMapImport()
    }, [cords])

    useEffect(() => {
        if (!mapInstance || !ymaps) return

        const svgString = `<svg width="25" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 40S25 24.873 25 13.044C25 5.839 19.403 0 12.5 0 5.596 0 0 5.84 0 13.043 0 25.155 12.5 40 12.5 40zm5.682-26.25c0-3.451-2.545-6.25-5.682-6.25-3.137 0-5.682 2.799-5.682 6.25S9.363 20 12.5 20c3.137 0 5.682-2.799 5.682-6.25z" fill="${themeColors[markerColor] || themeColors.red}"/></svg>`;

        const placemarkTemplate = ymaps.templateLayoutFactory.createClass(svgString)
        const placeMaprk = new ymaps.Placemark(cords, {
        }, {
            iconLayout: placemarkTemplate,
            iconOffset: [-12.5, -41],
        });
        mapInstance.geoObjects
            .add(placeMaprk);
    }, [mapInstance, ymaps]);


        return <>
            {Maps && <Maps.YMaps>
                <Maps.Map
                    defaultState={{
                        center: mapCenter,
                        zoom: 16,
                    }}
                    modules={['templateLayoutFactory', 'Template', "Placemark"]}
                    onLoad={setYmaps}
                    instanceRef={setMapInstance}
                    className="complex__map-wrap"
                >
                </Maps.Map>
            </Maps.YMaps>}
          </>
    }

    export default YaMap;