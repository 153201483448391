import React, {useEffect, useRef, useState} from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getSpecialOffers } from "../actions/suburbans.js";
import MobileModal from '../components/core/MobileModal';
import Modal from "../components/core/Modal";
import Wrap from '../components/core/ModalWrap';
import SpecialForm from "../components/forms/Special.js";
import Preloader from "../components/Preloader.js";
import DetailCard from "../components/Special/DetailCard.js";
import '../components/Special/index.scss';
import SpecialModal from "../components/Special/SpecialModal.js";
import {errorToast, standartErrorToast, successToast} from "../components/toasts.js";
import useApi from "../hooks/useApi.js";
import useMedia from "../hooks/useMedia.js";
import DetailCardNew from "../components/Special/DetailCardNew";
import DetailCardNewHouse from "../components/Special/DetailCardNewHouse";
import DetailHeader from "../components/Public/markup/detail/DetailHeader";
import Slider from "../components/Public/markup/detail/Slider";
import {Navigation} from "swiper";
import request, {getUrl} from "../api";
import {Swiper, SwiperSlide} from "swiper/react";
import InfoCard from "../components/Public/markup/detail/InfoCard";
import Button from "../components/core/Button";
import PanModal from "../components/core/PanModal";
import ContactsModal from "../components/Public/markup/detail/ContactsModal";
import {
  AddToCompilationIcon,
  AptStatusIcon, FixIcon, HeartIcon, PdfIcon, PresentationIcon,
  SuburbanAboutMainIcon,
  SuburbanBedIcon,
  SuburbanBuildingAreaIcon,
  SuburbanCeilingHeightIcon,
  SuburbanCountBathroomsIcon,
  SuburbanProjFloorIcon,
  SuburbanProjMetrIcon, SuburbanProjSecondFasadIcon,
  SuburbanProjSecondFloorIcon,
  SuburbanProjSecondHouseIcon,
  SuburbanProjSecondRepairIcon,
  SuburbanProjSecondRoofIcon, ViewIcon
} from "../components/Public/markup/icons";
import Files from "../components/Public/markup/detail/Files";
import useDocTitle from "../hooks/useDocTitle";
import {objectsArrayToFormData, toFormData} from "../utils";
import useModalWithData from "../hooks/useModalWithData";
import CompilationModal from "./NewFavorites/compilationModal";
import SubmitButton from "../components/SubmitButton";
import SimilarCardsList from "../components/Special/SimilarCardsList";

const HouseNew = props => {
  const { id, savedFilterXmlId, xmlId } = useParams()
  const isDesktop = useMedia("isDesktop");
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');

  const user = useSelector(state => state.user?.info)
  const isPublic = !!savedFilterXmlId;

  const filesRef = useRef(null);
  const aboutBlockRef = useRef(null);
  const filesBlockRef = useRef(null);

  const {
    data: { contractConstruction, semiObjects, objectInfo, priceInfo, dateUpdateInfo, suburban, projectInfo, equipments, otherProjects, filesInfo, contacts, schedule, bookingLink, fixationLink, otherHouses },
    isLoading: loading,
    refresher,
    isRefreshing,
    loadMore,
    moreIsLoading,
    inFavorite,
    isFavoriteLoading,
    toggleFavorite,
    send,
    compilationObjectType
  } = useApi({
    payload: ["panpartner:new.suburbans.ajax", "getSuburban", {id}],
    favoritesParams: {
      // id,
      type: 'house',
    },
    favoritePath: 'suburban.favorite',
  }, [id])

  const [complexView, setComplexView] = useState('photos')
  const [numberOfSlides, setNumberOfSlides] = useState(0);
  const handleInit = (swiper) => {
    if (swiper) {
      setNumberOfSlides(swiper.slides?.length);
    }
  };


  const [fetchingPdf, setFetchingPdf] = useState(false);
  const getPdf = () => {
    setFetchingPdf(true)
    let formData = toFormData( {});
    formData = objectsArrayToFormData([{objectId: suburban.id, city: 'spb', type: 'house'}], "objects", formData)
    return request('panpartner:new.suburbans.ajax', 'addPresentation', formData)
      .then((data) => {

        return request('panpartner:new.suburbans.ajax', 'getPresentationPdf', {type: 'house', id: data.xmlId})
          .then((resp) => {
            setFetchingPdf(false)
            window.open(getUrl(resp.path))
          })

      })
  }



  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          managerId={suburban?.manager?.id}
          // params={{
          //   type: "village",
          //   objectId: villageId,
          //   city
          // }}
        />
      </PanModal>
    ),
    [suburban],
  );

  const [fullDescription, setFullDescription] = useState('')
  const [isLessDescription, setIsLessDescription] = useState(true)

  useEffect(() => {
    setFullDescription(suburban?.descriptionHouse?.split('<br>')[0])
  }, [suburban])

  const showMoreDescription = () => {
    setFullDescription(suburban?.descriptionHouse)
    setIsLessDescription(!isLessDescription)
    isLessDescription ? setFullDescription(suburban?.descriptionHouse) : setFullDescription(fullDescription.split('<br>')[0])
  }
  useDocTitle(suburban?.title)


  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: suburban?.id, type: 'house', city: 'spb' }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  const addSuburbanPresentation = (id) => {
    request("panpartner:new.suburbans.ajax", "addPresentation",
      objectsArrayToFormData([{type: "house", city: "spb", objectId: id} ], "objects"))
      .then((resp) => {
        if (resp?.xmlId) {
          // window.open(`/suburban/presentation/${resp.xmlId}`, "_blank");
          navigator.clipboard.writeText(getUrl(`/suburban/presentation/${resp.xmlId}`))
          successToast("Презентация успешно создана. Cсылка скопирована в буфер обмена")
        } else {
          console.error("Ошибка: xmlId не найден в ответе");
          // errorToast(error[0]?.message)
        }
      })
      .catch((error) => {
        console.error("Ошибка запроса:", error);
      });
  };

  const scrollToFiles = () => {
    if (filesBlockRef.current) {
      const rect = filesBlockRef.current.getBoundingClientRect();
      const offset = window.scrollY + rect.top;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };


  if (loading) return <Preloader loading />;
  return (
    <>
      <div className="wrapper">

        <div className="apt-modal__title">
          <h3 className="h3">{suburban?.title}</h3>
          <div className={`label-item apt-modal__status label_${suburban?.status?.color}`}>
            <AptStatusIcon />
            {suburban?.status?.title}
          </div>
        </div>

        <div className="apt-modal__header-second">
          <div className="sf sf--apt-modal">
            <div className="sf__elems">
              <button
                type="button"
                className="sf__elem-btn button link"
                onClick={() => toggleFavorite({id: suburban?.id})}
                style={{ width: 192 }}
              >
                <HeartIcon isActive={inFavorite} />
                {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
              </button>

              <button
                type="button"
                className="sf__elem-btn button link"
                onClick={openCompilationModal}
              >
                <AddToCompilationIcon />
                В подборку
              </button>

              {/*{bookingLink &&*/}
              {/*  <span className="sf__elem-btn button link" onClick={() => window.open(bookingLink, "_blank")}>*/}
              {/*    <ViewIcon/>*/}
              {/*    Запись на просмотр*/}
              {/*  </span>*/}
              {/*}*/}

              <span className="sf__elem-btn button link record-not-available ">
                <ViewIcon />
                Запись на просмотр
                <span>Недоступно</span>
              </span>

              {fixationLink &&
                <button
                  type="button"
                  className="sf__elem-btn button link"
                  onClick={() => window.open(fixationLink, "_blank")}
                >
                  <FixIcon/>
                  Зафиксировать клиента
                </button>
              }

              <SubmitButton
                type="button"
                className="sf__elem-btn button link"
                // isLoading={fetching}
                onClick={() => addSuburbanPresentation(suburban?.id)}
              >
                <PresentationIcon />
                Ссылка на презентацию
              </SubmitButton>

              <SubmitButton
                className="sf__elem-btn button link"
                isLoading={fetchingPdf}
                onClick={() => {getPdf()}}
              >
                <PdfIcon />
                Скачать PDF
              </SubmitButton>



            </div>
          </div>
        </div>




        <div className="sf__detail-row">
          <div className="sf__detail-col complex-custom-view">
            {suburban?.photo?.length > 0 && complexView == 'photos' && (
              <div className="complex-custom-view__main-container">
                <Slider
                  items={suburban?.photo}
                  defoultThumbs
                />
              </div>
            )}


            {suburban?.plan?.length > 0 && complexView == 'genplan' && (
              <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                <Slider
                  items={suburban.plan}
                  defoultThumbs
                />
              </div>
            )}

            {suburban?.locationOnGP&& complexView == 'gp' && (
              <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                <Slider
                  items={[suburban.locationOnGP]}
                  defoultThumbs
                />
              </div>
            )}





            <ul className="complex-view-list complex-view-list_suburban">
              <Swiper
                spaceBetween={15}
                slidesPerView={5}
                modules={[Navigation]}
                onInit={handleInit}
              >

                {suburban?.photo?.length > 0 && <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => {setComplexView('photos')}}>
                    <img src={getUrl(suburban?.photo?.[0]?.src)} alt=""/>
                    <span>Фотографии</span>
                  </li>
                </SwiperSlide>}

                {suburban?.plan?.length > 0 && <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'genplan' ? 'active' : ''}`} onClick={() => {setComplexView('genplan')}}>
                      <img src={getUrl(suburban.plan?.[0]?.src)} alt=""/>
                      <span>Планировка</span>
                    </li> </SwiperSlide>}

                {suburban?.locationOnGP && <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'gp' ? 'active' : ''}`} onClick={() => {setComplexView('gp')}}>
                    <img src={getUrl(suburban.locationOnGP?.src)} alt=""/>
                    <span>Ген-план</span>
                  </li> </SwiperSlide>}


              </Swiper>
            </ul>

          </div>


          <div className="sf__detail-col">
            {!isTablet && <InfoCard items={objectInfo} topDate={dateUpdateInfo.title + ' ' + dateUpdateInfo.value} cols={priceInfo} />}
            <section className="sf__section">
            {!!bookingLink && <Button buttonType="primary" isFull onClick={() => window.open(bookingLink)}>
              Забронировать
            </Button>}

              <Button buttonType="line" isFull onClick={openContactsModal}>
                Контакты
              </Button>

              </section>
          </div>
        </div>

        <div className="sf__detail-row">
          <div className="sf__detail-col">
            <div className="about-redesign">
              <div className="about-redesign__top">
                <div className="about-redesign__top-icon">
                  <SuburbanAboutMainIcon/>
                </div>

                <div className="about-redesign__top-content" style={{width: '100%'}}>
                  <p className="about-redesign__top-title">
                    {`О проекте`}
                  </p>

                  <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescription ? suburban?.descriptionHouse : suburban?.descriptionHouse?.split('<br>')[0]}}></p>
                  {suburban?.descriptionHouse?.includes?.('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescription(prev=>!prev)}>{!isLessDescription ?  'Свернуть' : 'Подробнее'}</span>}

                  <div className="about-redesign__overlist communications-redesign__list communications-redesign__list_white">
                    <ul className="about-redesign__list">
                      {projectInfo.map((i) => {
                        return (
                          <li className="about-redesign__list-item">
                            <div className="about-redesign__list-icon">

                              {i.type === 'squareHouse' && <SuburbanProjMetrIcon/>}
                              {i.type === 'countFloors' && <SuburbanProjFloorIcon/>}
                              {i.type === 'countBedrooms' && <SuburbanBedIcon/>}
                              {i.type === 'buildingArea' && <SuburbanBuildingAreaIcon/>}
                              {i.type === 'countBathrooms' && <SuburbanCountBathroomsIcon/>}
                              {i.type === 'ceilingHeight' && <SuburbanCeilingHeightIcon/>}

                            </div>
                            <div className="about-redesign__list-text">
                              <div className="about-redesign__listtext-sm">{i.title}</div>
                              <div className="about-redesign__listtext-bd">{i.value}</div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>

                </div>


              </div>

            </div>


              {
                equipments?.length > 0 &&
                equipments.map((i) => {
                  return (
                    <div style={{marginTop: 30}} className="about-redesign">
                      <div>
                        <p className="communications-redesign__title">{i.title}</p>

                        <div className="communications-redesign__list communications-redesign__list_white">
                          <ul className="about-redesign__list">

                            {i.items.map((it) => {
                              return (
                                <li className="about-redesign__list-item">
                                  <div className="about-redesign__list-icon">

                                    {it.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                    {it.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                    {it.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                    {it.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                    {it.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}

                                  </div>
                                  <div className="about-redesign__list-text">
                                    <div className="about-redesign__listtext-sm">{it.title}</div>
                                    <div className="about-redesign__listtext-bd">{it.value}</div>
                                  </div>
                                </li>
                              )
                            })}


                          </ul>
                        </div>


                        <div style={{marginTop: 20}}>
                          <p className="communications-redesign__title">Также в комплектацию входит:</p>
                          <ul className="about-redesign__complectation-list">
                            <div className="about-redesign__complectation-list-container" dangerouslySetInnerHTML={{ __html: i.description }}></div>
                          </ul>
                        </div>

                        {/*<div style={{display: "flex", justifyContent: 'flex-end'}}>*/}
                        {/*  <span className="about-redesign__complectation-price">{i.price} </span>*/}
                        {/*</div>*/}

                      </div>
                    </div>
                  )
                })

              }

            {filesInfo?.length > 0 && (
              <div className="about-redesign" ref={filesBlockRef} style={{minWidth: 500, marginTop: 30}}>
                <div className="about-redesign__top-content">
                  <p className="about-redesign__top-title">
                    {`Файлы`}
                  </p>

                  {filesInfo?.length > 0 && (
                    <div ref={filesBlockRef} style={{maxWidth: 600}}>
                      <Files innerRef={filesRef} customTitle=" " items={filesInfo} />
                    </div>
                  )}

                </div>
              </div>
            )}


          </div>
        </div>

        {otherHouses?.length > 0 && (
          <div className="sf__detail-row sf__detail-row_full">
            <h3 className="h3 tab-title" style={{marginBottom: 20}}>Другие дома застройщика</h3>
            <SimilarCardsList redesign hideTitleNewVersion justHouse hidePrice semiObjects={otherHouses}/>
          </div>
        )}


      </div>
    </>
  );
};


export default HouseNew;
