import { useMediaQuery } from 'beautiful-react-hooks';
import React from 'react';
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { getUrl } from '../../api';
import useDropdown from '../../hooks/useDropdown';
import { media } from '../../utils';
import Popup from '../core/Popup';

const ContactDevelopers = (props) => {
  const [node, open, show, hide] = useDropdown(false);
  const { items, way } = props;
  return (
    <div className="contact__developers" ref={node}>
      <div className={'popover js-popover' + (open ? ' is-showing' : '')}>
        {media('isMobile') ?
          <Popup
            trigger={
              <div
                className="link-hover label_with-icon_prefix"
              >
                <div className="label__icon">
                  <svg className="icon icon_document">
                    <use xlinkHref="#house"></use>
                  </svg>
                </div>
                Список застройщиков
              </div>
            }
          >
            <SimpleBar className="contact__developers-block for-popup">
              <ul className="contact__developers-list">
                {items.map((item) => (
                  <li className="contact__developers-item" key={item.id}>
                    <Link to={`/realty/${item.city ?? "spb"}?filter[builders][0]=${item.id}`} className="link-hover" target="_blank">{item.title}</Link>
                  </li>
                ))}
              </ul>
            </SimpleBar>
          </Popup> :
          <div
            className="link-hover label_with-icon_prefix"
            onClick={() => show()}
          >
            <div className="label__icon">
              <svg className="icon icon_document">
                <use xlinkHref="#house"></use>
              </svg>
            </div>
            Список застройщиков
          </div>
        }
        {!media('isMobile') &&
          <div
            className={`popover__block popover__block_align_${way}`}
            data-align={way}
          >
            <div className="popover__inner">
              <SimpleBar className="contact__developers-block">
                <ul className="contact__developers-list">
                  {items.map((item) => (
                    <li className="contact__developers-item" key={item.id}>
                      <Link to={`/realty/${item.city ?? "spb"}?filter[builders][0]=${item.id}`} className="link-hover" target="_blank">{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </SimpleBar>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const ContactsItems = (props) => {
  const { items } = props;

  const mediumScreen = useMediaQuery('(max-width: 1240px)');
  const smallScreen = useMediaQuery('(max-width: 768px)');

  const sideWay = (index) => {
    let right = mediumScreen ? 3 : 4;
    return (index + 1) % right === 0 ? 'left' : 'right';
  };

  const contactDetails = (contact, index) => (
    <>
      <div className="contact__name">
        <h4 className="h4">{contact.name}</h4>
      </div>
      <div className="contact__post">
        <span>{contact.position}</span>
      </div>
      <div className="contact__contacts">
        <div className="contact__contacts-item contact__phone">
          <a
            className="link-hover"
            href={`tel:${contact.phone}`}
          >
            {contact.phone}
          </a>
        </div>
        <div className="contact__contacts-item contact__email">
          <a
            className="link-hover"
            href={`mailto:${contact.email}`}
          >
            {contact.email}
          </a>
        </div>
      </div>
      {contact.builders && contact.builders.length > 0 && (
        <ContactDevelopers
          items={contact.builders}
          way={sideWay(index)}
        />
      )}
      <ul className="contact__socials">
         {contact.watsApp && (
          <li className="contact__socials-item contact__socials_whatsapp">
            <a
              className="button button_view_round-control"
              href={`https://api.whatsapp.com/send?phone=${contact.watsApp}`}
            >
              <svg className="icon">
                <use xlinkHref="#socials_whatsapp"></use>
              </svg>
            </a>
          </li>
        )}
        {contact.telegram && (
          <li className="contact__socials-item contact__socials_tg">
            <a
              className="button button_view_round-control"
              href={`https://t.me/${contact.telegram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="icon">
                <use xlinkHref="#socials_tg"></use>
              </svg>
            </a>
          </li>
        )}
      </ul>
    </>
  )

  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="contacts__content">
          {items.map((group) => (
            <div className="contacts__department" key={group.id}>
              <div className="contacts__department-title">
                <h2 className="h2">{group.title}</h2>
              </div>
              <div className="contacts__grid">
                {group.items.map((contact, index) => (
                  <div className="contacts__grid-col" key={index}>
                    <div className="contacts__department-contact">
                      <div className="contact">
                        <div className="contact__photo">
                          {contact.photo && (
                            <img
                              src={getUrl(contact.photo.src)}
                              width="100%"
                              height="auto"
                              alt={contact.name}
                            />
                          )}
                        </div>
                        {media('isMobile')
                          ? <div className="contact__details">{contactDetails(contact, index)}</div>
                          : contactDetails(contact, index)
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactsItems;
