import React, { useEffect, useLayoutEffect } from 'react';
import { useModal } from "react-modal-hook";

const Popup = ({ trigger, children, closePopup, className = '', onClose }, ...props) => {

  // const useLockBodyScroll = () => {
  //   useLayoutEffect(() => {
  //    const originalStyle = window.getComputedStyle(document.body).overflow;
  //    document.body.style.overflow = 'hidden';
  //    return () => document.body.style.overflow = originalStyle;
  //    }, []);
  // }
  //
  // useLockBodyScroll()

  const closeModal = () => {
    hideModal()

    if (onClose) {
      onClose()
    }
  }

  const [showModal, hideModal] = useModal(() => (
    <div className={className}>
      <div className="pan-popup" onClick={closeModal}>
      </div>
      <div className={`pan-popup-content ${showModal ? 'is-active' : ''}`}>
        <div
          className="pan-popup-close-btn"
          onClick={closeModal}
        >

          <svg className="icon icon_x">
            <use xlinkHref="#x"></use>
          </svg>
        </div>
        {children}
      </div>
    </div>
  ), [props]);

  useEffect(() => {
    if (closePopup) hideModal()
  }, [closePopup]);

  return (
    <div className='pan-popup-trigger' onClick={showModal}>
      {trigger}
    </div>
  );
};

export default Popup;
