import formatter from 'format-number';
import without from 'lodash/without';
import React, { useEffect, useRef, useState } from 'react';
import useMedia from '../../hooks/useMedia';
import { getEndingsTitle, getSuffix } from "../../utils";
import EndingsFilter from '../FilterRealty/EndingsFilter';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SearchFilter from '../FilterRealty/SearchFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilterOnly from '../FilterRealty/SquareFilterOnly';
const priceFormat = (price) =>
  formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
const isExist = (val)=> {
  if(Array.isArray(val)){
    return !!val.length
  }
  return !!val
}
const Badge = (props) => {
  const { item, handleClick, title, identity } = props;

  return (
    <div className="filtered">
      <div className="filtered__inner">
        <span className="filtered__type">{title}:</span>
        <span className="filtered__value">{item?.title}</span>
        <button
          className="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            className="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    count,
    isPublic,
    savedFilterXmlId,
  } = props;

  const handleFilter = (value, identity) => {
    setFilter({ [identity]: value });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };
  const isMobile = useMedia("isMobile")
  const isLaptop = useMedia("isLaptop");

  const showBadges = () => {
    return (
      (filter &&
        (filter.communications?.length > 0 ||
          filter.countBedrooms?.length > 0 ||
          filter.bathroomTypes?.length > 0 ||
          filter.materials?.length > 0 ||
          filter.type?.length > 0 ||
          filter.rooms?.length > 0 ||
          filter.features?.length > 0 ||
          filter.prices?.min ||
          filter.prices?.max ||
          filter.floors?.min ||
          filter.floors?.max ||
          filter.countFloors?.min ||
          filter.countFloors?.max ||
          filter.squareHouse?.min ||
          filter.squareHouse?.max ||
          filter.squareParcel?.min ||
          filter.squareParcel?.max ||
          filter.isOnlyHanded?.length > 0 ||
          filter.endings?.min ||
          filter.endings?.max))
    );
  };

  const handleRemoveFilter = (value, identity) => {
    switch (identity) {
      case 'squareParcel':
      case 'squareHouse':
      case 'prices':
      case 'floors':
      case 'countFloors':
      case 'endings':
        setFilter({
          ...filter,
          [identity]: { ...filter[identity], [value]: "" },
        });
        break;
      case 'isOnlyHanded':
        setFilter({
          ...filter,
          [identity]: [],
        });
        break;
      default:
        let newArray = filter[identity];
        newArray = newArray.filter((item) => item != value);
        setFilter({ ...filter, [identity]: newArray });
    }
  };

  const handleToggle = (e) => {
    let tempFilter = [...filter.status];
    if (e.target.checked) {
      tempFilter.push('3');
    } else {
      tempFilter = without(tempFilter, '3');
    }
    setFilter({
      status: tempFilter,
    });
  };

  const scrollRef = useRef(null);

  const scrollToRef = (ref) => {
    try {
      window.scrollTo({
        top: (window.pageYOffset + ref.current.getBoundingClientRect().top),
        behavior: "smooth",
      });
    } catch {

    }
  }

  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => {
    setIsOpen(!isOpen);

    !isOpen && scrollToRef(scrollRef);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  useEffect(() => {
    if (props.isFiltersOpen) toggleFilters();
  }, [props.isFiltersOpen]);

  const submitTitle = () => {
    if (count === "initial") {
      return `Показать все`
    }
    return count === 0
      ? 'Ничего не найдено'
      : `Показать ${count} объект${getSuffix(count)}`
  }

  const submitBtn = (
    <div className="complex-search__filter-submit">
      <button
        className="button button_view_default"
        type="submit"
        disabled={count === 0}
        onClick={(e) => {
          onSubmit();
        }}
      >
        <span>
          {submitTitle()}
        </span>
      </button>
    </div>
  )

  const filters = (
    <>

      {isExist(filterData.bathroomTypes) && <SelectFilter
        onChange={(a, b) => handleFilter(a, 'bathroomTypes')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.bathroomTypes
            ? Object.values(filterData.bathroomTypes)
            : []
        }
        filterValues={filter.bathroomTypes}
        excludeValues={[]}
        data={{
          title: 'Санузел',
          autocompleteTitle: '',
          identity: 'bathroomTypes',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.countBedrooms?.items) &&  <SelectFilter
        onChange={(a, b) => handleFilter(a, 'countBedrooms')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.countBedrooms?.items ? Object.values(filterData.countBedrooms?.items) : []
        }
        filterValues={filter.countBedrooms}
        excludeValues={[]}
        data={{
          title: 'Спален',
          autocompleteTitle: '',
          identity: 'countBedrooms',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.communications) && <SelectFilter
        onChange={(a, b) => handleFilter(a, 'communications')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.communications ? Object.values(filterData.communications) : []
        }
        filterValues={filter.communications}
        excludeValues={[]}
        data={{
          title: 'Коммуникации',
          autocompleteTitle: '',
          identity: 'communications',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.materials) && <SelectFilter
        onChange={(a, b) => handleFilter(a, 'materials')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.materials ? Object.values(filterData.materials) : []
        }
        filterValues={filter.materials}
        excludeValues={[]}
        data={{
          title: 'Материалы дома',
          autocompleteTitle: '',
          identity: 'materials',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.features) &&  <SelectFilter
        onChange={(a, b) => handleFilter(a, 'features')}
        isExcludable={false}
        isAutocomplete={false}
        items={
          filterData.features ? Object.values(filterData.features) : []
        }
        filterValues={filter.features}
        excludeValues={[]}
        data={{
          title: 'Особенности',
          autocompleteTitle: '',
          identity: 'features',
        }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.endings?.range?.min && filterData.endings?.range?.max) && <EndingsFilter
        ident={"endings"}
        filter={filter.endings ? filter.endings : { min: '', max: '' }}
        items={filterData.endings ? filterData.endings.items : []}
        data={{
          title: 'Срок сдачи',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.endings
            ? filterData.endings?.ranges
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'endings')}
        secondData={{
          title: 'Сданные дома',
          value: filter.isOnlyHanded?.length
            ? filter.isOnlyHanded[0].id
            : 'N',
        }}
        secondChange={(a) =>
          handleFilter(a === 'Y' ? [{ id: a }] : [], 'isOnlyHanded')
        }
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.countFloors?.items) && <FloorsFilter
        items={
          filterData.countFloors?.items ? filterData.countFloors.items : []
        }
        filter={filter.countFloors}
        data={{
          title: 'Этажность',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.countFloors
            ? filterData.countFloors?.range
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'countFloors')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
       {/* скрыто тк пока высоток нет */}
      {false && <FloorsFilter
        items={
          filterData.floors?.items ? filterData.floors.items : []
        }
        filter={filter.floors}
        data={{
          title: 'Этаж',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.floors
            ? filterData.floors?.range
            : null,
        }}
        onChange={(a) => handleFilter(a.filter, 'floors')}
        // secondData={{
        //   title: filterData.isNoFirstFloor
        //     ? filterData.isNoFirstFloor[0]?.title
        //     : 'Не первый',
        //   value: filter.isNoFirstFloor.length
        //     ? filter.isNoFirstFloor
        //     : 'N',
        // }}
        // secondChange={(a) => {
        //   let tmp = a === 'Y' ? 'Y' : '';
        //   handleFilter(tmp, 'isNoFirstFloor');
        // }}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {!isPublic && (
        <div className="complex__filter-switch">
          <label className="switch" for="filter-booked">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                id="filter-booked"
                onChange={handleToggle}
                checked={filter.status.includes('3')}
              />
              <div className="switch__input" for="filter-booked"></div>
              <div className="switch__marker"></div>
            </div>
            <span className="switch__label">
              Показать забронированные
            </span>
          </label>
        </div>
      )}
    </>
  )

  const topFilters = (
    <>
     {isExist(filterData.type) && <SelectFilter
        onChange={(a) =>
          handleFilter(a, "type")
        }
        items={
          filterData.type
            ? Object.values(filterData.type)
            : []
        }
        filterValues={filter.type}
        excludeValues={[]}
        data={{
          title: "Тип объекта",
          autocompleteTitle: "",
          identity: "type",
        }}
        isAutocomplete={false}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
      {isExist(filterData.squareParcel) && <SquareFilterOnly
        filter={filter.squareParcel ?? {}}
        data={{
          title: 'Площадь участка',
          minPlaceholder: 'S уч. от ',
          maxPlaceholder: 'S уч. до ',
          defaultValues: filterData.squareParcel
            ? filterData.squareParcel?.range
            : null,
        }}
        onChange={(a, identity) => handleFilter(a.filter, identity)}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
        identity={"squareParcel"}
        placeholderMes="сот."
        items={filterData.squareParcel?.items}
        showPlaceholderOnLists
        autoPosition
      />}
      {isExist(filterData.squareHouse) && <SquareFilterOnly
        filter={filter.squareHouse ?? {}}
        data={{
          title: 'Площадь дома',
          minPlaceholder: 'S дома от ',
          maxPlaceholder: 'S дома до ',
          defaultValues: filterData.squareHouse
            ? filterData.squareHouse?.range
            : null,
        }}
        onChange={(a, identity) => handleFilter(a.filter, identity)}
        identity={"squareHouse"}
        clearFilterOnly={clearFilter}
        fullFilter={filter}
        items={filterData.squareHouse?.items}
        showPlaceholderOnLists
        autoPosition
      />}
      {isExist(filterData.prices?.items) && <PriceFilter
        filter={filter.prices ? filter.prices : { min: '', max: '' }}
        exclude={{ min: '', max: '' }}
        items={filterData.prices ? filterData.prices.items : []}
        data={{
          title: 'Цена',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.prices ? filterData.prices?.range : 0,
        }}
        onChange={(a) => handleFilter(a.filter, 'prices')}
        isMobile={isOpen && isMobile}
        clearFilterOnly={(e) => handleClearFilter(e)}
        fullFilter={filter}
        autoPosition
      />}
    </>
  )

  const appliedFilters = (
    <div className="index-search__filter-applied">
      <div className="complex-filter_wrapper">
        {filter.rooms?.map?.((id) => {
          let b = filterData.rooms.find((item) => id == item.id);
          if (!b) {
            return '';
          }
          return (
            <Badge
              key={`rooms${id}`}
              identity={`rooms`}
              item={b}
              title={`К-во комнат`}
              handleClick={handleRemoveFilter}
            />
          );
        })}
        {filter.countBedrooms?.map?.((id) => {
          let b = filterData.countBedrooms?.items.find((item) => id == item.id);
          if (!b) {
            return '';
          }
          return (
            <Badge
              key={`countBedrooms${id}`}
              identity={`countBedrooms`}
              item={b}
              title={`Спален`}
              handleClick={handleRemoveFilter}
            />
          );
        })}
        {filter.bathroomTypes?.map?.((id) => {
          let b = filterData.bathroomTypes.find((item) => id == item.id);
          if (!b) {
            return '';
          }
          return (
            <Badge
              key={`bathroomTypes${id}`}
              identity={`bathroomTypes`}
              item={b}
              title={`Санузел`}
              handleClick={handleRemoveFilter}
            />
          );
        })}
        {filter.squareHouse?.min && (
          <Badge
            key={`squareHouseMin`}
            identity={`squareHouse`}
            item={{ id: 'min', title: `${filter.squareHouse?.min} м²` }}
            title={`Площадь дома, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.squareHouse?.max && (
          <Badge
            key={`squareHouseMax`}
            identity={`squareHouse`}
            item={{ id: 'max', title: `${filter.squareHouse?.max} м²` }}
            title={`Площадь дома, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.squareParcel?.min && (
          <Badge
            key={`squareParcelMin`}
            identity={`squareParcel`}
            item={{ id: 'min', title: `${filter.squareParcel?.min} сот.` }}
            title={`Площадь участка, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.squareParcel?.max && (
          <Badge
            key={`squareParcelMax`}
            identity={`squareParcel`}
            item={{ id: 'max', title: `${filter.squareParcel?.max} сот.` }}
            title={`Площадь участка, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.prices?.min && (
          <Badge
            key={`priceMin`}
            identity={`prices`}
            item={{ id: 'min', title: priceFormat(filter.prices?.min) }}
            title={`Цена, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.prices?.max && (
          <Badge
            key={`priceMax`}
            identity={`prices`}
            item={{ id: 'max', title: priceFormat(filter.prices?.max) }}
            title={`Цена, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.endings && filter.endings?.min && (
          <Badge
            key={`endingsMin`}
            identity={`endings`}
            item={{
              id: 'min',
              title: getEndingsTitle(filter.endings?.min),
            }}
            title={`Срок сдачи, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.endings && filter.endings?.max && (
          <Badge
            key={`endingsMax`}
            identity={`endings`}
            item={{
              id: 'max',
              title: getEndingsTitle(filter.endings?.max),
            }}
            title={`Срок сдачи, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter.communications &&
          filter.communications?.map?.((id) => {
            let b = filterData.communications.find((item) => id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`communications${id}`}
                identity={`communications`}
                item={b}
                title={`Коммуникации`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.features &&
          filter.features?.map?.((id) => {
            let b = filterData.features.find((item) => id == item.id);
            return (
              <Badge
                key={`features${id}`}
                identity={`features`}
                item={b}
                title={`Особенности`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.materials &&
          filter.materials?.map?.((id) => {
            let b = filterData.materials.find((item) => id == item.id);
            return (
              <Badge
                key={`materials${id}`}
                identity={`materials`}
                item={b}
                title={`Материалы`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter.type &&
          filter.type?.map?.((id) => {
            let b = filterData.type?.find?.((item) => id == item.id);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`type${id}`}
                identity={`type`}
                item={b}
                title={`Тип`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
        {filter?.countFloors?.min && (
          <Badge
            key={`countFloorsMin`}
            identity={`countFloors`}
            item={{ id: 'min', title: filter.countFloors?.min }}
            title={`Этажность, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.countFloors?.max && (
          <Badge
            key={`countFloorsMax`}
            identity={`countFloors`}
            item={{ id: 'max', title: filter.countFloors?.max }}
            title={`Этажность, до`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.floors?.min && (
          <Badge
            key={`floorMin`}
            identity={`floors`}
            item={{ id: 'min', title: filter.floors?.min }}
            title={`Этаж, от`}
            handleClick={handleRemoveFilter}
          />
        )}
        {filter?.floors?.max && (
          <Badge
            key={`floorMax`}
            identity={`floors`}
            item={{ id: 'max', title: filter.floors?.max }}
            title={`Этаж, до`}
            handleClick={handleRemoveFilter}
          />
        )}
         {filter.isOnlyHanded &&
          filter.isOnlyHanded?.map?.((filterItem, i) => {
            let b = filterData.isOnlyHanded.find((item, indx) => i == indx);
            if (!b) {
              return '';
            }
            return (
              <Badge
                key={`isOnlyHanded${filterItem.id}`}
                identity={`isOnlyHanded`}
                item={{ id: 0, title: 'Да' }}
                title={`Сданные дома`}
                handleClick={handleRemoveFilter}
              />
            );
          })}
      </div>

      {isMobile &&
        <div className="complex__filter-clear-wrapper">
          <button
            className="complex__filter-clear-filter"
            onClick={() => clearFilter()}
          >
            <span>Очистить параметры</span>
          </button>
        </div>
      }
    </div>
  )


  return (
    <div className="complex__filter for-suburban">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="complex__filter-form">
          <div className="form__row complex__filter-row" ref={scrollRef}>
            {!isMobile && topFilters}

            {!isMobile && filters}

            {isOpen &&
              <div className="mobile-filters">
                <div className="mobile-filters__title">
                  <svg
                    className="icon icon_arrow_up"
                    onClick={() => {
                      toggleFilters()
                      props.onClick()
                    }}
                  >
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>Все фильтры</h3>
                </div>
                <div className="mobile-filters__content">
                  <SearchFilter
                    placeholder="№ квартиры"
                    handleChange={(val) => handleFilter(val, 'number')}
                    value={filter.number}
                  />

                  {showBadges() && appliedFilters}
                  {topFilters}
                  {filters}

                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        handleClearFilter(e)
                        toggleFilters()
                      }}
                    >
                      Очистить
      							</button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        toggleFilters()
                      }}
                    >
                      Применить
      							</button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        {isMobile &&
          <button className="button-mobile" onClick={toggleFilters}>
            {isOpen ? 'Скрыть фильтры' : 'Все фильтры'}
          </button>
        }

        {isMobile && submitBtn}

        {showBadges() && appliedFilters}

        <div className="complex__filter-actions">
          <div className="complex-search__filter-controls">
            <div className="complex-search__filter-clear">
              <button className="button button_type_clear">
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>очистить фильтр</span>
              </button>
            </div>

            {!isMobile &&
              <>
                {submitBtn}
                <div className="complex-search__filter-clear">
                  <button
                    className="button button_type_clear"
                    onClick={() => clearFilter()}
                  >
                    <svg className="icon icon_x">
                      <use xlinkHref="#x"></use>
                    </svg>
                    <span>очистить фильтр</span>
                  </button>
                </div>
              </>
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filter;
