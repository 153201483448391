import { memo, useEffect, useMemo } from "react";
import SimpleBar from 'simplebar-react';
import useApiManager from "../../hooks/useApiManager";
import { createBadges } from "../../hooks/useFilter/filterUtils";
import useMedia from "../../hooks/useMedia";
import { applyToFormData, combineFormData, toFormData } from '../../utils';
import BotBar from '../BotBar/BotBar';
import Dropdown from '../core/Dropdown';
import NewClient from '../forms/NewClient';
import SubmitButton from "../SubmitButton";
import { errorToast, standartErrorToast, standartInfoToast, successToast } from "../toasts";
import { Badge } from "./Components";

const copyLink = clientLink => navigator.clipboard.writeText(clientLink)
	.then(() => successToast("Ссылка скопирована в буфер обмена"))

const SendFilterReworked = memo(props => {

	const {
		filterId,
		filterView,
		clientId,
		sendOnly,
		hide,
		city = "spb",
		filterType,

		filterBadges,
		excludeBadges,

		filter = {},
		exclude = {},
		filterData = {},
		addParams = {}
	} = props;

	const isMobile = useMedia("isMobile");

	const {
		getClients,
		sendPost,
		editFilter,

		state,
		setValue,
		setValues,

	} = useApiManager([
		{ action: "getClients", method: "GET" },
		{ action: "sendPost" },
		{ action: "editFilter" },
	], {
		defaultPayload: { c: "panpartner:saved.filter.ajax" },
		initialState: {
			showClientForm: false,
			isShowFilter: "N",
			client: clientId ?? 0,
			url: "",
			savedId: null,
			clients: []
		}
	})

	const {
		loadingState,
		showClientForm,
		client,
		clients,
		url,
		savedId,
		isShowFilter
	} = state;

	const loadClients = client => getClients().then(({ resp }) => setValues({
		clients: resp.clients ?? [],
		client: client ?? clientId ?? resp.clients?.[0]?.id,
		showClientForm: false,
	}))

	const onSave = () => {

		const person = clients.find((item) => item.id == client);

		if (!person) return errorToast("Нужно выбрать клиента или добавить его");

		const filterFormData = toFormData(filter, "filter", true);

		const excludesFormData = toFormData(exclude, "exclude", true);

		applyToFormData(excludesFormData, {
			...(filterId ? { id: filterId } : []),
			clients: [person.id],
			isShowFilter,
			city: city ? city : filterData.cityCode,
			...(filterType ? { filterType: filterType } : []),
			...addParams
		})
		const params = combineFormData(filterFormData, excludesFormData)

		editFilter(params).then(
			({ resp }) => {
				setValues({
					savedId: resp.id?.[0],
					url: resp.links?.[0]?.url
				})
				standartInfoToast(resp)
				copyLink(resp.links?.[0]?.url)
			},
			standartErrorToast
		)
	};

	const onSend = () => {
		sendPost({
			id: [filterId || savedId],
			clientId: [client],
			city
		}).then(({ resp, ...rest }) => {
			if (resp.status === "error") return;
			setValue("url", resp.link)
			standartInfoToast(resp)
			hide()
		}, standartErrorToast)
	};

	const submit = () => sendOnly ? onSend() : onSave();

	useEffect(() => {
		loadClients()
	}, [])

	const clientPhone = useMemo(() => clients?.find(({ id }) => id == client)?.phone?.replace?.(/[+() -]/g, '') ?? ``, [clients, client])

	const filters = useMemo(() => filterView?.filter ?? filterBadges ?? createBadges(filter, filterData, ["status"]),
		[filterBadges, filter, filterData, filterView]);

	const excludes = useMemo(() => filterView?.exclude ?? excludeBadges ?? createBadges(exclude, filterData, ["status"]),
		[excludeBadges, exclude, filterData, filterView]);

	return <>
		{!isMobile &&
			<h2 className="modal__title">
				{sendOnly ? 'Отправить клиенту' : 'Сохранить фильтр'}
			</h2>
		}
		<div className="modal-form">
			<div className="modal-form__grid for-saved-filter">
				<div className="modal-form__filters">
					<SimpleBar style={{ maxHeight: '300px', marginBottom: '15px' }}>
						{!!filters?.length && <div className="modal-form__block" style={{ paddingRight: '15px' }} >
							<h4>Параметры фильтра</h4>
							{filters.map((item, i) => <Badge key={i} values={item.value ?? item.values ?? item.id} name={item.title ?? item.name} />)}
						</div>}
						{!!excludes?.length && <div className="modal-form__block" style={{ paddingRight: '15px' }} >
							<h4>Исключения</h4>
							{excludes.map((item, i) => <Badge key={i} values={item.value ?? item.values ?? item.id} name={item.title ?? item.name} />)}
						</div>}
					</SimpleBar>

					{!isMobile &&
						<button
							className="button button_view_outline-with-icon clients__back-btn js-modal-btn"
							onClick={hide}
						>
							<svg className="icon icon_back">
								<use xlinkHref="#back"></use>
							</svg>
							<span>Вернуться</span>
						</button>
					}
				</div>
				<div className="modal-form__col">
					<div className="modal-form__row">
						<div className="modal-form__wrap">
							<div className="select select_type_form">
								<label className="custom-select-label">Клиент</label>
								<Dropdown
									options={clients}
									value={client}
									onChange={v => setValue("client", v)}
								/>
							</div>
							{!showClientForm && (
								<div
									className="modal-form__add"
									style={{ width: 'auto' }}
									onClick={() => setValue("showClientForm", true)}
								>
									<button className="button button_type_add"></button>
									<span>Добавить клиента</span>
								</div>
							)}
							{showClientForm && <NewClient onAdd={loadClients} />}
							<hr className="modal-form__divider" />
							<div className="checkbox checkbox_view_red">
								<div className="checkbox__box">
									<input
										className="checkbox__control"
										type="checkbox"
										id="include-search-inside"
										checked={isShowFilter === "Y"}
										onChange={e => setValue("isShowFilter", e.target.checked ? "Y" : "N")}
									/>
									<label
										className="checkbox__input"
										htmlFor="include-search-inside"
									></label>
									<div className="checkbox__marker"></div>
								</div>
								<label
									className="checkbox__label"
									htmlFor="include-search-inside"
								>
									Включить поиск в подборке
								</label>
							</div>
							<div className="modal-form__send">
								<SubmitButton
									className="button button_view_default"
									onClick={submit}
									disabled={!!savedId}
									isLoading={!!loadingState.length}
								>
									<span>{sendOnly ? 'Отправить' : 'Сохранить'}</span>
								</SubmitButton>
							</div>
						</div>
					</div>
					{!sendOnly && !!savedId && (
						<div className="modal-form_row" style={{ padding: '26px 32px' }}>
							<div
								className="send-filter"
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<h4
									className="send-filter__label"
									style={{ margin: '15px 0' }}
								>
									Отправить фильтр клиенту?
								</h4>
								<ul
									className="subscribe__socials"
									style={{ justifyContent: 'flex-start' }}
								>
									<li
										className="subscribe__socials-item"
										onClick={onSend}
										style={{ width: '40px', height: '40px' }}
									>
										<div
											className="subscribe__socials-link"
											style={{
												fontSize: '20px',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<svg
												className="icon icon_social-mail"
												style={{ transform: 'none' }}
											>
												<use xlinkHref="#social-mail"></use>
											</svg>
										</div>
									</li>
									<li
										className="subscribe__socials-item"
										style={{ width: '40px', height: '40px' }}
									>
										<a
											className="subscribe__socials-link"
											style={{
												fontSize: '20px',
												display: 'flex',
												alignItems: 'center',
											}}
											href={`https://web.whatsapp.com/send?phone=${clientPhone}&text=${url}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<svg
												className="icon icon_social-wa"
												style={{ transform: 'none' }}
											>
												<use xlinkHref="#social-wa"></use>
											</svg>
										</a>
									</li>
									<li
										className="subscribe__socials-item"
										style={{ width: '40px', height: '40px' }}
									>
										<a
											className="subscribe__socials-link"
											style={{
												fontSize: '20px',
												display: 'flex',
												alignItems: 'center',
											}}
											href={`tg://msg_url?url=${url}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<svg
												className="icon icon_social-tg"
												style={{ transform: 'none' }}
											>
												<use xlinkHref="#social-tg"></use>
											</svg>
										</a>
									</li>
									<li
										className="subscribe__socials-item"
										style={{ width: '40px', height: '40px' }}
									>
										<a
											className="subscribe__socials-link"
											style={{
												fontSize: '20px',
												display: 'flex',
												alignItems: 'center',
											}}
											href={`viber://pa?chatURI=${clientPhone}&text=${url}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<svg
												className="icon icon_social-viber"
												style={{ transform: 'none' }}
											>
												<use xlinkHref="#social-viber"></use>
											</svg>
										</a>
									</li>
								</ul>

							</div>
						</div>
					)}
				</div>
			</div>
			{!!savedId && <BotBar />}

		</div>
	</>
})

export default SendFilterReworked;