import React, { useEffect, useRef, useState } from 'react';
import { ChatFooterFileIcon, ChatMessageActionIcon, ChatMessageSentIcon, DoubleCheckIcon, SingleCheckIcon } from "../Public/markup/icons";
import { getUrl } from '../../api';
import { PhotoPopup } from "../Viewer/PhotoSlider";
import MiniLoader from '../Loaders/MiniLoader';
import CopyToClipboard from 'react-copy-to-clipboard';
import AppPopover from '../core/Popover';
import classNames from 'classnames';


const Message = ({
  fromMe,
  time,
  images = [],
  file,
  content,
  content_type,
  unread,
  viewed,
  viewedByOthers,
  id,
  observer,
  deleteMsg,
  forwardMessage,
  replyMessage,
  error,
  scrollToMessage,
  status,
  onEditClick,
  files,
  forward,
  isSavedMessage,
  saveMessage
}) => {
  const messageRef = useRef();

  useEffect(() => {
    if (viewed || !messageRef.current || !observer) return;
    observer.observe(messageRef.current);
  }, [viewed, messageRef, observer]);

  const [gallery, setGallery] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const onClickToMessage = (e) => {
    const replyElement = e.target.closest(".reply");
    if (replyElement && replyElement.getAttribute("message-id")) {
      scrollToMessage({ id: `message-${replyElement.getAttribute("message-id")}` });
    }
  };

  const renderFiles = (files) => {
    return files.map((file, index) => {
      const isImage = file?.type === "image";
      const isVideo = file?.type === "video";

      if (isImage) return <img
        key={file.id}
        src={file.urlShow}
        alt={file.name}
        className="open-chat__message-file-image"
        onClick={() => {
          setGallery(files.filter((f) => f.type === "image"));
          setGalleryIndex(index + 1);
        }}
      />

      if (isVideo) return <video
        key={file.id}
        controls
        className="open-chat__message-file-video"
        src={file.urlShow}
      >
        Your browser does not support the video tag.
      </video>

      return <a
        key={file.id}
        href={file.urlDownload}
        target="_blank"
        rel="noopener noreferrer"
        className="open-chat__message-file-link"
      >
        {file.name}
      </a>;
    });
  };

  return (
    <div
      className={!fromMe ? "open-chat__overmessage" : "open-chat__overmessage open-chat__overmessage_me"}
      id={`message-${id}`}
      ref={messageRef}
      onClick={onClickToMessage}
    >
      <div className={classNames({
        "open-chat__message": true,
        "open-chat__message_me": fromMe,
        "open-chat__message-image": images.length,
        "open-chat__message_viewed": viewed,
        "open-chat__message_file": file,
        "open-chat__message_error": error,
        "open-chat__message-forwarded": forward,
      })}
      >
        {forward && <div className="open-chat__message-forwarded">
          Пересланное сообщение:
        </div>}
        {files && renderFiles(files || [])}
        {content && (
          <div
            className={
              classNames({
                "open-chat__message-text": true,
                "open-chat__message-text_me": fromMe,
              })}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {!!error && <p> Ошибка: {error}</p>}
        <div className="open-chat__message-info">
          <div className="open-chat__message-time">{time}</div>
          {status === "sending" && <div className="open-chat__message-status"><MiniLoader /></div>}
          {!isSavedMessage && !viewedByOthers && status !== "sending" && fromMe && <div className="open-chat__message-status"><SingleCheckIcon /></div>}
          {((viewedByOthers && fromMe) || isSavedMessage) && (
            <div className="open-chat__message-status">
              <DoubleCheckIcon />
            </div>
          )}
        </div>
      </div>
      <div className="open-chat__message-actions">
        <span className="open-chat__message-actionbtn">
          <ChatMessageActionIcon />
          <ul className="open-chat__action-list">
            {!fromMe && <li onClick={replyMessage}>Ответить</li>}
            {!isSavedMessage && <li onClick={saveMessage}>Сохранить</li>}
            <li onClick={forwardMessage}>Переслать</li>
            <AppPopover trigger="click" content="Текст сообщения скопирован" overlayClassName={'share-popover'} >
              <CopyToClipboard text={content}>
                <li>Копировать</li>
              </CopyToClipboard>
            </AppPopover>
            {fromMe && <li onClick={onEditClick}>Редактировать</li>}
            {fromMe && <li style={{ color: "#FF2D23FF" }} onClick={deleteMsg}>
              Удалить
            </li>}
          </ul>
        </span>
      </div>
      <PhotoPopup
        items={gallery}
        index={galleryIndex}
        open={!!gallery?.length}
        onClose={() => setGallery(false)}
      />
    </div>
  );
};

export default Message;
