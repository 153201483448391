import mergeWith from 'lodash/mergeWith';
import slice from 'lodash/slice';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import ym from "react-yandex-metrika";
import { getBuilders, getFormSettings, sendForm } from '../../actions/forms';
import SubmitButton from '../../components/SubmitButton';
import Dropdown from '../../components/core/Dropdown';
import MobileModal from '../../components/core/MobileModal';
import FormAddClient from '../../components/forms/FormAddClient';
import { errorToast, successToast } from '../../components/toasts';
import "../../pages/SellAssigment/index.scss";
import { media } from '../../utils';
import ru from "date-fns/locale/ru";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from 'moment';

registerLocale('ru', ru);
setDefaultLocale('ru');

const toDate = str => {
  if (str instanceof Date) return str
  const [day, months, years] = str?.split?.(".") || [];
  if (years?.trim?.()?.length !== 4) return new Date();
  return new Date(years, months - 1, day);
}



const defaultFiles = {
  files: {
    files: [],
    filesList: []
  },
};

const RecordClient = (props) => {
  const {
    phone,
    email,
    name,
    isPublic,
    client,
    objectId,
    redesign,
    close
  } = props;
  const searchParam = new URLSearchParams(window.location.search);
  // const { objectId } = useParams()
  const [clients, setClients] = useState([])
  const [show, setShow] = useState(false);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [files, setFiles] = useState(defaultFiles);
  const [fileNames] = useState([]);

  const [showPluses, setShowPluses] = useState(!media('breakLaptop'))

  const [fetching, setFetching] = useState(false);

  const [, setSuccess] = useState(false);
  const [newClientSubmit, setNewClientSubmit] = useState(false);

  const [form, setForm] = useState({})
  const [formSettings, setFormSettings] = useState(null)
  const [builders, setBuilders] = useState([])
  const [blocks, setBlocks] = useState([])
  const [formId, setFormId] = useState(69)
  const [isNewClient, setNewClient] = useState(false)
  const getFieldName = (code, isMulti) => {
    if (!formSettings) return
    for (let setting of formSettings) {
      if (setting.code === code) {
        if (isMulti) {
          return [...setting.items]
        }
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const fetch = (formId) => {
    return getFormSettings(formId).then(resp => {
      setFormSettings(resp.questions)
      setBuilders(resp.builders)
      setClients(resp.clients)
      setBlocks(resp.blocks || [])
    })
  }
  useEffect(() => {
    if (!formId) return
    setSelectedBuilders([])
    fetch(formId)
    setForm({})
  }, [formId])

  const defaultValues = {
    name: isPublic ? `${client?.name} ${client?.lastName}` : name,
    // clientId: isPublic ? client?.id : clients.length > 0 ? clients[0].id : 0,
    clientId: "",
    phone: isPublic ? client?.phone?.[0] : phone,
    email: isPublic ? client?.email?.[0] : email,
    comment: '',
    fromClient: isPublic ? 'Y' : 'N',
    files: [],
    objectId
  };
  const { handleSubmit, register, errors, control, setValue, getValues, clearError, setError } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (!formSettings) return
    const defaultPolicy = formSettings.find(setting => setting.code === "personal_data")?.items?.[0]?.value
    handleChange([defaultPolicy], "personal_data")
    const defaultClient = "" // clients[0]?.id
    if (!defaultClient) return
    !isNewClient && setValue(getFieldName("clientId"), defaultClient)
  }, [clients, formSettings])




  const handleAddClient = (id) => {
    setValue('clientId', id);
    setShow(false);
    setNewClient(true)
    fetch(formId)
    setValue(getFieldName("clientId"), id)
  };

  const onSubmit = (data) => {
    if (getValues().builderId?.length === 0 && formId === 35) {
      setError(
        "builderId",
        "notMatch",
        "Выберите застройщика"
      );
      return
    }
    if (getValues().blockId?.length === 0 && formId === 356) {
      setError(
        "blockId",
        "notMatch",
        "Выберите ЖК"
      );
      return
    }
    const isClientSelected = !!data[getFieldName("clientId")]
    if (!clients || clients.length === 0 || !isClientSelected) {
      errorToast(<div>Чтобы отправить форму, выберите или добавьте клиента</div>)
      return
    }
    setFetching(true);
    setFiles(defaultFiles);
    let formData = new FormData();
    let keys = Object.keys(data);
    keys.forEach((item) => {
      // let identity = item;
      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          if (!(item.substr(0, 4) === 'form')) return
          formData.append(`${item}`, appendValue);
        });
      } else {
        if (!(item.substr(0, 4) === 'form')) return
        formData.append(`${item}`, data[item]);
      }
    });
    const policyKey = getFieldName("personal_data", true)[0].fieldName

    const dateKey = getFieldName("date", true)[0].fieldName
    const timeKey = getFieldName("time", true)[0].fieldName
    formData.append(dateKey, selectedDates.date)
    formData.append(timeKey, selectedDates.time)

    formData.append(policyKey, form[policyKey])

    const objectIdKey = getFieldName("objectId")
    formData.append(objectIdKey, objectId)
    let filesKeys = Object.keys(files);
    filesKeys.forEach((key) => {
      files[key].files.forEach((data) => {
        if (data.file) {
          formData.append(data.fieldNames, data.file);
        }
      });
    });

    const buildersFormSettings = getFieldName(formId === 35 ? "builders" : "block", true)
    for (let i = 0, len = selectedBuilders.length; i < len; i++) {
      const builderId = selectedBuilders[i];
      if (i < len && buildersFormSettings[i]) {
        formData.append(buildersFormSettings[i].fieldName, builderId)
      } else {
        formData.append(formId === 35 ? "builders_text[]" : "block_text[]", builderId)
      }
    }

    // if (files.length) {
    //   files.map((file, index) => {
    //     form.append(`files[]`, file);
    //     return file;
    //   });
    // }
    // form.append("city", city)

    setNewClientSubmit(true)

    sendForm(formId, formData, formId === 35 ? "spb" : "msk").then(
      () => {
        setSuccess(true);
        setFetching(false);
        setFiles(defaultFiles)
        setValue(getFieldName("comment"), "")
        if (window.location.host !== "localhost:3000") {
          ym('reachGoal', 'fixation_btn')
        }
        successToast(<div>Ваша заявка успешно отправлена!</div>)

        setSelectedBuilders([]);
        setValue(formId === 35 ? "builderId" : "blockId", []);

        const defaultClient = clients[0]?.id
        if (!defaultClient) return
        setValue(getFieldName("clientId"), defaultClient)

        const defaultBuilders = formSettings.find(setting => setting.code === "builders")?.items?.[0]?.value
        handleChange([defaultBuilders], "builders")

        setShow(false)
        close?.()
      },
      (error) => {
        setError(true);
        setFetching(false);
        errorToast(<div>{error[0].message}</div>)
      },
    );

  };

  const loadFiles = (e, ident, isMulti) => {
    const tmp = [...files[ident].filesList, ...e.target.files];
    const fieldNames = getFieldName(ident, isMulti)
    if (!tmp.length > 0) return
    if (isMulti) {
      let tmpFiles = mergeWith(slice(fieldNames, 0, 10), slice(tmp, 0, 10), (first, second) => ({ fieldNames: first.fieldName, file: second }))
      setFiles(prev => ({
        ...prev,
        [ident]: {
          files: tmpFiles,
          filesList: slice(tmp, 0, 10)
        }
      }))
      return
    }
    setFiles(prev => {
      let reversed = [...tmp].reverse()
      return {
        ...prev, [ident]:
        {
          files: [{ fieldNames, file: reversed[0] }],
          filesList: slice(reversed, 0, 1)
        }
      }
    })

  };
  const removeFile = (key, index) => {
    const filesList = [...files[key]?.filesList];
    const tmpFiles = [...files[key]?.files];
    filesList.splice(index, 1);
    tmpFiles.splice(index, 1);
    setFiles({
      ...files, [key]: {
        files: tmpFiles,
        filesList
      }
    });
  };

  const handleChange = (value, code, arrayValue) => {
    const tmp = {
      ...form
    }
    const handlers = {

      defalutText: (code, val) => {
        const ident = getFieldName(code)
        tmp[ident] = val
      },

      blockName: function () {
        if (typeof value === "string" || !value) {
          return
        }
        this.defalutText("blockId", value.id)
        this.defalutText(code, value.name)
      },

    }
    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      handlers[code] ? handlers[code]() : handlers.defalutText(code, value)
    }
    setForm(prev => ({ ...prev, ...tmp }))
  }

  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    if (listRef.current) {
      setListHeight(listRef.current.clientHeight + 150);
    }
  }, [setListHeight, listRef])

  const [openMobileAddForm, closeMobileAddForm] = useModal(
    ({ }) => (
      <MobileModal closeClick={closeMobileAddForm} title="Добавить клиента">
        <FormAddClient
          show={true}
          onAddClient={(id) => handleAddClient(id)}
          forFixate
          closeClick={closeMobileAddForm}
          submitClick={newClientSubmit}
        />
      </MobileModal>
    ),
    [show, newClientSubmit, handleAddClient],
  );

  const plusesList = (showPluses) => (
    <ul className="fixating-list">
      <li>
        Вы будете в курсе обращений клиента в другое агентство (в случае если другое агентство так же отправило фиксацию по клиенту).
        Имея такую информацию, вы сможете правильно построить свою работу с клиентом.
      </li>

      {showPluses &&
        <>
          <li>
            Вы можете быть уверенными в том, что, в случае обращения клиента в прямой отдел продаж застройщика, вы будете информированы об этом.
          </li>

          <li>
            Часть застройщиков, где есть отделы агентских продаж, помогают риелторам довести клиента до договора.
          </li>

          <li>
            Если при фиксации клиента пришел отрицательный ответ (ранее уже был зафиксирован за прямым отделом продаж), вы сможете переориентировать клиента на другой ЖК / застройщика.
          </li>

          <li>
            При фиксации клиента и последующем пересечении с прямым отделом продаж (если фиксация за ОП будет позже вас) появится возможность продолжить работу с клиентом и довести его до сделки.
          </li>

          <li>
            Повышает доверие клиентов, т.к. если покупатель звонит застройщику, то менеджер ОП подтверждает, что клиент уже работает с застройщиком через партнёров.
          </li>
        </>
      }
    </ul>
  )

  const [openPluses, closePluses] = useModal(
    ({ }) => (
      <MobileModal closeClick={closePluses} title="Плюсы фиксации клиента">
        {plusesList(true)}
      </MobileModal>
    ),
    [plusesList],
  );

  const [selectedDates, setSelectedDates] = useState({})
  const [selectedType, setSelectedType] = useState([])
  const [isDateError, setIsDateError] = useState(false)

  const selectDateTime = (val, key, error = "") => {
    setSelectedDates(prev => ({
      ...prev,
      [key]: val,
      [`${key}Error`]: error
    }))
  }


  const selectDate = (val, key) => {
    const momentDate = val instanceof Date ? moment(val) : moment(val, 'DD.MM.YYYY', true);
    const isValidDate = momentDate?.isValid?.();
    const value = isValidDate ? momentDate.format("DD.MM.YYYY") : val;
    const isValidCount = val?.replace?.(/\D/gi, "")?.length < 8
    selectDateTime(value, key, isValidDate || isValidCount ? "" : "Некорректная дата")
  }

  const selectTime = (val, key) => { //копипаст из RecordViewing
    const strippedVal = val.replace(/\s/g, "");
    const isValidFormat = /^C\d{2}:\d{2}до\d{2}:\d{2}$/.test(strippedVal);
    let error = "";
    if (!isValidFormat) {
      error = "Некорректное время или интервал времени";
    } else {
      const [from, to] = strippedVal.substring(1).split("до").map(time => moment(time, "HH:mm", true));

      if (!from.isValid() || !to.isValid() || from.isSameOrAfter(to)) {
        error = "Неверный интервал времени";
      }
    }
    console.log(error);
    if (error !== "") {
      setIsDateError(true);
    } else {
      setIsDateError(false);
    }
    selectDateTime(val, key, error);
  };

  return (
    <section className={!redesign ? 'sell section section_view_white fixate' : 'sell section section_view_white fixate redesignFixate'}>
      <div className="container container_sm">
        <div className="fixate-client subuebans-book-form" style={{ minHeight: listHeight }}>
          {!objectId &&
            <div className="sell__title">
              <h1 className="h1">Фиксация клиента</h1>
              {/* <div className="sell__switcher switcher">
              <div
                className={`switcher__btn ${formId === 35 ? "active" : ""}`}
                onClick={() => {
                  setSelectedBuilders([]);
                  setFormId(35)
                }}
              >
                Санкт-Петербург
              </div>
              <div
                className={`switcher__btn ${formId === 36 ? "active" : ""}`}
                onClick={() => {
                  setSelectedBuilders([]);
                  setFormId(36)
                }}
              >
                Москва
              </div>
            </div> */}
            </div>
          }
          {!objectId &&
            <div className={`fixating-pluses ${showPluses && 'show-pluses'}`} ref={listRef}>
              <div className="fixating-pluses-title">Плюсы фиксации клиента:</div>

              <span className="icon-wrap">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
              </span>

              <span className="show-all" onClick={() => media('isMobile') ? openPluses() : setShowPluses(showPluses ? false : true)}>
                {showPluses ? 'Скрыть' : 'Читать полностью'}

                {!media('isMobile') &&
                  <svg className="icon icon_arrow_up" >
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                }
              </span>

              {plusesList(showPluses)}

            </div>
          }
          {/*<h4 className={!objectId ? 'sell__subtitle h4' : 'h4'} style={{marginBottom: 25, marginTop: 25}}>Информация об агенте</h4>*/}

          <form className="modal-form" name="reserve-apartment" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-form__wrapper">

              {!isPublic && (
                <div className="modal-form__row for-client">
                  <div className="modal-form__item">
                    <div className="select select_type_form">
                      <label
                        className="input__label custom-select-label"
                        htmlFor="modal-reservation-client"
                      >
                        Клиент
                      </label>
                      <Controller
                        as={<Dropdown placeholder={"Не выбран"} />}
                        name={getFieldName("clientId") || "clientId"}
                        options={clients}
                        onChange={([value]) => value}
                        innerRef={register({ required: true })}
                        control={control}
                      />
                    </div>
                    <div
                      className="add-client active"
                      style={{ display: !show ? 'flex' : 'none' }}
                      onClick={() => media('isMobile') ? openMobileAddForm() : setShow(true)}
                    >
                      <button
                        className="button button_type_add"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      ></button>
                      <span>Добавить клиента</span>
                    </div>
                  </div>
                </div>
              )}
              {!isPublic && !media('isMobile') && (
                <FormAddClient
                  show={show}
                  onAddClient={(id) => handleAddClient(id)}
                  forFixate
                  closeClick={() => setShow(false)}
                  submitClick={newClientSubmit}
                />
              )}

              <div className="subuebans-book-form__dates">
                <div className="hp__form-item">
                  <label className="hp__form-label text">
                    <span>Желаемая дата<span className="is-red-text sell__required">*</span></span>
                  </label>
                  <label className="input input_width_available input_type_form">

                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      showTimeSelect={false}
                      todayButton="Сегодня"
                      dropdownMode="select"
                      autoComplete="off"
                      onSelect={v => selectDate(v, "date")}
                      selected={toDate(selectedDates?.date)}
                      shouldCloseOnSelect
                      showYearDropdown
                      showMonthDropdown
                      minDate={new Date(new Date().setHours(24))}
                      placeholderText={'18.07.2023'}
                      wrapperClassName='input_width_available'
                      customInput={<InputMask
                        className={`input__control${!!selectedDates?.dateError ? " input__error" : ""}`}
                        mask="99.99.9999"
                        autoComplete="off"
                        maskChar=" "
                        placeholder={'18.07.2023'}
                        value={selectedDates?.date}
                        onChange={e => selectDate(e.target.value, "date", true)}
                      />}
                    />
                  </label>
                </div>
                <div className="hp__form-item">
                  <label className="hp__form-label text">
                    <span>Желаемое время<span className="is-red-text sell__required">*</span></span>
                  </label>
                  <label className="input input_width_available input_type_form">
                    <InputMask
                      mask="C 99:99 до 99:99"
                      maskChar="_"
                      formatChars={{ '9': '[0-9]', _: '[0-5]' }}
                      className={`input__control${selectedDates?.timeError ? " input__error" : ""}`}
                      value={selectedDates?.time}
                      onChange={e => selectTime(e.target.value, "time")}
                      placeholder={'C 13:00 до 14:00'}
                    />
                  </label>
                </div>
              </div>

              {/* {!isPublic && (
                <div className="modal-form__row">
                  <div className={`modal-form__item ${media('isMobile') ? 'modal-form__full-width' : ''}`}>
                    <Controller
                      as={DropdownMulti}
                      name={formId === 35 ? "builderId" : "blockId"}
                      items={formId === 35 ? builders : blocks}
                      data={{
                        title: formId === 35 ? 'Выберите застройщика' : "Выберите ЖК",
                        identity: 'builderId',
                      }}
                      onChange={([value]) => {
                        setSelectedBuilders(value);
                        if (value.length === 0) {
                          setError(
                            formId === 35 ? "builderId" : "blockId",
                            "notMatch",
                            formId === 35 ? "Выберите застройщика" : "Выберите ЖК",

                          );
                        } else {
                          clearError(formId === 35 ? "builderId" : "blockId",);
                        }
                        return value;
                      }}
                      isAutocomplete
                      innerRef={register({ required: true })}
                      control={control}
                      isMobile={media('isMobile')}
                      autocompleteTitle={formId === 35 ? "Введите название застройщика" : "Введите название ЖК"}
                    />
                    {errors.builderId && (
                      <span className="input__error-label">
                        {errors.builderId.message}
                      </span>
                    )}
                  </div>
                </div>
              )} */}
              <div className={"fixation-badges-container"}>
                {selectedBuilders.map((builderId, index) => (
                  <div class="filtered fixation-badges" key={'builder-' + index}>
                    <div className="filtered__inner">
                      <span className="filtered__type"></span>
                      {formId === 35 ? <span className="filtered__value"> {builders.find((builder) => builder.id == builderId)?.name}</span> :
                        <span className="filtered__value"> {blocks.find((builder) => builder.id == builderId)?.name}</span>
                      }
                      <button
                        class="button filtered__del"
                        type="button"
                        onClick={() =>
                          setSelectedBuilders((prev) => {
                            let tmpBuilders = prev.filter((id) => id != builderId);
                            setValue(formId === 35 ? "builderId" : "blockId", tmpBuilders);
                            return tmpBuilders;
                          })
                        }
                      >
                        <svg
                          className="icon icon_x"
                          width="7"
                          height="8"
                          viewBox="0 0 7 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
                          <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
                {selectedBuilders.length > 0 && (
                  <button
                    class="button button_type_filtered filtered-clear fixation-badges"
                    onClick={() => {
                      setSelectedBuilders([]);
                      setValue(formId === 35 ? "builderId" : "blockId", []);
                    }}
                  >
                    <span>Очистить параметры</span>
                  </button>
                )}
              </div>
              {!isPublic && (
                <div className="modal-form__row">
                  <div className="modal-form__item">
                    <label
                      className="textarea textarea_type_form"
                      htmlFor="modal-reservation-commentary"
                    >
                      <span className="textarea__label">Комментарий</span>
                      <textarea
                        className="textarea__control"
                        id="modal-reservation-commentary"
                        ref={register()}
                        name={getFieldName("comment") || "comment"}
                        placeholder="Укажите дополнительную информацию"
                      ></textarea>
                    </label>
                  </div>
                </div>
              )}

              <div className="modal-form modal-form_mt_25">
                {formSettings?.find(setting => setting.code === "personal_data")?.items?.map((item, i) => {
                  return <div className="modal-form__row" key={`personal_data-${i}`}>
                    <div className="modal-form__item">
                      <div className={`checkbox ${media('isMobile') ? '' : 'checkbox_view_invert'}`}>
                        <div className="checkbox__box">
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            name={getFieldName("personal_data") || "personal_data"}
                            value={item.value}
                            id="modal-reservation-agreement"
                            onChange={(e) => handleChange(e.target.checked, item?.fieldName, e.target.value)}
                            checked={!!form?.[item?.fieldName]?.includes(item.value)}

                          /><label className="checkbox__input" htmlFor="modal-reservation-agreement"></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label className="checkbox__label" htmlFor="modal-reservation-agreement">Cогласие на обработку персональных данных</label>
                      </div>
                    </div>
                  </div>
                })}

              </div>


            </div>
            <div className="modal-form__submit">
              <SubmitButton
                className="button button_view_default"
                isLoading={fetching}
              >
                <span>Отправить заявку</span>
              </SubmitButton>
            </div>
          </form>
        </div>


      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.user?.info?.email,
    phone: state.user?.info?.phone,
    clients: ownProps.client
      ? [ownProps.client]
      : state.user?.clients?.map((client) => ({
        id: client.id,
        title: `${client.name} ${client.lastName}`,
      })),
    name: state.user?.info?.name
      ? `${state.user?.info?.name} ${state.user?.info?.lastName}`
      : '',
    builders: state.forms?.builders?.map && state.forms?.builders?.map((builder) => ({
      id: builder.id,
      title: builder.name,
    })),
    city: state.user?.info?.city
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBuilders: () => dispatch(getBuilders()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecordClient);
