import React, { useCallback, useMemo, useState } from 'react';
import { formatMoney, makeComa } from '../../../utils';
import { InfoItem } from './ComplexInfo';
const typesAsObject = ["commercial", "suburban"]
const Info = ({ data, isParcel }) => {

  const { object, typeXmlId } = data;
  const isSuburban = typeXmlId === "suburban";
  const isCommercial = typeXmlId === "commercial";
  const isParking = object?.roomTypeXmlId === "abc24"
  const [decreaseMargin, setDecreaseMargin] = useState(false)
  const parentRef = useCallback(node => {
    if (node !== null) {
      if (node.childElementCount > 10) {
        setDecreaseMargin(true)
      } else {
        setDecreaseMargin(false)
      }
    }
  });
  const title = useMemo(() => {
    if (isParking) return "о машиноместе"
    if (isParcel) return "об участке"
    return typesAsObject.includes(typeXmlId) ? "об объекте" : "о квартире"
  }, [isParcel, typeXmlId, isParking])

  const numberTitle = useMemo(() => {
    if (isParking) return "машиноместа"
    if (isSuburban || isCommercial) return "объекта"
    return "квартиры"
  }, [isSuburban, typeXmlId, isParking, isCommercial])

  if (!object) return null;

  return (
    <div className="presentation__info pdf-block presentation__info_flat">
      <div className={`presentation__box ${decreaseMargin? "decrease-child-margin": ""}`} data-id="flat_area" ref={parentRef}>
        <div className="presentation__title">Информация {title}</div>
        <InfoItem title="Цена при 100% оплате" value={object.costWithDiscountFormated} dataId="price" />
        <InfoItem title="Цена за кв. м" value={formatMoney(Math.round(object.costWithDiscount/object.squareTotal), " руб.")} dataId="price" hide={isParcel} />
        <InfoItem title="Цена за сотку" value={formatMoney(Math.round(object.costWithDiscount / object.squareParcel), " руб.")} hide={!object.squareParcel} dataId="price" />
        <InfoItem title="Базовая цена" value={object.baseCostFormated} dataId="price" />
        <InfoItem title="Общая площадь" value={`${object.squareTotal} кв. м.`} hide={!object.squareTotal} />
        <InfoItem title="Жилая площадь" value={`${object.squareLiving} кв. м.`} hide={!object.squareLiving} />
        <InfoItem title="Площадь кухни" value={`${object.squareKitchen} кв. м.`} hide={!object.squareKitchen} />
        <InfoItem title="Площадь участка" value={`${object.squareParcel} кв. м.`} hide={!object.squareParcel} />
        <InfoItem title={`Номер ${numberTitle}`} value={object.number} />
        <InfoItem
          title={isSuburban ? "Этажей" : "Этаж"}
          value={isSuburban ? object.floors : object.floors ? `${object.floor}/${object.floors}` : object.floor}
          hide={!object.floor}
        />
        <InfoItem title="Вентиляция" value={`${object.ventilation} мм`} hide={!object.ventilation} />
        <InfoItem
          title="Материал дома"
          value={object?.buildingTypeName?.map?.(makeComa)}
          hide={!isSuburban || isParcel}
        />
        <InfoItem title="Отделка" value={object.decorationName} />
        <InfoItem title="Корпус" value={object.corpus} />
        <InfoItem title="Срок сдачи" value={object.endingDate} />
        <InfoItem title="Выдача ключей" value={object.issuingKeys} />
        <InfoItem
          title="Коммуникации"
          value={object?.communications?.map?.(makeComa)}
        />
        <InfoItem title="Секция" value={object.section} />
      </div>
    </div>
  );
};

export default Info;