import formatter from "format-number";
import React, { useEffect, useRef } from "react";
import { useModal } from "react-modal-hook";
import "react-photoswipe/lib/photoswipe.css";
import {connect, useSelector} from "react-redux";
import { Link, useParams } from "react-router-dom";
import { clearApartment, fetchApartment } from "../../actions/apartment";
import { fetchClients } from "../../actions/clients";
import {
  addClientToFavorite, addToFavorites,
  removeClientFromFavorite, removeFromFavorites
} from "../../actions/favorites";
import { getPricePerMeter, media } from "../../utils";
import Banks from "../Apartment/Banks";
import MortgageBlock from "../Apartment/MortgageBlock";
import PhotosBlock from "../Apartment/PhotosBlock";
import PresentationBlock from "../Apartment/PresentationBlock";
import Chart from '../Chart';
import Modal from "../core/Modal";
import ReserveApartment from "../forms/ReserveApartment";
import Manager from "../Manager";
import Preloader from "../Preloader";
import { successToast } from "../toasts";
import FilterDisabled from "./FilterDisabled";



const BlockLink = (props) => {
  const { id, city, name, savedFilterXmlId } = props;
  const url = `/public/${savedFilterXmlId}/${city}/complex/${id}`;
  const savedFilterSettings = useSelector(state => state.apartment.savedFilterSettings ?? {})
  return (
    <div className="flat__complex">
      <div className="flat__complex-title">
        <h4 className="h4" dangerouslySetInnerHTML={{ __html: name }}></h4>
      </div>
      <Link to={url} style={savedFilterSettings ? {color: savedFilterSettings?.colorButton} : {}} className={`link flat__complex-link`}>
        Страница ЖК
      </Link>
    </div>
  );
};

const priceFormat = (price) => {
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};

const Apartment = (props) => {
  const { id, city, savedFilterXmlId } = useParams();

  const {
    fetch,
    reset,
    isFetching,
    data,
    isActive,
    agent,
    banks,
    error,
  } = props;

  // Sticky button

  const ref = useRef(null);

  const handleScroll = () => {

    if (!ref.current) return;

    const elScrollHeight = ref.current.offsetTop - window.innerHeight + 110

    if (window.scrollY > elScrollHeight) {
      ref.current.classList.add('flat__info-btns-sticky');
    } else if (window.scrollY < elScrollHeight){
      ref.current.classList.remove('flat__info-btns-sticky');
    }
  };
  useEffect(() => {
    if(!data) return
    let el = document.createElement('html');
    el.innerHTML = data ? data.block.name : ''; // с бэка приходит хтмл поэтому так)
    document.title = `${data.roomType}, ${data.squareTotal} м², № ${data.number} в ${el.innerText}`;
  }, [data]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const submitReserve = () => {
    closeReserveForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const [openReserveForm, closeReserveForm] = useModal(
    ({  }) => (
      <Modal close={closeReserveForm} classes="modal_task">
        <ReserveApartment
          id={id}
          handleSend={() => submitReserve()}
          isPublic={true}
          client={data?.client}
          savedFilterXmlId={savedFilterXmlId}
          city={city}
        />
      </Modal>
    ),
    [data, savedFilterXmlId, city],
  );

  useEffect(() => {
    fetch(id, city, savedFilterXmlId);
    // fetchClients();
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const savedFilterSettings = useSelector(state => state.apartment.savedFilterSettings ?? {})

  if (error) return "Error";

  if (!isActive) return <FilterDisabled agent={agent} />;

  return (
    <div>
      {data && (
        <section className="section section_view_white flat flat_margin_0">
          <div className="wrapper">
            <div className="flat__header">
              <div className="flat__grid">
                <div className="flat__col flat__col-title">
                  <div className="flat__title">
                    <h1 className="h1">{data.roomType}</h1>
                  </div>
                  {data && (
                    <BlockLink
                      {...data.block}
                      savedFilterXmlId={savedFilterXmlId}
                    />
                  )}
                </div>
                <div className="flat__col flat__col-download">
                  <PresentationBlock
                    objectXmlId={data.xmlId}
                    city={city}
                    objectType="apartments"
                    savedFilterXmlId={savedFilterXmlId}
                  />
                  {/* <AddPresentation
                    id={data.xmlId}
                    city={city}
                    presentation={data.presentation}
                  /> */}
                </div>
              </div>
            </div>
            <div className="flat__content">
              <div className="flat__grid">
                <div className="flat__col flat__col-images">
                  <PhotosBlock {...data} />
                  {agent && (
                    <div className="flat__manager sm-hide">
                      <Manager
                        data={agent}
                        key="desktopManager"
                        isPublic={true}
                        objectId={id}
                      />
                    </div>
                  )}
                </div>
                <div className="flat__col flat__col-info">
                  <div className="flat__price">
                    <div className="flat__price-col flat__price-item">
                      <div className="flat__info-item">
                        <div
                          className={`label-item label_${
                            data?.status?.color
                          }`}
                        >
                          {data?.status?.name}
                        </div>
                      </div>
                    </div>

                    {media("isMobile") &&
                      <div className="flat__price-col flat__price-item">
                        <div className="flat__info-item">
                          <div className="flat__info-item_price">
                            {priceFormat(data.costWithDiscount)}
                          </div>
                          <div className="flat__info-item_desc">
                            Цена, 100% оплата
                          </div>
                        </div>
                      </div>
                    }

                    {!media("isMobile") &&
                      <div className="flat__price-col flat__price-actions"></div>
                    }
                  </div>
                  <div className="flat__price">

                    {!media("isMobile") &&
                      <div className="flat__price-col flat__price-item">
                        <div className="flat__info-item">
                          <div className="flat__info-item_price">
                            {priceFormat(data.costWithDiscount)}
                          </div>
                          <div className="flat__info-item_desc">
                            Цена, 100% оплата
                          </div>
                        </div>
                      </div>
                    }

                    <div className="flat__price-col flat__price-item">
                      <div className="flat__info-item">
                        <div className="flat__info-item_price">
                          {priceFormat(parseInt(getPricePerMeter(data.costWithDiscount, data.squareTotal)))}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена за кв. метр
                        </div>
                      </div>
                    </div>
                    <div className="flat__price-col flat__price-actions">
                      {false && (
                        <div className="flat__price-action">
                          <div
                            className="add-compare-btn js-compares-add"
                            data-compare-id="7005"
                          >
                            <button className="button button_type_settings">
                              <svg className="icon icon_settings">
                                <use xlinkHref="#icon-compare"></use>
                              </svg>
                            </button>
                            К сравнению
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flat__period-grid">
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.ending}
                        </div>
                        <div className="flat__info-item_desc">Срок сдачи</div>
                      </div>
                    </div>
                    {/* {data.issuingKeys && (
                      <div className="flat__period-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.issuingKeys}
                          </div>
                          <div className="flat__info-item_desc">
                            Срок выдачи ключей
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {data?.status?.id != "1001" && <div className="flat__info-btns" ref={ref}>
                    <div
                      className="js-modal-btn"
                      data-flat-id="flat-1"
                      data-id="modal-reservation"
                    >
                      <button
                        className="button button_view_default"
                        onClick={openReserveForm}
                        style={savedFilterSettings ? {backgroundColor: savedFilterSettings?.colorButton, color: savedFilterSettings?.colorButtonText} : {}}
                      >
                        Хочу забронировать
                      </button>
                    </div>
                  </div>}
                  <div className="flat__info-grid">
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {priceFormat(data.baseCost)}
                        </div>
                        <div className="flat__info-item_desc">Базовая цена</div>
                      </div>
                    </div>
                    {data.flatType &&(
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.flatType}
                          </div>
                          <div className="flat__info-item_desc">
                            Тип
                          </div>
                        </div>
                      </div>
                    )}
                    {data.squareTotal > 0 &&(
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareTotal} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Общая площадь
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">{data.id}</div>
                        <div className="flat__info-item_desc">ID</div>
                      </div>
                    </div>
                    {data.squareLiving > 0 &&(
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareTotal} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Жилая площадь
                          </div>
                        </div>
                      </div>
                    )}
                    {data.number && data.number > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.number}
                          </div>
                          <div className="flat__info-item_desc">
                            Номер квартиры
                          </div>
                        </div>
                      </div>
                    )}
                    {data.numberPib && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.numberPib}
                          </div>
                          <div className="flat__info-item_desc">
                            Номер квартиры ПИБ
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.floor}
                        </div>
                        <div className="flat__info-item_desc">Этаж</div>
                      </div>
                    </div>
                    {data.squareKitchen > 0 && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.squareKitchen} м<sup>2</sup>
                          </div>
                          <div className="flat__info-item_desc">
                            Площадь кухни
                          </div>
                        </div>
                      </div>
                    )}
                    {data.corpus && (
                      <div className="flat__info-col">
                        <div className="flat__info-item">
                          <div className="flat__info-item_value">
                            {data.corpus}
                          </div>
                          <div className="flat__info-item_desc">
                            Корпус / Участок / Дом
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.bathroom}
                        </div>
                        <div className="flat__info-item_desc">Санузел</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.section}
                        </div>
                        <div className="flat__info-item_desc">Секция</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.decoration}
                        </div>
                        <div className="flat__info-item_desc">Отделка</div>
                      </div>
                    </div>
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.contract}
                        </div>
                        <div className="flat__info-item_desc">
                          Форма договора
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {agent && (
              <div className="flat__manager sm-show">
                <Manager data={agent} key="mobileManager" isPublic={true} />
              </div>
            )}
          </div>
        </section>
      )}
        {data && <Chart info={data}/>}
      {data && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="apartment"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
          isPublic={true}
        />
      )}
      {banks && banks.length > 0 && <Banks items={banks} data={data} />}
      <Preloader loading={isFetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, city, savedFilterXmlId) =>
    dispatch(fetchApartment(id, city, savedFilterXmlId)),
  reset: () => dispatch(clearApartment()),
  addToFavorites: (city, id, type) => dispatch(addToFavorites(city, id, type)),
  removeFromFavorites: (city, id, type) =>
    dispatch(removeFromFavorites(city, id, type)),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        whatPage,
        type,
      ),
    ),
});

const mapStateToProps = (state) => ({
  data: state.apartment.data,
  isFetching: state.apartment.isFetching,
  error: state.apartment.error,
  manager: state.apartment.manager,
  agent: state.apartment.agent,
  isActive: state.apartment.isActive,
  banks: state.apartment.banks,
  clients: state.clients.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
