import _keys from 'lodash/keys';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ym from "react-yandex-metrika";
import { signup } from '../../actions/register';
import { validateMail } from '../../utils';
import Modal from '../core/Modal';
import Dropdown from '../Dropdown';
import { errorToast, infoToast } from '../toasts';
import BannerThin from './BannerThin';
import DotsSVG from './DotsSVG';

const citiesList = [
  {
    id: 'spb',
    title: 'Санкт-Петербург',
  },
  {
    id: 'msk',
    title: 'Москва',
  },
  {
    id: 'other',
    title: 'Другой город',
  },
];

const Register = (props) => {
  const { handleSubmit, register, errors, control, reset, watch } = useForm({
    defaultValues: {
      agree: true,
    },
  });
  useEffect(() => {
    const t = document.createElement('script');
    t.type="text/javascript"
    t.async=!0
    t.src="https://vk.com/js/api/openapi.js?168"
    t.onload=function(){
    window.VK.Retargeting.Init("VK-RTRG-1392517-49vFw")
    window.VK.Retargeting.Hit()
    }
    document.head.appendChild(t)
    return () => {
      document.head.removeChild(t);
    };
  }, []);
  const { signup, history, fetching, error } = props;
  const inviteCode = localStorage.getItem("inviteCode")
  const [cityName, setCity] = useState('spb');

  const [openSuccessModal, closeSuccessModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeSuccessModal} classes="modal_small pass-recover">
        <h3 className="modal__title">Вы успешно зарегистрировались!</h3>
        <p className="modal__hint">
          После подтверждения Вашей заявки менеджером, Вы получите доступ к
          системе.
        </p>
      </Modal>
    ),
  );

  const onSubmit = (values) => {
    let form = new FormData();
    let keys = _keys(values);

    keys.forEach((item) => {
      let identity = item === 'email' ? 'login' : item;
      form.append(`${identity}`, values[item]);
    });
    if (cityName !== 'other') {
      form.append(`cityName`, cityValue().title);
    }
    if (inviteCode) {
      form.append(`inviteCode`, inviteCode);
    }
    signup(form).then(
      (data) => {
        reset();
        openSuccessModal();
        if (window.location.host != 'localhost:3000') {
          ym('reachGoal', 'welcome_reg_page');
          window.fbq('track', 'Lead');
        }
        window.VK.Goal('complete_registration') 
        infoToast(<div>{data.message}</div>)
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const cityValue = () => citiesList.find((item) => item.id === cityName);

  return (
    <section className="section preview__auth">
      <div className="wrapper">
        <div className="auth__content">
        <div className="auth__title preview__auth-title preview__auth-title_nomob">
          <h1 className="h1">Регистрация</h1>
        </div>
          <div className="preview__mobile-tabs">
          <Link to={`/login`} className="button preview__mobile-tab">Войти</Link>
            <button type="button" className="button preview__mobile-tab preview__mobile-tab_active">Зарегистрироваться</button>
          </div>
          <div className="preview__auth-dots">
            <DotsSVG />
          </div>
          <div className="auth__form preview__auth-form">
            <div className="auth__sign-up">
              <div className="sign-up">
                <form
                  className="form"
                  name="sign-up"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="sign-in_name"
                      >
                        <input
                          name="name"
                          placeholder="Ваше имя"
                          id="sign-up_name"
                          className={
                            'input__control' +
                            (errors.name ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите имя',
                          })}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            {errors.name.message}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="sign-in_surname"
                      >
                        <input
                          name="lastName"
                          placeholder="Ваша фамилия"
                          id="sign-up_surname"
                          className={
                            'input__control' +
                            (errors.lastName ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите фамилию',
                          })}
                        />
                        {errors.surname && (
                          <span className="input__error-label">
                            {errors.lastName.message}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="sign-up_agency"
                      >
                        <input
                          name="company"
                          placeholder="Название агентства"
                          id="sign-up_agency"
                          className={
                            'input__control' +
                            (errors.company ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите название агентства',
                          })}
                        />
                        {errors.company && (
                          <span className="input__error-label">
                            {errors.company.message}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="sign-in_phone"
                      >
                        <Controller
                          as={
                            <InputMask
                              className={
                                'input__control' +
                                (errors.phone ? ' input__error' : '')
                              }
                            />
                          }
                          control={control}
                          autoComplete="off"
                          placeholder="Введите телефон"
                          mask="+7 (999) 999-99-99"
                          name="phone"
                          inputRef={register({
                            required: 'Введите телефон',
                          })}
                          defaultValue=""
                          rules={{
                            required: 'Введите телефон',
                          }}
                        />
                        {errors.phone && (
                          <span className="input__error-label">
                            {errors.phone.message}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="sign-in_email"
                      >
                        <input
                          name="email"
                          id="sign-in_email"
                          placeholder="Ваш e-mail"
                          className={
                            'input__control' +
                            (errors.email ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите e-mail',
                            pattern: {
                              value: validateMail,
                              message: 'Неправильный формат e-mail',
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="input__error-label">
                            {errors.email.message}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="sign-up__row">
                    <div className="sign-up__input">
                      <div className="select select_type_form">
                        <Dropdown
                          value={cityValue()}
                          options={citiesList}
                          onChange={(value) => setCity(value)}
                        />
                      </div>
                    </div>
                  </div>
                  {cityName === 'other' && (
                    <div className="sign-up__row">
                      <div className="sign-up__input">
                        <label
                          className="input input_width_available input_type_form"
                          htmlFor="sign-up_cityName"
                        >
                          <input
                            name="cityName"
                            placeholder="Название города"
                            id="sign-up_cityName"
                            className={
                              'input__control' +
                              (errors.company ? ' input__error' : '')
                            }
                            ref={register({
                              required: 'Введите название города',
                            })}
                          />
                          {errors.cityName && (
                            <span className="input__error-label">
                              {errors.cityName.message}
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="sign-up__row">
                    <div className="checkbox checkbox_view_invert">
                      <div
                        className={
                          'checkbox__box' +
                          (errors.agree ? ' checkbox__box-error' : '')
                        }
                      >
                        <input
                          className="checkbox__control"
                          type="checkbox"
                          id="modal-reservation-agreement"
                          name="agree"
                          ref={register({
                            required:
                              'Необходимо согласиться с правилами обработки данных',
                          })}
                        />
                        <label
                          className="checkbox__input"
                          htmlFor="modal-reservation-agreement"
                        ></label>
                        <div className="checkbox__marker"></div>
                      </div>
                      <label
                        className="checkbox__label"
                        htmlFor="modal-reservation-agreement"
                      >
                        Отправляя нам данную форму вы соглашаетесь с <br />
                        <Link to="/policy" className="preview__footer-policy" target="_blank">
                          политикой конфиденциальности
                        </Link>
                      </label>
                    </div>
                    {errors.agree && (
                      <span className="input__error-label">
                        {errors.agree.message}
                      </span>
                    )}
                  </div>

                  <div className="sign-up__row">
                    <div className="sign-up__submit">
                      <button className="button button_view_default">
                        <span>Зарегистрироваться</span>
                      </button>
                    </div>
                  </div>
                  <div className="sign-in__row">
                    <div className="sign-in__footer">
                      Уже зарегистрированы?{' '}
                      <Link to={`/login${window.location.search}`}>
                        <span className="sign-in__sign-up link-hover">
                          Войти
                        </span>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="preview__auth-banner">
          <BannerThin />
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: (form) => dispatch(signup(form)),
});

const mapStateToProps = (state) => ({
  fetching: state.user.fetching,
  error: state.user.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
