import formatter from "format-number";
import React, { useState } from "react";
import { getRoundedPrice, media } from '../../utils';

const priceFormat = (price) => {
  return formatter({ integerSeparator: "&nbsp;", suffix: "&nbsp;р." })(price);
};

const BuildingInfo = (props) => {
  return (
    <div class="custom-table__item">
      <div class="custom-table__tr">
        <div class="custom-table__td" style={{ flex: "1 0 32%", paddingLeft: media('isMobile') ? "10px" : "0" }}>
          {props.name}
        </div>
        <div class="custom-table__td" style={{ flex: media('isMobile') ? "1 0 15%" : "1 0 25%" }}>
          {props.countApartments}
        </div>
        <div class="custom-table__td nowrap" style={{ flex: "1 0 43%" }}>
          {media('isMobile') ? `от ${getRoundedPrice(props.minPrice)} до ${getRoundedPrice(props.maxPrice)} млн.р.` : props.price}
        </div>
      </div>
    </div>
  );
};

const ProgramInfo = (props) => {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const getPriceText = (min, max) => {
    return `от ${priceFormat(min)} до ${priceFormat(max)}`;
  };

  const getRoundedPriceText = (min, max) => {
    return `от ${getRoundedPrice(min)} до ${getRoundedPrice(max)} млн.р.`;
  };

  const getPaymentText = (min, max) => {
    return `от ${min} до ${max} %`;
  };

  const getPercentText = (min, max) => {
    if(min === max){
      return `${min} %`
    }
    return `от ${min} до ${max} %`;
  };

  const getPeriod = (min, max) => {
    if(min === max){
      return `до ${max} лет`
    }
    return `от ${min} до ${max} лет`;
  }; 

  return (
    <>
      {media("isMobile") ?
        <div class="custom-table__item">
          <div class={`custom-table__tr custom-table__drop-btn custom-table__tr-expanding ${showContent && `custom-table__tr-expanded`}`}>
            <button className={`expanding-button ${showContent && "expanding-button__expanded"}`} onClick={() => setShowContent(!showContent)} />
            <div class="custom-table__td custom-table__name" style={{ flex: "1 0 45%" }}>
              {props.name}
            </div>
            <div
              class="custom-table__td"
              style={{ flex: "1 0 45%" }}
              dangerouslySetInnerHTML={{
                __html: getRoundedPriceText(props.creditMin, props.creditMax),
              }}
            />
          </div>

          {showContent &&
            <>
              <div class="custom-table__tr">
                <div class="custom-table__td custom-table__details" style={{ flex: "1 0 55%" }}>
                  Перв. взнос:
                </div>
                <div
                  class="custom-table__td"
                  style={{ flex: "1 0 45%" }}
                  dangerouslySetInnerHTML={{
                    __html: getPaymentText(
                      props.initialPaymentMin,
                      props.initialPaymentMax
                    ),
                  }}
                />
              </div>

              <div class="custom-table__tr">
                <div class="custom-table__td custom-table__details" style={{ flex: "1 0 55%" }}>
                  Процент. ставка:
                </div>
                <div class="custom-table__td" style={{ flex: "1 0 45%" }}>
                {getPercentText(props.minPercent, props.maxPercent)}
                </div>
              </div>

              <div class="custom-table__tr">
                <div class="custom-table__td custom-table__details" style={{ flex: "1 0 55%" }}>
                  Срок:
                </div>
                <div class="custom-table__td" style={{ flex: "1 0 45%" }}>
                <span>{getPeriod(props.minPeriod,props.maxPeriod)}</span>
                </div>
              </div>


              <div class="custom-table__requires">
                <div class="custom-table__requires-row">
                  <div class="custom-table__requires-col">
                    <div class="list__item-label">Общий стаж</div>
                    <div class="list__item-value">{props.totalPeriod} мес.</div>
                  </div>
                </div>
                <div class="custom-table__requires-row">
                  <div class="custom-table__requires-col">
                    <div class="list__item-label">
                      Стаж на посл. месте работы для работника
                    </div>
                    <div class="list__item-value">{props.employeePeriod} мес.</div>
                  </div>
                </div>
                <div class="custom-table__requires-row">
                  <div class="custom-table__requires-col">
                    <div class="list__item-label">
                      Стаж на посл. месте работы собств. бизнеса
                    </div>
                    <div class="list__item-value">{props.businessPeriod} мес.</div>
                  </div>
                </div>
                <div class="custom-table__requires-row">
                  <div class="custom-table__requires-col">
                    <div class="list__item-label">Возраст мужчин</div>
                    <div class="list__item-value">
                      {props.maleAgeMin} - {props.maleAgeMax} лет
                    </div>
                  </div>
                </div>
                <div class="custom-table__requires-row">
                  <div class="custom-table__requires-col">
                    <div class="list__item-label">Возраст женщин</div>
                    <div class="list__item-value">
                      {props.femaleAgeMin} - {props.femaleAgeMax} лет{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div class="custom-table__dropdown-body">
                <div class="custom-table__info">
                  <p
                    className={`text ${showText && `text-is-visible`}`}
                    dangerouslySetInnerHTML={{
                      __html: props.requirements,
                    }}
                  />
                  <span
                    className="custom-table__show-text"
                    onClick={() => setShowText(!showText)}
                  >
                    {showText ? 'Скрыть' : 'Читать полностью'}

                    <svg className={`icon ${showText && `icon-reverse`}`}>
                      <use xlinkHref="#arrow_bottom"></use>
                    </svg>
                  </span>
                </div>

              </div>
            </>
          }
        </div> :

        <div className="custom-table__item">
          <div className="custom-table__tr custom-table__drop-btn">
            <div className="custom-table__td" style={{ flex: "1 0 18%" }}>
              {props.name}
            </div>
            <div
              className="custom-table__td"
              style={{ flex: "1 0 30%" }}
              dangerouslySetInnerHTML={{
                __html: getPriceText(props.creditMin, props.creditMax),
              }}
            />
            <div
              className="custom-table__td"
              style={{ flex: "1 0 14%" }}
              dangerouslySetInnerHTML={{
                __html: getPaymentText(
                  props.initialPaymentMin,
                  props.initialPaymentMax
                ),
              }}
            />
            <div className="custom-table__td" style={{ flex: "1 0 14%" }}>
            {getPercentText(props.minPercent, props.maxPercent)}
            </div>
            <div className="custom-table__td" style={{ flex: "1 0 19%" }}>
            <span>{getPeriod(props.minPeriod,props.maxPeriod)}</span>
              <div className="control">
                <button
                  className="button button_view_round-control"
                  onClick={() => setShow(!show)}
                >
                  <svg className="icon">
                    <use xlinkHref="#arrow_bottom"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className="banks__dropdown"
            style={{ display: show ? "block" : "none" }}
          >
            <div className="custom-table__dropdown-body">
              <div className="custom-table__info">
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: props.requirements,
                  }}
                />
              </div>
              <div className="custom-table__requires">
                <h4 className="h4">Требования к заемщику</h4>
                <div className="custom-table__requires-row">
                  <div className="custom-table__requires-col">
                    <div className="list__item-label">Общий стаж</div>
                    <div className="list__item-value">{props.totalPeriod} мес.</div>
                  </div>
                </div>
                <div className="custom-table__requires-row">
                  <div className="custom-table__requires-col">
                    <div className="list__item-label">
                      Стаж на посл. месте работы для работника
                    </div>
                    <div className="list__item-value">{props.employeePeriod} мес.</div>
                  </div>
                </div>
                <div className="custom-table__requires-row">
                  <div className="custom-table__requires-col">
                    <div className="list__item-label">
                      Стаж на посл. месте работы собств. бизнеса
                    </div>
                    <div className="list__item-value">{props.businessPeriod} мес.</div>
                  </div>
                </div>
                <div className="custom-table__requires-row">
                  <div className="custom-table__requires-col">
                    <div className="list__item-label">Возраст мужчин</div>
                    <div className="list__item-value">
                      {props.maleAgeMin} - {props.maleAgeMax} лет
                    </div>
                  </div>
                  <div className="custom-table__requires-col">
                    <div className="list__item-label">Возраст женщин</div>
                    <div className="list__item-value">
                      {props.femaleAgeMin} - {props.femaleAgeMax} лет{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

const BankInfo = (props) => {
  const { formName, bankPrograms, buildings } = props;
  const [tab, setTab] = useState("programs");

  return (
    <>
      <h2
        className="modal__title"
        dangerouslySetInnerHTML={{ __html: formName }}
      />
      <div className="switcher">
        <div
          className={`switcher__btn js-tab-nav ${
            tab === "programs" ? `active` : ``
          }`}
          onClick={() => setTab("programs")}
        >
          Ипотечные программы
        </div>
        <div
          className={`switcher__btn js-tab-nav ${
            tab === "buildings" ? `active` : ``
          }`}
          onClick={() => setTab("buildings")}
        >
          Cписок корпусов
        </div>
      </div>
      <div className={`modal-banks__tab ${tab === "programs" ? `active` : ``}`}>
        <div className="custom-table striped">
          <div className="custom-table__head">
            <div className="custom-table__tr">
              <div class="custom-table__th" style={{ flex: "1 0 18%", paddingLeft: media('isMobile') ? '50px' : 0 }}>
                {media("isMobile") ? 'Назв. прогр.' : 'Название программы'}
              </div>
              <div class="custom-table__th" style={{ flex: "1 0 30%", paddingLeft: media('isMobile') ? '30px' : 0 }}>
                Сумма
              </div>
              {!media("isMobile") &&
                <>
                  <div class="custom-table__th" style={{ flex: "1 0 14%" }}>
                    Перв. взнос
                  </div>
                  <div class="custom-table__th" style={{ flex: "1 0 14%" }}>
                    Проц. ставка
                  </div>
                  <div class="custom-table__th" style={{ flex: "1 0 19%" }}>
                    Срок
                  </div>
                </>
              }
            </div>
          </div>
          <div className="custom-table__body">
            {bankPrograms.map((program) => (
              <ProgramInfo key={program.id} {...program} />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`modal-banks__tab ${tab === "buildings" ? `active` : ``}`}
      >
        <div className="custom-table striped">
          <div className="custom-table__head">
            <div className="custom-table__tr">
              <div class="custom-table__th" style={{ flex: media('isMobile') ? "1 0 20%" : "1 0 32%" }}>
                Корпус, участок, дом
              </div>
              <div class="custom-table__th" style={{ flex: media('isMobile') ? "1 0 15%" : "1 0 25%" }}>
                Кол-во квартир
              </div>
              <div class="custom-table__th" style={{ flex: "1 0 43%" }}>
                Стоимость
              </div>
            </div>
          </div>
          <div className="custom-table__body">
            {buildings.map((program) => (
              <BuildingInfo key={program.id} {...program} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankInfo;
