import React from "react";
import {useModal} from "react-modal-hook";
import PanModal from "../components/core/PanModal";
import AppModal from "../components/AppModal";

const useAppModal = (closeModal) => useModal(
  () => {
    return (
    <PanModal title={'Мобильное приложение'} closeModal={closeModal} isBig>
      <AppModal/>
    </PanModal>
  )},
);

export default useAppModal
