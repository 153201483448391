import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorInput = ({ hexColor, onChange, labelTextInput }) => (
  <div className="hp__form-item">
    <label className="hp__form-label text">
      {labelTextInput}
    </label>
    <label className="input input_width_available input_type_form">
      <input type="text" className="input__control" value={hexColor} onChange={onChange} />
    </label>
  </div>
);

const SketchExample = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    onChange(newColor);
  };

  const styles = {
    color: {
      width: '21px',
      height: '21px',
      borderRadius: '2px',
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    },
    swatch: {
      border: '1px solid #ECEDF0',
      background: '#fff',
      borderRadius: '2px',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const pickerStyles = {
    picker: {
      width: '200px',
      padding: '20px 20px 10px',
      boxSizing: 'initial',
      background: '#fff',
      borderRadius: '20px',
      border: 'none',
      boxShadow: '0px 2px 20px rgba(143, 149, 172, 0.5)'

      // boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 8px 16px rgba(0,0,0,.15)'
    }
  };


  return (
    <div style={{position: 'absolute', right: 8, top: 36}}>
      {
        !displayColorPicker ? (
          <div onClick={handleClick} style={{cursor: 'pointer'}}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.7835 11.039L17.2241 11.4808C17.4936 11.7521 17.6448 12.119 17.6448 12.5013C17.6448 12.8837 17.4936 13.2505 17.2241 13.5218L16.5938 14.1521C16.4597 14.2879 16.2771 14.365 16.0862 14.3664C15.8953 14.3677 15.7117 14.2932 15.5757 14.1593L15.5685 14.1521L10.1108 8.68836C9.97492 8.55425 9.89785 8.3717 9.8965 8.18081C9.89515 7.98992 9.96963 7.8063 10.1036 7.67028L10.1108 7.66188L10.7411 7.03159C11.0128 6.76296 11.3795 6.6123 11.7615 6.6123C12.1436 6.6123 12.5103 6.76296 12.782 7.03159L13.2226 7.47339L15.7078 4.98824C16.6802 4.01579 18.265 3.96176 19.2554 4.9066C19.496 5.13409 19.6895 5.40674 19.8247 5.709C19.9599 6.01125 20.0343 6.33719 20.0436 6.66819C20.0528 6.99919 19.9968 7.32877 19.8786 7.63811C19.7605 7.94745 19.5826 8.23048 19.3551 8.47105L19.3094 8.51788L16.7835 11.039Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.8803 13.4581L9.83801 18.5004C9.48242 18.8607 9.03736 19.1198 8.54851 19.2513C8.05967 19.3827 7.54465 19.3817 7.05632 19.2484L4.94815 20.1668C4.81674 20.2244 4.67112 20.2415 4.52995 20.2158C4.38878 20.1902 4.25848 20.1229 4.15578 20.0227C4.0813 19.949 4.03106 19.8544 4.01175 19.7515C3.99245 19.6485 4.005 19.5421 4.04773 19.4464L5.02018 17.2134C4.88684 16.7249 4.88584 16.2097 5.01728 15.7207C5.14871 15.2316 5.40785 14.7864 5.76813 14.4305L10.8105 9.38818" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        ) : (
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>
        )
      }



      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker styles={pickerStyles} color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

const ColorPicker = ({labelTextInput, backHexColor, changeSettings, changeSettingsPayload}) => {

  const hexToRgb = hex =>
    hex?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      ?.map?.(x => parseInt(x, 16))
  const rgbColor = hexToRgb(backHexColor)
  const [color, setColor] = useState(rgbColor ? { r: rgbColor[0], g: rgbColor[1], b: rgbColor[2], a: 1 }: {r: 0, g: 0, b: 0, a: 1});
  const [hexColor, setHexColor] = useState(backHexColor ?? "#FFFFFF");

  const handleColorChange = (newColor) => {
    setColor(newColor.rgb);
    setHexColor(newColor.hex);
    changeSettings(changeSettingsPayload, newColor.hex)
  };

  const handleHexChange = (event) => {
    const newHexColor = event.target.value;
    setHexColor(newHexColor);
    if (newHexColor.length >= 3) {
      changeSettings(changeSettingsPayload, newHexColor)
      setColor({
        r: parseInt(newHexColor.slice(1, 3), 16),
        g: parseInt(newHexColor.slice(3, 5), 16),
        b: parseInt(newHexColor.slice(5, 7), 16),
        a: 1,
      });
    }
  };

  return (
    <div style={{position: 'relative'}}>
      <ColorInput hexColor={hexColor} labelTextInput={labelTextInput} onChange={handleHexChange} />
      <SketchExample color={color} onChange={handleColorChange} />
    </div>
  );
};

export default ColorPicker;
