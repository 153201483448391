import React, {useEffect} from "react";
import Button from "../../components/core/Button";
import { getSuffixUniversal, objectsArrayToFormData, toFormData } from "../../utils";
import { useState } from "react";
import { CheckboxControlled } from "../../components/core/Checkbox";
import SubmitButton from "../../components/SubmitButton";
import SimpleBar from "simplebar-react";
import useApi from "../../hooks/useApi";
import { useParams } from "react-router-dom";
import { successToast } from "../../components/toasts";
import Preloader from "../../components/Preloader";

const CompilationModal = ({addToCompilationParams,  close}) => {
  const [name, setName] = useState("");
  const [selectedCompilations, setSelectedCompilations] = useState([]);
  const toggleCompilation = (checked, id) => setSelectedCompilations(prev => checked ? [...prev, id] : prev.filter(prevId => prevId !== id));
  const [createNew, setCreateNew] = useState(false)

 const { savedFilterXmlId } = useParams()

  const {data: {compilations}, isLoading,  send, refresher} = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilations", {}],
    loadCondition: !savedFilterXmlId
  })

  const isInitial = compilations && compilations.length === 0 ? true : false
  const createCompilation = (params = {}) => {
    send(["panpartner:favorite.ajax", "saveCompilation", params])
      .then((resp) => {
        successToast(<div>{resp?.message}</div>)
        refresher()

        if (resp && compilations && compilations.length === 0) {
          addToCompilation(resp.compilations[0].id)
        }
      })
  }

  const addToCompilation = (compilations, favorite) => {
    let formData = toFormData( {id: compilations} );
    formData = objectsArrayToFormData(addToCompilationParams, "objects", formData)
    send(["panpartner:favorite.ajax", "addToCompilation", formData])
      .then((resp) => {
        successToast(<div>{resp?.message}</div>)
        refresher()
      })
  }

  if(isLoading) return <Preloader />
  return (
    <div className="compilation-modal">
      <h4 className="h4" style={isInitial ? {fontSize: 20} : {fontSize: 18}}>{isInitial ? 'Название подборки' : 'Добавить подборку'}</h4>
      <div className="compilation-modal__create">
        {!isInitial && !createNew && <span className="compilation-modal__create-link" onClick={() => {
          setCreateNew(true)
          setName('')
        }}>Создать новую подборку</span>}

        {isInitial &&
          <div className="compilation-modal__create-input">
            <label className="input input_width_available input_type_form">
              <input className={`input__control`} placeholder='Название новой подборки' value={name} onChange={e => setName(e.target.value)} />
            </label>
          </div>
        }

        {createNew &&
          <div>
            <div className="compilation-modal__create-input">
              <label className="input input_width_available input_type_form">
                <input className={`input__control`} placeholder='Название новой подборки' value={name} onChange={e => setName(e.target.value)} />
              </label>
            </div>
            <div className="favorite-note__actions">
              <button className={name === '' ? 'favorite-note__actions_btn-disabled' : ''} type="button" onClick={() => {
                createCompilation({ name })
                setName('')
              }}>Сохранить</button>
              <button onClick={()=>setCreateNew(false)}>Отменить</button>
            </div>
          </div>
        }

        {!isInitial &&
        <SimpleBar className="compilation-list-simplebar" style={{ maxHeight: 250, marginTop: 30 }}>
        <ul className="compilation-list" style={{maxHeight: 250}}>
          {compilations.map(({ title, countItems, id }) => (<li className="compilation-list__item" key={id}>
            <div className="compilation-list__item-overcont">
              <div className="compilation-list__item-info">
                <div className="compilation-list__item-title h6-l">{title}</div>
                <div className="compilation-list__item-advcount">
                  {countItems > 0 ?
                    `${countItems} объявлени${getSuffixUniversal({ "1-1": "е", "2-4": "я", default: "й" }, countItems)}` :
                    "Нет объявлений"}
                </div>
              </div>
            </div>
            <CheckboxControlled
              view="invert"
              checked={selectedCompilations.includes(id)}
              onChange={e => toggleCompilation(e.target.checked, id)}
              id={`checkbox-${id}`}
            />
          </li>))}
        </ul>
        </SimpleBar>
        }


        <div className="compilation-modal__action-btns">
          <Button buttonType="line" onClick={() => close()} isFull>Отменить</Button>
          <SubmitButton
              className="button button_view_default "
              isLoading={false}
              type="button"
              disabled={!isInitial && selectedCompilations.length === 0}
              // onClick={isInitial ? () => createCompilation({ name }) : () => addToCompilation(selectedCompilations)}
              onClick={isInitial ? () => {
                // debugger
                createCompilation({ name })
                close()
              } : () => {
                // debugger
                addToCompilation(selectedCompilations)
                close()
              }}
            >
            <span>Сохранить</span>
            </SubmitButton>
        </div>

      </div>
    </div>
  )
}

export default CompilationModal

export const CompilationViewingNoteModal = ({close, displayNote, setDisplayNote, handleViewNote}) => {
  return (
    <div className="compilation-modal">
      <h4 className="h4" onClick={handleViewNote}>Видимость заметки</h4>

      <CustomRadio displayNote={displayNote} setDisplayNote={setDisplayNote}/>

      <div className="compilation-modal__action-btns">
        <Button buttonType="line" onClick={() => close()} isFull>Отменить</Button>
        <Button isFull buttonType="primary" onClick={() => {handleViewNote()}}>Сохранить</Button>
      </div>
    </div>
  )
}

const CustomRadio = ({displayNote, setDisplayNote}) => {
  const [selectedOption, setSelectedOption] = useState(displayNote);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    if (selectedOption === 'all') {
      setDisplayNote('all')
    } else {
      setDisplayNote('onlyMe')
    }
  }, [selectedOption])

  return (
    <div className="custom-radio">
      <label>
        <input
          type="radio"
          value="onlyMe"
          checked={selectedOption === 'onlyMe'}
          onChange={handleOptionChange}
        />
        Видна только мне
      </label>

      <label>
        <input
          type="radio"
          value="all"
          checked={selectedOption === 'all'}
          onChange={handleOptionChange}
        />
        Мне и клиентам
      </label>



    </div>
  );
};
