import _keys from 'lodash/keys';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ym from "react-yandex-metrika";
import { signup } from '../../actions/register';
import { validateMail } from '../../utils';
import Modal from '../core/Modal';
import Dropdown from '../Dropdown';
import { errorToast, infoToast } from '../toasts';
const citiesList = [
  {
    id: 'spb',
    title: 'Санкт-Петербург',
  },
  {
    id: 'msk',
    title: 'Москва',
  },
  {
    id: 'other',
    title: 'Другой город',
  },
];

const Form = (props) => {
  const { handleSubmit, register, errors, control, reset, watch } = useForm({
    defaultValues: {
      agree: true,
    },
  });
  const {signup, innerRef, history, fetching, error } = props;

  const [cityName, setCity] = useState('spb');

  const [openSuccessModal, closeSuccessModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeSuccessModal} classes="modal_small pass-recover">
        <h3 className="modal__title">Вы успешно зарегистрировались!</h3>
        <p className="modal__hint">
          После подтверждения Вашей заявки менеджером, Вы получите доступ к
          системе.
        </p>
      </Modal>
    ),
  );

  const onSubmit = (values) => {
    let form = new FormData();
    let keys = _keys(values);

    keys.forEach((item) => {
      let identity = item === 'email' ? 'login' : item;
      form.append(`${identity}`, values[item]);
    });
    if (cityName !== 'other') {
      form.append(`cityName`, cityValue().title);
    }
    let params = new URLSearchParams(window.location.search);
    let utm = {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_medium: params.get('utm_medium'),
      utm_source: params.get('utm_source'),
      utm_term: params.get('utm_term'),
    };
    Object.keys(utm).map(key=>{
      form.append(`utm[${key}]`, utm[key]);
    })

    signup(form).then(
      (data) => {
        reset();
        openSuccessModal();
        if (window.location.host != 'localhost:3000') {
          ym('reachGoal', 'welcome_bottom_reg')
          window.fbq('track', 'Lead');
        }
        infoToast(<div>{data.message}</div>)
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const cityValue = () => citiesList.find((item) => item.id === cityName);

  return (
    <section className="preview__form-section wow animate__animated animate__fadeInUp">
      <div className="preview__container" ref={innerRef}>
        <div className="preview__form">
          <div className="preview__form-description">
            <div className="preview__form-title h2">
              Воспользуйтесь сервисом Panpartner.ru
            </div>
            <div className="preview__form-text">
              Зарабатывайте на новостройках Санкт-Петербурга и Москвы
            </div>
          </div>
          <div className="preview__form-body">
            <form
              className="form"
              name="sign-up"

            >
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-in_name"
                  >
                    <span className="input__label">Ваше имя</span>
                    <input
                      name="name"
                      id="sign-up_name"
                      className={
                        'input__control' + (errors.name ? ' input__error' : '')
                      }
                      ref={register({
                        required: 'Введите имя',
                      })}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-in_surname"
                  >
                    <span className="input__label">Ваша фамилия</span>
                    <input
                      name="lastName"
                      id="sign-up_surname"
                      className={
                        'input__control' +
                        (errors.lastName ? ' input__error' : '')
                      }
                      ref={register({
                        required: 'Введите фамилию',
                      })}
                    />
                    {errors.surname && (
                      <span className="input__error-label">
                        {errors.lastName.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-in_phone"
                  >
                    <span className="input__label">Телефон</span>
                    <Controller
                      as={
                        <InputMask
                          className={
                            'input__control' +
                            (errors.phone ? ' input__error' : '')
                          }
                        />
                      }
                      control={control}
                      autoComplete="off"
                      mask="+7 (999) 999-99-99"
                      name="phone"
                      inputRef={register({
                        required: 'Введите телефон',
                      })}
                      defaultValue=""
                      rules={{
                        required: 'Введите телефон',
                      }}
                    />
                    {errors.phone && (
                      <span className="input__error-label">
                        {errors.phone.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-in_email"
                  >
                    <span className="input__label">E-mail</span>
                    <input
                      name="email"
                      id="sign-in_email"
                      className={
                        'input__control' + (errors.email ? ' input__error' : '')
                      }
                      ref={register({
                        required: 'Введите e-mail',
                        pattern: {
                          value: validateMail,
                          message: 'Неправильный формат e-mail',
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="input__error-label">
                        {errors.email.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-up_agency"
                  >
                    <span className="input__label">Агентство</span>
                    <input
                      name="company"
                      id="sign-up_agency"
                      className={
                        'input__control' + (errors.company ? ' input__error' : '')
                      }
                      ref={register({
                        required: 'Введите название агентства',
                      })}
                    />
                    {errors.company && (
                      <span className="input__error-label">
                        {errors.company.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="modal-form__item">
                  <div className="select select_type_form">
                    <div className="custom-select-label">Город агентства</div>
                    <Dropdown
                      value={cityValue()}
                      options={citiesList}
                      onChange={(value) => setCity(value)}
                    />
                  </div>
                </div>
              </div>
              {cityName === 'other' && (
                <div className="modal-form__row">
                  <div className="modal-form__item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="sign-up_cityName"
                    >
                      <input
                        name="cityName"
                        placeholder="Название города"
                        id="sign-up_cityName"
                        className={
                          'input__control' +
                          (errors.company ? ' input__error' : '')
                        }
                        ref={register({
                          required: 'Введите название города',
                        })}
                      />
                      {errors.cityName && (
                        <span className="input__error-label">
                          {errors.cityName.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              )}
              <div className="modal-form__row">
                <button className="button button_view_default"      onClick={handleSubmit(onSubmit)}>
                  <span>Отправить</span>
                </button>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box' +
                        (errors.agree ? ' checkbox__box-error' : '')
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        ref={register({
                          required:
                            'Необходимо согласиться с правилами обработки данных',
                        })}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker"></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"
                    >
                      Отправляя нам данную форму вы соглашаетесь с <br />
                      <Link to="/policy" className="preview__footer-policy" target="_blank">
                        политикой конфиденциальности
                      </Link>
                    </label>
                  </div>
                  {errors.agree && (
                    <span className="input__error-label">
                      {errors.agree.message}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: (form) => dispatch(signup(form)),
});

const mapStateToProps = (state) => ({
  fetching: state.user.fetching,
  error: state.user.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
