const initState = 1;

const page = (state = initState, action) => {
  switch (action.type) {
    case "SET_PAGE":
      return action.page;
    default:
      return state;
  }
};

export default page;
