import React, { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import UnsubscribeForm from '../../components/Events/UnsubscribeForm';
import useMedia from "../../hooks/useMedia";
import './index.scss';
import SubscribeStaff from './SubscribeStaff';


const PageSign = props => {
  const {
    availableCount,
    availableReserveCount,
    closed,
    date,
    eventId,
    eventTime,
    isMainStream,
    isMaster,
    isSubscribed,
    name,
    recordLimit,
    agents,
    user,
    unsubscribe,
    subscribe,
    subscribedAgents,
    manager,
    isWebinar
  } = props
  const isMobile = useMedia("isMobile")
  const [unsubscribeModal, closeUnsubscribeModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeUnsubscribeModal} title="Отмена записи" >{children}</MobileModal>
        : <Modal close={closeUnsubscribeModal} classes="modal__events">{children}</Modal>
      } >
        <UnsubscribeForm
          name={name}
          date={date}
          time={eventTime}
          hideDate={true}
          handleSubmit={comment => unsubscribe(user.id, comment).finally(closeUnsubscribeModal)}
        />
      </Wrap >
    ), [name, eventTime, date, eventId, user]);


  const staffProps = useMemo(() => {
    const props = {
      title: "Свободных мест",
      buttonTitle: "Записаться",
      places: { left: availableCount, max: recordLimit }
    }
    const isReserveOnly = availableReserveCount > 0 && availableCount == 0
    if (isReserveOnly) {
      props.title = "Резервных мест"
      props.buttonTitle = "Записаться в резерв"
      props.noProgress = true
      props.places = { left: availableReserveCount, max: 1 }
    }
    return props
  }, [availableReserveCount, availableCount, recordLimit])
  // Мероприятие завершено:

  if (closed || user?.isBlockSubscribe || (availableCount === 0 && availableReserveCount === 0)) return <aside className="pe__sign"><div className="pe__sign-block">
    <div className="pe__sign-content">
      <p className="bold pe__sign-h5">{user?.isBlockSubscribe ? "Вы не можете быть записаны на мероприятие" : "Запись в лист ожидания по телефону +79657874839"}</p>
      {/* <button className="button button_view_default">Связаться с менеджером</button> */}
    </div>
  </div>
  </aside>
  //Инактив
  if (!isMainStream && !isSubscribed) return <aside className="pe__sign"> <div className="pe__sign-block pe__inactive">
    <div className="pe__sign-content">
      <img src={require('../../assets/images/pe/warning.svg').default} alt="" className="pe__inactive-warning" />
      <p className="bold pe__inactive-title">
        В связи с повышенным спросом,
        приоритетной возможностью записи на данное мероприятие обладают те партнеры,
        которые провели хотя бы одну сделку за последние 3 месяца.
      </p>
      <p className="pe__inactive-text">
        Мы заметили, что за последние пол года,
        у Вас не было сделок с нами.
        В связи с этим Вы можете записаться только в резерв.
      </p>
      <button className="button button_view_default" onClick={() => subscribe(user.id)}>Записаться в резерв</button>
    </div>

    <div className="pe__sign-content pe__sign-content_dark">
      <p className="pe__inactive-sign">
        Если Вы считаете, что мы ошиблись, считая, что сделок у Вас не было, сообщите об этом Вашему управляющему отдела развития
      </p>

      <div className="pe__inactive-manager">
        {manager && <p className="bold">{manager.name} {manager.lastName} </p>}
        {manager && <div className="pe__inactive-contacts">
          <a href={`tel:${manager.phone}`} className="link">{manager.phone}</a>
          {manager.telegram && <a class="pe__inactive-tg button button_with-icon button_view_social"
            href={`https://tlgg.ru/${manager.telegram.replace("@", "")}`}
            target="_blank" rel="noopener noreferrer">
            <svg className="icon icon_social-tg">
              <use xlinkHref="#social-tg"></use>
            </svg>
          </a>}
        </div>}
      </div>
      <div className="pe__inactive-general">
        <p className="bold">или по общему номеру</p>
        <div className="pe__inactive-contacts">
          <a href="tel:+ 78124261863" className="link">426-18-63</a>
        </div>
      </div>
    </div>
  </div>
  </aside>
  if (isSubscribed && (!isMaster || !isMainStream)) return <aside className="pe__sign"> <div className="pe__sign-block">
  <div className="pe__sign-content">
    <p className="bold pe__sign-h5">Вы записаны на это мероприятие</p>
    <button className="button button_view_outline" onClick={unsubscribeModal}>Отменить запись</button>
  </div>
</div>
</aside>
  return <SubscribeStaff
  {...staffProps}
  date={date}
  agents={agents}
  haveStaff={agents?.length > 0}
  subscribeSelf={() => subscribe(user.id)}
  subscribe={subscribe}
  unsubscribeSelf={unsubscribeModal}
  unsubscribe={unsubscribe}
  isSubscribed={isSubscribed}
  isMaster={isMaster}
  alreadySubscribed={subscribedAgents.filter(agent => agent.id != user?.id)}
  subscribedAgents={subscribedAgents}
  isWebinar={isWebinar}
/>
};
export default PageSign
