import React from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import ym from "react-yandex-metrika";
import { becomePartners } from "../../actions/tradeIn";
import { validateMail } from "../../utils";
import { errorToast, infoToast } from "../toasts";

const TradeInPartners = (props) => {
  const { title, sendPartner, hide, userInfo } = props;
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (data) => {
    sendPartner(data).then(
      data => {
        hide();
        infoToast(<div>{data.message}</div>)
        ym("reachGoal", "tradeIn_request");
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  return (
    <>
      <h3 className="modal__title">{title}</h3>

      <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <div class="modal-form__wrapper">
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label
                class="input input_width_available input_type_form"
                htmlFor="modal-partnership-firstName"
              >
                <span class="input__label">Имя</span>
                <input
                  id="modal-partnership-firstName"
                  name="name"
                  defaultValue={userInfo?.name}
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите имя",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label
                class="input input_width_available input_type_form"
                htmlFor="modal-partnership-secondName"
              >
                <span class="input__label">Фамилия</span>
                <input
                  id="modal-partnership-secondName"
                  name="lastName"
                  defaultValue={userInfo?.lastName}
                  className={
                    "input__control" + (errors.lastName ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.lastName && (
                  <span className="input__error-label">
                    {errors.lastName.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label
                class="input input_width_available input_type_form"
                htmlFor="modal-partnership-email"
              >
                <span class="input__label">E-mail</span>
                <input
                  id="modal-partnership-email"
                  name="email"
                  defaultValue={userInfo?.email}
                  className={
                    "input__control" + (errors.email ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите e-mail",
                    pattern: {
                      value: validateMail,
                      message: "Неправильный формат e-mail",
                    },
                  })}
                />
                {errors.email && (
                  <span className="input__error-label">
                    {errors.email.message}
                  </span>
                )}
              </label>
            </div>
            <div class="modal-form__item">
              <label
                class="input input_width_available input_type_form"
                htmlFor="modal-partnership-name"
              >
                <span class="input__label">Телефон</span>
                <InputMask
                  id="modal-partnership-name"
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  name="phone"
                  defaultValue={userInfo?.phone}
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label
                class="textarea textarea_type_form"
                htmlFor="modal-partnership-commentary"
              >
                <span class="textarea__label">Комментарий</span>
                <textarea
                  class="textarea__control"
                  name="comment"
                  ref={register}
                  id="modal-partnership-commentary"
                ></textarea>
              </label>
            </div>
          </div>
          <div class="modal-form__row">
            <div class="modal-form__item">
              <div class="checkbox checkbox_view_invert">
                <div
                  class={
                    "checkbox__box" +
                    (errors.agreement ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    class="checkbox__control"
                    type="checkbox"
                    id="modal-partnership-agreement"
                    name="agreement"
                    ref={register({
                      required: true,
                    })}
                    defaultChecked
                  />
                  <label
                    class="checkbox__input"
                    htmlFor="modal-partnership-agreement"
                  ></label>
                  <div class="checkbox__marker"></div>
                </div>
                <label
                  class="checkbox__label"
                  htmlFor="modal-partnership-agreement"
                >
                  Я согласен на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <button className="button button_view_default">Отправить</button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendPartner: (form) => dispatch(becomePartners(form)),
});

export default connect(null, mapDispatchToProps)(TradeInPartners);
