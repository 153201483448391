import React from 'react';
import formatter from "format-number";
import { media, getRoundedPrice } from '../utils'

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const adaptivePrice = (price) => media("isMobile")
  ? `${getRoundedPrice(price)} млн.`
  : priceFormat(price)

const PriceTitle = ({ minPrice, maxPrice }) => {
  return (
    minPrice !== maxPrice ? (
      <div>
        {!media("isMobile") && 'Цена '}
        от{" "}
        <span className="nowrap">
          {adaptivePrice(minPrice)}
        </span> до{" "}
        <span className="nowrap">
          {adaptivePrice(maxPrice)}
        </span>
      </div>
    ) : (
      <div>
        {!media("isMobile") && 'Цена '}
        <span className="nowrap">
          {adaptivePrice(minPrice)}
        </span>
      </div>
    )
  )
};

export default PriceTitle;
