import React, { memo } from "react";
import SubmitButton from "../../components/SubmitButton";
import NewAlert from "../../components/core/NewAlert";
import { standartErrorToast, standartSuccessToast } from "../../components/toasts";
import useApi from "../../hooks/useApi";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { FavoritesCheckboxItem } from "./components";

const checkboxKeys = [
  "showBlockTitle",
  "showBuilderTitle",
  "showPrice",
  "showDynamicPrice",
  "showPaymentGraphic",
  "showButtonGetPdf",
  "showContacts"
];

const inputStyle = { background: "#f6f7f8", boxShadow: 'none' };

const CompilationSettingsModal = memo(({ closeModal, refresher, id }) => {

  const {
    state,
    setValue,
    setValues
  } = useUniversalReducer({
    name: '',
    comment: '',
    vkPixel: '',
    yandexMetric: '',

    showBlockTitle: true,
    showBuilderTitle: true,
    showPrice: true,
    showDynamicPrice: true,
    showPaymentGraphic: true,
    showButtonGetPdf: true,
    showContacts: true
  })

  const { compilation, isLoading, isSending, send } = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilation", { id, noStatistic: "Y" }],
    loadCondition: id,
    processData: ({ compilation }) => {
      const settings = { ...compilation.settings };
      settings.name = compilation.title;
      settings.comment = compilation.comment;
      settings.vkPixel = compilation.vkPixel;
      settings.yandexMetric = compilation.yandexMetric;
      setValues(settings);
      return compilation;
    },
  })

  const createSetter = (key, isCheckbox) => e => setValue(key, isCheckbox ? e.target.checked : e.target.value);

  const createProps = (key, isCheckbox = true) => ({
    onChange: createSetter(key, isCheckbox),
    [isCheckbox ? "checked" : "value"]: state[key]
  })

  const onSuccessAction = resp => {
    standartSuccessToast(resp)
    refresher()
    closeModal()
  }

  const submit = () => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(state)) {
      if (checkboxKeys.includes(key)) formData.append(`settings[${key}]`, value ? 1 : 0);
      else formData.append(key, value);
    }
    if (id) formData.append("id", id);
    send(["panpartner:favorite.ajax", "saveCompilation", formData])
      .then(onSuccessAction, standartErrorToast);
  }

  const removeCompilation = () => send(["panpartner:favorite.ajax", "removeCompilation", { id }])
    .then(onSuccessAction, standartErrorToast);

  return (
    <div className="hp" style={{ marginTop: 20 }}>

      <div className="hp__form-item" style={{ width: '100%', marginBottom: 40 }}>
        <label className="input input_width_available input_type_form">
          <span className="input__label">Название подборки</span>
          <input
            className={'input__control'}
            style={inputStyle}
            placeholder="Название подборки для Вас и клиентов"
            {...createProps("name", false)}
          />
        </label>
      </div>

      <h3 className="h3">Настройки отображения для клиента</h3>
      <div className="compare-view-settings" style={{ marginTop: 0 }}>
        <ul className="compare-view-settings__items-list">
          <FavoritesCheckboxItem
            id={"showBlockTitle"}
            createProps={createProps}
            title="Показать название жк"
          />
          {/*<FavoritesCheckboxItem*/}
          {/*  id={"showBuilderTitle"}*/}
          {/*  createProps={createProps}*/}
          {/*  title="Показать название застройщика"*/}
          {/*/>*/}
          <FavoritesCheckboxItem
            id={"showPrice"}
            createProps={createProps}
            title="Показать цены"
          />
          <FavoritesCheckboxItem
            id={"showDynamicPrice"}
            createProps={createProps}
            title="Показать изменение цены"
          />
          <FavoritesCheckboxItem
            id={"showPaymentGraphic"}
            createProps={createProps}
            title="Показывать график платежей"
          />
          {/* <FavoritesCheckboxItem
            id={"showButtonGetPdf"}
            createProps={createProps}
            title="Показывать кнопку «Скачать PDF»"
          /> */}
          <FavoritesCheckboxItem
            id={"showContacts"}
            createProps={createProps}
            title="Показывать контакты"
          />
        </ul>
      </div>

      <div className="error-report__area" style={{ marginTop: 20 }}>
        <label
          className="textarea textarea_type_form"
          htmlFor="modal-reservation-commentary"
        >
          <span className="textarea__label">Комментарий к подборке </span>
          <textarea
            style={{ minHeight: 87 }}
            className="textarea__control"
            placeholder="По желанию Вы можете добавить приветственное сообщение"
            {...createProps("comment", false)}
          />
        </label>
      </div>
      <h3 className="h3">Аналитика</h3>

      {/* <div className="hp__form-item" style={{ width: '100%', marginTop: 20 }}>
        <label className="input input_width_available input_type_form">
          <div className="hint-label">
            <span className="input__label">VK Реклама</span>
            <a className="input__label input__label_hint" href="https://ads.vk.com/insights/chto-takoe-piksel-vk-reklamy" target="_blank">Что такое пиксель</a>
          </div>
          <input
            className={'input__control'}
            style={inputStyle}
            placeholder="VK-PRPR-1111-prpr"
            {...createProps("vkPixel", false)}
          />
        </label>
      </div> */}

      <div className="hp__form-item" style={{ width: '100%', marginBottom: 20, marginTop: 20 }}>
        <label className="input input_width_available input_type_form">
          <div className="hint-label">
            <span className="input__label">Счетчик Яндекс.Метрика</span>
            <a className="input__label input__label_hint" href="https://practicum.yandex.ru/blog/kak-rabotaet-yandex-metrika/" target="_blank">Что такое счетчик</a>
          </div>
          <input
            className={'input__control'}
            style={inputStyle}
            placeholder="98765432"
            {...createProps("yandexMetric", false)}
          />
        </label>
      </div>

      <NewAlert
        text="Чтобы отслеживать количество заявок с подборки, используйте название panCollectionCallRequest при создании цели."
        bgColor="#EEEFFB"
        iconColor="#8790E5"
      />

      <SubmitButton
        className="btn h6 btn_primary btn_full"
        type="button"
        onClick={submit}
        isLoading={(isLoading && !!id) || isSending}
      >
        Сохранить
      </SubmitButton>

      {!!id && <>
        <SubmitButton
          className="btn h6 btn_line btn_full"
          type="button"
          onClick={removeCompilation}
          isLoading={(isLoading && !!id)|| isSending}
        >
          Удалить подборку
        </SubmitButton>
        <div style={{ marginTop: 15 }}>
          <NewAlert
            text="Будьте внимательны! Восстановить подборку невозможно"
            bgColor="#ffe6e5"
            iconColor="#EF372E"
            oneLine
          />
        </div>
      </>
      }

    </div>
  )
})

export default CompilationSettingsModal;
