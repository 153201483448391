import React, {useState} from "react";
import useApi from "../../hooks/useApi";
import {
  FixationImportantIcon, SfFilterLinkIcon,
  SfFilterMailIcon,
  SfFilterTelegramIcon,
  SfFilterWhatsupIcon
} from "../../components/Public/markup/icons";
import NewAlert from "../../components/core/NewAlert";
import Preloader from "../../components/Preloader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SubmitButton from "../../components/SubmitButton";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { applyToFormData, toFormData } from "../../utils";
import { getUrl } from "../../api";
import {useSelector} from "react-redux";
import CommercialCalc from "../../components/Commercial/Redesign/CommercialCalc";
import useUniversalForm from "../../hooks/useUniversalForm";
import Loader from "../../components/Loader";
import {animated} from "react-spring";
import {successToast} from "../../components/toasts";

const PdfSettingsModal = ({ item = {} }) => {

  const { id, city, type, mortgage: propsMortgage, price } = item;
  const {
    state,
    setValue,
    reset,
    setValues
  } = useUniversalReducer({});

  const { data: { settings, mortgage: mortgageData }, isLoading, send, isSending } = useApi({
    payload: ["panpartner:favorite.ajax", "getPdfSettings", { id, type, city: city?.code }],
    processData: resp => {
      const result = {}
      for (const { items } of resp.settings) {
        for (const key in items) {
          const item = items[key]
          result[key] = item?.value && item?.valueXmlId ? item.value : ""
        }
      }
      setValues(result)
      return resp
    }
  }, [])


  const user = useSelector(state => state.user)
  const [isSend, setIsSend] = useState(false)
  const [tgLinkLoader, setTgLinkLoader] = useState(false)
  const [waLinkLoader, setWaLinkLoader] = useState(false)
  const [copyLinkLoader, setCopyLinkLoader] = useState(false)
  const getPdf = (t) => {
    if (t === 'tg') {
      setIsSend(true)
      setTgLinkLoader(true)
    }
    if (t === 'wa') {
      setIsSend(true)
      setWaLinkLoader(true)
    }
    if (t === 'copy') {
      setIsSend(true)
      setCopyLinkLoader(true)
    }

    const formData = toFormData(state, "settings", true, true);
    applyToFormData(formData, { objectId: id, type, city: city?.code })
    if (propsMortgage) applyToFormData(formData, sendMortgageValues, 'mortgage')
    send(["panpartner:favorite.ajax", "getPdf", formData]).then(({ filePath }) => {
      if (t === 'tg') {
        window.open(`https://t.me/share/?url=${getUrl(filePath)}`)
        setTgLinkLoader(false)
        setIsSend(false)
        return
      }
      if (t === 'wa') {
        window.open(`https://wa.me/send?text=${getUrl(filePath)}`)
        setWaLinkLoader(false)
        setIsSend(false)
        return
      }

      if (t === 'copy') {
        navigator.clipboard.writeText(getUrl(filePath))
        setCopyLinkLoader(false)
        setIsSend(false)
        successToast(<div>Ссылка скопирована в буфер обмена</div>)
        return
      }
      window.open(getUrl(filePath))
    })
  }


  const [priceEstate, setPriceEstate] = useState(price)
  const [firstPayEstate, setFirstPayEstate] = useState(propsMortgage ? propsMortgage?.initialPayment : 0)
  const [timeEstate, setTimeEstate] = useState(propsMortgage ? propsMortgage?.period : 0)
  const [rateEstate, setRateEstate] = useState(propsMortgage ? propsMortgage?.percent : 0)


  const sendMortgageValues = {
    initialPayment: firstPayEstate.toString(),
    percent: rateEstate.toString(),
    period: timeEstate.toString(),
  }


  // const userInfo = useSelector(state => state.user?.info);
  // const { send: sendUniveralForm } = useUniversalForm({
  //   formId: 65,
  //   defaultValues: {
  //     name: userInfo?.name,
  //     secondName: userInfo?.lastName,
  //     phone: userInfo?.phone || '',
  //     company: userInfo?.workCompany,
  //   },
  // });


  if (isLoading) return <Preloader loading />;
  return (
    <div className="pdf-modal">
      <div style={{ marginTop: 40, marginBottom: 30 }}>
        <NewAlert
          text="Перед тем, как скачать или отправить презентацию клиенту, Вы можете настроить показ той или иной информации"
          bgColor="#EEEFFB"
          iconColor="#8790E5"
          oneLine
        />
      </div>

      <div className="pdf-modal__grid">
        {settings.map(setting => <div className="pdf-modal__grid-item">
          <h3 className='h3'>{setting?.title}</h3>
          <div className="compare-view-settings">
            <ul className="compare-view-settings__items-list">
              {Object.entries(setting.items).map(([key, { value, id, name }]) => {
                return (
                  <>
                  <li key={id}>
                    <label className="switch" htmlFor={id}>
                      <span className="switch__label">
                        {name}
                      </span>
                      <div className="switch__box">
                        <input
                          className="switch__control"
                          type="checkbox"
                          id={id}
                          checked={!!state[key]}
                          onChange={e => setValue(key, e.target.checked ? value : "")}
                        />
                        <div className="switch__input" htmlFor={id} />
                        <div className="switch__marker" />
                      </div>
                    </label>
                  </li>
                  { key === 'mortgage_chart' && propsMortgage && !!state[key] &&
                    <div>
                      <CommercialCalc
                        price={priceEstate}
                        // city={"spb"}
                        // type="apartment"
                        id={'calcId1'}
                        period={timeEstate}
                        prepay={firstPayEstate}
                        percent={rateEstate}
                        btnClass={`constructor__btn`}
                        mortgage={mortgageData}
                        setPriceEstate={setPriceEstate}
                        setFirstPayEstate={setFirstPayEstate}
                        setTimeEstate={setTimeEstate}
                        setRateEstate={setRateEstate}
                        // sendUniveralForm={sendUniveralForm}
                        forFavorites
                        // getPdf={() => {
                        //   getPdf(xmlId, city, firstPayEstate, rateEstate, timeEstate)
                        // }}
                        // fetchingPdf={fetchingPdf}
                      />
                    </div>
                  }
                  </>
                )
              })}
            </ul>
          </div>
        </div>)
        }
      </div>


      <div className="pdf-modal__share">
        <ul className="sf-setting-links">

          <a className="sf-setting-links__item" onClick={() => {getPdf('wa')}} target="_blank" rel="noopener noreferrer">
            <div className="sf-setting-links__icon">
              {waLinkLoader ? <animated.div><Loader  loaderColor={'#777A89'}/></animated.div> : <SfFilterWhatsupIcon />}
            </div>
            <p className="sf-setting-links__text">
              WhatsApp
            </p>
          </a>
          <a className="sf-setting-links__item" onClick={() => {getPdf('tg')}} target="_blank" rel="noopener noreferrer">
            <div className="sf-setting-links__icon">
              {tgLinkLoader ? <animated.div><Loader  loaderColor={'#777A89'}/></animated.div> : <SfFilterTelegramIcon />}
            </div>
            <p className="sf-setting-links__text">
              Telegram
            </p>
          </a>
          <CopyToClipboard>
            <li className="sf-setting-links__item" onClick={() => {getPdf('copy')}}>
              <div className="sf-setting-links__icon">
                {copyLinkLoader ? <animated.div><Loader  loaderColor={'#777A89'}/></animated.div> : <SfFilterLinkIcon />}
              </div>
              <p className="sf-setting-links__text">
                Ссылкой
              </p>
            </li>
          </CopyToClipboard>
        </ul>
        <div style={{marginTop: 30}}>
          <SubmitButton
            className="btn h6 btn_primary"
            addStyle={{ width: '100%' }}
            type="button"
            onClick={getPdf}
            isLoading={!isSend && isSending}
          >
            Скачать PDF
          </SubmitButton>
        </div>

      </div>


    </div>
  )
}

export default PdfSettingsModal
