import min from 'lodash/min';
import React, {useEffect, useReducer, useState} from 'react';
import InputMask from "react-input-mask";
import {connect, useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router';
import {getUrl} from '../../api';
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import Dropdown from "../../components/core/Dropdown";
import Dropzone from '../../components/core/Dropzone';
import {FileRow} from '../../components/FileUploader';
import SubmitButton from '../../components/SubmitButton';
import {errorToast, standartErrorToast} from '../../components/toasts';
import useFileManager from '../../hooks/useFileManager';
import useMedia from "../../hooks/useMedia";
import usePermission from '../../hooks/usePermission';
import useUniversalForm from '../../hooks/useUniversalForm';
import AddClient from './AddClient';
import './index.scss';
import {Link, useLocation} from 'react-router-dom';

const getLink = (type, city="spb", objectId) => {
  switch (type) {
    case "apartment":
      return `/realty/${city}/apartment/${objectId}`
    case "parking":
      return `/realty/${city}/parking/${objectId}`
    case "assignment":
      return `/assignments/${objectId}`
    case "secondary":
      return `/secondary/${objectId}`
    case "commercial":
      return `/commercial/${objectId}`
    case "suburban":
      return `/lands/${city}/${objectId}`
    case "parcel":
      return `/lands/${city}/${objectId}`
    case "house":
      return `/house/${objectId}`
    default:
      throw new Error(`Что это вообще за страница то такая?! type, city, objectId: ${type}, ${city}, ${objectId}`)
  }
}
const ObjectInfo = (props) => {

  const {setShowObject, object = {}, suburbanType} = props;
  const {manager, plan} = object

  const {type, city, objectId} = useParams()
  return (
    <aside className="fbooking__object">
      <header className="fbooking__object-header">
        <img className="fbooking__object-img"
             src={getUrl(plan?.src)}
             onError={(e) => {
               e.target.onerror = null;
               e.target.src = houseEmpty;
             }}
             alt=""
        />

        {suburbanType === 'parcel' ?
          <a href={getLink(suburbanType, city, object?.xmlId)} target="_blank" rel="noreferrer noopener" className="fbooking__object-anchor link">Страница объекта</a>
          : suburbanType === 'house' ?
          <a href={getLink(suburbanType,'', object?.xmlId)} target="_blank" rel="noreferrer noopener" className="fbooking__object-anchor link">Страница объекта</a>
          : <a href={getLink(type, city, objectId)} target="_blank" rel="noreferrer noopener" className="fbooking__object-anchor link">Страница объекта</a>
        }
      </header>
      <div className="fbooking__info">
        <div className="fbooking__info-grid">
          {!!object?.id && <div className="fbooking__info-col">
            <div className="fbooking__info-value">ID</div>
            <div className="fbooking__info-desc">{object.id}</div>
          </div>}
          {!!object?.squareTotal && <div className="fbooking__info-col">
            <div className="fbooking__info-value">Общая площадь</div>
            <div className="fbooking__info-desc">{object.squareTotal}</div>
          </div>}
          {!!object?.floor && <div className="fbooking__info-col">
            <div className="fbooking__info-value">Этаж</div>
            <div className="fbooking__info-desc">{object?.floor}{object?.floors ? ` / ${object?.floors}` : ""}</div>
          </div>}
          {object?.corpus && <div className="fbooking__info-col">
            <div className="fbooking__info-value">Корпус</div>
            <div className="fbooking__info-desc">{object.corpus}</div>
          </div>}
          {!!object?.ending && <div className="fbooking__info-col">
            <div className="fbooking__info-value">Срок сдачи</div>
            <div className="fbooking__info-desc">{object.ending}</div>
          </div>}
          {!!object?.issuingKeys && <div className="fbooking__info-col">
            <div className="fbooking__info-value">Ключи</div>
            <div className="fbooking__info-desc">{object.issuingKeys}</div>
          </div>}
        </div>

      </div>


      {manager && <div className="fbooking__manager">
        <div className="manager">
          <div className="manager-avatar">
            <div className="manager-avatar_wrapper">
              <img src={getUrl(manager.photo?.src)} alt={`${manager.name} ${manager.lastName}`}/>
            </div>
          </div>
          <div className="manager-content">
            <div>
              <div className="manager-title">{`${manager.name} ${manager.lastName}`}</div>
              <div className="manager-post">{manager.position}</div>
            </div>

            <div className="manager-contacts">
              <ul className="manager-contacts_list">
                <li className="manager-contacts_item">
                  <a className="link-hover" href={`tel:${manager.phone}`}>{manager.phone}</a>
                </li>
                <li className="manager-contacts_item">
                  <a className="link-hover" href={`mailto:${manager.email}`}>{manager.email}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>}

      {
        setShowObject &&
        <div className="fbooking__showmore">
          <button type="button" className="fbooking__showmore-btn banks__slider-more"
                  onClick={() => setShowObject(false)}>
            <span>
              Скрыть
            </span>
            <svg className="icon icon_more" style={{transform: `rotate(180deg)`}}>
              <use xlinkHref="#chevron"></use>
            </svg>
          </button>
        </div>

      }

    </aside>
  );
};

const BookingProgress = ({perspectiveWidth, progressWidth}) => {
  return <div className="fbooking__progress">
    <ul className="fbooking__progress-percents">
      <li className="fbooking__progress-percent">0%</li>
      <li className="fbooking__progress-percent">20%</li>
      <li className="fbooking__progress-percent">40%</li>
      <li className="fbooking__progress-percent">60%</li>
      <li className="fbooking__progress-percent">80%</li>
      <li className="fbooking__progress-percent">100%</li>
    </ul>
    <div className="fbooking__progress-bar">
      <div className="fbooking__progress-filled" style={{width: `${progressWidth}%`}}></div>
      {progressWidth < 100 && <div className="fbooking__progress-prospective" style={{width: `${perspectiveWidth}%`}}>
        <div
          className={`fbooking__tooltip fbooking__progress-tooltip${perspectiveWidth < 40 ? ` fbooking__progress-tooltip_negative-coord` : ``}`}>
          <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49998 0.0596924L14.6447 12.4347H0.355289L7.49998 0.0596924Z" fill="#2CBA2E"/>
          </svg>

          {`+ ${perspectiveWidth - progressWidth}% за следующий шаг`}
        </div>
      </div>}
    </div>
  </div>
}

const initialClientState = {
  name: "",
  lastName: "",
  patronymic: "",
  phone: "",
  email: "",
}
const clientReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {...state, [action.ident]: action.value};
    case 'reset':
      return initialClientState;
    default:
      throw new Error();
  }
}

const errorsReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {...state, [action.ident]: {message: action.message}};
    case 'clear':
      return {...state, [action.ident]: null};
    case 'reset':
      return {};
    default:
      throw new Error();
  }
}
const errorMessages = {
  agentName: "Введите имя и фамилию",
  agentPhone: "Введите телефон",
  clientId: "Выберите клиента",
  lastName: "Введите фамилию",
  name: "Введите имя",
  patronymic: "Введите отчество",
  pay_type: "Выберите форму оплаты",
  phone: "Введите телефон",
  policy: "Согласитесь с политикой конфиденциальности",
  personal_data: "Согласитесь с обработкой персональных данных",
  familiar_with_advertising: "Ознакомьтесь с рекламным регламентом",
  familiar_with_builder: "Ознакомьтесь с регламентом взаимодействия с застройщиком"
}

const FlatBooking = (props) => {
  

  const isMobile = useMedia("isMobile");
  const {type, city, objectId} = useParams()
  const user = useSelector(state => state.user?.info)
  const history = useHistory()

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const suburbanType = queryParams.get("type");


  const getFormId = () => {
    switch (type) {
      case "apartment":
      case "parking":
        if (city === "spb") return 2;
        return 14;
      case "assignment":
        return 10;
      case "secondary":
        return 11;
      case "commercial":
        return 12;
      case "suburban":
        if (suburbanType === 'parcel') return 70
        if (suburbanType === 'house') return 71
        // if (suburbanType === 'project') return 72
        return 43;
      default:
        return false;
    }
  }

  const [agree, setAgree] = useState(true);

  const [personalData, setPersonalData] = useState(true);
  const [familiarWithAdvertising, setFamiliarWithAdvertising] = useState(true);
  const [familiarWithBuilder, setFamiliarWithBuilder] = useState(true);

  const [show, setShow] = useState(false);

  const [clientState, dispatchClient] = useReducer(clientReducer, initialClientState);
  // console.log(clientState)
  const [errors, dispatchErrors] = useReducer(errorsReducer, {});
  const setErr = (ident, message) => dispatchErrors({type: "set", ident, message})
  const clearErr = ident => dispatchErrors({type: "clear", ident})
  const [showObject, setShowObject] = useState(false);

  const [progressWidth, setprogressWidth] = useState(40);
  const [perspectiveWidth, setPerspectiveWidth] = useState(80);

  const rewardsByType = {
    agentName: 10,
    agentPhone: 10,
    "pay_type": 40
  }
  const onValueChanged = form => {
    let totalReward = 0
    const ungivenRewards = []

    Object.entries(rewardsByType).forEach(([key, reward]) => {
      const formValue = getValue(key)
      const haveValue = Array.isArray(formValue) ? formValue.length : formValue
      totalReward += haveValue ? reward : 0;
      if (!haveValue) ungivenRewards.push(reward)
    })

    const userCondition = (!!clientState.name && !!clientState.lastName && !!clientState.phone && show) || !!getValue("clientId")

    if (userCondition) totalReward += 40;
    else ungivenRewards.push(40);

    let nextRevard = min(ungivenRewards)

    if (!nextRevard) setPerspectiveWidth(0);
    else setPerspectiveWidth(totalReward + nextRevard);
    setprogressWidth(totalReward)
  }

  const {
    form,
    formSettings,
    getItems,
    getValue,
    isLoading,
    response,
    send,
    setValue,
    setValues,
    forcedSetValue,
  } = useUniversalForm({
    formId: getFormId(),
    city,
    defaultValues: {
      agentName: `${user.name} ${user.lastName}`,
      agentPhone: user.phone,
      agentId: user.id,
      objectId
    },
    requestOptions: {
      objectId,
      city
    },
    defaultCheckboxes: ["policy", "personal_data", "familiar_with_advertising", "familiar_with_builder"],
    onValueChanged
  })

  const paymentsOptions = getItems("pay_type").map?.(item => ({id: item.value, title: item.name}))

  const requiredFields = ["agentName", "agentPhone", "clientId", "pay_type", "policy", "personal_data", "familiar_with_advertising", "familiar_with_builder"]
  const validateForm = () => {
    formSettings.forEach(setting => {
      const value = getValue(setting.code)
      const isReq = requiredFields.includes(setting.code)
      if (isReq && Array.isArray(value) && !value.length) {
        return setErr(setting.code, errorMessages[setting.code])
      }
      if (isReq && !value) {
        setErr(setting.code, errorMessages[setting.code])
      }
    })
    Object.entries(clientState).forEach(([key, value]) => {
      if (!value?.toString?.()?.replace?.(/ /gi, "") && key !== "email" && key !== "patronymic") setErr(key, errorMessages[key])
    })
  }

  const setClientValue = (e, ident) => {
    dispatchClient({type: "set", value: e.target.value, ident})
    onValueChanged(form)
  }

  const sendForm = () => {
    validateForm()
    if (!agree) return errorToast("Для отправки формы необходимо согласиться с политикой конфиденциальности")
    if (progressWidth < 100) {
      return errorToast("Не заполнены обязательные поля")
    }

    send(null, {...clientState, ...({newClient: true})}).then(
      () => history.push({
        pathname: "/success-booking",
        state: {link: getLink(type, city, objectId), from: window.location.pathname, type}
      }),
      standartErrorToast
    )
  }

  const onFilesChange = files => forcedSetValue(files, "files")

  const {files, uploadFiles, deleteFile, editFileName,} = useFileManager({
    maxFiles: 100,
    onFilesChange
  });
  const setShowAddClient = val => {
    setShow(val)
    if (val) return setValue("", "clientId");
    else dispatchClient({type: "reset"})
  }
  const showComission = usePermission("motivation")
  const {clients = []} = response

  const emptyUniFormClient = {
    clientEmail: "",
    clientLastName: "",
    clientName: "",
    clientPatronymic: "",
    clientPhone: "",
    clientId: 0,
    clientBirthday: ""
  }
  const clientToUniForm = cl => ({
    clientEmail: cl.email ? cl.email : "",
    clientLastName: cl.lastName,
    clientName: cl.name,
    clientPatronymic: cl.patronymic,
    clientPhone: cl.phone,
    clientId: cl.id,
    clientBirthday: ""
  })

  const selectClient = id => {
    const client = clients.find(client => client.id === id)
    setValues(id ? clientToUniForm(client) : emptyUniFormClient)
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
      sanitizedData = sanitizedData.substring(2);
    }
    setValue('+7'+ sanitizedData, "clientPhone")
  };

  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="wrapper__center">

          <section className="fbooking">
            {response?.title && <h1 className="h1 fbooking__title">{response.title}</h1>}

            <div className="fbooking__container">
              <div className="fbooking__formbox">

                <div className="fbooking__info fbooking__info_border_none fbooking__info_form">
                  <div className="fbooking__info-row">
                    {response?.object?.price && <div className="fbooking__info-col">
                      <div className="fbooking__info-value">Цена объекта</div>
                      <div className="fbooking__info-desc">{response.object.price}</div>
                    </div>}
                    {showComission && response?.object?.commission && <div className="fbooking__info-col">
                      <div className="fbooking__info-value">Ваша комиссия</div>
                      <div className="fbooking__info-desc">{response.object.commission}</div>
                    </div>}
                    {showComission && <div className="fbooking__info-col">
                      <div className="fbooking__info-value">Авансирование</div>
                      <div className="fbooking__info-desc">{response.object?.prepayment ? "Да" : "Нет"}</div>
                    </div>}
                    {showComission && response?.object?.prepaymentRules && !!response.object?.prepayment && type !== "apartment" &&
                      <div className="fbooking__info-col">
                        <div className="fbooking__info-value">Сроки выплаты</div>
                        <div className="fbooking__info-desc">{response.object.prepaymentRules}</div>
                      </div>}
                  </div>

                  {
                    !showObject &&
                    <button type="button" className="fbooking__showmore-btn banks__slider-more"
                            onClick={() => setShowObject(true)}>
                      <span>
                        Подробнее
                      </span>
                      <svg className="icon icon_more">
                        <use xlinkHref="#chevron"></use>
                      </svg>
                    </button>
                  }

                  {isMobile && showObject && <ObjectInfo setShowObject={setShowObject} object={response.object}/>}
                </div>

                <BookingProgress progressWidth={progressWidth} perspectiveWidth={perspectiveWidth}/>

                <form action="#" className="fbooking__form modal-form"
                      style={{...(progressWidth === 100 ? {marginTop: 20} : [])}}>
                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-name"
                      >
                        <span className="input__label">Ваше имя и фамилия</span>

                        <input
                          id="flat-booking-name"
                          className={
                            "input__control" + (errors.agentName ? " input__error" : "")
                          }
                          placeholder="Введите имя и фамилию"
                          onChange={e => setValue(e.target.value, "agentName")}
                          value={getValue("agentName")}
                        />
                      </label>
                      {errors.agentName && (
                        <span className="input__error-label">
                          {errors.agentName.message}
                        </span>
                      )}
                    </div>
                    <div className="fbooking__form-col">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-phone"
                      >
                        <span className="input__label">Ваш телефон</span>
                        <InputMask
                          className={
                            "input__control" + (errors.agentPhone ? " input__error" : "")
                          }
                          placeholder="Введите телефон"
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar=" "
                          onChange={e => setValue(e.target.value, "agentPhone")}
                          value={getValue("agentPhone")}
                        />
                      </label>
                      {errors.agentPhone && (
                        <span className="input__error-label">
                          {errors.agentPhone.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">

                      {!show && <div className="select select_type_form">
                        <label
                          className="custom-select-label"
                          htmlFor="modal-reservation-client"
                        >
                          Ваш клиент
                        </label>
                        <Dropdown
                          withSearch
                          wrapperClass="booking-client-select"
                          options={clients.map((client) => ({
                            id: client.id,
                            title: `${client.name} ${client.lastName}`,
                          }))}
                          placeholder="Не выбран"
                          flatBookingTooltip={`+ ${40}% к ${isMobile ? `бронир.` : `бронированию`}`}
                          onChange={(id) => {
                            setValue(id, "clientId")
                            selectClient(id)
                          }}
                          value={getValue("clientId")}
                        />
                        {errors.clientId && (
                          <span className="input__error-label">
                            {errors.clientId.message}
                          </span>
                        )}
                      </div>}

                    </div>
                  </div>
                  {!show && getValue("clientId") !== undefined && getValue("clientId") !== 0 &&
                    <>
                      <div className="fbooking__form-row">
                        <div className="fbooking__form-col">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-reservation-name"
                          >
                            <span className="input__label">Фамилия<span className="is-red-text sell__required">*</span></span>
                            <input
                              id="flat-booking-name"
                              className="input__control"
                              onChange={e => setValue(e.target.value, "clientLastName")}
                              value={getValue("clientLastName")}
                            />
                          </label>

                        </div>
                        <div className="fbooking__form-col">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-reservation-phone"
                          >
                            <span className="input__label">Имя<span className="is-red-text sell__required">*</span></span>
                            <input
                              id="flat-booking-name"
                              className="input__control"
                              onChange={e => setValue(e.target.value, "clientName")}
                              value={getValue("clientName")}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="fbooking__form-row">
                        <div className="fbooking__form-col">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-reservation-phone"
                          >
                            <span className="input__label">Отчество</span>
                            <input
                              id="flat-booking-name"
                              className="input__control"
                              onChange={e => setValue(e.target.value, "clientPatronymic")}
                              value={getValue("clientPatronymic")}
                            />
                          </label>
                        </div>
                        <div className="fbooking__form-col">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-reservation-name"
                          >
                            <span className="input__label">Email<span className="is-red-text sell__required">*</span></span>
                            <input
                              id="flat-booking-name"
                              className="input__control"
                              onChange={e => setValue(e.target.value, "clientEmail")}
                              value={getValue("clientEmail")}
                            />
                          </label>

                        </div>
                      </div>
                      <div className="fbooking__form-row">
                        <div className="fbooking__form-col">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-reservation-phone"
                          >
                            <span className="input__label">Телефон<span className="is-red-text sell__required">*</span></span>
                            <InputMask
                              className="input__control"
                              mask="+7 (999) 999-99-99"
                              autoComplete="off"
                              maskChar=" "
                              onChange={e => setValue(e.target.value, "clientPhone")}
                              value={getValue("clientPhone")}
                              onPaste={handlePaste}
                            />
                          </label>
                        </div>
                        <div className="fbooking__form-col"></div>
                      </div>
                    </>
                  }
                  <div className="fbooking__form-row" style={{marginTop: -20}}>
                    <div className="fbooking__form-col">
                      <div
                        className="add-client js-show active"
                        style={{display: !show ? "flex" : "none"}}
                        onClick={() => {

                          setShowAddClient(true)
                          selectClient(false)

                        }
                        }
                      >
                        <button
                          className="button button_type_add"
                          onClick={(e) => {
                            // e.preventDefault();
                          }}
                        ></button>
                        <span>Добавить клиента</span>
                      </div>

                      <AddClient
                        setValue={setValue}
                        show={show}
                        forFixate
                        closeClick={() => setShowAddClient(false)}
                        nameProps={{
                          onChange: e => setClientValue(e, "name"),
                          value: clientState.name,
                          onFocus: () => clearErr("name")
                        }}
                        lastNameProps={{
                          onChange: e => setClientValue(e, "lastName"),
                          value: clientState.lastName,
                          onFocus: () => clearErr("lastName")
                        }}
                        patronymicProps={{
                          onChange: e => setClientValue(e, "patronymic"),
                          value: clientState.patronymic,
                          onFocus: () => clearErr("patronymic")
                        }}
                        phoneProps={{
                          onChange: e => setClientValue(e, "phone"),
                          // value: clientState.phone,
                          value: getValue("phone"),
                          onFocus: () => clearErr("phone")
                        }}
                        emailProps={{
                          onChange: e => setClientValue(e, "email"),
                          value: clientState.email,
                          onFocus: () => clearErr("email")
                        }}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">
                      <div className="select select_type_form">
                        <Dropdown
                          options={paymentsOptions}
                          placeholder="Форма оплаты"
                          flatBookingTooltip={`+ ${40}% к ${isMobile ? `бронир.` : `бронированию`}`}
                          onChange={value => setValue(value, "pay_type")}
                          value={getValue("pay_type")}
                        />
                        {errors["pay_type"] && (
                          <span className="input__error-label">
                          {errors["pay_type"].message}
                        </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">
                      <div className="input input_type_form">
                        <span className="input__label">Документы</span>

                        <Dropzone uploadFiles={uploadFiles}
                                  title={`Загрузите необходимые для постановки брони документы`}/>

                        <div className="modal-form__files">
                          {files.map((file, index) => (
                            <FileRow key={file.id} name={file.fileName} del={() => deleteFile(index)}/>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">

                      <label
                        className="textarea textarea_type_form"
                        htmlFor="modal-reservation-commentary"
                      >
                        <span className="textarea__label">Комментарий</span>
                        <textarea
                          className="textarea__control"
                          id="modal-reservation-commentary"
                          placeholder="Не более 350 символов"
                          onChange={e => setValue(e.target.value, "comment")}
                          value={getValue("comment")}
                        ></textarea>
                      </label>
                    </div>
                  </div>
                  {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
                    return <div className="fbooking__form-row" key={i}>
                      <div className="fbooking__form-col">
                        <div className="checkbox checkbox_view_red">
                          <div className="checkbox__box">
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="policy"
                              onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                              value={item.value}
                              defaultChecked
                              required
                              checked={!!form?.[item?.fieldName]?.includes(item.value)}
                            />
                            <label className="checkbox__input" htmlFor="policy"></label>
                            <div className="checkbox__marker"></div>
                          </div>
                          <label className="checkbox__label" htmlFor="policy">
                            Я принимаю условия <Link className="link link_color_brand-3" to="/policy" target="_blank"
                                                     rel="noreferrer noopener">политики конфиденциальности</Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  })}
                  {formSettings?.find(setting => setting.code === "personal_data")?.items?.map((item, i) => {
                    return <div className="fbooking__form-row" key={i}>
                      <div className="fbooking__form-col">
                        <div className="checkbox checkbox_view_red">
                          <div className="checkbox__box">
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="personal_data"
                              onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                              value={item.value}
                              defaultChecked
                              required
                              checked={!!form?.[item?.fieldName]?.includes(item.value)}
                            />
                            <label className="checkbox__input" htmlFor="personal_data"></label>
                            <div className="checkbox__marker"></div>
                          </div>
                          <label className="checkbox__label" htmlFor="personal_data">
                            Даю согласие на обработку персональных данных
                          </label>
                        </div>
                      </div>
                    </div>
                  })}
                  {formSettings?.find(setting => setting.code === "familiar_with_advertising")?.items?.map((item, i) => {
                    return <div className="fbooking__form-row">
                      <div className="fbooking__form-col">
                        <div className="checkbox checkbox_view_red">
                          <div className="checkbox__box">
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="familiar_with_advertising"
                              onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                              value={item.value}
                              defaultChecked
                              required
                              checked={!!form?.[item?.fieldName]?.includes(item.value)}
                            />
                            <label className="checkbox__input" htmlFor="familiar_with_advertising"></label>
                            <div className="checkbox__marker"></div>
                          </div>
                          <label className="checkbox__label" htmlFor="familiar_with_advertising">
                            Ознакомлен (-а) с регламентом по рекламе
                          </label>
                        </div>
                      </div>
                    </div>
                  })}

                  {formSettings?.find(setting => setting.code === "familiar_with_builder")?.items?.map((item, i) => {
                    return <div className="fbooking__form-row" key={i}>
                      <div className="fbooking__form-col">
                        <div className="checkbox checkbox_view_red">
                          <div className="checkbox__box">
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="familiar_with_builder"
                              onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                              value={item.value}
                              defaultChecked
                              required
                              checked={!!form?.[item?.fieldName]?.includes(item.value)}
                            />
                            <label className="checkbox__input" htmlFor="familiar_with_builder"></label>
                            <div className="checkbox__marker"></div>
                          </div>
                          <label className="checkbox__label" htmlFor="familiar_with_builder">
                            Ознакомлен (-а) с регламентом взаимодействия с застройщиком
                          </label>
                        </div>
                      </div>
                    </div>
                  })}
                  <div className="fbooking__form-row">
                    <div className="fbooking__form-col">
                      <SubmitButton
                        type="button"
                        className="button button_view_default"
                        onClick={sendForm}
                        isLoading={isLoading}
                      >
                        Отправить заявку
                      </SubmitButton>
                    </div>
                  </div>

                </form>

              </div>

              {!isMobile && <ObjectInfo suburbanType={suburbanType} object={response.object}/>}
            </div>
          </section>

        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  email: state.user.info?.email,
  phone: state.user.info?.phone,
  // clients: state.user.clients?.map((client) => ({
  //   id: client.id,
  //   title: `${client.name} ${client.lastName}`,
  // })),
  name: `${state.user.info?.name} ${state.user.info?.lastName}`,
  profile: state.profile.profile,

});

export default connect(mapStateToProps, null)(FlatBooking);
