import React, { memo } from "react";

// #оптимизация вот этого говна накопипастили очень много, желательно везде импортнуть 
const FilterBadge = memo(({ item, handleClick, title, identity }) => (
    <div className="filtered">
        <div className="filtered__inner">
            <span className="filtered__type">{title}:</span>
            <span className="filtered__value">{item.title}</span>
            <button
                className="button filtered__del"
                onClick={e => handleClick(item.id, identity)}
            >
                <svg
                    className="icon icon_x"
                    width="7"
                    height="8"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
                    <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
                </svg>
            </button>
        </div>
    </div>
));
export default FilterBadge