import React, { useEffect } from "react";
import useMedia from "../../hooks/useMedia";

const ViewRealty = ({ view, onChange }) => {
  const isMobile = useMedia("isMobile")
  useEffect(() => {
    if (view === "map" && isMobile) {
      document.body.classList.add("no-map-scroll");
    } else if (!(view === "map") && isMobile) {
      document.body.classList.remove("no-map-scroll");
    }
  }, [view]);

  return (
    <div className="public__map-button for-suburbans">
      {view === "list" && (
        <button
          className={`button ${isMobile ? "button_view_default" : "button_view_outline-with-icon-invert"}`}
          onClick={() => onChange("map")}
        >
        {!isMobile && <svg className="icon icon_marker">
            <use xlinkHref="#marker"></use>
          </svg>}
          <span>{isMobile ? 'На карте' : 'Показать на карте'}</span>
        </button>
      )}
      {view === "map" && (
        <button
          className="button button_view_outline-with-icon-invert"
          onClick={() => onChange("list")}
        >
          <svg className="icon icon_list">
            <use xlinkHref="#list"></use>
          </svg>
          <span>{isMobile ? "Список" : "Показать список"}</span>
        </button>
      )}
    </div>
  );
};

export default ViewRealty;
