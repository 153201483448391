import React from 'react'

const ComplexView = ({ viewTypes, setView, view, onClick }) => (
    <div className="complex__views">
      {/*
        <button
          className="presentation-filters-btn"
          onClick={onClick}
        >
          <Filters />
          Фильтры: 14
        </button>
      */}
  
      <ul className="complex__view">
        {viewTypes.map((item) => (
          <li
            className={`complex__view-item view__${item} ${
              item === view ? `active` : ``
            }`}
            key={item}
            onClick={() => setView(item)}
          >
            <svg className="view__icon">
              <use
                xlinkHref={`#view_${
                  item === "cards" ? `cards` : `rows`
                }`}
              ></use>
            </svg>
            {item === "cards" ? `Плитка` : `Список`}
          </li>
        ))}
      </ul>
    </div>
  )

export default ComplexView