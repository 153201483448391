import React, { memo, useEffect, useReducer } from "react";
import {useLocation} from "react-router";
import EmptyTable from "../../components/core/EmptyTable";
import Preloader from "../../components/Loaders/FilterTableLoader";
import Filter from "../../components/requests/Filter";
import Items from "../../components/requests/Items";
import { standartErrorToast, standartWarningToast } from "../../components/toasts";
import usePermission from "../../hooks/usePermission";
import { filterToParamDeals, getDeals, initialState, reducer } from './utils';
import Deal from "../Deals";

const Requests = memo(props => {
  const [{
    countAllDeals,
    data,
    filter,
    filterData,
    isLoading,
    page
  }, dispatch] = useReducer(reducer, initialState);

  const actionFactory = type => (data = {}) => dispatch({ type, ...data })
  const saveData = actionFactory("fetchData")
  const loadMoreAction = actionFactory("loadMore")
  const clearFilter = actionFactory("clearFilter")
  const setFilter = actionFactory("setFilter")
  const setState = actionFactory("setState")
  const showAgents = usePermission('master+')
  
  useEffect(() => {
    getDeals({ page, isGetFilter: 1 })
      .then(resp=>{
        standartWarningToast(resp.warnings)
        saveData(resp)
      }, standartErrorToast) 
  }, []);

  const loadMore = () => {
    const params = filterToParamDeals({ page }, filter)
    return getDeals(params).then(loadMoreAction)
  }
  const handleSetFilter = (newFilter, type) => {
    setState({ payload: { isLoading: true, page: 1 } })
    const params = filterToParamDeals({ page: 0 }, newFilter.filter)
    type === "set" && newFilter?.filter ? setFilter(newFilter) : clearFilter()
    getDeals(params)
      .then(resp => setState({ payload: { data: resp.deals, countAllDeals: resp.countAllDeals } }), standartErrorToast)
      .finally(() => setState({ payload: { isLoading: false } }))
  }

  return (
    <section className="section section_view_white orders no-header-profile">
      <div className="wrapper">
        <div className="clients__header">
          <div className="clients__title">
            <div className="h1">Сделки</div>
          </div>
        </div>
        {filterData && <Filter
          filter={filter}
          filterData={filterData}
          setFilter={filter => handleSetFilter(filter, "set")}
          clearFilter={handleSetFilter}
          isFilterSet={true}
          showAgents={showAgents}
        />}
        {isLoading && <Preloader />}
        {data.length === 0 && !isLoading && <EmptyTable title="Сделки не найдены" />}
        {data.length > 0 && !isLoading && <Items
          items={data}
          loadData={loadMore}
          hideLoadMore={data.length >= countAllDeals}
          showAgents={showAgents}
        />}
      </div>
    </section>
  );
});

export default Requests
