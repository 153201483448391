import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearContacts,
  clearFilterContacts,
  fetchContacts,
  filteredData,
  setFilterContacts
} from "../../actions/contacts";
import useDocTitle from "../../hooks/useDocTitle";
import Preloader from "../Preloader";
import ContactsAttendants from "./ContactsAttendants";
import ContactsHeader from "./ContactsHeader";
import ContactsItems from "./ContactsItems";
import ConstactsSchedule from "./ContactsSchedule";



const Contacts = (props) => {
  const {
    filterData,
    filter,
    contacts,
    fetch,
    reset,
    isFetching,
    error,
    setFilter,
    clearFilter,
    managersOnDuty,
  } = props;

  useDocTitle("Контакты")
  
  useEffect(() => {
    fetch();
    return () => {
      reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContactsHeader
        data={filterData}
        filter={filter}
        setFilter={setFilter}
        clearFilter={clearFilter}
      />
      <ConstactsSchedule />
      {managersOnDuty && managersOnDuty.length > 0 && <ContactsAttendants items={managersOnDuty} />}
      <ContactsItems items={contacts} />
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchContacts()),
  reset: () => dispatch(clearContacts()),
  setFilter: (filter) => dispatch(setFilterContacts({filter})),
  clearFilter: () => dispatch(clearFilterContacts()),
});

const mapStateToProps = (state) => ({
  filterData: state.contacts.filterData,
  filter: state.contacts.filter,
  contacts: filteredData(state),
  isFetching: state.contacts.isFetching,
  error: state.contacts.error,
  managersOnDuty: state.contacts.managersOnDuty,
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
