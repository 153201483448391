import { memo } from 'react';
import CrossIcon from './CrossIcon';

const Tag = memo(({ title, value, id, removeBadge, innerRef, readonly, ...rest }) => (
  <li className="sf__tag" ref={innerRef} {...rest}>
    {title} <span>{value ?? id}</span>
    {!readonly && <button
      type="button"
      className="sf__tag-close button"
      onClick={removeBadge}
    >
      <CrossIcon />
    </button>}
  </li>
));

export default Tag;
