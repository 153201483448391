import React from "react";
import { getUrl } from '../../../api';
import { sendConstructorEvent } from "../../../utils";

const eventNameBySocId = {
  'tg': "tr_telegram_click",
  'wa': "tr_whatsapp_click",
  'vb': "tr_viber_click",
  'ig': "tr_instagram_click",
  'fb': "tr_facebook_click",
  'vk': "tr_vk_click",
}

const Banner = (props) => {
  const { promo, promoText } = props

  
  return (
    <section className="banner constructor__section">
      <div className="constructor__container">
        <div className="constructor__box">
          <div className={`constructor__wrapper banner__wrapper${promo.length == 0 ? ` banner__wrapper_center` : ``}`}>
            {!!promoText && <p className="banner__title h3">{promoText}</p>}

            {
              promo.length > 0 && 
              <ul className="constructor__socials constructor__socials_banner">
                {promo.map((social, i)=>(<li className={`constructor__social-circle`} key={`promo-banner-social-${i}`} onClick={()=> sendConstructorEvent(eventNameBySocId[social.type.xmlId])}>
                <a
                  href={
                      social.type.xmlId === 'tg'
                        ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                        : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                        : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                        : social.link
                    }
                    target="_blank"
                  >
                  </a>
                  {/* {icons[social.type.xmlId]["logo"]} */}
                  <object type="image/svg+xml" data={getUrl(social?.type?.logo?.src)}/>
                </li>))}
              </ul>
            }

          </div>
        </div>
      </div>

      <div className="banner__img">
        <svg xmlns="http://www.w3.org/2000/svg" width="572" height="224" viewBox="0 0 572 224" fill="none">
          <path opacity="0.15" d="M107.119 401.699L528.568 187.167C540.275 181.208 542.552 168.243 537.805 158.852L393.02 -127.546C387.086 -139.283 374.16 -141.558 364.794 -136.791L-56.6549 77.7402C-66.0204 82.5075 -70.6389 96.665 -65.8919 106.055L78.8936 392.453C84.8275 404.191 97.7533 406.466 107.119 401.699Z" />
          <path opacity="0.25" d="M253.694 155.894L385.806 -132.022C385.806 -132.022 365.098 -136.945 355.429 -132.022L-44.9482 71.7821C-60.2175 79.1769 -66.3254 95.7885 -66.3254 95.7885L253.694 155.894Z" />
        </svg>
      </div>
    </section>
  )
};

export default Banner;
