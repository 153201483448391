import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { errorToast, standartErrorToast, successToast, infoToast } from '../../components/toasts';
import { scrollToRef } from "../../utils";
import { sendEducationRequest } from "../../actions/sendEducationRequest";
import ym from "react-yandex-metrika";
import InputMask from "react-input-mask";
import './index.scss';

const progQuestions = [
  `Куда движется рынок недвижимости?`,
  `Что продавать?`,
  `Что выгоднее продавать?`,
  `Новостройки или вторичка?`,
  `С какими застройщиками работать?`,
  `Новостройки или загородка?`,
  `Как устроен рынок апартаментов?`,
  `Как их продавать?`,
  `Почему клиенты срываются?`,
  `Как работать с возражениями?`,
  `Кто прав: клиент или эксперт?`,
  `Как повысить экспертность?`,
];

const progLearning = [
  {
    title: `Разбираться в проектах и девелоперах`,
    text: `Начнёте грамотно выбирать новостройки, апартаменты и загородные проекты, сравнивать, анализировать и аргументированно презентовать клиентам`,
  },
  {
    title: `Основам ипотеки`,
    text: `Научитесь подбирать объекты с учетом ипотечных программ и ставок.`,
  },
  {
    title: `Выбирать девелоперов`,
    text: `Станете сканировать застройщиков, и поймёте, с кем работать, кто гарантированно строит, не сжигает деньги клиентов и платит`,
  },
  {
    title: `Этично продавать`,
    text: `Разберётесь, как устроена “кухня” девелоперов, узнаете, как продавать, не нарушая регламенты застройщиков, поэтапно повышая размер комиссии`,
  },
  {
    title: `Настраивать работу с клиентами`,
    text: `Поймете, чего покупатели ждут от продавцов, научитесь правильно вести переговоры, изящно передвигая клиентка по воронке от первого звонка до встречи и сделки`,
  },
  {
    title: `Работать с инструментами рынка`,
    text: `Поймете, как подбирать жилые проекты, какие сервисы помогают в работе с клиентами, как с их помощью не терять клиентов и доводить их до договора`,
  },
];

const CheckIcon1 = () => {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.61551L7.6041 13.1587L20.2536 0.588867" stroke="#44D89E" strokeMiterlimit="10"/>
    </svg>
  );
};

const CheckIcon2 = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.0707" cy="22.7464" r="21.9686" fill="#44D89F"/>
      <path d="M13.4964 22.4881L20.1005 29.0313L32.75 16.4614" stroke="white" strokeMiterlimit="10"/>
    </svg>
  );
};

const RieltorSchool = (props) => {
  const formRef = useRef();

  const {userInfo = {name: "", phone: "", lastName: "", email: ""}} = props;
  const { handleSubmit, register, errors } = useForm();


  const onSubmit = (data) => {
    data.lastName = userInfo.lastName || '';
    data.course = "Консультация";
    sendEducationRequest(data).then(
      data => {
        infoToast(<div>{data.message}</div>)
        ym("reachGoal", "sendEducationRequest");
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };


  return (
    <section className="section section_view_white">

      <section className="sor sor_rieltor rie">

        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">
                <span className="sor__sup">курсы</span>
                <h1 className="sor__h1 rie__title">Прогрессивные продажи недвижимости</h1>
                <p className="sor__sign big-text">Объясним, как устроен рынок недвижимости, научим продавать и расскажем, как правильно работать с застройщиками. </p>

                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/rie/promo.png').default} alt="" className="sor__promo-img-lg" />
                  <img src={require('../../assets/images/rie/textSvg-1.svg').default} alt="" className="rie__promo-svg-1 rotating-reverse" />
                  <img src={require('../../assets/images/rie/textSvg-1.svg').default} alt="" className="rie__promo-svg-2 rotating" />
                </div>
              </div>


              <div className="rie__top-row">
                  <div className="rie__top-list">
                    <div className="rie__top-item">Новостройки Санкт-Петербурга</div>
                    <div className="rie__top-item">Апартаменты</div>
                    <div className="rie__top-item">Загородная недвижимость</div>
                  </div>
                  <div className="rie__top-list">
                    <div className="rie__top-item">Передовые техники продаж</div>
                    <div className="rie__top-item">Переуступки</div>
                    <div className="rie__top-item" style={{opacity: 0}}/>
                  </div>
                  <div className="rie__top-list">
                    <div className="rie__top-item">Психология переговоров и&nbsp;сделок</div>
                    <div className="rie__top-item">Ипотека</div>
                    <div className="rie__top-item" style={{opacity: 0}}/>
                  </div>
                </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 0)}>Оставить заявку</button>
                <a href="https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/План_обучения_теория_практика2.pptx" className="sor__promo-btn button button_view_outline-with-icon-invert" target="_blank" rel="noopener noreferrer">План обучения</a>
              </div>
            </div>
          </div>
        </header>

        <div className="sor__faq-section">
          <div className="wrapper">
            <div className="wrapper__center">
              <ul className="sor__faq">
                <li className="sor__faq-item">
                  <span className="sor__faq-title">длительность</span>
                  <p className="sor__faq-descr">2 недели<span>/ 1 недели теории, 1 неделя практика</span></p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">формат</span>
                  <p className="sor__faq-descr">Оффлайн-занятия, экскурсии</p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">уровень</span>
                  <p className="sor__faq-descr">Новичок, средний</p>
                </li>
              </ul>
            </div>
          </div>

        </div>


        <div className="wrapper">
          <div className="wrapper__center">

            <section className="rie__prog">
              <ul className="rie__prog-list">
                {progQuestions.map((it) => <li><CheckIcon1 />{it}</li>)}
              </ul>

              <aside className="rie__prog-aside">
                <img className="rie__prog-img" src={require('../../assets/images/rie/rieltor.png').default} alt="" />
                <p className="rie__prog-title">Грамотный риелтор знает</p>
                <p className="rie__prog-text">рынок меняется каждый день и диктует бизнесу быть гибким, а профессионалам — постоянно исследовать рынок, искать новые каналы и схемы продаж и наращивать компетенции</p>
              </aside>
            </section>

            <section className="rie__about rie__section">
               <div className="rie__about-left">
                 <img className="rie__about-img-left" src={require('../../assets/images/rie/rieltor-2.png').default} alt="" />
                 <p className="rie__about-info">Чтобы быть в рынке, быстро адаптироваться в новых условиях, сохранять экспертные позиции и получать все преференции профессии, пройдите наш курс прогрессивных продаж.</p>
               </div>

               <div className="rie__about-right">
                 <img className="rie__about-img-right" src={require('../../assets/images/rie/icon.svg').default} alt="" />
                 <p className="rie__about-text">Вы сможете организовать работу так, чтобы вы не бились о потолок по доходу, а работа приносила и преумножала доход.</p>
               </div>
            </section>

            <section className="rie__for rie__section">
              <h2 className="rie__title rie__section-title">Кому подойдет курс</h2>
              <div className="rie__for-row">
                <div className="rie__for-item">
                  <img src={require('../../assets/images/rie/who-01.svg').default} alt="" className="rie__for-img" />
                  <div className="rie__for-box">
                    <p className="rie__for-title">Начинающим риелторам</p>
                    <p className="rie__for-text">Изучите рынок новостроек, схемы продаж, получите инструменты для переговоров, научитесь использовать приемы продаж для закрытия сделок</p>
                  </div>
                </div>
                <div className="rie__for-item">
                  <img src={require('../../assets/images/rie/who-02.svg').default} alt="" className="rie__for-img" />
                  <div className="rie__for-box">
                    <p className="rie__for-title">Продавцам вторичной недвижимости</p>
                    <p className="rie__for-text">Усвоите новые техники продаж, поймете, как работать с клиентами на рынке новостроек, узнаете приоритетных застройщиков и актуальные объекты. Освоите новую нишу и получите дополнительный драйвер продаж</p>
                  </div>
                </div>

              </div>

              <div className="rie__for-row">
                <div className="rie__for-item">
                  <img src={require('../../assets/images/rie/who-03.svg').default} alt="" className="rie__for-img" />
                  <div className="rie__for-box">
                    <p className="rie__for-title">Практикующим риелторам</p>
                    <p className="rie__for-text">Усовершенствуете технику переговоров, узнаете новые подходы к выбору объектов для продажи, начнете последовательно повышать средний чек со сделок за счет повышения экспертизы</p>
                  </div>
                </div>

                <div className="rie__for-item">
                  <img src={require('../../assets/images/rie/who-04.svg').default} alt="" className="rie__for-img" />
                  <div className="rie__for-box">
                    <p className="rie__for-title">Маркетологам</p>
                    <p className="rie__for-text">Получите структурированную информацию о рынке, поймете, с какими трудностями сталкиваются риелторы при переговорах и продажах, сможете применять знания для разработки стратегий и рекламных кампаний</p>
                  </div>
                </div>
              </div>


            </section>

            <section className="rie__learn rie__section">
              <h2 className="rie__title rie__section-title">Чему научитесь</h2>
              <div className="rie__learn-container">
                {progLearning.map((it) => (
                  <div className="rie__learn-item">
                    <CheckIcon2 />
                    <p className="rie__learn-title">{it.title}</p>
                    <p className="rie__learn-text">{it.text}</p>
                  </div>))
                }
              </div>

            </section>


            <section className="rie__how rie__section">
              <h2 className="rie__title rie__section-title">Как проходит обучение</h2>
              <div className="rie__how-container">

                <aside className="rie__how-aside">
                  <img className="rie__how-img" src={require('../../assets/images/rie/rieltor-3.png').default} alt="" />
                  <button type="button" className="rie__how-btn button button_view_default" onClick={() => scrollToRef(formRef, 0)}>Оставить заявку</button>
                </aside>
                <section className="rie__how-list">
                  <div className="rie__how-item">
                    <p className="rie__how-title">01 / <b>Теория</b></p>
                    <span className="rie__how-badge">1 неделя — бесплатно</span>
                    <p className="rie__how-text">Встречаемся в онлайне и оффлайне <span className="nowrap">с эксперами по направлениям</span></p>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">02 / <b>Практика</b></p>
                    <span className="rie__how-badge">2-ая неделя — бесплатно</span>
                    <p className="rie__how-text">Работаем со скриптами и кейсами, звоним, пишем письма, назначаем встречи, подбираем объекты на портале, отправляем презентации. В группах, самостоятельно, в домашних заданиях</p>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">03 / <b>Сопровождение</b></p>
                    <p className="rie__how-text">Напоминаем о занятиях, чтобы вы не пропускали: информации много, и ее нужно усваивать последовательно. Вы — задаете вопросы и получаете обратную связь от кураторов в чате Telegram.</p>
                  </div>
                  <div className="rie__how-item">
                    <p className="rie__how-title">04 / <b>Экскурсия</b></p>
                    <p className="rie__how-text">Изучаем вживую локации и ЖК 40+ проектов: узнаём лучшие, выявляем преимущества и недостатки, учимся прзентовать</p>
                  </div>
                  {/*<div className="rie__how-item">*/}
                  {/*  <p className="rie__how-title">05 / <b>Сертификат</b></p>*/}
                  {/*</div>*/}
                </section>
              </div>
            </section>

          </div>
        </div>

        {/*<section className="rie__banner">*/}
        {/*  <div className="wrapper">*/}
        {/*    <div className="wrapper__center">*/}
        {/*      <div className="rie__banner-container">*/}
        {/*        <div className="rie__banner-content">*/}
        {/*          <span className="rie__banner-percent">100 %</span>*/}
        {/*          <div className="rie__banner-box">*/}
        {/*            <p className="rie__banner-title">За лучшие результаты промежуточных тестирований</p>*/}
        {/*            <p className="rie__banner-text">Разыгрываем бесплатное практическое обучение</p>*/}
        {/*            <img className="rie__banner-img" src={require('../../assets/images/rie/banner.svg').default} alt="" />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className="sor__footer" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="h2 sor__h2 sor__footer-title rie__footer-title">Скачайте <a href="https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/План_обучения_теория_практика2.pptx" target="_blank" rel="noopener noreferrer" className="rie__download">план обучения</a> или получите консультацию</h2>
              </div>
              <form action="#" className="sor__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="sor__form-box">
                  <div className="sor__form-item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-name"
                      >
                        <span className="input__label">Имя</span>
                        <input
                          id="modal-reservation-name"
                          name="name"
                          defaultValue={userInfo.name}
                          className={
                            "input__control" + (errors.name ? " input__error" : "")
                          }
                          placeholder="Ваше имя"
                          ref={register({
                            required: "Введите имя",
                          })}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            {errors.name.name}
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="sor__form-item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-phone"
                      >
                        <span className="input__label">Телефон</span>
                        <InputMask
                          id="modal-reservation-phone"
                          className={
                            "input__control" + (errors.phone ? " input__error" : "")
                          }
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar=" "
                          name="phone"
                          placeholder="Ваш телефон"
                          defaultValue={userInfo.phone}
                          inputRef={register({
                            required: "Введите телефон",
                          })}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            {errors.name.phone}
                          </span>
                        )}
                      </label>
                    </div>


                    <div className="sor__form-item">
                      <label
                        className="input input_width_available input_type_form"
                        htmlFor="modal-reservation-message"
                      >
                        <span className="input__label">Комментарий</span>
                        <input
                          id="modal-reservation-message"
                          name="comment"
                          className={
                            "input__control" + (errors.name ? " input__error" : "")
                          }
                          placeholder="Текст комментария"
                          ref={register}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            {errors.name.message}
                          </span>
                        )}
                      </label>
                    </div>

                  <button type="submit" className="sor__submit button button_view_default">Оставить заявку</button>


                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box' +
                        (errors.agreement ? " checkbox__box-error" : "")
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        ref={register({
                          required: true,
                        })}
                        defaultChecked
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

      </section>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(RieltorSchool);
