import React from "react";
import './index.scss';
import DealAct from "../DealAct";

const DealInfo = ({ act, items = [] }) => {

  return (
    <div className="common-info">
      {act && <DealAct act={act} />}
      <ul className="common-info-list">
        {items.map(({ title, value, color }) => <li className="common-info-list-item" key={title}>
          <span className="common-info-list-item__label">{title}</span>
          <span className={color ? "color-status" : "common-info-list-item__value"}
            style={{ color, backgroundColor: `${color}4d` }}
          >
            {value}
          </span>
        </li>)}
      </ul>
    </div>
  );
}

export default DealInfo;
