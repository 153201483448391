import { cleanFilter } from './complex';
const modifiedFilter = {...cleanFilter}
modifiedFilter.status = [...modifiedFilter.status, "3", "1001"]
const initialState = {
  isFetching: false,
  error: false,
  data: {
    sections: [],
    buildings: [],
    chess: [],
    city: '',
  },
  filter: modifiedFilter,
  filterData: {},
  count: 0
};

const chess = (state = initialState, action) => {
  switch (action.type) {
    case 'CHESS_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'CHESS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        ...(action.filter ? { filterData: action.filter } : []),
      };
    case 'CHESS_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case 'CHESS_RESET':
      return { ...initialState };
    case 'CHESS_SET_FILTER':
      return { ...state, filter: action.filter };
      case "CHESS_CLEAR_FILTER":
        return { ...state, filter: modifiedFilter }
    default:
      return state;
  }
};

export default chess;
