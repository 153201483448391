import React, { memo } from 'react';
import { SuburbanAdvantagesFilterIcon } from "./icons";
import { Toggle } from './ToggleFilter';


const VillagesTogglesFilter = memo(props => <>
  {!props.hideTitle && <div className="filter-title">
    <SuburbanAdvantagesFilterIcon />
    Приемущества
  </div>}
  <Toggle
    title="Терраса"
    ident="hasTerrace"
    {...props}
  />
  <Toggle
    title="Кухня-гостиная"
    ident="hasKitchenLiving"
    {...props}
  />
  <Toggle
    title="Панорамные окна"
    ident="hasPanoramicWindows"
    {...props}
  />
  <Toggle
    title="Гардероб"
    ident="hasWardrobe"
    {...props}
  />
  <Toggle
    title="Второй свет"
    ident="hasSecondLight"
    {...props}
  />
  <Toggle
    title="Мансардный этаж"
    ident="hasAtticFloor"
    {...props}
  />
  <Toggle
    title="Сауна"
    ident="hasSauna"
    {...props}
  />
</>);

export default VillagesTogglesFilter;
