import formatter from "format-number";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import {connect, useSelector} from 'react-redux';
import { Link, useParams } from "react-router-dom";
import ym from "react-yandex-metrika";
import { getUrl } from "../../api";
import useMedia from '../../hooks/useMedia';
import { getPricePerMeter } from "../../utils";
import AddPresentation from "../AddPresentation";
import PresentationBlock from "../Apartment/PresentationBlock";
import PopoverBlock from "../Cards/PopoverBlock";
import Modal from "../core/Modal";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import ReserveAssignation from "../forms/ReserveAssignation";
import Manager from "../Manager";
import { successToast } from "../toasts";
import Viewer from "../Viewer/Viewer";
import LawyersChecked from "./LawyersChecked";
import useRootCSS from "../../hooks/useRootCSS";
import {MapIconCustom} from "../Public/markup/icons";

const priceFormat = (price) => {
  if (price && price.toString().includes("%") || price?.toString()?.includes("По") || price?.toString()?.includes("по")) {
    return price
  }
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};

const tabs = [
  "plan",
  "floorPlan",
  "complex",
  "decoration",
  "interior",
  "aeroPanorama",
  "decorationFinish",
  "decorationPreFinish",
  "decorationRaw",
  "gidImg",
  "viewFromWindow",
  "viewFromWindowCords",
  "generalPlan"
]

const getSuffix = (count) => {
  if (count > 1 && count < 5) {
    return "ы"
  }
  if (count == 1) {
    return "а"
  }

  return "";
};

const SecondaryInfo = (props) => {
  const {
    manager,
    data,
    subwaysList,
    city,
    clientMode,
    addClientToFavorite,
    removeClientFromFavorite,
    handleFavorites,
    clients,
    fetchClients,
    isAgent,
    isCommercial,
    complexLink,
    agent,
    savedFilterXmlId,
    client,
    isPublic,
    presentationType,
    filterType = "assignments",
    isSecondary,
    userInfo,
    disableButtons,
    savedFilterSettings
  } = props;
  const { id } = useParams();
  const isMobile = useMedia("isMobile")
  const submitReserve = () => {
    closeReserveModal();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  const [initMap, setInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [Maps, setMaps] = useState(null)
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({ YMaps, Map, Placemark, RulerControl })
  };
  useEffect(() => {
    dynamicMapImport()
    if (!modalMap.current) return;
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);
  // Sticky button
  const modalMap = useRef(null);

  const [openReserveModal, closeReserveModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeReserveModal} classes="modal_task flat-reservation">
        <ReserveAssignation
          id={id}
          handleSend={() => submitReserve()}
          client={client}
          isPublic={isPublic}
          savedFilterXmlId={savedFilterXmlId}
          title={"квартиру"}
          isSecondary={isSecondary}
          savedFilterSettings={savedFilterSettings}
        />
      </Modal>
    ),
  );

  const [activeTab, setTab] = useState(data?.photos?.length > 0 ? "photos" : "plan");

  // Sticky button

  const ref = useRef(null);

  const handleScroll = () => {

    if (!ref.current) return;

    const elScrollHeight = ref.current.offsetTop - window.innerHeight + 110

    if (window.scrollY > elScrollHeight) {
      ref.current.classList.add('flat__info-btns-sticky');
    } else if (window.scrollY < elScrollHeight) {
      ref.current.classList.remove('flat__info-btns-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);


  const [isPhotoOpened, setisPhotoOpened] = useState(false);

  const photos = useMemo(() => ({
    plan: data.plan?.empty && data.photos?.length > 0 ? false : [data.plan],
    complex: data.photos?.map((src, id) => ({ id, src })),
    ...(data.floorPlan ? { floorPlan: [data.floorPlan] } : []),
    ...(data.gidImg?.src ? { gidImg: [data.gidImg] } : []),
    ...(data.viewFromWindow?.src && !data.viewFromWindow?.empty ? { viewFromWindow: [data.viewFromWindow] } : []),
    ...(data.viewFromWindow?.cone ? { viewFromWindowCords: data.viewFromWindow } : []),
    ...(data.generalPlan ? { generalPlan: data.generalPlan } : []),
    ...(data.builder?.typical3DTour ? { tour: getUrl(data.builder?.typical3DTour) } : []),
  }), [data])

  const mapLoad = (ymaps) => {
    setInitMap(true);
  };
  const isParcel = data?.objectType?.xmlId === "parcel"

  console.log(savedFilterSettings)



  const svgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
    <path fill="{color}" fill-rule="evenodd" clip-rule="evenodd" d="M9.00003 21.59C9.00003 21.59 17.0652 16.2132 17.0652 9.0441C17.0652 4.5898 13.4543 0.978882 9.00003 0.978882C4.54573 0.978882 0.934814 4.5898 0.934814 9.0441C0.934814 16.2132 9.00003 21.59 9.00003 21.59ZM9.00004 11.7326C10.4848 11.7326 11.6884 10.5289 11.6884 9.04415C11.6884 7.55938 10.4848 6.35574 9.00004 6.35574C7.51528 6.35574 6.31164 7.55938 6.31164 9.04415C6.31164 10.5289 7.51528 11.7326 9.00004 11.7326Z"/>
  </svg>
`.replace(/\{color\}/g, savedFilterSettings?.colorButton);

  const iconLayout = 'default#image';
  const iconImageHref = `data:image/svg+xml;utf8,${encodeURIComponent(svgIcon)}`;
  return (
    <section className="section section_view_white flat flat_margin_0">
      <div className="wrapper">
        <div className="assignation-info">
          <div className="flat__header">
            <div className="flat__grid">
              <div className="flat__col flat__col-title">
                <div className="flat__col-row is-row">
                  <div className="flat__title">
                    <h1 className="h1">{`${data.roomType?.name ? data.roomType?.name : data.objectType?.name},\n ${data.squareTotal} м²`}</h1>
                    {data.dateUpdate && (
                      <div className="flat__updated">
                        Обновлено{" "}
                        {new Date(
                          data.dateUpdate.substring(0, 10),
                        ).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                  {isMobile &&
                    <PresentationBlock
                      objectXmlId={data.xmlId}
                      objectType={presentationType}
                      city={city}
                      savedFilterXmlId={savedFilterXmlId}
                    />
                  }
                  <div className="flat__complex">
                    <div className="flat__complex-grid">
                      {data.block && <div className="flat__complex-col">
                        <div className="flat__complex-title">
                          {data.block.name && <h4 className="h4">ЖК {data.block.name}</h4>}
                          {!!data.block?.id && !data.block?.hideLink && (
                            <div className="flat__complex-link">
                              <Link
                                target="_blank"
                                className="link"
                                to={
                                  complexLink
                                    ? `${complexLink}${data.block.id}${savedFilterXmlId
                                      ? "?filtertype=" + filterType
                                      : ""
                                    }`
                                    : `/realty/${data.city}/complex/${data.block.id}`
                                }
                              >
                                Страница ЖК
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>}


                      {
                        isMobile &&
                        <div className="flat__info-item for-mobile">
                          <div
                            className={`label label-item label_blue label_id`}
                          >
                            ID: {data.id}
                          </div>
                          <div
                            className={`label-item label_${data?.status?.color
                              }`}
                          >
                            {data?.status?.name}
                          </div>
                        </div>
                      }

                      {!isMobile &&
                        <div className="flat__complex-col">
                          <div className="flat__complex-address">
                            <span>
                              {data.address}
                            </span>
                          </div>
                        </div>
                      }
                    </div>

                    <div className={`flat__complex-grid${data.subway.length > 2 ? ` flat__complex-grid_wrap` : ``}`}>
                      {data.subway && !isMobile &&
                        data.subway.map((subway, i) => {
                          if (!subway) {
                            return;
                          }
                          return (
                            <div
                              className="flat__complex-col"
                              key={subway.name + i}
                            >
                              <div className="complex__routes-item">
                                <span
                                  className="complex__metro-color"
                                  style={{ backgroundColor: subway.color }}
                                ></span>
                                <span className="complex__metro-station">
                                  м. {subway.name}
                                </span>
                                {subway.distanceType && <span className="complex__route-type">
                                  <svg
                                    className={`icon icon_${subway.distanceType === "byFoot"
                                      ? "pedestrian"
                                      : "auto"
                                      }`}
                                  >
                                    <use
                                      xlinkHref={`#${subway.distanceType === "byFoot"
                                        ? "pedestrian"
                                        : "auto"
                                        }`}
                                    ></use>
                                  </svg>
                                </span>}
                                {subway.minutes && <span className="complex__route-time">
                                  {subway.minutes} мин.
                                </span>}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

              </div>
              {!isMobile && (
                <div className="flat__col flat__col-download">
                  {!!data.coordinates &&
                    <div className="complex__address-map">
                      <span
                        className="show__map"
                        onClick={() => setShowMap(true)}
                      >
                      <MapIconCustom color={savedFilterSettings?.colorButton}/>
                      </span>
                    </div>
                  }
                  <PresentationBlock
                    objectXmlId={data.xmlId}
                    objectType={presentationType}
                    city={city}
                    savedFilterXmlId={savedFilterXmlId}
                  />
                  {!isPublic && <AddPresentation
                    id={data.xmlId}
                    city={city}
                    presentation={data.presentation}
                    type={presentationType}
                  />}
                </div>
              )}
            </div>
          </div>
          <div
            className="flat__content"
          >
            <div className="flat__grid flat__sticky-container">
              <div className="flat__col flat__col-images flat__col-slider" style={isPhotoOpened ? { position: "static" } : null}>
                <div className="special__detail-slider">
                  <ErrorPlug stopSending={true}>
                    <Viewer
                      data={photos}
                      onTabChange={id => id === "tour" && ym('reachGoal', 'showroom_view')}
                      addClass={`assignation-photos special__detail-photos`}
                      tabs={tabs}
                      statusSold={data.status.id === 1001}
                      blurBg
                      onPhotoOpen={() => setisPhotoOpened(true)}
                      onPhotoClosed={() => setisPhotoOpened(false)}
                    />
                  </ErrorPlug>
                </div>
                {(manager || agent) && (
                  <div className="flat__manager sm-hide">
                    <Manager
                      data={agent ? agent : manager}
                      isAssignment={!isCommercial}
                      isCommercial={isCommercial}
                      customType={"secondary"}
                      key="mobileManager"
                      objectId={id}
                      isPublic={disableButtons}
                      onSuccessCallback={() =>
                        ym("reachGoal", "assignment_callback")
                      }
                      managerIsAgent={manager.isAgent}
                      savedFilterSettings={savedFilterSettings}
                    />
                  </div>
                )}
              </div>

              <div className="mobile-complex-details">
                {isMobile &&
                  <div className="flat__complex-col">
                    <div className="flat__complex-address">
                      <span>
                        {data.address}
                      </span>
                    </div>
                  </div>
                }
                {data.subway && isMobile &&
                  data.subway.map((subway, i) => {
                    if (!subway) {
                      return;
                    }
                    return (
                      <li
                        className="flat__complex-col"
                        key={subway.name + i}
                      >
                        <div className="complex__routes-item">
                          <span
                            className="complex__metro-color"
                            style={{ backgroundColor: subway.color }}
                          ></span>
                          <span className="complex__metro-station">
                            м. {subway.name}
                          </span>
                          {subway.distanceType && <span className="complex__route-type">
                            <svg
                              className={`icon icon_${subway.distanceType === "byFoot"
                                ? "pedestrian"
                                : "auto"
                                }`}
                            >
                              <use
                                xlinkHref={`#${subway.distanceType === "byFoot"
                                  ? "pedestrian"
                                  : "auto"
                                  }`}
                              ></use>
                            </svg>
                          </span>}
                          {subway.minutes && <span className="complex__route-time">
                            {subway.minutes} мин.
                          </span>}
                        </div>
                      </li>
                    );
                  })}
              </div>
              {!isPublic && isMobile &&
                <div className="flat__mobile-presentation">
                  <AddPresentation
                    id={data.xmlId}
                    city={city}
                    presentation={data.presentation}
                    type={presentationType}
                  />
                </div>
              }


              <div className="flat__col flat__col-info">
                <div className="flat__price">
                  {!isMobile &&
                    <div className="flat__price-col flat__price-item flat__price-item_flex flat__price-item_padding_top">
                      <div className="flat__info-item">
                        <div
                          className={`label label-item label_blue label_id`}
                        >
                          ID: {data.id}
                        </div>
                      </div>
                      <div className="flat__info-item">
                        <div
                          className={`label-item label_${data?.status?.color}`}
                        >
                          {data?.status?.name}
                        </div>
                      </div>
                    </div>
                  }
                  <div className="flat__price-col flat__price-actions for-fav">
                    <div className="flat__price-action">
                      {!isPublic && (
                        <div className="complex__actions-item">
                          <div
                            className={`add-fav-btn js-favorites-add dark ${data.favorite && data.favorite.inFavorite
                              ? "is-fav"
                              : ""
                              }`}
                            onClick={() =>
                              handleFavorites(data.favorite.inFavorite)
                            }
                          >
                            <button className="button button_type_settings">
                              <svg className="icon icon_settings">
                                <use xlinkHref="#fav"></use>
                              </svg>
                            </button>
                            {!isMobile && (data.favorite && data.favorite.inFavorite
                              ? "В избранном"
                              : "В избранное")}
                            <PopoverBlock
                              type={"secondary"}
                              id={data.id}
                              favorite={data.favorite}
                              city={"spb"}
                              allClients={clients}
                              addClientToFavorite={addClientToFavorite}
                              removeClientFromFavorite={
                                removeClientFromFavorite
                              }
                              fetchClients={fetchClients}
                              whatPage={"secondary"}
                              showOnLeft={true}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {false && (
                      <div className="flat__price-action">
                        <div className="add-compare-btn js-compares-add">
                          <button className="button button_type_settings">
                            <svg className="icon icon_settings">
                              <use xlinkHref="#icon-compare"></use>
                            </svg>
                          </button>
                          К сравнению
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flat__period-grid">
                  <div className="flat__period-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">
                        {isSecondary ? priceFormat(data.baseCost) : priceFormat(data.costWithDiscount)}
                      </div>
                      <div className="flat__info-item_desc">
                        Цена, 100% оплата
                      </div>
                    </div>
                  </div>
                  {!!data.baseCost && !!data.squareTotal && <div className="flat__period-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">
                        {priceFormat(parseInt(getPricePerMeter(data.baseCost, data.squareTotal)))}
                      </div>
                      <div className="flat__info-item_desc">
                        Цена за кв. метр
                      </div>
                    </div>
                  </div>}
                  {!isPublic && !clientMode && (!!data.priceAgreement && data.priceAgreement > 0) && (
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {priceFormat(data.priceAgreement)}
                        </div>
                        <div className="flat__info-item_desc">
                          Цена ДДУ
                        </div>
                      </div>
                    </div>
                  )}
                  {data.endingDate && data.endingDate.length > 0 && (
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.endingDate}
                        </div>
                        <div className="flat__info-item_desc">Срок сдачи</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flat__period-grid">
                  {!clientMode && !isAgent && !isPublic && data.commission && (
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {priceFormat(data.commission)}
                        </div>
                        <div className="flat__info-item_desc">Комиссия</div>
                      </div>
                    </div>
                  )}
                  {data.encumbrances && data.encumbrances.length > 0 && (
                    <div className="flat__period-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.encumbrances.join(", ")}
                        </div>
                        <div className="flat__info-item_desc">
                          Список обременений
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {data?.status?.id != "1001" && !manager.isAgent && <div className="flat__info-btns">
                  <div className="js-modal-btn">
                    {
                      isPublic ? (
                        <button
                          className="button button_view_default"
                          onClick={openReserveModal}
                          style={{color: savedFilterSettings?.colorButtonText, backgroundColor: savedFilterSettings?.colorButton}}
                        >
                          Хочу забронировать
                        </button>
                      ) : (
                        <Link
                          className="button button_view_default"
                          to={`/flat-booking/secondary/${id}`}
                        >
                          Хочу забронировать
                        </Link>
                      )
                    }
                  </div>

                </div>}
                <div className="flat__info-grid">
                  {
                    // <div className="flat__info-col">
                    //   <div className="flat__info-item">
                    //     <div className="flat__info-item_value">{data.id}</div>
                    //     <div className="flat__info-item_desc">ID</div>
                    //   </div>
                    // </div>
                  }

                  {!!data.squareTotal && <div className="flat__info-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">
                        {data.squareTotal} м<sup>2</sup>
                      </div>
                      <div className="flat__info-item_desc">Общая площадь</div>
                    </div>
                  </div>}
                  {Boolean(data.squareLiving) && data.squareLiving > 0 && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.squareLiving} м<sup>2</sup>
                        </div>
                        <div className="flat__info-item_desc">
                          Жилая площадь
                        </div>
                      </div>
                    </div>
                  )}

                  {Boolean(data.squareKitchen) && data.squareKitchen > 0 && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.squareKitchen} м<sup>2</sup>
                        </div>
                        <div className="flat__info-item_desc">
                          Площадь кухни
                        </div>
                      </div>
                    </div>
                  )}
                  {data.roomType.countRooms && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.roomType.countRooms}
                        </div>
                        <div className="flat__info-item_desc">
                          Кол-во комнат
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.balconyType?.name && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.balconyType.name}
                        </div>
                        <div className="flat__info-item_desc">
                          Балкон
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.bathroomType?.name && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.bathroomType.name}
                        </div>
                        <div className="flat__info-item_desc">
                          Санузел
                        </div>
                      </div>
                    </div>
                  )}
                  {data.number && data.number > 0 && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.number}
                        </div>
                        <div className="flat__info-item_desc">
                          Номер квартиры
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.viewFromWindow?.length > 0 && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.viewFromWindow.map((viev, i, arr) => {
                            if (i === 0) {
                              return viev.name + ", "
                            }
                            if (arr.length === (i - 1)) {
                              return viev.name.toLowerCase() + ""
                            }
                            return viev.name.toLowerCase() + ", "
                          })}
                        </div>
                        <div className="flat__info-item_desc">Вид из окон</div>
                      </div>
                    </div>
                  )}
                  {data.entranceType && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.entranceType.name}
                        </div>
                        <div className="flat__info-item_desc">
                          Вход
                        </div>
                      </div>
                    </div>
                  )}
                  {"foor" in data && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.floor}{data.floors ? " / " + data.floors : ""}
                        </div>
                        <div className="flat__info-item_desc">Этаж</div>
                      </div>
                    </div>
                  )}
                </div>
                {!isParcel && <h3 className="flat-bordered-h3" >О доме</h3>}
                {!isParcel && <div className="flat__info-grid" >
                  {data?.builder?.name && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.builder.name}
                        </div>
                        <div className="flat__info-item_desc">Застройщик</div>
                      </div>
                    </div>
                  )}
                  {data.buildingType && <div className="flat__info-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">{data.buildingType.name}</div>
                      <div className="flat__info-item_desc">Тип дома</div>
                    </div>
                  </div>}
                  <div className="flat__info-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">
                        {data.isElevator ? "Лифт есть" : "Лифта нет"}
                      </div>
                      <div className="flat__info-item_desc">Лифт</div>
                    </div>
                  </div>
                  {data.buildingYear && <div className="flat__info-col">
                    <div className="flat__info-item">
                      <div className="flat__info-item_value">{data.buildingYear}</div>
                      <div className="flat__info-item_desc">Год постройки</div>
                    </div>
                  </div>}
                  {data.corpus && (
                    <div className="flat__info-col">
                      <div className="flat__info-item">
                        <div className="flat__info-item_value">
                          {data.corpus}
                        </div>
                        <div className="flat__info-item_desc">
                          Корпус / Очередь
                        </div>
                      </div>
                    </div>
                  )}
                </div>}

                {
                  !manager.isAgent && <LawyersChecked />
                }

              </div>
            </div>
          </div>
          {(manager || agent) && !manager.isAgent && (
            <div className="flat__manager sm-show">
              <Manager
                key="mobileManager"
                customType={"secondary"}
                data={agent ? agent : manager}
                isPublic={isPublic}
                onSuccessCallback={() =>
                  ym("reachGoal", "secondary_callback")
                }
              />
            </div>
          )}

          {
            manager.isAgent && (
              <div className="flat__manager sm-show">
                <div className="manager">
                  <div className="manager-content manager-content_managerIsAgent">
                    <div className="manager-title">{`${manager.name} ${manager.lastName}`}</div>
                    <div className="manager-contacts">
                      <ul className="manager-contacts_list">
                        {
                          manager.phone &&
                          <li className="manager-contacts_item">
                            <a href={`tel:${manager.phone}`} className="link-hover">{manager.phone}</a>
                          </li>
                        }
                        {
                          manager.email &&
                          <li className="manager-contacts_item">
                            <a href={`mailto:${manager.email}`} className="link-hover">{manager.email}</a>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className="modal" ref={modalMap}>
        <div className="modal__shadow " data-id="dist-map" style={{ background: `rgba(0, 0, 0, 0.4)` }}></div>
        <div className="modal__content">
          <div className="modal__inner">
            <div
              className="modal__close "
              data-id="dist-map"
              onClick={() => setShowMap(false)}
            >
              <svg className="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
            {/* <h3 className="modal__title">{`ЖК «${data.block?.name}» на карте`}</h3> */}
            <div className="dist-map" id="dist-map-container">
              {
                initMap || showMap ? (
                  <Maps.YMaps>
                    <Maps.Map
                      defaultState={{
                        center: data.coordinates,
                        zoom: 16,
                      }}
                      className="complex__map-wrap"
                      onLoad={mapLoad}
                    >
                      <Maps.Placemark
                        geometry={data.coordinates}
                        options={{
                          // iconLayout: "default#image",
                          // iconImageHref: "/assets/images/place.png",
                          iconLayout,
                          iconImageHref,
                          iconImageSize: [25, 40],
                          iconImageOffset: [-25, -20],
                        }}
                      />
                      <Maps.RulerControl
                        options={{ position: { top: 10, left: 10 } }}
                      />
                    </Maps.Map>
                  </Maps.YMaps>
                ) : (
                  <div className="dist-map" id="dist-map-container" />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryInfo);
