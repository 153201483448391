import compact from 'lodash/compact';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import { toTranslit } from '../utils';

const initialState = {
  isFetching: true,
  error: false,
  blocks: [],
  theme: {},
  agency: {},
  agent: {},
  complexes: [],
  sets: [],
  socials: [],
  countBlocks: 0,
  page: 1,
  hasNextPage: false,
  currentSetCity: "spb",
  setType: "apartment",
  setTabs: [],
  cities: [{ code: "" }],
  leadMagnet: null,
  isLoadBlocks: false,
  view: "list"
};
export const titleByType = {
  "apartment": "Новостройки",
  "secondaries": "Вторичка",
  "assignments": "Переуступки",
  "commercial": "Коммерция",
  "assignment": "Переуступки",
  "rent": "Аренда",
  "secondary": "Вторичка",
  "suburban": "Загородная",
}
const cityByCode = {
  "spb": "СПБ",
  "msk": "МСК",
  "": ""
}
const sortOrders = {
  "apartment": 0,
  "assignments": 1,
  "secondaries": 2,
  "commercial": 3,
  "secondary": 4,
  "assignment": 5,
  "rent": 6,
  "suburban": 7,
}
const setTabsWithoutMap = ["assignment", "rent", "secondary", "suburban"]
const switchCity = (defCity, sets) => {
  const hasDefaultSets = sets.some(set => set.city === defCity && set.active === 1)
  if (hasDefaultSets) return defCity
  const alternativeSets = sets.filter(set => set.city !== defCity && set.active === 1)
  if (alternativeSets.length === 0) return defCity
  return alternativeSets[0].city
}
const typeByParams = {
  "vtorichka": "secondary",
  "arenda": "rent",
  "pereustupki": "assignment"
}

const fromUrl = ( sets = []) => {
  const searchParams = new URLSearchParams(window.location.search)
  const viewFromUrl = searchParams.get("v")
  const match = decodeURI(window.location.search).match(/filter=(.+)\[(.+)\]/i);
  if (!match) return {}
  const cityFromUrl = match[1].match(/-(.+)/)?.[1] || "";
  let setFromUrl = sets.find(set => toTranslit(set.name.toLowerCase()) === match[2] && set.city === cityFromUrl);
  if (setFromUrl?.isSelfObjects) { // костыль тк категории своей недвиги без городов
    setFromUrl = sets.find(set => toTranslit(set.name.toLowerCase()) === match[2] && typeByParams[match[1]] === set.type);
  }
  return { cityFromUrl, setFromUrl, viewFromUrl }
}

const agency = (state = initialState, action) => {
  switch (action.type) {
    case "AGENCY_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "AGENCY_SUCCESS":
      const { site, blockTypes, setTypes, socialTypes, cities } = action.data
      const tmpSite = { ...site }
      tmpSite.socials = uniqBy([...tmpSite.socials, ...socialTypes].map((soc, i) => ({ ...soc, fakeId: i, active: soc.xmlId ? false : true, type: soc.type ? soc.type : soc.xmlId })), "type")
      if (tmpSite.multiLink) {
        return {
          ...state,
          ...tmpSite,
          blockTypes,
          isFetching: false,
        }
      }

      const setsBLock = site.blocks?.find(item=> item.component === "sets")
      if (setsBLock?.active === 0) {
        site.sets = site.sets.filter(item => item.isSelfObjects)
        if(site.sets?.length > 0){
          const setsBLockIndex = tmpSite.blocks?.findIndex(item=> item.component === "sets")
          tmpSite.blocks[setsBLockIndex].active = 1
        }
      }
      const currentSetCity = switchCity(site.agent.defaultCity, site.sets)
      const setType = site.sets.find(set => set.city === currentSetCity)
      tmpSite.sets = compact(site.sets.map((set, i) => {
        return set.active === 1 ? { ...set, filter: JSON.parse(set.filter), fakeId: i, isActive: false } : null
      }))

      const { cityFromUrl, setFromUrl, viewFromUrl } = fromUrl(tmpSite.sets)

      const setTabs = uniqBy(site.sets.filter(set => set.active === 1), (set) => set.city + set.type).map((set, i) => {
        let isActive = currentSetCity === set.city && setType.type === set.type
        if(setFromUrl && typeof cityFromUrl === "string"){
          isActive = setFromUrl.type === set.type && cityFromUrl === set.city
        }
        return {
          title: titleByType[set.type] + " " + cityByCode[set.city],
          type: set.type,
          cityCode: set.city,
          isActive,
          id: i + 1
        }
      }).sort((a, b) => {
        const typeOrder = sortOrders[a.type] - sortOrders[b.type]
        const isDefaultACity = a.cityCode === currentSetCity
        const isDefaultBCity = b.cityCode === currentSetCity
        if (typeOrder === 0 && isDefaultACity) {
          return -1
        }
        if (typeOrder === 0 && isDefaultBCity) {
          return 1
        }
        return typeOrder
      })

      const view = viewFromUrl? viewFromUrl : state.view
      const activeSetIndex = tmpSite.sets.findIndex(set => {
        if(setFromUrl && typeof cityFromUrl === "string"){
          return set.city === cityFromUrl && set.type === setFromUrl.type && setFromUrl.name === set.name
        }
        return set.city === currentSetCity && set.type === setType.type
      })

      if (activeSetIndex !== -1) {
        tmpSite.sets[activeSetIndex].isActive = true
      }
      // tmpSite.blocks = compact(site.blocks.map(block => block.active ? block : null))
      tmpSite.advantages = compact(
        site.advantages.map((adv, i) => {
          if (!adv.active) return null
          return { ...adv, fakeId: i }
        })
      )
      tmpSite.services = compact(
        site.services.map((serv, i) => {
          if (!serv.active) return null
          return { ...serv, fakeId: i }
        })
      )

      return {
        ...state,
        ...tmpSite,
        isFetching: false,
        blockTypes,
        setTypes,
        cities: [...cities, { code: "" }],
        currentSetCity,
        setType,
        setTabs,
        view
      };
    case "AGENCY_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "SET_ACTIVE_SET_TYPE":
      return {
        ...state,
        setType: action.setType
      }
    case "AGENCY_RESET":
      return { ...initialState };
    case "AGENCY_SET_STATE":
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case "AGENCY_SET_PREVIEW_STATE":
      const tmp = { ...action.payload }
      tmp.sets = compact(tmp.sets.map((set, i) => {
        return set.isActive ? { ...set, filter: JSON.parse(set.filter), fakeId: i, isActive: i === 0 } : null
      }))
      // tmp.agency = { ...tmp.agency, logo: tmp.agency.logo.startWith("data:") ? { src: tmp.agency.logo } : tmp.agency.logo }
      // tmp.agent = { ...tmp.agent, photo: tmp.agent.photo.startWith("data:") ? { src: tmp.agent.photo } : tmp.agent.photo }
      tmp.agency = { ...tmp.agency, logo: tmp.agency.logo }
      tmp.agent = { ...tmp.agent, photo: tmp.agent.photo }
      tmp.services = compact(tmp.services.map((service, i) => {
        return service.isActive ? { ...service } : null
      }))
      tmp.advantages = compact(tmp.advantages.map((adv, i) => {
        return adv.isActive ? { ...adv } : null
      }))
      tmp.blocks = compact(tmp.blocks.map(block => block.active ? block : null))
      return {
        ...state,
        ...tmp,
        isFetching: false,
      };
    case "GET_BLOCKS_REQUEST_AGENCY":
    case "GET_BLOCKS_REQUEST_AGENCY":
    case "GET_BLOCKS_REQUEST_AGENCY":
      return {
        ...state,
        isLoadBlocks: true
      }
    case "AGENCY_GETBLOCKS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        complexes: action.blocks,
        countBlocks: action.countBlocks,
        page: state.page + 1,
        hasNextPage: action.countBlocks > action.blocks.length,
        isLoadBlocks: false
      };
    case "AGENCY_LOAD_MORE_BLOCKS_SUCCESS":
      let complexes = concat(state.complexes, action.blocks)
      return {
        ...state,
        isFetching: false,
        complexes,
        countBlocks: action.countBlocks,
        page: state.page + 1,
        hasNextPage: action.countBlocks > complexes.length,
        isLoadBlocks: false
      };
    case "AGENCY_SET_ACTIVE_BLOCKS":
      const tmpSets = [...state.sets].map(set => {
        set.isActive = false
        return set
      })
      const setIndex = tmpSets.findIndex(set => set.fakeId === action.id)
      tmpSets[setIndex].isActive = true
      return {
        ...state,
        sets: tmpSets,
        page: 1,
      };
    case "AGENCY_SET_ACTIVE_SETTYPE":
      {
        const { setType, city } = action
        let tmpSets = [...state.sets].map(set => {
          set.isActive = false
          return set
        })
        const index = tmpSets.findIndex(set => set.type === setType && set.city === city)
        tmpSets[index].isActive = true
        const tmpSetTabs = [...state.setTabs].map(tab => {
          tab.isActive = false
          return tab
        })
        const tabIndex = tmpSetTabs.findIndex(tab => tab.cityCode === city && tab.type === setType)
        tmpSetTabs[tabIndex].isActive = true
        const view = setTabsWithoutMap.includes(setType) ? "list": state.view
        return {
          ...state,
          sets: tmpSets,
          page: 1,
          setTabs: tmpSetTabs,
          currentSetCity: city,
          view
        };
      }
      case "SET_ACTIVE_VIEW":
        return {
          ...state,
          view: action.view
        }
    default:
      return state;
  }
};

export default agency;
