import React, { useEffect, useState } from "react";
import useMedia from "../../hooks/useMedia";
import { removeBodyScroll, returnBodyScroll } from '../../utils';
import SelectFilter from "../FilterRealty/SelectFilter";

const Badge = (props) => {
  const { item, handleClick, title, identity } = props;

  return (
    <div class="filtered">
      <div class="filtered__inner">
        <span class="filtered__type">{title}:</span>
        <span class="filtered__value">{item.title}</span>
        <button
          class="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            class="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

const Filter = (props) => {
  const { filter, filterData, setFilter, clearFilter, isFilterSet, isFiltersOpen } = props;
  
  const isMobile = useMedia("isMobile")

  const handleFilter = (value, identity) => setFilter({ ...filter, [identity]: value });

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };

  const showBadges = () => {
    return (
      filter &&
      (filter.agents.length > 0 ||
        filter.active.length > 0 ||
        filter.rights.length > 0)
    );
  };

  const [count, setCount] = useState(
    filter &&
    (filter.agents.length +
      filter.active.length +
      filter.rights.length)
  );

  useEffect(() => {
    setCount(
      filter &&
      (filter.agents.length +
        filter.active.length +
        filter.rights.length))
  }, [filter]);

  const handleRemoveFilter = (value, identity) => {
    let newArray = filter[identity];
    newArray = newArray.filter((item) => item != value);
    setFilter({ ...filter, [identity]: newArray });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? removeBodyScroll()
      : returnBodyScroll()
  }

	useEffect(() => {
    if (isFiltersOpen) setIsOpen(!isOpen);
  }, [isFiltersOpen]);

  const filters = (
    <>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "agents")}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.agents ? Object.values(filterData.agents) : []}
          filterValues={filter.agents}
          excludeValues={[]}
          data={{
            title: "Сотрудники",
            autocompleteTitle: "Введите имя сотрудника",
            identity: "agents",
          }}
					isMobile={isOpen && isMobile}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "rights")}
          isExcludable={false}
          isAutocomplete={false}
          items={filterData.rights ? Object.values(filterData.rights) : []}
          filterValues={filter.rights}
          excludeValues={[]}
          data={{
            title: "Уровень доступа",
            autocomleteTitle: "",
            identity: "rights",
          }}
					isMobile={isOpen && isMobile}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "active")}
          isExcludable={false}
          isAutocomplete={false}
          items={filterData.active ? Object.values(filterData.active) : []}
          filterValues={filter.active}
          excludeValues={[]}
          data={{
            title: "Активность",
            autocomleteTitle: "",
            identity: "active",
          }}
					isMobile={isOpen && isMobile}
					clearFilters={clearFilter}
        />
      </div>
    </>
  )

  return (
    <div className="mini-filter">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {isMobile ?
          <>
            <div class="clients__filter-col clients__filter-col_search">
              <label
                class="input input_type_search input_width_available"
                for="clients-search"
              >
                <input
                  class="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск ..."
                  value={filter.search}
                  onChange={(e) => handleFilter(e.target.value, "search")}
                />
                <svg class="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div>
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Фильтры
              {count > 0 && ` (${count})`}
            </button>
          </> :

          <div className="mini-filter__grid">
            <div class="clients__filter-col clients__filter-col_search">
              <label
                class="input input_type_search input_width_available"
                for="clients-search"
              >
                <input
                  class="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск ..."
                  value={filter.search}
                  onChange={(e) => handleFilter(e.target.value, "search")}
                />
                <svg class="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div>

            {filters}

            <div class="mini-filter__col mini-filter__col_clear is-hidden-sm">
              <button
                class="button button_type_clear mini-filter__button"
                type="button"
                onClick={handleClearFilter}
                disabled={!showBadges()}
              >
                <svg class="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            </div>
          </div>
        }
      </form>

      {isOpen && isMobile &&
				<div className="mobile-filters for-clients">
					<div className="mobile-filters__title">
						<svg
							className="icon icon_arrow_up"
							onClick={() => {
								// props.onClick()
								toggleFilters()
							}}
						>
							<use xlinkHref="#arrow_up"></use>
						</svg>
						<h3>Все фильтры</h3>
					</div>
					<div className="mobile-filters__content">
						{filters}

            {showBadges() && (
              <div class="index-search__filter-applied">
                <div class="index-search__filter-inc no-margin">
                  {filter.agents.map((id) => {
                    let b = filterData.agents.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`agents${id}`}
                        identity={`agents`}
                        item={b}
                        title={`Сотрудник`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                  {filter.rights.map((id) => {
                    let b = filterData.rights.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`rights${id}`}
                        identity={`rights`}
                        item={b}
                        title={`Уровень доступа`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                  {filter.active.map((id) => {
                    let b = filterData.active.find((item) => id == item.id);
                    return (
                      <Badge
                        key={`active${id}`}
                        identity={`active`}
                        item={b}
                        title={`Активность`}
                        handleClick={handleRemoveFilter}
                      />
                    );
                  })}
                </div>
              </div>
            )}


						<div className="mobile-filters__show-all for-agents">
              <button
                className="button button_view_default"
                type="submit"
                onClick={(e) => {
									e.preventDefault()
									toggleFilters()
								}}
              >
                Показать
              </button>
							<button
								className="button button__mobile-filters"
                type="button"
								onClick={(e) => {
									handleClearFilter(e)
									toggleFilters()
								}}
							>
								Очистить
							</button>
						</div>
					</div>
				</div>
			}

      {showBadges() && !isMobile && (
        <div class="index-search__filter-applied">
          <div class="index-search__filter-inc">
            {filter.agents.map((id) => {
              let b = filterData.agents.find((item) => id == item.id);
              return (
                <Badge
                  key={`agents${id}`}
                  identity={`agents`}
                  item={b}
                  title={`Сотрудник`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.rights.map((id) => {
              let b = filterData.rights.find((item) => id == item.id);
              return (
                <Badge
                  key={`rights${id}`}
                  identity={`rights`}
                  item={b}
                  title={`Уровень доступа`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.active.map((id) => {
              let b = filterData.active.find((item) => id == item.id);
              return (
                <Badge
                  key={`active${id}`}
                  identity={`active`}
                  item={b}
                  title={`Активность`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;