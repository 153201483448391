import request from "../api";

const component = "panpartner:system.auth.form"

const getMainGroup = (groups) => {
  // const priority = ["test_access", "general_director", "master_account", "agent_extended", "agent", "toprealtors"]
  // return priority.find(item=>groups.includes(item)) || "" // а можно было бы так, но много где переделывать #оптимизация

  if (groups.includes("test_access")) return "test_access";
  if (groups.includes("head_sales_dep")) return "head_sales_dep";
  if (groups.includes("marketer")) return "marketer";
  if (groups.includes("general_director")) return "general";
  if (groups.includes("master_account")) return "master";
  if (groups.includes("agent_extended")) return "extended";
  if (groups.includes("agent")) return "agent";
  if (groups.includes("toprealtors")) return "toprealtors";
  return "";
};

export const setUserState = (payload = {}) =>({
  type: "SET_USER_STATE",
  payload
})

const requestLogin = () => ({
  type: "LOGIN_REQUEST",
});

const failureLogin = () => ({
  type: "LOGIN_FAILURE",
});

export const successLogin = data => ({
  type: "LOGIN_SUCCESS",
  data,
});

const logoutLogin = () => ({
  type: "LOGOUT",
});

export const acceptOffers = () => ({
  type: "ACCEPT_OFFERS",
});

const editUser = data => ({
  type: "USER_EDIT",
  data,
});

export const editUserData = data => dispatch => dispatch(editUser(data));

export const login = (form, method = "login") => dispatch => {
  dispatch(requestLogin());
  return request(component, method, form, true)
    .then(
      data => {
        localStorage.clear()
        const group = getMainGroup(data.user.groups);
        dispatch(successLogin({ ...data, access: group }));
        request('panpartner:telegram.live.ajax', 'getTelegramLive').then(resp=>{
          dispatch({
            type: "GENERAL_SET_HEADER_BANNER",
            payload: resp.telegramLive
        })})
        request('panpartner:system.auth.form', 'getServicesMenu').then(resp=>{
          dispatch({
            type: "GENERAL_SET_SERVICES_MENU",
            payload: resp.servicesMenu
        })})
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureLogin());
        return Promise.reject(error);
      }
    );
};

export const fakeLogin = form => login(form, "getData");

export const testLogin = () => login({}, "testLogin");

export const successTestAccessSms = (data) => ({
  type: "TEST_ACCESS_SMS_SUCCESS",
  data,
});
export const testAccessSms = form => request(component, "testAccessSms", form, true);

export const testAccessConfirm = form => login(form, "testAccessConfirm");

export const getConsultation = form => request(component, "getConsultation", form, true);

export const forgot = form => request(component, "forgotPassword", form, true);

export const recovery = form => request(component, "changePassword", form, true);

export const logout = () => dispatch => request(component, "logout").then(() => dispatch(logoutLogin()));

export const telegramBotAction = (telegramId, action) => request("panpartner:telegram.bot.ajax", action, { telegramId });

export const commercialTopAction = (id, action) => request("panpartner:telegram.bot.ajax", action, { id });

export const premiumUserAction = () => request(component, "checkPremium", null, true);

export const restorePasswordSimple = (data) => request('panpartner:system.auth.form', 'restorePasswordSimple', data);

export const loginByToken = form => login(form, "loginByToken");

export const restorePassword = form => request(component, "restorePassword", form);
