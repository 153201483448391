import { MapIcon, MapIconWhite } from './icons';
import Button from '../../core/Button';


const MapBtn = ({ setShowMap, noBg, noText = false }) => (
  <Button
    buttonType='secondary'
    addClass={`sf__btn${noBg ? ` sf__btn_no-bg` : ``}`}
    onClick={() => setShowMap(true)}
  >
    {noText ? <MapIconWhite /> : !noBg ? <MapIcon /> : null}
    {!noText && <span>На карте</span>}
  </Button>
);

export default MapBtn;
