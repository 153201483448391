import { handleFavorite } from './handlers';

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  manager: null,
  banks: [],
  subways: [],
  isActive: true,
  regulationsLink: "",
  savedFilterSettings: {}
};

const assignment = (state = initialState, action) => {
  switch (action.type) {
    case "ASSIGNMENT_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "ASSIGNMENT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        manager: action.manager,
        banks: action.banks,
        subways: action.subways,
        agent: action.agent,
        isActive: action.isActive,
        isShowFilter: action.isShowFilter,
        client: action.client,
        regulationsLink: action.regulationsLink,
        savedFilterSettings: action.savedFilterSettings
      };
    case "ASSIGNMENT_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "ASSIGNMENT_RESET":
      return { ...initialState };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'assignment') {
        return { ...state, data: handleFavorite(state.data, action, true) }
      }
      return state
    default:
      return state;
  }
};

export default assignment;
