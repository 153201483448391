import printJS from 'print-js';
import React, { useState } from 'react';
import { getPdf } from '../../actions/presentations';
import { getUrl } from '../../api';
import { downloadLink } from '../../utils';


const PresentationBlock = (props) => {
  const { city, objectXmlId, objectType, savedFilterXmlId } = props;
  const [pdf, setPdf] = useState(null);

  const handleSend = (type = 'download') => {
    const params = {
      objectXmlId,
      city,
      savedFilterXmlId,
      type: objectType,
    };

    getPdf(params).then((data) => {
      let path = getUrl(data.filePath)
      setPdf(path);
      if (type === 'print') {
        printJS(path);
      } else {
        downloadLink(path);
      }
    });
  };

  return (
    <>
      <div className="flat__download">
        <button
          className="button button_type_settings"
          onClick={() => {
            pdf ? printJS(pdf) : handleSend('print');
          }}
        >
          <svg className="icon icon_settings">
            <use xlinkHref="#icon_print"></use>
          </svg>
        </button>

        <button
          className="button button_type_settings"
          onClick={() => {
            pdf ? downloadLink(pdf) : handleSend('download');
          }}
        >
          <svg className="icon icon_settings">
            <use xlinkHref="#icon_download"></use>
          </svg>
        </button>
      </div>
    </>
  );
};

export default PresentationBlock;
