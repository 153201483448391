import React, { useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import { useReserveModals } from '../../components/../hooks/useReserveModals.js';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import GetConsultation from '../../components/forms/GetConsultation.js';
import ReserveMeetingRoom from '../../components/forms/ReserveMeetingRoom.js';
import { media, scrollToRef } from '../../utils';
import './index.scss';

const MeetingRooms = (props) => {

  const { user = {} } = props;

  const [showReserveMeetingRoom, hideReserveMeetingRoom] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideReserveMeetingRoom} classes="modal_task modal_large modal_top-overflow">
        <ReserveMeetingRoom
          defaultValues={{
            date: new Date(),
            comment: '',
            agree: true,
          }}
          handleReserve={handleReserve}
          close={hideReserveMeetingRoom}
          showFixateClient={showFixateClient}
        />
      </Modal>
    ),
  );

  const handleReserve = () => {
    fetch();
  }

  const [showFixateClient, hideFixateClient] = useReserveModals(media('isMobile'));
  const [showMobileReserveMeetingRoom, hideMobileReserveMeetingRoom] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        closeClick={hideMobileReserveMeetingRoom}
        classes="modal_task"
        title="Забронировать"
      >
        <ReserveMeetingRoom
          defaultValues={{
            date: new Date(),
            comment: '',
            agree: true,
          }}
          handleReserve={handleReserve}
          close={hideMobileReserveMeetingRoom}
          showFixateClient={showFixateClient}
        />
      </MobileModal>
    ),
  );


  const [openConsultationModal, closeConsultationModal] = useModal(
    ({ in: open, onExited }) => (

      <Modal close={closeConsultationModal} classes="modal_task">
        <GetConsultation
          title="Получить консультацию"
          hide={closeConsultationModal}
          userInfo={user.info}
        />
      </Modal>
    ),
  );

  const [openConsultationMobileModal, closeConsultationMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        closeClick={closeConsultationMobileModal}
        classes="modal_task"
        title="Получить консультацию"
      >
      <GetConsultation
        hide={closeConsultationModal}
        userInfo={user.info}
      />
      </MobileModal>
    ),
  );

  const [activeTab, setActiveTab] = useState(0);

  const tabsData = [
    { id: 0, title: 'Переговорная 1', text1: 'C 9:00 до 21:00 по будням', text2: 'с 10:00 до 19:00 по выходным' },
    { id: 1, title: 'Переговорная 2', text1: 'C 9:00 до 21:00 по будням', text2: 'с 10:00 до 19:00 по выходным' },
    { id: 2, title: 'Welcome-стол', text1: 'C 9:00 до 21:00 по будням', text2: 'с 10:00 до 19:00 по выходным' },
    { id: 3, title: 'Наш офис', text1: 'C 9:00 до 21:00 по будням', text2: 'с 10:00 до 19:00 по выходным' },
  ];

  const Tab = (props) => {
    const {moveSlider, title, text1, text2, isActive, id} = props;

    return (
      <li className={`mr__slider-nav-item${isActive === id ? ` active` : ``}`} onClick={() => moveSlider(id)}>
        <button type="button">
          <h4 className="h4">{title}</h4>
          <p>{text1}</p>
          <p>{text2}</p>
        </button>
      </li>
    );
  };

  const Tabs = (props) => {
    const {tabsData, moveSlider} = props;
    return (
      tabsData.map((tab, i) => <Tab key={`tab-${i}`}
      title={tab.title}
      text1={tab.text1}
      text2={tab.text2}
      moveSlider={moveSlider}
      isActive={activeTab}
      id={tab.id} />)
    );
  };

  const meetingSlider = useRef(null);
  const serviceSlider = useRef(null);

  const consultationRef = useRef();
  const meetingsRef = useRef();
  const footerRef = useRef();


  const moveSlider = (way) => {
    if (meetingSlider.current && meetingSlider.current.slider) {
      meetingSlider.current.slider.goTo(way);
    }
  };

  if (meetingSlider.current && meetingSlider.current.slider) {
    const meetingSliderObject = meetingSlider.current.slider;
    meetingSliderObject.events.on('indexChanged', () => setActiveTab(meetingSliderObject.getInfo().index));
  }


  const moveSlider2 = (way) => {
    if (serviceSlider.current && serviceSlider.current.slider) {
      serviceSlider.current.slider.goTo(way);
    }
  };


  const settings = {
    items: 1,
    nav: false,
    loop: false,
    controls: false,
  };

  const settings2 = {
    items: 1,
    nav: false,
    loop: false,
    controls: false,
    responsive: {
      1280: {
        items: 3,
        "center": false,
      },
      768: {
        items: 2,
        "center": true,
      },
    },
  };


  return (
    <>
      <section className="section section_view_white mr">

        <section className="mr__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="mr__promo-box">

                <h1 className="h1 mr__title">Переговоркинг</h1>

                <p className="big-text mr__subtitle">Присоединяйтесь к нашей команде и пользуйтесь следующими преимуществами</p>

                <ul className="mr__nav">
                  <li><a onClick={() => scrollToRef(consultationRef, -220)}>Получить косультацию</a></li>
                  <li><a onClick={() => scrollToRef(meetingsRef, -20)}>Забронировать переговорную</a></li>
                  <li><a onClick={() => scrollToRef(footerRef, 0)}>Стать партнером</a></li>
                </ul>
              </div>
            </div>
          </div>

        </section>

        <div className="wrapper">
          <div className="wrapper__center">

            <form action="" className="mr__partner-form">
              <div className="mr__partner-descr">
                <div className="mr__partner-box">

                  <h4 className="mr__partner-title h4">Стань партнером ПАН по вторичной недвижимости и повысь эффективность</h4>
                  <p className="mr__partner-effect">
                    <span className="mr__partner-percent">+200</span>
                    <span className="mr__partner-percentsign">% эффективность</span>
                  </p>

                  <p className="mr__partner-text text">Повысь свою эффективность, став партнёром ПАН по вторичной недвижимости</p>
                </div>
              </div>

              <div className="mr__partner-fieldset">
                <div className="mr__partner-row">
                <label
                  className="mr__partner-input input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Ваше имя</span>
                  <input
                    id="mr-name"
                    name="name"
                    className={
                      `input__control`
                    }
                    placeholder="Ваше имя"

                  />
                  {
                    // errors
                  }

                </label>
                <label
                  className="mr__partner-input input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Ваш телефон</span>

                    <InputMask
                      className={
                        `input__control`
                      }
                      placeholder="Введите телефон"
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                    />

                </label>

                </div>
                <div className="mr__partner-row">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        `checkbox__box`
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="mr-agreement"
                        name="agree"
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="mr-agreement"
                      ></label>
                      <div className="checkbox__marker"></div>
                    </div>
                    <label
                      className="checkbox__label mr__agreement"
                      htmlFor="mr-agreement"
                    >
                      Cогласие на обработку <Link to="/telegram" target="_blank">персональных данных</Link>
                    </label>
                  </div>
                </div>
                <button type="submit" className="button button_view_default">Отправить заявку</button>
              </div>
            </form>
          </div>
        </div>


        <div className="wrapper">
          <div className="wrapper__center">

            <section className="mr__advantages">
              <div className="mr__advantage">
                <img src={require('../../assets/images/mr/advantage-1.svg').default} alt="" className="mr__advantage-img"/>
                <h3 className="h3 mr__advantage-title">Полный сервис для работы на рынке</h3>
                <p className="text mr__advantage-text">CRM система для работы с клиентами и публикации объявлений, учебный центр, обучающие семинары</p>
              </div>
              <div className="mr__advantage">
                <img src={require('../../assets/images/mr/advantage-2.svg').default} alt="" className="mr__advantage-img"/>
                <h3 className="h3 mr__advantage-title">Консультация штатных специалистов</h3>
                <p className="text mr__advantage-text">Поддержка опытных в сфере недвижимости юристов, ипотечный брокеридж</p>
              </div>
              <div className="mr__advantage">
                <img src={require('../../assets/images/mr/advantage-3.svg').default} alt="" className="mr__advantage-img"/>
                <h3 className="h3 mr__advantage-title">Рабочие места и переговорные комнаты</h3>
                <p className="text mr__advantage-text">Мы предоставляем нашим партнёрам офис для работы и проведения сделок</p>
              </div>
            </section>


            <section className="mr__consultation">
              <div className="mr__consultation-aside">
                <div className="mr__consultation-asidebox">
                  <h2 className="h2 mr__consultation-title">Клиенты Ваши, юридические вопросы — наши!</h2>
                  <p className="text mr__consultation-text">Присоединяйтесь к нашей команде и пользуйтесь следующими преимуществами</p>
                </div>
                <img src={require('../../assets/images/mr/consultation-1.svg').default} alt="" className="mr__consultation-img"/>
              </div>

              <form action="" className="mr__consultation-form">
                <div className="mr__consultation-row">
                  <div className="mr__consultation-col">
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red checkbox_view_flex-start">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-1"
                            name="checkbox-1"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-1"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-1"
                        >
                          Информационное и юридическое сопровождение переговоров
                        </label>
                      </div>
                    </div>
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red checkbox_view_flex-start">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-2"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-2"
                        >
                          Полное комплексное сопровождение сделки
                        </label>
                      </div>
                    </div>
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-3"
                            name="checkbox-3"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-3"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-3"
                        >
                          Ускорение росреестра
                        </label>
                      </div>
                    </div>
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-4"
                            name="checkbox-4"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-4"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-4"
                        >
                          Выписка ЕГРН онлайн
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mr__consultation-col">
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red checkbox_view_flex-start">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-5"
                            name="checkbox-5"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-5"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-5"
                        >
                          Проверка юр. чистоты предоставляемых документов
                        </label>
                      </div>
                    </div>
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-6"
                            name="checkbox-6"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-6"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-6"
                        >
                          Запрос в компетентные органы
                        </label>
                      </div>
                    </div>
                    <div className="mr__consultation-checkbox">
                      <div className="checkbox checkbox_view_red">
                        <div
                          className={
                            `checkbox__box`
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="checkbox-7"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label mr__agreement"
                          htmlFor="checkbox-7"
                        >
                          Составление ДКП и ПДКП
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mr__consultation-row mr__consultation-row_footer">
                  <div className="mr__consultation-item mr__consultation-col">
                    <button type="button" className="mr__consultation-btn button button_view_default" onClick={!media('isMobile') ? openConsultationModal : openConsultationMobileModal}>Получить консультацию</button>
                    <div className="info">
                      <svg className="icon icon_info">
                        <use xlinkHref="#info"></use>
                      </svg>
                      <div className="info__popup">
                        <p>Консультация предоставляется бесплатно. Услуги оплачиваются отдельно.</p>
                      </div>
                    </div>
                  </div>
                  <p className="mr__consultation-sign mr__consultation-col">Услуги являются платными, вы можете выбрать и оплатить на странице <Link to="/" target="_blank" className="link">online оплаты</Link></p>
                </div>

              </form>
            </section>
          </div>
          <span class="ref" ref={consultationRef}></span>
        </div>

        <section className="mr__meetings" ref={meetingsRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="mr__meetimngs-box">
                <h2 className="mr__meetings-title h2">Проводите встречи на высшем уровне</h2>
                <p className="mr__meetings-subtitle big-text">Переговорные комнаты, услуги офис-менеджера, кофе, сладости — бесплатно для наших партнеров!</p>
              </div>

            </div>
          </div>

          <div className="mr__slider">

            <div className="wrapper">
              <div class="block__title-nav">
                <ul class="block__title-navs">
                  <li
                  class="block__title-navs_item prev"
                  onClick={() => moveSlider("prev")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                  class="block__title-navs_item next"
                  onClick={() => moveSlider("next")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>

              <div className="wrapper__center">
                <ul className="mr__slider-nav">
                {
                  //
                  // <li className="mr__slider-nav-item active">
                  //   <button type="button" onClick={() => moveSlider(0)}>
                  //     <h4 className="h4">Переговорная 1</h4>
                  //     <p>C 9:00 до 21:00 по будням<br />с 10:00 до 19:00 по выходным</p>
                  //   </button>
                  // </li>
                  // <li className="mr__slider-nav-item" onClick={() => moveSlider(1)}>
                  //   <button type="button">
                  //     <h4 className="h4">Переговорная 2</h4>
                  //     <p>C 9:00 до 21:00 по будням<br />с 10:00 до 19:00 по выходным</p>
                  //   </button>
                  // </li>
                  // <li className="mr__slider-nav-item" onClick={() => moveSlider(2)}>
                  //   <button type="button">
                  //     <h4 className="h4">Welcome-стол</h4>
                  //     <p>C 9:00 до 21:00 по будням<br />с 10:00 до 19:00 по выходным</p>
                  //   </button>
                  // </li>
                  // <li className="mr__slider-nav-item" onClick={() => moveSlider(3)}>
                  //   <button type="button">
                  //     <h4 className="h4">Наш офис</h4>
                  //     <p>C 9:00 до 21:00 по будням<br />с 10:00 до 19:00 по выходным</p>
                  //   </button>
                  // </li>
                }

                  <Tabs tabsData={tabsData} moveSlider={moveSlider} />
                </ul>

                <button type="button" class="mr__slide-btn button button_view_default" onClick={media('isMobile') ? showMobileReserveMeetingRoom : showReserveMeetingRoom}>Забронировать переговорную</button>
              </div>
            </div>

          {
            <TinySlider settings={settings} ref={meetingSlider}>
              <img src={require('../../assets/images/mr/slide-1.jpg').default} alt="" class="mr__slide-img" />
              <img src={require('../../assets/images/mr/slide-3.jpg').default} alt="" class="mr__slide-img" />
              <img src={require('../../assets/images/mr/slide-1.jpg').default} alt="" class="mr__slide-img" />
              <img src={require('../../assets/images/mr/slide-3.jpg').default} alt="" class="mr__slide-img" />
            </TinySlider>
          }






          </div>
        </section>

        <section className="mr__service">
          <div className="wrapper">


            <h2 className="h2">Мы организовали лучший сервис для наших партнёров!</h2>

            <div className="mr__service-slider">

              <div class="block__title-nav">
                <ul class="block__title-navs">
                  <li
                  class="block__title-navs_item prev"
                  onClick={() => moveSlider2("prev")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                  class="block__title-navs_item next"
                  onClick={() => moveSlider2("next")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
              <TinySlider settings={settings2} ref={serviceSlider}>

                <div>
                  <div className="mr__service-slide">
                    <div className="mr__service-header">
                      <span className="mr__service-counter">01</span>
                      <h4 className="h4">Скидки от партнеров</h4>
                    </div>
                    <ul className="mr__service-ul">

                      <li>Бесплатные безналичные расчеты от банков-партнеров ПАН (Сбербанк, Промсвязьбанк, Банк Санкт-Петербург)</li>
                      <li>Скидка на услуги расчетных центров</li>
                      <li>Нотариус</li>
                      <li>Приемка квартиры без отделки в новостройке бесплатно</li>

                    </ul>
                  </div>
                </div>

                <div>
                  <div className="mr__service-slide">
                    <div className="mr__service-header">
                      <span className="mr__service-counter">02</span>
                      <h4 className="h4">Наш офис</h4>
                    </div>

                    <ul className="mr__service-ul">
                      <li>Фирменные бланки договоров<br />с клиентами</li>
                      <li>Комната для деловых переговоров и встреч</li>
                      <li>Офис-менеджер встречает клиентов, приносит напитки, сканирует, печатает и ксерокопирует документы</li>
                      <li>Информационный портал (расчетные центры, нотариусы, МФЦ, банки)</li>
                      <li>Детский уголок</li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className="mr__service-slide">
                    <div className="mr__service-header">
                      <span className="mr__service-counter">03</span>
                      <h4 className="h4">Юр. поддержка и CRM</h4>
                    </div>

                    <ul className="mr__service-ul">
                      <li>Консультация по телефону или в офисе по вопросам недвижимости с 10:00 до 19:00 в будние дни</li>
                      <li>Подключение к TopnLab. Личный кабинет для ведения своей клиентской базы.</li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className="mr__service-slide">
                    <div className="mr__service-header">
                      <span className="mr__service-counter">03</span>
                      <h4 className="h4">Юр. поддержка и CRM</h4>
                    </div>

                    <ul className="mr__service-ul">
                      <li>Консультация по телефону или в офисе по вопросам недвижимости с 10:00 до 19:00 в будние дни</li>
                      <li>Подключение к TopnLab. Личный кабинет для ведения своей клиентской базы.</li>
                    </ul>
                  </div>
                </div>
              </TinySlider>
              {


              // <div className="mr__service-slide">
              //   <div className="mr__service-header">
              //     <span className="mr__service-counter">01</span>
              //     <h4 className="h4">Скидки от партнеров</h4>
              //   </div>
              //   <ul className="mr__service-ul">
              //     <li>Бесплатные безналичные расчеты от банков-партнеров ПАН (Сбербанк, Промсвязьбанк, Банк Санкт-Петербург)</li>
              //     <li>Скидка на услуги расчетных центров</li>
              //     <li>Нотариус</li>
              //     <li>Приемка квартиры без отделки в новостройке бесплатно</li>
              //   </ul>
              // </div>
              }

              {

              //
              }
            </div>
          </div>
        </section>


        <section className="mr__footer" ref={footerRef}>
          <div className="wrapper">
            <img className="mr__banner-bg" src={require('../../assets/images/mr/banner-bg.svg').default} alt=""/>
            <div className="wrapper__center">

              <div className="mr__banner">
              <h3 className="h3 mr__banner-title">Стоимость пользования сервисами ПАН</h3>

              <div className="mr__banner-info">
                <img src={require('../../assets/images/mr/script.svg').default} alt=""/>
                <div className="mr__banner-box">

                <p className="mr__banner-price">
                  <span className="mr__banner-bigprice">5 000</span>
                  <span className="mr__banner-smallprice">руб. / мес.</span>
                </p>

                <p className="text mr__banner-text">При подписании договора по вторичной недвижимости</p>
                </div>
              </div>

              <button type="button" className="button button_view_default mr__banner-btn">Подписать договор</button>
              </div>

              <h4 className="mr__footer-title h4">Вы всегда можете оплатить услуги переговоркинга и рабочего места не выходя из офиса, онлайн.</h4>
              <button type="button" className="button button_view_outline mr__btn">Оплатить online</button>
            </div>
          </div>



        </section>

      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRooms);
