import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const AccessDenied = () => {
  return (

  <section className="section">
    <div className="wrapper access-denied">
      <div className="access-denied__absolute-container">
      <Link type="button" className="access-denied__absolute-btn button button_view_outline-with-icon" to="/constructor-admin">
      <svg class="icon icon_back">
      <use xlinkHref="#back"></use>
      </svg>
      <span>
      Личный сайт
      </span>
      </Link>
      </div>


      <div className="wrapper__center">
        <section className="access-denied__box">


          {
            // <img className="access-denied__book" src={require('../../assets/images/access-denied/book.svg')} alt=""/>
            //

          }
          <h2 className="access-denied__h2 h2">Доступ к данному разделу открыт только для партнеров ПАН</h2>
          <p className="access-denied__text big-text">В нашей базе вы найдете:</p>

          <div className="access-denied__list">
            <div className="access-denied__item">
              <p>Десятки тысяч квартир и коммерческих помещений от застройщиков</p>
            </div>
            <div className="access-denied__item">
              <p>Несколько тысяч переуступок и квартир на вторичном рынке</p>
            </div>
            <div className="access-denied__item">
              <p>Коттеджные посёлки и таунхаусы</p>
            </div>
          </div>

          <p className="access-denied__text-info">По каждому объекту мы платим <span>максимально большую комиссию</span> на рынке недвижимости.</p>


          <div className="access-denied__dark-block">
          <img className="access-denied__book" src={require('../../assets/images/access-denied/book.svg')} alt=""/>
            <p className="big-text">Чтобы получить доступ ко всем возможностям сайта оставьте заявку. Мы обязательно вам позвоним!</p>
            <Link type="button" className="access-denied__btn button button_view_default" to="/add-partner">Отправить заявку</Link>
          </div>

        </section>
      </div>
    </div>
    </section>
  );
};

export default AccessDenied;
