import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const Question = () => {
  const hideBXbutton = () => {
    window.BX?.SiteButton.hide()

  }
  useEffect(() => {
    //window.addEventListener('onBitrixLiveChat', onBXliveChat);
    if (window.BX?.SiteButton) {
      window.BX.SiteButton.show()
      return hideBXbutton
    }
    const scriptEl = document.createElement('script');
    scriptEl.async = true;
    scriptEl.src = `https://crm.panpartner.ru/upload/crm/site_button/loader_3_nab83l.js?${(Date.now() / 60000 | 0)}`;
    const scriptNode = document.getElementsByTagName('script')[0];
    scriptNode.parentNode.insertBefore(scriptEl, scriptNode);
    return hideBXbutton
  }, [])

  return (
  // <a href="https://tlgg.ru/panpartner" className="hp__question" target={`_blank`}>
  //   <div className="hp__question-content">
  //     <b>Задайте вопрос в чате</b>
  //     <span>Наши специалисты на связи</span>
  //   </div>

  //   <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <circle cx="44.0002" cy="43.8073" r="35" transform="rotate(-17.2565 44.0002 43.8073)" fill="white"/>
  //     <circle cx="44" cy="43.8073" r="27" transform="rotate(-17.2565 44 43.8073)" fill="#FF2D23"/>
  //     <rect x="29.8704" y="38.7722" width="25" height="18" rx="2" transform="rotate(-17.2565 29.8704 38.7722)" fill="url(#paint0_linear_689_6564)"/>
  //     <path opacity="0.3" d="M44.3214 45.4661L31.6117 41.4737C30.9856 41.277 30.4308 40.7803 30.4788 40.1258C30.5694 38.8904 31.621 38.2284 32.2578 38.0306L50.8801 32.2459C52.8719 31.6272 53.8486 32.2921 54.2537 33.021C54.4928 33.4511 54.3272 33.9639 54.0389 34.3626L46.5414 44.73C46.0369 45.4276 45.1427 45.7241 44.3214 45.4661Z" fill="#8F95AC"/>
  //     <defs>
  //     <linearGradient id="paint0_linear_689_6564" x1="42.3704" y1="38.7722" x2="40.6643" y2="56.609" gradientUnits="userSpaceOnUse">
  //     <stop offset="0.0239521" stopColor="#FCFCFF"/>
  //     <stop offset="1" stopColor="#F1F1F8"/>
  //     </linearGradient>
  //     </defs>
  //   </svg>

  // </a>
  <></>
  )
}

export default Question;