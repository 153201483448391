import React from 'react';
import { Link } from 'react-router-dom';
import ym from "react-yandex-metrika";

const BannerItem = (props) => {
  const { title, icon, color, desc, link, modal, showModal, external, isNew, metrikaGoal } = props;

  const style = color ? { backgroundColor: color } : {};

  const content = () => (
    <div className="banner-card" style={style}>

      {icon && (
        <div className="banner-card__icon">
          <svg className="icon">
            <use xlinkHref={icon}></use>
          </svg>
        </div>
      )}

      <div className="banner-card__content">
        <div className={`h4 banner-card__name${isNew ? ` banner-card__name_new` : ``}`}>{title}</div>
        <div
          className="banner-card__description">
          {desc}
          <span className="banner-card__external-arrow">
            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" className="icon">
              <path d="M1 9.74302L9.007 1.73602" stroke="#FF2D23"/>
              <path d="M3.7793 1.33435H9.4493V7.00335" stroke="#FF2D23" strokeWidth="0.968"/>
            </svg>
          </span>
        </div>

      </div>
    </div>
  );

  return (
    <div className="banners__grid-col">
      {modal ? (
        <div onClick={() => showModal(modal)}>{content()}</div>
      ) : external ? (
        <a onClick={() => ym('reachGoal', metrikaGoal)} href={link} target="_blank" rel="noopener noreferrer">
          {content()}
        </a>
      ) : (
        <Link onClick={() => ym('reachGoal', metrikaGoal)} to={link}>{content()}</Link>
      )}
    </div>
  );
};

const Banners = (props) => {
  const { items, showModal } = props;

  // const [showAutopan, hideAutopan] = useModal(() => (
  //   <Modal>

  //   </Modal>
  // ))

  return (
    <section className="section banners">
      <div className="wrapper">
        <div className="section__grid">
          <div className="banners__grid">
            {items.map((item) => (
              <BannerItem {...item} key={item.id} showModal={showModal} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banners;
