import React, { memo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchMyObjects
} from "../../actions/myObjects";
import EmptyTable from "../core/EmptyTable";
import Preloader from "../Loaders/FilterTableLoader";
import Items from "./Items";

const MyObjects = memo(({ isFetching, remove, data, sendToManager, user }) => {
  const dispatch = useDispatch()
  const fetchItems = () => dispatch(fetchMyObjects(user.id))

  useEffect(() => { fetchItems() }, []);


  if (isFetching) return <Preloader />;

  return (
    <section class="section section_view_white orders no-header-profile">
      <div class="wrapper">
        <div class="clients__header">
          <div class="clients__title">
            <div class="h1">Мои объекты</div>
          </div>
          <div className="clients__sell-btns">
            {/* <a href={`/sell/secondary`} className="clients__sell-btn" target="_blank" rel="noopener noreferrer">
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M10.0728 0.00637817L0.896973 7.93862V21.1829H19.25V7.93862L10.0728 0.00637817Z" fill="#8F95AC" />
                <path d="M12.6144 7.76453C11.5525 7.76453 10.5755 8.34703 10.1083 9.28801C9.64107 8.34703 8.62164 7.76453 7.55973 7.76453C5.86068 7.76453 3.60944 9.19839 4.62887 13.1415C5.81821 16.995 10.0658 19.2354 10.0658 19.2354C10.0658 19.2354 14.3135 16.995 15.5028 13.1415C16.5647 9.19839 14.271 7.76453 12.6144 7.76453Z" fill="white" />
                <path d="M10.0815 17.4991C9.13829 16.8428 6.95008 15.2022 6.27099 12.9052C5.96916 11.7157 6.04462 10.8134 6.45962 10.1981C6.79917 9.70591 7.40282 9.50082 7.85555 9.50082C8.42147 9.50082 8.94965 9.82896 9.13829 10.2801L10.0815 12.29L11.0247 10.2801C11.251 9.78794 11.7792 9.50082 12.3074 9.50082C12.7602 9.50082 13.3261 9.70591 13.7033 10.1981C14.1184 10.7723 14.1561 11.7157 13.892 12.9052C13.2129 15.2022 11.0624 16.8428 10.0815 17.4991Z" fill="#8F95AC" />
              </svg>
              <span>Продать вторичку</span>
            </a> */}
            <a href={`/sell/assignment`} className="clients__sell-btn" target="_blank" rel="noopener noreferrer">
              <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M0.936318 16.4287L7.06284 12.3562C7.06284 12.3562 8.47461 11.2757 10.5523 11.5528L15.64 12.7163C15.64 12.7163 16.6788 13.1319 16.3059 14.1847C16.0129 14.9881 14.8941 14.7388 14.8941 14.7388L11.4313 14.0739C11.4313 14.0739 10.9252 13.963 10.8187 14.434C10.7388 14.8773 11.0318 15.0158 11.4313 15.0989C11.7776 15.182 16.8386 16.0686 16.8386 16.0686C16.8386 16.0686 17.7443 16.2625 18.5701 15.9578C19.3692 15.653 23.3115 14.0185 23.3115 14.0185C23.3115 14.0185 24.1106 13.7691 24.4835 14.5725C24.8564 15.376 23.9774 15.9301 23.9774 15.9301L17.9041 19.31C17.9041 19.31 17.6111 19.5039 17.105 19.5039L7.40913 19.1438C7.40913 19.1438 6.63665 19.0884 6.15718 19.5039C5.67772 19.9195 4.34586 20.9169 4.34586 20.9169C4.34586 20.9169 3.91967 21.1108 3.52011 20.9169C3.09392 20.7229 0.776496 17.3153 0.776496 17.3153C0.776496 17.3153 0.350302 16.8443 0.936318 16.4287Z" fill="#8F95AC" />
                <path d="M9.93922 9.94591C10.2056 9.94591 10.4986 9.97361 10.765 10.0013H10.8449L16.119 11.1926L16.2256 11.248C16.412 11.3311 17.3177 11.719 17.7439 12.7164C17.9303 13.1319 18.0635 13.7691 17.8238 14.5449C17.9303 14.5449 18.0369 14.5171 18.1168 14.4894C18.5163 14.3232 19.6884 13.8522 20.7539 13.409V4.43272L14.7605 0L8.79382 4.43272V10.029C9.14011 9.97361 9.51302 9.94591 9.93922 9.94591Z" fill="#8F95AC" />
              </svg>
              <span>Продать переуступку</span>
            </a>
          </div>
        </div>
        {data.length > 0 ? <Items
          items={data}
          handleDelete={remove}
          handleSend={sendToManager}
          fetchItems={fetchItems}
        /> : <EmptyTable title="Вы не добавили ни одного объекта" />}
      </div>
    </section>
  );
});


const mapStateToProps = (state) => ({
  isFetching: state.myObjects.isFetching,
  error: state.myObjects.error,
  data: state.myObjects.data,
  items: state.myObjects.data,
  user: state.user.info
});

export default connect(mapStateToProps)(MyObjects);
