import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import Preloader from '../Preloader';
import './index.scss';

// new
import DetailHeader from '../Public/markup/detail/DetailHeader';
import Slider from '../Public/markup/detail/Slider';
import PromoBar from '../Public/markup/detail/PromoBar';
import {applyToFormData, getYoutubeId, scrollToRef} from '../../utils';
import InfoCard from '../Public/markup/detail/InfoCard';

import About from '../Public/markup/detail/About';
import Help from '../Public/markup/detail/Help';
import PromoList from '../Public/markup/detail/PromoList';
import Furnish from '../Public/markup/detail/Furnish';
import VideoList from '../Public/markup/detail/VideoList';
import ContactsModal from '../Public/markup/detail/ContactsModal';

import Button from '../core/Button';
import Iframe from '../Public/markup/detail/Iframe';
import KeysList from '../Public/markup/detail/KeysList';

import PanModal from '../core/PanModal';
import { useModal } from 'react-modal-hook';
import useFilter from '../../hooks/useFilter';
import useDocTitle from '../../hooks/useDocTitle';
import { PhotoPopup } from '../Viewer/PhotoSlider';
import Files from '../Public/markup/detail/Files';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import { ListIcon, TileIcon } from '../core/Tabs/icons';
import DownloadApp from '../core/DownloadApp';
import useDownloadApp from '../../hooks/useDownloadApp';
import CardsList from '../Public/markup/CardsList';
import VillageCardContainer from '../Public/markup/Containers/VillageCardContainer';
import { getSuburbanFilterConfig } from './Utils';
import SuburbanContentNew from './SuburbanContentNew';
import SuburbanContentContainerNew from './SuburbanContentContainerNew';
import {
  SuburbanAboutMainIcon, SuburbanBuilderStarIcon,
} from "../Public/markup/icons";
import {Navigation} from "swiper";
// import {SwiperSlide, Swiper} from "swiper/swiper-react";
import {getUrl} from "../../api";
import {Swiper, SwiperSlide} from "swiper/react";
import VillageCardContainerNew from "../Public/markup/Containers/VillageCardContainerNew";
import SwiperSlider from "../core/SwiperSlider";
import VillagesHouseFilter from "../Public/markup/VillagesHouseFilter";
import SuburbanLandCard from '../Public/markup/Containers/SuburbanLandCard';
import SuburbanHouseCard from '../Public/markup/Containers/SuburbanHouseCard';
import usePermission from "../../hooks/usePermission";
import FilterDisabled from '../Public/FilterDisabled';
import VideoWithPreview, {VideoThumbnail} from "../VideoVkRutube";
import VideoEmbed from "../VideoVkRutube";
import { getURLparam } from '../../utils/location';
import aeroStub from "../../assets/images/suburbanPanoramaStub.jpg";

const typeTabs = [{ title: 'Планировки и цены' }, { title: 'Паркинг' }];
const viewTabs = [
  { title: 'Список', icon: ListIcon },
  { title: 'Плитка', icon: TileIcon },
  // { title: 'Шахматка', icon: ChessIcon },
];
const apiConfig = {
  component: 'panpartner:new.suburbans.ajax',
  submitFunction: 'getVillage',
  loadSubItems: 'getSuburbans',
}

const SuburbanNew = memo((props) => {
  const { villageId, city = "spb", savedFilterXmlId } = useParams();

  const isDesktop = useMedia("isDesktop");
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');

  const actionsRef = useRef(null);
  const filesRef = useRef(null);
  const aboutBlockRef = useRef(null);
  const filesBlockRef = useRef(null);
  const [showGp, setShowGp] = useState(false);

  const searchHashParams = new URLSearchParams(window.location.hash);

  useEffect(() => {
    const id = searchHashParams.get('id');
    if (!id) return;
    const getRowLink = () => {
      if (searchHashParams.get('objectType') === 'assignment') {
        return `/assignments/${id}${window.location.search}`;
      }
      return `/realty/${city}/apartment/${id}${window.location.search}`;
    };
    window.open(getRowLink(), '_self');
  }, []);

  const filterFromLS = useMemo(() => JSON.parse(localStorage.getItem('suburbansFilter'))?.[villageId], [])

  const [downloadApp, setDownloadApp] = useDownloadApp();
  let newFavorite = 'cottageSettlement'
  const contentProps = useFilter(getSuburbanFilterConfig({
    newFavorite,
    city,
    villageId,
    savedFilterXmlId,
    cottageSettlement: villageId,
    component: 'panpartner:new.suburbans.ajax',
    newParams: {
      processData: (resp, prev) => {
        if (prev.filter) delete resp.filter;
        return {
          ...prev,
          ...resp,
           fullDescription: resp.village?.description ? resp.village?.description?.split('<br>')[0]: prev.fullDescription
          };
      },
      apiConfig,
      configMutation: (resp, config)=>{
        if(!resp?.tabs?.[0]?.method) return
        config.defaultFilter = {...config.defaultFilter}
        config.apiConfig.submitFunction = resp.tabs?.[0]?.method ?? config.apiConfig.loadSubItems;
        config.defaultParams[`filter[cottageSettlement]`] = resp.village?.id
        config.defaultParams[`filter[type]`] = resp.tabs?.[0]?.typeId

      },
      defaultLoadCondition: true,
      filterFromLS: !savedFilterXmlId &&  filterFromLS && { filter: filterFromLS },
      restoreSF: !!savedFilterXmlId,
      sortEnabled: true,
      shadowFilters: ['villageId', 'status', "cottageSettlement", "type", "realtyType"] // см ниже
    },
  }));

  const {
    isLoading,
    filter = {},
    data: {
      village: block = {},
      actionInfo,
      filter: filterData = {},
      villageInfo,
      otherVillages,
      managerInfo,
      blockVideos,
      contacts,
      commission,
      schedule,
      priceInfo,
      filesInfo,
      fixationLink,
      limit,
      tabs,
      village,
      distanceToLocations,
      isSignUpToView,
      fullDescription,
      agent,
      isActive
    },
    showSkeleton,
    favoritesProps,
    createBadges,
    get
  } = contentProps;

  useEffect(() => {
    const urlRealtyType = getURLparam("realtyType");
    const firstOfType = urlRealtyType && urlRealtyType !== "unset" ? urlRealtyType : tabs?.[0]?.typeId;
    if (!firstOfType || !tabs || tabs?.length === 1) return;
    setActiveType(tabs.findIndex(tab => tab.typeId === firstOfType));
    if(filterFromLS?.type) delete filterFromLS.type;
  }, [filterFromLS, tabs])

  useEffect(() => () => localStorage.removeItem('suburbansFilter'), []);
  useDocTitle(`${block.name ? `${block.name}` : ''}`);

  const [activeType, setActiveType] = useState(0);
  const filterBadges = createBadges(filter, ['villageId', 'status', "cottageSettlement", "type", "realtyType"],  {}, { [tabs?.[0]?.searchFilter]: tabs?.[0]?.searchPlaceholder })

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          managerId={block?.manager?.id}
          params={{
            type: "village",
            objectId: villageId,
            city
          }}
        />
      </PanModal>
    ),
    [block],
  );

  const [complexView, setComplexView] = useState('photos')
  const showMotivation = usePermission('motivation');

  function convertToEmbedUrl(url) {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    return `https://www.youtube.com/embed/${videoId}`;
  }

  function getThumbnailUrl(youtubeUrl) {
    const urlObj = new URL(youtubeUrl);
    const videoId = urlObj.searchParams.get("v");
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  const [isLessDescription, setIsLessDescription] = useState(true)





  if (isLoading) return <Preloader loading />;

  const scrollToActions = () =>
    scrollToRef(actionsRef, -aboutBlockRef.current?.offsetTop);


  const scrollToFiles = () => {
    if (filesBlockRef.current) {
      const rect = filesBlockRef.current.getBoundingClientRect();
      const offset = window.scrollY + rect.top;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  if (!isLoading && !!savedFilterXmlId && !isActive) return <FilterDisabled agent={agent} />;

  return (
    <>
      <DownloadApp
        downloadApp={downloadApp}
        setDownloadApp={setDownloadApp}
        noCloser
      />

      {!downloadApp && (
        <div class="sf wrapper">
          {block && (
            <DetailHeader
              title={`Коттеджный поселок «${block.name}»`}
              description={block.address}
              subways={distanceToLocations}
              commission={commission}
              isCustomerFixation={block.builderVillage?.isCustomerFixation}
              coordinates={block.coordinates}
              isSignUpToView={isSignUpToView}
              onFixationClick={() => window.open(fixationLink)}
              newSuburban
              {...favoritesProps}
              sf={!!savedFilterXmlId}
            />
          )}
          <div className="sf__detail-row">
            <div className="sf__detail-col complex-custom-view">
              {!isTablet && complexView == 'photos' && (
                <div className="complex-custom-view__main-container">
                  <Slider
                    items={village?.photo}
                    defoultThumbs
                  />
                </div>
              )}


              {!isTablet && Object.keys(block?.genPlan).length > 0 && complexView == 'genplan' && (
                <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                  <Slider
                    items={[block.genPlan]}
                    defoultThumbs
                  />
                </div>
              )}

              {!isTablet && block?.decorationPhoto.length > 0  && complexView == 'furnish' && (
                <div className="complex-custom-view__main-container">
                  <Slider
                    items={block?.decorationPhoto}
                    defoultThumbs
                  />
                </div>
              )}

              {!isTablet && !!block?.aeroPanorama && complexView == 'panorama' && (
                <div className="complex-custom-view__main-container">
                  <section className="sf__section">
                    <Iframe title="Аэропанорама" src={`${block?.aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`} />
                  </section>
                </div>
              )}

              {!isTablet && block?.videoLink.length > 0 && complexView == 'video' && (
                <div className="complex-custom-view__main-container">

                  <section className="complex-custom-view__video-slider" style={{borderRadius: 20, overflow: 'hidden'}}>
                    <VideoEmbed url={block?.videoLink[0]} />
                  </section>
                </div>
              )}

              <ul className="complex-view-list complex-view-list_suburban">
                <Swiper
                  spaceBetween={15}
                  slidesPerView={isDesktop ? 4 : 5}
                  modules={[Navigation]}
                  // navigation={isDesktop ? numberOfSlides > 5 : numberOfSlides > 4}
                >
                  <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => {setComplexView('photos')}}>
                      <img src={getUrl(village?.photo?.[0]?.src)} alt=""/>
                      <span>Фотографии</span>
                    </li>
                  </SwiperSlide>

                  {  Object.keys(block?.genPlan).length > 0 &&
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'genplan' ? 'active' : ''}`} onClick={() => {setComplexView('genplan')}}>
                        <img src={getUrl(block.genPlan?.src)} alt=""/>
                        <span>Ген. план</span>
                      </li> </SwiperSlide>
                  }

                  {  block?.decorationPhoto.length > 0 &&
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'furnish' ? 'active' : ''}`} onClick={() => {setComplexView('furnish')}}>
                        <img src={getUrl(block.decorationPhoto?.[0]?.src)} alt=""/>
                        <span>Отделка</span>
                      </li> </SwiperSlide>
                  }

                  {!!block?.aeroPanorama && <SwiperSlide>
                    <li className={`complex-view-list__item ${complexView == 'panorama' ? 'active' : ''}`} onClick={() => {setComplexView('panorama')}}>
                      <img src={aeroStub} alt=""/>
                      <span>Аэропанорама</span>
                    </li> </SwiperSlide>}

                  {  block?.videoLink.length > 0 &&
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'video' ? 'active' : ''}`} onClick={() => {setComplexView('video')}}>
                        <img src={getUrl(village?.photo?.[1]?.src)} alt=""/>
                        {/*<VideoThumbnail url={getUrl(village?.photo?.[1]?.src)} />*/}
                        <span>Видео</span>
                      </li> </SwiperSlide>
                  }
                </Swiper>
              </ul>


              {actionInfo && (
                <PromoBar
                  title={actionInfo.title}
                  activeTo={actionInfo.actionPeriod}
                  count={actionInfo.moreActions?.length}
                  managerInfo={managerInfo}
                  onClick={scrollToActions}
                  id={actionInfo.id}
                />
              )}
            </div>
            <div className="sf__detail-col">
              {!isTablet && <InfoCard items={showMotivation ? villageInfo : villageInfo.slice(1)} cols={priceInfo} />}
              <section className="sf__section">
                <Button buttonType="primary" isFull onClick={openContactsModal}>
                  Контакты
                </Button>
                {filesInfo?.length > 0 && (
                  <Button buttonType="line" isFull onClick={scrollToFiles}>
                    Файлы
                  </Button>
                )}
              </section>
            </div>
          </div>

          <div className="sf__detail-row">
            <div className="sf__detail-col">
              <div className="about-redesign">
                <div className="about-redesign__top">
                  <div className="about-redesign__top-icon">
                    <SuburbanAboutMainIcon/>
                  </div>

                  <div className="about-redesign__top-content">
                    <p className="about-redesign__top-title">
                      {`О ${block ? block.name : ''}`}
                    </p>
                    <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescription ? block.description : fullDescription}}></p>
                    {block?.description?.includes?.('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescription(prev=>!prev)}>{!isLessDescription ?  'Свернуть' : 'Подробнее'}</span>}

                    <div className="about-redesign__overlist">
                      <ul className="about-redesign__list">
                        {
                          block?.advantages.map((i) => (
                            <li className="about-redesign__list-item">
                              <div className="about-redesign__list-icon">
                                {/*<SuburbanListParkIcon/>*/}
                                <img src={getUrl(i.icon)} alt=""/>
                              </div>
                              <div className="about-redesign__list-text">{i.description}</div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div className="sf__detail-row">
            <div className="sf__detail-col">
              <div className="communications-redesign">
                <p className="communications-redesign__title">Коммуникации и удобства</p>

                <div className="communications-redesign__list">
                  <ul className="about-redesign__list">

                    {
                      block?.communicationType.map((i) => (
                        <li className="about-redesign__list-item">
                          <div className="about-redesign__list-icon">
                            {/*<SuburbanListParkIcon/>*/}
                            <img src={getUrl(i.icon)} alt=""/>
                          </div>
                          <div className="about-redesign__list-text">{i.title}</div>
                        </li>
                      ))
                    }
                  </ul>
                </div>

              </div>
            </div>
          </div>

          {!!tabs?.length && <div className="sf__detail-row sf__detail-row_full">

            <StatelessTabs
              tabs={tabs}
              activeTab={activeType}
              setActiveTab={setActiveType}
              largeBtn
            />
            {activeType === 0 && <SuburbanContentNew
              {...contentProps}
              tab={tabs?.[0]}
              filterBadges={filterBadges}
              villageId={villageId}
              city={city}
              card={SuburbanLandCard}
              externalSorting={({sorter})=>contentProps.handleSort(null, {value: sorter?.field, by: sorter?.order?.replace?.("end", "")})} //desc end
            />}

          </div>}
          {tabs.map(({ method, ...tab }, i) => i === 0 ? null : <SuburbanContentContainerNew
            method={method}
            isActive={activeType === i}
            tab={tab}
            key={i}
            card={SuburbanHouseCard}
          />)}
          <div className="sf__detail-row" ref={aboutBlockRef}>
            <div className="sf__detail-col">
              {(block?.description ||
                block?.advantages ||
                block?.audioReview) && (
                  <>
                    <div className="sf__detail-row">
                      <div className="sf__detail-col">
                        <div className="about-redesign">
                          <div className="about-redesign__top">
                            <div className="about-redesign__top-icon">
                              <SuburbanBuilderStarIcon/>
                            </div>
                              <div className="about-redesign__top-content">
                                <p className="about-redesign__top-title">
                                  {block?.builderVillage?.title}
                                </p>
                                <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: block?.builderVillage?.description }}></p>

                                {filesInfo?.length > 0 && (
                                  <div ref={filesBlockRef}>
                                    <Files innerRef={filesRef} customTitle=" " items={filesInfo} />
                                  </div>
                                )}

                              </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </>
                )}

              {isLaptop && <Help openContactsModal={openContactsModal} />}
            </div>
            {!isLaptop && (
              <div className="sf__detail-col sf__sticky">
                <Help openContactsModal={openContactsModal} />
              </div>
            )}
          </div>

          {otherVillages.length > 0 && (
            <div className="sf__detail-row sf__detail-row_full">
              <h3 className="h3 tab-title">Другие проекты застройщика</h3>
              <CardsList
                items={otherVillages}
                getLink={(item) => `/suburban/${city}/${item.id}`}
                isLoading={showSkeleton}
                limit={limit}
                cardComponent={VillageCardContainerNew}
                {...favoritesProps}
                get={get}
                favoritesPath={i => `otherVillages[${i}].favorite`}
                isPublic={!!savedFilterXmlId}
              />
            </div>
          )}

          <Preloader loading={isLoading} />
          <PhotoPopup
            open={showGp}
            items={block?.village?.genPlan}
            index={0}
            onClose={() => setShowGp(false)}
          />
        </div>
      )}
    </>
  );
});

export default SuburbanNew;
