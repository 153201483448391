import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from "react-content-loader";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import { clearChess, fetch } from '../../actions/chess';
import useMedia from '../../hooks/useMedia';
import { formatMoney } from '../../utils';
import { filterToParam } from '../Complex/Utils';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import Preloader from '../Preloader';
import ChessFilter from './ChessFilter';
import { fakeData } from './fakeData';
import handleDrag from './handleDrag';
const Skeleton = (props) => (
  <ContentLoader
    speed={0.5}
    width={165}
    height={100}
    viewBox="0 0 165 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="165" height="100" />
  </ContentLoader>
)


const Cell = (props) => {
  const { color, objectType, city, newId, flatType, number, costWithDiscount, decoration, squareTotal, id, roomType } = props;
  const status = useMemo(() => {
    switch (color) {
      case "blue":
        return "assignment"
      case 'red':
        return 'reserved';
      case 'gray':
        return 'sold';
      default:
        return 'sale';
    }
  }, [color]);

  const getUrl = () => {
    if (objectType === "assignment") {
      return `/assignments/${id}`
    }
    return `/realty/${city}/apartment/${id}`
  }
  return (
    <a
      href={getUrl()}
      rel="noopener noreferrer"
      target={'_blank'}
    >
      <div className={`chessboard-flat ${status}`} style={{ color: '#1e2030' }}>
        <div className="chessboard-flat__head">
          <div className="chessboard-flat__name">
            <span>{roomType.name}</span>
          </div>
          <div className="chessboard-flat__number">
            <span>№ {number}</span>
          </div>
        </div>
        <div className="chessboard-flat__price">
          <span>{formatMoney(costWithDiscount)} р.</span>
        </div>
        <div className="chessboard-flat__status">
          <span>{status === "assignment" ? flatType.name : props.status.name}</span>
        </div>
        <div className="chessboard-flat__footer">
          <div className="chessboard-flat__furnish">
            <span>{decoration.name}</span>
          </div>
          <div className="chessboard-flat__sqr">
            <span>
              {squareTotal} м<sup>2</sup>
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

const Ending = (props) => {
  const { title, count, index, activeIndex, onClick, isMobile, } = props;
  if(!count) return null
  const getCount = () => {
    let word = 'квартиры';

    switch (Number(count)) {
      case 1:
        word = 'квартира';
        break;
      case 2:
      case 3:
      case 4:
        word = 'квартиры';
        break;
      default:
        word = 'квартир';
        break;
    }

    return `${count} ${word}`;
  };

  const classes = classNames({
    'complex__tabs-item': !isMobile,
    'complex__tabs-value': isMobile,
    active: index === activeIndex,
  });

  let intViewportWidth = window.innerWidth;

  return (
    <div
      className={isMobile ? 'complex__tabs-item' : ''}
      style={{
        width: isMobile ? intViewportWidth : "auto",
        alignItems: isMobile ? "center" : "flex-start",
      }}
    >
      <div className={classes} onClick={() => onClick()}>
        <div className="complex__tabs-value">{title}</div>
        <div className="complex__tabs-desc">Всего {getCount()}</div>
      </div>
    </div>
  );
};

const Chess = (props) => {
  const { id, city } = useParams();
  const isMobile = useMedia("isMobile")
  const {
    fetch,
    reset,
    isFetching,
    data,
    sections,
    filterData,
    filter,
    error,
    setFilter,
    clearFilter,
  } = props;

  const [activeSection, setActiveSection] = useState(0);
  const [index, setIndex] = useState(0);
  const node = useRef(null);

  const settings = {
    items: 1,
    controls: false,
    nav: false,
    loop: false,
    responsive: {
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1240: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    const params = {
      "filter[blockId]": id,
      city,
      isAssignment: true,
      isGetFilter: true,
    }
    fetch(params).then(() => {
      setTimeout(() => {
        handleDrag();
      }, 500);
    });
    return () => {
      reset();
    };
  }, [city, id]);

  const fetchBuilding = (buildingId, index, param = {}) => {
    const params = {
      "filter[blockId]": id,
      city,
      "filter[buildingId]": buildingId,
      isAssignment: true,
      ...param
    }
    fetch(params).then(() => {
      setTimeout(() => {
        handleDrag();
      }, 500);
      setIndex(index);
    });
  };

  const items = data.buildings.map((item, i) => (
    {
      index: i,
      id: item.id,
    }
  ))

  const [showMobileFilter, setShowMobileFilter] = useState(false)

  const [sliderIndex, setSliderIndex] = useState(0)

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }

    if (way === 'prev' && isMobile) {
      if ((sliderIndex - 1) >= 0) {
        setSliderIndex(sliderIndex - 1);
        fetchBuilding(items[sliderIndex - 1].id, sliderIndex - 1)
      }
    } else if (way === 'next' && isMobile) {
      if ((sliderIndex + 1) < items.length) {
        setSliderIndex(sliderIndex + 1);
        fetchBuilding(items[sliderIndex + 1].id, sliderIndex + 1)
      }
    }
  };

  const handleClick = (e, index) => {
    setActiveSection(index)

    const nextBounding = e.target.nextElementSibling?.getBoundingClientRect()
    const prevBounding = e.target.previousElementSibling?.getBoundingClientRect()

    const nextElementIsOutside = nextBounding && (nextBounding.right > (window.innerWidth || document.documentElement.clientWidth))
    const prevElementIsOutside = prevBounding && (prevBounding.left < 0)

    if (nextElementIsOutside) {
      e.target.nextElementSibling.scrollIntoView(false, { behavior: 'smooth' })
    } else if (prevElementIsOutside) {
      e.target.previousElementSibling.scrollIntoView(false, { behavior: 'smooth' })
    }
  }


  if (isFetching && data.buildings.length === 0) return <Preloader loading={true} />;
  if (error) return 'Error';

  const fake = fakeData()
  const filteredBuildings = data.buildings.filter(item=>!!item.count)

  return (
    <section className="section section_view_white chess">
       {isMobile &&
         <>
           <button class="button-mobile for-chess" onClick={() => setShowMobileFilter(true)}>Фильтры</button>

           {showMobileFilter &&
            <ChessFilter
              filter={filter}
              filterData={filterData}
              setFilter={setFilter}
              city={city}
              id={id}
              fetch={params=> fetchBuilding(null, 0, params)}
              clearFilter={clearFilter}
              closeClick={() => setShowMobileFilter(false)}
            />
          }
        </>
       }
       {!isMobile && <ChessFilter
          filter={filter}
          filterData={filterData}
          setFilter={setFilter}
          city={city}
          id={id}
          fetch={params=> fetchBuilding(null, 0, params)}
          clearFilter={clearFilter}
        />}
      <div className="chess__tabs" style={{ flexGrow: 1 }}>
        <div className="chess__tabs-nav">
          <ErrorPlug wrappedComponent="src/comp-ts/Chess/index.jsx">
            <div className="complex__appartments-navigation">
              {data.buildings && <div className="complex__tabs">
                <TinySlider settings={settings} ref={node} startIndex={index} key={filteredBuildings.length}>
                  {filteredBuildings.map((item, i) => (
                    <Ending
                      {...item}
                      index={i}
                      activeIndex={index}
                      key={item.xmlId}
                      onClick={() => fetchBuilding(item.id, i, filterToParam(filter))}
                      isMobile={isMobile}
                    />
                  ))}
                </TinySlider>
              </div>}
              {data.buildings.length > 1 && <div className="complex__move-nav">
                <ul className="tabs__navs">
                  <li
                    className="tabs__navs-item prev"
                    onClick={() => moveSlider('prev')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    className="tabs__navs-item next"
                    onClick={() => moveSlider('next')}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>}
            </div>
          </ErrorPlug>
        </div>
        <div className="chess__tabs-item active">
          <div className="chess__nav-sections">
            {data.sections &&
              data.sections.map((section, index) => (
                <div
                  className={`chess__nav-section js-board-section ${index == activeSection ? 'active' : ''
                    }`}
                  // onClick={() => setActiveSection(index)}
                  onClick={(e) => handleClick(e, index)}
                  data-id={`section-${section.id}`}
                  key={section.id}
                >
                  {isMobile ? section.name : <span>{section.name}</span>}
                </div>
              ))}
          </div>
          <div
            className="chess__board js-board"
            style={{
              paddingBottom: '40px',
            }}
          >
            <div className="chess__board-aside">
              {[...Array(data.maxFloor)].map((floor, index, arr) => (
                <div className="chess__board-num" key={index}>
                  <span>{arr.length - index}</span>
                </div>
              ))}
            </div>
            <div className="chess__sections" style={{ alignSelf: 'baseline' }}>
              {data.sections && !isFetching &&
                data.sections.map((section) => (
             
                  <div
                    className="chess__section"
                    id={`section-${section.id}`}
                    key={section.id}
                  >
                    {!isMobile &&
                      <div className="chess__board-section">
                        <span className="chess__board-section_label">
                          {section.name}
                        </span>
                      </div>
                    }
                    <div className="chess__board-table">
                      {[...Array(data.maxFloor)].map((max, index, arr) => {
                        const indexFloor = arr.length - index - 1
                        return (
                          <div className="chess__board-row" key={indexFloor}>
                            {[...Array(section.count)].map(
                              (number, indexNumber) => (
                                <div
                                  className="chess__board-cell"
                                  key={`${indexFloor}${indexNumber}`}
                                >
                                  {section.apartments &&
                                    section.apartments[indexFloor + 1] &&
                                    section.apartments[indexFloor + 1][
                                    indexNumber + 1
                                    ] && (
                                      <Cell
                                        {...section.apartments[indexFloor + 1][
                                        indexNumber + 1
                                        ]}
                                        city={city}
                                      />
                                    )}
                                </div>
                              ),
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              {isFetching &&
                fake.sections.map((section) => (
                  <div
                    className="chess__section"
                    id={`section-${section.id}`}
                    key={section.id}
                  >
                    {!isMobile &&
                      <div className="chess__board-section">
                        <span className="chess__board-section_label">
                          {section.name}
                        </span>
                      </div>
                    }
                    <div className="chess__board-table">
                      {[...Array(fake.maxFloor)].map((floor, indexFloor) => {
                        return (
                          <div className="chess__board-row" key={indexFloor}>
                            {[...Array(section.count)].map(
                              (number, indexNumber) => (
                                <div
                                  className="chess__board-cell"
                                  key={`${indexFloor}${indexNumber}`}
                                >
                                  {section.apartments &&
                                    section.apartments[indexFloor + 1] &&
                                    section.apartments[indexFloor + 1][
                                    indexNumber + 1
                                    ] && (
                                      <Skeleton />
                                    )}
                                </div>
                              ),
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (params) =>
    dispatch(fetch(params)),
  reset: () => dispatch(clearChess()),
  setFilter: (filter)=> dispatch({type: "CHESS_SET_FILTER", filter}),
  clearFilter: ()=>dispatch({type: "CHESS_CLEAR_FILTER"})
});

const mapStateToProps = (state) => ({
  data: state.chess.data,
  sections: state.chess.data?.sections,
  filter: state.chess.filter,
  filterData: state.chess.filterData,
  isFetching: state.chess.isFetching,
  error: state.chess.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Chess);
