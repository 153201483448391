import { useMediaQuery } from 'beautiful-react-hooks';
import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import usePermittedPaths from '../hooks/usePermittedPaths.js';
import Mobile from '../pages/Mobile';
import Dashboard from './layout/Dashboard';
import MobileLayout from './layout/MobileLayout';
import { useSelector } from 'react-redux';


const RouteWrapper = memo(props => {
  const {
    component: Component,
    layout: Layout = Dashboard,
    hideHeader = false,
    hideFooter = false,
    isPublic = false,
    mobileVersionDisabled,
    layoutProps,
    hideLayout = false,
    setFullScreenLoading,
    addMainClass = '',
    childProps = {},
    redesign,
    ...rest
  } = props;

  const is404 = rest.path.includes("/not-found");

  const mobileScreen = useMediaQuery('(max-width: 767px)');

  const { acceptOffers, isAuthorized } = useSelector(state => state?.user || {});

  const [ignoreMobile, setIgnoreMobile] = useState(mobileScreen); // false - чтобы снова включить заглушку мобильной версии

  const { MainPage, accessDenied, DeniedComponent } = usePermittedPaths(!isPublic);

  return (
    <Route
      {...rest}
      render={(props) => {
        let Children = Component
        if (accessDenied) {
          Children = DeniedComponent
          if (props.match.path === "/") {
            Children = MainPage
          }
        }

        if (mobileVersionDisabled && mobileScreen && !ignoreMobile) {
          return (
            <MobileLayout>
              <Mobile handleDesktop={setIgnoreMobile} />
            </MobileLayout>
          );
        }
        if (!isPublic && !isAuthorized)
          return (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          );
        if (!isPublic &&
          !acceptOffers &&
          !rest.path.includes('/start')) {
          return <Redirect to="/start" />;
        }

        if (hideLayout) {
          return <Children {...props} setFullScreenLoading={setFullScreenLoading} isPublic={isPublic} />;
        }

        return (
          <>
            {ignoreMobile && mobileScreen && mobileVersionDisabled && (
              <Helmet>
                <meta name="viewport" content={'width=1280'}></meta>
              </Helmet>
            )}
            <Layout
              hideHeader={hideHeader || (is404 && !isAuthorized)}
              hideFooter={hideFooter || (is404 && !isAuthorized)}
              isPublic={isPublic}
              addMainClass={addMainClass}
              {...layoutProps}
              setFullScreenLoading={setFullScreenLoading}
              redesign={redesign}
            >
              <Children {...props} {...childProps} setFullScreenLoading={setFullScreenLoading} isPublic={isPublic} />
            </Layout>
          </>
        );
      }}
    />
  );
})

const AppRoute = memo(props => {
  const match = useRouteMatch(props.path);
  if (!match) return null;
  return <RouteWrapper {...props} key={match.params?.city}/>;
});

export default AppRoute;
