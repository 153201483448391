export const createFeature = (id, coordinates, hintContent) => ({
    geometry: {
      coordinates,
      type: "Point"
    },
    id,
    properties: {
      hintContent
    },
    type: "Feature"
  })