import dateFormat from 'dateformat';
import React, { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import useMedia from '../../../hooks/useMedia';
import Confirm from '../../core/Confirm';
import MobileConfirm from '../../core/MobileConfirm';
import MobileModal from '../../core/MobileModal';
import Modal from '../../core/Modal';
import { infoToast } from '../../toasts';
import TaskForm from './TaskForm';

dateFormat.i18n = {
  dayNames: [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  monthNames: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
  timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
};

const times = [
  {
    id: '1',
    title: 'За час до события',
    short: 'За час',
  },
  {
    id: '3',
    title: 'За 3 часа до события',
    short: 'За 3 часа',
  },
  {
    id: '24',
    title: 'За день до события',
    short: 'За день',
  },
];

const dealDateFormat = `dd.mm в HH:MM`;

const TaskItem = (props) => {
  const { task, deals, handleEdit, client, removeTask, name, lastName } = props;
  const isMobile = useMedia("isMobile")
  const defaultValues = {
    dealType: task.dealTypeId,
    id: task.id,
    dealTime: new Date(task.dealDateTime),
    dealDate: new Date(task.dealDateTime),
    clientId: client,
    comment: task.comment? task.comment:  "",
    notify: task.notifies && task.notifies.length > 0,
    notifyId:
      task.notifies && task.notifies.length > 0 ? task.notifies[0].id : 0,
    notifyActive:
      task.notifies && task.notifies.length > 0
        ? task.notifies[0].active
        : false,
    notifyHours:
      task.notifies && task.notifies.length > 0
        ? task.notifies[0].countHours
        : 1,
  };

  const [openTask, closeTask] = useModal(
    () => (
      <Modal close={closeTask}>
        <TaskForm
          edit={true}
          deals={deals}
          close={closeTask}
          handleEdit={handleEdit}
          defaultValues={defaultValues}
          client={client}
          removeTask={removeTask}
        />
      </Modal>
    ),
    [deals, defaultValues, client],
  );

  const [openMobileTask, closeMobileTask] = useModal(
    () => (
      <MobileModal className="for-clients" closeClick={closeMobileTask} title="Редактировать задачу">
        <TaskForm
          edit={true}
          deals={deals}
          close={closeMobileTask}
          handleEdit={handleEdit}
          defaultValues={defaultValues}
          client={client}
          removeTask={removeTask}
        />
      </MobileModal>
    ),
    [deals, defaultValues, client],
  );

  const dealTitle = useMemo(() => {
    let a = deals.find((item) => item.id == task.dealTypeId);
    return a ? a.shortTitle || a.title : '';
  }, [task, deals]);

  const notifyTitle = useMemo(() => {
    if (task.notifies.length === 0) return 'без напоминания';
    let notifyTime = times.find(
      (time) => time.id == task.notifies[0].countHours,
    );
    return notifyTime ? notifyTime.short : 'без напоминания';
  }, [task]);

  const confirm = () => {
    removeTask(task.id, client).then(() => {
      hideModal();
      infoToast(<div>Задача удалена</div>)
    });
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить задачу?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [confirmMobileModal, hideMobileModal] = useModal(({ in: open, onExited }) => (
    <MobileConfirm
      closeClick={hideMobileModal}
      title={`Вы действительно хотите удалить задачу?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [openTaskModal, closeTaskModal] = useModal(({ in: open, onExited }) => (
    <MobileModal closeClick={closeTaskModal} title={`Клиент ${lastName} ${name?.[0]? name[0]: ""}.`}>
      <div className="task-info">
        <div className="task-info__item is-gray">
          <div className="task-info__title">
            Задача
          </div>
          <div className="task-info__content">
            {dealTitle}
          </div>
        </div>

        <div className="task-info__item">
          <div className="task-info__title">
            Дата
          </div>
          <div className="task-info__content">
            {dateFormat(task.dealDateTime, `dd mmmm yyyy`)}
          </div>
        </div>

        <div className="task-info__item">
          <div className="task-info__title">
            Время
          </div>
          <div className="task-info__content">
            {dateFormat(task.dealDateTime, `HH:MM`, "isoTime")}
          </div>
        </div>

        <div className="task-info__item">
          <div className="task-info__title">
            Напоминание
          </div>
          <div className="task-info__content">
            <div className="task-info__icon">
              <svg className="icon icon_alert-bell">
                <use xlinkHref="#alert-bell"></use>
              </svg>
            </div>
            {notifyTitle}
          </div>
        </div>

        <div className="task-info__item">
          <div className="task-info__title">
            Комментарий
          </div>
          <div className="task-info__content">
            {task.comment}
          </div>
        </div>

        <div className="clients__info-block clients__info-actions">
          <button
            className="button button_type_edit-small js-modal-btn"
            data-id="task-edit"
            onClick={() => {
                closeTaskModal()
                openMobileTask()
              }
            }
          >
            <svg className="icon icon_edit">
              <use xlinkHref="#edit"></use>
            </svg>
            <span>Редактировать</span>
          </button>
          <button
            className="button button_type_edit-small js-modal-btn"
            data-id="task-edit"
            onClick={confirmMobileModal}
          >
            <svg className="icon icon_delete">
              <use xlinkHref="#delete"></use>
            </svg>
            <span>Завершить</span>
          </button>
        </div>
      </div>
    </MobileModal>
  ));

  return (
    <div className="button button_type_task-assigned">
      <div className="icon-block info">
        <svg className="icon icon_alert-bell" onClick={openTaskModal}>
          <use xlinkHref="#alert-bell"></use>
        </svg>
        {!isMobile &&
          <div className="info__popup info__popup-clients">
            <div className="clients__info-content">
              <div className="clients__info-block">
                <div className="clients__info-label">{dealTitle}</div>
                <div className="clients__info-val">
                  {dateFormat(task.dealDateTime, `dd mmmm yyyy`)}
                </div>
              </div>
              <div className="clients__info-block clients__info-block_auto">
                <div className="clients__info-label">Время</div>
                <div className="clients__info-val">

                  {dateFormat(task.dealDateTime, `HH:MM`, "isoTime")}
                </div>
              </div>
              <div className="clients__info-block clients__info-block_auto">
                <div className="clients__info-label">Напоминание</div>
                <div className="clients__info-val">{notifyTitle}</div>
              </div>
              <div className="clients__info-block">
                <div className="clients__info-label">Комментарий</div>
                <div className="clients__info-val">{task.comment}</div>
              </div>
              <div className="clients__info-block clients__info-actions">
                <button
                  className="button button_type_edit-small js-modal-btn"
                  data-id="task-edit"
                  onClick={isMobile ? openMobileTask : openTask}
                >
                  <svg className="icon icon_edit">
                    <use xlinkHref="#edit"></use>
                  </svg>
                  <span>Редактировать</span>
                </button>
                <button
                  className="button button_type_edit-small js-modal-btn"
                  data-id="task-edit"
                  onClick={confirmModal}
                >
                  <svg className="icon icon_delete">
                    <use xlinkHref="#delete"></use>
                  </svg>
                  <span>Завершить</span>
                </button>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="task">
        <span>
          {dealTitle} {dateFormat(task.dealDateTime, dealDateFormat, "isoTime")}
        </span>
      </div>
    </div>
  );
};

const Tasks = (props) => {
  const { tasks, deals, client, handleEdit, removeTask, name, lastName } = props;
  const isMobile = useMedia("isMobile")
  const defaultValues = {
    dealTime: new Date(),
    dealDate: new Date(),
    clientId: client,
    dealType: deals.length > 0 ? deals[0].id : 0,
    comment: '',
    notify: true,
    notifyId: 0,
    notifyActive: true,
    notifyHours: 1,
  };

  const [openTask, closeTask] = useModal(
    () => (
      <Modal close={closeTask}>
        <TaskForm
          edit={false}
          deals={deals}
          close={closeTask}
          handleEdit={handleEdit}
          defaultValues={defaultValues}
          client={client}
          removeTask={removeTask}
        />
      </Modal>
    ),
    [client, deals, defaultValues],
  );

  const [openMobileTask, closeMobileTask] = useModal(
    () => (
      <MobileModal className="for-clients" closeClick={closeMobileTask} title={`Клиент ${lastName} ${name[0]}.`}>
        <TaskForm
          edit={false}
          deals={deals}
          close={closeMobileTask}
          handleEdit={handleEdit}
          defaultValues={defaultValues}
          client={client}
          removeTask={removeTask}
        />
      </MobileModal>
    ),
    [client, deals, defaultValues],
  );

  return (
    <div>
      {tasks &&
        tasks.map((task, i) => (
          <TaskItem
            task={task}
            deals={deals}
            handleEdit={handleEdit}
            client={client}
            removeTask={removeTask}
            key={`task-${task.id}`}
            name={name}
            lastName={lastName}
          />
        ))}
      <button className="button button_type_task" onClick={isMobile ? openMobileTask : openTask}>
        <svg className="icon icon_plus">
          <use xlinkHref="#plus"></use>
        </svg>
        <span>Добавить</span>
      </button>
    </div>
  );
};

export default Tasks;
