import React, {memo, useEffect, useRef, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import useDocTitle from "../../hooks/useDocTitle";
import Button from "../core/Button";
import DealInfo from "../core/DealInfo";
import NewAlert from "../core/NewAlert";
import Preloader from "../Preloader";

import('./index.scss')

const VideoPage = memo(() => {
  const {id} = useParams()
  const {data: {video = {}, videoInfo, speakerInfo,}, isLoading} = useApi({
    payload: ["panpartner:video.ajax", "getVideo", {id}]
  })
  const {
    title,
    previewText,
    link,
    speaker,
    speakerPost,
    presentation,
  } = video
  useDocTitle(title)

  const iframeRef = useRef(null);

  useEffect(() => {
    const handleAutoplay = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          JSON.stringify({ type: 'player:play' }),
          '*'
        );
      }
    };
    setTimeout(handleAutoplay, 1000);
  }, []);

  const [isRutube, setIsRutube] = useState(true)

  const checkRuTubeLink = (url) => {
    return typeof url === "string" && url.includes("https://rutube");
  };

  useEffect(() => {
    if (!checkRuTubeLink(link)) {
      setIsRutube(false);
    } else {
      setIsRutube(true);
    }
  }, [isLoading, link]);


  if (isLoading) return <Preloader loading/>

  const getEmbedUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const videoId = urlObj.pathname.split('/').filter(Boolean).pop();
      const token = urlObj.searchParams.get('p');
      return `https://rutube.ru/play/embed/${videoId}?p=${token}&autoplay=1&mute=1`;
    } catch (error) {
      console.error('Некорректный URL', error);
      return null;
    }
  };
  const embedUrl = getEmbedUrl(video?.link);

  return (
    <>
      <div className="wrapper">
        <div className="common-header common-header--video">
          <div className="common-header__title">
            <NavLink
              to={'/videos'}
              className="common-header__chevron"
            >
              <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round"/>
              </svg>
            </NavLink>
            <h3 className="common-title">
              {title}
            </h3>
          </div>
        </div>
        <div className="sf">
          <div className="sf__detail-row sf__detail-row--common-page">
            <div className="sf__detail-col">
              <div className="video-frame">
                {
                  link && isRutube ?

                  <iframe
                    ref={iframeRef}
                    src={embedUrl}
                    frameBorder="0"
                    allow="autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                  /> :
                  <iframe
                  src={`https://www.youtube.com/embed/` + link + `?autoplay=1&mute=1&rel=0&showinfo=0&modestbranding=1`}
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  showinfo='0'
                  rel='0'
                  />

                }
              </div>
              <div className="video-text" dangerouslySetInnerHTML={previewText && {__html: previewText}}/>
            </div>
            <div className="sf__detail-col sf__detail-col--common">
              {
                videoInfo &&
                <DealInfo items={videoInfo}/>
              }

              {speaker &&
              <div className="speaker-info">
                <h4 className="h4">
                  {speaker} {speakerPost && ' —'}
                </h4>
                {
                  speakerPost &&
                  <p className="sf__promo-key" style={{textTransform: 'lowercase'}}>
                    {speakerPost}
                  </p>
                }
              </div>
              }
              <section className="sf__section">
                {
                  presentation &&
                  <Button isLink to={presentation.src} isDownload target="_blank" buttonType="primary_gray" isFull>
                    Скачать презентацию
                  </Button>
                }
                {
                  link &&
                  <Button buttonType="line" isFull copy={`https://www.youtube.com/watch?v=` + link}>
                    Скопировать ссылку
                  </Button>
                }
              </section>
              <NewAlert
                text="Смотрите на телефоне. Все записи вебинаров и обзоры ЖК доступны в нашем "
                link="мобильном приложении"
                bgColor="#f6f7f8"
                iconColor="#2CBA2E"
                linkColor="#2CBA2E"
                openAppModal
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default VideoPage;
