import React from 'react';

const InputField = (props) => {
    const { error, name, handleChange, fieldName, form } = props
    return (
        <div className="poll__item">
            <div className="poll__q h4">{name}</div>
            <div className="poll__a">
                <input
                    value={form[fieldName]}
                    onChange={e => handleChange(e.target.value, fieldName)}
                    type="text"
                    className="text-input text-input_full"
                    placeholder="Ваш вариант ответа" />
            </div>
        </div>
    )
}
export default InputField