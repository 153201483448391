import React from "react";
import './index.scss'
import {EnvelopeIcon} from "../Public/markup/icons";

const SmsHint = ({sucessHint, errorHint, text}) => {
  return (
    <div className={`sms-hint ${sucessHint ? 'sms-hint--sucess' : ''} ${errorHint ? 'sms-hint--error' : ''}`}>
      <EnvelopeIcon color={`${sucessHint ? '#2CBA2E' : errorHint ? '#FF2D23' : 'black'} `}/>
      {text}
    </div>
  )
}

export default SmsHint
