import React, { useEffect, useState } from "react";
import { media } from '../../utils';
import Badge from "../Badge";
import DropdownMulti from "../core/DropdownMulti";
import RadioDropdown from "../core/RadioDropdown";



const FavoritesFilter = (props) => {
  const {
    filterData,
    filter,
    currentTab,
    setFavoritesFilter,
    clearFilter,
    isFiltersOpen,
    access,
  } = props;
  const setFilter = (value, identity) => {
    if (identity === "city") {
      setFavoritesFilter({ [identity]: { id: value } });
      return;
    }
    setFavoritesFilter({ [identity]: value });
  };

  useEffect(() => {
    setFilter(props.city == "spb" ? "1" : "3", "city");
  }, []);
  const showBadges = () => {
    return (
      filter &&
      (filter.agent.length > 0 ||
        filter.city.id ||
        filter.client.length > 0 ||
        filter.roomType.length > 0)
    );
  };

  const [count, setCount] = useState(
    filter &&
    (filter.agent.length +
      (filter.city.id && 1) +
      filter.client.length +
      filter.roomType.length)
  );

  useEffect(() => {
    setCount(
      filter &&
      (filter.agent.length +
        (filter.city.id && 1) +
        filter.client.length +
        filter.roomType.length))
  }, [filter]);

  const handleRemoveFilter = (value, identity) => {
    let newArray = [...filter[identity]];
    newArray = newArray.filter((item) => item != value);
    setFavoritesFilter({ [identity]: newArray });
  };
  const clearButtonIsEnabled = () => {
    return filter && (filter.agent.length > 0 || filter.client.length > 0);
  };
  const showFieldByAccess = () => {
    return access === "master" || access === "general";
  };

  const handleClearFilter = (e) => {
		e.preventDefault();
		clearFilter();
	};

  const [isOpen, setIsOpen] = useState(false);

	const addClasses = () => {
		document.body.classList.add('no-scroll')
	}

	const removeClasses = () => {
		document.body.classList.remove('no-scroll')
	}

	const toggleFilters = () => {
		setIsOpen(!isOpen);
		!isOpen
			? addClasses()
			: removeClasses()
	}

	useEffect(() => {
		if (isFiltersOpen) setIsOpen(!isOpen);
	}, [isFiltersOpen]);
  
  const filters = (
    <>
      <div className="mini-filter__col">
        <div className="form__item">
          <RadioDropdown
            right={true}
            onChange={(a) => setFilter(a, "city")}
            isAutocomplete={false}
            items={filterData.city ? filterData.city : []}
            value={filter.city ? +filter.city.id : {}}
            alternativeTitle={"Город"}
            data={{
              title: "Город",
              identity: "city",
            }}

            isMobile={isOpen && media("isMobile")}
  					clearFilters={clearFilter}
          />
        </div>
      </div>
      {filterData.client.length > 0 && (
        <div className="mini-filter__col">
          <div className="form__item">
            <DropdownMulti
              value={filter.client}
              items={filterData.client}
              data={{ title: "Клиенты" }}
              onChange={(v) => setFilter(v, "client")}

              isMobile={isOpen && media("isMobile")}
              clearFilters={clearFilter}
            />
          </div>
        </div>
      )}
      {filterData.roomType.length > 0 && (currentTab === "apartment" || currentTab === "secondary") && (
        <div className="mini-filter__col">
          <div className="form__item">
            <DropdownMulti
              value={filter.roomType}
              items={filterData.roomType}
              data={{ title: "Комнатность" }}
              onChange={(v) => setFilter(v, "roomType")}

              isMobile={isOpen && media("isMobile")}
              clearFilters={clearFilter}
            />
          </div>
        </div>
      )}
      {filterData.agent.length > 0 && showFieldByAccess() && (
        <div className="mini-filter__col">
          <div className="form__item">
            <DropdownMulti
              value={filter.agent}
              items={filterData.agent}
              data={{ title: "Агент" }}
              onChange={(v) => setFilter(v, "agent")}

              isMobile={isOpen && media("isMobile")}
              clearFilters={clearFilter}
            />
          </div>
        </div>
      )}
      <div className="mini-filter__col mini-filter__col_clear is-hidden-sm">
        <button
          className="button button_type_clear mini-filter__button"
          type="button"
          onClick={clearFilter}
          disabled={!clearButtonIsEnabled()}
        >
          <svg className="icon icon_x">
            <use xlinkHref="#x"></use>
          </svg>
          <span>Очистить фильтр</span>
        </button>
      </div>
    </>
  )

  const badges = (
    <>
      {showBadges() && (
        <div className="index-search__filter-applied">
          <div className="index-search__filter-inc">
            {filterData.city?.length > 0 && filter.city && (
              <Badge
                item={filterData.city.find(
                  (item) => filter.city.id == item.id
                )}
                title={`Город`}
                hideCloseButton={true}
              />
            )}
            {filter.agent.map((id) => {
              let b = filterData.agent.find((item) => id == item.id);
              return (
                <Badge
                  key={`agent-${id}`}
                  identity={`agent`}
                  item={b}
                  title={`Агент`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}

            {filter.client.map((id) => {
              let b = filterData.client.find((item) => id == item.id);
              return (
                <Badge
                  key={`client-${id}`}
                  identity={`client`}
                  item={b}
                  title={`Клиент`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filterData.roomType.length > 0 && filter.roomType.length > 0 &&
              currentTab === "apartment" &&
              filter.roomType.map((type) => {
                let b = filterData.roomType.find((item) => type == item.id);
                return (
                  <Badge
                    key={`roomType-${type}`}
                    identity={`roomType`}
                    item={b}
                    title={`Комнатность`}
                    handleClick={handleRemoveFilter}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  )


  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="mini-filter for-favorites">
          <form className="form">
            {media("isMobile") ?
              <button
                className="button-mobile for-main"
                onClick={
                  (e) => {
                    e.preventDefault()
                    toggleFilters()
                  }
                }
              >
                Фильтры
                {count > 0 && ` (${count})`}
              </button>
              :

              <div className="mini-filter__grid">
                {filters}
              </div>
            }
            {!media('isMobile') &&
              <div className="mini-filter__mobile">
                <button
                  className="button button_type_clear mini-filter__button "
                  type="button"
                  onClick={clearFilter}
                  disabled={!clearButtonIsEnabled()}
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              </div>
            }
          </form>

          {isOpen && media("isMobile") &&
    				<div className="mobile-filters for-clients">
    					<div className="mobile-filters__title">
    						<svg
    							className="icon icon_arrow_up"
    							onClick={() => {
    								// props.onClick()
    								toggleFilters()
    							}}
    						>
    							<use xlinkHref="#arrow_up"></use>
    						</svg>
    						<h3>Фильтры</h3>
    					</div>
    					<div className="mobile-filters__content">
    						{filters}
    						{badges}

    						<div className="mobile-filters__show-all for-agents">
                  <button
                    className="button button_view_default"
                    type="submit"
                    onClick={(e) => {
    									e.preventDefault()
    									toggleFilters()
    								}}
                  >
                    Показать
                  </button>
    							<button
    								className="button button__mobile-filters"
    								onClick={(e) => {
    									handleClearFilter(e)
    									toggleFilters()
    								}}
    							>
    								Очистить
    							</button>
    						</div>
    					</div>
    				</div>
    			}

          {!media('isMobile') && badges}
        </div>
      </div>
    </section>
  );
};
export default FavoritesFilter;
