import { useMediaQuery } from "beautiful-react-hooks";
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ReactComponent as Build } from "../../../assets/images/constructor/tabs-icons/ico_menu_01.svg";
import { ReactComponent as Portfolio, ReactComponent as SecondarySvg } from "../../../assets/images/constructor/tabs-icons/ico_menu_03.svg";
import { ReactComponent as Assignments, ReactComponent as AssignmentsSvg } from "../../../assets/images/constructor/tabs-icons/ico_menu_04.svg";
import { ReactComponent as RentSvg } from "../../../assets/images/constructor/tabs-icons/ico_menu_05.svg";
import { ReactComponent as HouseSvg } from "../../../assets/images/constructor/tabs-icons/ico_menu_06.svg";
import { titleByType } from "../../../reducers/agency";
import { getActiveSet, getFeatures, getSetsByCity, mapItems } from '../../../selectors/agency';
import { filterToParamsConstructor, scrollToRef, sendConstructorEvent, toTranslit } from '../../../utils';
import ViewRealty from '../../Estate/ViewRealty';
import SubmitButton from '../../SubmitButton';
import { themeColors } from '../constructorUtils';
import DropdownBordered from './DropdownBordered';
import Items from './Items';









const PropertyTab = memo(({ title, isActive, setActiveSets }) => (
  <li className="property__tabs-item" onClick={setActiveSets}>
    <button type="button" className={`property__btn constructor__btn${isActive ? ` property__btn_active` : ``}`}>{title}</button>
  </li>
))

const toUrl = (view, activeSet) => {
  const viewType = view === "map" ? `&v=map` : "";
  const title = activeSet.city ? `${toTranslit(titleByType[activeSet.type])}-${activeSet.city}` : toTranslit(titleByType[activeSet.type].replace(" ", "_"));
  const search = decodeURI(window.location.search).replace(/(\?|&)filter.+/gi, "")
  const result = `filter=${title}[${toTranslit(activeSet.name)}]${viewType}`.toLowerCase()
  window.history.pushState(null, null, `${window.location.pathname}${!!search ? `${search}&` : "?"}${result}`)
}
const haveUrlFilter = () => /(\?|&)filter.+/gi.test(decodeURI(window.location.search))

const paramsBySetType = {
  secondary: {
    "filter[saleType]": "sale",
    "filter[objectType]": "secondary",
  },
  assignment: {
    "filter[saleType]": "sale",
    "filter[objectType]": "assignment",
  },
  rent: {
    "filter[saleType]": "rent",
  },
}

const Property = props => {

  const { mapItems,
    items,
    features,
    theme,
    sets,
    setActiveSets,
    getBlocks,
    activeSet,
    hasNextPage,
    isPreview,
    innerRef,
    currentSetCity,
    setTabs,
    setActiveSetType,
    openCallbackModal,
    isLoadBlocks,
    setView,
    view,
  } = props;

  const [pageCount] = useState(3);
  const [isFetching, setIsFetching] = useState(false)
  const smallScreen = useMediaQuery('(max-width: 1279px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const iconsByType = {
    apartment: <Build />,
    assignments: <Assignments />,
    commercial: <Portfolio />,
    secondary: <SecondarySvg />,
    assignment: <AssignmentsSvg />,
    rent: <RentSvg />,
    suburban: <HouseSvg />,
  }

  const [tabWidth, setTabWidth] = useState(0)
  const [tabPosition, setTabPosition] = useState(0)
  const refs = Array(setTabs.length).fill(null).map(React.createRef)
  useEffect(() => {
    if (refs[0]) {
      const activeRef = refs.find(ref => ref?.current?.classList?.[1] === "is-active")
      setTabWidth(activeRef?.current?.getBoundingClientRect()?.width)
      setTabPosition(activeRef?.current?.offsetLeft)
    }
  }, [tabWidth, refs]);
  const [isFirstRequest, setIsFirstRequest] = useState(true)

  useEffect(() => {
    if (!sets || sets.length === 0 || !activeSet) return
    setIsFetching(true)
    setIsFirstRequest(false)
    if (!isFirstRequest) {
      toUrl(view, activeSet)
    }
    const params = filterToParamsConstructor({ filter: activeSet.filter }, isPreview)
    params.isPreview = isPreview ? 1 : 0;
    params.city = currentSetCity;
    params.limit = view === "map" ? null : 3;
    params.page = view === "map" ? null : 1;
    if (paramsBySetType[activeSet?.type]) {
      Object.assign(params, paramsBySetType[activeSet?.type])
    }

    getBlocks(params, isPreview).then(() => {
      setIsFetching(false)
      setIsFirstLoadingMore(true)
      if (haveUrlFilter() && isFirstRequest) {
        scrollToRef(innerRef)
      }
    })
  }, [sets, activeSet, view, currentSetCity])



  const [isFirstLoadingMore, setIsFirstLoadingMore] = useState(true)

  const handleLoadMore = () => {
    setIsFirstLoadingMore(false)
    const params = filterToParamsConstructor({ filter: activeSet.filter }, isPreview)
    params.isPreview = isPreview ? 1 : 0;
    params.city = currentSetCity;
    params.limit = view === "map" ? null : isFirstLoadingMore ? 2 : 3;
    params.isGetFilter = 0
    params.offset = view === "map" ? null : items.length
    if (paramsBySetType[activeSet?.type]) {
      Object.assign(params, paramsBySetType[activeSet?.type])
    }
    getBlocks(params, true)
    setIsFirstLoadingMore(false)
    sendConstructorEvent("tr_load_more_variants")
  }
  if (!items?.length) return null
  return (
    <section className="property constructor__section" ref={innerRef} id="sets">
      <div className="constructor__container">
        <div className="property__wrapper constructor__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title property__title">
              Подборки недвижимости
            </h2>
          </div>

        </div>
        <div className="property__tabs_wrapper">
          {isLaptop ?
            <div className="mobile-tabs">
              <DropdownBordered
                options={setTabs}
                onChange={(tab) => setActiveSetType(tab.type, tab.cityCode)}
                value={setTabs?.find(tab => tab.isActive)?.id}
                borderColor={themeColors[theme.color]}
              />

              {!activeSet?.isSelfObjects && <ViewRealty
                view={view}
                onChange={setView}
                borderColor={themeColors[theme.color]}
                classes={isLaptop ? (view === 'map' ? 'for-list for-laptop' : 'for-laptop') : (view === 'map' && 'for-list')}
                forConstructor="true"
                altListTitle="Список"
              />}

              <ul className="property__tabs">
                {sets.map((item, index) => <PropertyTab
                  title={item.name ? item.name : item.title} key={`propertyTab-${index}`}
                  isActive={item.isActive} setActiveSets={(e) => {
                    e.stopPropagation()
                    setActiveSets(item.fakeId)
                  }}
                />)}
              </ul>
            </div>
            :

            <div className="types-tabs__wrap" >
              <div className="small-types-tabs">
                <ul className="types-tabs">
                  {setTabs.map((tab, index) => (
                    <li
                      className={`types-tabs__item ${tab.isActive && 'is-active'}`}
                      key={`typestab-${index}`}
                      onClick={() => setActiveSetType(tab.type, tab.cityCode)}
                      ref={refs[index]}
                      is-active-attr={tab.isActive}
                    >
                      {iconsByType[tab.type]}
                      <div className="types-tabs__name">{tab.title}</div>


                    </li>
                  ))}
                  <li
                    className="types-tabs__background"
                    style={{
                      width: tabWidth,
                      transform: `translateX(${tabPosition ? (tabPosition - 10) : '-5'}px)`
                    }}
                  />

                </ul>
                {!activeSet?.isSelfObjects && smallScreen &&
                  <ViewRealty
                    view={view}
                    onChange={setView}
                    borderColor={themeColors[theme.color]}
                    classes={view === 'map' && 'for-list'}
                    forConstructor="true"
                    altListTitle="Список"
                  />
                }
              </div>

              <div className="types-tabs__subtabs">
                <ul className="property__tabs">
                  {sets.map((item, index) => <PropertyTab
                    title={item.name ? item.name : item.title} key={`propertyTab-${index}`}
                    isActive={item.isActive} setActiveSets={(e) => {
                    if(item.name === 'Загородная проекты домов') {
                      setView('list')
                    }
                      e.stopPropagation()
                      setActiveSets(item.fakeId)
                    }}
                  />)}
                </ul>

                {!activeSet?.isSelfObjects && !smallScreen && props.activeSet.name !== 'Загородная проекты домов' &&
                  <ViewRealty
                    view={view}
                    onChange={setView}
                    borderColor={themeColors[theme.color]}
                    classes={view === 'map' && 'for-list'}
                    forConstructor="true"
                    altListTitle="Список"
                  />
                }
              </div>
            </div>
          }
        </div>
        {items?.length > 0 && <Items
          items={items}
          features={features}
          mapItems={mapItems}
          view={view}
          fetching={isLoadBlocks}
          city={currentSetCity}
          theme={theme}
          isFetching={isFetching}
          key={view === 'list' ? "no-refresh" : features.length}
          openCallbackModal={openCallbackModal}
          isSelfObjects={activeSet.isSelfObjects}
        />}
        {hasNextPage && view === 'list' && <SubmitButton isLoading={isLoadBlocks} className="button constructor__btn constructor__btn_transparent" type="button" onClick={handleLoadMore}>Показать еще</SubmitButton>}
      </div>
    </section>

  )
};


const mapDispatchToProps = (dispatch, ownProps) => ({
  setActiveSets: id => dispatch({ type: "AGENCY_SET_ACTIVE_BLOCKS", id }),
  setActiveSetType: (setType, city) => dispatch({ type: "AGENCY_SET_ACTIVE_SETTYPE", setType, city }),
  setView: view => dispatch({ type: "SET_ACTIVE_VIEW", view })
});

const mapStateToProps = (state) => {
  return {
    features: getFeatures(state),
    mapItems: mapItems(state),
    items: state.agency.complexes,
    fetching: state.agency.fetching,
    countBlocksInSet: state.agency.countBlocks,
    page: state.agency.page,
    hasNextPage: state.agency.hasNextPage,
    currentSetCity: state.agency.currentSetCity,
    setType: state.agency.setType,
    setTabs: state.agency.setTabs,
    sets: getSetsByCity(state),
    activeSet: getActiveSet(state),
    allSets: state.agency.sets,
    isLoadBlocks: state.agency.isLoadBlocks,
    view: state.agency.view
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Property));
