import React, { useRef } from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../hooks/useDropdown";
import useElementPosition from "../../hooks/useElementPosition";
import { formatMoney } from "../../utils";
import useDebounceState from "../../hooks/useDebounceState";
import classNames from 'classnames';
import useMedia from "../../hooks/useMedia";
import SearchInput from "./SearchInput";
import { memo } from "react";

const PriceFilter = memo((props) => {
  const {
    autoPosition,
    customFormatEnabled,
    data = {},
    exclude,
    filter,
    items,
    onChange,
    right,
    customFormat = v => v,
    debounced,
    wrapperClass,
    openTop = false,
    clearFilterOnly,
    clearExcludeOnly,
    getCount, // убрать срань ниже и пересмотреть ту что выше после замены фильтров #реф #del
    fullFilter,
    defFullFilter,
    defaultFilter,
    renderTopContent
  } = props;
  const isMobile = useMedia("isTablet");
  const [node, open, show, hide] = useDropdown(false);
  const ref = useRef(null)
  const position = useElementPosition(ref, 150)

  const handleChange = (e, name) => {
    let value = e.target.value.replace(/\s/g, "");
    onChange({ filter: { ...filter, [name]: value }, exclude: { ...exclude } });
  };

  const [debouncedValues, debouncedHandleChange, setDebouncedValues] = useDebounceState({
    setState: (prev, ...args) => ({ ...prev, [args[1]]: args[0]?.target?.value?.replace(/\s/g, "") }),
    fn: handleChange,
    defaultState: { min: "", max: "" },
    observableValues: filter,
  })

  const reset = name => {
    onChange({ filter: { ...filter, [name]: "" }, exclude: { ...exclude } });
    setDebouncedValues(prev => ({ ...prev, [name]: "" }))
  }
  const clearFilters = () => {
    if (typeof getCount === "function") { // #del см коммент выше
      getCount({
        ...fullFilter,
        filter: defFullFilter?.filter ?? defaultFilter
      });
    }
    hide()
    clearFilterOnly?.();
    clearExcludeOnly?.();
  }

  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right")),
    dropdown__block_align_top: openTop,
  });

  const { title, minPlaceholder = "от ", defaultValues, maxPlaceholder = "до " } = data;
  const getFormat = v => customFormatEnabled ? customFormat(v) : formatMoney(v)
  const minInputPlaceholder = `${minPlaceholder}${defaultValues ? getFormat(defaultValues.min) : ""}`
  const maxInputPlaceholder = `${maxPlaceholder}${defaultValues ? getFormat(defaultValues.max) : ""}`
  return (
    <div className={`form__item ${wrapperClass ?? ""}`} ref={ref}>
      <div ref={node} className={`dropdown form__drop ${open ? "is-showing" : ""}`}>
        <div onClick={open ? hide : show} className="dropdown__btn button form__drop-btn">
          <span className="form__drop-name">{title}</span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {/*{renderTopContent && renderTopContent(props, hide)}*/}
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={hide}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{title}</h3>
                </div>
              }
              <div className="filter">
                <div className="filter__cols">
                  <div className="filter__col">
                    <div className="filter__filter">
                      <SearchInput
                        placeholder={minInputPlaceholder}
                        onChange={e => debounced ? debouncedHandleChange(e, "min") : handleChange(e, "min")}
                        reset={() => reset("min")}
                        value={formatMoney(debounced ? debouncedValues?.min : filter.min)}
                        wrapperClasses="is-thin"
                      />
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map(({ id }) => (
                            <div key={id} className="checkbox-group__item">
                              <div className="checkbox checkbox_type_btn">
                                <div className="checkbox__box" style={{ width: "100%" }}>
                                  <input
                                    className="checkbox__control"
                                    type="radio"
                                    name="price-from"
                                    id={`price-from-${id}`}
                                    value={id}
                                    onChange={e => handleChange(e, "min")}
                                    checked={id == filter.min}
                                  />
                                  <label htmlFor={`price-from-${id}`} className="checkbox__label">
                                    {getFormat(id)}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                  <div className="filter__col">
                    <div className="filter__filter">
                      <SearchInput
                        placeholder={maxInputPlaceholder}
                        onChange={e => debounced ? debouncedHandleChange(e, "max") : handleChange(e, "max")}
                        reset={() => reset("max")}
                        value={formatMoney(debounced ? debouncedValues?.max : filter.max)}
                        wrapperClasses="is-thin"
                      />
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map(({ id }) => (
                            <div key={id} className="checkbox-group__item">
                              <div className="checkbox checkbox_type_btn">
                                <div className="checkbox__box">
                                  <input
                                    className="checkbox__control"
                                    type="radio"
                                    id={`price-to-${id}`}
                                    value={id}
                                    onChange={e => handleChange(e, "max")}
                                    checked={id == filter.max}
                                  />
                                  <label htmlFor={`price-to-${id}`} className="checkbox__label">
                                    {getFormat(id)}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                </div>
                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button type="button" className="button button__mobile-filters" onClick={clearFilters}>
                      Очистить
                    </button>
                    <button type="button" className="button button__mobile-filters" onClick={hide}>
                      Применить
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
});

export default PriceFilter;
