import _filter from "lodash/filter";
import forIn from "lodash/forIn";
import _indexOf from "lodash/indexOf";
import without from "lodash/without";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { badgeTitles, specialBadgeValuesTitles } from "../../hooks/useFilter/filterSets";
import {
  formatMoney, isEmptyFilterOrExclude,
  media, specialValues, typesWithArrayValues,
  typesWithObjectValues
} from "../../utils";
import {
  defaultExclude, defaultFilter
} from "../../utils/defaultFilters";

/**
 *
 * Оставь надежду всяк сюда входищй
 * Запаситесь сигами и терпением - впереди костыли
 */

const titleById = (where, id, key) => {
  if (key === "isApartments") {
    return;
  }
  if (!where) {
    return;
  }
  const item = where.find((data) => id == data.id);
  if (!item) {
    return;
  }
  return item.title;
};


const DragableItem = (props) => {
  const {
    provided,
    title,
    value,
    type,
    removeFilter,
    item,
    readonly,
    enableRemoving,
  } = props;

  return (
    <div
      className="filtered"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="filtered__inner">
        {title && <span className="filtered__type">{title}</span>}
        <span className="filtered__value">{value}</span>
        {(!readonly || enableRemoving) && (
          <button
            className="button filtered__del"
            type="button"
            onClick={() =>
              removeFilter(
                item.key,
                item.id ? item.id : value,
                specialValues.includes(item.key)
                  ? item.type
                  : item.params.split(",")[1],
              )
            }
          >
            <svg
              className="icon icon_x"
              width="7"
              height="8"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
              <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
const nonExcludable = [
  "assignments",
  "availability",
  "isNoFirstFloor",
  "isFirstFloor",
  "isApartments",
  "encumbrances",
  "endings",
  "balconyType",
  "issuingKeys",
  "cellHeight", 
  // "realtyType"
];

const endByKey = {
  squareKitchen: " м²",
  squareTotal: " м²",
  squareHouse: " м²",
  squareParcel: " сот.",
  cellHeight: " м."
}
class Dragable extends Component {
  constructor(props) {
    super(props);
  }

  //функция для костыля с draggable
  getTypesAndValue = (params) => {
    return params.split(",");
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    } else {
      const types = this.getTypesAndValue(result.draggableId);
      let identity = types[0];
      let valueType = types[1];
      let value = types[2];
      let beReplaced = Object.values(endByKey)
      let id = value.toString();
      beReplaced.forEach(item => {
        id = id.replace(item, "")
      })
      if (valueType === "Array" && !specialValues.includes(identity)) {
        let filterTmpValues = [...this.props.filter[identity]];
        let excludeTmpValues = [...this.props.exclude[identity]];

        if (destination.droppableId === "exclude") {
          filterTmpValues = without(filterTmpValues, id);
          if (_indexOf(excludeTmpValues, id) < 0) {
            excludeTmpValues.push(id);
          }
        } else {
          excludeTmpValues = without(excludeTmpValues, id);
          if (_indexOf(filterTmpValues, id) < 0) {
            filterTmpValues.push(id);
          }
        }
        this.props.handleFilter(
          { filter: filterTmpValues, exclude: excludeTmpValues },
          identity,
        );
      }
      if (specialValues.includes(identity)) {
        let filterTmpValues = [...this.props.filter[identity]];
        let excludeTmpValues = [...this.props.exclude[identity]];

        if (destination.droppableId === "exclude") {
          filterTmpValues = _filter(
            filterTmpValues,
            (tmpValue) => tmpValue.id != id,
          );
          excludeTmpValues.push({ id: id });
        } else {
          excludeTmpValues = _filter(
            excludeTmpValues,
            (tmpValue) => tmpValue.id != id,
          );
          filterTmpValues.push({ id: id });
        }

        this.props.handleFilter(
          { filter: filterTmpValues, exclude: excludeTmpValues },
          identity,
        );
      }
      if (typesWithObjectValues.includes(identity)) {
        let filterTmpValues = { ...this.props.filter[identity] };
        let excludeTmpValues = { ...this.props.exclude[identity] };
        let beReplaced = Object.values(endByKey)
        let tmpValue = value.toString();
        beReplaced.forEach(item => {
          tmpValue = tmpValue.replace(item, "")
        })
        if (destination.droppableId === "exclude") {
          filterTmpValues = Object.assign(filterTmpValues, { [valueType]: "" });
          excludeTmpValues = Object.assign(excludeTmpValues, {
            [valueType]: tmpValue,
          });
        } else {
          filterTmpValues = Object.assign(filterTmpValues, {
            [valueType]: tmpValue,
          });
          excludeTmpValues = Object.assign(excludeTmpValues, {
            [valueType]: "",
          });
        }
        this.props.handleFilter(
          { filter: filterTmpValues, exclude: excludeTmpValues },
          identity,
        );
      }
    }
  };
  getItemsFromFilter = (filterOrExclude, type) => {
    let output = [];
    forIn(filterOrExclude, (value, key) => {
      //key == "blocks" /"districts" / etc
      if (Object.keys(this.props.filterData).length === 0) {
        return [];
      }
      if (value) {
        if (typesWithObjectValues.includes(key)) {
          let end = "";
          if (endByKey[key]) {
            end = endByKey[key];
          }
          if (value.min && value.min.length) {
            output.push({
              title: (media('isMobile') && !(key === "squareKitchen") && !(key === "floor"))
                ? 'от '
                : badgeTitles[key]["min"],
              value:
                key == "endings" || key == "issuingKeys"
                  ? titleById(this.props.filterData[key].items, value.min, key)
                  : value.min + end,
              key: key,
              params: ",min",
              type: type,
              id: value.max,
            });
          }
          if (value.max && value.max.length) {
            output.push({
              title: (media('isMobile') && !(key === "squareKitchen") && !(key === "floor"))
                ? 'до '
                : badgeTitles[key]["max"],
              value:
                key == "endings" || key == "issuingKeys"
                  ? titleById(this.props.filterData[key].items, value.max, key)
                  : value.max + end,
              key: key,
              params: ",max",
              type: type,
              id: value.max,
            });
          }
        }
        if (
          typesWithArrayValues.includes(key) &&
          value.length &&
          key !== "status"
        ) {
          value.forEach((v) => {
            output.push({
              title: (media('isMobile') && !(key === "registrations")) ? '' : badgeTitles[key],
              value: titleById(this.props.filterData[key], v, key),
              key: key,
              params: `,Array,${v}`,
              type: type,
              id: v,
            });
          });
        }
        if (specialValues.includes(key) && value.length > 0 && key !== "tradeIn") {
          if(key=== "encumbrances" && typeof value === "string"){
            output.push({
              title: badgeTitles["encumbrances"],
              value: specialBadgeValuesTitles[key][value],
              key: key,
              params: `,Array,${value}`,
              type: type,
              id: value,
            });
            return
          }
          if(key=== "isFirstFloor" && typeof value === "string"){
            output.push({
              title: badgeTitles["isFirstFloor"],
              value: specialBadgeValuesTitles[key][value],
              key: key,
              params: `,Array,${value}`,
              type: type,
              id: value,
            });
            return
          }
          if(key=== "assignments" && typeof value === "string"){
            output.push({
              title: badgeTitles["assignments"],
              value: specialBadgeValuesTitles[key][value],
              key,
              params: `,Array,${value}`,
              type,
              id: value,
            });
            return
          }
            Array.isArray(value) && value.forEach((v) => {
              output.push({
                title: (media('isMobile') && (key === 'isOnlyHanded')) ? '' : badgeTitles[key],
                value: (media('isMobile') && (key === 'isOnlyHanded')) ? badgeTitles[key].substring(0, badgeTitles[key].length - 2) : specialBadgeValuesTitles[key][v.id],
                key: key,
                params: `,Array,${v.id}`,
                type: type,
                id: v.id,
              });
            });
        }
      }
    });
    return output;
  };

  removeFilter = (identity, value, specialParam) => {
    let tempFilter;
    let tempExclude;
    if (identity === "status" || identity === "tradeIn") {
      return;
    }
    if (specialValues.includes(identity)) {
      if (specialParam == "exclude") {
        tempFilter = [...this.props.filter[identity]];
        tempExclude = _filter(this.props.exclude[identity], (isApp) => {
          return isApp.id != value.toString();
        });
      } else {
        tempFilter = _filter(
          this.props.filter[identity],
          (isApp) => isApp.id != value.toString(),
        );
        tempExclude = [...this.props.exclude[identity]];
      }
    }
    if (typesWithArrayValues.includes(identity)) {
      tempFilter = this.props.filter[identity].filter(id => id != value);
      tempExclude = this.props.exclude[identity].filter(id => id != value);
    }
    if (typesWithObjectValues.includes(identity)) {
      tempFilter = { ...this.props.filter[identity], [specialParam]: "" };
      tempExclude = { ...this.props.exclude[identity], [specialParam]: "" };
    }
    this.props.handleFilter(
      { filter: tempFilter, exclude: tempExclude },
      identity,
    );
  };

  showIncludes = () => {
    if (!this.props.readonly) return true;

    return this.getItemsFromFilter(this.props.filter, "filter").length > 0;
  };

  showExcludes = () => {
    if (!this.props.readonly) return true;

    return this.getItemsFromFilter(this.props.exclude, "exclude").length > 0;
  };

  componentDidUpdate() {
    const includes = this.getItemsFromFilter(this.props.filter, "filter").length
    const excludes = this.getItemsFromFilter(this.props.exclude, "exclude").length

    this.props.countFilters && this.props.countFilters(includes + excludes)
  }

  render() {
    const {defFullFilter} = this.props
    return (
      <div>
        {this.props.readonly && (
          <div className="index-search__filter-applied for-dragable">
            {this.showIncludes() && !this.props.hideParams &&
              <h4 className="index-search__filter-params">Параметры</h4>
            }
            {this.showExcludes() && !this.props.hideParams &&(
              <div
                style={{
                  padding: "0 0 12px 16px",
                  flex: 1,
                }}
              >
                <h4>Исключения</h4>
              </div>
            )}
          </div>
        )}
        <div
          className={
            "index-search__filter-applied for-dragable" +
            (this.props.readonly ? " readonly" : "")
          }
        >
          <DragDropContext onDragEnd={this.onDragEnd}>
            {this.showIncludes() && (
              <Droppable droppableId="filter">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={`index-search__filter-inc ${
                      this.props.readonly ? "clear-after" : ""
                    }`}
                  >
                    {this.getItemsFromFilter(this.props.filter, "filter").map(
                      (item, index) => {
                        return (
                          <Draggable
                            key={item.key + item.params + index}
                            draggableId={
                              item.key + item.params + "," + item.value
                            }
                            index={index}
                            isDragDisabled={
                              nonExcludable.includes(item.key) ||
                              this.props.readonly
                            }
                          >
                            {(provided, snapshot) => (
                              <DragableItem
                                removeFilter={this.removeFilter}
                                provided={provided}
                                title={item.title}
                                readonly={this.props.readonly}
                                value={
                                  item.key == "prices"
                                    ? formatMoney(item.value)
                                    : item.value
                                }
                                item={item}
                                enableRemoving={this.props.enableRemoving}
                              />
                            )}
                          </Draggable>
                        );
                      },
                    )}
                    <div className="filtered-clear__wrapper">
                      {(!this.props.readonly || this.props.enableRemoving) &&
                        !isEmptyFilterOrExclude(this.props.filter, "filter", defFullFilter) && (
                          <button
                            type="button"
                            onClick={() => {
                              if(this.props.getCount && typeof this.props.getCount === "function"){
                                this.props.getCount({
                                  ...this.props.fullFilter,
                                  filter: defFullFilter? defFullFilter.filter :defaultFilter,
                                });
                              }
                              this.props.clearFilterOnly();
                            }}
                            class="button button_type_filtered filtered-clear"
                          >
                            <span>Очистить параметры</span>
                          </button>
                        )}
                    </div>
                    {!this.props.readonly && !this.props.isMobile &&
                      this.getItemsFromFilter(this.props.filter, "filter")
                        .length === 0 && (
                        <p class="index-search__filter-tip">
                          Вы можете перетащить сюда параметры для поиска
                        </p>
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
            {this.showExcludes() && (
              <Droppable droppableId="exclude">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className="index-search__filter-exc"
                  >
                    {this.getItemsFromFilter(this.props.exclude, "exclude").map(
                      (item, index) => (
                        <Draggable
                          key={item.key + item.params + index}
                          draggableId={
                            item.key + item.params + "," + item.value
                          }
                          index={index}
                          isDragDisabled={this.props.readonly}
                        >
                          {(provided, snapshot) => (
                            <DragableItem
                              removeFilter={this.removeFilter}
                              provided={provided}
                              title={item.title}
                              readonly={this.props.readonly}
                              value={
                                item.key == "prices"
                                  ? formatMoney(item.value)
                                  : item.value
                              }
                              item={item}
                              enableRemoving={this.props.enableRemoving}
                            />
                          )}
                        </Draggable>
                      ),
                    )}
                    {!this.props.readonly &&
                      !isEmptyFilterOrExclude(
                        this.props.exclude,
                        "exclude",
                        defFullFilter
                      ) && (
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              this.props.getCount &&
                              typeof this.props.getCount === "function"
                            ) {
                              this.props.getCount({
                                ...this.props.fullFilter,
                                exclude: defFullFilter? defFullFilter.exclude : defaultExclude,
                              });
                            }
                            this.props.clearExcludeOnly();
                          }}
                          class="button button_type_filtered filtered-clear"
                        >
                          <span>Очистить исключения</span>
                        </button>
                      )}
                    {!this.props.readonly && !media("isMobile") &&
                      this.getItemsFromFilter(this.props.exclude, "exclude")
                        .length === 0 && (
                        <p class="index-search__filter-tip">
                          Вы можете перетащить сюда параметры для исключения
                        </p>
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default Dragable;
