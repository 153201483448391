import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './index.scss';
import Preloader from '../../components/Preloader';
import useMedia from '../../hooks/useMedia';
import useApi from '../../hooks/useApi';
import ShareBlock from '../../components/ShareBlock/ShareBlock';
import { getUrl } from '../../api';

const Blog = () => {
  const isMobile = useMedia('isMobile');
  const utms = useLocation().search;

  const { section, element } = useParams();

  const { data, isLoading } = useApi({
    payload: ['panpartner:blog.ajax', 'getItem', { element: element }],
  });

  const { item, semiItems } = data;

  const innerHtml = useMemo(() => {
    if (!item?.detailText) return ``;

    const wrap = (item, className = 'blog__table-wrapper', cb = null) => {
      const wrapperElement = document.createElement('div');
      wrapperElement.classList = className;

      item.before(wrapperElement);
      wrapperElement.append(item);

      if (cb) cb(wrapperElement);
    };
    const div = document.createElement('div');

    div.innerHTML = item?.detailText;

    

    div.querySelectorAll('table').forEach((table) => wrap(table));

    div
      .querySelectorAll('img')
      .forEach((img) => wrap(img, 'blog__img-container'));

    return div.innerHTML;
  }, [item?.detailText]);

  if (isLoading) return <Preloader loading={true} />;

  return (
    <div className="bg-gray">
      <section className="hp__promo blog">
        <div className="wrapper">
          <div className="wrapper__center blog__wrapper">
            <div className="blog__header text">
              <ol
                itemscope
                itemtype="http://schema.org/BreadcrumbList"
                className="blog__breadcrumbs"
              >
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="http://schema.org/ListItem"
                >
                  <a itemprop="item" href="/">
                    <span itemprop="name">Главная</span>
                  </a>
                  <meta itemprop="position" content="1" />
                </li>
                {/* <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="http://schema.org/ListItem"
                >
                  <a itemprop="item" href={`/blog/${item.section.code}`}>
                    <span itemprop="name">{item.section.code}</span>
                  </a>
                  <meta itemprop="position" content="2" />
                </li> */}
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="http://schema.org/ListItem"
                >
                  <a itemprop="item">
                    <span itemprop="name">{item.title}</span>
                  </a>
                  <meta itemprop="position" content="2" />
                </li>
              </ol>
              {/* item[section][code] + item[code] */}
              <span className="blog__date">{item.dateCreate}</span>
            </div>

            <h1>{item.title}</h1>

            <ShareBlock noOk noText />

            <div className="blog__main-pic">
              {
                // Исправить путь картинки
              }
              <img
                src={getUrl(item.photo.src)}
                alt=""
                className="blog__main-img"
              />

              {/* <div className="blog__socials">
                <a href={`https://vk.com/share.php?url=${window.location.href}&title=${item.title}`} className="blog__socials-item" target={`_blank`}>
                  <svg
                    width="21"
                    height="13"
                    viewBox="0 0 21 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 7.125C17.675 6.67969 18.4625 5.61094 18.9875 4.72031C20.65 2.22656 21 1.69219 21 1.33594C21 1.06875 20.825 0.890625 20.5625 0.890625H16.625C16.45 0.890625 16.275 1.06875 16.1875 1.24687C16.0125 2.49375 13.125 5.96719 12.6875 6.23438C12.5125 6.23438 12.25 5.78906 12.25 4.89844V1.33594V1.15781C12.25 0.890625 12.25 0.534375 12.075 0.35625C11.725 0 11.2875 0 10.0625 0C7.7875 0 7 0.7125 7 1.33594C7 1.60312 7.175 1.78125 7.4375 1.78125C7.4375 1.78125 7.875 2.1375 7.875 4.00781C7.875 4.54219 7.875 5.78906 7.7875 6.23438C6.65 6.05625 5.075 3.20625 4.375 1.15781C4.2875 0.979687 4.1125 0.890625 3.9375 0.890625H0.4375C0.35 0.890625 0.175 0.979688 0.0875 1.06875C0 1.15781 0 1.24688 0 1.425C0.525 4.09688 6.0375 12.4688 9.625 12.4688H11.375C12.3375 12.4688 12.3375 11.4891 12.425 10.7766C12.5125 10.1531 12.5125 9.79688 12.6875 9.79688C13.2125 9.79688 14.35 10.7766 15.05 11.4C15.8375 12.1125 16.275 12.4688 16.625 12.4688H18.8125C19.95 12.4688 21 12.4688 21 11.5781C21 11.2219 20.7375 10.8656 19.075 8.90625C18.4625 8.28281 17.7625 7.48125 17.5 7.125Z"
                      fill="#2C2E3E"
                    />
                  </svg>
                </a>

                <a href={`https://t.me/share/url?url=${window.location.href}&text=${item.title}`} className="blog__socials-item" target={`_blank`}>
                  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6982 12.212C6.71122 12.2081 6.72555 12.2042 6.73858 12.2016C6.7464 12.1677 6.75682 12.1325 6.75943 12.1C6.83629 11.2701 6.91836 10.4429 6.9848 9.61174C6.99913 9.42676 7.06296 9.29518 7.20235 9.17142C8.30446 8.18395 9.40136 7.19127 10.4996 6.2025C11.7163 5.1056 12.9318 4.00871 14.1433 2.9079C14.2189 2.84146 14.2501 2.72552 14.3009 2.63172C14.1876 2.61869 14.0651 2.5731 13.9609 2.59915C13.8202 2.63563 13.686 2.719 13.5597 2.79717C10.8774 4.48811 8.19503 6.18036 5.51011 7.86739C5.35769 7.96249 5.32903 8.04456 5.38765 8.21131C5.58567 8.7793 5.76544 9.3538 5.95173 9.9257L6.6982 12.212ZM9.38455 11.1007C8.7384 11.7312 8.09745 12.3487 7.46302 12.974C7.24156 13.1929 6.99144 13.314 6.67227 13.2958C6.45602 13.284 6.33486 13.1889 6.26712 12.9831C5.7812 11.4746 5.28747 9.9686 4.80415 8.45743C4.75726 8.31152 4.68821 8.23987 4.5397 8.19428C3.3933 7.84384 2.2508 7.4869 1.10961 7.12474C0.933745 7.06872 0.756574 6.99447 0.605457 6.89155C0.370966 6.73262 0.335793 6.47468 0.546835 6.28708C0.740941 6.11512 0.972827 5.96531 1.21383 5.87021C2.61427 5.31525 4.02121 4.77852 5.42686 4.23659C8.99373 2.8596 12.5606 1.48392 16.1262 0.106935C16.8049 -0.154914 17.326 0.213759 17.2556 0.947195C17.21 1.41618 17.0889 1.87734 16.9912 2.34112C16.2174 5.99657 15.4409 9.65464 14.6632 13.3114C14.4782 14.179 13.8685 14.4057 13.1546 13.8781C11.9574 12.9974 10.7615 12.1116 9.56563 11.2283C9.50831 11.188 9.44969 11.1476 9.38455 11.1007Z" fill="#2C2E3E" />
                  </svg>
                </a>



                <a href={`https://wa.me/?text=${window.location.href}`} className="blog__socials-item" target={`_blank`}>
                  <svg className="icon icon_wa">
                    <use xlinkHref="#social-wa"></use>
                  </svg>
                </a>
              </div> */}
            </div>

            <div
              className="blog__content"
              dangerouslySetInnerHTML={{ __html: innerHtml }}
            ></div>
          </div>
        </div>
      </section>

      {semiItems.length > 0 && (
        <section className="blog blog-cards section">
          <div className="wrapper">
            <div className="wrapper__center">
              <h2>Похожие статьи</h2>

              <div
                className={`blog__grid${
                  semiItems.length > 1 ? ` blog__grid_scroll` : ``
                }`}
              >
                {semiItems.map((semi) => (
                  <Link to={`/blog/${semi.section.code}/${semi.code}`} target={`_blank`} className="blog__card">
                    <div className="blog__card-pic">
                      <img src={getUrl(semi.photo.src)} alt="" />
                    </div>
                    <div className="blog__card-info">
                      <p className="blog__card-date text">{semi.dateCreate}</p>
                      <p className="blog__card-descr h5">{semi.title}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Blog;
