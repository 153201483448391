import React from "react";
import './index.scss';

const AutopanInfo = () => {
  return (
    <div className="autopan-info">
      <h4 className="h4 text-center autopan-info__title">Узнать свободные окна АвтоПАН:</h4>
      <div className="autopan-info__box">
        <div className="autopan-info__block autopan-info__block_tg">
          <p>
            В Telegram ПАН Партнер:<br />
            <a href="https://t.me/panpartner" className="bold" target="_blank" rel="noopener noreferrer">t.me/panpartner</a>
          </p>

          <p>
            Поиск по хештэгу<br />
            <span className="bold">#АвтоПАН</span>
          </p>
        </div>
        <div className="autopan-info__block">
          <p>
            По телефону:<br /> 
            <a href="tel:+79062694518" className="bold" target="_blank" rel="noopener noreferrer">+7 (906) 269-45-18</a>
          </p>
          <p>
            Тамара Горохова,<br />
            <span className="color-brand-3">управляющая проектом АвтоПАН</span>
          </p>
        </div>
      </div>
    </div>
  )
};

export default AutopanInfo;
