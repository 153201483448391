import React from 'react';
import Question from "./Question";
import './index.scss';

const Contacts = () => {
  return (
    <div className="hp__page">

      <div className="wrapper">
        <div className="wrapper__center">
          <section className="hp__become-p hp__promo hp__section-margin">
            <h1 className="hp__h1 h1 text-center">Контакты</h1>
            <p className="hp__page-sub color-brand-3 big-text text-center">По вопросам сотрудничества и подключения к платформе</p>
          </section>
          <section className="hp__contacts-grid">
            <div className="hp__contacts-item">
              <div className="hp__contacts-content">
                <h4 className="h4 hp__contacts-title">Отдел развития в&nbsp;Санкт-Петербруге</h4>
                <a className="h5 hp__contacts-phone link" href="tel:+78124261863">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1762 12.1349C14.0107 12.0373 13.8216 11.9858 13.629 11.9858C13.4365 11.9858 13.2475 12.0373 13.0819 12.1349L11.3823 13.1509C11.2029 13.2691 10.9923 13.3321 10.777 13.3321C10.5616 13.3321 10.351 13.2691 10.1716 13.1509C9.56626 12.666 8.61167 11.8809 7.84335 11.0496C7.09843 10.3157 6.39129 9.54503 5.72462 8.74051C5.60548 8.56258 5.54194 8.35373 5.54194 8.14014C5.54194 7.92655 5.60548 7.71772 5.72462 7.53979L6.74906 5.85414C6.84076 5.68773 6.88882 5.50114 6.88882 5.3115C6.88882 5.12186 6.84076 4.93527 6.74906 4.76886L4.2578 0.520125C4.13533 0.321 3.95143 0.166302 3.73336 0.0789598C3.51529 -0.00838222 3.27468 -0.0237176 3.04713 0.0352167C2.41602 0.200056 1.84366 0.535858 1.39408 1.00503C-0.515096 2.92159 -1.53959 6.15432 5.70131 13.3356C12.9422 20.5169 16.2018 19.524 18.1343 17.6075C18.5767 17.078 18.8662 16.4397 18.9724 15.7602C19.0243 15.5354 19.0026 15.3 18.9105 15.0883C18.8184 14.8765 18.6607 14.6994 18.4602 14.5825L14.1762 12.1349Z" fill="#FF2D23"/>
                  </svg>
                  <span className="color-brand-3">+7 (812)</span> 426-18-63
                </a>
              </div>
            </div>
            <div className="hp__contacts-item hp__contacts-item_msk">
              <div className="hp__contacts-content">
                <h4 className="h4 hp__contacts-title">Отдел развития в&nbsp;Москве</h4>
                <a className="h5 hp__contacts-phone link" href="tel:+74993221499">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1762 12.1349C14.0107 12.0373 13.8216 11.9858 13.629 11.9858C13.4365 11.9858 13.2475 12.0373 13.0819 12.1349L11.3823 13.1509C11.2029 13.2691 10.9923 13.3321 10.777 13.3321C10.5616 13.3321 10.351 13.2691 10.1716 13.1509C9.56626 12.666 8.61167 11.8809 7.84335 11.0496C7.09843 10.3157 6.39129 9.54503 5.72462 8.74051C5.60548 8.56258 5.54194 8.35373 5.54194 8.14014C5.54194 7.92655 5.60548 7.71772 5.72462 7.53979L6.74906 5.85414C6.84076 5.68773 6.88882 5.50114 6.88882 5.3115C6.88882 5.12186 6.84076 4.93527 6.74906 4.76886L4.2578 0.520125C4.13533 0.321 3.95143 0.166302 3.73336 0.0789598C3.51529 -0.00838222 3.27468 -0.0237176 3.04713 0.0352167C2.41602 0.200056 1.84366 0.535858 1.39408 1.00503C-0.515096 2.92159 -1.53959 6.15432 5.70131 13.3356C12.9422 20.5169 16.2018 19.524 18.1343 17.6075C18.5767 17.078 18.8662 16.4397 18.9724 15.7602C19.0243 15.5354 19.0026 15.3 18.9105 15.0883C18.8184 14.8765 18.6607 14.6994 18.4602 14.5825L14.1762 12.1349Z" fill="#FF2D23"/>
                  </svg>

                  <span className="color-brand-3">+7 (499)</span> 322-14-99
                </a>
              </div>
            </div>
          </section>
        </div>

        <Question />
      </div>



    </div>
  );
};

export default Contacts;