import React from 'react';
import { useSelector } from 'react-redux';
import { media } from '../../utils';
import PanModal from '../core/PanModal';
import { useModal } from 'react-modal-hook';
import { getUrl } from '../../api';

const AttentionBlock = ({ isForIpad, openBuyModal }) => {
  const addAssignmentsVideo = useSelector(
    (state) => state.assignments?.data?.addAssignmentsVideo,
  );

  const [openModal, closeModal] = useModal(() => (
    <PanModal title={'Видеорегламент'} closeModal={closeModal}>
      <video width="100%" controls autoPlay src={getUrl(addAssignmentsVideo?.src)} />
    </PanModal>
  ), [addAssignmentsVideo]);

  return (
    <div className="wrapper">
      <div
        className={`assignations-attention${
          isForIpad ? ` assignations-attention_static` : ``
        }`}
      >
        <div className="assignations-attention__btns">
          <div
            className="assignations-attention__btn js-modal-btn"
            data-id="modal-assignation-sell"
          >
            <button className="button" onClick={openBuyModal}>
              <span
                className={`assignations-attention__icon ${
                  media('isMobile') ? 'no-animation' : ''
                }`}
              >
                <svg className="icon icon_arrow-right">
                  <use xlinkHref="#arrow-right-clean"></use>
                </svg>
              </span>
              Добавить объект в базу
            </button>
          </div>
          {true && (
            <button
              className="button link-hover label_with-icon_prefix color-brand-3"
              onClick={openModal}
            >
              Видеорегламент
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttentionBlock;
