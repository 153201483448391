import _ from "lodash";
import request from "../api";
import { actionFactory } from './actionUtils';

const requestMotivation = actionFactory("MOTIVATION_REQUEST")

const successMotivation =  actionFactory("MOTIVATION_SUCCESS")

const clearMotivation = actionFactory("MOTIVATION_RESET")

const failureMotivation =  actionFactory("MOTIVATION_FAILURE")

const setFilterMotivation = actionFactory("MOTIVATION_SET_FILTER")

const clearFilterMotivation = actionFactory("MOTIVATION_CLEAR_FILTER")

const getMotivations = form => request("panpartner:motivation.ajax", "getMotivations", form);

const fetchMotivation = () =>
  dispatch => {
    dispatch(requestMotivation());
    return request("panpartner:motivation.ajax", "getMotivations")
      .then(
        data => {
          const { filter, motivations, files } = data;
          const { filterData, filterValues } = handleFilterInitial(filter);

          dispatch(setFilterMotivation({ filter: filterValues }));
          dispatch(
            successMotivation({
              motivations: handleMotivation(motivations),
              filterData: filterData,
              defaultData: filterValues,
              files: files,
            })
          );
        },
        error => {
          dispatch(failureMotivation());
          return Promise.reject(error);
        }
      );
  };


const getRewardForFilter = (from, to) => {
  const replace = string => parseFloat(string.replace(",", "."))
  let reward = 0;
  if (to) {
    reward = replace(to)
  } else if (from) {
    reward = replace(from)
  }
  return reward;
};

const handleMotivation = (motivations) =>
  motivations.map(motivation => {
    Object.entries(motivation.items).map(([key, values]) => {
      if (motivation.items[key].length > 0) {
        motivation.items[key] = motivation.items[key].map((item) => ({
          ...item,
          reward: getRewardForFilter(item.rewardFrom, item.rewardTo)
        }));
      }
    })
    return motivation;
  });

const handleFilterInitial = (filter) => {
  let cities = [];
  let years = [];
  let periods = {};

  filter.city.map((city) => {
    cities.push({ id: city.code, title: city.title });
    return city;
  });
  Object.keys(filter.period).map((year) => {
    years.push({ id: year, title: year });
    periods[year] = [];
    filter.period[year].map((month) => {
      periods[year].push({ id: month.title, title: month.title });
      return month;
    });
    return year;
  });
  filter.years = years;

  let year = _.last(years).id;
  let month = periods[year][0].id;
  let city = "spb";
  let type = _.first(filter.type).id;

  return {
    filterValues: {
      city: city,
      year: year,
      month: month,
      builders: [],
      type: type,
      motivation: [],
      prepayment: false,
    },
    filterData: {
      years: years,
      cities: cities,
      periods: periods,
      builders: filter.builder,
      types: filter.type,
      motivation: filter.motivation,
    },
  };
};

export { clearMotivation, setFilterMotivation, clearFilterMotivation, fetchMotivation, getMotivations };

