import React, { useMemo, useRef, useState } from "react";
import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
const PhotoPopup = (props) => {
  const { items, index = 0, open, onClose } = props;

  const options = useMemo(() => {
    return {
      index,
      closeOnScroll: false,
      closeOnVerticalDrag: false,
      history: false,
      maxSpreadZoom: 4,
      getDoubleTapZoom: (isMouseClick, item) => {
        if (isMouseClick) {
          return 1.5;

        } else {
          return item.initialZoomLevel < 0.7 ? 1 : 1.5;
        }
      }
    };
  }, [index]);

  const photos = useMemo(
    () =>
      items.map((item) => ({
        src: getUrl(item),
        w: 0,
        h: 0,
      })),
    [items]
  );
  const imageLoadComplete = (swipeRef, index, item) => {
    if (item.h < 1 || item.w < 1) {
      let img = new Image()
      img.onload = () => {
        item.w = img.width
        item.h = img.height
        swipeRef.invalidateCurrItems()
        swipeRef.updateSize(true)
      }
      img.src = item.src
    }
  }
  const handleClose = (swipeRef) => {
    swipeRef.destroy()
  }
  return (
    <PhotoSwipe
      isOpen={open}
      items={photos}
      options={options}
      onClose={onClose}
      close={handleClose}
      imageLoadComplete={imageLoadComplete}
    />
  );
};

const PhotoBlock = (props) => {
  const { photos, identity, largePhotos, onPhotoOpen, onPhotoClosed} = props;
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const navRef = useRef(null);
  const node = useRef(null);

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          controls: false,
          navContainer: `#complex-slider-thumbnails-${identity}`,
          navAsThumbnails: true,
        }
        : {},
    [navRef],
  );

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex - 1);
  };

  const handleOpen = () => {
    setOpen(true);
    return onPhotoOpen?.()
  };

  const handleClose = () => {
    setOpen(false);
    return onPhotoClosed?.()
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  return (
    <>
      <div className="assignations__slider-wrapper gallery__wrapper">
        <ErrorPlug wrappedComponent="src/comp-ts/PhotoBlock.js">
          <TinySlider
            settings={settings}
            ref={node}
            onIndexChanged={handleChangeIndex}
          >
            {photos.map((photo, index) => (
              <div
                className="gallery__item"
                key={`photo-first-${index}`}
                onClick={() => handleOpen()}
              >
                <img style={{ ...(photo.includes("emptyPlan") || photo.includes("bcg_houses") ? { width: "fit-content", height: "auto", minWidth: 300 } : []) }} src={`${photo}`} alt="" />
              </div>
            ))}
          </TinySlider> 
        </ErrorPlug>
      </div>
      <div className="complex__slider-preview">
        <ul
          className="complex__slider-thumbnails"
          id={`complex-slider-thumbnails-${identity}`}
          ref={navRef}
        >
          {photos.map((photo, index) => (
            <li className="complex__slider-thumbnails_item" key={`photo-second-${index}`}>
              <img src={`${photo}`} alt="" />
            </li>
          ))}
        </ul>
      </div>

      <div className="flat__image-expand" onClick={() => handleOpen()}>
        <svg className="icon icon_search">
          <use xlinkHref="#search"></use>
        </svg>
      </div>


      <PhotoPopup
        open={open}
        items={!!largePhotos?.length ? largePhotos : photos}
        index={index}
        onClose={handleClose}
      />
    </>
  );
};

export default PhotoBlock;
