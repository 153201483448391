import React, { useState } from "react";
import "../components/Mobile/index.css";


export const Mobile = ({ handleDesktop }) => {
  const [desktop, setDesktop] = useState(false);

  const handleDesktopVersion = () => {
    setDesktop(true);
    handleDesktop(true);
  };

  return (
    <div className="mobile">
      <div className="mobile__image">
        <svg className="icon">
          <use xlinkHref="#mobile_image"></use>
        </svg>
      </div>
      <div className="mobile__title">
        <h1 className="h1">Мобильная версия в разработке.</h1>
      </div>
      <div className="mobile__text">Воспользуйтесь полной версией сайта</div>
      <div className="mobile__button">
        <div
          className="button button_view_default"
          onClick={handleDesktopVersion}
        >
          Перейти
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default Mobile
