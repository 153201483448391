import React, {useState} from 'react';

const Checkbox = (props) => {
	const [checked, setChecked] = useState(props.isChecked);

	return (

		<div className={'checkbox' + (props.view ? ` checkbox_view_${props.view}` : '')}>
			<div className="checkbox__box">
				<input
					className="checkbox__control"
					type="checkbox"
					checked={checked}
					onChange={() => setChecked(prevState => !prevState)}
					{...props}
				/>
				<label className="checkbox__input" htmlFor={props.id}></label>
				<div className="checkbox__marker"></div>
			</div>
			{props.labelText ? <label className="checkbox__label" htmlFor={props.id}>{props.labelText}</label> : ``}
		</div>
	);
};

export const CheckboxControlled = ({ disabled, ...props }) => <div
	className={'checkbox' + (props.view ? ` checkbox_view_${props.view}` : '')}
	style={{ filter: disabled ? 'opacity(0.3)' : 'none' }}
>
	<div className="checkbox__box" style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
		<input
			className="checkbox__control"
			type="checkbox"
			disabled={disabled}
			{...props}
		/>
		<label className="checkbox__input" htmlFor={props.id} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}></label>
		<div className="checkbox__marker"></div>
	</div>
	{props.labelText ? <label className="checkbox__label" htmlFor={props.id}>{props.labelText}</label> : ``}
</div>

export default Checkbox;