import { memo } from 'react';
import PromoCard from './PromoCard';

const PromoList = memo(({ items, innerRef, managerInfo }) =>
  !!items?.length ? (
    <section className="sf__section">
      <h3 className="h3-l sf__h3" ref={innerRef}>
        Акции
      </h3>
      <section className="sf__promo-list">
        {items.map((item) => (
          <PromoCard {...item} key={item.title} managerInfo={managerInfo} />
        ))}
      </section>
    </section>
  ) : null,
);

export default PromoList;
