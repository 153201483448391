import React, { useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import usePermission from "../../hooks/usePermission";
import useSaveRef from '../../hooks/useSaveRef';
import useUniversalForm from "../../hooks/useUniversalForm";
import { socials } from "../../selectors/user";
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Wrap from '../core/ModalWrap';
import { standartErrorToast, standartSuccessToast } from "../toasts";
import './index.scss';
import Socials from "./Socials";


const ReviewForm = ({formId, closeModal}) => {
  const { send, setValue, getValue } = useUniversalForm({
    formId,
    defaultValues: {
      "url": window.location.href
    }
  
  })
  const isMobile = useMedia("isMobile");
  return <>
    {!isMobile && <h2 className="modal__title">{`Ваше сообщение`}</h2>}
    <form className="modal-form" onSubmit={() => { }}>
      <div className="modal-form__row">
        <div className="modal-form__item">
          <label
            className="textarea textarea_type_form"
            htmlFor="modal-footer-description-text"
          >

            <span className="textarea__label">
              Максимальное количество символов — {300}
            </span>

            <textarea
              className="textarea__control"
              maxLength={300}
              id="modal-footer-description-text"
              placeholder={`Ваше сообщение`}
              style={{ minHeight: 100 }}
              onChange={e => setValue(e.target.value, "question")}
              value={getValue("question")}
            ></textarea>
          </label>
        </div>
      </div>
      <div className="modal-form__submit">
        <button className="button button_view_default" type="button" onClick={()=>{
          send().then(standartSuccessToast,standartErrorToast)
          closeModal()
          }}>Отправить</button>
      </div>
    </form>
  </>
}

const Logo = ({ logo }) => {
  return (
    <Link to="/" className="footer__logo work-company__logo">
      {logo ? (
        <img className="icon icon_logo" src={getUrl(logo?.src)} alt="Логотип" />
      ) : (
        <svg className="icon icon_logo" style={{ maxHeight: "41px" }}>
          <use xlinkHref="#logo"></use>
        </svg>
      )}
    </Link>
  );
};

const Footer = (props) => {
  const { clientMode, workCompany } = props;
  const isToprealtors = usePermission("toprealtors");
  const isMobile = useMedia("isMobile");
  const [formId, setFormId] = useState(48)
  const [openFooterForm, closeFooterForm] = useModal(
    ({ in: open, onExited }) => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Ваше сообщение" closeClick={closeFooterForm}>{children}</MobileModal>
        : <Modal close={closeFooterForm} classes="modal_task">{children}</Modal>
      } >
        <ReviewForm formId={formId} closeModal={closeFooterForm} />
      </Wrap >
    ), [formId]
  );

  const openForm = id => {
    setFormId(id)
    openFooterForm()
  }


  const ref = useSaveRef("footer")

  return (
    <footer className="footer" ref={ref}>
      <div className="wrapper">
        <div className="footer__inner">
          <div className="footer__company footer__block">
            <Logo logo={props.logo} />
            {!clientMode && (
              <p className="footer__copy">
                &copy;&nbsp;2010&mdash;{new Date().getFullYear()} Первое Агентство Недвижимости
              </p>
            )}
            {workCompany && clientMode && (
              <p className="footer__copy">{workCompany}</p>
            )}
          </div>

          <div className="footer__app footer__block">
            <ul className="footer__app-list">
              <li className="footer__app-item"><a href="https://bit.ly/3kGH4q1" className="footer__app-anchor" target="_blank"><img src={require('../../assets/images/footer/google.svg').default} alt="" className="footer__app-svg" /></a></li>
              <li className="footer__app-item"><a href="https://apple.co/3klTwLt" className="footer__app-anchor" target="_blank"><img src={require('../../assets/images/footer/apple.svg').default} alt="" className="footer__app-svg" /></a></li>
            </ul>
          </div>

          {!clientMode && <Socials items={socials} />}

          {!clientMode && (
            <div className="footer__anchors footer__block">
              <button type="button" className="button footer__anchor" onClick={() => openForm(48)}>
                Задать вопрос
              </button>
              <button type="button" className="button footer__anchor" onClick={() => openForm(49)}>
                Предложить идею
              </button>

              <Link to="/policy" className="footer__anchor" target="_blank">
                Политика конфиденциальности
              </Link>
            </div>
          )}

        </div>
      </div>
    </footer>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  logo: state.user?.logo,
  workCompany: state.user?.info?.workCompany,
  clientMode: state.general.clientMode,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
