/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Preloader from "../Preloader";

import {
  fetchFiles,
  clearFiles,
  clearFilterFiles,
  setFilterFiles,
} from "../../actions/files";

import FilesHeader from "./FilesHeader";
import FilesItems from "./FilesItems";

const Files = (props) => {
  const {
    filter,
    files,
    folders,
    breadcrumbs,
    fetch,
    reset,
    isFetching,
    error,
    setFilter,
    clearFilter,
    page,
    changeFolder,
  } = props;

  let { id } = useParams();
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    fetch(id);
  }, [id]);

  //if (isFetching) return <Preloader />;

  //if (error) return 'Error';
 
  const filterFiles = (filesArray) => {
    if (!id) {
      return [];
    }
    return filesArray.filter((file) => file.folder == id);
  };
  return (
    <>
      <FilesHeader breadcrumbs={breadcrumbs} />
      <FilesItems
        folders={folders}
        files={filterFiles(files)}
        changeFolder={changeFolder}
        breadcrumbs={breadcrumbs}
      />
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchFiles(id)),
  reset: () => dispatch(clearFiles()),
  setFilter: (filter) => dispatch(setFilterFiles(filter)),
  clearFilter: () => dispatch(clearFilterFiles()),
});

const mapStateToProps = (state) => ({
  filter: state.files.filter,
  folders: state.files.folders,
  files: state.files.files,
  breadcrumbs: state.files.breadcrumbs,
  isFetching: state.files.isFetching,
  error: state.files.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Files);
