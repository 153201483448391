import formatter from "format-number";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { getSuffix } from '../../utils';
import MapList from "../Assignation/MapList";
import Card from "../Cards";
import Maps from "../Estate/Maps";
import LoadMoreSpinner from "../LoadMoreSpinner";

const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = _.chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 300);
  });

const priceFormat = (price) => {
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

const RowItem = (props) => {
  const [active, setActive] = useState(false);
  const { items, savedFilterXmlId } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setPage(0);
    setData(_.take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage((p + 1) * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    threshold: 50,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  return (
    <div class="assignations-flats__row">
      <div
        className={`flats__row-table public-assignments-wrapper is-active ${
          active ? `is-active` : ``
        }`}
        style={{ transition: "none" }}
        ref={infiniteRef}
      >
        {data.map((item, i) => {
          return (
            <Card
              key={"secondary-" + i}
              currentTab="secondary"
              {...item}
              planSmall={{...item.plan}}
              builder={{ name: item.builder.name }}
              disableFavorites={true}
              alternativeLink={props.alternativeLink}
              block={{address: item.address, name: item.block.name}}
              ending={item.endingDate}
              isPublic={true}
              savedFilterXmlId={savedFilterXmlId}
            />
          );
        })}
      </div>
      {loading && <LoadMoreSpinner loading={true} />}
    </div>
  );
};

let paintIsStarted = false;
const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, alternativeLink, savedFilterXmlId } = props;
  const [viewType, setViewType] = useState('list');
  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);



  const filterByCluster = (items) => {
    if (selectedItems) {
      return _.intersectionBy(items, selectedItems, 'id');
    }
    if (currentPoint && isClusterOrPoint === 'point') {
      paintIsStarted = false;
      return _.intersectionBy(items, [currentPoint], 'id');
    }
    if (currentCluster && isClusterOrPoint === 'cluster') {
      paintIsStarted = false;
      return _.intersectionBy(items, currentCluster.features, 'id');
    }

    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  return (
    <section
      className={`section`}
      ref={innerRef}
    >
      <div className="assignations-flats assignations-flats_public assignations-flats_table2">
        <div id="filter-results"></div>
        <div className="wrapper">
          <div className="assignations-flats__header">
            <div className="assignations-flats__title">
              <h2 className="h2">
                Найдено {items.length} объект{getSuffix(items.length)}:
              </h2>
            </div>
            {viewType === 'list' && (
              <div className="">
                <button
                  className="button button_view_outline-with-icon-invert"
                  onClick={() => setViewType('map')}
                >
                  <svg className="icon icon_marker">
                    <use xlinkHref="#marker"></use>
                  </svg>
                  <span>Показать на карте</span>
                </button>
              </div>
            )}
            {viewType === 'map' && (
              <div className="">
                <button
                  className="button button_view_outline-with-icon-invert"
                  onClick={() => setViewType('list')}
                >
                  <svg className="icon icon_list">
                    <use xlinkHref="#list"></use>
                  </svg>
                  <span>Показать список</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {viewType === 'list' && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables">
              {items.length > 0 &&
                  <RowItem
                    items ={items}
                    isAgent={isAgent}
                    clientMode={clientMode}
                    alternativeLink={alternativeLink}
                    savedFilterXmlId={savedFilterXmlId}
                  />
                }
              </div>
            </div>
          </div>
        )}
        {(isInitMap || viewType === 'map') && (
          <div
            className="section__map"
            style={{ display: viewType === 'map' ? 'block' : 'none' }}
          >
            <div className="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                fromPage={"secondaries"}
                favType={"secondary"}
                alternativeLink={"secondary"}
                disableFavorites={true}
              />
              <div className="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
