import { memo } from 'react';
import RadioDropdown from '../../core/RadioDropdown';
import { PriceCurency } from '../../FilterRealty/Components';
import DistrictsFilter from '../../FilterRealty/DistrictsFilter';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

const DubaiBottomFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    wrapperClass = "sf__filter-item"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    {!!filterData.issuingKeys?.items && <EndingsFilter
      autoPosition
      filter={filter.issuingKeys ?? { min: "", max: "" }}
      items={filterData.issuingKeys.items ?? []}
      wrapperClass={wrapperClass}
      data={{
        title: "Выдача ключей",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.issuingKeys?.ranges ?? null,
      }}
      onChange={v => setFilterAndExclude(v, "issuingKeys")}
      ident={"issuingKeys"}
    />}
    {!!filterData?.type?.length && <SelectFilter
      {...createSelectProps("type")}
      wrapperClass={wrapperClass + ' sf__filter-item_type'}
      data={{
        title: "Тип недвижимости",
        identity: "type",
      }}
    />}
    {!!filterData.residencePermit?.length && <RadioDropdown
      autoPosition
      onChange={v => handleFilter('residencePermit', v)}
      items={filterData.residencePermit ?? []}
      wrapperClass={wrapperClass}
      value={filter.residencePermit}
      alternativeTitle={'ВНЖ'}
      data={{
        title: 'ВНЖ',
        identity: 'residencePermit',
      }}
    />}
    {!!filterData?.toSea?.length && <SelectFilter
      {...createSelectProps("toSea")}
      wrapperClass={wrapperClass}
      data={{
        title: "До моря",
        identity: "toSea",
      }}
    />}
  </>
});

export default DubaiBottomFilters;
