import React, { memo } from 'react';
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import Dropdown2 from "../../components/Dropdown";
import { formatMoney } from '../../utils';


export const FormItem = memo(({ label, error, postfix, ...props }) => <div className="hp__form-item">
    <label className="hp__form-label text">
        <span>{label}</span>
    </label>
    <label className="input input_width_available input_type_form">
        <input
            type="number"
            className={`input__control${!!error ? " input__error" : ""}`}
            {...props}
        />
        <span className="input__control_postfix">{postfix}</span>
    </label>
</div>)

const toDate = str => {
    if (str instanceof Date) return str
    const [day, months, years] = str?.split?.(".") || [];
    if (years?.trim?.()?.length !== 4) return new Date();
    return new Date(years, months - 1, day);
}

export const EarlyForm = ({ setValue, values, types, periods, number }) => <div className="mortgage-calc__early-form">
    <div className="mortgage-calc__early-header">
        <div style={{ fontWeight: 'bold' }}>Досрочный платеж {number}</div>
        <div onClick={() => setValue(false, false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="22" height="22" rx="11" fill="#F6F7F8" />
                <path d="M9 9L12 12L15 9" stroke="#2C2E3E" stroke-linecap="round" />
                <path d="M9 15L12 12L15 15" stroke="#2C2E3E" stroke-linecap="round" />
            </svg>
        </div>

    </div>
    <div className="mortgage-calc__part">
        <div className="mortgage-calc__part-row">
            <div style={{ width: '50%' }} className="hp__form-item">
                <label className="hp__form-label text"><span style={{ color: '#1e2030' }}>Дата платежа</span></label>
                <label className="input input_width_available input_type_form">
                    <DatePicker
                        dateFormat="dd.MM.yyyy"
                        showTimeSelect={false}
                        todayButton="Сегодня"
                        dropdownMode="select"
                        autoComplete="off"
                        onSelect={v => setValue("date", v.toLocaleDateString("us"))}
                        selected={toDate(values.date)}
                        shouldCloseOnSelect
                        showYearDropdown
                        showMonthDropdown
                        minDate={new Date(`${new Date().getMonth() + 2}.01.${new Date().getFullYear()}`)}
                        placeholderText={'18.07.2023'}
                        customInput={<div>
                            <InputMask
                                className={`input__control`}
                                mask="99.99.9999"
                                autoComplete="off"
                                maskChar=" "
                                placeholder={'18.07.2023'}
                                value={values.date}
                                onChange={e => setValue("date", e.target.value)}
                            />
                        </div>
                        }
                    />
                    <span className="input__control_postfix">
                        <svg style={{ marginBottom: -10, }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3636 6H5.63636C5.28491 6 5 6.24421 5 6.54545V17.4545C5 17.7558 5.28491 18 5.63636 18H18.3636C18.7151 18 19 17.7558 19 17.4545V6.54545C19 6.24421 18.7151 6 18.3636 6Z" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 5V7" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 5V7" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5 9H19" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 12L11.7574 15L10 13.3636" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </label>
            </div>
            <div style={{ width: '50%' }} className="hp__form-item">
                <label className="hp__form-label text"><span style={{ color: '#1e2030' }}>Переодичность платежей</span></label>
                <label className="input input_width_available input_type_form mortgage-calc__select">
                    <Dropdown2
                        options={periods}
                        value={periods.find(({id})=>id === values.period) || {id: "monthly", title: "Ежемесячный"}}
                        onChange={v => setValue("period", v)}
                    />

                </label>
            </div>
        </div>

        <div className="mortgage-calc__part-row">
            <div style={{ width: '50%' }} className="hp__form-item">
                <label className="hp__form-label text"><span style={{ color: '#1e2030' }}>Уменьшить</span></label>
                <label className="input input_width_available input_type_form mortgage-calc__select">
                    <Dropdown2
                        options={types}
                        value={types.find(({id})=>id === values.type) || {id: "payment", title: "Платеж"}}
                        onChange={v => setValue("type", v)}
                    />

                </label>
            </div>
            <div style={{ width: '50%' }} className="hp__form-item">
                <label className="hp__form-label text" style={{ color: '#1e2030' }}><span>Сумма</span></label>
                <label className="input input_width_available input_type_form">
                    <input
                        type="text"
                        className={`input__control`}
                        onChange={e => setValue("sum", sanitizeNumber(e.target.value))}
                        value={formatMoney(values.sum)}
                    />
                    <span className="input__control_postfix" style={{ color: '#1e2030' }}>₽</span>
                </label>
            </div>
        </div>
    </div>
</div>

export const sanitizeNumber = v => `${v}`.replace(/\D/gi, "");

export const createLimitSanitizer = (min, max) => v => v > max ? max : v < min ? min : v;

export const extractFields = (object, fields, index) => {
    if (!object) return {}
    const result = {};
    const isSecondCalcOnly = object.price?.length === 1 && !!object.priceIncreasePercent

    fields.forEach(key => {
        const value = object?.[key]?.length === 1 && !!object.priceIncreasePercent ? object[key][0] : object?.[key]?.[index];

        if (value && !(index === 0 && isSecondCalcOnly)) result[key] = +value;
    })

    return result
}