const initialState = {
  isFetching: false,
  error: false,
  contacts: null,
  filterData: null,
  groups: [],
  filter: {
    searchTerm: "",
    builder: [],
    department: [],
  },
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case "CONTACTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "CONTACTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        contacts: action.contacts,
        filterData: action.filterData,
        groups: action.groups,
        managersOnDuty : action.managersOnDuty,
      };
    case "CONTACTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "CONTACTS_RESET":
      return { ...initialState };
    case "CONTACTS_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "CONTACTS_CLEAR_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

export default contacts;
