import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchClients } from '../../actions/clients';
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from '../../actions/favorites';
import { getCount, getVillage, getVillageLands } from '../../actions/suburbans';
import useMedia from '../../hooks/useMedia';
import useRootCSS from "../../hooks/useRootCSS";
import { ReactComponent as Calculator } from "../../images/svg/calc.svg";
import { ReactComponent as Desc } from "../../images/svg/desc.svg";
import { cleanFilter } from "../../reducers/complex";
import { getLocalSavedFilter } from '../../utils';
import Collapse from '../core/Collapse';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import Mortgage from "../MortgageIn";
import Preloader from "../Preloader";
import Header from "../Public/markup/Header";
import About from "./About";
import Apartments from "./Apartments";
import Block from "./Block";
import "./index.scss";
import OtherBlocks from "./OtherBlocks";
import { filterToParam } from './Utils';
var inDebounce;
const Complex = (props) => {
  const { city, id, savedFilterXmlId } = useParams();
  const isPublic = !!savedFilterXmlId;
  const {
    reset,
    village,

    addToFavorites,
    news,
    actions,
    removeFromFavorites,
    otherBlocks,
    isClientMode,
    userInfo,
    fetchClients,
    clients,
    addClientToFavorite,
    removeClientFromFavorite,

    setFilter,
    filter,
    clearFilter,
    filterData,
    apartments,
    count,
    getCount,

    getVillageLands,
    suburbans,
    getVillage,
    savedFilterSettings = {},
    agent,
    isFilterDisabled,
  } = props;


  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })


  const isMobile = useMedia('isMobile');
  const [isFetching, setIsFetching] = useState(true);
  const initialFilter = getLocalSavedFilter(
    'suburbansFilter',
    id,
    [
      'squareHouse',
      'squareParcel',
      'endings',
      'floors',
      'prices',
      'rooms',
      'status',
      'communications',
      'materials',
      'features',
      'countFloors',
      'isOnlyHanded',
      'type',
    ],
    ['bathroomTypes', 'countBedrooms'],
  );

  const checkRealtyFiltered = () => {
    return !isEqual(initialFilter, cleanFilter);
  };
  useEffect(() => {
    setFilter(initialFilter);
    const params = filterToParam(initialFilter, id, city);
    for (const [key, value] of Object.entries({
      isGetFilter: 1,
      isGetSuburbans: 1,
      ...(!!savedFilterXmlId ? { savedFilterXmlId } : []),
    })) {
      params.append(key, value);
    }
    getVillage(params).then(() => setIsFetching(false));
    if (!savedFilterXmlId) {
      fetchClients();
    }
    return () => {
      reset();
    };
  }, [id, city]);

  useEffect(() => {
    if (checkRealtyFiltered() && apartments?.length > 0 && !haveApartments) {
      setHaveApartments(true);
      const params = filterToParam(initialFilter, id, city);
      if (savedFilterXmlId) {
        params.append('savedFilterXmlId', savedFilterXmlId);
      }
      getCount(params);
    }
  }, [apartments]);
  useEffect(() => {
    document.title = `Посёлок «${village ? village.name : ''}»`;
  }, [village]);

  const getLands = () => {
    const params = filterToParam(filter, id, city);
    if (savedFilterXmlId) {
      params.append('savedFilterXmlId', savedFilterXmlId);
    }
    getVillageLands(params);
  };

  const handleSetFilter = (data, savedFilterXmlId) => {
    let tmpFilter = { ...filter, ...data };
    setFilter(tmpFilter);
    clearTimeout(inDebounce);
    const params = filterToParam(tmpFilter, id, city);
    if (savedFilterXmlId) {
      params.append('savedFilterXmlId', savedFilterXmlId);
    }
    inDebounce = setTimeout(() => {
      getCount(params);
    }, 300);
  };
  const handleFavoritesClick = (inFavorite) => {
    inFavorite
      ? removeFromFavorites(city, id, 'village', 'suburban')
      : addToFavorites(city, id, 'village', 'suburban');
  };
  const [activeNav, setActiveNav] = useState(null);
  const [haveApartments, setHaveApartments] = useState(false);
  const menuHeight = 165;
  const handleScroll = (e) => {
    let refs = compact([
      pricesRef.current && {
        name: 'prices',
        bottom: pricesRef.current.getBoundingClientRect().bottom,
      },
      aboutRef.current && {
        name: 'about',
        bottom: aboutRef.current.getBoundingClientRect().bottom,
      },
      mortgageRef.current && {
        name: 'mortgage',
        bottom: mortgageRef.current.getBoundingClientRect().bottom,
      },
      newsRef.current && {
        name: 'news',
        bottom: newsRef.current.getBoundingClientRect().bottom,
      },
      otherRef.current && {
        name: 'other',
        bottom: otherRef.current.getBoundingClientRect().bottom,
      },
      banksRef.current && {
        name: 'banks',
        bottom: banksRef.current.getBoundingClientRect().bottom,
      },
      promoRef.current && {
        name: 'promo',
        bottom: promoRef.current.getBoundingClientRect().bottom,
      },
    ]);
    refs = minBy(refs, (ref) => {
      if (ref.bottom - menuHeight > 0) {
        return ref.bottom;
      }
    });
    setActiveNav(refs?.name);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      localStorage.removeItem('suburbansFilter');
    };
  }, []);
  const pricesRef = useRef(null);
  const aboutRef = useRef(null);
  const mortgageRef = useRef(null);
  const newsRef = useRef(null);
  const otherRef = useRef(null);
  const banksRef = useRef(null);
  const promoRef = useRef(null);

  const navigation = useMemo(() => {
    let nav = [];

    if (haveApartments) {
      nav.push({
        title: 'Планировки и цены',
        ref: pricesRef,
        type: 'prices',
      });
    }
    if (village?.description?.length > 0) {
      nav.push({
        title: 'Описание',
        ref: aboutRef,
        type: 'about',
      });
    }

    // if (otherBlocks.length > 0) {
    //   nav.push({
    //     title: "Инфраструктура",
    //     ref: aboutRef,
    //   });
    // }

    if (village && village.mortgageManager && haveApartments) {
      nav.push({
        title: 'Ипотека',
        ref: mortgageRef,
        type: 'mortgage',
      });
    }
    if (actions?.length > 0) {
      nav.push({
        title: 'Скидки и акции',
        ref: promoRef,
        type: 'promo',
      });
    }
    if (news?.length > 0) {
      nav.push({
        title: 'Новости',
        ref: newsRef,
        type: 'news',
      });
    }
    if (otherBlocks?.length > 0) {
      nav.push({
        title: 'Другие ЖК застройщика',
        ref: otherRef,
        type: 'other',
      });
    }

    return nav;
  }, [village, haveApartments]);
  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  return (
    <>
      {agent && (
        <div className="sf wrapper sf_inner-public">
          <Header {...agent} savedFilterSettings={savedFilterSettings} />
        </div>
      )}
      <div class="complex">
        {village && (
          <div>
            <Block
              block={village}
              handleFavorites={handleFavoritesClick}
              navigation={navigation}
              isClientMode={isClientMode}
              clients={clients}
              addClientToFavorite={addClientToFavorite}
              removeClientFromFavorite={removeClientFromFavorite}
              fetchClients={fetchClients}
              activeNav={activeNav}
              haveApartments={haveApartments}
              isPublic={isPublic}
            />
            <Apartments
              city={city}
              id={id}
              manager={village.manager}
              innerRef={pricesRef}
              setHaveApartments={setHaveApartments}
              haveApartments={haveApartments}
              clientMode={isClientMode}
              userInfo={userInfo}
              filter={filter}
              setFilter={handleSetFilter}
              clearFilter={clearFilter}
              filterData={filterData}
              apartments={suburbans}
              getApartments={getLands}
              count={count}
              isPublic={isPublic}
              savedFilterXmlId={savedFilterXmlId}
            />

            {isMobile ? (
              <div className="complex__collapse">
                <Collapse
                  title={
                    <>
                      <span className="complex__collapse-icon">
                        <Desc />
                      </span>
                      Описание
                    </>
                  }
                >
                  <About
                    title={`Посёлок «${village.name}»`}
                    description={village.description}
                    advantages={village.advantages}
                    innerRef={aboutRef}
                  />
                </Collapse>
                {haveApartments && !isPublic && (
                  <Collapse
                    title={
                      <>
                        <span className="complex__collapse-icon">
                          <Calculator />
                        </span>
                        Рассчитать ипотеку
                      </>
                    }
                  >
                    <Mortgage
                      manager={village.mortgageManager}
                      id={village.id}
                      innerRef={mortgageRef}
                      price={village.suburbansInfo?.minPrice}
                      period={15}
                      prepay={round(village.suburbansInfo?.minPrice * 0.2, -4)}
                      percent={6.5}
                    />
                  </Collapse>
                )}
              </div>
            ) : (
              <>
                <About
                  title={`О «${village.name}»`}
                  description={village.description}
                  advantages={village.advantages}
                  innerRef={aboutRef}
                />
                {haveApartments && !isPublic && (
                  <ErrorPlug wrappedComponent="src/comp-ts/Complex/index.jsx">
                    <Mortgage
                      manager={village.mortgageManager}
                      id={village.id}
                      innerRef={mortgageRef}
                      price={village.suburbansInfo?.minPrice}
                      period={15}
                      prepay={round(village.suburbansInfo?.minPrice * 0.2, -4)}
                      percent={6.5}
                      className="for-suburban"
                      type={'suburban'}
                    />
                  </ErrorPlug>
                )}
              </>
            )}

            {/* <Subscribe email={userInfo.email} /> */}

            {props.otherVillages?.length > 0 && (
              <OtherBlocks
                items={props.otherVillages}
                innerRef={otherRef}
                city={city}
                isPublic={isPublic}
              />
            )}
          </div>
        )}
        <Preloader loading={isFetching} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch({ type: 'VILLAGE_SET_FILTER', filter }),
  clearFilter: () => dispatch({ type: 'VILLAGE_CLEAR_FILTER' }),
  getVillage: (form) => getVillage(form, dispatch),
  getVillageLands: (form) => getVillageLands(form, dispatch),
  getCount: (form) => getCount(form, null, dispatch),
  reset: () => dispatch({ type: 'SUBURBAN_RESET' }),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        whatPage,
        type,
      ),
    ),
});

const mapStateToProps = (state) => {
  return {
    block: state.suburban.village,
    otherBlocks: state.complex.otherBlocks,
    isClientMode: state.general.clientMode,
    userInfo: state.user.info,
    clients: state.clients.data,

    apartments: state.suburban.suburbans,
    count: state.suburban.count,

    filterData: state.suburban.filterData,
    filter: state.suburban.filter,
    otherVillages: state.suburban.otherVillages,
    suburbans: state.suburban.suburbans,
    villages: state.suburban.villages,
    village: state.suburban.village,
    savedFilterSettings: state.suburban.savedFilterSettings,
    agent: state.suburban.agent,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Complex);
