import React from "react";

const BlockFilters = (props) => {
  const { items, innerRef } = props;

  return (
    <div className="client__section has-offset" ref={innerRef}>
      <div className="client__section-header">
        <h3 className="client__section__title">Фильтры</h3>
      </div>
      <div className="client__table"></div>
    </div>
  );
};

export default BlockFilters;
