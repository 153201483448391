import React from 'react'
import Checkboxes from './Checkboxes'
import InputField from './InputField'
import RadioButtons from './RadioButtons'
import Textarea from './Textarea'
const Distributor = ({ questions, form, handleChange }) => {
    return questions.map((question, i) => {
        switch (question.type) {
            case "checkbox":
                return <Checkboxes {...question} form={form} handleChange={handleChange} key={`question-${i}`} />
            case "radio":
                return <RadioButtons {...question} form={form} handleChange={handleChange} key={`question-${i}`} />
            case "date":
                return <InputField {...question} form={form} handleChange={handleChange} key={`question-${i}`} />
            case "textarea":
                return <Textarea {...question} form={form} handleChange={handleChange} key={`question-${i}`} />
            default: return ""
        }
    })
}

export default Distributor
