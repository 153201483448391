
import {  useDispatch } from 'react-redux';
import { useRef, useEffect } from "react";


const useSaveRef = (name, defaultValue = null) => {
    const ref = useRef(defaultValue)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: "SET_REF", payload: { [name]: ref } })
    }, [ref])
    return ref

};

export default useSaveRef;
