import React, { useState } from "react";
import SearchInp from "../../components/core/SearchInput";
import Dropdown from "../../components/core/Dropdown";
import Button from "../../components/core/Button";
import './index.scss'
import SelectFilter from "../../components/FilterRealty/SelectFilter";
import { SfFilterTrashIcon } from "../../components/Public/markup/icons";

const CrmFilterBottomRow = ({ filters = {}, setFilter, filter, permissions }) => <div className="crm-filters-header__second-row">
  {Object.entries(filters).map(([ident, { title, permissionRequired }]) => !permissionRequired || permissions[permissionRequired] ?
    <div className="checkbox checkbox_view_red" key={ident}>
      <div className="checkbox__box">
        <input
          className="checkbox__control"
          type="checkbox"
          id={`crm-checkbox-${ident}`}
          name="notify"
          checked={!!filter[ident]}
          onChange={e => setFilter(ident, e.target.checked)}
        />
        <label className="checkbox__input" htmlFor={`crm-checkbox-${ident}`} />
        <div className="checkbox__marker" />
      </div>
      <label className="checkbox__label" htmlFor={`crm-checkbox-${ident}`}>
        {title}
      </label>
    </div> :
    null
  )}
</div>

const MainCrmFilter = ({ setFilter, filter, tabFilters = {}, onDelete, showDeleteButton, openFixationModal, filterData, searchPlaceholder, link = {},  permissions, url }) => {

  if (!filterData) return null
  return <div className="crm-filters-header__first-row" style={{position: 'relative'}}>
    <div className="crm-filters-header__left-cell">
      <div className={`crm-filters-header__search`} style={url === "/crm/filters" ? {width: 320} : {}}>
        <SearchInp
          value={filter.search}
          onChange={e => setFilter("search", e.target.value)}
          reset={() => setFilter("search", "")}
          placeholder={searchPlaceholder}
        />
      </div>
      {Object.entries(tabFilters).map(([ident, { type, dataKey, props = {}, permissionRequired }]) => {
        if(!filterData[dataKey ?? ident] || (permissionRequired && !permissions[permissionRequired])) return null

        switch (type) {
          case "multiple":
            return <div className="crm-filters-header__select" key={ident}>
              <SelectFilter
                items={filterData[dataKey ?? ident]}
                filterValues={filter[ident]}
                onChange={v => setFilter(ident, v)}
                isAutocomplete={filterData[dataKey ?? ident]?.length > 5}
                wrapperClass={"custom-select"}
                {...props}
              />
            </div>
          case "date":
          case "radio":
            return <div className="crm-filters-header__select" key={ident}>
              <Dropdown
                options={filterData[dataKey ?? ident]?.items ?? filterData[dataKey ?? ident]}
                value={filter[ident]}
                onChange={v => setFilter(ident, v)}
                placeholder="—"
                {...props}
              />
            </div>
          default:
            return null;
        }
      })}
    </div>

    {link?.buttonLink && <div className="crm-filters-header__right-cell">
      <Button isLink to={link?.buttonLink} buttonType="primary">{link?.buttonTitle}</Button>
    </div>}
    {openFixationModal &&
      <Button onClick={openFixationModal} buttonType="primary">Зафиксировать</Button>
    }
    {showDeleteButton && <div className="crm-filters-header__right-cell" onClick={onDelete}>
      <div className="common-link common-link_dark" >
        <SfFilterTrashIcon />
        Удалить выбранные подборки
      </div>
    </div>}

  </div>
}

export { MainCrmFilter, CrmFilterBottomRow }
