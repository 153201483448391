import CardsList from '../../components/Public/markup/CardsList';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import MapBlock from '../../components/Public/markup/MapBlock';
import MapBtn from '../../components/Public/markup/MapBtn';
import Title from '../../components/Public/markup/Title';
import TopBar from '../../components/Public/markup/TopBar';
import './index.scss';

import SubmitButton from '../../components/SubmitButton';
import useFilter from '../../hooks/useFilter';
import useMedia from '../../hooks/useMedia';
import { getSuffix, media } from '../../utils';

import Dropdown from '../../components/core/Dropdown';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import Preloader from '../../components/Preloader';
import RealtyMap from '../../components/Public/markup/Map';
import { sortlistVillages } from '../../hooks/useFilter/filterSets';
import { extractSuburbansMapItems, toSearchParams, getFilterStateFromParams } from '../../hooks/useFilter/filterUtils';

import { useEffect, useRef, useState } from 'react';

import { useModal } from 'react-modal-hook';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';


import DraggableZone from '../../components/FilterRealty/Draggable';
import SmartFilter from '../../components/FilterRealty/SmartFilter';
import VillageCardContainerNew from '../../components/Public/markup/Containers/VillageCardContainerNew';
import MapFilters from '../../components/Public/markup/MapFilters/Index';
import MobileMapFilter from '../../components/Public/markup/MapFilters/MobileMapFilter';
import VillagesPublicFilter from '../../components/Public/markup/VillagesPublicFilter';
import VillagesTopFilter from '../../components/Public/markup/VillagesTopFilter';
import VillagesFilter from '../../components/Public/markup/VillagesFilter';
import Top from '../../components/Secondaries/Top';
import PrepaidBanner from '../../components/PrepaidBanner';
import { useSelector } from 'react-redux';
import usePermission from '../../hooks/usePermission';
import SendFilterReworked from '../../components/SavedFilters/SendFilterReworked';
import { ReactComponent as SavedFilterIcon } from '../../assets/images/icons/SaveFilter.svg'
import { useParams } from 'react-router-dom';
import { TgDubaiIcon } from "../../components/Public/markup/icons";
import { FilterSearch } from '../../components/FilterRealty/FilterSearch';
import EmptyTable from '../../components/core/EmptyTable';
import VillagesNewTopFilter from '../../components/Public/markup/VillagesNewTopFilter';
import VillagesParcelfilter from '../../components/Public/markup/VillagesParcelfilter';
import VillagesHouseFilter from '../../components/Public/markup/VillagesHouseFilter';
import VillagesTogglesFilter from '../../components/Public/markup/VillagesTogglesFilter';
import SelectFilter from '../../components/FilterRealty/SelectFilter';
import RadioDropdown from '../../components/core/RadioDropdown';
import { FilterDebouncedSearch } from '../../components/FilterRealty/FilterDebouncedSearch';
import request from '../../api';
import useDocTitle from "../../hooks/useDocTitle";
import { effect, effectUseRootCSS } from '../../hooks/useRootCSS';
import FilterDisabled from '../../components/Public/FilterDisabled';
import { getURLparam } from '../../utils/location';
import { uniqBy } from 'lodash';
import VillagesGeneralFilter from '../../components/Public/markup/VillagesGeneralFilter';

const filterFromParams = getFilterStateFromParams()

const SfButton = ({ onClick }) => <div className="villages-save-filter" onClick={onClick}>
  <SavedFilterIcon />
  <span>Сохранить фильтр</span>
</div>
const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => { // to do удалить эту шляпу из всех разделов и вынести как компонент
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}
    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};

const VillagesNew = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');

  useDocTitle("Загородная недвижимость");

  const clientMode = useSelector(state => state.general.clientMode);
  const banners = useSelector(state => state.banners);
  const isAgent = usePermission('agent');
  const { city, savedFilterXmlId } = useParams()

  const [localState, setLocalState] = useState({
    showFilter: false,
    realtyType: getURLparam("realtyType") || "unset",
    searchValue: "",
    searchResults: [],
    searchData: []
  });

  const localStateRef = useRef(localState)
  localStateRef.current = localState;

  const {
    handleFilter,
    dispatchFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      savedFilterSettings,
      villages = [],
      topVillages,
      filter: filterData = {},
      telegramChannel,
      isActive,
      isShowFilter,
      agent,
      savedFilter
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent,
    setFilterState,
    favoritesProps,
    get
  } = useFilter({
    apiConfig: {
      component: 'panpartner:new.suburbans.ajax',
      getCountFunction: 'getCountVillages',
      submitFunction: 'getVillages',
      getMapItems: 'getVillages',
    },
    defaultFilter: {
      status: [1],
      realtyType: localState.realtyType
    },
    defaultParams: {
      getAllFilters: 1,
      type: localStateRef.current.realtyType,
      ...(savedFilterXmlId ? { savedFilterXmlId } : []),
    },
    // defaultOrder: 'price',
    // defaultBy: 'asc',
    defaultSortId: "0",
    sortList: sortlistVillages,
    sortEnabled: true,
    paginationEnabled: true,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'villages',
    shadowFilters: ['status', "realtyType"],
    extractCount: ({ countVillages }) => countVillages,
    extractLoadMoreCount: ({ countVillages }) => countVillages,
    extractMapItems: ({ villages }) => villages,
    processData: (resp, prev) => {
      return { ...prev, ...resp };
    },
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },

    favoritesParams: {
      city,
      type: "cottageSettlement",
    },

    fields: {
      villages: [],
      status: [],
      districts: [],
      rooms: [],
      endingDate: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
    badgeFormattersParams: {
      data: localStateRef.current.searchData
    },
    specialBadgeTitles: {
      endingDate: "Срок сдачи:"
    },
    filterFromLS: !savedFilterXmlId && filterFromParams,
    legacyRestoreFromLS: !!savedFilterXmlId,
    restoreSF: !!savedFilterXmlId,
    configAsRef: false
  });

  useEffect(() => {
    if (isLoading || savedFilterXmlId) return
    const tmpFilter = {...filter}
    delete tmpFilter.realtyType
    toSearchParams(tmpFilter, exclude, `&realtyType=${localStateRef.current.realtyType}`)
  }, [filter, exclude, localState])


  useEffect(() => {
    if (!savedFilterXmlId || !savedFilterSettings) return
    effectUseRootCSS({
      '--colorButton': savedFilterSettings?.colorButton,
      '--colorHeader': savedFilterSettings?.colorHeader,
      '--colorButtonText': savedFilterSettings?.colorButtonText,
      '--colorHeaderText': savedFilterSettings?.colorHeaderText,
      '--colorHover': savedFilterSettings?.colorHover
    })
    if(savedFilter?.realtyType) setLocalState(prev => ({ ...prev, realtyType: savedFilter.realtyType }))
  }, [savedFilterSettings])

  const onSearch = query => {
    setLocalState(prev => ({ ...prev, searchValue: query }))
    if (!query) return setLocalState(prev => ({ ...prev, searchValue: "", searchResults: [] }))
    request('panpartner:new.suburbans.ajax', "search", { query }).then(resp => {
      setLocalState(prev => ({ ...prev, searchResults: resp.result, searchData: uniqBy([...prev.searchData, ...resp.result], "title") }))
    })
  };

  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);


  const saveToLocalSotage = id => localStorage.setItem("suburbansFilter", JSON.stringify({ [id]: filter  }))

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <MobileMapFilter
            component={props => <>
              <VillagesTopFilter
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
              <VillagesFilter
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count} объект${getSuffix(
                  count,
                )}`}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
              <button
                className="button button_view_outline for-mobile-filters"
                type='button'
                style={{ margin: "10px 0" }}
                onClick={saveFilterModal}
              >
                Сохранить фильтр
              </button>

            </>}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={() => {
              closeFilter();
              submit()
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );

  // умный поиск, копипаста кода Айбека, отрефакторить и вынести как обёртку  или забить болт
  const [showFilter, toggleFilter] = useState(false);

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SendFilterReworked
          filter={filter}
          exclude={exclude}
          filterData={filterData}
          // filterType={localState.realtyType}
          filterType={'cottageSettlement'}
          hide={hideSaveFilterModal}
          city={city}
          filterBadges={filterBadges}
          excludeBadges={excludeBadges}
          addParams={{"realtyType": localState.realtyType }}
        />
      </Wrap >
    ),
    [filter, exclude, filterData, localState],
  );
  if (!isLoading && !!savedFilterXmlId && !isActive) return <FilterDisabled agent={agent} />;
  if (isLoading && !villages?.length) return <Preloader loading={true} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems}
          extractFeatures={extractSuburbansMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}

        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={VillageCardContainerNew}
          getLink={(item) => savedFilterXmlId ? `/public/suburban/${savedFilterXmlId}/spb/${item.id}`  :`/suburban/spb/${item.id}`}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={VillagesPublicFilter}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          onClick={saveToLocalSotage}
          dubai
          {...favoritesProps}
          get={get}
          isPublic={!!savedFilterXmlId}
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      <h3 className="h3 sf__h1 with-tg-link">
        <p>Загородная недвижимость</p>
        {
          !isLaptop && !savedFilterXmlId &&
          <a href={telegramChannel && telegramChannel} target="_blank" className="with-tg-link__link">
            Telegram-канал о загородной недвижимости
            <TgDubaiIcon />
          </a>
        }
      </h3>
      <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_new-suburban ${!isLaptop ? "" : "flex"}`}>
        <FilterDebouncedSearch
          searchResultHandler={(v, key) => {
            handleFilter(key, v)
            setLocalState(prev => ({ ...prev, searchResults: [] }))
          }}
          results={localState.searchResults}
          onSearch={onSearch}
          value={localState.searchValue}
          placeholder="Название КП, застройщик, район"
          toggleFilter={() => toggleFilter(prev => !prev)}
          hideInfoButton
          suburbansSearch
        />
        <RadioDropdown
          onChange={v => {
            setLocalState(prev => ({ ...prev, realtyType: v }))
            setFilterAndExclude({ filter: v }, "realtyType")
          }}
          items={[{ id: "parcel", title: "Участок" }, { id: "house", title: "Дома" }, { id: "unset", title: "Не выбран" },]}
          value={localState.realtyType}
          data={{ title: "Тип" }}
        />

        <VillagesNewTopFilter
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          onSumbit={submit}
          isLoading={isCountLoading || isLoading}
          disableButton={!count}
          clearByIdent={clearByIdent}
          wrapperClass=" "
          type={localState.realtyType}
        />

        <button
          className="button button_type_reveal-filter white-button"
          type="button"
          onClick={() => toggleFilter(prev=>!prev)}
        >
          <span >Все фильтры</span>
        </button>
      </div>
      {showFilter && <>
        <div className={`sf__filter filter__max filter__max_six form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>
          <VillagesGeneralFilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            wrapperClass=" "
          />
        </div>
        <div className={`sf__filter filter__max filter__max_six form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>
          <VillagesParcelfilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            wrapperClass=" "
            disabledFilters={localState.realtyType === "house" && ["onlySelfBuild"]}
          />
        </div>
        <div className={`sf__filter filter__max filter__max_six form smartfilter-wrapper ${!isLaptop ? "" : "flex"}${localState.realtyType === "parcel" ? " disabled-filter-row" : ""}`}>
          <VillagesHouseFilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            wrapperClass=" "
          />
        </div>
        <div className={`sf__filter filter__max filter__max_multi form smartfilter-wrapper ${!isLaptop ? "" : "flex"}${localState.realtyType === "parcel" ? " disabled-filter-row" : ""}`} >
          <VillagesTogglesFilter
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            wrapperClass=" "
          />
        </div>
        <div style={{ padding: "0 16px" }}>


          <div className="sf__filter filter__max form filter__max_second">

            <div className="form__row">
              <div className="index-search__show-booked" style={{ marginTop: 0 }}>
                <label className="switch" htmlFor="filter-booked">
                  <div className="switch__box">
                    <input
                      className="switch__control"
                      type="checkbox"
                      id="filter-booked"
                      checked={filter.status.includes("3")}
                      onChange={e => handleFilter("status", "3")}
                    />
                    <div
                      className="switch__input"
                      htmlFor="filter-booked"
                    ></div>
                    <div className="switch__marker"></div>
                  </div>
                  <span className="switch__label">
                    Забронированные
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </>}


      <DraggableZone
        filterBadges={filterBadges}
        excludeBadges={excludeBadges}
        clearFilter={clearFilter}
        clearExclude={clearExclude}
        isExcludeEmpty={isExcludeEmpty}
        isFilterEmpty={isFilterEmpty}
        removeBadge={removeBadge}
        switchValues={switchFilterToExclude}
      />

      <div className="sf__row">
        {!isTablet && <SfButton onClick={saveFilterModal} />}
        <div />
        <div className="sf__filter-btns">
          {isTablet && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn
            setShowMap={() => setView('map')}

          />
          {!isTablet && (
            <span style={isLaptop ? { marginLeft: 20 } : {}}>
              <FilterSubmitButton
                onClick={()=>submit({params: {"filter[realtyType]": localState.realtyType}})}
                isLoading={isCountLoading || isLoading}
                count={count}

              />
            </span>
          )}
        </div>
      </div>
      {topVillages?.length > 0 && (
        <Top
          isAgent={isAgent}
          items={topVillages}
          clientMode={clientMode}
          title={`Лучшие предложения`}
          isSuburbans={true}
          wrapperStyle={(banners?.assignments || banners?.before) && !clientMode ? { paddingBottom: 0 } : null}
          redisign
        />
      )}
      {banners?.before && <div className="wrapper">
        <PrepaidBanner bannerId="before" banners={banners} />
      </div>}
      {!villages?.length && <EmptyTable title="Ничего не найдено" hideBorder />}
      {!!villages?.length && <div className="sf__row">
        {/*{!isTablet && (*/}
        {/*  <div className="special-filter-drop">*/}
        {/*    <Dropdown*/}
        {/*      innerClass={``}*/}
        {/*      value={sortId}*/}
        {/*      options={sortlistVillages}*/}
        {/*      onChange={handleSort}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
        <h4 className="h4">{`Найдено ${count} объект${getSuffix(count)}`}</h4>
        <div />
        <div className="sf__filter-btns">
        </div>
      </div>}
      <CardsList
        items={villages}
        limit={limit}
        isLoading={showSkeleton || isLoading}
        itemsRef={infiniteRef}
        cardComponent={VillageCardContainerNew}
        getLink={(item) => savedFilterXmlId ? `/public/suburban/${savedFilterXmlId}/spb/${item.id}?realtyType=${localState.realtyType}`  :`/suburban/spb/${item.id}?realtyType=${localState.realtyType}`}
        listClass="sf__cards-list sf__cards-list_subuebans"
        // listClass="sf__cards-list sf__cards-list_dubai-complex-list"
        onClick={saveToLocalSotage}
        {...favoritesProps}
        get={get}
        isPublic={!!savedFilterXmlId}
      />
      <div style={{ marginTop: '-30px', marginBottom: 30 }}><LoadMoreSpinner loading={moreIsLoading} /></div>
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count) && (
            <CountRealty
              itemsCount={count}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default VillagesNew;
