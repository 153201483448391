import React, { useMemo, useRef, useState } from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import { formatDate, media } from "../../utils";
import { errorToast } from "../toasts";



const PhotoPopup = (props) => {
  const { items, index = 0, open, onClose } = props;

  const options = useMemo(() => {
    return {
      index: index === 0 ? 0 : index - 1,
      closeOnScroll: false,
      closeOnVerticalDrag: false
    };
  }, [index]);

  const photos = useMemo(
    () =>
      items.map((item) => ({
        src: getUrl(item.src),
        w: item.width,
        h: item.height,
      })),
    [items],
  );

  if (!photos.length && open) {
    errorToast(<div>На данном мероприятии отсутствуют фотографии</div>)
    onClose();
    return null;
  }

  return (
    <PhotoSwipe
      isOpen={open}
      items={photos}
      options={options}
      onClose={onClose}
    />
  );
};

const Reports = (props) => {
  const { data, identity } = props;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [openReport, setOpenReport] = useState(0);

  const navRef = useRef(null);
  const node = useRef(null);

  const settings = useMemo(
    () =>
      navRef
        ? {
            items: media('isMobile') ? 1 : 3,
            controls: false,
            gutter: media('isMobile') ? 0 : 30,
            nav: false,
            loop: false,
            responsive: {
              1240: {
                items: 4,
              },
            },
          }
        : {},
    [navRef],
  );

  const handleChangeIndex = (info) => {
    setIndex(info.index - 1);
  };

  const handleOpen = (index) => {
    setOpenReport(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  if (!data) return null;

  return (
    <div className="events__reports">
      <div className="events__reports-header">
        <div className="events__reports-title">
          <h2 className="h2">Отчеты с мероприятий</h2>
        </div>
        {!media('isMobile') &&
          <div className="events__reports-controls">
            <ul className="tabs__navs" id="reports-nav">
              <li
                className="tabs__navs-item prev"
                onClick={() => moveSlider("prev")}
              >
                <svg className="icon icon-dark_stroke">
                  <use xlinkHref="#icon-left_arrow"></use>
                </svg>
              </li>
              <li
                className="tabs__navs-item next"
                onClick={() => moveSlider("next")}
              >
                <svg className="icon icon-dark_stroke">
                  <use xlinkHref="#icon-right_arrow"></use>
                </svg>
              </li>
            </ul>
          </div>
        }
      </div>
      <div className="events__reports-slider">
        <TinySlider
          className="reports__slider"
          settings={settings}
          ref={node}
          onIndexChanged={handleChangeIndex}
        >
          {data.map((report, index) => (
            <div key={index}>
              <div className="reports__item" onClick={() => handleOpen(index)}>
                <div className="reports__item-image">
                  <img src={getUrl(report.photo.src)} alt="" />
                </div>
                <div className="reports__item-name link-hover">
                  {report.name}
                </div>
                <div className="reports__item-date">
                  {formatDate(report.date, {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })}
                </div>
              </div>
            </div>
          ))}
        </TinySlider>
        <PhotoPopup
          open={open}
          items={data[openReport].photos}
          index={index}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};

export default Reports;
