
import request from '../api';
import { actionFactory } from "./actionUtils";

export const setState = (state) => ({
  type: 'CONSTRUCTOR_SET_STATE',
  payload: state
});

const requestConstructor = actionFactory("CONSTRUCTOR_REQUEST");

const successConstructor = actionFactory("CONSTRUCTOR_SUCCESS");

const failureConstructor = actionFactory("CONSTRUCTOR_FAILURE");

const fetch = (method, args = {}) => request("panpartner:agent.sites.ajax", method, args)

export const fetchConstructor = (subDomain) =>
  dispatch => {
    dispatch(requestConstructor());
    fetch("getAdminSite", { subDomain }).then(
      data => {
        dispatch(
          successConstructor({
            data
          }),
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureConstructor());
        return Promise.reject(error);
      },
    );
  };

export const editSite = form => fetch("editSite", form);

export const deleteSite = id => fetch("deleteSite", { id });

export const checkSiteDomain = subDomain => fetch("checkSiteDomain", { subDomain });

const requestAgency = actionFactory("AGENCY_REQUEST");

const successAgency = actionFactory("AGENCY_SUCCESS");

const failureAgency = actionFactory("AGENCY_FAILURE");

export const fetchAgency = subDomain =>
  dispatch => {
    dispatch(requestAgency());
    fetch("getSite", { subDomain })
      .then(
        data => {
          dispatch(successAgency({ data }));
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureAgency());
          return Promise.reject(error);
        },
      );
  };

const successGetBlocks = actionFactory("AGENCY_GETBLOCKS_SUCCESS");

const successLoadMoreBlocks = actionFactory("AGENCY_LOAD_MORE_BLOCKS_SUCCESS");

export const getBlocks = (form, isLoadMore) => dispatch => {
  dispatch({type: "GET_BLOCKS_REQUEST_AGENCY"})
  return fetch("getBlocks", form).then(data => {
  if (isLoadMore) {
    dispatch(successLoadMoreBlocks(data));
  } else {
    dispatch(successGetBlocks(data));
  }
  return Promise.resolve(data);
})
}

export const getBlock = form => request('panpartner:agent.sites.ajax', 'getBlock', form)

export const getVillage = form => request('panpartner:agent.sites.ajax', 'getVillage', form)

export const callback = form => request('panpartner:forms.ajax', 'callback', form)

export const getFormSettings = (formId, city = "spb", subDomain) => request('panpartner:forms.ajax',
  'getFormSettingsSite', { formId, city, subDomain }
)

export const sendForm = (formId, form, city = "spb") => {
  form.append("formId", formId)
  form.append("city", city)
  return request("panpartner:forms.ajax", "sendFormSite", form)
};

export const getSites = (params = {}, dispatch) => {
  request("panpartner:agent.sites.ajax", "getSites", params).then(data => {
    dispatch({ type: "CONSTRUCTOR_STATS_SUCCES", data: { sites: data.sites } })
  })
}

export const getAdv = (params = {}, dispatch) =>
  request("panpartner:advertising.ajax", "getAdvertisingList", params)
    .then(data => {
      dispatch({
        type: "CONSTRUCTOR_ADV_SUCCES",
        data: {
          landings: data.landings,
          agents: data.masters
        }
      })
    })

export const changeResponsible = (params = {}) => request("panpartner:advertising.ajax", "changeResponsible", params)

export const joinToQueue = (params = {}) => request("panpartner:advertising.ajax", "joinToQueue", params)

export const checkDomain = form => request('panpartner:agent.sites.ajax', 'checkDomain', form)
