import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ClientsAdd from "./Add";
import ClientsList from "./ClientsList";
import ClientsEdit from "./Edit";
import ClientsView from "./View";


const Clients = props => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={ClientsList} />
      <Route exact path={`${path}/add`} component={ClientsAdd} />
      <Route exact path={`${path}/edit/:id`} component={ClientsEdit} />
      <Route exact path={`${path}/:id`} component={ClientsView} />
    </Switch>
  );
};

export default Clients;
