import floor from 'lodash/floor';
import React from 'react';
import { media } from '../utils';

const adaptiveSquare = (square) => media("isMobile")
  ? floor(square)
  : square

const SquareTitle = ({ minSquare, maxSquare }) => (
  minSquare !== maxSquare ? (
    <div>
      <span className="nowrap">
        {!media("isMobile") && 'S '}
        от {adaptiveSquare(minSquare)}</span> до{" "}
      <span className="nowrap">{adaptiveSquare(maxSquare)} м²</span>
    </div>
  ) : (
    <div>
      <span className="nowrap">S {adaptiveSquare(minSquare)} м²</span>
    </div>
  )
);

export default SquareTitle;
