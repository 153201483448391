import React, { Suspense } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import ZagorodLanding from '../pages/ZagorodLanding';
import AppRoute from './AppRoute';
import Dashboard from './layout/Dashboard';
import Preloader from './Preloader';
import ScrollRouter from './ScrollRouter';


const AppSuburbansLanding = props => <ModalProvider>
  <Switch>
    <Suspense fallback={<Preloader loading={true} />}>
      <ScrollRouter />
      <AppRoute
        exact
        path="/"
        layout={Dashboard}
        component={ZagorodLanding}
        mobileVersionEnabled={true}
        isPublic={true}
        hideFooter
        hideHeader
      />
    </Suspense>
  </Switch>
  <ToastContainer
    position="top-right"
    autoClose={4000}
    hideProgressBar
    newestOnTop={false}
    rtl={false}
    pauseOnFocusLoss
    transition={Slide}
  />
</ModalProvider>

export default AppSuburbansLanding
