import { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

import { PriceCurency } from '../../FilterRealty/Components';
import DistrictsFilter from '../../FilterRealty/DistrictsFilter';


const DubaiPublicFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    currency,
    currencyList,
    setCurrency,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <SelectFilter
      {...createSelectProps("blocks")}
      data={{
        title: "Название ЖК",
        autocompleteTitle: "Введите название ЖК",
        identity: "blocks",
      }}
      wrapperClass={wrapperClass}
    />
    {!!filterData?.districts?.length > 0 && <DistrictsFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      isExcludable
      isAutocomplete
      city={"dxb"}
      setDistricts={filter => setFilterAndExclude({ filter }, "districts")}
      wrapperClass={wrapperClass}
      hideMapButton
    />}
    {!!filterData.prices?.[currency] && <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.[currency]?.items ?? []}
      wrapperClass={wrapperClass}
      data={{
        title: "Цена от-до",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.[currency]?.range ?? 0,
      }}
      onChange={v => setFilterAndExclude(v, "prices")}
      renderTopContent={PriceCurency}
      debounced
      autoPosition
      currencyList={currencyList}
      currency={currency}
      setCurrency={setCurrency}

    />}
    {!!filterData?.countBedrooms?.length > 0 && <SelectFilter
      {...createSelectProps("countBedrooms")}
      data={{
        title: "Кол-во спален",
        identity: "countBedrooms",
      }}
      isAutocomplete={false}
      wrapperClass={wrapperClass}
    />}
    <EndingsFilter
      autoPosition
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}
      wrapperClass={wrapperClass}
    />

    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    />
  </>
});

export default DubaiPublicFilters;
