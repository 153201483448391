import React from 'react';

const Advantages = ({ data: { object }, activeSettings }) => {

  return <div className="presentation__info pdf-block presentation__info_flat" >
    <div className={`presentation__box ${object.advantages?.length > 0 ? '' : ' hidden'}`} style={{ margin: "50px 0" }} data-id="complex_advantages" >
      <div className="presentation__title">Особенности {activeSettings?.complex_name && `ЖК «${object.blockName}»`}</div>
      {object.advantages.map((adv, i) => <div className="presentation__info-grid" key={i}>
        <div
          className="presentation__info-col"
          style={{
            maxWidth: '100%',
            flexBasis: 'auto',
          }}>
          {adv}
        </div>
      </div>)}
    </div>
  </div>
};

export default Advantages;