import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';



const Footer = () => {
  return (
    <footer className="hp__footer">
    <div className="wrapper">
      <div className="wrapper__center">

        <div className="hp__footer-content">
          <div className="hp__footer-item">
            <div className="hp__footer-img">
              <img className="hp__footer-logo" src={require('../../assets/images/hp/logoicon.svg').default} alt="" />
            </div>
            <div className="hp__footer-info">
              <span className="bold hp__footer-title">ПАН Партнер, 2010 — {new Date().getFullYear()}</span>
              <a href="https://panpartner.ru/polzovatel" style={{marginBottom: 6}} className="hp__footer-text link link_color_brand-3" target={`_blank`}>Пользовательское соглашение</a>
              <a href="https://panpartner.ru/policy" style={{marginBottom: 6}} className="hp__footer-text link link_color_brand-3" target={`_blank`}>Политика конфиденциальности</a>
              <a href="https://kad.arbitr.ru/Card/bc4c9de7-aaf2-4818-a8d9-64ead7dc5077" className="hp__footer-text link link_color_brand-3" target="_blank">
              Правовая информация
              </a>
            </div>
          </div>

          <div className="hp__footer-item">
            <div className="hp__footer-img">
              <img className="hp__footer-pin" src={require('../../assets/images/hp/pin.svg').default} alt="" />
            </div>
            <div className="hp__footer-info">
              <span className="bold hp__footer-title">Санкт-Петербург, Владимирский пр., д. 23 лит. А, офис 701</span>
              <a href="tel:+78124261863" className="hp__footer-text hp__footer-phone link link_color_brand-3 bold">+7 (812) 426-18-63</a>
            </div>
          </div>

          <div className="hp__footer-item">
            <div className="hp__footer-img">
              <img className="hp__footer-pin" src={require('../../assets/images/hp/pin.svg').default} alt="" />
            </div>
            <div className="hp__footer-info">
              <span className="bold hp__footer-title">Москва, Цветной бульвар, д. 30, стр. 1</span>
              <a href="tel:+74993221499" className="hp__footer-text hp__footer-phone link link_color_brand-3 bold">+7 (499) 322-14-99</a>
            </div>
          </div>


        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer;

