import { createSelector } from "reselect";

const estateMapSelector = (state) => state.estate.mapItems;
const estateViewSelector = (state) => state.estate.view;

export const mapItems = createSelector([estateMapSelector],
  (data = []) => data.filter(item => !!item.coordinates)
);

export const mapEstate = createSelector([estateMapSelector, estateViewSelector], (data = [], view) => {
  if (view !== "map") return []
  let features = [];
  for (const item of data) {
    if (item.coordinates) {
      features.push({
        type: "Feature",
        id: item.id,
        geometry: {
          type: "Point",
          coordinates: item.coordinates,
        },
        properties: {
          hintContent: item.name,
        },
      });
    }
  }
  return features;
});
