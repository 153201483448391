import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import './index.css';
import List from "./List";
import View from "./View";



const Presentations = (props) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={List} />
      <Route exact path={`${path}/edit/:id`} component={View} />
    </Switch>
  );
};

export default Presentations;
