import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import Header from '../../components/Public/markup/Header';
import MapBlock from '../../components/Public/markup/MapBlock';
import MapBtn from '../../components/Public/markup/MapBtn';
import Tags from '../../components/Public/markup/Tags';
import Title from '../../components/Public/markup/Title';
import TopBar from '../../components/Public/markup/TopBar';
import './index.scss';

import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import Preloader from '../../components/Preloader';
import PrimaryPublicCard from '../../components/Public/markup/Containers/PrimaryPublicCard';
import RealtyMap from '../../components/Public/markup/Map';
import PublicMobileFilter from '../../components/Public/markup/MapFilters/PublicMobileFilter';
import SubmitButton from '../../components/SubmitButton';
import Dropdown from '../../components/core/Dropdown';
import useFilter from '../../hooks/useFilter';
import { sortListProjects, sortlistPrimary } from '../../hooks/useFilter/filterSets';
import { extractPrimaryMapItems } from '../../hooks/useFilter/filterUtils';
import useMedia from '../../hooks/useMedia';
import { getSuffix, media } from '../../utils';

import { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import ErrorPlug from '../../components/ErrorBoundry/ErrorPlug';
import FilterDisabled from '../../components/Public/FilterDisabled';
import MapFilters from '../../components/Public/markup/MapFilters/Index';
import PrimaryPublicFilters from '../../components/Public/markup/PrimaryPublicFilters';
import ProjectsBottomFilter from '../../components/Public/markup/ProjectsBottomFilter';
import ProjectsTopFilter from '../../components/Public/markup/ProjectsTopFilter';
import Card from '../../components/Special/Card';
import EmptyTable from '../../components/core/EmptyTable';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import useRootCSS from '../../hooks/useRootCSS';
import DraggableZone from '../../components/FilterRealty/Draggable';

const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}
      loaderColor={loaderColor}
    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};


const PublicProjects = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  const { savedFilterXmlId } = useParams();
  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      contractConstructions = [],
      filter: filterData = {},
      agent,
      city,
      isActive,
      isShowFilter,
      savedFilter,
      savedFilterSettings,
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent,

  } = useFilter({
    apiConfig: {
      component: "panpartner:new.suburbans.ajax",
      getCountFunction: 'getCountVillages',
      submitFunction: 'getProjectList',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      savedFilterXmlId,
    },
    shadowFilters: ['status'],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: '',
    },
    sortEnabled: true,
    paginationEnabled: true,
    loadMoreEnabled: true,
    isGetFilter: 1,
    extractCount: resp => resp.countVillages,
    loadMoreDataKey: 'contractConstructions',
    processData: (resp, prev) => ({ ...prev, ...resp }),
    defaultView: "list",
    defaultPage: 1,
    defaultLimit: 3,
    defaultSortId: 0,
    defaultOrder: "sort",
    defaultBy: "asc",
    sortList: sortListProjects,
    badgeFormattersParams: {

    },
    specialBadgeTitles: {
      floors: "Этажей: "
    },
    refreshWhenFilterChange: false,
    restoreSF: true,
  });
  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <PublicMobileFilter
            component={PrimaryPublicFilters}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            resetFilter={() => resetFilter(true)}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
            loaderColor={savedFilterSettings?.colorButtonText}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );
  const badges = useMemo(() => isShowFilter || !savedFilter?.filter ? filterBadges : createBadges(savedFilter?.filter), [filterBadges, savedFilter])
  const saveToLocalSotage = id => {
    const tmpFilter = savedFilter?.filter ? { ...savedFilter.filter, ...filter } : filter;
    const tmpExclude = savedFilter?.exclude ? { ...savedFilter.exclude, ...exclude } : exclude;
    // localStorage.setItem("blocksFilter", JSON.stringify({ [id]: { filter: tmpFilter, exclude: tmpExclude } })) // для новой карточки ЖК нужно это
    localStorage.setItem("projectFilter", JSON.stringify({ [id]: tmpFilter }))
  }
  if (isLoading) return <Preloader loading={true} />;
  if (!isActive) return <FilterDisabled agent={agent} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems}
          extractFeatures={extractPrimaryMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}
          savedFilterSettings={savedFilterSettings}
        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={PrimaryPublicCard}
          getLink={(item) =>
            `/public/project/${savedFilterXmlId}/${item.xmlId}`
          }
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={(props) => <MapFilters
            {...props}
            component={props => <>
              <ProjectsTopFilter
                {...props}
                filterData={filterData}
                handleFilter={handleFilter}
                filter={filter}
                setFilterAndExclude={setFilterAndExclude}
                disableButton={!count}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                wrapperClass={' '}
              />
              <ProjectsBottomFilter
                {...props}
                filter={filter}
                filterData={filterData}
                handleFilter={handleFilter}
                setFilterAndExclude={setFilterAndExclude}
                clearExcludeOnly={clearExclude}
                clearFilterOnly={clearFilter}
                wrapperClass={"sf__filter-item filter_item_temp"}
              />
            </>}
          />}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          onClick={saveToLocalSotage}
          isShowFilter={isShowFilter}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          loaderColor={savedFilterSettings?.colorButtonText}
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      {!savedFilterSettings?.hideHeader && (
        <Header {...agent} savedFilterSettings={savedFilterSettings} />
      )}

      {!savedFilterSettings?.hideTitle && (
        <Title
          title={
            savedFilterSettings?.title
              ? savedFilterSettings?.title
              : `Поиск дома по параметрам`
          }
        />
      )}
      {!isTablet && (
        <>
          {isShowFilter && (
            <div className="sf__filter form">

              <ProjectsTopFilter
                filterData={filterData}
                handleFilter={handleFilter}
                filter={filter}
                setFilterAndExclude={setFilterAndExclude}
                disableButton={!count}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                wrapperClass={''}
              />
              <ProjectsBottomFilter
                filter={filter}
                filterData={filterData}
                handleFilter={handleFilter}
                setFilterAndExclude={setFilterAndExclude}
                clearExcludeOnly={clearExclude}
                clearFilterOnly={clearFilter}
                wrapperClass={""}
              />
            </div>
          )}
          <div style={{ marginTop: 25 }}>
            <Tags
              badges={[...filterBadges, ...excludeBadges]}
              removeBadge={removeBadge}
              resetFilter={() => resetFilter(true)}
              readonly={!isShowFilter}
            />
          </div>
          {/* <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          /> */}
        </>
      )}

      {isLaptop && !isTablet && (
        <FilterSubmitButton
          onClick={submit}
          isLoading={isCountLoading || isLoading}
          count={count}
          loaderColor={savedFilterSettings?.colorButtonText}
        />
      )}
      <div className="sf__row ">
        {!isTablet && (
          <div className="special-filter-drop">
            <Dropdown
              innerClass={``}
              value={sortId}
              options={sortListProjects}
              onChange={handleSort}
            />
          </div>
        )}

        <div className="sf__filter-btns">
          {isTablet && isShowFilter && <FilterToggleBtn onClick={openFilter} />}
          {false && <MapBtn
            setShowMap={() => setView('map')}
            iconColor={savedFilterSettings?.colorButton}
          />}
          {!isLaptop && (
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}
              loaderColor={savedFilterSettings?.colorButtonText}
            />
          )}
        </div>
      </div>

      {contractConstructions?.length > 0 ? <ErrorPlug stopSending={true}>
        <div className="sf__cards-list special__cards-list" ref={infiniteRef}>
          {contractConstructions.map(item => <Card key={item.id} {...item} link={({ id, xmlId }) => `/public/project/${savedFilterXmlId}/${xmlId}`} />)}
        </div>
      </ErrorPlug> :
        <EmptyTable title="Ничего не найдено" />
      }

      <LoadMoreSpinner loading={moreIsLoading} />
    </div>
  );
};

export default PublicProjects;