import React, {useEffect, useState} from "react";
import './style.scss'
import PersonalCabinet from "../PersonalCabinet";
import Button from "../../components/core/Button";
import addCompany from "../../assets/images/addcompany.svg"
import useApi from "../../hooks/useApi";
import Preloader from "../../components/Preloader";
import CreateCompany from "../CreateCompany";

const AddCompany = () => {


  const { data: {company}, isLoading,  refresher, send, errors } = useApi({
    payload: ['panpartner:company.ajax', 'getMyCompany'],
  }, [])

  // const [companyState, setCompanyState] = useState({})
  const [companyView, setCompanyView] = useState('')

  useEffect(() => {
    if (company && Object.keys(company).length > 0 > 0) {
      // setCompanyState(company)
      setCompanyView('active')
    }
  }, [company])



  if (isLoading) return <Preloader loading />;
  // if(!company) return JSON.stringify(errors)
  return (
    <>
      <PersonalCabinet/>

      {!company && companyView === '' &&
        <>
          <div className="wrapper add-company">
            <h3 className="add-company__title">Настройки компании</h3>
            <div className="add-company__dashboard">
              <div className="add-company__dashboard-left">
                <h4 className="h4">У вас еще нет компании</h4>
                <Button
                  buttonType='primary'
                  onClick={() => setCompanyView('active')}
                >Создать компанию</Button>
              </div>
              <div className="add-company__dashboard-right">
                <img src={addCompany} alt=""/>
              </div>
            </div>
          </div>

          <div className="wrapper add-company">
            <div className="add-company__dashboard">
              <div className="add-company__dashboard-left">
                <div className="add-company__message">
                  <p className="add-company__message-title">Вы отправили заявку в компанию</p>
                  <p className="add-company__request-name">
                    ИП Королев Иван Иванович<br/>
                    ИНН 123456789123
                  </p>
                  <div className="" style={{textAlign: 'center'}}><Button buttonType='primary' onClick="">Отменить заявку</Button></div>
                </div>
              </div>
              <div className="add-company__dashboard-right">
                <img src={addCompany} alt=""/>
              </div>
            </div>
          </div>

          <div className="wrapper add-company">
            <div className="add-company__dashboard">
              <div className="add-company__dashboard-left">
                <h4 className="h4">У вас еще нет компании</h4>
                <p className="add-company__text">Какой-то текст про добавление компании</p>
                <Button buttonType='primary' onClick="">Зарегистрировать новое агенство</Button>
                <div style={{marginTop: 10}}>
                  <Button buttonType='primary_gray' onClick="">Выбрать существующее агенство</Button>
                </div>
              </div>
              <div className="add-company__dashboard-right">
                <img src={addCompany} alt=""/>
              </div>
            </div>
          </div>

        </>
      }
      {companyView === 'active' &&
        <CreateCompany
          company={company}
          setCompanyView={setCompanyView}
          refresher={refresher}
        />

      }

    </>
  )
}

export default AddCompany
