import request from '../api';
import { actionFactory } from './actionUtils';

const requestRegister = actionFactory("REGISTER_REQUEST");

const failureRegister = actionFactory("REGISTER_FAILURE");

const successRegister = actionFactory("REGISTER_SUCCESS");

export const signup = form => dispatch => {
  dispatch(requestRegister());
  return request('panpartner:system.auth.form', 'register', form).then(
    data => {
      dispatch(successRegister({ data }));
      return Promise.resolve(data);
    },
    error => {
      dispatch(failureRegister());
      return Promise.reject(error);
    },
  );
};

export const checkPhone = (phone) => request('panpartner:system.auth.form', 'checkPhone', phone);
export const checkEmail = (email) => request('panpartner:system.auth.form', 'checkEmail', email);
