import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  clearFilterSecondaries,
  clearSecondaries,
  fetchSecondaries,
  getCount,
  setFilterSecondaries
} from "../../actions/secondaries";
import useDocTitle from "../../hooks/useDocTitle";
import usePermission from '../../hooks/usePermission';
import { mapItemsSecondaries, mapSecondaries } from "../../selectors/secondaries";
import { filterToParams, scrollToRef } from "../../utils";
import { defaultFullFilter } from "../../utils/defaultFilters";
import Preloader from "../Preloader";
import PrepaidBanner from "../PrepaidBanner";
import AttentionBlock from "./AttentionBlock";
import Filter from "./Filter";
import Items from "./Items";
import Top from "./Top";

const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    setFilter,
    clearFilter,
    data,
    top,
    features,
    mapItems,
    getCount,
    countToShow,
    clientMode,
    user,
    banners
  } = props;

  const itemsRef = useRef(null);
  const permission = usePermission("motivation")
  const handleFilter = (params) => {
    fetch("spb",0, params).then((data) => {
      scrollToRef(itemsRef, 80);
    });
  };
  useDocTitle("Вторичная недвижимость")
  useEffect(() => {
    let params= filterToParams(defaultFullFilter)
    getCount(params);
    fetch("spb",1, params);
    return () => {
      reset();
    };
  }, []);
  
  return (
    <>
      <AttentionBlock openBuyModal={()=>window.open("/sell/secondary", "_blank")} />
      <Filter
        filter={filter}
        filterData={filterData}
        setFilter={setFilter}
        clearFilter={clearFilter}
        onSubmit={(params) => {
          handleFilter(params);
        }}
        getCount={getCount}
        info={data}
        countToShow={countToShow}
      />
      {top.length > 0 && (
        <Top isAgent={!permission}
         items={top}
         clientMode={clientMode}
         isSecondaries={true}
         title={`ТОП-${top.length} объектов`}
         wrapperStyle={banners?.secondary && !clientMode ? {paddingBottom: 0}: null}
         />
      )}
       <div className="wrapper">
        <PrepaidBanner bannerId="secondary"/>
      </div>
      <Items
        items={data}
        innerRef={itemsRef}
        features={features}
        mapItems={mapItems}
        isAgent={!permission}
        clientMode={clientMode}
      />
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city,isGetFilter=0, form) => dispatch(fetchSecondaries(city,isGetFilter, form)),
  reset: () => dispatch(clearSecondaries()),
  setFilter: (filter) => dispatch(setFilterSecondaries({ filter })),
  clearFilter: () => dispatch(clearFilterSecondaries()),
  getCount: (params) => dispatch(getCount(params)),
});

const mapStateToProps = (state) => {
  return {
  filterData: state.secondaries.filterData,
  filter: state.secondaries.filter,
  isFetching: state.secondaries.isFetching,
  error: state.secondaries.error,
  data: state.secondaries.data,
  features: mapSecondaries(state),
  mapItems: mapItemsSecondaries(state),
  top: state.secondaries.top,
  countToShow: state.secondaries.countToShow,
  clientMode: state.general?.clientMode,
  user: state.user?.info,
  banners: state.banners
}};

export default connect(mapStateToProps, mapDispatchToProps)(List);
