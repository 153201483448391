import React from 'react';
import ObjectsTable from '../components/Constructor/Admin/MyObjects/ObjectsTable';
import '../components/Constructor/index.scss';



const ConstructorObjectsTable = (props) => {
  return  <ObjectsTable />
};

export default ConstructorObjectsTable;
