import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { connect, useDispatch } from 'react-redux';
import ym from 'react-yandex-metrika';
import { DebounceInput } from 'react-debounce-input';

import {
  successLogin, successTestAccessSms,
  testAccessConfirm, testAccessSms
} from '../../actions/user';
import useTimer from '../../hooks/useTimer';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast } from '../../components/toasts';

import { checkPhone } from '../../actions/register';

const TrialRequest = (props) => {
  const { handleSubmit, register, errors, control, reset, watch, clearError, setError } = useForm();
  const { smsSuccess, userId, history, successLogin } = props;
  const [phone, setPhone] = useState('');
  const [fetching, setFetching] = useState(false);
  const [submitType, setSubmitType] = useState('request');
  const {
    timeout,
    timerIsStarted,
    runTimer
  } = useTimer(60)
  
  const dispatch = useDispatch()
  
  const onSubmit = (data) => {
    if (submitType === 'request') {
      let params = new URLSearchParams(window.location.search);
      let utm = {
        utm_campaign: params.get('utm_campaign'),
        utm_content: params.get('utm_content'),
        utm_medium: params.get('utm_medium'),
        utm_source: params.get('utm_source'),
        utm_term: params.get('utm_term'),
      };

      let form = new FormData();
      Object.keys(utm).map((key) => {
        form.append(`utm[${key}]`, utm[key]);
      });
      form.append(`phone`, data.phone);
      if (window.location.host !== 'localhost:3000' && window.location.host !== 'dev2.panpartner.ru') {
        ym('reachGoal', 'trial_request');
      }
      setFetching(true);
      testAccessSms(form, utm).then(
        (resp) => {
          smsSuccess(resp);
          setFetching(false);
          runTimer();
          setSubmitType('login');
          setPhone(data.phone);
        },
        (error) => {
          setFetching(false);
          errorToast(<div>{error}</div>)
        },
      );
    } else {
      let form = new FormData();
      form.append(`phone`, phone);
      form.append(`userId`, userId);
      form.append(`code`, data.password);

      dispatch(testAccessConfirm(form)).then(
        (resp) => {
          setFetching(false);
          successLogin(resp);
          if (window.location.host !== 'localhost:3000' || window.location.host !== 'dev2.panpartner.ru') {
            ym('reachGoal', 'success_trial_login');
          }

          history.push('/');
        },
        (error) => {
          setFetching(false);
          errorToast(<div>{error}</div>)
        },
      );
    }
  };
  const resendSms = () => {
    let params = new URLSearchParams(window.location.search);
    let utm = {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_medium: params.get('utm_medium'),
      utm_source: params.get('utm_source'),
      utm_term: params.get('utm_term'),
    };
    let form = new FormData();
    Object.keys(utm).map((key) => {
      form.append(`utm[${key}]`, utm[key]);
    });
    form.append(`phone`, phone);
    if (window.location.host !== 'localhost:3000' || window.location.host !== 'dev2.panpartner.ru') {
      ym('reachGoal', 'trial_request');
    }

    testAccessSms(form).then(
      (resp) => {
        smsSuccess(resp);
        setFetching(false);
        runTimer();
        setPhone(phone);
      },
      (error) => {
        setFetching(false);
        standartErrorToast(error)
      },
    );
  };

  const [phoneUnavailable, setPhoneUnavailable] = useState(false);

  const phoneCheckHandler = (value) => {
    console.log('value?.replace?.(/\D/gi, "")?.length', value?.replace?.(/\D/gi, "")?.length)
    if(value?.replace?.(/\D/gi, "")?.length < 11) {
      clearError("phone")
      return setPhoneUnavailable(false)
    }
    value ?
      checkPhone({ phone: value }).then((resp) => {
        resp.exist === 'Y' ? setPhoneUnavailable(true) : setPhoneUnavailable(false);
        clearError("phone")
      },
        ([error]) => {
            setError(
            "phone",
            "notMatch",
            error?.message
          )
        }

      ) : setPhoneUnavailable(false);
  };

  const request = (
    <form className="form" name="sign-trial" onSubmit={handleSubmit(onSubmit)}>

      <div className="hp__form-item">
        <label htmlFor="sign-in_phone" className="hp__form-label text">Номер телефона</label>
        <div
          className="input input_width_available input_type_form"
        >

          <Controller
            as={
              <DebounceInput
                element={InputMask} 
                debounceTimeout={500}
              />
            }
            className={
              'input__control' +
              (errors.phone || phoneUnavailable ? ' input__error' : '')
            }
            id={`sign-in_phone`}
            control={control}
            autoComplete="off"
            placeholder="Введите телефон"
            mask="+7 (999) 999-99-99"
            name="phone"
            inputRef={register({
              required: 'Введите телефон',
            })}
            defaultValue=""
            rules={{
              required: 'Введите телефон',
            }}
            // value={value}
            onChange={([e]) => {
              phoneCheckHandler(e.target.value)
              return e.target.value
            }}

          />
          {errors.phone && (
            <span className="input__error-label">
              {errors.phone.message}
            </span>
          )}

          {
            phoneUnavailable && (
              <span className="input__error-label">
                {`Этот телефон уже используется`}
              </span>
            )
          }
        </div>
      </div>

      <div className="hp__form-item">
        <SubmitButton
          className="hp__submit button button_view_default"
          isLoading={fetching}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={phoneUnavailable || errors.phone}
        >
          <span>Получить пароль</span>
        </SubmitButton>
      </div>
    </form>
  );
  const login = (
    <form className="form" name="sign-trial">

      <div className="hp__form-item">
        <label htmlFor="sign-in_password" className="hp__form-label text">Пароль</label>

        <div
          className="input input_width_available input_type_form"
          htmlFor="sign-in_password"
        >
          <input
            name="password"
            type="password"
            placeholder="Пароль"
            className={
              'input__control' + (errors.password ? ' input__error' : '')
            }
            id="sign-in_password"
            ref={register({
              required: 'Введите пароль',
            })}
          />

          {errors.password && (
            <span className="input__error-label">
              {errors.password.message}
            </span>
          )}
        </div>
      </div>
      {!timerIsStarted && (
        <div className="hp__form-item">
          <span className="preview__anchor" onClick={resendSms}>
            Запросить новый пароль
          </span>
        </div>
      )}
      {timerIsStarted && (
        <div className="hp__form-item preview__countdown">
          <p>
            Запросить новый пароль можно будет через:{' '}
            <span className="preview__counter">
              0:{timeout < 10 && '0'}
              {timeout}
            </span>
          </p>
        </div>
      )}
      <div className="hp__form-item">
        <SubmitButton
          className="button button_view_default hp__submit"
          isLoading={fetching}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          <span>Войти</span>
        </SubmitButton>
      </div>
    </form>
  );
  return (
    <div className="hp__page">
      <section className="hp__become-p hp__promo">
        <div className="hp__page-form">
          <h1 className="hp__h1 h1 text-center">{`Пробный доступ`}</h1>
          <p className="hp__sign hp__sign_sm color-brand-3 big-text text-center">Вы можете протестировать возможности нашей платформы абсолютно бесплатно получив пробный доступ на 3 часа. Мы свяжемся с Вами через сутки для подтверждения регистрации</p>

          <div className="hp__form-content">
            {submitType === 'request' ? request : login}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  smsSuccess: (data) => dispatch(successTestAccessSms(data)),
  successLogin: (data) => dispatch(successLogin(data)),
});

const mapStateToProps = (state) => ({
  userId: state.user.userId,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialRequest);
