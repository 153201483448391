import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import Preloader from '../Preloader';
import './index.scss';

// new
import DetailHeader from '../Public/markup/detail/DetailHeader';
import Slider from '../Public/markup/detail/Slider';
import PromoBar from '../Public/markup/detail/PromoBar';
import { scrollToRef } from '../../utils';
import InfoCard from '../Public/markup/detail/InfoCard';

import About from '../Public/markup/detail/About';
import Help from '../Public/markup/detail/Help';
import PromoList from '../Public/markup/detail/PromoList';
import Furnish from '../Public/markup/detail/Furnish';
import VideoList from '../Public/markup/detail/VideoList';
import ContactsModal from '../Public/markup/detail/ContactsModal';

import Button from '../core/Button';
import Iframe from '../Public/markup/detail/Iframe';
import KeysList from '../Public/markup/detail/KeysList';

import PanModal from '../core/PanModal';
import { useModal } from 'react-modal-hook';
import useFilter from '../../hooks/useFilter';
import useDocTitle from '../../hooks/useDocTitle';
import { PhotoPopup } from '../Viewer/PhotoSlider';
import Files from '../Public/markup/detail/Files';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import { ListIcon, TileIcon } from '../core/Tabs/icons';
import DownloadApp from '../core/DownloadApp';
import useDownloadApp from '../../hooks/useDownloadApp';
import CardsList from '../Public/markup/CardsList';
import VillageCardContainer from '../Public/markup/Containers/VillageCardContainer';
import { getSuburbanFilterConfig } from './Utils';
import SuburbanContent from './SuburbanContent';
import SuburbanContentContainer from './SuburbanContentContainer';

const typeTabs = [{ title: 'Планировки и цены' }, { title: 'Паркинг' }];
const viewTabs = [
  { title: 'Список', icon: ListIcon },
  { title: 'Плитка', icon: TileIcon },
  // { title: 'Шахматка', icon: ChessIcon },
];
const apiConfig = {
  component: 'panpartner:suburbans.ajax',
  submitFunction: 'getVillage',
  loadSubItems: 'getSuburbans',
}

const Suburban = memo((props) => {
  const { villageId, city } = useParams();
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');

  const actionsRef = useRef(null);
  const filesRef = useRef(null);
  const aboutBlockRef = useRef(null);
  const filesBlockRef = useRef(null);
  const [showGp, setShowGp] = useState(false);

  const [activeView, setActiveView] = useState(0);
  const searchHashParams = new URLSearchParams(window.location.hash);

  useEffect(() => {
    const id = searchHashParams.get('id');
    if (!id) return;
    const getRowLink = () => {
      if (searchHashParams.get('objectType') === 'assignment') {
        return `/assignments/${id}${window.location.search}`;
      }
      return `/realty/${city}/apartment/${id}${window.location.search}`;
    };
    window.open(getRowLink(), '_self');
  }, []);

  const filterFromLS = useMemo(() => JSON.parse(localStorage.getItem('suburbansFilter'))?.[villageId], [])

  const [downloadApp, setDownloadApp] = useDownloadApp();
  const contentProps = useFilter(getSuburbanFilterConfig({
    city,
    villageId,
    newParams: {
      processData: (resp, prev) => {
        if (prev.filter) delete resp.filter;
        return { ...prev, ...resp };
      },
      apiConfig,
      configMutation: (resp, config)=>{
        if(!resp?.tabs?.[0]?.method) return
        config.apiConfig.submitFunction = resp.tabs?.[0]?.method ?? config.apiConfig.loadSubItems;
        config.refreshMethod = resp.tabs?.[0]?.method ?? config.apiConfig.loadSubItems;
        config.defaultParams["filter[type]"] = resp.tabs?.[0]?.typeId ;
      },
      defaultLoadCondition: true,
      filterFromLS: { filter: filterFromLS },
      shadowFilters: ['villageId', 'status', "cottageSettlement", "type", "realtyType"]
    },
  }));

  const {
    isLoading,
    filter = {},
    data: {
      village: block = {},
      actionInfo,
      filter: filterData = {},
      villageInfo,
      otherVillages,
      managerInfo,
      blockVideos,
      contacts,
      schedule,
      priceInfo,
      filesInfo,
      fixationLink,
      limit,
      tabs
    },
    showSkeleton,
    favoritesProps,
    createBadges,
    get
  } = contentProps;

  useEffect(() => {
    const firstOfType = filterFromLS?.type?.[0];
    if (!firstOfType || !tabs || tabs?.length === 1) return;
    setActiveType(tabs.findIndex(tab => tab.typeId === firstOfType))
    delete filterFromLS.type;
  }, [filterFromLS, tabs])

  useEffect(() => () => localStorage.removeItem('suburbansFilter'), []);
  useDocTitle(`${block.name ? `${block.name}` : ''}`);

  const [activeType, setActiveType] = useState(0);
  const filterBadges = createBadges(filter, ['villageId', 'status'], {}, { [tabs?.[0]?.searchFilter]: tabs?.[0]?.searchPlaceholder })

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          managerId={block?.manager?.id}
          params={{
            type: "village",
            objectId: villageId,
            city
          }}
        />
      </PanModal>
    ),
    [block],
  );

  const decorationSliders = useMemo(() => {
    // const { finishing, finishingPreReady, finishingReady } = block;
    return [
      { title: 'Чистовая', items: block?.finishingReady },
      { title: 'Предчистовая', items: block?.finishingPreReady },
      { title: 'Черновая', items: block?.finishing },
    ].filter((item) => !!item?.items?.length);
  }, [block]);

  if (isLoading) return <Preloader loading />;

  const scrollToActions = () =>
    scrollToRef(actionsRef, -aboutBlockRef.current?.offsetTop);

  const scrollToFiles = () =>
    scrollToRef(filesRef, -filesBlockRef.current?.offsetParent?.offsetTop);



  return (
    <>
      <DownloadApp
        downloadApp={downloadApp}
        setDownloadApp={setDownloadApp}
        noCloser
      />

      {!downloadApp && (
        <div class="sf wrapper">
          {block && (
            <DetailHeader
              title={`${block.name}`}
              description={block.address}
              subways={block.subway}
              commission={block.commission}
              isCustomerFixation={block.builder?.isCustomerFixation}
              coordinates={block.coordinates}
              onFixationClick={() => window.open(fixationLink)}
              {...favoritesProps}
            />
          )}
          <div className="sf__detail-row">
            <div className="sf__detail-col">
              {!isTablet && !!block.photos?.complex?.length && (
                <Slider
                  items={block.photos.complex}
                  openGeneralPlan={() => setShowGp(true)}
                  showButton={!!block.photos.generalPlan?.length}
                />
              )}
              {actionInfo && (
                <PromoBar
                  title={actionInfo.title}
                  activeTo={actionInfo.actionPeriod}
                  count={actionInfo.moreActions?.length}
                  managerInfo={managerInfo}
                  onClick={scrollToActions}
                  id={actionInfo.id}
                />
              )}
            </div>
            <div className="sf__detail-col">
              {!isTablet && <InfoCard items={villageInfo} cols={priceInfo} />}
              <section className="sf__section">
                <Button buttonType="primary" isFull onClick={openContactsModal}>
                  Контакты
                </Button>
                {filesInfo?.length > 0 && (
                  <Button buttonType="line" isFull onClick={scrollToFiles}>
                    Файлы
                  </Button>
                )}
              </section>
            </div>
          </div>

          {!!tabs?.length && <div className="sf__detail-row sf__detail-row_full">
            {/*<h3 className="h3 tab-title">Планировки и цены</h3>*/}

            <StatelessTabs
              tabs={tabs}
              activeTab={activeType}
              setActiveTab={setActiveType}
              largeBtn
            />

            {activeType === 0 && <SuburbanContent
              {...contentProps}
              tab={tabs?.[0]}
              filterBadges={filterBadges}
              villageId={villageId}
              city={city}
            />
            }
          </div>}
          {tabs.map(({ method, ...tab }, i) => i === 0 ? null : <SuburbanContentContainer
            method={method}
            isActive={activeType === i}
            tab={tab}
          />)}
          <div className="sf__detail-row" ref={aboutBlockRef}>
            <div className="sf__detail-col">
              {(block?.description ||
                block?.advantages ||
                block?.audioReview) && (
                <About
                  title={`О ${block ? block.name : ''}`}
                  text={block?.description}
                  advantages={block?.advantages}
                  audioReview={block?.audioReview}
                />
              )}

              {isLaptop && <Help openContactsModal={openContactsModal} />}
              <Furnish items={decorationSliders} />

              <section className="sf__section">
                {!!block?.builder?.typical3DTour && (
                  <Iframe title="Шоу-рум" src={block.builder.typical3DTour} />
                )}
              </section>
              <section className="sf__section">
                <Iframe title="Аэропанорама" src={block?.photos?.aeroPanorama} />
              </section>

              {false && (
                <KeysList
                  items={[
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                  ]}
                />
              )}

              <PromoList
                items={actionInfo?.moreActions}
                innerRef={actionsRef}
                managerInfo={managerInfo}
              />

              <VideoList items={blockVideos} />

              {filesInfo?.length > 0 && (
                <div ref={filesBlockRef}>
                  <Files innerRef={filesRef} items={filesInfo} />
                </div>
              )}
            </div>
            {!isLaptop && (
              <div className="sf__detail-col sf__sticky">
                <Help openContactsModal={openContactsModal} />
              </div>
            )}
          </div>

          {otherVillages.length > 0 && (
            <div className="sf__detail-row sf__detail-row_full">
              <h3 className="h3 tab-title">Другие проекты застройщика</h3>
              <CardsList
                items={otherVillages}
                getLink={(item) => `/old/suburban/${city}/${item.id}`}
                isLoading={showSkeleton}
                limit={limit}
                cardComponent={VillageCardContainer}
                {...favoritesProps}
                get={get}
                favoritesPath={i => `otherVillages[${i}].favorite`}
              />
            </div>
          )}

          <Preloader loading={isLoading} />
          <PhotoPopup
            open={showGp}
            items={block.photos.generalPlan}
            index={0}
            onClose={() => setShowGp(false)}
          />
        </div>
      )}
    </>
  );
});

export default Suburban;
