import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Radio } from "../../components/core/RadioDropdown";
const Item = props => {
    const { name, value, fieldName, handleChange, checked } = props
    return (<div className="poll__elem">
        <Radio
            identity={name}
            title={name}
            id={value}
            onChange={(value) => { handleChange(value, fieldName) }}
            checked={checked}
        />
    </div>)
}
const RadioButtons = (props) => {
    const { name, items, handleChange, form } = props

    const [radioItems, setRadioItems] = useState([])
    const [itemsLength, setItemsLength] = useState([])

    const [inputItem, setInputItem] = useState(null)
    useEffect(() => {
        if (items) {
            let tmpItems = items.filter(item => item.type !== "textarea")
            setItemsLength(tmpItems.length)
            setRadioItems(chunk(tmpItems, 4))
            setInputItem(items.find(item => item.type === "textarea"))
        }

    }, [items])
    return (
        <div className={`poll__item ${itemsLength > 3 ? "poll__item_flex-start" : ""}`}>
            <div className="poll__q h4">{name}</div>
            <div className="poll__a">
                {radioItems.map((half, i) => (
                    <div className="poll__row" key={`half-${name}"-"${i}`}>
                        {half.map((item, idx) => (<Item
                            {...item} key={`${name}"-"${(idx + 3) * (i + 1)}`}
                            fakeIndex={(idx + 3) * (i + 1)}
                            handleChange={handleChange}
                            checked={form?.[item?.fieldName] == item.value}
                        />))}
                    </div>
                ))}
                {inputItem && <div className="poll__row">
                    <input
                     value={form[inputItem.fieldName]}
                      onChange={(e) => { handleChange(e.target.value, inputItem.fieldName) }}
                     type="text"
                      className="text-input text-input_full"
                      placeholder="Ваш вариант ответа" />
                </div>}
            </div>
        </div>
    )
}
export default RadioButtons