import React from 'react';
import './index.scss';

const DoneIcon = () => {
  return (
    <svg width="308" height="260" viewBox="0 0 308 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M132.327 259.605C203.937 259.605 261.988 201.49 261.988 129.802C261.988 58.1146 203.937 0 132.327 0C60.7173 0 2.66626 58.1146 2.66626 129.802C2.66626 201.49 60.7173 259.605 132.327 259.605Z" fill="#8F95AC" />
      <path d="M163.583 56.3644H187.845C191.341 56.3644 194.125 59.2219 194.125 62.651V213.313C194.125 216.814 191.27 219.6 187.845 219.6H76.8091C73.3124 219.6 70.5294 216.742 70.5294 213.313V62.651C70.5294 59.1505 73.3838 56.3644 76.8091 56.3644H101" fill="white" />
      <path d="M163.583 56.3644H187.845C191.341 56.3644 194.125 59.2219 194.125 62.651V213.313C194.125 216.814 191.27 219.6 187.845 219.6H76.8091C73.3124 219.6 70.5294 216.742 70.5294 213.313V62.651C70.5294 59.1505 73.3838 56.3644 76.8091 56.3644H101" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M154.734 51.2923H146.099C146.099 43.7199 139.962 37.5049 132.327 37.5049C124.763 37.5049 118.555 43.6485 118.555 51.2923H109.849C104.211 51.2923 99.6442 55.8644 99.6442 61.508V70.1519H164.867V61.4365C164.938 55.8644 160.371 51.2923 154.734 51.2923Z" fill="white" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M132.327 56.3644C134.298 56.3644 135.895 54.7652 135.895 52.7925C135.895 50.8198 134.298 49.2207 132.327 49.2207C130.356 49.2207 128.759 50.8198 128.759 52.7925C128.759 54.7652 130.356 56.3644 132.327 56.3644Z" fill="#2B2F3E" />
      <path d="M100.072 70.0805H82.6607C81.5903 70.0805 80.8053 70.8663 80.8053 71.8664V206.812C80.8053 207.813 81.6616 208.598 82.6607 208.598H181.922C182.992 208.598 183.777 207.813 183.777 206.812V71.8664C183.777 70.8663 182.921 70.0805 181.922 70.0805H163.511" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M108.207 61.7937H156.447" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M120.053 101.442V110.8C120.053 111.657 119.339 112.3 118.555 112.3H97.6461C96.7898 112.3 96.1476 111.586 96.1476 110.8V89.8688C96.1476 89.0115 96.8612 88.3686 97.6461 88.3686H115.486" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 92.0833H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 100.299H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 108.514H144.387" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M99.2161 98.5127L100.715 97.084L108.207 101.156L121.124 90.0116L122.408 91.2261L108.778 107.871L99.2161 98.5127Z" fill="#2B2F3E" />
      <path d="M120.053 140.732V150.091C120.053 150.948 119.339 151.591 118.555 151.591H97.6461C96.7898 151.591 96.1476 150.877 96.1476 150.091V129.16C96.1476 128.302 96.8612 127.659 97.6461 127.659H115.486" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 131.374H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 139.589H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 147.805H144.387" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M99.2161 137.804L100.715 136.375L108.207 140.447L121.124 129.302L122.408 130.517L108.778 147.162L99.2161 137.804Z" fill="#2B2F3E" />
      <path d="M120.053 179.732V189.091C120.053 189.948 119.339 190.591 118.555 190.591H97.6461C96.7898 190.591 96.1476 189.877 96.1476 189.091V168.16C96.1476 167.302 96.8612 166.659 97.6461 166.659H115.486" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 170.374H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 178.589H168.578" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.613 186.805H144.387" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M99.2161 176.804L100.715 175.375L108.207 179.447L121.124 168.302L122.408 169.517L108.778 186.162L99.2161 176.804Z" fill="#2B2F3E" />
      <path d="M173.53 27.4356L168.606 32.3648" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M173.673 32.2934L168.749 27.3642" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M139.479 235.6L133.2 241.887" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M139.622 241.887L133.342 235.6" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M162.024 147.805C134.907 204.098 177.937 232.101 177.937 232.101C177.937 232.101 185.145 204.955 196.205 204.098C207.266 203.241 204.34 238.031 204.34 238.031C246.8 244.674 280.41 201.383 280.41 201.383C280.41 201.383 248.369 196.668 250.225 189.167C252.08 181.595 296.323 182.881 296.323 182.881C303.102 174.951 307.669 154.234 307.669 154.234C307.669 154.234 277.127 161.807 274.13 154.663C271.133 147.519 307.812 115.372 307.812 115.372C301.818 97.0125 286.69 88.5829 286.69 88.5829C286.69 88.5829 265.639 127.874 256.861 128.945C248.084 130.017 261.428 81.2962 261.428 81.2962C253.864 79.4388 234.24 84.2966 234.24 84.2966C234.24 84.2966 235.953 131.088 224.749 137.518C213.546 143.947 209.478 86.5112 209.478 86.5112C209.478 86.5112 184.146 102.013 162.024 147.805ZM182.148 130.731C185.216 129.66 190.283 136.089 193.494 145.162C196.705 154.234 196.776 162.45 193.708 163.521C190.639 164.593 185.573 158.163 182.362 149.091C179.079 140.018 179.008 131.803 182.148 130.731Z" fill="#FF2D23" />
      <path d="M176.225 257.819C176.225 257.819 211.976 181.523 239.949 141.661" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M73.7687 241.887C64.2555 217.075 50.2361 179.315 11.9331 136.877" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M44.8953 180.485C44.8953 180.485 44.4781 162.273 46.4809 149.324" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M59.8328 207.635C59.8328 207.635 59.0818 195.104 61.6687 182.239" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M37.1348 168.622C37.1348 168.622 22.2809 161.354 11.3491 161.354" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M54.7425 197.527C54.7425 197.527 42.6424 190.51 30.8761 190.76" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M72.4335 237.96C72.4335 237.96 72.7673 216.156 82.1135 203.709" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M32.2947 159.683C36.1661 159.683 39.3044 156.542 39.3044 152.666C39.3044 148.79 36.1661 145.649 32.2947 145.649C28.4234 145.649 25.285 148.79 25.285 152.666C25.285 156.542 28.4234 159.683 32.2947 159.683Z" fill="#22292E" />
      <path d="M7.0097 168.873C10.881 168.873 14.0194 165.731 14.0194 161.855C14.0194 157.98 10.881 154.838 7.0097 154.838C3.13835 154.838 0 157.98 0 161.855C0 165.731 3.13835 168.873 7.0097 168.873Z" fill="#22292E" />
      <path d="M85.0344 207.134C88.9057 207.134 92.0441 203.992 92.0441 200.117C92.0441 196.241 88.9057 193.099 85.0344 193.099C81.163 193.099 78.0247 196.241 78.0247 200.117C78.0247 203.992 81.163 207.134 85.0344 207.134Z" fill="#22292E" />
      <path d="M51.2375 204.879C55.1088 204.879 58.2472 201.737 58.2472 197.861C58.2472 193.986 55.1088 190.844 51.2375 190.844C47.3661 190.844 44.2278 193.986 44.2278 197.861C44.2278 201.737 47.3661 204.879 51.2375 204.879Z" fill="#22292E" />
      <path d="M47.8997 151.162C51.7711 151.162 54.9094 148.021 54.9094 144.145C54.9094 140.269 51.7711 137.128 47.8997 137.128C44.0284 137.128 40.89 140.269 40.89 144.145C40.89 148.021 44.0284 151.162 47.8997 151.162Z" fill="#22292E" />
      <path d="M62.9203 184.662C66.7917 184.662 69.93 181.52 69.93 177.645C69.93 173.769 66.7917 170.627 62.9203 170.627C59.049 170.627 55.9106 173.769 55.9106 177.645C55.9106 181.52 59.049 184.662 62.9203 184.662Z" fill="#22292E" />
      <path d="M25.452 197.611C29.3233 197.611 32.4617 194.469 32.4617 190.593C32.4617 186.718 29.3233 183.576 25.452 183.576C21.5806 183.576 18.4423 186.718 18.4423 190.593C18.4423 194.469 21.5806 197.611 25.452 197.611Z" fill="#22292E" />
      <path d="M9.93035 139.884C13.8017 139.884 16.94 136.743 16.94 132.867C16.94 128.991 13.8017 125.85 9.93035 125.85C6.059 125.85 2.92065 128.991 2.92065 132.867C2.92065 136.743 6.059 139.884 9.93035 139.884Z" fill="#22292E" />
    </svg>
  );
};


const Done = ({ text = "Вы уже прошли это задание" }) => {
  return (
    <div className="training__poll">
      <div className="training__poll-header training__poll-header_column training__poll-header_additional training__poll-header_done">
        <DoneIcon />
        <h2 className="h2 training__poll-title">{text}</h2>
      </div>

    </div>
  );
};

export default Done;
