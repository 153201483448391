import React from "react";
import { useMediaQuery } from 'beautiful-react-hooks';
import { useModal } from "react-modal-hook";
import SpecialForm from "../forms/Special.js";
import Wrap from '../core/ModalWrap';
import MobileModal from '../core/MobileModal';
import Modal from "../core/Modal";

const Promo = () => {

  const isMobile = useMediaQuery("(max-width: 767px)");

  const [specialModal, hideSpecialModal] = useModal(
    ({ in: open, onExited }) => (

      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Получить консультацию" closeClick={hideSpecialModal} className="special-form-modal">{children}</MobileModal>
        : <Modal close={hideSpecialModal}>{children}</Modal>
      } >
        <SpecialForm close={hideSpecialModal} />
      </Wrap >
    )
  );

  return (
    <section className="special__promo">
      <div className="wrapper">
        <div className="special__promo-container">
          <div className="special__promo-box">
            <h1 className="h1 special__promo-title"><span>Строим для клиента,</span><br /><span>платим Вам</span></h1>
            <p className="big-text special__promo-text">Получайте комиссионное вознаграждение за&nbsp;строительство дома на участке клиента</p>
            <button className="button button_view_default" type="button" onClick={() => specialModal()}>Получить консультацию</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promo;
