import request from '../api';

const comp = "panpartner:new.partners.ajax"

export const getData = managerId => request(comp, "getData", { managerId });

export const getPdf = managerId => request(comp, "getPdf", { managerId });

export const sendRequest = (managerId, phone, name, agency) =>
	request(comp, "sendRequest", {
		managerId,
		phone,
		name,
		agency
	});