import React, { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import {
  clearFilterMeetings, clearMeetings, fetchMeetings,

  filteredMeetings,


  removeEventMeeting, setFilterMeetings
} from '../../actions/meetings';
import { useReserveModals } from '../../hooks/useReserveModals.js';
import { ReactComponent as Leaf } from "../../images/svg/leaf.svg";
import { media } from '../../utils';
import EmptyTable from '../core/EmptyTable';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import ReserveMeetingRoom from '../forms/ReserveMeetingRoom.js';
import Preloader from '../Preloader';
import Filter from './Filter';
import './index.scss';
import Items from './Items';



const Meetings = (props) => {
  const {
    fetch,
    reset,
    isFetching,
    filter,
    items,
    filterData,
    setFilter,
    clearFilter,
    removeEvent,
    data,
  } = props;

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    fetch().then(() => {
      setFirstRender(false);
    });

    return () => reset();
  }, []);

  const [showReserveMeetingRoom, hideReserveMeetingRoom] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideReserveMeetingRoom} classes="modal_task modal_large modal_top-overflow">
        <ReserveMeetingRoom
          defaultValues={{
            date: new Date(),
            comment: '',
            agree: true,
          }}
          handleReserve={handleReserve}
          close={hideReserveMeetingRoom}
          showFixateClient={showFixateClient}
        />
      </Modal>
    ),
  );
  const [showFixateClient, hideFixateClient] = useReserveModals(media('isMobile'))
  const [showMobileReserveMeetingRoom, hideMobileReserveMeetingRoom] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        closeClick={hideMobileReserveMeetingRoom}
        classes="modal_task"
        title="Забронировать"
      >
        <ReserveMeetingRoom
          defaultValues={{
            date: new Date(),
            comment: '',
            agree: true,
          }}
          handleReserve={handleReserve}
          close={hideMobileReserveMeetingRoom}
          showFixateClient={showFixateClient}
        />
      </MobileModal>
    ),
  );

  const handleReserve = () => {
    fetch();
  }

  return (
    <div>
      <section className="section section_view_white no-header-profile">
        <div className="wrapper">
          <a
            href="https://panpartner.ru/pan-comfort/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="lk-meetings__banner">
              {media('isMobile') ?
                <Leaf className="lk-meetings__leaf-icon" /> :
                <svg className="lk-meetings__banner-icon">
                  <use xlinkHref="#meetings_banner"></use>
                </svg>
              }
              <h4 className="lk-meetings__banner-title">
                Встречайтесь с клиентами быстрее и удобнее в офисе Пан Партнер
              </h4>
            </div>
          </a>

          <div className="lk-meetings__header">
            <div className="lk-meetings__title">
              <div className="h1">Welcome-столы</div>
            </div>
            {/* <div className="lk-meetings__show-calendar">
						Посмотреть мой календарь
						<svg className="icon">
							<use xlinkHref="#arrow_bottom"></use>
						</svg>
					</div> */}
            <div className="lk-meetings__all">
              <div
                className="button button_view_default"
                onClick={media('isMobile') ? showMobileReserveMeetingRoom : showReserveMeetingRoom}
              >
                Забронировать
              </div>
            </div>
          </div>
          {!firstRender &&
            (items.length > 0 ? (
              <>
                <Filter
                  filter={filter}
                  filterData={filterData}
                  setFilter={setFilter}
                  clearFilter={clearFilter}
                />
                <Items items={data} removeEvent={removeEvent} />
              </>
            ) : (
              <EmptyTable title="У Вас ничего не забронировано" />
            ))}
          {firstRender && <Preloader loading={isFetching} />}
        </div>
      </section>
      <Preloader loading={isFetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchMeetings()),
  reset: () => dispatch(clearMeetings()),
  setFilter: (filter) => dispatch(setFilterMeetings({ filter })),
  clearFilter: () => dispatch(clearFilterMeetings()),
  removeEvent: (id, comment, city) =>
    dispatch(removeEventMeeting(id, comment, city)),
});

const mapStateToProps = (state) => ({
  data: filteredMeetings(state),
  items: state.meetings.data,
  rooms: state.meetings.rooms,
  filterData: state.meetings.filterData,
  filter: state.meetings.filter,
  isFetching: state.meetings.isFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(Meetings);
