import request from '../api';
import { actionFactory } from './actionUtils';

const requestEvent = actionFactory("EVENT_REQUEST");

const successEvent = actionFactory("EVENT_SUCCESS");

const successAgents = actionFactory("EVENT_AGENTS_SUCCESS");

export const clearEvent = actionFactory("EVENT_RESET");

const failureEvent = actionFactory("EVENT_FAILURE");

export const fetchEvent = (id, city = 'spb') =>
	dispatch => {
		dispatch(requestEvent());
		return request("panpartner:events.ajax", "getEvent", { id, city })
			.then(
				data => dispatch(successEvent({ data })),
				() => dispatch(failureEvent())
			);
	};
export const getEvent = form => request("panpartner:events.ajax", "getEvent", form);

export const getStartEvents = () => request("panpartner:events.ajax", "getStartEvents", {city: 'spb'});

export const subscribeOnEvent = (eventId, agentId, city) =>
	dispatch =>
		request("panpartner:events.ajax", "subscribeOnEvent", { eventId, agentId, city })
			.then(
				data => {
					dispatch(successEvent({ data }));
					return Promise.resolve(data);
				},
				error => {
					dispatch(failureEvent());
					return Promise.reject(error);
				},
			);

export const unsubscribeOnEvent = (eventId, agentId, comment, city) =>
	dispatch =>
		request("panpartner:events.ajax", "unsubscribeOnEvent", { eventId, agentId, comment, city })
			.then(
				data => {
					dispatch(successEvent({ data }));
					return Promise.resolve(data);
				},
				error => {
					dispatch(failureEvent());
					return Promise.reject(error);
				},
			);

export const fetchAgents = (city) =>
	dispatch =>
		request("panpartner:agents.ajax", "getAgents", { city })
			.then(
				respData => {
					const data = respData.agents.map((item) => {
						let title = `${item.name}${item.lastName ? ` ${item.lastName}` : ``
							}`;
						return { id: item.id, title };
					});
					dispatch(successAgents({ data }));
					return Promise.resolve(data);
				},
				error => Promise.reject(error)
			);

export const getAgents = city => request("panpartner:agents.ajax", "getAgents", { city });

export const unsubscribeOnEventReq = form => request("panpartner:events.ajax", "unsubscribeOnEvent", form)

export const subscribeOnEventReq = form => request("panpartner:events.ajax", "subscribeOnEvent", form)