import React, { memo, useMemo, useState } from 'react';
import DropdownMulti from '../../components/core/DropdownMulti';
import { formattedLocaleDate } from '../../utils';
import CircularProgressBar from './CircularProgressBar';

const SubscribeStaff = memo(props => {
    const {
        agents,
        buttonTitle,
        date,
        haveStaff,
        isMaster,
        isSubscribed,
        noProgress,
        places,
        subscribe,
        subscribeSelf,
        title,
        unsubscribe,
        unsubscribeSelf,
        alreadySubscribed = [],
        subscribedAgents,
        isWebinar
    } = props
    const [showStaff, setShowStaff] = useState(false)
    const [selectedAgents, setSelectedAgents] = useState([]);
    const deselectAgent = id => setSelectedAgents(prev => prev.filter(agentId => agentId != id))
    const disabledFields = useMemo(() => {
        if(isWebinar ) return []
        const totalAgents = subscribedAgents?.length ? selectedAgents.length + subscribedAgents?.length : selectedAgents.length
        if(totalAgents > 2 && agents){
            return agents.filter(agent => !selectedAgents.some(selected => selected == agent.id)).concat(subscribedAgents)
        }
        if (subscribedAgents?.length > 2 && agents) {
            return agents
        }
        return subscribedAgents
    }, [selectedAgents, subscribedAgents, agents, isWebinar])

    return (<aside className="pe__sign"> <div className="pe__sign-block">
        {isMaster && isSubscribed && <div className="pe__sign-content">
            <p className="bold pe__sign-h5">Вы записаны на это мероприятие</p>
            <button className="button button_view_outline" onClick={unsubscribeSelf}>Отменить запись</button>
        </div>}
        <div className={isMaster && isSubscribed ? "pe__sign-content pe__sign-content_dark" : "pe__sign-content"}>
            <p className="pe__sign-text">{title}</p>
            <div className="pe__sign-progress">
                <CircularProgressBar
                    strokeWidth="4"
                    sqSize="95"
                    places={places}
                    noProgress={noProgress}
                />
            </div>

            <p className="pe__sign-text">Окончание регистрации</p>
            <p className="h5 pe__sign-h5">{formattedLocaleDate(date)}</p>
            {!(isMaster && isSubscribed) && <button className="button button_view_default" onClick={subscribeSelf}>{buttonTitle}</button>}

            {haveStaff && <div className="pe__sign-footer">
                <button
                    className="button pe__add-staff"
                    type="button"
                    onClick={() => setShowStaff(prev => !prev)}
                >
                    <span className={`button button_type_${showStaff ? `delete` : `add`}`}></span>
                    Записать сотрудников
                </button>
            </div>}
        </div>
        <div className={`pe__edit${showStaff ? `` : ` hidden`}`}>
            <div className="select select_type_form">
                <DropdownMulti
                    value={selectedAgents}
                    items={agents || []}
                    onChange={setSelectedAgents}
                    data={{ title: 'Выберите сотрудников' }}
                    isAutocomplete={agents?.length > 6}
                    disabledFields={disabledFields}
                    wrapperClass="event_form_item"
                />
            </div>
            <div className="pe__edit-workers">
                {selectedAgents.map(agentId => {
                    const agent = agents.find(item => item.id == agentId)
                    return <div className="pe__edit-worker">
                        <button className="button button_type_delete" type="button" onClick={() => deselectAgent(agent.id)}></button>
                        <p>{agent.title}</p>
                    </div>
                }
                )}

                {selectedAgents.length > 0 && <button
                    className="pe__edit-btn button button_view_default"
                    onClick={() => subscribe(selectedAgents).then(() => {
                        setSelectedAgents([])
                        setShowStaff(false)
                    })}>
                    Записать
                </button>}
            </div>
        </div>
        {alreadySubscribed?.length > 0 && <div className="pe__edit">
            <div className="pe__edit-workers">
                <p className="pe__edit-title">Уже записаны</p>
                {alreadySubscribed.map(item => <div className="pe__edit-worker">
                    <button className="button button_type_delete" type="button" onClick={() => unsubscribe(item.id)}></button>
                    <p>{item.name} {item.lastName}</p>
                </div>)}
            </div>
        </div>}
    </div>
    </aside>
    )
})

export default SubscribeStaff