import request from '../../../../api';


const getData = form => request("panpartner:self.objects.ajax", "getData", form);

const getObject = id => request("panpartner:self.objects.ajax", "getEditObject", { id });

const editObject = (form) => request("panpartner:self.objects.ajax", "editObject", form, false,);

export { getData, getObject, editObject };

