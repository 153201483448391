import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMedia from "../../hooks/useMedia";
import './index.scss';

const Register = () => {

  const isMobile = useMedia("isMobile");

  const BecomeItems = [
    {
      title: "Как риелтор",
      text: "Партнерские привилегии и бонусы с каждой сделки",
      link: "/register/realtor"
    },
    {
      title: "Как владелец агентства",
      text: "Повышайте продажи и эффективность сотрудников",
      link: "/register/agency"
    },
    {
      title: "Как начинающий агент",
      text: "Секреты успешной работы с клиентами и не только",
      link: "/register/agent"
    },
    // {
    //   title: "Как застройщик",
    //   text: "Эксклюзивные условия продажи ваших объектов",
    //   link: "/register/builder"
    // },
    {
      title: "Как застройщик",
      text: "Повышайте продажи с нашей партнерской сетью",
      link: "/register/developer"
    },
  ];

  const utms = useLocation().search;

  return (
    <div className="hp__page">

      <section className="hp__become-p hp__promo">
        <h1 className="hp__h1 h1 text-center">Стать партнером</h1>
        <div className="hp__become-grid">
          {
            BecomeItems.map(it => (
              <Link to={`${it.link}${utms}`} className="hp__become-item" key={`key${it.link}`}>
                <div className="hp__become-content">
                  <h4 className="h5 hp__become-title">{it.title}</h4>
                  <p className="hp__become-text medium-text">{it.text}</p>
                </div>

                <div className="hp__arrow">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3906 7.77344L15.3906 12.7734L10.3906 17.7734" stroke="white" strokeLinecap="round"/>
                  </svg>
                </div>

              </Link>
            ))
          }
        </div>
      </section>

    </div>
  );
};

export default Register;
