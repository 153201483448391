import React from "react";
import { useModal } from "react-modal-hook";
import { icons } from '../../../utils/special';
import Modal from "../../core/Modal";
import Banner from './Banner';

const Promo = (props) => {
    const { socials, changeHandler, isActive, setActive, promoText, setPromoText } = props;

    const [openFirstScreenModal, closeFirstScreenModal] = useModal(({ in: open, onExited }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal c-modal_preview" close={closeFirstScreenModal} closeByPastClick={true}>

            {/*<button type="button"
                className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                aria-label="Закрыть окно"
                onClick={closeFirstScreenModal}
            >
                <span className="c-modal__icon">
                    <svg className="icon icon_cross">
                        <use xlinkHref="#cross" />
                    </svg>
                </span>
            </button>*/}
            <div className="c-modal__box">
                <div className="c-modal__content">
                  <Banner socials={socials} />
                </div>
            </div>
        </Modal>
    ), []);

    return (<div className={`admin__reviews admin__section admin__section_sm${isActive ? `` : ` admin__section_pb-20`}`}>
        <div className="admin__title">
            <label className="switch" htmlFor="socials-work">
                <div className="switch__box">
                    <input className="switch__control" type="checkbox" id="socials-work"
                        checked={isActive}
                        onChange={setActive}
                    />
                    <div className="switch__input" htmlFor="socials-work" />
                    <div className="switch__marker" />
                </div>
            </label>
            <h5 className="admin__h5 h5">
                Промо-блок
      </h5>
            <div className="info info_cursor_pointer" onClick={openFirstScreenModal}>
                <svg className="icon icon_info">
                    <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                    <p>Промо-блок</p>
                </div>
            </div>
        </div>
        {socials.some(item => item.active) ? <>
            <div className="admin__input-block">
                <p className="admin__label">Выберите социальную сеть</p>
                <ul className="constructor__socials admin__socials">
                    {socials?.map((soc, i) => {
                        if (!soc.active) return null
                        return <li key={"socialPromo-" + i}>
                            <input className="admin__social-input" type="checkbox" checked={soc.activeInPromo} id={`socials-promo-${soc.type}`} onChange={(e) => changeHandler({ ...soc, activeInPromo: e.target.checked })} />
                            <label className={`admin__social-label admin__social-label_${soc.type}`} htmlFor={`socials-promo-${soc.type}`}>
                                {icons[soc.type]["logo"]}
                            </label>
                        </li>
                    })}
                </ul>
            </div>
            <div className="admin__input-block">
                <label htmlFor="" className="admin__label">Текст промо-блока</label>
                <input type="text"
                    className="admin__input"
                    value={promoText}
                    placeholder="Лучший сервис на рынке недвижимости"
                    onChange={(e) => setPromoText(e.target.value)}
                    maxLength="80"
                />
                <span className="admin__input-notice">Не больше 80 символов</span>
            </div>
        </> : <span style={{ color: "red" }}>Сначала необходимо добавить соцсети в первом блоке</span>
        }
    </div>)
};

export default Promo;
