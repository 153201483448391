import React, { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBlock, getVillage } from '../actions/constructor';
import About from '../components/Constructor/About/About.js';
import Background from '../components/Constructor/Background/Background.js';
//import Infra from '../components/Constructor/Infra/Infra.js';
import Calculator from '../components/Constructor/Calculator/Calculator.js';
import CallbackModal from "../components/Constructor/CallbackModal/CallbackModal.js";
import Card from '../components/Constructor/Card/Card.js';
import ConstructorBanks from '../components/Constructor/ConstructorBanks/ConstructorBanks.js';
import Consultation from '../components/Constructor/Consultation/Consultation-2.js';
import '../components/Constructor/index.scss';
import Typical from '../components/Constructor/Typical/Typical.js';
import Preloader from '../components/Preloader';
import { runScriptsFromHTML } from "../utils";
import { getSubdomain } from '../utils/location';
import CardCottageSettlement from "../components/Constructor/Card/CardCottageSettlement";


const ConstructorCardSuburban = (props) => {
  const { id, city } = useParams()
  const [block, setBlock] = useState(null)
  const [isFetching, setFetching] = useState(true)
  const subdomain = getSubdomain()
  const [callBackTitle, setcallBackTitle] = useState("Получить консультацию")
  const [customHtml, setCustomHtml] = useState(null)
  const isPreview = window.location.search.includes("preview")

  const [openModal, closeCallbackModal] = useModal(() => (
    <CallbackModal closeCallbackModal={closeCallbackModal} title={callBackTitle}  isPreview={isPreview} id={id} />
  ), [callBackTitle]);
  const openCallbackModal = (title="Получить консультацию") => {
    setcallBackTitle(title)
    openModal()
  }
  useEffect(() => {
    if(!id){
      return
    }

    const form = new FormData()
    form.append('id', id)
    form.append("city", city)
    if(isPreview){
      form.append("isAdmin", 1)

    }
    form.append("subDomain", subdomain? subdomain :"test")
    getVillage(form).then(data=>{
      setBlock(data.block)
      let agency =  data.site.agency
      let agent =  data.site.agent
      let socials = data.site.socials
      let theme = data.site.theme
      let yandexMetric = data.site.yandexMetric
      if(isPreview){
        const data = localStorage.getItem("previewData")
        if(!data){
          props.history.push("/constructor-admin")
          props.setFullScreenLoading(false)
          return
        }
        const parsedData = JSON.parse(data)
        agent = parsedData.agent
        agency = parsedData.agency
         socials = parsedData.socials
         theme = parsedData.theme
         yandexMetric = parsedData.yandexMetric
      }
      props.agencySetState({
        agent,
        agency,
        socials,
        theme,
        yandexMetric,

      })
      const customJs = data?.site?.customJS
      if (customJs) {
        try {
          setCustomHtml(runScriptsFromHTML(customJs))
        } catch (error) {

        }
      }
      setFetching(false)
    })
  }, [id])

  if(isFetching){
    return <Preloader loading={true} />
  }
  return (
    <>
      <div className="constructor__background">
        <Background cardTheme={true} />
        <CardCottageSettlement block={block} theme={props.theme}/>
      </div>
      {/*<Typical apartments = {block.apartmentByRoomTypes} isPreview={isPreview} id={id}/>*/}
      <About
        about={''}
        title={`О ${block.name}`}
        previewText={block.about.previewText}
        advantages={[]}
      />
      <Calculator openCallbackModal={openCallbackModal} minPrice={5000000} />
      {/*{block.banks.length > 0 && <ConstructorBanks banks={block.banks} />}*/}
      <Consultation darkTheme={true} isPreview={isPreview}/>
      {!!customHtml && <div dangerouslySetInnerHTML={{__html: customHtml}} />}

    </>
  )
};

const mapStateToProps = (state) => ({
  theme: state.agency.theme
});

const mapDispatchToProps = (dispatch) => ({
  setPreviewData: (data) => dispatch({ type: "AGENCY_SET_PREVIEW_STATE", payload: data }),
  agencySetState: (payload)=> dispatch({type: "AGENCY_SET_STATE", payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstructorCardSuburban);
