import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import TinySlider from "tiny-slider-react";
import { useMediaQuery } from "beautiful-react-hooks";
//import Card from "../Estate/Card";
import Card from "../Cards/Card";
import { media } from '../../utils';
import ErrorPlug from '../ErrorBoundry/ErrorPlug'
const OtherBlocks = (props) => {
  const { items, innerRef, city, isPublic, savedFilterXmlId } = props;

  const node = useRef(null);

  const responsive1600 = useMediaQuery("(min-width: 1600px)");
  const responsiveOther = useMediaQuery("(max-width: 1599px)");

  const settings = {
    items: 1,
    nav: false,
    loop: media("isMobile") ? false : true,
    controls: false,
    responsive: {
      1600: {
        items: 3,
      },
    },
  };

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const showNavigation = useMemo(() => {
    const count = items.length;

    if (count > 3 && responsive1600) {
      return true;
    } else if (count > 1 && responsiveOther) {
      return true;
    }

    return false;
  }, [responsive1600, responsiveOther, items]);

  if (items.length === 0) return "";
  return (
    <section class={`section  section__another ${!media("isMobile") && `section_view_gray`}`} ref={innerRef}>
      <div id="anchor-others"></div>
      <div class="wrapper">
        <div class="wrapper__center">
          <div class="block__title block__title-buttons">
            <h2 class="h2">Другие ЖК застройщика</h2>
            {!media("isMobile") && showNavigation && (
              <div class="block__title-nav">
                <ul class="block__title-navs" id="anotherBlocks-nav">
                  <li
                    class="slider__nav-item prev"
                    onClick={() => moveSlider("prev")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    class="slider__nav-item next"
                    onClick={() => moveSlider("next")}
                  >
                    <svg class="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div class="complex__blocks">
          <div class={`complex__blocks-slider ${items.length === 1 ? 'for-one' : ''}`} id="anotherBlocks-slider">
            <ErrorPlug wrappedComponent="src/comp-ts/Complex/OtherBlocks.js">
              <TinySlider settings={settings} ref={node}>
                {items &&
                  items.map((item) => (
                    <Card
                      {...item}
                      endings={item.endingPeriod}
                      fullWidth={true}
                      city={city}
                      showOnLeft={true}
                      whatPage={"complex"}
                      isPublic={isPublic}
                      savedFilterXmlId={savedFilterXmlId}
                      key={item.id}
                    />
                  ))}
              </TinySlider>
            </ErrorPlug>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(OtherBlocks);
