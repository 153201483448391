import React from "react";
import './index.scss';
import Modal from '../core/Modal';
import MobileModal from '../core/MobileModal';
import ReserveAutopan from '../forms/ReserveAutopan.js';
import { useModal } from 'react-modal-hook';
import { media } from '../../utils';
import { Link } from "react-router-dom";

const AutopanTrigger = (props) => {
  const {modClass} = props;
  const [showReserveAutopan, hideReserveAutopan] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideReserveAutopan} classes="modal_task">
        <ReserveAutopan
          defaultValues={{
            date: new Date(),
            agree: true,
          }}
          close={hideReserveAutopan}
        />
      </Modal>
    ),
  );

  const [showMobileReserveAutopan, hideMobileReserveAutopan] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideMobileReserveAutopan} title="АвтоПАН" classes="modal_task modal_task_lg">
        <ReserveAutopan
          defaultValues={{
            date: new Date(),
            agree: true,
          }}
          close={hideMobileReserveAutopan}
        />
      </MobileModal>
    ),
  );

  return (
    <Link className={`autopan-trigger${modClass ? ` ${modClass}` : ``}`} to="/autopan" target={"_blank"}>
      Доступен АвтоПАН
      <svg className="icon">
        <use xlinkHref={modClass === `autopan-trigger_detail` ? `#wheel-red` : `#wheel`}></use>
      </svg>
    </Link>
  );
};

export default AutopanTrigger;
