
import { useEffect, useRef } from "react";

// юзать в крайних случаях (не лучшая практика)
/*
    const Parent = () => {
        const innerCbRef = useInnerCallBack() // можно положить условие в качестве первого аргумента, когда оно станет true  - выполниться функция потомка
        innerCbRef.current() // вызываем функцию которую положили в потомке
        return <Child innerCbRef={innerCbRef} />
    }
    const Child = ({ innerCbRef }) => {
        const someFunction = () => window.scrollTo(100)
        innerCbRef.current = ()=> someFunction()
        ...
    }
*/

const useInnerCallback = (flag = false) => {
    const fn = useRef(null)
    useEffect(() => {
        if (!flag && !fn.current) return
        fn.current()
    }, [flag, fn])
    return fn
};

export default useInnerCallback;
