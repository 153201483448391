import {Link, useLocation, useParams} from 'react-router-dom';
import React, { useState } from 'react';
import Toggler from '../../core/Toggler';
import MobileSlideMenu from '../../core/Toggler/MobileSlideMenu';
import Overlay from '../../core/Toggler/Overlay';
import useMedia from '../../../hooks/useMedia';
import { memo } from 'react';
import { getUrl } from '../../../api';

const Header = memo(
  ({
    phone,
    logo,
    email,
    name,
    lastName,
    workCompany,
    savedFilterSettings = {},
  }) => {
    const { hideAgency } = savedFilterSettings;
    const [menu, setMenu] = useState(null);

    const openMenuHandler = () => setMenu((prevState) => !prevState);
    const isTablet = useMedia('isTablet');

    const location = useLocation()
    const { savedFilterXmlId } = useParams()

    if(!name && !phone) return null
    return (
      <header className="sf__header">
        {!!logo?.src && (
          <Link to={window.location.pathname.includes("apartments") ? `/public/apartments/${savedFilterXmlId}` : `/public/global/${savedFilterXmlId}`} className="sf__logo">
            <img src={getUrl(logo?.src)} alt="" />
          </Link>
        )}
        <div className="sf__header-item sf__header-manager">
          <b className="text bold">
            {lastName} {name}
          </b>
          {workCompany && !hideAgency && <span className="text sf__work-company">АН «{workCompany}»</span>}
        </div>

        {!isTablet && (
          <>
            {!!phone && (
              <div className="sf__header-item sf__header-info">
                <span className="text">Телефон</span>
                <a href={`tel:${phone.toString()}`} className="text bold">
                  {phone.toString()}
                </a>
              </div>
            )}

            {!!email && (
              <div className="sf__header-item sf__header-info">
                <span className="text">E-mail</span>
                <a href={`mailto:${email.toString()}`} className="text bold">
                  {email.toString()}
                </a>
              </div>
            )}
          </>
        )}

        {isTablet && (
          <>
            <Overlay state={menu} setState={setMenu} addClass={'sf__overlay'} />

            <MobileSlideMenu
              state={menu}
              clickHandler={openMenuHandler}
              togglerClass="sf__toggler opened"
              addClass="sf__side-menu mobile-only"
            >
              <>
                {!!phone && (
                  <div className="sf__header-item sf__header-info">
                    <span className="text">Телефон</span>
                    <a href={`tel:${phone.toString()}`} className="text bold">
                      {phone.toString()}
                    </a>
                  </div>
                )}

                {!!email && (
                  <div className="sf__header-item sf__header-info">
                    <span className="text">E-mail</span>
                    <a
                      href={`mailto:${email.toString()}`}
                      className="text bold"
                    >
                      {email.toString()}
                    </a>
                  </div>
                )}
              </>
            </MobileSlideMenu>

            <Toggler clickHandler={openMenuHandler} addClass="sf__toggler" />
          </>
        )}
      </header>
    );
  },
);

export default Header;
