import { memo } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";

const ProjectCard = memo((props) => {
    const {
        link,
        id,
        xmlId,
        photo,
        title,
        priceDiscount,
        countBedrooms,
        constructionPeriod,
        materialHouse,
        squareHouse,
        squareLiving,
        fullPrice,
        compilationObjectType,
        equipments,
        index,
        withoutBtnsList,
        houseType,
        isActive,
        isFetchingQuickCard
    } = props;


    return <Card
        {...props}
        isFlat
        isBordered
        topLabel={fullPrice}
        link={link}
        title={title}
        subtiltle={squareHouse}
        src={getUrl(photo)}
        descValue={materialHouse}
        descTitle={"Материал"}
    />
});

export default ProjectCard;
