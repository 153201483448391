const initialState = {
  isFetching: false,
  error: false,
  data: [],
};

const myObjects = (state = initialState, action) => {
  switch (action.type) {
    case "MY_OBJECTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MY_OBJECTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        filterData: action.filterData,
      };
    case "MY_OBJECTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "MY_OBJECTS_RESET":
      return { ...initialState };
    case "MY_OBJECTS_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };
    default:
      return state;
  }
};

export default myObjects;
