import { memo } from 'react';
import SubmitButton from '../../../SubmitButton';
import SimpleBar from 'simplebar-react';
import Button from '../../../core/Button';

const PublicMobileFilter = memo((props) => {
  const { count, isLoading, onSumbit, resetFilter, disableSubmit, loaderColor, component: Comp } = props;

  return (
    <div className="sf__filter-block">
      <div className="sf__filter sf__filter_on-map">
        <Comp {...props} />
        <div className="sf__filter-btns">
          <Button
            buttonType="secondary"
            addClass="sf__btn"
            onClick={resetFilter}
          >
            Очистить
          </Button>
          <SubmitButton
            className="sf__filter-btn button button_view_default sf__map-btn"
            onClick={onSumbit}
            isLoading={isLoading}
            type="button"
            disabled={disableSubmit}
            loaderColor={loaderColor}
          >
            {count}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
});

export default PublicMobileFilter;
