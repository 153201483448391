import React, { useMemo } from 'react';
import { getUrl } from '../../../api';

const FirstScreen = ({ data = {}, activeSettings, isCommercial }) => {

  const { object, typeXmlId } = data;
  const isParcel = object?.type?.xmlId === "parcel"
  const isSuburban = typeXmlId === "suburban"
  const isParking = object?.roomTypeXmlId === "abc24"
  const imageTitle = useMemo(() => {
    if (isParking)  return "План паркинга"
    if (isParcel) return "План"
    if (isSuburban) return "Цокольный этаж"
    return "Планировка"
  }, [isParcel, isSuburban, isParking])


  if (!data) return 'Loading...';
  if (!object) return null;
  const pathToPlan = isParcel ? object.plan?.[0] : object[isSuburban ? "planBasement" : "plan"]
  return (
    <div className="presentation__info pdf-block">
      {!!pathToPlan && !pathToPlan?.empty && <div className="presentation__box mb-25" data-id="layout_plan">
        {!!imageTitle && <div className="presentation__title">{imageTitle}</div>}
        <img
          className="presentation__img"
          src={getUrl(pathToPlan?.src)}
          alt=""
          style={{ maxHeight: 420, margin: "0px auto" }} />
      </div>}
      {isSuburban && !isParcel && object.plan && object.plan.map((plan, i) => <div className="pdf-block"><div
        className={`presentation__box`}
        data-id="layout_plan"
        key={i}
      >
        <div className="presentation__title">{i + 1} Этаж</div>
        <img className="presentation__img" src={getUrl(plan.src)} alt="" style={{ maxHeight: 420, margin: "0px auto" }} />
      </div></div>
      )}
    </div>
  );
};

export default FirstScreen;