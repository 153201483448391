import request from "../api";
import { actionFactory } from "./actionUtils";

const component = "panpartner:profile.ajax"

const requestOffers = actionFactory("PROFILE_OFFERS_REQUEST");

const successOffers = actionFactory("PROFILE_OFFERS_SUCCESS");

const failureOffers = actionFactory("PROFILE_OFFERS_FAILURE");

export const clearOffers = actionFactory("PROFILE_OFFERS_RESET");

export const fetchOffers = () =>
  dispatch => {
    dispatch(requestOffers());
    return request(component, "getOffers", { city: "spb" })
      .then(
        data => {
          dispatch(
            successOffers({
              offers: data?.offers,
            })
          );
        },
        error => {
          dispatch(failureOffers());
          return Promise.reject(error);
        }
      );
  };

export const saveOffers = () => request(component, "confirmOffers", { city: "spb" });