export const PriceCurency = ({ currencyList, currency, setCurrency }, hide) => <div style={{ display: "flex", flexDirection: "column", paddingBottom: 16, }}>
    <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 8 }}>
        {Object.keys(currencyList).map((currencyName) => <div
            style={{
                cursor: "pointer",
                border: "1px solid #DFE1E9",
                borderRadius: 5,
                padding: "12px 30px",
                fontWeight: 700,
                backgroundColor: currency === currencyName ? "#ECEDF0" : "white"
            }}
            onClick={() => setCurrency(currencyName)}
        >
            {currencyName}
        </div>
        )}
    </div>
    {currencyList[currency]?.coefficient && <span style={{ color: "#8F95AC" }}>По курсу: {currencyList[currency]?.coefficient}</span>}
</div>