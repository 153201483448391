export const ZoomIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.94393 10.9185C6.94393 14.2322 9.70923 16.9185 13.1204 16.9185C16.5316 16.9185 19.2969 14.2322 19.2969 10.9185C19.2969 7.60475 16.5316 4.91846 13.1204 4.91846C9.70923 4.91846 6.94393 7.60475 6.94393 10.9185Z"
      stroke="#2C2E3E"
    />
    <path
      d="M9.04004 15.5347L5.3999 19.4185"
      stroke="#2C2E3E"
      strokeLinecap="square"
    />
  </svg>
);
