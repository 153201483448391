
import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useBitrixButton = () => {
  const userState = useSelector(state => state.user ?? {})
  const [widgetInstance, setwidgetInstance] = useState()
  const { info: user, access, siteDomain } = userState;

  useEffect(() => {
    window.addEventListener('onBitrixLiveChat', onBXliveChat);
    if (window.BX?.SiteButton) {
      window.BX.SiteButton.show()
      return hideBXbutton
    }
    const scriptEl = document.createElement('script');
    scriptEl.async = true;
    scriptEl.src = `https://crm.panpartner.ru/upload/crm/site_button/loader_4_9mlfhf.js?${(Date.now() / 60000 | 0)}`;
    const scriptNode = document.getElementsByTagName('script')[0];
    scriptNode.parentNode.insertBefore(scriptEl, scriptNode);
    return hideBXbutton
  }, [])

  const customData = useMemo(() => {
    if (!user?.id) return
    return [
      {
        "USER": {
          "NAME": `${user.name} ${user.lastName}`,
        }
      },
      {
        "GRID": [
          {
            "NAME": "Телефон",
            "VALUE": user.phone,
            "DISPLAY": "LINE"
          },
          {
            "NAME": "E-mail",
            "VALUE": user.email,
            "DISPLAY": "LINE",
          },
          {
            "NAME": "ID",
            "VALUE": user.id,
            "DISPLAY": "LINE"
          },
          {
            "NAME": "Личный сайт",
            "VALUE": siteDomain,
            "DISPLAY": "LINE"
          },

        ]
      }
    ]
  }
    , [user, siteDomain])

  useEffect(() => {
    const hasData = !!window.BXLiveChat?.__privateMethods__?.customData?.length
    if (!customData || !widgetInstance || hasData) return
    widgetInstance.setCustomData(customData);
    widgetInstance.setUserRegisterData({
      'name': user.name,
      'lastName': user.lastName,
      'email': user.email,
      'position': access
    })
  }, [customData, widgetInstance, user, access])

  const onBXliveChat = e => setwidgetInstance(e?.detail?.widget)

  const hideBXbutton = () => {
    window.BX?.SiteButton.hide()
    window.removeEventListener("onBitrixLiveChat", onBXliveChat)
  }
  return widgetInstance
}

export default useBitrixButton;