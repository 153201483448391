import React, { useEffect, useState } from 'react';
import { Map, Polygon, YMaps } from 'react-yandex-maps';
import Checkin from '../assets/images/icons/Checkin.svg';

const ApartmentMap = ({ cone, cords, center, mapZoom = 16, enableResetButton }) => {

    const [mapInstance, setMapInstance] = useState(null);
    const [ymaps, setYmaps] = useState(null);

    useEffect(() => {
        if (!mapInstance || !ymaps?.Polygon) return
        cone.map(item => addSector(item))
    }, [mapInstance, cone])
    
    const getAngle = degrees => degrees * Math.PI / 180; //угол в радианах

    const evaluateSector = (startGlobalCords, endGlobalCords, radius, angleOfView) => {
        const deltaY = endGlobalCords[1] - startGlobalCords[1]
        const deltaX = endGlobalCords[0] - startGlobalCords[0]
        const vectorLength = Math.sqrt(deltaX ** 2 + deltaY ** 2)
        const pointCos = radius * deltaX / vectorLength
        // const pointSin = radius * deltaY / vectorLength

        const halfAngle = angleOfView / 2

        if (deltaX > 0 && deltaY > 0) {
            return Math.acos(pointCos) - halfAngle
        }
        if (deltaX < 0 && deltaY > 0) {
            return Math.acos(pointCos) - halfAngle
        }
        if (deltaX < 0 && deltaY < 0) {
            return Math.acos(Math.abs(pointCos)) - halfAngle + Math.PI
        }
        if (deltaX > 0 && deltaY < 0) {
            return 2 * Math.PI - Math.acos(pointCos) - halfAngle
        }

    }
     
    const dict = ["Восток", "Юго-восток", "Юг", "Юго-Запад", "Запад", "Северо-Запад", "Север", "Северо-Восток",]
    const smallPointsIndex = [0, 2, 4, 5]
    const getWorldSide = (absolutePointValue) => {  //absolutePointValue -  точное значение конечной точки на тригонометрическом круге (от 0 до 2 (пи))
        //тк угол в 45 градусов это 0.25 ПИ, то максимальный угол до следующего сектора это 22.5 градуса или в ПИ:
        let angleStep = 0.25
        for (let i = 0, k = 0; i < dict.length; k += angleStep, i += 1) {
            angleStep = smallPointsIndex.includes(i) ? 0.3 : 0.2
            const nextK = k + angleStep
            if (absolutePointValue > k && absolutePointValue <= nextK) {
                console.log(dict[i])
            }
        }
    }
    const addSector = (cords) => {
        const [lineStart, lineEnd] = cords
        const projection = mapInstance.options.get('projection');
        const zoom = 10 //mapInstance.getZoom();
        const radius = 1;
        const startGlobalCords = projection.toGlobalPixels(lineStart, zoom);
        const endGlobalCords = projection.toGlobalPixels(lineEnd, zoom);

        const precision = 130; // кол-во точек для полигона 
        let points = [];
        const degrees = 45 // угол обзора из окон
        const angleOfView = getAngle(degrees) //перевёл в радианы
        const center = startGlobalCords
        const start = evaluateSector(startGlobalCords, endGlobalCords, radius, angleOfView) //от 0 до 6 для тестов сторон света
        const delta = angleOfView;
        // getWorldSide((start + angleOfView) / Math.PI)
        const step = delta / precision;
        points.push(center);
        for (var i = 0; i <= delta + step; i += step) {
            points.push([
                center[0] + radius * Math.cos(start + i),
                center[1] + radius * Math.sin(start + i)
            ]);
        }
        points.push(center);

        points = points.map(point => projection.fromGlobalPixels(point, zoom));

        let polygon = new ymaps.Polygon([points], {}, {
            fillColor: '#8790E5',
            opacity: 0.6,
            strokeColor: '#8790E540',
            strokeOpacity: 0,
        });
        mapInstance.geoObjects.add(polygon);
    }
    return (
        <YMaps>
            <Map
                defaultState={{
                    center: center || cone[0]?.[0],
                    zoom: mapZoom,
                }}
                width="100%"
                height="100%"
                modules={["Polygon"]}
                onLoad={setYmaps}
                instanceRef={setMapInstance}
                options={{
                    suppressMapOpenBlock: true,
                    suppressObsoleteBrowserNotifier: true
                }}
            >
                <Polygon
                    geometry={cords}
                    options={{
                        fillColor: '#8790e533',
                        strokeColor: '#8790E5',
                        strokeWidth: 1,
                        strokeOpacity: 0.5,
                    }}
                />
            </Map>
            {!!enableResetButton && <div
                style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    bottom: 40,
                    height: 40,
                    position: "absolute",
                    right: 16,
                    width: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={() => mapInstance?.setCenter(center || cone[0]?.[0])}
            >
                <img src={Checkin} alt="XY" />
            </div>}
        </YMaps>
    )
}

export default ApartmentMap
