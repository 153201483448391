import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import {Prompt, useHistory, useLocation, useParams} from 'react-router-dom';
import {
  addAdditionalPhoto, clearPresentation,
  editPresentation, fetchPresentation,
  getClients, removeAdditionalPhoto
} from '../../actions/presentation';
import {
  deletePresentations, getPdfThunk,
  handleAttachClient
} from '../../actions/presentations';
import useMedia from '../../hooks/useMedia';
import { settingsToParams } from '../../utils';
import Preloader from '../Preloader';
import PresentationContent from './PresentationContent';
import PresentationHeader from './PresentationHeader';
import renderPDF from './renderPDF';
import Settings from './Settings';



const View = (props) => {
  const { id } = useParams();
  const {
    isFetching,
    error,
    fetch,
    fetchClients,
    reset,
    remove,
    addPhoto,
    removePhoto,
    setFormData,
    attachClient,
    saveIt,
    data,
    settings,
    currSettings,
    settingsData,
    formData,
    clients,
    getPdf,
    filePath
  } = props;

  const [initialModalMortgageState, setInitialModalMortgageState] = useState({})
  const [modalMortgageState, setModalMortgageState] = useState({})
  const [mortgageRates, setMortgageRates] = useState([])

  useEffect(() => {
    setModalMortgageState(data?.mortgage)
    setInitialModalMortgageState(data?.mortgage)
    setMortgageRates(data?.mortgageRates)
  }, [data, mortgageRates])

  const [isChanged, setIsChanged] = useState(false);
  const [rendering, setRendering] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMedia("isMobile")

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = 0;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > offset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleSettings = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  let history = useHistory();

  const handleBackClick = (e) => {
    history.push(`/personal/presentations`);
    e.preventDefault();
  };

  useEffect(() => {
    fetch(id).then(() => {
      setTimeout(() => setRendering(false), 500);
    });
    fetchClients()
    return () => {
      reset();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if(!data || !!filePath) return

    getPdf({id: data.id, city: data.city})
  }, [data, filePath])

  useEffect(() => {
    if (isChanged) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      setTimeout(() => setRendering(false), 500);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [isChanged]);

  useEffect(() => {
    if (!isFetching) renderPDF();
  }, [isFetching]);

  const handleSettings = () => {
    if (!rendering) setIsChanged(true);
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleSave = () => {
    if (isChanged) {
      setRendering(true);
      setIsChanged(false);
    }
  };
  const save = (settings, presId, description, comment, firstPayEstate, rateEstate, timeEstate) => {
    formData.mortgage = modalMortgageState
    return saveIt(settingsToParams(formData.settings),
      id,
      description ?? formData.description,
      comment ?? formData.comment,
      data.city,
      firstPayEstate ?? formData.mortgage.firstPayEstate,
      rateEstate ?? formData.mortgage.rateEstate,
      timeEstate ?? formData.mortgage.timeEstate

    )
  };
  if (error) return 'Error';

  if (!data) return <Preloader loading={true} />;


  return (
    <div className={`${isMobile ? "mobile-filters" : ""}`}>
      {!isMobile &&
        <PresentationHeader
          {...data}
          id={id}
          handleDelete={remove}
          handleSave={handleSave}
          canSave={isChanged}
          save={save}
          formData={formData}
          isFetching={isFetching}
          path={filePath}
          {...props}
        />
      }
      {isMobile &&
        <>
          <div className="mobile-filters__title">
            <svg onClick={handleBackClick} className="icon icon_arrow_up"><use xlinkHref="#arrow_up"></use></svg>
            <h3>Редактировать</h3>
          </div>
          <button className="button-mobile for-presentations" onClick={toggleSettings}>
            Настройки презентации
          </button>

          <div className={`mobile-filters for-presentation ${isOpen ? 'is-active' : ''}`}>
            {isOpen &&
              <>
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={toggleSettings}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>Настройки презентации</h3>
                </div>
                <div className="mobile-filters__content">
                  <Settings
                    settings={settings}
                    setFormData={setFormData}
                    formData={formData}
                    save={save}
                    addPhoto={addPhoto}
                    removePhoto={removePhoto}
                    data={data}
                    currSettings={currSettings}
                    settingsData={settingsData}
                    handleSettings={handleSettings}
                    handleSave={handleSave}
                    id={id}
                    canSave={isChanged}
                    onClose={toggleSettings}
                    initialModalMortgageState={initialModalMortgageState}
                    modalMortgageState={modalMortgageState}
                    setModalMortgageState={setModalMortgageState}
                    mortgageRates={mortgageRates}
                  />
                </div>
              </>
            }
          </div>
        </>
      }
      <div className="sf wrapper">
        <div className="sf__detail-row sf__detail-row--common-page">
          <div className="sf__detail-col">
            <PresentationContent
              getPdf={getPdf}
              fetchClients={fetchClients}
              clients={clients}
              data={data}
              settings={settings}
              formData={formData}
              attachClient={attachClient}
              save={save}
              path={filePath}
              {...props}
            />
          </div>
          {!isMobile &&
            <div className={`sf__detail-col ${isSticky ? 'sticky-settings' : ''}`}
                 style={{position: 'sticky', top: 70}}>
              <Settings
                settings={settings}
                setFormData={setFormData}
                formData={formData}
                save={save}
                addPhoto={addPhoto}
                removePhoto={removePhoto}
                data={data}
                currSettings={currSettings}
                settingsData={settingsData}
                handleSettings={handleSettings}
                modalMortgageState={modalMortgageState}
                setModalMortgageState={setModalMortgageState}
                mortgageRates={mortgageRates}
                initialModalMortgageState={initialModalMortgageState}
              />
            </div>
          }
        </div>
      </div>


      {/*<section className="section section_view_white">*/}
      {/*  <div className="wrapper">*/}
      {/*    <div className="presentation__body">*/}
      {/*      {!isMobile &&*/}
      {/*        <Settings*/}
      {/*          settings={settings}*/}
      {/*          setFormData={setFormData}*/}
      {/*          formData={formData}*/}
      {/*          save={save}*/}
      {/*          addPhoto={addPhoto}*/}
      {/*          removePhoto={removePhoto}*/}
      {/*          data={data}*/}
      {/*          currSettings={currSettings}*/}
      {/*          settingsData={settingsData}*/}
      {/*          handleSettings={handleSettings}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      <PresentationContent*/}
      {/*        getPdf={getPdf}*/}
      {/*        fetchClients={fetchClients}*/}
      {/*        clients={clients}*/}
      {/*        data={data}*/}
      {/*        settings={settings}*/}
      {/*        formData={formData}*/}
      {/*        attachClient={attachClient}*/}
      {/*        save={save}*/}
      {/*        path={filePath}*/}
      {/*        {...props}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  {isMobile &&*/}
      {/*    <PresentationHeader*/}
      {/*      {...data}*/}
      {/*      id={id}*/}
      {/*      handleDelete={remove}*/}
      {/*      handleSave={handleSave}*/}
      {/*      canSave={isChanged}*/}
      {/*      save={save}*/}
      {/*      formData={formData}*/}

      {/*      getPdf={getPdf}*/}
      {/*      fetchClients={fetchClients}*/}
      {/*      clients={clients}*/}
      {/*      data={data}*/}
      {/*      settings={settings}*/}
      {/*      attachClient={attachClient}*/}
      {/*      isFetching={isFetching}*/}
      {/*      path={filePath}*/}
      {/*    />*/}
      {/*  }*/}
      {/*</section>*/}
      <Preloader loading={rendering || isFetching || !filePath} />
      {isChanged && (
        <Prompt message="Вы не сохранили изменения. Вы точно хотите выйти?" />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchPresentation(id)),
  fetchClients: () => dispatch(getClients()),
  reset: () => dispatch(clearPresentation()),
  remove: (ids) => dispatch(deletePresentations(ids)),
  addPhoto: (form, id, objectXmlId, type, city) =>
    dispatch(addAdditionalPhoto(form, id, objectXmlId, type, city)),
  removePhoto: (form, id, objectXmlId, type, city) =>
    dispatch(removeAdditionalPhoto(form, id, objectXmlId, type, city)),
  setFormData: (data) =>
    dispatch({ type: 'PRESENTATION_SET_FORMDATA', data: data }),
  saveIt: (settings, id, descriptions, comment, city, firstPayEstate, rateEstate, timeEstate) =>
    dispatch(editPresentation(settings, id, descriptions, comment, city, firstPayEstate, rateEstate, timeEstate)),
  attachClient: (clientId, presentationId, city, isAttaching) =>
    dispatch(handleAttachClient(clientId, presentationId, city, isAttaching)),
  getPdf: (params)=>dispatch(getPdfThunk(params))
});

const mapStateToProps = (state) => ({
  data: state.presentation.data,
  clients: state.presentation.clients,
  isFetching: state.presentation.isFetching,
  error: state.presentation.error,
  settings: state.presentation.settings,
  currSettings: state.presentation.currSettings,
  settingsData: state.presentation.settingsData,
  formData: state.presentation.formData,
  filePath:  state.presentation.filePath,
});

View.propTypes = {
  fetch: PropTypes.func,
  reset: PropTypes.func,
  data: PropTypes.object,
  settings: PropTypes.object,
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
