import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { socials } from "../../selectors/user";

const MobileHeader = (props) => {
  return (
    <>
      <div className="auth__department">
        <div className="auth__department-phone">
          <a className="link-hover" href="tel:+78122439999">
            8 (812) 426-18-63
          </a>
        </div>
        {/* <div className="auth__department-name">Отдел развития</div> */}
      </div>
      <div className="auth__logo">
        <svg className="icon">
          <use xlinkHref="#panpartner"></use>
        </svg>
      </div>
    </>
  );
};

const MobileLayout = ({ socials, children }) => {
  return (
    <>
      <div className="content">
        <main>
          <div className="auth">
            <MobileHeader />
            <section className="section auth__content-section">
              <div className="wrapper">
                <div className="auth__content">
                  {children}
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  socials: socials,
});

const mapDispatchToProps = (dispatch) => ({});

MobileLayout.propTypes = {
  socials: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileLayout);
