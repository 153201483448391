import React, { memo } from "react";
import { useSelector } from "react-redux";
import request from "../../api";
import useSimpleId from "../../hooks/useSimpleId";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import SubmitButton from "../SubmitButton";
import { standartErrorToast, standartSuccessToast } from "../toasts";

const dict = {
  notWork: 'Не работаю на рынке недвижимости',
  notUse: 'Не пользуюсь вашим сервисом',
  other: 'Иная причина'
}

const DeleteForm = memo(props => {

  const userName = useSelector(state => state.user?.info?.name);

  const secretCode = useSimpleId()

  const {
    state: {
      reason,
      comment,
      password,
      isLoading,
      confirmSecretCode,
      errors
    },
    setValue,
    setValues
  } = useUniversalReducer({
    reason: "notWork",
    comment: "",
    isLoading: false,
    password: "",
    confirmSecretCode: "",
    errors: {}
  })

  const isOtherType = reason === "other"

  const isButtonDisabled = (isOtherType && !comment.replace(/ /g, "")) || !reason || !password.replace(/ /g, "") || (secretCode !== confirmSecretCode)

  const deleteUser = () => {
    if (isButtonDisabled) return setValue("errors", {
      secretCode: secretCode !== confirmSecretCode && "Некорректный код",
      comment: isOtherType && !comment.replace(/ /g, "") && "Введите причину",
      password: !password.replace(/ /g, "") && "Введите пароль"
    })

    setValues({
      errors: {},
      isLoading: true
    })

    request("panpartner:system.auth.form", "deleteAccount", {
      reason: dict[reason],
      comment,
      password,
    })
      .then(standartSuccessToast, standartErrorToast)
      .finally(() => setValue("isLoading", false))
  }

  const setReason = (e, comment = "") => setValues({
    reason: e?.target?.value ?? e,
    comment
  })

  return (
    <div className='wrapper delete-user'>
      <h1 className="delete-user__title">Здравствуйте, {userName}!</h1>
      <div className="delete-user__form">
        <p className='delete-user__hint' style={{ marginBottom: 20 }}>Для удаления аккаунта, укажите причину </p>
        <div className="custom-radio">
          <label>
            <input
              type="radio"
              value="notWork"
              checked={reason === 'notWork'}
              onChange={setReason}
            />
            Не работаю на рынке недвижимости
          </label>

          <label>
            <input
              type="radio"
              value="notUse"
              checked={reason === 'notUse'}
              onChange={setReason}
            />
            Не пользуюсь вашим сервисом
          </label>

          <label>
            <input
              type="radio"
              value="other"
              checked={reason === 'other'}
              onChange={setReason}
            />
            Иная причина
          </label>

        </div>

        {
          reason === 'other' &&
          <div className="modal-form__row" style={{ marginTop: 10 }}>
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <span className="textarea__label">Иная причина</span>
                <textarea
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  name="comment"
                  onChange={e => setReason("other", e.target.value)}
                  value={comment}
                  style={{ resize: 'none' }}
                />
                {!!errors.comment && (
                  <span className="input__error-label">
                    {errors.comment}
                  </span>
                )}
              </label>
            </div>
          </div>
        }

        <div className="hp__form-item" style={{ marginTop: 30 }}>
          <label className="hp__form-label text">
            Пароль
          </label>
          <label
            className="input input_width_available input_type_form"
          >
            <input
              className={'input__control'}
              type="password"
              placeholder="Введите пароль"
              value={password}
              onChange={e => setValue("password", e.target.value)}
            />
            {!!errors.password && (
              <span className="input__error-label">
                {errors.password}
              </span>
            )}
          </label>
        </div>

        <div className="delete-user__code-area">
          <div className="delete-user__code">{secretCode}</div>
          <div className="hp__form-item" style={{ marginTop: 10 }}>
            <label className="hp__form-label text">
              Введите код
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={'input__control'}
                type="text"
                value={confirmSecretCode}
                onChange={e => setValue("confirmSecretCode", e.target.value)}
              />
              {!!errors.secretCode && (
                <span className="input__error-label">
                  {errors.secretCode}
                </span>
              )}
            </label>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <SubmitButton
            className="button button_view_default btn_full"
            onClick={deleteUser}
            isLoading={isLoading}
          >
            <span>Удалить аккаунт</span>
          </SubmitButton>
        </div>

      </div>
    </div>
  )
})

export default DeleteForm;