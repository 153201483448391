import React, { memo, useState } from "react";
import {formatMoney, getRoundedPrice, media, objectsArrayToFormData, toFormData} from "../../../../../utils";

import emptyPlan from '../../../../../assets/images/emptyPlan.svg'
import useMedia from "../../../../../hooks/useMedia";
import LoadMoreSpinner from "../../../../LoadMoreSpinner";
import { getUrl } from "../../../../../api";
import {AddToCompilationIcon} from "../../icons";
import useApi from "../../../../../hooks/useApi";
import {successToast} from "../../../../toasts";
import useModalWithData from "../../../../../hooks/useModalWithData";
import CompilationModal from "../../../../../pages/NewFavorites/compilationModal";
import { useParams } from "react-router-dom";




const onImgError = ({ target }) => {
    target.onerror = null;
    target.src = emptyPlan
}
const getSortFn = ident => (a, b) => a[ident] - b[ident]
const getAnotherSortFn = ident => (a, b) => {
    if (a[ident].name < b[ident].name) {
        return -1;
    }
    if (a[ident].name > b[ident].name) {
        return 1;
    }
    return 0;
}
const renderMoney = price => <span className="nowrap">{formatMoney(price, " р.")}</span>

const IMG = ({ src }) => {
    const [loading, setLoading] = useState(true);
    return <div className="table-img">
        {loading && <div style={{ height: "35px", width: "100%" }}><LoadMoreSpinner loading={true} /></div>}
        <img src={getUrl(src)} alt="" onError={onImgError} onLoad={() => setLoading(false)} />
        <div className="table-img__preview">
            <img src={src} alt="" onError={onImgError} />
        </div>
    </div>
}
const renderImg = params => <IMG {...params} />
const Surface = ({ title }) => {
    const isLaptop = useMedia("isLaptop")
    const isTablet = useMedia("isTablet")
    const handleSurface = e => isLaptop && e.stopPropagation();
    return <div className="surface" onClick={handleSurface}>
        <div className="surface__wrap">{title}</div>
    </div>
}
const createColumn = (key, sortEnabled, lastSort, renderDash) => ({
    dataIndex: key,
    key,
    ...(sortEnabled ? { sorter: getSortFn(key) } : []),
    ...(lastSort ? { sortOrder: lastSort && lastSort.columnKey === key && lastSort.order } : []),
    ...(renderDash ? { render: v=> v? v: "—" } : []),
})

const TOOLTIP_CUT_VALUE = 14;
const Tooltip = memo(({ title }) => title?.length > TOOLTIP_CUT_VALUE ? <div className="table2-with-tooltip">
    <span>{`${title.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
    <div className="table2-with-tooltip__content">{title}</div>
</div>
    : <span>
        {title}
    </span>)


const GetColumns = params => {
    const { isDesktop, isLaptop, isTablet, type, showMotivation, lastSort, items, city, builderId } = params

    const [isId, setIsId] = useState(null)
    const [isType, setIsType] = useState(null)

    const {
        openModal: openCompilationModal,
    } = useModalWithData({
        component: ({ closeModal, favorite }) => <CompilationModal
            close={closeModal}
            addToCompilationParams={[{ objectId: isId, type: isType, city }]}
        />,
        modalProps: {
            withoutBG: true,
            closeByPastClick: true,
            classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
        },
        dep: []
    })


    const apartmentsColumns = [
        {
            title: "ID",
            ...createColumn("id", true),
            width: 80,
        },
        {
            title: "План.",
            ...createColumn("plan"),
            width: isDesktop ? 50 : 80,
            render: renderImg
        },
        {
            title: "Секц.",
            ...createColumn("apartmentSection", true, false, true),
        },
        {
            title: isDesktop ? "Эт." : "Этаж",
            ...createColumn("floor", true),
        },
        {
            title: "№ кв.",
            ...createColumn("number", true),
        },
        {
            title: "S общ.",
            ...createColumn("squareTotal", true),
        },
        {
            title: "S жил.",
            ...createColumn("squareLiving", true),
        },
        {
            title: "S кух.",
            ...createColumn("squareKitchen", true),
        },
        {
            title: "Отделка",
            ...createColumn("decoration"),
            sorter: (a, b) => a.decoration?.length - b.decoration?.length,
            render: v => <Tooltip title={v} />
        },
        {
            title: "Цена 100%",
            ...createColumn("costWithDiscount", true),
            render: renderMoney,
        },
        {
            title: "Цена, м²",
            ...createColumn("minMeterPrice", true),
            render: renderMoney,
        },
        {
            title: builderId === 11615 ? "Цена при ипотеке" :"Цена баз.", 
            ...createColumn("baseCost", true),
            render: (price, { objectType, costWithDiscount }) => renderMoney(objectType === "apartment" ? price : costWithDiscount),
            width: 100,
        },
        {
            title: "Тип",
            ...createColumn("flatType"),
            sorter: (a, b) => a.flatType?.length - b.flatType?.length,
            width: 100,
            render: (v, { objectType }) => objectType === "assignment" ?
                <div class="status-assignment">Уступка ПАН</div> :
                <Tooltip title={v} />
        },

        {
            title: "Статус",
            dataIndex: "status",
            key: "status1",
            sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
            width: 60,
            render: ({ color, name }, { bookingLink, id, compilationObjectType }) => {

                return (
                    <div class="table2-status">
                        <div className={`status-circle status-circle_${color === "green" ? "positive" : "negative"}`}>
                            <div className="status-circle__tooltip">{name}</div>
                        </div>

                        <div className="table-compilation-icon" onClick={(e) => {
                            e?.stopPropagation?.();
                            setIsId(id)
                            setIsType(compilationObjectType)
                            openCompilationModal()
                        }
                        }>
                            <AddToCompilationIcon/>
                            <div className="table-compilation-icon__hint">Добавить в подборку</div>
                        </div>
                    </div>
                )
            },
        },
    ]
    const mobileApartmentsColumns = [
        {
            title: "Пл",
            ...createColumn("plan"),
            render: ({ src }) => <div className="table-img">
                <img src={src} alt="" onError={onImgError} />
            </div>
        },
        {
            title: "Эт",
            ...createColumn("floor"),
        },
        {
            title: "S общ",
            ...createColumn("squareTotal"),
            render: square => <span className="nowrap">{square} м²</span>,
        },
        {
            title: "Цена",
            ...createColumn("costWithDiscount"),
            render: price => <span className="nowrap">{getRoundedPrice(price)} млн.</span>,
        },
        {
            title: "Тип",
            ...createColumn("flatType"),
            render: (flatType, { objectType }) => objectType === "assignment" ?
                <div class="status-assignment">Переуст</div> :
                <Surface title={flatType} />,
        },
        {
            title: "Стат",
            dataIndex: "status",
            key: "status1",
            render: ({ color, name }) => <div class="table2-status">
                <div className={`status-circle status-circle_${color === "green" ? "positive" : "negative"}`}>
                    <div className="status-circle__tooltip">{name}</div>
                </div>
            </div>
        },
    ]
    const assignmentsColumns = [
        {
            title: "ID",
            width: 60,
            ...createColumn("id", true, lastSort)
        },
        {
            title: "План.",
            ...createColumn("plan"),
            width: isDesktop ? 50 : 80,
            render: renderImg,
        },
        {
            title: "Район",
            ...createColumn("region", false, lastSort),
            width: !isDesktop ? 130 : 80,
            render: v => <Tooltip title={v.name} />,
            sorter: getAnotherSortFn("region")
        },
        {
            title: "Метро",
            ...createColumn("subway", false, lastSort),
            render: subway => <Surface title={subway ? subway.map(v => v.name).join(", ") : ""} />,
            sorter: (a, b) => a.subway.length - b.subway.length,
        },
        {
            title: !isDesktop ? "Застройщик" : "Застр.",
            ...createColumn("builder", false, lastSort),
            render: (builder) => <Surface title={builder?.name} />,
            sorter: getAnotherSortFn("builder"),
        },
        {
            title: "ЖК",
            ...createColumn("block", false, lastSort),
            width: !isDesktop ? 130 : 60,
            render: v => <Tooltip title={v.name} />,
            sorter: getAnotherSortFn("block")
        },
        {
            title: !isDesktop ? "Корп." : "Кор.",
            ...createColumn("corpus", true, lastSort),
            width: !isDesktop ? 120 : 70,
            render: v => <Tooltip title={v} />,
        },
        {
            title: "Эт.",
            ...createColumn("floor", true, lastSort),
            width: 60,
        },
        {
            title: !isDesktop ? "S общ." : "S об.",
            width: 80,
            ...createColumn("squareTotal", true, lastSort),
        },
        {
            title: "S кух.",
            ...createColumn("squareKitchen", true, lastSort),
            width: 80,
        },
        {
            title: !isDesktop ? "Отделка" : "Отд",
            ...createColumn("decoration", false, lastSort),
            width: !isDesktop ? 110 : 80,
            render: v => <Tooltip title={v} />,
            sorter: (a, b) => {
                if (a.decoration < b.decoration) {
                    return -1;
                }
                if (a.decoration > b.decoration) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Цена",
            ...createColumn("costWithDiscount", true, lastSort),
            fixed: "right",
            render: renderMoney,
            width: 100,
        },
        ...(showMotivation
            ? [
                {
                    title: !isDesktop ? "Комисс." : "Ком.",
                    ...createColumn("commissionPercent", true, lastSort),
                    fixed: "right",
                    render: renderMoney,
                    width: 100,
                },
            ]
            : []),
        {
            title: "Сдача",
            width: 100,
            ...createColumn("endingDate", false, lastSort),
            fixed: "right",
            render: (endingDate) => <span className="nowrap">{endingDate}</span>,
            sorter: (a, b) => {
                if (a.endingDate < b.endingDate) {
                    return -1;
                }
                if (a.endingDate > b.endingDate) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: !isDesktop ? "Статус" : "Ст.",
            dataIndex: "status",
            key: "status1",
            sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
            width: !isDesktop ? 100 : 80,
            render: (status, record, {id}) => {
                console.log(id)
                return (
                    <div className="table2-status">
                        <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
                            <div className="status-circle__tooltip">{status?.name}</div>
                        </div>
                        <div className="status-anchors">
                            <a href={`/flat-booking/assignment/${record.id}/spb`} target="_blank" onClick={e => e.stopPropagation()}>
                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M16.4409 2.59855C14.2024 0.312851 10.4717 0.312851 8.23322 2.59855C6.74092 4.12236 6.24348 6.40806 6.86528 8.56678L0.274261 15.2969C0.149902 15.4239 0.149902 15.5509 0.149902 15.6779V18.7255C0.149902 18.9794 0.39862 19.2334 0.647338 19.2334H3.63195C3.75631 19.2334 3.75631 19.2334 3.88067 19.2334V17.2017H5.87041V15.1699H7.73579V13.1382H9.72553L10.4717 12.3763C12.5858 13.0112 14.8242 12.5033 16.3165 10.9795C18.8037 8.69376 18.8037 4.88426 16.4409 2.59855ZM14.9486 6.40806C14.3268 7.04298 13.3319 7.04298 12.7101 6.40806C12.0883 5.77314 12.0883 4.75727 12.7101 4.12236C13.3319 3.48744 14.3268 3.48744 14.9486 4.12236C15.5704 4.75727 15.5704 5.77314 14.9486 6.40806Z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                )
            },
        },
    ];
    const mobileAssignmentsColumn = [
        {
            title: "Пл",
            ...createColumn("plan"),
            render: ({ src }) => <div className="table-img">
                <img src={src} alt="" onError={onImgError} />
            </div>,
        },
        {
            title: "Эт.",
            ...createColumn("floor"),
        },
        {
            title: "S общ.",
            ...createColumn("squareTotal"),
        },
        {
            title: "Цена",
            ...createColumn("costWithDiscount"),
            render: (price) => <span className="nowrap">{getRoundedPrice(price)} млн.</span>,
        },
        ...(showMotivation
            ? [
                {
                    title: "Комиссия",
                    dataIndex: "commissionPercent",
                    key: "commissionPercent",
                    fixed: "right",
                    render: renderMoney
                },
            ]
            : []),
        {
            title: "Ст",
            dataIndex: "status",
            key: "status1",
            render: (status) => {
                return (
                    <div className="table2-status">
                        <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
                            <div className="status-circle__tooltip">{status?.name}</div>
                        </div>
                    </div>
                )
            },
        },

    ];

    const parkingsColumns = [
        {
            title: "ID",
            ...createColumn("id", true),
            width: 80,
        },
        {
            title: isDesktop ? "Эт." : "Этаж",
            ...createColumn("floor", true),
        },
        {
            title: "№",
            ...createColumn("number", true),
        },
        {
            title: "S общ.",
            ...createColumn("squareTotal", true),
        },
      
        {
            title: "Цена 100%",
            ...createColumn("costWithDiscount", true),
            render: renderMoney,
        },
        {
            title: "Цена, м²",
            ...createColumn("minMeterPrice", true),
            render: renderMoney,
        },
        
        {
            title: builderId === 11615 ? "Цена при ипотеке" :"Цена баз.", 
            ...createColumn("baseCost", true),
            render: (price, { objectType, costWithDiscount }) => renderMoney(objectType === "apartment" || objectType === "parking" ? price : costWithDiscount),
            width: 100,
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status1",
            sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
            width: 60,
            render: ({ color, name }, { bookingLink }) => {
                return (
                    <div class="table2-status">
                        <div className={`status-circle status-circle_${color === "green" ? "positive" : "negative"}`}>
                            <div className="status-circle__tooltip">{name}</div>
                        </div>
                        <div className="status-anchors">
                            <a href={bookingLink} target="_blank" onClick={e => e.stopPropagation()} rel="noreferrer">
                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M16.4409 2.59855C14.2024 0.312851 10.4717 0.312851 8.23322 2.59855C6.74092 4.12236 6.24348 6.40806 6.86528 8.56678L0.274261 15.2969C0.149902 15.4239 0.149902 15.5509 0.149902 15.6779V18.7255C0.149902 18.9794 0.39862 19.2334 0.647338 19.2334H3.63195C3.75631 19.2334 3.75631 19.2334 3.88067 19.2334V17.2017H5.87041V15.1699H7.73579V13.1382H9.72553L10.4717 12.3763C12.5858 13.0112 14.8242 12.5033 16.3165 10.9795C18.8037 8.69376 18.8037 4.88426 16.4409 2.59855ZM14.9486 6.40806C14.3268 7.04298 13.3319 7.04298 12.7101 6.40806C12.0883 5.77314 12.0883 4.75727 12.7101 4.12236C13.3319 3.48744 14.3268 3.48744 14.9486 4.12236C15.5704 4.75727 15.5704 5.77314 14.9486 6.40806Z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                )
            },
        },
    ]
    const commercialApartmentColumns = [
        {
            title: "ID",
            ...createColumn("id", true),
            width: 80,
        },
        {
            title: "План.",
            ...createColumn("plan"),
            width: isDesktop ? 50 : 80,
            render: renderImg
        },
        {
            title: "Секц.",
            ...createColumn("apartmentSection", true, false, true),
        },
        {
            title: isDesktop ? "Эт." : "Этаж",
            ...createColumn("floor", true),
        },
        {
            title: "№ пом.",
            ...createColumn("number", true),
        },
        {
            title: "S общ.",
            ...createColumn("squareTotal", true),
        },
        {
            title: "Отделка",
            ...createColumn("decoration"),
            sorter: (a, b) => a.decoration?.length - b.decoration?.length,
            render: v => <Tooltip title={v} />
        },
        {
            title: "Цена 100%",
            ...createColumn("costWithDiscount", true),
            render: renderMoney,
        },
        {
            title: "Цена, м²",
            ...createColumn("minMeterPrice", true),
            render: renderMoney,
        },
        {
            title: builderId === 11615 ? "Цена при ипотеке" :"Цена баз.", 
            ...createColumn("baseCost", true),
            render: (price, { objectType, costWithDiscount }) => renderMoney(objectType === "apartment" ? price : costWithDiscount),
            width: 100,
        },
        {
            title: "Тип",
            ...createColumn("flatType"),
            sorter: (a, b) => a.flatType?.length - b.flatType?.length,
            width: 100,
            render: (v, { objectType }) => objectType === "assignment" ?
                <div class="status-assignment">Уступка ПАН</div> :
                <Tooltip title={v} />
        },

        {
            title: "Статус",
            dataIndex: "status",
            key: "status1",
            sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
            width: 60,
            render: ({ color, name }, { bookingLink, id, compilationObjectType }) => {
                return (
                    <div class="table2-status">
                        <div className={`status-circle status-circle_${color === "green" ? "positive" : "negative"}`}>
                            <div className="status-circle__tooltip">{name}</div>
                        </div>
                        <div className="table-compilation-icon" onClick={(e) => {
                            e?.stopPropagation?.();
                            setIsId(id)
                            setIsType(compilationObjectType)
                            openCompilationModal()
                        }
                        }>
                            <AddToCompilationIcon/>
                            <div className="table-compilation-icon__hint">Добавить в подборку</div>
                        </div>
                    </div>
                )
            },
        },
    ]
    switch (type) {
        case "apartments":
            return isTablet ? mobileApartmentsColumns : apartmentsColumns
        case "assignments":
            return isTablet ? mobileAssignmentsColumn : assignmentsColumns
        case "parkings":
            return parkingsColumns
        case "commercial":
            return isTablet ? mobileApartmentsColumns : commercialApartmentColumns
        default:
            break;
    }
    return {
        mobileApartmentsColumns,
        apartmentsColumns,
        mobileAssignmentsColumn,
        assignmentsColumns
    }
}

export default GetColumns
