import React from "react";

import PrimaryCommercialTopFilters from "./PrimaryCommercialTopFilters";
import PrimaryCommercialBottomFilters from "./PrimaryCommercialBottomFilters";

const PrimaryCommercialMapsFilters = props => <div className="primary-map-filter">
  <PrimaryCommercialTopFilters {...props} />
  <PrimaryCommercialBottomFilters {...props} />
</div>

export default PrimaryCommercialMapsFilters;