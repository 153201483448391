import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual'

/**
 * Хук позволяет использовать функцию debounce сохраняя при этом локальное состояние и вызывая функцию только когда юзер закончит вводить
 * Принимает 1 параметр - объект config, который содержит:
 * @param {Function} fn - функция, которая будет вызвана не чаще чем раз в delay секунд
 * @param {Function} setState - функция которая будет вызвана с prevValue в качестве первого аргумента prevValue === debouncedValues и параметрами которые будут переданы в fn она же debouncedHandleChange
 * @param {any} defaultState - дефолтное значение состояния  debouncedValues
 * @param {Number} delay  - как часто можно вызывать функцию fn
 * @param {any} observableValues - // Если observableValues есть и он отличается от debouncedValues то debouncedValues = observableValues (нужно чтобы менять состояние из вне)
 * @return {[any, Function]} возвращает debounceValue - просто состояние, используется в качестве value у инпута(ов) и функцию setDebouncedValues которая позволяет вызывать fn сохранять значение в стейт, 
 * @example
 *  
   const [debouncedValues, debouncedHandleChange] = useDebounceState({
    setState: (prev, ...args) => ({ ...prev, [args[1]]: args[0]?.target?.value?.replace(/\s/g, "") }),
    fn: handleChange, //fn примет все аргументы которые будут переданы в debouncedHandleChange
    defaultState: { min: "", max: "" },
    observableValues: filter, // Если observableValues есть и он отличается от debouncedValues то debouncedValues = observableValues (нужно когда)
  }
  )
 */ 
const useDebounceState = ({ fn, setState, defaultState, delay = 300, observableValues }) => {
    if (!fn || typeof fn !== "function") throw new Error("Первый аргумент должен быть функцией")
    const [debouncedValues, setDebouncedValues] = useState(observableValues ? observableValues : defaultState)

    const debouncedHandleChange = useCallback(debounce(fn, delay), [observableValues])

    const handleChange = (...args) => {
        setDebouncedValues(prev => {
            debouncedHandleChange(...args, prev)
            return setState(prev, ...args)
        })

    }
    useEffect(() => {
        const condition = !observableValues ||
            isEqual(debouncedValues, observableValues) ||
            debouncedValues == observableValues;
        if (condition) return
        setDebouncedValues(observableValues)
    }, [observableValues])

    return [debouncedValues, handleChange, setDebouncedValues]
}

export default useDebounceState