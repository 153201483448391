import React from "react";
import ym from 'react-yandex-metrika';
import './BotBar.scss';
const BotBar = (props) => {
  const {customText = `Моментальные уведомления о\u00A0бронировании квартиры клиентом в нашем телеграм-боте`, largeText} = props;
  const openLink = ()=> {
    ym('reachGoal','telegram_bot_connect')
    window.open("https://t.me/PanPartner_ReloadBot", "_blank")
  }
  return (
    <div className="botbar">
        <p className={`botbar__text${largeText ? ` botbar__text_lg h4` : ``}`}>
          {customText}
        </p>
      <img src={require('../../assets/images/bot.svg').default} alt="" className="botbar__img"/>
      <div onClick={openLink} className="button button_view_default botbar__btn">@PanPartner_ReloadBot</div>
    </div>
  )
};

export default BotBar;
