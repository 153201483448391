import React from 'react';
import SimpleBar from 'simplebar-react';
import useMedia from '../../../hooks/useMedia';
import {SuburbanSearchBuilderVillageIcon, SuburbanSearchCottageSettlementIcon} from "../../Public/markup/icons";

const blocksIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 19.3929H21" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M13.2858 19.3929V5.25C13.2858 5.07951 13.218 4.91599 13.0975 4.79544C12.9769 4.67488 12.8134 4.60715 12.6429 4.60715H4.92862C4.75813 4.60715 4.59461 4.67488 4.47405 4.79544C4.3535 4.91599 4.28577 5.07951 4.28577 5.25V19.3929" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M19.7143 19.3929V10.3929C19.7143 10.2224 19.6466 10.0588 19.526 9.93829C19.4055 9.81773 19.242 9.75 19.0715 9.75H13.2858" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6.85718 7.82144H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M8.14282 12.9643H10.7142" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6.85718 16.1786H9.42861" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M15.8572 16.1786H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M15.8572 12.9643H17.1429" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const districtiIcon = <svg
  width="14"
  height="18"
  viewBox="0 0 14 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.5 0C2.89865 0 0 2.89865 0 6.5C0 7.29054 0.175676 8.08108 0.439189 8.78378C0.439189 8.78378 1.93243 12.6486 6.58784 17.6554C6.58784 17.6554 10.8041 13.7905 12.7365 8.78378C12.7365 8.78378 13.0878 7.55405 13.0878 6.5C13 2.89865 10.1014 0 6.5 0ZM6.5 9.39865C5.00676 9.39865 3.77703 8.16892 3.77703 6.67568C3.77703 5.18243 5.00676 3.9527 6.5 3.9527C7.99324 3.9527 9.22297 5.18243 9.22297 6.67568C9.31081 8.16892 7.99324 9.39865 6.5 9.39865Z"
    fill="#8F95AC"
  />
</svg>

const iconByType = {
  subways: ({ color }) => <span className="complex__metro-color" style={{ backgroundColor: color }} />,
  blocks: () => blocksIcon,
  villages: () => blocksIcon,
  districts: () => districtiIcon,
  address: () => districtiIcon,
  builders: () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.69116 7.14795L18.2567 7.14795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M6.0885 11.3795L18.2568 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M12.8405 7.19943L10.4424 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M17.1945 7.19943L14.7964 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M8.48666 7.19943L6.0885 11.3795" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M17.2658 14.1646L6.73421 14.1646L5 9.1289L6.73421 4L17.2658 4L19 9.1289L17.2658 14.1646Z" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M11.5033 14.8849C11.5033 14.8849 11.5638 16.1442 10.7346 16.9849C9.90547 17.8256 9.35388 18.4165 9.35388 19.5053C9.35388 20.861 10.715 21.4158 11.489 21.4158C12.263 21.4158 13.7078 20.9903 13.7078 19.1624" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>,
  banks: () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none"><path fill="#8F95AC" d="M1.989 12.172c0-2.486.248-6.547.248-6.547h1.658s.248 4.06.248 6.547H1.99ZM6.629 12.172c0-2.486.248-6.547.248-6.547h1.658s.248 4.06.248 6.547H6.63ZM11.27 12.172c0-2.486.248-6.547.248-6.547h1.657s.249 4.06.249 6.547H11.27ZM15.08 14.989H.25V13.58a.83.83 0 0 1 .828-.828h13.175a.83.83 0 0 1 .829.828v1.409ZM15.247 3.637c-.083 0-.083-.083-.083-.083L8.038.074c-.332-.166-.663 0-.663 0L.249 3.554C.083 3.637 0 3.72 0 3.885v.746c0 .249.166.414.414.414h14.584c.249 0 .414-.165.414-.414v-.746c0-.083-.083-.165-.165-.248Z" /></svg>,
  builderVillage: () => <SuburbanSearchBuilderVillageIcon />,
  cottageSettlement: () => <SuburbanSearchCottageSettlementIcon />

}

const FilterSearchResult = (props) => {
  const {
    results,
    isVisible,
    searchResultHandler,
    left = 0,
    redesignResult
  } = props;

  const isMobile = useMedia("isMobile")

  const smallScreen = useMedia("isLaptop");

  const simplebarStyles = redesignResult ?
    {
      display: isVisible ? 'block' : 'none',
      maxHeight: 200,
      overflow: 'hidden auto',
    }
    :
    {
      left: isMobile ? 0 : left + 42,
      display: isVisible ? 'block' : 'none',
      minWidth: isMobile ? '100%' : 350,
      maxHeight: 200,
      overflow: 'hidden auto',
    };

  return (
    <SimpleBar
      className={`smart-serach-result ${redesignResult ? 'smart-serach-result_redesign' : ''}`}
      style={simplebarStyles}
    >
      <ul>
        {results.map(({ id, title, color, fieldName, link, excludable, type }) => <li
          className={`smart-filter-result`}
          key={id}
          onClick={() => searchResultHandler(id, type ?? fieldName, 'filter')}
        >
          {iconByType[type ?? fieldName]?.({ color })}
          <div style={{ paddingLeft: '10px' }}>{title}</div>
          {link && <a href={link} target="_blank" className="exclude"
            style={{ marginLeft: 20, visibility: "visible", order: 1 }}
            rel="noreferrer"
          >
            Перейти
          </a>}
          {excludable && <span
            className="exclude"
            onClick={(e) => {
              e.stopPropagation();
              searchResultHandler(id, fieldName, 'exclude');
            }}
            style={{ ...(smallScreen ? { visibility: "visible" } : []) }}
          >
            Исключить
          </span>}
        </li>
        )}
      </ul>
    </SimpleBar>
  );
};
export default FilterSearchResult;
