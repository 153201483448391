import _ from "lodash";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { sendMortgage } from "../../actions/forms";
import { media } from "../../utils";
import Dropdown from "../core/Dropdown";
import SubmitButton from "../SubmitButton";
import FormAddClient from "./FormAddClient";

const MortgageForm = (props) => {
  const {
    clients,
    id = false,
    blockId = false,
    city = "spb",
    type = false,
    phone,
    name,
    handleSend,
    calc,
    total,
  } = props;

  const defaultValues = {
    id: id,
    blockId: blockId,
    name: name,
    city: city,
    type: type,
    clientId: clients.length > 0 ? clients[0].id : 0,
    phone: phone,
    montlyPayment: calc.total,
    percent: calc.percent,
    fullPrice: calc.price,
    loadPeriod: calc.period,
    primaryPayment: calc.prepay,
    comment: "",
    payType: [],
    agree: true,
  };

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [show, setShow] = useState(false);

  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAddClient = (id) => {
    setValue("clientId", id);
    setShow(false);
  };

  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    let keys = _.keys(data);

    keys.forEach((item) => {
      let identity = item;

      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        if (item === "id" && !id) return;
        if (item === "blockId" && !blockId) return;
        if (item === "type" && !type) return;
        form.append(`${identity}`, data[item]);
      }
    });

    sendMortgage(form).then(
      (data) => {
        setSuccess(true);
        handleSend();
        setFetching(false);
      },
      (error) => {
        setError(true);
        setFetching(false);
      }
    );
  };

  return (
    <>
      {!media("isMobile") && <h2 className="modal__title">Рассчитать ипотеку</h2>}
      <form className="modal-form" name="reserve-apartment">
        <div className="modal-form__wrapper">
          {id && <input type="hidden" name="id" ref={register} />}
          {blockId && <input type="hidden" name="blockId" ref={register} />}
          {type && <input type="hidden" name="type" ref={register} />}
          <input type="hidden" name="city" ref={register} />
          <input type="hidden" name="fullPrice" ref={register} />
          <input type="hidden" name="primaryPayment" ref={register} />
          <input type="hidden" name="loadPeriod" ref={register} />
          <input type="hidden" name="percent" ref={register} />
          <input type="hidden" name="montlyPayment" ref={register} />
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-name"
              >
                <span className="input__label">Имя и фамилия</span>
                <input
                  id="modal-reservation-name"
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-phone"
              >
                <span className="input__label">Телефон</span>
                <InputMask
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autocomplete="off"
                  maskChar=" "
                  name="phone"
                  defaultValue={phone}
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="select select_type_form">
                <label
                  className="custom-select-label"
                  htmlFor="modal-reservation-client"
                >
                  Клиент
                </label>
                <Controller
                  as={Dropdown}
                  name="clientId"
                  options={clients}
                  onChange={([value]) => value}
                  inputRef={register}
                  control={control}
                />
              </div>
              <div
                className="add-client js-show active"
                style={{ display: !show ? "flex" : "none" }}
                onClick={() => setShow(true)}
              >
                <button
                  className="button button_type_add"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                ></button>
                <span>Добавить клиента</span>
              </div>
            </div>
          </div>
          <FormAddClient
            show={show}
            onAddClient={(id) => handleAddClient(id)}
          />
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <span className="textarea__label">Комментарий</span>
                <textarea
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  ref={register}
                  name="comment"
                ></textarea>
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  phone: state.user.info.phone,
  clients: state.user.clients.map((client) => ({
    id: client.id,
    title: `${client.name} ${client.lastName}`,
  })),
  name: `${state.user.info.name} ${state.user.info.lastName}`,
});

export default connect(mapStateToProps, null)(MortgageForm);
