import React, { useEffect, useMemo, useRef, useState } from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import { Button } from "react-yandex-maps";
import ym from 'react-yandex-metrika';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import Aeropanorama from '../../assets/images/Aeropanorama.svg';
import T3DTour from '../../assets/images/T3DTour.svg';
import useMedia from '../../hooks/useMedia';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import ShowroomTooltip from '../ShowroomTooltip/index.js';

SwiperCore.use([Navigation]);

const PhotoPopup = (props) => {
  const { items, index = 0, open, onClose, } = props;

  const options = useMemo(() => {
    return {
      index: index === 0 ? 0 : index - 1,
      closeOnScroll: false,
      closeOnVerticalDrag: false,
      maxSpreadZoom: 4
    };
  }, [index]);
  const photos = useMemo(
    () =>
      items?.map?.((item) => ({
        src: getUrl(item.src),
        w: !!item.width ? item.width : 1600,
        h: !!item.height ? item.height : 900,
      })),
    [items]
  );
  if (!items) return null
  return (
    <PhotoSwipe
      isOpen={open}
      items={photos}
      options={options}
      onClose={onClose}

    />
  );
};

const PhotoBlock = (props) => {

  const { photos,
    identity,
    aeroPanorama,
    current,
    tour,
    blurBg,
    customIdent,
    statusSold,
    onPhotoOpen,
    onPhotoClosed,
    trumbsWidth,
  } = props;

  const isMobile = useMedia("isMobile")
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(isMobile ? 1 : 0);
  const iframeRef = useRef(null)
  const navRef = useRef(null);
  const node = useRef(null);
  const navNode = useRef(null);
  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          controls: false,
          navContainer: isMobile ? `#complex-slider-thumbnails-${identity}-${customIdent}` : false,
          navAsThumbnails: false,
          nav: false
        }
        : {},
    [navRef]
  );
  useEffect(() => {
    if (iframeRef && iframeRef.current && (current === "aeroPanorama" || current === "tour")) {
      iframeRef.current.src = iframeRef.current.src;
    }
  }, [current]);

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const handleOpen = () => {
    setOpen(true);
    return onPhotoOpen?.()
  };

  const handleClose = () => {
    setOpen(false);
    return onPhotoClosed?.()
  };

  const moveSlider = (way) => {
    if (node.current?.slider) {
      node.current.slider.goTo(way);
    }
    if (navNode.current?.slider) {
      navNode.current.slider.goTo(way);
    }

  };

  return (
    <ErrorPlug wrappedComponent="src/comp-ts/Complex/Photos.js">
      <>
        {current == "tour" && <ShowroomTooltip />}
        {identity != "aeroPanorama" && identity != "tour" ? (

          <div className="complex__slider-box">
            <div
              class={`complex__slider-wrapper gallery__wrapper${statusSold ? ` status-sold` : ``}`}
              id="complex-slider-photos"
            >
            <TinySlider
                settings={settings}
                ref={node}
                onIndexChanged={handleChangeIndex}
              >
                {photos.map((photo, index) => (
                  <div
                    class="complex__slider-item gallery__item"
                    key={index}
                    onClick={() => handleOpen()}
                  >
                    {blurBg ? <div className="complex__slider-blur-bg" style={{ backgroundImage: `url("${getUrl(photo.src)}")` }}></div> : ``}
                    <img src={getUrl(photo.src)} />
                  </div>
                ))}
              </TinySlider>
            </div>


            {photos?.length > 1 && <div class="complex__slider-preview" style={{ maxWidth: '100%', width: trumbsWidth }}>
              {isMobile ?
                <ul
                  class="complex__slider-thumbnails"
                  id={`complex-slider-thumbnails-${identity}-${customIdent}`}
                  ref={navRef}
                >
                  {photos.map((photo, index) => (
                    <li class="complex__slider-thumbnails_item" key={index}>
                      {isMobile
                        ? <span aria-controls="base"></span>
                        : <img src={getUrl(photo.src)} />
                      }
                    </li>
                  ))}
                </ul> :
                <Swiper
                  slidesPerView={photos?.length < 5 ? photos?.length : 5}
                  spaceBetween={0}
                  centeredSlides={photos?.length > 4}
                  centerInsufficientSlides={photos?.length > 4}
                  slideToClickedSlide={true}
                  className="complex__slider-thumbnails"
                  tag="ul"
                >
                  {photos.map((photo, i) => (
                    <SwiperSlide key={"slide-" + i} className={`complex__slider-thumbnails_item ${i + 1 === index ? "thumb_active" : ""}`} onClick={() => moveSlider(i)}>
                      {isMobile
                        ? <span aria-controls="base"></span>
                        : <img src={getUrl(photo.src)} />
                      }
                    </SwiperSlide>
                  ))}
                </Swiper>
              }
            </div>}

            {photos?.length > 1 && <ul
              class="complex__slider-controls"
              id={`complex-slider-controls-${identity}`}
            >
              <li class="prev" onClick={() => moveSlider("prev")}>
                <svg class="icon icon-white_stroke">
                  <use xlinkHref="#icon-left_arrow"></use>
                </svg>
              </li>
              <li class="next" onClick={() => moveSlider("next")}>
                <svg class="icon icon-white_stroke">
                  <use xlinkHref="#icon-right_arrow"></use>
                </svg>
              </li>
            </ul>}
            <div class="complex__slider-magnifier" onClick={() => handleOpen()}>
              <svg class="icon icon_search">
                <use xlinkHref="#search"></use>
              </svg>
            </div>

            {isMobile &&
              <span class="complex__slider-info">
                {index} / {photos.length}
              </span>
            }
          </div>
        ) : (
          <iframe
            allow="true"
            loading="lazy"
            allowFullScreen={true}
            src={identity != "aeroPanorama" ? getUrl(tour) : `${aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`}
            width="100%"
            ref={iframeRef}
            {...(window.location.host.includes("toprealtors.ru") ? { referrerPolicy: "no-referrer" } : [])}
          ></iframe>
        )}
        <PhotoPopup
          open={open}
          items={photos}
          index={index}
          onClose={handleClose}
        />
      </>
    </ErrorPlug>
  );
};

const titles = {
  complex: "Фотографии",
  generalPlan: "Ген. план",
  decoration: "Отделка",
  interior: "Интерьер",
  aeroPanorama: "Панорама",
  tour: "Шоу-рум",
  "decorationFinish": "Чистовая",
  "decorationPreFinish": "Подчистовая",
  "decorationRaw": "Без отделки",
  plan: "Планировка",
  floorPlan: "План этажа",
  gidImg: "На карте",
  viewFromWindow: "Вид из окна"
};

const Apartments = (props) => {
  const {
    addClass,
    blurBg,
    photos,
    tour,
    tabs = ["complex", "generalPlan", "plan", "decoration", "interior", "aeroPanorama", "decorationFinish", "decorationPreFinish", "decorationRaw"],
    disableIcons,
    isCounstructor,
    statusSold,
    onPhotoOpen,
    onPhotoClosed,
    thumbsSettings,
    withToggler,
    navTitles = titles,
    wideTrumbs,
  } = props;


  
  const trumbsWidth = wideTrumbs ? 660 : 500;


  const [current, setCurrent] = useState("");
  const setCurrentTab = (identity) => {
    if (identity === "tour") {
      ym('reachGoal', 'showroom_view')
    }
    setCurrent(identity)
  }

  const navigation = useMemo(() => {
    let nav = [];
    tabs.forEach((identity) => {
      if (identity === "aeroPanorama" && photos[identity]?.length > 0) {
        if (isCounstructor) return
        nav.push({
          identity: identity,
          type: "photos",
          title: navTitles[identity],
          ...(!disableIcons ? { icon: Aeropanorama } : [])
        });
        return
      }
      if (photos[identity]?.length > 0) {
        nav.push({
          identity: identity,
          type: "photos",
          title: navTitles[identity],
        });
      }
    });
    if (tour?.length > 0) {
      nav.push({
        identity: "tour",
        type: "photos",
        title: navTitles["tour"],
        ...(!disableIcons ? { icon: T3DTour } : [])
      });
    }

    return nav;
  }, [photos]);

  useEffect(() => {
    if (navigation.length > 0) {
      setCurrentTab(navigation[0].identity);
    }
  }, [navigation]);


  const toggle = () => { 
    const currentIndex = navigation.findIndex(nav=>nav.identity === current)
    if(currentIndex === -1) return 
    if(currentIndex + 1 === navigation.length) setCurrentTab(navigation[0]?.identity)
    if(currentIndex + 1 < navigation.length) setCurrentTab(navigation[currentIndex + 1]?.identity)
   }
   const currentToggleTitle = useMemo(() => {
    const currentIndex = navigation.findIndex(nav=>nav.identity === current)
    if(currentIndex === -1) return ""
    if(currentIndex + 1 === navigation.length) return navigation[0]?.title
    if(currentIndex + 1 <= navigation.length) return navigation[currentIndex + 1]?.title

   }, [current, navigation])
  return (
    <div class={`complex__images${addClass ? ` ${addClass}` : ``}`}>
      {navigation.length > 1 && !withToggler && (
        <ul className="complex__images-navs">
          {navigation.map((nav, index) => (
            <li key={`nav-item-${index}`}
              className={`complex__images-nav ${nav.identity === current ? `active` : ``
                }`}
              data-tab={nav.identity}
              onClick={() => setCurrentTab(nav.identity)}
              style={{ ...(!!nav.icon ? { paddingLeft: 10 } : []) }}
            >
              {!!nav.icon && <object type="image/svg+xml" data={nav.icon} style={{ marginRight: 3, maxWidth: 22 }} />}{nav.title}
            </li>
          ))}
        </ul>
      )}

      {
        // Функционала нет
        withToggler && (
          <button 
          type="button"
           className="slider-toggler button button_view_white"
           onClick={toggle}
           >{currentToggleTitle}</button>
        )
      }

      <div class="complex__slider gallery">
        {navigation.map((nav, i) => (
          <div
            className={`complex__slider-tab ${nav.identity === current ? `active` : ``
              }`}
            key={"nav-items-" + i}
          >
            <PhotoBlock
              photos={photos[nav.identity]}
              aeroPanorama={photos.aeroPanorama}
              tour={tour} identity={nav.identity}
              current={current}
              blurBg={blurBg}
              customIdent={props.customIdent || "test"}
              statusSold={statusSold}
              onPhotoOpen={onPhotoOpen}
              onPhotoClosed={onPhotoClosed}
              thumbsSettings={thumbsSettings}
              trumbsWidth={trumbsWidth}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Apartments;
