import React, { memo, useMemo } from 'react';
import useMedia from '../../../hooks/useMedia';
import { formatMoney } from '../../../utils';
import SelfObjectCard from './SelfObjectCard';

const SelfCardContainer = memo(props => {
    const {
        address,
        description,
        id,
        meterPrice,
        photos,
        plan,
        price,
        roomType,
        squareTotal,
        isHighlight,
        title,
        subTitle,
        rentTypeXmlId,
        saleTypeXmlId,
        rentType
    } = props

    const isTablet = useMedia("isTablet")
    const isDesktop = useMedia("isDesktop")

    const cardPhotos = useMemo(() => {
        if (!plan && !photos) return []
        if (!photos?.length && plan?.empty) return [plan]
        if (!plan || plan?.empty) return photos
        return [plan, ...photos]
    }, [plan, photos])

    const cropText = useMemo(() => {
        if (!description) return ""
        if (description?.length < 121) return description
        //порядок важен
        if (isTablet) return `${description.substr(0, 120)}...`
        if (isDesktop) return `${description.substr(0, 370)}...`
        return `${description.substr(0, 120)}...`
    }, [description, isTablet, isDesktop])

    return <SelfObjectCard
        address={address}
        title={title}
        subTitle={subTitle}
        price={formatMoney(price, saleTypeXmlId === "rent" ? rentType === "daily" ? " р./сутки" : " р./мес" : " руб.")}
        pricePerMeter={formatMoney(meterPrice, " руб./м²")}
        description={cropText}
        photos={cardPhotos}
        isHighlight={isHighlight}
        onClick={e => {
            if (e.target.className.includes("swiper-button")) {
                return
            }
            window.open(`/object/${id}`)
        }}
    />
})

export default SelfCardContainer;
