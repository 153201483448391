import ru from "date-fns/locale/ru"; // the locale you want
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { callback } from "../../actions/forms";
import { media } from "../../utils";
import SubmitButton from "../SubmitButton";



registerLocale("ru", ru);
const dateFormat = (date) => {
  let dd = date.getDate()
  let mm = date.getMonth()+1
  let yy = date.getFullYear()
  let hh = date.getHours()
  let mi = date.getMinutes()
  let ss = date.getSeconds()
  return (`${dd}.${mm}.${yy} ${hh}:${mi}:${ss}`)
}
const Callback = (props) => {
  const { name, handleClickSubmit, isAssignment, isCommercial, manager, customType, objectId, savedFilterSettings } = props;
  const [fetching, setFetching] = useState(false);

  const defaultValues = {
    date: new Date(),
    time: new Date(),
    name: name,
    urgently: false,
    agree: true,
  };

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: defaultValues,
  });

  const isUrgently = watch("urgently", defaultValues.urgently);
  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    form.append(`name`, data.name);
    if(objectId) form.append("objectId", objectId)
    if (data.urgently) {
      form.append(`urgently`, true);
    } else {
      form.append(`date`,  dateFormat(data.date));
      form.append(`time`, dateFormat(data.time));
    }
    if (isAssignment && !customType) {
      form.append("type", "assignment");
    }
    if(isCommercial && !customType){
      form.append("type", "commercial");

    }
    if(customType){
      form.append("type", customType); //ебучий пиздец, всё надо переписывать с нуля
    }
    form.append("managerId", manager.id);

    callback(form).then(
      (data) => {
        handleClickSubmit();
      },
      (error) => {}
    );
  };

  return (
    <>
      {!media("isMobile") && <h2 class="modal__title">Обратный звонок</h2>}
      <form class="modal-form">
        <div class="modal-form__wrapper">
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label class="input input_width_available input_type_form">
                <span class="input__label">Ваше имя и фамилия</span>
                <input
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div class="modal-form__row">
            <div class="modal-form__gist">
              <div class="modal-form__row">
                <h4 class="h4">Удобное время для звонка</h4>
              </div>
              <div class="modal-form__row">
                <div class="modal-form__item">
                  <label
                    class="input input_width_available input_type_form"
                    for="modal-callback-date"
                  >
                    <span class="input__label">Дата</span>
                    <Controller
                      as={
                        <DatePicker
                          dateFormat="d MMM yyyy"
                          minDate={new Date()}
                          showTimeSelect={false}
                          todayButton={false}
                          dropdownMode="select"
                          className={
                            "input__control" +
                            (errors.date ? " input__error" : "")
                          }
                          autoComplete="off"
                          shouldCloseOnSelect
                          locale="ru"
                          disabled={isUrgently}
                        />
                      }
                      control={control}
                      register={register()}
                      name="date"
                      valueName="selected" // DateSelect value's name is selected
                      onChange={([selected]) => {
                        return selected;
                      }}
                      autoComplete="off"
                      rules={
                        !isUrgently
                          ? {
                              required: true,
                            }
                          : {}
                      }
                    />
                  </label>
                </div>
                <div class="modal-form__item">
                  <label
                    class="input input_width_available input_type_form"
                    for="modal-callback-time"
                  >
                    <span class="input__label">Время</span>
                    <Controller
                      as={
                        <DatePicker
                          dateFormat="HH:mm"
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          className="input__control"
                          shouldCloseOnSelect
                          autoComplete="off"
                          timeCaption="Время"
                          timeFormat="p"
                          locale="ru"
                          disabled={isUrgently}
                        />
                      }
                      control={control}
                      name="time"
                      valueName="selected"
                      onChange={([selected]) => {
                        return selected;
                      }}
                      rules={
                        !isUrgently
                          ? {
                              required: true,
                            }
                          : {}
                      }
                    />
                  </label>
                </div>
              </div>
              <div class="modal-form__row">
                <div class="modal-form__item">
                  <div class="checkbox">
                    <div class="checkbox__box">
                      <input
                        class="checkbox__control"
                        type="checkbox"
                        id="modal-callback-asap"
                        name="urgently"
                        ref={register}
                      />
                      <label
                        class="checkbox__input"
                        for="modal-callback-asap"
                      ></label>
                      <div class="checkbox__marker"></div>
                    </div>
                    <label class="checkbox__label" for="modal-callback-asap">
                      Как можно скорее
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            onClick={handleSubmit(onSubmit)}
            addStyle={{color: savedFilterSettings?.colorButtonText, backgroundColor: savedFilterSettings?.colorButton}}
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  email: state.user.info.email,
  phone: state.user.info.phone,
  name: `${state.user.info.name} ${state.user.info.lastName}`,
});

export default connect(mapStateToProps, null)(Callback);
