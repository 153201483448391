import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getFormSettings, sendForm } from '../../../actions/constructor';
import useMedia from "../../../hooks/useMedia";
import { sendConstructorEvent, sendVKEvent } from '../../../utils';
import { getFormWithUTM, getSubdomain } from '../../../utils/location';
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";
import { errorToast, standartErrorToast, successToast } from "../../toasts";
import request from "../../../api";

const CallbackModal = (props) => {

  const { closeCallbackModal, title="Получить консультацию", isPreview, selectedType, id, selfObjectId } = props;

  const [callbackForm, setCallbackForm] = useState({})
  const [formSettings, setFormSettings] = useState([])
  const theme = useSelector(state => state.agency.theme);
  const [color, setColor] = useState(null)
  const subDomain = getSubdomain()
  // const [title, setTitle] = useState(null)


  const isMobile = useMedia("isMobile")

  useEffect(() => {
    sendConstructorEvent(id ? "tr_building_form_view" : "tr_main_form_view", id ? { complexId: id } : null)
  }, [])

  useEffect(() => {
    if(!theme) return
    let currentColor;
    switch (theme.color) {
      case 'green':
        currentColor = `constructor`;
        break;

      case 'blue':
        currentColor = `constructor constructor_color-blue`;
        break;

      case 'yellow':
        currentColor = `constructor constructor_color-yellow`;
        break;

      case 'red':
        currentColor = `constructor constructor_color-red`;
        break;

      case 'gray':
        currentColor = `constructor constructor_color-gray`;
        break;

      case 'asphalt':
        currentColor = `constructor constructor_color-asphalt`;
        break;

      case 'purple':
        currentColor = `constructor constructor_color-purple`;
        break;

      case 'burgundy':
        currentColor = `constructor constructor_color-burgundy`;
        break;
      case 'nightsky':
        currentColor = `constructor constructor_color-nightsky`;
        break;
      case 'turquoise':
        currentColor = `constructor constructor_color-turquoise`;
        break;

      default:
        currentColor = `constructor`;
        break;
    }
    setColor(currentColor)
  }, [theme])
  const getFieldName = (code) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const setForm = (value, code, arrayValue) => {
    const tmp = {
      ...callbackForm
    }

    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      const ident = getFieldName(code)
      tmp[ident] = value
    }
    setCallbackForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = (successCallback = () => { }) => {
    let formData = getFormWithUTM();
    let keys = Object.keys(callbackForm);
    if (isPreview) {
      successToast(<div>В режиме предварительного просмотра отправка форм невозможна</div>)
      return
    }
    if(id){
      const buildingField = getFieldName("building_name")
      formData.append(buildingField, id)
    }
    if(selectedType){
      const typeField = getFieldName("roomType")
      formData.append(typeField, selectedType)

    }
    keys.forEach((key) => {
      if (Array.isArray(callbackForm[key])) {
        callbackForm[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, callbackForm[key]);
      }
    });

    formData.append("subDomain", subDomain)
    if(selfObjectId){
      formData.append("selfObjectId", selfObjectId)
    }

    formData.append("title", title)
    formData.append("url", window.location.href)
    if (captchaImageUrl && captchaSid) {
      formData.append("captchaSid", captchaSid)
    }
    formData.append("captchaWord", captchaWord)

    sendForm(34, formData).then(
      (data) => {
        closeCallbackModal()
        successToast(<div>Спасибо! Форма успешно отправлена</div>)
        sendConstructorEvent(id ? "tr_buiding_form_send" : "tr_main_form_send", id ? { complexId: id } : null, true);
        sendVKEvent('conversion');
      },
      (error) => {
        errorToast(<div>{error[0]?.message}</div>)
      },
    );
  }

  const refreshCaptcha = () => {
    return request('panpartner:forms.ajax', 'refreshCaptcha')
      .then(
        data => {
          setCaptchaSid(data?.captchaSid)
          setCaptchaImageUrl(data?.captchaImageUrl)
          return Promise.resolve(data);
        },
      );
  }

  const [captchaImageUrl, setCaptchaImageUrl] = useState('')
  const [captchaSid, setCaptchaSid] = useState('')
  const [captchaWord, setCaptchaWord] = useState('')

  useEffect(() => {
    getFormSettings(34, "spb", subDomain).then(resp => {
      setFormSettings(resp.questions)
        setCaptchaImageUrl(resp.captchaImageUrl);
        setCaptchaSid(resp.captchaSid)
    },
      standartErrorToast
    )
  }, [])
  useEffect(() => {
    if (!formSettings) return
    const defaultPolicyValue = formSettings.find(setting => setting.code === "policy")?.items?.[0]?.value
    setForm([defaultPolicyValue], "policy")
  }, [formSettings])
  // useEffect(() => {
  //   if (captchaImageUrl && captchaSid) {
  //     console.log(captchaImageUrl);
  //     console.log(captchaSid);
  //   }
  //   console.log(callbackForm)
  // }, [captchaImageUrl, captchaSid, callbackForm]);
  const modalContent = (
    color &&
    <div className={`constructor ${color}`}>
      {!isMobile &&
        <button type="button"
          className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
          aria-label="Закрыть окно"
          onClick={closeCallbackModal}
        >
          <span className="c-modal__icon">
            <svg className="icon icon_cross">
              <use xlinkHref="#cross" />
            </svg>
          </span>
        </button>
      }

      <div className="c-modal__box">
        {!isMobile && <h3 className="c-modal__header c-modal__header_center">{title}</h3>}
        <form action="#" method="post" className={`c-modal__content`}>
          <div className={`c-modal__input-block ${isMobile ? 'input_type_form modal-form__item' : ''}`}>
            <label htmlFor="callback-name" className={`c-modal__label ${isMobile ? 'input__label' : '' }`}>Имя</label>
            <input id="callback-name"
              type="text"
              className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
              value={callbackForm[getFieldName("name")]}
              onChange={e => setForm(e.target.value, "name")}
              placeholder="Введите ваше имя" />
          </div>
          <div className={`c-modal__input-block ${isMobile ? 'input_type_form modal-form__item' : ''}`}>
            <label htmlFor="callback-phone" className={`c-modal__label ${isMobile ? 'input__label' : '' }`}>Телефон</label>
            <InputMask
              id="callback-phone"
              type="text"
              className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
              mask="+7 (999) 999-99-99"
              autoComplete="off"
              maskChar=" "
              value={callbackForm[getFieldName("phone")]}
              onChange={e => setForm(e.target.value, "phone")}
            />
          </div>
          <div className={`c-modal__input-block ${isMobile ? 'input_type_form modal-form__item' : ''}`}>
            <div style={{width: '100%', marginBottom: 23, display: 'flex', gap: 15, alignItems: 'center', justifyContent: 'center'}}>
              <img src={captchaImageUrl} alt=""/>
              <div style={{cursor: 'pointer'}} onClick={refreshCaptcha}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0026 13.6693L6.72845 16.9434L10.0026 20.2175" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.9048 10.5586L17.1789 7.28443L13.9048 4" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.0978 7.28442H6.51223C5.84594 7.28442 5.20695 7.5491 4.73581 8.02024C4.26468 8.49137 4 9.13037 4 9.79665V15.9743" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.89191 16.9434H17.4878C17.8172 16.946 18.1434 16.8794 18.4453 16.7477C19.0593 16.4909 19.5475 16.0027 19.8044 15.3887C19.9314 15.0853 19.9979 14.76 20 14.4311V8.25352" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <label htmlFor="modal-partnership-captchaWord" className={`c-modal__label ${isMobile ? 'input__label' : '' }`}>Код</label>

            <input
              id="modal-partnership-captchaWord"
              className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
              placeholder="Введите код с картинки"
              value={captchaWord}
              onChange={e => setCaptchaWord(e.target.value)}
            />
          </div>

          {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
            return <div className={`c-modal__input-block ${isMobile ? 'modal-form__row' : ''}`} key={"policy-i"}>
              <div className={`checkbox ${isMobile ? 'checkbox_view_invert' : ''}`}>
                <div className="checkbox__box">
                  <input className="checkbox__control" type="checkbox" id="callback-agreement"
                    value={item.value}
                    onChange={(e) => setForm(e.target.checked, item?.fieldName, e.target.value)}
                    checked={!!callbackForm?.[item?.fieldName]?.includes(item.value)}
                  />
                  <label className="checkbox__input" htmlFor="callback-agreement" />
                  <div className="checkbox__marker"></div>
                </div>
                <label className="checkbox__label" htmlFor="callback-agreement">
                  <span className="consultation__form">
                    Я согласен на обработку <a className="link" href="https://toprealtors.ru/policy" target="_blank">персональных данных</a>
                  </span>
                </label>
              </div>
            </div>
          })}
          <div className={isMobile ? 'modal-form__submit' : ''}>
            <button
              type="button"
              className={`button c-modal__submit constructor__btn`}
              onClick={() => submit()}
            >
              Отправить
            </button>
          </div>
        </form>
      </div>
    </div>
  )

  return (
    <>
      {isMobile ?
        <MobileModal hideDefaultClose={true} closeClick={closeCallbackModal} title="Получить консультацию" className="constructor-modal">
          {modalContent}
        </MobileModal> :

        <Modal hideDefaultClose={true} closeByPastClick close={closeCallbackModal} classes="modal_task c-modal c-modal_small">
          {modalContent}
        </Modal>
      }
    </>
  )
};

export default CallbackModal;
