import React from 'react';

import { Link } from 'react-router-dom';

const Breadcrumbs = ({currentPage = `Текущая страница`}) => {
  return (
    <ul className="breadcrumbs">
      <li>
        <Link to="/" className="link">Главная</Link>
      </li>
      <li>
        <a>{currentPage}</a>
      </li>
    </ul>
  );
};

export default Breadcrumbs;