import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { socials } from "../../selectors/user";

const LoginHeader = (props) => {
  return (
    <>
      <div className="auth__department">
        <div className="auth__department-phone">
          <a className="link-hover" href="tel:+78124261863">
            8 (812) 426-18-63
          </a>
        </div>
        <div className="auth__department-name">Отдел развития</div>
      </div>
      <div className="auth__bg">
        <div className="auth__bg_left">
          <svg className="icon">
            <use xlinkHref="#auth_left"></use>
          </svg>
        </div>
        <div className="auth__bg_right">
          <svg className="icon">
            <use xlinkHref="#auth_right"></use>
          </svg>
        </div>
        <div className="auth__bg_planet-1">
          <svg className="icon icon_saturn">
            <use xlinkHref="#saturn_light"></use>
          </svg>
        </div>
        <div className="auth__bg_planet-2">
          <svg className="icon icon_jupiter">
            <use xlinkHref="#jupiter_light"></use>
          </svg>
        </div>
      </div>
      <div className="auth__logo">
        <svg className="icon">
          <use xlinkHref="#panpartner"></use>
        </svg>
      </div>
    </>
  );
};

const LoginFooter = ({ socials }) => {
  return (
    <section className="section auth__footer">
      <div className="wrapper">
        <div className="auth__footer-content">
          <div className="auth__footer-pan">
            Площадка Актуальной Недвижимости
          </div>
          <div className="auth__footer-socials">
            <div className="auth__footer-join">Присоединяйтесь</div>
            <div className="footer__social">
              <ul className="footer__social-list">
                {socials.map((social) => (
                  <li className="footer__social-item" key={social.link}>
                    <a
                      className="footer__social-link"
                      href={social.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="button button_with-icon button_view_social">
                        <svg className={`icon icon_${social.icon}`}>
                          <use xlinkHref={`#social-${social.icon}`}></use>
                        </svg>
                      </button>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PublicLayout = ({ socials, children }) => {
  return (
    <>
      <div className="content">
        <main>
          <div className="auth">
            <LoginHeader />
            <section className="section auth__content-section">
              <div className="wrapper">
                <div className="auth__form">{children}</div>
              </div>
            </section>
            <LoginFooter socials={socials} />
          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  socials: socials,
});

const mapDispatchToProps = (dispatch) => ({});

PublicLayout.propTypes = {
  socials: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
