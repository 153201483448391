import intersectionWith from "lodash/intersectionWith";
import uniqBy from "lodash/uniqBy";
import { createSelector } from "reselect";

const favoritesBlocks = ({ favorites: { block = [], blockEntity = [] } }) => [...block, ...blockEntity];
const favoriteApartments = (state) => {
  return state.favorites.apartment.concat(
    uniqBy(state.favorites.assignment, (item)=>item.id),
    state.favorites.commercial,
    state.favorites.secondary,
    state.favorites.suburban,
    state.favorites.parking
  );
}; 
const favoritesSecondary = state => state.favorites.secondary;

const favoritesSuburban = state => state.favorites.suburban;

const filter = (state) => state.favorites.filter;

const filterById = (arrayOfIds, id) => {
  if(!id){
    return true
  }
  if (arrayOfIds.length == 0) {
    return true;
  }
  return intersectionWith(arrayOfIds, id, (a, b) => a == b).length > 0
};
const filterByRoomType = (roomTypes, roomType) => {
  if (roomTypes.length == 0) {
    return true;
  }
  return roomTypes.some((type) => {
    return type == roomType;
  });
};
let cityById = {
  3: "msk",
  1: "spb",
  0: "spb",
};
 // временное решение, должно было бы быть block.cityId == filter.city.id но filter.city.id для спб бывает и 0 и 1
 let temporarySolution = {
  3: 3,
  0: 0,
  1: 0,
  2: 0,
  7: 7,
  6: 6
};
let temporarySolution2 = {
  3: 3,
  1: 1,
  0: 1,
  2: 1,
  7: 7,
  6: 6
};
let temporarySolution3 = {
  3: 3,
  1: 2,
  0: 2,
  2: 2,
  7: 7,
  6: 6
};

export const filteredBlocks = createSelector(
  [favoritesBlocks, filter],
  (blocks, filter) => {
    return blocks.filter((block) => {
      const cityId = block.city?.id?? block.cityId
      return (
        (cityId == temporarySolution[filter.city.id] ||
          cityId == temporarySolution2[filter.city.id] || cityId == temporarySolution3[filter.city.id]) &&
        filterById(filter.agent, [block.agentId]) &&
        filterById(filter.client, block?.favorite?.clients?.map(v=>v.id))
      );
    });
  }
);
export const filteredApartments = createSelector(
  [favoriteApartments, filter],
  (apartments, filter) => {
    return apartments.filter((apartment) => {
      if(!apartment) return false
      return (
        (apartment.cityCode === cityById[filter.city.id] || (filter.city.id !== 3 && !apartment.cityCode)) &&
        filterById(filter.agent, [apartment.agentId]) &&
        filterById(filter.client, apartment.clientId) &&
        filterByRoomType(filter.roomType, apartment.roomType)
      );
    });
  }
);
export const filteredSecondary = createSelector(
  [favoritesSecondary, filter],
  (secondary, filter) => {
    return secondary.filter((second) => {
      return (
        second.cityCode === cityById[filter.city.id] &&
        filterById(filter.agent, [second.agentId]) &&
        filterById(filter.client, second.clientId) &&
        filterByRoomType(filter.roomType, second.roomType)
      );
    });
  }
);
const favoritesVillages = (state) => state.favorites.village;
export const filteredVillages = createSelector(
  [favoritesVillages, filter],
  (villages, filter) => {
    return villages.filter((village) => {
      return (
        (village.cityId == temporarySolution[filter.city.id] ||
          village.cityId == temporarySolution2[filter.city.id] || village.cityId == temporarySolution3[filter.city.id]) &&
        filterById(filter.agent, [village.agentId]) &&
        filterById(filter.client, village.clientId) 
      );
    });
  }
);

export const filteredSuburbans = createSelector(
  [favoritesSuburban, filter],
  (suburbans, filter) => {
    return suburbans.filter((suburban) => {
      return (
        "spb" === cityById[filter.city.id] &&
        filterById(filter.agent, [suburban.agentId]) &&
        filterById(filter.client, suburban.clientId) &&
        filterByRoomType(filter.roomType, suburban.roomType)
      );
    });
  }
);