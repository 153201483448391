import React from 'react';
import useMedia from '../../hooks/useMedia';
import DistrictsFilter from '../FilterRealty/DistrictsFilter';
import EndingsFilter from "../FilterRealty/EndingsFilter";
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from "../FilterRealty/PriceFilter";
import SelectFilter from "../FilterRealty/SelectFilter";
import SquareFilterOnly from "../FilterRealty/SquareFilterOnly";

const FilterSuburbans = (props) => {
    const { filterData, filter, handleFilter, clearFilter, clearExcludeOnly, clearFilterOnly } = props
    const isMobile = useMedia("isMobile")
    const smallScreen = useMedia("isLaptop");
    return (
        <>
            <div className="modal-form__row">
                <div className="modal-form__item">
                    <DistrictsFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "districts")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                            filterData.districts
                                ? Object.values(filterData.districts)
                                : []
                        }
                        filterValues={filter.filter.districts}
                        excludeValues={filter.exclude.districts}
                        data={{
                            title: "Район",
                            autocompleteTitle: "Введите название района",
                            identity: "districts",
                        }}
                        city={props.city}
                        setDistricts={(a) =>
                            handleFilter({ filter: a }, "districts")
                        }
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                        right={isMobile && true}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "type")
                        }
                        isExcludable={true}
                        items={
                            filterData.type
                                ? Object.values(filterData.type)
                                : []
                        }
                        filterValues={filter.filter.type}
                        excludeValues={filter.exclude.type}
                        data={{
                            title: "Тип объекта",
                            autocompleteTitle: "",
                            identity: "type",
                        }}
                        isAutocomplete={false}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}

                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <SquareFilterOnly
                        filter={filter.filter.squareParcel}
                        data={{
                            title: 'Площадь участка',
                            minPlaceholder: 'S уч. от ',
                            maxPlaceholder: 'S уч. до ',
                            defaultValues: filterData.squareParcel
                                ? filterData.squareParcel.range
                                : null,
                        }}
                        onChange={(a, identity) => handleFilter(a, identity)}
                        clearFilterOnly={clearFilter}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                        identity={"squareParcel"}
                    />
                </div>
                <div className="modal-form__item">
                    <SquareFilterOnly
                        filter={filter.filter.squareHouse}
                        data={{
                            title: 'Площадь дома',
                            minPlaceholder: 'S дома от ',
                            maxPlaceholder: 'S дома до ',
                            defaultValues: filterData.squareHouse
                                ? filterData.squareHouse.range
                                : null,
                        }}
                        onChange={(a, identity) => handleFilter(a, identity)}
                        identity={"squareHouse"}
                        clearFilterOnly={clearFilter}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
            </div>
            <div className="modal-form__row">
                <div className="modal-form__item">
                    <PriceFilter
                        filter={
                            filter.filter.prices
                                ? filter.filter.prices
                                : { min: "", max: "" }
                        }
                        exclude={
                            filter.exclude.prices
                                ? filter.exclude.prices
                                : { min: "", max: "" }
                        }
                        items={
                            filterData.prices ? filterData.prices.items : []
                        }
                        data={{
                            title: "Цена",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.prices
                                ? filterData.prices.range
                                : 0,
                        }}
                        onChange={(a) => handleFilter(a, "prices")}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <EndingsFilter
                        ident={"endings"}

                        filter={
                            filter.filter.endings
                                ? filter.filter.endings
                                : { min: "", max: "" }
                        }
                        items={
                            filterData.endings ? filterData.endings.items : []
                        }
                        data={{
                            title: "Срок сдачи",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.endings
                                ? filterData.endings.ranges
                                : null,
                        }}
                        onChange={(a) => handleFilter(a, "endings")}
                        // secondData={{
                        //   title: "Сданные дома",
                        //   value: filter.filter?.isOnlyHanded?.length
                        //     ? filter.filter?.isOnlyHanded[0]?.id
                        //     : "N",
                        // }}
                        // secondChange={(a) => {
                        //   let tmp = a === "Y" ? [{ id: a }] : [];
                        //   handleFilter(
                        //     {
                        //       filter: tmp,
                        //       exclude: [
                        //         ..._filter(
                        //           filter.exclude.isOnlyHanded,
                        //           (val) => val.id != a,
                        //         ),
                        //       ],
                        //     },
                        //     "isOnlyHanded",
                        //   );
                        // }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    /> </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "builders")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                            filterData.builders
                                ? Object.values(filterData.builders)
                                : []
                        }
                        filterValues={filter.filter.builders}
                        excludeValues={filter.exclude.builders}
                        data={{
                            title: "Застройщик",
                            autocompleteTitle: "Введите название застройщика",
                            identity: "builders",
                        }}
                        right={!smallScreen}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "materials")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.materials
                                ? Object.values(filterData.materials)
                                : []
                        }
                        filterValues={filter.filter.materials}
                        excludeValues={filter.exclude.materials}
                        data={{
                            title: "Материал дома",
                            autocompleteTitle: "",
                            identity: "materials",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                        right={smallScreen}
                    />
                </div>
            </div>
            <div className="modal-form__row">
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "features")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.features
                                ? Object.values(filterData.features)
                                : []
                        }
                        filterValues={filter.filter.features}
                        excludeValues={filter.exclude.features}
                        data={{
                            title: "Особенности",
                            autocompleteTitle: "",
                            identity: "features",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                        right={smallScreen}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "communications")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.communications
                                ? Object.values(filterData.communications)
                                : []
                        }
                        filterValues={filter.filter.communications}
                        excludeValues={filter.exclude.communications}
                        data={{
                            title: "Коммуникации",
                            autocompleteTitle: "",
                            identity: "communications",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                        right={smallScreen}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "villages")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.villages
                                ? Object.values(filterData.villages)
                                : []
                        }
                        filterValues={filter.filter.villages}
                        excludeValues={filter.exclude.villages}
                        data={{
                            title: "Посёлок",
                            autocompleteTitle: "",
                            identity: "villages",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                        right={smallScreen}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "rooms")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.rooms
                                ? Object.values(filterData.rooms)
                                : []
                        }
                        filterValues={filter.filter.rooms}
                        excludeValues={filter.exclude.rooms}
                        data={{
                            title: "К-во комнат",
                            autocompleteTitle: "",
                            identity: "rooms",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
            </div>
            <div className="modal-form__row">
                <div className="modal-form__item">
                    <FloorsFilter
                        // 
                        filter={filter.filter.floors}
                        items={
                            filterData.floors?.items ? filterData.floors.items : []
                        }
                        data={{
                            title: "Этаж",
                            minPlaceholder: "от ",
                            maxPlaceholder: "до ",
                            defaultValues: filterData.floors
                                ? filterData.floors.range
                                : null,
                        }}
                        onChange={(a) => handleFilter(a, "floors")}
                        // secondData={{
                        //   title: filterData.isNoFirstFloor
                        //     ? filterData.isNoFirstFloor[0].title
                        //     : "Не первый",
                        //   value: filter.filter.isNoFirstFloor.length
                        //     ? filter.filter.isNoFirstFloor[0].id
                        //     : "N",
                        // }}
                        // secondChange={(a) => {
                        //   let tmp = a === "Y" ? [{ id: a }] : [];
                        //   handleFilter(
                        //     {
                        //       filter: tmp,
                        //       exclude: [
                        //         ..._filter(
                        //           filter.exclude.isNoFirstFloor,
                        //           (val) => val.id != a,
                        //         ),
                        //       ],
                        //     },
                        //     "isNoFirstFloor",
                        //   );
                        // }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}


                        fullFilter={filter}
                        right={smallScreen}
                    />
                </div>
                <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "nearby")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.nearby
                                ? Object.values(filterData.nearby)
                                : []
                        }
                        filterValues={filter.filter.nearby}
                        excludeValues={filter.exclude.nearby}
                        data={{
                            title: "Рядом",
                            autocompleteTitle: "",
                            identity: "nearby",
                        }}
                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>
                {filter.filter.payment && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "payment")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.payment ? Object.values(filterData.payment) : []
                        }
                        filterValues={filter.filter.payment}
                        excludeValues={filter.exclude.payment}
                        data={{
                            title: "Оплата",
                            autocompleteTitle: "",
                            identity: "payment",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
                {filterData.purpose && <div className="modal-form__item">
                    <SelectFilter
                        onChange={(a, b) =>
                            handleFilter({ filter: a, exclude: b }, "purpose")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                            filterData.purpose ? Object.values(filterData.purpose) : []
                        }
                        filterValues={filter.filter.purpose}
                        excludeValues={filter.exclude.purpose}
                        data={{
                            title: "Статус земли",
                            autocompleteTitle: "",
                            identity: "purpose",
                        }}

                        isMobile={isMobile}
                        clearFilterOnly={clearFilterOnly}
                        clearExcludeOnly={clearExcludeOnly}
                        fullFilter={filter}
                    />
                </div>}
            </div>
        </>
    )
}
export default FilterSuburbans
