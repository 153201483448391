import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../../hooks/useDropdown';

const DropdownCustomColor = ({ value, options, onChange, titleKey = "title", openTop }) => {
  const [node, open, show, hide] = useDropdown(false);
  const [current, setCurrent] = useState(null);

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    hide();
  };

  useEffect(() => {
    let m = false;
    if (value) {
      m = options.find((item) => item.id === value);
    }
    setCurrent(m || options[0]);
  }, [value, options]);

  return (
    <div ref={node} className={`custom-select${open ? ` is-showing` : ``}${openTop ? ` open-top` : ``}`}>
      {current && (
        <>
          <div
            className="custom-select__selected"
            onClick={(e) => (open ? hide() : show())}
          >
            <span
              className={`label-item label_${current.color}`}
              style={current.color?.includes("#") ? {
                color: current.color,
                backgroundColor: current.color + '33',
              } : {}}>
              {current[titleKey]}
            </span>
          </div>
          <SimpleBar className="custom-select__options">
            {options.map((item) => (
              <li
                className={
                  'custom-select__option' +
                  (current.id === item.id ? ' is-active' : '')
                }
                key={item.id}
                onClick={() => handleChange(item.id)}
              >
                <span
                  className={`label-item label_${item.color}`}
                  style={item.color?.includes("#") ? {
                    color: item.color,
                    backgroundColor: item.color + '33',
                  } : {}}
                >
                  {item[titleKey]}
                </span>
              </li>
            ))}
          </SimpleBar>
        </>
      )}
    </div>
  );
};

export default DropdownCustomColor;