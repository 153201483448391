import maxBy from 'lodash/maxBy';
import React, { useEffect, useMemo, useRef, useState } from "react";
import InputMask from 'react-input-mask';
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Map, SearchControl, YMaps } from 'react-yandex-maps';
import { checkSiteDomain } from '../../../../actions/constructor';
import { setUserState } from '../../../../actions/user';
import adminLink from "../../../../assets/images/constructor/admin-link.svg";
import linkImg from "../../../../assets/images/constructor/link.svg";
import useElement from '../../../../hooks/useElement';
import useFileManager from '../../../../hooks/useFileManager';
import useMedia from '../../../../hooks/useMedia';
import usePermission from '../../../../hooks/usePermission';
import { isValidSubdomain } from '../../../../utils/location';
import Collapse from '../../../core/Collapse';
import Dropdown from "../../../core/Dropdown";
import DropdownMulti from "../../../core/DropdownMulti";
import Dropzone from '../../../core/Dropzone';
import Modal from "../../../core/Modal";
import { FileRow } from '../../../FileUploader';
import Preloader from '../../../Preloader';
import Analytics from '../Analytics';
import Domen from '../Domen';
import List from '../List';
import PhotoUpload from '../PhotoUpload';
import Preview from '../Preview';
import Promo from '../Promo';
import Reviews from '../Reviews';
import Seo from '../Seo';
import Socials from '../Socials';
import Themes from '../Themes';
import { createPreview } from '../utils';
const colors = {
    'green': {
        currentColor: `#2CBA2E`,
        secondColor: `#183024`
    },
    'blue': {
        currentColor: `#62B8E7`,
        secondColor: `#173343`
    },
    'yellow': {
        currentColor: `#FFD336`,
        secondColor: `#2C2E3E`
    },

    'red': {
        currentColor: `#DD453B`,
        secondColor: `#301120`
    },
    'gray': {
        currentColor: `#A9A9B8`,
        secondColor: `#3F3F57`
    },
    'asphalt': {
        currentColor: `#5E617F`,
        secondColor: `#1F223E`
    },
    'purple': {
        currentColor: `#9377B7`,
        secondColor: `#584274`
    },
    'burgundy': {
        currentColor: `#781B1B`,
        secondColor: `#280101`
    },

    'nightsky': {
        currentColor: `#4545C2`,
        secondColor: `#14143A`
    },
    'turquoise': {
        currentColor: `#5EB7A7`,
        secondColor: `#102E28`
    },
}


const IconEyeButton = ({ clickHandler }) => {
    return <button type="button" className="button admin__icon-btn admin__icon-btn_with-hover" aria-label="Предпросмотр" onClick={clickHandler}>
        <span className="admin__icon admin__icon_sm admin__icon_eye">
            <svg className="icon icon_eye">
                <use xlinkHref="#view" />
            </svg>
        </span>
    </button>
}

const Edit = (props) => {
    const { services,
        theme,
        blocks,
        advantages,
        agent,
        isFetching,
        sets,
        status,
        agency,
        socials,
        promo,
        customJS,
        subDomain,
        reviews,
        yandexMetric,
        socialTypes,
        promoText,
        dateUpdate,
        defaultCity,
        cities,
        widgetReviews,
        leadMagnet,
        selfDomain,
        site,
        coordinates,
        phoneAgency,
        multiLink,
        googleVerification,
        yandexVerification,
        realtyTypes,
        defaultRealtyType
    } = props.state


    const { color } = theme
    const {
        forcedSetState,
        user,
        saveSite,
        setState,
        isSavingInProcess,
        domain,
        setDomain,
        scrollToFirstSectorRef
    } = props

    const { files, uploadFiles, deleteFile, editFileName } = useFileManager({
        maxFiles: 1,
        onFilesChange: files => forcedSetState({ leadMagnet: { ...leadMagnet, file: files[0] } })
    });
    const [mapInstance, setMapInstance] = useState(null);
    const mapCenter = user?.city == 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587];
    const [ymaps, setYmaps] = useState(null);

    const [searchRef, setSearchRef] = useState(null)
    const [isOldSubDomain, setIsOldSubDomain] = useState((status == 1 && domain?.toLowerCase?.() === subDomain?.toLowerCase?.()))
    const [domainMessage, setDomainMessage] = useState({ isExist: false, message: false })

    const isMobile = useMedia("isMobile")
    const isTablet = useMedia("isTablet")
    const isDesktop = useMedia("isDesktop")
    const isToprealtors = usePermission('toprealtors')
    useEffect(() => {
        if (domain) {
            setIsOldSubDomain(status == 1 && domain?.toLowerCase?.() === subDomain?.toLowerCase?.())
        }
    }, [domain])

    useEffect(() => {
        setDomain(subDomain || `agent-${user.id}`);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [subDomain]);

    useEffect(() => {
        if (!!defaultRealtyType && !!defaultCity) return
        forcedSetState({
            defaultCity: agent?.defaultCity || "spb",
            defaultRealtyType: agent.defaultRealtyType?.id
        })
        
    }, [user, cities, realtyTypes, agent])

    const [openFirstScreenModal, closeFirstScreenModal] = useModal(({ }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal c-modal_preview" close={closeFirstScreenModal} closeByPastClick={true}>

            {/*<button type="button"
                className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
                aria-label="Закрыть окно"
                onClick={closeFirstScreenModal}
            >
                <span className="c-modal__icon">
                    <svg className="icon icon_cross">
                        <use xlinkHref="#cross" />
                    </svg>
                </span>
            </button>*/}
            <div className="c-modal__box">
                <div className="c-modal__content">
                    <Preview />
                </div>
            </div>
        </Modal>
    ), []);


    useEffect(() => {
        if (!ymaps || !mapInstance) return
        const { onChange, value } = propsCreator("coordinates")
        const placeMark = new ymaps.Placemark(!!value?.length ? value : mapInstance.getCenter(), {
        }, {
            iconLayout: "default#image",
            iconImageHref: "/assets/images/place.png",
            iconImageSize: [25, 40],
            iconImageOffset: [-12.5, -40],
            draggable: true
        })
        mapInstance.geoObjects
            .removeAll()
        mapInstance.geoObjects
            .add(placeMark)
        placeMark.events.add(
            ['dragend'],
            () => onChange({ target: { value: placeMark.geometry.getCoordinates() } })
        );
        mapInstance.events.add('click', function (e) {
            var coords = e.get('coords');
            placeMark.geometry.setCoordinates(coords)
            onChange({ target: { value: coords } })
        });
        if (searchRef) {
            searchRef.events.add(
                ['resultselect'],
                (e) => {
                    searchRef.getResult(0)
                    searchRef.getResult(0).then(resp => {
                        const newCords = resp.geometry.getCoordinates()
                        onChange({ target: { value: newCords } })
                        placeMark.geometry.setCoordinates(newCords)
                        searchRef.clear()
                    }
                    )
                }
            );
        }
    }, [ymaps, mapInstance, searchRef])


    const [showMapModal, closeMapModal] = useModal(({ }) => (
        <Modal hideDefaultClose={true} classes="modal_task c-modal c-modal_preview" close={closeMapModal} closeByPastClick={true}>
            <div style={{ width: 720, height: 600 }}>
                {!isMobile &&
                    <button type="button"
                        className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_map"
                        aria-label="Закрыть окно"
                        onClick={closeMapModal}
                    >
                        <span className="c-modal__icon">
                            <svg className="icon icon_cross">
                                <use xlinkHref="#cross" />
                            </svg>
                        </span>
                    </button>
                }
                <YMaps
                    query={{ apikey: "1c00f913-9b45-4a8e-921c-b61d324deb58" }}
                >
                    <Map
                        defaultState={{
                            center: !!coordinates.length ? coordinates : mapCenter,
                            zoom: 16,
                        }}
                        className="complex__map-wrap"
                        instanceRef={setMapInstance}
                        modules={["Placemark",]}
                        onLoad={setYmaps}
                    >
                        <SearchControl
                            instanceRef={setSearchRef}
                        />
                    </Map>
                </YMaps>
            </div>
        </Modal>
    ), []);

    const [isScrollPanelVisible, setVisible] = useState(true);

    const [darkPopover, setDarkPopover] = useState(true);

    const [showMenuList, setShowMenuList] = useState(false);

    const dispatch = useDispatch()
    const isSaveSitePopoverVisible = useSelector(state => state.user.isSaveSitePopoverVisible)

    const hideSavePopover = () => dispatch(setUserState({ isSaveSitePopoverVisible: false }))
    const showSavePopover = () => dispatch(setUserState({ isSaveSitePopoverVisible: true }))

    const offsetTop = isMobile ? 50 : 50;

    const firstSectionRef = useRef(null);
    const firstHalfSectionRef = useRef(null);
    const secondSectionRef = useRef(null);
    const thirdSectionRef = useRef(null);
    const fourthSectionRef = useRef(null);
    const fifthSectionRef = useRef(null);
    const sixthSectionRef = useRef(null);
    const firstMenuItem = useRef(null);
    const firstHalfMenuItem = useRef(null);
    const secondMenuItem = useRef(null);
    const thirdMenuItem = useRef(null);
    const fourthMenuItem = useRef(null);
    const seoBlockRef = useRef(null);

    const leadMagnetRef = useRef(null);
    const estateRef = useRef(null);
    const servicesRef = useRef(null);
    const mortgageRef = useRef(null);
    const advantagesRef = useRef(null);
    const videosRef = useRef(null);
    const reviewsRef = useRef(null);
    const promoRef = useRef(null);


    const submitButtonRef = useRef(null);
    const wrapperRef = useRef(null);

    const getFooter = useElement("footer")

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [showMenuList, isScrollPanelVisible]);

    const fix = (button, footer) => {
        if (!wrapperRef.current) return
        if ((button.current?.offsetTop + window.scrollY) + button.current?.offsetHeight >=
            (footer.offsetTop) - 10) {
            wrapperRef.current.style.cssText = 'position: static;'
            button.current.style.cssText = `position: absolute; bottom: 0px;`;
        }

        if (window.scrollY + window.innerHeight < (footer.offsetTop)) {
            wrapperRef.current.style.cssText = 'position: relative;'
            button.current.style.cssText = `position: fixed; bottom: 0;`;
        }
    };

    // useEffect(() => {
    //   if (submitButtonRef.current && getFooter) {
    //     isMobile && window.addEventListener('scroll', () => fix(submitButtonRef, getFooter));
    //   }
    //
    //   return () => {
    //     isMobile && window.removeEventListener('scroll', () => fix(submitButtonRef, getFooter));
    //   };
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [submitButtonRef, getFooter]);

    const onMenuBtnClick = (ref) => {
        // scrollToRef(ref, isMobile ? 0 : -50);
        ref.current.scrollIntoView({ behavior: "smooth" })

        const offset = isMobile ? 50 : 140;
        const scrollPosition = ref.current.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });

        setShowMenuList(false)
    };
    scrollToFirstSectorRef.current = () => onMenuBtnClick(firstSectionRef)
    const items = [
        {
            section: firstSectionRef,
            item: firstMenuItem,
        },
        {
            section: firstHalfSectionRef,
            item: firstHalfMenuItem,
        },
        {
            section: secondSectionRef,
            item: secondMenuItem,
        },
        {
            section: thirdSectionRef,
            item: thirdMenuItem,
        },
        {
            section: fourthSectionRef,
            item: fourthMenuItem,
        },
    ]


    const changeMenuItem = (section, item) => {
        const sectionIsVisible = (section.current?.getBoundingClientRect().top - 340) < 0
        const sectionNotVisible =
            (Math.abs(section.current?.getBoundingClientRect().top) + 340) > section.current?.getBoundingClientRect().height ||
            section.current?.getBoundingClientRect().top > 340

        if (sectionIsVisible && !sectionNotVisible) {
            return (
                item.current?.classList.add('is-active')
            )
        } else if (sectionNotVisible) {
            return (
                item.current?.classList.remove('is-active')
            )
        }
    }

    const handleScroll = () => {
        let currentScrollPosition = isMobile
            ? firstHalfSectionRef.current?.getBoundingClientRect().top
            : firstSectionRef.current?.getBoundingClientRect().top;
        setVisible(currentScrollPosition > offsetTop);

        if (showMenuList && isScrollPanelVisible) {
            setShowMenuList(false)
        }

        items.map((arrItem) => (
            changeMenuItem(arrItem.section, arrItem.item)
        )
        )

        if (submitButtonRef.current && getFooter) {
            fix(submitButtonRef, getFooter)
        }
    };
    const match = (path) => path.match(/(?:[a-z]+)/gi)



    const propsCreator = (path, val, beforeSet = v => v) => {
        let value = match(path).reduce((acc, current) => {
            return acc[current]
        }, props.state)
        return {
            onChange: (e) => setState(beforeSet(val ? val : e.target.value), path),
            value,
            ...(value === val ? { checked: value === val } : [])
        }
    }

    const addReview = () => {
        const tmp = [...reviews]
        const maxFakeId = maxBy(reviews, "fakeId")?.fakeId
        const template = {
            "id": 0,
            "fio": "",
            "photo": false,
            "review": "",
            fakeId: maxFakeId >= 0 ? maxFakeId + 1 : 0,
        }
        tmp.push(template)
        setState(tmp, "reviews")
    }

    const removeReview = (fakeId) => {
        const tmp = [...reviews]
        let index = reviews.findIndex(item => {
            return item.fakeId == fakeId
        })
        tmp.splice(index, 1)
        forcedSetState({ reviews: tmp })
    }

    const changeDomain = val => {
        if (isValidSubdomain(val) && val.length > 3) {
            checkSiteDomain(val).then((resp) => {
                setDomainMessage(prev => ({ ...prev, isExist: resp.isExist, message: resp.isExist ? "Данный домен не доступен для регистрации" : "Данный домен не доступен для регистрации" }))
            }, error => {
                setDomainMessage(prev => ({ ...prev, isExist: true, message: error[0]?.message }))
            })
        } else {
            setDomainMessage(prev => ({ ...prev, isExist: true, message: "Данный домен не доступен для регистрации" }))
        }
        setDomain(val.toLowerCase())
    }
    if (isFetching) {
        return <Preloader loading={true} />
    }

    const cityByCode = {
        spb: "Новостройки СПБ",
        msk: "Новостройки МСК"
    };
    const changeMail = e => {
        const { onChange } = propsCreator("email")
        onChange(e)
    }

    return (
        <div className="constructor__container">
            <div className="admin__wrapper constructor__wrapper">
                <form action="#" className="admin__form">
                    <div className="admin__wrapper constructor__wrapper constructor__wrapper_admin">

                        <aside className="admin__aside">
                            <div className="admin__nav">
                                <div className={`admin__nav-item admin__nav-item_with-border-bot collapse collapse_with-chevron${status === 0 ? ` admin__nav-item_disabled` : ``}`}>
                                    <Link className="collapse__title" target="_blank" to={`/objects`}>
                                        <span className="collapse__title-text">Свои объекты</span>
                                        {
                                            status === 0 &&
                                            <div className="info">
                                                <svg className="icon icon_info">
                                                    <use xlinkHref="#info" />
                                                </svg>
                                                <div className="info__popup">
                                                    <p>Доступ к добавлению своих объектов будет открыт после публикации сайта</p>
                                                </div>
                                            </div>
                                        }

                                        {
                                            status === 1 &&
                                            <div className={`collapse__chevron`}>
                                                <svg class="icon icon_chevron">
                                                    <use xlinkHref="#chevron"></use>
                                                </svg>
                                            </div>
                                        }
                                    </Link>

                                </div>
                                <div className="admin__nav-item">
                                    <a
                                        href="#"
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(firstSectionRef);
                                        }}
                                    >
                                        Основное
                                    </a>
                                </div>
                                <div className="admin__nav-item">
                                    <a
                                        href=""
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(secondSectionRef);
                                        }}
                                    >
                                        Стиль оформления
                                    </a>
                                </div>
                                <div className="admin__nav-item">
                                    <Collapse title={`Контент`} chevron>
                                        <ul>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(thirdSectionRef);
                                                    }}
                                                >
                                                    Первый экран
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(leadMagnetRef);
                                                    }}
                                                >
                                                    Лид-магнит
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(estateRef);
                                                    }}
                                                >
                                                    Подборки недвижимости
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(servicesRef);
                                                    }}
                                                >
                                                    Услуги
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(mortgageRef);
                                                    }}
                                                >
                                                    Расчет ипотеки
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(advantagesRef);
                                                    }}
                                                >
                                                    Преимущества
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(videosRef);
                                                    }}
                                                >
                                                    Мои видео
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(reviewsRef);
                                                    }}
                                                >
                                                    Отзывы
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        onMenuBtnClick(promoRef);
                                                    }}
                                                >
                                                    Промо-блок
                                                </a>
                                            </li>
                                        </ul>

                                    </Collapse>
                                </div>

                                <div className="admin__nav-item">
                                    <a
                                        href=""
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(fourthSectionRef);
                                        }}
                                    >
                                        Домен
                                    </a>
                                </div>

                                <div className="admin__nav-item">
                                    <a
                                        href=""
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(fifthSectionRef);
                                        }}
                                    >
                                        Аналитика
                                    </a>
                                </div>
                                <div className="admin__nav-item">
                                    <a
                                        href=""
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(seoBlockRef);
                                        }}
                                    >
                                        SEO
                                    </a>
                                </div>
                                <div className="admin__nav-item">
                                    <a
                                        href=""
                                        className="admin__menu-anchor"
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            onMenuBtnClick(sixthSectionRef);
                                        }}
                                    >
                                        Другое
                                    </a>
                                </div>
                            </div>

                            <div className="admin__aside-btns">
                                <button type="button" className="button button_view_outline" disabled={isSavingInProcess}
                                    onClick={() => {
                                        saveSite(0);
                                        showSavePopover();
                                    }}>
                                    Сохранить
                                </button>
                                <button type="button" className="button button_view_default" disabled={isSavingInProcess} onClick={() => saveSite(1)}>Опубликовать</button>
                            </div>

                            <div className={`admin__popover popover ${isSaveSitePopoverVisible ? " is-showing" : ""}`}>
                                <div className="popover__block popover__block_align_left clip__tooltip">
                                    <div className="popover__inner">
                                        <div className="clip__tooltip-close">
                                            <button
                                                type="button"
                                                className="button button_type_delete"
                                                onClick={() => hideSavePopover()}
                                            ></button>
                                        </div>
                                        <div className="popover__flex">
                                            <img src={adminLink} />
                                            <div>
                                                <div className="clip__tooltip-text">
                                                    Сайт почти готов. Осталось только опубликовать его 👇
                                                </div>
                                                <button type="button" disabled={isSavingInProcess} onClick={() => {
                                                    hideSavePopover()
                                                    saveSite(1)
                                                }} className="button button_view_default admin__menu-btn">
                                                    Сделать это 🚀
                                                </button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>

                            {
                                //theme.darkMode === 'Y' &&
                                false &&
                                <div className={`admin__popover admin__popover_dark popover ${darkPopover ? " is-showing" : ""}`}>
                                    <div className="popover__block popover__block_align_left clip__tooltip">
                                        <div className="popover__inner">
                                            <div className="clip__tooltip-close">
                                                <button
                                                    type="button"
                                                    className="button button_type_delete"
                                                    onClick={() => setDarkPopover(false)}
                                                ></button>
                                            </div>
                                            <div className="popover__flex popover__flex_center">
                                                <img src={adminLink} />
                                                <div>
                                                    <div className="clip__tooltip-text">
                                                        Вы перешли на тёмную сторону!
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            }



                        </aside>

                        <div className="admin__boxes">

                            {
                                // О вас / Агентство
                            }
                            <div className="admin__box" ref={firstSectionRef}>
                                <div className="constructor__box">
                                    <div className="admin__row">
                                        <div className="admin__halfbox">
                                            <h3 className="h3 admin__h3">О вас</h3>


                                            <div className="admin__input-row admin__input-row_sm_column">
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Ваше имя</label>
                                                    <input type="text" className="admin__input" {...propsCreator("agent.name")} />
                                                </div>
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Ваша фамилия</label>
                                                    <input type="text" className="admin__input" {...propsCreator("agent.lastName")} />
                                                </div>
                                            </div>
                                            <div className="admin__input-block">
                                                <label htmlFor="" className="admin__label">Должность</label>
                                                <input type="text" className="admin__input" {...propsCreator("agent.post")} />
                                            </div>

                                            <div className="admin__input-row admin__input-row_sm_column">
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Телефон</label>
                                                    <InputMask type="text" className="admin__input" mask="+7 (999) 999-99-99" maskChar="_" {...propsCreator("agency.phone")} />
                                                </div>
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">E-mail</label>
                                                    <input type="text" className="admin__input" {...propsCreator("email")} onChange={changeMail} />
                                                </div>
                                            </div>

                                            <PhotoUpload
                                                image={agent.photo}
                                                handleImageUpload={(val) => setState(val, "agent.photo")}
                                                title="Загрузить фото"
                                                modalTitle="Загрузить фото менеджера"
                                            />

                                        </div>

                                        {!isMobile &&
                                            <div className="admin__halfbox">
                                                <h3 className="h3 admin__h3">Агентство</h3>

                                                <div className="admin__input-row admin__input-row_sm_column">
                                                    <div className="admin__input-block">
                                                        <label htmlFor="" className="admin__label">Название агентства</label>
                                                        <input type="text" className="admin__input" {...propsCreator("agency.name")} />
                                                    </div>

                                                    <div className="admin__input-block">
                                                        <label htmlFor="" className="admin__label">Телефон агентства</label>
                                                        <InputMask type="text" className="admin__input" mask="+7 (999) 999-99-99" maskChar="_" {...propsCreator("phoneAgency")} />
                                                    </div>
                                                </div>


                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Адрес офиса</label>
                                                    <div className="admin__input-flex">
                                                        <input type="text" className="admin__input" {...propsCreator("agency.address")} />
                                                        <div className={`${!!agency.address ? "with-tooltip-disabled" : "with-tooltip"}`}>
                                                            <button className="admin__map-btn" type="button" onClick={showMapModal} disabled={!agency.address}>На карте</button>
                                                            <span className="tooltip">Чтобы указать точку на карте, заполните поле «Адрес офиса»</span>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Корпоративный сайт</label>
                                                    <input type="text" className="admin__input" placeholder="corporate-site.ru" {...propsCreator("site")} />
                                                </div>

                                                <PhotoUpload
                                                    image={agency.logo}
                                                    handleImageUpload={(val) => setState(val, "agency.logo")}
                                                    title="Загрузить лого"
                                                    modalTitle="Загрузить логотип"
                                                    addPhotoStyles={{
                                                        objectFit: "contain"
                                                    }}
                                                />


                                                <Socials socials={socials} changeHandler={(value) => setState(value, "socials", socials, "fakeId")} />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>

                            {isMobile &&
                                <div className="admin__box" ref={firstHalfSectionRef}>
                                    <div className="constructor__box">
                                        <div className="admin__row">
                                            <h3 className="h3 admin__h3">Агентство</h3>

                                            <div className="admin__input-row">
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Название агентства</label>
                                                    <input type="text" className="admin__input" {...propsCreator("agency.name")} />
                                                </div>
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Телефон</label>
                                                    <InputMask type="text" className="admin__input" mask="+7 (999) 999-99-99" maskChar="_" {...propsCreator("agency.phone")} />

                                                    {/* <input type="text" className="admin__input" {...propsCreator("agency.phone")} /> */}
                                                </div>
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">E-mail</label>
                                                    <input type="text" className="admin__input" {...propsCreator("email")} onChange={changeMail} />
                                                </div>
                                            </div>

                                            <div className="admin__input-block">
                                                <label htmlFor="" className="admin__label">Адрес офиса</label>
                                                <input type="text" className="admin__input" {...propsCreator("agency.address")} />
                                            </div>

                                            <PhotoUpload
                                                image={agency.logo}
                                                handleImageUpload={(val) => setState(val, "agency.logo")}
                                                title="Загрузить лого"
                                                modalTitle="Загрузить логотип"
                                                addPhotoStyles={{
                                                    objectFit: "contain"
                                                }}
                                            />


                                            <Socials socials={socials} changeHandler={(value) => setState(value, "socials", socials, "fakeId")} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                // Стиль оформления
                            }
                            <div className="admin__box" ref={secondSectionRef}>
                                <div className="constructor__box">
                                    <h3 className="h3 admin__h3">Стиль оформления</h3>

                                    {
                                        // Выберите цветовую гамму
                                    }
                                    <div className="admin__color-choice admin__section admin__section_no-border">
                                        <div className="admin__title">
                                            <h5 className="admin__h5 h5">Цветовая гамма</h5>
                                        </div>

                                        <ul className="admin__color-list">
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-1" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-1" className="admin__radio" {...propsCreator("theme.color", "blue")} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_blue"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-2" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-2" className="admin__radio" {...propsCreator("theme.color", 'red')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_red"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-3" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-3" className="admin__radio" {...propsCreator("theme.color", 'green')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_green"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-4" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-4" className="admin__radio" {...propsCreator("theme.color", 'yellow')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_yellow"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-5" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-5" className="admin__radio" {...propsCreator("theme.color", 'gray')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_gray"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-6" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-6" className="admin__radio" {...propsCreator("theme.color", "asphalt")} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_asphalt"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-7" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-7" className="admin__radio" {...propsCreator("theme.color", 'purple')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_purple"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-8" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-8" className="admin__radio" {...propsCreator("theme.color", 'burgundy')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_burgundy"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-9" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-9" className="admin__radio" {...propsCreator("theme.color", 'nightsky')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_nightsky"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-10" className="admin__radio-label">
                                                    <input type="radio" name="color" id="color-10" className="admin__radio" {...propsCreator("theme.color", 'turquoise')} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_turquoise"></div>
                                                </label>

                                            </li>
                                        </ul>

                                    </div>

                                    {
                                        // Настройка темы
                                    }
                                    <div className="admin__color-choice admin__section admin__section_no-border">
                                        <div className="admin__title">
                                            <h5 className="admin__h5 h5">Настройка темы</h5>
                                        </div>

                                        <ul className="admin__color-list">
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-light" className="admin__radio-label">
                                                    <input type="radio" name="darkMode" id="color-light" className="admin__radio" {...propsCreator("theme.darkMode", "N")} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_light"></div>
                                                </label>

                                            </li>
                                            <li className="admin__color-item admin__radio-box">
                                                <label htmlFor="color-dark" className="admin__radio-label">
                                                    <input type="radio" name="darkMode" id="color-dark" className="admin__radio" {...propsCreator("theme.darkMode", "Y")} />
                                                    <span className="admin__radio-marker"></span>
                                                    <div className="admin__color-bar admin__color-bar_dark"></div>
                                                </label>

                                            </li>

                                        </ul>

                                    </div>

                                    {
                                        // Выберите стиль первого экрана
                                    }
                                    <div className="admin__theme-choice admin__section">
                                        <div className="admin__title">
                                            <h5 className="admin__h5 h5">Стиль первого экрана</h5>
                                        </div>
                                        <Themes
                                            {...colors[color]}
                                            {...agent}
                                            setTheme={(id) => setState(id, "theme.style")}
                                            darkMode={theme.darkMode}
                                            currentTheme={theme.style - 1}
                                            iconEyeButton={() => <IconEyeButton clickHandler={() => createPreview(props.state)} />}
                                        />
                                    </div>

                                </div>
                            </div>

                            {
                                // Контент
                            }
                            <div className="admin__box" ref={thirdSectionRef}>
                                <div className="constructor__box">
                                    <h3 className="h3 admin__h3">Контент</h3>
                                    {
                                        // Первый экран
                                    }
                                    <div className="admin__first-screen admin__section admin__section_sm">
                                        <div className="admin__title">
                                            <h5 className="admin__h5 h5">
                                                Первый экран
                                            </h5>
                                            <div className="info info_cursor_pointer" onClick={openFirstScreenModal}>
                                                <svg className="icon icon_info">
                                                    <use xlinkHref="#info" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="admin__input-row">
                                            <div className="admin__input-block">
                                                <label htmlFor="" className="admin__label">Заголовок</label>
                                                <textarea
                                                    className="admin__textarea"
                                                    cols="30" rows="5"
                                                    maxLength="50"
                                                    value={theme.header}
                                                    onChange={(e) => setState(e.target.value, "theme.header")}
                                                />
                                                <span className="admin__input-notice">Не больше 50 символов</span>
                                            </div>
                                            <div className="admin__input-block">
                                                <label htmlFor="" className="admin__label">Подпись к заголовку</label>
                                                <textarea
                                                    className="admin__textarea"
                                                    cols="30" rows="5"
                                                    maxLength="150"
                                                    value={theme.text}
                                                    onChange={(e) => setState(e.target.value, "theme.text")}
                                                />
                                                <span className="admin__input-notice">Не больше 150 символов</span>
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        // Лид-магнит
                                    }



                                    <div className={`admin__first-screen admin__section admin__section_sm${blocks.find(block => block.component === "leadMagnet")?.active ? `` : ` admin__section_pb-20`}`} ref={leadMagnetRef}>
                                        <div className="admin__title admin__title_mb-40">
                                            <label className="switch" htmlFor="lid-work">
                                                <div className="switch__box">
                                                    <input className="switch__control" type="checkbox" id="lid-work"
                                                        checked={blocks.find(block => block.component === "leadMagnet")?.active}
                                                        onChange={(e) => setState({ ...blocks.find(block => block.component === "leadMagnet"), active: e.target.checked }, "blocks", blocks)}
                                                    />
                                                    <div className="switch__input" htmlFor="lid-work" />
                                                    <div className="switch__marker" />
                                                </div>
                                            </label>
                                            <h5 className="admin__h5 h5">
                                                Лид-магнит
                                            </h5>
                                            <div className="info">
                                                <svg className="icon icon_info">
                                                    <use xlinkHref="#info" />
                                                </svg>
                                                <div className="info__popup">
                                                    <p>Скачиваемый файл, который вы обмениваете на контактные данные клиента</p>
                                                </div>
                                            </div>

                                            {status == 1 && (subDomain || selfDomain == 1) &&
                                                <div style={{ marginLeft: `auto`, marginRight: `auto`, display: `flex`, gap: `25px` }}>
                                                    <div className={"admin__anchor-wrapper"}>
                                                        <img src={linkImg} />
                                                        <a
                                                            href={selfDomain ? `https://${props.state.domain}/#lead` : `https://${subDomain}.toprealtors.ru/#lead`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="admin__anchor"
                                                        >{`Ссылка на блок`}</a>
                                                    </div>

                                                    <div className={"admin__anchor-wrapper"}>
                                                        <img src={linkImg} />
                                                        <a
                                                            href={selfDomain ? `https://${props.state.domain}/lead-form` : `https://${subDomain}.toprealtors.ru/lead-form`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="admin__anchor"
                                                        >{`Ссылка на страницу`}</a>
                                                    </div>

                                                </div>}

                                        </div>
                                        {!!blocks.find(block => block.component === "leadMagnet")?.active && <>

                                            <div className="admin__input-row">
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Заголовок</label>
                                                    <textarea
                                                        className="admin__textarea"
                                                        cols="30" rows="5"
                                                        maxLength="50"
                                                        {...propsCreator("leadMagnet.title")}
                                                        placeholder="Например, «5 долгостроев Санкт-Петербурга в 2019-2020»‎"
                                                    />
                                                    <span className="admin__input-notice">Не больше 50 символов</span>
                                                </div>
                                                <div className="admin__input-block">
                                                    <label htmlFor="" className="admin__label">Подпись к заголовку</label>
                                                    <textarea
                                                        className="admin__textarea"
                                                        cols="30" rows="5"
                                                        maxLength="150"
                                                        {...propsCreator("leadMagnet.subTitle")}
                                                        placeholder="Например, «Узнайте, в каких новостройках лучше отказаться от покупки квартир в ближайшее время»‎"
                                                    />
                                                    <span className="admin__input-notice">Не больше 150 символов</span>
                                                </div>
                                            </div>


                                            <div className="admin__input-row admin__input-row_flex-end">
                                                <div className="admin__input-block">
                                                    <label for="" className="admin__label">Название кнопки</label>
                                                    <input
                                                        type="text"
                                                        className="admin__input"
                                                        {...propsCreator("leadMagnet.buttonTitle")}
                                                        placeholder="Например, «Скачать»‎"
                                                    />
                                                </div>
                                                <div className="admin__input-block">
                                                    <div class="admin__title admin__title_with-info">
                                                        <label for="" class="admin__label">Поля для формы</label>
                                                        <div class="info">
                                                            <svg class="icon icon_info">
                                                                <use xlinkHref="#info"></use>
                                                            </svg>
                                                            <div class="info__popup">
                                                                <p>Выберите данные, которые должен будет заполнить клиент перед отправкой формы</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <DropdownMulti
                                                        data={{
                                                            title: "Поля для формы",
                                                            identity: "fields",
                                                        }}
                                                        items={leadMagnet.fields}
                                                        isMobile={isMobile}
                                                        onChange={idsArr => setState(leadMagnet.fields.map(field => ({ ...field, active: idsArr.includes(field.id) ? 1 : 0 })), "leadMagnet.fields")}
                                                        value={leadMagnet.fields.filter(field => !!field.active)?.map(f => f.id)}
                                                        disabledFields={leadMagnet.fields?.filter(field => field.permanent)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="admin__input-row admin__input-row_flex-end">

                                                <div className="admin__input-block">
                                                    <div class="admin__title admin__title_with-info">
                                                        <label for="" class="admin__label">Действие после заполнения формы</label>
                                                        <div class="info">
                                                            <svg class="icon icon_info">
                                                                <use xlinkHref="#info"></use>
                                                            </svg>
                                                            <div class="info__popup">
                                                                <p>В зависимости от выбранного формата, после заполнения формы начнет скачиваться файл или будет переход по указанной ссылке</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="select select_type_form">
                                                        <Dropdown
                                                            options={[{ id: "file", title: "Файл" }, { id: "link", title: "Ссылка" }]}
                                                            onChange={v => setState(v, "leadMagnet.type")}
                                                            value={leadMagnet.type}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            {
                                                leadMagnet.type == 'file' &&
                                                <div className="admin__input-block">
                                                    <div class="admin__title admin__title_with-info">
                                                        <label for="" class="admin__label">Прикрепить файл</label>
                                                        <div class="info">
                                                            <svg class="icon icon_info">
                                                                <use xlinkHref="#info"></use>
                                                            </svg>
                                                            <div class="info__popup">
                                                                <p>Загрузите файл, который будет скачиваться, после того, как клиент заполнит форму</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Dropzone uploadFiles={uploadFiles} title={`Загрузите файл, который сможет скачать ваш клиент после заполнения лид-формы`} />
                                                    {leadMagnet?.file && <FileRow name={leadMagnet?.file?.fileName} del={() => setState(null, "leadMagnet.file")} />}
                                                </div>
                                            }



                                            {
                                                leadMagnet.type == 'link' &&
                                                <div className="admin__input-block">
                                                    <label for="" className="admin__label">Ссылка для перехода</label>
                                                    <input
                                                        type="text"
                                                        className="admin__input"
                                                        {...propsCreator("leadMagnet.link")}
                                                        placeholder="lid-magnet.toprealtors.ru"
                                                    />
                                                </div>
                                            }
                                        </>}
                                    </div>


                                    {
                                        // Подборки недвижимости
                                    }
                                    <div className="admin__property admin__section admin__section_sm" ref={estateRef}>
                                        <List
                                            items={sets}
                                            ident="sets"
                                            title="Подборки недвижимости"
                                            modalTitle="Выберите необходимые Вам подборки недвижимости для отображения их на сайте."
                                            changeHandler={(value) => setState(value, "sets", sets, "fakeId")}
                                            withoutModal={true}
                                            switcher={{
                                                id: "sets",
                                                checked: blocks.find(block => block.component === "sets")?.active,
                                                onChange: e => setState({ ...blocks.find(block => block.component === "sets"), active: e.target.checked }, "blocks", blocks)
                                            }}
                                        />
                                        {cities && <div className="admin__input-block">
                                            <div class="admin__title" style={{ margin: `20px 0 12px` }}>
                                                <label for="" class="admin__label" style={{ margin: 0 }}>Приоритет вывода подборок</label>
                                                <div class="info">
                                                    <svg class="icon icon_info">
                                                        <use xlinkHref="#info"></use>
                                                    </svg>
                                                    <div class="info__popup">
                                                        <p>Выбранный вами тип недвижимости будет выведен по умолчанию на странице сайта</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {!!realtyTypes?.length && <div className="admin__input-row admin__cities-row">
                                                {realtyTypes.map(({ id, xmlId, title }) => {
                                                    if (title.includes("Новострой")) return cities.sort((a) => {
                                                        return a.code === user.city ? -1 : 0
                                                    })
                                                        .map(city => <div className="admin__radio-box" key={`${xmlId}-city-${city.code}`}>
                                                            <label htmlFor={`${xmlId}-city-${city.code}`} className="admin__radio-label">
                                                                <input
                                                                    type="radio"
                                                                    name="city"
                                                                    id={`${xmlId}-city-${city.code}`}
                                                                    className="admin__radio"
                                                                    value={`${xmlId}-${city.code}`}
                                                                    checked={city.code == defaultCity && id === defaultRealtyType}
                                                                    onClick={(e) => forcedSetState({defaultCity: city.code, defaultRealtyType: id})}
                                                                />
                                                                <span className="admin__radio-marker"></span>
                                                                <span>{cityByCode[city.code]}</span>
                                                            </label>
                                                        </div>)
                                                    const city = { code: "spb" }
                                                    return <div className="admin__radio-box" key={`${xmlId}-city-${city.code}`}>
                                                    <label htmlFor={`${xmlId}-city-${city.code}`} className="admin__radio-label">
                                                        <input
                                                            type="radio"
                                                            name="city"
                                                            id={`${xmlId}-city-${city.code}`}
                                                            className="admin__radio"
                                                            value={`${xmlId}-${city.code}`}
                                                            checked={city.code == defaultCity && id === defaultRealtyType}
                                                            onClick={(e) => forcedSetState({defaultCity: city.code, defaultRealtyType: id})}
                                                        />
                                                        <span className="admin__radio-marker"></span>
                                                        <span>{title} СПБ</span>
                                                    </label>
                                                </div>
                                                })}
                                            </div>}
                                        </div>}
                                    </div>


                                    {
                                        // Выберите услугу
                                    }
                                    <div className="admin__services admin__section admin__section_xsm" ref={servicesRef}>
                                        <List
                                            items={services}
                                            ident="services"
                                            title="Выберите услугу"
                                            tooltipTitle="Выберите список предоставляемых услуг для отображения их на сайте"
                                            changeHandler={(value) => setState(value, "services", services, "fakeId")}
                                            withoutTabs={true}
                                        />
                                    </div>
                                    {
                                        // Расчет ипотеки
                                    }
                                    <div className="admin__calculator admin__section admin__section_pb-20" ref={mortgageRef}>
                                        <div className="admin__title">
                                            <label className="switch" htmlFor="calculator-work">
                                                <div className="switch__box">
                                                    <input className="switch__control" type="checkbox" id="calculator-work"
                                                        checked={blocks.find(block => block.component === "mortgage").active}
                                                        onChange={(e) => setState({ ...blocks.find(block => block.component === "mortgage"), active: e.target.checked }, "blocks", blocks)} />
                                                    <div className="switch__input" htmlFor="calculator-work" />
                                                    <div className="switch__marker" />
                                                </div>
                                            </label>
                                            <h5 className="admin__h5 h5">
                                                Расчет ипотеки
                                            </h5>
                                            <div className="info">
                                                <svg className="icon icon_info">
                                                    <use xlinkHref="#info" />
                                                </svg>
                                                <div className="info__popup">
                                                    <p>Вывод ипотечного калькулятора на Вашем сайте</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        // Выберите преимущества
                                    }
                                    <div className="admin__advantages admin__section admin__section_xsm" ref={advantagesRef}>
                                        <List
                                            items={advantages}
                                            ident="advantages"
                                            title="Выберите преимущества"
                                            tooltipTitle="Выберите список преимуществ для отображения их на сайте"
                                            changeHandler={(value) => setState(value, "advantages", advantages, "fakeId")}
                                            withoutTabs={true}
                                        />
                                    </div>

                                    {
                                        // Мои видео
                                    }
                                    <div className="admin__calculator admin__section admin__section_pb-20" ref={videosRef}>
                                        <div className="admin__title">
                                            <label className="switch" htmlFor="video-work">
                                                <div className="switch__box">
                                                    <input className="switch__control" type="checkbox" id="video-work"
                                                        checked={blocks.find(block => block.component === "video").active}
                                                        onChange={e => setState({ ...blocks.find(block => block.component === "video"), active: e.target.checked }, "blocks", blocks)}
                                                    />
                                                    <div className="switch__input" htmlFor="video-work" />
                                                    <div className="switch__marker" />
                                                </div>
                                            </label>
                                            <h5 className="admin__h5 h5">
                                                Мои видео
                                            </h5>
                                        </div>

                                        {blocks.find(block => block.component === "video").active && <div class="admin__input-row admin__input-row_sm_column admin__halfbox admin__halfbox_single">
                                            <div class="admin__input-block">
                                                <label htmlFor="input-video" class="admin__label">Заголовок</label>
                                                <input type="text" class="admin__input" id="input-video" placeholder="Мои видео" {...propsCreator("videoTitle")} />
                                            </div>
                                            <div class="admin__input-block">
                                                <label htmlFor="input-video-id" class="admin__label">ID канала</label>
                                                <input type="text" class="admin__input" id="input-video-id" placeholder="mkApEREWV-E" {...propsCreator("videoChannel")} />
                                            </div>
                                            <a href="https://teletype.in/@toprealtors/M2Gfgoxg1wS" className="admin__anchor admin__anchor_red admin__anchor_mt-5" target="_blank" rel="noreferrer">Как получить ID канала?</a>
                                        </div>}
                                    </div>
                                    {
                                        // Отзывы
                                    }

                                    <span ref={reviewsRef}></span>
                                    <Reviews
                                        isActive={blocks.find(block => block.component === "reviews").active}
                                        setIsActive={(e) => setState({ ...blocks.find(block => block.component === "reviews"), active: e.target.checked }, "blocks", blocks)}
                                        revs={reviews}
                                        handleImageUpload={(id, value) => setState({ ...reviews.find(review => review.id === id), photo: value }, "reviews", reviews)}
                                        addReview={addReview}
                                        setReview={(id, rev) => setState({ ...reviews.find(review => review.fakeId === id), ...rev }, "reviews", reviews, "fakeId")}
                                        removeReview={removeReview}
                                        changeWidget={(e) => setState(e.target.value, "widgetReviews")}
                                        widgetReviews={widgetReviews}
                                    />
                                    {
                                        // Промо-блок
                                    }
                                    <span ref={promoRef}></span>
                                    <Promo
                                        isActive={blocks.find(block => block.component === "promo").active && socials.some(soc => soc.active)}
                                        setActive={(e) => setState({ ...blocks.find(block => block.component === "promo"), active: e.target.checked }, "blocks", blocks)}
                                        socialTypes={socialTypes}
                                        promo={promo}
                                        socials={socials}
                                        changeHandler={(value) => setState(value, "socials", socials, "fakeId")}
                                        setPromoText={(value) => setState(value, "promoText")}
                                        promoText={promoText}
                                    />
                                </div>
                            </div>
                            {
                                // Домен
                            }
                            <div className="admin__box" ref={fourthSectionRef}>
                                <div className="constructor__box">
                                    <div className="admin__calculator admin__section admin__section_md admin__section_pb-0">
                                        <div className="admin__settings-header">
                                            <h3 className="h3">Домен</h3>
                                            { // status === 0 ? <span className="admin__status admin__status_not-published">Не опубликована</span> :
                                                //    <span className="admin__status admin__status_published">Опубликована</span>}
                                                //{dateUpdate && dateUpdate !== "01.01.1970" && <span className="admin__last-update-date">Дата последнего обновления {dateUpdate}</span>
                                            }
                                        </div>

                                        <div className="admin__settings-body">

                                            <Domen
                                                isOldSubDomain={isOldSubDomain}
                                                domain={domain}
                                                subDomain={subDomain}
                                                domainMessage={domainMessage}
                                                changeDomain={changeDomain}
                                                {...propsCreator("domain", null, v => v.toLowerCase())}
                                                saveSite={saveSite}
                                                isToprealtors={isToprealtors}
                                                isSavingInProcess={isSavingInProcess}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>

                            {
                                // Аналитика
                            }
                            <div className="admin__box" ref={fifthSectionRef}>
                                <div className="constructor__box">
                                    <div className="admin__calculator admin__section admin__section_md admin__section_pb-0">
                                        <div className="admin__settings-header">
                                            <h3 className="h3">Аналитика</h3>
                                        </div>

                                        <div className="admin__settings-body">

                                            <Analytics
                                                yandexMetric={yandexMetric}
                                                setState={setState}
                                                propsCreator={propsCreator}
                                                selfDomain={selfDomain}
                                            />

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="admin__box" ref={seoBlockRef}>
                                <div className="constructor__box">
                                    <div className="admin__calculator admin__section admin__section_md admin__section_pb-0">
                                        <div className="admin__settings-header">
                                            <h3 className="h3">SEO</h3>
                                        </div>

                                        <div className="admin__settings-body">

                                            <Seo
                                                propsCreator={propsCreator}
                                                googleVerification={googleVerification}
                                                yandexVerification={yandexVerification}
                                            />

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="admin__box" ref={sixthSectionRef}>
                                <div className="constructor__box">
                                    <div className="admin__calculator admin__section admin__section_md admin__section_pb-0">
                                        <div className="admin__settings-header">
                                            <h3 className="h3">Другое</h3>
                                        </div>

                                        <div className="admin__settings-body">
                                            <div className="admin__input-block admin__input-block_sm">
                                                <label htmlFor="" className="admin__label">
                                                    Код виджета
                                                    <div className="info">
                                                        <svg className="icon icon_info">
                                                            <use xlinkHref="#info" />
                                                        </svg>
                                                        <div className="info__popup">
                                                            <p>Подключите внешние виджеты.</p>
                                                        </div>
                                                    </div>
                                                </label>
                                                <textarea className="admin__textarea" name="" id="" cols="30" rows="5" value={customJS} onChange={(e) => setState(e.target.value, "customJS")} ></textarea>
                                            </div>
                                        </div>

                                        <div className="admin__calculator admin__section admin__section_pb-0">
                                            <div className="admin__title">
                                                <label className="switch" htmlFor="multi-link">
                                                    <div className="switch__box">
                                                        <input className="switch__control" type="checkbox" id="multi-link"
                                                            checked={multiLink}
                                                            onChange={(e) => setState(e.target.checked, "multiLink")} />
                                                        <div className="switch__input" htmlFor="multi-link" />
                                                        <div className="switch__marker" />
                                                    </div>
                                                </label>
                                                <h5 className="admin__h5 h5">
                                                    Режим мульти-ссылки
                                                </h5>
                                                <div className="info">
                                                    <svg className="icon icon_info">
                                                        <use xlinkHref="#info" />
                                                    </svg>
                                                    <div className="info__popup">
                                                        <p> В режиме мульти-ссылки выключены все блоки кроме первого экрана, на котором будут отображены ваши контактные данные и информация о Вас</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                    <div className={`admin__menu${isScrollPanelVisible ? ` hidden` : ``}  ${isMobile ? 'is-mobile' : ''}`} hidden>
                        <div className="constructor__container">
                            <div className="admin__menu-content">

                                <div className="admin__menu-list-burger" onClick={() => setShowMenuList(!showMenuList)}>
                                    <svg className="icon icon_burger">
                                        <use xlinkHref="#burger"></use>
                                    </svg>
                                </div>

                                <div className={`admin__menu-list-shadow ${showMenuList ? 'is-active' : ''}`} onClick={() => setShowMenuList(false)}></div>
                                <div className={`admin__menu-list-wrap ${showMenuList ? 'is-active' : ''}`}>
                                    <ul className="admin__menu-list">
                                        <li className="admin__menu-item">
                                            <Link className="admin__menu-anchor" target="_blank" to={`/objects`}>Свои объекты</Link>
                                        </li>
                                        <li className="admin__menu-item" ref={firstMenuItem}><a href="#" className="admin__menu-anchor"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                onMenuBtnClick(firstSectionRef);
                                            }}>
                                            {isMobile ? 'о вас' : 'о вас / агентство'}</a></li>
                                        {isMobile &&
                                            <li className="admin__menu-item" ref={firstHalfMenuItem}><a href="#" className="admin__menu-anchor"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    onMenuBtnClick(firstHalfSectionRef);
                                                }}>
                                                агентство</a></li>
                                        }
                                        <li className="admin__menu-item" ref={secondMenuItem}><a href="#" className="admin__menu-anchor"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                onMenuBtnClick(secondSectionRef);
                                            }}>стиль оформления</a></li>
                                        <li className="admin__menu-item" ref={thirdMenuItem}><a href="#" className="admin__menu-anchor"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                onMenuBtnClick(thirdSectionRef);
                                            }}>контент</a></li>
                                        <li className="admin__menu-item" ref={fourthMenuItem}><a href="#" className="admin__menu-anchor"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                onMenuBtnClick(fourthSectionRef);
                                            }}>настройки</a></li>
                                    </ul>
                                </div>
                                {!isTablet &&
                                    <div className="admin__menu-btns">
                                        {/*
                            <button type="button" onClick={() => createPreview(props.state)} className="button admin__menu-btn admin__menu-btn_transparent">
                              {isDesktop &&
                                <span className="icon__wrap">
                                  <svg className="icon icon_watch">
                                    <use xlinkHref="#icon_watch"></use>
                                  </svg>
                                </span>
                              }
                              Предпросмотр
                            </button>
                          */}
                                        <button type="button" disabled={isSavingInProcess} onClick={() => saveSite(0)} className="button admin__menu-btn admin__menu-btn_transparent">
                                            {isDesktop &&
                                                <span className="icon__wrap">
                                                    <svg className="icon icon_document">
                                                        <use xlinkHref="#document"></use>
                                                    </svg>
                                                </span>
                                            }
                                            Сохранить
                                        </button>
                                        <button type="button" disabled={isSavingInProcess} onClick={() => saveSite(1)} className="button button_view_default admin__menu-btn">
                                            Опубликовать
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {isTablet &&
                        <div className={`for-mobile admin__menu${isScrollPanelVisible ? ` hidden` : ``}`} ref={submitButtonRef} hidden>
                            <div className="admin__menu-btns">
                                <button type="button" disabled={isSavingInProcess} onClick={() => saveSite(1)} className="button button_view_default admin__menu-btn">Опубликовать</button>
                            </div>
                        </div>
                    }


                </form>
            </div>
        </div>
    )
};

export default Edit
