import { createSelector } from "reselect";

const userFavoritesSelector = (state) => state.user.favorites;
const userCompareSelector = (state) => state.user.compare;
const userNotificationsSelector = (state) => state.user.notifications;
const userFiltersSelector = (state) => state.user.filters;

export const favoritesCount = createSelector(
  userFavoritesSelector,
  (favorites) => favorites.length
);

export const compareCount = createSelector(
  userCompareSelector,
  (compare) => compare.length
);

export const filtersCount = createSelector(
  userFiltersSelector,
  (filters) => filters.length
);

export const notificationsCount = createSelector(
  userNotificationsSelector,
  (notifications) => notifications.length
);

export const socials = [
  // { title: "ig", link: "https://www.instagram.com/panpartner/", icon: "ig" },
  { title: "tg", link: "https://tlgg.ru/panpartner", icon: "tg" },
  // { title: "fb", link: "https://www.facebook.com/PANpartnerspb", icon: "fb" },
  { title: "vk", link: "https://vk.com/panpartnerspb", icon: "vk" },
];
