import React, {useRef, useState} from 'react';
import './style.scss'
import useMedia from "../../hooks/useMedia";
import panschoolBottomLine from "../../assets/images/BookingNew/booking-slide3-bg.png";
import InputMask from "react-input-mask";
import SubmitButton from "../../components/SubmitButton";
import {Link} from "react-router-dom";
import useUniversalForm from "../../hooks/useUniversalForm";
import {programsList} from "../SchoolOnRoad/data";
import useApi from "../../hooks/useApi";
import {useSelector} from "react-redux";
import {errorToast, standartErrorToast, standartInfoToast, successToast} from "../../components/toasts";

const BookingNew = () => {

  const isTablet = useMedia('isTablet');
  const isSmallLaptop = useMedia('isSmallLaptop');
  const is950 = useMedia('is950');

  const handleScroll = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };




  const userInfo = useSelector(state => state.user?.info);
  const errors = false;
  const formRef = useRef();
  const city = 'spb';


  const [agree, setAgree] = useState(true);
  const { send, setValue, getValue,  clearForm } = useUniversalForm({
    formId: 60,
    defaultValues: {
      name: userInfo?.name ? `${userInfo.name} ${userInfo.lastName}` : "",
      phone: userInfo?.phone || "",
    },
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic",

  })

  const handleSend = (params) => {
    if (!agree) return errorToast("Необходимо согласие на обработку персональных данных")
    send().then(() => {
      successToast("Заявка отправлена");
      clearForm();
    }, standartErrorToast)
  }


  return (
    <div className="wrapper wrapper-lands booking-new">
      <div className="autopan-sec1">
        <p className="autopan-sec1__intensive">Для партнеров</p>
        <h1 className="autopan-sec1__title">Расширенное <br/>бронирование</h1>
        <p className="autopan-sec1__secondtitle">Закройте клиента на сделку с помощью<br/> эксперта ПАН Партнер</p>
        <button className="btn btn_secondary booking-new__sec1-btn h6" onClick={handleScroll}>Оставить заявку</button>
      </div>
      <div className="autopan-sec4 booking-new__slide2">
        <p className="autopan-sec4__title">У вас есть клиент, который готов приобрести квартиру в новостройке, и вы:</p>
        <ul className="autopan-sec4__list">
          <li>
            <div className="autopan-sec4__list-logo">
              1
            </div>
            <p className="autopan-sec4__list-title">Начинающий специалист или выпускник PAN School</p>
            <p className="autopan-sec4__list-text">Хотите избежать ошибок в работе с клиентом и нуждаетесь в профессиональном кураторе</p>
          </li>
          <li>
            <div className="autopan-sec4__list-logo">
              2
            </div>
            <p className="autopan-sec4__list-title">Занимаетесь продажами на вторичном рынке — и начали работать с «первичкой»</p>
            <p className="autopan-sec4__list-text">Еще не успели изучить рынок первичной недвижимости,
              но хотите сохранить клиента</p>
          </li>
          <li>
            <div className="autopan-sec4__list-logo">
              3
            </div>
            <p className="autopan-sec4__list-title">Агент из другого региона</p>
            <p className="autopan-sec4__list-text">Работаете с недвижимостью Санкт-Петербурга, но вам неудобно постоянно приезжать на каждый показ и встречу
              c клиентом</p>
          </li>
        </ul>

      </div>

      <div className="office-training">
        <div className="autopan-sec6 panschool-sec6" ref={formRef}>
        <p className="panschool-sec1__maintitle">Воспользуйтесь сервисом расширенного бронирования — и будьте уверены в результате!</p>

        <div className="panschool-sec6__form">



          <form action="#" className="sor__form">
            <div className="sor__form-box">

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    value={getValue("name")}
                    className={
                      "input__control" + (errors.name ? " input__error" : "")
                    }
                    placeholder="Ваше имя"
                    onChange={e => setValue(e.target.value, "name")}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                          {errors.name.name}
                        </span>
                  )}
                </label>
              </div>

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <InputMask
                    id="modal-reservation-phone"
                    className={
                      "input__control" + (errors.phone ? " input__error" : "")
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    placeholder="Ваш телефон"
                    value={getValue("phone")}
                    onChange={e => setValue(e.target.value, "phone")}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                          {errors.name.phone}
                        </span>
                  )}
                </label>
              </div>


              <SubmitButton type="button" className="btn_full button btn_secondary button_view_default" onClick={handleSend}>Оставить заявку</SubmitButton>


            </div>

            <div className="sor__form-footer">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box'
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    checked={agree}
                    onChange={e => setAgree(e.target.checked)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"

                >
                  Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>

      <div className="booking-new__sec4">
        <h1 className="autopan-sec1__title">Поможем вам закрыть клиента на сделку</h1>
        <p className="booking-new__sec4-text">
          Выберите вариант расширенного<br/> бронирования, который подходит вам
        </p>

        <div className="booking-new__sec4-variants">
          <div className="booking-new__sec4-var booking-new__sec4-var_1">

            <div>
              <p className="autopan-sec1__intensive">Вариант 1</p>
              <p className="booking-new__sec4-vartit">
                Продающий показ объекта
              </p>
              <ul className="booking-new__variants-list">
              <li>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
                Покажем объект клиенту
              </li>
              <li>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
                Подпишем договор через подписанта
              </li>
            </ul>
            </div>
            <div>
              <p className="booking-new__sec4-vartext">
                Услуга доступна для наших партнеров при проведении сделки через ПАН Партнер
              </p>
              <div className="booking-new__sec4-varprice">
                <span>Стоимость</span>10 000 руб.
              </div>
            </div>
          </div>
          <div className="booking-new__sec4-var booking-new__sec4-var_2">
            <div>
              <p className="autopan-sec1__intensive">Вариант 2</p>
              <p className="booking-new__sec4-vartit">
                Полное сопровождение сделки экспертом ПАН Партнер
              </p>
              <ul className="booking-new__variants-list">
              <li>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
                Выявим потребности и возможности клиента
              </li>
              <li>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
                Забронируем квартиру и подпишем договор
              </li>
              <li>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
                Подберем варианты по застройщикам Санкт-Петербурга и Москвы
              </li>
            </ul>
            </div>
            <div>
              <p className="booking-new__sec4-vartext">
                Услуга распространяется на клиентов, готовых приобрести квартиру — мы не берем в работу «холодные лиды». При передаче клиента вы заполняете анкету с описанием запроса клиента.              </p>
              <div className="booking-new__sec4-varprice">
                <span>Стоимость</span>50% комиссионного вознаграждения.
              </div>
            </div>
          </div>
        </div>

        <p className="autopan-sec4__title">
          Фиксируйте клиента через платформу ПАН Партнер и оставляйте заявку
          на расширенное бронирование
        </p>
        <p className="booking-new__sec4-text">
          Свяжемся с вами, чтобы обсудить детали
        </p>

        <div className="panschool-sec6__form">


          <form action="#" className="sor__form">
            <div className="sor__form-box">

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    value={getValue("name")}
                    className={
                      "input__control" + (errors.name ? " input__error" : "")
                    }
                    placeholder="Ваше имя"
                    onChange={e => setValue(e.target.value, "name")}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                          {errors.name.name}
                        </span>
                  )}
                </label>
              </div>

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <InputMask
                    id="modal-reservation-phone"
                    className={
                      "input__control" + (errors.phone ? " input__error" : "")
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    placeholder="Ваш телефон"
                    value={getValue("phone")}
                    onChange={e => setValue(e.target.value, "phone")}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                          {errors.name.phone}
                        </span>
                  )}
                </label>
              </div>


              <SubmitButton type="button" className="button btn_full button_view_default" onClick={handleSend}>Оставить заявку</SubmitButton>


            </div>

            <div className="sor__form-footer">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box'
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    checked={agree}
                    onChange={e => setAgree(e.target.checked)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"

                >
                  Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>


    </div>
  )
}

export default BookingNew
