import axios from 'axios';
const constants = require("./constants.js")

export default {
  setupInterceptors: (store, history) => {
    axios.interceptors.response.use(response => {
      if (response?.data?.data?.needReload) {
        window.location.reload(true)
      }
      if (response?.data?.data?.clearLS) {
        localStorage.clear()
      }
      if (response?.data?.data?.version && response?.data?.data?.version !== constants.version && window.location.host === "dev2.panpartner.ru") {
        window.location.reload(true)
      }
      return response;
    }, error => {
      if(!error.response && !!error.message){
        return Promise.reject(`${error.message}`);
      }
      if (!error.response && !error.message) {
     
        window.location.reload()
        return Promise.reject("Сервер не отвечает или отсутствует подключение к сети");
      }
      if (error?.response?.status === 401) {
        store.dispatch({ type: "SET_USER_STATE", payload: { isAuthorized: false } })
        history.push('/');
      }

      if (error?.response?.status === 404) {
        history.push('/not-found');
      }
      if (error?.response?.status === 403) {
        history.push('/denied');
      }
      
      return Promise.resolve(error?.response);
    });
  },
};
