import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import usePermission from "../../hooks/usePermission";
import mocData from "./mocData";
import SimilarCardsList from "./SimilarCardsList";
import DetailHeader from "../Public/markup/detail/DetailHeader";
import InfoCard from "../Public/markup/detail/InfoCard";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "../Public/markup/detail/Slider";
import ContactCard from "../core/ContactCard";
import SubmitButton from "../SubmitButton";
import { useModal } from "react-modal-hook";
import PanModal from "../core/PanModal";
import FixateClient from "../../pages/FixateClientSpecial";
import RecordViewing from "../RecordViewing";
import RecordViewingSuburban from "../RecordViewing/suburban";
import RecordClient from "../RecordViewing/suburban2";
import useApi from "../../hooks/useApi";
import { successToast } from "../toasts";
import { downloadLink, objectsArrayToFormData, toFormData } from "../../utils";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import { AddToCompilationIcon, ComissionIcon, FixIcon, HeartIcon, PdfIcon, ViewIcon } from "../Public/markup/icons";
import AddPresenatation from "../AddPresentation";
import AppPopover from "../core/Popover";
import Wrap from "../core/ModalWrap";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import SpecialForm from "../forms/Special";
import ViewApartment from "../forms/ViewApartment";


const data = mocData;
const photos = { ...data.block.photos }
const photosComplex = {
  complex: [...(photos.complex ? photos.complex : [])],
  ...(!!data.block.aeroPanorama ? { aeroPanorama: data.block.aeroPanorama } : [])
}
const DetailCardNew = props => {
  const isMobile = useMedia("isMobile");
  const {
    presentation,
    heightCell1,
    decoration,
    features,
    manager,
    floors,
    openSpecialModal,
    countBedrooms,
    heightCell2,
    commission,
    id,
    title,
    price,
    squareTotal,
    meterPrice,
    section,
    squareHouse,
    constructionPeriod,
    photos,
    planPhotos,
    decorationPhotos,
    livingWinter,
    semiObjects,
    hidePrice,
    objectInfo,
    priceInfo,
    dateUpdateInfo,
    detailText,
    inFavorite,
    toggleFavorite,
    otherProjects
  } = props
  priceInfo && console.log([priceInfo])
  const photosObject = {
    complex: photos,
    plan: planPhotos
  }

  const isTablet = useMedia('isTablet');
  const isDesktop = useMedia("isDesktop");
  const [numberOfSlides, setNumberOfSlides] = useState(0);
  const handleInit = (swiper) => {
    if (swiper) {
      setNumberOfSlides(swiper.slides.length);
    }
  };

  const [complexView, setComplexView] = useState('photos')

  const shoowMotivation = usePermission("motivation")

  // const callback = () => send(["panpartner:forms.ajax", "callback", {
  //   urgently: true,
  //   managerId: managerInfo?.id,
  //   name: `${userInfo?.name} ${userInfo?.lastName}`,
  //   type: objectType,
  //   objectId: id,
  //   city
  // }]).then(
  //   success => setCallbackStatus({ type: "success" }),
  //   err => setCallbackStatus({ type: "error", message: err[0]?.message })
  // )


  const [openFixModal, closeFixModal] = useModal(
    () => (
      <PanModal title={'Фиксация клиента'} closeModal={closeFixModal} isBig>
        <FixateClient redesign closeFixModal={closeFixModal} objectId={id} />
      </PanModal>
    ),
    [],
  );

  const [bookATourModal, closeBookATourModal] = useModal(
    () => (
      <PanModal title={'Запись на просмотр по проектам домов'} closeModal={closeBookATourModal} isBig>
        {/*<RecordViewingSuburban/>*/}
        <RecordClient redesign objectId={id} close={closeBookATourModal} />
      </PanModal>
    ),
    [],
  );


  const [specSpecModal, closeSpecModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeSpecModal} title="Запись на просмотр">{children}</MobileModal>
        : <Modal close={closeSpecModal} classes="modal_task">{children}</Modal>
      } >
        <SpecialForm close={() => {
          closeSpecModal()
        }}
          specialOfferId={id}
        // isShowRoom={isShowRoom} setIsShowRoom={setIsShowRoom}
        />
      </Wrap >
    ));

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: id, city: 'spb', type: 'project' }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })



  return (
    <>
      <div className="subuebans-object">

        <div className="sf__detail-head">
          <h1 className="h3-l sf__detail-title">{title}</h1>

          {commission && (
            <AppPopover content={commission} placement="right">
              <div className="sf-tooltip sf__commission">
                <ComissionIcon />
                Мотивация
              </div>
            </AppPopover>
          )}


        </div>

        <div className="sf__elems" style={{ gridTemplateColumns: 'repeat(5, min-content)' }}>

          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={() => toggleFavorite()}
          >
            <HeartIcon isActive={inFavorite} />
            {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
          </button>


          <span className="sf__elem-btn button link" onClick={bookATourModal}>
            <ViewIcon />
            Запись на просмотр
          </span>

          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={openFixModal}
          >
            <FixIcon />
            Зафиксировать клиента
          </button>

          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={openCompilationModal}
          >
            <AddToCompilationIcon />
            В подборку
          </button>

          <a href={getUrl(presentation?.src)}
            className="sf__elem-btn button link"
            target="_blank"
          >
            <PdfIcon />
            Скачать PDF
          </a>

        </div>


        <div className="sf__detail-row">
          <div className="sf__detail-col complex-custom-view">

            {!isTablet && !!photos?.length && complexView == 'photos' && (
              <div className="complex-custom-view__main-container">
                <Slider
                  items={photos}
                  defoultThumbs
                />
              </div>
            )}

            {!isTablet && !!planPhotos?.length && complexView == 'planPhotos' && (
              <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                <Slider
                  items={planPhotos}
                  defoultThumbs
                />
              </div>
            )}


            <ul className="complex-view-list">
              <Swiper
                spaceBetween={15}
                slidesPerView={5}
                modules={[Navigation]}
                navigation={isDesktop ? numberOfSlides > 5 : numberOfSlides > 6}
                onInit={handleInit}
                breakpoints={{
                  1599: {
                    slidesPerView: 6,
                  },
                }}
              >
                <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => { setComplexView('photos') }}>
                    <img src={getUrl(photos?.[0].src)} alt="" />
                    <span>Фотографии</span>
                  </li>
                </SwiperSlide>
                {!!planPhotos && <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'planPhotos' ? 'active' : ''}`} onClick={() => { setComplexView('planPhotos') }}>
                    <img src={getUrl(planPhotos?.[0].src)} alt="" />
                    <span>Планировки</span>
                  </li> </SwiperSlide>}

              </Swiper>
            </ul>

            {
              detailText && detailText !== '' &&
              <div className="special__about">
                <h3 className="h3">О проекте</h3>
                <p className="special__about-text">
                  {detailText}
                </p>
              </div>
            }


          </div>
          <div className="sf__detail-col">
            <InfoCard items={objectInfo} topDate={dateUpdateInfo.title + ' ' + dateUpdateInfo.value} cols={priceInfo} />

            <section className="sf__section">
              {presentation?.src &&
                <a
                  href={getUrl(presentation.src)}
                  type="application/octet-stream"
                  target="_blank"
                  // onClick={() => openSpecialModal(title, manager)}
                  className="button button_view_default btn h6 btn_primary btn_full "
                  download
                >
                  Скачать презентацию
                </a>
              }
              <Link
                className="button btn h6 btn_line btn_full "
                onClick={specSpecModal}
              >
                Оставить заявку
              </Link>
            </section>

            {manager &&
              <ContactCard
                name={manager?.name + ' ' + manager?.lastName}
                phone={Array.isArray(manager?.phone) ? manager.phone[0] : manager?.phone}
                telegram={manager?.telegram !== '' ? manager?.telegram : false}
                logo
                forAptModal
                photo={manager?.photo}
                post={manager?.post}
                email={manager?.email}
              />
            }

            {/*<SubmitButton*/}
            {/*  type="button"*/}
            {/*  className="btn h6 btn_line btn_full"*/}
            {/*  // onClick={callback}*/}
            {/*  // isLoading={isLoading || isSending}*/}
            {/*>*/}
            {/*  Перезвоните мне*/}
            {/*</SubmitButton>*/}


            {/*{callbackStatus?.type === "success" && <NewAlert*/}
            {/*  title="Заявка отправлена"*/}
            {/*  text={`Ожидайте звонок. ${managerInfo?.name ? managerInfo?.name + " скоро свяжется с Вами." : "Мы свяжемся с Вами в рабочее время."}`}*/}
            {/*  bgColor="#DFF5E0"*/}
            {/*  iconColor="#2CBA2E"*/}
            {/*/>}*/}
            {/*{callbackStatus?.type === "error" && <NewAlert*/}
            {/*  title="Ошибка"*/}
            {/*  text={callbackStatus?.message}*/}
            {/*  bgColor="#FFEAE9"*/}
            {/*  iconColor="#FF2D23"*/}
            {/*/>}*/}
            {/*<section className="sf__section">*/}
            {/*  {!(callbackStatus?.type === "success") && <SubmitButton*/}
            {/*    type="button"*/}
            {/*    className="btn h6 btn_line btn_full"*/}
            {/*    onClick={callback}*/}
            {/*    isLoading={isSending}*/}
            {/*  >*/}
            {/*    Перезвоните мне*/}
            {/*  </SubmitButton>}*/}

            {/*</section>*/}

          </div>
        </div>

      </div>

      <article className="special__detail-card">


        <div className="special__detail-info">


        </div>


      </article>
      {semiObjects.length > 0 && (
          <div className="sf__detail-row sf__detail-row_full">
            <h3 className="h3 tab-title" style={{marginBottom: 20, marginTop: -50}}>Похожие по цене объекты</h3>
            <SimilarCardsList redesign hideTitleNewVersion hidePrice semiObjects={semiObjects}/>
          </div>
        )}
    </>
  );
};

export default DetailCardNew;
