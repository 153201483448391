
import { useSelector } from 'react-redux';

const useElement = (element) => {
    const { current } = useSelector(state => ({
        ...state.refs[element],
    }));
    return current
};

export default useElement;
