import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from '../Preloader';
import './index.scss';
import useFilter from '../../hooks/useFilter';
import { useEffect } from 'react';
import SuburbanContentNew from './SuburbanContentNew';
import { getSuburbanFilterConfigNew } from './Utils';

const SuburbanContentContainerNew = memo(({ method, isActive, tab = [], card, externalSorting }) => {
  const { villageId, city, savedFilterXmlId } = useParams()

  const contentProps = useFilter(getSuburbanFilterConfigNew({
    method, city, villageId, tab, cottageSettlement: villageId, savedFilterXmlId,
    defaultParams: {
      "filter[cottageSettlement]": villageId,
      "filter[type]": tab.typeId
    },
    newParams: {
      sortEnabled: true,
      shadowFilters: ['villageId', 'status', "cottageSettlement", "type", "realtyType"]
    }
  }));

  const filterBadges = contentProps.createBadges(contentProps.filter, ['villageId', 'status', "cottageSettlement", "type", "realtyType"], {}, { [tab.searchFilter]: tab.searchPlaceholder })
  const { isLoading, data: { items }, setLoadCondition } = contentProps;

  useEffect(() => {
    if (items || !isActive) return;
    setLoadCondition(true)
  }, [isActive, items])

  if (!isActive) return null
  if (isLoading) return <Preloader loading />;
  return <SuburbanContentNew
    villageId={villageId}
    // cottageSettlement={villageId}
    city={city}
    {...contentProps}
    filterBadges={filterBadges}
    tab={tab}
    card={card}
    externalSorting={({sorter})=>contentProps.handleSort(null, {value: sorter?.field, by: sorter?.order?.replace?.("end", "")})}
  />
});

export default SuburbanContentContainerNew;
