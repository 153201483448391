import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { searchRuSymbols } from '../../utils';
import './search-input.scss';

const SearchInput = ({ changeHandler = () => { }, data = [], wrapperClassName = "modal-form__item", label, searchKey = "title", placeholder, isRequired, value }) => {

    const [searchResult, setSearchResult] = useState([])
    const [inputValue, setInputValue] = useState("")

    const handler = (value) => {
        if (value.replace(/ /g, "")) {
            const result = data.filter(
                item =>
                    item?.[searchKey]?.
                        toLowerCase?.()?.
                        includes(searchRuSymbols(value.toLowerCase())) ||
                    item?.[searchKey]?.
                        toLowerCase?.()?.
                        includes(value.toLowerCase()) ||
                    item.aliases?.some?.(alias=>alias.toLowerCase?.()?.includes(value.toLowerCase()))

            )
            setSearchResult(result)
        } else {
            setSearchResult([])
        }
        setInputValue(value)
        changeHandler({ [searchKey]: value })
    }

    const clickHandler = (result) => {
        changeHandler(result)
        setInputValue(result[searchKey])
        setSearchResult([])
    }

    return (
        <div className={wrapperClassName}>
            <label className="input input_width_available input_type_form">
                <span className="input__label">{label} {isRequired && <span className="is-red-text sell__required">*</span>}</span>
                <input className="input__control" onChange={(e) => handler(e.target.value)} value={value} placeholder={placeholder} />
                {searchResult?.length > 0 &&
                    <SimpleBar className="search-result">
                        {searchResult.map(result => {
                            return <li key={`search-result-${result[searchKey]}`} className="search-result-option" onClick={() => clickHandler(result)}>{result[searchKey]}</li>
                        })}
                    </SimpleBar>
                }
            </label>
        </div>
    )
}
export default SearchInput