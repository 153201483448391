import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { checkDomain } from "../../../actions/constructor";
import SubmitButton from '../../../components/SubmitButton';
import { errorToast, standartErrorToast, successToast } from "../../toasts";
const Domen = (props) => {
  const {isOldSubDomain, domain, subDomain, domainMessage, changeDomain, onChange, value, saveSite, isToprealtors, isSavingInProcess} = props;
  const [activeTab, setActiveTab] = useState(1);
   const [isCheckingInProcess, setisCheckingInProcess] = useState(false)
  const domainCheck = () => {
    setisCheckingInProcess(true)
    checkDomain({domain: value}).then(resp=>{
      resp.domainAvailable?
    successToast(resp.message): errorToast(resp.message)
    }, standartErrorToast).finally(()=>setisCheckingInProcess(false))
  }
  const save = () => saveSite(0).then(resp=>successToast("Домен сохранен"))
  
  return (
    <>


    <div className="admin__input-block admin__list-tabs domens">
      <div className="admin__list-container admin__list-container_sm">
        <div className={`admin__list-tab${activeTab == 1 ? ` active` : ``}`} onClick={() => setActiveTab(1)}>
          <span>Домен {isToprealtors? "Top:Realtors": "ПАН"}</span>
        </div>
        <div className={`admin__list-tab${activeTab == 2 ? ` active` : ``}`} onClick={() => setActiveTab(2)}>
          <span>Свой домен</span>
        </div>

      </div>

      <div className={`admin__list-box${activeTab == 1 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt">
          <div className="admin__input-block">
              <label htmlFor="" className="admin__label">
                  Название страницы
                  <div className="info">
                      <svg className="icon icon_info">
                          <use xlinkHref="#info" />
                      </svg>
                      <div className="info__popup">
                          <p>Требования к поддомену:
                              <ul>
                                  <li>От 4 до 30 символов.</li>
                                  <li>Только буквы латинского алфавита, цифры, знаки дефиса.</li>
                                  <li>Начинается и заканчивается буквой или цифрой.</li>
                                  <li>Не содержит повторяющихся дефисов.</li>
                              </ul>
                          </p>
                      </div>
                  </div>
              </label>
              <p className="admin__domen">
                  <div className="admin__domen-input">
                      <span>https://</span>
                      <DebounceInput
                          className="admin__input"
                          placeholder="латинские буквы, цифры и знак дефиса"
                          value={domain}
                          minLength={2}
                          debounceTimeout={500}
                          onChange={(e) => changeDomain(e.target.value.toLowerCase())}
                      />
                      <span>.toprealtors.ru</span>
                  </div>
                  {!isOldSubDomain && domain && domain.length > 1 && (!domainMessage.isExist || domain === subDomain ? <p className="admin__domen-result admin__domen-result_enabled">Данный домен доступен для регистрации</p> :
                      <p className="admin__domen-result admin__domen-result_disabled">{domainMessage.message}</p>)}

              </p>
          </div>
        </div>
      </div>

      <div className={`admin__list-box${activeTab == 2 ? `` : ` hidden`}`}>
        <section className="domen admin__section admin__section_sm admin__section_pb-20">

          <h5 className="h5 domen__title">Используйте зарегистрированный домен на вашем сайте</h5>

          <div className="admin__input-block">
            <label htmlFor="domen-name" class="admin__label">Имя домена</label>
            <input type="text" class="admin__input" name="domen-name" id="domen-name" onChange={onChange} placeholder="Имя домена" value={value} />
          </div>

          <p className="domen__text text">Для подключаемого собственного домена в DNS используйте следующие записи:</p>

          <table className="domen__table">
            <tr>
              <th>Хост</th>
              <th>Тип записи</th>
              <th>Значение записи</th>
            </tr>

            <tr>
              <td className="bold">@</td>
              <td>A</td>
              <td>193.232.158.191</td>
            </tr>
          </table>

          <div className="text domen__sign">
            <span className="info">
              <svg className="icon icon_info">
                <use xlinkHref="#info" />
              </svg>
            </span>

            <p>
              Внимание! Для корректно подключаемого домена необходимо удалить такие записи,<br />как А-записи и ААА-записи
              <a className="link link_color_brand-3" href="https://teletype.in/@toprealtors/Almtr6HXSrU" target="_blank" rel="noopener noreferrer">Подробнее</a>
            </p>
          </div>

          <div className="domens__btns">
          <SubmitButton
              className="domen__save-btn button button_view_default admin__menu-btn"
              isLoading={isSavingInProcess || isCheckingInProcess}
              type="button"
              onClick={save}
            >
            <span>Сохранить</span>
            </SubmitButton>
            <SubmitButton
              className="domen__save-btn button admin__menu-btn admin__menu-btn_transparent"
              isLoading={isSavingInProcess || isCheckingInProcess}
              type="button"
              onClick={domainCheck}
            >
               <span>Проверить подключение</span>
           
            </SubmitButton>
            {/* <button type="button" className="domen__save-btn button button_view_default admin__menu-btn" disabled={isSavingInProcess}  onClick={save}>Сохранить</button>
            <button type="button" className="domen__save-btn button admin__menu-btn admin__menu-btn_transparent" disabled={isSavingInProcess}  onClick={domainCheck}>Проверить подключение</button> */}
          </div>

        </section>
      </div>
    </div>



    </>
  );
};

export default Domen;
