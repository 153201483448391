
import React, { memo } from 'react';
import newsSvg from '../../assets/images/news.svg';

const Notification = memo(props => {
  return (
    <div
      className={`notification notification_webinar`}>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <div className="notification__container">
            {
              props.type === 'live' && 
              <div className="go-live">Live</div>
            }

            {
              props.type === 'news' &&
                <object data={newsSvg} type="image/svg+xml" width="57" height="22"></object>
            }

            <p>{props.text}</p>
          </div>
        </a>
    </div>
  );
});

export default Notification;
