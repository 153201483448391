import React, { memo, useEffect, useMemo } from "react";
import SelectFilter from "../../components/FilterRealty/SelectFilter";
import Preloader from "../../components/Preloader";
import SubmitButton from "../../components/SubmitButton";
import NewAlert from "../../components/core/NewAlert";
import { errorToast, standartErrorToast, successToast } from "../../components/toasts";
import useUniversalForm from "../../hooks/useUniversalForm";
import './index.scss';

import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Button from "../../components/core/Button";
import Modal from "../../components/core/Modal";
import PanModal from "../../components/core/PanModal";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { formatDate, makeComa } from "../../utils";
import AdvantagesFixation from "./AdvantagesFixation";
import { AddNewClientButton, Client, clientToUniForm, emptyUniFormClient, filterAndMap, uniFormToClient } from "./Components";


const FixationFormNew = memo(props => {
  const { userInfo, openBuilderModal, showContent, formId, city, loadCondition } = props

  const initialState = {
    selectedItems: [],
    selectedItemsBlocks: [],
    successMessage: "",
    components: [0],
    addedClients: [],
  };

  const { state, setValue, setValues } = useUniversalReducer(initialState);

  const { selectedItems, selectedItemsBlocks, successMessage, components, addedClients } = state;

  const initialObjectKey = useMemo(() => (city === "msk" ? "blocks" : "builders"), [city]);
  const initialObjectCodeName = useMemo(() => (city === "msk" ? "block" : "builders"), [city]);

  const queryParams = new URLSearchParams(window.location.search);
  const builderId = queryParams.get('builderId');
  const blockId = queryParams.get('blockId');
  const cityParam = queryParams.get('city');

  useEffect(() => {
    if (builderId && cityParam === 'spb') {
      setValue("selectedItems", [Number(builderId)]);
    }
    if (blockId && cityParam === 'msk') {
      setValue("selectedItems", [Number(blockId)]);
    }
  }, [builderId, blockId, cityParam])


  const requiredFields = {
    clientLastName: "Введите фамилию клиента",
    clientName: "Введите имя клиента",
    clientPhone: "Введите телефон клиента",
    ...(selectedItems?.length ? [] : { selected: `Выберите ${city === "msk" ? "ЖК" : "застройщика"}` })
  }
  const {
    form,
    getValue,
    isLoading,
    isSending,
    response: {
      clients,
      fieldsDescription,
      ...resp
    },
    setValue: setFormValue,
    setCheckboxValue,
    setValues: setFormValues,
    send,
    convertArrayToUniformData,
    errors,
    forcedSetValue,
    setResponse
  } = useUniversalForm({
    formId,
    city,
    defaultValues: {
      name: `${userInfo.name} ${userInfo.lastName}`,
      phone: userInfo.phone,

    },
    defaultCheckboxes: ["personal_data", "familiar_with_advertising", "familiar_with_builder"],
  }, loadCondition)
  const selectClient = id => {
    const client = clients.find(client => client.id === id)
    setFormValues(id ? clientToUniForm(client) : emptyUniFormClient)
  }

  const normalizedClients = useMemo(() => {
    if (!clients) return [];
    const tmpClients = [...clients]
    return tmpClients.map(cl => ({ ...cl, title: `${cl.name} ${cl.lastName}` }))
  }, [clients])



  const [openPassportModal, hidePassportModal] = useModal(() => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick
      close={hidePassportModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#2C2E3E" />
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round" />
          <circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E" />
        </svg>
        Застройщики для которых важен паспорт:
      </div>
      <SimpleBar className="search-result"
        style={{ maxHeight: 200, display: "block", position: 'relative', boxShadow: 'none' }}>
        {resp.builders && filterAndMap(resp.builders, (builder) => {
          if (!builder.passportFix) return
          return (
            <div style={{ marginTop: 5, marginBottom: 5 }}>{builder.name}</div>
          )
        })}
      </SimpleBar>

      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={hidePassportModal}>Понятно</Button>
      </div>
    </Modal>
  ), [resp.builders]);

  const submit = () => {

    const multipleClients = {}
    for (const client of addedClients) {
      Object.entries(client).forEach(([key, value]) => {
        multipleClients[key] = Array.isArray(multipleClients[key]) ? [...multipleClients[key], value] : [value];
      })
    }
    const additionalData = {
      ...convertArrayToUniformData(initialObjectCodeName, selectedItems),
      ...Object.entries(multipleClients).reduce((acc, [key, value]) => ({ ...acc, ...convertArrayToUniformData(key, value) }), {})
    }

    send(
      getValue("clientBirthday") ? { clientBirthday: formatDate(getValue("clientBirthday")) } : false,
      additionalData,
      // requiredFields
    ).then(resp => {
      setValue("successMessage", resp.message);
      successToast(resp.message);
      setValues({ selectedItems: [], successMessage: "" });
      setFormValues({ ...emptyUniFormClient, comment: "" })
      setTimeout(() => setValue("successMessage", ""), 5000)
    }, standartErrorToast)
  }

  const [openModal, closeModal] = useModal(() => (
    <PanModal title={'Преимущества фиксации'} closeModal={closeModal}>
      <AdvantagesFixation />
    </PanModal>
  ), []);


  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
      sanitizedData = sanitizedData.substring(2);
    }
    setFormValue(sanitizedData, "clientPhone")
  };

  const addClient = () => {
    setValue("addedClients", prev => (prev.length < 3 ? [...prev, { ...emptyUniFormClient, id: "", timeStamp: Date.now() }] : prev));
  };

  const deleteClient = (index, client) => {
    setValue("addedClients", prev => (prev.filter((_, i) => i !== index)));
  };


  const handleSaveClient = (client, index) => {
    if (client.clientId === "0") {

      //  валидация при сохранении клиента, точнее просто проверка на наличие
      const errors = []
      for (const key in client) {
        if (!client[key] && key !== 'clientBirthday') {
          errors.push(requiredFields[key])
        }
      }

      if (errors.length) return errorToast(errors.map((v, i, a) => makeComa(v, i, a, true, " \n")))

      // вот тут наверное надо запрос на создание клиента 

      setResponse(prev => ({ ...prev, clients: [...prev.clients, uniFormToClient(client)] }))
    }
    setValue("addedClients", prev => {
      const tmp = [...prev];
      tmp[index] = client;
      return tmp;
    })
  }

  if (isLoading && !isSending && loadCondition) return <Preloader loading={true} />;

  if (!showContent) return null;

  return <div className="fixations__redesign">

    <div className="fixations__redesign-top">
      {!!addedClients?.length && addedClients.map((client, index) => (

        <Client
          addedClient={client}
          key={client.timeStamp}
          index={index}
          maxComponents={addedClients.length}
          onDelete={deleteClient}
          normalizedClients={normalizedClients}
          getValue={getValue}
          selectClient={selectClient}
          errors={errors}
          setValue={setFormValue}
          fieldsDescription={fieldsDescription}
          openBuilderModal={openBuilderModal}
          handlePaste={handlePaste}
          openPassportModal={openPassportModal}
          forcedSetValue={forcedSetValue}
          setValues={setFormValues}
          saveClient={handleSaveClient}
        />
      ))}
      {addedClients?.length < 3 && <AddNewClientButton handleAddClient={addClient} />}
    </div>

    <div className="fixations__redesign-bottom">
      <div className="fixations__redesign-part fixations__redesign-part_full">
        <p className="h6">Данные фиксации</p>
        <div className="fixations__redesign-bottomcontent">
          <div className="fixations__redesign-fullcontent">

            {/*<div className="hp__form-item">*/}
            {/*    <label*/}
            {/*      className="input input_width_available input_type_form"*/}
            {/*    >*/}
            {/*        <Dropdown*/}
            {/*          wrapperClass="custom-select_sf-filter custom-select_fixations"*/}
            {/*          withSearch={normalizedClients?.length > 4}*/}
            {/*          options={normalizedClients}*/}
            {/*          placeholder="Выберите клиента"*/}
            {/*          value={getValue("clientId")}*/}
            {/*          onChange={selectClient}*/}
            {/*          searchPlaceholder="Введите ФИО клиента"*/}
            {/*        />*/}
            {/*    </label>*/}
            {/*</div>*/}

            <div className="hp__form-item">

              <label
                className="input input_width_available input_type_form"
              >
                <SelectFilter
                  items={resp[initialObjectKey]}
                  isAutocomplete
                  filterValues={selectedItems}
                  onChange={(val) => setValue("selectedItems", val)}
                  data={{
                    title: city === "msk" ? "Выберите ЖК" : "Выберите застройщика",
                    identity: initialObjectKey,
                    autocompleteTitle: city === "msk" ? "Введите название ЖК" : "Введите название застройщика",
                  }}
                  titleKey="name"

                  wrapperClass={`select-filter-fix custom-select_sf-filter custom-select_sf-filter-fixations${!selectedItems.length && !!Object.entries(errors).length ? " drop__error" : ""}`}
                  disabledIds={selectedItems.length > 14 && city !== "msk" ? filterAndMap(resp[initialObjectKey], item => selectedItems.includes(item.id) ? false : item.id) : []}
                />
              </label>
              {!selectedItems.length && !!Object.entries(errors).length && (
                <span className="input__error-label">
                  {city === "msk" ? "Выберите ЖК" : "Выберите застройщика"}
                </span>
              )}
              <div>
                {selectedItems.map(id => {
                  const item = resp[initialObjectKey]?.find?.(item => item.id === id)
                  const fixCondition = city === "msk" ? false : item?.fixConditions;
                  // console.log(id)
                  // console.log(resp['blocks'][11569])
                  return <div className="hp__form-item hp__form-item_half" style={{ marginTop: 10 }}>
                    <div className="hp__form-container">
                      <div className="hp__fixhalf-block">
                        <span>{item?.name}</span>
                        {fixCondition &&
                          <span className="rules-text" onClick={() => openBuilderModal({
                            title: `Условия фиксации ${item.name}`,
                            text: fixCondition
                          })}>
                            Условия фиксации
                          </span>}
                      </div>
                    </div>
                    <div className="hp__form-container">
                      {resp['blocks'][id] ?
                        <SelectFilter
                          items={[resp['blocks'][id]]}
                          isAutocomplete
                          filterValues={selectedItemsBlocks}
                          onChange={(val) => {
                            setValue("selectedItemsBlocks", val)
                          }}
                          data={{
                            title: "Выберите ЖК",
                            identity: 'blocks',
                            autocompleteTitle: "Введите название ЖК",
                          }}
                          titleKey="name"
                          wrapperClass={`select-filter-fix custom-select_sf-filter custom-select_sf-filter-fixations${!selectedItems.length && !!Object.entries(errors).length ? " drop__error" : ""}`}
                          disabledIds={selectedItems.length > 14 && city !== "msk" ? filterAndMap(resp[initialObjectKey], item => selectedItems.includes(item.id) ? false : item.id) : []}
                        /> :
                        <div className="hp__form-item">
                          <label
                            className="input input_width_available input_type_form"
                          >
                            <input
                              className={`input__control`}
                              value="Невозможно выбрать ЖК"
                              disabled={true}
                            />
                          </label>
                        </div>
                      }

                    </div>
                    <div className="hp__form-container hp__form-container_close"
                      onClick={() => setValue("selectedItems", prev => prev.filter(selectedId => selectedId !== id))}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.17163 8.82837L8.82849 3.17151" stroke="#8F95AC" stroke-linecap="round" />
                        <path d="M3.17163 3.17163L8.82848 8.82849" stroke="#8F95AC" stroke-linecap="round" />
                      </svg>
                    </div>

                  </div>
                })}
              </div>
            </div>

          </div>
          <div className="fixations__redesign-fullcontent">
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <textarea
                  style={{ minHeight: 106 }}
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  placeholder="Укажите дополнительную информацию"
                  value={getValue("comment")}
                  onChange={e => setFormValue(e.target.value, "comment")}
                />
              </label>
            </div>
            <NewAlert
              title="Преимущества фиксации"
              text="Зачем фиксировать клиента "
              link=" читайте здесь."
              bgColor="#f6f7f8"
              iconColor="#2CBA2E"
              linkColor="#2CBA2E"
              openAppModal
              customModalLink={openModal}
            />

          </div>
        </div>

      </div>
    </div>

    <div className="fixations__redesign-send">
      <div className="fixations__redesign-sendcontent">
        <div className="checkbox checkbox_view_red fixations__checkboxs">
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="data-id"
              name="remember"
              checked={!!getValue("personal_data", true)}
              onChange={e => setCheckboxValue(e, "personal_data")}

            />
            <label className="checkbox__input" htmlFor="data-id" />
            <div className="checkbox__marker" />
          </div>
          <label className="checkbox__label" htmlFor="data-id" style={{ marginBottom: -3 }}>
            Отправляя заявку вы соглашаетесь на обработку
            <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link link-red"> персональных
              данных</Link>
          </label>
        </div>
        <div className="checkbox checkbox_view_red fixations__checkboxs" style={{ marginTop: 5 }}>
          <div className="checkbox__box">
            <input
              className="checkbox__control"
              type="checkbox"
              id="data-adv"
              name="remember"
              checked={!!getValue("familiar_with_advertising", true)}
              onChange={e => setCheckboxValue(e, "familiar_with_advertising")}

            />
            <label className="checkbox__input" htmlFor="data-adv" />
            <div className="checkbox__marker" />
          </div>
          <label className="checkbox__label" htmlFor="data-adv">
            Ознакомлен (-а) с регламентом по рекламе
          </label>
        </div>
      </div>
      <div className="fixations__redesign-sendcontent">
        <SubmitButton
          className="button button_view_default btn_full"
          type="button"
          isLoading={isSending}
          onClick={submit}
        >
          <span>Отправить заявку</span>
        </SubmitButton>
      </div>
    </div>


  </div>
})
export default FixationFormNew;