import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DropItem from './DropItem';

const TopMenuItem = (props) => {
  const { className, title, link, submenu, modal, showModal } = props;

  const itemClass = classNames('header__top-menu-item', className);

  return (
    <li className={itemClass}>
      {modal ? (
        <div
          className="header__top-menu-link"
          style={{ cursor: 'pointer' }}
          onClick={() => showModal(modal)}
        >
          {title}
        </div>
      ) : submenu ? (
        <DropItem title={title} menu={submenu} />
      ) : (
        <Link to={link} className="header__top-menu-link">
          {title}
        </Link>
      )}
    </li>
  );
};

TopMenuItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.array,
  submenu: PropTypes.array,
};

const TopMenu = (props) => {
  const { menu, showModal } = props;

  return (
    <ul className="header__top-menu">
      {menu.map((item, index) => (
        <TopMenuItem {...item} key={index} showModal={showModal} />
      ))}
    </ul>
  );
};

TopMenu.propTypes = {
  menu: PropTypes.array,
};

export default TopMenu;
