export const getSelectPropsCreator = ({ filterData, filter = {}, exclude = {}, setFilterAndExclude, clearByIdent,  ...rest }) => ident => ({
    ...rest,
    items: filterData[ident]?.items ?? filterData[ident] ?? [],
    isAutocomplete: filterData[ident]?.length > 5,
    filterValues: filter[ident],
    excludeValues: exclude[ident],
    wrapperClass: "sf__filter-item",
    onChange: (filter, exclude) => setFilterAndExclude({ filter, exclude }, ident),
    clearFilterOnly: () => clearByIdent(ident),
    clearExcludeOnly: () => clearByIdent(ident, "exclude"),
    autoPosition: true
})