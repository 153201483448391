import React, {useEffect, useRef, useState} from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import { getUrl } from "../../../api";
import { ReactComponent as SavedFilterIcon } from "../../../assets/images/icons/SaveFilter.svg";
import useApi from "../../../hooks/useApi";
import { extractPrimaryMapItems } from "../../../hooks/useFilter/filterUtils";
import useMedia from "../../../hooks/useMedia";
import { getSuffix, media } from "../../../utils";
import DraggableZone from "../../FilterRealty/Draggable";
import SmartFilter from "../../FilterRealty/SmartFilter";
import Preloader from "../../Preloader";
import RecentlyCard from "../../RecentlyCard";
import SendFilter from "../../SavedFilters/SendFilter";
import SubmitButton from "../../SubmitButton";
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";
import Wrap from "../../core/ModalWrap";
import CardSkeleton from "./CardSkeleton";
import CardsList from "./CardsList";
import EstateCardContainer from "./Containers/EstateCardContainer";
import MainCardContainer from "./Containers/MainCardContainer";
import FilterToggleBtn from "./FilterToggleBtn";
import RealtyMap from "./Map";
import MapBlock from "./MapBlock";
import MapBtn from "./MapBtn";
import MapFilters from "./MapFilters/Index";
import PrimaryBottomFilters from "./PrimaryBottomFilters";
import PrimaryMapsFilters from "./PrimaryMapsFilters";
import PrimaryTopFilters from "./PrimaryTopFilters";
import TopBar from "./TopBar";
import { FilterSearch } from "../../FilterRealty/FilterSearch";
import {TgDubaiIcon} from "./icons";


const SfButton = ({ onClick }) => <div className="villages-save-filter" onClick={onClick}>
  <SavedFilterIcon />
  <span>Сохранить фильтр</span>
</div>
const FilterSubmitButton = ({ onClick, isLoading, count }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count?.countBlocks}
    >
      {`${count?.countBlocks ?? 0} объект${getSuffix(count?.countBlocks ?? 0)}`}
    </SubmitButton>
  );
};

const MainFilter = props => {

  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');

  const [showFilter, toggleFilter] = useState(false);

  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      filter: filterData
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    showSkeleton,
    infiniteRef,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,
    clearFilter,
    clearExclude,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    clearByIdent,
    favoritesProps,
    get,
    city,
  } = props

  // const ref = useRef(null);
  // const isVisible = useRef(null);
  //
  // useEffect(() => {
  //   isVisible.current = useViewportSpy(ref.current);
  // }, []);
  //
  // useEffect(() => {
  //   const observedElement = ref.current;
  //   if (observedElement && isVisible.current) {
  //     isVisible.current.observe(observedElement);
  //   }
  //   return () => {
  //     if (observedElement && isVisible.current) {
  //       isVisible.current.unobserve(observedElement);
  //     }
  //   };
  // }, []);



  const { data: { viewedObjects } } = useApi({
    payload: ["panpartner:viewed.objects.ajax", "getItems", { limit: 3 }],
    loadCondition: () => !isLoading
  }, [isLoading])

  const { data: { topStarts }, toggleFavorite, get: _topStartsGet } = useApi({
    payload: ["panpartner:block.filter.ajax", "getTopStarts", { city, }],
    favoritesParams: {
      city,
      type: 'block',
    },
    favoritePath: 'blocks.favorite',
    loadCondition: () => !isLoading
  }, [isLoading])

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр"
          classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      }>
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="primary"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap>
    ),
    [filter, exclude, filterData],
  );

  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);
  const saveToLocalSotage = id => localStorage.setItem("blocksFilter", JSON.stringify({ [id]: filter }))

  if (isLoading) return <Preloader loading={true} />;

  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
            // [59.9342802, 30.3350986]
          }
          {...mapState}
          items={mapItems?.blocks}
          extractFeatures={extractPrimaryMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}

        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems?.blocks
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={EstateCardContainer}
          getLink={(item) => `/realty/${city}/complex/${item.id}`}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={PrimaryMapsFilters}
          count={`${count.countBlocks} объект${getSuffix(count.countBlocks)}`}
          onSumbit={submitMap}
          disableSubmit={!count.countBlocks}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          isShowFilter
          onClick={saveToLocalSotage}
          dubai
          {...favoritesProps}
          get={get}
          wrapperClass={"sf__filter-item"}
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      <h3 className="h3 sf__h1 with-tg-link">
        <p>Новостройки {city === 'spb' ? 'Санкт-Петербурга' : city === 'msk' && 'Москвы'}</p>
        {city === 'spb' &&
          <a href={'https://t.me/panpartner'} target="_blank" className="with-tg-link__link">
            Telegram-канал ПАН Партнер
            <TgDubaiIcon/>
          </a>
        }


      </h3>
      {
        !isLoading &&
        <>
          <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_primary`}>
            <FilterSearch
              filterData={filterData}
              config={{
                fields: {
                  blocks: {
                    addLink: ({ xmlId, id }) => `/realty/${city}/complex/${city === "dxb" ? xmlId : id}${window.location.pathname.includes("commercial") ? "?type=commercial" : ""}`
                  },
                  villages: {
                    addLink: ({ id }) => `/suburban/spb/${id}`
                  },
                  builders: {},
                  districts: {},
                  microDistricts: {},
                  subways: {},

                }
              }}

              placeholder="ЖК, район, метро, застройщик"
              toggleFilter={() => toggleFilter(prev=>!prev)}
              filterValues={filter}
              excludeValues={exclude}
              handleFilter={setFilterAndExclude}
              submit={submit}
              hideInfoButton
            />
            {/* <SmartFilter
              filterData={filterData}
              setFilter={(...args) => {
              }}
              handleFilter={setFilterAndExclude}
              filterValues={filter}
              excludeValues={exclude}
              fullFilterValues={{ filter, exclude }}
              clearFilter={resetFilter}
              city={city}
              clearExcludeOnly={clearExclude}
              clearFilterOnly={clearFilter}
              getCount={() => {
              }}
              placholder="ЖК, район, метро, застройщик"
              textFilters={['districts', 'subways', 'blocks', 'banks', 'builders', "villages"]}
              hideInfoButton
              showClearButton={false}
              dubai
            /> */}
            <PrimaryTopFilters
              filterData={filterData}
              handleFilter={handleFilter}
              filter={filter}
              setFilterAndExclude={setFilterAndExclude}
              clearByIdent={clearByIdent}
              disableButton={!count}
              onSumbit={submit}
              isLoading={isCountLoading || isLoading}
            />
            <button
              className="button button_type_reveal-filter white-button"
              type="button"
              onClick={() => toggleFilter(!showFilter)}
            >
              <span>Все фильтры</span>
            </button>
          </div>
          {(isLaptop || (!isTablet && showFilter)) && <div style={{ padding: "0 16px", position: 'relative', paddingBottom: '40px' }}>
            <div className="sf__filter filter__max form smartfilter-wrapper_primary smartfilter-wrapper_primary-bottom">
              {!isTablet && showFilter &&
                <PrimaryBottomFilters
                  filter={filter}
                  filterData={filterData}
                  handleFilter={handleFilter}
                  setFilterAndExclude={setFilterAndExclude}
                  clearByIdent={clearByIdent}
                  clearExcludeOnly={clearExclude}
                  clearFilterOnly={clearFilter}
                  wrapperClass={"sf__filter-item filter_item_temp"}
                />
              }
            </div>
          </div>
          }

          <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          />

          <div className="sf__row">
            {!isTablet && <SfButton onClick={saveFilterModal} />}
            <div />
            <div className="sf__filter-btns">
              {isTablet && <FilterToggleBtn />}
              <MapBtn
                setShowMap={() => setView('map')}

              />
              {!isTablet && (
                <span style={isLaptop ? { marginLeft: 20 } : {}}>
                  <FilterSubmitButton
                    onClick={props.submit}
                    isLoading={isCountLoading || isLoading}
                    count={count}

                  />
                </span>
              )}
            </div>
          </div>
        </>
      }

      {
        !topStarts ?
          <div className="sf__cards-list">
            <CardSkeleton isLaptop={isLaptop} />
            <CardSkeleton isLaptop={isLaptop} />
            <CardSkeleton isLaptop={isLaptop} />
          </div> :
          <div>
            <h3 className="h3 sf__h1 with-tg-link">
              <p>Старты продаж</p>
            </h3>
            <CardsList
              items={topStarts.slice(0, 3)}
              limit={3}
              isLoading={showSkeleton}
              itemsRef={infiniteRef}
              cardComponent={MainCardContainer}
              getLink={(item) => `/realty/${city}/complex/${item.id}`}
              listClass="sf__cards-list"
              topStarts={true}
              toggleFavorite={toggleFavorite}
              get={_topStartsGet}
            />
            {<div style={{ textAlign: 'center', marginTop: -30 }}>
              <a href={'/realty/' + city} className="link">
                Все старты продаж</a>
            </div>}
          </div>
      }
      {
        !viewedObjects ?
          <div className="sf__cards-list">
            <CardSkeleton small />
            <CardSkeleton small />
            <CardSkeleton small />
          </div> :
          <div>
            <h3 className="h3 sf__h1 with-tg-link">
              <p>Вы недавно смотрели</p>
            </h3>
            <div className="sf__cards-list">
              {
                viewedObjects.map((item) => {
                  return (
                    <RecentlyCard
                      img={getUrl(item?.objectInfo?.photo?.src)}
                      link={item?.objectInfo?.link}
                      price={item?.objectInfo?.price}
                      description={item?.objectInfo?.description}
                      builder={item?.objectInfo?.title}
                      key={item.id}
                    />
                  )
                })
              }
            </div>
            <div style={{ textAlign: 'center', marginTop: -30, marginBottom: 50 }}>
              <Link
                to={'/viewed'}
                className="link"
              >
                Показать все
              </Link>
            </div>
          </div>
      }
    </div>
  )
}

export default MainFilter;
