import React, {useMemo, useState} from "react";
import PersonalCabinet from "../index";
import {CRM_TABS, crmFiltrateItems, defaultCrmFilter, getCurrentCRMTab} from "../../Crm/utils";
import {useSelector} from "react-redux";
import usePermission from "../../../hooks/usePermission";
import useApi from "../../../hooks/useApi";
import _get from "lodash/get";
import request from "../../../api";
import {standartErrorToast, successToast} from "../../../components/toasts";
import {useModal} from "react-modal-hook";
import Modal from "../../../components/core/Modal";
import Button from "../../../components/core/Button";
import FilterSkeleton from "../../Crm/FilterSkeleton";
import {CrmFilterBottomRow, MainCrmFilter} from "../../Crm/CrmFilter";
import Loader from "../../../components/Loader";
import Table from "../../../components/core/Table";
import {CheckboxControlled} from "../../../components/core/Checkbox";
import {LocaleNotFound} from "../../../components/core/Table/tableUtils";
import {Link, NavLink} from "react-router-dom";
import Preloader from "../../../components/Preloader";
import PanModal from "../../../components/core/PanModal";
import FixationsNew from "../../Fixations/fixationsNew";


const links = [ // удалить когда надо будет вернуть табы, раскоментить CRM_TABS
                // { id: 2, title: "Клиенты", url: "/crm/clients" },
                // { id: 3, title: "Презентации", url: "/personal/presentations" },
                // { id: 4, title: "Подборка фильтров", url: "/personal/filters" },
                // { id: 5, title: "Подборки", url: "/compilations" },
]

const FixationsList = ({ match: { path }, location: { pathname }, history }) => {

  const currentTab = getCurrentCRMTab(pathname)
  const payload = currentTab?.payload
  const [filter, setFilter] = useState(defaultCrmFilter)
  const [selectedItems, setSelectedItems] = useState([])
  const user = useSelector(state => state.user?.info)
  const isNotAgent = usePermission("not-agent")
  const resetFilter = () => setFilter(defaultCrmFilter)
  const setFilterValue = (key, value) => setFilter(prev => ({ ...prev, [key]: value }));


  const [addFixationtModal, closeFixationtModal] = useModal(
    () => (
      <PanModal title={'Фиксация клиента'} isBig closeModal={closeFixationtModal}>
        <FixationsNew/>
      </PanModal>
    ),
    [],
  );

  const { data, isLoading, refresher, isRefreshing } = useApi({
    payload,
    processData: (resp, prev) => ({ ...prev, [pathname]: resp }),
    loadCondition: data => !data[pathname] && payload,
    successCallback: resp => document.title = resp.meta?.title ?? "ПАН партнер",
  }, [])


  const currentTabData = data[pathname] ?? {}

  const filteredItems = useMemo(() => {
    return isLoading || isRefreshing ? [] : crmFiltrateItems({
      currentTab,
      data: currentTabData,
      filter,
      userId: user.id,
    }) ?? []
  }, [currentTabData, isLoading, isRefreshing, filter]);
  const onTableClick = ({ clientId, id }, rowIndex) => {
    if (currentTab.selectionEnabled) return;
    const path = `${currentTab?.isDefault ? currentTab.url : pathname}/${clientId ? clientId : id}`.replace(/\/\//g, "/")
    history.push(path, filter)
  }
  const columns = currentTab?.columns?.filter?.(column => {
    const permissionCond = !column.permission || (column.permission === "not-agent" && isNotAgent)
    let filterCondition = true;
    if (column.filterBy) {
      const [path, comparator] = column.filterBy;
      const value = _get(currentTabData, path);
      filterCondition = comparator(value);
    }
    return permissionCond && filterCondition;
  })

  const onSelectItem = (checked, id) => {
    if (id == "all") {
      setSelectedItems(checked ? filteredItems.filter((item) => user.id == item?.agentId) : []);
      return;
    }

    const checkedItem = filteredItems.find((item) => item.id == id)
    setSelectedItems(
      checked
        ? [...selectedItems, ...(checkedItem ? [checkedItem] : [])]
        : [...selectedItems.filter((item) => item.id != id)]
    );
  };
  const deleteSf = () => {
    request("panpartner:saved.filter.ajax", "delete", {
      id: selectedItems.map(item => item.id),
    }).then(
      resp => {
        successToast(resp.message);
        closeDeleteModal();
        refresher();
        setSelectedItems([]);
      },
      standartErrorToast
    )
  }
  const [openDeleteModal, closeDeleteModal] = useModal(
    () => (
      <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={closeDeleteModal}>
        <div className="crm-table__sf-controlsmodaltitle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
            <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
            <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
          </svg>
          Удалить подборки?
        </div>
        <div className="crm-table__sf-controlsmodaltext">
          После удаления вся информация будет утеряна
        </div>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={deleteSf}>Да</Button>
          <Button buttonType="line" onClick={closeDeleteModal}>Нет</Button>
        </div>
      </Modal>
    ),
    [selectedItems]);

  return (
    <div>
      <PersonalCabinet/>

      <div className="wrapper fixations no-header-profile">
        {(isLoading || isRefreshing) && payload && <Preloader loading={isLoading} />}

        {!isLoading && !isRefreshing && <div className="crm-filters-header">
          <div className="common-header"><h2 className="h2">Фиксации</h2></div>
          {payload && <MainCrmFilter
            filterData={currentTabData?.filter ?? currentTabData?.filters}
            filter={filter}
            setFilter={setFilterValue}
            tabFilters={currentTab?.topFilters}
            searchPlaceholder={currentTab?.searchPlaceholder}
            openFixationModal={addFixationtModal}
            permissions={{"not-agent": isNotAgent}}
            url={currentTab?.url}
            showDeleteButton={!!selectedItems?.length && currentTab?.showDeleteButton}
            onDelete={openDeleteModal}
          />}
          {payload && currentTab?.bottomFilters && <CrmFilterBottomRow
            filters={currentTab?.bottomFilters}
            setFilter={setFilterValue}
            filter={filter}
            permissions={{"not-agent": isNotAgent}}
          />}
        </div>}

        {isLoading && payload && <div className="crm-table crm-table_deals">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader loaderColor='#2c2e3e'/>
            {/*<object*/}
            {/*  style={{ width: "120px" }}*/}
            {/*  type="image/svg+xml"*/}
            {/*  data="/assets/images/pan-preloader.svg"*/}
            {/*>*/}
            {/*  <img src="/assets/images/pan-preloader.svg" alt="preloader" />*/}
            {/*</object>*/}
          </div>
        </div>}
        {!isLoading && data[pathname] && <div className="crm-table crm-table_filters">
          <Table
            rowSelection={currentTab.selectionEnabled ? {
              columnTitle: (...ars) => {
                return <CheckboxControlled
                  view="invert"
                  checked={!!filteredItems?.length && selectedItems?.length === filteredItems?.filter?.((item) => user.id == item?.agentId)?.length}
                  onChange={e => onSelectItem(e.target.checked, "all")}
                  id={"all"}
                />
              },
              renderCell: (checked, {id, agentId}, index, originNode) => {
                return <CheckboxControlled
                  view="invert"
                  id={`row-item-${id}`}
                  checked={!!selectedItems.find(item => item.id == id)}
                  onChange={e => onSelectItem(e.target.checked, id)}
                  disabled={agentId != user.id}
                />
              },
            } : null}
            columns={columns}
            dataSource={filteredItems}
            pagination={false}

            customPagination={true}
            firstPageCount={10}
            // pageCount={50}
            locale={LocaleNotFound}
            rowKey="id"
            onRow={row => ({
              onClick: e => onTableClick(row, e)
            })}
          />
        </div>}
      </div>
    </div>
  )
}

export default FixationsList
