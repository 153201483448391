import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import {formatMoney, getEndingsTitle, getPricePerMeter, getYoutubeId} from '../../utils';
import Preloader from '../Preloader';
import './index.scss';

// new
import TinySlider from "tiny-slider-react";
import DetailHeader from '../Public/markup/detail/DetailHeader';
import Slider from '../Public/markup/detail/Slider';
import PromoBar from '../Public/markup/detail/PromoBar';
import { scrollToRef } from '../../utils';
import InfoCard from '../Public/markup/detail/InfoCard';

import About from '../Public/markup/detail/About';
import Help from '../Public/markup/detail/Help';
import PromoList from '../Public/markup/detail/PromoList';
import Furnish from '../Public/markup/detail/Furnish';
import VideoList from '../Public/markup/detail/VideoList';
import ContactsModal from '../Public/markup/detail/ContactsModal';

import Button from '../core/Button';
import Iframe from '../Public/markup/detail/Iframe';
import KeysList from '../Public/markup/detail/KeysList';

import PanModal from '../core/PanModal';
import { useModal } from 'react-modal-hook';
import useFilter from '../../hooks/useFilter';
import cloneDeep from 'lodash/cloneDeep';
import useDocTitle from '../../hooks/useDocTitle';
import { PhotoPopup } from '../Viewer/PhotoSlider';
import PrimaryComplexFilterForm from '../Public/markup/PrimaryComplexFilterForm';
import ParkingFilterForm from '../Public/markup/ParkingFilterForm';
import DetailList from '../Public/markup/detail/DetailList/DetailList';
import Tags from '../Public/markup/Tags';
import Files from '../Public/markup/detail/Files';
import NotFound from '../Public/markup/detail/NotFound';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import { ListIcon, TileIcon, ChessIcon } from '../core/Tabs/icons';
import DownloadApp from '../core/DownloadApp';
import useDownloadApp from '../../hooks/useDownloadApp';
import { getUrl } from "../../api";
import CaseModal from "../CaseModal";
import { getURLparam } from '../../utils/location';
import PrimaryComplexCommercialFilterForm from '../Public/markup/PrimaryComplexCommercialFilterForm';
import VideoCard from "../Public/markup/detail/VideoCard";
import SwiperSlider from "../core/SwiperSlider";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import aeroStub from '../../assets/images/aeroStub.png'
import showStub from '../../assets/images/showStub.png'
import ActionSlider from "./ActionSlider";

const typeTabs = [{ title: 'Планировки и цены' }, { title: 'Паркинги', withNew: true }];

const ComplexNew = memo((props) => {
  const { blockId, city } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const [complexView, setComplexView] = useState('photos')

  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');

  const actionsRef = useRef(null);
  const filesRef = useRef(null);
  const aboutBlockRef = useRef(null);
  const filesBlockRef = useRef(null);
  const [showGp, setShowGp] = useState(false);
  const [activeType, setActiveType] = useState(0);
  const [activeView, setActiveView] = useState(0);
  const searchHashParams = new URLSearchParams(window.location.hash);
  const isCommercial = getURLparam("type") === "commercial";
  const viewTabs = [
    { title: 'Список', icon: ListIcon },
    { title: 'Плитка', icon: TileIcon },
    ...(isCommercial ? [] : [{ title: 'Шахматка', icon: ChessIcon }])
  ];

  const modifyRow = (item) => {
    const { objectType, id } = item;
    const isAssignment = objectType === 'assignment';
    const isParking = objectType === 'parking';
    const links = {
      assignment: `/assignments/${id}${window.location.search}`,
      booking: `/flat-booking/${objectType}/${id}/${city}`,
      apartments: `/realty/${city}/apartment/${id}${window.location.search}`,
      parking: `/realty/${city}/parking/${id}${window.location.search}`,
    };
    item.costPerMeter = parseInt(
      getPricePerMeter(item.costWithDiscount, item.squareTotal),
    );
    item.bookingLink = item.bookingLink ?? links.booking;
    item.link = links.apartments;
    if (isAssignment) item.link = links.assignment;
    if (isParking) item.link = links.parking;
    return item;
  };
  const extractSubItems = (setData, resp) => {
    const dataKey = isParking ? "parking" : "apartments"
    const building = resp[dataKey]?.[0];
    const type = building?.types?.[0];
    setData((prev) => {
      const currentBuildingIndex = prev[dataKey].findIndex(
        (tmpBuilding) => tmpBuilding?.buildingId === building?.buildingId,
      );
      if (currentBuildingIndex === -1) return prev;
      const idKey = isParking ? "parkingTypeId" : "roomTypeId"
      const tmp = cloneDeep(prev);
      const currentTypeIndex = tmp[dataKey][currentBuildingIndex]
        .types.findIndex((tmpType) => tmpType[idKey] === type[idKey]);
      tmp[dataKey][currentBuildingIndex].types[currentTypeIndex] = type;
      tmp[dataKey][currentBuildingIndex].types[currentTypeIndex].items.map(
        modifyRow,
      );
      return tmp;
    });
  };
  useEffect(() => {
    const id = searchHashParams.get('id');
    if (!id) return;
    const getRowLink = () => {
      if (searchHashParams.get('objectType') === 'assignment') {
        return `/assignments/${id}${window.location.search}`;
      }
      return `/realty/${city}/apartment/${id}${window.location.search}`;
    };
    window.open(getRowLink(), '_self');
  }, []);

  const [downloadApp, setDownloadApp] = useDownloadApp();


  const [caseId, setCaseId] = useState(false)
  const [openCaseModal, closeCaseModal] = useModal(
    () => (
      <PanModal title={'Кейс'} closeModal={closeCaseModal} hashParams={`caseId=${caseId}`} isBig>
        <CaseModal caseId={caseId} closeModal={closeCaseModal} />
      </PanModal>
    ),
    [caseId]);
  useEffect(() => {
    const id = searchHashParams.get('caseId');
    if (!id) return;
    setCaseId(id)
    openCaseModal()
  }, []);

  const openCaseModalCallback = (id) => {
    setCaseId(id)
    openCaseModal()
  }
  const defaultFilter = useMemo(() => ({
    status: [1],
    blockId,
    ...(isCommercial ? { rooms: [90] } : [])
  }), [])

  const {
    handleFilter,
    isLoading,
    filter = {},
    data: {
      // вся инфа тут
      block = {},
      actionInfo,
      filter: filterData = {},
      blockInfo,
      settings,
      priceInfo,
      news,
      agent,
      client,
      isActive,
      isShowFilter,
      managerInfo,
      otherBlocks,
      savedFilter,
      apartments,
      builderActions,
      blockVideos,
      savedFilterSettings,
      contacts,
      schedule,
      filesInfo,
      cases,
      hasParking
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    removeBadge,
    resetFilter,
    loadSubItems,
    favoritesProps,
    dataSetter
  } = useFilter({
    //TO DO: вынести конфиги фильтров отдельно
    apiConfig: {
      component: 'panpartner:block.detail.ajax',
      submitFunction: 'getBlock',
      loadSubItems: 'getApartments',
    },
    defaultFilter,
    defaultParams: {
      isGetApartments: true,
      isAssignment: true,
      onlyTypes: true,
      city,
      id: blockId,
    },
    favoritesParams: {
      city,
      id: blockId,
      type: 'block',
    },
    favoritePath: 'block.favorite',
    shadowFilters: ['blockId', 'status', ...(isCommercial ? ['rooms'] : [])],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: '',
    },
    isGetFilter: true,
    refreshWhenFilterChange: true,
    legacyRestoreFromLS: true,
    extractSubItems,
    filterFromLS: JSON.parse(localStorage.getItem(isCommercial? "commercialBlocksFilter" : 'blocksFilter'))?.[blockId],
    processData: (resp, prev) => {
      if (prev.filter) delete resp.filter;
      return { ...prev, ...resp };
    },
  });
  const {
    handleFilter: handleParkingsFilter,
    isLoading: isParkingsLoading,
    filter: parkingsFilter = {},
    data: {
      filter: parkingsFilterData = {},
      parking,
    },
    setLoadCondition,
    showParkingSkeleton,
    setFilterAndExclude: setParkingsFilterAndExclude,
    filterBadges: parkingFilterBadges,
    removeBadge: removeParkingBadge,
    resetFilter: resetParkingFilter,
    loadSubItems: loadParkingSubItems,
    dataSetter: dataSetterParking

  } = useFilter({
    //TO DO: вынести конфиги фильтров отдельно
    apiConfig: {
      component: 'panpartner:block.detail.ajax',
      submitFunction: 'getParking',
      loadSubItems: 'getParking',
    },
    defaultFilter: {
      status: [1],
      blockId,
    },
    defaultParams: {
      isGetApartments: true,
      onlyTypes: true,
      city,
      id: blockId,
    },
    shadowFilters: ['blockId', 'status'],
    fields: {
      parkingType: [],
      prices: {},
      status: [],
      features: []
    },
    isGetFilter: true,
    refreshWhenFilterChange: true,
    extractSubItems,
    defaultLoadCondition: false,
  });
  useEffect(() => () => localStorage.removeItem(isCommercial? "commercialBlocksFilter" : 'blocksFilter'), []);
  useDocTitle(`ЖК ${block.name ? `«${block.name}»` : ''}`);

  useEffect(() => setLoadCondition(activeType === 1), [activeType])
  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          managerId={block?.manager?.id}
          blockId={block.id}
          params={{
            city,
            type
          }}
        />
      </PanModal>
    ),
    [block],
  );

  const decorationSliders = useMemo(() => {
    // const { finishing, finishingPreReady, finishingReady } = block;
    return [
      { title: 'Чистовая', items: block?.finishingReady },
      { title: 'Предчистовая', items: block?.finishingPreReady },
      { title: 'Черновая', items: block?.finishing },
      { title: 'С мебелью', items: block?.photos?.interior},
    ].filter((item) => !!item?.items?.length);
  }, [block]);

  const isDesktop = useMedia("isDesktop");
  const [numberOfSlides, setNumberOfSlides] = useState(0);
  const handleInit = (swiper) => {
    if (swiper) {
      setNumberOfSlides(swiper.slides.length);
    }
  };

  const iframeRef = useRef(null);

  useEffect(() => {
    const handleAutoplay = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          JSON.stringify({ type: 'player:play' }),
          '*'
        );
      }
    };
    setTimeout(handleAutoplay, 1000);
  }, []);
  const getEmbedUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const videoId = urlObj.pathname.split('/').filter(Boolean).pop();
      const token = urlObj.searchParams.get('p');
      return `https://rutube.ru/play/embed/${videoId}?p=${token}&autoplay=1&mute=1`;
    } catch (error) {
      console.error('Некорректный URL', error);
      return null;
    }
  };

  const [isYoutube, setIsYoutube] = useState(true)

  const checkYouTubeLink = (url) => {
    return typeof url === "string" && url.includes("https://youtu");
  };

  useEffect(() => {
    if (!checkYouTubeLink(blockVideos?.[0]?.link)) {
      setIsYoutube(false);
    } else {
      setIsYoutube(true);
    }
  }, [blockVideos, isLoading]);

  if (isLoading) return <Preloader loading />;
  const scrollToActions = () =>
    scrollToRef(actionsRef, -aboutBlockRef.current?.offsetTop);

  const scrollToFiles = () =>
    scrollToRef(filesRef, -filesBlockRef.current?.offsetParent?.offsetTop);


  const isParking = activeType === 1

  const sortHandler = (pagination, filters, sorter, extra, items, buildingIndex, itemsIndex) => {
    const changeFn = isParking ? dataSetterParking : dataSetter;
    const key = isParking ? "parking" : "apartments"
    changeFn(key, prev => {
      const tmp = [...prev]
      const tmpItems = [...items]
      if (tmp?.[buildingIndex]?.types?.[itemsIndex]?.items) tmp[buildingIndex].types[itemsIndex].items = tmpItems.sort((a, b) => {
        const sortResult = sorter?.column?.sorter?.(a, b) ?? a?.costWithDiscount - b?.costWithDiscount
        return sorter?.order === "descend" ? -sortResult : sortResult;
      })
      return tmp
    })
  }



  const haveItems = (isParking && !!parking?.length) || (!isParking && !!apartments?.length)
  return (
    <>
      <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} noCloser />

      {!downloadApp && (
        <div class="sf wrapper">
          {block && (
            <DetailHeader
              title={`ЖК «${block.name}»`}
              description={block.address}
              subways={block.subway}
              commission={block.commission}
              isCustomerFixation={block.builder?.isCustomerFixation}
              coordinates={block.coordinates}
              onFixationClick={() =>
                window.open(
                  `/client-fixation?city=${city}&builderId=${block?.builder?.id}&blockId=${blockId}`,
                )
              }
              {...favoritesProps}
              isSignUpToView={block?.isSignUpToView}
              blockId={block.id}
              city={city}
            />
          )}
          <div className="sf__detail-row">
            <div className="sf__detail-col complex-custom-view">
              {!isTablet && !!block.photos?.complex?.length && complexView == 'photos' && (
                <div className="complex-custom-view__main-container">
                  <Slider
                    items={block.photos.complex}
                    defoultThumbs
                  />
                </div>
              )}

              {!isTablet && !!block?.aeroPanorama && complexView == 'panorama' && (
                <div className="complex-custom-view__main-container">
                  <section className="sf__section">
                    <Iframe title="Аэропанорама" src={`${block?.aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`} />
                  </section>
                </div>
              )}

              {!isTablet && !!block?.builder?.typical3DTour && complexView == '3dtour' && (
                <div className="complex-custom-view__main-container">
                  <section className="sf__section">
                    <Iframe title="Шоу-рум" src={block.builder.typical3DTour} />
                  </section>
                </div>
              )}

              {!isTablet && decorationSliders.length > 0  && complexView == 'furnish' && (
                <div className="complex-custom-view__main-container">
                 <Furnish defoultThumbs items={decorationSliders} />
                </div>
              )}

              {!isTablet && !!block.generalPlan?.length && complexView == 'genplan' && (
                <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                  <Slider
                    items={[block.generalPlan?.[0]]}
                    defoultThumbs
                  />
                  {/*<img className="complex-custom-view__genplan-img" src={getUrl(block.generalPlan?.[0].src)} alt=""/>*/}
                </div>
              )}

              {!isTablet && blockVideos.length > 0 && complexView == 'video' && (
                <div className="complex-custom-view__main-container">

                  <section className="complex-custom-view__video-slider">
                    <div className="swiper-pan">

                      {
                        isYoutube ?
                          <SwiperSlider
                            slidesPerView={1}
                            items={blockVideos}
                            thumbs={blockVideos}
                            defoultThumbs
                            navigation={blockVideos?.length > 1}
                            wrap={(item) => (
                              <iframe
                                className="complex-custom-view__video-frame"
                                src={`https://www.youtube.com/embed/${getYoutubeId(item.link)}?autoplay=${item.id === blockVideos[0].id ? 1 : 0}&mute=1`}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="video"
                                controls
                                autoPlay={item.id === blockVideos[0].id}
                              />
                            )}
                          /> :
                          <SwiperSlider
                            slidesPerView={1}
                            items={blockVideos}
                            thumbs={blockVideos}
                            defoultThumbs
                            navigation={blockVideos?.length > 1}
                            wrap={(item) => (
                              <iframe
                                ref={iframeRef}
                                src={getEmbedUrl(item.link)}
                                className="complex-custom-view__video-frame"
                                frameBorder="0"
                                allow="autoplay"
                                webkitAllowFullScreen
                                mozallowfullscreen
                                allowFullScreen
                              />

                            )}
                          />
                      }
                    </div>
                  </section>

                </div>
              )}

              <ul className="complex-view-list">
                <Swiper
                  spaceBetween={15}
                  slidesPerView={5}
                  modules={[Navigation]}
                  navigation={isDesktop ? numberOfSlides > 5 : numberOfSlides > 6}
                  onInit={handleInit}
                  breakpoints={{
                    1599: {
                      slidesPerView: 6,
                    },
                  }}
                >
                    <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => {setComplexView('photos')}}>
                        <img src={getUrl(block.photos?.complex?.[0].src)} alt=""/>
                        <span>Фотографии</span>
                      </li>
                    </SwiperSlide>
                    {!!block?.aeroPanorama && <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'panorama' ? 'active' : ''}`} onClick={() => {setComplexView('panorama')}}>
                        <img src={aeroStub} alt=""/>
                        <span>Аэропанорама</span>
                      </li> </SwiperSlide>}
                    {!!block?.builder?.typical3DTour && <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == '3dtour' ? 'active' : ''}`} onClick={() => {setComplexView('3dtour')}}>
                        <img src={showStub} alt=""/>
                        <span>Шоу-Рум</span>
                      </li> </SwiperSlide>
                    }
                    {decorationSliders.length > 0 && <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'furnish' ? 'active' : ''}`} onClick={() => {setComplexView('furnish')}}>
                        <img src={getUrl(decorationSliders[0].items[0].src)} alt=""/>
                        <span>Отделка</span>
                      </li> </SwiperSlide>
                    }

                    {
                      !!block.generalPlan?.length && <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'genplan' ? 'active' : ''}`} onClick={() => {setComplexView('genplan')}}>
                        <img src={getUrl(block.generalPlan?.[0].src)} alt=""/>
                        <span>Ген.план</span>
                      </li> </SwiperSlide>
                    }
                    {blockVideos.length > 0 && <SwiperSlide>
                      <li className={`complex-view-list__item ${complexView == 'video' ? 'active' : ''}`} onClick={() => {setComplexView('video')}}>
                        <img src={getUrl(blockVideos[0].photo.src)} alt=""/>
                        <span>Видео</span>
                      </li> </SwiperSlide>
                    }
                </Swiper>
              </ul>

              {/*{actionInfo && (*/}
              {/*  <PromoBar*/}
              {/*    title={actionInfo.title}*/}
              {/*    activeTo={actionInfo.actionPeriod}*/}
              {/*    count={actionInfo.moreActions?.length}*/}
              {/*    managerInfo={managerInfo}*/}
              {/*    onClick={scrollToActions}*/}
              {/*    id={actionInfo.id}*/}
              {/*  />*/}
              {/*)}*/}
              {/*<PromoList*/}
              {/*  items={actionInfo?.moreActions}*/}
              {/*  innerRef={actionsRef}*/}
              {/*  managerInfo={managerInfo}*/}
              {/*/>*/}

            </div>
            <div className="sf__detail-col">
              {block?.isAutoPan &&
                <Link target="_blank" className="link sf__elem-btn" to={'/autopan'} style={{ display: 'flex', float: 'right', marginTop: -55, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <svg style={{ marginRight: 10 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.5791 14.5789L17.5647 17.5644" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.87988 6.87988L9.86544 9.86544" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.86544 14.5789L6.87988 17.5644" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5647 6.87988L14.5791 9.86544" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Доступен Автопан
                </Link>}
              {!isTablet && <InfoCard items={blockInfo} cols={priceInfo} />}
              <section className="sf__section">
                <Button buttonType="primary" isFull onClick={openContactsModal}>
                  Контакты
                </Button>
                <Button buttonType="line" isFull onClick={scrollToFiles}>
                  Файлы
                </Button>
              </section>
            </div>
          </div>
          {actionInfo && (
            <ActionSlider
              actionInfo={actionInfo}
              managerInfo={managerInfo}
              scroll={scrollToActions}
            />
          )}

          <div className="sf__detail-row sf__detail-row_full">
            {hasParking && <StatelessTabs
              tabs={typeTabs}
              activeTab={activeType}
              setActiveTab={setActiveType}
              largeBtn
            />}
            {!hasParking && <h3 className="h3 tab-title">Планировки и цены</h3>}
            {activeType === 0 && !isCommercial && (
              <PrimaryComplexFilterForm
                filterData={filterData}
                filter={filter}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                isLoading={isLoading}
              />
            )}
            {activeType === 0 && isCommercial && (
              <PrimaryComplexCommercialFilterForm
                filterData={filterData}
                filter={filter}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                isLoading={isLoading}
              />
            )}
            {isParking && (
              <ParkingFilterForm
                filterData={parkingsFilterData}
                filter={parkingsFilter}
                setFilterAndExclude={setParkingsFilterAndExclude}
                handleFilter={handleParkingsFilter}
                isLoading={isParkingsLoading}
              />
            )}
            <Tags
              badges={isParking ? parkingFilterBadges : filterBadges}
              removeBadge={isParking ? removeParkingBadge : removeBadge}
              resetFilter={isParking ? resetParkingFilter : resetFilter}
              checkValues={true}
            />
            {haveItems && !isParking && (
              <StatelessTabs
                tabs={viewTabs}
                activeTab={isParking ? 0 : activeView}
                setActiveTab={setActiveView}
                withIcons
                onClick={(i) =>
                  i === 2
                    ? window.open(`/realty/${city}/chess/${blockId}`, '_blank')
                    : setActiveView(i)
                }
              />
            )}

            {haveItems && (
              <DetailList
                items={isParking ? parking : apartments}
                loadItems={isParking ? loadParkingSubItems : loadSubItems}
                showSkeleton={isParking ? showParkingSkeleton : showSkeleton}
                view={isParking ? 0 : activeView}
                city={city}
                quickViewEnabled={settings?.isFastView === '1'}
                defaultTitle={isParking ? `Паркинги в ЖК «${block.name}»` : `ЖК «${block.name}»`}
                columnsType={isParking ? "parkings" : isCommercial ? "commercial" : "apartments"}
                sortHandler={sortHandler}
                builderId={block?.builderId}
              />
            )}
            {!haveItems && <NotFound />}
          </div>

          <div className="sf__detail-row" ref={aboutBlockRef}>
            <div className="sf__detail-col">
              <About
                title={`О ЖК «${block ? block.name : ''}»`}
                text={block?.about?.previewText}
                advantages={block?.about?.advantages}
                audioReview={block?.audioReview}
              />
              {isLaptop && <Help openContactsModal={openContactsModal} />}
              {/*<Furnish items={decorationSliders} />*/}

              {/*<section className="sf__section">*/}
              {/*  {!!block?.builder?.typical3DTour && (*/}
              {/*    <Iframe title="Шоу-рум" src={block.builder.typical3DTour} />*/}
              {/*  )}*/}
              {/*</section>*/}
              {/*{!!block?.aeroPanorama && <section className="sf__section">*/}
              {/*  <Iframe title="Аэропанорама" src={`${block?.aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`} />*/}
              {/*</section>}*/}

              {!!cases?.length && <section className="sf__section">
                <div className="case-info">
                  <p className="h3-l sf__h3 case-info__block-title_link" style={cases && cases.length > 0 ? {} : { marginBottom: 0 }}>Кейсы
                    <Link className="case-info__cases-link" to='/cases'>Все кейсы</Link>
                  </p>
                  <div className="other-cases">
                    {
                      cases &&
                      cases.map((item) => {
                        return (
                          <div className="other-cases__item" onClick={() => openCaseModalCallback(item?.id)}
                               key={item?.id}>
                            <img className="other-cases__img" src={getUrl(item?.plan?.src)} alt="" />
                            <p className="other-cases__tit h4">{item?.title}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </section>
              }

              {false && (
                <KeysList
                  items={[
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                    {
                      title: 'Квартира 2-евро с отделкой за 1 рубль в месяц',
                      imgSrc: 'https://placekitten.com/58/56',
                    },
                  ]}
                />
              )}

              <PromoList
                items={actionInfo?.moreActions}
                innerRef={actionsRef}
                managerInfo={managerInfo}
              />
              <div className="complex__bottomvideo">
                <VideoList items={blockVideos} />
              </div>


              {filesInfo?.length > 0 && (
                <div ref={filesBlockRef}>
                  <Files innerRef={filesRef} items={filesInfo} />
                </div>
              )}
            </div>
            {!isLaptop && (
              <div className="sf__detail-col sf__sticky">
                <Help openContactsModal={openContactsModal} />
              </div>
            )}
          </div>
          <Preloader loading={isLoading} />
          <PhotoPopup
            open={showGp}
            items={block.generalPlan}
            index={0}
            onClose={() => setShowGp(false)}
          />
        </div>
      )}
    </>
  );
});

export default ComplexNew;

