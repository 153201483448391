import React from "react";

const PropertyBanner = (props) => {
  const {openCallbackModal} = props
  return (
    <section className="banner constructor__section banner_property">
      <div className="constructor__container">
        <div className="constructor__box">
          <div className="constructor__wrapper banner__wrapper">
            <div class="banner__content">
              <p className="banner__title h3">Не нашли, то что искали?</p>
              <p className="banner__text text big-text">Оставьте заявку и получите доступ ко всей базе объектов</p>
            </div>

            <button type="button" className="button constructor__btn banner__btn" onClick={openCallbackModal}>получить доступ</button>

            <div className="banner__img">
              <svg xmlns="http://www.w3.org/2000/svg" width="466" height="231" viewBox="0 0 466 231" fill="none">
                <path opacity="0.15" d="M175.733 -117.671C87.3653 -114.601 19.5293 -38.7789 22.1567 46.479C25.2568 134.962 101.007 202.914 186.155 200.316C274.523 197.246 342.831 124.649 339.731 36.1662C336.631 -52.3164 264.101 -120.741 175.733 -117.671Z" fill="#2CBA2E"/>
                <path opacity="0.25" d="M290.479 36.7968C293.018 99.0098 248.11 151.707 185.505 151.001C123.372 153.52 73.9455 108.061 71.4061 45.8482C68.8668 -16.3649 117.469 -66.3099 179.601 -68.8286C238.513 -70.8753 291.633 -22.6636 290.479 36.7968Z" fill="#2CBA2E"/>
                <path opacity="0.15" d="M301.347 150.193L326.394 173.039" stroke="#2CBA2E" strokeWidth="3" strokeMiterlimit="10"/>
                <path opacity="0.15" d="M424.521 300.431L458.911 265.745C461.659 262.048 460.714 255.599 457.021 252.846L357.694 158.704C353.529 152.726 347.088 153.67 344.34 157.367L309.949 192.053C307.202 195.75 308.147 202.199 311.84 204.952L411.166 299.095C411.639 302.319 418.553 304.6 424.521 300.431Z" fill="#2CBA2E"/>
              </svg>
            </div>
          </div>
        </div>
      </div>


    </section>
  )
};

export default PropertyBanner;