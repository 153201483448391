import React from "react";
import "./index.scss";
import NewsShare from './Share.js';

const Primorskiy = (props) => {


  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Предбронирование Полис Приморский`} />
        <div className="news__container container">
          <h1 className="h1 news__title">Предбронирование Полис Приморский</h1>
          <img src={require('../../assets/images/news/prim.svg').default} alt="" className="news__promo-img" />
          <p className="h3 news__subtitle">
            <span role="img" aria-label="hot">🔥</span> В продаже:
          </p>

          <p className="news__text">
            ДОМ №1 секции 1 и 6 – с подчистовой отделкой<br/>
            ДОМ №2 секция 6 с – <b>ЧИСТОВОЙ ОТДЕЛКОЙ!</b>
          </p>

          <p className="h3 news__subtitle">
            ШАГ 1. Выбираем квартиру
          </p>

          <p className="news__text">
            1.	Выбираем планировку квартиры по <a href="https://panpartner.ru/files/3960" className="link bold" target="_blank" rel="noopener noreferrer">ссылке</a><br />
            2.	Как определить номер квартиры?<br />
            Классический номер квартиры Полис Групп, например:  <b>КМ 22  К 1  2/04/10</b><br />
            <b>КМ 22</b> - наименование обьекта<br />
            <b>К1</b> – корпус 1<br />
            <b>2</b> - номер секции<br />
            <b>04</b> - этаж<br />
            <b>10</b> - номер квартиры- считаем от лифта слева направо (по часовой стрелке).
          </p>

          <p className="news__text">
            <b><span role="img" aria-label="attention">❗️</span> ВНИМАНИЕ!</b><br />
            Жилые квартиры начинаются <b>со 2 этажа.</b><br />
          </p>


          <p className="news__text">
            3.	Как определить куда выходят окна?<br />
            Скачать <a href="https://panpartner.ru/upload/iblock/136/gen-plan.png" className="link bold" target="_blank" rel="noopener noreferrer">по ссылке</a> генеральный план обьекта и понять конфигурацию по крайнему торцу, где есть окна и балконы.
          </p>

          <p className="h3 news__subtitle">
            ШАГ 2. Прислать на почту управляющему заявку на бронь СТРОГО по форме
          </p>


          <p className="news__text bold">
            Уважаемые партнеры!<br />
            Тут просим отнестись с пониманием к просьбе, заполнять строго по форме.<br />
            Чем слаженней мы с Вами сработаем, тем больше шансов, что мы забронируем нужные нам варианты квартир.
          </p>

          <p className="news__text">
            <b>Тема письма:</b> Залог ФИО ЖК, корпус, № квартиры, Форма оплаты<br />
            <b>Тело письма:</b><br />
            1. ФИО<br />2. ЖК<br />
            3. Корпус<br />
            4. № квартиры<br />
            5. Форма оплаты <br />
            6. Паспорт  (1-2, 3-4 страницы)
          </p>

          <p className="h3 news__subtitle">
            Пример:
          </p>

          <p className="news__text">
            <b>Тема письма:</b> Залог Петров Петр Петрович КМ22  К1 6/05/03 ипотека<br />
            <b>Тело письма:</b><br />
            <p className="news__text">
              <br /><b>приоритетно:</b><br />
              Петров Петр Петрович КМ22-К1/1/03/07  100%
            </p>

            <p className="news__text">
              <b>либо</b>
            </p>

            <p className="news__text">
              Петров Петр Петрович  КМ22-К2/6/04/03 ипотека<br />
              Петров Петр Петрович  КМ22-К2/6/08/03 ипотека
            </p>

            <p className="news__text bold">
              Выбирайте с клиентом сразу несколько квартир, присылайте в порядке приоритета.И обязательно приложить читаемый скан паспорта клиента с пропиской.
            </p>

            <p className="news__text bold">
              <span role="img" aria-label="attention">❗️</span> Никакой другой дополнительной информации указывать не нужно!
            </p>

            <p className="news__text">
              <b>Стоимость брони:</b><br />
              100% оплата - 20 000 руб. на 5 раб дней,<br />
              Ипотека – 30 000 руб. на 10 раб дней.
            </p>

            <p className="news__text bold">
            <span role="img" aria-label="attention">❗️</span> Оплатить и предоставить платежку строго до 09:00 следующего дня!
            </p>

          </p>

        </div>
      </div>

    </section>
  )
};

export default Primorskiy;
