import React from 'react';
import { connect } from 'react-redux';
import { isDev } from '../api';
import App from '../components/App';
import AppConstructor from '../components/AppConstructor';
import AppSuburbansLanding from '../components/AppSuburbansLanding';
import { checkSubdomain } from '../utils/location';

const search = new URLSearchParams(window.location.search)
const subdomain = search.get("sub")? search.get("sub"): false
const host = window.location.host
const constructorHosts = ["sci-msk.ru", "pro-rieltor.ru"]

export const isConstructor = (checkSubdomain() || constructorHosts.some(constrHost => host === constrHost)) && !host.includes("panpartner.ru")

const RootContainer = props => {
  if (host.includes("pan-zagorod.ru")) return <AppSuburbansLanding {...props} />
  return isConstructor ?<AppConstructor {...props} /> : <App {...props} />
}

const mapStateToProps = (state) => {
    const { router } = state;
  
    return {
      paths: [],
      router,
      routes: {
        
      },
    };
  };

export default connect(mapStateToProps, {
})(RootContainer);