import React, { useState, useEffect } from "react";
import SelectFilter from "../FilterRealty/SelectFilter";
import { media } from '../../utils';

const Badge = (props) => {
  const { item, handleClick, title, identity } = props;

  return (
    <div className="filtered">
      <div className="filtered__inner">
        <span className="filtered__type">{title}:</span>
        <span className="filtered__value">{item.title}</span>
        <button
          className="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            className="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

const ContactsFilter = (props) => {
  const { setFilter, clearFilter, data, filter, isFiltersOpen } = props;
  const { searchTerm, department, builder } = props.filter;

  const handleFilter = (value, identity) => {
    setFilter({ [identity]: value });
  };

  const handleClearFilter = (e) => {
    clearFilter();
    e.preventDefault();
  };

  const showBadges = () => {
    return department.length > 0 || builder.length > 0;
  };

  const handleRemoveFilter = (value, identity) => {
    let newArray = filter[identity];
    newArray = newArray.filter((item) => item != value);
    setFilter({ ...filter, [identity]: newArray });
  };

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

	// useEffect(() => {
  //   if (isFiltersOpen) setIsOpen(!isOpen);
  // }, [isFiltersOpen]);

  const filters = (
    <>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "builder")}
          isExcludable={false}
          isAutocomplete={true}
          items={data ? Object.values(data.builder) : []}
          filterValues={builder}
          excludeValues={[]}
          data={{
            title: "Застройщик",
            autocompleteTitle: "Введите название застройщика",
            identity: "builders",
          }}
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "department")}
          isExcludable={false}
          isAutocomplete={true}
          items={data ? Object.values(data.department) : []}
          filterValues={department}
          excludeValues={[]}
          data={{
            title: "Отдел",
            autocompleteTitle: "Введите название отдела",
            identity: "department",
          }}
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
    </>
  )

  const badges = (
    <>
      {showBadges() && (
        <div className="index-search__filter-applied">
          <div className="index-search__filter-inc">
            {filter.builder.map((id) => {
              let b = data.builder.find((item) => id == item.id);
              return (
                <Badge
                  key={`builder${id}`}
                  identity={`builder`}
                  item={b}
                  title={`Застройщик`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.department.map((id) => {
              let b = data.department.find((item) => id == item.id);
              return (
                <Badge
                  key={`department${id}`}
                  identity={`department`}
                  item={b}
                  title={`Отдел`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  )

  return (
    <div className="contacts__filter">
      <div className="mini-filter for-contacts">
        <form className="form">

          {media("isMobile") ?
            <>
              <div className="mini-filter__col mini-filter__col_search">
                <label
                  className="input input_type_search input_width_available"
                  htmlFor="contacts-search"
                >
                  <input
                    className="input__control"
                    type="search"
                    id="contacts-search"
                    value={searchTerm}
                    placeholder="Поиск по менеджеру"
                    onChange={(e) => handleFilter(e.target.value, "searchTerm")}
                  />
                  <svg className="icon icon_search">
                    <use xlinkHref="#search"></use>
                  </svg>
                </label>
              </div>

              <button
                className="button-mobile for-main"
                onClick={(e) => {
                  e.preventDefault()
                  toggleFilters()
                }}
              >
                Фильтры (2)
              </button>
            </> :

            <>
              <div className="mini-filter__grid">
                <div className="mini-filter__col mini-filter__col_search">
                  <label
                    className="input input_type_search input_width_available"
                    htmlFor="contacts-search"
                  >
                    <input
                      className="input__control"
                      type="search"
                      id="contacts-search"
                      value={searchTerm}
                      placeholder="Поиск по менеджеру"
                      onChange={(e) => handleFilter(e.target.value, "searchTerm")}
                    />
                    <svg className="icon icon_search">
                      <use xlinkHref="#search"></use>
                    </svg>
                  </label>
                </div>

                {filters}

                <div className="mini-filter__col mini-filter__col_clear is-hidden-sm">
                  <button
                    className="button button_type_clear mini-filter__button"
                    disabled={!showBadges()}
                    onClick={(e) => handleClearFilter(e)}
                  >
                    <svg className="icon icon_x">
                      <use xlinkHref="#x"></use>
                    </svg>
                    <span>Очистить фильтр</span>
                  </button>
                </div>
              </div>
              <div className="mini-filter__mobile">
                <button
                  className="button button_type_clear"
                  disabled={!showBadges()}
                  onClick={(e) => handleClearFilter(e)}
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              </div>
            </>
          }
        </form>

        {isOpen && media("isMobile") &&
  				<div className="mobile-filters for-clients">
  					<div className="mobile-filters__title">
  						<svg
  							className="icon icon_arrow_up"
  							onClick={() => {
  								// props.onClick()
  								toggleFilters()
  							}}
  						>
  							<use xlinkHref="#arrow_up"></use>
  						</svg>
  						<h3>Все фильтры</h3>
  					</div>
  					<div className="mobile-filters__content">
              {badges}
              {filters}

              <div className="mobile-filters__show-all for-agents">
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleFilters()
                  }}
                >
                  Показать
                </button>
                <button
                  className="button button__mobile-filters"
                  onClick={(e) => {
                    handleClearFilter(e)
                    toggleFilters()
                  }}
                >
                  Очистить
                </button>
              </div>
            </div>
          </div>
        }

        {badges}
      </div>
    </div>
  );
};

export default ContactsFilter;
