import React, { useEffect } from "react";
import { media } from "../../utils";
import useMedia from "../../hooks/useMedia";

const ViewRealty = ({ view, onChange, borderColor, classes, forConstructor, altListTitle="Показать список"}) => {
  const isMobile = useMedia("isMobile");
  useEffect(() => {
    if (view === "map" && isMobile) {
      document.body.classList.add("no-map-scroll");
    } else if (!(view === "map") && isMobile) {
      document.body.classList.remove("no-map-scroll");
    }
  }, [view]);

  return (
    <div
      className={`public__map-button${classes ? ` ${classes}` : ``}`}
    >
      {view === "list" && (
        <button
          className={`button button_view_outline-with-icon-invert`}
          onClick={() => onChange("map")}
          style={borderColor &&
            {
              border: `1px solid ${borderColor}`,
              backgroundColor: `${borderColor}`,
              borderRadius: 5,
              color: `#fff`
            }
          }
        >
          <svg className="icon icon_marker" style={
            borderColor && {
              fill: `#fff`,
            }
          }>
            <use xlinkHref="#marker"></use>
          </svg>
          {forConstructor ?
            <span>{media("isLaptop") ? '' : 'На карте'}</span> :
            <span>{isMobile ? 'На карте' : 'Показать на карте'}</span>
          }
        </button>
      )}
      {view === "map" && (
        <button
          className="button button_view_outline-with-icon-invert"
          onClick={() => onChange("list")}
          style={borderColor &&
            {
              border: `1px solid ${borderColor}`,
              backgroundColor: "white",
              borderRadius: 8
            }
          }
        >
          <svg className="icon icon_list">
            <use xlinkHref="#list"></use>
          </svg>
          <span>{isMobile ? "Список" : altListTitle}</span>
        </button>
      )}
    </div>
  );
};

export default ViewRealty;
