import mergeWith from 'lodash/mergeWith';
import slice from 'lodash/slice';
import React, { useEffect, useState } from "react";
import ym from "react-yandex-metrika";
import { getFormSettings, sendForm } from '../../actions/forms';
import BotBar from '../../components/BotBar/BotBar';
import Dropdown from '../../components/core/Dropdown';
import Preloader from '../../components/Preloader';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, infoToast, standartErrorToast } from '../../components/toasts';
import { formatMoney, media } from "../../utils";
import FileButton from './FileButton';
import './index.scss';
import SearchInput from './SearchInput';
import NewAlert from "../../components/core/NewAlert";
import { BackTop } from "antd";

const defaultFiles = {
  plan: {
    files: [],
    filesList: []
  },
  floorPlan: {
    files: [],
    filesList: []
  },
  map: {
    files: [],
    filesList: []
  },
  clientPassport: {
    files: [],
    filesList: []
  },
  ddu: {
    files: [],
    filesList: []
  },
  contractWithClient: {
    files: [],
    filesList: []
  },
  otherDocuments: {
    files: [],
    filesList: []
  },
};
const defaultValuesConfig = ["roomType", "decoration"]
const SellAssigment = () => {

  const [formSettings, setFormSettings] = useState(null)
  const [form, setForm] = useState({})
  const [builders, setBuilders] = useState([])
  const [activeBuilder, setActiveBuilder] = useState({})
  const [errors, setErrors] = useState("")
  const [agreement] = useState(true)
  const [files, setFiles] = useState(defaultFiles);
  const [sending, setSending] = useState(false);
  const [regulationsLink, setRegulationsLink] = useState(false);
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    getFormSettings(30, "spb").then(resp => {
      setFormSettings(resp.questions)
      setBuilders(resp.builders)
      setRegulationsLink(resp.regulationsLink);

    },
      standartErrorToast
    )
  }, [])

  const setDefaultValues = () => {
    if (!formSettings) return;
    defaultValuesConfig.forEach(code => {
      const defaultRoomTypeValue = formSettings.find(setting => setting.code === code)?.items?.[0]?.value;
      handleChange(defaultRoomTypeValue, code);
    })
  }

  useEffect(setDefaultValues, [formSettings]);

  const getFieldName = (code, isMulti) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        if (isMulti) {
          return [...setting.items]
        }
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const isRequired = code => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.required
      }
    }
    return false
  }
  const handleChange = (value, code, arrayValue) => {
    const tmp = {
      ...form
    }
    const handlers = {

      defalutText: (code, val) => {
        const ident = getFieldName(code)
        tmp[ident] = val
      },

      builderName: function () {
        if (typeof value === "string") {
          setActiveBuilder({})
          this.defalutText("builderId", false)
          this.defalutText(code, value)
          return
        }
        setActiveBuilder(value)
        this.defalutText("builderId", value.id)
        this.defalutText(code, value.name)
      },

      blockName: function () {
        if (typeof value === "string" || !value) {
          return
        }
        this.defalutText("blockId", value.id)
        this.defalutText(code, value.name)
      },

    }
    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      handlers[code] ? handlers[code]() : handlers.defalutText(code, value)
    }
    setForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = () => {

    if (!!form[getFieldName("encumbrance")]?.length && !form[getFieldName("commentEncumbrances")]) {
      return errorToast(`Заполните поле "комментарий"`)
    }
    if (!agreement) {
      setErrors(prev => ({ ...prev, agreement: true }))
      return
    }

    if (window.location.host !== 'localhost:3000') {
      ym('reachGoal', 'sell_assignment');
    }

    let formData = new FormData();
    let keys = Object.keys(form);

    keys.forEach((key) => {
      if (Array.isArray(form[key])) {
        form[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, form[key]);
      }
    });
    let filesKeys = Object.keys(files);
    filesKeys.forEach((key) => {
      files[key].files.forEach((data) => {
        if (data.file) {
          formData.append(data.fieldNames, data.file);
        }
      });
    });
    setSending(true)
    sendForm(30, formData, "spb").then(
      () => {
        infoToast(<div>Спасибо! Форма успешно отправлена</div>)
        setIsSending(true)
        setSending(false)
        setFiles(defaultFiles);
        setDefaultValues()
        const defaultRoomTypeValue = formSettings.find(setting => setting.code === "roomType")?.items?.[0]?.value;
        setForm({ [getFieldName("roomType")]: defaultRoomTypeValue });
        const defaultDecorationsValue = formSettings.find(setting => setting.code === "decoration")?.items?.[0]?.value;
        setForm({ [getFieldName("decoration")]: defaultDecorationsValue });
      },
      error => {
        standartErrorToast(error)
        setSending(false)
      },
    );
  }

  const loadFiles = (e, ident, isMulti) => {
    const tmp = [...files[ident].filesList, ...e.target.files];
    const fieldNames = getFieldName(ident, isMulti)
    if (!tmp.length > 0) return
    if (isMulti) {
      let tmpFiles = mergeWith(slice(fieldNames, 0, 10), slice(tmp, 0, 10), (first, second) => ({ fieldNames: first.fieldName, file: second }))
      setFiles(prev => ({
        ...prev,
        [ident]: {
          files: tmpFiles,
          filesList: slice(tmp, 0, 10)
        }
      }))
      return
    }
    setFiles(prev => {
      let reversed = [...tmp].reverse()
      return {
        ...prev, [ident]:
        {
          files: [{ fieldNames, file: reversed[0] }],
          filesList: slice(reversed, 0, 1)
        }
      }
    })

  };

  const removeFile = (key, index) => {
    const filesList = [...files[key]?.filesList];
    const tmpFiles = [...files[key]?.files];
    filesList.splice(index, 1);
    tmpFiles.splice(index, 1);
    setFiles({
      ...files, [key]: {
        files: tmpFiles,
        filesList
      }
    });
  };
  if (!formSettings) {
    return <Preloader loading={true} />
  }

  const afterSend = () => {
    setIsSending(false)
    // setForm({})
    // setFiles(defaultFiles)
    setDefaultValues()
  }
  return (
    <section className="sell section section_view_white">
      <div className="container container_sm">
        <div className="sell__title">
          <h1 className="h1">Продать переуступку</h1>
          {
            regulationsLink && (
              <a
                className="link-hover label_with-icon_prefix color-brand-3"
                href={`${regulationsLink}`}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <div className="label__icon">
                  <svg className="icon icon_document">
                    <use xlinkHref="#document"></use>
                  </svg>
                </div>
                Регламент
              </a>
            )
          }

        </div>

        <h4 className="sell__subtitle h4">Информация о комплексе</h4>

        <div className="modal-form">
          <div className="modal-form__wrapper">
            <div className={`modal-form__gist ${media('isMobile') ? 'with-margin' : ''}`}>
              <div className="modal-form__row">
                <SearchInput
                  label="Застройщик"
                  data={builders}
                  searchKey="name"
                  changeHandler={(value) => handleChange(value, "builderName")}
                  placeholder="Название застройщика"
                  isRequired={isRequired("builderName")}
                  value={form[getFieldName("builderName")] ? form[getFieldName("builderName")] : ""}
                />
                <SearchInput
                  label="ЖК"
                  data={activeBuilder?.blocks}
                  searchKey="name"
                  changeHandler={(value) => handleChange(value, "blockName")}
                  placeholder="Название ЖК"
                  isRequired={isRequired("blockName")}
                  value={form[getFieldName("blockName")] ? form[getFieldName("blockName")] : ""}
                />
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item modal-form__item_sm">

                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Корпус {isRequired("corpus") && <span className="is-red-text sell__required">*</span>}</span>
                    <input name="name" className="input__control" value={form[getFieldName("corpus")] ? form[getFieldName("corpus")] : ""} onChange={(e) => handleChange(e.target.value, "corpus")} />
                  </label>
                </div>
                <div className="modal-form__item modal-form__item_sm">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Секция {isRequired("section") && <span className="is-red-text sell__required">*</span>}</span>
                    <input name="name" className="input__control" value={form[getFieldName("section")] ? form[getFieldName("section")] : ""} onChange={(e) => handleChange(e.target.value, "section")} />
                  </label>
                </div>
                <div className="modal-form__item modal-form__item_sm">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Этаж {isRequired("floor") && <span className="is-red-text sell__required">*</span>}</span>
                    <input name="name" className="input__control" value={form[getFieldName("floor")] ? form[getFieldName("floor")] : ""} onChange={(e) => handleChange(e.target.value, "floor")} />
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>


        <h4 className="sell__subtitle h4">Информация о квартире</h4>

        <div className="modal-form">
          <div className="modal-form__wrapper">

            <div className="modal-form__row">
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Номер квартиры {isRequired("number") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("number")] ? form[getFieldName("number")] : ""} onChange={(e) => handleChange(e.target.value, "number")} />
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Кол-во комнат {isRequired("roomType") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "roomType")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("roomType")] ? form[getFieldName("roomType")] : ""}
                      onChange={(val) => handleChange(val, "roomType")}
                    />
                  </div>
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Отделка {isRequired("decoration") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "decoration")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("decoration")] ? form[getFieldName("decoration")] : ""}
                      onChange={(val) => handleChange(val, "decoration")}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S общая {isRequired("squareTotal") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("squareTotal")] ? form[getFieldName("squareTotal")] : ""} onChange={(e) => handleChange(e.target.value, "squareTotal")} placeholder="Общая площадь" />
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S жилая {isRequired("squareLiving") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("squareLiving")] ? form[getFieldName("squareLiving")] : ""} onChange={(e) => handleChange(e.target.value, "squareLiving")} placeholder="Жилая площадь" />
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S кухни {isRequired("squareKitchen") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("squareKitchen")] ? form[getFieldName("squareKitchen")] : ""} onChange={(e) => handleChange(e.target.value, "squareKitchen")} placeholder="Площадь кухни" />
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form input_preudo_rub">
                  <span className="input__label">Цена в договоре {isRequired("priceInContract") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={formatMoney(form[getFieldName("priceInContract")])} onChange={(e) => handleChange(e.target.value.replace(/\s/g, ""), "priceInContract")} />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form input_preudo_rub">
                  <div className="input__label">
                    Цена продажи (включая Вашу комиссию) {isRequired("priceOfHands") && <span className="is-red-text sell__required">*</span>}

                    {/* <div className="info">
                      <svg className="icon icon_info">
                        <use xlinkHref="#info"></use>
                      </svg>
                      <div className="info__popup big-info-container">
                        Ваша цена на руки (цена с учетом Вашей комиссии)
                        {
                          // добавить
                        }
                      </div>
                    </div> */}
                  </div>
                  <input name="name" className="input__control" value={formatMoney(form[getFieldName("priceOfHands")])} onChange={(e) => handleChange(e.target.value.replace(/\s/g, ""), "priceOfHands")} />
                </label>
              </div>
            </div>
            <div className="modal-form__row modal-form__row_mt_32">
              <FileButton
                label={`Планировка ${isRequired("plan") ? "*" : ""}`}
                loadFiles={(e) => loadFiles(e, "plan")}
                files={files.plan.filesList}
                ident="plan"
                removeFile={removeFile}
              />

              <FileButton
                label={`План этажа ${isRequired("floorPlan") ? "*" : ""}`}
                loadFiles={(e) => loadFiles(e, "floorPlan")}
                files={files.floorPlan.filesList}
                ident="floorPlan"
                removeFile={removeFile}
              />
            </div>
            <div className="modal-form__row">
              <FileButton
                label={`Расположение на карте ${isRequired("map") ? "*" : ""}`}
                loadFiles={(e) => loadFiles(e, "map")}
                files={files.map.filesList}
                ident="map"
                additionalWrapperClass="modal-form__item_half"
                removeFile={removeFile}
              />
            </div>
          </div>
        </div>
        <div className="modal-form modal-form_mt_50">
          <div className="modal-form__wrapper">
            <div className={`modal-form__gist ${media('isMobile') ? 'with-margin' : ''}`}>
              <h4 className="sell__subtitle h4">Файлы</h4>
              <div className="modal-form__row modal-form__row_mt_32">
                <FileButton
                  label={`ДДУ ${isRequired("ddu") ? "*" : ""}`}
                  loadFiles={(e) => loadFiles(e, "ddu", true)}
                  files={files.ddu.filesList}
                  ident="ddu"
                  removeFile={removeFile}
                />

                <FileButton
                  label={`Паспорт клиента ${isRequired("clientPassport") ? "*" : ""}`}
                  loadFiles={(e) => loadFiles(e, "clientPassport", true)}
                  files={files.clientPassport.filesList}
                  ident="clientPassport"
                  removeFile={removeFile}
                />
              </div>

              <div className="modal-form__row">
                <FileButton
                  label={`Договор с клиентом ${isRequired("contractWithClient") ? "*" : ""}`}
                  loadFiles={(e) => loadFiles(e, "contractWithClient", true)}
                  files={files.contractWithClient.filesList}
                  ident="contractWithClient"
                  removeFile={removeFile}
                />

                <FileButton
                  label={`Другие документы ${isRequired("otherDocuments") ? "*" : ""}`}
                  loadFiles={(e) => loadFiles(e, "otherDocuments", true)}
                  files={files.otherDocuments.filesList}
                  ident="otherDocuments"
                  removeFile={removeFile}
                />
              </div>
            </div>
          </div>
        </div>

        <h4 className="sell__subtitle h4">Обременения</h4>

        <div className="modal-form">
          <div className="modal-form__wrapper">
            <div className="modal-form__row">
              {formSettings?.find(setting => setting.code === "encumbrance")?.items?.map((item, i) => {
                return <div className="modal-form__item" key={`encumbrance-${i}`}>
                  <div className="checkbox">
                    <div className="checkbox__box">
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id={`modal-callback-encum-${i}`}
                        value={item.value}
                        onChange={(e) => {
                          handleChange(e.target.checked, item?.fieldName, e.target.value);
                        }}
                        checked={!!form?.[item?.fieldName]?.includes(item.value)}

                      />
                      <label className="checkbox__input" htmlFor={`modal-callback-encum-${i}`}></label>
                      <div className="checkbox__marker"></div>
                    </div>
                    <label className="checkbox__label" htmlFor={`modal-callback-encum-${i}`}>{item.name}</label>
                  </div>
                </div>
              })}
            </div>

            {!!form[getFieldName("encumbrance")]?.length &&
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="textarea textarea_type_form"
                  >
                    <span className="textarea__label">Комментарий: {<span className="is-red-text sell__required">*</span>}</span>
                    <textarea
                      className="textarea__control"
                      name="commentEncumbrances"
                      onChange={(e) => handleChange(e.target.value, "commentEncumbrances")}
                      placeholder="Укажите дополнительную информацию об обременении. Например, в каком банке ипотека."
                      value={form[getFieldName("commentEncumbrances")] ? form[getFieldName("commentEncumbrances")] : ""}
                      required
                    ></textarea>
                  </label>
                </div>
              </div>
            }
          </div>
        </div>


        <div className="modal-form modal-form_mt_50">
          <div className="modal-form__wrapper">
            <div className={`modal-form__gist ${media('isMobile') ? 'with-margin' : ''}`}>
              <h4 className="sell__subtitle h4">Дополнительно</h4>
              <div className="modal-form__row">

                {/* <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sell-assigment-phone"
                  >
                    <span className="input__label">Телефон клиента: {isRequired("clientPhone") && <span className="is-red-text sell__required">*</span>}</span>
                    <InputMask
                      className={
                        "input__control" + (errors.phone ? " input__error" : "")
                      }
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                      name="phone"
                      inputRef={register({
                        required: "Введите телефон",
                      })}
                      value={form[getFieldName("clientPhone")] ? form[getFieldName("clientPhone")] : ""}
                      onChange={(e) => handleChange(e.target.value, "clientPhone")}
                    />
                    {errors.phone && (
                      <span className="input__error-label">
                        {errors.phone.message}
                      </span>
                    )}
                  </label>
                </div> */}

                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Ссылка на объект в рекламе: {isRequired("adLink") && <span className="is-red-text sell__required">*</span>}</span>
                    <input name="name" className="input__control" value={form[getFieldName("adLink")] ? form[getFieldName("adLink")] : ""} onChange={(e) => handleChange(e.target.value, "adLink")} />
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="textarea textarea_type_form"
                  >
                    <span className="textarea__label">Примечания: {isRequired("note") && <span className="is-red-text sell__required">*</span>}</span>
                    <textarea
                      className="textarea__control"
                      name="comment"
                      onChange={(e) => handleChange(e.target.value, "note")}
                      placeholder="Укажите дополнительную информацию о Вашей переуступке. Например, уступка от физ или юр,лица, куда выходят окна, какая разница по цене от аналогов застройщика, важные моменты по проведению сделки, продажа по доверенности, продавец нерезидент, готовы ли к торгу."
                      value={form[getFieldName("note")] ? form[getFieldName("note")] : ""}
                    ></textarea>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal-form modal-form_mt_25">
          {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
            return <div className="modal-form__row" key={`policy-${i}`}>
              <div className="modal-form__item">
                <div className={`checkbox ${media('isMobile') ? '' : 'checkbox_view_invert'}`}>
                  <div className="checkbox__box">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      name="agree"
                      value={item.value}
                      id="modal-reservation-agreement"
                      onChange={(e) => handleChange(e.target.checked, item?.fieldName, e.target.value)}
                      checked={!!form?.[item?.fieldName]?.includes(item.value)}

                    /><label className="checkbox__input" htmlFor="modal-reservation-agreement"></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label className="checkbox__label" htmlFor="modal-reservation-agreement">Cогласие на обработку персональных данных {isRequired("policy") && <span className="is-red-text sell__required">*</span>}</label>
                </div>
              </div>
            </div>
          })}
        </div>

        <div className={`modal-form__submit ${isSending ? ' modal-form__submit_is' : ''}`}>
          {
            !isSending &&
            <SubmitButton
              className="button button_view_default"
              onClick={submit}
              isLoading={sending}
            >
              Отправить заявку
            </SubmitButton>
          }
          {
            isSending &&
            <>
              <NewAlert
                title="Заявка на добавление объекта отправлена"
                text="После добавления объекта мы отправим Вам e-mail и push-уведомление"
                bgColor="#DFF5E0"
                iconColor="#2CBA2E"
              />
              <BackTop onClick={afterSend}>
                <p className="more-object-link">Добавить еще один объект</p>
              </BackTop>
            </>
          }
        </div>

        <BotBar customText={`Моментальные уведомления о изменении Вашего объекта в нашем телеграм-боте`} largeText={true} />
      </div>
    </section>
  );
};

export default SellAssigment;

