import React, {useState} from "react";
import SubmitButton from "../../components/SubmitButton";
import NewAlert from "../../components/core/NewAlert";
import useUniversalForm from "../../hooks/useUniversalForm";
import {errorToast} from "../../components/toasts";

const SpeakerRequest = ({id, city, type, userInfo}) => {


  const [description, setDescription] = useState("")
  const [errorHint, setErrorHint] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [eventTheme, setEventTheme] = useState("")
  const [experience, setExperience] = useState(false)

  const { send: sendUniveralForm, response } = useUniversalForm({
    formId: 66,
    defaultValues: {
      name: userInfo?.name + ' ' + userInfo?.lastName,
      secondName: userInfo?.lastName,
      phone: userInfo?.phone || '',
      company: userInfo?.workCompany,
    },
  });


  const sendCallback = () => {
    const themeId = response.questions[2].fieldName
    const descriptionId = response.questions[3].fieldName
    setIsLoading(true)
    sendUniveralForm(false, {
      [themeId]: eventTheme,
      [descriptionId]: description,
      'form_checkbox_has_experience[]': experience ? response.questions[4].items[0].value : ''
    }).then(
      resp => {
        setExperience(false)
        setDescription("")
        setEventTheme("")
        setErrorHint(resp.message)
        setIsLoading(false)
      }, errors=> {
        errorToast(<div>{errors[0]?.message}</div>)
        setIsLoading(false)
      }
    )
  }


  return (
    <div className="speakers-form" style={{marginTop: 20}}>

      <div className="hp__form-item" style={{width: '100%'}}>
        <label className="input input_width_available input_type_form">
          <span className="input__label">Тема мероприятия</span>
          <input
            className={'input__control'}
            style={{ background: "#f6f7f8", boxShadow: 'none' }}
            value={eventTheme}
            onChange={(e) => setEventTheme(e.target.value)}
            placeholder="Например, инструменты продаж для риелтора"
          />
        </label>
      </div>

      <div className="error-report__area" style={{marginTop: 20}}>
        <label
          className="textarea textarea_type_form"
          htmlFor="modal-reservation-commentary"
        >
          <span className="textarea__label">Описание мероприятия</span>
          <textarea
            style={{minHeight: 87}}
            className="textarea__control"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </label>
      </div>

      <div className="checkbox checkbox_view_red fixations__checkboxs" style={{width: '100%', marginBottom: 20}}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id="data-adv"
            name="remember"
            checked={experience}
            onChange={() => {setExperience(!experience)}}
          />
          <label className="checkbox__input" htmlFor="data-adv" />
          <div className="checkbox__marker" />
        </div>
        <label className="checkbox__label" htmlFor="data-adv">
          У меня есть опыт выступлений
        </label>
      </div>

      {errorHint ?
        <NewAlert
          title={errorHint}
          bgColor="#DFF5E0"
          iconColor="#2CBA2E"
        /> :
        <>
        <SubmitButton
          className="btn h6 btn_primary "
          addStyle={{width: '100%'}}
          isLoading={isLoading}
          onClick={sendCallback}
        >
          Отправить
        </SubmitButton>
        </>
      }


    </div>
  )
}

export default SpeakerRequest
