import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import { sendRequest } from "../../actions/suburbans";
import SubmitButton from "../SubmitButton";
import { errorToast, infoToast } from "../toasts";

const Special = (props) => {
  const { name, lastName, phone, close, specialOfferId, isShowRoom, setIsShowRoom } = props;
  const [fetching, setFetching] = useState(false);
  const defaultValues = {
    name: name,
    phone: phone,
    lastName: lastName,
    agree: true,
  };

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    form.append(`name`, data.name);
    form.append(`lastName`, data.lastName);
    form.append(`phone`, data.phone);
    form.append(`specialOfferId`, specialOfferId);

    if (isShowRoom) {
      form.append(`showRoom`, 1);
      setIsShowRoom(false);
    }

    sendRequest(form).then(
      data => {
        setFetching(false);
        infoToast(<div>{data.message}</div>)
        close();
      },
      error => {
        setFetching(false);
        errorToast(<div>{error?.[0]?.message}</div>)
      }
    );
  };

  return (
    <>
      <h2 class="modal__title hide-mobile">{isShowRoom ? `Записаться в шоу-рум` : `Получить консультацию`}</h2>
      <form class="modal-form">
        <div class="modal-form__wrapper">
          <div class="modal-form__row">
            <div class="modal-form__item">
              <label class="input input_width_available input_type_form">
                <span class="input__label">Ваше имя</span>
                <input
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите имя",
                  })}
                  placeholder="Введите имя"
                  defaultValue={name}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div class="modal-form__item">
              <label class="input input_width_available input_type_form">
                <span class="input__label">Ваша Фамилия</span>
                <input
                  name="lastName"
                  className={
                    "input__control" + (errors.lastName ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите Фамилию",
                  })}
                  placeholder="Введите Фамилию"
                  defaultValue={lastName}
                />
                {errors.lastName && (
                  <span className="input__error-label">
                    {errors.lastName.message}
                  </span>
                )}
              </label>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
              className="input input_width_available input_type_form"
              htmlFor="modal-reservation-phone"
              >
              <span className="input__label">Телефон</span>
                <Controller
                as={
                  <InputMask
                  className={
                    'input__control' +
                    (errors.phone ? ' input__error' : '')
                  }
                  name="phone"
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  defaultValue={phone}
                  />
                }
                name="phone"
                control={control}
                rules={{
                  required: 'Введите телефон',
                }}
              />
              {errors.phone && (
                <span className="input__error-label">
                {errors.phone.message}
                </span>
              )}
              </label>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_flex-start">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label checkbox__label_agreement"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.user.info.name,
  lastName: state.user.info.lastName,
  phone: state.user.info.phone,
});

export default connect(mapStateToProps, null)(Special);
