import MapList from './MapList';
import { memo, useState } from 'react';
import Button from '../../core/Button';
import { ArrowRightIcon } from './icons';
import classNames from 'classnames';
import CardSkeleton from './CardSkeleton';
import SimpleBar from 'simplebar-react';

const MapBlock = memo(
  ({ MapFilterBlock, showFilters, setShowFilters, showBlock, setShowBlock, isShowFilter, showSkeleton, dubai, ...props }) => {
    const blockClasses = classNames({
      "sf__map-block": true,
      "sf__map-block_dubai": dubai,
      "hidden": !showBlock,
    });
    const togglerClasses = classNames({
      "sf__map-toggler button": true,
      "active": showBlock,
    });

    return (
      <div className={blockClasses}>
        <button type="button" className={togglerClasses} onClick={() => setShowBlock(!showBlock)}>
          <ArrowRightIcon />
        </button>
        {isShowFilter && <Button
          buttonType="line"
          addClass="sf__map-btn"
          onClick={() => setShowFilters(!showFilters)}
        >
          <b>{showFilters ? 'Объекты' : 'Фильтр'}</b>
        </Button>}
        {showSkeleton && <div className="sf__map-list" style={!isShowFilter ? {'height': '100%'} : {}}>
          <SimpleBar className="sf__map-scroll">
            {Array(3).fill(1).map((item, i) => <CardSkeleton key={i} mapBlock={dubai}/>)}
          </SimpleBar>
        </div>}
        {!showSkeleton && showFilters && isShowFilter ? <MapFilterBlock {...props} /> : <MapList {...props} key={props.items?.length} isShowFilter={isShowFilter} />}
      </div>
    );
  },
);

export default MapBlock;
