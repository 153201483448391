import request from '../api';
import { actionFactory } from './actionUtils';

export const requestCommercialUnit = actionFactory("COMMERCIAL_UNIT_REQUEST");

const successCommercialUnit = actionFactory("COMMERCIAL_UNIT_SUCCESS");

const failureCommercialUnit = actionFactory("COMMERCIAL_UNIT_FAILURE");

export const clearCommercialUnit = actionFactory("COMMERCIAL_UNIT_RESET");

export const fetchCommercialUnit = (id, city, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestCommercialUnit());
    return request("panpartner:commercials.ajax", "getCommercial", { id, city, savedFilterXmlId })
      .then(
        data => {
          const {
            commercial,
            bankPrograms,
            subways,
            agent,
            isActive,
            isShowFilter,
            client,
            savedFilterSettings
          } = data;
          const { manager, ...rest } = commercial;
          dispatch(
            successCommercialUnit({
              data: rest,
              manager: manager,
              banks: bankPrograms,
              subways: subways,
              agent,
              isActive,
              isShowFilter,
              client,
              savedFilterSettings
            }),
          );
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureCommercialUnit());
          return Promise.reject(error);
        },
      );
  };
