import usePermission from '../../hooks/usePermission';
import Card from './Card';


const SimilarCardsList = ({semiObjects, hidePrice, hideTitleNewVersion, redesign, justHouse}) => {
    const showComission = usePermission("motivation")
  return (
    <div className={!redesign && 'special__similar'}>
        {!hideTitleNewVersion && <h2 className={redesign ? 'h3' : 'h2 text-center'} style={redesign && {marginBottom: 20}}>Похожие по цене объекты</h2>}
      <div className="special__cards-list">

      {semiObjects.map(item => <Card key={item.id} {...item} justHouse={justHouse} hideTitleNewVersion={hideTitleNewVersion} showComission={showComission} />)}
      </div>
    </div>
  );
};




export default SimilarCardsList;
