import React, { memo } from "react";
import ApartmentMap from "../ApartmentMap";
import PhotoSlider from "./PhotoSlider";


/*
@param type {string} - "photo", "map", "iframe"
*/
const TabContent = memo(({ type, ...props }) => {
    switch (type) {
        case "photos": return <PhotoSlider {...props} key={props.current} />;
        case "map": return <ApartmentMap {...props} />;
        case "iframe": return <iframe
            allow="true"
            loading="lazy"
            allowFullScreen={true}
            // src={identity != "aeroPanorama" ? getUrl(tour) : `${aeroPanorama}?show_builder_name=no&show_realty_name=no&show_card=no&show_building=no`}
            width="100%"
            // {...(window.location.host.includes("toprealtors.ru") ? { referrerPolicy: "no-referrer" } : [])}
            {...props}
            title={type}
        />;
        default: return null
    }
})

export default TabContent