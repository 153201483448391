import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearFilterEstate,
  fetchEstateFilter,
  getCountBlocks, setFilterEstate
} from "../actions/estate";
import Modal from "../components/core/Modal";
import Filter from "../components/FilterRealty";
import ReserveAutopan from "../components/forms/ReserveAutopan.js";
import Banners from "../components/Home/Banners";
import "../components/Home/index.css";
import Preloader from "../components/Preloader";
import useDocTitle from "../hooks/useDocTitle";
import { homeBannersSelector } from "../selectors/home";
import { filterToParams, media } from "../utils";
import '../components/FilterRealty/Redesign/index.scss'

const Home = (props) => {
  const {
    banners,
    filter,
    fetch,
    getCountBlocks,
    city,
  } = props;
  const [isFetching, setFetching] = useState(true);

  const [showReserveAutopan, hideReserveAutopan] = useModal(
    ({  }) => (
      <Modal close={hideReserveAutopan} classes="modal_task modal_task_lg">
        <ReserveAutopan
          defaultValues={{
            date: new Date(),
            agree: true,
          }}
          close={hideReserveAutopan}
        />
      </Modal>
    ),
  );

  useDocTitle(`Недвижимость Санкт-Петербурга и Москвы`)
  const showModal = (modal) => {
    switch (modal) {
      case "autopan":
        showReserveAutopan();
        break;
      default:
        break;
    }
  };

  let history = useHistory();

  const handleFilter = () => {
    history.push({
      pathname: `/realty/${city}`,
      search: `scroll`,
      state: { detail: "some_value", noClearFilter: false },
    });
  };

  useEffect(() => {
    fetch(city, {}).then(() => {
      setFetching(false);
    });
    getCountBlocks(city, filterToParams(filter));
    return () => { };
  }, []);


  const switchRef = useRef(null);
  const { setRef } = props

  useEffect(() => {
    setRef(switchRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchRef]);


  return (
    <>
      <div className={`home 1 home_light`} id="theme-container">
      {/*<div className={`home 1 home_light redesign`} id="theme-container">*/}
        {/*
          <div className={`home__planets_${theme}`}>
            <svg className="icon_saturn">
              <use xlinkHref={`#saturn_${theme}`}></use>
            </svg>
            <svg className="icon_jupiter">
              <use xlinkHref={`#jupiter_${theme}`}></use>
            </svg>
          </div>
        */}

        <Filter
          onSubmit={(params) => {
            handleFilter(params);
          }}
          city={city}
          isHomePage={true}
        />

        <Banners items={banners} showModal={showModal} />
      </div>
      <Preloader loading={isFetching} />
    </>
  );
};

const mapStateToProps = (state) =>
({
  clientMode: state.general.clientMode,
  banners: homeBannersSelector(state),
  filterData: state.estate.filterData,
  filter: state.estate.filter,
  city: state.user.info.city ? state.user.info.city : state.general.city,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (city) => dispatch(fetchEstateFilter(city)),
  setFilter: (filter) => dispatch(setFilterEstate(filter)),
  clearFilter: () => dispatch(clearFilterEstate()),
  getCountBlocks: (city, params) => dispatch(getCountBlocks(city, params)),
  setRef: (refs) => dispatch({ type: "SET_SWITCH_REF", refs }),
});


export default connect(mapStateToProps, mapDispatchToProps)(Home);
