import dateFormat from 'dateformat';
import filesize from 'filesize';
import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import SimpleBar from "simplebar-react";
import { searchFiles } from '../../actions/files';
import { getUrl } from '../../api';
import useDropdown from '../../hooks/useDropdown';
import { media } from '../../utils';

const FilesSearchInput = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [files, setFiles] = useState([]);
  const [popupFiles, setPopupFiles] = useState([]);
  const [maxFiles, setMaxFiles] = useState(5);

  const [node, open, show, hide] = useDropdown(false);

  useEffect(() => {
    if (searchTerm.length > 2) {
      show();
      searchFiles(searchTerm).then((data) => {
        setFiles(data.files);
      });
    } else {
      hide();
    }
  }, [searchTerm]);

  useEffect(() => {
    // setPopupFiles(take(files, maxFiles));
    setPopupFiles(files);
  }, [files, maxFiles]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (props.isOpen) {
      inputRef.current.focus();
    }
  }, [props.isOpen]);

  return (
    <div className={`search ${props.withBack ? 'with-back' : ''}`} ref={node}>
      <div className="search-input" onClick={media('isMobile') && props.onClick}>
        <label
          className="input input_type_search_form input_width_available"
          htmlFor="files-search-input"
        >
          <DebounceInput
            className="input__control search-input-control"
            id="files-search-input"
            placeholder="Поиск"
            value={searchTerm}
            minLength={2}
            debounceTimeout={300}
            onChange={(event) => setSearchTerm(event.target.value)}
            autocomplete={media('isMobile') && "off"}
            inputRef={inputRef}
          />

          <svg className="icon icon_search">
            <use xlinkHref="#search"></use>
          </svg>
        </label>
      </div>
      {open && (
        <SimpleBar
          className="search-result"
          style={{ maxHeight: 500, display: "block" }}
        >
          {/* <SimpleBar style={{ maxHeight: 500 }}>
        </SimpleBar> */}

          <div>
            <div className="search-result__list">
              {popupFiles.map((item) => (
                <FileItem {...item} key={item.id} />
              ))}
            </div>
            {/* {files.length > 5 && maxFiles == 5 && (
              <div
                className="search-result__more link-hover"
                onClick={() => setMaxFiles(files.length)}
              >
                Показать все ({files.length})
              </div>
            )} */}
          </div>
        </SimpleBar>
      )}
    </div>
  );
};

const FolderItem = (props) => {
  const link = `/files${props.id ? `/${props.id}` : ``}`;
  return (
    <Link to={link}>
      <div className="document__row">
        <div className="document__col document__col_icon">
          <div className="document__icon">
            <svg className="icon icon_folder">
              <use xlinkHref="#folder"></use>
            </svg>
          </div>
        </div>
        <div className="document__col document__col_name">
          <div
            className="folder__name"
            dangerouslySetInnerHTML={{ __html: props.name }}
          ></div>
        </div>
        <div className="document__col">
          <div className="folder__files-amount"></div>
        </div>
        <div className="document__arrow">
          <svg className="icon">
            <use xlinkHref="#arrow_bottom"></use>
          </svg>
        </div>
      </div>
    </Link>
  );
};

const FileItem = (props) => {
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if (tooltip) {
      delay(2000).then(() => setTooltip(false));
    }
  }, [tooltip]);

  const delay = (ms) =>
    new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });

  const openUrl = () => {
    let src = '';

    if (props.type === 'doc') {
      src = `https://view.officeapps.live.com/op/view.aspx?src=${getUrl(props.src, 'https://panpartner.ru')}`;
    } else {
      src = getUrl(props.src, 'https://panpartner.ru');
    }

    return src;
  };

  return (
    <div className="document__row files__item">
      {media('isMobile') ?
        <>
          <div className="document__col document__col_icon">
            <div className="document__icon">
              <svg className="icon icon_document">
                <use xlinkHref="#document"></use>
              </svg>
            </div>
          </div>
          <div className="document__mobile-wrapper">
            <div className="document__col document__col_name">
              <a
                className="files__item-name link-hover"
                target="_blank"
                rel="noreferrer noopener"
                href={openUrl()}
              >
                {props.name}
              </a>
            </div>
            <div className="document__col document__col_info">
              {props.size && (
                <div className="files__item-size">{filesize(props.size)}</div>
              )}

              <div className="files__item-date">
                {dateFormat(props.timeStamp, 'dd.mm.yyyy')}
              </div>
            </div>
          </div>
        </> :

        <>
          <div className="document__col document__col_icon">
            <div className="document__icon">
              <svg className="icon icon_document">
                <use xlinkHref="#document"></use>
              </svg>
            </div>
          </div>
          <div className="document__col document__col_name">
            <a
              className="files__item-name link-hover"
              target="_blank"
              rel="noreferrer noopener"
              href={openUrl()}
            >
              {props.name}
            </a>
          </div>
          <div className="document__col document__col_info">
            {props.size && (
              <div className="files__item-size">{filesize(props.size)}</div>
            )}

            <div className="files__item-date">
              {dateFormat(props.timeStamp, 'dd.mm.yyyy')}
            </div>
          </div>
        </>
      }
      <div className="document__col document__col_controls">
        <div className="files__item-control">
          <div className="clip">
            <div
              className={`popover js-popover ${tooltip ? ' is-showing' : ''}`}
            >
              <div className="js-copy">
                <CopyToClipboard
                  text={getUrl(props.src, 'https://panpartner.ru')}
                  onCopy={() => setTooltip(true)}
                >
                  <div className="files__item-copy">
                    <svg className="icon">
                      <use xlinkHref="#icon_copy"></use>
                    </svg>
                  </div>
                </CopyToClipboard>
              </div>
              <div
                className={`popover__block clip__tooltip ${media('isMobile') ? 'popover__block_align_left'  : 'popover__block_align_right '}`}
                data-align="right"
              >
                <div className="popover__inner">
                  <div className="clip__tooltip-close">
                    <button className="button button_type_delete js-popover-close"></button>
                  </div>
                  <div className="clip__tooltip-text">
                    Ссылка скопирована на ваш компьютер
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="files__item-control files__item-download">
          <a
            href={getUrl(props.src, 'https://panpartner.ru')}
            download
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg className="icon">
              <use xlinkHref="#download"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

const FilesItems = (props) => {
  const { folders, files, breadcrumbs } = props;
  const [back, setBack] = useState(false);

  useEffect(() => {
    setBack(
      breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : false,
    );
  }, [breadcrumbs]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSearch = () => {
    setIsOpen(!isOpen);
  }

  return (
    <section className="section section_view_white">
      <div className="wrapper for-files">
        {back && (
          <div className="files__return">
            <Link
              to={`/files${back.id > 0 ? `/${back.id}` : ``}`}
              key={back.id}
            >
              <div className="button button_view_outline-strong">
                <svg className="icon icon_arrow-return">
                  <use xlinkHref="#arrow_return"></use>
                </svg>
              </div>
            </Link>
          </div>
        )}

        <div className="files__body">
          <FilesSearchInput withBack={back} onClick={toggleSearch}/>
          <div className="files__list">
            {folders.map((item) => (
              <FolderItem {...item} key={item.id} />
            ))}
            {files.map((item) => (
              <FileItem {...item} key={item.id} />
            ))}
          </div>
        </div>

        <div className={`mobile-search-id ${isOpen ? 'is-open' : 'is-hidden'}`}>
          <div className="mobile-filters__title">
            <svg className="icon icon_arrow_up" onClick={toggleSearch}>
              <use xlinkHref="#arrow_up"></use>
            </svg>
            <h3>Поиск</h3>
          </div>

          <div className="mobile-filters__content">
            <FilesSearchInput withBack={back} isOpen={isOpen} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FilesItems;
