import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import request from "../../api";
import { PenIcon } from "../Public/markup/icons";
import SubmitButton from "../SubmitButton";
import Tabs from "../core/Tabs";
import { standartErrorToast, standartSuccessToast } from '../toasts';
import Button from "../core/Button";

const alfaBiks = [
  '044525593',
  '042202824',
  '045004774',
  '046015207',
  '044030786',
  '046577964',
  '040702752',
  '040813770'
];

const RequisitesCompany = ({refresher, requisites, bankRequisites, companyId, showForm, showRequisites, close, isEdited }) => {
console.log(isEdited)

  const {handleSubmit, register,  watch, errors, control} = useForm();
  const [activeTab, setActiveTab] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  // errors && console.log(errors)

  function formatReadableDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  }

  const bikForSz = watch('requisitsSzCompanyBankBik')


  const onSubmit = values => {
    setIsFetching(true)
    let payload;
    if (activeTab === 'Организация') payload = {
      companyId: companyId,
      presetId: 1,
      [`params[type]`]: 'Организация',
      [`params[nameOrg]`]: 'Организация',
      [`params[companyName]`]: values.requisitsOrganizationCompanyName,
      [`params[companyFullName]`]: values.requisitsOrganizationCompanyFullName,
      [`params[inn]`]: values.requisitsOrganizationCompanyInn,
      [`params[kpp]`]: values.requisitsOrganizationCompanyKpp,
      [`params[ogrn]`]: values.requisitsOrganizationCompanyOgrn,
      [`params[director]`]: values.requisitsOrganizationCompanyDirector,
      [`params[address]`]: values.requisitsOrganizationCompanyAddress,
      [`params[bankName]`]: values.requisitsOrganizationCompanyBankName,
      [`params[bik]`]: values.requisitsOrganizationCompanyBankBik,
      [`params[accNum]`]: values.requisitsOrganizationCompanyAccNum,
      [`params[corAccNum]`]: values.requisitsOrganizationCompanyСorAccNum,
    }

    if (activeTab === 'ИП') payload = {
        companyId: companyId,
        presetId: 2,
        [`requisites[firstName]`]: values.requisitsIpFirstname,
        [`requisites[lastName]`]: values.requisitsIpLastname,
        [`requisites[secondName]`]: values.requisitsIpSecondname,
        [`requisites[inn]`]: values.requisitsIpInn,
        [`requisites[ogrnip]`]: values.requisitsIpOgrn,
        [`requisites[address]`]: values.requisitsIpAddress,
        [`bankRequisites[bankName]`]: values.requisitsIpCompanyBankName,
        [`bankRequisites[bik]`]: values.requisitsIpCompanyBankBik,
        [`bankRequisites[accNum]`]: values.requisitsIpCompanyAccNum,
        [`bankRequisites[corAccNum]`]: values.requisitsIpCompanyСorAccNum,
      }

      if (isEdited) {
        return request('panpartner:company.ajax', 'updateRequisites', payload)
          .then(standartSuccessToast, standartErrorToast)
          .finally(()=>{
            refresher()
            setIsFetching(false)
          })
      } else {
        return request('panpartner:company.ajax', 'addRequisites', payload)
          .then(standartSuccessToast, standartErrorToast)
          .finally(()=>{
            refresher()
            setIsFetching(false)
          })
      }

      // return request('panpartner:company.ajax', 'addRequisites', payload)
      // .then(standartSuccessToast, standartErrorToast)
      // .finally(()=>{
      //   refresher()
      //   setIsFetching(false)
      // })
  }

  return (
    <>
          {showRequisites && <div className="lk-form__row requisites">
            <div className="lk-form__row-content">
              <div className="requisites__text-block">
                <div className="requisites__info">
                  <div className="requisites__info-title">Реквизиты компании</div>
                  <div className="requisites__info-text">

                    {requisites && requisites.map((item, index) => {
                      return (
                        <p>{index !== 0 ? item.title + ": " + item.value : item.value}</p>
                      )
                    })}


                  </div>
                </div>
                <div className="requisites__info">
                  <div className="requisites__info-title">Банковские реквизиты</div>
                  <div className="requisites__info-text">

                    {bankRequisites && bankRequisites.map((item) => {
                      return (
                        <p>
                          {item.title + ": " + item.value}
                        </p>
                      )
                    })}

                  </div>
                </div>
              </div>
            </div>
          </div>}
          {showForm && <form onSubmit={handleSubmit(onSubmit)}>
            <div className="lk-form__row _bg-gray requisites">
              <div className="lk-form__row-content">

                <Tabs getActiveTab={setActiveTab}>
                  <section label={'Организация'}>
                    <div className="requisites__form">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available"
                               htmlFor="requisitsOrganizationCompanyName">
                          <span className="input__label">Название организации</span>
                          <input
                            id="requisitsOrganizationCompanyName"
                            type="text"
                            name="requisitsOrganizationCompanyName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название организации",
                              })
                            }
                            autoComplete="off"
                          />
                          {errors.requisitsOrganizationCompanyName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Полное наименование организации</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyFullName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyFullName ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }
                          />

                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ИНН</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyInn"
                            className={"input__control" + (errors.requisitsOrganizationCompanyInn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Инн должен состоять из 10 цифр",
                                minLength: {value: 10},
                                maxLength: {value: 10}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyInn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyInn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">КПП</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyKpp"
                            className={"input__control" + (errors.requisitsOrganizationCompanyKpp ? " input__error" : "")}
                            ref={
                              register({
                                required: "Кпп должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyKpp &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyKpp.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ОГРН</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyOgrn"
                            className={"input__control" + (errors.requisitsOrganizationCompanyOgrn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ОГРН организации",
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyOgrn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyOgrn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Ген. директор</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyDirector"
                            className={"input__control" + (errors.requisitsOrganizationCompanyDirector ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }


                          />
                          {errors.requisitsOrganizationCompanyDirector &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyDirector.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Юридический адрес</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyAddress"
                            className={"input__control" + (errors.requisitsOrganizationCompanyAddress ? " input__error" : "")}
                            ref={
                              register({
                                required: 'Укажите юредический адрес организации',
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyAddress &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyAddress.message}</p>}
                        </label>
                      </div>


                    </div>
                    <p className="requisites__form-title requisites__form-title_bank">Банковские реквизиты</p>
                    <div className="requisites__form requisites__form_bank">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Наименование банка</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyBankName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyBankName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название банка",
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyBankName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyBankName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">БИК</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyBankBik"
                            className={"input__control" + (errors.requisitsOrganizationCompanyBankBik ? " input__error" : "")}
                            ref={
                              register({
                                required: "БИК банка должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyBankBik &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyBankBik.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Расчетный счёт</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyAccNum"
                            className={"input__control" + (errors.requisitsOrganizationCompanyAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Расчетный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyAccNum.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кор. счёт</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyСorAccNum"
                            className={"input__control" + (errors.requisitsOrganizationCompanyСorAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Корпоративный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyСorAccNum &&
                            <p
                              style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyСorAccNum.message}</p>}
                        </label>
                      </div>

                    </div>
                  </section>
                  <section label={'ИП'}>
                    <div className="requisites__form">


                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Фамилия</span>
                          <input
                            type="text"
                            name="requisitsIpLastname"
                            className={"input__control" + (errors.requisitsIpLastname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите Фамилию",
                              })
                            }
                          />
                          {errors.requisitsIpLastname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpLastname.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Имя</span>
                          <input
                            type="text"
                            name="requisitsIpFirstname"
                            className={"input__control" + (errors.requisitsIpFirstname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите имя",
                              })
                            }
                          />
                          {errors.requisitsIpFirstname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpFirstname.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Отчество</span>
                          <input
                            type="text"
                            name="requisitsIpSecondname"
                            className={"input__control" + (errors.requisitsIpSecondname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите имя",
                              })
                            }
                          />
                          {errors.requisitsIpSecondname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpSecondname.message}</p>}
                        </label>
                      </div>
                       {/*required: "Укажите ИНН (12 цифр)",*/}
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ИНН</span>
                          <input
                            type="number"
                            name="requisitsIpInn"
                            className={"input__control" + (errors.requisitsIpInn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ИНН (12 цифр)",
                                // pattern: {
                                //   value: /^[0-9]{12}$/,
                                //   message: 'Укажите ИНН (12 цифр)',
                                // },
                                minLength: {value: 12},
                                maxLength: {value: 12}
                              })
                            }
                          />
                          {errors.requisitsIpInn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpInn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ОГРНИП</span>
                          <input
                            type="number"
                            name="requisitsIpOgrn"
                            className={"input__control" + (errors.requisitsIpOgrn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ОГРНИП (15 цифр)",
                                minLength: {value: 15},
                                maxLength: {value: 15}
                              })
                            }
                          />
                          {errors.requisitsIpOgrn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpOgrn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Адрес регистрации</span>
                          <input
                            type="text"
                            name="requisitsIpAddress"
                            className={"input__control" + (errors.requisitsIpAddress ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <p className="requisites__form-title requisites__form-title_bank">Банковские реквизиты</p>
                    <div className="requisites__form requisites__form_bank">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Наименование банка</span>
                          <input
                            type="text"
                            name="requisitsIpCompanyBankName"
                            className={"input__control" + (errors.requisitsIpCompanyBankName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название банка",
                              })
                            }
                          />
                          {errors.requisitsIpCompanyBankName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyBankName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">БИК</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyBankBik"
                            className={"input__control" + (errors.requisitsIpCompanyBankBik ? " input__error" : "")}
                            ref={
                              register({
                                required: "БИК банка должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyBankBik &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyBankBik.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Расчетный счёт</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyAccNum"
                            className={"input__control" + (errors.requisitsIpCompanyAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите счет должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyAccNum.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кор. счёт</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyСorAccNum"
                            className={"input__control" + (errors.requisitsIpCompanyСorAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Корпоративный счет должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyСorAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyСorAccNum.message}</p>}
                        </label>
                      </div>

                    </div>
                  </section>
                </Tabs>


                <div className="create-company__action-btns" style={{marginRight: 20}}>
                  <Button buttonType="secondary" onClick={close}>Отменить</Button>

                  <span
                    className="button button_view_default"
                    isLoading={isFetching}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {isEdited ? 'Редактировать реквизиты' : 'Добавить реквизиты'}
                  </span>
                </div>
              </div>

            </div>

          </form>}
    </>
  )
}

export default RequisitesCompany
