import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchClients } from "../../actions/clients";
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from "../../actions/favorites";
import {
  clearSecondary,
  fetchSecondary
} from "../../actions/secondary";
import usePermission from '../../hooks/usePermission';
import MortgageBlock from "../Apartment/MortgageBlock";
import Preloader from "../Preloader";
import About from './About';
import SecondaryInfo from "./SecondaryInfo";
import Similar from "./Similar";

const View = (props) => {
  const { id } = useParams();
  const city = "spb";

  const {
    fetch,
    reset,
    isFetching,
    data,
    manager,
    banks,
    error,
    subways,
    clientMode,
    addToFavorites,
    addClientToFavorite,
    removeClientFromFavorite,
    removeFromFavorites,
    clients,
    fetchClients,
    similar
  } = props;
  const isAgent = !usePermission("motivation")
  useEffect(() => {
    document.title = `${data ? `${data.roomType?.name? data.roomType?.name: data.objectType?.name}. ${data.squareTotal} м² ${data.address ? "по адресу " + data.address : ""}` : ""
      }`;
  }, [data]);
  useEffect(() => {
    fetch(id, city);
    fetchClients();
    return () => {
      reset();
    };
  }, [id]);

  if (error) return "Error";
  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFromFavorites("spb", id, "secondary", "secondary")
      : addToFavorites("spb", id, "secondary", "secondary");
  };

  return (
    <>
      {data && (
        <SecondaryInfo
          data={data}
          manager={manager}
          subwaysList={subways}
          city={city}
          addToFavorites={addToFavorites}
          clientMode={clientMode}
          addClientToFavorite={addClientToFavorite}
          removeClientFromFavorite={removeClientFromFavorite}
          handleFavorites={handleFavorites}
          clients={clients}
          fetchClients={fetchClients}
          isAgent={isAgent}
          isSecondary={true}
          presentationType={"secondary"}
        />
      )}
      {data && data.description &&
        <About
          title={`Описание`}
          previewText={data.description}
        />}
      {data && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="secondary"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
          showInstallment={false}
        />
      )}
      {similar && <Similar items={similar} city={"spb"} />}
      <Preloader isLoading={isFetching || !data} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, city) => dispatch(fetchSecondary(id, city)),
  reset: () => dispatch(clearSecondary()),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, "secondary")),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, "secondary")),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, "secondary", type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        "secondary",
        type,
      ),
    ),
  fetchClients: () => dispatch(fetchClients()),
});

const mapStateToProps = (state) => ({
  data: state.secondary.data,
  similar: state.secondary.similar,
  isFetching: state.secondary.isFetching,
  error: state.secondary.error,
  manager: state.secondary.manager,
  banks: state.secondary.banks,
  subways: state.secondary.subways,
  clientMode: state.general?.clientMode,
  clients: state.clients?.data,

});

export default connect(mapStateToProps, mapDispatchToProps)(View);
