import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Map, ObjectManager, YMaps, ZoomControl } from "react-yandex-maps";
import distMsk from "../assets/data/distMsk.json";
import distSpb from "../assets/data/distSpb.json";
import useMedia from "../hooks/useMedia";

const mskCenter = [55.753994, 37.622093];
const spbCenter = [59.95601547, 30.33519484];

const DistrictsMap = (props) => {
  const { closePopup, city, setDistricts, selectedDistricts } = props;
  const [objectManagerRef, setObjManager] = useState(null);
  const [isInitMap, setIsInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const isMobile = useMedia("isMobile")
  const districts = useMemo(() => {
    return city === "spb" ? distSpb : distMsk;
  }, [city]);
  const mapLoad = (ymaps) => {
    setIsInitMap(true);
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "add":
        return [...state, action.payload];
      case "reset":
        return action.payload;
      default:
        throw new Error();
    }
  };
  const getInitialFilters = () => {
    return selectedDistricts.map((id) => {
      const obj = {
        id: "",
        label: "Район",
        value: "",
        inc: true,
      };
      let finded = districts.features.find((dist) => dist.id == id);
      if (!finded) {
        return;
      }
      obj.id = finded.id;
      obj.value = finded.properties.hintContent;
      return obj;
    });
  };
  const [filters, setFilters] = useReducer(reducer, getInitialFilters());
  const filtersRef = useRef(filters);
  useEffect(() => {}, [filters]);
  useEffect(() => {
    setShowMap(true);
    return () => {
      if(!objectManagerRef){
        return
      }
      filtersRef.current = [];
      objectManagerRef.objects.each(function (object) {
        objectManagerRef.objects.setObjectOptions(object.id, {
          fillOpacity: 0.4,
        });
    });
      setIsInitMap(false);
    };
  }, [objectManagerRef]);
  const colorizeDists = (id, om) => {
    if (filtersRef.current.map((item) =>{
      if(!item){
        return
      }
      return item.id}).indexOf(id) !== -1) {
      om.objects.setObjectOptions(id, {
        fillOpacity: 0.75,
      });
    } else {
      om.objects.setObjectOptions(id, {
        fillOpacity: 0.4,
      });
    }
  };

  const addFiltered = (id, val) => {
    const obj = {
      id: id,
      label: "Район",
      value: val,
      inc: true,
    };
    if (filtersRef.current.filter((item) => item.id == id).length === 0) {
      filtersRef.current = [...filtersRef.current, obj];
      setDistricts(filtersRef.current.map((dist) => dist.id));
      setFilters({ type: "add", payload: obj });
    } else {
      let tmp = filtersRef.current.filter((item) => item.id != id);
      filtersRef.current = tmp;
      setDistricts(tmp.map((dist) => dist.id));
      setFilters({
        type: "reset",
        payload: tmp,
      });
    }
  };
  const onObjectEvent = (e) => {
    const objectId = e.get("objectId"),
      objectTitle = objectManagerRef.objects.getById(objectId).properties
        .hintContent;

    addFiltered(objectId, objectTitle);
    colorizeDists(objectId, objectManagerRef);
  };
  useEffect(() => {
    if (objectManagerRef) {
      objectManagerRef.events.add("click", onObjectEvent);
      filters.map((item) => {
        const id = item.id;
        colorizeDists(id, objectManagerRef);
      });
      objectManagerRef.objects.events.add("mouseenter", function (e) {
        var objectId = e.get("objectId"),
          overlay = objectManagerRef.objects.overlays.getById(objectId);
        if (filters.filter((item) => item.id === objectId).length === 0) {
          addHover(objectId);
          overlay.events.add("mapchange", unsetHover);
        }
      });

      objectManagerRef.objects.events.add("mouseleave", function (e) {
        var objectId = e.get("objectId"),
          overlay = objectManagerRef.objects.overlays.getById(objectId);

        if (filters.filter((item) => item.id === objectId).length === 0) {
          unsetHover(objectId);
          overlay.events.remove("mapchange", unsetHover);
        }
      });
    }
  }, [objectManagerRef]);

  const unsetHover = (objectId) => {
    objectManagerRef.objects.setObjectOptions(objectId, {
      fillOpacity: 0.4,
    });
    filtersRef.current.map((item) => {
      objectManagerRef.objects.setObjectOptions(item.id, {
        fillOpacity: 0.75,
      });
    });
  };

  const addHover = (objectId) => {
    objectManagerRef.objects.setObjectOptions(objectId, {
      fillOpacity: 0.6,
    });
  };


  return (
    <div class={`modal is-opened for-districts`}>
      <div class="modal__shadow " data-id="dist-map"></div>
      <div class="modal__content">
        <div class="modal__inner">
          {!isMobile &&
            <div class="modal__close " data-id="dist-map" onClick={closePopup}>
              <svg class="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
          }
          <h3 class={`modal__title ${isMobile && `mobile-filters__title`}`}>
            <svg className="icon icon_arrow_up" onClick={closePopup}>
              <use xlinkHref="#arrow_up"></use>
            </svg>
            Карта районов города
          </h3>
          <div class="dist-map" id="dist-map-container">
            {showMap &&  (
              <YMaps>
                <Map
                  defaultState={{
                    center: city === "spb" ? spbCenter : mskCenter,
                    zoom: 9,
                  }}
                  className="complex__map-wrap"
                  onLoad={mapLoad}
                >
                  <ObjectManager
                    instanceRef={(inst) => setObjManager(inst)}
                    objects={{
                      fillOpacity: 0.75,
                    }}
                    clusters={{
                      preset: "islands#redClusterIcons",
                    }}
                    // filter={(object) => object.id % 2 === 0}
                    defaultFeatures={districts}
                    modules={["objectManager.addon.objectsHint"]}
                  />
                  <ZoomControl options={{ float: "right" }} />
                </Map>
              </YMaps>
            )}
            {!isInitMap && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <object
                  style={{ width: "120px" }}
                  type="image/svg+xml"
                  data="/assets/images/pan-preloader.svg"
                >
                  <img src="/assets/images/pan-preloader.svg" alt="preloader" />
                </object>
              </div>
            )}

            {isMobile &&
              <div className="mobile-filters__submit">
                <button
                  className="button button__mobile-filters"
                  onClick={(e) => props.clearFilters(e)}
                >
                  Очистить
                </button>
                <button
                  className="button button__mobile-filters"
                  onClick={closePopup}
                >
                  Применить
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictsMap;