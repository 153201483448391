import React, { memo, useState } from "react";

const Seo = memo(props => {
  const {
    propsCreator,
    yandexVerification,
    googleVerification,
  } = props;

  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="admin__input-block admin__list-tabs domens">
      <div className="admin__list-container admin__list-container_lg">
        <div className={`admin__list-tab${activeTab == 1 ? ` active` : ``}`} onClick={() => setActiveTab(1)}>
          <span>Яндекс.Вебмастер</span>
        </div>
        <div className={`admin__list-tab${activeTab == 2 ? ` active` : ``}`} onClick={() => setActiveTab(2)}>
          <span>Google Console</span>
        </div>

      </div>

      <div className={`admin__list-box${activeTab == 1 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt admin__input-row_flex_column">
          <div className="admin__input-block admin__input-block_lg admin__input-block_with-anchor">
            <label htmlFor="" className="admin__label">
              Верификация домена
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Для подтверждения ваших прав на домен, добавьте метатег из личного кабинета</p>
                </div>
              </div>
            </label>
            <input type="text"
              className="admin__input"
              value={yandexVerification ? yandexVerification : ""}
              {...propsCreator("yandexVerification")}
              placeholder={`<meta name="yandex-verification" content="ваш уникальный код" />`}
              style={{width: "100%"}}
            />
          </div>
          <a href="https://teletype.in/@toprealtors/F5-Gn5Jebd9" className="admin__anchor admin__anchor_red admin__anchor_mt-5" target="_blank" rel="noreferrer">Как верифицировать домен?</a>

        </div>
      </div>

      <div className={`admin__list-box${activeTab == 2 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt admin__input-row_flex_column">
          <div className="admin__input-block admin__input-block_lg admin__input-block_with-anchor">
            <label htmlFor="" className="admin__label">
              Верификация домена
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Для подтверждения ваших прав на домен, добавьте метатег из личного кабинета</p>
                </div>
              </div>
            </label>
            <input type="text"
              className="admin__input"
              value={googleVerification ? googleVerification : ""}
              {...propsCreator("googleVerification")}
              placeholder={`<meta name="google-site-verification" content="ваш уникальный код" />`}
              style={{width: "100%"}}
            />
          </div>
          <a href="https://teletype.in/@toprealtors/F5-Gn5Jebd9" className="admin__anchor admin__anchor_red admin__anchor_mt-5" target="_blank" rel="noreferrer">Как верифицировать домен?</a>
        </div>
      </div>
    </div>
  );
});

export default Seo;