import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import promoSvg from '../../assets/images/hp/furnish/main.svg';
import useMedia from '../../hooks/useMedia';
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import SwiperSlider from '../../components/core/SwiperSlider';
import { getUrl } from '../../api';
import InputMask from 'react-input-mask';
import SubmitButton from '../../components/SubmitButton';
import PanModal from '../../components/core/PanModal';
import { useModal } from 'react-modal-hook';
import { scrollToRef } from '../../utils';
import {
  errorToast,
  standartErrorToast,
  successToast,
} from '../../components/toasts';
import useUniversalForm from '../../hooks/useUniversalForm';
import { useSelector } from 'react-redux';

const Furnish = () => {
  const isMobile = useMedia('isMobile'); 

  const userInfo = useSelector((state) => state.user?.info);
  const errors = false;

  const [openModal, closeModal] = useModal(
    () => (
      <PanModal title={'Видео'} closeModal={closeModal}>
        <iframe
          src="https://www.youtube.com/embed/d5GWIo2Y3u8?autoplay=1&mute=1"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </PanModal>
    ),
    [],
  );

  const { send, setValue, getValue, clearForm, formSettings, form } = useUniversalForm({
    formId: 63,
    defaultValues: {
      name: userInfo?.name ? `${userInfo.name} ${userInfo.lastName}` : '',
      phone: userInfo?.phone || '',
    },
    getSettingsAction: 'getFormSettingsPublic',
    sendAction: 'sendFormPublic',
    defaultCheckboxes: ["personal_data"],
  });

  const handleSend = (params) => {
    send().then(() => {
      successToast('Заявка отправлена');
      clearForm();
    }, standartErrorToast);
  };

  const [openForm, closeForm] = useModal(() => {
    return (
      <PanModal title={'Оставить заявку'} closeModal={closeForm}>
        <div className="hp__form-content hp__form-content_no-bg">
          <form className="form" name="">
            <div className="hp__form-item">
              <label htmlFor="modal-name" className="hp__form-label text">
                Имя
              </label>
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-name"
              >
                <input
                  id="modal-name"
                  name="name"
                  value={getValue('name')}
                  className={
                    'input__control' + (errors.name ? ' input__error' : '')
                  }
                  placeholder="Ваше имя"
                  onChange={(e) => setValue(e.target.value, 'name')}
                />
                {errors.name && (
                  <span className="input__error-label">{errors.name.name}</span>
                )}
              </label>
            </div>

            <div className="hp__form-item">
              <label htmlFor="modal-phone" className="hp__form-label text">
                Телефон
              </label>
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-phone"
              >
                <InputMask
                  id="modal-phone"
                  className={
                    'input__control' + (errors.phone ? ' input__error' : '')
                  }
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  name="phone"
                  placeholder="Ваш телефон"
                  value={getValue('phone')}
                  onChange={(e) => setValue(e.target.value, 'phone')}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.phone}
                  </span>
                )}
              </label>
            </div>
           
            {formSettings?.find(setting => setting.code === "personal_data")?.items?.map((item, i) => {
              return <div className="hp__form-item" key={i}>
                <div className="checkbox checkbox_view_red">
                  <div className={'checkbox__box'}>
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-reservation-agreement"
                      name="agree"
                      onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                      value={item.value}
                      defaultChecked
                      required
                      checked={!!form?.[item?.fieldName]?.includes(item.value)}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-reservation-agreement"
                    ></label>
                    <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                  </div>
                  <label
                    className="checkbox__label"
                    htmlFor="modal-reservation-agreement"
                  >
                    Отправляя заявку вы соглашаетесь на обработку{' '}
                    <Link
                      to="/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      персональных данных
                    </Link>
                  </label>
                </div>
              </div>
            })}

            <div className="hp__form-item">
              <SubmitButton
                type="button"
                className="hp__submit hp__submit_mb-10 button button_view_default"
                onClick={handleSend}
              >
                Оставить заявку
              </SubmitButton>
            </div>
          </form>
        </div>
      </PanModal>
    );
  }, [formSettings, form]);

  const itemsRef = useRef();

  const photos = [
    {
      title: 'pic',
      id: 1,
    },
    {
      title: 'pic',
      id: 2,
    },
    {
      title: 'pic',
      id: 3,
    },
    {
      title: 'pic',
      id: 4,
    },
    {
      title: 'pic',
      id: 5,
    },
    {
      title: 'pic',
      id: 6,
    },
  ];

  return (
    <>
      <section className="hp__promo">
        <div className="wrapper">
          <object
            type="image/svg+xml"
            className="hp__promo-img"
            data={promoSvg}
            alt=""
          />

          <div className="wrapper__center">
            <div className="hp__promo-content hp__promo-content_self hp__promo-content_furnish">
              <span className="hp__label color-red caps">пан партнер</span>
              <h1 className="hp__h1 h1">Меблировка под ключ</h1>
              <p className="hp__sign color-brand-3 big-text">
                Предложите клиенту максимум — комплексное оснащение квартиры для
                жизни или сдачи в аренду
              </p>
              <div className="hp__promo-btns">
                <button
                  type="button"
                  className="hp__promo-btn button button_view_default"
                  onClick={openForm}
                >
                  Оставить заявку
                </button>
                <button
                  type="button"
                  className="hp__promo-btn hp__promo-btn_second hp__promo-btn_bold button button_view_outline"
                  onClick={() => scrollToRef(itemsRef)}
                >
                  Узнать больше
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="wrapper">
        <div className="wrapper__center">
          <section className="hp__capa hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Забота о клиенте может быть легкой!</h2>
            </div>

            <p className="hp__subtitle color-brand-3 big-text">
              Быть лучшим риелтором — просто.
              <br />
              Закройте все потребности вашего клиента без усилий — расскажите
              про удобный сервис меблировки квартиры.
            </p>
          </section>

          <section className="hp__capa hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Готовое и комфортное решение</h2>
            </div>

            <section className="sf-slider hp__slider">
              <div className="swiper-pan">
                <SwiperSlider
                  thumbs={photos}
                  items={photos}
                  wrap={(item) => (
                    <div className="sf-slide">
                      <img
                        src={require(`../../assets/images/hp/furnish/${item.id}.jpg`).default}
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = houseEmpty;
                        }}
                        alt={item.title}
                      />
                    </div>
                  )}
                  navigation={photos?.length > 1}
                />
              </div>
            </section>
          </section>

          <section className="hp__banner hp__banner_black" ref={itemsRef}>
            <div className="hp__banner-content">
              <h2 className="h3 hp__banner-h3">
                Вашим клиентам не придется тратить время и силы, чтобы получить
                идеальную квартиру с техникой и мебелью!
              </h2>
            </div>

            <div className="hp__banner-nav">
              <button className="hp__banner-btn button button_view_default" onClick={openForm}>
                Подробнее
              </button>
            </div>
          </section>

          <section className="hp__develop hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">
                Все продумано — и уже включено в сервис:
              </h2>
            </div>
            <div className="hp__develop-grid hp__develop-grid_furnish">
              {DevelopItems.map((item) => (
                <span
                  className="hp__develop-item hp__develop-item_flex hp__develop-item_no-hover"
                  key={`about-${item.id}`}
                >
                  <div className="hp__develop-square">
                    <img
                      className="hp__develop-pic"
                      src={
                        require(`../../assets/images/hp/furnish/icons/${item.id}.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>

                  <div className="hp__develop-content">
                    <h5 className="h5">{item.title}</h5>
                  </div>
                </span>
              ))}
            </div>

            {
              // isMobile && <a href="#" className="button hp__develop-all" target={`_blank`}>Все сервисы</a>
            }
          </section>

          <section className="hp__banner hp__banner_gray">
            <div className="hp__banner-content">
              <h2 className="h3 hp__banner-h3">
                Все это за 14 дней!
                <span className="hp__star h3">*</span>
              </h2>

              <p className="hp__banner-descr big-text color-brand-3">
                <span className="color-red">*</span> срок от передачи ключей до
                выполнения работ{' '}
              </p>
            </div>

            <div className="hp__banner-nav">
              <button className="hp__banner-btn button button_view_default" onClick={openForm}>
                Оставить заявку
              </button>
            </div>
          </section>

          <section className="hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Красивый и быстрый результат</h2>
            </div>

            <div className="hp__youtube" onClick={openModal}>
              <img
                src={
                  require(`../../assets/images/hp/furnish/youtube.jpg`).default
                }
                alt=""
              />
            </div>
          </section>

          <section className="hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Почему еще это удобно и выгодно</h2>
            </div>

            <div className="hp__blocks">
              <div className="hp__block h5">
                Практичный, дизайнерский интерьер всего за 14 дней
              </div>
              <div className="hp__block h5">
                Без нервов, поездок по магазинам и лишних трат для клиента
              </div>
              <div className="hp__block h5">
                Расходы и комплектация известны заранее — цена под ключ
              </div>
              <div className="hp__block h5">Точный срок готовности</div>
              <div className="hp__block h5">
                Ожидание соответствует реальности — успех у арендатора
              </div>
            </div>
          </section>

          <section className="hp__banner hp__banner_form  hp__banner_plant_1">
            <div className="hp__banner-content">
              <h2 className="h3 hp__banner-h3">
                Предложите надежный сервис и получите довольного клиента!
              </h2>
            </div>
            <form action="#" className="sor__form">
              <div className="sor__form-box">
                <div className="sor__form-item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-reservation-name"
                  >
                    <input
                      id="modal-reservation-name"
                      name="name"
                      value={getValue('name')}
                      className={
                        'input__control' + (errors.name ? ' input__error' : '')
                      }
                      placeholder="Ваше имя"
                      onChange={(e) => setValue(e.target.value, 'name')}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.name}
                      </span>
                    )}
                  </label>
                </div>

                <div className="sor__form-item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-reservation-phone"
                  >
                    <InputMask
                      id="modal-reservation-phone"
                      className={
                        'input__control' + (errors.phone ? ' input__error' : '')
                      }
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                      name="phone"
                      placeholder="Ваш телефон"
                      value={getValue('phone')}
                      onChange={(e) => setValue(e.target.value, 'phone')}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.phone}
                      </span>
                    )}
                  </label>
                </div>

                <SubmitButton
                  type="button"
                  className="sor__submit button button_view_default"
                  onClick={handleSend}
                >
                  Оставить заявку
                </SubmitButton>
              </div>
              {formSettings?.find(setting => setting.code === "personal_data")?.items?.map((item, i) => {
              return <div className="sor__form-footer" key={i}>
              <div className="checkbox checkbox_view_red">
                <div className={'checkbox__box'}>
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement-1"
                    name="agree-1"
                    onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                    value={item.value}
                    defaultChecked
                    required
                    checked={!!form?.[item?.fieldName]?.includes(item.value)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement-1"
                  ></label>
                  <div
                    className="checkbox__marker"
                    style={{ zIndex: 0 }}
                  ></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement-1"
                >
                  Отправляя заявку вы соглашаетесь на обработку{' '}
                  <Link
                    to="/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    персональных данных
                  </Link>
                </label>
              </div>
            </div>
            })}
            </form>
          </section>

          <section className="hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Комплектация</h2>
            </div>
            <div className="hp__table">
              <div className="hp__table-grid">
                <h3 className="h3">Lite</h3>
                <h3 className="h3">Home</h3>
              </div>

              <div className="hp__table-row">
                <div className="hp__table-header">
                  <h4 className="h4 hp__table-h4">Прихожая</h4>
                </div>
                <div className="hp__table-grid">
                  <ul className="hp__table-item">
                    <li>Зеркало настенное</li>
                    <li>Вешалка настенная</li>
                    <li>Обувница</li>
                    <li>Двустворчатый шкаф</li>
                    <li>Светильник</li>
                  </ul>

                  <ul className="hp__table-item">
                    <li>Зеркало настенное</li>
                    <li>Вешалка настенная</li>
                    <li>Банкетка+обувница</li>
                    <li>Трехстворчатый шкаф с ящиками</li>
                    <li>Открытый стеллаж</li>
                    <li>Светильник</li>
                  </ul>
                </div>
              </div>

              <div className="hp__table-row">
                <div className="hp__table-header">
                  <h4 className="h4 hp__table-h4">Ванная</h4>
                </div>
                <div className="hp__table-grid">
                  <ul className="hp__table-item">
                    <li>Зеркало настенное с полкой</li>
                    <li>Стиральная машина</li>
                  </ul>

                  <ul className="hp__table-item">
                    <li>Шкаф подвесной с зеркалом</li>
                    <li>Стиральная машина</li>
                  </ul>
                </div>
              </div>

              <div className="hp__table-row">
                <div className="hp__table-header">
                  <h4 className="h4 hp__table-h4">Кухня</h4>
                </div>
                <div className="hp__table-grid">
                  <ul className="hp__table-item">
                    <li>Холодильник мини</li>
                    <li>Кухонный гарнитур прямой</li>
                    <li>Вытяжка подвесная</li>
                    <li>Мойка врезная со смесителем</li>
                    <li>Электрическая варочная панель на 2 конфорки</li>
                    <li>Микороволновая печь</li>
                    <li>Столешница из ЛДСП 38 мм</li>
                    <li>Стол обеденный</li>
                    <li>2 пластиковых стула</li>
                    <li>Светильник</li>
                  </ul>

                  <ul className="hp__table-item">
                    <li>Холодильник с морозильником</li>
                    <li>Кухонный гарнитур угловой с подсветкой</li>
                    <li>Встраиваемая вытяжка</li>
                    <li>Мойка врезная с крылом, смеситель</li>
                    <li>Электрическая варочная панель на 2 конфорки</li>
                    <li>Микороволновая печь</li>
                    <li>Столешница из ЛДСП 38 мм</li>
                    <li>Стол полубарный</li>
                    <li>2 стула с мягкой обивкой</li>
                    <li>Светильник</li>
                  </ul>
                </div>
              </div>

              <div className="hp__table-row">
                <div className="hp__table-header">
                  <h4 className="h4 hp__table-h4">Комната</h4>
                </div>
                <div className="hp__table-grid">
                  <ul className="hp__table-item">
                    <li>Кровать двуспальная</li>
                    <li>Матрас</li>
                    <li>Тумба прикроватная</li>
                    <li>Комод</li>
                    <li>LCD TV</li>
                    <li>Стол письменный</li>
                    <li>Карниз для штор</li>
                    <li>Светильник LED</li>
                  </ul>

                  <ul className="hp__table-item">
                    <li>Кровать двуспальная c cистемой хранения</li>
                    <li>Матрас ортопедический</li>
                    <li>Тумба прикроватная</li>
                    <li>Комод</li>
                    <li>Smart TV 32"</li>
                    <li>Рабочий стол с ящиками</li>
                    <li>Мягкое кресло</li>
                    <li>Карниз для штор</li>
                    <li>Светильник LED</li>
                  </ul>
                </div>
              </div>

              <div className="hp__table-grid hp__table-row">
                <h3 className="h3">369 000 руб.</h3>
                <h3 className="h3">449 000 руб.</h3>
              </div>
            </div>
          </section>

          <section className="hp__banner hp__banner_form hp__banner_plant_2 hp__banner_main">
            <div className="hp__banner-content">
              <h2 className="h3 hp__banner-h3">
                Готовый интерьер для квартиры клиента — это легко, выгодно и
                быстро!
              </h2>
            </div>
            <form action="#" className="sor__form">
              <div className="sor__form-box">
                <div className="sor__form-item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-reservation-name"
                  >
                    <input
                      id="modal-reservation-name"
                      name="name"
                      value={getValue('name')}
                      className={
                        'input__control' + (errors.name ? ' input__error' : '')
                      }
                      placeholder="Ваше имя"
                      onChange={(e) => setValue(e.target.value, 'name')}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.name}
                      </span>
                    )}
                  </label>
                </div>

                <div className="sor__form-item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-reservation-phone"
                  >
                    <InputMask
                      id="modal-reservation-phone"
                      className={
                        'input__control' + (errors.phone ? ' input__error' : '')
                      }
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                      name="phone"
                      placeholder="Ваш телефон"
                      value={getValue('phone')}
                      onChange={(e) => setValue(e.target.value, 'phone')}
                    />
                    {errors.name && (
                      <span className="input__error-label">
                        {errors.name.phone}
                      </span>
                    )}
                  </label>
                </div>

                <SubmitButton
                  type="button"
                  className="sor__submit button button_view_default"
                  onClick={handleSend}
                >
                  Оставить заявку
                </SubmitButton>
              </div>
              {formSettings?.find(setting => setting.code === "personal_data")?.items?.map((item, i) => {
              return <div className="sor__form-footer" key={i}>
              <div className="checkbox checkbox_view_red">
                <div className={'checkbox__box'}>
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement-2"
                    name="agree-2"
                    onChange={e => setValue(e.target.checked, item?.fieldName, e.target.value)}
                    value={item.value}
                    defaultChecked
                    required
                    checked={!!form?.[item?.fieldName]?.includes(item.value)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement-2"
                  ></label>
                  <div
                    className="checkbox__marker"
                    style={{ zIndex: 0 }}
                  ></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement-2"
                >
                  Отправляя заявку вы соглашаетесь на обработку{' '}
                  <Link
                    to="/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    персональных данных
                  </Link>
                </label>
              </div>
            </div>})}
              
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

const DevelopItems = [
  {
    id: `1`,
    title: `Контрольные замеры на объекте`,
  },
  {
    id: `2`,
    title: `Подготовка 2D-планировки по чистовым размерам`,
  },
  {
    id: `3`,
    title: `Подбор мебели, техники и комплектующих`,
  },
  {
    id: `4`,
    title: `Заказ, доставка и прием на объекте`,
  },
  {
    id: `5`,
    title: `Полная сборка, подключение и проверка`,
  },
  {
    id: `6`,
    title: `Финальная уборка в квартире`,
  },
];

export default Furnish;
