import floor from 'lodash/floor';
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearAssignments,
  clearFilterAssignments,
  fetchAssignments,
  getCount,
  setFilterAssignments
} from "../../actions/assignments";
import useDocTitle from '../../hooks/useDocTitle';
import { mapAssignments } from "../../selectors/assignments";
import { scrollToRef } from '../../utils';
import Filter from "../Assignation/Filter";
import FilterError from '../ErrorBoundry/FilterError';
import Preloader from "../Preloader";
import FilterDisabled from "../Public/FilterDisabled";
import Items from "./Items";

const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    setFilter,
    clearFilter,
    data,

    features,
    mapItems,
    getCount,
    countToShow,
    clientMode,
    isShowFilter,
    isActive,
    agent,
    savedFilter
  } = props;

  const itemsRef = useRef(null);
  const { savedFilterXmlId } = useParams();
  const city = "spb"
  const handleFilter = (params) => {
    fetch("spb",0, params, savedFilterXmlId).then((data) => {
      scrollToRef(itemsRef, 80);
    });
  };
  useDocTitle("Переуступки Санкт-Петербурга")
  useEffect(() => {
    const noStatistic = new URLSearchParams(window.location.search).get("noStatistic")
    fetch("spb",1, {noStatistic}, savedFilterXmlId);
    return () => {
      reset();
    };
  }, []);
  const correctedFilter = (filter) => {
    if (!filter)
      return {
        filter: {},
        exclude: {},
      };

    const specials = ["isNoFirstFloor", "isApartments", "isOnlyHanded"];
    const result = filter;

    specials.forEach((key) => {
      Object.entries(result).forEach(([identity, value]) => {
        if (Array.isArray(value?.[key]) || !value?.[key]) return;
        result[identity][key] = [{ id: value[key] }];
      });
    });

    return result;
  };
  return (
    <>
      <div class="wrapper">
        <div class="assignations-filter">
          <div class="assignations-filter__btns"></div>
        </div>
      </div>
      {isActive ? (
        <>
        <FilterError    filter={filter}    filterData={filterData}>
          <Filter
            savedFilterXmlId={savedFilterXmlId}
            filterData={filterData}
            savedFilter={correctedFilter(savedFilter)}
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(params) => {
              handleFilter(params);
            }}
            getCount={getCount}
            info={{...data, count: floor(950,-2)}}
            countToShow={countToShow}
            filterType="assignment"
            isShowFilter={isShowFilter}
            isPublic={true}
            city={city}
          />
          </FilterError>
          <Items
            items={data}
            innerRef={itemsRef}
            features={features}
            mapItems={mapItems}
            clientMode={clientMode}
            alternativeLink={`/public/assignments/${savedFilterXmlId}/spb/`}
          />
        </>
      ) : (
        <FilterDisabled agent={agent} />
      )}
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city,isGetFilter, form, savedFilterXmlId) =>
    dispatch(fetchAssignments(city,isGetFilter, form, savedFilterXmlId)),
  reset: () => dispatch(clearAssignments()),
  setFilter: (filter) => dispatch(setFilterAssignments(filter)),
  clearFilter: () => dispatch(clearFilterAssignments()),
  getCount: (params, savedFilterXmlId) => dispatch(getCount(params, savedFilterXmlId)),
});

const mapStateToProps = (state) => ({
  filterData: state.assignments.filterData,
  filter: state.assignments.filter,
  isFetching: state.assignments.isFetching,
  error: state.assignments.error,
  data: state.assignments.data,
  features: mapAssignments(state),
  mapItems: state.assignments.mapItems,
  items: state.assignments.data,
  countToShow: state.assignments.countToShow,
  clientMode: state.general.clientMode,
  isShowFilter: state.assignments.isShowFilter,
  isActive: state.assignments.isActive,
  agent: state.assignments.agent,
  savedFilter: state.assignments.savedFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
