const initialState = {
  error: false,
  fetching: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_REQUEST":
      return { ...state, isAuthorized: false, fetching: true };
    case "REGISTER_SUCCESS":
      return {
        error: false,
        fetching: false,
      };
    case "REGISTER_FAILURE":
      return { ...state, error: true, isAuthorized: false };
    default:
      return state;
  }
};

export default user;
