import React from "react";
import ContactsFilter from "./ContactsFilter";

const ContactsHeader = (props) => {
  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="contacts__header">
          <div className="contacts__title">
            <h1 className="h1">Контакты</h1>
          </div>
          <ContactsFilter {...props} />
        </div>
      </div>
    </section>
  );
};

export default ContactsHeader;
