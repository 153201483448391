import React, { Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Preloader from '../Preloader';
import PublicHeader from '../Public/PublicHeader';
import List from './List';
import View from './View';
import Header from '../Public/markup/Header';
import PublicCommercial from '../../pages/PublicCommercial';
import useRootCSS from "../../hooks/useRootCSS";

// import "./index.css";

const Public = (props) => {
  const { path } = useRouteMatch();
  const { savedFilterXmlId } = useParams();
  const [loading, setLoading] = useState(true);
  const { agent, isFilterEnabled, savedFilterSettings } = props;
  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover,
  });
  let filterType = 'commercials';
  return (
    <div>
      {/* <Helmet>
        <meta property="og:title" content="Подборка ЖК" />
        <meta
          property="og:image"
          content={
            baseUrl + require("../../assets/images/og_image_filters.png").default
          }
        />
      </Helmet> */}
      <Suspense fallback={<Preloader loading={true} />}>
        {agent && isFilterEnabled && (
          <div className="sf wrapper sf_inner-public">
            <Header {...agent} savedFilterSettings={savedFilterSettings} />
          </div>
          // <PublicHeader agent={agent} savedFilterXmlId={savedFilterXmlId} filterType={filterType}/>
        )}
        <Switch>
          <Route exact path={`${path}`} component={PublicCommercial} />
          <Route
            path={`/public/commercials/:savedFilterXmlId/:city/:id`}
            component={View}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    agent: state.commercial.agent || state.commercialUnit.agent,
    isFilterEnabled: state.commercial.isActive || state.commercialUnit.isActive,
    savedFilterSettings: state.commercial.savedFilterSettings || state.commercialUnit.savedFilterSettings,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Public);
