import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { clearAssignment, fetchAssignment } from "../../actions/assignment";
import MortgageBlock from "../Apartment/MortgageBlock";
import AssignationInfo from "../Assignation/AssignationInfo";
import Preloader from "../Preloader";

const View = (props) => {
  const { id, savedFilterXmlId } = useParams();
  const city = "spb";
  const {
    fetch,
    reset,
    isFetching,
    data,
    manager,
    banks,
    error,
    subways,
    clientMode,
    agent,
    client
  } = props;

  useEffect(() => {
    if(!data) return
    const blockName = " в ЖК «" + data.block?.name + "»"
    document.title = `${data.roomType}, ${data.squareTotal} м², № ${data.number}${blockName}`;
  }, [data]);
  
  useEffect(() => {
    fetch(id, city, savedFilterXmlId);
    return () => {
      reset();
    };
  }, []);

  if (error) return "Error";
  return (
    <>
      {data && (
        <AssignationInfo
          data={data}
          manager={manager}
          subwaysList={subways}
          city={city}
          clientMode={clientMode}
          isAgent={props.isAgent}
          disableButtons={true}
          complexLink={`/public/apartments/${savedFilterXmlId}/spb/complex/`}
          agent={agent}
          savedFilterXmlId={savedFilterXmlId}
          client={client}
          isPublic={true}
          presentationType={"apartments_for_assignment"}
        />
      )}
      {data && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="assignment"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
          showInstallment={false}
          isPublic={true}
        />
      )}

      {/*
      <AssignationInfrastructure />
      <AssignationMortgage />
    <AssignationBanks />*/}
      <Preloader isLoading={isFetching || !data} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, city, savedFilterXmlId) => dispatch(fetchAssignment(id, city, savedFilterXmlId)),
  reset: () => dispatch(clearAssignment()),
});

const mapStateToProps = (state) => ({
  data: state.assignment.data,
  isFetching: state.assignment.isFetching,
  error: state.assignment.error,
  manager: state.assignment.manager,
  banks: state.assignment.banks,
  subways: state.assignment.subways,
  clientMode: state.general.clientMode,
  client: state.assignment.client,
  isAgent: state.user?.info?.groups.some((group) => group === "agent" ||  group === "test_access"),
  agent: state.assignment.agent,
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
