export const CarIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.201 6.602L5.101 3.901H11.704L12.605 6.602H4.201ZM11.704 9.60301C11.206 9.60301 10.804 9.20201 10.804 8.70301C10.804 8.20501 11.206 7.80301 11.704 7.80301C12.203 7.80301 12.605 8.20501 12.605 8.70301C12.605 9.20201 12.203 9.60301 11.704 9.60301ZM5.101 9.60301C4.603 9.60301 4.201 9.20201 4.201 8.70301C4.201 8.20501 4.603 7.80301 5.101 7.80301C5.599 7.80301 6.002 8.20501 6.002 8.70301C6.002 9.20201 5.599 9.60301 5.101 9.60301ZM12.557 3.607C12.434 3.252 12.098 3 11.704 3H5.101C4.708 3 4.372 3.252 4.249 3.607L3 7.203V12.005C3 12.335 3.27 12.605 3.6 12.605H4.201C4.534 12.605 4.801 12.335 4.801 12.005V11.405H12.005V12.005C12.005 12.335 12.275 12.605 12.605 12.605H13.205C13.538 12.605 13.805 12.335 13.805 12.005V7.203L12.557 3.607Z"
      fill="#8F95AC"
    />
  </svg>
);

export const WalkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.56682 3.64602C9.29754 3.64602 9.8894 3.05416 9.8894 2.32344C9.8894 1.59273 9.29754 1 8.56682 1C7.83611 1 7.24425 1.59273 7.24425 2.32344C7.24425 3.05416 7.83611 3.64602 8.56682 3.64602ZM11.8017 8.0599L10.2647 7.31096L10.2699 7.30401C9.66782 7.01299 9.52396 6.34423 9.44664 5.98482L9.44664 5.98481C9.43035 5.90909 9.41701 5.8471 9.40297 5.80527C9.39457 5.77979 9.38442 5.74437 9.37203 5.70112C9.26793 5.33786 9.00561 4.42241 8.29214 4.20759C7.21516 3.88215 6.22323 4.74565 6.22323 4.74565L4.76874 5.97189C4.49885 6.18451 4.37648 6.40494 4.32181 6.56375C4.30359 6.60714 4.29231 6.65314 4.28536 6.70174C4.27929 6.73298 4.27668 6.75728 4.27582 6.77377L4.02675 8.97112C3.99464 9.28527 4.22288 9.56645 4.5379 9.59856C4.85206 9.63241 5.13323 9.4033 5.16621 9.08914L5.34412 7.52357L5.35453 7.53139C5.42049 6.93085 5.74158 6.69132 5.74158 6.69132L6.55301 6.03871L6.55908 6.04392L6.02016 8.69254L7.15268 10.4907L9.71365 14.5869C9.94883 14.9635 10.4452 15.0781 10.8219 14.842C11.1985 14.6068 11.3131 14.1113 11.077 13.7338L8.12986 9.01885L8.536 7.03759C8.60996 7.19067 8.69841 7.36931 8.7853 7.54481L8.78536 7.54494L8.78767 7.54961C8.96645 7.90802 9.35003 8.12151 9.5019 8.19528C9.53488 8.22218 9.57046 8.24561 9.60951 8.26384L11.2992 9.08914C11.5838 9.22713 11.9266 9.1091 12.0655 8.82532C12.2035 8.54067 12.0854 8.19788 11.8017 8.0599ZM5.0725 12.0915C5.41616 11.6472 5.43612 11.3947 5.43612 11.3947L5.85007 9.70933L6.84982 11.2636L6.68753 12.0004C6.58686 12.4152 6.39507 12.6061 6.39507 12.6061L6.12344 12.9784L6.1217 12.9776L4.83992 14.7193C4.61862 15.0239 4.19251 15.0908 3.88877 14.8695C3.58416 14.6482 3.51734 14.2229 3.73864 13.9183L5.07944 12.0976L5.0725 12.0915Z"
      fill="#8F95AC"
    />
  </svg>
);