import React, { useEffect } from "react";
import { connect } from "react-redux";
import ym from 'react-yandex-metrika';
import { clickBanner, getBanners } from "../../actions/banners";
import { getUrl } from '../../api';
import usePermission from '../../hooks/usePermission';
import './index.scss';
const PrepaidBanner = (props) => {

  const {
    addClass = "",
    getBanners,
    banners,
    bannerId,
    style
  } = props;

  useEffect(() => {
    if (banners[bannerId]) return
    getBanners(window.location.pathname)
  }, []);
  const isClientMode = usePermission('client')
  if (!banners[bannerId]) {
    return ""
  }
  const openLink = (evt) => {
    ym('reachGoal', banners[bannerId].target);
    evt.preventDefault();
    window.open(getUrl(banners[bannerId].href, "https://panpartner.ru"), "_blank");
    clickBanner({bannerId: banners[bannerId]?.id})
  }

  return (
    !isClientMode && (
      <div className={`prepaid-banner ${addClass}`} style={style}>
        <a href="" rel="noopener noreferrer" onClick={openLink}>
          <object data={getUrl(banners[bannerId]?.banner?.src)} type="image/svg+xml" aria-label="Авансируем ФСК Лидер"></object>
        </a>
      </div>
    )

  );
};
const mapDispatchToProps = (dispatch) => ({
  getBanners: (url) => dispatch(getBanners(url))
});

const mapStateToProps = (state, ownProps) => {
  return {
    banners: ownProps.banners ? ownProps.banners : state.banners
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrepaidBanner);
