import React, {useEffect, useState} from "react";
import './index.scss'
import {
  CabinetClientsIconCompare,
  CabinetClientsIconPresentation,
  CabinetClientsIconSF,
  CabinetClientsIconSite,
  CabinetCrmIconCalendar,
  CabinetCrmIconDeals,
  CabinetCrmIconFixation,
  CabinetCrmIconRecord,
  CabinetFavoritesIconComplexes, CabinetFavoritesIconCottageSettment,
  CabinetFavoritesIconObjects,
  CabinetMenuIconClient,
  CabinetMenuIconCrm,
  CabinetMenuIconFavorites, CabinetMenuIconObjects, CabinetObjectsIconFeed, CabinetObjectsIconHouse,
  MenuCloseIcon,
  MenuCRMIcon,
  MenuExitIcon,
  MenuMotivationIcon,
  MenuNavAdvIcon,
  MenuNavArrowIcon,
  MenuNavBackIcon,
  MenuNavCardIcon,
  MenuNavCaseIcon,
  MenuNavContactsIcon,
  MenuNavEventIcon,
  MenuNavFilesIcon,
  MenuNavLockIcon,
  MenuNavPeoplesIcon,
  MenuNavServicesIcon,
  MenuNavSiteIcon,
  MenuNavTableIcon,
  MenuNavTeachIcon,
  MenuNavWebRewIcon
} from "../../Public/markup/icons";
import {Link} from "react-router-dom";
import ClientMode from "../../Header/ClientMode";
import {logout} from "../../../actions/user";
import {connect} from "react-redux";
import {getUrl} from "../../../api";
import useMedia from "../../../hooks/useMedia";
import SearchById from "../../Header/SearchById";
import useApi from "../../../hooks/useApi";
import usePermission from "../../../hooks/usePermission";
import useClickPast from "../../../hooks/useClickPast";
import {BlockMenu} from "../../../pages/PersonalCabinet";

const Menu = ({isOpen, setOpen, logout, user, servicesMenu, togglerNode}) => {

  const [services, setSercices] = useState(false)
  const showMotivation = usePermission("motivation");
  const showClients = usePermission("agent");
  const isLaptop = useMedia('isLaptop');

  const menuRef = useClickPast(null, ({ target }, { current }) => {
    if (current?.contains?.(target) || target?.closest?.('.menu')) return
    setOpen(false)
    setSercices(false)
  })

  useEffect(() => {
    isOpen ? window.BX?.SiteButton.hide() : window.BX?.SiteButton.show()
  }, [isOpen])

  return (
    <div className={"menu" + (isOpen ? ' is-showing' : '')} ref={menuRef}>

      <div className="menu__title">
        <p className="menu__title-main">Главное меню</p>
        <div className="menu__close-menu" onClick={() => setOpen(false)}><MenuCloseIcon/></div>
      </div>
      {!!isLaptop &&
        <SearchById fullWidth align="left"/>
      }
      <div className="menu-profile">
        <div className="menu-profile__user">
          {user?.logo?.src && user?.logo?.src !== null && user?.logo?.src !== undefined &&
          <div className="menu-profile__user-logo">
            <img src={getUrl(user?.logo?.src)} alt=""/>
          </div>
          }

          <div className="menu-profile__user-contact">
            <p className="menu-profile__user-fio">{user?.lastName + ' ' + user?.name}</p>
            <Link className="menu-profile__user-toprofile" to="/personal/profile">Перейти в профиль</Link>
          </div>
        </div>
        <div className="menu-profile__logout" onClick={() => logout()}>
          <MenuExitIcon/>
        </div>
      </div>

      <ClientMode new/>

      <div className="menu-cards-grid">
        {
          showMotivation &&
          <Link to="/motivation" className="menu-cards-grid__item menu-cards-grid__item_motivation">
            <div className="menu-cards-grid__item-info">
              <p className="menu-cards-grid__item-title">Мотивация</p>
              <p className="menu-cards-grid__item-text">Про деньги</p>
            </div>
            <div className="menu-cards-grid__item-icon"><MenuMotivationIcon/></div>
          </Link>
        }

        <Link to="/crm" className="menu-cards-grid__item menu-cards-grid__item_crm" style={showMotivation ? {}: {width: '100%', marginRight: 0}}>
          <div className="menu-cards-grid__item-info">
            <p className="menu-cards-grid__item-title">CRM</p>
            <p className="menu-cards-grid__item-text">Про клиентов</p>
          </div>
          <div className="menu-cards-grid__item-icon"><MenuCRMIcon/></div>
        </Link>
      </div>

      {/*<nav className="menu-nav menu-nav_menu">*/}
      {/*  {*/}
      {/*    showClients &&*/}
      {/*    <Link to="/personal/agents" className="menu-nav__item">*/}
      {/*      <div className="menu-nav__item-text">*/}
      {/*        <MenuNavPeoplesIcon/>*/}
      {/*        Сотрудники*/}
      {/*      </div>*/}
      {/*      <div className="menu-nav__item-arrow">*/}
      {/*        <MenuNavArrowIcon/>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*  }*/}
      {/*  <Link to="/cases" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavCaseIcon/>*/}
      {/*      Выгодные кейсы*/}
      {/*      <div className="menu-nav__item-new">New</div>*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/videos" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavWebRewIcon/>*/}
      {/*      Вебинары и обучение*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/personal/meetings" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavTableIcon/>*/}
      {/*      Welcome-столы для встреч*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/personal/events" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavEventIcon/>*/}
      {/*      Записи на мероприятия*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/personal/objects" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavAdvIcon/>*/}
      {/*      Объявления в базе ПАН*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/videos?tab=platform" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <MenuNavTeachIcon/>*/}
      {/*      Обучение платформе*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*  <Link to="/constructor-admin" className="menu-nav__item">*/}
      {/*    <div className="menu-nav__item-text">*/}
      {/*      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*        <path d="M12 15.8247C14.1123 15.8247 15.8247 14.1123 15.8247 12C15.8247 9.88767 14.1123 8.17529 12 8.17529C9.88769 8.17529 8.17531 9.88767 8.17531 12C8.17531 14.1123 9.88769 15.8247 12 15.8247Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*        <path d="M16.4377 6.98752C16.6403 7.17379 16.8315 7.36503 17.0114 7.56122L19.1873 7.87251C19.542 8.48792 19.8152 9.14683 20 9.83267L18.6773 11.5931C18.6773 11.5931 18.7007 12.1349 18.6773 12.4058L20 14.1673C19.8158 14.8534 19.5426 15.5124 19.1873 16.1275L17.0114 16.4377C17.0114 16.4377 16.6375 16.8287 16.4377 17.0114L16.1275 19.1873C15.5121 19.542 14.8532 19.8152 14.1673 20L12.4058 18.6773C12.1354 18.7007 11.8635 18.7007 11.5931 18.6773L9.83267 20C9.1466 19.8158 8.48761 19.5426 7.87251 19.1873L7.56122 17.0114C7.36574 16.8255 7.17344 16.6343 6.98752 16.4377L4.81275 16.1275C4.45804 15.5121 4.18484 14.8532 4 14.1673L5.32271 12.4058C5.32271 12.4058 5.29827 11.864 5.32271 11.5931L4 9.83267C4.18418 9.1466 4.45742 8.48761 4.81275 7.87251L6.98752 7.56122C7.17344 7.36467 7.36574 7.17344 7.56122 6.98752L7.87251 4.81275C8.48792 4.45804 9.14683 4.18484 9.83267 4L11.5931 5.32271C11.8635 5.29827 12.1355 5.29827 12.4058 5.32271L14.1673 4C14.8534 4.18418 15.5124 4.45742 16.1275 4.81275L16.4377 6.98752Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
      {/*      </svg>*/}
      {/*      Личный сайт*/}
      {/*    </div>*/}
      {/*    <div className="menu-nav__item-arrow">*/}
      {/*      <MenuNavArrowIcon/>*/}
      {/*    </div>*/}
      {/*  </Link>*/}

      {/*  {!!isLaptop &&*/}
      {/*    <>*/}
      {/*      <Link to="/crm/fixations" className="menu-nav__item">*/}
      {/*        <div className="menu-nav__item-text">*/}
      {/*          <MenuNavLockIcon/>*/}
      {/*          Фиксации*/}
      {/*        </div>*/}
      {/*      </Link>*/}
      {/*      <Link to="/events" className="menu-nav__item">*/}
      {/*        <div className="menu-nav__item-text">*/}
      {/*          <MenuNavEventIcon/>*/}
      {/*          Мероприятия*/}
      {/*        </div>*/}
      {/*      </Link>*/}
      {/*      <Link to="/constructor-admin" className="menu-nav__item">*/}
      {/*        <div className="menu-nav__item-text">*/}
      {/*          <MenuNavSiteIcon/>*/}
      {/*          Личный сайт*/}
      {/*        </div>*/}
      {/*      </Link>*/}
      {/*      <Link to="/files" className="menu-nav__item">*/}
      {/*        <div className="menu-nav__item-text">*/}
      {/*          <MenuNavFilesIcon/>*/}
      {/*          Файлы*/}
      {/*        </div>*/}
      {/*      </Link>*/}

      {/*      {servicesMenu &&*/}
      {/*        <span onClick={() => setSercices(!services)} className="menu-nav__item">*/}
      {/*          <div className="menu-nav__item-text">*/}
      {/*            <MenuNavServicesIcon/>*/}
      {/*            Сервисы*/}
      {/*          </div>*/}
      {/*          <div className="menu-nav__item-arrow">*/}
      {/*            <MenuNavArrowIcon/>*/}
      {/*          </div>*/}
      {/*        </span>*/}
      {/*      }*/}


      {/*      <Link to="/contacts" className="menu-nav__item">*/}
      {/*        <div className="menu-nav__item-text">*/}
      {/*          <MenuNavContactsIcon/>*/}
      {/*          Контакты*/}
      {/*        </div>*/}
      {/*      </Link>*/}

      {/*      {*/}
      {/*        services &&*/}
      {/*        <div className="menu-services">*/}
      {/*          <div className="menu-services__back">*/}
      {/*            <span onClick={() => {setSercices(!services)}} className="menu-services__back-arrow">*/}
      {/*              <MenuNavBackIcon/>*/}
      {/*            </span>*/}
      {/*          </div>*/}

      {/*          {servicesMenu.map((service) => {*/}
      {/*            return (*/}
      {/*            <a href={service.link} target="_blank" className="menu-nav__item" key={service.id}>*/}
      {/*              <div className="menu-nav__item-text">*/}
      {/*                {service.title}*/}
      {/*              </div>*/}
      {/*            </a>*/}
      {/*            )*/}
      {/*          })}*/}

      {/*        </div>*/}
      {/*      }*/}
      {/*    </>*/}
      {/*  }*/}
      {/*</nav>*/}


      <div className="menu-newblock">
        <BlockMenu content={{
          title: 'CRM',
          icon: <CabinetMenuIconCrm />,
          items: [
            {
              to: "/personalcabinet/clients",
              title: "Клиенты",
              icon: <MenuNavPeoplesIcon />
            },
            {
              to: "/personalcabinet/fixations",
              title: "Фиксации",
              icon: <CabinetCrmIconFixation />
            },
            {
              to: "",
              title: "Записи на просмотр",
              icon: <CabinetCrmIconRecord />
            },
            {
              to: "",
              title: "Сделки",
              icon: <CabinetCrmIconDeals />
            },
            {
              to: "",
              title: "Календарь",
              icon: <CabinetCrmIconCalendar />
            },
          ]
        }} />
        <BlockMenu content={{
          title: 'Клиентам',
          icon: <CabinetMenuIconClient />,
          items: [
            {
              to: "",
              title: "Презентации",
              icon: <CabinetClientsIconPresentation />
            },
            {
              to: "",
              title: "Сохраненные фильтры",
              icon: <CabinetClientsIconSF />
            },
            {
              to: "",
              title: "Сравнение",
              icon: <CabinetClientsIconCompare />
            },
            {
              to: "",
              title: "Личный сайт",
              icon: <CabinetClientsIconSite />
            },
          ]
        }} />
        <BlockMenu content={{
          title: 'Избранное',
          icon: <CabinetMenuIconFavorites />,
          items: [
            {
              to: "",
              title: "Объекты",
              icon: <CabinetFavoritesIconObjects />
            },
            {
              to: "",
              title: "Комплексы",
              icon: <CabinetFavoritesIconComplexes />
            },
            {
              to: "",
              title: "Поселки",
              icon: <CabinetFavoritesIconCottageSettment />
            },
          ]
        }} />
        <BlockMenu content={{
          title: 'Объекты',
          icon: <CabinetMenuIconObjects />,
          items: [
            {
              to: "",
              title: "Вторичка",
              icon: <CabinetFavoritesIconComplexes />
            },
            {
              to: "",
              title: "Уступка",
              icon: <CabinetFavoritesIconComplexes />
            },
            {
              to: "",
              title: "Загородка",
              icon: <CabinetObjectsIconHouse />
            },
            {
              to: "",
              title: "Фиды",
              icon: <CabinetObjectsIconFeed />
            },
          ]
        }} />
      </div>
    </div>

  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
