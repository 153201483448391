import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import request from '../api';
import { actionFactory } from './actionUtils';

const component = "panpartner:events.ajax";

const myEventsSelector = (state) => state.events.myEvents;
const filterSelector = (state) => state.events.filter;

export const filteredMyEvents = createSelector(
	[myEventsSelector, filterSelector],
	(myEvents, filter) => {
		let result = myEvents;

		if (filter.agents.length) {
			result = result.filter((item) =>
				includes(filter.agents, `${item.agentId}`),
			);
		}

		if (filter.statuses.length) {
			result = result.filter((item) =>
				includes(filter.statuses, `${item.statusId}`),
			);
		}

		if (filter.date.startDate) {
			result = result.filter((item) => item.date >= filter.date.startDate);
		}

		if (filter.date.endDate) {
			result = result.filter((item) => item.date <= filter.date.endDate);
		}

		return result;
	},
);

const requestEvents = actionFactory("EVENTS_REQUEST");

const successEvents = actionFactory("EVENTS_SUCCESS");

const successEventsPdf = actionFactory("EVENTS_PDF_SUCCESS");

export const clearEvents = actionFactory("EVENTS_RESET");

const failureEvents = actionFactory("EVENTS_FAILURE");

const requestMyEvents = actionFactory("MY_EVENTS_REQUEST");

const successMyEvents = actionFactory("MY_EVENTS_SUCCESS");

export const clearMyEvents = actionFactory("MY_EVENTS_RESET");

export const setFilterMyEvents = (filter) => ({
	type: 'MY_EVENTS_SET_FILTER',
	filter,
});

export const clearFilterMyEvents = actionFactory("MY_EVENTS_CLEAR_FILTER");

export const fetchEvents = city =>
	dispatch => {
		dispatch(requestEvents());
		return request(component, "getEvents", { city })
			.then(
				data => dispatch(successEvents({
					data: data.events,
					eventReports: data.eventReports
				})),
				error => {
					dispatch(failureEvents());
					return Promise.reject(error);
				},
			);
	};

export const fetchEventsPdf = city =>
	dispatch => {
		dispatch(requestEvents());
		return request(component, "getEventsPdf", { city })
			.then(
				data => dispatch(successEventsPdf({ eventsPdf: data.eventsPdf })),
				error => {
					dispatch(failureEvents());
					return Promise.reject(error);
				},
			);
	};

export const fetchMyEvents = city =>
	dispatch => {
		dispatch(requestMyEvents());
		return request(component, "getMyEvents", { city })
			.then(
				data => {
					const { events, filterData } = handleFilter(data);
					dispatch(successMyEvents({ data: events, filterData }));
				},
			);
	};

export const unsubscribeOnEvent = (eventId, agentId, city) =>
	dispatch => request(
		component,
		"unsubscribeOnEvent",
		{
			eventId,
			agentId,
			city,
		}).then(
			data => {
				dispatch(fetchMyEvents());
				return Promise.resolve(data);
			},
			error => {
				dispatch(failureEvents());
				return Promise.reject(error);
			},
		);

const handleFilter = ({ filter, events }) => {
	let items = [];
	let myEvents = [...events.actual, ...events.past];

	myEvents.forEach((item) => {
		let result = [];

		item.subscribedAgents.forEach((agent) => {
			let iDate = new Date(item.date).setHours(0, 0, 0, 0);

			result.push({
				...item,
				agentId: agent.id,
				agentName: agent.name,
				status: agent.status,
				statusColor: agent.statusColor,
				statusId: agent.statusId,
				statusComment: agent.comment,
				date: iDate,
			});
		});

		items = [...items, ...result];
	});

	let minMax = {
		min: new Date(filter.date.range.min),
		max: new Date(filter.date.range.max),
	};

	return {
		filterData: {
			agents: filter.agent,
			statuses: filter.status,
			dates: minMax,
		},
		events: items,
	};
};