import './index.scss';
import { CloseIcon } from './icons';
import { useEffect } from 'react';
import classNames from 'classnames';
import useMedia from "../../../hooks/useMedia";
import {BurgerIcon} from "../../Public/markup/icons";
import Button from "../Button";

const PanModal = ({
  closeModal,
  customClass,
  title = 'Информация',
  isSmall = false,
  children,
  isBig = false,
  hashParams = 'show-modal',
  docTitle = document.title,
  hideBackButton,
  mapView
}) => {
  const keyListener = e => e.key === "Escape" ? closeModal?.() : null
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener("keydown", keyListener)
    let prevTitle = document.title
    if (hashParams) {
      window.history.pushState({}, docTitle, `#&${hashParams}`)
      window.addEventListener("hashchange", closeModal)
    }
    return () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener("keydown", keyListener)
      if (hashParams) {
        window.history.pushState({}, prevTitle, `#`)
        window.removeEventListener("hashchange", closeModal)
      }
    }
  });

  const modalContentClasses = classNames({
    'panmodal__content': true,
    'panmodal__content_sm': isSmall,
    'panmodal__content_lg': isBig,
  });
  const isTablet = useMedia("isTablet")

  return (
    <section className={`panmodal ${customClass}`}  onClick={closeModal}>
    {/*<section className={`panmodal ${customClass}`}   onClick={e => e.stopPropagation()}>*/}
      <div className={'panmodal__wrapper'} onClick={e => e.stopPropagation()}>
        {
          !mapView &&
          <header className="panmodal__header">
            {/*{!hideBackButton && customClass === 'panmodal_mobile content_redesign-mobile' && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="panmodal__close" onClick={closeModal}><path d="M14.5 17L9.5 12L14.5 7" stroke="#2C2E3E" strokeLinecap="round"/></svg>}*/}
            {isTablet && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="panmodal__close" onClick={closeModal}><path d="M14.5 17L9.5 12L14.5 7" stroke="#2C2E3E" strokeLinecap="round"/></svg>}
            <h3 className="h3 panmodal__h3">{title}</h3>
            <button className="button panmodal__btn" onClick={closeModal}>
              <CloseIcon />
            </button>
          </header>
        }
        <div className={!mapView && modalContentClasses}>{children}</div>



      </div>
      {mapView &&
      <div style={{position: 'absolute', right: 30, top: 30}}>
        <Button
          addClass="sf__backbutton"
          buttonType="line"
          onClick={closeModal}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#2c2e3e"
          >
            <path d="M7 7L12 12L17 7" strokeLinecap="round"  />
            <path d="M7 17L12 12L17 17" strokeLinecap="round"  />
          </svg>
          <span>Закрыть</span>
        </Button>
      </div>
      }
    </section>
  );
};

export default PanModal;
