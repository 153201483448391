import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import SimpleBar from "simplebar-react";
import useDropdown from '../../hooks/useDropdown';
import { getUrl } from '../../api';
import _ from "lodash";

const Attendant = (props) => {
  const {item} = props;
  const contacts = item.contacts;
  return (
    <div className="attendats__item contact">
      <div className="attendats__label">{item.type}</div>
      <div className="attendats__content">
        {item.photo.src && (
          <img
            src={getUrl(item.photo.src)}
            className="attendats__photo"
            alt={item.name}
          />
        )}
        <div className="attendats__block">
          <div className="attendats__person">
            <b className="attendats__name">{item.name}</b>
            <p className="attendats__position contact__post">{item.position}</p>
          </div>
          <div className="attendats__contacts contact__contacts-item">
            <a className="link-hover" href={`tel:${contacts.phone}`}>{contacts.phone}</a>
            <a className="link-hover" href={`mailto:${contacts.email}`}>{contacts.email}</a>
          </div>

          <ul className="contact__socials attendats__socials">
            {/* {contacts.wa && (
              <li className="contact__socials-item contact__socials_whatsapp">
                <a
                  className="attendats__contacts-icon button button_view_round-control"
                  href={`https://api.whatsapp.com/send?phone=${contacts.wa}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="icon">
                    <use xlinkHref="#socials_whatsapp"></use>
                  </svg>
                </a>
              </li>
            )} */}
            {contacts.tg && (
              <li className="contact__socials-item contact__socials_tg">
                <a
                  className="attendats__contacts-icon button button_view_round-control"
                  href={`https://t.me/${contacts.tg}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="icon">
                    <use xlinkHref="#socials_tg"></use>
                  </svg>
                </a>
              </li>
            )}
          </ul>
        </div>

      </div>
    </div>
  );
};

const ContactsAttendants = (props) => {

  const {items} = props;

  if (!items) {
    return "";
  }

  const data = [
    ...(items[0].develop ? [{
      type: `Отдел развития`,
      photo: items[0].develop.photo,
      name: items[0].develop.name,
      position: items[0].develop.position,
      contacts: {
        phone: items[0].develop.phone,
        email: items[0].develop.email,
        tg: items[0].develop.telegram,
        wa: items[0].develop.watsApp,
      },
    }]: []),
    ...(items[0].booking ? [{
      type: `Отдел бронирования`,
      photo: items[0].booking.photo,
      name: items[0].booking.name,
      position: items[0].booking.position,
      contacts: {
        phone: items[0].booking.phone,
        email: items[0].booking.email,
        tg: items[0].booking.telegram,
        wa: items[0].booking.watsApp,
      },
    }]: []),
    ...(items[0].mortgage ? [{
      type: `Отдел ипотеки`,
      photo: items[0].mortgage.photo,
      name: items[0].mortgage.name,
      position: items[0].mortgage.position,
      contacts: {
        phone: items[0].mortgage.phone,
        email: items[0].mortgage.email,
        tg: items[0].mortgage.telegram,
        wa: items[0].mortgage.watsApp,
      },
    }]: []),
    ...(items[0].moscow ? [{
      type: `Московский офис`,
      photo: items[0].moscow.photo,
      name: items[0].moscow.name,
      position: items[0].moscow.position,
      contacts: {
        phone: items[0].moscow.phone,
        email: items[0].moscow.email,
        tg: items[0].moscow.telegram,
        wa: items[0].moscow.watsApp,
      },
    }]: []),
    ...(items[0].assignments ? [{
      type: `Отдел переуступок`,
      photo: items[0].assignments.photo,
      name: items[0].assignments.name,
      position: items[0].assignments.position,
      contacts: {
        phone: items[0].assignments.phone,
        email: items[0].assignments.email,
        tg: items[0].assignments.telegram,
        wa: items[0].assignments.watsApp,
      },
    }]: []),
    ...(items[0].legal ? [{
      type: `Юридический отдел`,
      photo: items[0].legal.photo,
      name: items[0].legal.name,
      position: items[0].legal.position,
      contacts: {
        phone: items[0].legal.phone,
        email: items[0].legal.email,
        tg: items[0].legal.telegram,
        wa: items[0].legal.watsApp,
      },
    }]: []),
    ...(items[0].suburban ? [{
      type: `Отдел загородной недвижимости`,
      photo: items[0].suburban.photo,
      name: items[0].suburban.name,
      position: items[0].suburban.position,
      contacts: {
        phone: items[0].suburban.phone,
        email: items[0].suburban.email,
        tg: items[0].suburban.telegram,
        wa: items[0].suburban.watsApp,
      },
    }]: []),
  ];

  const dataArray = _.chunk(data, 2);

  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="contacts__content">
          <div className="contacts__department attendats">
            <div className="contacts__department-title">
              <h2 className="h2">Дежурные сегодня</h2>
            </div>
              {
                dataArray.map((dataArrayItem) => {
                  return (
                    <div className="attendats__row">
                      {dataArrayItem.map((item) => <Attendant item={item} />)}
                    </div>
                  );
                })
              }
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactsAttendants;
