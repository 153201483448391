import React from 'react';

const Controls = (props) => {
    const {
      saveFilterModal,
      handleClearFilter,
      handleFilterClick,
      countBlocksToShow,
      fetching,
      isHomePage,
      isMobile,
      altButtonTitle,
      isPublic
    } = props;

    if (!isHomePage) {
      return (
        <div>
          <div className="index-search__filter-controls">
            {/* <div className="index-search__filter-control index-search__filter-voice">
    <button className="button button_view_round">
      <svg className="icon icon_mic">
        <use xlinkHref="#mic"></use>
      </svg>
    </button>
    <span>Голосовой помощник</span>
  </div> */}
            <div className="index-search__filter-control index-search__filter-options">
              {!isPublic && <button
                className="button button_view_outline"
                onClick={(e) => {
                  e.preventDefault();
                  saveFilterModal();
                }}
              >
                Сохранить фильтр
              </button>}
              {/* <button className="button button_type_settings">
        <svg className="icon icon_settings">
          <use xlinkHref="#settings"></use>
        </svg>
      </button> */}
            </div>
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_mobile"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
            <a
              className="index-search__filter-control index-search__filter-submit"
              href="#filter-results"
            >
              {countBlocksToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={handleFilterClick}
                  disabled={fetching ? true : false}
                >
                  {altButtonTitle? altButtonTitle(countBlocksToShow) : `Показать ${countBlocksToShow} ЖК`}
                </button>
              ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled
                  >
                    Ничего не найдено
                  </button>
                )}
            </a>

            <div className="index-search__filter-control index-search__filter-options">
              <button
                onClick={handleClearFilter}
                className="button button_type_clear visible_note"
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            </div>
          </div>
          <div className="index-search__filter-controls-m">
            <div className="index-search__filter-controls-col">
              {/* <div className="index-search__filter-control index-search__filter-voice">
                      <button className="button button_view_round">
                        <svg className="icon icon_mic">
                          <use xlinkHref="#mic"></use>
                        </svg>
                      </button>
                    </div> */}
              {!isMobile &&
                <button
                  onClick={handleClearFilter}
                  className="button button_type_clear visible_mobile"
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              }
            </div>
            <div className="index-search__filter-controls-col">
              <div className="index-search__filter-control index-search__filter-submit">
                {countBlocksToShow ? (
                  <button
                    className="button button_view_default"
                    type="submit"
                    onClick={handleFilterClick}
                    disabled={fetching ? true : false}
                  >
                   {altButtonTitle? altButtonTitle(countBlocksToShow) : `Показать ${countBlocksToShow} ЖК`}
                  </button>
                ) : (
                    <button
                      className="button button_view_default"
                      type="submit"
                      disabled
                    >
                      Ничего не найдено
                    </button>
                  )}
              </div>
            </div>
            {!isMobile &&
              <div className="index-search__filter-controls-col">
                <div className="index-search__filter-control index-search__filter-options">
                  <button
                    onClick={handleClearFilter}
                    className="button button_type_clear visible_note"
                  >
                    <svg className="icon icon_x">
                      <use xlinkHref="#x"></use>
                    </svg>
                    <span>Очистить фильтр</span>
                  </button>
                  {!isPublic && <div className="index-search__filter-save">
                    <button
                      className="button button_view_outline"
                      onClick={(e) => {
                        e.preventDefault();
                        saveFilterModal();
                      }}
                    >
                      Сохранить фильтр
                    </button>
                    {/* <button className="button button_type_settings">
                            <svg className="icon icon_settings">
                              <use xlinkHref="#settings"></use>
                            </svg>
                          </button> */}
                  </div>}
                </div>
              </div>
            }
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          className="index-search__filter-controls"
          style={{
            justifyContent: "space-between",
            maxWidth: "100%",
            marginLeft: 0,
          }}
        >
          {/* <div className="index-search__filter-control index-search__filter-voice">
  <button className="button button_view_round">
    <svg className="icon icon_mic">
      <use xlinkHref="#mic"></use>
    </svg>
  </button>
  <span>Голосовой помощник</span>
  </div> */}
          {!isPublic && <div className="index-search__filter-save">
            <button
              className="button button_view_outline"
              onClick={(e) => {
                e.preventDefault();
                saveFilterModal();
              }}
            >
              Сохранить фильтр
            </button>
            {/* <button className="button button_type_settings">
      <svg className="icon icon_settings">
        <use xlinkHref="#settings"></use>
      </svg>
    </button> */}
          </div>}
          <button
            onClick={handleClearFilter}
            className="button button_type_clear visible_mobile"
          >
            <svg className="icon icon_x">
              <use xlinkHref="#x"></use>
            </svg>
            <span>Очистить фильтр</span>
          </button>
          <div className="index-search__filter-control index-search__filter-options">
            <a
              className="index-search__filter-control index-search__filter-submit"
              href="#filter-results"
            >
              {countBlocksToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={handleFilterClick}
                  disabled={fetching ? true : false}
                >
                 {altButtonTitle? altButtonTitle(countBlocksToShow) : `Показать ${countBlocksToShow} ЖК`}
                </button>
              ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled
                  >
                    Ничего не найдено
                  </button>
                )}
            </a>
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_note"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
          </div>
          <button
            className="button button_view_outline-with-icon-invert filter-show-on-map"
            type="submit"
            onClick={(e) => handleFilterClick(e, true)}
            disabled={fetching ? true : false}
          >
            <svg className="icon icon_marker" style={{ stroke: "none" }}>
              <use xlinkHref="#marker"></use>
            </svg>
            <span>Показать на карте</span>
          </button>
        </div>
        <div
          className="index-search__filter-controls-m"
          style={{ justifyContent: "space-between" }}
        >
          <div className="index-search__filter-save">
            {!isMobile && !isPublic && 
              <button
                className="button button_view_outline"
                onClick={(e) => {
                  e.preventDefault();
                  saveFilterModal();
                }}
              >
                Сохранить фильтр
              </button>
            }
          </div>
          <div
            className="index-search__filter-controls-col"
            style={{ flexGrow: 0, width: isMobile ? "100%" : "auto" }}
          >
            <div
              className="index-search__filter-control index-search__filter-submit"
              style={{ marginRight: "15px" }}
            >
              {countBlocksToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={handleFilterClick}
                  disabled={fetching ? true : false}
                >
                  {altButtonTitle? altButtonTitle(countBlocksToShow) : `Показать ${countBlocksToShow} ЖК`}
                  
                </button>
              ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled
                  >
                    Ничего не найдено
                  </button>
                )}
            </div>
            {!isMobile &&
              <button
                onClick={handleClearFilter}
                className="button button_type_clear visible_mobile"
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            }
          </div>
          {!isMobile &&
            <button
              className="button button_view_outline-with-icon-invert filter-show-on-map"
              type="submit"
              onClick={(e) => handleFilterClick(e, true)}
              disabled={fetching ? true : false}
            >
              <svg className="icon icon_marker" style={{ stroke: "none" }}>
                <use xlinkHref="#marker"></use>
              </svg>
              <span>Показать на карте</span>
            </button>
          }
        </div>
      </div>
    );
  };
  export default Controls
