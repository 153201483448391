import { memo } from 'react';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import RadioDropdown from '../../core/RadioDropdown';


const VillagesNewTopFilter = memo(props => {
  const {
    filter,
    exclude,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    clearExcludeOnly,
    clearFilterOnly,
    type,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>

    {type === 'parcel' ?
      <PriceFilter
        filter={filter.priceParcel ?? { min: "", max: "" }}
        items={filterData.priceParcel?.items ?? []}
        data={{
          title: "Цена",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.priceParcel?.range ?? 0
        }}
        onChange={({ filter }) => setFilterAndExclude({ filter }, "priceParcel")}
        debounced
        wrapperClass={wrapperClass}
        clearFilterOnly={() => clearByIdent("priceParcel")}
        clearExcludeOnly={() => clearByIdent("priceParcel", "exclude")}
      /> :
      <PriceFilter
        filter={filter.priceHouse ?? { min: "", max: "" }}
        items={filterData.priceHouse?.items ?? []}
        data={{
          title: "Цена",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.priceHouse?.range ?? 0
        }}
        onChange={({ filter }) => setFilterAndExclude({ filter }, "priceHouse")}
        debounced
        wrapperClass={wrapperClass}
        clearFilterOnly={() => clearByIdent("priceHouse")}
        clearExcludeOnly={() => clearByIdent("priceHouse", "exclude")}
      />
    }

    <SelectFilter
      {...createSelectProps("districtSpb")}
      data={{
        title: "Район",
        autocompleteTitle: "",
        identity: "districtSpb",
      }}
      wrapperClass={wrapperClass}
    />
  </>
});

export default VillagesNewTopFilter;
