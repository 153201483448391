const initialState = {
	isFetching: false,
	error: false,
	data: null,
	eventReports: null,
	eventsPdf: null,
	myEvents: [],
	filterData: {
		agents: [],
		date: {
			min: '',
			max: '',
		},
		statuses: [],
	},
	filter: {
		agents: [],
		date: {
			startDate: null,
			endDate: null,
		},
		statuses: [],
	},
};

const events = (state = initialState, action) => {
	switch (action.type) {
		case 'EVENTS_REQUEST':
			return {
				...state,
				isFetching: true,
			};
		case 'EVENTS_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				data: action.data,
				eventReports: action.eventReports,
			};
		case 'EVENTS_PDF_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				eventsPdf: action.eventsPdf,
			};

		case 'MY_EVENTS_REQUEST':
			return {
				...state,
				isFetching: true,
				error: false,
			};
		case 'MY_EVENTS_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				myEvents: action.data,
				filterData: action.filterData,
			};
		case 'MY_EVENTS_SET_FILTER':
			return { ...state, filter: { ...state.filter, ...action.filter } };
		case 'MY_EVENTS_REMOVE':
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.id),
			};
		case 'MY_EVENTS_CLEAR_FILTER':
			return { ...state, filter: initialState.filter };
		case 'EVENTS_FAILURE':
			return {
				...state,
				isFetching: false,
				error: true,
			};
		case 'EVENTS_RESET':
			return { ...initialState };
		default:
			return state;
	}
};

export default events;
