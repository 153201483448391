import request from "../api";
import { actionFactory } from "./actionUtils";

const bannersSuccess = actionFactory("BANNERS_SUCCESS");

export const getBanners = url => dispatch =>
    request("panpartner:banners.ajax", "getBanners", { url })
        .then(
            data => {
                dispatch(bannersSuccess({ data }));
                return Promise.resolve(data);
            },
        );
export const clickBanner = form => request("panpartner:banners.ajax", "click", form)
