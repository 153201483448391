import React, { useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { sendLeadMagnet } from '../../../actions/forms';
import { getUrl } from "../../../api";
import useMedia from "../../../hooks/useMedia";
import { applyToFormData, downloadLink, generateConstructorMarkdown, iOSSafari, sendConstructorEvent, sendVKEvent } from "../../../utils";
import { getFormWithUTM, getSubdomain } from "../../../utils/location";
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";
import { errorToast, standartErrorToast, successToast } from "../../toasts";

const TelegramInput = props => {
  const { placeholder, value, changeHandler, isMobile } = props
  const [validValue, setValidValue] = useState("")
  const patterns = {
    "telegram": new RegExp(`^${placeholder.replace(/\//g, "\\/")}[_0-9a-zA-Z]{0,}`),
  }
  const telegramInputFocus = (e) => {
    if (e.target.value) {
      return
    }
    setValidValue(placeholder)
    changeHandler(placeholder)
  }
  const telegramBlur = (e) => {
    if (e.target.value === placeholder) {
      setValidValue("")
      changeHandler("")
    }
  }

  const setSocialValue = (e) => {
    let val = e.currentTarget.value
    if (val && !val.startsWith(placeholder)) {
      changeHandler(`${placeholder}${val}`)
      val = `${placeholder}${val}`
    }
    let match = val.match(patterns["telegram"])
    if (match && match[0] === val) {
      setValidValue(val)
      changeHandler(val)
    } else {
      changeHandler(validValue)
    }
  }
  return <input
    type="text"
    className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
    placeholder={placeholder}
    value={value}
    onFocus={telegramInputFocus}
    onChange={e => setSocialValue(e)}
    onBlur={e => telegramBlur(e)}
    id={`lid-telegram`}
  />
}

const LidModal = (props) => {

  const { closeLidModal, title = "Заголовок лид-магнита", isPreview, selectedType, id, file, fields, buttonTitle, type, link } = props;

  // const [callbackForm, setCallbackForm] = useState({})
  // const [formSettings, setFormSettings] = useState([])
  const theme = useSelector(state => state.agency.theme);
  const [color, setColor] = useState(null)
  // const subDomain = getSubdomain()
  // const [title, setTitle] = useState(null)
  const isMobile = useMedia("isMobile")

  useEffect(() => {
    if (!theme) return
    let currentColor;
    switch (theme.color) {
      case 'green':
        currentColor = `constructor`;
        break;

      case 'blue':
        currentColor = `constructor constructor_color-blue`;
        break;

      case 'yellow':
        currentColor = `constructor constructor_color-yellow`;
        break;

      case 'red':
        currentColor = `constructor constructor_color-red`;
        break;

      case 'gray':
        currentColor = `constructor constructor_color-gray`;
        break;

      case 'asphalt':
        currentColor = `constructor constructor_color-asphalt`;
        break;

      case 'purple':
        currentColor = `constructor constructor_color-purple`;
        break;

      case 'burgundy':
        currentColor = `constructor constructor_color-burgundy`;
        break;
      case 'nightsky':
        currentColor = `constructor constructor_color-nightsky`;
        break;
      case 'turquoise':
        currentColor = `constructor constructor_color-turquoise`;
        break;
    }
    setColor(currentColor)
  }, [theme]);

  
  let leadMagnetLink = link;

  if (!/^https?:\/\//i.test(leadMagnetLink)) {
    leadMagnetLink = 'http://' + leadMagnetLink;
}

  const [fieldsState, setState] = useState({ agree: true })

  const setValues = (val, id) => setState(prev => ({ ...prev, [id]: val }))

  const phoneFields = ["phone", "whatsapp", "viber"]
  const textFields = ["name", "surname"]
  const componentByField = field => {
    if (phoneFields.includes(field.id)) {
      return <InputMask
        id={`lid-${field.id}`}
        type="text"
        className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
        mask="+7 (999) 999-99-99"
        autoComplete="off"
        maskChar=" "
        value={fieldsState[field.id]}
        onChange={(e) => setValues(e.target.value, field.id)}

      />
    }
    if (field.id === "email") {
      return <input
        id={`lid-${field.id}`}
        type="text"
        className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
        placeholder="E-mail"
        value={fieldsState[field.id]}
        onChange={(e) => setValues(e.target.value, field.id)}

      />
    }
    if (field.id === "telegram") {
      return <TelegramInput
        placeholder="@"
        value={fieldsState[field.id]}
        changeHandler={val => setValues(val, field.id)}
        isMobile={isMobile}
      />
    }
    if (textFields.includes(field.id)) {
      return <input id={`lid-${field.id}`}
        type="text"
        className={`c-modal__input ${isMobile ? 'input__control' : ''}`}
        placeholder={field.name}
        onChange={(e) => setValues(e.target.value, field.id)}
        value={fieldsState[field.id]}
      />
    }
    return null
  }
  const [errors, setErrors] = useState({})

  const setError = (val, id) => setErrors(prev => ({ ...prev, [id]: val }))

  const noContactField = ["name", "surname"]
  
  const validateForm = () => {
    if (!fieldsState.agree) {
      errorToast("Необходимо согласиться с политикой конфиденциальности")
      return
    }

    const tmp = { ...fieldsState }
    delete tmp.agree
    delete tmp.name
    const haveContact = Object.values(tmp).some(val => !!val.replace(/\s/gi, ""))
    const haveName = fieldsState?.name?.toString?.().replace?.(/ /gi, "")
    if (!haveContact) {
      const unfiledFields = fields.map(field => field.active && !noContactField.includes(field.id) ? <>{field.name}<br /></> : null)
      errorToast(<div>
        {!haveName ? <>Заполните поле "имя".<br /> </> : ""}
        Заполните одно из полей: <br />{unfiledFields}</div>)
      return
    }
    if(!haveName){
      errorToast(`Заполните поле "имя"`)
      return
    }
    return true
  }

  const sendForm = () => {
    if (!validateForm()) return
    const formWithUtm = getFormWithUTM()
    const params = applyToFormData(formWithUtm, fieldsState)
    params.append("subDomain", getSubdomain())

    if (type == 'file') {
      if (iOSSafari && !!file?.src) {
        var windowRef = window.open();
      }
    }

    sendLeadMagnet(params).then(data => {
      if (type == 'file') {

        if (windowRef) {
          windowRef.location.href = getUrl(file.src, "https://panpartner.ru")
        }
  
        if (!!file?.src && !windowRef) {
          setTimeout(() => {
            downloadLink(getUrl(file.src, "https://panpartner.ru"))
          }, 4000)
          
        }

      }

      if (type == 'link') {
                  
        setTimeout(() => {
          window.open(leadMagnetLink, '_self')
        }, 4000)
      }

      sendConstructorEvent("tr_magnet_form_send", null, true);
      sendVKEvent('conversion');
      successToast(data.message)
      closeLidModal()
    }, err => {
      standartErrorToast(err)
      return windowRef?.close?.()
    })
  }

  const htmlTitle = useMemo(() =>generateConstructorMarkdown(title, theme.colorHash || "red"), []);

  const modalContent = (
    color &&
    <div className={`constructor ${color}`} >
      {!isMobile &&
        <button type="button"
          className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
          aria-label="Закрыть окно"
          onClick={closeLidModal}
        >
          <span className="c-modal__icon">
            <svg className="icon icon_cross">
              <use xlinkHref="#cross" />
            </svg>
          </span>
        </button>
      }
      <div className="c-modal__box" >
        {!isMobile &&
          <h2
          className="c-modal__header c-modal__header_center"
          dangerouslySetInnerHTML={{
            __html: htmlTitle,
          }}
          ></h2>
        }

        <p className="big-text">
          {
            type == 'file' && `Файл начнет скачиваться после заполнения формы:`
          }

          {
            type == 'link' && `Ссылка станет доступна после заполнения формы`
          }
        </p>

        <form className={`c-modal__content`}>

          {!!fields?.length && fields.map(field => field.active ? <div className={`c-modal__input-block ${isMobile ? 'input_type_form modal-form__item' : ''}`} key={field.id}>
            <label htmlFor={`lid-${field.id}`} className={`c-modal__label ${isMobile ? 'input__label' : ''}`}>{field.name}</label>
            {componentByField(field)}
          </div> : null
          )}
          <div className={`c-modal__input-block ${isMobile ? 'modal-form__row' : ''}`}>
            <div className={`checkbox ${isMobile ? 'checkbox_view_invert' : ''}`}>
              <div className="checkbox__box">
                <input
                  className="checkbox__control"
                  type="checkbox"
                  id="lid-agreement"
                  checked={fieldsState.agree}
                  onChange={(e) => setValues(e.target.checked, "agree")}
                />
                <label className="checkbox__input" htmlFor="lid-agreement" />
                <div className="checkbox__marker"></div>
              </div>
              <label className="checkbox__label" htmlFor="lid-agreement">
                <span className="consultation__form">
                  Я согласен на обработку <a className="link" href="https://toprealtors.ru/policy" target="_blank">персональных данных</a>
                </span>
              </label>
            </div>
          </div>
          <div className={isMobile ? 'modal-form__submit' : ''}>
            <button
              type="button"
              className={`button c-modal__submit constructor__btn`}
              onClick={sendForm}
            >
              {buttonTitle}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
  

  return (
    isMobile ?
      <MobileModal hideDefaultClose={true} closeClick={closeLidModal} title={title} className="constructor-modal">
        {modalContent}
      </MobileModal> :

      <Modal hideDefaultClose={true} close={closeLidModal} closeByPastClick classes="modal_task c-modal c-modal_lid">
        {modalContent}
      </Modal>
  )
};

export default LidModal;
