import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { telegramBotAction } from '../actions/user';
import stepan from '../assets/images/stepan.svg';
import Preloader from '../components/Preloader';
import SubmitButton from '../components/SubmitButton';

const BotStepan = (props) => {
  const {history} = props
  let { id } = useParams();
  const [isConnected, setConnected] = useState(false)
  const [isFetching, setFetching] = useState(false)
  useEffect(() => {
    setFetching(true)
    telegramBotAction(id, "checkConnect").then(resp => {
      let status = resp.status
      setConnected(status)
      if (status) {
        setConnected(true)
        return Promise.resolve()
      }
      setFetching(false)
      return true
    }).then(() => {
      telegramBotAction(id, "saveTelegramUserId").then(() => {
        setFetching(false)
        return Promise.resolve()
      })
    })
  }, [id])
  if (isFetching) {
    return <Preloader loading={true} />
  }
  return (
    <div className="bot-wrapper">
      <div className="bot-content">
        <span className="bot-title">{!isConnected ? <div>Поздравляем, связь с телеграм-ботом<br/> Сте<span className="bot-title-red">ПАН</span>ом установлена</div> : "Вы уже подключены к телеграм-боту"}</span>
        <object type="image/svg+xml" data={stepan} />
        <SubmitButton
          className="button button_view_default bot-button"
          onClick={() => history.push("/")}
        >
          <span>На главную</span>
        </SubmitButton>
      </div>
    </div>
  );
};

export default BotStepan
