export const programsList = [
  // {
  //   id: 0,
  //   title: 'ГК Полис Групп'
  // },
  // {
  //   id: 1,
  //   title: 'ЦДС'
  // },
  // {
  //   id: 2,
  //   title: 'РСТИ'
  // },
  // {
  //   id: 3,
  //   title: 'Glorax Development'
  // },
  // {
  //   id: 4,
  //   title: 'Арсенал'
  // },
  // {
  //   id: 5,
  //   title: 'ЛенСпецСму'
  // },
  // {
  //   id: 6,
  //   title: 'AAG'
  // },
  // {
  //   id: 7,
  //   title: 'Лидер Групп'
  // },
  // {
  //   id: 5,
  //   title: 'Евроинвест'
  // },
  // {
  //   id: 6,
  //   title: 'Строительный трест'
  // },
  // {
  //   id: 7,
  //   title: 'ПСК'
  // },
  // {
  //   id: 8,
  //   title: 'Мавис'
  // },
  // {
  //   id: 9,
  //   title: 'ЛСР'
  // },

  {
    id: 9,
    title: 'Обучение по застройщикам'
  },
  {
    id: 10,
    title: 'Обучение  по апартаментам'
  },
  {
    id: 13,
    title: 'Обучение по переуступкам'
  },
  
  {
    id: 14,
    title: 'Обучение по коммерции'
  },
  {
    id: 11,
    title: 'Загородная недвижимость'
  },
  {
    id: 12,
    title: 'Репетитор по продажам'
  },

  
];

export const accordPrograms = [
  {
    number: 1,
    title: `Обучение по застройщикам и объектам`,
    items: [
      `Узнаете историю застройщиков и проникнитесь их идеологией`,
      `Поймете, как застройщики делают правильные акценты на преимуществах и обходят недостатки проектов в своих презентациях`,
      `Узнаете, как правильно взаимодействовать с застройщиками, чтобы выстроить долгосрочные отношения и кратно увеличивать объемы продаж: регламенты, инсайды, о которых не говорят вслух`,
    ],
  },
  {
    number: 2,
    title: `Обучение по загородной недвижимости`,
    items: [
      `Узнаете ассортимент коттеджных поселков, открытых к продажам`,
      `Систематизируете знания по проектам, включая нюансы, которые сильно волнуют клиентов: типы домов, технологии строительства, назначения земель, льготная ипотека, программы рассрочки, скидки`,
      `Поймете алгоритм продажи загородной недвижимости и отбросите страхи перед выходом в новую нишу продаж`,
    ],
  },
  // {
  //   number: 3,
  //   title: `Обучение по апартаментам`,
  //   items: [
  //     `Узнаете историю апартаментов от истоков до наших дней`,
  //     `Познакомитесь с типами «апартов» и научитесь отличать их друг от друга`,
  //     `Посмотрите иным взглядом на этот тип недвижимости, поймете в чем прелесть, научитесь работать со страхами клиентов и аргументированно подводить к покупке`,
  //     `Узнаете, какие есть управляющие компании, и как стандарты внутри УК влияют на востребованность у арендаторов, разберетесь в программах доходности`,
  //     `Разберетесь в преимуществах сервисных апартаментов перед стандартной арендой`,
  //     `Изучите лучшие предложения петербургского рынка апартаментов, получите сравнительный анализ объектов по ключевым показателям и готовый пример расчета доходности`,
  //   ],
  // },
  {
    number: 3,
    title: `Обучение по коммерческой недвижимости`,
    items: [
      `Узнаете стратегии управления коммерческой недвижимостью`,
      `Определите для себя основные плюсы инвестиций в коммерческую недвижимость и научитесь ее продавать`,
      `Познакомитесь с типами арендаторов и научитесь подбирать помещения под запрос клиента или инвестора`,
      `Посмотрите иным взглядом на этот вид инвестиций, как на наиболее доходный при соотношении риска/доходности`,
      `Узнаете, какие есть возможности управления арендным потоком, как выгодно презентовать клиенту коммерческий объект и аргументированно работать с возражениями клиентов`,
    ],
  },
  {
    number: 4,
    title: `Репетитор по продажам`,
    items: [
      `Возможность расширить или более глубоко изучить теорию`,
      `Разобрать этапы звонка и план действий на каждом этапе`,
      `Рассмотреть отправку подборки для клиента, как инструмент закрытия на встречу`,
      `Разобрать структуру встречи`,
      `Отработать полученные знания на практике и перенять рабочие кейсы`,
      `Получить ответы на интересующие вопросы и консультацию практикующего специалиста`,
    ],
  },
];
