import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import request, { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import usePermission from "../../hooks/usePermission";
import mocData from "./mocData";
import SimilarCardsList from "./SimilarCardsList";
import DetailHeader from "../Public/markup/detail/DetailHeader";
import InfoCard from "../Public/markup/detail/InfoCard";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Slider from "../Public/markup/detail/Slider";
import ContactCard from "../core/ContactCard";
import SubmitButton from "../SubmitButton";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import FixateClient from "../../pages/FixateClientSpecial";
import RecordViewing from "../RecordViewing";
import RecordViewingSuburban from "../RecordViewing/suburban";
import RecordClient from "../RecordViewing/suburban2";
import useApi from "../../hooks/useApi";
import {successToast} from "../toasts";
import {downloadLink, objectsArrayToFormData, toFormData} from "../../utils";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import {
  AddToCompilationIcon,
  ComissionIcon,
  FixIcon,
  HeartIcon,
  PdfIcon, PresentationIcon,
  SuburbanAboutMainIcon,
  SuburbanBedIcon, SuburbanBuilderStarIcon,
  SuburbanBuildingAreaIcon,
  SuburbanCeilingHeightIcon,
  SuburbanCountBathroomsIcon,
  SuburbanListBagIcon,
  SuburbanListCameraIcon,
  SuburbanListHorseIcon,
  SuburbanListParkIcon,
  SuburbanListStarIcon,
  SuburbanProjBagIcon,
  SuburbanProjCVIcon,
  SuburbanProjFloorIcon,
  SuburbanProjMetrIcon,
  SuburbanProjSecondFasadIcon,
  SuburbanProjSecondFloorIcon,
  SuburbanProjSecondHouseIcon,
  SuburbanProjSecondRepairIcon,
  SuburbanProjSecondRoofIcon,
  SuburbanProjSecondSizeIcon,
  SuburbanProjSizeIcon,
  ViewIcon
} from "../Public/markup/icons";
import AddPresenatation from "../AddPresentation";
import AppPopover from "../core/Popover";
import Wrap from "../core/ModalWrap";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import SpecialForm from "../forms/Special";
import ViewApartment from "../forms/ViewApartment";
import CardsList from "../Public/markup/CardsList";
import VillageCardContainerNew from "../Public/markup/Containers/VillageCardContainerNew";
import Files from "../Public/markup/detail/Files";
import Help from "../Public/markup/detail/Help";
import Button from "../core/Button";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import useDocTitle from "../../hooks/useDocTitle";


const data = mocData;
const photos = { ...data.block.photos }
const photosComplex = {
  complex: [...(photos.complex ? photos.complex : [])],
  ...(!!data.block.aeroPanorama ? { aeroPanorama: data.block.aeroPanorama } : [])
}
const DetailCardNewHouse = props => {
  const isMobile = useMedia("isMobile");
  const {
    presentation,
    heightCell1,
    decoration,
    features,
    manager,
    floors,
    openSpecialModal,
    countBedrooms,
    heightCell2,
    commission,
    id,
    title,
    price,
    squareTotal,
    meterPrice,
    section,
    squareHouse,
    constructionPeriod,
    photos,
    planPhotos,
    decorationPhotos,
    livingWinter,
    semiObjects,
    hidePrice,
    objectInfo,
    priceInfo,
    dateUpdateInfo,
    detailText,
    inFavorite,
    toggleFavorite,
    suburban,
    projectInfo,
    equipments,
    otherProjects,
    filesInfo,
    contacts,
    schedule,
    fixationLink,
    bookingLink
  } = props
console.log(props)
  const photosObject = {
    complex: photos,
    plan: planPhotos
  }


  const isTablet = useMedia('isTablet');
  const isDesktop = useMedia("isDesktop");
  const [numberOfSlides, setNumberOfSlides] = useState(0);
  const handleInit = (swiper) => {
    if (swiper) {
      setNumberOfSlides(swiper.slides.length);
    }
  };

  const [complexView, setComplexView] = useState('photos')

  const shoowMotivation = usePermission("motivation")

  // const callback = () => send(["panpartner:forms.ajax", "callback", {
  //   urgently: true,
  //   managerId: managerInfo?.id,
  //   name: `${userInfo?.name} ${userInfo?.lastName}`,
  //   type: objectType,
  //   objectId: id,
  //   city
  // }]).then(
  //   success => setCallbackStatus({ type: "success" }),
  //   err => setCallbackStatus({ type: "error", message: err[0]?.message })
  // )


  const [openFixModal, closeFixModal] = useModal(
    () => (
      <PanModal title={'Фиксация клиента'} closeModal={closeFixModal} isBig>
        <FixateClient redesign closeFixModal={closeFixModal} objectId={id}/>
      </PanModal>
    ),
    [],
  );

  const [bookATourModal, closeBookATourModal] = useModal(
    () => (
      <PanModal title={'Запись на просмотр по проектам домов'} closeModal={closeBookATourModal} isBig>
        {/*<RecordViewingSuburban/>*/}
        <RecordClient redesign objectId={id} close={closeBookATourModal} />
      </PanModal>
    ),
    [],
  );


  const [specSpecModal, closeSpecModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeSpecModal} title="Запись на просмотр">{children}</MobileModal>
        : <Modal close={closeSpecModal} classes="modal_task">{children}</Modal>
      } >
        <SpecialForm close={() => {
          closeSpecModal()
        }}
                     specialOfferId={id}
          // isShowRoom={isShowRoom} setIsShowRoom={setIsShowRoom}
        />
      </Wrap >
    ));


  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{objectId: suburban.id, city: 'spb', type: 'contractConstruction'}]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          // managerId={block?.manager?.id}
          // params={{
          //   type: "village",
          //   objectId: villageId,
          //   city
          // }}
        />
      </PanModal>
    ),
    [suburban],
  );

  const addSuburbanPresentation = (id) => {
    request("panpartner:new.suburbans.ajax", "addPresentation",
      // objectsArrayToFormData([{type: "parcel", city: "spb", objectId: parcelId ?? id}, {type: "contractConstruction", city: "spb", objectId: id} ], "objects"))
      objectsArrayToFormData([{type: "contractConstruction", city: "spb", objectId: id} ], "objects"))
      .then((resp) => {
        if (resp?.xmlId) {
          // window.open(`/suburban/presentation/${resp.xmlId}`, "_blank");
          navigator.clipboard.writeText(getUrl(`/suburban/presentation/${resp.xmlId}`))
          successToast("Презентация успешно создана. Cсылка скопирована в буфер обмена")
        } else {
          console.error("Ошибка: xmlId не найден в ответе");
        }
      })
      .catch((error) => {
        console.error("Ошибка запроса:", error);
      });
  };




  const [fetchingPdf, setFetchingPdf] = useState(false);
  const getPdf = () => {
    setFetchingPdf(true)
    let formData = toFormData( {});
    formData = objectsArrayToFormData([{objectId: suburban.id, city: 'spb', type: 'contractConstruction'}], "objects", formData)
    return request('panpartner:new.suburbans.ajax', 'addPresentation', formData)
      .then((data) => {

        return request('panpartner:new.suburbans.ajax', 'getPresentationPdf', {type: 'contractConstruction', id: data.xmlId})
          .then((resp) => {
            setFetchingPdf(false)
            window.open(getUrl(resp.path))
          })

      })
  }

  const isLaptop = useMedia('isLaptop');

  const actionsRef = useRef(null);
  const filesRef = useRef(null);
  const aboutBlockRef = useRef(null);
  const filesBlockRef = useRef(null);
  useDocTitle(suburban?.title)

  const [isLessDescription, setIsLessDescription] = useState(true)


  return (
    <>
      <div className="subuebans-object">

        <div className="sf__detail-head">
          <h1 className="h3-l sf__detail-title">{suburban?.title}</h1>

          {suburban?.commission && shoowMotivation && (
            <AppPopover content={suburban?.commission} placement="right">
              <div className="sf-tooltip sf__commission">
                <ComissionIcon />
                Мотивация
              </div>
            </AppPopover>
          )}


        </div>

        <div className="sf__elems" style={{gridTemplateColumns: 'repeat(5, min-content)'}}>

          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={() => toggleFavorite({id: suburban?.id})}
          >
            <HeartIcon isActive={inFavorite} />
            {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
          </button>


          {/*<span className="sf__elem-btn button link"  onClick={bookATourModal}>*/}
          {/*    <ViewIcon />*/}
          {/*    Запись на просмотр*/}
          {/*  </span>*/}

          {/*<span className="sf__elem-btn button link record-not-available ">*/}
          {/*    <ViewIcon />*/}
          {/*    Запись на просмотр*/}
          {/*    <span>Недоступно</span>*/}
          {/*  </span>*/}
          {fixationLink &&
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => window.open(fixationLink, "_blank")}
            >
              <FixIcon/>
              Зафиксировать клиента
            </button>
          }

          <SubmitButton
            type="button"
            className="sf__elem-btn button link"
            // isLoading={fetching}
            onClick={() => addSuburbanPresentation(suburban?.id)}
          >
            <PresentationIcon />
            Ссылка на презентацию
          </SubmitButton>

          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={openCompilationModal}
          >
            <AddToCompilationIcon/>
            В подборку
          </button>

          <SubmitButton
            className="sf__elem-btn button link"
            isLoading={fetchingPdf}
            onClick={() => {getPdf()}}
          >
            <PdfIcon />
            Скачать PDF
          </SubmitButton>

        </div>


        <div className="sf__detail-row">
          <div className="sf__detail-col complex-custom-view">

            {!isTablet && !!suburban?.photo?.length && complexView == 'photos' && (
              <div className="complex-custom-view__main-container">
                <Slider
                  items={suburban.photo}
                  defoultThumbs
                />
              </div>
            )}

            {!isTablet && !!suburban?.plan?.length && complexView == 'planPhotos' && (
              <div className="complex-custom-view__main-container complex-custom-view__main-container_genplan">
                <Slider
                  items={suburban.plan}
                  defoultThumbs
                />
              </div>
            )}


            <ul className="complex-view-list">
              <Swiper
                spaceBetween={15}
                slidesPerView={5}
                modules={[Navigation]}
                onInit={handleInit}
              >
                <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'photos' ? 'active' : ''}`} onClick={() => {setComplexView('photos')}}>
                    <img src={getUrl(suburban?.photo?.[0]?.src)} alt=""/>
                    <span>Фотографии</span>
                  </li>
                </SwiperSlide>
                {!!suburban?.plan && suburban?.plan.length > 0 && <SwiperSlide>
                  <li className={`complex-view-list__item ${complexView == 'planPhotos' ? 'active' : ''}`} onClick={() => {setComplexView('planPhotos')}}>
                    <img src={getUrl(suburban.plan?.[0]?.src)} alt=""/>
                    <span>Планировки</span>
                  </li> </SwiperSlide>}

              </Swiper>
            </ul>

            {
              detailText && detailText !== '' &&
              <div className="special__about">
                <h3 className="h3">О проекте</h3>
                <p className="special__about-text">
                  {detailText}
                </p>
              </div>
            }


            <div style={{marginTop: 30}} className="about-redesign">
                <div className="about-redesign__top">
                  <div className="about-redesign__top-icon">
                    <SuburbanAboutMainIcon/>
                  </div>

                  <div className="about-redesign__top-content">
                    <p className="about-redesign__top-title">
                      О проекте
                    </p>

                    <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: !isLessDescription ? suburban?.descriptionHouse : suburban?.descriptionHouse?.split('<br>')[0]}}></p>
                    {suburban?.descriptionHouse?.includes?.('<br>') && <span className="about-redesign__top-more" onClick={e=>setIsLessDescription(prev=>!prev)}>{!isLessDescription ?  'Свернуть' : 'Подробнее'}</span>}


                    <div className="communications-redesign__list">
                      <ul className="about-redesign__list">

                        {projectInfo.map((i) => {
                          return (
                            <li className="about-redesign__list-item">
                              <div className="about-redesign__list-icon">

                                {i.type === 'squareHouse' && <SuburbanProjMetrIcon/>}
                                {i.type === 'countFloors' && <SuburbanProjFloorIcon/>}
                                {i.type === 'countBedrooms' && <SuburbanBedIcon/>}
                                {i.type === 'buildingArea' && <SuburbanBuildingAreaIcon/>}
                                {i.type === 'countBathrooms' && <SuburbanCountBathroomsIcon/>}
                                {i.type === 'ceilingHeight' && <SuburbanCeilingHeightIcon/>}

                              </div>
                              <div className="about-redesign__list-text">
                                <div className="about-redesign__listtext-sm">{i.title}</div>
                                <div className="about-redesign__listtext-bd">{i.value}</div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                </div>

              </div>


            {
              equipments?.length > 0 &&
              equipments.map((i) => {
                return (
                  <div style={{marginTop: 30}} className="about-redesign">
                    <div>
                      <p className="communications-redesign__title">{i.title}</p>

                      <div className="communications-redesign__list communications-redesign__list_white">
                        <ul className="about-redesign__list">

                          {i.items.map((it) => {
                            return (
                              <li className="about-redesign__list-item">
                                <div className="about-redesign__list-icon">

                                  {it.type === 'materialHouse' && <SuburbanProjSecondHouseIcon/>}
                                  {it.type === 'materialRoof' && <SuburbanProjSecondRoofIcon/>}
                                  {it.type === 'foundation' && <SuburbanProjSecondFloorIcon/>}
                                  {it.type === 'decorationHouse' && <SuburbanProjSecondRepairIcon/>}
                                  {it.type === 'facadeFinishing' && <SuburbanProjSecondFasadIcon/>}

                                </div>
                                <div className="about-redesign__list-text">
                                  <div className="about-redesign__listtext-sm">{it.title}</div>
                                  <div className="about-redesign__listtext-bd">{it.value}</div>
                                </div>
                              </li>
                            )
                          })}


                        </ul>
                      </div>


                      <div style={{marginTop: 20}}>
                        <p className="communications-redesign__title">Также в комплектацию входит:</p>
                        <ul className="about-redesign__complectation-list">
                          <div className="about-redesign__complectation-list-container" dangerouslySetInnerHTML={{ __html: i.description }}></div>
                          {/*<li>входные двери</li>*/}
                        </ul>
                      </div>

                      <div style={{display: "flex", justifyContent: 'flex-end'}}>
                        <span className="about-redesign__complectation-price">{i.price} </span>
                      </div>

                    </div>
                  </div>
                )
              })

            }


            <div className="sf__detail-row" style={{marginTop: 30}} ref={aboutBlockRef}>
              <div className="sf__detail-col">

                  <>
                    <div className="sf__detail-row">
                      <div className="sf__detail-col">
                        <div className="about-redesign">
                          <div className="about-redesign__top">
                            <div className="about-redesign__top-icon">
                              <SuburbanBuilderStarIcon/>
                            </div>
                            <div className="about-redesign__top-content">
                              <p className="about-redesign__top-title">
                                {suburban?.builderVillage?.title}
                              </p>
                              <p className="about-redesign__top-text" dangerouslySetInnerHTML={{ __html: suburban?.builderVillage?.description }}></p>

                              {filesInfo?.length > 0 && (
                                <div ref={filesBlockRef} style={{minWidth: 500}}>
                                  <Files innerRef={filesRef} customTitle=" " items={filesInfo} />
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </>

                {/*{isLaptop && <Help openContactsModal={openContactsModal} />}*/}
              </div>
              {/*{!isLaptop && (*/}
              {/*  <div className="sf__detail-col sf__sticky">*/}
              {/*    <Help openContactsModal={openContactsModal} />*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>


          </div>
          <div className="sf__detail-col">
            <InfoCard items={objectInfo} topDate={dateUpdateInfo.title + ' ' + dateUpdateInfo.value} cols={priceInfo} />

            <section className="sf__section">

              <Button buttonType="primary" isFull onClick={openContactsModal}>
                Контакты
              </Button>

              {/*{presentation?.src &&*/}
              {/*  <a*/}
              {/*    href={getUrl(presentation.src)}*/}
              {/*    type="application/octet-stream"*/}
              {/*    target="_blank"*/}
              {/*    className="button button_view_default btn h6 btn_primary btn_full "*/}
              {/*    download*/}
              {/*  >*/}
              {/*    Скачать презентацию*/}
              {/*  </a>*/}
              {/*}*/}
              <Link
                className="button btn h6 btn_line btn_full "
                onClick={specSpecModal}
              >
                Оставить заявку
              </Link>
            </section>

            {manager &&
              <ContactCard
                name={manager?.name + ' ' + manager?.lastName}
                phone={manager?.phone}
                telegram={manager?.telegram !== '' ? manager?.telegram : false}
                logo
                forAptModal
                photo={manager?.photo}
                post={manager?.post}
                email={manager?.email}
              />
            }


          </div>
        </div>

        {otherProjects.length > 0 && (
          <div className="sf__detail-row sf__detail-row_full">
            <h3 className="h3 tab-title" style={{marginBottom: 20, marginTop: -50}}>Похожие по цене объекты</h3>
            <SimilarCardsList redesign hideTitleNewVersion hidePrice semiObjects={otherProjects}/>
          </div>
        )}

      </div>

      <article className="special__detail-card">


        <div className="special__detail-info">


        </div>


      </article>

      {!!semiObjects?.length && <SimilarCardsList redesign hidePrice semiObjects={semiObjects}/>}
    </>
  );
};

export default DetailCardNewHouse;

export const ComplectationPopup = () => {
  return (
    <div className="complectation-popup">
      <div className="complectation-popup__left">
        <div className="complectation-popup__image">
          <img src='https://crm.panpartner.ru/upload/iblock/9af/hhzzf5fdl78tr384ntxyfgu92wba0olj/4c919d519974cc34.jpg' alt=""/>
        </div>
      </div>
      <div className="complectation-popup__right">
        <div className="complectation-popup__title">Каркасный дом</div>
        <div className="complectation-popup__text">Основу каркасного дома составляют сухие строганные доски, из которых изготавливаются стойки, косые и поперечные элементы стен. Обязательно проходит термическую обработку, благодаря которой из древесины удаляется лишняя влага.</div>
        <div className="complectation-popup__over-list">
          <div className="complectation-popup__list-title">Преимущества материала: </div>
          <ul className="complectation-popup__list">
            <li>высокая плотность</li>
            <li>незначительная усадка каркаса</li>
            <li>экологичность</li>
            <li>ускорение темпов строительства</li>
          </ul>
        </div>

      </div>
    </div>
  )
}
