import React, { useRef } from "react";
import InputMask from "react-input-mask";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import request from "../../api";
import statusStub from '../../assets/images/status-stub.png';
import statusChart from '../../assets/images/statusStub.svg';
import RequisitesCompany from "../../components/Requisites/RequisitesCompany";
import Button from "../../components/core/Button";
import PanModal from "../../components/core/PanModal";
import useFileManager from "../../hooks/useFileManager";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { toFormData } from "../../utils";
import PersonalCabinet from "../PersonalCabinet";
import './style.scss';

const themes = [
  { id: 1, colorLeft: '#2C2F3E', colorRight: '#D8433E' },
  { id: 2, colorLeft: '#2C2F3E', colorRight: '#E3785A' },
  { id: 3, colorLeft: '#2C2F3E', colorRight: '#EAA03E' },
  { id: 4, colorLeft: '#2C2F3E', colorRight: '#225354' },
  { id: 5, colorLeft: '#2C2F3E', colorRight: '#A5C4F2' },
  { id: 6, colorLeft: '#2C2F3E', colorRight: '#32317A' },
  { id: 7, colorLeft: '#2C2F3E', colorRight: '#7366A6' },
];

const addCompany = form => request("panpartner:company.ajax", "addCompany", form)

const updateCompany = form => request("panpartner:company.ajax", "updateCompany", form)

const CreateCompany = ({ company, refresher }) => {

  const userInfo = useSelector(state => state.user?.info);
  console.log(userInfo)
  console.log(company)

  const defaultValues = company ? {
    name: company.name,
    email: company.email?.[0] || '',
    site: company.site,
    description: company.description,
    phone: company.phone?.map(phone => ({ number: phone })) || [{ number: '' }],
    address: company.adress || '',
    logo: company.logo?.src || null,
    companyView: 'opened',
  } : {
    name: '',
    email: '',
    site: '',
    description: '',
    phone: [{ number: '' }],
    address: '',
    logo: null,
    companyView: 'new',
  };

  const { state: formState, setValue, setValues, reset } = useUniversalReducer(defaultValues);

  const { files, uploadFiles, deleteFile } = useFileManager({
    maxFiles: 1,
    onlyImages: true,
    defaultFIles: company.logo?.src ? [{ fileObj: null, fileName: company.logo.src }] : [],

  });
  const fileInputRef = useRef(null);
  const {
    companyView,
    name,
    email,
    site,
    description,
    address,
    phone,

  } = formState;
  const setCompanyView = v => setValue('companyView', v);
  console.log('files :>> ', files, formState);

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 10) {
      return phoneNumber;
    }
    const formattedNumber = `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 8)}-${phoneNumber.slice(8, 10)}`;
    return formattedNumber;
  };
  const handleAddPhone = () => {
    setValue('phone', prev => ([...prev.phone, { number: '' }]));
  };
  const handlePhoneChange = (index, value) => {
    const updatedPhones = [...phone];
    updatedPhones[index].number = value;
    setValue('phone', updatedPhones);
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    }
    const formattedNumber = formatPhoneNumber(sanitizedData);
    const updatedPhones = [...phone];
    updatedPhones[index].number = formattedNumber;
    setValue('phone', updatedPhones);
  };
  const handleRemovePhone = (index) => {
    const updatedPhones = [...phone];
    updatedPhones.splice(index, 1);
    setValue('phone', updatedPhones);
  };

  const [openRequisitesModal, closeRequisitesModal] = useModal(
    () => (
      <PanModal customClass="edit-requisites" title={'Изменение реквизитов компании'} closeModal={closeRequisitesModal}>
        {/*<RequisitesCompany showForm={true} />*/}
        <RequisitesCompany isEdited={companyView === 'edited'} close={closeRequisitesModal} showForm={true} refresher={refresher} />
      </PanModal>
    ),
    [companyView],
  );

  const handleAddButtonClick = () => fileInputRef.current.click();

  const handleRemoveImage = () => deleteFile(0);

  const removeCompany = () => {
    return request("panpartner:company.ajax", "removeCompany",)
      .then(
        data => {
          // debugger
          refresher()
          return Promise.resolve(data);
        },
        error => {
          return Promise.reject(error);
        }
      );
  }



  const onSubmit = () => {

    const form = toFormData(formState, "params")

    phone.forEach((phone, index) => {
      form.append(`params[phone][${index}]`, phone.number);
    });

    if (!!files.length && files[0].fileObj) form.append('params[logo]', files[0].fileObj, files[0].fileName);

    if (companyView === 'new') {
      addCompany(form).then(
        data => {
          refresher()
          setValue('companyView', 'opened');
          return Promise.resolve(data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }

    if (companyView === 'edited') {
      updateCompany(form).then(
        data => {
          refresher()
          setValue('companyView', 'opened');
          return Promise.resolve(data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }

  }

  return (
    <>
      <PersonalCabinet />
      <div className={`create-company ${companyView === 'edited' ? 'create-company_edited' : ''}`}>
        <h3 className="h3 create-company__title">Настройки компании</h3>

        <form className="create-company__form" onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
          <div className="create-company__top">
            <div className="create-company__top-left">
              <div className="create-company__block">
                <p className="create-company__block-title">Компания</p>
                <div className="create-company__add">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      uploadFiles(e);
                      fileInputRef.current.value = "";
                    }}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  {files.length === 0 && (
                    <div className="create-company__add-btn" onClick={handleAddButtonClick}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 4.92871V11.9998H19.5711" stroke="#2C2E3E" stroke-linecap="round" /><path d="M5.42896 12H12.5V19.0711" stroke="#2C2E3E" stroke-linecap="round" /></svg>
                    </div>
                  )}
                  {files.length > 0 && (
                    <div className="create-company__over-upload">
                      <img className="create-company__upload-image" src={files[0].fileObj ? URL.createObjectURL(files[0].fileObj) : files[0].fileName} alt="Company Logo" />
                      <span className="create-company__remove-image" onClick={handleRemoveImage}>Удалить</span>
                    </div>
                  )}
                  <div className="cabinet-input">
                    <input
                      type="text"
                      disabled={companyView === 'opened'}
                      value={name}
                      onChange={(e) => setValue('name', e.target.value)}
                      placeholder="Название компании"
                    />
                  </div>
                </div>
              </div>
              <div className="create-company__block">
                <p className="create-company__block-title">Контакты</p>

                <div className="create-company__contacts">

                  <div className="cabinet-input">

                    {phone.map((phone, index) => (
                      <div className="cabinet-input__overinp" key={index}>
                        <InputMask
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar="_"
                          placeholder={index === 0 ? 'Телефон' : `Телефон ${index + 1}`}
                          value={phone.number}
                          onChange={(e) => handlePhoneChange(index, e.target.value)}
                        />
                        {index > 0 && (
                          <span className="cabinet-input__remove-inp" onClick={() => handleRemovePhone(index)}></span>
                        )}
                      </div>
                    ))}

                    <div className="cabinet-input__add-new" onClick={handleAddPhone}>
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#8F95AC" fill-opacity="0.1" /><path d="M9.99994 14H17.9999" stroke="#8F95AC" stroke-linecap="round" /><path d="M13.9999 10L13.9999 18" stroke="#8F95AC" stroke-linecap="round" /></svg>
                      <span>Добавить еще телефон</span>
                    </div>
                  </div>
                  <div className="cabinet-input">
                    <input
                      type="text"
                      disabled={companyView === 'opened'}
                      value={email}
                      onChange={(e) => setValue('email', e.target.value)}
                      placeholder="E-mail"
                    />
                  </div>
                  <div className="cabinet-input">
                    <input
                      type="text"
                      disabled={companyView === 'opened'}
                      value={site}
                      onChange={(e) => setValue('site', e.target.value)}
                      placeholder="Сайт"
                    />
                  </div>
                  <div className="cabinet-input">
                    <input
                      type="text"
                      disabled={companyView === 'opened'}
                      value={address}
                      onChange={(e) => setValue('address', e.target.value)}
                      placeholder="Адрес"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="create-company__top-right">
              <div className="create-company__block create-company__block_dark">
                <p className="create-company__block-title">Ваш личный менеджер</p>
                <div className="create-company__manager">
                  <img src={company.manager ? company.manager.personalPhoto.src : 'https://panpartner.ru/upload/main/a80/gy953icyo487fa0jaxcvm3u3b1zuyxp2/pan.png'} className="create-company__manager-img" alt="" />
                  <div className="create-company__manager-info">
                    <p className="create-company__manager-name">{company.manager ? company.manager.name + ' ' + company.manager.lastName : 'Информационный отдел'}</p>
                    <div className="create-company__manager-seconodinfo">
                      <div>
                        <div><a href={company.manager ? 'tel:' + company.manager.personalPhone : 'tel:8 (812) 426-18-63'} className="create-company__manager-phone link link_color_black">{company.manager ? company.manager.personalPhone : '8 (812) 426-18-63'}</a></div>
                        <div><a href={company.manager ? 'mailto:' + company.manager.email : 'mailto:info@panpartner.ru'} className="create-company__manager-email link link_color_black">{company.manager ? company.manager.email : 'info@panpartner.ru'}</a></div>
                      </div>
                      {/*<div className="create-company__manager-social">*/}
                      {/*  <Link><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="white"/><path d="M22.2 9.02576L7.46685 13.1746C7.34133 13.2086 7.22935 13.2805 7.14618 13.3805C7.06302 13.4805 7.0127 13.6037 7.0021 13.7333C6.9915 13.8629 7.02113 13.9926 7.08695 14.1048C7.15277 14.217 7.25157 14.3061 7.3699 14.3601L14.1353 17.5605C14.2676 17.6221 14.3739 17.7285 14.4356 17.8608L17.6391 24.6293C17.6929 24.7477 17.7819 24.8466 17.8939 24.9125C18.0059 24.9785 18.1356 25.0084 18.2652 24.998C18.3948 24.9876 18.518 24.9376 18.6181 24.8546C18.7183 24.7717 18.7904 24.6599 18.8247 24.5345L22.9745 9.8003C23.0209 9.6417 23.0027 9.47116 22.9237 9.326C22.8447 9.18085 22.7114 9.07289 22.553 9.02576C22.4378 8.99141 22.3151 8.99141 22.2 9.02576V9.02576Z" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.3439 17.6563L17.9162 14.084" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/></svg></Link>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="create-company__block create-company__block_dark">
                <p className="create-company__block-title">Статус агенства</p>

                <div className="create-company__status-block">
                  <img className="create-company__status-bg" src={statusStub} alt="" />
                  <div className="create-company__status-info">
                    <p className="create-company__status-title">{company.status || 'Нет статуса'}</p>
                    <p className="create-company__status-text">Статус агенства</p>
                  </div>
                  <div className="create-company__status-chart" style={{ height: 48, marginBottom: 16 }}>
                    <img src={statusChart} alt="" />
                  </div>
                </div>

                <div className="create-company__status-stat">
                  <div className="create-company__status-topstat">
                    <span>За последние 3 месяца</span>
                    <span>{company.countDealsPeriod || 0}</span>
                  </div>
                  <div className="create-company__status-botstat">
                    <span>Всего сделок</span>
                    <span>{company.countWinDeals || 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-company__block">
            <p className="create-company__block-title">Описание</p>
            <textarea
              className="cabinet-textarea"
              value={description}
              onChange={(e) => setValue('description', e.target.value)}
              placeholder="По желанию Вы можете указать описание компании"
            ></textarea>
          </div>
          {/*<div className="create-company__block">*/}
          {/*  <p className="create-company__block-title">*/}
          {/*    Цвета*/}
          {/*    <span className="create-company__block-titlehint"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11.5435" cy="12" r="10" fill="#F6F7F8"/><line x1="11.5869" y1="15.5" x2="11.5869" y2="9.5" stroke="#777A89" stroke-linecap="round"/><circle cx="11.5869" cy="7.5" r="0.5" transform="rotate(-180 11.5869 7.5)" fill="#777A89"/></svg></span>*/}
          {/*  </p>*/}

          {/*  <div className="project-theme">*/}
          {/*    {themes.map((theme) => (*/}
          {/*      <label*/}
          {/*        key={theme.id}*/}
          {/*        className={`project-theme__item ${activeTheme === theme.id ? 'project-theme__item--active' : ''}`}*/}
          {/*      >*/}
          {/*        <div className="project-theme__colors">*/}
          {/*          <div className="project-theme__color project-theme__color--left" style={{ backgroundColor: theme.colorLeft }}></div>*/}
          {/*          <div className="project-theme__color project-theme__color--right" style={{ backgroundColor: theme.colorRight }}></div>*/}
          {/*        </div>*/}
          {/*        <input*/}
          {/*          type="radio"*/}
          {/*          name="project-theme"*/}
          {/*          value={theme.id}*/}
          {/*          checked={activeTheme === theme.id}*/}
          {/*          onChange={() => setActiveTheme(theme.id)}*/}
          {/*          className="project-theme__radio"*/}
          {/*        />*/}
          {/*        <span className="project-theme__radio-custom"></span>*/}
          {/*      </label>*/}
          {/*    ))}*/}
          {/*  </div>*/}


          {/*</div>*/}
          {!company.requisites?.length &&
            <div className="create-company__block create-company__block_add-requisites">
              <p className="create-company__block-title">
                Реквизиты
                <span className="create-company__block-titlehint"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11.5435" cy="12" r="10" fill="#F6F7F8" /><line x1="11.5869" y1="15.5" x2="11.5869" y2="9.5" stroke="#777A89" stroke-linecap="round" /><circle cx="11.5869" cy="7.5" r="0.5" transform="rotate(-180 11.5869 7.5)" fill="#777A89" /></svg></span>
              </p>
              <Button buttonType="line" onClick={openRequisitesModal}>Добавить реквизиты</Button>
            </div>
          }
          {!!company.requisites?.length &&
            <>
              {companyView === 'edited' &&
                <div className="create-company__editrequisites-btn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#8790E5" stroke-linecap="round" stroke-linejoin="round" /><path d="M12.5015 6.53906L17.4607 11.4983" stroke="#8790E5" stroke-linecap="round" stroke-linejoin="round" /><path d="M19.5862 19.9998H8.95928L4.04492 15.0854" stroke="#8790E5" stroke-linecap="round" stroke-linejoin="round" /><path d="M14.981 9.01904L6.47949 17.5206" stroke="#8790E5" stroke-linecap="round" stroke-linejoin="round" /></svg>
                  <span onClick={openRequisitesModal}>Редактировать</span>
                </div>
              }
              <RequisitesCompany requisites={company.requisites[0]} bankRequisites={company.bankRequisites[0]} showRequisites={true} refresher={refresher} />
            </>
          }

          <div className="create-company__block">
            <p className="create-company__block-title">Информация о компании</p>
            <div className="create-company__info">
              <p>Дата создания: {new Date(company.dateCreate).toLocaleDateString()}</p>
              <p>Дата обновления: {new Date(company.dateUpdate).toLocaleDateString()}</p>
            </div>
          </div>

          <div className="create-company__action-btns">

            {companyView === 'opened' &&

              <>
                <Button buttonType='primary' onClick={() => {
                  setCompanyView('edited')
                }}>Редактировать</Button>
                <Button buttonType='primary' onClick={removeCompany}>Удалить</Button>
              </>
            }

            {companyView === 'edited' &&
              <>
                <Button buttonType='primary' onClick={() => {
                  setCompanyView('opened')
                }}>Отменить</Button>
                <Button buttonType='primary' onClick={onSubmit} type="button">Сохранить</Button>
              </>
            }

            {companyView === 'new' && <>
              <Button buttonType="secondary" onClick={() => { setCompanyView('') }} type="button">Отменить</Button>
              <Button buttonType='primary' onClick={onSubmit} type="button">Сохранить</Button>
            </>
            }

          </div>
        </form>
      </div>
    </>
  )
}

export default CreateCompany
