import { getDay, setHours, setMinutes } from 'date-fns';
import ru from 'date-fns/locale/ru';
import dateFormat from 'dateformat';
import _keys from 'lodash/keys';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { addRequest, fetchAutopan } from '../../actions/autopan';
import { media } from '../../utils';
import AutopanInfo from '../AutopanInfo';
import Dropdown from '../core/Dropdown';
import { errorToast, successToast } from '../toasts';


registerLocale('ru', ru);

const ReserveAutopan = (props) => {
  const { data, fetch, defaultValues, close, isFullWidth } = props;

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  let intViewportWidth = window.innerWidth;
  const [viewportWidth, setViewportWidth] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (data && data.districts && data.districts[0]) {
      setValue('districtId', data.districts[0].id);
      setViewportWidth(intViewportWidth)
    }
  }, [data]);

  const onSubmit = (data) => {
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item;
      switch (identity) {
        case 'time':
          let time = dateFormat(data[item], 'HH:MM');
          form.append(`${identity}`, time);
          break;
        case 'date':
          let date = dateFormat(data[item], 'dd.mm.yyyy');
          form.append(`${identity}`, date);
          break;
        default:
          form.append(`${identity}`, data[item]);
      }
    });

    addRequest(form).then(
      data => {
        close();
        successToast( <div>{data.message}</div>)
        ym('reachGoal','autopan_request')
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    )
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const isWeekday = date => {
    const day = getDay(date);
    const maxDay = new Date("01/09/2022")
    const minDay = new Date("01/01/2022")
    const dayCondition = date < minDay || date > maxDay
    return day !== 0 && day !== 6 && dayCondition;
  };

  return (
    <>
      <div
        className="assignation-reservation"
        // style={{ width: (media('isMobile') && !isFullWidth) ? (viewportWidth - 68) : 'auto'}}
      >
        <div
          className="assignation-reservation__header"
          style={{
            backgroundColor: media('isMobile') ? 'none' : 'rgba(143, 149, 172, 0.08)',
            height: '265px',
            padding: 0,
            display: media('isMobile') ? 'none' : 'block',
          }}
        >
          <div
            className="assignation-reservation__header-bg"
            style={{ paddingTop: '25px' }}
          >
            <svg className="icon">
              <use xlinkHref="#autopan"></use>
            </svg>
          </div>
        </div>

        <AutopanInfo />
        <h2 className="modal__title">Забронировать машину</h2>
        <div className="modal__grey-wrapper">
          <form className="modal-form modal-form_width_470" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <div className="select select_type_form">
                  <label className="custom-select-label" for="modal-task">
                    Районы
                  </label>
                  <Controller
                    as={Dropdown}
                    name="districtId"
                    control={control}
                    options={data.districts}
                    innerRef={register}
                    onChange={([value]) => value}
                  />
                </div>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  for="modal-task-date"
                >
                  <span className="input__label">Дата</span>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="d MMM yyyy"
                        minDate={new Date()}
                        showTimeSelect={false}
                        todayButton="Сегодня"
                        dropdownMode="select"
                        autoComplete="off"
                        locale="ru"
                        className="input__control"
                        shouldCloseOnSelect
                        filterDate={isWeekday}
                      />
                    }
                    control={control}
                    register={register({ required: true })}
                    name="date"
                    valueName="selected" // DateSelect value's name is selected
                    onChange={([selected]) => {
                      setSelectedDate(selected)
                      return selected;
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                </label>
              </div>
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  for="modal-task-time"
                >
                  <span className="input__label">Время</span>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        timeCaption="Время"
                        minTime={
                          // selectedDate.getDay() === 6 ?
                          // setHours(setMinutes(new Date(), 0), 11) :
                          // setHours(setMinutes(new Date(), 0), 10)
                          setHours(setMinutes(new Date(), 30), 9)
                        }
                        maxTime={
                          // selectedDate.getDay() === 6 ?
                          // setHours(setMinutes(new Date(), 0), 17) :
                          // setHours(setMinutes(new Date(), 0), 18)
                          setHours(setMinutes(new Date(), 0), 16)
                        }
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        className={
                          'input__control' + (errors.time ? ' input__error' : '')
                        }
                        locale="ru"
                        autoComplete="off"
                        shouldCloseOnSelect
                      />
                    }
                    control={control}
                    register={register({ required: true })}
                    name="time"
                    valueName="selected"
                    onChange={([selected]) => {
                      return selected;
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.time && (
                    <span className="input__error-label">
                      {errors.time.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="textarea textarea_type_form textarea_width_available"
                  htmlFor="cl-comment"
                >
                  <span className="textarea__label">Комментарий</span>
                  <textarea
                    className="textarea__control"
                    id="cl-comment"
                    name="comment"
                    placeholder="Текст комментария"
                    rows="5"
                    ref={register}
                  ></textarea>
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <div className="checkbox checkbox_view_invert">
                  <div
                    className={
                      'checkbox__box' +
                      (errors.agree ? ' checkbox__box-error' : '')
                    }
                  >
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-reservation-agreement"
                      name="agree"
                      ref={register({
                        required:
                          'Необходимо согласиться с правилами обработки данных',
                      })}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-reservation-agreement"
                    ></label>
                    <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                  </div>
                  <label
                    className="checkbox__label"
                    htmlFor="modal-reservation-agreement"
                  >
                    Cогласие на обработку персональных данных
                  </label>
                </div>
                {errors.agree && (
                  <span className="input__error-label">
                    {errors.agree.message}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-form__submit">
              <button className="button button_view_default" type="submit">
                Отправить
              </button>
            </div>
          </form>
        </div>

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.autopan.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (city) => dispatch(fetchAutopan(city)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReserveAutopan);
