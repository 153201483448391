import React, {useState} from "react";
import ContactCard from "../core/ContactCard";
import Button from "../core/Button";
import NewAlert from "../core/NewAlert";
import useApi from "../../hooks/useApi";
import Preloader from "../Preloader";
import SubmitButton from "../SubmitButton";
import ('./index.scss')

const FixationModal = ({id}) => {
  const [requestMessage, setRequestMessage] = useState(false)
  const {data: {fixation, fixationInfo, managerInfo, isExtendFixation, }, send, isLoading, isSending} = useApi({
    payload: ["panpartner:deals.ajax", "getFixation", {id: id}],
  },[requestMessage])

  console.log(fixation?.status)

  const extendFixation = () => send(["panpartner:deals.ajax", "extendFixation", {id: id}])
      .then( resp => {
        setRequestMessage(resp.message)
      })
    const updateStatus = () => send(["panpartner:deals.ajax", "getFixation", {id: id}])
      .then(resp => {
      })

  if (isLoading) return <Preloader loading/>

  const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16))

  return (
    <div className="fixation-modal">
      <div className="fixation-modal__header">
        {fixation && <p className="h3">{fixation.client}</p>}
        {
          fixation?.endFixationPeriod ?
            <span className="fixation-modal__status" style={{color: '#FF2D23', backgroundColor: 'rgba(255, 45, 35, 0.2)'}}>{fixation.endFixationPeriod}</span> :
            <span className="fixation-modal__status" style={{color: fixation?.status.color, backgroundColor: `rgba(${hexToRgb(fixation?.status.color)+',0.2'})`}}>{fixation?.status.title}</span>
        }
      </div>

      {fixationInfo && fixationInfo.map((i) => {
        return (
          <div className="fixation-modal__field">
            <p className="fixation-modal__label">{i.title}</p>
            <p className="h6"  dangerouslySetInnerHTML={{__html: i.value}}/>
          </div>
        )
      })}

      <div className="fixation-modal__manager">
        <p className="fixation-modal__manager-title">Подробную информацию уточняйте у менеджера</p>
        <ContactCard
          name={managerInfo.name}
          phone={managerInfo.phone}
          post="Специалист отдела партнерских продаж"
          telegram={managerInfo.telegram !== '' ? managerInfo.telegram : false}
          logo={managerInfo.photo.src}
        />
      </div>
      <div className="fixation-modal__action">
        {isExtendFixation &&
        <SubmitButton
          className="btn h6 btn_primary btn_full"
          isLoading={isLoading || isSending}
          type="submit"
          onClick={() => {
            extendFixation()
            updateStatus()
          }}
        >
          Продлить фиксацию
        </SubmitButton> }
        {
          requestMessage && <NewAlert
            title="Заявка на продление фиксации отправлена"
            text={requestMessage}
            bgColor="#DFF5E0"
            iconColor="#2CBA2E"
          />
        }

      </div>
    </div>
  )
}

export default FixationModal
