import React, { memo } from 'react';
import BackToTop from '../BackToTop';
import Footer from '../Footer';
import Header from '../Header';
import HeaderPublic from '../Public/markup/Header';

import { favoritesCount } from '../../selectors/user';

const Dashboard = memo(({
    children,
    hideHeader = false,
    hideFooter = false,
    isPublic = false,
    isAssignments,
    showPublicHeader,
    addMainClass = '',
    disableTitle,
    redesign,
    ...rest
}) => {

    if (disableTitle) document.title = 'ПАН партнер';

    return (
        <>
            {/*<div className="content">*/}
            <div className={`content` + `${redesign ? ' content_redesign-mobile' : ''}`}>
                {!hideHeader && <Header redesign={redesign} isAssignments={window.location.pathname.includes("/assignments")} isPublic={isPublic} />}
                {/* {showPublicHeader && <PublicHeader {...rest} />} */}
                {showPublicHeader && <HeaderPublic {...rest} />}

                {/*{!isPublic && <PanelContainer />}*/}
                <main className={addMainClass}>{children}</main>
            </div>
            {!hideFooter && <Footer />}
            <BackToTop />
        </>
    );
});

export default Dashboard;