import request from "../api";
import { actionFactory } from './actionUtils';

const requestMyObjects = actionFactory("MY_OBJECTS_REQUEST");

const successMyObjects = actionFactory("MY_OBJECTS_SUCCESS");

const removeMyObjects = actionFactory("MY_OBJECTS_REMOVE");

const failureMyObjects = actionFactory("MY_OBJECTS_FAILURE");

export const fetchMyObjects = () =>
  dispatch => {
    dispatch(requestMyObjects());
    return request("panpartner:profile.ajax", "getMyObjects", null, true)
      .then(
        resp => dispatch(
          successMyObjects({
            data: resp.myObjects
          })
        ),
        error => {
          dispatch(failureMyObjects());
          return Promise.reject(error);
        }
      );
  };

export const removeRequest = requestId =>
  dispatch =>
    request("panpartner:requests.ajax", "removeRequest", {
      city: "spb",
      requestId
    }, true)
      .then(
        data => {
          dispatch(removeMyObjects({ id: requestId }));
          return Promise.resolve(data);
        },
      );