import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import Dropdown from '../../components/core/Dropdown';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast, successToast } from '../../components/toasts';
import useMedia from "../../hooks/useMedia";
import useUniversalForm from '../../hooks/useUniversalForm';
import { scrollToRef } from "../../utils";
import { accordPrograms, programsList } from "./data.js";
import './index.scss';



const Accordeon = ({number, title, items, scrollToFooter}) => {

  const defaultState = () => number === 1;
  const [isOpened, setIsOpened] = useState(defaultState);

  return (
    <div className="sor__prog-item" key={`sor-prog-${number}`}>
      <div className="sor__prog-header" onClick={() => setIsOpened(prevState => !prevState)}>
        <div className="sor__prog-label">программа {number}</div>
        <h3 className="h3 sor__h3">{title}</h3>

        <button
          type="button"
          className={`sor__accordion-btn${isOpened ? ` opened` : ``}`}
          aria-label="Открыть панель">
          <svg className="icon icon_more">
            <use xlinkHref="#chevron"></use>
          </svg>
        </button>
      </div>
      <div className={`sor__prog-body${isOpened ? ` opened` : ``}`}>
        <img src={require(`../../assets/images/sor/prog${number}.jpg`).default} alt="" className="sor__prog-img" />
        <div>

          <ul className="sor__prog-ul big-text">
            {items.map((item) => (
              <li key={`sor-item-${number}`}>{item}</li>
              ))}
          </ul>

          <button type="button" className="sor__prog-btn button button_view_default" onClick={scrollToFooter}>Записаться</button>
        </div>
      </div>
    </div>
  );
};

const SchoolOnRoad = (props) => {

  const {userInfo = {name: "", phone: "", lastName: "", email: ""}} = props;

  const errors = false;
  const progRef = useRef();
  const formRef = useRef();

  const { register } = useForm();

  const scrollToFooter = () => scrollToRef(formRef, 0);

  const [agree, setAgree] = useState(true)
  const { send, setValue, form, isLoading, getValue } = useUniversalForm({
    formId: 45,
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic",
    defaultValues: {
      date_time: new Date(new Date().setHours(34)),
      program: programsList[0].title
    }
  })
  const handleSend = (params) => {
    if (!agree) return errorToast("Необходимо согласие на обработку персональных данных")
    send().then(() => successToast("Заявка отправлена"), standartErrorToast)
  }

  const isMobile = useMedia("isMobile");

  const filterPassedTime = (time) => {
    const hours = new Date(time).getHours()
    return hours >= 10 && hours < 18 

  };
  return (
    <section className="section section_view_white">

      <section className="sor">
        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">

                <h1 className="sor__h1">Выездное обучение<br />у вас в офисе</h1>
                <p className="sor__sign big-text">По застройщикам, объектам и техникам продаж</p>


                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/sor/img1.png').default} alt="" className="sor__promo-img-lg" />
                  <img src={require('../../assets/images/sor/img2.png').default} alt="" className="sor__promo-img-sm" />
                  <img src={require('../../assets/images/sor/textLogo.svg').default} alt="" className="sor__promo-svg rotating-reverse" />
                </div>
              </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 0)}>Записаться бесплатно</button>
                <button type="button" className="sor__promo-btn button button_view_outline-with-icon-invert" onClick={() => scrollToRef(progRef, -325)}>Программы обучения</button>
              </div>

              <ul className="sor__faq">
                <li className="sor__faq-item">
                  <span className="sor__faq-title">когда</span>
                  <p className="sor__faq-descr">В любое время</p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">как</span>
                  <p className="sor__faq-descr">В удобном формате в близком кругу коллег</p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">для кого</span>
                  <p className="sor__faq-descr">В агентствах от 5 человек</p>
                </li>
              </ul>
            </div>
          </div>
        </header>

        <div className="wrapper">
          <div className="wrapper__center">

            <section className="sor__about">
              <div className="sor__about-item">
                <p className="sor__about-header">
                  <span className="sor__about-counter">01 / </span>
                  <p className="sor__about-title">мы едем к вам</p>
                </p>
                <p className="sor__about-text big-text">Согласуем тему и время выездного обучения и сами приедем к вам в офис с подробной теорией по объектам.</p>
              </div>

              <div className="sor__about-item">
                <p className="sor__about-header">
                  <span className="sor__about-counter">02 / </span>
                  <p className="sor__about-title">рассказываем больше</p>
                </p>
                <p className="sor__about-text big-text">Уделим повышенное внимание вопросам, которые остаются не раскрытыми в онлайн-обучениях или обучениях в больших группах.</p>
              </div>

              <div className="sor__about-item">
                <p className="sor__about-header">
                  <span className="sor__about-counter">03 / </span>
                  <p className="sor__about-title">гарантируем результат</p>
                </p>
                <p className="sor__about-text big-text">Поделимся экспертизой и позаботимся, чтобы занятия прошли комфортно для каждого сотрудника, и плодотворно для всей компании.</p>
              </div>
            </section>

            <section className="sor__prog">
              <div className="sor__prog-t">
                <h2 className="sor__prog-title sor__h2">Выберите программу для своего агентства</h2>
              </div>

              <div className="sor__prog-list">

                {accordPrograms.map((prog, index) => (
                  <Accordeon {...prog} scrollToFooter={scrollToFooter} key={`prog-${prog.number}`} />
                ))}
                <div className="sor__prog-ref" ref={progRef}></div>
              </div>
            </section>
          </div>
        </div>
        <section className="sor__footer" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="h2 sor__h2 sor__footer-title">Выберите обучение под свой запрос и оставьте заявку нашему управляющему</h2>
              </div>
              <form action="#" className="sor__form">
                <div className="sor__form-box">

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-program"
                    >
                      <span className="input__label">Программа обучения</span>
                      <Dropdown
                        options={programsList}
                        value={(() => {
                          const value = getValue("program")
                          const item = programsList.find(program => program.title === value)
                          return item?.id
                        })()}
                        onChange={(id) => {
                          const item = programsList.find(program => program.id === id)
                          setValue(item.title, "program")
                        }}
                      />
                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-date"
                    >
                      <span className="input__label">Дата и время</span>
                      <DatePicker
                        dateFormat="d MMM yyyy, HH:mm"

                        minDate={new Date(new Date().setHours(24))}
                        showTimeSelect={true}
                        selected={getValue("date_time")}
                        
                        todayButton="Сегодня"
                        dropdownMode="select"
                        autoComplete="off"
                        className="input__control"
                        onChange={(date) => setValue(date, "date_time")}
                        shouldCloseOnSelect
                        value={getValue("date_time")}
                        timeCaption="Время"
                        filterTime={filterPassedTime}
                      />

                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-name"
                    >
                      <span className="input__label">Имя</span>
                      <input
                        id="modal-reservation-name"
                        name="name"
                        defaultValue={userInfo.name}
                        className={
                          "input__control" + (errors.name ? " input__error" : "")
                        }
                        placeholder="Ваше имя"
                        onChange={e => setValue(e.target.value, "name")}
                      />
                      {errors.name && (
                        <span className="input__error-label">
                          {errors.name.name}
                        </span>
                      )}
                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-phone"
                    >
                      <span className="input__label">Телефон</span>
                      <InputMask
                        id="modal-reservation-phone"
                        className={
                          "input__control" + (errors.phone ? " input__error" : "")
                        }
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        name="phone"
                        placeholder="Ваш телефон"
                        defaultValue={userInfo.phone}
                        onChange={e => setValue(e.target.value, "phone")}
                      />
                      {errors.name && (
                        <span className="input__error-label">
                          {errors.name.phone}
                        </span>
                      )}
                    </label>
                  </div>


                  <SubmitButton type="button" className="sor__submit button button_view_default" onClick={handleSend} isLoading={isLoading}>Оставить заявку</SubmitButton>


                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box'
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

      </section>
    </section>
  );
};

export default SchoolOnRoad;
