import floor from 'lodash/floor';
import React from 'react';
import { formatDate, formatMoney } from '../../../utils';

const Prices = ({ dynamicPrice = {}, mortgage = {}, settings }) => {
  const { actualPrice, startPrice, diff, diffMeterPrice } = dynamicPrice
  const startCost = startPrice?.price
  const todayCost = actualPrice?.price
  const isPositiveDynamic = todayCost > startCost
  const priceChanged = todayCost !== startCost
  return <div className="presentation__info pdf-block presentation__info_flat" style={{marginTop: 20}}>

    <div className="presentation__box" data-id="dynamic_price">
      <div className="presentation__title">Динамика цены</div>
      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          <div className="presentation__prices-box">
            <p className="presentation__prices-key">Цена на {formatDate(startPrice?.date, {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}</p>
            <p className="presentation__prices-value">{formatMoney(startCost)} руб.</p>
            <p className="presentation__prices-sub">/ {formatMoney(startPrice?.meterPrice)} руб./м²</p>
          </div>
        </div>
        <div className="presentation__info-col">
          <div className="presentation__prices-box">
            <p className="presentation__prices-key">Цена на сегодня, {formatDate(actualPrice?.date, {
              day: "numeric",
              month: "long",
            })}</p>
            <p className="presentation__prices-value">{formatMoney(todayCost)} руб.
              {priceChanged && <span className="presentation__prices-positive">
                <span className={`chart__diff  ${isPositiveDynamic ? "chart__diff_plus" : "chart__diff_minus"}`}>
                  <svg class="icon icon_triangle-down">
                    <use xlinkHref="#triangle-down"></use>
                  </svg>
                </span>
              </span>}
            </p>
            <p className="presentation__prices-sub">/ {formatMoney(actualPrice?.meterPrice)}./м²</p>
          </div>
        </div>
        {priceChanged && <div className="presentation__info-col">
          <div className="presentation__prices-box">
            <p className="presentation__prices-key">Общая динамика</p>
            <p className="presentation__prices-value">
              + {formatMoney(diff)} руб.
              <span className="presentation__prices-plus">{isPositiveDynamic ? "+" : "-"} {floor(diff / startCost * 100, 2)}%</span>
            </p>
          </div>
        </div>}
        {priceChanged && <div className="presentation__info-col">
          <div className="presentation__prices-box">
            <p className="presentation__prices-key">Динамика за м²</p>
            <p className="presentation__prices-value">{isPositiveDynamic ? "+" : "-"} {formatMoney(diffMeterPrice)} руб./м²</p>
          </div>
        </div>}

      </div>

    </div>

  </div>
};
export default Prices;