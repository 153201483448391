import React from 'react';
import { formatMoney } from '../../../utils';

const Mortgage = ({ mortgage = {}, objectName = "квартиры" }) => {

  return <div className="presentation__info pdf-block presentation__info_flat" style={{ marginBottom: 50 }}>
    <div className="presentation__box" data-id="mortgage">
      <div className="presentation__title">Расчет ипотеки</div>
      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          Ежемесячный платеж
        </div>
        <div className="presentation__info-col">
          {formatMoney(mortgage.monthlyPayment)} руб.
        </div>
      </div>

      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          Стоимость {objectName}
        </div>
        <div className="presentation__info-col">
          {formatMoney(mortgage.cost)} руб.
        </div>
      </div>

      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          Первоночальный взнос
        </div>
        <div className="presentation__info-col">
          <span className="color-gray">{mortgage.initialPaymentPercent}%</span> / {formatMoney(mortgage.initialPayment)} руб.
        </div>
      </div>

      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          Срок ипотеки
        </div>
        <div className="presentation__info-col">
          {mortgage.period} лет
        </div>
      </div>

      <div className="presentation__info-grid">
        <div className="presentation__info-col">
          Процентная ставка
        </div>
        <div className="presentation__info-col">
          {mortgage.percent}%
        </div>
      </div>

      <p className="presentation__prices-info">Расчет ипотечного кредита является предварительным и не является публичной офертой. Для окончательного расчета ежемесячных платежей по ипотеке обратитесь к своему менеджеру</p>
    </div>

  </div>
};
export default Mortgage;