import formatter from "format-number";
import chunk from 'lodash/chunk';
import React from "react";
import emptyPlan from '../../assets/images/emptyPlan.svg';
import { getRoundedPrice, media } from "../../utils";
export const filterToParam = (filter, id, isPublic) => {
  let params = {};
  params["filter[blockId]"] = id;
  if (!filter.number && !filter.number.replace(/\s/g, "").length > 0) {
    Object.entries(filter).forEach(([key, value]) => {
      switch (key) {
        case "squareTotal":
        case "squareKitchen":
        case "prices":
        case "cellHeight":
        case "floors":
        case "endings":
        case 'issuingKeys':
        if(!value){
          break
        }
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (subValue !== null && subValue.length > 0) {
              params[`filter[${key}][${subKey}]`] = subValue;
            }
          });
          break;
        case "isOnlyHanded":
        case "isTwoLevels":
        case "masterBedroom":  
          if (value?.length > 0) {
            params[`filter[${key}]`] = "Y";
          }
          break;
        case "isApartments":
          if (value?.length > 0) {
            params[`filter[isApartments]`] = value[0].id;
          }
          break;
        case "assignments":
          if (value?.length > 0) {
            params[`filter[assignments]`] = value[0].id;
          }
          if (value?.length > 0 && typeof value === "string") {
            params[`filter[assignments]`] = value;
          }
          break;
        default:
          if (value && value.length) {
            params[`filter[${key}]`] = value;
          }
      }
    });
  } else {
    params["filter[number]"] = filter.number;
  }
  if (!isPublic) {
    params["isAssignment"] = true
  }
  return params;
};

export const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);
export const whitelistForCardClick = [
  "card__header",
  "card__content",
  "card__title",
  "card__address",
  "card__left",
  "card__right",
  "card__metro-station",
  "card__left-part",
  "card__metro-color card__metro-color_1",
  "card__dates-to",
  "card__dates-from",
  "card__pill",
  "card__option",

  "flats__card-features",
  "flats__card-feature_value",
  "flats__card-feature_title",
  "card__dates-from",
  "flats__card-info",
  "flats__card-price",
  "for-click-class",
];

const TOOLTIP_CUT_VALUE = 14;

export const getColumns = (isDesktop, city) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 80,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "План.",
    dataIndex: "plan",
    key: "plan",
    width: isDesktop ? 50 : 80,
    render: (plan) => {
      return (
        <div className="table-img">
          <img src={plan.src} alt=""  onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }}/>
          <div className="table-img__preview">
            <img src={plan.src} alt="" onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }} />
          </div>
        </div>
      )
    },
  },
  {
    title: "Корп.",
    dataIndex: "section",
    key: "section",
    sorter: (a, b) => a.section - b.section,
  },
  {
    title: isDesktop ? "Эт." : "Этаж",
    dataIndex: "floor",
    key: "floor",
    sorter: (a, b) => a.floor - b.floor,
  },
  {
    title: "№ кв.",
    dataIndex: "number",
    key: "number",
    sorter: (a, b) => a.number - b.number,
  },
  {
    title: "S общ.",
    dataIndex: "squareTotal",
    key: "squareTotal",
    sorter: (a, b) => a.squareTotal - b.squareTotal,
  },
  {
    title: "S жил.",
    dataIndex: "squareLiving",
    key: "squareLiving",
    sorter: (a, b) => a.squareLiving - b.squareLiving,
  },
  {
    title: "S кух.",
    dataIndex: "squareKitchen",
    key: "squareKitchen",
    sorter: (a, b) => a.squareKitchen - b.squareKitchen,
  },
  {
    title: "Отделка",
    dataIndex: "decoration",
    key: "decoration",
    sorter: (a, b) => a.decoration?.length - b.decoration?.length,
    render: (decoration) => {
      return decoration?.length > TOOLTIP_CUT_VALUE ? (
        <div className="table2-with-tooltip">
          <span>{`${decoration.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
          <div  className="table2-with-tooltip__content">{decoration}</div>
        </div>
      ) : (
        <span>{decoration}</span>
      )
    },
  },
  {
    title: "Цена 100%",
    dataIndex: "costWithDiscount",
    key: "costWithDiscount",
    sorter: (a, b) => a.costWithDiscount - b.costWithDiscount,
    render: (price) => <span className="nowrap">{priceFormat(price)}</span>,
    // width: 120,
  },
  {
    title: "Цена, м²",
    dataIndex: "minMeterPrice",
    key: "minMeterPrice",
    sorter: (a, b) => a.minMeterPrice - b.minMeterPrice,
    render: (costPerMeter) => <span className="nowrap">{priceFormat(costPerMeter)}</span>,
    // width: 120,
  },
  {
    title: "Цена баз.",
    dataIndex: "baseCost",
    key: "baseCost",
    sorter: (a, b) => a.baseCost - b.baseCost,
    render: (price, item) => {
      return <span className="nowrap">{priceFormat(item.objectType === "apartment" ? price : item.costWithDiscount)}</span>
    },
    width: 100,
  },
  {
    title: "Тип",
    dataIndex: "flatType",
    key: "flatType",
    sorter: (a, b) => a.flatType?.length - b.flatType?.length,
    width: 100,
    render: (flatType, item, index) => {
      if (item.objectType === "assignment") {
        return <div class="status-assignment">Уступка ПАН</div>
      }
      return flatType?.length > TOOLTIP_CUT_VALUE ? (
        <div className="table2-with-tooltip">
          <span className="nowrap">{`${flatType.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
          <div  className="table2-with-tooltip__content">{flatType}</div>
        </div>
      ) : (
        <span>{flatType}</span>
      )
    },
  },

  {
    title: "Статус",
    dataIndex: "status",
    key: "status1",
    sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
    width: 60,
    render: (status, record) => {
      return (
        <div class="table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
          <div className="status-anchors">
            <a href={`/flat-booking/${record.objectType}/${record.id}/${city}`} target="_blank" onClick={e=> e.stopPropagation()}>
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M16.4409 2.59855C14.2024 0.312851 10.4717 0.312851 8.23322 2.59855C6.74092 4.12236 6.24348 6.40806 6.86528 8.56678L0.274261 15.2969C0.149902 15.4239 0.149902 15.5509 0.149902 15.6779V18.7255C0.149902 18.9794 0.39862 19.2334 0.647338 19.2334H3.63195C3.75631 19.2334 3.75631 19.2334 3.88067 19.2334V17.2017H5.87041V15.1699H7.73579V13.1382H9.72553L10.4717 12.3763C12.5858 13.0112 14.8242 12.5033 16.3165 10.9795C18.8037 8.69376 18.8037 4.88426 16.4409 2.59855ZM14.9486 6.40806C14.3268 7.04298 13.3319 7.04298 12.7101 6.40806C12.0883 5.77314 12.0883 4.75727 12.7101 4.12236C13.3319 3.48744 14.3268 3.48744 14.9486 4.12236C15.5704 4.75727 15.5704 5.77314 14.9486 6.40806Z" />
              </svg>
            </a>
          </div>
        </div>
      )
    },
  },
];

export const getMobileColumns = () => [
  {
    title: "Пл",
    dataIndex: "plan",
    key: "plan",
    render: (plan) => {
      return (
        <div className="table-img">
          <img src={plan.src} alt="" onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }} />
        </div>
      )
    },
  },
  {
    title: "Эт",
    dataIndex: "floor",
    key: "floor",
  },
  {
    title: "S общ",
    dataIndex: "squareTotal",
    key: "squareTotal",
    render: (square) => <span className="nowrap">{square} м²</span>,
  },
  {
    title: "Цена",
    dataIndex: "costWithDiscount",
    key: "costWithDiscount",
    render: (price) => <span className="nowrap">{getRoundedPrice(price)} млн.</span>,
  },
  {
    title: "Тип",
    dataIndex: "flatType",
    key: "flatType",
    render: (flatType, item, index) => {
      if (item.objectType === "assignment") {
        return <div class="status-assignment">Переуст</div>
      }
      return <div className="surface" onClick={e => { if (media("isLaptop")) e.stopPropagation() }}>
      <div className="surface__wrap">{flatType}</div>
    </div>
    },
  },
  {
    title: "Стат",
    dataIndex: "status",
    key: "status1",
    render: (status) => {
      return (
        <div class="table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>
      )
    },
  },
];

export const loadItems = (prevArray = [], page = 0) =>
new Promise((resolve) => {
  setTimeout(() => {
    let newArray = [];
    if (prevArray.length > page * 15) {
      newArray = chunk(prevArray, 15)[page];
    }
    resolve(newArray);
  }, 100);
});
