import React, { memo, useMemo, useState } from "react";
import Aeropanorama from '../../assets/images/Aeropanorama.svg';
import T3DTour from '../../assets/images/T3DTour.svg';
import TabContent from "./TabContent";
import Tabs from "./Tabs";
import useMedia from "../../hooks/useMedia";


const navTitles = {
  complex: "Фотографии",
  generalPlan: "Ген. план",
  decoration: "Отделка",
  interior: "Интерьер",
  aeroPanorama: "Панорама",
  tour: "Шоу-рум",
  "decorationFinish": "Чистовая",
  "decorationPreFinish": "Подчистовая",
  "decorationRaw": "Без отделки",
  plan: "Планировка",
  floorPlan: "План этажа",
  gidImg: "На карте",
  viewFromWindow: "Вид из окна",
  viewFromWindowCords: "Вид из окна",
};
const Viewer = memo(props => {
  const {
    isCounstructor, //оставил чтобы не забыть в конструкторе потом убрать аэропанораму


    addClass,
    onPhotoOpen,
    onPhotoClosed,
    blurBg,

    tabs = ["complex", "generalPlan", "plan", "decoration", "interior", "aeroPanorama", "decorationFinish", "decorationPreFinish", "decorationRaw"],
    data = {},
    statusSold,
    onTabChange,
    tabDataProps = {
      aeroPanorama: {
        icon: props.disableIcons ? null : Aeropanorama,
        type: "iframe"
      },
      tour: {
        icon: props.disableIcons ? null : T3DTour,
        type: "iframe"
      },
      viewFromWindowCords: {
        type: "map"
      }
    }
  } = props;

  const isTablet = useMedia("isTablet");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const [activeTab, setActiveTab] = useState(0);

  const setCurrentTab = id => {
    if (onTabChange) onTabChange(id);
    setActiveTab(id)
  }

  const tabsData = useMemo(() => {
    const nav = [];
    for (const id of tabs) {
      if (!data[id]) continue;
      (data[id]?.length > 0 || Object.keys(data[id]).length > 0) && nav.push({
        id,
        type: "photos",
        title: navTitles[id],
        ...(tabDataProps[id] ? tabDataProps[id] : []),
      })
    }
    return nav
  }, [data]);

  const activeTabData = useMemo(() => {
    const tab = tabsData[activeTab]
    if (!tab) return {}
    return tab
  }, [data, activeTab])

  if (!tabsData.length) return null
  return (
    <div className={`complex__images${addClass ? ` ${addClass}` : ``}`}>
      <Tabs setTab={setCurrentTab} active={activeTab} tabs={tabsData} />
      <div className="complex__slider gallery">
        <div className={`complex__slider-tab active`} style={{ height: isTablet ? 200 : isDesktop ? 432 : 500 }}>
          <TabContent
            type={tabsData[activeTab].type}
            photos={data[activeTabData.id]}
            identity={activeTab}
            current={activeTab}
            blurBg={blurBg}
            customIdent={props.customIdent || "test"}
            statusSold={statusSold}
            onPhotoOpen={onPhotoOpen}
            onPhotoClosed={onPhotoClosed}
            activeTabData={activeTabData}
            {...data[activeTabData.id]}
          />
        </div>
      </div>
    </div>
  );
});

export default Viewer;