
import { useEffect } from 'react';
import { isConstructor } from '../containers/RootContainer';

const useDocTitle = title => {
    useEffect(() => {
        if(!title || isConstructor) return
        setTimeout(()=> document.title = title)
    }, [title])
}
export default useDocTitle
