import printJS from 'print-js';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { getData, getPdf, sendRequest } from '../../actions/newPartner';
import { getUrl } from '../../api';
import Modal from "../../components/core/Modal";
import Preloader from '../../components/Preloader';
import SubmitButton from "../../components/SubmitButton";
import { standartErrorToast, successToast } from '../../components/toasts';
import { downloadLink, months } from '../../utils';
import './index.scss';


const FutureCallbackModal = ({ sendForm, close }) => {

  const { handleSubmit, register, errors, control } = useForm();
  const submit = data => {
    sendForm(data.phone, data.name, data.agency).then(() => close())
  }
  return (
    <>
      <h2 className="modal__title">Обратный звонок</h2>
      <form className="modal-form" onSubmit={handleSubmit(submit)} name="callback-modal">
        <div className="modal-form__wrapper">
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Имя и фамилия</span>
                <input
                  name="name"
                  className={
                    "input__control"
                  }
                  placeholder="Введите имя и фамилию"
                  ref={register}
                />
              </label>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-phone"
              >
                <span className="input__label">Телефон</span>
                <InputMask
                  className={
                    'input__control'
                  }
                  name="phone"
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  inputRef={register}
                />

              </label>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Агентство</span>
                <input
                  name="agency"
                  className={
                    "input__control"
                  }
                  placeholder="Введите агентство"
                  ref={register}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

const Future = (props) => {
  const params = new URLSearchParams(window.location.search)
  const managerId = params.get("id")

  const [data, setData] = useState(null)
  const [motivations, setMotivations] = useState([])
  const [actions, setActions] = useState([])
  const [phone, setPhone] = useState(null)
  const [prepaymentOnly, setPrepaymentOnly] = useState(false)
  const [pdfLink, setPdfLink] = useState(null)
  useEffect(() => {
    getData(managerId).then(resp => {
      setData(resp)
      setMotivations(resp.motivations?.slice(0, 4) || [])
      setActions(resp.actions?.slice(0, 4) || [])
    })
  }, []);

  const getFullData = (type) => {
    type === "actions" ? setActions(data.actions) : setMotivations(data.motivations)
  }

  const showPrepaymentOnly = (checked) => {

    if (checked) {
      const filteredMotivations = data.motivations.filter(item => item.prepayment)
      setMotivations(data.motivations.length === motivations.length ? filteredMotivations : filteredMotivations.slice(0, 4))

    } else {
      setMotivations(motivations.length > 4 ? data.motivations : data.motivations.slice(0, 4))
    }
    setPrepaymentOnly(checked)

  }


  const sendCallBack = (phone, name, agency) => sendRequest(managerId, phone, name, agency).then(() => {
    setPhone(null)
    successToast(
      <div className="Toastify__text">
        Заявка отправлена
    </div>
    )
  },
    standartErrorToast
  )

  const getPDF = (type) => {
    if (pdfLink) {
      type === "download" ?
        downloadLink(pdfLink) :
        printJS(pdfLink)
      return
    }
    getPdf(managerId).then((data) => {
      setPdfLink(data.filePath)
      type === "download" ?
        downloadLink(data.filePath) :
        printJS(data.filePath)
    });
  }
  const formatDate = (dateStr, isShort) => {
    const date = new Date(dateStr)

    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    if (isShort) {
      return `${day.toString().length === 1 ? "0" + day : day}.${(month + 1).toString().length === 1 ? "0" + (month + 1) : (month + 1)}.${year}`
    }
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return [`${day} ${months[month]} ${year}`, `${hours}:${minutes.toString().length === 1 ? "0" + minutes : minutes}`]
  }

  const [futureModal, hideFutureModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideFutureModal}>
        <FutureCallbackModal close={hideFutureModal} sendForm={sendCallBack} />
      </Modal>
    )
  );
  if (!data) return <Preloader loading />
  return (
    <>
      <section className="future section section_view_white">

        <aside className="panel visible_note">
          <div className="panel__block">
            <ul className="panel__list">
              <li className="panel__item">
                <div className="panel__link" onClick={getPDF}>
                  <svg className="icon icon_print"><use xlinkHref="#icon_print_blank"></use></svg>
                  <div className="panel__tooltip"><div className="panel__tooltip-title">Версия для печати</div></div>
                </div>
              </li>
              <li className="panel__item">
                <div className="panel__link" onClick={() => getPDF("download")}>
                  <svg className="icon icon_download"><use xlinkHref="#icon_download_blank"></use></svg>
                  <div className="panel__tooltip"><div className="panel__tooltip-title">Скачать</div></div>
                </div>
              </li>
            </ul>
          </div>
          <div className="panel__block panel__social">
            <ul className="panel__list">
              <li class="panel__item">
                <a class="panel__link" href="https://www.instagram.com/panpartner/" target="_blank" rel="noopener noreferrer">
                  <svg class="icon icon_ig"><use xlinkHref="#social-ig"></use></svg>
                </a>
              </li>
              <li class="panel__item">
                <a class="panel__link" href="https://tlgg.ru/panpartner" target="_blank" rel="noopener noreferrer">
                  <svg class="icon icon_tg"><use xlinkHref="#social-tg"></use></svg>
                </a>
              </li>
              <li class="panel__item">
                <a class="panel__link" href="https://www.facebook.com/PANpartnerspb" target="_blank" rel="noopener noreferrer">
                  <svg class="icon icon_fb"><use xlinkHref="#social-fb"></use></svg>
                </a>
              </li>
              <li class="panel__item">
                <a class="panel__link" href="https://vk.com/panpartnerspb" target="_blank" rel="noopener noreferrer">
                  <svg class="icon icon_vk"><use xlinkHref="#social-vk"></use></svg>
                </a>
              </li>
            </ul>
          </div>
        </aside>

        <header className="future__header">
          <div className="wrapper">
            <div className="future__header-box">
              <svg className="icon icon_logo"><use xlinkHref="#logo"></use></svg>
              <h1 className="future__h1">Лучшая партнерская программа для профессионалов рынка недвижимости</h1>
              <button className="future__callback button button_view_default" onClick={futureModal}>
                <svg className="icon">
                  <use xlinkHref="#phone"></use>
                </svg>
              Обратный звонок
            </button>
              <a className="future__header-phone future__anchor" href="tel:+78122439999">
                8 (812) 243 99 99
            </a>
            </div>
          </div>
        </header>


        <section className="promo">
          <div className="wrapper">
            <img
              className="promo__image"
              src={require('../../assets/images/future/promo.svg').default}
              alt=""
            />
            <div className="wrapper__center wrapper__center_start">
              <div className="promo__container">

                <div className="promo__box">

                  <h2 className="promo__box-title h2">
                    Получите 100% комиссионных со своих сделок в новостройках <span className="no-wrap">Санкт-Петербурга</span> и Москвы
                  </h2>

                  <p className="promo__box-text big-text">С предоставлением переговорных в офисе, программ обучения, лучшей тарифной карты от застройщиков и всех сервисов необходимых для работы</p>

                  <form action="#" className="promo__form">
                    <InputMask
                      className={
                        'promo__input'
                      }
                      name="phone"
                      placeholder="+ 7 ( _ _ _) _ _ _ - _ _ - _ _"
                      mask="+7 (999) 999-99-99"
                      autoComplete="off"
                      maskChar=" "
                      onChange={e => setPhone(e.target.value)}
                    />
                    <button
                      className="promo__submit button button_view_default btn"
                      type="button"
                      onClick={() => sendCallBack(phone)}
                    >Отправить заявку
                    </button>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </section>

        {actions.length> 0 && <div className="wrapper">
          <div className="wrapper__center wrapper__center_start">

            <section className="benefits future__section">
              <h2 className="h2 future__h2">{data.actionsTitle}</h2>

              <div className="benefits__list">
                {actions.map((action, i) => {

                  return <div className="benefits__item benefits__item_01" key={`benefits__item_${i}`}  >
                    <span className="benefits__date">{formatDate(action.date, true)}</span>
                    <h3 className="benefits__title h3">
                      {action.name}
                    </h3>
                    <div dangerouslySetInnerHTML={{
                      __html: action.detailText,
                    }} />
                  </div>
                })}
              </div>

              {actions.length !== data.actions.length && <button
                className="benefits__btn button button_view_default"
                type="button"
                onClick={() => getFullData("actions")}>
                Все акции
                 </button>}
            </section>
          </div>
        </div>}


        <section className="tariff future__section">
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start furute__flex-wrapper">
              <header className="future__section-header">
                <h2 className="future__h2 h2">
                  {data.motivationsTitle}
                </h2>

                <label className="switch" for="filter-cash">
                  <div className="switch__box">
                    <input className="switch__control"
                      type="checkbox"
                      id="filter-cash"
                      onChange={e => showPrepaymentOnly(e.target.checked)}
                    />
                    <div className="switch__input" for="filter-cash"></div>
                    <div className="switch__marker"></div>
                  </div>
                  <span className="switch__label">Только авансируемые</span>
                  <div className="info">
                    <svg className="icon icon_info">
                      <use xlinkHref="#info"></use>
                    </svg>
                    <div className="info__popup">
                      <p>Быстрые выплаты комиссионного вознаграждения</p>
                    </div>
                  </div>
                </label>

              </header>

              <div className="tariff__table custom-table motivation__table">
                <div className="custom-table__head">
                  <div className="custom-table__tr">
                    <div className="custom-table__th">Застройщик</div>
                    <div className="custom-table__th">Процент</div>
                    <div className="custom-table__th">Условия оплаты</div>
                  </div>
                </div>
                <div className="custom-table__body">
                  {motivations.map((motivation, i) => {
                    if (!motivation.prepayment && prepaymentOnly) return null
                    return <div className="custom-table__item custom-table__card" key={`motivations-${i}`}>
                      <div className="custom-table__tr">
                        <div className="custom-table__td">
                          <div className="motivation__builder-name">
                            {motivation.builder.name || motivation.name} {motivation.type?.name && !motivation.type?.name?.includes("Жилая")? `(${motivation.type.name})`: ""}
                            {motivation.prepayment &&
                              <p className="tariff__cash">
                                <img
                                  src={require('../../assets/images/future/cash.svg').default}
                                  alt=""
                                />
                              Авансирование
                            </p>}
                          </div>
                        </div>
                        <div className="custom-table__td">
                          <span className="tariff__mobile-header">Процент</span>
                          <div className="label-item label_purple">
                            {
                              motivation.percents.min !== motivation.percents.max ? `от ${motivation.percents.min} до ${motivation.percents.max}%` :
                                !!motivation.percents.min ? `${motivation.percents.min}%` : "По договорённости"
                            }
                          </div>
                        </div>

                        <div className="custom-table__td">
                          <span className="tariff__mobile-header">Условия оплаты</span>
                          <div className="label__text"
                            dangerouslySetInnerHTML={{
                              __html: motivation.rules,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>

              {motivations.length !== data.motivations.length && <button
                className="benefits__btn button button_view_default"
                type="button"
                onClick={getFullData}
              >
                Вся тарифная карта
              </button>}

            </div>
          </div>
        </section>

        <div className="wrapper">
          <div className="wrapper__center wrapper__center_start furute__flex-wrapper">
            {data.events.length > 0 && <section className="news">
              <h2 className="future__h2 news__title h2">
                {data.eventsTitle}
                </h2>
              <div className="tariff__table tariff__table_stripped custom-table motivation__table">
                <div className="custom-table__head">
                  <div className="custom-table__tr">
                    <div className="custom-table__th">Название</div>
                    <div className="custom-table__th">Дата и время</div>
                    <div className="custom-table__th">Спикер</div>
                  </div>
                </div>
                <div className="custom-table__body">
                  {data.events.map((event, i) => {
                    const [date, hours] = formatDate(event.date)
                    return <div className="custom-table__item" key={`event-${i}`}>
                      <div className="custom-table__tr">

                        <div className="custom-table__td">
                          <div className="motivation__builder-name motivation__builder-name_events">
                            {event.name}
                          </div>
                        </div>

                        <div className="custom-table__td">
                          <div className="tariff__date">
                            <span className="tariff__mobile-header">Дата, время:</span>
                            <span className="tariff__day">{date}</span>
                            <span className="tariff__time">{hours}</span>
                          </div>
                        </div>

                        <div className="custom-table__td">
                          <span className="tariff__mobile-header">Спикер:</span>
                          <div className="label__text">{event.speaker || "—"}</div>
                        </div>
                      </div>
                    </div>
                  }
                  )}
                </div>
              </div>

            </section>}

            <section className="manager">

              <h3 className="h3 manager__title">
                Ваш персональный менеджер
              </h3>
              <div className="manager__container">
                <div className="contact">
                  <div className="manager__block">
                    <div className="contact__photo">
                      <img src={getUrl(data.manager?.photo?.src)} width="100%" height="auto" alt=" " />
                    </div>

                    <div className="manager__info">
                      <div className="contact__name">
                        <h4 className="h4">{`${data.manager.name} ${data.manager.lastName}`}</h4>
                      </div>

                      <div className="contact__post">
                        <span>{data.manager.position}</span>
                      </div>
                    </div>
                  </div>


                  <div className="contact__contacts-block">

                    <div className="contact__contacts">
                      <div className="contact__contacts-item contact__phone">

                        <a className="link-hover h4" href={data.manager.phone}>
                          <svg className="icon">
                            <use xlinkHref="#phone"></use>
                          </svg>
                          {data.manager.phone}
                        </a>
                      </div>
                      <div className="contact__contacts-item contact__email">
                        <a className="link-hover h4" href="mailto:+7 (962) 685-76-22">
                          <svg className="icon">
                            <use xlinkHref="#social-mail"></use>
                          </svg>
                          {data.manager.email}
                        </a>
                      </div>
                    </div>

                    <ul className="contact__socials">
                      {/* {data.manager.watsApp && <li className="contact__socials-item contact__socials_whatsapp">
                        <a className="button button_view_round-control"
                          href={`https://api.whatsapp.com/send?phone=${data.manager.watsApp}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <svg className="icon">
                            <use xlinkHref="#socials_whatsapp"></use>
                          </svg>
                        </a>
                      </li>} */}
                      {data.manager.telegram && <li className="contact__socials-item contact__socials_tg">
                        <a className="button button_view_round-control" href={`https://t.me/${data.manager.telegram.replace("@", "")}`} target="_blank" rel="noopener noreferrer">
                          <svg className="icon">
                            <use xlinkHref="#socials_tg"></use>
                          </svg>
                        </a>
                      </li>}
                    </ul>
                  </div>


                </div>

              </div>

            </section>


          </div>
        </div>

        <footer className="future__footer">
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start">
              <div className="future__header-box">
                <svg className="icon icon_logo"><use xlinkHref="#logo"></use></svg>
                <p className="future__footer-text">© 2010–{new Date().getFullYear()} Площадка Актуальной Недвижимости</p>
                <div className="future__footer-separators">
                  <a className="future__footer-separator future__footer-phone future__anchor" href="tel:+78122439999">8 (812) 243 99 99</a>
                  <p className="future__footer-separator footer__address">Владимирский проспект, 23, лит. А, офис 701</p>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </section>
    </>
  );
};

export default Future;
