import React from "react";
import { getUrl } from "../../../api";
import stub from '../../../assets/images/stub-contact.png'

import './style.scss'

const ContactCard = ({logo, name, phone, post, telegram, forAptModal, photo, email}) => {
    return (
        <div className={`contact-card ${forAptModal && 'contact-card--apt-modal'}`}>
            {!!logo && <div className="contact-card__img">
              {/*{!forAptModal ? <img src={getUrl(logo)} width="70" height="70" alt=""/> : <img src={stub} width="70" height="70" alt=""/>}*/}
              {!forAptModal && <img src={getUrl(logo)} width="70" height="70" alt=""/>}
              {forAptModal && <img src={getUrl(photo?.src)} width="70" height="70" alt=""/>}
            </div>}
            <div className="contacts-card-user">
                {name && <h6 className="contacts-card-user__name">{name}</h6>}
                {!!post && <p className="contacts-card-user__post">{post}</p>}
                {!!email && <a href={`mailto:${email}`} className="link link_color_black">{email}</a>}
                <div className="contacts-card-user-info">
                  {!!phone && <span className="contacts-card-user-info__phone"><a href={`tel:${phone.replace(/-/g, '').replace(/\s/g, '')}`}>{phone}</a></span>}
                    {!!telegram && <a href={`https://t.me/${telegram}`} target="_blank" className="contacts-card-user-info__link">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30 15C30 6.716 23.284 0 15 0C6.716 0 0 6.716 0 15C0 23.284 6.716 30 15 30C23.284 30 30 23.284 30 15Z" fill="#F6F7F8"/>
                            <path d="M21.2 8.02576L6.46685 12.1746C6.34133 12.2086 6.22935 12.2805 6.14618 12.3805C6.06302 12.4805 6.0127 12.6037 6.0021 12.7333C5.9915 12.8629 6.02113 12.9926 6.08695 13.1048C6.15277 13.217 6.25157 13.3061 6.3699 13.3601L13.1353 16.5605C13.2676 16.6221 13.3739 16.7285 13.4356 16.8608L16.6391 23.6293C16.6929 23.7477 16.7819 23.8466 16.8939 23.9125C17.0059 23.9785 17.1356 24.0084 17.2652 23.998C17.3948 23.9876 17.518 23.9376 17.6181 23.8546C17.7183 23.7717 17.7904 23.6599 17.8247 23.5345L21.9745 8.8003C22.0209 8.6417 22.0027 8.47116 21.9237 8.326C21.8447 8.18085 21.7114 8.07289 21.553 8.02576C21.4378 7.99141 21.3151 7.99141 21.2 8.02576V8.02576Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.3438 16.6563L16.9161 13.084" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>}
                </div>
            </div>
        </div>
    )
}

export default ContactCard
