import { useEffect } from "react";

export const effectUseRootCSS = (config) => {
    const root = document.documentElement;
    Object.entries(config).forEach(([key, value]) => {
        if (!value) return
        root.style.setProperty(key, value);
    })
}

const useRootCSS = (config = {}) => {
    useEffect(() => {
        effectUseRootCSS(config)
    }, [config]);
}

export default useRootCSS;