import { differenceInCalendarDays } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useModal } from "react-modal-hook";
import { Link } from 'react-router-dom';
import MobileModal from "../../components/core/MobileModal";
import Modal from "../../components/core/Modal";
import Wrap from "../../components/core/ModalWrap";
import Preloader from '../../components/Preloader';
import useApi from '../../hooks/useApi';
import useMedia from "../../hooks/useMedia";


import './index.scss';
import Question from "./Question";
// import {events} from './Events/mocEvents'

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

const getAbsoluteOffset = el =>{
  const bounds = el?.getBoundingClientRect()
  if(!bounds) return 
  return bounds.top + window.scrollY
};

const ArrowIcon = () => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.47614 7.325L11.5238 4.175" stroke="#8F95AC" strokeLinecap="round" />
    <path d="M11.5239 3.825L8.47624 0.675" stroke="#8F95AC" strokeLinecap="round" />
    <path d="M11.7143 3.99996L0.5 3.99996" stroke="#8F95AC" strokeLinecap="round" />
  </svg>
);

const EmptyEventsIcon = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="35" fill="url(#paint0_linear_770_5925)" />
    <path d="M24 33H26V35H24V33Z" fill="#8F95AC" />
    <path d="M24 38H26V40H24V38Z" fill="#8F95AC" />
    <path d="M24 43H26V45H24V43Z" fill="#8F95AC" />
    <path d="M29 33H31V35H29V33Z" fill="#8F95AC" />
    <path d="M29 38H31V40H29V38Z" fill="#8F95AC" />
    <path d="M29 43H31V45H29V43Z" fill="#8F95AC" />
    <path d="M34 33H36V35H34V33Z" fill="#8F95AC" />
    <path d="M34 38H36V40H34V38Z" fill="#8F95AC" />
    <path d="M34 43H36V45H34V43Z" fill="#8F95AC" />
    <path d="M39 33H41V35H39V33Z" fill="#8F95AC" />
    <path d="M39 38H41V40H39V38Z" fill="#8F95AC" />
    <path d="M44 33H46V35H44V33Z" fill="#8F95AC" />
    <path d="M44 38H46V40H44V38Z" fill="#8F95AC" />
    <path d="M19 28H51.2173" stroke="#8F95AC" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M29 22H40.4279" stroke="#8F95AC" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M47.4393 22H48.3489H49.2585C49.4872 22 49.7137 22.0423 49.9249 22.1245C50.1362 22.2068 50.3282 22.3273 50.4899 22.4792C50.6516 22.6311 50.7799 22.8114 50.8674 23.0099C50.955 23.2084 51 23.4211 51 23.6359V48.3641C51 48.5789 50.955 48.7916 50.8674 48.9901C50.7799 49.1886 50.6516 49.3689 50.4899 49.5208C50.3282 49.6727 50.1362 49.7932 49.9249 49.8755C49.7137 49.9577 49.4872 50 49.2585 50H20.7493C20.5199 50.0009 20.2926 49.9593 20.0804 49.8776C19.8683 49.7958 19.6754 49.6754 19.5128 49.5234C19.3503 49.3714 19.2213 49.1908 19.1333 48.9918C19.0453 48.7929 19 48.5795 19 48.3641V23.6359C19 23.4205 19.0453 23.2071 19.1333 23.0082C19.2213 22.8092 19.3503 22.6286 19.5128 22.4766C19.6754 22.3246 19.8683 22.2042 20.0804 22.1224C20.2926 22.0407 20.5199 21.9991 20.7493 22H22.4052" stroke="#8F95AC" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M26 19V23" stroke="#8F95AC" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M44 19V23" stroke="#8F95AC" strokeWidth="2" strokeMiterlimit="10" />
    <defs>
      <linearGradient id="paint0_linear_770_5925" x1="33.7241" y1="3.55473" x2="35" y2="70" gradientUnits="userSpaceOnUse">
        <stop offset="0.260115" stopColor="#F3F4FC" stopOpacity="0.58" />
        <stop offset="1" stopColor="#F4F4F7" />
      </linearGradient>
    </defs>
  </svg>
);

const formattedDate = (date) => {
  const options = { day: "numeric", month: "long" };
  return new Date(date).toLocaleDateString("ru", options);
};

const formatDayOnly = (date) => {
  const options = { weekday: "long" };
  return new Date(date).toLocaleDateString("ru", options);
};

const isToday = (date) => {
  const options = { day: "numeric", month: "long" };
  return new Date().toLocaleDateString("ru", options) === new Date(date).toLocaleDateString("ru", options);
};

const isTomorrow = (date) => {
  return new Date(date).getDate() - new Date().getDate() === 1;
};
const tabItems = [{
  title: `Все`,
  id: "all"
}, 
{
  title: `Вебинар`,
  id: "webinar"
},
{
  title: `Офлайн-обучение`,
  id: "learning"
},
{
  title: `Экскурсии`,
  id: "excursion"
}];

const Events = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [currentEvent, setCurrentEvent] = useState(0);

  const dateBounds = useRef({})

  const isMobile = useMedia("isMobile");

  const { data: { events }, isLoading } = useApi({
    payload: ["panpartner:events.ajax", "getStartEvents",],
  })

  const filteredData = useMemo(() => {
    if (activeTab === 0) return events
    return events.filter((item) => item.type.xmlId === tabItems[activeTab]?.id)
  },
    [events, activeTab])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.hash.replace("#", "?"));
    const eventId = searchParam.get("eventId")
    if(!eventId || !events?.length ||!events.some(event => event.id == eventId)) return
    setCurrentEvent(eventId)
    openEventModal()
  }, [events])

  useEffect(() => {
    if(!calendarValue) return
    const tmpDate = new Date(calendarValue)
    tmpDate.setDate(calendarValue.getDate()+1)
    const val = tmpDate.toISOString().slice(0,10)
    if(!val in dateBounds.current) return
    if(!dateBounds.current[val]){
      setActiveTab(0)
      return setTimeout(()=>{
        window.scroll({
          top: dateBounds.current[val] - 80,
          behavior: "smooth",
        });
      })
    }
    window.scroll({
      top: dateBounds.current[val] - 80,
      behavior: "smooth",
    });
  }, [calendarValue])
  
  const onChangeCalendar = (nextValue) => setCalendarValue(nextValue);

  const renderTile = ({ date, view }) => {

    const eventsInCurrentDay = events.filter(item => isSameDay(new Date(item.date), date))

    if (view === 'month') {
      if (eventsInCurrentDay.length > 0) {
        return (
          <div className="hp__calendar-events">
            {eventsInCurrentDay.map((event, index) => <span key={event.id} className={`hp__calendar-circle hp__calendar-circle_${event.type.xmlId}`}></span>)}
          </div>
        )
      }
    }
  };

  const [openEventModal, closeEventModal] = useModal(
    () => {
      const data = events.find(event => event.id == currentEvent);
      const closeEvent = () => { 
        window.location.hash=``
        closeEventModal()
       }
      return (
      <Wrap wrapper={children => isMobile
        ? <MobileModal hideDefaultClose={true} className="constructor-modal" closeClick={closeEvent} title={events.title}>{children}</MobileModal>
        : <Modal hideDefaultClose={true} closeByPastClick classes="modal_task c-modal hp-modal" close={closeEvent}>{children}</Modal>
      } >
        <EventModal isMobile={isMobile} closeEventModal={closeEvent} data={data} formattedDate={formattedDate} />
      </Wrap >
    )
  }, [currentEvent]);
  const openEvent = id => { 
    window.location.hash=`#eventId=${id}`
    openEventModal()
   }
  if (isLoading) return <Preloader loading={true} />

  const tabClickHandler = (evt) => {
    setActiveTab(+evt.target.dataset.index);
  };

  const setDateBound = (date, y) => {
    if(dateBounds.current[date] && y !== undefined) return
    dateBounds.current = { ...dateBounds.current, [date]: y}
   }

  const HomeCalendar = () => {
    return (
      <>
        <div className="hp__calendar">
          <p className="hp__calendar-title hp__h4 h5">Календарь мероприятий</p>
          <Calendar
   
            //activeStartDate={new Date(item)}
            onChange={onChangeCalendar}
            // value={calendarValue}
            // minDetail={'month'}
            // view={'month'}
            // prevLabel=""
            // nextLabel=""

            //showNeighboringMonth={false}

            showNavigation={false}
            className="events-calendar"
            tileContent={renderTile}
            tileDisabled={
              ({date}) => new Date(date).getDay() === 0 || new Date(date).getDay() === 6
            }
          />
        </div>

        <div className="hp__calendar-legend">
          <span className="hp__event-status hp__event-status_webinar text">{`Вебинар`}</span>
          <span className="hp__event-status hp__event-status_learning text">{`Офлайн-обучение`}</span>
          <span className="hp__event-status hp__event-status_excursion text">{`Экскурсии`}</span>
        </div>
      </>
    )
  };

  return (
    <div className="hp__page">

      <div className="wrapper">
        <div className="wrapper__center">
          <section className="hp__become-p hp__promo hp__promo_calendar">
            <h1 className="hp__h2 h2 text-center">Мероприятия</h1>
            <p className="hp__page-sub color-brand-3 big-text text-center">Расписание полезных мероприятий для участников рынка недвижимости</p>
          </section>

          {
            isMobile && <HomeCalendar />
          }

          <EventTabs items={tabItems} activeTab={activeTab} tabClickHandler={tabClickHandler} />

          <div className={`hp__events-grid${filteredData.length === 0 ? ` hp__events-grid_align-center` : ``}`}>
            <div className="hp__events-item">
              <div className="hp__events-list">

                {
                  filteredData.length === 0 &&
                  <section className="hp__events-empty">
                    <EmptyEventsIcon />
                    <h4 className="hp__h4">Нет событий в этом месяце</h4>
                  </section>
                }

                {filteredData && filteredData.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => {
                  let insertDate = true;

                  if (index > 0) {
                    insertDate = new Date(events[index - 1].date).getDate() != new Date(item.date).getDate();
                  }

                  return <Event
                    currentTab={tabItems[activeTab]?.title}
                    data={item}
                    openEventModal={openEvent}
                    insertDate={insertDate}
                    index={item.id}
                    setCurrentEvent={setCurrentEvent}
                    key={item.id}
                    setDateBound={setDateBound}
                  />
                })}

                {/* {events && <Event data={events[0]} />} */}

              </div>
            </div>

            <div className="hp__events-item hp__events-sticky">
              {
                !isMobile && <HomeCalendar />
              }




              <article className="hp__e-socials">
                <img className="hp__e-socials-img" src={require('../../assets/images/hp/events/social-girl.svg').default} alt="" />
                <div className="hp__e-socials-content">
                  <h4 className="h4 hp__h4 hp__e-socials-title">Подписывайтесь на наши социальные сети</h4>
                  <p className="hp__e-socials-text text">Информация от экспертов в лайт-формате и на понятном языке</p>

                  <ul className="hp__e-socials-list">
                    <li className="hp__e-socials-item">
                      <a href="https://t.me/pan_live" target={`_blank`}>
                        <img src={require('../../assets/images/hp/events/social-01.png').default} alt="" />
                      </a>
                    </li>
                    <li className="hp__e-socials-item">
                      <a href="https://vk.com/panpartnerspb" target={`_blank`}>
                        <img src={require('../../assets/images/hp/events/social-02.png').default} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>

              </article>
            </div>
          </div>
        </div>

        <Question />
      </div>



    </div>
  );
};

const Event = (props) => {
  const {data, insertDate, openEventModal, index, setCurrentEvent, currentTab, setDateBound } = props;
  
  return (
    <section className="hp__events-day"
    >
      {
        insertDate &&
        (
          <header className="hp__events-header" ref={el=>setDateBound(data.date.slice(0,10), getAbsoluteOffset(el))}>
            <span className="hp__events-label">
              {
                isToday(data.date) ? `Сегодня` :
                  isTomorrow(data.date) ? `Завтра` : (
                    <>
                      {`${formattedDate(data.date)}, `}
                      <span>{formatDayOnly(data.date)}</span>
                    </>
                  )
              }
            </span>
          </header>
        )
      }

      <button
        type="button"
        className={`button hp__event hp__event_${data.type.xmlId}`}
        onClick={() => {
          setCurrentEvent(data.id);
          openEventModal(data.id);
        }}
      >
        <header className="hp__event-header">
          <span className="hp__event-time h4">{data.time}</span>

          <span className="hp__event-status text">{data.type.title}</span>
        </header>
        <h4 className="hp__event-name h5">{data.title}</h4>
        <footer className="hp__event-footer">
          <span type="button" className="button hp__event-showmore">
            Подробнее
            <ArrowIcon />
          </span>

          {
            data.countEmpty > 0 &&
            <span className="hp__event-places">
              Осталось <span className="hp__event-counterplace">{data.countEmpty}</span> мест
            </span>
          }

          {
            data.countEmpty == 0 &&
            <span className="hp__event-places">
              Мест не осталось
            </span>
          }

        </footer>
      </button>

    </section>
  );
};

const EventModal = ({data, isMobile, closeEventModal, formattedDate }) => {
  return (
    <div className="hp-modal__body">
      {!isMobile &&
        <button type="button"
          className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_with-hover"
          aria-label="Закрыть окно"
          onClick={closeEventModal}
        >
          <span className="c-modal__icon">
            <svg className="icon icon_cross">
              <use xlinkHref="#cross" />
            </svg>
          </span>
        </button>
      }

      <div className="hp-modal__content">
        <h3 className="h3 hp-modal__title">{data.title}</h3>
        <div className="hp-modal__container">
          <div className="hp-modal__info">
            <div className="hp-modal__dates hp-modal__info-item">
              <span className="hp-modal__dates-title caps">Дата и время:</span>
              <h4 className="h4 hp-modal__h4">{`${formattedDate(data.date)} в ${data.time}`}</h4>
              <span className={`hp-modal__status hp-modal__status_${data.type.xmlId}`}>{data.type.title}</span>
            </div>

            <div className="hp-modal__speaker hp-modal__info-item">
              <span className="hp-modal__dates-title caps">Спикер</span>
              <h4 className="h4 hp-modal__h4">{data.speaker}</h4>
              <span className="hp-modal__text hp-modal__dates-text text">{data.speakerPost}</span>
            </div>
          </div>

          <div className="hp-modal__right-box hp-modal__descr"
            dangerouslySetInnerHTML={{
              __html: data.detailText,
            }}>
          </div>
        </div>

        <div className="hp-modal__block">
          <div className="hp-modal__block-inner hp-modal__descr">
            Чтобы принять участие в мероприятии, <Link to={`/login?event=${data.id}`} className="link link_red">войдите</Link> или <Link to={`/register`} className="link link_red">зарегистрируйтесь</Link>
          </div>
        </div>

      </div>
    </div>
  )
};

const EventTabs = ({ items, activeTab, tabClickHandler }) => {

  return (
    <div className="hp__tabs">
      <ul className="hp__tabs-list">
        {
          items.map((it, index) => (
            <li className="hp__tabs-item" key={it.id}>
              <button
                className={`hp__tabs-btn button button_view_outline${index === activeTab ? ` hp__tabs-btn_current` : ``}`}

                onClick={tabClickHandler}
                data-index={index}>
                {it.title}
              </button>
            </li>
          ))
        }
      </ul>
    </div>
  );
};


export default Events;
