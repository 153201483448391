
export const defaultFullFilterSuburbans = Object.freeze({
    exclude: Object.freeze({
      builders: [],
      communications: [],
      bathroomTypes: [],
      countFloors: {
        max: "",
        min: ""
      },
      districts: [],
      endings: {
        max: "",
        min: ""
      },
      features: [],
      floors: {
        max: "",
        min: ""
      },
      payment: [],
      purpose: [],
      isOnlyHanded: [],
      materials: [],
      nearby: [],
      prices: {
        max: "",
        min: ""
      },
      rooms: [],
      squareHouse: {
        max: "",
        min: ""
      },
      squareParcel: {
        max: "",
        min: ""
      },
      type: [],
      villages: [],
      address: [],
    }),
    filter: Object.freeze({
      builders: [],
      communications: [],
      bathroomTypes: [],
      countFloors: {
        max: "",
        min: ""
      },
      districts: [],
      endings: {
        max: "",
        min: ""
      },
      features: [],
      floors: {
        max: "",
        min: ""
      },
      payment: [],
      purpose: [],
      isOnlyHanded: [],
      materials: [],
      nearby: [],
      prices: {
        max: "",
        min: ""
      },
      rooms: [],
      squareHouse: {
        max: "",
        min: ""
      },
      squareParcel: {
        max: "",
        min: ""
      },
      status: [1] // В продаже (шлем всегда)
      // 3, // Забронированные (при включенной галке Показывать забронированные)
      ,
      type: [],
      villages: [],
      address: [],
    })
  });
  
  export const defaultExclude = {
    assignments: [],
    availability: [],
    banks: [],
    blockClass: [],
    blocks: [],
    builders: [],
    cellHeight: {
      max: "",
      min: ""
    },
    decoration: [],
    districts: [],
    encumbrances: [],
    endings: {
      max: "",
      min: ""
    },
    floors: {
      max: "",
      min: ""
    },
    isApartments: [],
    isFirstFloor: [],
    isNoFirstFloor: [],
    isOnlyHanded: [],
    payment: [],
    prices: {
      max: "",
      min: ""
    },
    registrations: [],
    rooms: [],
    squareKitchen: {
      max: "",
      min: ""
    },
    squareTotal: {
      max: "",
      min: ""
    },
    subways: [],
    realtyType: []
  };
  export const defaultExcludeEstate = {
    assignments: [],
    availability: [],
    balconyType: [],
    banks: [],
    blockClass: [],
    blocks: [],
    builders: [],
    cellHeight: {
      max: "",
      min: ""
    },
    decoration: [],
    districts: [],
    encumbrances: [],
    endings: {
      max: "",
      min: ""
    },
    floors: {
      max: "",
      min: ""
    },
    isApartments: [],
    isFirstFloor: [],
    isNoFirstFloor: [],
    isOnlyHanded: [],
    tradeIn: [],
    payment: [],
    prices: {
      max: "",
      min: ""
    },
    registrations: [],
    rooms: [],
    squareKitchen: {
      max: "",
      min: ""
    },
    squareTotal: {
      max: "",
      min: ""
    },
    subways: []
  };
  export const defaultFullFilter = Object.freeze({
    exclude: {
      assignments: [],
      availability: [],
      banks: [],
      blockClass: [],
      blocks: [],
      builders: [],
      decoration: [],
      districts: [],
      encumbrances: [],
      endings: {
        max: "",
        min: ""
      },
      floor: {
        max: "",
        min: ""
      },
      isApartments: [],
      isFirstFloor: [],
      isNoFirstFloor: [],
      isOnlyHanded: [],
      payment: [],
      prices: {
        max: "",
        min: ""
      },
      registrations: [],
      rooms: [],
      squareKitchen: {
        max: "",
        min: ""
      },
      squareTotal: {
        max: "",
        min: ""
      },
      subways: [],
      realtyType: []
    },
    filter: Object.freeze({
      assignments: [],
      availability: [],
      banks: [],
      blocks: [],
      builders: [],
      decoration: [],
      districts: [],
      endings: {
        min: "",
        max: ""
      },
      floor: {
        min: "",
        max: ""
      },
      isApartments: [],
      isNoFirstFloor: [],
      isFirstFloor: [],
      isOnlyHanded: [],
      payment: [],
      blockClass: [],
      prices: {
        min: "",
        max: ""
      },
      rooms: [],
      squareKitchen: {
        min: "",
        max: ""
      },
      squareTotal: {
        min: "",
        max: ""
      },
      status: [1],
      // В продаже (шлем всегда)
      // 3, // Забронированные (при включенной галке Показывать забронированные)
      subways: [],
      registrations: [],
      encumbrances: [],
      realtyType: []
    })
  });
  export const defaultFullFilterSecondaries = Object.freeze({
    exclude: {
      balcony: [],
      blocks: [],
      builders: [],
      districts: [],
      endings: { min: "", max: "" },
      floors: { min: "", max: "" },
      isNoFirstFloor: [],
      objectType: [],
      prices: { min: "", max: "" },
      rooms: [],
      squareKitchen: { min: "", max: "" },
      squareTotal: { min: "", max: "" },
      subways: [],
    },
    filter: Object.freeze({
      balcony: [],
      blocks: [],
      builders: [],
      districts: [],
      endings: { min: "", max: "" },
      floors:{ min: "", max: "" },
      isNoFirstFloor: [],
      objectType: [],
      prices: { min: "", max: "" },
      rooms: [],
      squareKitchen: { min: "", max: "" },
      squareTotal: { min: "", max: "" },
      subways: [],
      status: [1]
    }),
  });
  export const defaultFullFilterEstate = Object.freeze({
    exclude: {
      assignments: [],
      availability: [],
      balconyType: [],
      banks: [],
      blockClass: [],
      blocks: [],
      builders: [],
      cellHeight: {
        max: "",
        min: ""
      },
      decoration: [],
      districts: [],
      encumbrances: [],
      endings: {
        max: "",
        min: ""
      },
      floors: {
        max: "",
        min: ""
      },
      isApartments: [],
      isFirstFloor: [],
      isNoFirstFloor: [],
      isOnlyHanded: [],
      tradeIn: [],
      issuingKeys: {
        max: "",
        min: ""
      },
      payment: [],
      prices: {
        max: "",
        min: ""
      },
      registrations: [],
      rooms: [],
      squareKitchen: {
        max: "",
        min: ""
      },
      squareTotal: {
        max: "",
        min: ""
      },
      subways: []
    },
    filter: Object.freeze({
      assignments: [],
      availability: [],
      balconyType: [],
      banks: [],
      blockClass: [],
      blocks: [],
      builders: [],
      cellHeight: {
        max: "",
        min: ""
      },
      decoration: [],
      districts: [],
      encumbrances: [],
      tradeIn: [],
      endings: {
        max: "",
        min: ""
      },
      floors: {
        max: "",
        min: ""
      },
      isApartments: [],
      isFirstFloor: [],
      isNoFirstFloor: [],
      isOnlyHanded: [],
      issuingKeys: {
        max: "",
        min: ""
      },
      payment: [],
      prices: {
        max: "",
        min: ""
      },
      registrations: [],
      rooms: [],
      squareKitchen: {
        max: "",
        min: ""
      },
      squareTotal: {
        max: "",
        min: ""
      },
      status: [1],
      // В продаже (шлем всегда)
      // 3, // Забронированные (при включенной галке Показывать забронированные)
      subways: []
    })
  });
  export const defaultFilterEstate = {
    assignments: [],
    availability: [],
    balconyType: [],
    banks: [],
    blockClass: [],
    blocks: [],
    builders: [],
    cellHeight: {
      max: "",
      min: ""
    },
    decoration: [],
    districts: [],
    encumbrances: [],
    endings: {
      max: "",
      min: ""
    },
    floors: {
      max: "",
      min: ""
    },
    isApartments: [],
    isFirstFloor: [],
    isNoFirstFloor: [],
    isOnlyHanded: [],
    tradeIn: [],
    issuingKeys: {
      max: "",
      min: ""
    },
    payment: [],
    prices: {
      max: "",
      min: ""
    },
    registrations: [],
    rooms: [],
    squareKitchen: {
      max: "",
      min: ""
    },
    squareTotal: {
      max: "",
      min: ""
    },
    status: [1],
    // В продаже (шлем всегда)
    // 3, // Забронированные (при включенной галке Показывать забронированные)
    subways: []
  };
  export const defaultFilter = {
    assignments: [],
    availability: [],
    banks: [],
    blocks: [],
    builders: [],
    decoration: [],
    districts: [],
    endings: { min: "", max: "" },
    floor: { min: "", max: "" },
    isApartments: [],
    isNoFirstFloor: [],
    isFirstFloor: [],
    isOnlyHanded: [],
    payment: [],
    blockClass: [],
    prices: { min: "", max: "" },
    cellHeight: { min: "", max: "" },
    rooms: [],
    squareKitchen: { min: "", max: "" },
    squareTotal: { min: "", max: "" },
    status: [1], // В продаже (шлем всегда)
    // 3, // Забронированные (при включенной галке Показывать забронированные)
    subways: [],
    registrations: [],
    encumbrances: [],
    issuingKeys: { min: "", max: "" },
    realtyType: []
  };


  export const defaultFullSmartFilter = Object.freeze({
    exclude: {
      address: [],
      assignments: [],
      availability: [],
      banks: [],
      blockClass: [],
      blocks: [],
      builders: [],
      decoration: [],
      districts: [],
      encumbrances: [],
      endings: {
        max: "",
        min: ""
      },
      floor: {
        max: "",
        min: ""
      },
      isApartments: [],
      isFirstFloor: [],
      isNoFirstFloor: [],
      isOnlyHanded: [],
      payment: [],
      prices: {
        max: "",
        min: ""
      },
      registrations: [],
      rooms: [],
      squareKitchen: {
        max: "",
        min: ""
      },
      squareTotal: {
        max: "",
        min: ""
      },
      subways: []
    },
    filter: Object.freeze({
      address: [],
      assignments: [],
      availability: [],
      banks: [],
      blocks: [],
      builders: [],
      decoration: [],
      districts: [],
      endings: {
        min: "",
        max: ""
      },
      floor: {
        min: "",
        max: ""
      },
      isApartments: [],
      isNoFirstFloor: [],
      isFirstFloor: [],
      isOnlyHanded: [],
      payment: [],
      blockClass: [],
      prices: {
        min: "",
        max: ""
      },
      rooms: [],
      squareKitchen: {
        min: "",
        max: ""
      },
      squareTotal: {
        min: "",
        max: ""
      },
      status: [1],
      // В продаже (шлем всегда)
      // 3, // Забронированные (при включенной галке Показывать забронированные)
      subways: [],
      registrations: [],
      encumbrances: []
    })
  });