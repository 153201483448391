import React, { memo } from 'react';
import RadioDropdown from '../../core/RadioDropdown';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import { getSelectPropsCreator } from '../utils';
import Checkbox from "../../core/Checkbox";
import ym from "react-yandex-metrika";
import useMedia from "../../../hooks/useMedia";

const PrimaryCommercialBottomFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  const isLaptop = useMedia('isLaptop');
  const isSmallLaptop = useMedia('isSmallLaptop');

  return <>
    {isSmallLaptop &&
      <EndingsFilter
        autoPosition
        ident={"endings"}
        filter={filter.endings ?? { min: "", max: "" }}
        items={filterData.endings?.items ?? []}
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
        }}
        onChange={v => setFilterAndExclude(v, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.isOnlyHanded ?? "N"
        }}
        secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
        clearFilterOnly={() => clearByIdent("endings")}
        clearExcludeOnly={() => clearByIdent("endings", "exclude")}
        wrapperClass={wrapperClass}
      />
    }
    <SelectFilter
      {...createSelectProps("decoration")}
      data={{
        title: "Отделка",
        identity: "decoration",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      autoPosition
      items={filterData.floors?.items ?? []}
      filter={filter.floors ?? {}}
      data={{
        title: 'Этаж',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.floors?.range ?? null,
      }}
      onChange={({ filter }) => handleFilter('floors', filter)}
      secondData={{
        title: filterData.isNoFirstFloor?.[0]?.title ?? 'Не первый',
        value: filter.isNoFirstFloor ?? 'N',
      }}
      secondChange={v => handleFilter('isNoFirstFloor', v === 'Y' ? v : "")}
      wrapperClass={wrapperClass}
    />


    {/* <div className="form__row">

      <div className="index-search__show-booked index-search__show-booked_fst">
        <Checkbox
          id={'tradeIn'}
          onChange={(e) => {
            handleFilter('tradeIn', e.target.checked ? "Y" : false)
            try {
              ym("reachGoal", "trade_In_filter")
            } catch (error) {

            }
          }}
          checked={filter?.tradeIn === "Y"}
          labelText={"Trade-In"}
        />
      </div>

      <div className="index-search__show-booked index-search__show-booked_second">
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status.includes("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            />
            <div className="switch__marker" />
          </div>
          <span className="switch__label">
            Забронированные
          </span>
        </label>
      </div>
    </div> */}

  </>
});

export default PrimaryCommercialBottomFilters;
