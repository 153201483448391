import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { loginByToken } from '../../actions/user';
import { socials } from '../../selectors/user';

const PreviewHeader = (props) => {
  const { headerWithBg } = props;

  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = (e) => {
    const scrollVal = window.scrollY;

    setHeaderFixed(scrollVal > 80);
  };

  return (
    <header className={`preview__header${headerFixed ? ' fixed' : ''}${headerWithBg ? ' preview__header_bg' : ''}`}>
      <div className="wrapper">
        <div className="preview__header-body">
          <Link to="/welcome" className="preview__header-logo header__logo">
            <svg className="icon icon_logo">
              <use xlinkHref="#logo"></use>
            </svg>
          </Link>
          <div className="preview__header-phone">
            <a className="link-hover" href="tel:+78124261863">
              8 (812) 426-18-63
            </a>
          </div>
          <div className="preview__header-btns">
            <Link to="/register" className="preview__header-sign-up">
              Зарегистрироваться
            </Link>
            <Link
              className="preview__header-sign-in"
              to={{
                pathname: "/login",
                state: { fromLogin: window.location.pathname === "/login" }
              }}
            >
              Войти
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

const PreviewFooter = ({ socials }) => {
  return (
    <footer>
      <div className="wrapper">
        <div className="preview__footer">
          <div className="preview__footer-logo">
            <svg className="icon icon_logo">
              <use xlinkHref="#logo"></use>
            </svg>
          </div>
          <div className="preview__footer-content">
            <div className="preview__footer-col">
              <div className="preview__footer-top">
                ПАН Партнер
                <br />
                Первое Агентство Недвижимости, 2010—{new Date().getFullYear()}
              </div>
              <div className="preview__footer-bottom">
                <Link to="/policy" className="preview__footer-policy" target="_blank">
                  Политика конфиденциальности
                </Link>
              </div>
            </div>
            <div className="preview__footer-col">
              <div className="preview__footer-top preview__footer-address">
                Санкт-Петербург, Владимирский пр., д. 23 лит. А, офис 701
              </div>

              <div className="preview__footer-bottom">
                <a
                  className="preview__footer-phone link-hover"
                  href="tel:+78124261863"
                >
                  +7 (812) 426-18-63
                </a>
              </div>
            </div>
            <div className="preview__footer-col">
              <div className="preview__footer-top preview__footer-address">
                Москва, Цветной бульвар,<br />д. 30, стр. 1
              </div>

              <div className="preview__footer-bottom">
                <a
                  className="preview__footer-phone link-hover"
                  href="tel:+74993221499"
                >
                  +7 (499) 322-14-99
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const PreviewLayout = ({ socials, headerWithBg, children, loginByToken, setFullScreenLoading, history, isNewPayment }) => {

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let token = params.get("token")
    if (token) {
      setFullScreenLoading(true)
      localStorage.clear();
      loginByToken({ token }).then(data => {
        history.push("/")
        Promise.resolve()
      }, (error) => setFullScreenLoading(false)
      ).then(() => setFullScreenLoading(false))
    }
  }, [])
  return (
    <>
      <div className="content">
        <main>
          <div className={isNewPayment ? 'preview preview_newpayment' : 'preview'}>
            <PreviewHeader headerWithBg={headerWithBg} />
            {children}
            <PreviewFooter socials={socials} />
          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  socials: socials,
});

const mapDispatchToProps = (dispatch) => ({
  loginByToken: (data) => dispatch(loginByToken(data))
});

PreviewLayout.propTypes = {
  socials: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewLayout));
