
const initialState = {
  isFetching: false,
  error: false,
  files: [],
  breadcrumbs: [{ id: 0, name: "Файлы" }],
  folders: [],
  folder: [],
  filter: {
    searchTerm: "",
  },
};

const files = (state = initialState, action) => {
  switch (action.type) {
    case "FILES_CHANGE_FOLDER":
      return {
        ...state,
        current: action.folder,
      };
    case "FILES_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "FILES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        files: action.files,
        folders: action.sections,
        folder: action.section,
        breadcrumbs: [
          ...initialState.breadcrumbs,
          ...action.section.breadcrumbs || [],
        ],
      };
    case "FILES_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "FILES_RESET":
      return { ...initialState };
    case "FILES_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "FILES_SET_BREADCRUMBS":
      return {
        ...state,
        breadcrumbs: [...initialState.breadcrumbs, ...action.breadcrumbs],
      };
    case "FILES_CLEAR_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

export default files;
