import React, { useState, useEffect } from "react";
import './index.scss'
import {Link} from "react-router-dom";
import NewAlert from "../core/NewAlert";
import SubmitButton from "../SubmitButton";

const DeleteUser = () => {


  return (
    <>
      <div className='wrapper delete-user'>
        <h1 className="delete-user__title">Как удалить аккаунт на panpartner?</h1>
        <div className="delete-user__content">
          <p className='delete-user__hint'>Это можно сделать только в мобильном браузере или на компьютере в
            браузере</p>
          <ul className="delete-user__rules">
            <li><span>1</span>
              <div className="delete-user__initem">Войдите на&nbsp;<Link to="/login">странице авторизации</Link>&nbsp;в почтовый
                ящик, который хотите удалить.
              </div>
            </li>
            <li><span>2</span>
              <div className="delete-user__initem">Перейдите на&nbsp;<Link to={'/user/delete/form'}>страницу удаления</Link>.</div>
            </li>
            <li><span>3</span>
              <div className="delete-user__initem">Укажите причину удаления, пароль и код с картинки.</div>
            </li>
            <li><span>4</span>
              <div className="delete-user__initem">Нажмите «Удалить».</div>
            </li>
          </ul>
          <NewAlert
            text="Удалив аккаунт на panpartner.ru, вы больше не сможете пользоваться нашими сервисами. Все фотографии и личные данные будут удалены."
            secondText="Вы можете восстановить свой аккаунт после удаления в течение 5 дней. По истечении этого срока вся информация о клиентах и проведенных сделках будет удалена."
            bgColor="#E3F0F7"
            iconColor="#ED2324"
            linkColor="#2CBA2E"
          />
        </div>
      </div>
    </>
  )
}

export default DeleteUser
