import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useModal } from "react-modal-hook";
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getUrl } from '../../api';
import CallbackModal from "../../components/Constructor/CallbackModal/CallbackModal.js";
import useMedia from '../../hooks/useMedia';
import useDropdown from '../../hooks/useDropdown';
import { ReactComponent as ToprealtorsLogo } from "../../images/svg/toprealtors-logo.svg";
import { getUrlWithProtocol } from '../../utils/location';
import { icons } from '../../utils/special';
import classNames from 'classnames';


const ConstructorHeader = props => {
  const { agent, agency, services, sets, socials, mortgage, reviews, multiLink, site, isMainPage, theme } = props;

  const [menuOpened, toggleMenuOpened] = useState(false);
  const isPreview = window.location.search.includes("preview")
  const { id, selfObjectId } = useParams()

  const [openCallbackModal, closeCallbackModal] = useModal(({ in: open, onExited }) => (
    <CallbackModal closeCallbackModal={closeCallbackModal} title="Обратный звонок" isPreview={isPreview} id={id} selfObjectId={selfObjectId} />
  ));

  const socialNames = {
    'ig': 'Instagram',
    'tg': 'Telegram',
    'vk': 'Vkontakte',
    'wa': 'WhatsApp',
    'vb': 'Viber',
    'fb': 'Facebook'
  }
  const isMobile = useMedia("isMobile")
  const checkSomeActive = (arr = []) => arr.some(item => item.active)

  const [node, open, show, hide] = useDropdown(false);

  const dropdownClass = classNames({
    dropdown: true,
    'is-showing': open,
  });

  return (
    <header className={`header constructor__header`}>
      <div className="constructor__container">
        <div className="header__wrapper">
          <Link to={`/`} className="header__logo-link">
            {agency.logo && <img
              className="constructor__logo header__logo"
              src={agency?.logo?.src ? getUrl(agency?.logo?.src) : agency?.logo}
              alt=""
            />}
          </Link>
          {/* <h1 className="header__title">{agency?.name}</h1> */}
          {
            <div className={`header__nav-toggler${menuOpened ? ` header__nav-toggler_close` : ``}${multiLink ? ` header__nav-toggler_multi-link` : ``}`}>
              <button onClick={() => toggleMenuOpened(prevState => !prevState)} type="button" aria-label="Переключение меню"></button>
            </div>
          }
          {

            <nav className={`header__nav${menuOpened ? ' header__nav_opened' : ''}`}>
              {isMobile &&
                <>
                  <div className="header__nav-title">
                    <div className="header__nav-back-btn" onClick={() => toggleMenuOpened(prevState => !prevState)}>
                      <svg
                        className="icon icon_arrow_up"
                      >
                        <use xlinkHref="#arrow_up"></use>
                      </svg>
                      Назад
                    </div>
                  </div>

                  <div className="header__nav-content">
                    <div className="header__nav-agent">
                      <img
                        src={getUrl(agent?.photo?.src)}
                        alt="agent"
                        className="header__nav-agent-avatar"
                      />

                      <div>
                        <div className="header__nav-agent-name">{agent?.lastName} {agent?.name}</div>
                        <div className="header__nav-agent-role">{agent?.post}</div>
                      </div>

                      <button className="button header__callback-btn header__callback-btn_mob" type="button" onClick={openCallbackModal}>
                        Получить консультацию
                      </button>
                    </div>

                    <ul className="constructor__socials constructor__socials_promo">
                      {socials?.map((social, i) => (<li className={`constructor__social-btn constructor__social-btn_${social?.type?.xmlId}`} key={`promo-social-${i}`}>
                        <a
                          href={
                            social.type.xmlId === 'tg'
                              ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                              : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                                : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                                  : social.link
                          }
                          target="_blank"
                        >
                          {icons[social.type.xmlId] ? icons[social.type.xmlId]?.logo : icons[social.type].logo}
                          {socialNames[social.type.xmlId]}
                        </a>
                      </li>))}
                      {
                        site && (
                          <li className="constructor__social-btn constructor__social-btn_site">
                            <a target="_blank" rel="noopener noreferrer nofollow" href={getUrlWithProtocol(site)}>
                              <svg className="icon icon_site">
                                <use xlinkHref="#globe"></use>
                              </svg>
                              Site
                            </a>

                          </li>
                        )
                      }
                    </ul>
                    {agency?.phone && <a className="header__nav-phone" target="_blank" href={`tel:${agency.phone.replace(/[\- \(\)]/gi, "")}`}>
                      {agency.phone}
                    </a>}
                  </div>
                </>
              }
              {!isMobile && <button className="button header__callback-btn header__callback-btn_mob" onClick={() => openCallbackModal()}>Обратный звонок</button>}
              {(!isMobile && isMainPage) &&
                <ul className="header__list">
                  {checkSomeActive(sets) && <li>
                    <HashLink smooth to="/#sets">Подборки объектов</HashLink>
                  </li>}
                  {checkSomeActive(services) && <li className="header__list-dropdown">
                    {mortgage.active == 0 && <HashLink smooth to="/#services">Услуги</HashLink>}

                    {
                    mortgage.active == 1 &&
                    <div className={dropdownClass} ref={node}>
                      <button
                        className="dropdown__btn button"
                        onClick={(e) => (open ? hide() : show())}
                      >
                        <span className="header__list-title">
                          Услуги
                          <svg className="icon icon_chevron">
                            <use xlinkHref="#chevron"></use>
                          </svg>
                        </span>
                      </button>
                      <div className="dropdown__block">
                        <div className="dropdown__inner">
                          <div className="header__top-menu-drop">
                            <ul className="header__top-drop-list">

                                <li>
                                  <HashLink smooth to="/ipoteka">Ипотека</HashLink>
                                </li>
                              {/* <li>
                                <HashLink smooth to="/#services">Покупателям</HashLink>
                              </li>
                              <li>
                                <HashLink smooth to="/#services">Продавцам</HashLink>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </li>}
                  {reviews?.length > 0 &&
                    <li>
                      <HashLink smooth to="/#reviews">Отзывы</HashLink>
                    </li>
                  }
                    <li>
                      <Link to="/contacts">Контакты</Link>
                    </li>

                </ul>
              }

              {!isMobile &&
                <div className="header__box">
                  <a className="header__phone header__phone_mob link" href={`tel:${agency?.phone?.replace?.(/[\- \(\)]/gi, "")}`}>
                    {agency.phone}
                  </a>

                  <ul className="constructor__socials header__socials header__socials_mob">
                    <li>
                      <a href="https://fb.com">
                        <svg className="icon icon_social-fb">
                          <use xlinkHref="#social-fb" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://vk.com">
                        <svg className="icon icon_social-vk">
                          <use xlinkHref="#social-vk" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com">
                        <svg className="icon icon_social-ig">
                          <use xlinkHref="#social-ig" />
                        </svg>
                      </a>
                    </li>
                  </ul>

                </div>
              }

            </nav>

          }

          <div className="header__contacts">
            <a className="header__phone link" href={`tel:${agency?.phone?.replace?.(/[\- \(\)]/gi, "")}`}>
              {agency?.phone}
            </a>
            <button className="button header__callback-btn" onClick={openCallbackModal}>Обратный звонок</button>
            {socials?.length > 0 && !multiLink &&
              <>
                <div className="constructor__socials header__socials">
                  {socials?.length > 0 && socials.map((social, i) => {
                    return <div key={`header-social-${i}`}>
                      <a
                        href={
                          social.type.xmlId === 'tg'
                            ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                            : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                              : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                                : social.link
                        }
                        target="_blank"
                      >
                        {icons[social.type.xmlId] ? icons[social.type.xmlId]?.logo : icons[social.type].logo}
                      </a>
                    </div>
                  })}

                  <span className="header__social-sign text">Для связи</span>
                </div>


              </>
            }
          </div>
        </div>

      </div>
    </header>
  );
};

const ConstructorFooter = ({ socials, agency, phoneAgency, site }) => {

  return (
    <footer className="constructor__footer footer">
      <div className="constructor__container">
        <div className="footer__wrapper">
          <div className="footer__left">
            {agency.logo && <img src={agency?.logo?.src ? getUrl(agency?.logo?.src) : agency?.logo} alt="" className="constructor__logo footer__logo" />}
            <div className="footer__box">
              <p className="footer__title">{agency?.name}</p>

            </div>

          </div>

          <div className="footer__right">

            {agency?.address ? <div className="footer__contacts footer__adress">
              <p className="footer__contact-label">Наш офис</p>
              <p className="footer__address">{agency.address}</p>
            </div> :
              <div className="footer__contacts footer__adress" />}

            <div className="footer__contacts">
              <p className="footer__contact-label">Контакты</p>
              <div className="footer__contacts-flex">
                <div>
                  {agency?.phone && <p>
                    <a className="footer__phone link" href={`tel:${agency.phone.replace(/[\- \(\)]/gi, "")}`}>
                      {agency.phone}
                    </a>
                  </p>}
                  {phoneAgency && phoneAgency !== agency?.phone && <p>
                    <a className="footer__phone link" href={`tel:${phoneAgency.replace(/[\- \(\)]/gi, "")}`}>
                      {phoneAgency}
                    </a>
                  </p>}
                </div>
                <div>
                  <p>
                    <a className="footer__phone link" href={`mailto:${agency?.email}`}>
                      {agency?.email}
                    </a>
                  </p>
                  {site && <p>
                    <a className="footer__phone link" target="_blank" rel="noopener noreferrer nofollow" href={getUrlWithProtocol(site)}>
                      {site.replace("https://", "")}
                    </a>
                  </p>}
                </div>
              </div>

            </div>

            <ul className="constructor__socials footer__socials">
              {socials?.length > 0 && socials.map((social, i) => {
                return <li key={`footer-social-${i}`}>
                  <a
                    href={
                      social.type.xmlId === 'tg'
                        ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                        : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                          : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                            : social.link
                    }
                    // href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {icons[social.type.xmlId] ? icons[social.type.xmlId]?.logo : icons[social.type].logo}
                  </a>
                </li>
              })}
            </ul>
          </div>

          <div className="footer__bottom">
            <p className="footer__note">
              Обращаем Ваше внимание на то, что данный интернет-сайт, а также вся информация о наличии и ценах, предоставленная на нем, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации. Для получения подробной информации о наличии и стоимости, пожалуйста, обращайтесь к менеджеру с помощью специальной формы связи.
            </p>

            <div className="footer__toprealtors">
              <a href="http://toprealtors.ru/" target="_blank">
                <ToprealtorsLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const ConstructorLayout = props => {
  const {
    children,
    theme,
    setFullScreenLoading,
    multiLink,
    isMainPage,
    isEmpty,
  } = props;


  const [color, setColor] = useState(theme.color);
  const [darkMode, setDarkMode] = useState(theme.darkMode);

  let currentColor;
  useEffect(() => {

    if (theme.color) {
      setColor(theme.color);
      setDarkMode(theme.darkMode);
      setFullScreenLoading(false);
    }

  }, [theme]);


  switch (color) {
    case 'green':
      currentColor = `constructor constructor_color-green`;
      break;

    case 'blue':
      currentColor = `constructor constructor_color-blue`;
      break;

    case 'yellow':
      currentColor = `constructor constructor_color-yellow`;
      break;

    case 'red':
      currentColor = `constructor constructor_color-red`;
      break;

    case 'gray':
      currentColor = `constructor constructor_color-gray`;
      break;

    case 'asphalt':
      currentColor = `constructor constructor_color-asphalt`;
      break;

    case 'purple':
      currentColor = `constructor constructor_color-purple`;
      break;

    case 'burgundy':
      currentColor = `constructor constructor_color-burgundy`;
      break;

    case 'nightsky':
      currentColor = `constructor constructor_color-nightsky`;
      break;

    case 'turquoise':
      currentColor = `constructor constructor_color-turquoise`;
      break;
    default:
      currentColor = `constructor`;
      break;
  }

  if (darkMode === 'Y') {
    currentColor = `${currentColor} constructor_darkMode`;
  }

  return (
    <>
      <div className="content">
        <main>


          <div className={currentColor}>



            {/* <div className="colors">
              <div className="color color-1"></div>
              <div className="color color-2"></div>
              <div className="color color-3"></div>
            </div> */}

            {!isEmpty && <ConstructorHeader {...props} multiLink={multiLink} isMainPage={isMainPage} />}
            <section className={`constructor__main${multiLink ? ` constructor__main_100vh` : ``}${isEmpty ? ` constructor__main_full-page` : ``}`}>
              {children}
            </section>
            {!multiLink && !isEmpty && <ConstructorFooter  {...props} />}

          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.agency.theme,
  agency: state.agency.agency,
  agent: state.agency.agent,
  site: state.agency.site,
  phoneAgency: state.agency?.phoneAgency,
  multiLink: state.agency.multiLink,
  socials: compact(state.agency.socials.map(soc => soc.link ? soc : null)),
  reviews: state.agency?.reviews,
  sets: state.agency?.sets,
  services: state.agency?.services,
  mortgage: state.agency?.blocks.find(it => it.component == 'mortgage'),
})

const mapDispatchToProps = (dispatch) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(ConstructorLayout);
