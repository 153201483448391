import React from 'react';
import { media } from '../../../utils';

const Exclude = (props) => {
  const { showExcludeButton, currentExcludeMatch, onClick } = props;
  return (
    <div
      className={`index-search__tooltip ${
        showExcludeButton ? 'is-visible' : ''
      }`}
      style={{
        left: media('isMobile') ? 10 : (props.left + 130),
        overflow: 'hidden auto',
        position: 'absolute',
        top: '-25px',
        width: 'auto',
      }}
    >
      {currentExcludeMatch && (
        <span style={{ paddingRight: '12px' }}>
          {currentExcludeMatch.title}
        </span>
      )}
      <button
        type="button"
        className="button button_view_outline-small"
        onClick={() => onClick()}
      >
        Исключить
      </button>
    </div>
  );
};
export default Exclude;
