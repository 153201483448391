import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  clearFilterAssignments,
  fetchAssignmentsFilter,
  setFilterAssignments
} from "../actions/assignments";
import {
  clearFilterCommercial, fetchCommercialFilter,
  setFilterCommercial
} from "../actions/commercial";
import { clearFilterEstate, fetchEstateFilter, setFilterEstate } from '../actions/estate';
import {
  changeActive,
  clearFilterSavedFilters,
  clearSavedFilters,
  fetchSavedFilters,
  filteredSavedFilters,
  removeFilter,
  setFilterSavedFilters
} from '../actions/savedFilters';
import {
  clearFilterSecondaries,
  fetchSecondariesFilter,
  setFilterSecondaries
} from "../actions/secondaries";
import {
  clearFilterSuburbans,

  getSuburbansFilter,
  setFilterSuburbans
} from "../actions/suburbans";
import EmptyTable from '../components/core/EmptyTable';
import Preloader from '../components/Preloader';
import Filter from '../components/SavedFilters/Filter';
import Items from '../components/SavedFilters/Items';



const SavedFilters = ({
  fetch,
  reset,
  data,
  items,
  filter,
  filterData,
  isFetching,
  setFilter,
  clearFilter,
  fetchFilterEstate,
  setFilterEstate,
  clearFilterEstate,
  filterDataEstate,
  filterEstate,
  remove,
  handleActive,
  error,
  clients,
  filterDataAssignments,
  filterAssignments,
  setFilterAssignments,
  clearFilterAssignments,
  fetchAssignmentsFilter,
  clearEstateFilterOnly,
  clearEstateExcludeOnly,

  fetchCommercialFilter,
  setFilterCommercial,
  clearFilterCommercial,
  filterDataCommercial,
  filterCommercial,
  clearCommercialExcludeOnly,
  clearCommercialFilterOnly,

  fetchSecondaryFilter,
  setFilterSecondary,
  clearFilterSecondary,
  clearSecondaryFilterOnly,
  clearSecondaryExcludeOnly,
  filterDataSecondaries,
  filterSecondaries,


  getSuburbansFilter,
  setFilterSuburbans,
  clearFilterSuburbans,
  clearSuburbansFilterOnly,
  clearSuburbansExcludeOnly,
  filterDataSuburbans,
  filterSuburbans,

  user
}) => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    fetch().then(() => {
      setFirstRender(false);
    });
    fetchSecondaryFilter()
    fetchFilterEstate();
    fetchAssignmentsFilter();
    fetchCommercialFilter()
    getSuburbansFilter()
    return () => {
      reset();
    };
  }, []);
  return (
    <>
      <section className="section section_view_white no-header-profile">
        <div className="wrapper">
          <div className="lk-filters__header clients__header">
            <div className="lk-filters__title clients__title">
              <div className="h1">Сохраненные фильтры</div>
            </div>
          </div>
          {!firstRender &&
            (items.length > 0 ? (
              <>
                <Filter
                  filter={filter}
                  filterData={filterData}
                  setFilter={setFilter}
                  clearFilter={clearFilter}
                />
                <Items
                  user={user}
                  items={data}
                  handleDelete={remove}
                  handleActive={handleActive}
                  setFilterEstate={setFilterEstate}
                  clearFilterEstate={clearFilterEstate}
                  filterDataEstate={filterDataEstate}
                  filterEstate={filterEstate}
                  clients={clients}
                  fetch={fetch}
                  setFilterAssignments={setFilterAssignments}
                  clearFilterAssignments={clearFilterAssignments}
                  filterDataAssignments={filterDataAssignments}
                  filterAssignments={filterAssignments}
                  clearEstateFilterOnly={clearEstateFilterOnly}
                  clearEstateExcludeOnly={clearEstateExcludeOnly}

                  setFilterCommercial={setFilterCommercial}
                  clearFilterCommercial={clearFilterCommercial}
                  filterDataCommercial={filterDataCommercial}
                  filterCommercial={filterCommercial}
                  clearCommercialExcludeOnly={clearCommercialExcludeOnly}
                  clearCommercialFilterOnly={clearCommercialFilterOnly}


                  setFilterSecondary={setFilterSecondary}
                  clearFilterSecondary={clearFilterSecondary}
                  clearSecondaryFilterOnly={clearSecondaryFilterOnly}
                  clearSecondaryExcludeOnly={clearSecondaryExcludeOnly}
                  filterDataSecondaries={filterDataSecondaries}
                  filterSecondaries={filterSecondaries}


                  setFilterSuburbans={setFilterSuburbans}
                  clearFilterSuburbans={clearFilterSuburbans}
                  clearSuburbansFilterOnly={clearSuburbansFilterOnly}
                  clearSuburbansExcludeOnly={clearSuburbansExcludeOnly}
                  filterDataSuburbans={filterDataSuburbans}
                  filterSuburbans={filterSuburbans}
                />
              </>
            ) : (
                <EmptyTable title="У вас нет сохраненных фильтров" />
              ))}
          {firstRender && <Preloader loading={isFetching} />}
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city) => dispatch(fetchSavedFilters(city)),
  reset: () => dispatch(clearSavedFilters()),
  remove: (id, city) => dispatch(removeFilter(id, city)),
  setFilter: (filter) => dispatch(setFilterSavedFilters(filter)),
  clearFilter: () => dispatch(clearFilterSavedFilters()),
  setFilterEstate: (filter) => dispatch(setFilterEstate(filter)),
  clearFilterEstate: () => dispatch(clearFilterEstate()),
  handleActive: (id, active, city) => dispatch(changeActive(id, active, city)),
  fetchFilterEstate: (city) => dispatch(fetchEstateFilter(city)),
  fetchAssignmentsFilter: () => dispatch(fetchAssignmentsFilter()),
  setFilterAssignments: (filter) => dispatch(setFilterAssignments(filter)),
  clearFilterAssignments: () => dispatch(clearFilterAssignments()),
  clearEstateFilterOnly: () => dispatch({ type: "ESTATE_CLEAR_FILTER_ONLY" }),
  clearEstateExcludeOnly: () => dispatch({ type: "ESTATE_CLEAR_EXCLUDE_ONLY" }),

  fetchCommercialFilter: () => dispatch(fetchCommercialFilter()),
  setFilterCommercial: (filter) => dispatch(setFilterCommercial(filter)),
  clearFilterCommercial: () => dispatch(clearFilterCommercial()),
  clearCommercialFilterOnly: () => dispatch({ type: "COMMERCIAL_CLEAR_FILTER_ONLY" }),
  clearCommercialExcludeOnly: () => dispatch({ type: "COMMERCIAL_CLEAR_EXCLUDE_ONLY" }),

  fetchSecondaryFilter: () => dispatch(fetchSecondariesFilter()),
  setFilterSecondary: (filter) => dispatch(setFilterSecondaries({ filter })),
  clearFilterSecondary: () => dispatch(clearFilterSecondaries()),
  clearSecondaryFilterOnly: () => dispatch({ type: "SECONDARIES_CLEAR_FILTER_ONLY" }),
  clearSecondaryExcludeOnly: () => dispatch({ type: "SECONDARIES_CLEAR_EXCLUDE_ONLY" }),

  getSuburbansFilter: (form) => getSuburbansFilter(form, dispatch),
  setFilterSuburbans: (filter) => dispatch(setFilterSuburbans(filter)),
  clearFilterSuburbans: () => dispatch(clearFilterSuburbans()),
  clearSuburbansFilterOnly: () => dispatch({ type: "SUBURBANS_CLEAR_FILTER_ONLY" }),
  clearSuburbansExcludeOnly: () => dispatch({ type: "SUBURBANS_CLEAR_EXCLUDE_ONLY" }),

});

const mapStateToProps = (state) => ({
  user: state.user.info,
  data: filteredSavedFilters(state),
  items: state.savedFilters.data,
  isFetching: state.savedFilters.isFetching,
  error: state.savedFilters.error,
  filter: state.savedFilters.filter,
  filterData: state.savedFilters.filterData,
  clients: state.savedFilters.clients,

  filterDataEstate: state.estate.filterData,
  filterEstate: state.estate.filter,

  filterDataAssignments: state.assignments.filterData,
  filterAssignments: state.assignments.filter,

  filterDataCommercial: state.commercial.filterData,
  filterCommercial: state.commercial.filter,

  filterDataSecondaries: state.secondaries.filterData,
  filterSecondaries: state.secondaries.filter,

  filterDataSuburbans: state.suburbans.filterData,
  filterSuburbans: state.suburbans.filter,

});

SavedFilters.propTypes = {
  fetch: PropTypes.func,
  reset: PropTypes.func,
  data: PropTypes.array,
  items: PropTypes.array,
  filter: PropTypes.object,
  filterData: PropTypes.object,
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  clients: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedFilters);
