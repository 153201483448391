import { useMediaQuery } from "beautiful-react-hooks";
import formatter from "format-number";
import _floor from "lodash/floor";
import intersectionBy from "lodash/intersectionBy";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlan.svg';
import useMedia from '../../hooks/useMedia';
import { ReactComponent as Filters } from "../../images/svg/filters.svg";
import {formatMoney, getRoundedPrice, media, objectsArrayToFormData, toFormData} from "../../utils";
import { whitelistForCardClick } from "../Complex/Utils";
import Table, { ShowMore } from "../core/Table2";
import Maps from "../Estate/Maps";
import MapList from "./MapList";
import useApi from "../../hooks/useApi";
import {successToast} from "../toasts";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";
import {AddToCompilationIcon} from "../Public/markup/icons";

const Card = props => {
  const {
    id,
    squareTotal,
    floor,
    isPublic,
    savedFilterXmlId,
    squareKitchen,
    decoration,
    section,
    plan,
    corpus,
    costWithDiscount,
    number,
    objectType,
    squareLiving,
    city="spb",
    flatType,
    status,
    roomType
  } = props;

  return (
    <div className="complex__appartments-card complex__appartments-card_tile">
      <div
        className="card-tile"
        onClick={(e) => {
          let link = isPublic
            ? `/public/${savedFilterXmlId}/${city}/apartment/${id}`
            : `/realty/${city}/apartment/${id}${window.location.search}`
          if (objectType === "assignment") {
            link = `/assignments/${id}${window.location.search}`
          }
          window.open(link);
        }}

      >
        <div className="card-tile__image">
          <img src={
            getUrl(plan.src)}
            alt={""}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }}
          />
        </div>

        {/* <span className={`card-tile__label${objectType === `assignment` ? ` card-tile__label_assign` : ``}`}>{roomType}</span> */}
        <div className="card-tile__status table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>

        <div className="card-tile__body">
          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__value-small">{floor} этаж</span>
              <span className="card-tile__value-lg">{priceFormat(costWithDiscount)}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__value-small">№ квартиры: {number}</span>
              <span className="card-tile__value-lg">{squareTotal} м²</span>
            </div>
          </div>

          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__key">S жилая</span>
              <span className="card-tile__value">{squareLiving}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">S кухни</span>
              <span className="card-tile__value">{squareKitchen} м²</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">Отделка</span>
              <span className="card-tile__value">{decoration}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


const CardsApartments = (props) => {
  const { items=[], expand, isPublic, savedFilterXmlId, innerRef, objectType } = props;
  const [loadedItems, setloadedItems] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setloadedItems(items.slice(0, 10))
  }, [items])
  const loadMore = () => {
    setLoading(true)
    setloadedItems(prev=>{
      const prevLen = prev.length
      return [...prev, ...items.slice(prevLen, prevLen + 10)]
    }
      )
    setLoading(false)
  }


  return (
    <div
      className="flats__row-results"
      style={{ display: expand ? "block" : "none" }}
      ref={innerRef}
    >
      <div className="complex__appartments-cards" >
        {loadedItems.map((item) => (
          <Card
            {...item}
            key={item.id}
            isPublic={isPublic}
            savedFilterXmlId={savedFilterXmlId}
            objectType={objectType}
          />
        ))}
      </div>
      {loadedItems.length < items.length && <ShowMore customMoreTitle={"Показать ещё"} onClick={loadMore} loading={loading} />}
    </div>
  );

};


const priceFormat = (price) => {
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};
const TableRow = (props) => {

  const { active, items, isAgent, clientMode } = props;

  const media1280 = useMediaQuery("(max-width: 1280px)");
  const history = useHistory();
  const [lastSort, setLastSort] = useState(null);

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const TOOLTIP_CUT_VALUE = !isDesktop ? 11 : 5;

  const handleSurface = (e) => {
    if (media1280) e.stopPropagation();
  };

  const tableRef = useRef(null)
  const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0})
  const [isShowToopltip, setIsShowToopltip] = useState(false)

  const [isId, setIsId] = useState(null)
  const [isType, setIsType] = useState(null)

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: isId, type: isType, city: 'spb' }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  const getColumns = () => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
      sorter: (a, b) => a.id - b.id,
      sortOrder: lastSort && lastSort.columnKey === "id" && lastSort.order,
    },
    {
      title: "План.",
      dataIndex: "plan",
      key: "plan",
      width: isDesktop ? 50 : 80,
      render: (plan) => {
        return (
          <div className="table-img">
            <img src={plan?.src} alt="" onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }} />
            <div className="table-img__preview">
              <img src={plan?.src} alt="" onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }} />
            </div>
          </div>
        )
      },
    },
    {
      title: "Район",
      dataIndex: "region",
      key: "region",
      width: !isDesktop ? 130 : 80,
      render: (region) => {
        return region?.name?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${region?.name.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{region?.name}</div>
          </div>
        ) : (
          <span>{region?.name}</span>
        )
      },

      sorter: (a, b) => {
        if (a.region.name < b.region.name) {
          return -1;
        }
        if (a.region.name > b.region.name) {
          return 1;
        }
        return 0;
      },
      sortOrder: lastSort && lastSort.columnKey === "region" && lastSort.order,
    },
    {
      title: "Метро",
      dataIndex: "subway",
      key: "subway",
      render: (subway) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">
            {subway ? subway.map((v) => v.name).join(", ") : ""}
          </div>
        </div>
      ),

      sorter: (a, b) => a.subway.length - b.subway.length,
      sortOrder: lastSort && lastSort.columnKey === "subway" && lastSort.order,
    },
    {
      title: !isDesktop ? "Застройщик" : "Застр.",
      dataIndex: "builder",
      key: "builder",
      render: (builder) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{builder?.name}</div>
        </div>
      ),

      sorter: (a, b) => {
        if (a.builder?.name < b.builder?.name) {
          return -1;
        }
        if (a.builder?.name > b.builder?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === "builder" && lastSort.order,
    },
    {
      title: "ЖК",
      dataIndex: "block",
      key: "block",
      width: !isDesktop ? 130 : 60,

      render: (block) => {
        return block?.name?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${block?.name.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{block?.name}</div>
          </div>
        ) : (
          <span>{block?.name}</span>
        )
      },

      sorter: (a, b) => {
        if (a.block?.name < b.block?.name) {
          return -1;
        }
        if (a.block?.name > b.block?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === "block" && lastSort.order,
    },
    {
      title: !isDesktop ? "Корп." : "Кор.",
      dataIndex: "corpus",
      key: "corpus",
      width: !isDesktop ? 120 : 70,
      sorter: (a, b) => a.corpus - b.corpus,
      sortOrder: lastSort && lastSort.columnKey === "corpus" && lastSort.order,
      render: (corpus) => {
        return corpus?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${corpus.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{corpus}</div>
          </div>
        ) : (
          <span>{corpus}</span>
        )
      },
    },
    {
      title: "Эт.",
      dataIndex: "floor",
      key: "floor",
      width: 60,
      sorter: (a, b) => a.floor - b.floor,
      sortOrder: lastSort && lastSort.columnKey === "floor" && lastSort.order,
    },
    {
      title: !isDesktop ? "S общ." : "S об.",
      dataIndex: "squareTotal",
      key: "squareTotal",
      width: 80,
      sorter: (a, b) => a.squareTotal - b.squareTotal,
      sortOrder:
        lastSort && lastSort.columnKey === "squareTotal" && lastSort.order,
    },
    {
      title: "S кух.",
      dataIndex: "squareKitchen",
      key: "squareKitchen",
      width: 80,
      sorter: (a, b) => a.squareKitchen - b.squareKitchen,
      sortOrder:
        lastSort && lastSort.columnKey === "squareKitchen" && lastSort.order,
    },
    {
      title: !isDesktop ? "Отделка" : "Отд",
      dataIndex: "decoration",
      key: "decoration",
      width: !isDesktop ? 110 : 80,
      render: (decoration) => {
        return decoration?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${decoration.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{decoration}</div>
          </div>
        ) : (
          <span>{decoration}</span>
        )
      },
      sorter: (a, b) => {
        if (a.decoration < b.decoration) {
          return -1;
        }
        if (a.decoration > b.decoration) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === "decoration" && lastSort.order,
    },
    {
      title: "Цена",
      dataIndex: "costWithDiscount",
      key: "costWithDiscount",
      fixed: "right",
      render: (price) => <span className="nowrap">{priceFormat(price)}</span>,
      width: 100,
      sorter: (a, b) => a.costWithDiscount - b.costWithDiscount,
      sortOrder:
        lastSort && lastSort.columnKey === "costWithDiscount" && lastSort.order,
    },
    ...(!isAgent && !clientMode
      ? [
        {
          title: !isDesktop ? "Комисс." : "Ком.",
          dataIndex: "commissionPercent",
          key: "commissionPercent",
          fixed: "right",
          render: (commission) => (
            <span className="nowrap">{priceFormat(commission)}</span>
          ),
          width: 100,
          sorter: (a, b) => a.commission - b.commission,
          sortOrder:
            lastSort && lastSort.columnKey === "commissionPercent" && lastSort.order,
        },
      ]
      : []),
    {
      title: "Сдача",
      width: 100,
      dataIndex: "endingDate",
      key: "endingDate",
      fixed: "right",
      render: (endingDate) => <span className="nowrap">{endingDate}</span>,
      sorter: (a, b) => {
        if (a.endingDate < b.endingDate) {
          return -1;
        }
        if (a.endingDate > b.endingDate) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === "endingDate" && lastSort.order,
    },
    {
      title: !isDesktop ? "Статус" : "Ст.",
      dataIndex: "status",
      key: "status1",
      sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
      width: !isDesktop ? 100 : 80,
      render: (status, record, {id, compilationObjectType }) => {

        return (
          <div className="table2-status">
            <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
              <div className="status-circle__tooltip">{status?.name}</div>
            </div>
            <div className="table-compilation-icon" onClick={(e) => {
              e?.stopPropagation?.();
              setIsId(record.id)
              setIsType(record.compilationObjectType)
              openCompilationModal()
            }
            }>
              <AddToCompilationIcon/>
              <div className="table-compilation-icon__hint">Добавить в подборку</div>
            </div>
          </div>
        )
      },
    },
  ];

  const getMobileColumns = () => [
    {
      title: "Пл",
      dataIndex: "plan",
      key: "plan",
      render: (plan) => {
        return (
          <div className="table-img">
            <img src={plan?.src} alt="" onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }} />
          </div>
        )
      },
    },
    {
      title: "Эт.",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "S общ.",
      dataIndex: "squareTotal",
      key: "squareTotal",
    },
    {
      title: "Цена",
      dataIndex: "costWithDiscount",
      key: "costWithDiscount",
      render: (price) => <span className="nowrap">{getRoundedPrice(price)} млн.</span>,
    },
    ...(!isAgent && !clientMode
      ? [
        {
          title: "Комиссия",
          dataIndex: "commissionPercent",
          key: "commissionPercent",
          fixed: "right",
          render: (commission) => (
            <span className="nowrap">{priceFormat(commission)}</span>
          ),
        },
      ]
      : []),
    {
      title: "Ст",
      dataIndex: "status",
      key: "status1",
      render: (status) => {
        return (
          <div className="table2-status">
            <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
              <div className="status-circle__tooltip">{status?.name}</div>
            </div>
          </div>
        )
      },
    },

  ];




  function onChange(pagination, filters, sorter, extra) {
    //console.log("params", pagination, filters, sorter, extra);
    setLastSort({
      order: sorter.order,
      columnKey: sorter.field,
    });
  }
  const onLoadEndCallBack = () => { };
  return (
    <div className={`flats__row-table ${active ? `is-active` : ``}`} ref={tableRef}>
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={isLaptop ? getMobileColumns() : getColumns()}
        dataSource={items}
        pagination={false}
        locale={{ emptyText: "Загрузка данных..." }}
        customPagination={true}
        customMoreTitle={media('isMobile') && "Показать еще 10"}
        onChange={onChange}
        onLoadEndCallBack={onLoadEndCallBack}
        // scroll={{ x: 1140 }}
        pageCount={50}
        firstPageCount={10}
        rowClassName={(record, index) => {
          if(record.isHot) return "apartments-action-row"
          return (index + 1) % 2 === 0 ? "ant-table-second-color" : ""
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              window.open(`/assignments/${record.id}`);

            },
            onMouseMove: e => {
              if(!record.isHot || isMobile || e.target.tagName === "IMG" || e.target.classList.toString().includes("status")) return setIsShowToopltip(false)
              const targetBounds = tableRef.current.getBoundingClientRect()
              setTooltipPosition({top: e.clientY - targetBounds.y, left: e.clientX - targetBounds.x + 25 })
              setIsShowToopltip(true)
            },
            onMouseLeave: ()=>{
              setIsShowToopltip(false)
            }
          };
        }}

      />
        <div className="action-row-tooltip" style={{visibility: isShowToopltip ? "visible" : "hidden", width: "fit-content",  ...tooltipPosition}}>
        Горячее предложение
      </div>
    </div>
  );
};

const RowItem = props => {
  const [active, setActive] = useState(false);
  const {
    clientMode,
    countStr,
    isAgent,
    count,
    isMobile,
    items,
    maxCostWithDiscount,
    maxSquareTotal,
    minCostWithDiscount,
    minSquareTotal,
    onClick,
    minMeterPrice,
    roomTitle,
    strCostWithDiscount,
    strSquareTotal,
    roomTypeId,
    loadRow,
    viewType,
    isPublic,
    savedFilterXmlId
  } = props

  return (
    <div className="assignations-flats__row">
      <div
        className={`flats__row ${active ? `is-active` : ``}`}
        onClick={() => {
          setActive(!active)
          items.length === 0 && loadRow(roomTypeId)
        }}
      >
        <div className="flats__row-col flats__row-rooms">{roomTitle}</div>
        <div className="flats__row-concat">
          {!isMobile && <div className="flats__row-col flats__row-count">{countStr}</div>}
          <div className="flats__row-col flats__row-square">
            {isMobile
              ? `от ${_floor(minSquareTotal)} до ${_floor(maxSquareTotal)} м²`
              : strSquareTotal
            }
          </div>
          <div className="flats__row-col flats__row-price">
            {isMobile
              ? `от ${getRoundedPrice(minCostWithDiscount)} до ${getRoundedPrice(maxCostWithDiscount)} млн.`
              : strCostWithDiscount
            }
          </div>
          {!isMobile && !!minMeterPrice && <div className="flats__row-col flats__row-price">
               от {formatMoney(minMeterPrice, " р./м²")}
          </div>}
        </div>


        {isMobile && <div className="flats__row-col flats__row-count">{count}</div>}
        <div className="flats__row-col flats__row-action">
          <button className="button button_view_round button_with_icon">
            <svg className="icon icon_collapse">
              <use xlinkHref="#arrow_bottom"></use>
            </svg>
          </button>
        </div>
      </div>




      {
        viewType === "cards" && active && (
          <CardsApartments
            items={items}
            expand={active}
            isPublic={isPublic}
            savedFilterXmlId={savedFilterXmlId}
            objectType="assignment"
          />
          )
      }

      {
        viewType === "list" && active &&
        <TableRow
          items={props.items}
          active={active}
          isAgent={props.isAgent}
          clientMode={props.clientMode}
          onClick={props.onClick}
        />
      }


    </div>
  );
};

let paintIsStarted = false;

const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, loadRow, setViewType, viewType } = props;

  const [isInitMap, setInitMap] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);
  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");

  const filterByCluster = (items) => {
    if (selectedItems) {
      return intersectionBy(items, selectedItems, "id");
    }
    if (currentPoint && isClusterOrPoint === "point") {
      paintIsStarted = false;
      return intersectionBy(items, [currentPoint], "id");
    }
    if (currentCluster && isClusterOrPoint === "cluster") {
      paintIsStarted = false;
      return intersectionBy(items, currentCluster.features, "id");
    }

    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  useEffect(() => {
    if (isMobile && viewType == "map") {
      window.scrollTo({ top: 0 })
      document.body.classList.add('no-scroll')
    }
    if (isMobile && viewType == "list") {
      document.body.classList.remove('no-scroll')
    }
  }, [viewType]);

  return (
    <section
      className={`section`}
      ref={innerRef}
    >
      <div className="assignations-flats for-commercial assignations-flats_table2 redesign">
        <div id="filter-results"></div>

        <div className="wrapper">
          <div className="assignations-flats__header ">
            <div className="assignations-flats__title">
              <h3 className="h3">Найдено {items.count} уступки:</h3>
            </div>

            {!isLaptop && (
              <div className="assignations-flats__btns">

                <div className={`complex__view-item${viewType == `list` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("list")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_rows2"></use>
                    </svg>
                    <span>Список</span>
                  </button>
                </div>

                <div className={`complex__view-item${viewType == `cards` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("cards")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_cards2"></use>
                    </svg>
                    <span>Планировки</span>
                  </button>
                </div>


              </div>


            )}


          </div>
        </div>


        {(viewType === "list" || viewType === "cards") && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables table2">
                {items.types &&
                  items.types.map((row, i) => (
                    <RowItem
                      {...row}
                      key={row?.roomTitle + "-" + i}
                      isAgent={isAgent}
                      clientMode={clientMode}
                      onClick={props.onClick}
                      isMobile={isMobile}
                      loadRow={loadRow}
                      viewType={viewType}
                    />
                  ))}

              {isMobile &&
                <div className="controls-wrapper" style={{ bottom: 0, position: 'sticky' }}>

                  <div className={`complex__view-item view__map active`}>
                    <button
                      className={`view__btn button`}
                      onClick={() => setViewType("map")}
                    >
                      <svg className="view__icon view__icon_map">
                        <use xlinkHref="#marker"></use>
                      </svg>
                      <span>На карте</span>
                    </button>
                  </div>
                  <button className="presentation-filters-btn" onClick={props.onClick}>
                    <Filters />
                    фильтры
                    {props.count > 0 && `: ${props.count}`}
                  </button>
                </div>
                }
              </div>
            </div>
          </div>
        )}
        {(isInitMap || viewType === "map") && mapItems.length > 0 && (
          <div
            className="section__map"
            style={{ display: viewType === "map" ? "block" : "none" }}
          >
            <div className="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                fromPage={"assignments"}
                favType={"assignment"}
              />
              <div className="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
