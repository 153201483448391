import React from 'react';
import useMedia from "../../../../hooks/useMedia";
import { parseDate } from '../../../../utils';
import Table from "../../../core/Table";

const getCountByType = (countViewsByType, xmlId) => {
  const count =   countViewsByType?.find?.(item => item.type.xmlId === xmlId)?.count
  return !!count ? count : "—"
}


const Stats = (props) => {
    const { sites, user } = props

    const isMobile = useMedia("isMobile")
    const getMobileColumns = () => [
        {
            title: "Название",
            dataIndex: "subdomain",
            key: "subdomain",
            render: (subdomain, record) => <a
                className="clients__table-link clients__table-client"
                href={`https://${subdomain}.toprealtors.ru`}
                target="_blank"
            >
                {`${subdomain}.toprealtors.ru`}
            </a>,
        },
        {
            title: "Автор",
            dataIndex: "owner",
            key: "owner",
            render: (owner) => user.id === owner.id ? "Вы" : `${owner.lastName} ${owner.name}`,
        },
    ]

    const getColumns = () => [
        {
            title: "Название",
            dataIndex: "subdomain",
            key: "subdomain",
            render: (subdomain, record) => <a
                className="clients__table-link clients__table-client"
                href={`https://${subdomain}.toprealtors.ru`}
                target="_blank"
            >
                {`${subdomain}.toprealtors.ru`}
            </a>,
        },
        {
            title: "Автор",
            dataIndex: "owner",
            key: "owner",
            render: (owner) => user.id === owner.id ? "Вы" : `${owner.lastName} ${owner.name}`,
        },
        {
            title: "Дата создания",
            dataIndex: "dateAdd",
            key: "dateAdd",
            render: (dateAdd) => {
                const [day, time] = parseDate(dateAdd, true)
                return `${day} в ${time}`
            },
        },
        {
            title: "Дата обновления",
            dataIndex: "dateUpdate",
            key: "dateUpdate",
            render: (dateAdd) => {
                const [day, time] = parseDate(dateAdd, true)
                return `${day} в ${time}`
            },
        },
        {
            title: "Просмотров главной",
            dataIndex: "countViewsByType",
            key: "countViewsByTypeMain",
            render: (countViewsByType) => getCountByType(countViewsByType, "siteMain"),
        },
        {
            title: "Просмотров ЖК",
            dataIndex: "countViewsByType",
            key: "countViewsByType",
            render: (countViewsByType) => getCountByType(countViewsByType, "siteBlock"),
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (status) => <span className={`label-item ${status === 1 ? "label_green" : "label_red"}`}>{status === 1 ? "Опубликован" : "Не опубликован"}</span>,

        }
    ];

    const expandedRow = (record) => {
        const [dayAdd, timeAdd] = parseDate(record.dateAdd, true)
        const [dayUpdate, timeEpdate] = parseDate(record.dateUpdate, true)
        return (
            <div className="flats__row-table-expanded">
                <div className="flats__row-table-expanded-column">
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Дата создания:</span>
                        {`${dayAdd} в ${timeAdd}`}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Дата обновления:</span>
                        {`${dayUpdate} в ${timeEpdate}`}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Просмотров главной:</span>
                        {getCountByType(record.countViewsByType, "siteMain")}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Просмотров ЖК:</span>
                        {getCountByType(record.countViewsByType, "siteBlock")}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Статус:</span>
                        <span className={`label-item ${record.status === 1 ? "label_green" : "label_red"}`}>
                            {record.status === 1 ? "Опубликован" : "Не опубликован"}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="orders__content for-agents">
            <div className="constructor__container">
                <Table
                    rowKey="id"
                    showSorterTooltip={false}
                    columns={isMobile ? getMobileColumns() : getColumns()}
                    expandable={isMobile ? {
                        expandedRowRender: record => expandedRow(record),
                    } : false}
                    dataSource={sites}
                    pagination={false}
                    locale={{ emptyText: "ничего не найдено" }}
                    customPagination={true}
                />
            </div>
        </div>
    );
}
export default Stats