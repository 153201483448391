import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Link, useHistory } from "react-router-dom";
import formatter from "format-number";
import MapList from "../Assignation/MapList";
import Maps from "../Estate/Maps";
import ViewRealty from "../Estate/ViewRealty";
import _ from "lodash";
import Card from "../Cards";
import LoadMoreSpinner from "../LoadMoreSpinner";
import SquareTitle from "../SquareTitle";
import PriceTitle from "../PriceTitle";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { media } from "../../utils";
const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = _.chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 300);
  });


const RowItem = (props) => {
  const [active, setActive] = useState(false);
  const { items } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setPage(0);
    setData(_.take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage((p + 1) * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading: loading || !active,
    hasNextPage,
    threshold: 50,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  return (
    <div class="assignations-flats__row">
      <div
        className={`flats__row ${active ? `is-active` : ``}`}
        onClick={() => setActive(!active)}
      >
        <div class="flats__row-col flats__row-rooms">{props.roomTitle}</div>
        <div class="flats__row-concat">
          {!media("isMobile") && <div class="flats__row-col flats__row-count">{props.countStr}</div>}
          <div class="flats__row-col flats__row-square">
            <SquareTitle
              minSquare={props.minSquareTotal}
              maxSquare={props.maxSquareTotal}
            />
          </div>
          <div class="flats__row-col flats__row-price">
            <PriceTitle
              maxPrice={props.maxCostWithDiscount}
              minPrice={props.minCostWithDiscount}
            />
          </div>
        </div>
        {media("isMobile")
          ? <div class="flats__row-col flats__row-count">{items.length}</div>
          : <div class="flats__row-col flats__row-info">
              Показать все{" "}
              {props.roomTitle === "Студия" ? "студии" : props.roomTitle}
            </div>
        }
        <div class="flats__row-col flats__row-action">
          <button class="button button_view_round button_with_icon">
            <svg class="icon icon_collapse">
              <use xlinkHref="#arrow_bottom"></use>
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`flats__row-table public-assignments-wrapper ${
          active ? `is-active` : ``
        }`}
        style={{ transition: "none" }}
        ref={infiniteRef}
      >
        {data.map((item, i) => {
          return (
            <Card
              key={"assignment-" + i}
              currentTab="apartment"
              {...item}
              planSmall={{...item.plan}}
              disableFavorites={true}
              alternativeLink={props.alternativeLink}
              block={{address: item.address, name: item.block?.name}}
              ending={item.endingDate}
            />
          );
        })}
      </div>
      {loading && <LoadMoreSpinner loading={true} />}
    </div>
  );
};
let paintIsStarted = false;
const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, alternativeLink } = props;
  const [viewType, setViewType] = useState("list");
  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);

  const template = useMemo(() => {
    return ymaps
      ? ymaps.templateLayoutFactory.createClass(
          '<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>',
        )
      : null;
  }, [ymaps]);

  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
  const filterByCluster = (items) => {
    if (selectedItems) {
      return _.intersectionBy(items, selectedItems, "id");
    }
    if (currentPoint && isClusterOrPoint === "point") {
      paintIsStarted = false;
      return _.intersectionBy(items, [currentPoint], "id");
    }
    if (currentCluster && isClusterOrPoint === "cluster") {
      paintIsStarted = false;
      return _.intersectionBy(items, currentCluster.features, "id");
    }
    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  return (
    <section
      className={`section ${
        viewType === "list" ? "section_view_gray" : "section_view_white section_view_top"
      }`}
      ref={innerRef}
    >
      <div class="assignations-flats">
        <div id="filter-results"></div>
        <div class="wrapper">
          <div class="assignations-flats__header">
            <div class="assignations-flats__title">
              <h2 class="h2">Найдено {items.count} объектов:</h2>
            </div>

            <ViewRealty
              view={viewType}
              onChange={setViewType}
            />

          </div>
        </div>
        {viewType === "list" && (
          <div class="section__cards">
            <div class="wrapper">
              <div class="assignations-flats__tables assignations-flats__public-assignment">
                {items.types &&
                  items.types.map((row, i) => (
                    <RowItem
                      {...row}
                      key={row.roomTitle + "-" + i}
                      isAgent={isAgent}
                      clientMode={clientMode}
                      alternativeLink={alternativeLink}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
        {(isInitMap || viewType === "map") && (
          <div
            className="section__map"
            style={{ display: viewType === "map" ? "block" : "none" }}
          >
            <div class="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                disableFavorites={true}
                alternativeLink={"assignments"}
                fromPage={"assignments"}
                favType={"assignment"}
              />
              <div class="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
