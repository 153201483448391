import { handleFavorite } from './handlers';

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  // активность сохр. фильтра по ссылкам /public/*
  isActive: true,
  agent: null,
  manager: null,
  banks: [],
  subways: [],
  savedFilterSettings: {}
};

const apartment = (state = initialState, action) => {
  switch (action.type) {
    case "APARTMENT_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "APARTMENT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        agent: action.agent,
        manager: action.manager,
        banks: action.banks,
        subways: action.subways,
        savedFilterSettings: action.savedFilterSettings
      };
    case "APARTMENT_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "APARTMENT_RESET":
      return { ...initialState };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'apartment') {
        return { ...state, data: handleFavorite(state.data, action, true) }
      }
      return state
    default:
      return state;
  }
};

export default apartment;
