import React from "react";
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlanList.svg';
import { priceFormat } from '../Complex/Utils';


const SecondaryCard = (props) => {
  const {
    id,
    roomType,
    decoration,
    squareTotal,
    costWithDiscount,
    squareKitchen,
    block,
    ending,
    floor,
    address,
    corpus,
    subway,
    planSmall,
    city,
    favorite,
    whatPage,
    showOnLeft,
    number,
    countBuildings,
    type,
    builder,
    disableFavorites,
    alternativeLink,
    isCommercial,
    status,
    isPublic,
    savedFilterXmlId,
    objectType,
    plan,
    squareLiving,
    endingDate,
    buildingYear,
    baseCost,
    objectTypeChecker, 
    style,
    onMouseMove
  } = props;
  

  return (
    <div className="complex__appartments-card complex__appartments-card_tile" style={style} onMouseMove={()=>onMouseMove?.(id)}>
      <div
        className="card-tile card-tile_img-full"
        onClick={(e) => {
          let link = isPublic
            ? `/public/secondary/${savedFilterXmlId}/${id}`
            : `/secondary/${id}${window.location.search}`
          if (objectTypeChecker === "secondary") {
            link = `/secondary/${id}${window.location.search}`
          }
          window.open(link);
        }}

      >
        <div className="card-tile__image">
          <img src={
            getUrl(plan.src)}
            alt={""}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }}
          />
        </div>

        {status && <div className="card-tile__status table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>}
        
        <div className="card-tile__body">
          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__value-small">{floor} этаж</span>
              <span className="card-tile__value-lg">{priceFormat(baseCost)}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__value-small">Тип: {objectType.name}</span>
              <span className="card-tile__value-lg">{squareTotal} м²</span>
            </div>
          </div>

          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__key">S жилая</span>
              <span className="card-tile__value">{squareLiving} м²</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">S кухня</span>
              <span className="card-tile__value">{squareKitchen} м²</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">Год постройки</span>
              <span className="card-tile__value">{buildingYear}</span>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};
export default SecondaryCard;
