import './index.scss'
import { EnvelopeIcon, MobileAppIcon } from "../Public/markup/icons";
import InputMask from "react-input-mask";
import useApi from '../../hooks/useApi';
import SubmitButton from '../SubmitButton';
import { useEffect, useState } from 'react';
import { standartErrorToast, successToast } from '../toasts';
import useTimer from '../../hooks/useTimer';
import SmsHint from "../SmsHint";

const processData = ({ dateSmsSendAccess }) => {
  if (!dateSmsSendAccess) return 0
  const lastSendDate = new Date(dateSmsSendAccess.replace("Z", "")).getTime()
  const remaining = lastSendDate - Date.now()
  return remaining >= 0 ? Math.ceil(remaining / 1000) : 0
}

const AppModal = ({ openAptModal }) => {
  const [phone, setPhone] = useState("")
  const [loadKey, setLoadKey] = useState(false) // ключик для применения useApi, нужен чтобы при смене вкладки чекать не изменилось ли чего на других вкладках
  const { data: remaining, isLoading, send, isSending, setData } = useApi({
    payload: ['panpartner:system.auth.form', 'checkLinkAppSms'],
    processData: resp => {
      const remaining = processData(resp)
      setTime(remaining)
      if (remaining > 0) runTimer()
      return remaining
    }
  }, [loadKey])

  const {
    timeout,
    timerIsStarted,
    runTimer,
    setTime
  } = useTimer(remaining > 0 ? remaining : 60)
  const visibilityChangeFn = e => setLoadKey(prev => e.target.hidden ? prev : !prev)
  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeFn)
    return () => document.removeEventListener("visibilitychange", visibilityChangeFn)
  }, [])

  const [successHintMessage, setSuccessHintMessage] = useState(false)
  const [errorHintMessage, setErrorHintMessage] = useState(false)
  const sendLinkAppSms = () => send(['panpartner:system.auth.form', 'sendLinkAppSms', { phone }])
    .then(resp => {
      setErrorHintMessage(false)
      const remaining = processData(resp)
      if (remaining > 0) runTimer()
      setData(remaining)
      setSuccessHintMessage(resp.message)
    }, err => {
      setSuccessHintMessage(false)
      if (remaining > 0) runTimer()
      setData(remaining)
      setErrorHintMessage(err[0].message)
    });

  return <div className="app-modal">
    <div className="app-modal__qr">
      <MobileAppIcon />
    </div>
    <p className="app-modal__text">
      Наведите камеру телефона на код, чтобы быстро скачать приложение
    </p>
    {
      timerIsStarted && successHintMessage &&
      <SmsHint
        text={successHintMessage}
        sucessHint
      />
    }

    {!timerIsStarted && errorHintMessage &&
      <SmsHint
        text={errorHintMessage}
        errorHint
      />
    }
    <div className="app-modal__action">
      <label className="input input_width_available input_type_form">
        <span className="input__label">Телефон</span>
        <InputMask
          onChange={e => setPhone(e.target.value)}
          className="input__control input__control--gray"
          name="phone"
          placeholder="Введите телефон"
          mask="+7 (999) 999-99-99"
          autoComplete="off"
          maskChar="_"
          value={phone}
        />
      </label>
      <SubmitButton
        className="btn h6 btn_primary for-mobile-app-popup"
        isLoading={isLoading || isSending}
        type="submit"
        onClick={sendLinkAppSms}
        disabled={!!timerIsStarted}
      >
        {timerIsStarted && timeout > 0 ? `Доступно через ${timeout}` : "Получить"}
      </SubmitButton>
    </div>
    <p className="app-modal__text">
      Получить ссылку на приложение по SMS
    </p>
  </div>
}

export default AppModal;
