import React, {useEffect, useMemo, useState} from "react";
import {Link, NavLink, useParams, useHistory, useLocation} from "react-router-dom";
import useApi from "../../hooks/useApi";
import Preloader from "../../components/Preloader";
import {
  CompilationOpenIcon,
  CompilationsShareIcon, CompilationsStubIcon,
  SfFilterCopyIcon, SfFilterDeleteIcon,
  SfFilterEditIcon, SfFilterWhatsupIcon, TelegramIcon, WhatsupIcon,
  CopyLinkIcon, PdfIcon
} from "../../components/Public/markup/icons";
import {CheckboxControlled} from "../../components/core/Checkbox";
import {goToUrl, sortComplex} from "./datasets";
import request, {getUrl, isDev} from "../../api";
import {compareWords, getSuffixUniversal, objectsArrayToFormData, toFormData} from "../../utils";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import {standartErrorToast, successToast} from "../../components/toasts";
import {getFavoriteCount} from "../../actions/favorites";
import {useModal} from "react-modal-hook";
import PanModal from "../../components/core/PanModal";
import PdfSettingsModal from "./PdfSettingsModal";
import {useDispatch} from "react-redux";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/core/Modal";
import CompilationModal, {CompilationViewingNoteModal} from "./compilationModal";
import Button from "../../components/core/Button";
import CompilationSettingsModal from "./CompilationSettingsModal";
import {CopyToClipboard} from "react-copy-to-clipboard";
import useClickPast from "../../hooks/useClickPast";
import CompareSettingsModal from "./CompareSettingsModal";
import SubmitButton from "../../components/SubmitButton";

const Compilation = () => {

  const { id } = useParams()
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromCrm = queryParams.get('fromCrm');
  const { data: { items = [], title, compilation, agent }, isLoading, send, setData, refresher} = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilation", {id}],
  })

  const dispatch = useDispatch();

  const {
    state: {
      selectedCity,
      selectedType,
      selectedTab,
      selectedDate,
      selectedAgent,
      tabs,
      search,
      selectedFavorites,
      compilations,
      sort,
      pdfItem
    },
    setValue,
    reset,
    setValues
  } = useUniversalReducer({
    selectedCity: { title: 'Все города' , code: "all"},
    selectedType: { title: 'Тип недвижимости' },
    selectedDate: { title: 'За 30 дней' },
    selectedAgent: { title: 'Сотрудник' },
    selectedTab: 0,
    search: "",
    tabs: [],
    selectedFavorites: [],
    compilations: [],
    sort: 2,
    pdfItem: {}
  });

  const [displayNote, setDisplayNote] = useState(null)
  const [displayNoteText, setDisplayNoteText] = useState(null)
  const [displayNoteId, setDisplayNoteId] = useState(null)

  const [checkCompState, setCheckCompState] = useState(false)
  useEffect(() => {
    send(["panpartner:favorite.ajax", "getCompilations", {}])
      .then(resp => setValue("compilations", resp.compilations))
    setCheckCompState(false)
  }, [checkCompState])

  const filteredFavorites = useMemo(() => {
    const filtered = items.filter(({ type, city, dateAdd, title, builderTitle }) => {
      const typeCondition = selectedType?.xmlId ? type === selectedType.xmlId : true;
      const cityCondition = selectedCity?.code ? city.code === selectedCity.code || selectedCity.code === "all" : true;
      const dateCondition = dateAdd && selectedDate?.id ? new Date(dateAdd) > new Date(selectedDate.id) : true;
      const searchCondition = (title || builderTitle) && !!search.replace(/ /g, "") ? compareWords(title ?? "", search) || compareWords(builderTitle ?? "", search) : true
      return typeCondition && cityCondition && dateCondition && searchCondition;
    })
    const currentSort = sortComplex[sort]
    return [...filtered].sort(currentSort.fn)
  }, [items, selectedCity, selectedType, selectedDate, search, sort])

  const onSelectItem = (checked, id) => {
    if (id == "all") return setValue("selectedFavorites", checked ? filteredFavorites : []);
    const checkedItem = filteredFavorites.find((item) => item.id == id);
    const selected = checked ? [...selectedFavorites, ...(checkedItem ? [checkedItem] : [])] : [...selectedFavorites.filter((item) => item.id != id)];
    setValue("selectedFavorites", selected);
  };

  const [showPdfModal, hidePdfModal] = useModal(() => (
    <PanModal isBig title={'Настроить PDF'} closeModal={closePdfModal}>
      <PdfSettingsModal item={pdfItem} />
    </PanModal>
  ), [pdfItem]);
  const openPdfModal = item => {
    setValue("pdfItem", item)
    showPdfModal()
  }
  const closePdfModal = (params) => {
    setValue("pdfItem", {})
    hidePdfModal()
  }
  const [noteText, setNoteText] = useState("");

  const [activeNote, setActiveNote] = useState(null)
  const [noteStatus, setNoteStatus] = useState("initial");
  const [fstArea, setFstArea] = useState(false)
  const [editCancelText, setEditCancelText] = useState('')

  const handleNoteBtnClick = (id) => {
    setNoteStatus("edit");
    setFstArea(true)
    setActiveNote(id)
    setNoteText('')
  };

  const remove = ({ city, id, type, compilationItemId }, e) => {
    e?.stopPropagation?.();
    send(["panpartner:favorite.ajax", "removeFromCompilation", { city: city?.code, id: compilation.id, compilationItemId: compilationItemId, type }])
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.items = tmp.items.filter(item => item.compilationItemId !== compilationItemId)
          return tmp;
        })
      }, standartErrorToast)
      .finally(() => dispatch(getFavoriteCount()))
  }


  const removeMultCompilation = () => {

  let compilationItemId = []
    selectedFavorites.map((item) => {
      compilationItemId.push(item.compilationItemId)
    })

    hideDeleteModal()
    send(["panpartner:favorite.ajax", "removeFromCompilation", {compilationItemId, id: compilation.id}])
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.items = tmp.items.filter(item => !selectedFavorites.some(({ id }) => item.id === id))
          return tmp;
        })
        setValue("selectedFavorites", [])
        successToast(<div>Выбранные объекты успешно удалены</div>)
      }, standartErrorToast)
      .finally(() => {
        dispatch(getFavoriteCount())
      })
  }



  const handleSaveNoteClick = (id, note) => {
    if (noteText.trim() !== "") {
      return request('panpartner:favorite.ajax', "saveNoteCompilationItem", {id, note})
        .then((resp) => {
          setNoteStatus("ready");
          setActiveNote(null)
          filteredFavorites.map((i) => {
            if (i.id === activeNote) {
              i.note = editCancelText
            }
          })
        })

    }
  };

  const handleViewNote = () => {
    return request('panpartner:favorite.ajax', "saveNoteCompilationItem", {id: displayNoteId, note: displayNoteText, displayNote: displayNote})
      .then((resp) => {
        closeNoteViewModal()
        successToast(<div>Настройки отображения видимости заметки изменены</div>)
        refresher()
      })
  }

  const handleRemoveNoticle = (compilationItemId, id) => {
    setNoteStatus("initial");
    setNoteText('')

    const index = filteredFavorites.findIndex((item) => item.id === id);
    if (index !== -1) {
      filteredFavorites[index].note = '';
      setNoteText('')
      setActiveNote(filteredFavorites[index].id)
    }

    return request('panpartner:favorite.ajax', "saveNoteCompilationItem", {id: compilationItemId, note: ''})

  }

  const handleCancelNoteClick = (id) => {
    if (noteStatus === "edit" && fstArea) {
      setNoteStatus("initial");
      setNoteText('')
    } else if (noteStatus === "edit"  && !fstArea) {
      setNoteStatus("ready");
      setNoteText(editCancelText)
      setActiveNote(null)
    }

    else if (noteStatus === "ready") {
      setNoteStatus("initial");
      setNoteText('')
    }
  };

  const handleEditNoteClick = (id) => {
    setFstArea(false)
    setNoteStatus("edit");
    setEditCancelText(noteText)
  };


  const saveCompilation = (params = {}) => {
    send(["panpartner:favorite.ajax", "saveCompilation", params])
      .then((resp) => {
        setValue("compilations", resp.compilations)
        successToast(<div>{resp?.message}</div>)
        setCheckCompState(true)

        if (resp && compilations && compilations.length === 0) {
          addToCompilation(resp.compilations[0].id)

        }

      })

  }

  const addToCompilation = (compilations) => {
    const sanitizedSelectedObjects = selectedFavorites.map(({ id, type, city }) => ({ objectId: id, type, city: city?.code }))
    let formData = toFormData( {id: compilations} );
    formData = objectsArrayToFormData(sanitizedSelectedObjects, "objects", formData)
    send(["panpartner:favorite.ajax", "addToCompilation", formData])
      .then((resp) => {
        setValue("compilations", resp.compilations)
        successToast(<div>{resp?.message}</div>)
      })
    setCheckCompState(true)
  }

  const deleteCompilations = id => {
    const payload = id ? { id } : toFormData({ id: selectedFavorites });
    hideDeleteModal()
    return request("panpartner:favorite.ajax", "removeCompilation", payload).then(
      resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.favorites = tmp.favorites.filter(item => item.id !== id)
          return tmp;
        })
        successToast(<div>Ваша подборка успешно удалена</div>)
        send(["panpartner:favorite.ajax", "getCompilations", {}])
          .then(resp => setValue("compilations", resp.compilations))
        return Promise.resolve(resp)
      }, standartErrorToast
    )
  }

  const removeMultiple = () => {
    const sanitizedSelectedObjects = selectedFavorites.map(({ id, type, city }) => ({ id, type, city: city?.code }))
    const formData = objectsArrayToFormData(sanitizedSelectedObjects, "objects")
    hideDeleteModal()
    send(["panpartner:favorite.ajax", "removeFromFavorites", formData])
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.favorites = tmp.favorites.filter(item => !selectedFavorites.some(({ id }) => item.id === id))
          return tmp;
        })
        setValue("selectedFavorites", [])
      }, standartErrorToast)
      .finally(() => {
        dispatch(getFavoriteCount())
      })
  }




  const [openCompilationModal, closeCompilationModal] = useModal(() => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation" closeByPastClick close={closeCompilationModal}>
      <CompilationModal
        close={closeCompilationModal}
        isInitial={compilations && compilations.length === 0 ? true : false}
        compilations={compilations}
        createCompilation={saveCompilation}
        addToCompilation={addToCompilation}
      />
    </Modal>
  ), [compilations, saveCompilation]);

  const [openNoteViewModal, closeNoteViewModal] = useModal(() => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation" closeByPastClick close={closeNoteViewModal}>
      <CompilationViewingNoteModal
        close={closeNoteViewModal}
        displayNote={displayNote}
        setDisplayNote={setDisplayNote}
        handleViewNote={handleViewNote}
      />
    </Modal>
  ), [displayNote]);

  const [openDeleteModal, hideDeleteModal] = useModal(({ }) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={hideDeleteModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
          <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
        </svg>
        Удалить выбранные объекты из подборки?
      </div>
      <div className="crm-table__sf-controlsmodaltext">
        После удаления вся информация будет утеряна
      </div>
      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={tabs[selectedTab]?.id === 'compilations'? deleteCompilations : removeMultCompilation}>Да</Button>
        <Button buttonType="line" onClick={hideDeleteModal}>Нет</Button>
      </div>
    </Modal>
  ), [selectedFavorites, selectedTab, tabs]);





  const [openCompilationSettingsModal, closeCompilationSettingsModal] = useModal(() => (
    <PanModal isSmall title={'Настроить Подборку'} closeModal={closeCompilationSettingsModal}>
      <CompilationSettingsModal
        refresher={refresher}
        closeModal={closeCompilationSettingsModal}
        id={id}
      />
    </PanModal>
  ), [compilation]);

  const [openCompareSettingsModal, closeCompareSettingsModal] = useModal(() => (
    <PanModal isBig title={'Настройки отображения для клиента'} closeModal={closeCompareSettingsModal}>
      <CompareSettingsModal
        refresher={refresher}
        closeModal={closeCompareSettingsModal}
        id={id}
      />
    </PanModal>
  ), [compilation]);

  const [isActive, setIsActive] = useState(false)
  const [isActive2, setIsActive2] = useState(false)
  const node = useClickPast(null, ({ target }, { current }) => {
    if (current?.contains?.(target) || target?.closest?.('.crm-table__sf-controls') || target?.closest?.('.items-filters__addcompilation-btn')) return
    setIsActive(false)
  })
  const node2 = useClickPast(null, ({ target }, { current }) => {
    if (current?.contains?.(target) || target?.closest?.('.crm-table__sf-controls') || target?.closest?.('.items-filters__addcompilation-btn')) return
    setIsActive2(false)
  })

  const saveComparePdf = () => {
    return request('panpartner:favorite.ajax', 'getCompilationComparePdf', { id })
      .then(
        data => {
          window.open(getUrl(data?.pdf?.path), '_blank')
          return Promise.resolve(data);
        },
      );
  }


  const [fetchingPdf, setFetchingPdf] = useState(null);
  const getPdf = (id, type) => {
    setFetchingPdf(id)
    let formData = toFormData( {});
    formData = objectsArrayToFormData([{objectId: id, city: 'spb', type: type}], "objects", formData)
    return request('panpartner:new.suburbans.ajax', 'addPresentation', formData)
      .then((data) => {

        return request('panpartner:new.suburbans.ajax', 'getPresentationPdf', {type: type, id: data.xmlId})
          .then((resp) => {
            setFetchingPdf(null)
            window.open(getUrl(resp.path))
          })

      })
  }


  if (isLoading) return <Preloader loading />;
  const isAllSelected = !!filteredFavorites?.length && selectedFavorites?.length === filteredFavorites?.length;
  return (
    <div className="wrapper">
      <div className="common-header" style={{position: 'relative', marginBottom: 10}}>
        <div className="common-header__title">
          <NavLink className="common-header__chevron" to={!fromCrm ? '/favorites' : '/crm/compilations'}><svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round"/></svg></NavLink>
          <h3 className="common-title">
            {compilation.title}
            {false && <span className="common-title__blue-link" style={{marginTop: -3}} onClick={openCompilationSettingsModal}>Редактировать</span>}
          </h3>
        </div>
         <div style={{ display: "flex", gap: 14 }}>
          <button className="header__bottom-controls_stock-link" onClick={openCompareSettingsModal}>
            <span style={{ paddingLeft: 4, fontFamily: '"Formular", sans-serif' }}>Настройки</span>
          </button>
          {/*{items.length > 0 && <a className="items-filters__addcompilation-btn secondary" href={`/public/compare/${id}`} target="_blank">*/}
          {/*  <SfFilterCopyIcon />*/}
          {/*  <span style={{ paddingLeft: 4 }}>Сравнение</span>*/}
          {/*</a>}*/}
          {/* {items.length > 0 && <button className="items-filters__addcompilation-btn blank" onClick={() => { window.open(getUrl(`/public/compare/${id}`), '_blank') }}>*/}
           {items.length > 0 && <button className="items-filters__addcompilation-btn items-filters__addcompilation-btn_link blank" onClick={() => {
             setIsActive2(prev => !prev)
             setIsActive(false)
           }}>
             <CompilationsShareIcon />
             <span style={{ paddingLeft: 4 }}>Сравнение</span>
           </button>}
          <button className="items-filters__addcompilation-btn items-filters__addcompilation-btn_link blank" onClick={() => {
            setIsActive(prev => !prev)
            setIsActive2(false)
          }}>
            <CompilationsShareIcon />
            <span style={{ paddingLeft: 4 }}>Подборка</span>
          </button>
        </div>
        {false && <button className="items-filters__addcompilation-btn" onClick={() => { setIsActive(prev => !prev) }}>
          <CompilationsShareIcon />
          <span style={{ paddingLeft: 4 }}>Поделиться</span>
        </button>}

        <ul style={{right: 0, top: 50}} className={`crm-table__sf-controls${isActive ? ' crm-table__sf-controls_shareOn' : ''}`} ref={node}>

          <li>
            <a href={`https://t.me/share/?url=` + getUrl(`/compilation/${compilation?.xmlId}`)} target='_blank' style={{color: 'inherit', display: 'flex', width: '100%', alignItems: 'center'}}>
              <TelegramIcon/>
              Telegram
            </a>
          </li>
          <li>
            <a href={`https://wa.me/send?text=` + getUrl(`/compilation/${compilation?.xmlId}`)} target='_blank' style={{color: 'inherit', display: 'flex', width: '100%', alignItems: 'center'}}>
              <WhatsupIcon />
              WhatsUp
            </a>
          </li>
          <li>
            <CopyToClipboard
              text={getUrl(`/public/compilation/${compilation?.xmlId}`)}
              onCopy={() => {
                successToast(<div>Ссылка успешно скопирована в буфер обмена</div>)
                setIsActive(false)
              }}
            >
              <Button buttonType="clean"><SfFilterCopyIcon />Скопировать ссылку</Button>
            </CopyToClipboard>
          </li>

        </ul>

        <ul style={{right: 0, top: 50}} className={`crm-table__sf-controls${isActive2 ? ' crm-table__sf-controls_shareOn' : ''}`} ref={node2}>
          <li>
            <a href={getUrl(`/public/compare/${id}`)} target='_blank' style={{color: 'inherit', display: 'flex', width: '100%', alignItems: 'center'}}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.819 13.7792L15.7466 9.39684" stroke="#2C2E3E" stroke-linecap="round"/>
                <path d="M15.4989 9.14932L11.1166 9.07693" stroke="#2C2E3E" stroke-linecap="round"/>
                <path d="M15.7577 9.13842L7.82797 17.0681" stroke="#2C2E3E" stroke-linecap="round"/>
              </svg>

              Открыть
            </a>
          </li>
          <li>
            <CopyToClipboard
              text={getUrl(`/public/compare/${id}`)}
              onCopy={() => {
                successToast(<div>Ссылка успешно скопирована в буфер обмена</div>)
                setIsActive2(false)
              }}
            >
              <Button buttonType="clean"><SfFilterCopyIcon />Скопировать ссылку</Button>
            </CopyToClipboard>
          </li>

          <li>
            <Button buttonType="clean" onClick={() => {
              saveComparePdf()
              setIsActive2(false)
            }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 11L12 14L15 11" stroke="#2C2E3E" stroke-linecap="round"/>
                <path d="M12 13L12 5" stroke="#2C2E3E" stroke-linecap="round"/>
                <path d="M5 13L5 18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V13" stroke="#2C2E3E" stroke-linecap="round"/>
              </svg>
              Скачать
            </Button>
          </li>

        </ul>


      </div>
      {items.length > 0 ?
      <>

        <div className="items-filters" style={{marginTop: 0}}>
          <div className="items-filters__left">
            <CheckboxControlled
              view="red"
              checked={isAllSelected}
              onChange={e => onSelectItem(e.target.checked, "all")}
              id={"all"}
            />
            <label className="checkbox__label" htmlFor="all-complexs">
              <b style={{
                color: 'black',
                paddingLeft: 5
              }}>{filteredFavorites?.length} {`объявлени${getSuffixUniversal({
                "1-1": "е",
                "2-4": "я",
                default: "й"
              }, filteredFavorites?.length,)}`}</b>
            </label>
            <div className="special-filter-drop special-filter-drop_favorites">

              <Dropdown
                value={sortComplex[sort]}
                options={sortComplex}
                onChange={sortId => setValue("sort", sortId)}
              />
            </div>
          </div>
          <div className="item-filters__right">
            <div className="items-filters__buttons">

              {!!selectedFavorites?.length && <span className="link" onClick={openDeleteModal}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                        d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6"
                        stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6"
                        stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                      Удалить выбранные
                    </span>}
              {/*<span className="link" onClick={openCompilationSettingsModal}>*/}
              {/*  модалка настройки подборки*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
        <ul className="favorites-list">
          {
            filteredFavorites.map((item, index) => {
              return (
                <li className={`favorites-list__over-item`}  id={`myIdIs-${item.id}`} key={item.id}>
                  <CheckboxControlled
                    view="invert"
                    checked={!!selectedFavorites.find(({id}) => item.id == id)}
                    onChange={e => onSelectItem(e.target.checked, item.id)}
                    id={`row-item-${item.id}`}
                  />
                  <div className="favorites-list__item" onClick={e => goToUrl(item?.link, e)}>
                    {item?.status?.name === 'Продано' || item?.status?.name === 'Бронь' ? <div className="favorites-list__item_sold"/> : <></>}
                    <div className="favorites-list__item-left">
                      <img className="favorites-list__item-img favorites-list__item-img_apartments" src={getUrl(item?.photo?.src)}/>
                      <div className="favorites-list__item-content">
                        <div className="favorites-list__item-info">
                          <div className="favorites-list__item-title h4" style={{display: 'flex', alignItems: 'center'}}>
                            {item?.minPrice}

                            <div className="favorites-list__item-dynamic">
                              {!!item?.dynamicPrices?.[2]?.flag && <svg style={item?.flag === 'down' ? {cursor: 'pointer', transform: 'rotate(180deg)'} : {marginBottom: 3, cursor: 'pointer'}} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.92578 0.0742188L11.0577 8.96289H0.793904L5.92578 0.0742188Z" fill={item?.dynamicPrices[2]?.flag === 'up' ? '#2CBA2E' : '#ff2d23'}/></svg>}
                              <div className="sf favorites-list__item-dynamic_hint">
                                {item.dynamicPrices && item?.dynamicPrices &&
                                <div className={`sf__block-hint`}>
                                  <p className="h5-l" style={{marginBottom: 20}}>Динамика цены</p>
                                  {item?.dynamicPrices.map(({ title, value, flag }) => (
                                    <div className="sf__info-row" key={item.id}>
                                      <div className="sf__info-key">{title}</div>
                                      <div className="sf__info-value" style={flag && flag === 'up' ? {color: '#2CBA2E'} : flag && flag === 'down' && {color: '#ff2d23'} }>{ value}</div>
                                    </div>
                                  ))}
                                </div>}
                              </div>
                            </div>
                          </div>
                          <div className="favorites-list__item-price">{item?.title}</div>
                          {item.objectType === 'secondary' ?
                            <div className="favorites-list__item-address">{item?.address}</div> :
                            <div className="favorites-list__item-address">{item?.blockTitle}</div>
                          }
                          <div className="favorites-list__item-deadline">{item?.ending}</div>
                        </div>

                        <div className="favorites-list__item-action">
                          <div className="items-filters" style={{marginTop: 0}}>
                            <div className="items-filters__buttons">

                              <SubmitButton
                                className="button link"
                                isLoading={fetchingPdf === item.id}
                                onClick={
                                  (e) =>{
                                    e?.stopPropagation?.();
                                    if (item.type === 'parcel' || item.type === 'house' || item.type === 'contractConstruction') {
                                      getPdf(item.id, item.type)
                                    } else {
                                      openPdfModal(item)
                                    }
                                  }
                                }
                              >
                                <PdfIcon />
                                Скачать PDF
                              </SubmitButton>

                              {item.note === '' && agent.id === compilation.agentId &&
                              <span id="note-btn" className="link" onClick={
                                (e) => {
                                  e?.stopPropagation?.();
                                  handleNoteBtnClick(item.id)
                                }
                              }>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        Заметка
                                      </span>
                              }


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="favorites-list__item-right">
                      <ul className="favorites-list__item-tags">
                        <li className={`label-item ${item?.status?.color === 'green' ? ' label_green' : item?.status?.color === 'gray' ?  ' label_red' : item?.status?.color === 'red' ?  ' label_red' : ''}`}>{item?.status?.name}</li>
                        {item.types && item.types.map((i) => {return (<li>{i}</li>)})}
                      </ul>
                      <div className="favorites-list__item-remove">
                        {agent.id === compilation.agentId &&
                          <span onClick={e => remove(item, e)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"><path
                                  d="M12.2192 18.9806C12.2192 18.9806 4.21924 14.5099 4.21924 9.08126C4.21924 8.12158 4.55243 7.19153 5.16214 6.44937C5.77184 5.7072 6.62038 5.19876 7.5634 5.01055C8.50641 4.82234 9.48564 4.96599 10.3345 5.41706C11.1833 5.86812 11.8494 6.59874 12.2192 7.4846C12.5891 6.59874 13.2551 5.86812 14.104 5.41706C14.9528 4.96599 15.9321 4.82234 16.8751 5.01055C17.8181 5.19876 18.6666 5.7072 19.2763 6.44937C19.886 7.19153 20.2192 8.12158 20.2192 9.08126C20.2192 14.5099 12.2192 18.9806 12.2192 18.9806Z"
                                  fill="#EF372E"/></svg>
                                Удалить из подборки
                              </span>
                        }
                      </div>
                    </div>



                    <div className="favorite-note">
                      {agent.id === compilation.agentId &&
                        <>
                      {noteStatus === "edit" && activeNote === item.id && (
                        <div className="favorite-note__create-note">
                          <span className="favorite-note__label">Заметка</span>
                          <textarea
                            className="textarea__control"
                            placeholder="Текст Вашей заметки"
                            value={noteText}
                            onChange={(e) => {
                              setNoteText(e.target.value)
                              setEditCancelText(e.target.value)
                            }}
                            onClick={(e) => {e?.stopPropagation?.();}}
                          />

                          <div className="favorite-note__actions">
                            <button id="save-note" className={noteText === '' || noteText === item.note ? 'favorite-note__actions_disable-btn' : ''} onClick={(e) => {
                              e?.stopPropagation?.();
                              handleSaveNoteClick(item.compilationItemId, noteText)
                              item.note = editCancelText
                            }}>Сохранить</button>
                            <button id="cancel-note" onClick={
                              (e) => {
                                e?.stopPropagation?.();
                                handleCancelNoteClick()
                              }
                            }>Отменить</button>
                          </div>

                        </div>
                      )}

                      {item.note !== '' &&
                      <div className={`favorite-note__ready-note ${activeNote === item.id ? 'favorite-note__ready-note_hide' : ''}`}>
                        <div className='favorite-note__ready-content'>
                          <b>Заметка:{"\n"}</b>
                          {item.note}

                        </div>
                        <div className="favorite-note__ready-actions">
                          <span id="viewing-note" onClick={(e) => {
                            e?.stopPropagation?.();
                            openNoteViewModal()
                            setDisplayNote(item.displayNote.xmlId)
                            setDisplayNoteText(item.note)
                            setDisplayNoteId(item.compilationItemId)
                          }}>
                            {item.displayNote.xmlId === 'all' ?
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12 7C6.28571 7 4 12 4 12C4 12 6.28571 17 12 17C17.7143 17 20 12 20 12C20 12 17.7143 7 12 7Z"
                                  stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                  d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                                  stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              : <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12 7C6.28571 7 4 12 4 12C4 12 6.28571 17 12 17C17.7143 17 20 12 20 12C20 12 17.7143 7 12 7Z"
                                  stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                  d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                                  stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                                <line x1="18.3674" y1="5.33914" x2="6.3674" y2="18.3391" stroke="black"/>
                              </svg>
                            }


                              </span>
                                <span id="edit-note" onClick={(e) => {
                                  e?.stopPropagation?.();
                                  handleEditNoteClick(item.id)
                                  setActiveNote(item.id)
                                  setNoteText(item.note)
                                }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg></span>
                          <span id="cancel-note" onClick={(e)=>{
                            e?.stopPropagation?.();
                            handleRemoveNoticle(item.compilationItemId, item.id)
                          }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg></span>
                        </div>
                      </div>
                      }

                        </>
                      }
                    </div>






                    <div></div>

                  </div>

                </li>
              )
            })
          }
        </ul>
      </> :
        <>
          <div className="compilations-stub">
            <div className="compilations-stub__icon">
              <CompilationsStubIcon/>
            </div>
            <p className="compilations-stub__text">Подборка создана, но еще не заполнена</p>
            <div className="compilations-stub__btn" style={{marginBottom: 40}}>
              <Button buttonType="primary" onClick={() => {
                history.push('/favorites')
              }}>Добавить объекты</Button>
            </div>
          </div>
        </>
      }


    </div>
  )
}

export default Compilation;
