import intersectionBy from 'lodash/intersectionBy';
import { memo, useEffect, useMemo, useReducer, useState } from 'react';
import {
  Button,
  Map,
  ObjectManager,
  YMaps,
  ZoomControl,
  RulerControl,
} from 'react-yandex-maps';
import { getUrl } from '../../../../api';
import cluster_point_default from '../../../../assets/images/cluster_point.svg';
import map_point from '../../../../assets/images/map_point.svg';
import useMedia from '../../../../hooks/useMedia';
import './index.scss';
import {
  behaviors,
  clusterMinPriceFilter,
  clusterTemplateMarkup,
  iconTemplateMarkup,
  mapButtonTemplate,
  paintOnMapFunction,
  polygonStyles,
  roundMapSelectButton,
} from './mapUtils';
import { roundButtons } from './roundButtons';

let paintIsStarted = false;

const RealtyMap = memo((props) => {
  const {
    extractFeatures,
    items,
    mapCenter,
    zoom,
    dispatch,
    ymaps,
    isLoading,
    onObjectClick,
    savedFilterSettings,
    innerCbRef = {},
    currency,

    clusterPointTemplate,
    clusterPointIcon,
    pointTemplateFn,
    mapPointIcon
  } = props;

  useEffect(() => {
    document.body.classList.add('body-map');

    return () => {
      document.body.classList.remove('body-map');
    };
  }, []);

  const cluster_point = clusterPointIcon || getUrl(savedFilterSettings?.icon?.src) || cluster_point_default

  // Основное состояние карты и сеттер
  const [mapInstance, setMapInstance] = useState(null);
  const [objectManagerRef, setObjectManagerRef] = useState(null);
  const [rullerControlRef, setRullerControlRef] = useState(null);
  const [buttonRef, setButtonRef] = useState(null);
  const [clearArea, setClearArea] = useState(false);
  const setToStore = (payload = {}) => dispatch({ type: 'setData', payload });
  const isMobile = useMedia("isMobile")
  // Набор данных для карты
  const features = useMemo(() => {
    // метки на карту с координатами и настройками см ESTATE_SUCCESS_MAP
    if (!items?.length) return;
    return extractFeatures(items);
  }, [items]);

  //SVGшные и HTMLные шаблоны под карту
  const createClass = (template, ...args) =>
    ymaps ? ymaps.templateLayoutFactory.createClass(template, ...args) : null;
  const clusterTemplate = useMemo(
    () => createClass(clusterPointTemplate || clusterTemplateMarkup),
    [ymaps, currency],
  );
  const pointTemplate = useMemo(() => createClass(pointTemplateFn?.(currency) || iconTemplateMarkup(currency)), [ymaps, currency]);
  const buttonTemplate = useMemo(() => createClass(mapButtonTemplate), [ymaps]);
  const roundButton = useMemo(() => createClass(roundMapSelectButton), [ymaps]);
  // свойства для objectManager'a
  const objectOptions = {
    iconLayout: 'default#imageWithContent',
    iconImageHref: mapPointIcon || map_point,
    iconContentLayout: pointTemplate,
    iconImageSize: [83, 34],
    iconImageOffset: [-34, -24],
  };
  const clusterOptions = {
    clusterIcons: [
      {
        href: cluster_point,
        size: [110, 34],
        offset: [-20, -20],
      },
    ],
    clusterIconContentLayout: clusterTemplate,
  };
  const objectManagerOpttions = {
    clusterize: true,
    gridSize: 64,
    clusterHasBalloon: false,
  };
  //Общие функции карты (зум и т.д.)

  const disableRuler = () => rullerControlRef?.disable?.(); //выключаем линейку
  const enableRuler = () => rullerControlRef?.enable?.();
  const deselectRuler = () => rullerControlRef?.deselect?.();
  const deselectButton = () => buttonRef?.state?.set('selected', false);
  const selectButton = () => buttonRef?.state?.set('selected', true);

  useEffect(() => {
    if (ymaps) roundButtons(ymaps);
    if (!ymaps || ymaps.modules.isDefined('ext.paintOnMap') || !features) return;

    const extensionName = 'ext.paintOnMap';
    const params = ['meta', 'util.extend', 'pane.EventsPane', 'Event'];
    ymaps.modules.define(extensionName, params, paintOnMapFunction); // Определяем собственное расширение для карты

    let paintProcess;

    // запускаем процесс рисования:
    mapInstance.events.add(['mousedown', 'touchmove'], (e) => {
      if (!paintIsStarted) return;
      paintProcess = ymaps.ext.paintOnMap(mapInstance, e);
    });

    // завершаем рисование:
    mapInstance.events.add(['mouseup', 'touchend'], (e) => {
      if (!paintProcess) return;
      const coordinates = paintProcess.finishPaintingAt(e);
      paintProcess = null;
      //создаем полигон, шлёпаем его на карту
      const drawArea = new ymaps.Polygon([coordinates], {}, polygonStyles);
      mapInstance.geoObjects.add(drawArea);
      setTimeout(()=>{
        mapInstance.setBounds(drawArea.geometry.getBounds()); // Зум фиксить тут
      }, 35)
      // завершаем рисование
      paintIsStarted = false;

      // добавляем менеджер объектов на карту
      const objectManager = new ymaps.ObjectManager(objectManagerOpttions);
      objectManager.add(features);
      objectManager.objects.options.set(objectOptions);
      objectManager.clusters.options.set(clusterOptions);

      mapInstance.geoObjects.add(objectManager);

      // запрашиваем все объекты и делаем их видимыми
      const objects = ymaps.geoQuery(objectManager.objects);
      objects.setOptions('visible', true);

      // ищем объекты внутри нужной области
      const findedInArea = objects.searchInside(drawArea);
      findedInArea.setOptions('visible', true);

      let selectedItems = [];
      findedInArea.each((v) => {
        const findedItem = items.find(
          (feature) => feature.coordinates[0] == v.geometry._coordinates[0],
        );
        selectedItems.push(findedItem);
      });
      setClearArea(true);

      e.originalEvent.target.controls.each((e) =>
        e.options._name === 'rulerControl' ? e.enable() : e,
      );
      setToStore({ selectedItems, showFilters: false });
      if (selectedItems.length > 0) onObjectClick?.();
      objectManager.events.add('click', clickOnMap);
    });
    // добавим фильтр по цене
    ymaps.template.filtersStorage.add('price', (dataManager, features) => clusterMinPriceFilter(dataManager, features, currency));
    // круглые кнопки

  }, [ymaps, rullerControlRef, features]);
  
  useEffect(() => {
    if (!ymaps?.template?.filtersStorage?.hash?.price) return
    ymaps.template.filtersStorage.hash.price = (dataManager, features) => clusterMinPriceFilter(dataManager, features, currency);
  }, [currency, ymaps])
 
  const showHint = (objectId) => {
    const projection = mapInstance?.options?.get?.('projection');
    const findetObject = objectManagerRef?.objects?.getById?.(objectId);
    if (!findetObject) return;
    const bounds = projection.toGlobalPixels(
      findetObject.geometry.coordinates,
      mapInstance.getZoom(),
    );
    objectManagerRef.objects.hint.open(objectId, bounds);
  };
  if ('current' in innerCbRef) innerCbRef.current = showHint;

  const clickOnMap = (e) => {
    const objManager = e.originalEvent.currentTarget;
    const objectId = e.get('objectId');
    const type = typeof objectId;

    if (type === 'number' || type === 'string') {
      const objects =
        type === 'number'
          ? [objManager.objects.getById(objectId)]
          : objManager.clusters.getById(objectId)?.features;

      const selectedItems = intersectionBy(items, objects, 'id');
 
      setToStore({
        selectedItems,
        showFilters: false,
        ...(objects?.length === 1? { 
          mapCenter: objects[0]?.geometry.coordinates,
          // zoom: 13 // выкл зума при клике на объект
        }: [])
      });
      // if(objects?.length === 1) { // выкл зума при клике на объект
      //   mapInstance.setCenter(objects[0]?.geometry.coordinates, 13);

      // }
      onObjectClick?.();
    }
  };

  const handlePaintStart = (e) => {
    if (clearArea) {
      enableRuler();
      paintIsStarted = false;
      setClearArea(false);
      e.originalEvent.target.state.set('selected', false);
      setToStore({ selectedItems: [] });
      mapInstance.geoObjects.each((e) =>
        e instanceof ymaps.Polygon ? mapInstance.geoObjects.remove(e) : null,
      );
      return;
    }
    paintIsStarted = !paintIsStarted;
    deselectRuler();
    paintIsStarted ? disableRuler() : enableRuler();
    // удаление старой области
    mapInstance.geoObjects.each((e) =>
      e instanceof ymaps.Polygon ? mapInstance.geoObjects.remove(e) : null,
    );
    if (!mapInstance.geoObjects.get(1))
      return mapInstance.cursors.push('arrow');
    //показывать надо всё
    objectManagerRef.setFilter(() => true);
    // обхватываем все точки на карте
    mapInstance.setBounds(objectManagerRef.getBounds());
    //меняем курсор на стрелочку
    mapInstance.cursors.push('arrow');
  };

  return (
    <div
      className="sf__map round_map_button"
      style={{ filter: isLoading ? 'blur(5px)' : 'none' }}
    >
      <YMaps>
        <Map
          defaultState={{
            center: [59.9342802, 30.3350986],
            zoom: 9,
            behaviors,
          }}
          state={{
            center: mapCenter,
            zoom,
            behaviors,
          }}
          modules={[
            'templateLayoutFactory',
            'template.filtersStorage',
            'Template',
          ]}
          className="fullMap"
          id="myMap"
          onLoad={(ymaps) => setToStore({ ymaps })}
          instanceRef={(inst) => setMapInstance(inst)} // если положить сразу setMapInstance то отвалятся метки
        >
         {!!features &&  <ObjectManager
            instanceRef={setObjectManagerRef}
            options={objectManagerOpttions}
            clusters={clusterOptions}
            objects={objectOptions}
            features={features}
            onClick={clickOnMap}
            modules={[
              'package.full',
              'ext.paintOnMap',
              'Polygon',
              'ObjectManager',
              'geoQuery',
            ]}
          />}
          <ZoomControl
            options={{
              layout: 'round#zoomLayout',
              position: {
                bottom: '33px',
                right: '16px',
              },
            }}
          />
          <Button
            instanceRef={setButtonRef}
            data={{
              title: 'Выделить область',
            }}
            key={clearArea}
            options={{
              layout: roundButton,
              maxWidth: 36,
              position: {
                top: '396px',
                right: '16px',
              },
            }}
            onClick={handlePaintStart}
            state={{ selected: paintIsStarted || clearArea }}
          />
          <RulerControl
            instanceRef={setRullerControlRef}
            options={{
              layout: 'round#rulerLayout',
              position: {
                top: '340px',
                right: '16px',
              },
            }}
          />
        </Map>
      </YMaps>
    </div>
  );
});

export default RealtyMap;