import { useEffect, useState } from "react";
import { formatMs } from '../utils';

const toMs = dateString => new Date(dateString).getTime()

const delta = (start, end) => toMs(end) - toMs(start)


const toUserTime = (serverTime, userTime) => {
  return current => current + Math.abs(delta(serverTime, userTime))
}
// start (serverTime), end, frequency=1000 (1000 === 1 s., 60000 === 1 min.)
const useCountdown = (startDate, endDate, frequency = 1000) => {

  const [timer, setTimer] = useState(null);
  const [countdown, setCountdown] = useState(null)
  const [timerIsStarted, setTimerIsStarted] = useState(false)
  const [userTime, setUserTime] = useState(new Date())

  useEffect(() => {
    clearInterval(timer);
    setCountdown(delta(startDate, endDate))
    runTimer()
    return () => {
      clearInterval(timer);
      setTimer(null)
    }
  }, [startDate, endDate])

  const runTimer = () => {
    setTimerIsStarted(true);
    setUserTime(new Date())
    setTimer(
      setInterval(() => {
        setCountdown((prevTime) => prevTime - frequency);

      }, frequency),
    );
  };

  return {
    countdown: countdown <= 0 ? formatMs(0) : formatMs(toUserTime(startDate, userTime)(countdown)),
    timeIsOver: countdown <= 0,
    timerIsStarted,
    setTimerIsStarted,
  }
};

export default useCountdown;
