import { Link } from 'react-router-dom';
import { getUrl } from "../../api";
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import { CardHeartBtnIcon } from '../Public/markup/icons';
import { useState } from 'react';

export const CardWithFavorite = props => {

  const { toggleFavorite, id, index, get, hideTitleNewVersion } = props

  const path = `contractConstructions[${index}].favorite`

  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const handleFavorite = e => {
    e.preventDefault()
    e.stopPropagation()
    if (isFavoriteLoading) return
    setIsFavoriteLoading(true)

    toggleFavorite({ id }, path).finally(setIsFavoriteLoading)
  };

  return <Card {...props} hideTitleNewVersion={hideTitleNewVersion} inFavorite={get(`${path}.inFavorite`)} toggleFavorite={handleFavorite} />
}
const Card = (props) => {

  const {
    commission,
    id,
    photo,
    showComission,
    squareHouseSrc,
    section,
    squareTotal,
    price,
    meterPrice,
    title,
    hidePrice,
    link,
    inFavorite,
    toggleFavorite,
    xmlId,
    hideTitleNewVersion,
    justHouse
  } = props;

  return (
    <Link
      to={{
        pathname: link?.(props)
          ?? (
            justHouse && hideTitleNewVersion
              ? `/house/${xmlId}`
              : hideTitleNewVersion
                ? `/build-house/${xmlId}`
                : `/old/build-house/${id}`
          ),
        state: { squareHouseSrc },
      }}
      className="special__card-a"
      target="_blank"
    >
      <article className="special__card">
        <header className="special__card-header">
          <img
            className="special__card-preview"
            src={getUrl(photo?.src)}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = houseEmpty
              e.target.classList.add('is-empty')
            }}
            loading="lazy"
            />

          {showComission && <span className="special__label special__label_absolute">{commission}</span>}

          {toggleFavorite && <ul className="sf-card__btn-list">
            {<li className="sf-card__btn sf-card__btn_heart" onClick={toggleFavorite}>
              <CardHeartBtnIcon inFavorite={inFavorite} />
            </li>}
          </ul>}
        </header>

        <div className="special__card-body">
          <div className="special__card-row-top">
            <h3 className="h3 special__card-title">{title}</h3>

            {!hidePrice && <div className="special__card-price-block" style={{flexShrink: 0}}>
              <p className="special__card-price">{price}</p>
              <p className="special__card-price-per">{meterPrice}</p>
            </div>}
          </div>

          <div className="special__card-row-bottom">
            <p className="special__card-info">Площадь: <b>{squareTotal}</b></p>
            <p className="special__card-info">Материал: <b>{section}</b></p>
          </div>


          {/* {
            constructionPeriod
          } */}
          {/* {
            livingWinter
          } */}

        </div>
      </article>
    </Link>
  );
};

export default Card;
