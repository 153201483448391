import { useState } from 'react';
import {CarIcon, ViewIcon, WalkIcon} from '../icons';
import Slider from './Slider';
import useMedia from '../../../../hooks/useMedia';
import { useModal } from 'react-modal-hook';
import InfoCard from './InfoCard';
import MobileModal from '../../../core/MobileModal';

import {
  ArrowRightIcon,
  InfoIcon,
  ComissionIcon,
  HeartIcon,
  FixIcon,
} from '../icons';
import { memo } from 'react';
import AppPopover from '../../../core/Popover';
import usePermission from '../../../../hooks/usePermission';
import { useMapModal } from '../../../../hooks/useMapModal';
import {Link} from "react-router-dom";
// import Link from "react-router-dom/modules/Link";

const Subway = memo(({ title, color, time, minutes, distanceType, newSuburban}) => (
  <div className="sf__metro">
    {!newSuburban && <span className="sf__metro-circle" style={{ backgroundColor: color }}></span>}
    {!newSuburban ? <span className="sf__metro-station">м. {title}</span> : <span className="sf__metro-station">{title}</span>}
    {distanceType && (
      <div className="sf__metro-time">
        {distanceType === 'byTransport' ? <CarIcon /> : <WalkIcon />}
        {minutes && <span className="sf__metro-route">{minutes} мин</span>}
        {time && <span className="sf__metro-route">{time}</span>}
      </div>
    )}
  </div>
));

const DetailHeader = memo((props) => {
  const {
    commission,
    description,
    inFavorite,
    isFavoriteLoading,
    isCustomerFixation,
    subways,
    title,
    toggleFavorite,
    onFixationClick,
    coordinates,
    sf,
    status,
    isSignUpToView,
    blockId,
    city,
    newSuburban
  } = props;
  const isTablet = useMedia('isTablet');
  const showMotivation = usePermission('motivation');
  const [infoModal, hideInfoModal] = useModal(({ in: open, onExited }) => (
    <MobileModal
      title="О жилом комплексе"
      closeClick={hideInfoModal}
      className="sf-mobile-modal"
    >
      <InfoCard />
    </MobileModal>
  ));

  const [openMapModal, closeMapModal] = useMapModal({
    cords: coordinates,
    mapCenter: coordinates,
  })

  return (
    <section className="sf__detail-header">
      <div className="sf__detail-head">
        <h1 className="h3-l sf__detail-title">{title}</h1>

        {commission && showMotivation && !sf && (
          <AppPopover complexHeaderMotivation overlayClassName={'complexHeaderMotivation'} content={commission} placement="right">
            <div className="sf-tooltip sf__commission">
              <ComissionIcon />
              Мотивация
            </div>
          </AppPopover>
        )}

        {
          status &&
          <div className="sf-tooltip sf__commission">
            <ComissionIcon />
            {status.name}
          </div>
        }

      </div>

      {isTablet && coordinates && (
        <span className="link link_color-red" onClick={openMapModal}>
          На карте
        </span>
      )}
      {isTablet && <Slider />}

      {isTablet && (
        <button
          type="button"
          className="sf__modal-btn button h4"
          onClick={infoModal}
        >
          <InfoIcon />
          О жилом комплексе
          <ArrowRightIcon />
        </button>
      )}

      <div className="sf__detail-bar">
        <p className="text sf__detail-descr">{description}</p>
        {coordinates && (
        <span className="link link_color-red" onClick={openMapModal}>
          На карте
        </span>
        )}
      </div>
      {!!subways?.length && (
        <div className="sf__detail-metro">
          {subways.map((item) => (
            <Subway newSuburban={newSuburban} {...item} />
          ))}
        </div>
      )}
      {
        !sf &&
        <div className="sf__elems">
          <button
            type="button"
            className="sf__elem-btn button link"
            onClick={() => toggleFavorite()}
          >
            <HeartIcon isActive={inFavorite} />
            {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
          </button>

          {isCustomerFixation && (
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={onFixationClick}
            >
              <FixIcon />
              Зафиксировать клиента
            </button>
          )}

          {isSignUpToView && (
            <Link to={'/book-a-tour/' + city + '/' + blockId}
              className="sf__elem-btn button link"
            >
              <ViewIcon />
              Запись на просмотр
            </Link>
          )}

          {!isSignUpToView && (
            <span className="sf__elem-btn record-not-available ">
              <ViewIcon />
              Запись на просмотр
              <span>Недоступно</span>
            </span>
          )}
        </div>
      }

    </section>
  );
});

export default DetailHeader;
