import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	clearFilterMyEvents, clearMyEvents, fetchMyEvents, filteredMyEvents,
	setFilterMyEvents, unsubscribeOnEvent
} from '../../actions/events';
import EmptyTable from '../core/EmptyTable';
import LocalError from '../ErrorBoundry/Local';
import Preloader from '../Preloader';
import Filter from './Filter';
import Items from './Items';

const Events = memo((props) => {
	const {
		fetch,
		reset,
		isFetching,
		filter,
		items,
		filterData,
		setFilter,
		clearFilter,
		unsubscribe,
		data,
	} = props;

	useEffect(() => {
		fetch()
		return reset;
	}, []);

	return (
		<div>
			<section className="section section_view_white no-header-profile">
				<div className="wrapper">
					<div className="lk-events__header">
						<div className="lk-events__title">
							<div className="h1">Мероприятия</div>
						</div>
						{/* <div className="lk-events__show-calendar">
						Посмотреть мой календарь
						<svg className="icon">
							<use xlinkHref="#arrow_bottom"></use>
						</svg>
					</div> */}
						<div className="lk-events__all">
							<Link to="/events">
								<div className="button button_view_default">
									Все мероприятия
								</div>
							</Link>
						</div>
					</div>

					{items.length > 0 ? (
						<>
							<LocalError wrappedComponent={"Filter 73"} values={filter}>
								<Filter
									filter={filter}
									filterData={filterData}
									setFilter={setFilter}
									clearFilter={clearFilter}
								/>
							</LocalError>
							<LocalError wrappedComponent={"Items 82"} values={data}>
								<Items items={data} unsubscribe={unsubscribe} />
							</LocalError>
						</>
					) : <EmptyTable title="У вас нет событий" />}
				</div>
			</section>
			<Preloader loading={isFetching} />
		</div>
	);
});

const mapDispatchToProps = (dispatch) => ({
	fetch: () => dispatch(fetchMyEvents()),
	reset: () => dispatch(clearMyEvents()),
	setFilter: (filter) => dispatch(setFilterMyEvents(filter)),
	clearFilter: () => dispatch(clearFilterMyEvents()),
	unsubscribe: (eventId, agentId, city) =>
		dispatch(unsubscribeOnEvent(eventId, agentId, city)),
});

const mapStateToProps = (state) => ({
	data: filteredMyEvents(state),
	items: state.events.myEvents,
	filterData: state.events.filterData,
	filter: state.events.filter,
	isFetching: state.events.isFetching
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
