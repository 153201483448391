import _ from "lodash";
import { createSelector } from "reselect";
import request from "../api";
import { actionFactory } from './actionUtils';

const comp = "panpartner:presentations.ajax"

const presentationsSelector = (state) => state.presentations.data;
const filterSelector = (state) => state.presentations.filter;

export const filteredPresentations = createSelector(
  [presentationsSelector, filterSelector],
  (presentations, filter) => {
    let result = presentations;

    if (filter.cities.length) {
      result = result.filter((item) => _.includes(filter.cities, item.city));
    }

    if (filter.clients.length) {
      result = result.filter((item) =>
        _.some(item.clientId, (id) => _.includes(filter.clients, `${id}`))
      );
    }

    if (filter.agents.length) {
      result = result.filter((item) =>
        _.includes(filter.agents, `${item.agentId}`)
      );
    }

    return result;
  }
);

const requestPresentations = actionFactory("PRESENTATIONS_REQUEST");

const successPresentations = actionFactory("PRESENTATIONS_SUCCESS");

const removePresentations = actionFactory("PRESENTATIONS_REMOVE");

export const clearPresentations = actionFactory("PRESENTATIONS_RESET");

const failurePresentations = actionFactory("PRESENTATIONS_FAILURE");

export const setFilterPresentations = actionFactory("PRESENTATIONS_SET_FILTER");

export const clearFilterPresentations = actionFactory("PRESENTATIONS_CLEAR_FILTER");

export const fetchPresentations = () =>
  dispatch => {
    dispatch(requestPresentations());
    return request(comp, "getPresentations")
      .then(
        data => {
          const { items, filterData, clients } = handleFilter(data);
          dispatch(
            successPresentations({
              data: items,
              filterData: filterData,
              clients: clients,
            })
          );
          return Promise.resolve({ items, filterData, clients });
        },
        error => {
          dispatch(failurePresentations());
          return Promise.reject(error);
        }
      );
  };

export const handleAttachClient = (clientId, presentationId, city, active) =>
  dispatch =>
    request(comp, active
      ? "addClientToPresentation"
      : "removeClientToPresentation", {
      clientId,
      presentationId,
      city,
    })
      .then(
        data => {
          const { items, filterData, clients } = handleFilter(data);
          dispatch(
            successPresentations({
              data: items,
              filterData: filterData,
              clients: clients,
            })
          );
          return Promise.resolve(data);
        },
        error => {
          dispatch(failurePresentations());
          return Promise.reject(error);
        }
      );

export const deletePresentations = (id, city = "") =>
  dispatch =>
    request(comp, "removePresentations", { id, city, })
      .then(
        data => {
          dispatch(fetchPresentations());
          return Promise.resolve(data);
        },
        error => {
          dispatch(failurePresentations());
          return Promise.reject(error);
        }
      );

export const getPdf = params => request(comp, "getPdf", params)
const successGetPDF = actionFactory("PRESENTATION_GET_PDF_SUCCESS");
export const getPdfThunk = params =>
  dispatch =>
    request(comp, "getPdf", params)
      .then(data => dispatch(successGetPDF({filePath: data.filePath})));

export const sendPresentation = (id, clients, city, type) =>
  dispatch =>
    request(comp, "sendPresentation", { id, clients, city, type })
      .then(
        data => {
          // dispatch(removePresentations(ids));
          return Promise.resolve(data);
        },
        (error) => {
          dispatch(failurePresentations());
          return Promise.reject(error);
        }
      );

export const addPresentation = (objectXmlId, city, type, method = "editPresentation", firstPay, percent, period, withoutMortgage) =>
  request(comp, method, {
    objectXmlId,
    city,
    type,
    'mortgage[initialPayment]': firstPay,
    'mortgage[percent]': percent,
    'mortgage[period]': period,
    // settings: "",
    'settings[mortgage]': withoutMortgage
  });

const handleFilter = ({ filter, presentations, clients }) => {
  let agents = [];
  let cities = [];
  let allClients = [];

  filter.agent.forEach(({ id, title }) => {
    agents.push({
      id: id,
      title: title,
    });
  });

  filter.city.forEach(({ title, code }) => {
    cities.push({
      id: code,
      title: title,
    });
  });

  clients.forEach(({ id, name, lastName, email, phone }) => {
    let title = `${name}${lastName ? ` ${lastName}` : ``}`;

    allClients.push({
      id: id,
      title: title,
      email: email[0],
      phone: phone[0],
    });
  });

  return {
    filterData: {
      agents: agents,
      cities: cities,
      clients: filter.client,
    },
    clients: allClients,
    items: presentations,
  };
};
