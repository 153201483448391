import { useMemo, useState } from "react"
import { compareWords } from "../../utils"



const useSearchEngine = (config = {}, filterData) => {
    const {
        aliasesSearchEnabled = true,
        fields = {}
    } = config

    const [search, setSearch] = useState("")

    const results = useMemo(() => {
        if (search?.length < 2) return []
        const result = []

        for (const fieldName in fields) {

            const items = filterData[fieldName];

            if (!items) continue;

            const { key = "title", converter, addLink, excludable = true } = fields[fieldName];

            for (const item of items) {
                const title = item[key]
                if(!title) continue;
                const keyCondition = compareWords(search, title) || compareWords(title, search)
                const aliasesCondition = aliasesSearchEnabled && Array.isArray(item?.aliases) ?
                    item.aliases.some(alias => compareWords(alias, search) || compareWords(search, alias)) : false;
                if (keyCondition || aliasesCondition) {
                    const tmpItem = {
                        ...item,
                        fieldName,
                        excludable,
                        link: addLink?.(item)
                    }
                    result.push(converter ? converter(tmpItem) : tmpItem)
                }
            }

        }

        return result;

    }, [search, fields, filterData, aliasesSearchEnabled])


    const onChange = e => setSearch(e.target.value)

    const reset = () => setSearch("")

    return {
        inputProps: {
            onChange,
            value: search
        },
        results,
        reset,
        hasValue: !!search?.length,
        hasResults: !!results?.length
    }
}

export default useSearchEngine;