import min from "lodash/min";
import { createSelector } from "reselect";
import { getRoundedPrice } from '../utils';

const suburbansSelector = (state) => state.suburbans.items;

export const mapItems = createSelector([suburbansSelector], (data) => {
  let items = [];
  if (data) {
    data.map((item) => {
      if (item.coordinates) {
        items.push(item);
      }
      return item;
    });
  }
  return items;
});


export const getFeatures = createSelector([suburbansSelector], (data) => {
  const features = []
  data.map((item) => {
    if (item.coordinates) {
      features.push({
        type: 'Feature',
        id: item.id,
        geometry: {
          type: 'Point',
          coordinates: item.coordinates,
        },
        properties: {
          hintContent: item.name,
          minPrice: getRoundedPrice(min(item.suburbansInfo.suburbansByType.map(val=>val.minPrice)))
        },
      });
    }
  
    return item;
  });
  return features
});
