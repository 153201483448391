import Toggler from ".";
const MobileSlideMenu = ({state, addClass, clickHandler, togglerClass, children}) => {
  
  return (
    <div className={`mobile-slide-menu${state ? ` opened` : ``}${addClass ? ` ${addClass}` : ``}`}>
    <Toggler clickHandler={clickHandler} addClass={togglerClass} />
    {children}
  </div>
  );
};

export default MobileSlideMenu;