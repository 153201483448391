import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword, saveProfile } from "../../actions/profile";
import { editUserData, fakeLogin } from "../../actions/user";
import useApi from "../../hooks/useApi";
import usePermission from "../../hooks/usePermission";
import { applyToFormData, toFormData } from "../../utils";
import Preloader from "../Preloader";
import { standartErrorToast, standartInfoToast } from "../toasts";
import Form from "./Form";
import "./index.css";


const Profile = ({ history }) => {

  const dispatch = useDispatch();

  const canEditAgency = usePermission("general");

  const isNotAgent = usePermission("not-agent");

  const { data, isLoading: isProfileLoading, send } = useApi({
    payload: ["panpartner:profile.ajax", "getProfile", {}],
    successCallback: resp=>setSettings(resp.settings)
  })
  const { data: requisitesData, isLoading: isRequisitesLoading, refresher: refreshRequisites} = useApi({
    payload: ['panpartner:b24.ajax', 'getRequisites'],
    // loadCondition: data => !isProfileLoading && isNotAgent
    loadCondition: data => {
      if (!isProfileLoading && isNotAgent) {
        return true
      }

    }
  }, [isProfileLoading]);

  const [settings, setSettings] = useState()

  useEffect(() => {
    dispatch(fakeLogin())
  }, []);

  const handleSave = values => {

    const formData = toFormData(values, false, true, true)

    applyToFormData(formData, settings, "settings")
    if(values.workLogo) formData.append("workLogo", values.workLogo)
    saveProfile(formData).then(
      data => {
        history.replace();
        dispatch(editUserData({ name: values.name, lastName: values.lastName }));
        standartInfoToast(data)
      },
      standartErrorToast,
    )
  };



  if (isProfileLoading ) return <Preloader loading={true} />

  const defaultValues = {
    id: data.id,
    name: data.name,
    lastName: data.lastName,
    patronymic: data.patronymic,
    phone: data.phone?.length ? data.phone : [""],
    email: data.email?.length ? data.email : [""],
    telegram: data.telegram,
    watsApp: data.watsApp,
    ...(canEditAgency ? {
      workLogo: data?.workLogo,
      workCompany: data?.workCompany,
      cityName: data?.cityName,
    } : [])
  }
  return <Form
    defaultValues={defaultValues}
    profile={data}
    canEditAgency={canEditAgency}
    edit={handleSave}
    editPassword={changePassword}
    history={history}
    settings={settings}
    setSettings={setSettings}
    requisitesData={requisitesData}
    showRequisites={isNotAgent && !isProfileLoading}
    refreshRequisites={refreshRequisites}
  />
};

export default Profile
