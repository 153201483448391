import React, {useEffect} from "react";
import Preloader from "../components/Preloader";

const PlatformCheck = (props) => {
  const ua = window.navigator.userAgent;
  const isAndroid = /android/i.test(ua);
  const isIOS = /iPad|iPhone|iPod/.test(ua);

  useEffect(() => {
    if (isAndroid) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.panmobile";
    }
    if (isIOS) {
      window.location.href = "https://apps.apple.com/ru/app/%D0%BF%D0%B0%D0%BD-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80/id1614819078";
    }
  }, []);

  return (
    <Preloader loading={true} />
  );
};

export default PlatformCheck;
