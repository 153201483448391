import { HelpIcon } from '../icons';
import {Link, useParams} from 'react-router-dom';
import PanModal from '../../../core/PanModal';
import { useModal } from 'react-modal-hook';
import Button from '../../../core/Button';
import NewAlert from "../../../core/NewAlert";
import SubmitButton from "../../../SubmitButton";
import React, {useState} from "react";
import request from "../../../../api";
import {useSelector} from "react-redux";

const Help = ({openContactsModal, sf, managerInfo, dubai}) => {



  const [callbackStatus, setCallbackStatus] = useState(false)
  const callback = () => request("panpartner:forms.ajax", "callback", {
    urgently: true,
  }).then(
    (success) => {
      setCallbackStatus({ type: "success" })},
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )

  const { blockId, savedFilterXmlId } = useParams();
  const userInfo = useSelector(state => state.user?.info);
  const callbackDubai = () => request("panpartner:forms.ajax", "callback", {
    urgently: true,
    managerId: managerInfo?.id,
    name: `${userInfo?.name} ${userInfo?.lastName}`,
    type: 'global',
    objectId: blockId,
    city: 'dxb',
    savedFilterXmlId: savedFilterXmlId
  }).then(
    (success) => {
      setCallbackStatus({ type: "success" })},
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )



  return (
    <section className="sf__promo sf__block sf__mortgage">
      <div className="sf__promo-icon">
        <HelpIcon />
      </div>

      <div className="sf__promo-body sf__mortgage-body" style={sf ? {width: '100%'} : {}}>
        <div className="sf__mortgage-content">
          <p className="h3-l sf__promo-title">
            Нужна помощь?
          </p>
          <p className="sf__promo-timing sf__mortgage-timing">
            <span className="sf__mortgage-text text">
              {sf ? 'Ответим на любой вопрос' : 'Наши менеджеры помогут с любым вопросом'}
            </span>
          </p>
        </div>

        {
          sf ?
            <>
            <br/>
            {callbackStatus?.type === "success" && <NewAlert
              title="Заявка отправлена"
              text={`Ожидайте звонок. Мы свяжемся с Вами в рабочее время.`}
              bgColor="#DFF5E0"
              iconColor="#2CBA2E"
              sf={sf}
            />}
            {callbackStatus?.type === "error" && <NewAlert
              title="Ошибка"
              text={callbackStatus?.message}
              bgColor="#FFEAE9"
              iconColor="#FF2D23"
              sf={sf}
            />}
              {!(callbackStatus?.type === "success") && <SubmitButton
                type="button"
                className="btn h6 btn_primary btn_full "
                onClick={dubai ? callbackDubai : callback}
                // isLoading={isSending}
              >
                Перезвоните мне
              </SubmitButton>}
            </> :
            <Button
              isFull
              addClass="sf__mortgage-btn"
              onClick={openContactsModal}
            >
              Контакты
            </Button>
        }

      </div>
    </section>
  );
};

export default Help;
