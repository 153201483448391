import { combineReducers } from "redux";
import data from "./data";
import filter from "./filter";
import filterData from "./filterData";
import sort from "./sort";
import page from "./page";
import countView from "./count";
import view from "./view";

export default combineReducers({
  data,
  filter,
  filterData,
  sort,
  page,
  countView,
  view
});