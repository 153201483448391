import React, {useState} from "react";
import NewAlert from "../../components/core/NewAlert";
import SubmitButton from "../../components/SubmitButton";
import {getUrl} from "../../api";
import {
  SfFilterLinkIcon,
  SfFilterMailIcon,
  SfFilterTelegramIcon,
  SfFilterWhatsupIcon
} from "../../components/Public/markup/icons";
import {CopyToClipboard} from "react-copy-to-clipboard";

const AddCompare = ({download}) => {

  console.log(download)
  const [buildingSettings, setBuildingSettings] = useState(false)
  const [builderSettings, setBuilderSettings] = useState(false)
  const [priceSettings, setPriceingSettings] = useState(false)
  const [contactsSettings, setContactsSettings] = useState(false)

  return (
    <div>
      <div className="hp__form-item" style={{width: '100%'}}>
        <label className="input input_width_available input_type_form">
          <span className="input__label">Название подборки</span>
          <input
            className={'input__control'}
            style={{ background: "#f6f7f8", boxShadow: 'none' }}
            // value={eventTheme}
            // onChange={(e) => setEventTheme(e.target.value)}
            placeholder="Например, студии до 3 млн."
          />
        </label>
      </div>

      <div className="compare-view-settings" style={{marginBottom: 20}}>
        <h3 className="h3">Настройки отображение</h3>
        <ul className="compare-view-settings__items-list">
          <li>
            <label className="switch" htmlFor="buildingSettings">
              <span className="switch__label">
                Показать настройки ЖК
              </span>
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="buildingSettings"
                  onChange={() => {
                    setBuildingSettings(!buildingSettings)
                  }}
                  checked={buildingSettings}
                />
                <div className="switch__input" htmlFor="buildingSettings"/>
                <div className="switch__marker"/>
              </div>
            </label>
          </li>
          <li>
            <label className="switch" htmlFor="builderSettings">
              <span className="switch__label">
                Показать название застройщика
              </span>
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="builderSettings"
                  onChange={() => {
                    setBuilderSettings(!builderSettings)
                  }}
                  checked={builderSettings}
                />
                <div className="switch__input" htmlFor="builderSettings"/>
                <div className="switch__marker"/>
              </div>
            </label>
          </li>
          <li>
            <label className="switch" htmlFor="priceSettings">
              <span className="switch__label">
                Показать цены
              </span>
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="priceSettings"
                  onChange={() => {
                    setPriceingSettings(!priceSettings)
                  }}
                  checked={priceSettings}
                />
                <div className="switch__input" htmlFor="priceSettings"/>
                <div className="switch__marker"/>
              </div>
            </label>
          </li>
          <li>
            <label className="switch" htmlFor="contactsSettings">
              <span className="switch__label">
                Показать контакты
              </span>
              <div className="switch__box">
                <input
                  className="switch__control"
                  type="checkbox"
                  id="contactsSettings"
                  onChange={() => {
                    setContactsSettings(!contactsSettings)
                  }}
                  checked={contactsSettings}
                />
                <div className="switch__input" htmlFor="contactsSettings"/>
                <div className="switch__marker"/>
              </div>
            </label>
          </li>
        </ul>
      </div>

      {!download &&
        <>
          <div className="compare-sent-settings">
        <h3 className="h3">Как отправить?</h3>
        <ul className="sf-setting-links">

          <a className="sf-setting-links__item"
            // href={`https://wa.me/${phone.replace(/[+() \-_]/g, "").length === 11 ? phone.replace(/[+() \-_]/g, "") : ""}?text=${getUrl(savedFilter.url)}`}
            //  target="_blank"
             rel="noopener noreferrer"
          >
            <div className="sf-setting-links__icon">
              <SfFilterMailIcon />
            </div>
            <p className="sf-setting-links__text">
              По почте
            </p>
          </a>

          <a className="sf-setting-links__item"
             // href={`https://wa.me/${phone.replace(/[+() \-_]/g, "").length === 11 ? phone.replace(/[+() \-_]/g, "") : ""}?text=${getUrl(savedFilter.url)}`}
             target="_blank"
             rel="noopener noreferrer"
          >
            <div className="sf-setting-links__icon">
              <SfFilterWhatsupIcon />
            </div>
            <p className="sf-setting-links__text">
              WhatsApp
            </p>
          </a>
          <a
            className="sf-setting-links__item"
            // href={`https://t.me/share/url?url=${getUrl(savedFilter.url)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="sf-setting-links__icon">
              <SfFilterTelegramIcon />
            </div>
            <p className="sf-setting-links__text">
              Telegram
            </p>
          </a>
          {/*<CopyToClipboard text={getUrl(savedFilter.url)} onCopy={handleCopy}>*/}
          <CopyToClipboard>
            <li className="sf-setting-links__item">
              <div className="sf-setting-links__icon">
                <SfFilterLinkIcon />
              </div>
              <p className="sf-setting-links__text">
                Ссылкой
              </p>
            </li>
          </CopyToClipboard>
        </ul>
      </div>
          <div className="error-report__area" style={{marginTop: 20}}>
            <label
              className="textarea textarea_type_form"
              htmlFor="modal-reservation-commentary"
            >
              <span className="textarea__label">Текст сообщения</span>
              <textarea
                style={{minHeight: 87}}
                className="textarea__control"
                placeholder={'По желанию Вы можете добавить приветственное сообщение'}
                // onChange={(e) => setDescription(e.target.value)}
                // value={description}
              />
            </label>
          </div>
        </>
      }

      <SubmitButton
        className="btn h6 btn_primary "
        addStyle={{width: '100%'}}
        // isLoading={isLoading}
        // onClick={sendCallback}
      >
        Отправить
      </SubmitButton>
      <br/>
      <br/>
      <NewAlert
        // title={errorHint}
        title="Письмо отправлено"
        text="Если письмо не пришло, проверьте папку «Спам»."
        bgColor="#DFF5E0"
        iconColor="#2CBA2E"
      />




    </div>
  )
}

export default AddCompare
