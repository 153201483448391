const initState = {
  filterData: [],
  filter: [],
  banners: [
    {
      id: 1,
      link: '/trade-in',
      title: 'Trade-In',
      desc: 'Комиссия до 2,5% по нашей программе',
      icon: '#trade-in',
      hideClientMode: true,
      metrikaGoal: 'banners_grid_tradein',
    },
    {
      id: 2,
      link: 'https://panpartner.ru/pan-comfort/',
      external: true,
      title: 'ПАН комфорт',
      // desc: 'Сеть переговорных ПАН Партнер и Абсолют Банк',
      desc: 'Сеть переговорных ПАН и Абсолют Банк',
      // icon: "#banner-icon_2",
      icon: '#pan_comfort',
      hideClientMode: false,
      metrikaGoal: 'banners_grid_comfort',
    },
    {
      id: 3,
      link: '/pan-school',
      title: 'PAN School',
      desc: 'Готовим экспертов в сфере недвижимости',
      icon: '#pan-school',
      hideClientMode: false,
      metrikaGoal: 'banners_grid_school',
    },
    // {
    //   id: 3,
    //   modal: 'autopan',
    //   title: 'АвтоПАН',
    //   desc: 'Просмотры объектов за КАДом',
    //   icon: '#banner-icon_3',
    //   color: '#8269AD',
    //   hideClientMode: false,
    // },
  ],
};

const home = (state = initState, action) => {
  const { city, clientMode, theme, type } = action;
  switch (type) {
    case "SET_SWITCH_REF":
      return { ...state, refs: action.refs }
    default:
      return state;
  }
};

export default home;
