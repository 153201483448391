import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import useFileManager from "../../hooks/useFileManager";
import { appendFiles, toFormData } from "../../utils";
import { FileRow } from "../FileUploader";
import {
    ChatFooterAddIcon, ChatFooterFileIcon,
    ChatFooterImageIcon,
    ChatFooterSendIcon, ChatReplyCloseIcon
} from "../Public/markup/icons";
import './index.scss';
import { debounce, throttle, uniqBy } from "lodash";
import { errorToast } from "../toasts";

//Перенес как есть из файла index.js
const ChatBtn = ({ onClick }) => {
    return (
        <div className="chat-btn" onClick={onClick}>
            <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M34.2359 7.67005L32.8492 6.68713C27.8388 3.08309 23.51 0 19.5152 0V0.543874L19.4477 0C15.4866 0 11.1578 3.09947 6.14067 6.69041L4.76072 7.67333C0.826644 10.4681 0 13.5315 0 15.612V25.5199C0 30.1429 3.33351 32.5609 6.62315 32.5609H8.55309C9.92968 32.5609 11.5391 32.5609 12.4433 32.5609V35.8373C12.433 36.661 12.6773 37.4687 13.1447 38.1561C13.612 38.8436 14.2809 39.3791 15.0649 39.6936C15.8498 40.0082 16.7148 40.0827 17.5445 39.9071C18.3741 39.7314 19.1287 39.314 19.7075 38.7107L25.4433 32.787C25.5139 32.7139 25.5991 32.6557 25.6936 32.6157C25.7882 32.5757 25.8901 32.5548 25.9933 32.5543H32.4038C36.2266 32.5543 39 29.6056 39 25.5101V15.6088C39 13.5283 38.1734 10.4648 34.2427 7.67005M12.7942 28.8127H6.8256C4.21076 28.8127 4.00831 27.0794 4.00831 26.5454V15.0452C4.00831 13.8657 4.88556 12.6469 6.61642 11.4183C7.15288 11.0382 7.68596 10.6549 8.24266 10.2551C12.0047 7.56193 17.1534 3.87598 19.441 3.84649H19.5591C21.8095 3.84649 26.9818 7.54883 30.7674 10.2617L32.3903 11.4183C34.1178 12.6469 34.9951 13.8657 34.9951 15.0452V26.5552C34.9951 26.5782 34.8567 28.8225 32.6333 28.8225H25.4163C24.9549 28.8199 24.4979 28.9099 24.0741 29.0869C23.6502 29.264 23.2686 29.5242 22.9533 29.8513L16.6776 36.3517C16.6359 36.396 16.5811 36.4267 16.5207 36.4396C16.4603 36.4525 16.3973 36.4469 16.3402 36.4238C16.2835 36.4033 16.2347 36.366 16.2008 36.3172C16.1669 36.2685 16.1496 36.2106 16.1513 36.1518V32.0825C16.1513 31.2136 15.7958 30.3802 15.1631 29.7658C14.5304 29.1513 13.6722 28.8061 12.7773 28.8061" fill="#FF2D23" />
                <path d="M12.6115 17.288H13.8035V18.92C13.8035 19.0427 13.8062 19.136 13.8115 19.2C13.8168 19.264 13.8408 19.3467 13.8835 19.448C13.9262 19.544 13.9848 19.6187 14.0595 19.672C14.1395 19.72 14.2568 19.7653 14.4115 19.808C14.5715 19.8453 14.7662 19.864 14.9955 19.864C15.3475 19.864 15.6755 19.8213 15.9795 19.736V17.288H17.1715V23H15.9795V20.792C15.5902 20.8827 15.1662 20.928 14.7075 20.928C14.3822 20.928 14.0968 20.9067 13.8515 20.864C13.6115 20.816 13.4168 20.7467 13.2675 20.656C13.1182 20.56 12.9955 20.4613 12.8995 20.36C12.8088 20.2587 12.7422 20.1307 12.6995 19.976C12.6622 19.816 12.6382 19.6773 12.6275 19.56C12.6168 19.4427 12.6115 19.2907 12.6115 19.104V17.288ZM20.9481 23V22.648H20.7401C20.4788 22.952 20.0948 23.104 19.5881 23.104C19.1401 23.104 18.7855 22.9733 18.5241 22.712C18.2628 22.4453 18.1321 22.12 18.1321 21.736C18.1321 21.368 18.2708 21.048 18.5481 20.776C18.8255 20.504 19.2441 20.368 19.8041 20.368H20.9001V20.264C20.9001 19.8373 20.6575 19.624 20.1721 19.624C19.7401 19.624 19.5081 19.7707 19.4761 20.064H18.3641C18.3748 19.6747 18.5428 19.3653 18.8681 19.136C19.1935 18.9013 19.6095 18.784 20.1161 18.784C20.7028 18.784 21.1588 18.9093 21.4841 19.16C21.8148 19.4107 21.9801 19.8 21.9801 20.328V23H20.9481ZM19.9801 22.216C20.2521 22.216 20.4735 22.136 20.6441 21.976C20.8148 21.816 20.9001 21.608 20.9001 21.352V21.096H20.0201C19.5081 21.096 19.2521 21.28 19.2521 21.648C19.2521 22.0267 19.4948 22.216 19.9801 22.216ZM22.7556 19.824V18.888H26.5716V19.824H25.2276V23H24.1076V19.824H22.7556Z" fill="#FF2D23" />
            </svg>
        </div>
    )
};

const AutoResizeTextarea = memo(props => {
    const textareaRef = useRef(null);

    useEffect(() => {
        const adjustHeight = (element) => {
            element.style.height = 'inherit';
            element.style.height = `${element.scrollHeight}px`;
        };

        const handleInput = (event) => {
            adjustHeight(event.target);
        };

        const textarea = textareaRef.current;

        if (textarea) {
            textarea.addEventListener('input', handleInput);
            const len = textarea.value.length
            textarea.focus()
            textarea.setSelectionRange(len, len)
            adjustHeight(textarea);

            return () => {
                textarea.removeEventListener('input', handleInput);
            };
        }
    }, [props]);

    return (
        <textarea
            ref={ref => {
                textareaRef.current = ref
                props.inputRef.current = ref
            }}
            className="open-chat__message-field"
            placeholder="Сообщение"
            {...props}
        ></textarea>
    );
}, (prev, next) => prev.value === next.value);

const Accordion = memo(({ number, title, children, Icon }) => {
    const defaultState = () => number === 0;
    const [isOpened, setIsOpened] = useState(defaultState);
    const contentRef = useRef(null);

    useEffect(() => {
        const content = contentRef.current;
        if (content) {
            if (isOpened) {
                content.style.maxHeight = `${content.scrollHeight}px`;
            } else {
                content.style.maxHeight = '0px';
            }
        }
    }, [isOpened]);

    return (
        <div className="chat-accordion">
            <div className="chat-accordion__header" onClick={() => setIsOpened(prevState => !prevState)}>
                <span className="chat-accordion__title">
                    {Icon && <Icon />}
                    {title}
                </span>
                <button
                    type="button"
                    className={`chat-accordion__btn${isOpened ? ` opened` : ``}`}
                    aria-label="Открыть панель">
                    <svg className="icon icon_more">
                        <use xlinkHref="#chevron"></use>
                    </svg>
                </button>
            </div>
            <div ref={contentRef} className={`chat-accordion__body${isOpened ? ` opened` : ``}`}>
                {children}
            </div>
        </div>
    );
});

const CustomSelect = memo(({ options, placeholder, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const ref = useRef(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange) {
            onChange(option);
        }
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setSelectedOption({ value: 'option1', label: 'Все сообщения' })
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="new-custom-select" ref={ref}>
            <button type="button" className={`new-custom-select__button ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
                {selectedOption ? selectedOption.label : placeholder}
                <svg className="icon icon_more new-custom-select__arrow">
                    <use xlinkHref="#chevron"></use>
                </svg>

            </button>
            {isOpen && (
                <ul className="new-custom-select__list">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="new-custom-select__option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});


const tabs = [
    { title: 'Личные' },
    { title: 'Сделки' },
    { title: 'Фиксации' },
];

const itemsByTab = ["members", "streams", "members"]

const extractMesages = ({ resp }, params, state) => {
    const { result, error_description } = resp || {};

    if (error_description) errorToast(`getMessages: ${error_description}`);
    if (!result) return;

    const { messages, users, files } = result;

    const enrichedMessages = messages.map((message) => ({
        ...message,
        files: (message.params?.FILE_ID || []).map((fileId) =>
            files.find((file) => file.id == fileId)
        ),
        author: users?.find((user) => user.id === message.author_id) || null,
        unread: message.unread,
        viewed: message.viewed,
        viewedByOthers: message.viewedByOthers,
    }));

    return {
        allMessages: uniqBy([...state.allMessages, ...enrichedMessages], "id"),
        currentChat: {
            ...state.currentChat,
            lastMessage: enrichedMessages[enrichedMessages.length - 1],
        },
    };
}

const mapStateByMethod = {
    "getProfile": resp => ({ user: resp.profile }),
    "getUsersList": resp => ({ members: resp.users?.members }),
    "getChannels": resp => ({ members: resp.resp.result ?? [] }),
    "getMessages": ({ resp }, params, state) => {
        const { result, error_description } = resp || {};

        if (error_description) errorToast(`getMessages: ${error_description}`);
        if (!result) return;

        const { messages, users, files } = result;

        const enrichedMessages = messages.map((message) => ({
            ...message,
            files: (message.params?.FILE_ID || []).map((fileId) =>
                files.find((file) => file.id == fileId)
            ),
            author: users?.find((user) => user.id === message.author_id) || null,
            unread: message.unread,
            viewed: message.viewed,
            viewedByOthers: message.viewedByOthers,
        }));

        return {
            allMessages: uniqBy([...state.allMessages, ...enrichedMessages], "id"),
            currentChat: {
                ...state.currentChat,
                lastMessage: enrichedMessages[enrichedMessages.length - 1],
            },
        };
    },
    "registerQueue": resp => ({ queueId: resp.queueId, lastEventId: resp.lastEventId }),
    "updatePresence": resp => ({ presences: resp?.result?.presences })
}

const ChatFooter = memo(({ state, stateRef, setValue, setValues, send, addFile, setTyping, disabled, reply, inputRef, uploadFiles, deleteFile, files }) => {
    const { messagesDump, currentChat, showAddList, editingMessages, cancelEdit } = state;
    const isEditing = !!editingMessages?.[currentChat.requestID];
    const editingKey = isEditing ? "editingMessages" : "messagesDump";

    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);


    const debouncedSetTyping = useCallback(debounce((type = "start") => setTyping({ dialogId: currentChat.type === "chat" ? currentChat.id : currentChat.chat_id }), 600), [currentChat]);

    const onChange = e => {
        debouncedSetTyping(e.target.value ? "start" : "stop");
        const val = e.target.value;
        setValues(prevState => ({
            [editingKey]: {
                ...prevState[editingKey],
                [currentChat?.requestID]: isEditing ? { ...prevState[editingKey][currentChat.requestID], text: val } : val
            }
        }));
    };

    const handleDrop = useCallback(event => {
        event.preventDefault();
        setIsDragging(false);
        const files = Array.from(event.dataTransfer.files);
        uploadFiles({ target: { files } });
    }, [uploadFiles]);

    const handleDragOver = useCallback(event => {
        event.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsDragging(false);
    }, []);

    return (
        <div
            style={{ display: "flex", flexDirection: "column", position: "relative" }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            {isDragging && (
                <div className="drag-overlay">
                    <p>Отпустите файлы здесь для загрузки</p>
                </div>
            )}
            {!!files.length && (
                <div className="open-chat__footer" style={{ display: "flex", flexDirection: "column", marginBottom: 0 }}>
                    {files.map(file => (
                        <FileRow
                            key={file.fileObj.fakeId}
                            name={file.fileName}
                            del={() => deleteFile(null, null, file.fileObj.fakeId)}
                        />
                    ))}
                </div>
            )}
            {currentChat.isTyping && !currentChat.isSavedMessage && (
                <div style={{ margin: "0 20px 4px 20px", opacity: 0.5 }}>
                    {currentChat.full_name} пишет...
                </div>
            )}
            {reply && (
                <div className="open-chat__footer open-chat__footer_answer">
                    <b>Ответ</b>
                    <p dangerouslySetInnerHTML={{ __html: reply.text }} />
                    <span className="open-chat__reply-close" onClick={cancelEdit}>
                        <ChatReplyCloseIcon />
                    </span>
                </div>
            )}
            {isEditing && (
                <div className="open-chat__footer open-chat__footer_answer">
                    <b>Редактирование сообщения</b>
                    <p dangerouslySetInnerHTML={{ __html: editingMessages?.[currentChat.requestID].content }} />
                    <span className="open-chat__reply-close" onClick={() => setValue("reply", null)}>
                        <ChatReplyCloseIcon />
                    </span>
                </div>
            )}
            <div className="open-chat__footer">
                <span className="open-chat__add-icon" onMouseEnter={() => {
                    setValue("showAddList", true);
                    setTimeout(() => stateRef.current.showAddList && setValue("showAddList", false), 10000);
                }}>
                    <span className="open-chat__add-add" onClick={() => setValue("showAddList", !showAddList)}>
                        <ChatFooterAddIcon />
                    </span>
                    {showAddList && (
                        <ul className="open-chat__add-list" onMouseLeave={() => stateRef.current.showAddList && setValue("showAddList", false)}>
                            <input
                                className="input__control input__hidden"
                                type="file"
                                multiple
                                onChange={e => uploadFiles(e)}
                                ref={fileInputRef}
                                key={files?.length}
                                onBlur={() => debouncedSetTyping("stop")}
                            />
                            <li onClick={() => !disabled && fileInputRef.current.click()}>
                                <ChatFooterImageIcon />Фото или видео
                            </li>
                            <li onClick={() => !disabled && fileInputRef.current.click()}>
                                <ChatFooterFileIcon />Файл
                            </li>
                        </ul>
                    )}
                </span>
                <AutoResizeTextarea
                    onChange={onChange}
                    value={(isEditing ? state[editingKey][currentChat.requestID]?.text : state[editingKey][currentChat.requestID]) || ""}
                    key={currentChat?.requestID}
                    onKeyPress={e => {
                        const { shiftKey, key } = e.nativeEvent;
                        if (key === "Enter" && !state[editingKey][currentChat.requestID]) e.preventDefault();
                        if (key === "Enter" && currentChat && state[editingKey][currentChat.requestID] && !shiftKey) {
                            e.preventDefault();
                            send();
                            debouncedSetTyping("stop");
                        }
                    }}
                    disabled={disabled}
                    inputRef={inputRef}
                />
                <span className="open-chat__send-icon" onClick={() => send()}>
                    <ChatFooterSendIcon />
                </span>
            </div>
        </div>
    );
});


export { Accordion, AutoResizeTextarea, ChatBtn, ChatFooter, CustomSelect, itemsByTab, mapStateByMethod, tabs, extractMesages };