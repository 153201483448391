import Slider from './Slider';
import Tabs from '../../../core/Tabs';
import { memo } from 'react';

const Furnish = memo(({ items, complexTitle, defoultThumbs }) => !!items?.length ?
  <section class="sf__furnish sf__section">
    <Tabs title={'Отделка'} complexTitle={complexTitle}>
      {items.map(({ title, items }) => <div label={title}>
        <Slider defoultThumbs={defoultThumbs} items={items} />
      </div>)}
    </Tabs>
  </section>
  : null
)

export default Furnish;
