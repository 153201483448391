const initState = {
  blocks: [],
  rooms: [],
  districts: [],
  builders: [],
  endings: []
};

const filterData = (state = initState, action) => {
  const {type, filter} = action;
  switch (type) {
    case "SET_FILTER_DATA":
      return { ...state, ...filter };
    default:
      return state;
  }
};

export default filterData;
