import formatter from "format-number";
import { chunk, take } from "lodash";
import React, { useEffect, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { addToFavorites, removeFromFavorites } from "../../actions/favorites";
import { getSuffix, media } from '../../utils';
import LoadMoreSpinner from "../LoadMoreSpinner";

const priceFormat = (price) => {
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};

const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 0);
  });

const getLink = (alternativeLink, savedFilterXmlId, fromPage, id) => {
  if (alternativeLink === "secondary") {
    return `/public/${alternativeLink}/${savedFilterXmlId}/${id}`
  }
  if (alternativeLink) {
    return `/public/${alternativeLink}/${savedFilterXmlId}/spb/${id}`
  }
  switch (fromPage) {
    case "commercials":
      return `/commercial/${id}`
    case "secondaries":
      return `/secondary/${id}`
    default:
      return `/assignments/${id}`
  }
}

const MapItem = (props) => {
  const {
    addToFavorites,
    alternativeLink,
    disableFavorites,
    fromPage,
    removeFav,
    setMapCenter,

    id,
    roomType,
    builder,
    coordinates,
    favorite,
    squareTotal,
    costWithDiscount,
    plan,
    block,
    region,
    address,
    endingDate,
    subway
  } = props;
  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFav("spb", id)
      : addToFavorites("spb", id);
  };
  const { savedFilterXmlId } = useParams()

  return (
    <li
      className="map-list__item map-list__flat"
      onClick={() => setMapCenter(coordinates)}
    >
      <div className="flats__card">
        <div className="flats__card-image">
          <img
            src={`${plan
              ? plan.src
              : "https://dev2.panpartner.ru/upload/emptyPlan.svg"
              }`}
            alt={``}
          />
          {!disableFavorites && (
            <div className="flats__card-top">
              <div className="flats__card-status"></div>
              <div
                className={`card__fav js-favorites-add ${favorite.inFavorite ? "is-fav" : ""
                  }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavorites(favorite.inFavorite);
                }}
              >
                <div className="card__fav-add">
                  <svg className="icon icon_fav">
                    <use xlinkHref="#fav"></use>
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flats__card-info">
          {fromPage != "commercials" && <div className="flats__card-price">
            {roomType && <span>{roomType} | </span>}
            {squareTotal? `${squareTotal} м² |`: ""} {priceFormat(costWithDiscount)}
          </div>}
          {fromPage === "commercials" && <div className="flats__card-price">
            {<span>Помещение | </span>}
            {squareTotal} м² | {priceFormat(costWithDiscount)}
          </div>}
          <div className="card__row card__row_distribute_between card__mt-10">
            <div className="card__place">
              <div className="card__title">{block?.name}</div>
              <div className="card__address">{region?.name? region?.name + ", ": ""}{address ? address : ""}</div>
            </div>
            <div className="card__dates">
              <div className="card__dates-from">{endingDate}</div>
              <div className="card__dates-to"></div>
            </div>
          </div>
          {builder?.name && <div className="card__company card__mt-5">Застройщик: {builder?.name}</div>}
          <ul className="card__routes">
            {subway &&
              subway.map((subway, i) => {
                if (!subway) {
                  return;
                }
                return (
                  <li className="card__routes-item" key={subway.name + i}>
                    <span
                      className="card__metro-color card__metro-color_1"
                      style={{ backgroundColor: subway.color }}
                    ></span>
                    <span className="card__metro-station">
                      м. {subway.name}
                    </span>
                    {subway.distanceType && <span className="card__route-type">
                      <svg
                        className={`icon icon_${subway.distanceType === "byFoot"
                          ? "pedestrian"
                          : "auto"
                          }`}
                      >
                        <use
                          xlinkHref={`#${subway.distanceType === "byFoot"
                            ? "pedestrian"
                            : "auto"
                            }`}
                        ></use>
                      </svg>
                    </span>}
                    {subway.minutes && <span className="card__route-time">
                      {subway.minutes} мин.
                    </span>}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {!media("isMobile") &&
        <Link to={getLink(alternativeLink, savedFilterXmlId, fromPage, id)} target="_blank">
          <button className="map-list__btn button button_view_default">Подробнее</button>
        </Link>
      }
    </li>
  );
};

const MapList = (props) => {
  const {
    items,
    removeFromFavorites,
    addToFavorites,
    fromPage,
    alternativeLink,
  } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { savedFilterXmlId } = useParams()

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage(p * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    threshold: 20,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  useEffect(() => {
    setPage(0);
    setData(take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  return (
    <div className="map__list">
      <div className="wrapper wrapper_fluid">
        <div className="cards__map-cards">
          {!media("isMobile") && <h3 className="map-list-title">{items.length} {`объект${getSuffix(items.length)}`}</h3>}
          <SimpleBar className="map-list">
            <div ref={infiniteRef}>
              <ul className="map-list__list for-assignations">
                {data &&
                  data.map((item) => {
                    return (
                      media("isMobile") ?
                        <Link
                          to={getLink(alternativeLink, savedFilterXmlId, fromPage, item.id)}
                          target="_blank"
                          className="map-list__link"
                        >
                          <MapItem
                            key={item.id}
                            {...item}
                            setMapCenter={props.setMapCenter}
                            removeFav={removeFromFavorites}
                            addToFavorites={addToFavorites}
                            disableFavorites={props.disableFavorites}
                            alternativeLink={props.alternativeLink}
                            fromPage={fromPage}
                          />
                        </Link> :
                        <MapItem
                          key={item.id}
                          {...item}
                          setMapCenter={props.setMapCenter}
                          removeFav={removeFromFavorites}
                          addToFavorites={addToFavorites}
                          disableFavorites={props.disableFavorites}
                          alternativeLink={props.alternativeLink}
                          fromPage={fromPage}
                        />
                    )
                  })}
              </ul>
            </div>
            {loading && <LoadMoreSpinner loading={true} />}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const page = ownProps.fromPage
  const type = ownProps.favType
  return {
    addToFavorites: (city, id) =>
      dispatch(addToFavorites(city, id, type, page)),
    removeFromFavorites: (city, id) =>
      dispatch(removeFromFavorites(city, id, type, page)),
  }
};

export default connect(null, mapDispatchToProps)(MapList);
