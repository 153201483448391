import React from "react";
import { connect } from "react-redux";
import { setClientMode } from "../../actions/general";

const ClientMode = props => {

  const { clientMode, changeMode } = props;

  const handleChange = e => {
    const { checked } = e.target;
    changeMode(checked);
  }
  return (
    <>
      {
        !props.new &&
        <div className="switch header-bottom__client-switch">
          <label className="switch__label color-brand-3" htmlFor="client-work">
            Работа с клиентом
          </label>
          <div className="switch__box">
            <input className="switch__control" checked={clientMode} onChange={handleChange} type="checkbox" id="client-work" />
            <label className="switch__input" htmlFor="client-work"/>
            <div className="switch__marker"/>
          </div>
        </div>
      }
      {
        props.new &&
        <div className="menu-client-mode">

          <div className="menu-client-mode__info">
            <p className="menu-client-mode__title">Режим работы с клиентом</p>
            <p className="menu-client-mode__text">Скрывает информацию о мотивации</p>
          </div>

          <div className="switch">
            <div className="switch__box">
              <input className="switch__control" checked={clientMode} onChange={handleChange} type="checkbox" id="client-work" />
              <label className="switch__input" htmlFor="client-work"/>
              <div className="switch__marker"/>
            </div>
          </div>

        </div>
      }
    </>
  );
};

const mapStateToProps = state => ({
  clientMode: state.general.clientMode
});

const mapDispatchToProps = dispatch => ({
  changeMode: clientMode => dispatch(setClientMode({ clientMode }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientMode);
