import { useEffect, useState } from "react";

const useTimer = timeout => {
  const [time, setTime] = useState(timeout);
  const [timerIsStarted, setTimerIsStarted] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (time !== 0) return;
    setTime(60);
    setTimerIsStarted(false);
    clearInterval(timer);
  }, [time]);

  const runTimer = () => {
    setTimerIsStarted(true);
    setTimer(setInterval(() => setTime(time => time - 1), 1000));
  };

  return { timeout: time, timerIsStarted, runTimer, setTimerIsStarted, setTime };
};

export default useTimer;