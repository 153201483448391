import { useState } from "react";
import useMedia from "../../../../../hooks/useMedia";
import { formatMoney } from "../../../../../utils";
import PriceTitle from "../../../../PriceTitle";
import SquareTitle from "../../../../SquareTitle";
import TableApartments from "./TableApartments";
import LoadMoreSpinner from "../../../../LoadMoreSpinner";
import { memo } from "react";
import { useEffect } from "react";
import { CardsApartments } from "../../../../Complex/ApartmentList2";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import take from 'lodash/take'
import chunk from 'lodash/chunk'

const ApartmentRow = memo(props => {
    const {
        roomTitle,
        count,
        minSquareTotal,
        maxSquareTotal,
        minCostWithDiscount,
        maxCostWithDiscount,
        view,
        items,
        isPublic,
        savedFilterXmlId,
        onClick,
        minMeterPrice,
        loadItems,
        roomTypeId,
        buildingId,
        columns,
        showSkeleton,
        tableProps,
        city,
        quickViewEnabled,
        defaultTitle,
        columnsType,
        parkingTypeId
    } = props;
    const len = items?.length
// console.log(props)
    const isLaptop = useMedia("isLaptop");
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (!items?.length) return setActive(false)
    }, [items])

    const load = () => {
        setIsLoading(true)
        loadItems({
            ...(buildingId ? { "filter[buildings]": buildingId } : []),
            ...(roomTypeId ? { "filter[rooms]": roomTypeId } : []),
            ...(parkingTypeId ? { "filter[parkingType]": parkingTypeId } : []),
            isAssignment: true
        }, ["onlyTypes", "isGetApartments", "id", "filter[buildings][]"])
            .finally(setIsLoading)
    }
    const clickHandler = () => {
        setActive(prev => !prev);
        onClick?.();
        if (items?.length > 0) return
        load()
    }


    //паста
    const [page, setPage] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setPage(0);
        setData(take(items, 15));
        setHasNextPage(len > 15);
    }, [items]);

    function handleLoadMore() {
        setLoading(true);
        let p = page + 1;
        const newData = len > page * 15 ? chunk(items, 15)[page] : []
        setData([...data, ...newData]);
        setPage(p);
        setHasNextPage((p + 1) * 15 < len);
        setLoading(false);
    }
    const infiniteRef = useInfiniteScroll({
        loading: loading || !active,
        hasNextPage,
        threshold: 50,
        onLoadMore: handleLoadMore,
        scrollContainer: "window",
    });
    // конец пасты
    return <div className={`complex__appartments-tab active`}>
        <div className={`complex__appartments-row  ${showSkeleton ? "skeleton-box" : ""}`}>
            <div
                className={`flats__row ${active ? `is-active` : ``}`}
                onClick={clickHandler}
            >
                <div className="flats__row-col flats__row-rooms">{roomTitle}</div>
                <div className="flats__row-concat">
                    {!isLaptop && <div className="flats__row-col flats__row-count">{count}</div>}
                    <div className="flats__row-col flats__row-square">
                        <SquareTitle
                            minSquare={minSquareTotal}
                            maxSquare={maxSquareTotal}
                        />
                    </div>
                    <div className="flats__row-col flats__row-price">
                        <PriceTitle
                            maxPrice={maxCostWithDiscount}
                            minPrice={minCostWithDiscount}
                        />
                    </div>
                    <div className="flats__row-col flats__row-price">
                        от {formatMoney(minMeterPrice)} тыс. руб. / м²
                    </div>
                </div>
                <div className="flats__row-col flats__row-action">
                    <button className="button button_view_round button_with_icon">
                        <svg className="icon icon_collapse">
                            <use xlinkHref="#arrow_bottom"></use>
                        </svg>
                    </button>
                </div>
            </div>
            {view === 0 && <TableApartments
                items={items}
                active={active}
                isPublic={isPublic}
                columns={columns}
                savedFilterXmlId={savedFilterXmlId}
                tableProps={tableProps}
                city={city}
                quickViewEnabled={quickViewEnabled}
                defaultTitle={defaultTitle}
                columnsType={columnsType}
            />}
            {active && view === 1 && <CardsApartments
                items={data}
                expand={active}
                isPublic={isPublic}
                savedFilterXmlId={savedFilterXmlId}
                innerRef={infiniteRef}
                loading={loading}
                city={city}
                quickViewEnabled={quickViewEnabled}
                defaultTitle={defaultTitle}
                columnsType={columnsType}
            />}
          <LoadMoreSpinner loading={isLoading} styles="padding: 13.5px 0" />
        </div>
    </div>
});

export default ApartmentRow
