import React, { memo, useMemo, useRef, useState } from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import useMedia from '../../hooks/useMedia';
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import ShowroomTooltip from '../ShowroomTooltip/index.js';

export const PhotoPopup = memo(props => {
    const { items, index = 0, open, onClose, contentByIndex, setIndex } = props;

    const options = useMemo(() => {
        return {
            index: index === 0 ? 0 : index - 1,
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            history: false,
            maxSpreadZoom: 4,
            getDoubleTapZoom: (isMouseClick,  item) =>{
                if(isMouseClick) {
                    return 1.5;
                
                } else {
                    return item.initialZoomLevel < 0.7 ? 1 : 1.5;
                }
            }
        };
    }, [index]);
    const photos = useMemo(
        () =>
            items?.map?.((item) => ({
                src: getUrl(item.src),
                w: !!item.width ? item.width : 1600,
                h: !!item.height ? item.height : 900,
            })),
        [items]
    );

    //фу-фу-фу :()
    const afterChange = (ref) => {
        if(!ref || !setIndex) return
        const index = ref.getCurrentIndex()
        if (contentByIndex[index + 1]) setIndex(index + 1)
    }
    const nextSlide = (e) => setIndex(prev => prev >= photos.length ? 0 : prev)
    const prevSlide = (e) => setIndex(prev => prev - 1)

    if (!items) return null

    return (
        <>
            {open && contentByIndex?.[index] && <div style={{
                position: "fixed",
                overflowY: "auto",
                zIndex: 9999,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: "white"
            }}>
                <div class="pswp__ui pswp__ui--fit pswp__ui--over-close">
                    {contentByIndex?.[index]}
                    <div class="pswp__top-bar">
                        <div class="pswp__counter">{index} / {photos.length}</div>
                        <button class="pswp__button pswp__button--close" title="Close (Esc)" onClick={onClose}>
                        </button><div class="pswp__preloader">
                            <div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut">
                            </div></div>
                            </div>
                        </div>
                    </div>

                    <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div class="pswp__share-tooltip">

                        </div>
                    </div>
                    <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)" onClick={prevSlide}>
                    </button>
                    <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)" onClick={nextSlide}></button>
                    <div class="pswp__caption pswp__caption--fake">
                        <div class="pswp__caption__center"></div></div>
                    <div class="pswp__caption pswp__caption--empty"><div class="pswp__caption__center">

                    </div>
                    </div>
                </div>

            </div>}
            {open && !contentByIndex?.[index] && <PhotoSwipe
                isOpen={open}
                items={photos}
                options={options}
                onClose={onClose}
                afterChange={afterChange}
            />}
        </>
    );
});

const sliderSettings = {
    items: 1,
    controls: false,
    navAsThumbnails: true,
    nav: false
}

const PhotoSlider = memo((props) => {

    const {
        photos,
        current,
        blurBg,
        statusSold,
        onPhotoOpen,
        onPhotoClosed,
        activeTabData
    } = props;

    const isMobile = useMedia("isMobile")
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(1);

    const node = useRef(null);

    const handleOpen = () => {
        setOpen(true);
        return onPhotoOpen?.();
    };

    const handleClose = () => {
        setOpen(false);
        return onPhotoClosed?.();
    };

    const handleChangeIndex = info => setIndex(info.displayIndex);

    const goToPrev = () => node.current.slider.goTo("prev");

    const goToNext = () => node.current.slider.goTo("next");


    return (
        <ErrorPlug wrappedComponent="src/comp-ts/Viewer.js">
            {current == "tour" && <ShowroomTooltip />}
            <div className={`complex__slider-box`}>
                <div
                    className={`complex__slider-wrapper gallery__wrapper${statusSold ? ` status-sold` : ``}`}
                    id="complex-slider-photos"
                >
                    <TinySlider
                        settings={sliderSettings}
                        ref={node}
                        onIndexChanged={handleChangeIndex}
                    >
                        {photos.map((photo, i) => (
                            <div
                                className="complex__slider-item gallery__item"
                                key={i}
                                onClick={() => handleOpen()}
                            >
                                {blurBg ? <div className="complex__slider-blur-bg" style={{ backgroundImage: `url("${getUrl(photo.src)}")` }}></div> : ``}
                                <img src={getUrl(photo.src)} alt="" />
                            </div>
                        ))}
                    </TinySlider>
                </div>


                {photos?.length > 1 &&  <div className="complex__slider-preview" style={{ maxWidth: '100%', width: 500 }}>
                    <ul className="complex__slider-thumbnails">
                        {photos.map((photo, i) => (
                            <li className={`complex__slider-thumbnails_item ${i + 1 === index ? "tns-nav-active" : ""}`} key={i} onClick={() => node.current.slider.goTo(i)}>
                                {isMobile
                                    ? <span aria-controls="base" />
                                    : <img src={getUrl(photo.src)} alt="" />
                                }
                            </li>
                        ))}
                    </ul>
                </div>}

                {photos?.length > 1 && <ul className="complex__slider-controls">
                    <li className="prev" onClick={goToPrev}>
                        <svg className="icon icon-white_stroke">
                            <use xlinkHref="#icon-left_arrow"></use>
                        </svg>
                    </li>
                    <li className="next" onClick={goToNext}>
                        <svg className="icon icon-white_stroke">
                            <use xlinkHref="#icon-right_arrow"></use>
                        </svg>
                    </li>
                </ul>}
                <div className="complex__slider-magnifier" onClick={() => handleOpen()}>
                    <svg className="icon icon_search">
                        <use xlinkHref="#search"></use>
                    </svg>
                </div>
                {isMobile &&
                    <span className="complex__slider-info">
                        {index} / {photos.length}
                    </span>
                }
            </div>
            <PhotoPopup
                open={open}
                items={photos}
                index={index}
                onClose={handleClose}
            />
        </ErrorPlug>
    );
});
export default PhotoSlider
