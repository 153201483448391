
export const team = [
  {
    id: 1,
    name: `Дмитрий Соловьев`,
    pos: `Специалист по продаже вторичного жилья`,
    exp: `Стаж 8 лет`,
  },

  {
    id: 2,
    name: `Александра Счастливая`,
    pos: `Специалист по продаже вторичного жилья`,
    exp: `Стаж 7 лет`,
  },

  {
    id: 3,
    name: `Руслан Шейко`,
    pos: `Специалист по продаже вторичного жилья`,
    exp: `Стаж 12 лет`,
  },


  {
    id: 4,
    name: `Ольга Арсланова`,
    pos: `Директор департамента вторичного жилья`,
    exp: `Стаж 15 лет`,
    withGradient: true,
    setSize: true,
  },

  {
    id: 5,
    name: `Анастасия Снопова`,
    pos: `Специалист по продаже вторичного жилья`,
    exp: `Стаж 13 лет`,
    withGradient: true,
  },

];


export const slides = [
  {
    id: 1,
    name: `ОПТИ 120`,
  },
  {
    id: 2,
    name: `МАКСИ 190`,
  },
  {
    id: 3,
    name: `AXIOM 90`,
  },
  {
    id: 4,
    src: `#`,
    name: `BASIS 120-4`,
  },
  {
    id: 5,
    name: `CONSTANT 90`,
  },
  {
    id: 6,
    name: `SIMPLEX 100-2`,
  },
  {
    id: 7,
    name: `SIMPLEX 100-3`,
  },
  {
    id: 8,
    name: `AXIOM 120-2`,
  },
];

export const slidesLove = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const diplomas = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
];