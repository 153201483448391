import React, { memo, useState } from 'react';
import Avatar from "react-avatar";
import { ChatFooterFileIcon } from "../Public/markup/icons";
import { CheckboxControlled } from "../core/Checkbox";

const ChatItem = memo(props => {
  const { isActive, userName, lastMessageTime, message, unreadMessages, logo, isFile, isImage, sortered, onClick, isOnline, id, selected, selectItem } = props;

  return (
    <li className={isActive ? 'chat-item active-chat' : 'chat-item'} onClick={onClick} id={`chat-item-${id}`}>
      <div className="chat-item__overlogo">
        {sortered &&
          <div className="chat-item__sortered-checkbox">
            <CheckboxControlled
              id={Math.random().toString(36).substr(2, 9) + '-' + Date.now()}
              checked={selected}
              onChange={selectItem}
              view="red"
            />
          </div>
        }
        <div className="chat-item__logo-stub">
          {
            logo ? <img src={logo} className="chat-item__logo" /> :
              <Avatar
                name={userName}
                size="48"
                round={true}
                textSizeRatio={2.857}
              />
          }

        </div>
        {isOnline && <div className="open-chat__user-status open-chat__user-status_active" />}
      </div>
      <div className="chat-item__info-block">
        <div className="chat-item__top-info">
          <div className="chat-item__name">{userName}</div>
          <div className="chat-item__time">{lastMessageTime}</div>
        </div>
        <div className="chat-item__bottom-info">
          {!!message && <div className="chat-item__message" dangerouslySetInnerHTML={{ __html: message }} />}
          {isFile && <div className="chat-item__isfile"><span className="chat-item__isfile-icon"><ChatFooterFileIcon /></span><span className="chat-item__isfile-name">{isFile}</span></div>}
          {!!isImage?.src && <div className="chat-item__isfile"><span className="chat-item__isimage-logo"><img src={isImage.src} alt="" /></span><span className="chat-item__isfile-name">{isImage.text}</span></div>}
          {!!unreadMessages && <div className="chat-item__message-count">{unreadMessages}</div>}
        </div>
      </div>
    </li>
  )
})

export default ChatItem;