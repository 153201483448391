import React, { useEffect, useState } from "react";
import { confirmRequest } from "../../actions/confirmation";
import useQuery from "../../hooks/useQuery";

const Confirmation = (props) => {
  const { type } = props;
  const query = useQuery();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    let params = {};
    for (var pair of query.entries()) {
      params[pair[0]] = pair[1];
    }

    setSuccess(false);
    setError(false);

    confirmRequest(params, type).then(
      (data) => {
        setSuccess(true);
        setSuccessMessage(data.message);
      },
      (errorMessage) => {
        setError(true);
        setErrorMessage(errorMessage);
      }
    );
  }, []);

  return (
    <>
      {error && (
        <div className="form__error-text">
          {errorMessage || `Неверный логин пользователя или пароль`}
        </div>
      )}
      {success && (
        <div className="form__info-text">
          {successMessage || `Неверный логин пользователя или пароль`}
        </div>
      )}
    </>
  );
};

export default Confirmation;
