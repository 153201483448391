import React from 'react';
import Avatar from "react-avatar";

const UserInChatItem = ({name, post, phone, logo}) => {
  return (
    <li className="chat-users__list-item">
      <div className="chat-users__user">
        <div className="chat-users__user-logo">
          {logo ?
            <img className="chat-users__user-img" src={logo} alt=""/> :
          <Avatar
            name={name}
            size="48"
            round={true}
            textSizeRatio={2.857}
          />
          }
          <div className="chat-users__user-status chat-users__user-status_active"/>
        </div>
        <div className="chat-users__user-info">
          <p className="chat-users__user-name">{name}</p>
          <p className="chat-users__user-post">{post}</p>
          <a href={`tel:+79876543210`} className="chat-users__user-phone">{phone}</a>
          <div className="chat-users__overchat-btn">
            <button className="chat-users__chat-btn">Личный чат</button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default UserInChatItem
