const banners = (state = {}, action) => {
  const { type, data, } = action;
  switch (type) {
    case "BANNERS_SUCCESS":
      return data?.banners;
    default:
      return state;
  }
};

export default banners;