import { memo } from 'react';
import CrossIcon from '../../components/Public/markup/CrossIcon';
import './index.scss'

const FlexibleTag = memo(({ title, removeBadge }) => <div className="flexible-tag__container">
    <span className="flexible-tag__title">{title}</span>
    <span className="flexible-tag__button " onClick={removeBadge}><CrossIcon /></span>
</div>
);

export default FlexibleTag;