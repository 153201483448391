import React from "react";

const Badge = (props) => {
  const { item, handleClick, title, identity, hideCloseButton, showTitle = true, alternativeKey = "title" } = props;
  if(!item){
    return ""
  }
  return (
    <div class="filtered">
      <div class="filtered__inner">
        {showTitle && <span class="filtered__type">{title}:</span>}
        <span class="filtered__value">{item[alternativeKey]}</span>
       {!hideCloseButton && <button
          class="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            class="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>}
      </div>
    </div>
  );
};
export default Badge;
